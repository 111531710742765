import styled from '@emotion/styled/macro';
import { ArrowBack, Cancel } from '@mui/icons-material';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { DatePicker } from '@mui/x-date-pickers';
import { DBWorkOrders } from '@prisma/client';
import {
  BranchesData,
  DBBranchResponse,
  EdiCreateOrderResponse,
  FinishOrder,
  ProductDetailsDataInterface,
} from '@tyrio/dto';
import { FEATURE_FLAGS } from '@tyrio/feature-flags';
import { CalendarDate } from 'calendar-date';
import _, { get, isEmpty, set, uniq } from 'lodash';
import qs from 'qs';
import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import { ExpiredSessionModal } from '../../../components/Timer/modals/ExpiredSession';
import { WorkOrderDataType } from '../../../context/WorkOrderContext';
import { CustomerOrdersSidebar } from '../../../features/customer-orders/CustomerOrdersSidebar';
import { SalesOrdersFlowContext } from '../../../features/sales-orders/SalesOrdersFlowContext';
import { useBranchQuery } from '../../../hooks/api/useBranchQuery';
import { useClientSuppliersQuery } from '../../../hooks/api/useClientSuppliersQuery';
import { useFinishWorkOrder } from '../query/useFinishOrder';
import { TransportPriority } from '../types';
import { FlowHeader } from './FlowHeader';
import { OrderSupplierBox } from './OrderComponents/OrderSupplierBox';
import { WorkOrderBox } from './WorkOrder/WorkOrderBox';
import { PriceComparisonContext } from './context';
import { OrderSuccessAlert } from './elements/OrderSuccessAlert';
import { isoToCalendarDate } from './utils';

export const OrderScreenV2 = () => {
  const ctx = useContext(PriceComparisonContext);
  const soctx = useContext(SalesOrdersFlowContext);
  const order = ctx.data.order;
  const history = useHistory();
  const supplierIds = Object.keys(ctx.data.order ?? {});
  const [splitShipment, setSplitShipment] = useState(
    soctx.customerOrder &&
      soctx.customerOrder?.workOrders &&
      soctx.customerOrder?.workOrders.length > 1 &&
      soctx.isDropshipping
      ? true
      : false
  );
  const [branchErpId, setBranchErpId] = useState<Record<number, string | null>>(
    {}
  );
  const [ritamResponse, setRitamResponse] = useState<string[] | null>(
    ctx.data?.ritamResponse ?? null
  );
  const [branches, setBranches] = useState<DBBranchResponse[]>([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [ordersBody, setOrdersBody] = useState<FinishOrder | null>(null);

  const onBranchesSuccess = (data: DBBranchResponse[]) => {
    data.forEach((branch) => {
      set(branchErpId, branch.id, branch.erpId);
      setBranchErpId({ ...branchErpId });
    });
    setBranches(data.filter((branch) => branch.isActive));
  };

  const setOrderResponse = (key: string, response: DBWorkOrders) => {
    ctx.setInputValue({
      workOrderData: {
        ...(ctx.data?.workOrderData ?? {}),
        orderResponse: {
          ...(ctx.data?.workOrderData?.orderResponse ?? {}),
          [key]: [response],
        },
      },
    });
  };

  const removeValues = () => {
    // HARD RESET
    soctx.setQty('', '', 0, 'stock', true);
  };

  const onFinishSuccess = (res: {
    data: DBWorkOrders[];
    ritamResponse: string[];
  }) => {
    const { data, ritamResponse } = res;

    setRitamResponse(ritamResponse);
    ctx.setInputValue({ ritamResponse: ritamResponse });

    if (!splitShipment) {
      // in this case only one order is created
      Object.keys(
        ctx.data?.workOrderData?.serviceAddressBranches ?? {}
      ).forEach((key) => {
        const response = data[0];
        setOrderResponse(key, response);
      });
    } else {
      Object.entries(
        ctx.data?.workOrderData?.serviceAddressBranches ?? {}
      ).forEach(([key, value]) => {
        const erpId = get(branchErpId, value, null);
        // const order = ctx.data.workOrderData?.order?.[key];

        const response = data.find((e) => e.erpBranchId === erpId);

        if (response) setOrderResponse(key, response);
      });
    }
    removeValues();

    soctx.setScreen('process_order');

    const storageSalesChannels = JSON.parse(
      (localStorage.getItem('@customer_orders_sales_channel') as string) ?? '[]'
    );

    const search = {
      searchFilter: 'orderNumber',
      pageSize: 20,
      page: 1,
      salesChannelKeys: storageSalesChannels,
    };

    history.push({
      pathname: '/dashboard/customer-orders',
      search: qs.stringify(search),
    });
  };

  const { data: clientSuppliers } = useClientSuppliersQuery(supplierIds);
  useBranchQuery(onBranchesSuccess);

  const { finishRitamOrder, status } = useFinishWorkOrder(onFinishSuccess);

  const orderCompleteStats = useMemo(() => {
    const successfulOrders: EdiCreateOrderResponse[] = [];
    const failedOrders: EdiCreateOrderResponse[] = [];

    Object.values(ctx.data.orderResponse ?? {}).forEach((item) => {
      if (item.errorCode === '0') successfulOrders.push(item);
      else failedOrders.push(item);
    });

    const allOrdersSuccessful =
      failedOrders.length === 0 &&
      successfulOrders.length === clientSuppliers?.count;

    return {
      allOrdersSuccessful,
      failedOrders,
      successfulOrders,
    };
  }, [clientSuppliers?.count, ctx.data.orderResponse]);

  const handleForStockLabelChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void = useCallback(
    (e) => {
      const checked = e.target.checked;

      if (checked)
        ctx.setInputValue({ reference: 'FOR STOCK', forStock: true });
      else ctx.setInputValue({ reference: '', forStock: false });
    },
    [ctx]
  );

  const requestedDeliveryDate = useMemo(() => {
    // now.setDate()
    let calendarDate: CalendarDate;
    if (ctx.data.requestedDeliveryCalendarDate) {
      calendarDate = new CalendarDate(ctx.data.requestedDeliveryCalendarDate);
    } else {
      const now = new Date();

      calendarDate = new CalendarDate(
        `${String(now.getFullYear()).padStart(2, '0')}-${String(
          now.getMonth() + 1
        ).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`
      );
      calendarDate.addDays(1);
    }
    return calendarDate.toDate();
  }, [ctx.data.requestedDeliveryCalendarDate]);

  const [isDirty, setIsDirty] = useState(false);
  const [isUploadInProcess, setIsUploadInProcess] = useState(false);

  const isFinnishOrderDisabled = () => {
    const selectedBranchesPO = Object.values(
      ctx.data?.serviceAddressBranches ?? {}
    );
    const selectedBranchesWO = Object.values(
      ctx.data.workOrderData?.serviceAddressBranches ?? {}
    );

    const allValues = [...selectedBranchesPO, ...selectedBranchesWO];

    if (isEmpty(allValues)) return true;

    const disabled = uniq(allValues).length > 1;
    return disabled && !splitShipment;
  };

  const checkIfOrderIsIncomplete = (
    orders: Record<string, WorkOrderDataType | ProductDetailsDataInterface[]>,
    orderIds: string[],
    orderResponse: Record<string, EdiCreateOrderResponse | DBWorkOrders[]>
  ) => {
    const ordersLength = Object.keys(orders ?? []).length;
    const ordersResponseLength = Object.keys(orderResponse ?? []).length;
    return (
      ordersLength > 0 &&
      (isEmpty(orderIds) || ordersLength > ordersResponseLength)
    );
  };

  const prepWorkOrdersBody = () => {
    const workOrders = ctx.data?.workOrderData?.order;
    const orderResponse = Object.keys(
      ctx.data.workOrderData?.orderResponse ?? []
    );
    const data = Object.entries(workOrders ?? []).filter(
      ([key]) => !orderResponse.includes(key)
    );

    return Object.values(Object.fromEntries(data));
  };

  const createBody = () => {
    const purchaseOrderIds: string[] = [];

    const branchIds: BranchesData = {
      purchaseOrder: {},
      workOrder: {},
      defaultId: null,
    };

    Object.entries(ctx.data.orderResponse ?? []).forEach(([key, value]) => {
      const supplierId = key;
      const orderId = value.createdOrder.id;
      const branchId = ctx.data?.serviceAddressBranches?.[supplierId];
      purchaseOrderIds.push(orderId);

      const erpId = get(branchErpId, branchId ?? 0, null);

      if (!splitShipment && branchId && branchIds.defaultId === null) {
        branchIds.defaultId = erpId;
      } else set(branchIds.purchaseOrder, orderId, erpId);
    });

    const workOrders = prepWorkOrdersBody();

    (workOrders ?? []).forEach((value) => {
      const key = value.branchId;

      const branchId =
        ctx.data?.workOrderData?.serviceAddressBranches?.[Number(key)];

      const erpId = get(branchErpId, branchId ?? 0, null);

      if (!splitShipment && branchId && branchIds.defaultId === null) {
        branchIds.defaultId = erpId;
      }
    });

    const purchaseOrdersIncomplete = checkIfOrderIsIncomplete(
      ctx?.data?.order ?? {},
      purchaseOrderIds,
      ctx?.data?.orderResponse ?? {}
    );

    const body: FinishOrder = {
      customerOrderId: soctx.customerOrderId,
      workOrders,
      purchaseOrderIds,
      splitShipment,
      branchesData: branchIds,
      dropShipping: soctx.isDropshipping,
    };

    if (purchaseOrdersIncomplete) {
      setShowWarningModal(purchaseOrdersIncomplete);
      setOrdersBody(body);
      return;
    } else return body;
  };

  const onBack = () => {
    if (ctx.mode === 'purchase_orders') ctx.setInputValue({ screen: 'table' });
    if (ctx.mode === 'sales_orders') soctx.setScreen('process_order');
  };

  if (!order) return null;
  return (
    <PageTemplateWrapper
      style={{
        paddingRight: ctx.canSidebarOpen && ctx.data.sidebarOpen ? 450 : 0,
        transition: 'all .3s ease',
      }}
      id="order_screen"
    >
      <ExpiredSessionModal
        text={'Some orders are not finished'}
        subtitle={'Are you sure you want to continue?'}
        isOpen={showWarningModal}
        onContinue={() => {
          setShowWarningModal(false);
          if (ordersBody) {
            finishRitamOrder(ordersBody);
            setOrdersBody(null);
          }
        }}
        onCancel={() => {
          setShowWarningModal(false);
          setOrdersBody(null);
        }}
      />
      <PageTemplateContent>
        <FlowHeader
          hasPlacedCustomerOrders={
            orderCompleteStats.successfulOrders.length > 0
          }
        />

        <Grid sx={{ padding: 2 }} container spacing={2}>
          {ctx.mode !== 'sales_orders' && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="reference"
                    label="Reference"
                    sx={{ maxWidth: 300 }}
                    value={ctx.data.reference}
                    disabled={
                      ctx.data.forStock ||
                      Object.values(ctx.data.orderResponse ?? {}).length > 0 ||
                      !!soctx.data.orderRef
                    }
                    onChange={(e) =>
                      ctx.setInputValue({ reference: e.target.value })
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleForStockLabelChange}
                          disabled={
                            Object.values(ctx.data.orderResponse ?? {}).length >
                              0 || !!soctx.data.orderRef
                          }
                        />
                      }
                      label="For stock"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="transport-priority-label">
                      Transport priority
                    </InputLabel>
                    <Select
                      labelId="transport-priority-label"
                      id="transport-priority"
                      value={ctx.data.transportPriority ?? 'RUSH'}
                      disabled={
                        Object.values(ctx.data.orderResponse ?? {}).length >
                          0 || !!soctx.data.orderRef
                      }
                      label="Transport priority"
                      onChange={(e) =>
                        ctx.setInputValue({
                          transportPriority: e.target
                            .value as TransportPriority,
                        })
                      }
                    >
                      <MenuItem value={'RUSH'}>RUSH</MenuItem>
                      <MenuItem value={'REPL'}>REPL</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    disablePast
                    onChange={(date) => {
                      if (date) {
                        const calendarDate = new CalendarDate(
                          isoToCalendarDate(date.toISOString())
                        );

                        ctx.setInputValue({
                          requestedDeliveryCalendarDate:
                            calendarDate.toFormat('yyyy-MM-dd'),
                        });
                      }
                    }}
                    value={requestedDeliveryDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        error={false}
                        label={'Preferred delivery date'}
                        style={{
                          width: '100%',
                          height: 56,
                          color: '#d32f2f',
                          borderRadius: 8,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {orderCompleteStats.allOrdersSuccessful && <OrderSuccessAlert />}
          <Grid item xs={12}>
            {clientSuppliers?.data?.map((s, idx) => {
              if (isEmpty(order[s.supplierId])) return null;

              return (
                <OrderSupplierBox
                  orderKey={idx + 1}
                  hasMultipleOrderKeys={clientSuppliers.data.length > 1}
                  clientSupplier={s}
                  data={order[s.supplierId]}
                  branches={branches ?? []}
                  key={s.supplierId + idx}
                />
              );
            })}
          </Grid>

          {ctx.data.workOrderData && !isEmpty(ctx.data.workOrderData.order) && (
            <Grid item xs={12}>
              {Object.values(ctx.data.workOrderData.order ?? {})?.map(
                (workOrder, idx) => (
                  <WorkOrderBox
                    key={idx}
                    workOrder={workOrder}
                    branches={branches ?? []}
                    branchErpId={branchErpId}
                  />
                )
              )}
            </Grid>
          )}
        </Grid>
        {ctx.mode === 'sales_orders' && (
          <BottomFixed style={{ bottom: '65px' }}></BottomFixed>
        )}

        <BottomFixed>
          {ritamResponse && (
            <Alert style={{ margin: '0 16px' }}>
              {ritamResponse?.map((str, index) => (
                <p key={index} style={index === 0 ? { margin: 0 } : undefined}>
                  {str}
                </p>
              ))}
            </Alert>
          )}
          <Grid container spacing={2} sx={{ padding: 2 }} gridRow={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {ctx.mode === 'purchase_orders' && (
              <Grid
                item
                xs={4}
                columnGap={2}
                sx={{ display: 'flex', justifyContent: 'start' }}
              >
                <Button
                  variant={'outlined'}
                  color={'info'}
                  startIcon={<ArrowBack color={'info'}></ArrowBack>}
                  onClick={onBack}
                >
                  Back
                </Button>

                <Button
                  variant={'outlined'}
                  color={'warning'}
                  startIcon={<Cancel color={'warning'}></Cancel>}
                  onClick={() => {
                    ctx.reset();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            )}
            <Grid item xs={ctx.mode === 'purchase_orders' ? 8 : 6}>
              {ctx.hasPlacedOrders && (
                <Alert
                  severity="warning"
                  sx={{ marginRight: 2, padding: '0 16px' }}
                >
                  You've started the order process. Further modification of
                  quantities is not possible for this order.
                </Alert>
              )}
            </Grid>

            {ctx.mode === 'sales_orders' && FEATURE_FLAGS.FINISH_ORDERS && (
              <Grid
                item
                xs={6}
                columnGap={2}
                sx={{ display: 'flex', justifyContent: 'end', padding: 0 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={splitShipment}
                      color={'info'}
                      disabled={
                        (soctx.customerOrder &&
                          soctx.customerOrder?.workOrders &&
                          soctx.customerOrder?.workOrders.length > 1 &&
                          soctx.isDropshipping) ??
                        false
                      }
                      onChange={() => setSplitShipment(!splitShipment)}
                    />
                  }
                  label="Split shipment"
                />
                <Tooltip
                  title={
                    isFinnishOrderDisabled()
                      ? 'Ensure all "Service address" dropdowns have the same value, or check the "Split Shipment" checkbox.'
                      : ''
                  }
                >
                  <span style={{ width: '50%' }}>
                    <Button
                      variant={'contained'}
                      color={'info'}
                      fullWidth
                      disabled={isFinnishOrderDisabled()}
                      disableElevation
                      startIcon={
                        status === 'loading' ? (
                          <CircularProgress size={15} />
                        ) : null
                      }
                      onClick={() => {
                        const body = createBody();
                        if (body) {
                          finishRitamOrder(body);
                        }
                      }}
                    >
                      Finish order
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </BottomFixed>
        <BottomSpacer />
      </PageTemplateContent>

      <CustomerOrdersSidebar
        setIsUploadInProcess={setIsUploadInProcess}
        isUploadInProcess={isUploadInProcess}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        showGoBackToCustomerOrders={true}
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        isProcessOrder={true}
        invisibleButton={
          !_.isEmpty(ctx.data.orderResponse) ||
          !_.isEmpty(ctx.data.workOrderData?.orderResponse)
        }
      />
    </PageTemplateWrapper>
  );
};

const BottomFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const BottomSpacer = styled.div`
  display: block;
  height: 100px;
  width: 100%;
`;
