/**
 * CONVERSION FROM CSV HELPER:
 *  const data = {};
 *  Object.keys(SPECIAL_MARKING).forEach((k, i) => {
 *      data[k.toUpperCase()] = {...SPECIAL_MARKING[k], key: k, type: SPECIAL_MARKING[k].type.split(';'), id: i+1}
 *  })
 *  JSON.stringify(data)
 *
 *  s.forEach((sw, idx) => {   data[sw.name.toUpperCase()] = { ...sw, key: sw.name.toUpperCase(), description: sw.longDescription, type: sw.type.split(';'), id: idx + 1 }})
 *
 */

export type SpecialMarkingType =
  | 'oem'
  | 'sidewall'
  | 'silent'
  | 'electric'
  | 'seal'
  | 'specMark';

export interface SpecialMarkingItem {
  key: string;
  description: string;
  shortDescription: string;
  name: string;
  type: string[];
  id: number;
}

export const SPECIAL_MARKING_TYPE_MAP: Record<
  string,
  { value: string; label: string }
> = {
  oem: {
    value: 'oem',
    label: 'OEM',
  },
  runFlat: {
    value: 'runFlat',
    label: 'Run Flat',
  },
  seal: {
    value: 'seal',
    label: 'Seal',
  },
  silent: {
    value: 'silent',
    label: 'Silent',
  },
  electric: {
    value: 'electric',
    label: 'Electric',
  },
  flank: {
    value: 'flank',
    label: 'Flank',
  },
  specMark: {
    value: 'specMark',
    label: 'Other',
  },
};
export const SIDEWALL_DATA = {
  WW: { name: 'WW', description: 'Whitewall', shortDescription: 'Whitewall' },
  WWW: {
    name: 'WWW',
    description: 'Wide Whitewall',
    shortDescription: 'Wide Whitewall',
  },
  WSW: {
    name: 'WSW',
    description: 'White Sidewall',
    shortDescription: 'White Sidewall',
  },
  WSE: {
    name: 'WSE',
    description: 'White Sidewall',
    shortDescription: 'White Sidewall',
  },
  NW: {
    name: 'NW',
    description: 'Narrow Whitewall',
    shortDescription: 'Narrow Whitewall',
  },
  XNW: {
    name: 'XNW',
    description: 'Extra Narrow White Width',
    shortDescription: 'Extra Narrow White Width',
  },
  RWL: {
    name: 'RWL',
    description: 'Rised White Letter',
    shortDescription: 'Rised White Letter',
  },
  OWL: {
    name: 'OWL',
    description: 'Outside White Letter',
    shortDescription: 'Outside White Letter',
  },
  ORWL: {
    name: 'ORWL',
    description: 'Outside Rised White Letter',
    shortDescription: 'Outside Rised White Letter',
  },
  WL: {
    name: 'WL',
    description: 'White Letter',
    shortDescription: 'White Letter',
  },
  WS: {
    name: 'WS',
    description: 'White Stripe',
    shortDescription: 'White Stripe',
  },
  VSB: {
    name: 'VSB',
    description: 'Vertical Serrated Band',
    shortDescription: 'Vertical Serrated Band',
  },
  BSW: {
    name: 'BSW',
    description: 'Black Sidewall',
    shortDescription: 'Black Sidewall',
  },
  BLK: {
    name: 'BLK',
    description: 'Black Sidewall',
    shortDescription: 'Black Sidewall',
  },
  BW: {
    name: 'BW',
    description: 'Black Sidewall',
    shortDescription: 'Black Sidewall',
  },
  RBL: {
    name: 'RBL',
    description: 'Rised Black Letter',
    shortDescription: 'Rised Black Letter',
  },
  OBL: {
    name: 'OBL',
    description: 'Outside Black Letter',
    shortDescription: 'Outside Black Letter',
  },
  'RACING LOGO': {
    name: 'RACING LOGO',
    description: 'Racing Logo Sidewall',
    shortDescription: 'Racing Logo Sidewall',
  },
  FRV: {
    name: 'FRV',
    description:
      'FRV Special sidewall design. Tires with FRV have a velourised, ring-shaped design of the tire sidewall (Full Ring Velvet)',
    shortDescription: 'Full Ring Velvet',
  },
  WLT: {
    name: 'WLT',
    description: 'White Letters',
    shortDescription: 'White Letters',
  },
  SW: {
    name: 'SW',
    description: 'White Sidewall',
    shortDescription: 'White Sidewall',
  },
  MWW: {
    name: 'MWW',
    description: 'Medium White Sidewall',
    shortDescription: 'Medium White Sidewall',
  },
  BSB: {
    name: 'BSB',
    description: 'Broken Serrated Band',
    shortDescription: 'Broken Serrated Band',
  },
  BSL: {
    name: 'BSL',
    description: 'Black Serrated Letters',
    shortDescription: 'Black Serrated Letters',
  },
  FB: {
    name: 'FB',
    description: 'Fianco bianco',
    shortDescription: 'Fianco bianco',
  },
  PTL: {
    name: 'PTL',
    description: 'Premium Touch Logo',
    shortDescription: 'Premium Touch Logo',
  },
  PW: {
    name: 'PW',
    description: 'White Sidewall',
    shortDescription: 'White Sidewall',
  },
  RBOL: {
    name: 'RBOL',
    description: 'Raised Black outline letters',
    shortDescription: 'Raised Black outline letters',
  },
  RRL: {
    name: 'RRL',
    description: 'Raised Red Letter',
    shortDescription: 'Raised Red Letter',
  },
  RWOL: {
    name: 'RWOL',
    description: 'Raised White outline letters',
    shortDescription: 'Raised White outline letters',
  },
  SRL: {
    name: 'SRL',
    description: 'Solid Red Letter',
    shortDescription: 'Solid Red Letter',
  },
  SYL: {
    name: 'SYL',
    description: 'Solid Yellow Letter',
    shortDescription: 'Solid Yellow Letter',
  },
  VUL: {
    name: 'VUL',
    description: 'Vulcanette Sidewall',
    shortDescription: 'Vulcanette Sidewall',
  },
  LW: {
    name: 'LW',
    description: 'White Letter Sidewall',
    shortDescription: 'White Letter Sidewall',
  },
  OW: {
    name: 'OW',
    description: 'Orange Wall Sidewall',
    shortDescription: 'Orange Wall Sidewall',
  },
};
export const SPECIAL_MARKING = {
  '(+)': {
    name: '(+)',
    shortDescription: 'OEM VW',
    longDescription: 'Original Equipment Volkswagen',
    type: ['oem'],
    icon: 'ICONS.volkswagen',
    key: '(+)',
    description: 'Original Equipment Volkswagen',
    id: 1,
  },
  '*': {
    name: '*',
    shortDescription: 'OEM BMW',
    longDescription: 'Original Equipment BMW',
    type: ['oem'],
    icon: 'ICONS.bmw',
    key: '*',
    description: 'Original Equipment BMW',
    id: 2,
  },
  A6A: {
    name: 'A6A',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A6A',
    description: 'Original Equipment Aston Martin',
    id: 3,
  },
  A7A: {
    name: 'A7A',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A7A',
    description: 'Original Equipment Aston Martin',
    id: 4,
  },
  A8A: {
    name: 'A8A',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A8A',
    description: 'Original Equipment Aston Martin',
    id: 5,
  },
  ABA: {
    name: 'ABA',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'ABA',
    description: 'Original Equipment Aston Martin',
    id: 6,
  },
  ALP: {
    name: 'ALP',
    shortDescription: 'OEM Alpina',
    longDescription: 'Original Equipment Alpina',
    type: ['oem'],
    icon: 'ICONS.alpina',
    key: 'ALP',
    description: 'Original Equipment Alpina',
    id: 7,
  },
  AM: {
    name: 'AM',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM',
    description: 'Original Equipment Aston Martin',
    id: 8,
  },
  AM4: {
    name: 'AM4',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM4',
    description: 'Original Equipment Aston Martin',
    id: 9,
  },
  AM8: {
    name: 'AM8',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM8',
    description: 'Original Equipment Aston Martin',
    id: 10,
  },
  AM9: {
    name: 'AM9',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM9',
    description: 'Original Equipment Aston Martin',
    id: 11,
  },
  AMP: {
    name: 'AMP',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AMP',
    description: 'Original Equipment Aston Martin',
    id: 12,
  },
  AMS: {
    name: 'AMS',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AMS',
    description: 'Original Equipment Aston Martin',
    id: 13,
  },
  AMV: {
    name: 'AMV',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AMV',
    description: 'Original Equipment Aston Martin',
    id: 14,
  },
  A5B: {
    name: 'A5B',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A5B',
    description: 'Original Equipment Aston Martin',
    id: 15,
  },
  A2A: {
    name: 'A2A',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A2A',
    description: 'Original Equipment Aston Martin',
    id: 16,
  },
  A4A: {
    name: 'A4A',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A4A',
    description: 'Original Equipment Aston Martin',
    id: 17,
  },
  A5A: {
    name: 'A5A',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A5A',
    description: 'Original Equipment Aston Martin',
    id: 18,
  },
  AMR: {
    name: 'AMR',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AMR',
    description: 'Original Equipment Aston Martin',
    id: 19,
  },
  AO: {
    name: 'AO',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Audi',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'AO',
    description: 'Original Equipment Audi',
    id: 20,
  },
  AO1: {
    name: 'AO1',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Audi',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'AO1',
    description: 'Original Equipment Audi',
    id: 21,
  },
  AO2: {
    name: 'AO2',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Audi',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'AO2',
    description: 'Original Equipment Audi',
    id: 22,
  },
  AO3: {
    name: 'AO3',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Audi',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'AO3',
    description: 'Original Equipment Audi',
    id: 23,
  },
  AR: {
    name: 'AR',
    shortDescription: 'OEM Alfa Romeo',
    longDescription: 'Original Equipment Alfa Romeo',
    type: ['oem'],
    icon: 'ICONS.alfa-romeo',
    key: 'AR',
    description: 'Original Equipment Alfa Romeo',
    id: 24,
  },
  ARR: {
    name: 'ARR',
    shortDescription: 'OEM Alfa Romeo',
    longDescription: 'Original Equipment Alfa Romeo',
    type: ['oem'],
    icon: 'ICONS.alfa-romeo',
    key: 'ARR',
    description: 'Original Equipment Alfa Romeo',
    id: 25,
  },
  B: {
    name: 'B',
    shortDescription: 'OEM Bentley',
    longDescription: 'Original Equipment Bentley',
    type: ['oem'],
    icon: 'ICONS.bentley',
    key: 'B',
    description: 'Original Equipment Bentley',
    id: 26,
  },
  B1: {
    name: 'B1',
    shortDescription: 'OEM Bentley',
    longDescription: 'Original Equipment Bentley',
    type: ['oem'],
    icon: 'ICONS.bentley',
    key: 'B1',
    description: 'Original Equipment Bentley',
    id: 27,
  },
  BC: {
    name: 'BC',
    shortDescription: 'OEM Bentley',
    longDescription: 'Original Equipment Bentley',
    type: ['oem'],
    icon: 'ICONS.bentley',
    key: 'BC',
    description: 'Original Equipment Bentley',
    id: 28,
  },
  BG: {
    name: 'BG',
    shortDescription: 'OEM Bugatti',
    longDescription: 'Original Equipment Bugatti',
    type: ['oem'],
    icon: 'ICONS.bugatti',
    key: 'BG',
    description: 'Original Equipment Bugatti',
    id: 29,
  },
  BL: {
    name: 'BL',
    shortDescription: 'OEM Bentley',
    longDescription: 'Original Equipment Bentley',
    type: ['oem'],
    icon: 'ICONS.bentley',
    key: 'BL',
    description: 'Original Equipment Bentley',
    id: 30,
  },
  C1: {
    name: 'C1',
    shortDescription: 'OEM Chrysler/Dodge',
    longDescription: 'Original Equipment Chrysler/Dodge',
    type: ['oem'],
    icon: 'ICONS.chrysler-doge',
    key: 'C1',
    description: 'Original Equipment Chrysler/Dodge',
    id: 31,
  },
  F: {
    name: 'F',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'F',
    description: 'Original Equipment Ferrari',
    id: 32,
  },
  F01: {
    name: 'F01',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'F01',
    description: 'Original Equipment Ferrari',
    id: 33,
  },
  F02: {
    name: 'F02',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'F02',
    description: 'Original Equipment Ferrari',
    id: 34,
  },
  F03: {
    name: 'F03',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'F03',
    description: 'Original Equipment Ferrari',
    id: 35,
  },
  F04: {
    name: 'F04',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'F04',
    description: 'Original Equipment Ferrari',
    id: 36,
  },
  GOE: {
    name: 'GOE',
    shortDescription: 'OEM Genesis',
    longDescription: 'Original Equipment Genesis',
    type: ['oem'],
    icon: 'ICONS.genesis',
    key: 'GOE',
    description: 'Original Equipment Genesis',
    id: 37,
  },
  H0: {
    name: 'H0',
    shortDescription: 'OEM Honda',
    longDescription: 'Original Equipment Honda',
    type: ['oem'],
    icon: 'ICONS.honda',
    key: 'H0',
    description: 'Original Equipment Honda',
    id: 38,
  },
  HN: {
    name: 'HN',
    shortDescription: 'OEM Hyundai',
    longDescription: 'Original Equipment Hyundai',
    type: ['oem'],
    icon: 'ICONS.hyundai',
    key: 'HN',
    description: 'Original Equipment Hyundai',
    id: 39,
  },
  HO: {
    name: 'HO',
    shortDescription: 'OEM Honda',
    longDescription: 'Original Equipment Honda',
    type: ['oem'],
    icon: 'ICONS.honda',
    key: 'HO',
    description: 'Original Equipment Honda',
    id: 40,
  },
  HP: {
    name: 'HP',
    shortDescription: 'OEM Pagani',
    longDescription: 'Original Equipment Pagani',
    type: ['oem'],
    icon: 'ICONS.pagani',
    key: 'HP',
    description: 'Original Equipment Pagani',
    id: 41,
  },
  I: {
    name: 'I',
    shortDescription: 'OEM Nio',
    longDescription: 'Original Equipment Nio',
    type: ['oem'],
    icon: 'ICONS.nio',
    key: 'I',
    description: 'Original Equipment Nio',
    id: 42,
  },
  'I*': {
    name: 'I*',
    shortDescription: 'OEM BMW',
    longDescription: 'Original Equipment BMW',
    type: ['oem'],
    icon: 'ICONS.bmw',
    key: 'I*',
    description: 'Original Equipment BMW',
    id: 43,
  },
  J: {
    name: 'J',
    shortDescription: 'OEM Jaguar',
    longDescription: 'Original Equipment Jaguar',
    type: ['oem'],
    icon: 'ICONS.jaguar',
    key: 'J',
    description: 'Original Equipment Jaguar',
    id: 44,
  },
  JLR: {
    name: 'JLR',
    shortDescription: 'OEM Jaguar & Land Rover',
    longDescription: 'Original Equipment Jaguar & Land Rover',
    type: ['oem'],
    icon: 'ICONS.jaguar-land-rover',
    key: 'JLR',
    description: 'Original Equipment Jaguar & Land Rover',
    id: 45,
  },
  JP: {
    name: 'JP',
    shortDescription: 'OEM Jeep',
    longDescription: 'Original Equipment Jeep',
    type: ['oem'],
    icon: 'ICONS.jeep',
    key: 'JP',
    description: 'Original Equipment Jeep',
    id: 46,
  },
  JRS: {
    name: 'JRS',
    shortDescription: 'OEM Jaguar',
    longDescription: 'Original Equipment Jaguar',
    type: ['oem'],
    icon: 'ICONS.jaguar',
    key: 'JRS',
    description: 'Original Equipment Jaguar',
    id: 47,
  },
  K1: {
    name: 'K1',
    shortDescription: 'OEM Ferrari',
    longDescription:
      'Original Equipment Ferrari except for Pirelli (Different Tread)',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'K1',
    description:
      'Original Equipment Ferrari except for Pirelli (Different Tread)',
    id: 48,
  },
  K2: {
    name: 'K2',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'K2',
    description: 'Original Equipment Ferrari',
    id: 49,
  },
  K3: {
    name: 'K3',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'K3',
    description: 'Original Equipment Ferrari',
    id: 50,
  },
  L: {
    name: 'L',
    shortDescription: 'OEM Lamborghini',
    longDescription: 'Original Equipment Lamborghini',
    type: ['oem'],
    icon: 'ICONS.lamborghini',
    key: 'L',
    description: 'Original Equipment Lamborghini',
    id: 51,
  },
  L1: {
    name: 'L1',
    shortDescription: 'OEM Lamborghini',
    longDescription: 'Original Equipment Lamborghini',
    type: ['oem'],
    icon: 'ICONS.lamborghini',
    key: 'L1',
    description: 'Original Equipment Lamborghini',
    id: 52,
  },
  LM1: {
    name: 'LM1',
    shortDescription: 'OEM Lucid',
    longDescription: 'Original Equipment Lucid',
    type: ['oem'],
    icon: 'ICONS.lucid',
    key: 'LM1',
    description: 'Original Equipment Lucid',
    id: 53,
  },
  LR: {
    name: 'LR',
    shortDescription: 'OEM Land Rover',
    longDescription: 'Original Equipment Land Rover',
    type: ['oem'],
    icon: 'ICONS.land-rover',
    key: 'LR',
    description: 'Original Equipment Land Rover',
    id: 54,
  },
  LR1: {
    name: 'LR1',
    shortDescription: 'OEM Land Rover',
    longDescription: 'Original Equipment Land Rover',
    type: ['oem'],
    icon: 'ICONS.land-rover',
    key: 'LR1',
    description: 'Original Equipment Land Rover',
    id: 55,
  },
  LR2: {
    name: 'LR2',
    shortDescription: 'OEM Land Rover',
    longDescription: 'Original Equipment Land Rover',
    type: ['oem'],
    icon: 'ICONS.land-rover',
    key: 'LR2',
    description: 'Original Equipment Land Rover',
    id: 56,
  },
  LR3: {
    name: 'LR3',
    shortDescription: 'OEM Land Rover',
    longDescription: 'Original Equipment Land Rover',
    type: ['oem'],
    icon: 'ICONS.land-rover',
    key: 'LR3',
    description: 'Original Equipment Land Rover',
    id: 57,
  },
  LR4: {
    name: 'LR4',
    shortDescription: 'OEM Land Rover',
    longDescription: 'Original Equipment Land Rover',
    type: ['oem'],
    icon: 'ICONS.land-rover',
    key: 'LR4',
    description: 'Original Equipment Land Rover',
    id: 58,
  },
  LR5: {
    name: 'LR5',
    shortDescription: 'OEM Land Rover',
    longDescription: 'Original Equipment Land Rover',
    type: ['oem'],
    icon: 'ICONS.land-rover',
    key: 'LR5',
    description: 'Original Equipment Land Rover',
    id: 59,
  },
  LS: {
    name: 'LS',
    shortDescription: 'OEM Lotus',
    longDescription: 'Original Equipment Lotus',
    type: ['oem'],
    icon: 'ICONS.lotus',
    key: 'LS',
    description: 'Original Equipment Lotus',
    id: 60,
  },
  LTS: {
    name: 'LTS',
    shortDescription: 'OEM Lotus',
    longDescription: 'Original Equipment Lotus',
    type: ['oem'],
    icon: 'ICONS.lotus',
    key: 'LTS',
    description: 'Original Equipment Lotus',
    id: 61,
  },
  MA: {
    name: 'MA',
    shortDescription: 'Mazda',
    longDescription: 'Original Equipment Mazda',
    type: ['oem'],
    icon: 'ICONS.mazda',
    key: 'MA',
    description: 'Original Equipment Mazda',
    id: 62,
  },
  MC: {
    name: 'MC',
    shortDescription: 'OEM McLaren',
    longDescription: 'Original Equipment McLaren',
    type: ['oem'],
    icon: 'ICONS.mclaren',
    key: 'MC',
    description: 'Original Equipment McLaren',
    id: 63,
  },
  'MC-C': {
    name: 'MC-C',
    shortDescription: 'OEM McLaren',
    longDescription: 'Original Equipment McLaren',
    type: ['oem'],
    icon: 'ICONS.mclaren',
    key: 'MC-C',
    description: 'Original Equipment McLaren',
    id: 64,
  },
  MC1: {
    name: 'MC1',
    shortDescription: 'OEM McLaren',
    longDescription: 'Original Equipment McLaren',
    type: ['oem'],
    icon: 'ICONS.mclaren',
    key: 'MC1',
    description: 'Original Equipment McLaren',
    id: 65,
  },
  MC2: {
    name: 'MC2',
    shortDescription: 'OEM McLaren',
    longDescription: 'Original Equipment McLaren',
    type: ['oem'],
    icon: 'ICONS.mclaren',
    key: 'MC2',
    description: 'Original Equipment McLaren',
    id: 66,
  },
  ME2: {
    name: 'ME2',
    shortDescription: 'OEM Dallara',
    longDescription: 'Original Equipment Dallara',
    type: ['oem'],
    icon: 'ICONS.dallara',
    key: 'ME2',
    description: 'Original Equipment Dallara',
    id: 67,
  },
  MGT: {
    name: 'MGT',
    shortDescription: 'OEM Maserati',
    longDescription: 'Original Equipment Maserati',
    type: ['oem'],
    icon: 'ICONS.maserati',
    key: 'MGT',
    description: 'Original Equipment Maserati',
    id: 68,
  },
  MGT1: {
    name: 'MGT1',
    shortDescription: 'OEM Maserati',
    longDescription: 'Original Equipment Maserati',
    type: ['oem'],
    icon: 'ICONS.maserati',
    key: 'MGT1',
    description: 'Original Equipment Maserati',
    id: 69,
  },
  MO: {
    name: 'MO',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO',
    description: 'Original Equipment Mercedes',
    id: 70,
  },
  'MO-P': {
    name: 'MO-P',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO-P',
    description: 'Original Equipment Mercedes',
    id: 71,
  },
  'MO-S': {
    name: 'MO-S',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO-S',
    description: 'Original Equipment Mercedes',
    id: 72,
  },
  'MO-V': {
    name: 'MO-V',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO-V',
    description: 'Original Equipment Mercedes',
    id: 73,
  },
  MO1: {
    name: 'MO1',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO1',
    description: 'Original Equipment Mercedes',
    id: 74,
  },
  MO1A: {
    name: 'MO1A',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO1A',
    description: 'Original Equipment Mercedes',
    id: 75,
  },
  MO2: {
    name: 'MO2',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO2',
    description: 'Original Equipment Mercedes',
    id: 76,
  },
  MO3: {
    name: 'MO3',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO3',
    description: 'Original Equipment Mercedes',
    id: 77,
  },
  N0: {
    name: 'N0',
    shortDescription: 'OEM Porsche',
    longDescription: 'Original Equipment Porsche',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'N0',
    description: 'Original Equipment Porsche',
    id: 78,
  },
  N1: {
    name: 'N1',
    shortDescription: 'OEM Porsche',
    longDescription: 'Original Equipment Porsche',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'N1',
    description: 'Original Equipment Porsche',
    id: 79,
  },
  N2: {
    name: 'N2',
    shortDescription: 'OEM Porsche',
    longDescription: 'Original Equipment Porsche',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'N2',
    description: 'Original Equipment Porsche',
    id: 80,
  },
  N3: {
    name: 'N3',
    shortDescription: 'OEM Porsche',
    longDescription: 'Original Equipment Porsche',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'N3',
    description: 'Original Equipment Porsche',
    id: 81,
  },
  N4: {
    name: 'N4',
    shortDescription: 'OEM Porsche',
    longDescription: 'Original Equipment Porsche',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'N4',
    description: 'Original Equipment Porsche',
    id: 82,
  },
  N5: {
    name: 'N5',
    shortDescription: 'OEM Porsche',
    longDescription: 'Original Equipment Porsche',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'N5',
    description: 'Original Equipment Porsche',
    id: 83,
  },
  N6: {
    name: 'N6',
    shortDescription: 'OEM Porsche',
    longDescription: 'Original Equipment Porsche',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'N6',
    description: 'Original Equipment Porsche',
    id: 84,
  },
  NA0: {
    name: 'NA0',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA0',
    description: 'Original Equipment Porsche 911',
    id: 85,
  },
  NA1: {
    name: 'NA1',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA1',
    description: 'Original Equipment Porsche 911',
    id: 86,
  },
  NA2: {
    name: 'NA2',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA2',
    description: 'Original Equipment Porsche 911',
    id: 87,
  },
  NA3: {
    name: 'NA3',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA3',
    description: 'Original Equipment Porsche 911',
    id: 88,
  },
  NA4: {
    name: 'NA4',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA4',
    description: 'Original Equipment Porsche 911',
    id: 89,
  },
  NA5: {
    name: 'NA5',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA5',
    description: 'Original Equipment Porsche 911',
    id: 90,
  },
  NA6: {
    name: 'NA6',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA6',
    description: 'Original Equipment Porsche 911',
    id: 91,
  },
  NA7: {
    name: 'NA7',
    shortDescription: 'OEM Porsche 911',
    longDescription: 'Original Equipment Porsche 911',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NA7',
    description: 'Original Equipment Porsche 911',
    id: 92,
  },
  NB0: {
    name: 'NB0',
    shortDescription: 'OEM Porsche Cayman/Boxster',
    longDescription: 'Original Equipment Porsche Cayman/Boxster',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NB0',
    description: 'Original Equipment Porsche Cayman/Boxster',
    id: 93,
  },
  NB1: {
    name: 'NB1',
    shortDescription: 'OEM Porsche Cayman/Boxster',
    longDescription: 'Original Equipment Porsche Cayman/Boxster',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NB1',
    description: 'Original Equipment Porsche Cayman/Boxster',
    id: 94,
  },
  NB2: {
    name: 'NB2',
    shortDescription: 'OEM Porsche Cayman/Boxster',
    longDescription: 'Original Equipment Porsche Cayman/Boxster',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NB2',
    description: 'Original Equipment Porsche Cayman/Boxster',
    id: 95,
  },
  NB3: {
    name: 'NB3',
    shortDescription: 'OEM Porsche Cayman/Boxster',
    longDescription: 'Original Equipment Porsche Cayman/Boxster',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NB3',
    description: 'Original Equipment Porsche Cayman/Boxster',
    id: 96,
  },
  NB4: {
    name: 'NB4',
    shortDescription: 'OEM Porsche Cayman/Boxster',
    longDescription: 'Original Equipment Porsche Cayman/Boxster',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NB4',
    description: 'Original Equipment Porsche Cayman/Boxster',
    id: 97,
  },
  NB5: {
    name: 'NB5',
    shortDescription: 'OEM Porsche Cayman/Boxster',
    longDescription: 'Original Equipment Porsche Cayman/Boxster',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NB5',
    description: 'Original Equipment Porsche Cayman/Boxster',
    id: 98,
  },
  NB6: {
    name: 'NB6',
    shortDescription: 'OEM Porsche Cayman/Boxster',
    longDescription: 'Original Equipment Porsche Cayman/Boxster',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NB6',
    description: 'Original Equipment Porsche Cayman/Boxster',
    id: 100,
  },
  NC0: {
    name: 'NC0',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC0',
    description: 'Original Equipment Porsche Cayenne',
    id: 101,
  },
  NC1: {
    name: 'NC1',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC1',
    description: 'Original Equipment Porsche Cayenne',
    id: 102,
  },
  NC2: {
    name: 'NC2',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC2',
    description: 'Original Equipment Porsche Cayenne',
    id: 103,
  },
  NC3: {
    name: 'NC3',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC3',
    description: 'Original Equipment Porsche Cayenne',
    id: 104,
  },
  NC4: {
    name: 'NC4',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC4',
    description: 'Original Equipment Porsche Cayenne',
    id: 105,
  },
  NC5: {
    name: 'NC5',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC5',
    description: 'Original Equipment Porsche Cayenne',
    id: 106,
  },
  NC6: {
    name: 'NC6',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC6',
    description: 'Original Equipment Porsche Cayenne',
    id: 107,
  },
  NC7: {
    name: 'NC7',
    shortDescription: 'OEM Porsche Cayenne',
    longDescription: 'Original Equipment Porsche Cayenne',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NC7',
    description: 'Original Equipment Porsche Cayenne',
    id: 108,
  },
  ND0: {
    name: 'ND0',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND0',
    description: 'Original Equipment Porsche Panamera',
    id: 109,
  },
  ND1: {
    name: 'ND1',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND1',
    description: 'Original Equipment Porsche Panamera',
    id: 110,
  },
  ND2: {
    name: 'ND2',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND2',
    description: 'Original Equipment Porsche Panamera',
    id: 111,
  },
  ND3: {
    name: 'ND3',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND3',
    description: 'Original Equipment Porsche Panamera',
    id: 112,
  },
  ND4: {
    name: 'ND4',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND4',
    description: 'Original Equipment Porsche Panamera',
    id: 113,
  },
  ND5: {
    name: 'ND5',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND5',
    description: 'Original Equipment Porsche Panamera',
    id: 114,
  },
  ND6: {
    name: 'ND6',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND6',
    description: 'Original Equipment Porsche Panamera',
    id: 115,
  },
  ND7: {
    name: 'ND7',
    shortDescription: 'OEM Porsche Panamera',
    longDescription: 'Original Equipment Porsche Panamera',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'ND7',
    description: 'Original Equipment Porsche Panamera',
    id: 116,
  },
  NE0: {
    name: 'NE0',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE0',
    description: 'Original Equipment Porsche Macan',
    id: 117,
  },
  NE1: {
    name: 'NE1',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE1',
    description: 'Original Equipment Porsche Macan',
    id: 118,
  },
  NE2: {
    name: 'NE2',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE2',
    description: 'Original Equipment Porsche Macan',
    id: 119,
  },
  NE3: {
    name: 'NE3',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE3',
    description: 'Original Equipment Porsche Macan',
    id: 120,
  },
  NE4: {
    name: 'NE4',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE4',
    description: 'Original Equipment Porsche Macan',
    id: 121,
  },
  NE5: {
    name: 'NE5',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE5',
    description: 'Original Equipment Porsche Macan',
    id: 122,
  },
  NE6: {
    name: 'NE6',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE6',
    description: 'Original Equipment Porsche Macan',
    id: 123,
  },
  NE7: {
    name: 'NE7',
    shortDescription: 'OEM Porsche Macan',
    longDescription: 'Original Equipment Porsche Macan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NE7',
    description: 'Original Equipment Porsche Macan',
    id: 124,
  },
  NF0: {
    name: 'NF0',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF0',
    description: 'Original Equipment Porsche Taycan',
    id: 125,
  },
  NF1: {
    name: 'NF1',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF1',
    description: 'Original Equipment Porsche Taycan',
    id: 126,
  },
  NF2: {
    name: 'NF2',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF2',
    description: 'Original Equipment Porsche Taycan',
    id: 127,
  },
  NF3: {
    name: 'NF3',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF3',
    description: 'Original Equipment Porsche Taycan',
    id: 128,
  },
  NF4: {
    name: 'NF4',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF4',
    description: 'Original Equipment Porsche Taycan',
    id: 129,
  },
  NF5: {
    name: 'NF5',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF5',
    description: 'Original Equipment Porsche Taycan',
    id: 130,
  },
  NF6: {
    name: 'NF6',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF6',
    description: 'Original Equipment Porsche Taycan',
    id: 131,
  },
  NF7: {
    name: 'NF7',
    shortDescription: 'OEM Porsche Taycan',
    longDescription: 'Original Equipment Porsche Taycan',
    type: ['oem'],
    icon: 'ICONS.porsche',
    key: 'NF7',
    description: 'Original Equipment Porsche Taycan',
    id: 132,
  },
  NR1: {
    name: 'NR1',
    shortDescription: 'OEM Nissan GT-R Nismo',
    longDescription: 'Original Equipment Nissan GT-R Nismo',
    type: ['oem'],
    icon: 'ICONS.nissan',
    key: 'NR1',
    description: 'Original Equipment Nissan GT-R Nismo',
    id: 133,
  },
  R: {
    name: 'R',
    shortDescription: 'OEM Renault',
    longDescription: 'Original Equipment Renault',
    type: ['oem'],
    icon: 'ICONS.renault',
    key: 'R',
    description: 'Original Equipment Renault',
    id: 134,
  },
  RIV: {
    name: 'RIV',
    shortDescription: 'OEM Rivian',
    longDescription: 'Original Equipment Rivian',
    type: ['oem'],
    icon: 'ICONS.rivian',
    key: 'RIV',
    description: 'Original Equipment Rivian',
    id: 135,
  },
  RO1: {
    name: 'RO1',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Sports Audi cars quattro',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'RO1',
    description: 'Original Equipment Sports Audi cars quattro',
    id: 136,
  },
  RO2: {
    name: 'RO2',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Sports Audi cars quattro',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'RO2',
    description: 'Original Equipment Sports Audi cars quattro',
    id: 137,
  },
  T0: {
    name: 'T0',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'T0',
    description: 'Original Equipment Tesla',
    id: 138,
  },
  T1: {
    name: 'T1',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'T1',
    description: 'Original Equipment Tesla',
    id: 139,
  },
  T2: {
    name: 'T2',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'T2',
    description: 'Original Equipment Tesla',
    id: 140,
  },
  T3: {
    name: 'T3',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'T3',
    description: 'Original Equipment Tesla',
    id: 141,
  },
  T4: {
    name: 'T4',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'T4',
    description: 'Original Equipment Tesla',
    id: 142,
  },
  T5: {
    name: 'T5',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'T5',
    description: 'Original Equipment Tesla',
    id: 143,
  },
  T6: {
    name: 'T6',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'T6',
    description: 'Original Equipment Tesla',
    id: 144,
  },
  TO: {
    name: 'TO',
    shortDescription: 'OEM Tesla',
    longDescription: 'Original Equipment Tesla',
    type: ['oem'],
    icon: 'ICONS.tesla',
    key: 'TO',
    description: 'Original Equipment Tesla',
    id: 145,
  },
  TPC: {
    name: 'TPC',
    shortDescription: 'OEM General Motors',
    longDescription: 'Original Equipment General Motors',
    type: ['oem'],
    icon: 'ICONS.general-motors',
    key: 'TPC',
    description: 'Original Equipment General Motors',
    id: 146,
  },
  VOL: {
    name: 'VOL',
    shortDescription: 'OEM Volvo',
    longDescription: 'Original Equipment Volvo',
    type: ['oem'],
    icon: 'ICONS.volvo',
    key: 'VOL',
    description: 'Original Equipment Volvo',
    id: 147,
  },
  AOE: {
    name: 'AOE',
    shortDescription: 'Run Flat Audi',
    longDescription: 'Run Flat Audi Original Extended',
    type: ['oem', 'runFlat'],
    icon: 'ICONS.audi',
    key: 'AOE',
    description: 'Run Flat Audi Original Extended',
    id: 148,
  },
  MOE: {
    name: 'MOE',
    shortDescription: 'Run Flat Mercedes',
    longDescription: 'Run Flat Mercedes Original Extended',
    type: ['oem', 'runFlat'],
    icon: 'ICONS.mercedes',
    key: 'MOE',
    description: 'Run Flat Mercedes Original Extended',
    id: 149,
  },
  'MOE-S': {
    name: 'MOE-S',
    shortDescription: 'Run Flat Mercedes',
    longDescription: 'Run Flat Mercedes Original Extended',
    type: ['oem', 'runFlat'],
    icon: 'ICONS.mercedes',
    key: 'MOE-S',
    description: 'Run Flat Mercedes Original Extended',
    id: 150,
  },
  BSR: {
    name: 'BSR',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'BSR',
    description: 'Run Flat',
    id: 151,
  },
  DSST: {
    name: 'DSST',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'DSST',
    description: 'Run Flat',
    id: 152,
  },
  EMT: {
    name: 'EMT',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'EMT',
    description: 'Run Flat',
    id: 153,
  },
  HRS: {
    name: 'HRS',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'HRS',
    description: 'Run Flat',
    id: 154,
  },
  PAX: {
    name: 'PAX',
    shortDescription: 'Run Flat',
    longDescription: 'Zero pressure Michelin',
    type: ['runFlat'],
    icon: '',
    key: 'PAX',
    description: 'Zero pressure Michelin',
    id: 155,
  },
  'R-F': {
    name: 'r-f',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'R-F',
    description: 'Run Flat',
    id: 156,
  },
  RFT: {
    name: 'RFT',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'RFT',
    description: 'Run Flat',
    id: 157,
  },
  ROF: {
    name: 'ROF',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'ROF',
    description: 'Run Flat',
    id: 158,
  },
  RSC: {
    name: 'RSC',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'RSC',
    description: 'Run Flat',
    id: 159,
  },
  'RUN FLAT': {
    name: 'Run Flat',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'RUN FLAT',
    description: 'Run Flat',
    id: 160,
  },
  RUNONFLAT: {
    name: 'RunOnFlat',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'RUNONFLAT',
    description: 'Run Flat',
    id: 161,
  },
  SSR: {
    name: 'SSR',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'SSR',
    description: 'Run Flat',
    id: 162,
  },
  SST: {
    name: 'SST',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat Self Supporting Technology',
    type: ['runFlat'],
    icon: '',
    key: 'SST',
    description: 'Run Flat Self Supporting Technology',
    id: 163,
  },
  XRP: {
    name: 'XRP',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'XRP',
    description: 'Run Flat',
    id: 164,
  },
  ZP: {
    name: 'ZP',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'ZP',
    description: 'Run Flat',
    id: 165,
  },
  ZPS: {
    name: 'ZPS',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat',
    type: ['runFlat'],
    icon: '',
    key: 'ZPS',
    description: 'Run Flat',
    id: 166,
  },
  EXT: {
    name: 'EXT',
    shortDescription: 'Run Flat EXT',
    longDescription: 'Run Flat Extension',
    type: ['runFlat'],
    icon: '',
    key: 'EXT',
    description: 'Run Flat Extension',
    id: 167,
  },
  'B-SEAL': {
    name: 'B-Seal',
    shortDescription: 'SelfSeal',
    longDescription: 'Bridgestone self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'B-SEAL',
    description: 'Bridgestone self sealing technology',
    id: 168,
  },
  C_S: {
    name: 'C_S',
    shortDescription: 'ContiSeal',
    longDescription: 'ContiSeal - self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'C_S',
    description: 'ContiSeal - self sealing technology',
    id: 169,
  },
  CONTISEAL: {
    name: 'ContiSeal',
    shortDescription: 'ContiSeal',
    longDescription: 'Continental self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'CONTISEAL',
    description: 'Continental self sealing technology',
    id: 170,
  },
  'CORE SEAL': {
    name: 'Core Seal',
    shortDescription: 'SelfSeal',
    longDescription:
      'Falken Core Seal - The CORESEAL technology prevents the loss of air pressure due to punctures',
    type: ['seal'],
    icon: '',
    key: 'CORE SEAL',
    description:
      'Falken Core Seal - The CORESEAL technology prevents the loss of air pressure due to punctures',
    id: 171,
  },
  CORESEAL: {
    name: 'CORESEAL',
    shortDescription: 'SelfSeal',
    longDescription:
      'Falken Core Seal - The CORESEAL technology prevents the loss of air pressure due to punctures',
    type: ['seal'],
    icon: '',
    key: 'CORESEAL',
    description:
      'Falken Core Seal - The CORESEAL technology prevents the loss of air pressure due to punctures',
    id: 172,
  },
  'S-I': {
    name: 's-i',
    shortDescription: 'SelfSeal',
    longDescription: 'Pirelli self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'S-I',
    description: 'Pirelli self sealing technology',
    id: 173,
  },
  SEALGUARD: {
    name: 'SealGuard',
    shortDescription: 'SelfSeal',
    longDescription: 'Hankook self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'SEALGUARD',
    description: 'Hankook self sealing technology',
    id: 174,
  },
  SEALTECH: {
    name: 'SealTech',
    shortDescription: 'SelfSeal',
    longDescription: 'GoodYear self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'SEALTECH',
    description: 'GoodYear self sealing technology',
    id: 175,
  },
  SELFSEAL: {
    name: 'SelfSeal',
    shortDescription: 'SelfSeal',
    longDescription: 'Michelin  self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'SELFSEAL',
    description: 'Michelin  self sealing technology',
    id: 176,
  },
  SLT: {
    name: 'SLT',
    shortDescription: 'SelfSeal',
    longDescription: 'Bridgestone self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'SLT',
    description: 'Bridgestone self sealing technology',
    id: 177,
  },
  ST: {
    name: 'ST',
    shortDescription: 'Different Tread',
    longDescription: 'Different Tread',
    type: ['specMark'],
    icon: '',
    key: 'ST',
    description: 'Different Tread',
    id: 217,
  },
  ACOUSTIC: {
    name: 'Acoustic',
    shortDescription: 'Silent',
    longDescription: 'Michelin noise cancelling tyre',
    type: ['silent'],
    icon: '',
    key: 'ACOUSTIC',
    description: 'Michelin noise cancelling tyre',
    id: 179,
  },
  'B-SILENT': {
    name: 'B-Silent',
    shortDescription: 'Silent',
    longDescription: 'Bridgestone noise cancelling tyre',
    type: ['silent'],
    icon: '',
    key: 'B-SILENT',
    description: 'Bridgestone noise cancelling tyre',
    id: 180,
  },
  'K-SILENT': {
    name: 'K-Silent',
    shortDescription: 'Silent',
    longDescription:
      'Kumho K-Silent Technology employs a custom-designed polyurethane foam to muffle noise',
    type: ['silent'],
    icon: '',
    key: 'K-SILENT',
    description:
      'Kumho K-Silent Technology employs a custom-designed polyurethane foam to muffle noise',
    id: 181,
  },
  NCS: {
    name: 'NCS',
    shortDescription: 'Silent',
    longDescription: 'Pirelli Noise Cancelling System',
    type: ['silent'],
    icon: '',
    key: 'NCS',
    description: 'Pirelli Noise Cancelling System',
    id: 182,
  },
  NST: {
    name: 'NST',
    shortDescription: 'Silent',
    longDescription: 'Dunlop Noise Shield Technology',
    type: ['silent'],
    icon: '',
    key: 'NST',
    description: 'Dunlop Noise Shield Technology',
    id: 183,
  },
  PNCS: {
    name: 'PNCS',
    shortDescription: 'Silent',
    longDescription: 'Pirelli Noise Cancelling System',
    type: ['silent'],
    icon: '',
    key: 'PNCS',
    description: 'Pirelli Noise Cancelling System',
    id: 184,
  },
  SCT: {
    name: 'SCT',
    shortDescription: 'Silent',
    longDescription: 'GoodYear noise cancelling tyre',
    type: ['silent'],
    icon: '',
    key: 'SCT',
    description: 'GoodYear noise cancelling tyre',
    id: 185,
  },
  SILENT: {
    name: 'Silent',
    shortDescription: 'Silent',
    longDescription: 'Reduced road noise',
    type: ['silent'],
    icon: '',
    key: 'SILENT',
    description: 'Reduced road noise',
    id: 186,
  },
  'SILENT CORE': {
    name: 'Silent Core',
    shortDescription: 'Silent',
    longDescription:
      'Falken Silent Core Noice redue - It can reduce drive-by noise generation by up to 10 decibels',
    type: ['silent'],
    icon: '',
    key: 'SILENT CORE',
    description:
      'Falken Silent Core Noice redue - It can reduce drive-by noise generation by up to 10 decibels',
    id: 187,
  },
  'SILENT FOAM': {
    name: 'Silent Foam',
    shortDescription: 'Silent',
    longDescription: 'Yokohama Silent',
    type: ['silent'],
    icon: '',
    key: 'SILENT FOAM',
    description: 'Yokohama Silent',
    id: 188,
  },
  ELECT: {
    name: 'ELECT',
    shortDescription: 'Electric',
    longDescription: 'Electric vehicles',
    type: ['electric'],
    icon: 'ICONS.electric',
    key: 'ELECT',
    description: 'Electric vehicles',
    id: 189,
  },
  ELECTRIC: {
    name: 'Electric',
    shortDescription: 'Electric',
    longDescription: 'Electric vehicles',
    type: ['electric'],
    icon: 'ICONS.electric',
    key: 'ELECTRIC',
    description: 'Electric vehicles',
    id: 190,
  },
  ELT: {
    name: 'elt',
    shortDescription: 'Electric',
    longDescription: 'Electric vehicles',
    type: ['electric'],
    icon: 'ICONS.electric',
    key: 'ELT',
    description: 'Electric vehicles',
    id: 191,
  },
  ENLITEN: {
    name: 'Enliten',
    shortDescription: 'Electric',
    longDescription: 'Electric vehicles',
    type: ['electric'],
    icon: 'ICONS.electric',
    key: 'ENLITEN',
    description: 'Electric vehicles',
    id: 192,
  },
  FP: {
    name: 'FP',
    shortDescription: 'Rim protection',
    longDescription: 'Rim protection',
    type: ['flank'],
    icon: '',
    key: 'FP',
    description: 'Rim protection',
    id: 193,
  },
  FR: {
    name: 'FR',
    shortDescription: 'Rim protection',
    longDescription: 'Rim protection',
    type: ['flank'],
    icon: '',
    key: 'FR',
    description: 'Rim protection',
    id: 194,
  },
  FSL: {
    name: 'FSL',
    shortDescription: 'Rim protection',
    longDescription: 'Rim protection',
    type: ['flank'],
    icon: '',
    key: 'FSL',
    description: 'Rim protection',
    id: 195,
  },
  MFS: {
    name: 'MFS',
    shortDescription: 'Rim protection',
    longDescription: 'Rim protection',
    type: ['flank'],
    icon: '',
    key: 'MFS',
    description: 'Rim protection',
    id: 196,
  },
  RPB: {
    name: 'RPB',
    shortDescription: 'Rim protection',
    longDescription: 'Rim protection',
    type: ['flank'],
    icon: '',
    key: 'RPB',
    description: 'Rim protection',
    id: 197,
  },
  FLANK: {
    name: 'Flank',
    shortDescription: 'Rim protection',
    longDescription: 'Rim protection',
    type: ['flank'],
    icon: '',
    key: 'FLANK',
    description: 'Rim protection',
    id: 198,
  },
  '(E)': {
    name: '(e)',
    shortDescription: 'Eco',
    longDescription: 'Eco tyre',
    type: ['specMark'],
    icon: '',
    key: '(E)',
    description: 'Eco tyre',
    id: 199,
  },
  '#': {
    name: '#',
    shortDescription: 'Out of production',
    longDescription: 'Out of production',
    type: ['specMark'],
    icon: '',
    key: '#',
    description: 'Out of production',
    id: 200,
  },
  '##': {
    name: '##',
    shortDescription: 'Out of production',
    longDescription: 'Out of production',
    type: ['specMark'],
    icon: '',
    key: '##',
    description: 'Out of production',
    id: 201,
  },
  '###': {
    name: '###',
    shortDescription: 'Out of production',
    longDescription: 'Out of production',
    type: ['specMark'],
    icon: '',
    key: '###',
    description: 'Out of production',
    id: 202,
  },
  DT: {
    name: 'DT',
    shortDescription: 'Different Tread',
    longDescription: 'Different Tread',
    type: ['specMark'],
    icon: '',
    key: 'DT',
    description: 'Different Tread',
    id: 203,
  },
  DT1: {
    name: 'DT1',
    shortDescription: 'Different Tread',
    longDescription: 'Different Tread',
    type: ['specMark'],
    icon: '',
    key: 'DT1',
    description: 'Different Tread',
    id: 204,
  },
  DT2: {
    name: 'DT2',
    shortDescription: 'Different Tread',
    longDescription: 'Different Tread',
    type: ['specMark'],
    icon: '',
    key: 'DT2',
    description: 'Different Tread',
    id: 205,
  },
  // ECO: {
  //   name: 'eco',
  //   shortDescription: 'Eco',
  //   longDescription: 'Eco tyre',
  //   type: ['specMark'],
  //   icon: '',
  //   key: 'ECO',
  //   description: 'Eco tyre',
  //   id: 206,
  // },
  G1: {
    name: 'G1',
    shortDescription: 'Different Tread',
    longDescription:
      'This marking indicates that outside diameter or tread width differs from a tyre without this marking. We recommend to mount two G1 on the same axle. For a 4x4, we recommend 4 G1 tyres.',
    type: ['specMark'],
    icon: '',
    key: 'G1',
    description:
      'This marking indicates that outside diameter or tread width differs from a tyre without this marking. We recommend to mount two G1 on the same axle. For a 4x4, we recommend 4 G1 tyres.',
    id: 207,
  },
  GRNX: {
    name: 'GRNX',
    shortDescription: 'Eco',
    longDescription: 'Eco tyre',
    type: ['specMark'],
    icon: '',
    key: 'GRNX',
    description: 'Eco tyre',
    id: 208,
  },
  KS: {
    name: 'KS',
    shortDescription: 'Different Tread',
    longDescription: 'Different Tread',
    type: ['specMark'],
    icon: '',
    key: 'KS',
    description: 'Different Tread',
    id: 209,
  },
  NHS: {
    name: 'NHS',
    shortDescription: 'Not for Highway Service',
    longDescription: 'Not for Highway Service',
    type: ['specMark'],
    icon: '',
    key: 'NHS',
    description: 'Not for Highway Service',
    id: 210,
  },
  'P.O.R.': {
    name: 'P.O.R.',
    shortDescription: 'Proffesional Off-Road',
    longDescription: 'Proffesional Off-Road',
    type: ['specMark'],
    icon: '',
    key: 'P.O.R.',
    description: 'Proffesional Off-Road',
    id: 211,
  },
  POR: {
    name: 'POR',
    shortDescription: 'Proffesional Off-Road',
    longDescription: 'Proffesional Off-Road',
    type: ['specMark'],
    icon: '',
    key: 'POR',
    description: 'Proffesional Off-Road',
    id: 212,
  },
  S1: {
    name: 'S1',
    shortDescription: 'Reduced rolling resistance',
    longDescription: 'Michelin Reduced rolling resistance',
    type: ['specMark'],
    icon: '',
    key: 'S1',
    description: 'Michelin Reduced rolling resistance',
    id: 213,
  },
  S2: {
    name: 'S2',
    shortDescription: 'Reduced rolling resistance',
    longDescription: 'Michelin Reduced rolling resistance',
    type: ['specMark'],
    icon: '',
    key: 'S2',
    description: 'Michelin Reduced rolling resistance',
    id: 214,
  },
  S3: {
    name: 'S3',
    shortDescription: 'Reduced rolling resistance',
    longDescription: 'Michelin Reduced rolling resistance',
    type: ['specMark'],
    icon: '',
    key: 'S3',
    description: 'Michelin Reduced rolling resistance',
    id: 215,
  },
  S4: {
    name: 'S4',
    shortDescription: 'Reduced rolling resistance',
    longDescription: 'Michelin Reduced rolling resistance',
    type: ['specMark'],
    icon: '',
    key: 'S4',
    description: 'Michelin Reduced rolling resistance',
    id: 216,
  },
  STUDDED: {
    name: 'studded',
    shortDescription: 'Studded tyre',
    longDescription:
      'Studded tyre. Type of winter tyre which has extra metal studs inserted into its tread.',
    type: ['specMark'],
    icon: '',
    key: 'STUDDED',
    description:
      'Studded tyre. Type of winter tyre which has extra metal studs inserted into its tread.',
    id: 218,
  },
  OLOGIC: {
    name: 'Ologic',
    shortDescription: 'Ologic',
    longDescription: 'Superior Fuel Efficiency',
    type: ['specMark'],
    icon: '',
    key: 'OLOGIC',
    description: 'Superior Fuel Efficiency',
    id: 219,
  },
  ML: {
    // AS 10.10.2023
    name: 'ML',
    shortDescription: 'Rim protection',
    longDescription: 'Rim protection',
    type: ['flank'],
    icon: '',
    key: 'ML',
    description: 'Rim protection',
    id: 220,
  },
  SEAL: {
    // AS 10.10.2023
    name: 'SEAL',
    shortDescription: 'SelfSeal',
    longDescription: 'Self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'SEAL',
    description: 'Self sealing technology',
    id: 221,
  },
  FEXM: {
    // AS 10.10.2023
    name: 'FEXM',
    shortDescription: 'Run Flat',
    longDescription: 'Run Flat Falken',
    type: ['runflat'],
    icon: '',
    key: 'FEXM',
    description: 'Run Flat Falken',
    id: 221,
  },
  FORD: {
    // AS 11.10.2023
    name: 'FORD',
    shortDescription: 'OEM Ford',
    longDescription: 'Original Equipment Ford',
    type: ['oem'],
    icon: 'ICONS.ford',
    key: 'FORD',
    description: 'Original Equipment Ford',
    id: 222,
  },
  VW: {
    // AS 11.10.2023
    name: 'VW',
    shortDescription: 'OEM VW',
    longDescription: 'Original Equipment Volkswagen',
    type: ['oem'],
    icon: 'ICONS.Volkswagen',
    key: 'VW',
    description: 'Original Equipment Volkswagen',
    id: 223,
  },
  RG: {
    // AS 13.10.2023
    name: 'RG',
    shortDescription: 'Rim guard',
    longDescription: 'Rim guard with rim protection strip',
    type: ['flank'],
    icon: '',
    key: 'RG',
    description: 'Rim guard with rim protection strip',
    id: 224,
  },
  A4E: {
    name: 'A4E',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'A4E',
    description: 'Original Equipment Aston Martin',
    id: 225,
  },
  AL: {
    name: 'AL',
    shortDescription: 'OEM Alfa Romeo',
    longDescription: 'Original Equipment Alfa Romeo',
    type: ['oem'],
    icon: 'ICONS.alfa-romeo',
    key: 'AL',
    description: 'Original Equipment Alfa Romeo',
    id: 226,
  },
  AM5: {
    name: 'AM5',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM5',
    description: 'Original Equipment Aston Martin',
    id: 227,
  },
  AM6: {
    name: 'AM6',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM6',
    description: 'Original Equipment Aston Martin',
    id: 228,
  },
  AMB: {
    name: 'AMB',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AMB',
    description: 'Original Equipment Aston Martin',
    id: 229,
  },
  AML: {
    name: 'AML',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AML',
    description: 'Original Equipment Aston Martin',
    id: 230,
  },
  AMX: {
    name: 'AMX',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AMX',
    description: 'Original Equipment Aston Martin',
    id: 231,
  },
  AOE1: {
    name: 'AOE1',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Audi',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'AOE1',
    description: 'Original Equipment Audi',
    id: 232,
  },
  AOK1: {
    name: 'AOK1',
    shortDescription: 'OEM Audi',
    longDescription: 'Original Equipment Audi',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'AOK1',
    description: 'Original Equipment Audi',
    id: 233,
  },
  AOPlus: {
    name: 'AO+',
    shortDescription: 'OEM Audi VW',
    longDescription: 'Original Equipment Audi & Volkswagen',
    type: ['oem'],
    icon: 'ICONS.audi',
    key: 'AO+',
    description: 'Original Equipment Audi & Volkswagen',
    id: 234,
  },
  ARKS: {
    name: 'ARKS',
    shortDescription: 'OEM Alfa Romeo',
    longDescription: 'Original Equipment Alfa Romeo Sport',
    type: ['oem'],
    icon: 'ICONS.alfa-romeo',
    key: 'ARKS',
    description: 'Original Equipment Alfa Romeo Sport',
    id: 236,
  },
  BG2: {
    name: 'BG2',
    shortDescription: 'OEM Bugatti',
    longDescription: 'Original Equipment Bugatti',
    type: ['oem'],
    icon: 'ICONS.bugatti',
    key: 'BG2',
    description: 'Original Equipment Bugatti',
    id: 237,
  },
  BL1: {
    name: 'BL1',
    shortDescription: 'OEM Bentley',
    longDescription: 'Original Equipment Bentley',
    type: ['oem'],
    icon: 'ICONS.bentley',
    key: 'BL1',
    description: 'Original Equipment Bentley',
    id: 238,
  },
  BM: {
    name: 'BM',
    shortDescription: 'OEM BMW Mini',
    longDescription: 'Original Equipment BMW Mini',
    type: ['oem'],
    icon: 'ICONS.bmw',
    key: 'BM',
    description: 'Original Equipment BMW Mini',
    id: 239,
  },
  DA: {
    name: 'DA',
    shortDescription: 'OEM Dacia',
    longDescription: 'Original Equipment Dacia',
    type: ['oem'],
    icon: 'ICONS.dacia',
    key: 'DA',
    description: 'Original Equipment Dacia',
    id: 241,
  },
  F1: {
    name: 'F1',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'F1',
    description: 'Original Equipment Ferrari',
    id: 242,
  },
  F05: {
    name: 'F05',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'F05',
    description: 'Original Equipment Ferrari',
    id: 243,
  },
  FE: {
    name: 'FE',
    shortDescription: 'OEM Ferrari',
    longDescription: 'Original Equipment Ferrari',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'FE',
    description: 'Original Equipment Ferrari',
    id: 244,
  },
  FI: {
    name: 'FI',
    shortDescription: 'OEM Fiat',
    longDescription: 'Original Equipment Fiat',
    type: ['oem'],
    icon: 'ICONS.fiat',
    key: 'FI',
    description: 'Original Equipment Fiat',
    id: 245,
  },
  FI1: {
    name: 'FI1',
    shortDescription: 'OEM Fiat',
    longDescription: 'Original Equipment Fiat',
    type: ['oem'],
    icon: 'ICONS.fiat',
    key: 'FI1',
    description: 'Original Equipment Fiat',
    id: 246,
  },
  FIAT: {
    name: 'FIAT',
    shortDescription: 'OEM Fiat',
    longDescription: 'Original Equipment Fiat',
    type: ['oem'],
    icon: 'ICONS.fiat',
    key: 'FIAT',
    description: 'Original Equipment Fiat',
    id: 247,
  },
  FO: {
    name: 'FO',
    shortDescription: 'OEM Ford',
    longDescription: 'Original Equipment Ford',
    type: ['oem'],
    icon: 'ICONS.ford',
    key: 'FO',
    description: 'Original Equipment Ford',
    id: 248,
  },
  FO1: {
    name: 'FO1',
    shortDescription: 'OEM Ford',
    longDescription: 'Original Equipment Ford',
    type: ['oem'],
    icon: 'ICONS.ford',
    key: 'FO1',
    description: 'Original Equipment Ford',
    id: 249,
  },
  GE: {
    name: 'GE',
    shortDescription: 'OEM Genesis',
    longDescription: 'Original Equipment Genesis',
    type: ['oem'],
    icon: 'ICONS.genesis',
    key: 'GE',
    description: 'Original Equipment Genesis',
    id: 250,
  },
  HQ: {
    name: 'HQ',
    shortDescription: 'OEM Hongqi',
    longDescription: 'Original Equipment Hongqi',
    type: ['oem'],
    icon: 'ICONS.hongqi',
    key: 'HQ',
    description: 'Original Equipment Hongqi',
    id: 251,
  },
  IBC: {
    name: 'IBC',
    shortDescription: 'OEM Isuzu',
    longDescription: 'Original Equipment Isuzu',
    type: ['oem'],
    icon: 'ICONS.isuzu',
    key: 'IBC',
    description: 'Original Equipment Isuzu',
    id: 252,
  },
  IS: {
    name: 'IS',
    shortDescription: 'OEM Isuzu',
    longDescription: 'Original Equipment Isuzu',
    type: ['oem'],
    icon: 'ICONS.isuzu',
    key: 'IS',
    description: 'Original Equipment Isuzu',
    id: 253,
  },
  IV: {
    name: 'IV',
    shortDescription: 'OEM Iveco',
    longDescription: 'Original Equipment Iveco',
    type: ['oem'],
    icon: 'ICONS.iveco',
    key: 'IV',
    description: 'Original Equipment Iveco',
    id: 254,
  },
  J1: {
    name: 'J1',
    shortDescription: 'OEM Jaguar',
    longDescription: 'Original Equipment Jaguar',
    type: ['oem'],
    icon: 'ICONS.jaguar',
    key: 'J1',
    description: 'Original Equipment Jaguar',
    id: 255,
  },
  J2: {
    name: 'J2',
    shortDescription: 'OEM Jaguar',
    longDescription: 'Original Equipment Jaguar',
    type: ['oem'],
    icon: 'ICONS.jaguar',
    key: 'J2',
    description: 'Original Equipment Jaguar',
    id: 256,
  },
  K: {
    name: 'K',
    shortDescription: 'OEM Ferrari',
    longDescription:
      'Original Equipment Ferrari except for Pirelli (Different Tread)',
    type: ['oem'],
    icon: 'ICONS.ferrari',
    key: 'K',
    description:
      'Original Equipment Ferrari except for Pirelli (Different Tread)',
    id: 257,
  },
  L2: {
    name: 'L2',
    shortDescription: 'OEM Lamborghini',
    longDescription: 'Original Equipment Lamborghini',
    type: ['oem'],
    icon: 'ICONS.lamborghini',
    key: 'L2',
    description: 'Original Equipment Lamborghini',
    id: 259,
  },
  LA: {
    name: 'LA',
    shortDescription: 'OEM Lancia',
    longDescription: 'Original Equipment Lancia',
    type: ['oem'],
    icon: 'ICONS.lancia',
    key: 'LA',
    description: 'Original Equipment Lancia',
    id: 260,
  },
  LAM: {
    name: 'LAM',
    shortDescription: 'OEM Lamborghini',
    longDescription: 'Original Equipment Lamborghini',
    type: ['oem'],
    icon: 'ICONS.lamborghini',
    key: 'LAM',
    description: 'Original Equipment Lamborghini',
    id: 261,
  },
  LM2: {
    name: 'LM2',
    shortDescription: 'OEM Lucid',
    longDescription: 'Original Equipment Lucid',
    type: ['oem'],
    icon: 'ICONS.lucid',
    key: 'LM2',
    description: 'Original Equipment Lucid',
    id: 262,
  },
  LRO: {
    name: 'LRO',
    shortDescription: 'OEM Land Rover',
    longDescription: 'Original Equipment Land Rover',
    type: ['oem'],
    icon: 'ICONS.land-rover',
    key: 'LRO',
    description: 'Original Equipment Land Rover',
    id: 263,
  },
  MO1B: {
    name: 'MO1B',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO1B',
    description: 'Original Equipment Mercedes',
    id: 264,
  },
  MO1C: {
    name: 'MO1C',
    shortDescription: 'OEM Mercedes',
    longDescription: 'Original Equipment Mercedes',
    type: ['oem'],
    icon: 'ICONS.mercedes',
    key: 'MO1C',
    description: 'Original Equipment Mercedes',
    id: 265,
  },
  OP: {
    name: 'OP',
    shortDescription: 'OEM Opel',
    longDescription: 'Original Equipment Opel',
    type: ['oem'],
    icon: 'ICONS.opel',
    key: 'OP',
    description: 'Original Equipment Opel',
    id: 266,
  },
  OP1: {
    name: 'OP1',
    shortDescription: 'OEM Opel',
    longDescription: 'Original Equipment Opel',
    type: ['oem'],
    icon: 'ICONS.opel',
    key: 'OP1',
    description: 'Original Equipment Opel',
    id: 267,
  },
  OP2: {
    name: 'OP2',
    shortDescription: 'OEM Opel',
    longDescription: 'Original Equipment Opel',
    type: ['oem'],
    icon: 'ICONS.opel',
    key: 'OP2',
    description: 'Original Equipment Opel',
    id: 268,
  },
  OP3: {
    name: 'OP3',
    shortDescription: 'OEM Opel',
    longDescription: 'Original Equipment Opel',
    type: ['oem'],
    icon: 'ICONS.opel',
    key: 'OP3',
    description: 'Original Equipment Opel',
    id: 269,
  },
  PE: {
    name: 'PE',
    shortDescription: 'OEM Peugeot',
    longDescription: 'Original Equipment Peugeot',
    type: ['oem'],
    icon: 'ICONS.peugeot',
    key: 'PE',
    description: 'Original Equipment Peugeot',
    id: 270,
  },
  PE1: {
    name: 'PE1',
    shortDescription: 'OEM Peugeot',
    longDescription: 'Original Equipment Peugeot',
    type: ['oem'],
    icon: 'ICONS.peugeot',
    key: 'PE1',
    description: 'Original Equipment Peugeot',
    id: 271,
  },
  PE2: {
    name: 'PE2',
    shortDescription: 'OEM Peugeot',
    longDescription: 'Original Equipment Peugeot',
    type: ['oem'],
    icon: 'ICONS.peugeot',
    key: 'PE2',
    description: 'Original Equipment Peugeot',
    id: 272,
  },
  PE3: {
    name: 'PE3',
    shortDescription: 'OEM Peugeot',
    longDescription: 'Original Equipment Peugeot',
    type: ['oem'],
    icon: 'ICONS.peugeot',
    key: 'PE3',
    description: 'Original Equipment Peugeot',
    id: 273,
  },
  RA: {
    name: 'RA',
    shortDescription: 'OEM Rimac',
    longDescription: 'Original Equipment Rimac',
    type: ['oem'],
    icon: 'ICONS.rimac',
    key: 'RA',
    description: 'Original Equipment Rimac',
    id: 274,
  },
  RAP: {
    name: 'RAP',
    shortDescription: 'OEM Rimac',
    longDescription: 'Original Equipment Rimac',
    type: ['oem'],
    icon: 'ICONS.rimac',
    key: 'RAP',
    description: 'Original Equipment Rimac',
    id: 275,
  },
  RE: {
    name: 'RE',
    shortDescription: 'OEM Renault',
    longDescription: 'Original Equipment Renault',
    type: ['oem'],
    icon: 'ICONS.renault',
    key: 'RE',
    description: 'Original Equipment Renault',
    id: 276,
  },
  RE1: {
    name: 'RE1',
    shortDescription: 'OEM Renault',
    longDescription: 'Original Equipment Renault',
    type: ['oem'],
    icon: 'ICONS.renault',
    key: 'RE1',
    description: 'Original Equipment Renault',
    id: 277,
  },
  RE2: {
    name: 'RE2',
    shortDescription: 'OEM Renault',
    longDescription: 'Original Equipment Renault',
    type: ['oem'],
    icon: 'ICONS.renault',
    key: 'RE2',
    description: 'Original Equipment Renault',
    id: 278,
  },
  RE3: {
    name: 'RE3',
    shortDescription: 'OEM Renault',
    longDescription: 'Original Equipment Renault',
    type: ['oem'],
    icon: 'ICONS.renault',
    key: 'RE3',
    description: 'Original Equipment Renault',
    id: 279,
  },
  ROL: {
    name: 'ROL',
    shortDescription: 'OEM Rolls Royce',
    longDescription: 'Original Equipment Rolls Royce',
    type: ['oem'],
    icon: 'ICONS.rolls-royce',
    key: 'ROL',
    description: 'Original Equipment Rolls Royce',
    id: 280,
  },
  TO1: {
    name: 'TO1',
    shortDescription: 'OEM Toyota',
    longDescription: 'Original Equipment Toyota',
    type: ['oem'],
    icon: 'ICONS.toyota',
    key: 'TO1',
    description: 'Original Equipment Toyota',
    id: 281,
  },
  VO: {
    name: 'VO',
    shortDescription: 'OEM VW',
    longDescription: 'Original Equipment Volkswagen',
    type: ['oem'],
    icon: 'ICONS.volkswagen',
    key: 'VO',
    description: 'Original Equipment Volkswagen',
    id: 283,
  },
  AM10: {
    name: 'AM10',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM10',
    description: 'Original Equipment Aston Martin',
    id: 284,
  },
  AM11: {
    name: 'AM11',
    shortDescription: 'OEM Aston Martin',
    longDescription: 'Original Equipment Aston Martin',
    type: ['oem'],
    icon: 'ICONS.aston-martin',
    key: 'AM11',
    description: 'Original Equipment Aston Martin',
    id: 285,
  },
  BT: {
    name: 'BT',
    shortDescription: 'OEM BMW Mini',
    longDescription: 'Original Equipment BMW Mini',
    type: ['oem'],
    icon: 'ICONS.bmw',
    key: 'BT',
    description: 'Original Equipment BMW Mini',
    id: 286,
  },
  FO2: {
    name: 'FO2',
    shortDescription: 'OEM Ford',
    longDescription: 'Original Equipment Ford',
    type: ['oem'],
    icon: 'ICONS.ford',
    key: 'FO2',
    description: 'Original Equipment Ford',
    id: 287,
  },
  H1: {
    name: 'H1',
    shortDescription: 'OEM Honda',
    longDescription: 'Original Equipment Honda',
    type: ['oem'],
    icon: 'ICONS.honda',
    key: 'H1',
    description: 'Original Equipment Honda',
    id: 288,
  },
  LB: {
    name: 'LB',
    shortDescription: 'OEM Lamborghini',
    longDescription: 'Original Equipment Lamborghini',
    type: ['oem'],
    icon: 'ICONS.lamborghini',
    key: 'LB',
    description: 'Original Equipment Lamborghini',
    id: 289,
  },
  LM: {
    name: 'LM',
    shortDescription: 'OEM Lamborghini',
    longDescription: 'Original Equipment Lamborghini',
    type: ['oem'],
    icon: 'ICONS.lamborghini',
    key: 'LM',
    description: 'Original Equipment Lamborghini',
    id: 290,
  },
  LX: {
    name: 'LX',
    shortDescription: 'OEM Lexus',
    longDescription: 'Original Equipment Lexus',
    type: ['oem'],
    icon: 'ICONS.lexus',
    key: 'LX',
    description: 'Original Equipment Lexus',
    id: 291,
  },
  MZ: {
    name: 'MZ',
    shortDescription: 'OEM Mazda',
    longDescription: 'Original Equipment Mazda',
    type: ['oem'],
    icon: 'ICONS.mazda',
    key: 'MZ',
    description: 'Original Equipment Mazda',
    id: 292,
  },
  TZ: {
    name: 'TZ',
    shortDescription: 'OEM Mazda',
    longDescription: 'Original Equipment Mazda',
    type: ['oem'],
    icon: 'ICONS.mazda',
    key: 'TZ',
    description: 'Original Equipment Mazda',
    id: 293,
  },
  MT: {
    name: 'MT',
    shortDescription: 'OEM Mitsubishi',
    longDescription: 'Original Equipment Mitsubishi',
    type: ['oem'],
    icon: 'ICONS.mazda',
    key: 'MT',
    description: 'Original Equipment Mitsubishi',
    id: 294,
  },
  NI: {
    name: 'NI',
    shortDescription: 'OEM Nissan',
    longDescription: 'Original Equipment Nissan',
    type: ['oem'],
    icon: 'ICONS.nissan',
    key: 'NI',
    description: 'Original Equipment Nissan',
    id: 295,
  },
  NI1: {
    name: 'NI1',
    shortDescription: 'OEM Nissan',
    longDescription: 'Original Equipment Nissan',
    type: ['oem'],
    icon: 'ICONS.nissan',
    key: 'NI1',
    description: 'Original Equipment Nissan',
    id: 296,
  },
  NS: {
    name: 'NS',
    shortDescription: 'OEM Nissan',
    longDescription: 'Original Equipment Nissan',
    type: ['oem'],
    icon: 'ICONS.nissan',
    key: 'NS',
    description: 'Original Equipment Nissan',
    id: 297,
  },
  KZ: {
    name: 'KZ',
    shortDescription: 'OEM Nissan',
    longDescription: 'Original Equipment Nissan',
    type: ['oem'],
    icon: 'ICONS.nissan',
    key: 'KZ',
    description: 'Original Equipment Nissan',
    id: 298,
  },
  VW1: {
    name: 'VW1',
    shortDescription: 'OEM VW',
    longDescription: 'Original Equipment Volkswagen',
    type: ['oem'],
    icon: 'ICONS.volkswagen',
    key: 'VW1',
    description: 'Original Equipment Volkswagen',
    id: 299,
  },
  VW2: {
    name: 'VW2',
    shortDescription: 'OEM VW',
    longDescription: 'Original Equipment Volkswagen',
    type: ['oem'],
    icon: 'ICONS.volkswagen',
    key: 'VW2',
    description: 'Original Equipment Volkswagen',
    id: 300,
  },
  G: {
    name: 'G',
    shortDescription: 'OEM VW Golf',
    longDescription: 'Original Equipment Volkswagen Golf',
    type: ['oem'],
    icon: 'ICONS.volkswagen',
    key: 'G',
    description: 'Original Equipment Volkswagen Golf',
    id: 301,
  },
  EV: {
    name: 'EV',
    shortDescription: 'Electric',
    longDescription: 'Electric vehicles specific',
    type: ['electric'],
    icon: 'ICONS.electric',
    key: 'EV',
    description: 'Electric vehicles specific',
    id: 302,
  },
  'MO-EXT': {
    name: 'MO-EXT',
    shortDescription: 'Run Flat Mercedes',
    longDescription: 'Run Flat Mercedes Original Extended',
    type: ['oem', 'runFlat'],
    icon: 'ICONS.mercedes',
    key: 'MO-EXT',
    description: 'Run Flat Mercedes Original Extended',
    id: 303,
  },
  CS: {
    name: 'CS',
    shortDescription: 'ContiSeal',
    longDescription: 'ContiSeal - self sealing technology',
    type: ['seal'],
    icon: '',
    key: 'CS',
    description: 'ContiSeal - self sealing technology',
    id: 304,
  },
  EVc: {
    name: 'EVc',
    shortDescription: 'Electric',
    longDescription: 'Electric vehicles specific',
    type: ['electric'],
    icon: 'ICONS.electric',
    key: 'EVc',
    description: 'Electric vehicles specific',
    id: 305,
  },
};
