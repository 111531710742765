import * as z from "zod"
import { DBRepeatUnit, DBSupplierApiConnectionType } from ".prisma/client"
import { CompleteDBSupplierConnection, RelatedDBSupplierConnectionModel } from "./index"

export const DBApiConnectionModel = z.object({
  id: z.string(),
  apiUrl: z.string(),
  apiKey: z.string(),
  apiClientId: z.string(),
  apiClientSecret: z.string(),
  startUpdateTime: z.date(),
  repeatEvery: z.number().int().nullish(),
  repeatUntil: z.nativeEnum(DBRepeatUnit),
  supplierConnectionId: z.string().nullish(),
  remark: z.string(),
  connectionType: z.nativeEnum(DBSupplierApiConnectionType),
})

export interface CompleteDBApiConnection extends z.infer<typeof DBApiConnectionModel> {
  supplierConnection?: CompleteDBSupplierConnection | null
}

/**
 * RelatedDBApiConnectionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBApiConnectionModel: z.ZodSchema<CompleteDBApiConnection> = z.lazy(() => DBApiConnectionModel.extend({
  supplierConnection: RelatedDBSupplierConnectionModel.nullish(),
}))
