export const admins = [
  {
    id: '00000000-0000-1000-a000-000000000000',
    userId: '00000000-0000-1000-a000-000000000000',
    active: true,
  },
  {
    id: '00000000-0000-1000-a000-000000000001',
    userId: '00000000-0000-1000-a000-000000000001',
    active: true,
  },
  {
    id: '00000000-0000-1000-a000-000000000002',
    userId: '00000000-0000-1000-a000-000000000003',
    active: true,
  },
  {
    id: '00000000-0000-1000-a000-000000000003',
    userId: '00000000-0000-1000-a000-000000000005',
    active: true,
  },
  {
    id: '00000000-0000-1000-a000-000000000004',
    userId: '00000000-0000-1000-a000-000000000006',
    active: true,
  },
];
