import * as z from "zod"
import { DBSupplierLocationType } from ".prisma/client"
import { CompleteDBSupplier, RelatedDBSupplierModel, CompleteDBCountry, RelatedDBCountryModel, CompleteDBSupplierStock, RelatedDBSupplierStockModel } from "./index"

export const DBSupplierLocationModel = z.object({
  id: z.string(),
  supplierId: z.string().nullish(),
  locationId: z.string(),
  locationName: z.string(),
  slug: z.string(),
  countryId: z.string().nullish(),
  zipCode: z.string(),
  city: z.string(),
  adress: z.string(),
  isHeadQuarter: z.boolean(),
  supplierLocationType: z.nativeEnum(DBSupplierLocationType),
  createdAt: z.date(),
})

export interface CompleteDBSupplierLocation extends z.infer<typeof DBSupplierLocationModel> {
  supplier?: CompleteDBSupplier | null
  country?: CompleteDBCountry | null
  supplierStock: CompleteDBSupplierStock[]
}

/**
 * RelatedDBSupplierLocationModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierLocationModel: z.ZodSchema<CompleteDBSupplierLocation> = z.lazy(() => DBSupplierLocationModel.extend({
  supplier: RelatedDBSupplierModel.nullish(),
  country: RelatedDBCountryModel.nullish(),
  supplierStock: RelatedDBSupplierStockModel.array(),
}))
