import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { DateRangePicker } from 'mui-daterange-picker';
import { useState } from 'react';
import qs from 'qs';
import './date-picker.css';
import moment from 'moment';

interface TransferDatePickerProps {
  isSidebarOpen: boolean;
  filters: qs.ParsedQs;
  setFilters: (value: Partial<qs.ParsedQs>) => void;
}

const TransferDatePicker = (props: TransferDatePickerProps) => {
  const [open, setOpen] = useState(false);

  const getDateString = (date?: Date) => {
    if (date === undefined)
      date = new Date(new Date().setDate(new Date().getDate() - 30));

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  };

  const getPeriodString = (start?: Date, end?: Date) => {
    return `${getDateString(start)} - ${getDateString(end)}`;
  };

  return (
    <>
      <TextField
        fullWidth
        label="Period"
        value={getPeriodString(
          props.filters?.['startDate']
            ? moment(props.filters?.['startDate'] as string).toDate()
            : undefined,
          props.filters?.['endDate']
            ? moment(props.filters?.['endDate'] as string).toDate()
            : new Date()
        )}
        onClick={() => setOpen(!open)}
        sx={{
          '.MuiOutlinedInput-input': {
            cursor: 'pointer',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid
        sx={{
          position: 'absolute',
          right: props.isSidebarOpen ? '43%' : '2%',
          zIndex: '4',
        }}
      >
        <DateRangePicker
          open={open}
          toggle={() => setOpen(!open)}
          maxDate={undefined}
          onChange={(range) => {
            props.setFilters({
              ...props.filters,
              startDate:
                range.startDate &&
                new Date(range.startDate.setHours(0, 0, 0, 0)).toString(),
              endDate:
                range.endDate &&
                new Date(range.endDate.setHours(23, 59, 59, 999)).toString(),
            });
          }}
          initialDateRange={{
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date(),
          }}
        />
      </Grid>
    </>
  );
};

export default TransferDatePicker;
