import { useContext } from 'react';
import { queryClient } from '../../../../../query-client';
import { StockListContext } from '../../../../context/StockListContext';
import { useCartTimer } from '../../../../context/TimerContext';
import { useWS } from '../../../../context/WSContext';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { CartDetails } from './CartDetails';
import { FullCart } from './FullCart';
import { useCartData } from './helper/cart-helper';

export const Cart = () => {
  const ws = useWS();

  const { orderSent, setOutboundOrderItems } = useContext(StockListContext);

  const { clearTimer } = useCartTimer();

  const { newCartItems, newCartOutboundData, hasItems } = useCartData();

  const deleteAllCartItems = () => {
    ws.socket?.emit('remove-all-cart-items', {});
    clearTimer('@TYR_INT');
    queryClient.refetchQueries('main_branches_stock_list');
    queryClient.refetchQueries('branches_stock_list');
    setOutboundOrderItems([]);
  };

  const renderItem = () => {
    if (orderSent && !hasItems) return <CartDetails type="order_sent" />;

    if (hasItems)
      return <FullCart inbound={newCartItems} outbound={newCartOutboundData} />;
    else return <CartDetails type="empty" />;
  };

  return (
    <SideMenuLayout
      type="cart"
      children={renderItem()}
      showDelete={hasItems}
      deleteText="Empty cart"
      handleDelete={deleteAllCartItems}
    />
  );
};
