import api from '@tyrio/api-factory';
import { DBWorkOrdersApi } from '@tyrio/dto';
import { useMutation } from 'react-query';

export const usePrintData = () => {
  const {
    data: printDetails,
    isLoading,
    isSuccess,
    mutateAsync,
  } = useMutation(
    ['get_work_orders_print_data'],
    async (workOrderId: string) => {
      return await api.fetch<DBWorkOrdersApi['preparePrintData']>(
        'get_work_orders_print_data',
        {
          id: workOrderId ?? '',
        }
      );
    },
    {}
  );

  return {
    printDetails,
    isLoading,
    isSuccess,
    mutateAsync,
  };
};
