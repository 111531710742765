import * as z from "zod"
import { CompleteDBServiceCategory, RelatedDBServiceCategoryModel } from "./index"

export const DBServiceCategoryCodeModel = z.object({
  id: z.string(),
  code: z.number().int(),
})

export interface CompleteDBServiceCategoryCode extends z.infer<typeof DBServiceCategoryCodeModel> {
  serviceCategory: CompleteDBServiceCategory[]
}

/**
 * RelatedDBServiceCategoryCodeModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBServiceCategoryCodeModel: z.ZodSchema<CompleteDBServiceCategoryCode> = z.lazy(() => DBServiceCategoryCodeModel.extend({
  serviceCategory: RelatedDBServiceCategoryModel.array(),
}))
