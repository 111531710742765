import styled from '@emotion/styled/macro';
import { Divider } from '@mui/material';

export const divStyle = { display: 'flex', gap: '8px', alignItems: 'center' };

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  margin-top: 16px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StorageName = styled.div`
  width: calc(100% - 160px);
  padding-left: 12px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const StockWrapper = styled.div<{ onlyAvailable: boolean }>`
  display: flex;
  flex-direction: row;
  max-width: ${(props) => (props.onlyAvailable ? '80px' : '160px')};
  width: 100%;
  justify-content: space-around;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 12px;
  gap: 8px;
  height: 70px;
  border-bottom: 1px solid #dfe3e8;
  cursor: default;
  justify-content: space-between;
  #document_svg {
    color: #637381;
  }

  &:hover {
    background: #f9fafb;

    #document_svg {
      color: #1976d2;
    }
    #document_name {
      color: #1976d2;
      font-weight: 600;
    }
  }
`;

export const ItemWrapperDiv = styled.div`
  width: calc(100% - 260px);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  border-width: 2px;
  border-color: #e6e8f0;
  margin-top: 16px;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const DocumentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const YearsWrapper = styled.div`
  max-width: 110px;
  width: 100%;
  gap: 5px;
  display: flex;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 1px;
    height: 1px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    width: 1px;
    height: 1px;
  }
`;
