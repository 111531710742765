/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled/macro';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useContext, useState } from 'react';
import { InboundInvoiceContext } from '../../../context/InboundInvoiceContext';
import { CostComparison } from '../components/CostComparison';
import { TotalAmount } from '../components/TotalAmount';
import { useCalculatePrice } from '../helpers/calculation';
import { getTextField } from '../../form-helper';

type CalculationType = 'quantity' | 'value';

const calculationTypes = [
  { key: 'quantity', label: 'By quantity', disabled: false },
  { key: 'value', label: 'By value', disabled: false },
];

const renderInput = (
  key: string,
  errors: FieldErrors,
  register: UseFormRegister<FieldValues>
) => {
  return (
    <CostsWrapper key={key}>
      <Grid item style={{ width: '60%' }}>
        {getTextField(
          '',
          key,
          () => true,
          errors,
          register,
          { lg: 3, xs: 12 },
          '',
          { disabled: false, size: 'small', type: 'number' }
        )}
      </Grid>
    </CostsWrapper>
  );
};

const renderRow = (
  text: string,
  key: string,
  errors: FieldErrors,
  register: UseFormRegister<FieldValues>
) => {
  return (
    <RowWrapper>
      <div style={{ width: '100%' }}>
        <Text color="#212b36">{text}</Text>
      </div>
      {renderInput(key, errors, register)}
    </RowWrapper>
  );
};

interface VariableCostProps {
  errors: FieldErrors;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
}

export const VariableCost = ({
  errors,
  register,
  watch,
  setValue,
}: VariableCostProps) => {
  const { preparedInvoices, setPreparedInvoices } = useContext(
    InboundInvoiceContext
  );

  const [customTotalAmount, setCustomTotalAmount] = useState<string | number>(
    0
  );
  const [calculationType, setCalculationType] = useState<'quantity' | 'value'>(
    'quantity'
  );

  const [calculatedCost, setCalculatedCost] = useState(
    preparedInvoices?.transportCost ?? 0
  );

  const { totalQty, itemsPrice } = useCalculatePrice(
    preparedInvoices?.items ?? [],
    preparedInvoices?.vatChargeType
  );

  const handleVariableCostCalculation = () => {
    const totalCost =
      Number(watch('domestic')) +
      Number(watch('international')) +
      Number(watch('domesticOther')) +
      Number(watch('internationalOther'));

    setValue('totalVarCost', totalCost);
    setCalculatedCost(totalCost);

    preparedInvoices?.items.forEach((item, idx) => {
      const lineItems = item.lineItems.map((lineItem) => {
        const totalPerQty = totalCost / totalQty;
        const totalPricePerItem = lineItem.quantity * lineItem.purchasePrice;

        return {
          ...lineItem,
          variableCost:
            calculationType === 'quantity'
              ? totalPerQty * lineItem.quantity
              : (totalPricePerItem / itemsPrice) * totalCost,
        };
      });

      item.lineItems = lineItems;

      const items = [...(preparedInvoices?.items ?? [])];
      items[idx] = item;

      setPreparedInvoices((prevState) => ({
        ...prevState,
        branchId: prevState?.branchId ?? 1,
        branchName: prevState?.branchName ?? 'Poslovnica Zagreb',
        date: prevState?.date ?? new Date(),
        items,
        supplier: prevState?.supplier ?? null,
        partner: prevState?.partner ?? undefined,
        transportCost: prevState?.transportCost ?? 0,
      }));
    });
  };

  const handleCustomTaxCalculation = () => {
    preparedInvoices?.items.forEach((item, idx) => {
      const lineItems = item.lineItems.map((lineItem) => {
        const totalPricePerItem = lineItem.quantity * lineItem.purchasePrice;

        const customPrice =
          ((totalPricePerItem + lineItem.variableCost) /
            (itemsPrice + calculatedCost)) *
          Number(customTotalAmount);

        return {
          ...lineItem,
          customsPer: customPrice / (totalPricePerItem + lineItem.variableCost),
          customsPrice: customPrice,
        };
      });

      item.lineItems = lineItems;
      const items = [...(preparedInvoices?.items ?? [])];
      items[idx] = item;

      setPreparedInvoices((prevState) => ({
        ...prevState,
        branchId: prevState?.branchId ?? 1,
        branchName: prevState?.branchName ?? 'Poslovnica Zagreb',
        date: prevState?.date ?? new Date(),
        items,
        supplier: prevState?.supplier ?? null,
        partner: prevState?.partner ?? undefined,
        transportCost: prevState?.transportCost ?? 0,
      }));
    });
  };

  return (
    <Wrapper>
      <CalculationTypes>
        <div
          style={{
            height: 'fit-content',
            display: 'flex',
            gap: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginRight: '35px',
              }}
            >
              <Text color="#212b36" weight="500">
                Domestic
              </Text>
            </div>
            {renderRow('Transport', 'domestic', errors, register)}
            {renderRow('Other costs', 'domesticOther', errors, register)}
          </div>
          {preparedInvoices?.partner?.countryId !==
            '274e7e37-6d32-f25b-4a5f-daf91b949567' && (
            <div style={{ display: 'flex', gap: '20px' }}>
              <Divider orientation="vertical" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Text color="#212b36" weight="500">
                  International
                </Text>
                {[
                  {
                    key: 'international',
                  },
                  {
                    key: 'internationalOther',
                  },
                ].map((item) => renderInput(item.key, errors, register))}
              </div>
            </div>
          )}
        </div>

        <Divider
          variant="fullWidth"
          sx={{
            borderWidth: '1px',
            borderColor: '#E6E8F0',
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            flexDirection: 'column',
          }}
        >
          <Text
            color="#919eab"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              alignItems: 'center',
            }}
          >
            Choose calculation type:
          </Text>
          <Grid
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <RadioGroup
              row
              defaultValue={watch('calculationType')}
              aria-labelledby="demo-radio-buttons-group-label"
            >
              {calculationTypes.map((item, index) => {
                return (
                  <FormControlLabel
                    id="calculationType"
                    key={index}
                    value={item.key}
                    control={<Radio color="info" />}
                    label={item.label}
                    onChange={() =>
                      setCalculationType(item.key as unknown as CalculationType)
                    }
                    disabled={item.disabled}
                    style={{ display: 'flex' }}
                  />
                );
              })}
            </RadioGroup>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="text"
                color="info"
                onClick={handleVariableCostCalculation}
                sx={{ width: '90px', height: '35px' }}
                disableElevation
              >
                APPLY
              </Button>
            </div>
          </Grid>
        </div>
      </CalculationTypes>
      <Divider
        variant="fullWidth"
        sx={{
          borderWidth: '2px',
          borderColor: '#E6E8F0',
        }}
      />
      {preparedInvoices?.vatChargeType === 'V05D' && (
        <TotalAmount
          text={'Total Customs Tax amount from invoice'}
          buttonText={'APPLY'}
          value={customTotalAmount}
          setValue={setCustomTotalAmount}
          onButtonClick={handleCustomTaxCalculation}
        />
      )}

      <CostComparison
        expectedCost={preparedInvoices?.transportCost ?? 0}
        calculatedCost={calculatedCost ?? 0}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
`;

const Text = styled.div<{ color: string; weight?: string }>`
  color: ${(props) => props.color};
  text-overflow: ellipsis;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  width: fit-content;
`;

const CostsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CalculationTypes = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px dashed #e6e6e6;
  border-radius: 8px;
  padding: 16px;
`;
