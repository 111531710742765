/* eslint-disable react-hooks/rules-of-hooks */
import api from '@tyrio/api-factory';
import { CartStockListItem, SingleItem } from '@tyrio/dto';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { ExpiredSessionModal } from '../../../../components/Timer/modals/ExpiredSession';
import { CartContext } from '../../../../context/CartContext';
import { useWS } from '../../../../context/WSContext';
import { useCartData } from '../Cart/helper/cart-helper';
import { getReservedAndUpcomingValues } from '../Cart/helper/socket-helper';

interface IAvailable {
  [key: string]: {
    quantity: number;
    reserved: number;
    upcoming: number;
  };
}

export const SessionExpiredModal = () => {
  const ws = useWS();

  const {
    openSessionExpiredModal,
    setOpenSessionExpiredModal,
    setOpenModal,
    setUnavailableQuantitesData,
    timer,
  } = useContext(CartContext);

  const [getData, setGetData] = useState(false);
  const [availableQuantities, setAvailableQuantities] =
    useState<IAvailable[]>();

  const { modalData, modalDataOtherUsers } = useCartData();

  const getAvailableMutation = useMutation(
    (body: SingleItem[]) =>
      api.fetch('get_available_quantity', {
        body,
      }),
    {
      mutationKey: 'get_available_quantity',
      onSuccess: (res) => {
        setAvailableQuantities(res as IAvailable[]);
        setGetData(true);
      },
    }
  );

  const getIntersection = () => {
    const intersection = [];

    for (const item1 of modalData) {
      for (const item2 of modalDataOtherUsers) {
        for (const key in item1) {
          if ((item2 as Record<string, string | CartStockListItem>)[key]) {
            const obj = { [key]: item1[key] };
            intersection.push(obj);
          }
        }
      }
    }
    return intersection;
  };

  const createBody = useMemo(() => {
    const body = [];

    for (const data of modalData) {
      if (!isEmpty(data)) {
        const split = Object.keys(data)[0].split('_');
        body.push({
          ean: split[0],
          dot: split[1],
          branchId: Number(split[2]),
        });
      }
    }
    return body;
  }, [modalData]);

  useEffect(() => {
    if (openSessionExpiredModal && !getData) {
      getAvailableMutation.mutate(createBody);
    }
  }, [createBody, getAvailableMutation, getData, openSessionExpiredModal]);

  // TODO: KEY IS NOT CORRECT
  const useGetAvailableQty = (key1: string) => {
    if (
      availableQuantities !== undefined &&
      availableQuantities !== null &&
      !isEmpty(availableQuantities)
    ) {
      if (!isEmpty(availableQuantities)) {
        const findAvailable = availableQuantities.find(
          (a) => a[key1] !== undefined
        );

        if (findAvailable !== undefined) {
          const newKey = key1.split('_');
          const b = newKey[0] + '_' + newKey[1];
          const { reserved } = getReservedAndUpcomingValues(
            ws?.state?.cart?.userCart,
            b,
            Number(newKey[2])
          );

          return findAvailable[key1].quantity - reserved;
        } else return 0;
      }
      return 0;
    } else return 0;
  };

  const formAvailableArray = () => {
    const dataArray = [];

    const intersection = getIntersection();

    for (const data of intersection) {
      if (!isEmpty(data)) {
        const value = Object.values(data)[0];
        const requested = (value as CartStockListItem).quantity;
        const available = useGetAvailableQty(Object.keys(data)[0]);

        if (requested > available)
          dataArray.push({
            ...(data as Record<string, string | CartStockListItem>),
            available: available,
          });
      }
    }
    return dataArray;
  };

  const handleCancel = () => {
    ws.socket?.emit('remove-all-cart-items', {});
    setOpenSessionExpiredModal(false);
    setUnavailableQuantitesData([]);
    timer?.clear();
  };

  const handleContinue = () => {
    const data = formAvailableArray();

    if (!isEmpty(data)) {
      setOpenModal(true);
      setUnavailableQuantitesData(data);
    } else {
      timer?.restart();
      setUnavailableQuantitesData([]);
    }
    setOpenSessionExpiredModal(false);
  };

  return (
    <ExpiredSessionModal
      text={'RESERVATION SESSION EXPIRED'}
      isOpen={openSessionExpiredModal}
      onCancel={handleCancel}
      onContinue={handleContinue}
    />
  );
};
