import styled from '@emotion/styled/macro';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SellIcon from '@mui/icons-material/Sell';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Pagination,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { CustomPriceStockType, DBClientPriceCalculationApi } from '@tyrio/dto';
import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CheckRebatesModal from '../customer-rebates/CheckRebates/CheckRebates';
import CheckRulesModal from '../price-calculation/components/CheckRules/CheckRules';
import { useDebounce } from '../../hooks/useDebounce';
import AddNewPrice from './CustomPriceCatalogCreateModal';
import CollapsibleTable from './CustomPriceCatalogTable';
import { useGetCustomPriceCatalog } from './queries/get-custom-price-catalog';

type ModalType = 'rrp' | 'rbt' | 'new';

const CustomPriceCatalog = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [type, setType] = useState<CustomPriceStockType>('all');
  const debouncedSearch = useDebounce(search, 500);

  const { data, status } = useGetCustomPriceCatalog({
    keyword: debouncedSearch,
    page,
    type,
  });
  const history = useHistory();

  const [modals, setModals] = useState({
    rrp: false,
    rbt: false,
    new: false,
  });

  const handleOpen = (type: ModalType) => {
    setModals({ ...modals, [type]: true });
  };

  const handleClose = (type: ModalType) => {
    setModals({ ...modals, [type]: false });
  };

  const handleChangeType = (
    event: React.SyntheticEvent,
    newValue: CustomPriceStockType
  ) => {
    setType(newValue);
  };

  const pageSize = 20;

  const totalPageNumber = data?.count
    ? data?.count / pageSize - Math.floor(data?.count / pageSize) === 0
      ? data?.count / pageSize
      : Math.floor(data?.count / pageSize) + 1
    : 1;

  return (
    <Grid>
      <AddNewPrice
        open={modals.new}
        close={() => {
          handleClose('new');
        }}
      />
      <HeaderWrapper>
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <ArkeyBackIosIconStyled onClick={() => history.push('/dashboard')} />
          <HeaderText>Custom prices</HeaderText>
        </Grid>
        <Grid sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Button
            variant="text"
            color="info"
            startIcon={<PriceCheckIcon />}
            onClick={() => handleOpen('rrp')}
          >
            CHECK PRICE
          </Button>
          <Button
            variant="text"
            color="info"
            startIcon={<PriceCheckIcon />}
            onClick={() => handleOpen('rbt')}
          >
            CHECK REBATE
          </Button>
          <Button
            variant="text"
            color="info"
            startIcon={<SellIcon />}
            onClick={() => handleOpen('new')}
          >
            ADD NEW PRICE
          </Button>
        </Grid>
      </HeaderWrapper>
      <Grid>
        <CheckRulesModal
          isOpen={modals.rrp}
          close={() => handleClose('rrp')}
          priceCalculationData={
            {
              supplierId: null,
            } as DBClientPriceCalculationApi['getOne']['response']
          }
        />
        <CheckRebatesModal
          isOpen={modals.rbt}
          close={() => handleClose('rbt')}
        />
        <Grid>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Please note that the custom price you input will be prominently
            featured on the Point of Sale (POS) system and will take precedence
            over any pricing rules established in the price calculation module.
          </Alert>
        </Grid>
        <Grid>
          <Grid sx={{ marginTop: '15px' }}>
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <TextField
                label="Search by EAN"
                value={search}
                type="number"
                disabled={data?.data.length === 0 && search === ''}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {search !== '' && (
                        <CloseIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSearch('');
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              sx={{
                marginTop: '10px',
              }}
            >
              <Tabs value={type} onChange={handleChangeType}>
                <Tab label="ALL" value="all" />
                <Tab
                  label={
                    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                      <Grid
                        sx={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: '#2e7d32',
                          borderRadius: '50%',
                          marginRight: '7px',
                        }}
                      ></Grid>
                      ON STOCK
                    </Grid>
                  }
                  value="stock-list"
                />
                <Tab
                  label={
                    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                      <Grid
                        sx={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: '#d32f2f',
                          borderRadius: '50%',
                          marginRight: '7px',
                        }}
                      ></Grid>
                      NOT ON STOCK
                    </Grid>
                  }
                  value="product-catalog"
                />
              </Tabs>
            </Grid>
            {data && data?.data.length > 0 && (
              <CollapsibleTable items={data.data} />
            )}
            {status === 'loading' && (
              <Grid
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '150px',
                }}
              >
                <CircularProgress />
              </Grid>
            )}
            {data?.data.length === 0 && (
              <Alert color="info" sx={{ marginTop: '10px' }}>
                There is no custom prices!
              </Alert>
            )}
            {data && data?.data.length > 0 && (
              <Grid
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={totalPageNumber}
                  sx={{
                    marginTop: '30px',
                    backgroundColor: 'white',
                    display: 'flex',
                  }}
                  onChange={(e: ChangeEvent<unknown>, page: number) => {
                    setPage(page);
                  }}
                  page={page}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const HeaderWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;
const HeaderText = styled(Grid)`
  margin-left: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  cursor: default;
`;

const ArkeyBackIosIconStyled = styled(ArrowBackIosIcon)`
  cursor: pointer;
`;

export default CustomPriceCatalog;
