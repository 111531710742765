import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const SUPERIOR_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'category_id',
    template: '72',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.NMarke',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.map',
    sourceKey: 'c.Design',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Winterreifen',
    condition: 'equals',
    conditionValues: ['Ja'],
    conditionOutputs: ['winter'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.EAN',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Hersteller_ArtNr"]',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.Breite',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
    // postProcess: ['trimZeroes'],
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.width',
    matchValue: ['.0'],
    replaceValue: [''],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Hauptmass',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'pcd',
    template: '#{c.LochAnzahl}x#{c.Lochkreis}',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.pcd',
    matchValue: ['.00', '.30'],
    replaceValue: ['', '.3'],
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.ET',
    targetKey: 'et',
    delimiter: ',',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Nabenbohrung(CB)"]',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  // Instalation kit
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'partialKit',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Befestigung',
    condition: 'equals',
    conditionValue: 'Serie',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'installationKit',
    outputValue: 'oem',
    outputType: 'string',
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.Farbe',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  // Weight
  {
    type: 'rule.map',
    sourceKey: 'c.Nettogewicht',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  ...GLOBAL_RULESET,
];
