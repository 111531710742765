import _ from 'lodash';
import { useWorkOrderCtx } from '../../../context/WorkOrderContext';
import { cardArray } from '../constants/constants';
import { StockOutDeliveryType } from '../queries/get-quantity';
import { WorkOrdersOverviewWrapper } from '../styles/styles';
import { Card } from './Card';

export const WorkOrdersOverview = () => {
  const { workOrders } = useWorkOrderCtx();

  return (
    <WorkOrdersOverviewWrapper>
      {cardArray.map((n) => {
        const path = n.key as unknown as StockOutDeliveryType;
        let qty = 0;
        qty = workOrders?.[path]?.length ?? 0;
        if (path === 'DISPATCH' || path === 'COLLECTION') {
          const items =
            workOrders?.[path] && workOrders?.[path]?.length > 0
              ? workOrders?.[path]?.map((s) => s.quantity)
              : [];
          qty = _.sum(items) ?? 0;
        }
        return (
          <Card key={n.key} quantity={qty} title={n.title} image={n.image} />
        );
      })}
    </WorkOrdersOverviewWrapper>
  );
};
