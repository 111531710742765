/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { DBDeliveryLocation } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBCountryApi, DeliveryZones } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useState } from 'react';
import {
  Control,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getAutocompleteSelectChip,
  getNumberInputField,
  getSwitchField,
  getTextField,
} from '../form-helper';
import { DeleteRepeaterFieldButton } from '../supplier-form/helpers/components';

const priceListRadioValues = [
  { key: 1, label: 'Price per order', disabled: false },
  { key: 2, label: 'Price per package', disabled: false },
  { key: 3, label: 'Price per weight', disabled: true },
  { key: 4, label: 'Price per product category', disabled: true },
];

interface ZonesFormProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: {
    [x: string]: any;
  };
  watch: UseFormWatch<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (payload: FieldValues) => void;
  zonesForm: DeliveryZones[];
  deleteZone: (id: number) => void;
}

export const ZonesForm = ({
  register,
  control,
  errors,
  watch,
  handleSubmit,
  onSubmit,
  zonesForm,
  deleteZone,
}: ZonesFormProps) => {
  const { id } = useParams<{ id: string }>();
  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);
  const [expandedData, setExpandedData] = useState<number[]>([]);

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setCountries(dropdownItems);
      },
    }
  );

  return (
    <Container>
      <form
        key={id}
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
      >
        {zonesForm && zonesForm.length > 0 && (
          <div>
            {zonesForm.map((_item, index) => (
              <Accordion expanded={expandedData.includes(index)} key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() =>
                    setExpandedData(
                      expandedData.includes(index)
                        ? expandedData.filter((i) => i !== index)
                        : [...expandedData, index]
                    )
                  }
                  style={{
                    borderBottom: '1px solid #dfe3e8',
                    marginBottom: '20px',
                  }}
                >
                  <Typography>
                    {watch(`zones[${index}].zoneSettings.name`) &&
                    watch(`zones[${index}].zoneSettings.name`) !== ''
                      ? watch(`zones[${index}].zoneSettings.name`)
                      : `Zone ${index + 1}`}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '40px',
                  }}
                >
                  <FormRowWrapper container spacing={2}>
                    <Title>Settings</Title>

                    <Grid>
                      {getTextField(
                        'Zone name',
                        `zones[${index}].zoneSettings.name`,
                        () => false,
                        errors,
                        register,
                        { lg: 2, xs: 12 }
                      )}
                    </Grid>

                    <Grid
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getNumberInputField(
                        'Days to deliver',
                        `zones[${index}].zoneSettings.daysToDeliver`,
                        () => false,
                        errors,
                        register,
                        { lg: 2, xs: 5 }
                      )}

                      {getNumberInputField(
                        'Max days to deliver',
                        `zones[${index}].zoneSettings.maxDaysToDeliver`,
                        () => false,
                        errors,
                        register,
                        { lg: 2, xs: 5 }
                      )}

                      {getNumberInputField(
                        'Min order quantity',
                        `zones[${index}].zoneSettings.minOrderQuantity`,
                        () => false,
                        errors,
                        register,
                        { lg: 2, xs: 5 }
                      )}

                      {getNumberInputField(
                        'Max package weight',
                        `zones[${index}].zoneSettings.maxPackageWeight`,
                        () => false,
                        errors,
                        register,
                        { lg: 2, xs: 5 }
                      )}

                      {getSwitchField(
                        'Cash on delivery',
                        `zones[${index}].zoneSettings.cashOnDelivery`,
                        errors,
                        control,
                        { lg: 3, xs: 12 },
                        { color: 'info' }
                      )}

                      {getSwitchField(
                        'Card on delivery',
                        `zones[${index}].zoneSettings.cardOnDelivery`,
                        errors,
                        control,
                        { lg: 3, xs: 12 },
                        { color: 'info' }
                      )}
                    </Grid>
                    {watch('deliveryLocation') ===
                    DBDeliveryLocation.INTERNATIONAL ? (
                      <Grid>
                        {getAutocompleteSelectChip(
                          'Countries',
                          `zones[${index}].zoneSettings.country`,
                          () => false,
                          errors,
                          control,
                          { lg: 6, xs: 12 },
                          countries.map((item) => ({
                            id: item.value.toString(),
                            name: item.label,
                          })),
                          (option: { id: string; name: string }) => option.name,
                          false,
                          (option, value) => option.id === value.id
                        )}
                      </Grid>
                    ) : (
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '20px',
                        }}
                      >
                        {getTextField(
                          'Zip codes',
                          `zones[${index}].zoneSettings.zipCodes`,
                          () => false,
                          errors,
                          register,
                          { lg: 12, xs: 12 }
                        )}
                      </Grid>
                    )}
                  </FormRowWrapper>
                  <FormRowWrapper container spacing={2}>
                    <Title>Price list</Title>
                    <Grid>
                      <RadioGroup
                        row
                        defaultValue={priceListRadioValues[0].key}
                        aria-labelledby="demo-radio-buttons-group-label"
                        key={`zones[${index}].priceList.priceType`}
                      >
                        {priceListRadioValues.map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={item.key}
                              control={<Radio color="info" />}
                              label={item.label}
                              onChange={() => {
                                console.log('helo');
                              }}
                              disabled={item.disabled}
                              style={{ display: 'flex' }}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        gap: '10px',
                      }}
                    >
                      {getNumberInputField(
                        'Delivery price',
                        `zones[${index}].priceList.deliveryPrice`,
                        () => false,
                        errors,
                        register,
                        { lg: 2, xs: 5 }
                      )}
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {getSwitchField(
                        'Free delivery',
                        `zones[${index}].priceList.freeDelivery`,
                        errors,
                        control,
                        { lg: 3, xs: 5 },
                        { color: 'info' }
                      )}

                      {watch(`zones[${index}].priceList.freeDelivery`) && (
                        <Grid
                          xs={12}
                          lg={12}
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}
                        >
                          {getNumberInputField(
                            'Min order quantity',
                            `zones[${index}].priceList.minOrderQuantity2`,
                            () => false,
                            errors,
                            register,
                            { lg: 2, xs: 5 }
                          )}
                          {getNumberInputField(
                            'Min order amount',
                            `zones[${index}].priceList.minOrderAmount`,
                            () => false,
                            errors,
                            register,
                            { lg: 2, xs: 5 }
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </FormRowWrapper>
                  {watch('deliveryLocation') === 'INTERNATIONAL' && (
                    <FormRowWrapper container spacing={2}>
                      <Title>Surcharges</Title>
                      <Grid style={{ display: 'flex', gap: '15px' }}>
                        {getTextField(
                          'COD surcharge',
                          `zones[${index}].surcharges.codSurcharge`,
                          () => false,
                          errors,
                          register,
                          { lg: 2, xs: 5 },
                          '',
                          {
                            disabled: watch(
                              `zones[${index}].zoneSettings.cardOnDelivery`
                            ),
                          },
                          {
                            disabled: watch(
                              `zones[${index}].zoneSettings.cardOnDelivery`
                            ),
                          }
                        )}
                        {getTextField(
                          'CCOD surcharge',
                          `zones[${index}].surcharges.ccodSurcharge`,
                          () => false,
                          errors,
                          register,
                          { lg: 2, xs: 5 },
                          '',
                          {
                            disabled: watch(
                              `zones[${index}].zoneSettings.cashOnDelivery`
                            ),
                          },
                          {
                            disabled: watch(
                              `zones[${index}].zoneSettings.cashOnDelivery`
                            ),
                          }
                        )}
                        {getNumberInputField(
                          'Min quantity surcharge',
                          `zones[${index}].zoneSettings.minQuantitySurcharge`,
                          () => false,
                          errors,
                          register,
                          { lg: 2, xs: 5 }
                        )}
                      </Grid>
                    </FormRowWrapper>
                  )}

                  <Grid
                    container
                    display="flex"
                    sx={{ marginTop: '20px' }}
                    justifyContent="flex-end"
                  >
                    <DeleteRepeaterFieldButton
                      onDeleteRepeaterField={() => deleteZone(index)}
                      index={index}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </form>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const Title = styled.div`
  color: #212b36;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
`;

const FormRowWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #f9fafb;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #dfe3e8;
`;
