import api from '@tyrio/api-factory';
import { DBWarehouseSettingsApi } from '@tyrio/dto';
import { useState } from 'react';
import { useQuery } from 'react-query';

export interface WarehousesOptions {
  label: string;
  value: string | number;
}

interface GetWarehousesProps {
  setSelectedWarehouse: (v: string) => void;
}

export const useGetWarehouses = (props: GetWarehousesProps) => {
  const { setSelectedWarehouse } = props;

  const [warehouses, setWarehouses] = useState<WarehousesOptions[]>([]);

  const getWarehouses = useQuery(
    ['client_warehouses'],
    async () => {
      return await api.fetch<DBWarehouseSettingsApi['list']>(
        'client_warehouses'
      );
    },
    {
      onSuccess: (d) => {
        const dropdownItems = d.map((item) => ({
          label: item.branch.branchName,
          value: item.id,
        }));

        setWarehouses(dropdownItems);

        const main = d[0]?.id;

        if (main) setSelectedWarehouse(main);
      },
    }
  );

  return { warehouseData: { ...getWarehouses }, warehouses };
};
