import { Badge, Grid, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DBOrderStatus } from '@prisma/client';
import {
  DBClientSupplierOrderItemCountByStatus,
  DBClientSupplierOrderItemFilters,
  DBCustomerOrderItemCountByStatus,
  DBCustomerOrderItemFilters,
  DBCustomerOrderStatusEnum,
} from '@tyrio/dto';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  CUSTOMER_ORDER_STATUSES,
  SUPPLIER_ORDER_STATUSES,
} from '../helpers/filters-data';
import {
  CustomerOrderStatusColors,
  getOrderStatusColor,
} from '../helpers/generate-color';
import { ComponentType } from '../helpers/types';
import { sum } from 'lodash';

interface TabsFilterProps {
  filters: DBCustomerOrderItemFilters | DBClientSupplierOrderItemFilters;
  setFilters: Dispatch<
    SetStateAction<
      DBCustomerOrderItemFilters | DBClientSupplierOrderItemFilters
    >
  >;
  setPage: (data: number) => void;
  countOrders:
    | DBClientSupplierOrderItemCountByStatus[]
    | DBCustomerOrderItemCountByStatus[];
  componentType: ComponentType;
  setFilterValue?: (data: Partial<qs.ParsedQs>) => void;
}

const TabsFilter = ({
  filters,
  setFilters,
  setPage,
  componentType,
  countOrders,
  setFilterValue,
}: TabsFilterProps) => {
  const isPurchase = componentType === ComponentType.purchase;
  const containerWidth = isPurchase ? '100%' : '93%';
  const [shouldResetTabs, setShouldResetTabs] = useState(false);
  const TAB_FILTERS = isPurchase
    ? SUPPLIER_ORDER_STATUSES
    : CUSTOMER_ORDER_STATUSES;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setFilters({
      ...filters,
      orderStatus: newValue,
    });

    setPage(1);
    setShouldResetTabs(false);
    setFilterValue && setFilterValue({ selected: undefined });
  };

  const getOrderCount = (
    orderStatus: DBOrderStatus | DBCustomerOrderStatusEnum
  ) => {
    if (!isPurchase) {
      const filterdOrder = (
        countOrders as unknown as DBCustomerOrderItemCountByStatus[]
      )?.filter(
        (item) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item.orderStatus as any).status === orderStatus
      );

      return sum(filterdOrder.map((b) => b._count)) ?? 0;
    }
    return (
      (
        countOrders as unknown as DBClientSupplierOrderItemCountByStatus[]
      )?.find((item) => item.orderStatus === orderStatus)?._count ?? 0
    );
  };

  const isStorned = filters.orderStatus === DBOrderStatus.STORNED;

  const OrderStatus = isPurchase ? DBOrderStatus : DBCustomerOrderStatusEnum;

  useEffect(() => {
    // if there's no search keyword, set the default order status
    if (!filters['searchKeyword']) {
      const defaultStatus = isPurchase ? 'PROCESSING' : 'NEW';
      setFilters((prevState) => ({
        ...prevState,
        orderStatus: defaultStatus,
      }));
    } else {
      // when there is a search keyword, set order status to 'all'
      setFilters((prevState) => ({
        ...prevState,
        orderStatus: 'all',
      }));
    }

    // dependencies handled manually
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters['searchKeyword'], isPurchase, setFilters]);

  const badgeColor = (status: string) => {
    if (status === 'NEW') return 'gray';
    else
      return CustomerOrderStatusColors[
        DBCustomerOrderStatusEnum[
          status as keyof typeof DBCustomerOrderStatusEnum
        ]
      ];
  };

  return (
    <Grid
      sx={{
        width: containerWidth,
        overflow: 'auto',
      }}
    >
      <TabsStyled
        value={
          isStorned || shouldResetTabs
            ? isPurchase
              ? 'PROCESSING'
              : filters.orderStatus
            : filters.orderStatus
        }
        onChange={handleChange}
        scrollButtons={false}
        variant="scrollable"
      >
        {TAB_FILTERS.map((status, index) => {
          const orderCount = getOrderCount(
            OrderStatus[status.value as keyof typeof OrderStatus]
          );
          const isDisabled = isStorned && status.value !== 'all';
          const color = isPurchase
            ? getOrderStatusColor(
                DBOrderStatus[status.value as keyof typeof DBOrderStatus]
              )
            : badgeColor(status.value);

          const visibleTab = index < 4 && !isPurchase;

          if (!visibleTab && orderCount <= 0 && !isPurchase) return null;

          return (
            <TabStyled
              disabled={filters.orderStatus === DBOrderStatus.STORNED}
              value={status.value}
              iconPosition="start"
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
                '&:hover': {
                  color: isDisabled ? 'text.disabled' : '#1976D2',
                  opacity: 1,
                },
              }}
              key={status.label}
              icon={
                <Grid
                  sx={{
                    width: status.width,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip
                    title={
                      isDisabled
                        ? 'If you want to search by order status, you must turn off storned switch!'
                        : ''
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {status.label}
                    </Typography>
                  </Tooltip>
                  {orderCount > 0 && status.value !== 'all' && (
                    <Badge
                      badgeContent={orderCount}
                      sx={{
                        '& .MuiBadge-badge': {
                          color: 'white',
                          backgroundColor: isDisabled ? 'text.disabled' : color,
                        },
                      }}
                    />
                  )}
                </Grid>
              }
            />
          );
        })}
      </TabsStyled>
    </Grid>
  );
};

const TabsStyled = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1976D2',
  },
});

const TabStyled = styled(Tab)({
  '&.Mui-selected': {
    color: '#1976D2',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#1976D2',
    color: '#1976D2',
  },
});

export default TabsFilter;
