import { DBProductApi } from '@tyrio/dto';
import EuLabelGenerator from '../EuLabel/EuLabelGenerator';
import EuLabelOldGenerator from '../EuLabel/EuLabelOldGenerator';

interface ProductEprelGeneratorProps {
  product: DBProductApi['getOne']['response'];
}

const ProductEprelGenerator = ({ product }: ProductEprelGeneratorProps) => {
  if (product.euDirectiveNumber === '1222/2009')
    return <EuLabelOldGenerator product={product} />;
  if (product.euDirectiveNumber === '2020/740')
    return <EuLabelGenerator product={product} />;
  return <div>No EU Directive number selected</div>;
};

export default ProductEprelGenerator;
