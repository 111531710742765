/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useRef } from 'react';
import {
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  Utils as QbUtils,
  Query,
} from 'react-awesome-query-builder';
import {
  IJsonLogic,
  INewCondition,
} from '../../features/price-calculation/context/RulesContext';
import './scss/QueryBuilder.style.scss';

const QueryBuilder = ({
  config,
  jsonTree,
  updatedConditions,
  index,
  newRuleCondition,
}: any) => {
  const { checkTree, loadFromJsonLogic, jsonLogicFormat, getTree } = QbUtils;

  // const generateTree = useMemo(() => {
  //   return checkTree(
  //     loadFromJsonLogic(jsonTree, config) as ImmutableTree,
  //     config
  //   );
  // }, []);

  const generateTree = checkTree(
    loadFromJsonLogic(jsonTree, config) as ImmutableTree,
    config
  );

  const treeValue = useRef(generateTree);

  const updateNewRuleCondition = useCallback(
    (immutableTree: ImmutableTree) => {
      const findIdx = newRuleCondition.current.find(
        (rule: INewCondition) => rule.id === index
      );
      if (findIdx !== undefined) findIdx.tree = getTree(immutableTree);
      else
        newRuleCondition.current = [
          ...newRuleCondition.current,
          { id: index, tree: getTree(immutableTree) },
        ];
    },
    [getTree, index, newRuleCondition]
  );

  const handleChange = useCallback(
    (immutableTree: ImmutableTree, config: Config) => {
      const json: string = JSON.stringify(
        jsonLogicFormat(immutableTree, config).logic
      );

      treeValue.current = immutableTree;

      updateNewRuleCondition(immutableTree);

      if (updatedConditions !== undefined) {
        const update = updatedConditions.current.find(
          (a: IJsonLogic) => a.ruleIndex === index
        );

        if (update !== undefined) {
          update.jsonLogic = json;
        }
      }
    },
    [updateNewRuleCondition, index, jsonLogicFormat, updatedConditions]
  );

  return (
    <div>
      <Query
        {...config}
        value={treeValue.current}
        renderBuilder={renderBuilder}
        onChange={handleChange}
      ></Query>
    </div>
  );
};

export default QueryBuilder;

export const renderBuilder = (props: BuilderProps) => (
  <div className="query-builder-container">
    <div className="query-builder qb-lite">
      <Builder {...props} />
    </div>
  </div>
);
