export const company_contacts = [
  {
    id: '00000000-0000-1000-cc00-000000000000',
    firstName: 'Marko',
    lastName: 'Modric',
    email: 'marko@modric.com',
    businessPhone: '38762987374',
    mobilePhone: '38762987374',
    remark: 'test 1',
    clientId: '00000000-0000-1000-b000-0000000000011',
  },
  {
    id: '00000000-0000-1000-cc00-000000000001',
    firstName: 'Ado',
    lastName: 'Suljic',
    email: 'ado@suljic.com',
    businessPhone: '38762987375',
    mobilePhone: '38762987375',
    remark: 'test 2',
    clientId: '00000000-0000-1000-b000-0000000000022',
  },
];
