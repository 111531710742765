import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DBDeliveryTypeEnum } from '@prisma/client';
import { DBClientSupplierOrderItemApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import { Enum } from '../../supplier-form/helpers/types';
import dhlLogo from './../assets/dhl-logo.jpg';
import dpdLogo from './../assets/dpd-logo.svg';
import truckLogo from './../assets/truck-logo.png';

interface SelectInputProps {
  attributeKey: Enum;
  valueName: keyof DBClientSupplierOrderItemApi['getOne']['response'];
  label: string;
  dropdownData: TyrioSelectInputOption[];
  selectedOrderData: DBClientSupplierOrderItemApi['getOne']['response'] | null;
  setSelectedOrderData: (
    value: DBClientSupplierOrderItemApi['getOne']['response']
  ) => void;
}

const SelectInput = (props: SelectInputProps) => {
  return (
    props.selectedOrderData && (
      <FormControl fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Select
          label={props.label}
          value={props.selectedOrderData[props.valueName]}
          onChange={(e) => {
            props.selectedOrderData &&
              props.setSelectedOrderData({
                ...props.selectedOrderData,
                [props.valueName]: e.target.value,
              });
          }}
        >
          {props.dropdownData.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              <Typography> {item.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
};

export const getLogoByDeliveryType = (deliveryType: DBDeliveryTypeEnum) => {
  if (deliveryType === DBDeliveryTypeEnum.DHL) return dhlLogo;
  if (deliveryType === DBDeliveryTypeEnum.DPD) return dpdLogo;
  if (deliveryType === DBDeliveryTypeEnum.TRUCK) return truckLogo;
  else return '';
};

export default SelectInput;
