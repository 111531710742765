export const customers = [
  {
    id: '00000000-0000-1000-a000-000000000000',
    firstName: 'Samra',
    lastName: 'Bejtic',
    email: 'samra@ankorainc.com',
    phoneNumber: '+38761000000',
  },
  {
    id: '00000000-0000-1000-a000-000000000001',
    firstName: 'Emir',
    lastName: 'Brackovic',
    email: 'brackovic97@gmail.com',
    phoneNumber: '+38761000000',
  },
  {
    id: '00000000-0000-1000-a000-000000000002',
    firstName: 'Haris',
    lastName: 'Pandzic',
    email: 'haris@tyrio.com',
    phoneNumber: '+38762209709',
  },
  {
    id: '00000000-0000-1000-a000-000000000003',
    email: 'youssef.aly@ankorainc.com',
    firstName: 'Youssef',
    lastName: 'Aly',
    phoneNumber: '+38761111111',
  },
  {
    id: '00000000-0000-1000-a000-000000000004',
    email: 'nedim@tyrio.com',
    firstName: 'Nedim',
    lastName: 'Muhamedagic',
    phoneNumber: '+38765685479',
  },
  {
    id: '00000000-0000-1000-a000-000000000005',
    email: 'beckaskola033@gmail.com',
    firstName: 'Haris',
    lastName: 'Zorlak',
    phoneNumber: '+38761468135',
  },
  {
    id: '00000000-0000-1000-a000-000000000006',
    email: 'adna@ankorainc.com',
    firstName: 'Adna',
    lastName: 'Pasic',
    phoneNumber: '+38762142000',
  },
  {
    id: '00000000-0000-1000-a000-000000000007',
    email: 'neirapiranic@gmail.com',
    firstName: 'Neira',
    lastName: 'Piranic',
    phoneNumber: '+38761989187',
  },
];
