/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JsonFormatter from 'react-json-formatter';

interface CatalogImportEntriesProps {
  data: Array<{
    message: string;
    data: any;
    context?: any;
  }>;
}
const jsonStyle = {
  propertyStyle: { color: 'red' },
  stringStyle: { color: 'green' },
  numberStyle: { color: 'darkorange' },
};

export const CatalogImportEntries = (props: CatalogImportEntriesProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {(props.data || []).map((item) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{item.message}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <strong>Data</strong>
              </Typography>

              <code>
                <JsonFormatter
                  jsonStyle={jsonStyle}
                  json={JSON.stringify(item.data)}
                  tabWith={2}
                />
              </code>
              {item.context && (
                <>
                  <Typography>
                    <strong>Context</strong>
                  </Typography>

                  <code>
                    <JsonFormatter
                      jsonStyle={jsonStyle}
                      json={JSON.stringify(item.context)}
                      tabWith={2}
                    />
                  </code>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};
