import api from '@tyrio/api-factory';
import { DBClientSupplierOrderApi } from '@tyrio/dto';
import { useMutation } from 'react-query';

export const useOrderMutation = () => {
  return useMutation(
    'upsert_order',
    (data: DBClientSupplierOrderApi['create']['request']) =>
      api.fetch<DBClientSupplierOrderApi['create']>('create_order', {
        ...data,
      }),
    {}
  );
};
