import styled from '@emotion/styled';
import { colors, fontSize } from './index';

export const Title = styled.h1`
  display: flex;
  color: ${colors.primary};
  font-size: ${fontSize.title}px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
`;
