import api from '@tyrio/api-factory';
import { DBBranchesApi } from '@tyrio/dto';
import {
  SearchableVerticalList,
  SearchableVerticalListDataType,
} from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDebounce } from '../../../hooks/useDebounce';
import routeNames from '../../../lib/routeNames';

interface BranchesProps {
  switchMenu?: boolean;
  setSwitchMenu: (menu: boolean) => void;
}

const Branches = ({ switchMenu, setSwitchMenu }: BranchesProps) => {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);
  const pageSize = 10;

  const {
    isFetching,
    isError,
    data: branchesData,
  } = useQuery(
    ['get_branches', { debouncedSearch, page }],
    () => searchBranches(search, page),
    {}
  );

  const [selected, setSelected] = useState<Record<string, boolean>>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      setSelected((prevValue) => ({
        ...prevValue,
        [item.id]: !prevValue[item.id],
      }));
      history.push(`${path}/${item.id}`);
    }
    if (type === 'new') {
      history.push(`${path}/new`);
    }
  };

  const searchBranches = async (search: string, page: number) => {
    return await api.fetch<DBBranchesApi['list']>('get_branches', {
      search,
      page,
      pageSize,
    });
  };

  useEffect(() => {
    setPage(1);
    if (location.pathname === path) setSelected({});
  }, [debouncedSearch, location.pathname, path]);

  return (
    <SearchableVerticalList
      header="Branches"
      type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
      data={
        branchesData?.data.map((item) => ({
          id: item.id.toString(),
          name: item.branchName,
          type: item.city.toUpperCase(),
          active: item.isActive,
          disabled: !item.isActive,
          isSelected: selected[item.id],
        })) || []
      }
      path={routeNames.dashboard()}
      isFetching={isFetching}
      isError={isError}
      onSearchChange={setSearch}
      onCreateNew={() => handleClick('new', {})}
      handleItemClick={(item) => handleClick('itemClicked', item)}
      onChangePage={(page) => setPage(page)}
      pageNumber={page}
      switchMenu={switchMenu}
      setSwitchMenu={setSwitchMenu}
      count={branchesData?.count}
    />
  );
};

export default Branches;
