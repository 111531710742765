import api from '@tyrio/api-factory';
import { DBServiceCategoryApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetServiceCategory = (
  search?: string,
  page?: number,
  pageSize?: number,
  onSuccess?: (data: DBServiceCategoryApi['list']['response']) => void
) => {
  const { data, isFetching, isError } = useQuery(
    ['get_service_categories', { search, page, pageSize }],
    () =>
      api.fetch<DBServiceCategoryApi['list']>('get_service_categories', {
        search,
        page,
        pageSize,
      }),
    {
      onSuccess: (res) => {
        if (onSuccess !== undefined) onSuccess(res);
      },
    }
  );

  return { data, isFetching, isError };
};
