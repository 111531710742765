import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBClientStockList, RelatedDBClientStockListModel, CompleteDBWarehouseZones, RelatedDBWarehouseZonesModel, CompleteDBWarehouseSubzones, RelatedDBWarehouseSubzonesModel, CompleteDBWarehouseFloors, RelatedDBWarehouseFloorsModel, CompleteDBWarehouseRacks, RelatedDBWarehouseRacksModel, CompleteDBWarehouseSections, RelatedDBWarehouseSectionsModel, CompleteDBWarehouseShelfs, RelatedDBWarehouseShelfsModel, CompleteDBWarehouseBins, RelatedDBWarehouseBinsModel, CompleteDBStockListItemLocation, RelatedDBStockListItemLocationModel } from "./index"

export const DBBranchWarehouseAreasModel = z.object({
  id: z.string(),
  clientId: z.string().nullish(),
  branchId: z.number().int(),
})

export interface CompleteDBBranchWarehouseAreas extends z.infer<typeof DBBranchWarehouseAreasModel> {
  client?: CompleteDBClient | null
  branch: CompleteDBBranch
  stockListItems: CompleteDBClientStockList[]
  zones: CompleteDBWarehouseZones[]
  subzones: CompleteDBWarehouseSubzones[]
  floors: CompleteDBWarehouseFloors[]
  racks: CompleteDBWarehouseRacks[]
  sections: CompleteDBWarehouseSections[]
  shelfs: CompleteDBWarehouseShelfs[]
  bins: CompleteDBWarehouseBins[]
  items: CompleteDBStockListItemLocation[]
}

/**
 * RelatedDBBranchWarehouseAreasModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBBranchWarehouseAreasModel: z.ZodSchema<CompleteDBBranchWarehouseAreas> = z.lazy(() => DBBranchWarehouseAreasModel.extend({
  client: RelatedDBClientModel.nullish(),
  branch: RelatedDBBranchModel,
  stockListItems: RelatedDBClientStockListModel.array(),
  zones: RelatedDBWarehouseZonesModel.array(),
  subzones: RelatedDBWarehouseSubzonesModel.array(),
  floors: RelatedDBWarehouseFloorsModel.array(),
  racks: RelatedDBWarehouseRacksModel.array(),
  sections: RelatedDBWarehouseSectionsModel.array(),
  shelfs: RelatedDBWarehouseShelfsModel.array(),
  bins: RelatedDBWarehouseBinsModel.array(),
  items: RelatedDBStockListItemLocationModel.array(),
}))
