import * as z from "zod"
import { CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel } from "./index"

export const DBSupplierClientB2BSettingsModel = z.object({
  id: z.string(),
  supplierClientSettingsId: z.string(),
  b2bUrl: z.string(),
  b2bUserName: z.string(),
  b2bPassword: z.string(),
})

export interface CompleteDBSupplierClientB2BSettings extends z.infer<typeof DBSupplierClientB2BSettingsModel> {
  supplierClientSettings: CompleteDBSupplierClientSettings
}

/**
 * RelatedDBSupplierClientB2BSettingsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierClientB2BSettingsModel: z.ZodSchema<CompleteDBSupplierClientB2BSettings> = z.lazy(() => DBSupplierClientB2BSettingsModel.extend({
  supplierClientSettings: RelatedDBSupplierClientSettingsModel,
}))
