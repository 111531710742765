import styled from '@emotion/styled';
import { Button, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import TextField from '@mui/material/TextField/TextField';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi, DBProductModelApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

interface ChangeModelProps {
  control: Control;
  isEditDisabled: boolean;
}

const ChangeModel = ({ control, isEditDisabled }: ChangeModelProps) => {
  const location = useLocation();
  const isNewProduct = location.pathname.split('/').pop() === 'new';
  const [disabled, setDisabled] = useState(!isNewProduct);

  const { data: products_data, isFetching: isProductsDataFetching } = useQuery(
    ['product_models'],
    () =>
      api.fetch<DBProductModelApi['list']>('product_models', {
        pageSize: 10000,
      })
  );

  const {
    data: categoryData,
    isFetching: isCategoriesDataFetching,
    status,
  } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  );

  const shapedCategories = categoryData?.reduce((acc, category) => {
    acc[category.id] = category.parent_category_name
      ? category.parent_category_name + ' > ' + category.name
      : category.name;
    return acc;
  }, {} as Record<string, string>);

  return isProductsDataFetching ||
    isCategoriesDataFetching ||
    status === 'loading' ||
    !shapedCategories ? (
    <CircularProgress />
  ) : (
    <Controller
      name="modelName"
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render={({ field, fieldState: { error } }: any) => {
        return (
          <Container>
            <Autocomplete
              disablePortal
              fullWidth
              disabled={
                disabled || isProductsDataFetching || isCategoriesDataFetching
              }
              value={field.value}
              onChange={(e, x) => field.onChange(x)}
              options={products_data?.data || []}
              groupBy={(option) => shapedCategories?.[option.category_id]}
              getOptionLabel={(option) =>
                `${option.brand} - ${option.modelName} ${
                  option.season ? ' - ' + option.season : ''
                } `
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose model"
                  disabled={disabled || isProductsDataFetching}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: true,
                  }}
                  error={!!error}
                  helperText={
                    error ||
                    (field.value?.category_id &&
                      'Category: ' + shapedCategories[field.value?.category_id])
                  }
                />
              )}
            />
            <StyledButton
              disabled={isProductsDataFetching || isEditDisabled}
              onClick={() => !isProductsDataFetching && setDisabled(!disabled)}
              color="info"
            >
              {isProductsDataFetching ? (
                <CircularProgress />
              ) : disabled ? (
                'CHANGE MODEL'
              ) : (
                'CANCEL'
              )}
            </StyledButton>
          </Container>
        );
      }}
    />
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  height: 56px;
  width: 200px;
`;

export default ChangeModel;
