import styled from '@emotion/styled';
import {
  CategoryLevels,
  generateColor,
} from '../ProductDetails/ProductComponents/PerformanceCategory';
interface EprelClassInterface {
  width: string;
  category: CategoryLevels;
  type: 'wetGrip' | 'rollingResistance';
  productClass: CategoryLevels;
}

const EprelClass = ({
  width,
  category,
  type,
  productClass,
}: EprelClassInterface) => {
  return (
    <Container>
      <ClassWrapper
        style={{ paddingTop: productClass === category ? '2px' : '' }}
      >
        <MainBox width={width} color={generateColor(category, type)}>
          {category}
        </MainBox>
        <SideBox color={generateColor(category, type)}></SideBox>
      </ClassWrapper>
      {productClass === category && (
        <SelectedWrapper>
          <SelectedStart />
          <SelectedClass>{productClass}</SelectedClass>
        </SelectedWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
  position: relative;
`;

const ClassWrapper = styled.div`
  display: flex;
`;

const SelectedWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  margin-top: -5px;
`;

const SelectedStart = styled.div`
  width: 8px;
  height: 36px;
  border-top: 18px solid transparent;
  border-right: 12px solid black;
  border-bottom: 18px solid transparent;
`;

const SelectedClass = styled.div`
  height: 36px;
  width: 24px;
  text-align: center;
  background: black;
  text-transform: uppercase;
  line-height: 36px;
  font-size: 25px;
  font-weight: 600;
  color: white;
`;

const MainBox = styled.div<{ color: string; width?: string }>`
  width: ${(props) => props.width};
  height: 25px;
  display: flex;
  padding-left: 7px;
  align-items: center;
  color: white;
  background: ${(props) => props.color};
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 20px;
`;

const SideBox = styled.div<{ color: string }>`
  width: 12.5px;
  height: 25px;
  border-top: 12.5px solid transparent;
  border-left: 11px solid ${(props) => props.color};
  border-bottom: 12.5px solid transparent;
`;

export default EprelClass;
