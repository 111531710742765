import * as React from 'react';
import { useContext } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { PriceComparisonContext } from '../context';

function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const TransferList = () => {
  const ctx = useContext(PriceComparisonContext);
  const [checked, setChecked] = React.useState<readonly string[]>([]);

  const left =
    ctx.data.supplierTransferList?.left || ctx.suppliers.map((s) => s.id) || [];

  const right = ctx.data.supplierTransferList?.right || [];

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    ctx.setInputValue(
      {
        ...ctx.data,
        supplierTransferList: {
          left: [],
          right: right.concat(left),
        },
      },
      true
    );
  };

  const handleCheckedRight = () => {
    ctx.setInputValue(
      {
        ...ctx.data,
        supplierTransferList: {
          left: not(left, leftChecked),
          right: right.concat(leftChecked),
        },
      },
      true
    );
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    ctx.setInputValue(
      {
        ...ctx.data,
        supplierTransferList: {
          left: left.concat(rightChecked),
          right: not(right, rightChecked),
        },
      },
      true
    );
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    ctx.setInputValue(
      {
        ...ctx.data,
        supplierTransferList: {
          right: [],
          left: left.concat(right),
        },
      },
      true
    );
  };

  const sortItems = (items: readonly string[]) => {
    const mappedArray = items.map((id) => {
      const correspondingObject = ctx.suppliers.find((obj) => obj.id === id);
      return { id, name: correspondingObject?.name ?? '' };
    });

    mappedArray.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    return mappedArray.map((item) => item.id);
  };

  const customList = (items: readonly string[]) => (
    <Paper sx={{ width: '100%', height: 700, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {sortItems(items).map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`;

          if (value === ctx.data.mainSupplier?.id) return null;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  color="info"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={ctx.suppliers.find((s) => s.id === value)?.name}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid xs={5} item>
        {customList(left)}
      </Grid>
      <Grid xs={2} item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid xs={5} item>
        {customList(right)}
      </Grid>
    </Grid>
  );
};
