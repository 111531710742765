import {
  DBConnectionProtocol,
  DBDeliveryTypeEnum,
  DBOrderStatus,
  DBSalesChannel,
  Prisma,
} from '@prisma/client';
import { clients } from './clients';
import { suppliers } from './suppliers';
const dummyAddress = {
  address_line_1: 'Marcela Snajdera 5',
  address_line_2: 'Lamela B',
  city: 'Sarajevo',
  country: 'Bosnia and Herzegovina',
  zipCode: '71000',
};
export const orderItems0: Prisma.DBClientSupplierOrderItemUpsertArgs[] = [
  {
    where: {
      id: '00000000-0000-1000-ad00-000000000001',
    },
    create: {
      id: '00000000-0000-1000-ad00-000000000001',
      orderStatus: DBOrderStatus.DELIVERY_LATE,
      orderNumberPrefix: '01', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000001',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000001',
            orderNumber: 1,
          },
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      client: {
        connect: {
          id: clients[1].id,
        },
      },
      salesChannel: DBSalesChannel.BULK_ORDERS,
      orderNumberSufix: 'RE-0001',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
    update: {
      orderStatus: DBOrderStatus.DELIVERY_LATE,
      orderNumberPrefix: '01', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000001',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000001',
            orderNumber: 1,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      orderNumberSufix: 'RE-0001',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
  },
  {
    where: {
      id: '00000000-0000-1000-ad00-000000000002',
    },
    create: {
      id: '00000000-0000-1000-ad00-000000000002',
      orderStatus: DBOrderStatus.DELIVERED,
      orderNumberPrefix: '02', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000002',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000002',
            orderNumber: 2,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      client: {
        connect: {
          id: clients[1].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      salesChannel: DBSalesChannel.BULK_ORDERS,
      orderNumberSufix: 'RE-0002',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
    update: {
      orderStatus: DBOrderStatus.DELIVERED,
      orderNumberPrefix: '02', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000003',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000003',
            orderNumber: 1,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      orderNumberSufix: 'RE-0002',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
  },
  {
    where: {
      id: '00000000-0000-1000-ad00-000000000003',
    },
    create: {
      id: '00000000-0000-1000-ad00-000000000003',
      orderStatus: DBOrderStatus.BACKORDER,
      orderNumberPrefix: '03', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000003',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000003',
            orderNumber: 2,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      client: {
        connect: {
          id: clients[1].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      salesChannel: DBSalesChannel.BULK_ORDERS,
      orderNumberSufix: 'RE-0003',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
    update: {
      orderStatus: DBOrderStatus.BACKORDER,
      orderNumberPrefix: '03', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000003',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000003',
            orderNumber: 1,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      orderNumberSufix: 'RE-0003',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
  },
  {
    where: {
      id: '00000000-0000-1000-ad00-000000000004',
    },
    create: {
      id: '00000000-0000-1000-ad00-000000000004',
      orderStatus: DBOrderStatus.DELIVERED,
      orderNumberPrefix: '04', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000004',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000004',
            orderNumber: 2,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      client: {
        connect: {
          id: clients[1].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      salesChannel: DBSalesChannel.BULK_ORDERS,
      orderNumberSufix: 'RE-0004',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
    update: {
      orderStatus: DBOrderStatus.DELIVERED,
      orderNumberPrefix: '04', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000004',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000004',
            orderNumber: 1,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      orderNumberSufix: 'RE-0004',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'Lorem ipsum lorem historia ipsum lorem est.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
  },
  {
    where: {
      id: '00000000-0000-1000-ad00-000000000005',
    },
    create: {
      id: '00000000-0000-1000-ad00-000000000005',
      orderStatus: DBOrderStatus.PROCESSING,
      orderNumberPrefix: '05', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000005',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000005',
            orderNumber: 2,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      client: {
        connect: {
          id: clients[1].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      salesChannel: DBSalesChannel.BULK_ORDERS,
      orderNumberSufix: 'RE-0005',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
    update: {
      orderStatus: DBOrderStatus.PROCESSING,
      orderNumberPrefix: '05', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000005',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000005',
            orderNumber: 1,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      orderNumberSufix: 'RE-0005',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'Lorem ipsum lorem historia est ipsum lorem.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
  },
  {
    where: {
      id: '00000000-0000-1000-ad00-000000000006',
    },
    create: {
      id: '00000000-0000-1000-ad00-000000000006',
      orderStatus: DBOrderStatus.MISSING,
      orderNumberPrefix: '06', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000006',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000006',
            orderNumber: 2,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      client: {
        connect: {
          id: clients[1].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      salesChannel: DBSalesChannel.BULK_ORDERS,
      orderNumberSufix: 'RE-0006',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'We need to pick this up asap.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
    update: {
      orderStatus: DBOrderStatus.MISSING,
      orderNumberPrefix: '06', // TODO
      order: {
        connectOrCreate: {
          where: {
            id: '00000000-0000-1000-ad00-000000000006',
          },
          create: {
            id: '00000000-0000-1000-ad00-000000000006',
            orderNumber: 1,
          },
        },
      },
      supplier: {
        connect: {
          id: suppliers[8].id,
        },
      },
      branch: {
        connect: {
          id: 2,
        },
      },
      orderNumberSufix: 'RE-0004',
      deliveryAddress: dummyAddress,
      deliveryType: DBDeliveryTypeEnum.DPD,
      estimatedDeliveryDateFrom: new Date(),
      estimatedDeliveryDateTo: new Date(),
      orderedAt: new Date(),
      supplierRemark: 'Send over the latest DOT please.',
      remark: 'Lorem ipsum lorem historia est ipsum lorem.',
      lineItems: [],
      orderLineItemMeta: {},
      metaDeliveryLate: false,
      metaOrderForStock: false,
      metaOrderDropShipping: false,
      trackingInfo: {},
      cancelReason: null,
      emailConfig: {},
      subtotal: 5000,
      ecoTax: 5000,
      delivery: 5000,
      total: 5000,
      connectionProtocol: DBConnectionProtocol.FTP,
    },
  },
];
