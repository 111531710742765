import { DBWorkOrdersCreate, IModalData } from '@tyrio/dto';
import React, { useState } from 'react';
export type WorkOrderDataType = DBWorkOrdersCreate & {
  branchName: string;
  branchId: string;
};

export interface ILineItem {
  title: string;
  subtitle: string;
  orderNr: string;
  quantity: number;
  date?: string;
  time?: string;
  orderDetails: IModalData[];
}

export type WorkOrdersType = Record<string, ILineItem[]>;
export type SetWorkOrder = (val: WorkOrdersType) => void;

export interface WorkOrderContextShape {
  workOrders: WorkOrdersType | null;
  setWorkOrders: SetWorkOrder;

  filteredWorkOrders: WorkOrdersType | null;
  setFilteredWorkOrders: SetWorkOrder;

  readyWorkOrder: WorkOrdersType | null;
  setReadyWorkOrders: SetWorkOrder;

  searchFilterValue: string | null;
  setSearchFilterValue: (val: string) => void;

  textFieldValue: string | null;
  setTextFieldValue: (val: string) => void;

  setOpenModal: (val: boolean) => void;
  openModal: boolean;

  modalData: IModalData[];
  setModalData: (a: IModalData[]) => void;
}
export const WorkOrderContext = React.createContext<WorkOrderContextShape>({
  workOrders: null,
  setWorkOrders: () => null,
  readyWorkOrder: null,
  setReadyWorkOrders: () => null,
  searchFilterValue: null,
  setSearchFilterValue: () => null,
  textFieldValue: null,
  setTextFieldValue: () => null,
  filteredWorkOrders: null,
  setFilteredWorkOrders: () => null,
  setOpenModal: () => null,
  openModal: false,
  modalData: [],
  setModalData: () => null,
});

export const WorkOrderContextProvide = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [workOrders, setWorkOrders] = useState<WorkOrdersType>({});
  const [filteredWorkOrders, setFilteredWorkOrders] = useState<Record<
    string,
    ILineItem[]
  > | null>({});

  const [readyWorkOrder, setReadyWorkOrders] = useState<Record<
    string,
    ILineItem[]
  > | null>({});

  const [searchFilterValue, setSearchFilterValue] = useState<string | null>(
    'orderNumber'
  );
  const [textFieldValue, setTextFieldValue] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [modalData, setModalData] = useState<IModalData[]>([]);

  return (
    <WorkOrderContext.Provider
      value={{
        workOrders,
        setWorkOrders,
        searchFilterValue,
        setSearchFilterValue,
        textFieldValue,
        setTextFieldValue,
        readyWorkOrder,
        setReadyWorkOrders,
        setFilteredWorkOrders,
        filteredWorkOrders,
        openModal,
        setOpenModal,
        modalData,
        setModalData,
      }}
    >
      {children}
    </WorkOrderContext.Provider>
  );
};

export const useWorkOrderCtx = () => React.useContext(WorkOrderContext);
