import { FormShape, TyrioSelectInputOption } from '../types';
import { createInput } from '../utils';
import { zodAdminObject } from './zodObjects';
export const generateAdminForm = (
  roles: TyrioSelectInputOption[],
  languages: TyrioSelectInputOption[],
  isUser: boolean,
  isAdminOrSuperAdmin: boolean
) => {
  const zodSchema = zodAdminObject;
  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: 'Personal Info',
        inputs: [
          createInput({
            id: 'roleId',
            width: { xs: 12, lg: 6 },
            type: 'input.select',
            options: roles,
            label: 'User role *',
            disabled: isAdminOrSuperAdmin,
          }),
          createInput({
            id: 'language',
            width: { xs: 12, lg: 6 },
            type: 'input.select',
            options: languages,
            label: 'Language *',
            disabled: isAdminOrSuperAdmin,
          }),
          createInput({
            id: 'firstName',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'First name *',
            disabled: isAdminOrSuperAdmin,
          }),
          createInput({
            id: 'lastName',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'Last name *',
            disabled: isAdminOrSuperAdmin,
          }),
          createInput({
            id: 'email',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'E-mail *',
            disabled: isUser,
          }),
          createInput({
            id: 'mobilePhone',
            width: { xs: 12, lg: 4 },
            type: 'input.phone',
            label: 'Phone number *',
            disabled: isUser,
          }),
          createInput({
            id: 'birthday',
            width: { xs: 12, lg: 4 },
            mode: 'calendardate',
            type: 'input.datepicker',
            label: 'Birthday',
            disabled: isAdminOrSuperAdmin,
          }),
          createInput({
            id: 'remark',
            width: { xs: 12, lg: 12 },
            type: 'input.string',
            label: 'Remark',
            disabled: isAdminOrSuperAdmin,
          }),
        ],
      },
    ],
  };
  return { form, zodSchema };
};
