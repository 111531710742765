import { TRule } from '../../typings';
import { EDI_WHEEL_B2_STOCKLIST_RULESET } from '../edi-b2/stocklist';

export const GOODYEAR_STOCKLIST_RULESET: TRule[] = [
  ...EDI_WHEEL_B2_STOCKLIST_RULESET,
  {
    type: 'rule.map',
    sourceKey: "c.['AVAILABLE']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
];
