import { TRule } from '../../typings';

export const GUMAX_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.brand",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.SKU",
    destination: 'product',
    targetKey: 'manufacturerCode',
  },
  {
    type: 'rule.operate',
    sourceKey: "c['zaliha']",
    destination: 'product',
    targetKey: 'qty',
    operationName: 'getGumaxQty',
  },
  {
    type: 'rule.operate',
    sourceKey: "c['zaliha']",
    destination: 'product',
    targetKey: 'nextShippingDate',
    operationName: 'getGumaxShippingDate',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['itemID']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
  {
    type: 'rule.operate',
    sourceKey: "c['name']",
    destination: 'product',
    targetKey: 'dot',
    operationName: 'getGumaxDOT',
  },
];
