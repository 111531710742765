import api from '@tyrio/api-factory';
import { CustomPriceStockType, DBCustomPriceCatalogApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

interface GetCustomPriceCatalogProps {
  keyword: string;
  page: number;
  type: CustomPriceStockType;
}

export const useGetCustomPriceCatalog = (props: GetCustomPriceCatalogProps) => {
  const { keyword, page, type } = props;

  const customPriceCatalog = useQuery(
    ['cpc_get_catalog', { keyword, page, type }],
    async () => {
      return await api.fetch<DBCustomPriceCatalogApi['list']>(
        'cpc_get_catalog',
        {
          keyword: keyword,
          page: page,
          type: type,
        }
      );
    }
  );

  return { ...customPriceCatalog };
};
