import { useContext } from 'react';
import { WarehouseContext } from '../../../context/WarehouseContext';

export const useCheckCurrent = () => {
  const { selected } = useContext(WarehouseContext);

  const isZone =
    selected.locations.zone &&
    !selected.locations.subzone &&
    !selected.locations.floor &&
    !selected.locations.rack &&
    !selected.locations.section &&
    !selected.locations.shelf &&
    !selected.locations.bin;

  const isSubzone =
    selected.locations.zone &&
    selected.locations.subzone &&
    !selected.locations.floor &&
    !selected.locations.rack &&
    !selected.locations.section &&
    !selected.locations.shelf &&
    !selected.locations.bin;

  const isRack =
    selected.locations.zone &&
    selected.locations.subzone &&
    selected.locations.floor &&
    selected.locations.rack &&
    !selected.locations.section &&
    !selected.locations.shelf &&
    !selected.locations.bin;

  const isShelf =
    selected.locations.zone &&
    selected.locations.subzone &&
    selected.locations.floor &&
    selected.locations.rack &&
    selected.locations.section &&
    selected.locations.shelf &&
    !selected.locations.bin;

  return {
    isZone,
    isSubzone,
    isRack,
    isShelf,
  };
};
