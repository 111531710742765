import api from '@tyrio/api-factory';
import { DBDeliveryTypesApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { QueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

export const useCreateDeliveryMethod = (queryClient: QueryClient) => {
  const history = useHistory();
  const { data, mutate } = useMutation(
    ['create_delivery_type'],
    (body: DBDeliveryTypesApi['create']['requestBody']) =>
      api.fetch<DBDeliveryTypesApi['create']>('create_delivery_type', {
        ...body,
      }),
    {
      onSuccess: (res) => {
        queryClient.refetchQueries('get_client_delivery_types');

        history.push(`/dashboard/company-settings/delivery-method/${res.id}`);
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully created delivery method.'
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Error',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occured.'
          );
        }
        throw error;
      },
    }
  );

  return { data, createDelivery: mutate };
};
