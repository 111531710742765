import { MuiConfig } from '@react-awesome-query-builder/mui';
import {
  BRANDS,
  SEASON_MAP,
  SPEED_INDEX_DROPDOWN_DATA,
  WHEEL_POSITION_MAP,
  WHEEL_RIM_DIAMETER_DROPDOWN_DATA,
  WHEEL_WIDTH_DROPDOWN_DATA,
} from '@tyrio/shared-vars';
import { useFetchCategories } from './fetch-data';

const InitialConfig = MuiConfig;

export const Config = () => {
  const { categories } = useFetchCategories();
  const BRAND_DROPDOWN_DATA = Object.values(BRANDS).map((b) => ({
    value: b.name,
    title: b.name,
  }));

  const WHEEL_POSITIONS_DROPDOWN_DATA = Object.keys(WHEEL_POSITION_MAP).map(
    (w) => ({
      value: w,
      title: WHEEL_POSITION_MAP[w],
    })
  );

  const WIDTH = WHEEL_WIDTH_DROPDOWN_DATA.map((c) => {
    return { value: c.value, title: c.label };
  });

  const selectOperators = [
    'select_equals',
    'select_not_equals',
    'select_any_in',
    'select_not_any_in',
  ];

  const stringOperators = [
    'equal',
    'not_equal',
    'multiselect_contains',
    'multiselect_not_contains',
    'starts_with',
    'ends_with',
    'is_empty',
    'is_not_empty',
  ];

  const boolOperators = ['equal', 'not_equal'];

  const mainCategories = categories.filter((item) => item.main);
  const subCategories = categories.filter((item) => !item.main);

  const configObj = {
    ...InitialConfig,
    settings: {
      ...InitialConfig.settings,
      groupActionsPosition: 'bottomRight',
      maxNesting: 1, //this removes add group button
      removeRuleConfirmOptions: {
        title: 'Are you sure you want to delete this condition?',
        okText: 'Yes',
        cancelText: 'Cancel',
      },
    },
    fields: {
      category_id: {
        label: 'Category',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: mainCategories?.map((c) => {
            return {
              value: c.id,
              title: c.label,
            };
          }),
        },
      },
      subcategory_id: {
        label: 'Subcategory',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: subCategories?.map((c) => {
            return {
              value: c.id,
              title: c.label,
            };
          }),
        },
      },
      season: {
        label: 'Season',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
            { value: 'summer', title: SEASON_MAP['summer'] },
            { value: 'winter', title: SEASON_MAP['winter'] },
            { value: 'allseason', title: SEASON_MAP['allseason'] },
          ],
        },
      },
      brands: {
        label: 'Brands',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: BRAND_DROPDOWN_DATA,
        },
      },
      modelName: {
        label: 'Model name',
        operators: stringOperators,
        type: 'text',
        valueSources: ['value'],
      },
      wheelPosition: {
        label: 'Wheel position',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: WHEEL_POSITIONS_DROPDOWN_DATA,
        },
      },

      width: {
        label: 'Width',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: WIDTH,
        },
      },

      rimDiameter: {
        label: 'Rim Diameter',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: WHEEL_RIM_DIAMETER_DROPDOWN_DATA.map((r) => {
            return {
              value: r.value,
              title: r.label,
            };
          }),
        },
      },

      size: {
        label: 'Size',
        operators: stringOperators,
        type: 'text',
        valueSources: ['value'],
      },

      speedIndex: {
        label: 'Speed Index',
        operators: selectOperators,
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: SPEED_INDEX_DROPDOWN_DATA.map((r) => {
            return {
              value: r.value,
              title: r.label,
            };
          }),
        },
      },
      runFlat: {
        label: 'Run Flat',
        operators: boolOperators,
        type: 'boolean',
        valueSources: ['value'],
      },

      pcd: {
        label: 'PCD',
        operators: stringOperators,
        type: 'text',
        valueSources: ['value'],
      },
    },
    operators: {
      ...InitialConfig.operators,
      equal: {
        ...InitialConfig.operators.equal,
        label: 'Equal',
      },
      not_equal: {
        ...InitialConfig.operators.not_equal,
        label: 'Not equal',
      },
      select_equals: {
        ...InitialConfig.operators.select_equals,
        label: 'Equal',
      },
      select_not_equals: {
        ...InitialConfig.operators.select_not_equals,
        label: 'Not equal',
      },
    },
  };

  return configObj;
};

// noiseClassType
//   number
