import {
  LandingPage,
  SearchableVerticalList,
  SearchableVerticalListDataType,
  tyrioIcons,
} from '@tyrio/ui-library';
import { DBSupplierClientSettingsApi } from '@tyrio/dto';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { ExpandableLayout } from '../../layouts/ExpandableLayout';
import routeNames from '../../lib/routeNames';
import { useDispatch } from 'react-redux';
import { changeSupplierClientModalVisibility } from '../../features/auth/store/auth.reducer';
import { useDebounce } from '../../hooks/useDebounce';
import api from '@tyrio/api-factory';
import { pageSize } from '../../../../constants/pagination.constants';
import { useAuth } from '../../context/AuthContext';

interface DashboardAdminProps {
  children: React.ReactNode;
}

const DashboardClientSupplier = ({ children }: DashboardAdminProps) => {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const dispatch = useDispatch();

  const { user } = useAuth();

  const clientId = user?.clientId || undefined;

  const { isFetching, isError, data } = useQuery(
    ['supplier_client_settings_list', { debouncedSearch, page }],
    () => getClientSupplierSettingsByClientId(search, page),
    {}
  );

  const getClientSupplierSettingsByClientId = async (
    search: string,
    page: number
  ) => {
    return await api.fetch<DBSupplierClientSettingsApi['list']>(
      'get_supplier_client_settings',
      {
        clientId: clientId,
        search: search,
        page: page,
        pageSize: pageSize,
      }
    );
  };

  const [selected, setSelected] = useState<Record<string, boolean>>({
    [location.pathname.split('/').pop()]: true,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      setSelected((prevValue) => ({
        ...prevValue,
        [item.id]: !prevValue[item.id],
      }));
      history.push(`/dashboard/supplier/${item.id}`);
    }
    if (type === 'new') {
      history.push(`/dashboard/supplier/new`);
      dispatch(changeSupplierClientModalVisibility());
    }
  };

  return (
    <ExpandableLayout
      sidebarComponent={
        <SearchableVerticalList
          header="Suppliers Settings"
          type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
          data={
            data?.data.map((item) => ({
              id: item.id,
              name: item.supplier?.companyShortName ?? '',
              type: item.supplier?.supplierType ?? '',
              active: item.isActive,
              disabled: !item.isActive,
              isSelected: selected[item.id],
            })) || []
          }
          path={routeNames.dashboard()}
          isFetching={isFetching}
          isError={isError}
          onSearchChange={setSearch}
          onCreateNew={() => handleClick('new', {})}
          handleItemClick={(item) => handleClick('itemClicked', item)}
          onChangePage={(page) => setPage(page)}
          pageNumber={page}
          count={data?.count}
        />
      }
      landingPage={
        <LandingPage
          icon={tyrioIcons.menuIcon5}
          title={'Welcome to Supplier settings'}
          subtitle={'Choose supplier from the list or create new.'}
          buttonText={'Add new supplier'}
          onClick={() => handleClick('new', {})}
        />
      }
    >
      {children}
    </ExpandableLayout>
  );
};

export default DashboardClientSupplier;
