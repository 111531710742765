import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { TyrioLogo, tyrioMUI, tyrioUI } from '@tyrio/ui-library';
import { onLogout } from '../../../helpers/firebase';

const DeactivatedUser = () => {
  const handleSignIn = () => {
    onLogout();
  };

  return (
    <LayoutContainer>
      <Wrapper>
        <LogoWrapper>
          <img src={TyrioLogo} alt="tyrio-logo" />
          <LogoText>SOFTWARE FOR TYRE PROFESSIONALS</LogoText>
        </LogoWrapper>
        <InfoWrapper>
          <TypographyTitle gutterBottom variant="h4">
            Deactivated
          </TypographyTitle>
          <BodyWrapper>
            This User is deactivated, please contact your admin, or try to login
            with different user.
          </BodyWrapper>
          <Button
            type="submit"
            onClick={handleSignIn}
            variant="contained"
            color="info"
            fullWidth
          >
            Login
          </Button>
        </InfoWrapper>
      </Wrapper>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${tyrioUI.spacing.xl}px;
  background: linear-gradient(140.03deg, #37496c 1.85%, #1b2130 85.49%);
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LogoWrapper = styled.div``;

const LogoText = styled.p`
  color: #919eab;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-bottom: 90px;
`;

const TypographyTitle = styled(Typography)`
  height: 34px;
  letter-spacing: 0.3px;
  color: #212b36;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 26px;
`;

const InfoWrapper = styled.div`
  background-color: ${tyrioMUI.muiTheme.palette.background.paper};
  padding: ${tyrioUI.spacing.xl}px;
  flex: 1;
  max-width: 522px;
  border-radius: ${tyrioUI.borderRadius.l}px;
  box-shadow: ${tyrioMUI.muiTheme.shadows[1]};
  margin-bottom: 150px;
`;

const BodyWrapper = styled.div`
  text-align-last: center;
  margin-bottom: 26px;
  letter-spacing: 0.83px;
`;

export default DeactivatedUser;
