import api from '@tyrio/api-factory';
import { DBStockListCronApi } from '@tyrio/dto';
import { useMutation } from 'react-query';

export const useGetLogsById = () => {
  const query = useMutation(
    (req: DBStockListCronApi['get_id']['requestBody']) =>
      api.fetch<DBStockListCronApi['get_id']>('getLogsById', {
        ...req,
      }),
    {
      mutationKey: 'getLogsById',
    }
  );

  return { ...query };
};
