import styled from '@emotion/styled/macro';
import { Button, Modal, TextField, Typography } from '@mui/material';

interface OrderSupplierModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  orderNumber: string;
  setOrderNumber: (a: string) => void;
}

export const OrderSupplierModal = ({
  isOpen,
  onCancel,
  onSubmit,
  orderNumber,
  setOrderNumber,
}: OrderSupplierModalProps) => {
  return (
    <Modal
      open={isOpen}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalWrapper>
        <Typography fontSize={20} fontWeight={600} letterSpacing={0.3}>
          {`Enter supplier order number`}
        </Typography>
        <TextField
          label="Supplier order number"
          variant="outlined"
          style={{ width: '260px' }}
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
        />

        <ButtonWrapper>
          <StyledButton
            variant="outlined"
            color="info"
            size="large"
            onClick={onCancel}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="info"
            size="large"
            onClick={onSubmit}
          >
            SUBMIT
          </StyledButton>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 430px;
  background: #fafafa;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22),
    0px 19px 38px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  height: auto;
  padding: 32px;
  gap: 20px;
`;

const StyledButton = styled(Button)`
  width: 179px;
  height: 60px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 24px;
  margin-top: 16px;
`;
