import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import truck from '../assets/truck_gray.svg';
import calendar from '../assets/calendar-tick.svg';

enum ComponentType {
  product = 'product',
  services = 'services',
}

interface ProductCardProps {
  title: string;
  ean?: string;
  ipc: string;
  sku: string;
  componentType: string;
  price: string;
  total: string;
  qty: number;
  discount: string;
  showDate?: boolean;
  orderSent?: boolean;
  deliveryDate?: string;
  isService?: boolean;
  serviceErpId?: string;
  slug?: string;
  purchasePrice?: number;
}

export const TableProductItem = ({
  title,
  ean,
  ipc,
  sku,
  componentType,
  price,
  total,
  qty,
  discount,
  showDate = true,
  orderSent = false,
  deliveryDate,
  isService,
  serviceErpId,
}: ProductCardProps) => {
  const subtitle = () => {
    if (!isService) {
      return (
        <EanIpcSkuWrapper>
          <div>EAN: {ean}</div> <div>IPC: {ipc}</div> <div> SKU: {sku}</div>
          {/* {slug && <div>SLUG: {slug}</div>}
          {purchasePrice && <div>PRICE: {purchasePrice}</div>} */}
        </EanIpcSkuWrapper>
      );
    }
    return (
      <EanIpcSkuWrapper>
        <div>ERP ID: {serviceErpId ?? ''}</div>
      </EanIpcSkuWrapper>
    );
  };
  return (
    <CardWrapper orderSent={orderSent}>
      <ProductDetailsWrapper>
        <div>{title}</div>

        <SubtitleWrapper>{subtitle()}</SubtitleWrapper>

        {showDate && componentType === ComponentType.product ? (
          <DateDetails
            title="Delivery date:"
            date={deliveryDate ?? ''}
            img={truck}
          />
        ) : (
          showDate && (
            <SubtitleWrapper>
              <DateDetails
                title="Installation date:"
                date="25.11. - 30.11."
                img={calendar}
              />

              <Typography variant="caption" color={'#919EAB'}>
                Montažni partner:
                <span style={{ color: '#0894DA' }}>AUTO ANTONIO - SPLIT</span>
              </Typography>
            </SubtitleWrapper>
          )
        )}
      </ProductDetailsWrapper>
      <ProductPricesWrapper>
        <Typography fontWeight={400}>{price}</Typography>
        <Typography fontWeight={400}>{discount}</Typography>
        <Typography fontWeight={600}>{qty}</Typography>
        <TotalWrapper>
          <Typography fontWeight={400}>{total}</Typography>
        </TotalWrapper>
      </ProductPricesWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div<{
  orderSent: boolean;
}>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
  opacity: ${(prop) => (prop.orderSent ? '50%' : `100%`)};
  &:last-child {
    border: none;
  }
  padding: 8px 0;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 100%;
`;

const ProductPricesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 280px;
  max-width: 280px;
  width: 100%;
  gap: 15px;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const EanIpcSkuWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  max-width: 400px;
  flex-direction: row;
  color: #919eab;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

const TotalWrapper = styled.div`
  background: #f4f6f8;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 56px;
  gap: 10px;
  width: 90px;
`;

export const DateDetails = ({
  img,
  title,
  date,
}: {
  img: string;
  title: string;
  date: string;
}) => {
  return (
    <SubtitleWrapper style={{ justifyContent: 'flex-start' }}>
      <img src={img} alt="delivery_truck"></img>
      <Typography variant="caption" align="right" color={'#919EAB'}>
        {title}
      </Typography>
      <Typography variant="caption" align="right" color={'#919EAB'}>
        {date}
      </Typography>
    </SubtitleWrapper>
  );
};
