import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import api, { ENDPOINTS } from '@tyrio/api-factory';
import {
  DBClientSupplierOrderItemApi,
  DBOrderDocumentApi,
  OrderStatusProps,
  StockInOrderMeta,
} from '@tyrio/dto';
import {
  documentIcon,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import axios, { AxiosError } from 'axios';
import _, { startCase } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  TabContent,
  tabProps,
  TabsStyled,
  TabStyled,
  TabsWrapper,
} from '../../../components/Tabs/Tabs';
import UploadDocuments from '../../../components/UploadDocuments/UploadDocuments';
import { StockInContext } from '../../../context/StockInContext';
import { getStockInOrderStatusColor } from '../../purchase-orders/helpers/generate-color';
import { OrderStatus } from '../../purchase-orders/PurchaseOrderTable';
import { useDeleteOrderDocument } from './queries/delete-order-document';
import StockInOrderDetails from './StockInOrderDetailsTab';

const StockInOrderDetailsSidebarTabs = () => {
  const { selectedOrderData, setSelectedOrderData, setIsUploadInProcess } =
    useContext(StockInContext);

  const [activeTab, setActiveTab] = useState(0);

  // get order documents
  const { data: orderDocuments, refetch } = useQuery(
    ['get_order_documents', selectedOrderData?.id],
    () =>
      api.fetch<DBOrderDocumentApi['list']>(`get_order_documents`, {
        id: selectedOrderData?.id,
        type: 'stockin',
      }),
    {
      enabled: !!selectedOrderData?.id,
    }
  );

  // upload order documents mutation
  const uploadOrderDocuments = useMutation(
    (data: {
      data: FormData;
      headers: {
        Authorization: string;
        'Content-Type': string;
        type: string;
      };
    }) =>
      axios.put(
        `${ENDPOINTS.upload_order_document.uri}${selectedOrderData?.id}`,
        data.data,
        {
          headers: data.headers,
        }
      ),
    {
      mutationKey: 'upload_order_document',
      onSuccess: () => {
        refetch();
        setIsUploadInProcess(false);
        ToastHelper.showToast(
          'Related documents',
          ToastType.SUCCESS,
          ToastMessageType.UPLOAD
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Stock IN order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const onOpen = useCallback(
    (acceptedFiles: File[]) => {
      setIsUploadInProcess(true);
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append('file', file, file.name));
      uploadOrderDocuments.mutate({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@@apiToken')}`,
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
          type: 'stockin',
        },
        data: formData,
      });
    },
    [setIsUploadInProcess, uploadOrderDocuments]
  );

  // delete order document
  const { deleteOrderDocument } = useDeleteOrderDocument(refetch);

  const onDelete = (id: string) => {
    deleteOrderDocument(id);
  };

  const { data } = useQuery(
    [
      'get_po_by_id',
      (selectedOrderData?.orderMeta as unknown as StockInOrderMeta).orderId,
    ],
    () =>
      api.fetch<DBClientSupplierOrderItemApi['getOne']>('get_po_by_id', {
        id:
          (selectedOrderData?.orderMeta as unknown as StockInOrderMeta)
            .orderId ?? '',
      }),
    {
      enabled:
        selectedOrderData !== null &&
        !!(
          (selectedOrderData.orderMeta as unknown as StockInOrderMeta)
            .requestType === 0
        ) &&
        (selectedOrderData.orderMeta as unknown as StockInOrderMeta).orderId !==
          'unmatched',
    }
  );

  const connectedOrders = useMemo(() => {
    const orders = [];
    if (
      selectedOrderData &&
      (selectedOrderData.orderMeta as unknown as StockInOrderMeta).orderId !==
        'unmatched'
    )
      orders.push({
        fileName: 'Purchase order',
        url: `/dashboard/purchase-orders?searchFilter=orderNumber&searchKeyword=${
          (selectedOrderData.orderMeta as unknown as StockInOrderMeta)
            .orderNumber
        }&selected=${
          (selectedOrderData.orderMeta as unknown as StockInOrderMeta).orderId
        }`,
        chipLabel: data?.orderStatus ?? 'UNKNOWN',
        documentIcon: documentIcon,
        reference:
          (selectedOrderData.orderMeta as unknown as StockInOrderMeta)
            .orderNumber ?? '',
      });

    if (data && data.customerOrder)
      orders.push({
        fileName: 'Customer order',
        url: `/dashboard/customer-orders?searchFilter=orderNumber&searchKeyword=${_.get(
          data.customerOrder,
          'details.number'
        )}&selected=${data.customerOrder.id}`,
        chipLabel:
          (data?.customerOrder.orderStatus as unknown as OrderStatusProps)
            .status ?? 'UNKNOWN',
        documentIcon: documentIcon,
        reference: data.customerOrder.orderNumber ?? '',
      });

    return orders;
  }, [data, selectedOrderData]);

  return (
    selectedOrderData && (
      <Container>
        <Wrapper>
          <TabsWrapper>
            <TabsStyled value={activeTab} onChange={(_e, v) => setActiveTab(v)}>
              <TabStyled label="Order" {...tabProps(0)} />
              <TabStyled label="Related documents" {...tabProps(0)} />
            </TabsStyled>
            <StatusPrinterWrapper>
              <OrderStatus
                color={getStockInOrderStatusColor[selectedOrderData.status]}
                style={{ fontSize: '12px' }}
              >
                {startCase(selectedOrderData.status)}
              </OrderStatus>
              {selectedOrderData.dropShipping && (
                <OrderStatus
                  color={'black'}
                  style={{ fontSize: '12px', marginLeft: '5px' }}
                >
                  {'DS'}
                </OrderStatus>
              )}
            </StatusPrinterWrapper>
          </TabsWrapper>

          <TabContent value={activeTab} index={0}>
            <StockInOrderDetails
              selectedOrderData={selectedOrderData}
              setSelectedOrderData={setSelectedOrderData}
            />
          </TabContent>

          <TabContent value={activeTab} index={1}>
            <UploadDocuments
              orderDocuments={orderDocuments}
              onOpen={onOpen}
              onDelete={onDelete}
              isLoading={uploadOrderDocuments.isLoading}
              hasConnectedOrder={
                (selectedOrderData.orderMeta as unknown as StockInOrderMeta)
                  .requestType === 0 &&
                (selectedOrderData.orderMeta as unknown as StockInOrderMeta)
                  .orderId !== 'unmatched'
              }
              connectedOrders={connectedOrders}
            />
          </TabContent>
        </Wrapper>
      </Container>
    )
  );
};

const Container = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 16px;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: 1;
  width: 100%;
  border-color: divider;
  height: fit-content;
`;

const StatusPrinterWrapper = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  height: 50px;
  align-items: center;
`;

export default StockInOrderDetailsSidebarTabs;
