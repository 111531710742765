import { Header } from '@tyrio/ui-library';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import { SearchFilter } from '../stock-list/components/Search/SearchFilter';
import { FiltersWrapper, MainContent, SearchWrapper } from './styles/styles';
import { WorkOrdersOverview } from './components/WorkOrdersOverview';
import {
  WorkOrdersType,
  useWorkOrderCtx,
} from '../../context/WorkOrderContext';
import { LineItemsModal } from './components/modal/Modal';
import { useHistory } from 'react-router-dom';

export const searchTypeOptions = [
  { value: 'orderNumber', name: 'Order number' },
  { value: 'customer', name: 'Customer' },
];

export const WorkOrdersDashboardPage = () => {
  const history = useHistory();
  const {
    searchFilterValue,
    setSearchFilterValue,
    textFieldValue,
    setTextFieldValue,
    workOrders,
    setFilteredWorkOrders,
    openModal,
    setOpenModal,
  } = useWorkOrderCtx();

  const onChange = (value: string, key: string) => {
    if (key === 'searchType') setSearchFilterValue(value);
    if (key === 'searchKeyword') setTextFieldValue(value);
  };

  const removeSpaceAndSpecialCharacters = (value: string) => {
    return (value ?? '')
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '');
  };

  const filterData = () => {
    const entries = Object.entries(workOrders as WorkOrdersType);

    const filteredEntries = entries.map(([key, value]) => {
      const path =
        searchFilterValue === 'orderNumber'
          ? 'title'
          : searchFilterValue === 'customer'
          ? 'subtitle'
          : null;

      if (textFieldValue && path) {
        return [
          key,
          value.filter((f) => {
            const formattedValue = removeSpaceAndSpecialCharacters(f[path]);
            const formattedSearch =
              removeSpaceAndSpecialCharacters(textFieldValue);

            const hasReference =
              searchFilterValue === 'orderNumber'
                ? f.orderDetails.some((s) =>
                    s.reference.includes(textFieldValue)
                  )
                : false;

            return formattedValue.includes(formattedSearch) || hasReference;
          }),
        ];
      }
      return [key, value];
    });

    const data = Object.fromEntries(filteredEntries);

    setFilteredWorkOrders(data);
  };

  return (
    <PageTemplateWrapper>
      <LineItemsModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <PageTemplateContent style={{ marginRight: 0 }}>
        <Header
          title="Stock out dashboard"
          backButton={() => {
            history.goBack();
          }}
        />

        <MainContent>
          {/* header */}
          <FiltersWrapper>
            <SearchWrapper>
              <SearchFilter
                optionsArray={searchTypeOptions}
                onChange={onChange}
                selectValue={searchFilterValue ?? ''}
                textFieldValue={textFieldValue ?? ''}
                filterFunction={filterData}
              />
            </SearchWrapper>
          </FiltersWrapper>

          {/* main component */}
          <WorkOrdersOverview />
        </MainContent>
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};
