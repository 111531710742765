import { Checkbox, Divider, Grid, Typography } from '@mui/material';
import api from '@tyrio/api-factory';
import { DBCountryApi, DBSupplierApi } from '@tyrio/dto';
import { TyrioSelectInputOption, ViesInputCustom } from '@tyrio/forms';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  SelectList,
  TextFieldInput,
} from '../supplier-form/helpers/components';
import { dropdownDataDefaultProps } from '../supplier-form/helpers/default-values';
import { CompanyLogoImage } from '../supplier-form/SupplierClientForm';

export interface SupplierClientCompanyInfoProps {
  supplierData: DBSupplierApi['getOne']['response'];
}

const SupplierClientCompanyInfo = ({
  supplierData,
}: SupplierClientCompanyInfoProps) => {
  const { t } = useTranslation();

  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setCountries(dropdownItems);
      },
    }
  );

  return (
    <Grid>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '28px',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '0.3px',
          color: '#212B36',
          marginBottom: '9px',
        }}
      >
        {t('Company info')}
      </Typography>
      <Divider light />
      {supplierData.companyLogo && (
        <CompanyLogoImage
          src={supplierData.companyLogo.fileUrl}
          style={{
            height: '60px',
            marginTop: '40px',
          }}
          alt="supplier-logo"
        />
      )}
      <Grid
        container
        spacing={2}
        sx={{ marginTop: supplierData.companyLogo ? '50px' : '30px' }}
      >
        <Grid item xs={12} lg={6}>
          <SelectList
            label="Supplier Type"
            value={supplierData.supplierType}
            dropdownData={dropdownDataDefaultProps.supplierTypes}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ViesInputCustom
            key={'vatNumber'}
            label={'VAT number'}
            value={supplierData.vatNumber}
            disabled={true}
          />
        </Grid>
        <Grid
          spacing={2}
          container
          sx={{ marginTop: '30px', marginLeft: '0px' }}
        >
          <Grid item xs={12} lg={6}>
            <TextFieldInput
              label="Company Official Name"
              value={supplierData.companyOfficialName}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldInput
              label="Company Short Name"
              value={supplierData.companyShortName}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldInput
              label="Slug"
              value={supplierData.slug.toLowerCase()}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid
          spacing={2}
          container
          sx={{ marginTop: '30px', marginLeft: '0px' }}
        >
          <Grid item xs={12} lg={4.5}>
            <SelectList
              label="Country"
              value={supplierData.countryId ?? ''}
              dropdownData={countries}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldInput
              label="Zip Code"
              value={supplierData.zipCode}
              type="number"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} lg={4.5}>
            <TextFieldInput
              label="City"
              value={supplierData.city}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid
          spacing={2}
          container
          sx={{ marginTop: '30px', marginLeft: '0px' }}
        >
          <Grid item xs={12} lg={12}>
            <TextFieldInput
              label="Address"
              value={supplierData.address}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid
          spacing={2}
          container
          sx={{ marginTop: '30px', marginLeft: '0px' }}
        >
          <Grid
            item
            xs={12}
            lg={12}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Checkbox disabled checked sx={{ marginRight: '5px' }} />
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.26)' }}>
              Company location is also central warehouse location
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SupplierClientCompanyInfo;
