import { Typography } from '@mui/material';
import { DropdownWrapper } from '../styles/OrderTabs.style';

export const PriceItem = ({
  title,
  price,
  color,
  titleColor,
  fontSize,
  bold = false,
  negativeValue = false,
}: {
  title: string;
  price: number | string;
  titleColor?: string;
  color?: string;
  fontSize?: string;
  bold?: boolean;
  negativeValue?: boolean;
}) => {
  return (
    <DropdownWrapper style={{ justifyContent: 'space-between' }}>
      <Typography
        variant="subtitle1"
        fontWeight={bold ? 600 : 400}
        color={titleColor}
        fontSize={fontSize ? fontSize : '16px'}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={bold ? 600 : 400}
        color={color}
      >
        {negativeValue && '-'}
        {price.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </Typography>
    </DropdownWrapper>
  );
};
