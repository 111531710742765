import React from 'react';
import { Branches } from './Branches';
import { Cart } from './Cart/Cart';
import { Details } from './Details/Details';
import { Search } from './Search';
import { Services } from './Services/Services';
import { Suppliers } from './Suppliers';
import { WheelDetailStep } from './WheelDetails/WheelDetailStep';

const FieldComponent: Record<string, () => JSX.Element> = {
  SEARCH: Search,
  DETAILS: Details,
  BRANCHES: Branches,
  SUPPLIERS: Suppliers,
  SERVICES: Services,
  CART: Cart,
  WHEEL_DETAILS: WheelDetailStep,
};

export const PosSteps = (step: string) => {
  if (Object.keys(FieldComponent).find((a) => a === step) === undefined) {
    console.log('Error - Step passed in is not expected!');
    return;
  }

  if (step in Object.keys(FieldComponent)) {
    console.log('Error - Step passed in is not expected!');
  }
  return React.createElement(FieldComponent[step]);
};
