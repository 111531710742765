import api from '@tyrio/api-factory';
import { WheelSizeApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetVehicleBrands = () => {
  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    ['get_brands'],
    () => api.fetch<WheelSizeApi['getBrands']>('get_brands'),

    {}
  );

  return {
    vehicleBrands: data?.data ?? [],
    isFetched,
    isError,
    isLoading,
    refetch,
  };
};
