import styled from '@emotion/styled';
import { tyreYear } from '@tyrio/ui-library';

export const YearlySales = () => {
  return (
    // TODO: Stats for year need to be implemented.
    //  As agreed, we just put image for now.
    <YearlySalesWrapper>
      <img src={tyreYear} alt="coming-soon" style={{ width: '90%' }} />
    </YearlySalesWrapper>
  );
};

const YearlySalesWrapper = styled.div`
  width: 100%;
`;
