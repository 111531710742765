import api from '@tyrio/api-factory';
import { ClientShippingCourierApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetShippingCouriers = () => {
  const { data, isFetching, isError } = useQuery(
    ['get_client_shipping_couriers'],
    () =>
      api.fetch<ClientShippingCourierApi['list']>(
        'get_client_shipping_couriers'
      ),

    {}
  );

  return { couriersData: data ?? [], isFetching, isError };
};
