import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
import {
  genericGenerateSpecialMarkings,
  splitSpecialMarkingString,
} from './operations.helpers';

export function generateGoodyearSpecialMarkings(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateContinentalSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = [input['PROD_INFO'], input['RUN_FLAT']].join(' ');

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => ['DSROF', 'ROF', 'DSST', 'EMT'].includes(input['RUN_FLAT']),
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }
  const splitSpecialMarkings = splitSpecialMarkingString(
    data.product['specialMarking']
  );

  if (input['FLANK'] === 'Y') {
    splitSpecialMarkings.push('FR');
  }

  data.product['specialMarking'] = [...new Set(splitSpecialMarkings)]
    .join(' ')
    .replace(/ +(?= )/g, '');

  return data;
}
