import React, { useContext } from 'react';
import { Divider, Grid, TextField } from '@mui/material';
import { PriceComparisonContext } from '../context';

export const OrderEmailFields = ({
  email,
  clientSupplierId,
}: {
  email: string;
  clientSupplierId: string;
}) => {
  const ctx = useContext(PriceComparisonContext);
  return (
    <>
      <Divider textAlign={'left'} sx={{ marginTop: 1, marginBottom: 1 }}>
        Email
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="To"
            disabled
            InputLabelProps={{ shrink: true }}
            value={email}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Cc"
            InputLabelProps={{ shrink: true }}
            value={ctx?.data?.ccEmail?.[clientSupplierId]}
            onChange={(e) => {
              ctx.setInputValue({
                ccEmail: {
                  ...(ctx.data.ccEmail ?? {}),
                  [clientSupplierId]: e.target.value,
                },
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
