import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { DBTransferOrderItem } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBOrderDocumentApi,
  DBTransferOrderBranchDetails,
  DBTransferOrderStatusEnum,
  OrderTypesEnum,
  PdfApi,
} from '@tyrio/dto';
import { startCase } from 'lodash';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import {
  TabPanelProps,
  tabProps,
  TabsStyled,
  TabStyled,
} from '../../../components/Tabs/Tabs';
import UploadDocuments from '../../../components/UploadDocuments/UploadDocuments';
import { useAuth } from '../../../context/AuthContext';
import { getTransferOrderStatusColor } from '../../../features/purchase-orders/helpers/generate-color';
import { OrderStatus } from '../../../features/purchase-orders/PurchaseOrderTable';
import { useDocuments } from '../../../hooks/useDocuments';
import { TrackingTabs } from '../../customer-orders/tabs/TrackingTabs';
import { parseStatus } from '../helper/status-helper';
import OrderDetailsTab from './TransferOrderDetailsTab';

interface TransferOrderDetailsSidebarTabsProps {
  selectedOrderData: DBTransferOrderItem | null;
  originalOrderData: DBTransferOrderItem | null;
  setSelectedOrderData: (item: DBTransferOrderItem | null) => void;
  setIsUploadInProcess: (item: boolean) => void;
}

const TransferOrderDetailsSidebarTabs = ({
  selectedOrderData,
  originalOrderData,
  setSelectedOrderData,
  setIsUploadInProcess,
}: TransferOrderDetailsSidebarTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const { clientId } = useAuth();

  // get order documents
  const { data: orderDocuments, refetch } = useQuery(
    ['get_order_documents', selectedOrderData?.id],
    () =>
      api.fetch<DBOrderDocumentApi['list']>(`get_order_documents`, {
        id: selectedOrderData?.id,
        type: 'transfer',
      }),
    {
      enabled: !!selectedOrderData?.id,
    }
  );

  const { uploadOrderDocuments, deleteOrderDocument } = useDocuments(
    selectedOrderData,
    refetch,
    setIsUploadInProcess,
    'Transfer'
  );

  const onOpen = useCallback(
    (acceptedFiles: File[]) => {
      setIsUploadInProcess(true);
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append('file', file, file.name));
      uploadOrderDocuments.mutate({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@@apiToken')}`,
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
          type: 'transfer',
        },
        data: formData,
      });
    },
    [setIsUploadInProcess, uploadOrderDocuments]
  );

  const onDelete = (id: string) => {
    deleteOrderDocument.mutate(id);
  };

  const { data } = useQuery(
    [
      'get_generated_pdf',
      `generated-pdf-${clientId}-transfer-order-${selectedOrderData?.id}`,
    ],
    async () => {
      return await api.fetch<PdfApi['getOne']>('get_generated_pdf', {
        key: `generated-pdf-${clientId}=transfer-order=${selectedOrderData?.id}=delivery-note=${selectedOrderData?.orderNumber}`,
      });
    },
    {
      enabled: selectedOrderData !== null,
    }
  );

  return (
    selectedOrderData && (
      <Container>
        <Wrapper>
          <TabsWrapper>
            <TabsStyled value={activeTab} onChange={(_e, v) => setActiveTab(v)}>
              <TabStyled label="Order" {...tabProps(0)} />
              <TabStyled label="Tracking" {...tabProps(0)} />
              <TabStyled label="Related documents" {...tabProps(0)} />
            </TabsStyled>
            <StatusPrinterWrapper>
              <OrderStatus
                color={
                  getTransferOrderStatusColor[
                    parseStatus(selectedOrderData.orderStatus).status
                  ]
                }
                textColor={
                  parseStatus(selectedOrderData.orderStatus).status !==
                  DBTransferOrderStatusEnum.NEW
                    ? 'white'
                    : 'gray'
                }
                border={
                  parseStatus(selectedOrderData.orderStatus).status !==
                  DBTransferOrderStatusEnum.NEW
                    ? false
                    : true
                }
                style={{ fontSize: '12px' }}
              >
                {startCase(parseStatus(selectedOrderData.orderStatus).status)}
              </OrderStatus>
            </StatusPrinterWrapper>
          </TabsWrapper>

          <TabContent value={activeTab} index={0}>
            <OrderDetailsTab
              selectedOrderData={selectedOrderData}
              originalOrderData={originalOrderData}
              setSelectedOrderData={setSelectedOrderData}
            />
          </TabContent>

          <TabContent value={activeTab} index={1}>
            <TrackingTabs
              zipCode={
                (
                  selectedOrderData?.outboundBranchDetails as unknown as DBTransferOrderBranchDetails
                )?.zipCode || null
              }
              orderId={selectedOrderData?.id}
              orderType={OrderTypesEnum.TRANSFER_ORDER}
            />
          </TabContent>

          <TabContent value={activeTab} index={2}>
            <UploadDocuments
              orderDocuments={orderDocuments}
              onOpen={onOpen}
              onDelete={onDelete}
              isLoading={uploadOrderDocuments.isLoading}
              generatedPdf={data}
            />
          </TabContent>
        </Wrapper>
      </Container>
    )
  );
};

export const TabContent = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Grid
      hidden={value !== index}
      {...other}
      style={{ height: 'calc(100% - 160px)', overflowY: 'auto' }}
    >
      {value === index && (
        <Box sx={{ paddingTop: '16px', height: '100%' }}>{children}</Box>
      )}
    </Grid>
  );
};

export const Container = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 16px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: 1;
  width: 100%;
  border-color: divider;
`;

export const TabsWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1;
  border-color: divider;
  position: relative;
`;

const StatusPrinterWrapper = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  height: 50px;
  align-items: center;
`;

export default TransferOrderDetailsSidebarTabs;
