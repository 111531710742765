export const usePagination = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  pageSize: number,
  page: number
) => {
  const begin = (page - 1) * pageSize;
  const end = begin + pageSize;
  return data.slice(begin, end);
};
