import api from '@tyrio/api-factory';
import { DBWarehouseSettingsApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

interface GetWarehousesProps {
  id: string;
}

export const useGetWarehouse = (props: GetWarehousesProps) => {
  const { id } = props;

  const getLocationDetails = useQuery(
    ['warehouse_details', { id }],
    async () => {
      return await api.fetch<DBWarehouseSettingsApi['getOne']>(
        'warehouse_details',
        {
          id,
        }
      );
    },
    {
      enabled: !!id,
    }
  );

  return { getLocationDetails };
};
