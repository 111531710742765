import * as z from "zod"
import { CompleteDBWorkOrders, RelatedDBWorkOrdersModel } from "./index"

export const DBWorkOrdersNumberModel = z.object({
  id: z.string(),
  orderNumber: z.number().int(),
})

export interface CompleteDBWorkOrdersNumber extends z.infer<typeof DBWorkOrdersNumberModel> {
  workOrder: CompleteDBWorkOrders[]
}

/**
 * RelatedDBWorkOrdersNumberModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWorkOrdersNumberModel: z.ZodSchema<CompleteDBWorkOrdersNumber> = z.lazy(() => DBWorkOrdersNumberModel.extend({
  workOrder: RelatedDBWorkOrdersModel.array(),
}))
