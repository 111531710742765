import api from '@tyrio/api-factory';
import { DBAppointmentApi, FittingBoxesResData } from '@tyrio/dto';
import moment from 'moment';
import { useQuery } from 'react-query';

interface GetAppointmentsProps {
  date: Date;
  boxes?: FittingBoxesResData[];
}

export const useGetAppointments = (props: GetAppointmentsProps) => {
  const { boxes, date } = props;
  const boxesIds = boxes?.map((item) => item.shelfId);

  const query = useQuery(
    [boxesIds, date],
    () =>
      api.fetch<DBAppointmentApi['all']>('getAllAppointments', {
        date: moment(date).add(1, 'hour').toString(),
        boxes: boxesIds ?? [],
      }),
    {}
  );

  return { ...query };
};
