import { Divider } from '@mui/material';
import { DBTransferOrderType } from '@prisma/client';
import { DBBranchStockListApi } from '@tyrio/dto';
import { UnselectedRowWarning, wmsIcons } from '@tyrio/wms-ui-library';
import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { StockListContext } from '../../../../context/StockListContext';
import { InboundOutboundOrders } from '../InboundOrders/InboundOrders';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { useGetBranchesStock } from './queries/get-branches-stock-list';
import { SingleStorage } from './SingleStorage';

export const Branches = () => {
  const { filterValues, selectedTableRow } = useContext(StockListContext);

  const warehouseId = filterValues?.['warehouseId'] as string;

  const { branchesStock, refetchBranchesStock } = useGetBranchesStock(
    selectedTableRow?.ean ?? '',
    warehouseId
  );

  useEffect(() => {
    refetchBranchesStock();
  }, [refetchBranchesStock, selectedTableRow, warehouseId]);

  const renderBranches = () => {
    if (isEmpty(branchesStock)) {
      return (
        <UnselectedRowWarning
          icon={wmsIcons.inbound}
          emptyData="No available items for this product."
        />
      );
    }
    if (
      selectedTableRow !== null &&
      selectedTableRow?.branchId === Number(warehouseId)
    ) {
      return branchesStock?.map(
        (m: DBBranchStockListApi['getOne']['response'], index: number) => {
          return (
            <div key={index}>
              <SingleStorage
                branchName={m.branchName}
                branchId={m.branchId}
                branchCity={m.branchCity}
                item={m.lineItems}
                key={m.stockLocationId + index}
                stockLocationId={m.stockLocationId}
              />
              {index !== branchesStock.length - 1 && (
                <Divider
                  variant="fullWidth"
                  sx={{ borderWidth: '2px', borderColor: '#E6E8F0' }}
                  key={index}
                />
              )}
              {index === branchesStock.length - 1 && (
                <InboundOutboundOrders
                  type={DBTransferOrderType.INBOUND}
                  key={m.branchId}
                />
              )}
            </div>
          );
        }
      );
    } else
      return (
        <UnselectedRowWarning
          icon={wmsIcons.inbound}
          text="branches stock list"
        />
      );
  };

  return (
    <SideMenuLayout
      type="inbound"
      children={renderBranches()}
      showSwitch={false}
      checked={false}
      viewBox={'0 0 20 19'}
    />
  );
};
