import { StorageType, TimerContextShape } from './TimerContext';

export class Timer {
  private timerContext: TimerContextShape;
  private storageType: StorageType;
  displayTime: { minutes: number | null; seconds: number | null };

  constructor(timerContext: TimerContextShape, storageType: StorageType) {
    this.timerContext = timerContext;
    this.storageType = storageType;
    this.displayTime = timerContext.getDisplayTime(storageType) ?? {
      minutes: 10,
      seconds: 0,
    };
  }

  clear = () => {
    this.timerContext.clearTimer(this.storageType);
  };

  restart = () => {
    this.timerContext.restartTimer(this.storageType);
  };

  getDisplayTime = () => {
    return this.displayTime;
  };
}
