import * as z from "zod"
import { CompleteDBBranch, RelatedDBBranchModel, CompleteDBClient, RelatedDBClientModel, CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBProduct, RelatedDBProductModel, CompleteDBReservationsItems, RelatedDBReservationsItemsModel, CompleteDBStockListItemLocation, RelatedDBStockListItemLocationModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBClientStockListModel = z.object({
  id: z.string(),
  branchId: z.number().int(),
  clientId: z.string(),
  warehouseAreaId: z.string(),
  ean: z.string(),
  ipc: z.string(),
  sku: z.string(),
  productId: z.string(),
  productionYear: z.string().nullish(),
  dot: z.string().nullish(),
  quantity: z.number().int(),
  reserved: z.number().int(),
  upcoming: z.number().int(),
  putaway: z.number().int(),
  demo: z.boolean(),
  favoritedBy: z.string().array(),
  price: z.number(),
  calculatedPrice: jsonSchema,
})

export interface CompleteDBClientStockList extends z.infer<typeof DBClientStockListModel> {
  branch: CompleteDBBranch
  client: CompleteDBClient
  warehouseArea: CompleteDBBranchWarehouseAreas
  product: CompleteDBProduct
  cartReservations: CompleteDBReservationsItems[]
  location: CompleteDBStockListItemLocation[]
}

/**
 * RelatedDBClientStockListModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientStockListModel: z.ZodSchema<CompleteDBClientStockList> = z.lazy(() => DBClientStockListModel.extend({
  branch: RelatedDBBranchModel,
  client: RelatedDBClientModel,
  warehouseArea: RelatedDBBranchWarehouseAreasModel,
  product: RelatedDBProductModel,
  cartReservations: RelatedDBReservationsItemsModel.array(),
  location: RelatedDBStockListItemLocationModel.array(),
}))
