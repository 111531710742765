import { FormShape } from '../../services/constants/service.form';
import { ClientShippingResponse } from '@tyrio/dto';
import { DBDeliveryLocation, DeliveryType } from '@prisma/client';
import { parseEnumToInputOption } from '../../../../../helpers/enum-parser';

export const DBDeliveryTypeEnum = {
  WMS_SHIPPING: 'Parcel shipping',
  WMS_COLLECTION: 'In-store pickup',
  WMS_DISPATCH: 'Local dispatch',
  WMS_FITTING: 'Fitting station',
};

function parseDeliveryTypes(e: Record<string, string>) {
  return Object.entries(e).map(([key, value]) => {
    return { label: value, value: key };
  });
}
export const DeliveryMethodFormShape = (
  couriers: ClientShippingResponse[],
  selectedDeliveryType: DeliveryType
) => {
  const deliveryType = parseDeliveryTypes(DBDeliveryTypeEnum);
  const deliveryLocations = parseEnumToInputOption(DBDeliveryLocation);

  const mainForm: FormShape[] = [
    {
      key: 'code_name',
      inputs: [
        {
          id: 'code',
          width: '20%',
          type: 'input.string',
          label: 'Code *',
          hasPadding: false,
        },
        {
          id: 'sortOrder',
          width: '15%',
          type: 'input.string',
          label: 'Sort order',
          hasPadding: false,
        },
        {
          id: 'name',
          width: 'calc(65% - 32px)',
          type: 'input.string',
          label: 'Name *',
          hasPadding: false,
        },
      ],
    },
    {
      inputs: [
        {
          id: 'description',
          width: '100%',
          type: 'input.string',
          label: 'Description',
          hasPadding: false,
        },
      ],
    },
    {
      key: 'delivery_type',
      inputs: [
        {
          id: 'deliveryTypeEnum',
          width: '25%',
          type: 'input.select',
          options: deliveryType,
          label: 'Delivery type *',
          hasPadding: false,
        },
        {
          id: 'deliveryLocation',
          width: '20%',
          type: 'input.select',
          options: deliveryLocations,
          label: 'Delivery type *',
          hasPadding: false,
          skipField: selectedDeliveryType !== 'WMS_SHIPPING',
        },
        {
          id: 'dbDeliveryCouriers',
          width: 'calc(55% - 32px)',
          type: 'input.multiselect',
          options: couriers.map((item: ClientShippingResponse) => {
            return { label: item.shippingCourier.abbreviation, value: item.id };
          }),
          label: 'Choose couriers',
          hasPadding: false,
          skipField: selectedDeliveryType !== 'WMS_SHIPPING',
        },
      ],
    },
  ];

  return {
    form: mainForm,
  };
};
