import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Pagination,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DBStockListImportLogRes, ImportSettings } from '@tyrio/dto';
import { backIcon } from '@tyrio/ui-library';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageTemplateWrapper } from '../../components/PageTemplate/PageTemplate';
import {
  BackButton,
  HeaderText,
  HeaderWrapperTop,
} from '../purchase-orders/PurchaseOrderOverview';
import { ImportStockList } from '../stock-list/components/modals/ImportStockList';
import { useGetFtpLogs } from './queries/get-ftp-import-logs';
import { useGetFtpSettings } from './queries/get-ftp-settings';
import { useUpdateFtpSettings } from './queries/update-ftp-settings';
import { useGetLogsById } from './queries/get-logs-by-id';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ReactJson from 'react-json-view';

const defaultFtpSettings: ImportSettings = {
  settings: {
    type: 'ftp',
    isActive: true,
  },
  ftp: {
    host: '',
    path: '',
    port: 21,
    username: '',
    password: '',
  },
};

type MuiColorType =
  | 'error'
  | 'success'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'default'
  | undefined;

export const StockListImport = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importSettings, setImportSettings] =
    useState<ImportSettings>(defaultFtpSettings);

  const { mutate } = useUpdateFtpSettings();

  const { data: logs, status } = useGetFtpLogs(page);
  useGetFtpSettings(setImportSettings);

  const onChangeFtp = (att: string, val: string | number | boolean) => {
    setImportSettings({
      ...importSettings,
      ftp: {
        ...importSettings.ftp,
        [att]: val,
      },
    });
  };

  const onChangeSettings = (att: string, val: string | number | boolean) => {
    setImportSettings({
      ...importSettings,
      settings: {
        ...importSettings.settings,
        [att]: val,
      },
    });
  };

  const pageSize = 5;

  const totalPageNumber = logs?.count
    ? logs?.count / pageSize - Math.floor(logs?.count / pageSize) === 0
      ? logs?.count / pageSize
      : Math.floor(logs?.count / pageSize) + 1
    : 1;

  return (
    <PageTemplateWrapper
      style={{
        justifyContent: 'space-between',
        right: 0,
        position: 'absolute',
      }}
    >
      <Container>
        {isImportModalOpen && (
          <ImportStockList
            isOpen={isImportModalOpen}
            setOpen={setIsImportModalOpen}
          />
        )}
        <HeaderWrapperTop>
          <HeaderContent>
            <BackButtonWrapper>
              <BackButton onClick={() => history.push('/dashboard')}>
                <img src={backIcon} alt="back-icon" />
              </BackButton>
              <HeaderText>Stock list import</HeaderText>
            </BackButtonWrapper>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    data-cy="switch"
                    color="info"
                    onChange={(e) => {
                      onChangeSettings('isActive', e.target.checked);
                    }}
                    value={importSettings.settings?.isActive}
                    checked={importSettings.settings?.isActive}
                    disabled={false}
                  />
                }
                label="Active"
              />
              <Button
                title="Import"
                color="info"
                variant="text"
                onClick={() => {
                  setIsImportModalOpen(true);
                }}
              >
                IMPORT
              </Button>
            </Box>
          </HeaderContent>
        </HeaderWrapperTop>
        <ContentContainer>
          <Grid container>
            <FormControl>
              <FormLabel>Import type</FormLabel>
              <RadioGroup row defaultValue={importSettings.settings?.type}>
                <FormControlLabel
                  value="ftp"
                  control={
                    <Radio
                      checked={importSettings.settings?.type === 'ftp'}
                      onChange={() => {
                        onChangeSettings('type', 'ftp');
                      }}
                    />
                  }
                  label="FTP"
                />
                <FormControlLabel
                  value="api"
                  control={
                    <Radio
                      checked={importSettings.settings?.type === 'api'}
                      onChange={() => {
                        onChangeSettings('type', 'api');
                      }}
                    />
                  }
                  label="API"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {importSettings.settings?.type === 'ftp' && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="FTP host"
                    variant="outlined"
                    value={importSettings.ftp?.host}
                    onChange={(e) => {
                      onChangeFtp('host', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="FTP path"
                    variant="outlined"
                    value={importSettings.ftp?.path}
                    onChange={(e) => {
                      onChangeFtp('path', e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginTop={1}>
                <Grid item xs={4}>
                  <TextField
                    label="Username"
                    variant="outlined"
                    value={importSettings.ftp?.username}
                    onChange={(e) => {
                      onChangeFtp('username', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    value={importSettings.ftp?.password}
                    onChange={(e) => {
                      onChangeFtp('password', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Port"
                    variant="outlined"
                    type="number"
                    value={importSettings.ftp?.port}
                    onChange={(e) => {
                      onChangeFtp('port', e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {importSettings.settings?.type === 'api' && (
            <Box>
              <Alert severity="info">
                <AlertTitle>API import</AlertTitle>
                You can change your RITAM settings in general settings.
              </Alert>
            </Box>
          )}

          <Grid container spacing={2} marginTop={1}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  mutate(importSettings);
                }}
              >
                SAVE
              </Button>
            </Grid>
          </Grid>

          <Grid container marginTop={2}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Started at</TableCell>
                    <TableCell align="left">Finished at</TableCell>
                    <TableCell align="left">Requested</TableCell>
                    <TableCell align="left">Imported</TableCell>
                    <TableCell align="left">Errors</TableCell>
                    <TableCell align="left">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status === 'success' &&
                    logs?.data.map((row) => <Row key={row.id} row={row} />)}
                  {status === 'loading' && (
                    <CircularProgress
                      style={{ height: '15px', width: '15px' }}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container>
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={totalPageNumber}
                sx={{
                  marginTop: '30px',
                  backgroundColor: 'white',
                  display: 'flex',
                }}
                onChange={(_e: ChangeEvent<unknown>, page: number) => {
                  setPage(page);
                }}
                page={page}
              />
            </Grid>
          </Grid>
        </ContentContainer>
      </Container>
    </PageTemplateWrapper>
  );
};

function Row(props: { row: DBStockListImportLogRes }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const { mutate, status, data } = useGetLogsById();

  const getStatus = () => {
    const status = {
      text: 'done',
      color: 'success',
    };

    if (row.status === 'STARTED' && !row.finishedAt) {
      status.text = 'error';
      status.color = 'error';
    }

    if (row.status === 'STARTED' && row.errors > 0) {
      status.text = 'import errors';
      status.color = 'warning';
    }

    return status;
  };

  const { text, color } = getStatus();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!open) mutate({ id: row.id });
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {moment(row.startedAt).format('dddd, DD.MM.YYYY')}
        </TableCell>
        <TableCell align="left">
          {moment(row.startedAt).format('hh:mm:ss')}
        </TableCell>
        <TableCell align="left">
          {moment(row.finishedAt).format('hh:mm:ss')}
        </TableCell>
        <TableCell align="left">{row.request}</TableCell>
        <TableCell align="left">{row.response}</TableCell>
        <TableCell align="left">{row.errors}</TableCell>
        <TableCell align="left">
          <Chip label={text} color={color as MuiColorType} size="small" />
        </TableCell>
      </TableRow>
      {status === 'success' && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <code>
                  <ReactJson
                    iconStyle="triangle"
                    collapsed={true}
                    src={{
                      ftpInfo: data.ftpInfo,
                      requested: _.keyBy(data.request as [], 'Ean'),
                      imported: _.keyBy(data.response as [], 'ean'),
                      errors: data.errors,
                    }}
                  />
                </code>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100svh - 44px);
  border-radius: 8px;
  width: 100%;
  transition: linear 0.4s;
  position: relative;
  left: 0;
`;

export const ContentContainer = styled.div`
  padding: 16px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const BackButtonWrapper = styled.div``;
