import styled from '@emotion/styled/macro';
import { WheelsCard } from './WheelsCard';
import {
  defaultFilterValue,
  useVehicleCtx,
} from '../../../../context/VehicleContext';
import { debounce, get, isEmpty, isEqual, isNull, merge, uniq } from 'lodash';
import { useGetWheels } from './query/get-wheels';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePosCtx } from '../../../../context/POSContext';
import NoData from '../DataIndicators/NoData';
import { LandingPage, tyrioIcons } from '@tyrio/ui-library';
import { CircularProgress } from '@mui/material';
import { FilterDetails } from './Filter/FilterDetails';
import { usePrevious } from '../../../../hooks/usePrevious';
import { IFilterData, SearchWheelsResponse } from '@tyrio/dto';
import { Info } from './components/Info';

interface WheelSearchPageProps {
  onOpenSidebarFilters: () => void;
  warehouseId?: string;
}

export const WheelsSearchPage = ({
  onOpenSidebarFilters,
  warehouseId,
}: WheelSearchPageProps) => {
  // CONTEXT
  const {
    filter,
    setSelectedWheel,
    selectedWheel,
    backendFilterData,
    setPosWheelsFilterData,
    setSelectedProduct,
    setDetailsFilter,
    apiData,
    posWheelsFilterData,
    page,
    setPage,
    setSelectedEt,
    wheelsData,
    setWheelsData,
  } = useVehicleCtx();

  const { setActiveStep, setBadgeContent } = usePosCtx();

  // DATA
  const divRef = useRef<HTMLDivElement>(null);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [isPageBottom, setIsPageBottom] = useState(false);

  const pageSize = 30;

  // Previous filter state
  const prevFilter = usePrevious(filter);
  const prevWarehouseId = usePrevious(warehouseId);

  // HOOKS
  const hasFilters = useMemo(() => {
    const hasValue = Object.values(backendFilterData ?? []).every(
      (b) => !isEmpty(b)
    );
    return backendFilterData !== null && hasValue;
  }, [backendFilterData]);

  const onSuccess = (
    data: SearchWheelsResponse[],
    filterData?: IFilterData
  ) => {
    const shouldSaveData = !isEqual(filter, defaultFilterValue);

    setShowPageLoader(false);

    if (page === 1) {
      setWheelsData([...data]);
      if (shouldSaveData && filterData) {
        setPosWheelsFilterData(filterData);
      }
    } else {
      setWheelsData([...(wheelsData ?? []), ...data]);
      if (shouldSaveData && filterData) {
        const res = merge(posWheelsFilterData, filterData);
        setPosWheelsFilterData(res);
      }
    }
  };

  // QUERY - WHEEL DATA
  const {
    isFetched,
    refetch,
    hasNextPage,
    showLoader,

    status,
  } = useGetWheels(
    onSuccess,
    page,
    pageSize,
    get(apiData, 'cb', ''),
    get(apiData, 'pcd', ''),
    uniq(apiData?.et) ?? [],
    filter,
    apiData !== null && hasFilters,
    warehouseId
  );

  const isQueryEnabled = apiData !== null && hasFilters;

  useEffect(() => {
    if (!isEqual(filter, defaultFilterValue) || warehouseId || page) {
      const filterValueChanged = !isEqual(filter, prevFilter);
      if (
        page !== 1 &&
        (filterValueChanged || !isEqual(prevWarehouseId, warehouseId))
      ) {
        // makes sure to reset page every time filter or warehouse is changed
        setPage(1);
      } else {
        if (apiData !== null && hasFilters) {
          if (filterValueChanged) setShowPageLoader(true);

          // refetch data
          refetch();
        }
      }
    }
  }, [
    filter,
    refetch,
    warehouseId,
    page,
    prevFilter,
    prevWarehouseId,
    apiData,
    hasFilters,
    setPage,
  ]);

  // FUNCTIONS

  const scroll = () => {
    if (divRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 200 && !hasNextPage)
        setIsPageBottom(true);

      if (Math.round(scrollTop + clientHeight) >= scrollHeight * 0.7) {
        if (hasNextPage && !showLoader) {
          setPage(page + 1);
        }
      }
    }
  };

  const handleScroll = debounce(() => {
    scroll();
  }, 200);

  const scrollToTop = () => {
    const div = document.getElementById('content_wrapper');
    div?.scroll({ top: 0, behavior: 'smooth' });
    setIsPageBottom(false);
  };

  // LANDING PAGE
  if (apiData === null || !isQueryEnabled) {
    return (
      <LandingPage
        icon={tyrioIcons.menuIcon3}
        title={'Welcome to POS'}
        subtitle={'Choose product category on right side'}
        cyId={'create-new-pos-landing'}
        buttonIcon={tyrioIcons.menuIcon5}
      />
    );
  }

  if (!hasFilters && backendFilterData !== null) {
    return (
      <NoDataWrapper
        style={{
          width: '100%',
          margin: '50px auto',
          padding: '16px',
        }}
      >
        <NoData
          title="No products available for your current filter settings"
          subtitle="Please choose different filter options"
          color="warning"
        />
      </NoDataWrapper>
    );
  }

  if ((!isFetched && showLoader) || status === 'idle' || !isQueryEnabled) {
    return (
      <LoadWrapper>
        <CircularProgress />
      </LoadWrapper>
    );
  }

  // PAGE WITH DATA
  return (
    <WheelsSearchWrapper id="wheels_content">
      <FilterDetails onOpen={onOpenSidebarFilters} />
      {showLoader && showPageLoader && (
        <LoadWrapper>
          <CircularProgress />
        </LoadWrapper>
      )}
      {isQueryEnabled && !showLoader && isEmpty(wheelsData) ? (
        <NoDataWrapper>
          <NoData
            title="No products available for your current filter settings"
            subtitle="Please choose different filter options"
            color="warning"
          />
        </NoDataWrapper>
      ) : !isNull(wheelsData) ? (
        <MainWrapper>
          <ContentWrapper
            ref={divRef}
            onScroll={handleScroll}
            id="content_wrapper"
          >
            {wheelsData.map((m, idx) => (
              <WheelsCard
                brand={m.model.brand}
                color={m.model.color}
                rimSizes={m.availableRimSizes.sort()}
                stockListRims={m.stockListRimSizes}
                price={m.price}
                modelName={m.model.modelName}
                photos={m.model.photos}
                key={idx}
                isSelected={isEqual(m, selectedWheel)}
                onClick={() => {
                  setSelectedWheel(m);
                  setActiveStep('WHEEL_DETAILS');
                  setSelectedProduct(null);
                  setSelectedEt(null);
                  setDetailsFilter({ rimDiameter: [] });
                  setBadgeContent((prevState) => ({
                    ...prevState,
                    SUPPLIERS: 0,
                    BRANCHES: 0,
                  }));
                }}
              />
            ))}
          </ContentWrapper>
          {showLoader && !showPageLoader && (
            <ScrollLoaderWrapper>
              <CircularProgress size={20} />
            </ScrollLoaderWrapper>
          )}
          {isPageBottom && !hasNextPage && (
            <Info
              title="No more data to load. Click to scroll to top!"
              onClick={scrollToTop}
            />
          )}
        </MainWrapper>
      ) : showLoader ? (
        <LoadWrapper>
          <CircularProgress />
        </LoadWrapper>
      ) : null}
    </WheelsSearchWrapper>
  );
};

const WheelsSearchWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 16px;
  height: 100%;
  max-height: calc(100svh - 104px);

  @media (max-width: 1630px) {
    max-height: calc(100svh - 164px);
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 16px;
  overflow-y: scroll;
  // height: calc(100svh - 110px);
  height: calc(100% - 30px);
  align-content: flex-start;
`;

const NoDataWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LoadWrapper = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  display: flex;

  position: absolute;
  height: 100%;
  width: calc(100%);
  background: #dfe3e894;
  top: 0;
  left: 0px;
  right: -32px;
  border-radius: 16px;
`;

const ScrollLoaderWrapper = styled.div`
  padding: 10px 16px;
  gap: 10px;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 30px;
`;
