import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { ProductsTableProperties } from '../../../../context/PrintContext';
import { JustifyBetween } from '../MainInfo';
import { JustifyCenter } from '../PdfFooter';
import './../../style.css';

export const TableHeader = ({
  tableProps,
}: {
  tableProps: ProductsTableProperties;
}) => {
  const width = tableProps.columnCount * 66;
  return (
    <Wrapper className="confirmation-header">
      <Text style={{ width: '30px' }}>#</Text>
      <ProductDetailsWrapper>
        <Text>Product name</Text>
      </ProductDetailsWrapper>
      <JustifyBetween style={{ width }}>
        {tableProps.showWeight && (
          <JustifyCenter>
            <Text>Weight kg</Text>
          </JustifyCenter>
        )}

        {tableProps.showQuantity && (
          <JustifyCenter>
            <Text>Qty</Text>
          </JustifyCenter>
        )}

        {tableProps.showTotalWeight && (
          <JustifyCenter>
            <Text>Total weight</Text>
          </JustifyCenter>
        )}
      </JustifyBetween>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 30px;
  width: 100%;
  background: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 8px;
`;

const ProductDetailsWrapper = styled.div`
  width: calc(100% - 200px);
  display: flex;
  justify-content: center;
`;

const Text = styled(Typography)`
  color: var(--Primary-Black-black-90, #454f5b);
  font-family: Barlow;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
`;
