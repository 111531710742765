import * as z from "zod"
import { DBTimeZone, DBLanguage, DBCharacterType, DBCurrency, DBDecimalSeparator } from ".prisma/client"
import { CompleteDBAddress, RelatedDBAddressModel, CompleteDBSupplier, RelatedDBSupplierModel, CompleteDBSupplierLocation, RelatedDBSupplierLocationModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBPartner, RelatedDBPartnerModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBCountryModel = z.object({
  id: z.string(),
  name: z.string(),
  native_name: z.string(),
  time_zone: z.nativeEnum(DBTimeZone),
  iso_code: z.string(),
  intrastat_code: z.string(),
  calling_code: z.number().int(),
  TLD: z.string(),
  language: z.nativeEnum(DBLanguage),
  character_type: z.nativeEnum(DBCharacterType),
  currency: z.nativeEnum(DBCurrency),
  decimal_separator: z.nativeEnum(DBDecimalSeparator),
  remark: z.string(),
  vatRates: jsonSchema,
})

export interface CompleteDBCountry extends z.infer<typeof DBCountryModel> {
  DBAddress: CompleteDBAddress[]
  DBSupplier: CompleteDBSupplier[]
  DBSupplierLocation: CompleteDBSupplierLocation[]
  branches: CompleteDBBranch[]
  DBPartner: CompleteDBPartner[]
}

/**
 * RelatedDBCountryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBCountryModel: z.ZodSchema<CompleteDBCountry> = z.lazy(() => DBCountryModel.extend({
  DBAddress: RelatedDBAddressModel.array(),
  DBSupplier: RelatedDBSupplierModel.array(),
  DBSupplierLocation: RelatedDBSupplierLocationModel.array(),
  branches: RelatedDBBranchModel.array(),
  DBPartner: RelatedDBPartnerModel.array(),
}))
