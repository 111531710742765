import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { INTERTYRE_CATALOG_RULESET } from './intertyre/catalog';
import { INTERTYRE_PRICELIST_RULESET } from './intertyre/pricelist';
import { INTERTYRE_STOCKLIST_RULESET } from './intertyre/stocklist';

export const INTERTYRE_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: INTERTYRE_CATALOG_RULESET,
  pricelistRuleset: INTERTYRE_PRICELIST_RULESET,
  stockRuleset: INTERTYRE_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'INTERTYRE',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    available: {
      '> ': '',
    },
    'nett-price': {
      ',': '.',
    },
    weight: {
      ',': '.',
    },
    PCD: {
      '098': '98',
    },
    centerhole: {
      ',': '.',
    },
    width: {
      ',': '.',
    },
    'brand description': {
      'AVUS VELGEN': 'AVUS',
      'MONACO WHEELS 2': 'MONACO WHEELS',
      'IT WHEELS 2': 'IT WHEELS',
      'RONAL 2': 'RONAL',
      'AANHANGER STAAL': 'AANHANGER',
      'MW STEEL': 'MW',
      'KRONPRINZ STEEL': 'KRONPRINZ',
    },
    colour: {
      '-kleur-ts-': 'Silver',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        ['VELGEN', 'VELGEN STAAL'].includes(item['group description']) &&
        !item['brand description'].includes('ORIGINAL EQUIPMENT')
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        ['VELGEN', 'VELGEN STAAL'].includes(item['group description']) &&
        !item['brand description'].includes('ORIGINAL EQUIPMENT')
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        ['VELGEN', 'VELGEN STAAL'].includes(item['group description']) &&
        !item['brand description'].includes('ORIGINAL EQUIPMENT')
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
