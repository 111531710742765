import api from '@tyrio/api-factory';
import { DBDeliveryTypesApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';

export const useUpdateDeliveryMethod = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_delivery_method'],
    (body: DBDeliveryTypesApi['updateOne']['requestBody']) =>
      api.fetch<DBDeliveryTypesApi['updateOne']>('update_delivery_method', {
        ...body,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_delivery_method');
        queryClient.refetchQueries('get_client_delivery_types');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Error',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occured.'
          );
        }
        throw error;
      },
    }
  );

  return { data, updateDeliveryMethod: mutate };
};
