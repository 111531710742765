import api from '@tyrio/api-factory';
import { DBServiceCategoryApi, DBServicesApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';

// UPDATE MAIN CATEGORY ACTIVE STATUS
export const useUpdateActiveServiceCategory = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_service_category_active_status'],
    (body: DBServiceCategoryApi['activeStatus']['requestBody']) =>
      api.fetch<DBServiceCategoryApi['activeStatus']>(
        'update_service_category_active_status',
        {
          ...body,
        }
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_service_category_by_id');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateActiveServiceCategory: mutate };
};

// UPDATE SINGLE SERVICE ACTIVE STATUS
export const useUpdateActiveService = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_service_active_status'],
    (body: DBServicesApi['activeStatus']['requestBody']) =>
      api.fetch<DBServicesApi['activeStatus']>('update_service_active_status', {
        ...body,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_service_by_id');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateActiveService: mutate };
};
