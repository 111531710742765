import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { tyrioIcons } from '@tyrio/ui-library';

const typeToText = {
  SHIPPING: 'ship',
  DISPATCH: 'dispatch',
  FITTING: 'fit',
  COLLECTION: 'collect',
};

export const EmptyList = ({
  type,
}: {
  type: 'SHIPPING' | 'DISPATCH' | 'FITTING' | 'COLLECTION';
}) => {
  return (
    <Wrapper>
      <Img src={tyrioIcons.collection} alt="empty_list" />
      <Typography textAlign={'center'} fontSize={24} fontWeight={600}>
        Empty list
      </Typography>
      <Typography
        textAlign={'center'}
        fontSize={16}
        fontWeight={400}
        color="#919EAB"
      >
        nothing to {typeToText[type]}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const Img = styled.img`
  width: 100px;
  height: 100px;
  opacity: 0.4;
`;
