import styled from '@emotion/styled';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Button } from '@mui/material';
import { landingLogo } from '@tyrio/ui-library';

export const NetworkError = () => {
  return (
    <Wrapper>
      <Container>
        <img
          src={landingLogo}
          alt="tyrio-logo"
          style={{
            opacity: '0.7',
            height: '100px',
          }}
        />
        <ErrorTitle>ERR_NETWORK</ErrorTitle>
        <Error500>500</Error500>
        <ErrorSubtitle>Internal server error</ErrorSubtitle>
        <Button
          variant="outlined"
          startIcon={<DashboardIcon />}
          onClick={() => {
            window.location.reload();
          }}
        >
          Go to dashboard
        </Button>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f4f6f8;
  padding: 16px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 16px;
`;

const ErrorTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: '#212B36';
`;
const Error500 = styled.div`
  font-size: 96px;
  font-weight: 700;
  line-height: 106px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: '#212B36';
`;

const ErrorSubtitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: '#212B36';
`;
