import InfoIcon from '@mui/icons-material/Info';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const MenuList = [
  {
    icon: WarehouseIcon,
    text: 'UPLOAD',
    disabled: false,
  },
  {
    icon: WarehouseIcon,
    text: 'WAREHOUSE',
    disabled: false,
  },
  {
    icon: InfoIcon,
    text: 'DETAILS',
    disabled: false,
  },
];
