import styled from '@emotion/styled';

export const Wrapper = styled.div<{ showButton?: boolean }>`
  display: flex;
  flex-direction: row;
  max-width: ${(props) => (props.showButton ? '220px;' : '130px;')}
  width: 100%;
  justify-content: space-between;
  align-items: center;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

export const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ecf0f4;
  border-radius: 8px;
  padding: 8px 10px;
  width: 100%;
  max-width: 128px;
  justify-content: space-between;
`;

export const ClickableIcon = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #ecf0f4;
  width: 32px;
  height: 32px;
  justify-content: center;
  cursor: pointer;
`;
