import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';
import { useHistory } from 'react-router-dom';
import pageNotFound from '../../../../assets/tyrio-tyre-doctor.png';
import routeNames from '../../lib/routeNames';

const PageNotFound = () => {
  const history = useHistory();

  const redirect = () => {
    history.push(routeNames.dashboard());
  };

  return (
    <Wrapper>
      <AnimationWrapper>
        <Text>4</Text>
        <Image src={pageNotFound} alt="404" />
        <Text>4</Text>
      </AnimationWrapper>
      <h2>Page Not Found</h2>
      <RedirectButton onClick={redirect}>Redirect to dashboard</RedirectButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const RedirectButton = styled.button`
  background: ${tyrioUI.colors.primary};
  border: 0;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  color: white;
  &:hover {
    background: rgb(27, 33, 48, 0.9);
  }
`;

const Image = styled.img`
  animation: rotation 8s infinite linear;
  width: 220px;
  height: 220px;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
  margin: 0;
  padding: 0;
`;

const Text = styled.p`
  font-size: 250px;
`;

export default PageNotFound;
