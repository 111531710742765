/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Column, useBlockLayout, usePagination, useTable } from 'react-table';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TableCell from './Cells/TableCell';
import { TableCellInterface } from './temp';
import ArticleCell from './Cells/ArticleCell';
import _ from 'lodash';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from '@mui/material';
import { StickyTableTotalCell } from './Cells/TotalCell';
import FooterCell from './Cells/FooterCell';
import { tyrioUI } from '@tyrio/ui-library';
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { PriceComparisonShape } from '@tyrio/dto';
import { filterPaginated } from './stickyTableHelper';
import { DBSupplierType } from '@prisma/client';
import classNames from 'classnames';
import { css, Global } from '@emotion/react';

interface DimensionsStickyTableProps {
  rawData: PriceComparisonShape;
  columns: Array<Column<TableCellInterface>>;
  data: TableCellInterface[];
  shouldShowDifference: boolean;
  onChangeSelectedRow: (id: string) => void;
  // Reserved
  onChangeValues: (values: Record<string, Record<string, number>>) => void;
  initialQtyValues: { value: string; qty: number }[];
  shouldShowCode: boolean;
}

// TODO: Move this to separate file.
// ------
interface CustomComponentProps {
  title: string;
  children: React.ReactNode;
  expanded: boolean;
  setExpanded: (ev: boolean) => void;
  hideChevron?: boolean;
}

const DimensionsGroupComponent = ({
  title,
  children,
  expanded,
  setExpanded,
  hideChevron,
}: CustomComponentProps) => {
  return (
    <div style={{ margin: 0 }}>
      <DimensionsGroupComponentTitleWrapper
        style={{
          width: `100%`,
          height: 39,
          backgroundColor: '#ECF5FB',
        }}
      >
        <div style={{ position: 'sticky', left: '0' }}>
          <Temp>
            {!hideChevron && (
              <ChevronWrapper onClick={() => setExpanded(!expanded)}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </ChevronWrapper>
            )}
            <DimensionsGroupComponentTitle
              onClick={() => setExpanded(!expanded)}
            >
              {title}
            </DimensionsGroupComponentTitle>
          </Temp>
        </div>
        <div> </div>
      </DimensionsGroupComponentTitleWrapper>
      <div style={{ display: expanded ? 'block' : 'none' }}>{children}</div>
    </div>
  );
};

const Temp = styled.div`
  display: flex;
  flex-direction: row;
`;

const DimensionsGroupComponentTitleWrapper = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const DimensionsGroupComponentTitle = styled.div`
  padding: ${tyrioUI.spacing.s}px ${tyrioUI.spacing.s}px ${tyrioUI.spacing.s}px
    64px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #212b36;
`;

const ChevronWrapper = styled.div`
  padding: ${tyrioUI.spacing.s}px;
  position: sticky;
  left: 0;
  color: #6b7280;
`;
// ------

export default function DimensionStickyTable(
  props: DimensionsStickyTableProps
) {
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [sortState, setSortState] = useState({
    sortKey: 'articleName',
    sortDirection: 'DESC',
  });

  const [values, setValues] = useState<Record<string, Record<string, number>>>(
    {}
  );
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [selectedGroup, setSelectedGroup] = useState(0);

  const [hoveredRow, setHoveredRow] = useState<number | undefined>(undefined);
  const [hoveredGroup, setHoveredGroup] = useState<number | undefined>(
    undefined
  );
  const [tableOptions, setTableOptions] = useState({
    left: true,
    right: true,
    canScroll: false,
    extraArticleWidth: 0,
  });
  const tableRef = useRef(null);
  const [, setHasAnyProductBackorder] = useState(false);
  const { columns, data } = props;

  const defaultColumn = {
    minWidth: 100,
    maxWidth: 600,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: rowsCompleteList,
    prepareRow,
  } = useTable(
    { columns, data, defaultColumn },
    useBlockLayout,
    // useSticky,
    usePagination
  );

  const generateInitialState = useCallback(
    (val: boolean) => {
      const keys = Object.keys(
        _.groupBy(rowsCompleteList, `values.articleName.size`)
      );

      const state: Record<string, boolean> = {};
      keys.forEach((item) => {
        state[item] = val;
      });

      return state;
    },
    [rowsCompleteList]
  );

  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (
      rowsCompleteList.length > 0 &&
      Object.keys(expandedSections).length === 0
    ) {
      setExpandedSections(generateInitialState(true));
    }
  }, [expandedSections, generateInitialState, rowsCompleteList]);

  // Rows
  const rows = useMemo(
    () =>
      filterPaginated(
        rowsCompleteList,
        page,
        itemsPerPage,
        sortState.sortKey,
        sortState.sortDirection
      ),
    [
      itemsPerPage,
      page,
      rowsCompleteList,
      sortState.sortKey,
      sortState.sortDirection,
    ]
  );

  const groupedRows = _.groupBy(rows, `values.articleName.size`);

  const count = useMemo(() => {
    return Math.ceil(rowsCompleteList.length / itemsPerPage);
  }, [itemsPerPage, rowsCompleteList.length]);

  // Footer
  const footerGroups = headerGroups.slice().reverse();
  const generateRowForFooter = useCallback(() => {
    const response: Record<string, { value: number; numberOfItems: number }> =
      {};

    props.columns.forEach((col) => {
      let numOfItems = 0;
      let valueOfElem = 0;
      Object.keys(values).forEach((value) => {
        if (values[value][`${col.accessor}`]) {
          if (col.accessor === 'mainSupplier') {
            numOfItems += values[value][`${col.accessor}`];
            const mainSupplierId = Object.keys(
              props.rawData.mainSupplier || {}
            )[0];
            const priceToBe = props.rawData.prices[value][mainSupplierId];
            if (priceToBe.value)
              valueOfElem += priceToBe.value * values[value][`${col.accessor}`];
          } else {
            numOfItems += values[value][`${col.accessor}`];

            const priceToBe = props.rawData.prices[value][`${col.accessor}`];
            if (priceToBe.value)
              valueOfElem += priceToBe.value * values[value][`${col.accessor}`];
          }
        }
      });
      response[`${col.accessor}`] = {
        value: valueOfElem,
        numberOfItems: numOfItems,
      };
    });

    return response;
  }, [props.columns, props.rawData.mainSupplier, props.rawData.prices, values]);

  const footerRows = useMemo(
    () => generateRowForFooter(),
    [generateRowForFooter]
  );

  const handleChangeSort = (columnId: string) => {
    if (columnId !== 'total')
      setSortState({
        sortKey: columnId,
        sortDirection: sortState.sortDirection === 'ASC' ? 'DESC' : 'ASC',
      });
  };

  const getNumberOfAvailableItemsForReserved = useMemo(() => {
    setHasAnyProductBackorder(false);
    let totalNumOfAvailable = 0;

    Object.keys(values).forEach((elId: string) => {
      const obj = Object.keys(props.rawData.prices).find(
        (priceId: string) => priceId === elId
      );
      if (obj) {
        Object.keys(values[elId]).forEach((supElId: string) => {
          const k = Object.keys(props.rawData.prices[elId]).find(
            (supPriceId: string) => supPriceId === supElId
          );
          if (k && values[elId][supElId] > 0) {
            totalNumOfAvailable += props.rawData.prices[elId][supElId].quantity;
            if (
              props.rawData.prices[elId][supElId].quantity <
              values[elId][supElId]
            )
              setHasAnyProductBackorder(true);
          }
        });
      }
    });
    return totalNumOfAvailable;
  }, [props.rawData, values]);

  useEffect(() => {
    if (rows.length === 0 && page > 0) {
      setPage(page - 1);
    }
  }, [page, rows.length, rowsCompleteList.length]);

  useEffect(() => {
    props.onChangeValues(values);
  }, [values, props]);

  const totalValue = useMemo(() => {
    return Object.values(footerRows)
      .map((item) => item.value)
      .reduce((acc, curr) => acc + curr, 0);
  }, [footerRows]);

  const totalNumOfItems = useMemo(() => {
    return Object.values(footerRows)
      .map((item) => item.numberOfItems)
      .reduce((acc, curr) => acc + curr, 0);
  }, [footerRows]);

  // const handleScrollPositions = useCallback(
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   (e: any) => {
  //     const max =
  //       e.nativeEvent?.target?.scrollWidth - e.nativeEvent?.target?.offsetWidth;
  //
  //     const FIXED_WIDTHS = 330 + 150; // Article name + Total column width
  //     const SUPPLIER_WIDTHS = props.columns.length * 130; // Supplier columns * width of column
  //     const CLIENT_WIDTH = e.nativeEvent.target.clientWidth;
  //     let extraWidth = 0;
  //
  //     if (max === 0) {
  //       const currentWidth = FIXED_WIDTHS + SUPPLIER_WIDTHS;
  //
  //       while (extraWidth + currentWidth <= CLIENT_WIDTH) {
  //         extraWidth += 130;
  //       }
  //     }
  //
  //     if (max === 0) {
  //       setTableOptions({
  //         ...tableOptions,
  //         left: true,
  //         right: true,
  //         extraArticleWidth: extraWidth,
  //       });
  //       return;
  //     }
  //     const pos = Math.ceil(e.nativeEvent?.target?.scrollLeft);
  //
  //     const leftPosition = props.shouldShowCode ? 150 : 0;
  //
  //     if (pos <= leftPosition) {
  //       setTableOptions({
  //         left: true,
  //         right: false,
  //         canScroll: true,
  //         extraArticleWidth: extraWidth,
  //       });
  //     } else if (
  //       pos > leftPosition &&
  //       pos < max &&
  //       (tableOptions.left || tableOptions.right)
  //     ) {
  //       setTableOptions({
  //         left: false,
  //         right: false,
  //         canScroll: true,
  //         extraArticleWidth: extraWidth,
  //       });
  //     } else if (pos === max) {
  //       setTableOptions({
  //         left: false,
  //         right: true,
  //         canScroll: true,
  //         extraArticleWidth: extraWidth,
  //       });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [
  //     props.shouldShowCode,
  //     props.columns,
  //     tableOptions.left,
  //     tableOptions.right,
  //     tableOptions.canScroll,
  //     tableOptions.extraArticleWidth,
  //   ]
  // );

  const handleScrollPositions = useCallback(
    (e: any) => {
      const max =
        e.nativeEvent?.target?.scrollWidth - e.nativeEvent?.target?.offsetWidth;

      const FIXED_WIDTHS = 330 + 150; // Article name + Total column width
      const SUPPLIER_WIDTHS = props.columns.length * 130; // Supplier columns * width of column
      const CLIENT_WIDTH = e.nativeEvent.target.clientWidth;
      let extraWidth = 0;

      if (max === 0) {
        const currentWidth = FIXED_WIDTHS + SUPPLIER_WIDTHS;

        while (extraWidth + currentWidth <= CLIENT_WIDTH) {
          extraWidth += 130;
        }
        extraWidth += 161;

        if (props.shouldShowCode) {
          // extraWidth += 72;
        }
      }

      if (max === 0) {
        setTableOptions({
          ...tableOptions,
          left: true,
          right: true,
          extraArticleWidth: extraWidth,
        });
        return;
      }
      const pos = Math.ceil(e.nativeEvent?.target?.scrollLeft);

      const leftPosition = props.shouldShowCode ? 150 : 0;

      if (pos <= leftPosition) {
        setTableOptions({
          left: true,
          right: false,
          canScroll: true,
          extraArticleWidth: extraWidth,
        });
      } else if (
        pos > leftPosition &&
        pos < max &&
        (tableOptions.left || tableOptions.right)
      ) {
        setTableOptions({
          left: false,
          right: false,
          canScroll: true,
          extraArticleWidth: extraWidth,
        });
      } else if (pos === max) {
        setTableOptions({
          left: false,
          right: true,
          canScroll: true,
          extraArticleWidth: extraWidth,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.shouldShowCode,
      props.columns,
      tableOptions.left,
      tableOptions.right,
      tableOptions.canScroll,
      tableOptions.extraArticleWidth,
    ]
  );

  useEffect(() => {
    if (tableRef.current) {
      handleScrollPositions({ nativeEvent: { target: tableRef.current } });
    }
  }, [handleScrollPositions]);

  const expandAll = useCallback(
    (val: boolean) => {
      const newPayload: Record<string, boolean> = generateInitialState(val);

      setExpandedSections(newPayload);
    },
    [generateInitialState]
  );

  return (
    <>
      <div style={{ paddingLeft: 24, paddingBottom: 12 }}>
        <Button
          style={{ marginRight: 12 }}
          variant="contained"
          onClick={() => expandAll(false)}
        >
          Collapse all
        </Button>

        <Button variant="contained" onClick={() => expandAll(true)}>
          Expand all
        </Button>
      </div>

      <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
        <Global
          styles={css`
            body {
              overflow-x: hidden;
            }
          `}
        />
        <ScrollerBg
          hasMainSupplier={!!props.rawData.mainSupplier}
          left={tableOptions.left}
          right={tableOptions.right}
          count={props.columns.length - 2}
          shouldShowCode={props.shouldShowCode}
        />
        <TablesWrapper>
          <Styles
            style={{ width: '100%' }}
            ref={tableRef}
            onScroll={handleScrollPositions}
            count={props.columns.length - 2}
            tableOptions={tableOptions}
            showCode={props.shouldShowCode}
            hasMainSupplier={!!props.rawData.mainSupplier}
          >
            <div {...getTableProps()} className={'table sticky'}>
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                    key={`sticky_table_header_group_${headerGroup.id}`}
                  >
                    {headerGroup.headers
                      .slice(0, headerGroup.headers.length - 1)
                      .map((column) => (
                        <div
                          {...column.getHeaderProps()}
                          className={classNames('th', column.id)}
                          onClick={() => handleChangeSort(column.id)}
                          key={`sticky_table_header_cell_${column.id}`}
                        >
                          <ArticleHeader
                            key={`sticky_table_header_article_${column.id}`}
                            style={{
                              alignItems:
                                column.id === 'articleName' ||
                                column.id === 'code'
                                  ? 'left'
                                  : 'center',
                            }}
                            title={column.Header?.toString() || ''}
                          >
                            <ArticleHeaderTitle
                              key={`sticky_table_header_article_title_${column.id}`}
                              title={column.Header?.toString() || ''}
                            >
                              {column.render('Header')}
                              {column.id !== 'code' && column.id !== 'total' && (
                                <ArrowsContainer>
                                  <ArrowsWrapper>
                                    <KeyboardArrowUp
                                      style={{
                                        height:
                                          column.id === sortState.sortKey &&
                                          sortState.sortDirection === 'ASC'
                                            ? '20px'
                                            : '15px',
                                        width:
                                          column.id === sortState.sortKey &&
                                          sortState.sortDirection === 'ASC'
                                            ? '20px'
                                            : '15px',
                                        opacity:
                                          (column.id === sortState.sortKey &&
                                            sortState.sortDirection ===
                                              'ASC') ||
                                          column.id !== sortState.sortKey
                                            ? 1
                                            : 0.3,
                                        color: '#6B7280',
                                      }}
                                    />
                                  </ArrowsWrapper>
                                  <ArrowsWrapper>
                                    <KeyboardArrowDown
                                      style={{
                                        height:
                                          column.id === sortState.sortKey &&
                                          sortState.sortDirection !== 'ASC'
                                            ? '20px'
                                            : '15px',
                                        width:
                                          column.id === sortState.sortKey &&
                                          sortState.sortDirection !== 'ASC'
                                            ? '20px'
                                            : '15px',
                                        opacity:
                                          (column.id === sortState.sortKey &&
                                            sortState.sortDirection !==
                                              'ASC') ||
                                          column.id !== sortState.sortKey
                                            ? 1
                                            : 0.3,
                                        color: '#6B7280',
                                      }}
                                    />
                                  </ArrowsWrapper>
                                </ArrowsContainer>
                              )}
                            </ArticleHeaderTitle>
                          </ArticleHeader>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {Object.keys(groupedRows).map((gRowKey) => {
                  const groupRows = groupedRows[gRowKey];

                  return (
                    <DimensionsGroupComponent
                      expanded={expandedSections[gRowKey]}
                      setExpanded={() =>
                        setExpandedSections({
                          ...expandedSections,
                          [gRowKey]: !expandedSections[gRowKey],
                        })
                      }
                      title={gRowKey}
                      key={`${gRowKey}`}
                    >
                      {groupRows.map((row, rowIdx) => {
                        const indexOfRowInGrouped = Object.keys(
                          groupedRows
                        ).findIndex((el) => el === gRowKey);
                        prepareRow(row);
                        if (
                          selectedRow === rowIdx &&
                          selectedGroup === indexOfRowInGrouped &&
                          row.cells[0].value &&
                          row.cells[0].value['id']
                        )
                          props.onChangeSelectedRow(row.cells[0].value['id']);
                        let mainSuppValue: number | undefined = undefined;
                        let rv: number[] = [];
                        let hasAlreadyAddedQty = false;
                        Object.keys(row.values).forEach((value) => {
                          if (
                            value !== 'articleName' &&
                            value !== 'code' &&
                            value !== 'total'
                          ) {
                            if (row.values[value])
                              rv.push(row.values[value]['value']);
                          }
                        });
                        const lowestVal =
                          rv.length >= 1
                            ? rv.reduce((a, b) => (a < b ? a : b))
                            : 0;

                        rv = rv.filter((val) => val > lowestVal);
                        const secondLowestVal =
                          rv.length > 1
                            ? rv.reduce((a, b) => (a < b ? a : b))
                            : 0;

                        return (
                          <div
                            {...row.getRowProps()}
                            className="tr"
                            onClick={() => {
                              setSelectedRow(rowIdx);
                              setSelectedGroup(indexOfRowInGrouped);
                            }}
                            key={`sticky_table_body_row_${row.id}_${rowIdx}`}
                            onMouseOver={() => {
                              setHoveredRow(rowIdx);
                              setHoveredGroup(indexOfRowInGrouped);
                            }}
                            onMouseLeave={() => {
                              setHoveredRow(undefined);
                              setHoveredGroup(undefined);
                            }}
                          >
                            {row.cells
                              .slice(0, row.cells.length - 1)
                              .map((cell, cellIdx) => {
                                if (
                                  cell.column.id === 'mainSupplier' &&
                                  cell.value
                                ) {
                                  mainSuppValue = cell.value.value;
                                }
                                let hasCodeColumn = false;
                                if (
                                  row.cells.find((c) => c.column.id === 'code')
                                )
                                  hasCodeColumn = true;

                                if (
                                  cell.value &&
                                  cell.value.value === lowestVal &&
                                  !hasAlreadyAddedQty &&
                                  row.values['articleName']['ean']
                                ) {
                                  const foundedInitialValueAndQty =
                                    props.initialQtyValues.find(
                                      (el) =>
                                        el.value ===
                                        row.values['articleName']['ean']
                                    );
                                  if (foundedInitialValueAndQty) {
                                    if (
                                      !values[row.values['articleName']['id']]
                                    )
                                      setValues({
                                        ...values,
                                        [row.values['articleName']['id']]: {
                                          ...(values[
                                            row.values['articleName']['id']
                                          ] || {}),
                                          [cell.column.id]:
                                            foundedInitialValueAndQty.qty,
                                        },
                                      });
                                    hasAlreadyAddedQty = true;
                                  }
                                }
                                return (
                                  <Cell
                                    {...cell.getCellProps()}
                                    pair={
                                      hasCodeColumn
                                        ? cellIdx === 0 || cellIdx % 2 !== 0
                                        : cellIdx % 2 === 0
                                    }
                                    className={classNames('td', cell.column.id)}
                                    key={`sticky_table_body_cell_${cellIdx}_${cell.column.id}`}
                                    hovered={
                                      hoveredRow !== undefined &&
                                      hoveredGroup !== undefined
                                        ? hoveredRow === rowIdx &&
                                          hoveredGroup === indexOfRowInGrouped
                                        : false
                                    }
                                  >
                                    {cell.column.id === 'articleName' ? (
                                      <ArticleCell
                                        title={cell.value.className}
                                        subtitle={cell.value.name}
                                        key={`sticky_table_article_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                      />
                                    ) : cell.column.id === 'mainSupplier' ? (
                                      <div>
                                        <TableCell
                                          key={`sticky_table_main_supplier_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                          isSelected={
                                            selectedRow === rowIdx &&
                                            selectedGroup ===
                                              indexOfRowInGrouped
                                          }
                                          value={
                                            cell && cell.value
                                              ? cell.value.value
                                              : undefined
                                          }
                                          isLowestValue={
                                            cell.value &&
                                            cell.value.value === lowestVal
                                          }
                                          isSecondLowest={
                                            cell.value &&
                                            cell.value.value === secondLowestVal
                                          }
                                          percentage={undefined}
                                          numberOfAvailableItems={
                                            cell.value
                                              ? cell.value.quantity
                                              : undefined
                                          }
                                          // These 2 props exist BELOW too!
                                          numberValue={
                                            values?.[
                                              row.values['articleName']['id']
                                            ]?.[cell.column.id]
                                          }
                                          onNumberChange={(e) => {
                                            let numberValue = parseInt(
                                              e.target.value,
                                              10
                                            );

                                            if (
                                              isNaN(numberValue) ||
                                              !numberValue
                                            ) {
                                              numberValue = 0;
                                            }

                                            const supplier =
                                              props.rawData.mainSupplier;

                                            const maxValue = cell.value
                                              ? cell.value.quantity
                                              : undefined;

                                            const value =
                                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              // @ts-ignore
                                              supplier?.['supplierType'] ===
                                              DBSupplierType.DISTRIBUTER
                                                ? Math.min(
                                                    numberValue,
                                                    maxValue
                                                  )
                                                : numberValue;

                                            setValues({
                                              ...values,
                                              [row.values['articleName']['id']]:
                                                {
                                                  ...(values[
                                                    row.values['articleName'][
                                                      'id'
                                                    ]
                                                  ] || {}),
                                                  [cell.column.id]: value,
                                                },
                                            });
                                          }}
                                        />
                                      </div>
                                    ) : cell.column.id === 'total' ? (
                                      <StickyTableTotalCell
                                        hideTotal
                                        key={`sticky_table_total_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                        price={row.cells.reduce((acc, curr) => {
                                          const r = curr?.row?.original?.[
                                            'articleName'
                                          ] as unknown as Record<
                                            string,
                                            string
                                          >;
                                          if (
                                            r['id'] &&
                                            curr?.value &&
                                            'value' in curr.value
                                          ) {
                                            const qty =
                                              values?.[r['id']]?.[
                                                curr.column.id
                                              ] || 0;

                                            return acc + curr.value.value * qty;
                                          }

                                          return acc;
                                        }, 0)}
                                        qty={row.cells.reduce((acc, curr) => {
                                          const r = curr?.row?.original?.[
                                            'articleName'
                                          ] as unknown as Record<
                                            string,
                                            string
                                          >;
                                          const qty =
                                            values?.[r['id']]?.[
                                              curr.column.id
                                            ] || 0;
                                          return acc + qty;
                                        }, 0)}
                                        availableNumber={row.cells.reduce(
                                          (acc, curr) => {
                                            let suppId = curr.column.id;
                                            if (
                                              suppId === 'mainSupplier' &&
                                              props.rawData.mainSupplier
                                            ) {
                                              suppId = Object.keys(
                                                props.rawData.mainSupplier
                                              )[0];
                                            }
                                            const r = curr?.row?.original?.[
                                              'articleName'
                                            ] as unknown as Record<
                                              string,
                                              string
                                            >;
                                            const productId = r['id'];

                                            const a =
                                              props.rawData.prices[productId][
                                                suppId
                                              ];
                                            const b =
                                              values?.[r['id']]?.[
                                                curr.column.id
                                              ];

                                            if (a && b) {
                                              const n =
                                                props.rawData.prices[productId][
                                                  suppId
                                                ].quantity;
                                              return acc + n || 0;
                                            } else return acc;
                                          },
                                          0
                                        )}
                                        // hasBackorder={true}
                                        isHovered={
                                          hoveredRow !== undefined &&
                                          hoveredGroup !== undefined
                                            ? hoveredRow === rowIdx &&
                                              hoveredGroup ===
                                                indexOfRowInGrouped
                                            : false
                                        }
                                      />
                                    ) : cell.column.id === 'code' ? (
                                      <ArticleCell
                                        title={cell?.value?.ean}
                                        subtitle={cell?.value?.manufacturerCode}
                                        type={1}
                                        key={`sticky_table_code_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                      ></ArticleCell>
                                    ) : (
                                      <TableCell
                                        key={`sticky_table_normal_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                        isSelected={
                                          selectedRow === rowIdx &&
                                          selectedGroup === indexOfRowInGrouped
                                        }
                                        value={
                                          cell && cell.value
                                            ? cell.value.value
                                            : undefined
                                        }
                                        isLowestValue={
                                          cell.value &&
                                          cell.value.value === lowestVal
                                        }
                                        isSecondLowest={
                                          cell.value &&
                                          cell.value.value === secondLowestVal
                                        }
                                        percentage={
                                          props.shouldShowDifference &&
                                          mainSuppValue &&
                                          cell.value &&
                                          cell.value.value
                                            ? cell.value.value / mainSuppValue -
                                              1.0000001
                                            : undefined
                                        }
                                        numberOfAvailableItems={
                                          cell.value
                                            ? cell.value.quantity
                                            : undefined
                                        }
                                        // These 2 props exist above too!
                                        numberValue={
                                          values?.[
                                            row.values['articleName']['id']
                                          ]?.[cell.column.id]
                                        }
                                        onNumberChange={(e) => {
                                          let numberValue = parseInt(
                                            e.target.value,
                                            10
                                          );

                                          if (
                                            isNaN(numberValue) ||
                                            !numberValue
                                          ) {
                                            numberValue = 0;
                                          }
                                          setValues({
                                            ...values,
                                            [row.values['articleName']['id']]: {
                                              ...(values[
                                                row.values['articleName']['id']
                                              ] || {}),
                                              [cell.column.id]: numberValue,
                                            },
                                          });
                                        }}
                                      />
                                    )}
                                  </Cell>
                                );
                              })}
                          </div>
                        );
                      })}
                    </DimensionsGroupComponent>
                  );
                })}

                <div className="footer">
                  {footerGroups.map((footerGroup) => (
                    <div
                      {...footerGroup.getHeaderGroupProps()}
                      className={'tr'}
                      style={{ height: 60 }}
                      key={`sticky_table_footer_group_${footerGroup.id}`}
                    >
                      {footerGroup.headers
                        .slice(0, footerGroup.headers.length - 1)
                        .map((column, colIdx) => {
                          return (
                            <div
                              {...column.getHeaderProps()}
                              className={classNames('td', column.id)}
                              key={`sticky_table_footer_row_${footerGroup.id}_${column.id}_${colIdx}`}
                            >
                              {column.id === 'articleName' ||
                              column.id === 'code' ? (
                                <ArticleHeader
                                  key={`sticky_table_footer_header_article_code_cell_${column.id}_${colIdx}`}
                                  title={''}
                                >
                                  <ArticleHeaderTitle
                                    key={`sticky_table_footer_header_article_code_empty_cell_${column.id}_${colIdx}`}
                                    title={column.Header?.toString() || ''}
                                  >
                                    {' '}
                                  </ArticleHeaderTitle>
                                </ArticleHeader>
                              ) : (
                                <ArticleHeader
                                  key={`sticky_table_footer_normal_cell_${column.id}_${colIdx}`}
                                  style={{
                                    alignItems: 'center',
                                  }}
                                  title={column.Header?.toString() || ''}
                                >
                                  <ArticleHeaderTitle
                                    key={`sticky_table_footer_header_cell_${column.id}_${colIdx}`}
                                    title={column.Header?.toString() || ''}
                                  >
                                    {column.render('Header')}
                                  </ArticleHeaderTitle>
                                </ArticleHeader>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ))}

                  {footerGroups.map((footerGroup) => (
                    <div
                      {...footerGroup.getHeaderGroupProps()}
                      className="tr footerrow"
                      key={`sticky_table_footer_values_${footerGroup.id}`}
                    >
                      {footerGroup.headers
                        .slice(0, footerGroup.headers.length - 1)
                        .map((column, colIdx) => {
                          let hasCodeColumn = false;
                          if (
                            footerGroup.headers.find((el) => el.id === 'code')
                          )
                            hasCodeColumn = true;

                          const cellValue = _.get(footerRows, column.id);

                          if (column.id === 'code')
                            return (
                              <div {...column.getHeaderProps()} className="td">
                                {' '}
                                <div
                                  style={{
                                    backgroundColor: 'white',
                                    height: '100%',
                                  }}
                                ></div>{' '}
                              </div>
                            );

                          if (column.id === 'articleName')
                            return (
                              <div {...column.getHeaderProps()} className="td">
                                <TotalTextWrapper>Total</TotalTextWrapper>
                              </div>
                            );
                          return (
                            <div {...column.getHeaderProps()} className="td">
                              {column.id === 'total' ? (
                                <StickyTableTotalCell
                                  price={totalValue}
                                  qty={totalNumOfItems}
                                  hideTotal
                                  availableNumber={
                                    getNumberOfAvailableItemsForReserved
                                  }
                                />
                              ) : (
                                <FooterCell
                                  key={`footer_value_cell_${cellValue.numberOfItems}_${column.id}`}
                                  value={cellValue.value}
                                  numberOfItems={cellValue.numberOfItems}
                                  pair={
                                    hasCodeColumn
                                      ? colIdx % 2 !== 0
                                      : colIdx % 2 === 0
                                  }
                                />
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Styles>
          <div className="totalColumn">
            <StyledHeader className="header">
              {headerGroups.map((headerGroup) => {
                const column =
                  headerGroup.headers[headerGroup.headers.length - 1];
                return (
                  <div>
                    <div
                      onClick={() => handleChangeSort(column.id)}
                      key={`sticky_table_header_cell_${column.id}`}
                    >
                      <ArticleHeader
                        key={`sticky_table_header_article_${column.id}`}
                        style={{
                          alignItems: 'center',
                        }}
                        title={column.Header?.toString() || ''}
                      >
                        <ArticleHeaderTitle
                          key={`sticky_table_header_article_title_${column.id}`}
                          title={column.Header?.toString() || ''}
                        >
                          {column.render('Header')}
                        </ArticleHeaderTitle>
                      </ArticleHeader>
                    </div>
                  </div>
                );
              })}
            </StyledHeader>
            <div {...getTableBodyProps()} className="body">
              {Object.keys(groupedRows).map((gRowKey) => {
                const groupRows = groupedRows[gRowKey];
                const indexOfRowInGrouped = Object.keys(groupedRows).findIndex(
                  (el) => el === gRowKey
                );
                return (
                  <DimensionsGroupComponent
                    expanded={expandedSections[gRowKey]}
                    setExpanded={() => null}
                    title={''}
                    key={`${gRowKey}`}
                    hideChevron
                  >
                    {groupRows.map((row, rowIdx) => {
                      // prepareRow(row);
                      if (
                        selectedRow === rowIdx &&
                        row.cells[0].value &&
                        row.cells[0].value['id']
                      )
                        props.onChangeSelectedRow(row.cells[0].value['id']);

                      const rv: number[] = [];

                      Object.keys(row.values).forEach((value) => {
                        if (
                          value !== 'articleName' &&
                          value !== 'code' &&
                          value !== 'total'
                        ) {
                          if (row.values[value])
                            rv.push(row.values[value]['value']);
                        }
                      });

                      const cell = row.cells.pop();
                      const cellIdx = 123456;

                      if (!cell) return null;

                      return (
                        <>
                          <Row
                            onClick={() => {
                              setSelectedRow(rowIdx);
                            }}
                            key={`sticky_table_body_row_${row.id}`}
                          >
                            <Cell
                              pair={false}
                              className={classNames('td', cell.column.id)}
                              key={`sticky_table_body_cell_${cellIdx}_${cell.column.id}`}
                            >
                              <div
                                className={classNames('totalContent', {
                                  open:
                                    selectedRow === rowIdx &&
                                    selectedGroup === indexOfRowInGrouped,
                                })}
                              >
                                <StickyTableTotalCell
                                  key={`sticky_table_total_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                  price={row.cells.reduce((acc, curr) => {
                                    const r = curr?.row?.original?.[
                                      'articleName'
                                    ] as unknown as Record<string, string>;
                                    if (
                                      r['id'] &&
                                      curr?.value &&
                                      'value' in curr.value
                                    ) {
                                      const qty =
                                        values?.[r['id']]?.[curr.column.id] ||
                                        0;

                                      return acc + curr.value.value * qty;
                                    }

                                    return acc;
                                  }, 0)}
                                  qty={row.cells.reduce((acc, curr) => {
                                    const r = curr?.row?.original?.[
                                      'articleName'
                                    ] as unknown as Record<string, string>;
                                    const qty =
                                      values?.[r['id']]?.[curr.column.id] || 0;
                                    return acc + qty;
                                  }, 0)}
                                  isHovered={false}
                                />
                              </div>
                            </Cell>
                          </Row>
                          <RowSeparator />
                        </>
                      );
                    })}
                  </DimensionsGroupComponent>
                );
              })}

              <div className="footer">
                {footerGroups.map((footerGroup) => {
                  const column = footerGroup.headers.pop();
                  const colIdx = 123456;
                  if (!column) return null;

                  return (
                    <div
                      className={classNames('td', column.id)}
                      style={{ width: '100%' }}
                      key={`sticky_table_footer_row_${footerGroup.id}_${column.id}_${colIdx}`}
                    >
                      <ArticleHeader
                        key={`sticky_table_footer_normal_cell_${column.id}_${colIdx}`}
                        style={{
                          width: '100%',
                          alignItems: 'center',
                        }}
                        title={column.Header?.toString() || ''}
                      >
                        <ArticleHeaderTitle
                          key={`sticky_table_footer_header_cell_${column.id}_${colIdx}`}
                          title={column.Header?.toString() || ''}
                        >
                          {column.render('Header')}
                        </ArticleHeaderTitle>
                      </ArticleHeader>
                    </div>
                  );
                })}

                {footerGroups.map((footerGroup) => {
                  const column = footerGroup.headers.pop();
                  if (!column) return null;
                  return (
                    <div
                      {...column.getHeaderProps()}
                      style={{ width: '100%' }}
                      className="td mainTotal"
                    >
                      <StickyTableTotalCell
                        price={totalValue}
                        qty={totalNumOfItems}
                        availableNumber={getNumberOfAvailableItemsForReserved}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </TablesWrapper>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <CenteredGridItem>
              <Pagination
                count={count}
                page={page + 1}
                onChange={(evt, page) => {
                  setPage(page - 1);
                }}
                sx={{
                  button: {
                    '&.css-d3ksqy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected':
                      {
                        backgroundColor: `${tyrioUI.colors.lightBlue}`,
                        color: 'white',
                      },
                  },
                }}
              />
            </CenteredGridItem>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="rows-per-page">Rows per page</InputLabel>
              <Select
                labelId="rows-per-page"
                id="rows-per-page"
                value={itemsPerPage}
                label="Rows per page"
                onChange={(e) => {
                  if (typeof e.target.value === 'number') {
                    setItemsPerPage(e.target.value);
                  } else {
                    setItemsPerPage(parseInt(e.target.value, 10));
                  }
                }}
                sx={{ height: '40px', width: '100px' }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={rowsCompleteList.length}>All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const StyledHeader = styled.div``;

const ScrollerBg = styled.div<{
  left: boolean;
  right: boolean;
  count: number;
  hasMainSupplier: boolean;
  shouldShowCode: boolean;
}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 72px;
  top: 0;
  width: 100%; // ${(props) => `${480 + props.count * 130}`}px!important;
  z-index: 1000;
  background: ${(props) => `linear-gradient(to right, rgba(0, 0, 0, ${
    props.left ? 0 : 0.1
  }) 0%, rgba(0, 0, 0, 0)),
    linear-gradient(to left, rgba(0, 0, 0, ${
      props.right ? 0 : 0.1
    }) 0%, rgba(0, 0, 0, 0))`};

  background-size: 20px 100%, 20px 100%;
  background-repeat: no-repeat;
  background-position: ${(props) => {
      let base = 334;
      if (props.hasMainSupplier) {
        base += 130;
      }
      if (props.shouldShowCode) {
        base += 150;
      }
      return base;
    }}px,
    calc(100% - 150px);
  transition: all 0.11s ease;

  pointer-events: none;
  background-attachment: scroll, scroll;
`;

const Cell = styled.div<{ pair: boolean; hovered?: boolean }>`
  padding: 16px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: ${(props) =>
    props.hovered ? '#FFF8E5' : !props.pair ? '#F9FAFB' : 'white'};
`;

const Row = styled.div`
  &:hover {
    > * {
      background: #fff8e5 !important;
    }
  }
`;

const ArticleHeader = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 16px;
  background: #ecf0f4;
`;
const ArticleHeaderTitle = styled.div`
  color: #212b36;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArrowsContainer = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  width: 10px;
  height: 22px;
  margin-left: ${tyrioUI.spacing.s}px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ArrowsWrapper = styled.div`
  height: 15px;
  display: flex;
  align-items: center;
`;

const TablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .totalColumn {
    min-width: 150px;

    margin-left: -4px;
    z-index: 100;

    .total {
      padding: 0;
      display: flex;
      .totalContent {
        width: 100%;
        height: 95px;
        //height: 74px;
        &.open {
          height: 132px;
        }
        > div {
          padding: 16px 20px;
        }
      }
    }

    .mainTotal {
      height: 69px !important;
      min-height: 69px !important;
    }
  }
`;

const Styles = styled.div<{
  count: number;
  showCode: boolean;
  tableOptions: Record<string, any>;
  hasMainSupplier: boolean;
}>`
  position: relative;
  overflow-x: scroll;
  z-index: 1;
  background-color: #ecf5fb;

  .header,
  .footer {
    background-color: rgb(236, 240, 244);
  }

  .header,
  .body {
    .tr {
      display: flex;
      width: ${(props) => {
        let base = 330 - 150;

        base += props.count * 130;

        // if (props.showCode) base += 150;

        return base;
      }}px!important;

      .td {
        min-height: 95px;
      }
      .th,
      .td {
        width: 130px;
        max-width: 130px;
        min-width: 130px !important;
        flex: 1;

        &.code {
          width: 150px !important;
          max-width: 150px !important;
          min-width: 150px !important;
          position: sticky;
          left: 0px;
          > div {
            width: 100%;
            max-width: 100%;
          }
        }

        &.mainSupplier {
          position: sticky;
          z-index: 100;
          left: ${(props) => {
            let base = 334;

            if (props.showCode) {
              base += 150;
            }
            return base;
          }}px!important;
        }

        &.articleName {
          position: sticky;
          z-index: 100;
          left: ${(props) => (props.showCode ? 150 : 0)}px;

          min-width: 334px !important;
          > div {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .footerrow {
    .td {
      ${(props) =>
        props.showCode
          ? `
           &:first-child {
              position: sticky;
              left: 0;
              min-width: 334px!important;
              z-index: 100;
            }

            &:nth-child(2) {
              position: sticky;
              left: 334px;
              min-width: 150px!important;
              z-index: 100;
            }

            &:nth-child(3) {
            position: sticky;
              ${
                props.hasMainSupplier
                  ? `left: 480px!important;`
                  : `left: 334px!important;`
              }
            }
        `
          : `
        &:first-child {
          position: sticky;
          left: 0;
          min-width: 334px!important;
          z-index: 100;
        }

        &:nth-child(2) {
        position: sticky;
          ${
            props.hasMainSupplier
              ? `left: 334px!important;`
              : `left: 200px!important;`
          }
        }
      `}
      > div {
        height: 95px;
      }
    }
  }
`;
// .footerrow {
// .td {
//   &:first-child {
//       position: sticky;
//       left: 0;
//       min-width: ${(props) => {
//           let base = 330;
//
//           if (props.showCode) {
//             base += 20;
//           }
//       return base;
//     }}px!important;
// }
//
// &:nth-child(2) {
//   ${(props) =>
//     props.hasMainSupplier
//       ? `
//   position: sticky;
//   left: ${330 + (props.showCode ? 150 : 0)}px!important;
//   `
//             : `position: sticky;
//   left: ${200 + (props.showCode ? 150 : 0)}px!important;`}
//       }
//
//       > div {
//         > height: 95px;
//       }
//     }
//   }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const Styles = styled.div<{ count: number; tableOptions: Record<string, any> }>`
//   position: relative;
//   overflow-x: scroll;
//   background: rgba(25, 118, 210, 0.08);
//   //  .header {
//   //  }
//   .header {
//     .tr {
//       display: flex;
//       width: ${(props) =>
//         480 +
//         props.tableOptions['extraArticleWidth'] +
//         props.count * 130}px!important;
//       .th {
//         width: 130px;
//         max-width: 130px;
//         flex: 1;
//
//         &:first-child {
//           width: ${(props) => 330 + props.tableOptions['extraArticleWidth']}px;
//           max-width: ${(props) =>
//             330 + props.tableOptions['extraArticleWidth']}px;
//           > div {
//             width: 100%;
//             max-width: 100%;
//           }
//         }
//         &:last-child {
//           width: 150px;
//           max-width: 150px;
//           > div {
//             width: 150px;
//             max-width: 150px;
//           }
//         }
//       }
//     }
//   }
//   .body {
//     .tr {
//       display: flex;
//       border-bottom: 1px solid #e6e8f0;
//       width: ${(props) =>
//         480 +
//         props.tableOptions['extraArticleWidth'] +
//         props.count * 130}px!important;
//
//       .td {
//         width: 130px;
//         max-width: 130px;
//         flex: 1;
//
//         &:first-child {
//           width: ${(props) => 330 + props.tableOptions['extraArticleWidth']}px;
//           max-width: ${(props) =>
//             330 + props.tableOptions['extraArticleWidth']}px;
//           > div {
//             width: 100%;
//             max-width: 100%;
//           }
//         }
//         &:last-child {
//           width: 150px;
//           max-width: 150px;
//           > div {
//             width: 150px;
//             max-width: 150px;
//           }
//
//           padding: 0;
//         }
//       }
//     }
//   }
//   //padding: 1rem;
//   //
//   //.table {
//   //  margin-bottom: 32px;
//   //  background-color: rgba(25, 118, 210, 0.08);
//   //
//   //  .header {
//   //    background-color: #ecf0f4;
//   //  }
//   //  .tr {
//   //    :last-child {
//   //      .td {
//   //        border-bottom: 0;
//   //      }
//   //    }
//   //  }
//   //
//   //  .th {
//   //    border-bottom: 1px solid #ddd;
//   //
//   //    overflow: hidden;
//   //
//   //    :last-child {
//   //      border-right: 0;
//   //    }
//   //
//   //    .resizer {
//   //      display: inline-block;
//   //      width: 5px;
//   //      height: 100%;
//   //      position: absolute;
//   //      right: 0;
//   //      top: 0;
//   //      transform: translateX(50%);
//   //      z-index: 1;
//   //
//   //      &.isResizing {
//   //        background: red;
//   //      }
//   //    }
//   //  }
//   //  ,
//   //  .td {
//   //    border-bottom: 1px solid #ddd;
//   //
//   //    overflow: hidden;
//   //
//   //    :last-child {
//   //      border-right: 0;
//   //
//   //      padding: 0;
//   //      margin: 0;
//   //      display: flex;
//   //      align-items: stretch;
//   //      justify-content: stretch;
//   //    }
//   //
//   //    .resizer {
//   //      display: inline-block;
//   //      width: 5px;
//   //      height: 100%;
//   //      position: absolute;
//   //      right: 0;
//   //      top: 0;
//   //      transform: translateX(50%);
//   //      z-index: 1;
//   //
//   //      &.isResizing {
//   //        background: red;
//   //      }
//   //    }
//   //  }
//   //
//   //  &.sticky {
//   //    overflow: scroll;
//   //    .header {
//   //      position: sticky;
//   //      z-index: 100;
//   //      width: fit-content;
//   //    }
//   //    ,
//   //    .footer {
//   //      position: sticky;
//   //      z-index: 100;
//   //      width: fit-content;
//   //    }
//   //
//   //    .header {
//   //      top: 0;
//   //    }
//   //
//   //    .footer {
//   //      bottom: 0;
//   //    }
//   //
//   //    .body {
//   //      position: relative;
//   //      z-index: 0;
//   //    }
//   //
//   //    [data-sticky-td] {
//   //      position: sticky;
//   //    }
//   //
//   //    [data-sticky-last-left-td] {
//   //      box-shadow: 2px 0px 3px #ccc;
//   //    }
//   //
//   //    [data-sticky-first-right-td] {
//   //      box-shadow: -2px 0px 3px #ccc;
//   //    }
//   //  }
//   //}
// `;

const CenteredGridItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TotalTextWrapper = styled.div`
  margin: 0;
  padding: ${tyrioUI.spacing.l}px;
  text-align: right;
  background-color: white;
  font-size: ${tyrioUI.fontSize.title}px;
  font-weight: 700;
`;
const RowSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: rgb(224 224 224);
  position: absolute;
  left: 0;
`;
