import { TRule } from '../../typings';

export const APOLLOVREDESTEIN_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: 'c.ean',
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.supplierCode',
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.brand',
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.purchasePrice',
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.suggestedRetailPrice',
    destination: 'product',
    targetKey: 'suggestedRetailPrice',
    outputType: 'number',
  },
];
