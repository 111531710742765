import { Grid } from '@mui/material';

interface OrderDetailsContainerProps {
  leftChildren: JSX.Element;
  rightChildren: JSX.Element;
}

const OrderDetailsContainer = (props: OrderDetailsContainerProps) => {
  return (
    <Grid
      sx={{
        display: 'flex',
        padding: '12px',
        height: '110px',
        cursor: 'default',
      }}
    >
      <Grid
        sx={{
          width: '50%',
          borderRight: 1,
          borderColor: 'divider',
          paddingRight: '8px',
        }}
      >
        {props.leftChildren}
      </Grid>
      <Grid sx={{ width: '50%', paddingLeft: '8px' }}>
        {props.rightChildren}
      </Grid>
    </Grid>
  );
};

export default OrderDetailsContainer;
