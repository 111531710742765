import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';

interface ProductSectionProps {
  children: JSX.Element;
  title: string;
}
const ProductSection = ({ children, title }: ProductSectionProps) => {
  return (
    <Container>
      <Title>{title} </Title>
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 348px;
  padding: ${tyrioUI.spacing.l}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #dfe3e8;
`;

const Title = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #919eab;
`;

const ContentWrapper = styled.div`
  padding: ${tyrioUI.spacing.l}px;
`;

export default ProductSection;
