import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { LocationType } from '@tyrio/dto';
import { Dispatch, SetStateAction } from 'react';
import { LocationTypeText, LocationTypeWrapper } from './style/common.style';

interface LocationTypeProps {
  value: LocationType | string;
  setValue?: Dispatch<SetStateAction<Record<string, LocationType>>>;
  setLocationType?: Dispatch<SetStateAction<LocationType>>;
  index?: string;
}

export const LocationTypes = ({
  value,
  setValue,
  setLocationType,
  index,
}: LocationTypeProps) => {
  return (
    <LocationTypeWrapper>
      <LocationTypeText>Choose new location type</LocationTypeText>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="shelf"
          name="radio-buttons-group"
          value={value}
          onChange={(e) => {
            if (setValue && index)
              setValue((prevState: Record<string, LocationType>) => ({
                ...prevState,
                [index]:
                  e.target.value.toUpperCase() as unknown as LocationType,
              }));
            else if (setLocationType)
              setLocationType(
                e.target.value.toUpperCase() as unknown as LocationType
              );
          }}
        >
          <FormControlLabel value="shelf" control={<Radio />} label="Shelf" />
          <FormControlLabel value="bin" control={<Radio />} label="Bin" />
        </RadioGroup>
      </FormControl>
    </LocationTypeWrapper>
  );
};
