import { useQuery } from 'react-query';
import api from '@tyrio/api-factory';
import { DBBranchResponse, DBBranchesApi } from '@tyrio/dto';

export const useBranchQuery = (
  onSuccess?: (data: DBBranchResponse[]) => void
) => {
  const data = useQuery(['get_branches'], () => searchBranches('', 1), {
    onSuccess: (res) => {
      if (onSuccess) onSuccess(res.data);
    },
  });

  const searchBranches = async (search: string, page: number) => {
    return await api.fetch<DBBranchesApi['list']>('get_branches', {
      search,
      page,
      pageSize: 1000,
    });
  };

  return data;
};
