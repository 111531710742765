import styled from '@emotion/styled';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DBOrderStatus } from '@prisma/client';
import { DBClientSupplierOrderItemApi } from '@tyrio/dto';
import moment from 'moment';

import {
  generateNumberColor,
  getOrderStatusColor,
  OrderLineItem,
} from '../helpers/generate-color';
interface OrderDetailsTableProps {
  data: OrderLineItem[];
  page: number;
  pageSize: number;
  meta: Record<string, Record<string, number>>;
  setSelectedOrderData: (
    value: DBClientSupplierOrderItemApi['getOne']['response']
  ) => void;
  selectedOrderData: DBClientSupplierOrderItemApi['getOne']['response'];
}

const OrderDetailsTable = ({
  data,
  meta,
  page,
  pageSize,
  setSelectedOrderData,
  selectedOrderData,
}: OrderDetailsTableProps) => {
  const handleChange = (
    productId: string,
    key: string,
    value: number,
    maxValue: number
  ) => {
    if (value < 0 || !value) value = 0;
    if (value > maxValue) value = maxValue;

    setSelectedOrderData({
      ...selectedOrderData,
      orderLineItemMeta: {
        ...meta,
        [productId]: {
          ...meta[productId],
          [key]: value,
        },
      },
    });
  };

  return data.length === 0 ? (
    <NoDataContainer>
      <Typography>No data</Typography>
    </NoDataContainer>
  ) : (
    <TableContainer component={Paper} sx={{ marginTop: '32px' }}>
      <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow
            sx={{
              background: '#ECF0F4',
            }}
          >
            <StyledTableCell>#</StyledTableCell>
            <TableCell style={{ fontSize: '12px' }}>Item</TableCell>
            <StyledTableCell>Price</StyledTableCell>
            <StyledTableCell>Qty</StyledTableCell>
            <StyledTableCell>Total</StyledTableCell>
            <NumberHeader>Ordered</NumberHeader>
            <NumberHeader>Confirmed</NumberHeader>
            <NumberHeader>Backorder</NumberHeader>
            <NumberHeader>Received</NumberHeader>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((order: OrderLineItem, index) => {
            return (
              <TableRow key={`${order.uid}-${index}`}>
                <StyledTableCell>
                  {page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}
                </StyledTableCell>
                <TableCell style={{ fontSize: '12px' }}>
                  <div>{order.productName}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: '#919EAB', fontSize: '11px' }}>
                      EAN:{order.ean}
                    </div>
                    <div style={{ color: '#919EAB', fontSize: '11px' }}>
                      <span style={{ marginLeft: '5px' }}>IPC:{order.ipc}</span>
                    </div>
                  </div>
                </TableCell>
                <StyledTableCell style={{ fontSize: '16px' }}>
                  {order.price.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })}
                </StyledTableCell>
                <StyledTableCell style={{ fontSize: '16px' }}>
                  {order.qty}
                </StyledTableCell>
                <StyledTableCell style={{ fontSize: '16px' }}>
                  {(order.price * order.qty).toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })}
                </StyledTableCell>
                <StyledTableCell>
                  <OrderNumbers color={'grey'}>
                    {meta[order.uid]?.['ordered']}
                  </OrderNumbers>
                </StyledTableCell>
                <StyledTableCell>
                  <OrderNumbers
                    background={generateNumberColor('confirmed', order)}
                    color={'#495a69'}
                    status={DBOrderStatus.PROCESSING}
                  >
                    <HiddenInput
                      value={meta[order.uid]?.['confirmed']}
                      type="number"
                      onChange={(e) =>
                        handleChange(
                          order.uid,
                          'confirmed',
                          parseInt(e.target.value),
                          meta[order.uid]?.['ordered'] -
                            meta[order.uid]?.['backorder']
                        )
                      }
                    />
                  </OrderNumbers>
                </StyledTableCell>
                <StyledTableCell>
                  <OrderNumbers
                    background={generateNumberColor('backorder', order)}
                    color={'#495a69'}
                    status={DBOrderStatus.PROCESSING}
                  >
                    <HiddenInput
                      value={meta[order.uid]?.['backorder']}
                      type="number"
                      onChange={(e) =>
                        handleChange(
                          order.uid,
                          'backorder',
                          parseInt(e.target.value),
                          meta[order.uid]?.['ordered'] -
                            meta[order.uid]?.['confirmed']
                        )
                      }
                    />
                  </OrderNumbers>
                </StyledTableCell>
                <StyledTableCell>
                  <OrderNumbers
                    background={generateNumberColor('received', order)}
                    status={DBOrderStatus.PROCESSING}
                    color={'#495a69'}
                  >
                    <HiddenInput
                      value={meta[order.uid]?.['received']}
                      type="number"
                      onChange={(e) =>
                        handleChange(
                          order.uid,
                          'received',
                          parseInt(e.target.value),
                          meta[order.uid]?.['confirmed']
                        )
                      }
                    />
                  </OrderNumbers>
                </StyledTableCell>
                <StyledTableCell>
                  {meta[order.uid]?.['confirmed'] +
                    meta[order.uid]?.['ordered'] ===
                  0 ? (
                    <MissingBox>MISSING</MissingBox>
                  ) : (
                    <div>
                      <div>
                        {moment(order.orderTimestamp).format('DD.MM.yyyy')}
                      </div>
                      <div
                        style={{
                          color: getOrderStatusColor(DBOrderStatus.PROCESSING),
                        }}
                      >
                        {DBOrderStatus.PROCESSING}
                      </div>
                    </div>
                  )}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoDataContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTableCell = styled(TableCell)`
  text-align: center;
  font-size: 12px;
`;

const NumberHeader = styled(StyledTableCell)`
  padding-inline: 0;
  font-size: 12px;
`;

const HiddenInput = styled.input`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  border: none;
  background: transparent;
`;

const MissingBox = styled.div`
  padding: 2px 8px;
  width: 67px;
  height: 22px;
  background: #e22a2a;
  color: white;
  border-radius: 100px;
  margin-left: 5px;
`;

const OrderNumbers = styled.div<{
  background?: string;
  color?: string;
  status?: DBOrderStatus;
}>`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid
    ${(prop) => (prop.status === DBOrderStatus.MISSING ? 'white' : prop.color)};

  font-weight: 400;
  font-size: 16px;
  background: ${(prop) => prop.background};
  color: ${(prop) =>
    prop.status === DBOrderStatus.MISSING ? 'white' : prop.color};
`;

export default OrderDetailsTable;
