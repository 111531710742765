import { envName } from '@tyrio/config';

export enum FEATURE_FLAGS {
  DEV_DROP_DATABASE,
  RUN_SEEDS,
  REACT_QUERY_DEVTOOLS_ENABLED,
  S3_ENDPOINTS_ENABLED,
  SHOW_WMS,
  SHOW_STOCK_LIST,
  SHOW_STOCK_LOCATIONS,
  SHOW_TRANSFER_ORDERS,
  SHOW_STOCK_IN,
  KEEP_CATALOG_ARCHIVE,
  SHOW_POS,
  SHOW_PARTNERS,
  AUTOMATIC_ADMIN_JOBS,
  SHOW_SERVICES,
  SHOW_PAYMENT_METHOD,
  SHOW_BRANCH_SERVICES,
  SHOW_BRANCH_PAYMENT_METHODS,
  SHOW_COMPANY_SETTING_OPTIONS,
  SHOW_CUSTOM_PRICE_CATALOG,
  SHOW_BRANCH_DELIVERY_METHODS,
  SHOW_DOCUMENTS_LOOK,
  SHOW_GENERATED_PDF,
  WHEELS_SEARCH,
  SHOW_INBOUND_INVOICE,
  SHOW_APPOINTMENT_CALENDAR,
  STOCK_LIST_IMPORT,
  FINISH_ORDERS,
  SEND_INVOICE_TO_RITAM,
  LOCATION_DETAILS,
}

type FeatureFlagEnabledShape = {
  a?: boolean;
  d?: boolean;
  t?: boolean;
  p?: boolean;
  ci?: boolean;
};

export type FeatureFlagShape = Record<
  FEATURE_FLAGS,
  {
    enabled: FeatureFlagEnabledShape;
  } & Record<string, FeatureFlagEnabledShape | string | boolean | number>
>;

export const FEATURE_FLAG_CONFIG: FeatureFlagShape = {
  [FEATURE_FLAGS.DEV_DROP_DATABASE]: {
    enabled: {
      a: false,
      d: false,
      p: false,
    },
  },
  [FEATURE_FLAGS.RUN_SEEDS]: {
    enabled: {
      a: false,
      d: false,
      p: false,
    },
  },
  [FEATURE_FLAGS.REACT_QUERY_DEVTOOLS_ENABLED]: {
    enabled: {
      a: true,
      d: true,
      p: false,
    },
  },
  [FEATURE_FLAGS.S3_ENDPOINTS_ENABLED]: {
    enabled: {
      a: true,
      d: true,
      p: false,
    },
  },
  [FEATURE_FLAGS.SHOW_WMS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_STOCK_LIST]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_STOCK_LOCATIONS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.LOCATION_DETAILS]: {
    enabled: {
      a: true,
      d: false,
      p: false,
    },
  },
  [FEATURE_FLAGS.SHOW_TRANSFER_ORDERS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_STOCK_IN]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.KEEP_CATALOG_ARCHIVE]: {
    enabled: {
      a: false,
      d: false,
      p: false,
    },
  },
  [FEATURE_FLAGS.SHOW_POS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_PARTNERS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.AUTOMATIC_ADMIN_JOBS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_SERVICES]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_PAYMENT_METHOD]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_BRANCH_SERVICES]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_BRANCH_PAYMENT_METHODS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_COMPANY_SETTING_OPTIONS]: {
    enabled: { a: true, d: true, p: true },
    general: {
      a: true,
      d: true,
      p: true,
    },
    payment_method: {
      a: true,
      d: true,
      p: true,
    },
    delivery_method: {
      a: true,
      d: true,
      p: true,
    },
    shipping_couriers: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_CUSTOM_PRICE_CATALOG]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_BRANCH_DELIVERY_METHODS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_DOCUMENTS_LOOK]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_GENERATED_PDF]: {
    enabled: {
      a: true,
      d: true,
      p: false,
    },
  },
  [FEATURE_FLAGS.WHEELS_SEARCH]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_INBOUND_INVOICE]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SHOW_APPOINTMENT_CALENDAR]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.STOCK_LIST_IMPORT]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.FINISH_ORDERS]: {
    enabled: {
      a: true,
      d: true,
      p: true,
    },
  },
  [FEATURE_FLAGS.SEND_INVOICE_TO_RITAM]: {
    enabled: {
      a: false,
      d: false,
      p: false,
    },
  },
};

export const isFlagEnabled = (f: FEATURE_FLAGS) => {
  const cleanEnvName = envName || 'p';
  const specificValue = FEATURE_FLAG_CONFIG[f].enabled[cleanEnvName];

  if (typeof specificValue !== undefined) return specificValue;

  return false;
};

export const isFlagOptionEnabled = (f: FEATURE_FLAGS, option: string) => {
  const cleanEnvName = envName || 'p';
  const specificValue = (
    FEATURE_FLAG_CONFIG[f][option] as FeatureFlagEnabledShape
  )[cleanEnvName];

  if (typeof specificValue !== undefined) return specificValue;

  return false;
};
