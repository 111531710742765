import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
// import { genericGenerateSpecialMarkings } from './operations.helpers';

export function getRonalModelData(input: RawImport, _rule: TRule) {
  const modelName = input['Design'].trim().replace(/\s+$/, '');

  return modelName;
}

export function getRonalManufacturerCode(input: RawImport, _rule: TRule) {
  const manufacturerCode = input['Wheel number'].trim().replace(/\s+$/, '');

  return manufacturerCode;
}

export function getRonalColor(input: RawImport, _rule: TRule) {
  let color = input['Colour'].trim().replace(/\s+$/, '');

  if (color === 'polished') {
    color = 'silver polished';
  }

  return color;
}

// export function calculateRonalPackageQuantity(input: RawImport, _rule: TRule) {
//   console.log('SIZE', input['size'])
//   const packageQuantity =
//     parseFloat(input['size'].toLowerCase().split('x')[1].trim()) >= 13 &&
//     parseFloat(input['size'].toLowerCase().split('x')[1].trim()) <= 18
//       ? 2
//       : 1;

//   return packageQuantity;
// }
