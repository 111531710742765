import api from '@tyrio/api-factory';
import { CheckRebateRes, RebateCalculationApi } from '@tyrio/dto';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface GetPriceProps {
  setData: (data: CheckRebateRes) => void;
  setErrorText: (val: string) => void;
}

export const useGetRebate = (props: GetPriceProps) => {
  const { setData, setErrorText } = props;

  const getRebate = useMutation(
    (req: RebateCalculationApi['create']['request']) =>
      api.fetch<RebateCalculationApi['create']>('get_rebate_calc', {
        ...req,
      }),
    {
      mutationKey: 'get_rebate_calc',
      onSuccess: (data) => {
        setData(data);
      },
      onError: (error) => {
        const errorData = error as AxiosError<unknown>;
        setErrorText((errorData.response?.data as ErrorProps)?.error?.name);
      },
    }
  );

  return { getRebate };
};

interface ErrorProps {
  error: { name: string };
}
