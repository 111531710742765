import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table as MuiTable,
  TableBody,
  TextField,
} from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { ISingleItem } from './ModalContent';

export const Table = ({
  items,
  handleCheckboxChange,
  checkedItems,
  handleTextChange,
}: {
  items: ISingleItem[];
  handleCheckboxChange: (key: string, value?: boolean) => void;
  checkedItems: Record<string, boolean>;
  handleTextChange: (key: string, value: number) => void;
}) => {
  return (
    <MuiTable>
      <TableHeader handleCheckboxChange={handleCheckboxChange} />
      <TableBody>
        {items.map((item) => (
          <TableSingleItem
            name={item.name}
            ean={item.ean}
            quantity={item.qty}
            onChange={handleCheckboxChange}
            value={checkedItems[item.ean]}
            handleTextChange={handleTextChange}
          />
        ))}
      </TableBody>
    </MuiTable>
  );
};

const TableHeader = ({
  handleCheckboxChange,
}: {
  handleCheckboxChange: (key: string, value?: boolean) => void;
}) => {
  return (
    <TableHead style={{ background: `${tyrioUI.colors.black.B40}` }}>
      <TableRow>
        <TableCell>
          <Checkbox
            color="info"
            defaultChecked
            onChange={(e) => {
              handleCheckboxChange('all', e.target.checked);
            }}
          />
        </TableCell>
        <TableCell sx={{ color: `${tyrioUI.colors.black.B90}` }}>
          <Typography fontWeight={600}>Product name</Typography>
        </TableCell>
        <TableCell sx={{ color: `${tyrioUI.colors.black.B90}` }}>
          <Typography fontWeight={600}>EAN</Typography>
        </TableCell>
        <TableCell sx={{ color: `${tyrioUI.colors.black.B90}` }}>
          <Typography fontWeight={600}>Qty</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const TableSingleItem = ({
  name,
  ean,
  quantity,
  onChange,
  value,
  handleTextChange,
}: {
  name: string;
  ean: string;
  quantity: number;
  onChange: (key: string, value?: boolean) => void;
  value: boolean;
  handleTextChange: (key: string, value: number) => void;
}) => {
  return (
    <TableRow sx={{ width: '100%' }}>
      <TableCell sx={{ width: '10%', padding: '8px 16px' }}>
        <Checkbox
          color="info"
          checked={value}
          onChange={() => {
            onChange(ean);
          }}
        />
      </TableCell>

      <TableCell sx={{ width: '40%', padding: '8px 16px' }}>
        <Typography>{name}</Typography>
      </TableCell>

      <TableCell sx={{ width: '30%', padding: '8px 16px' }}>
        <Typography>{ean}</Typography>
      </TableCell>

      <TableCell sx={{ width: '20%', padding: '8px 16px' }}>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          defaultValue={quantity}
          onChange={(e) => {
            handleTextChange(ean, Number(e.target.value));
          }}
          type="number"
          inputProps={{ min: 0 }}
        />
      </TableCell>
    </TableRow>
  );
};
