import { DBHttpsConnection } from '@prisma/client';

export const https_connections: DBHttpsConnection[] = [
  {
    id: 'a58a26f1-dbf4-4132-82bb-ae6f31672e98',
    httpsUrl: '#',
    username: '#',
    password: '#',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: 'de607bcd-1bd1-4d92-a422-a5283c1be163',
    remark: '',
    customerId: '#',
  },
  {
    id: 'c95c680d-c983-4964-9668-ef818d423c32',
    httpsUrl: '#',
    username: '#',
    password: '#',
    startUpdateTime: new Date(),
    repeatEvery: 4,
    repeatUntil: 'HOURS',
    supplierConnectionId: '19f2d907-99a5-402b-a813-b1a68427cf62',
    remark: '',
    customerId: '#',
  },
];
