import { FormControlLabel, Switch } from '@mui/material';
import { MenuItem } from '@tyrio/wms-ui-library';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import MenuPopover from '../../../../components/StepsMenu/Popover';
import PopoverButton from '../../../../components/StepsMenu/PopoverButton';
import {
  SwitchWrapper,
  MenuWrapper,
} from '../../../../components/StepsMenu/style/Menu.style';
import { PosCartContext } from '../../../../context/PosCartContext';
import {
  ActiveStep,
  BadgeContent,
  POSContext,
} from '../../../../context/POSContext';
import { Countdown } from '../../../../components/Timer/Countdown';
import { usePosCartData } from '../../steps/Cart/helpers/cart-data';
import { MenuList } from './MenuList';
import {
  PosSearchActiveTab,
  usePosSearchContext,
} from '../SearchPOS/SearchContext';
import { useTranslation } from 'react-i18next';

interface MenuProps {
  shouldDisableMenuItems: boolean;
  shouldEnlarge: boolean;
  setShouldEnlarge: Dispatch<SetStateAction<boolean>>;
  activeStep: ActiveStep;
  setActiveStep: Dispatch<SetStateAction<ActiveStep>>;
  shouldShowDot?: boolean;
  setShouldShowDot?: Dispatch<SetStateAction<boolean>>;
  isVatIncluded?: boolean;
  setIsVatIncluded?: Dispatch<SetStateAction<boolean>>;
}

export const Menu = ({
  shouldDisableMenuItems,
  shouldEnlarge,
  setShouldEnlarge,
  activeStep,
  setActiveStep,
  shouldShowDot,
  setShouldShowDot,
  isVatIncluded,
  setIsVatIncluded,
}: MenuProps) => {
  const [isMenuPopupVisible, setIsMenuPopupVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { modalData } = usePosCartData();
  const { badgeContent } = useContext(POSContext);
  const { timer } = useContext(PosCartContext);
  const { activeTab } = usePosSearchContext();
  const { t } = useTranslation();

  const handleClick = (m: ActiveStep, isDisabled: boolean) => {
    if (!isDisabled) {
      if (m === 'DETAILS' && activeTab === PosSearchActiveTab.wheels_search)
        setActiveStep('WHEEL_DETAILS');
      else setActiveStep(m);
    }
  };

  const displayTime = timer?.getDisplayTime();
  const { minutes, seconds } = displayTime ?? { minutes: 0, seconds: 0 };

  return (
    <MenuWrapper>
      {MenuList.map((m, index) => {
        const shouldDisable = !shouldDisableMenuItems
          ? m.disabled
          : m.text === 'CART' && modalData.length > 0
          ? false
          : m.text !== 'SEARCH' && shouldDisableMenuItems;

        return (
          <MenuItem
            icon={m.icon}
            text={m.text}
            status={m.text === activeStep ? 'HOVER' : 'DEFAULT'}
            disabled={shouldDisable}
            onClick={() => handleClick(m.text as ActiveStep, shouldDisable)}
            isBadgeInvisible={
              m.text !== 'CART' &&
              m.text !== 'BRANCHES' &&
              m.text !== 'SUPPLIERS'
            }
            key={index}
            activeStep={activeStep}
            badgeContent={badgeContent[m.text as keyof BadgeContent] ?? 0}
            componentType="POS"
          />
        );
      })}

      <SwitchWrapper>
        <FormControlLabel
          control={
            <Switch
              color="info"
              onChange={(_e) => {
                setShouldShowDot && setShouldShowDot(!shouldShowDot);
              }}
              checked={shouldShowDot}
            />
          }
          label="DOT"
        />
        <FormControlLabel
          control={
            <Switch
              color="info"
              onChange={(_e) => {
                setIsVatIncluded && setIsVatIncluded(!isVatIncluded);
              }}
              checked={isVatIncluded}
            />
          }
          label={t('VAT')}
        />
      </SwitchWrapper>

      {modalData.length > 0 && (
        <Countdown cart={false} minutes={minutes} seconds={seconds} />
      )}

      <PopoverButton
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        setIsMenuPopupVisible={setIsMenuPopupVisible}
      />

      <MenuPopover
        isMenuPopupVisible={isMenuPopupVisible}
        setIsMenuPopupVisible={setIsMenuPopupVisible}
        shouldEnlarge={shouldEnlarge}
        setShouldEnlarge={setShouldEnlarge}
      />
    </MenuWrapper>
  );
};
