import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { ZPERFORMANCE_CATALOG_RULESET } from './zperformance/catalog';
import { ZPERFORMANCE_PRICELIST_RULESET } from './zperformance/pricelist';
import { ZPERFORMANCE_STOCKLIST_RULESET } from './zperformance/stocklist';

export const ZPERFORMANCE_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: ZPERFORMANCE_CATALOG_RULESET,
  pricelistRuleset: ZPERFORMANCE_PRICELIST_RULESET,
  stockRuleset: ZPERFORMANCE_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: true,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'ZPERFORMANCE',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    Foto: {
      '///': '//z-performance.com/',
    },
    Inch: {
      "''": "",
    },
    PCD: {
      "/": "x",
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !!item['Articlenumber'] &&
          !!item['Quantity'] &&
          parseFloat(item['Quantity']) > 0
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !!item['Articlenumber'] &&
          !!item['Quantity'] &&
          parseFloat(item['Quantity']) > 0
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
