import styled from '@emotion/styled';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabelProps,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioFormError, TyrioViesInput } from '../types';
import { checkIfValidVat } from '../utils';

interface ViesInputFormProps {
  item: TyrioViesInput;
  id: string;
  gridProps: FormGridProps;
  error?: TyrioFormError;
  register: UseFormRegister<FieldValues>;
}
export const ViesInputForm = ({
  item,
  id,
  gridProps,
  error,
  register,
}: ViesInputFormProps) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  // useEffect(() => {
  //   axios({
  //     method: 'get',
  //     url: `${appConfig.apiUrl}/vies/${value}`,
  //   })
  //     .then((res) => {
  //       setVerified(res.data);
  //     })
  //     .catch((e) => console.log(e, 'e'));
  // }, [value, setVerified]);

  return (
    <GridContainer item {...gridProps}>
      <FormControl fullWidth>
        <Container>
          <TextField
            fullWidth
            error={!!error}
            helperText={error?.message}
            id={id}
            disabled={item.disabled}
            required={item.required}
            label={t(`${item.label}`)}
            {...register(id)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Tooltip
                      followCursor
                      title={
                        checkIfValidVat(value)
                          ? t('VIES OK')
                          : t('VIES CHECK FAILED')
                      }
                    >
                      {checkIfValidVat(value) ? (
                        <VerifiedUserIcon color="success" />
                      ) : (
                        <NotInterestedIcon color="error" />
                      )}
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
          />
        </Container>
      </FormControl>
    </GridContainer>
  );
};

interface ValidationProps {
  errorKey: string;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: boolean;
  };
  validationRules: RegisterOptions;
  validationText?: string;
}

interface ViesInputCustomProps {
  key: string;
  label: string;
  value: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  register?: UseFormRegister<FieldValues>;
  disabled?: boolean;
  helperText?: string;
  inputLabelProps?: InputLabelProps;
  validationProps?: ValidationProps;
}

export const ViesInputCustom = ({
  key,
  label,
  error,
  value,
  onChange,
  disabled,
  inputLabelProps,
  validationProps,
}: ViesInputCustomProps) => {
  return (
    <TextField
      {...validationProps?.register(
        validationProps.errorKey,
        validationProps.validationRules
      )}
      fullWidth
      value={value}
      id={key}
      disabled={disabled}
      label={label}
      error={
        (inputLabelProps?.required && !!error) ||
        validationProps?.errors[validationProps?.errorKey]
      }
      helperText={
        validationProps?.errors[validationProps.errorKey] && 'Field is required'
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <Tooltip
                followCursor
                title={checkIfValidVat(value) ? 'VIES OK' : 'VIES CHECK FAILED'}
              >
                {checkIfValidVat(value) ? (
                  <VerifiedUserIcon color="success" />
                ) : (
                  <NotInterestedIcon color="error" />
                )}
              </Tooltip>
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        ...inputLabelProps,
      }}
      onChange={onChange}
    />
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
`;
