import { useState } from 'react';
import CustomDrawerContainer from '../CustomDrawerContainer';
import { ActiveDrawer } from '../../../context/StockListContext';
import {
  FocusedImage,
  ImagesWrapper,
  SingleImageWrapper,
  UnfocusedImage,
  Wrapper,
} from './ImageGallery.style';
import { PhotoShape } from '@tyrio/dto';

interface ImageGalleryProps {
  activeDrawer: ActiveDrawer;
  setActiveDrawer: (a: ActiveDrawer) => void;
  modelImages: PhotoShape[];
}

export const ImageGallery = ({
  activeDrawer,
  setActiveDrawer,
  modelImages,
}: ImageGalleryProps) => {
  const [focusedImage, setFocusedImage] = useState<number>(0);

  const renderImageGallery = () => {
    return (
      <Wrapper>
        <FocusedImage>
          <img
            src={modelImages[focusedImage].url}
            alt="product-model"
            style={{ maxWidth: '90%', maxHeight: '90%' }}
          />
        </FocusedImage>

        <ImagesWrapper>
          {modelImages.map((p, index) => {
            return (
              <SingleImageWrapper focused={index === focusedImage} key={index}>
                <UnfocusedImage
                  src={p.url}
                  onClick={() => setFocusedImage(index)}
                />
              </SingleImageWrapper>
            );
          })}
        </ImagesWrapper>
      </Wrapper>
    );
  };

  return (
    <CustomDrawerContainer
      open={activeDrawer === 'GALLERY'}
      child={renderImageGallery()}
      onClick={() => setActiveDrawer('')}
    />
  );
};
