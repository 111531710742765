import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { DBSupplier } from '@prisma/client';
import { tyrioUI } from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import TransferList from '../../components/TransferList/TransferList';

interface SupplierComparisonFormProps {
  suppliers: DBSupplier[];
  onChangeMainSupplier: (mainSupplierId: string | undefined) => void;
  onChangeSelectedSuppliers: (selectedSuppliersIds: string[]) => void;
}
const SupplierComparisonForm = (props: SupplierComparisonFormProps) => {
  const { suppliers, onChangeMainSupplier, onChangeSelectedSuppliers } = props;
  const [supplierNames, setSupplierNames] = useState<string[]>([]);

  const [allSupplierNames, setAllSupplierNames] = useState<string[]>([]);
  const [mainSupplier, setMainSupplier] = useState<string>('');

  const handleChangeOfMainSupp = (supp: string) => {
    setMainSupplier(supp);

    if (supp !== '' && suppliers && suppliers.length !== 0) {
      const wholeArray = suppliers.map((el: DBSupplier) => el.companyShortName);

      const indexSupp = wholeArray.indexOf(supp);
      const newArray = [
        ...wholeArray.slice(0, indexSupp),
        ...wholeArray.slice(indexSupp + 1, wholeArray.length),
      ];

      setSupplierNames(newArray);
      onChangeMainSupplier(
        suppliers.find((el: DBSupplier) => el.companyShortName === supp)?.id
      );
    } else {
      const wholeArray = suppliers.map((el: DBSupplier) => el.companyShortName);

      setSupplierNames(wholeArray);
      onChangeMainSupplier(undefined);
    }
  };

  useEffect(() => {
    if (suppliers && suppliers.length !== 0) {
      setSupplierNames(suppliers.map((el: DBSupplier) => el.companyShortName));
      setAllSupplierNames(
        suppliers.map((el: DBSupplier) => el.companyShortName)
      );
    }
  }, [suppliers]);

  const handleChangeOfSelectedSuppliers = (value: string[]) => {
    const arrayOfIds: string[] = [];
    suppliers.forEach((el: DBSupplier) => {
      if (value.find((newEl) => newEl === el.companyShortName))
        arrayOfIds.push(el.id);
    });
    onChangeSelectedSuppliers(arrayOfIds);
  };

  return (
    <SupplierPickerWrapper>
      <FormControl sx={{ minWidth: '30vh' }}>
        <InputLabel id={'supplier_picker_id'}> Main supplier</InputLabel>
        <Select
          labelId={'supplier_picker_id'}
          label={'Main supplier'}
          fullWidth={true}
          defaultValue={mainSupplier}
          value={mainSupplier.length ? mainSupplier : ''}
          endAdornment={
            <Close
              style={{
                width: '15px',
                height: '15px',
                position: 'absolute',
                right: '35px',
                cursor: 'pointer',
              }}
              onClick={() => handleChangeOfMainSupp('')}
            ></Close>
          }
          onChange={(event) => handleChangeOfMainSupp(event.target.value)}
        >
          {allSupplierNames.map((supplier: string) => (
            <MenuItem key={`supplier_dropdown_${supplier}`} value={supplier}>
              {supplier}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider textAlign={'left'} sx={{ marginTop: `${tyrioUI.spacing.l}px` }}>
        <DividerLabel>Choose suppliers</DividerLabel>
      </Divider>
      {supplierNames.length !== 0 && (
        <TransferListWrapper>
          <TransferList
            leftList={supplierNames}
            rightList={[]}
            key={`transfer_list_${supplierNames.length}_${mainSupplier}`}
            onRightListChanged={handleChangeOfSelectedSuppliers}
          ></TransferList>
        </TransferListWrapper>
      )}
    </SupplierPickerWrapper>
  );
};

const SupplierPickerWrapper = styled.div`
  margin-left: 64px;
`;

const TransferListWrapper = styled.div`
  margin-top: ${tyrioUI.spacing.xl}px;
`;

const DividerLabel = styled.label`
  color: ${tyrioUI.colors.darkGrey};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export default SupplierComparisonForm;
