import styled from '@emotion/styled/macro';
import Button from '@mui/material/Button/Button';
import { DBProductApi, PhotoShape } from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import { useRef } from 'react';
import EprelToPrint from '../ProductDetails/EprelToPrint';
import ProductEprelGenerator from '../ProductDetails/ProductEprelGenerator';
import ReactToPrint from 'react-to-print';
import PhotoIcon from '@mui/icons-material/Photo';

interface ProductHeaderProps {
  title?: string | null;
  img?: string;
  model?: string | null;
  tags: string[];
  product?: DBProductApi['getOne']['response'];
  photo?: PhotoShape;
}

const ProductHeader = ({
  title,
  img,
  model,
  tags,
  product,
  photo,
}: ProductHeaderProps) => {
  const componentRef = useRef<HTMLDivElement>(null);

  console.log('component ref', componentRef);

  return (
    <Container isProduct={!!product}>
      {img ? (
        <Image src={img} alt="model-picture" />
      ) : product && product.euDirectiveNumber ? (
        <PrintWrapper>
          <div style={{ display: 'none' }}>
            <EprelToPrint ref={componentRef} product={product} />
          </div>
          <ProductEprelGenerator product={product} />
          <ReactToPrint
            trigger={() => (
              <Button
                variant="outlined"
                color="info"
                sx={{ width: '80px', height: '40px', marginTop: '10px' }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
            bodyClass="print-container"
          />
        </PrintWrapper>
      ) : (
        <>
          <ImageWrapper style={{ display: '' }}>
            {photo ? (
              <img
                src={photo.url}
                alt="category"
                style={{
                  maxHeight: '200px',
                  maxWidth: '230px',
                }}
              />
            ) : (
              <NoImage>
                <PhotoIcon
                  color="disabled"
                  sx={{
                    maxHeight: '200px',
                    maxWidth: '230px',
                    height: '100%',
                    width: '100%',
                  }}
                />
                <div style={{ color: 'gray' }}>No image</div>
              </NoImage>
            )}
          </ImageWrapper>
          <Wrapper>
            <Brand>{title}</Brand>
            <Model>{model}</Model>
            <Tags>
              {tags.map((el) => (
                <Tag key={el}>{el}</Tag>
              ))}
            </Tags>
          </Wrapper>
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ isProduct: boolean }>`
  width: 348px;
  padding: ${tyrioUI.spacing.l}px;
  height: ${(props) => (props.isProduct ? 'auto' : '360px')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border: 1px dashed #dfe3e8;
  border-radius: 16px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Image = styled.img`
  height: 230px;
`;

const PrintWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Brand = styled.div`
  margin-top: 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  width: 100%;
`;

const Model = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  width: 100%;
`;
const Tags = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

const Tag = styled.div`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background: rgb(145, 158, 171, 0.2);
  border-radius: 5px;
  padding: 3px;
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  height: 230px;
  width: 240px;
  align-items: center;
  justify-content: center;
`;

export default ProductHeader;
