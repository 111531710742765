import styled from '@emotion/styled/macro';
import { Divider } from '@mui/material';
import {
  DBProductApi,
  //  DBProductCategoryApi
} from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
// import { useMemo, useState } from 'react';
import { MonthlySales } from './MonthlySales';
// import { ProductReview } from './ProductReview';
import { YearlySales } from './YearlySales';
// import { useQuery } from 'react-query';
// import api from '@tyrio/api-factory';

interface ProductReviewWithStatsProps {
  selectedProduct?: DBProductApi['getOne']['response'];
  numberOfAvailableItems?: number;
  numberOfBackorderItems?: number;
  numberOfReservedItems?: number;
}
export const ProductReviewWithStats = (props: ProductReviewWithStatsProps) => {
  // const [selectedProduct] = useState(props.selectedProduct);
  const selectedProduct = props.selectedProduct;
  // const [numberOfAvailableItems] = useState(props.numberOfAvailableItems);
  // const [numberOfBackorderItems] = useState(props.numberOfBackorderItems);
  // const [numberOfReservedItems] = useState(props.numberOfReservedItems);

  // const { data: mainCategories } = useQuery(['all_categories'], () =>
  //   api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  // );

  // const subcategory = useMemo(() => {
  //   return mainCategories?.find(
  //     (c) => c.id === selectedProduct?.model?.category_id
  //   );
  // }, [mainCategories, selectedProduct?.model?.category_id]);

  //
  // const parentCategoryName = useMemo(() => {
  //   return mainCategories?.find(
  //     (c) => c.id === subcategory.parent_category_id
  //   );
  // }, [mainCategories, selectedProduct?.model?.category_id]);

  return (
    <div>
      {selectedProduct ? (
        <GeneralWrapper>
          {/* <ProductReview
            productName={selectedProduct.productName}
            ean={selectedProduct.ean}
            ipc={selectedProduct.manufacturerCode}
            brand={selectedProduct.model?.brand || ''}
            numOfAvailable={numberOfAvailableItems || 0}
            numOfBackorder={numberOfBackorderItems || 0}
            numOfReserved={numberOfReservedItems || 0}
            parentCategory={subcategory?.parent_category_name?.toUpperCase()}
            category={subcategory?.name.toUpperCase()}
            season={selectedProduct?.model?.season?.toUpperCase()}
          /> */}
          <Divider />
          <MonthlySales />
          <Divider />
          <YearlySales />
        </GeneralWrapper>
      ) : null}
    </div>
  );
};

const GeneralWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${tyrioUI.spacing.l}px;
`;
