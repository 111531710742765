import { isNaN } from 'lodash';
import { z } from 'zod';

export const PaymentValidation = z
  .object({
    code: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    name: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    description: z.string().optional(),
    paymentType: z
      .string({
        required_error: 'This field is required!',
        invalid_type_error: 'This field is required!',
      })
      .nonempty({ message: 'This field is required!' }),
    priceType: z.string().or(z.null()).optional(),
    cashDiscount: z.boolean().optional(),
    restrictions: z
      .object({
        minAmount: z.number().or(z.nan()).optional(),
        maxAmount: z.number().or(z.nan()).optional(),
      })
      .refine(
        (o) => {
          if (
            o.maxAmount !== undefined &&
            o.minAmount !== undefined &&
            !isNaN(o.maxAmount) &&
            !isNaN(o.minAmount)
          )
            return Number(o.maxAmount) > Number(o.minAmount);
          else return true;
        },
        {
          message: 'Value should be greater than min amount!',
          path: ['maxAmount'],
        }
      )
      .optional(),

    isFiscalizationEnabled: z.boolean().optional(),
    fiscalType: z.string(),
    paymentGateway: z.string().optional(),
    cardSettings: z
      .object({
        installements: z.string().optional(),
        acceptedCards: z
          .array(z.object({ label: z.string(), value: z.string() }).or(z.nan()))
          .optional(),
      })
      .optional(),
  })
  .refine((data) => !(data.isFiscalizationEnabled && data.fiscalType === ''), {
    message: 'This field is required!',
    path: ['fiscalType'],
  });
