/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '@tyrio/api-factory';
import { DBCollectionApi } from '@tyrio/dto';
import { useQuery } from 'react-query';
import { prepOrders } from '../components/mapping';

export const useGetCollection = (
  enabled: boolean,
  setWorkOrders: any,
  setReadyWorkOrders: any
) => {
  const { data, error, isFetched, refetch, status, isLoading, isFetching } =
    useQuery(
      [`get_collections`],
      () => api.fetch<DBCollectionApi['list']>('get_collections'),
      {
        enabled,
        refetchInterval: 180000,
        onSuccess: (res) => {
          const data = prepOrders(res as any[], 'COLLECTION');
          setWorkOrders((prevState: any) => ({
            ...prevState,
            COLLECTION: data.openOrders,
          }));

          setReadyWorkOrders((prevState: any) => ({
            ...prevState,
            COLLECTION: data.openOrders,
          }));
        },
      }
    );

  return {
    data: data,
    isFetched,
    error,
    refetch,
    status,
    showLoader: isLoading || isFetching,
  };
};
