import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function calculateReifenMuellerPackageQuantity(
  input: RawImport,
  _rule: TRule
) {
  const packageQuantity = parseFloat(input['Breite']) > 255 ? 1 : 2;

  return packageQuantity;
}

export function globalGetReifenMuellerSpecialMarkings(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetReifenMuellerSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = input['Profilzusatz'];

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => ['RFT', 'ROF'].includes(input['Profilzusatz']),
    false
  );

  return data;
}
