import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { INTERSPRINT_CATALOG_RULESET } from './inter-sprint/catalog';
import { INTERSPRINT_PRICELIST_RULESET } from './inter-sprint/pricelist';
import { INTERSPRINT_STOCKLIST_RULESET } from './inter-sprint/stocklist';

export const INTERSPRINT_SUPPLIER: SupplierShape = {
  weight: 8,
  override: true,
  ruleset: INTERSPRINT_CATALOG_RULESET,

  pricelistRuleset: INTERSPRINT_PRICELIST_RULESET,
  stockRuleset: INTERSPRINT_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'INTERSPRINT',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    weight: {
      ',': '.',
    },
    available: {
      '>  ': '',
    },
    'brand description': {
      'B.F. GOODRICH': 'BFGOODRICH',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    // console.log('READ FILE', file);
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter((item) =>
        ['11', '13', '16', '17', '23', '24', '25', '28', '29', '33'].includes(
          item['group']
        )
      ),
      this.preprocessKeys
    );
  },
};
