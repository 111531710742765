import {
  LineItemPrintShape,
  ProductsTableProperties,
  RowDetail,
} from '../../../../context/PrintContext';
import { TableHeader } from './Header';
import { TableRow } from './Row';

interface OrderTableProps {
  lineItems: LineItemPrintShape[];
  tableProps: ProductsTableProperties;
}

export const OrderTable = ({ lineItems, tableProps }: OrderTableProps) => {
  const prepareRowDetails = () => {
    return lineItems.map((item) => ({
      name: `${item?.brand ?? ''} ${item.productName}`,
      details: `${item.sku} · ${item.ean} · DOT ${item.dot}`,
      weight: item.weight ?? 0,
      quantity: item.quantity,
      totalWeight: Number(item.weight) * item.quantity ?? 0,
    })) as unknown as RowDetail[];
  };

  return (
    <div>
      <TableHeader tableProps={tableProps} />
      {prepareRowDetails() &&
        prepareRowDetails().length > 0 &&
        prepareRowDetails().map((lineItem, index) => (
          <TableRow
            key={index}
            rowNumber={index + 1}
            rowDetails={lineItem}
            tableProps={tableProps}
          />
        ))}
    </div>
  );
};
