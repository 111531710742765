import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ServicesPage } from '../pages/dashboard/services/ServicesPage';
import Services from '../pages/dashboard/services/Services';

export const ServicesRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const isNew = location.pathname.split('/').pop() === 'new';

  return (
    <Switch>
      <Services>
        {isNew ? (
          <Route exact path={`${path}/new`}>
            <ServicesPage isNew={isNew} />
          </Route>
        ) : (
          <Route exact path={`${path}/:id`}>
            <ServicesPage isNew={isNew} />
          </Route>
        )}
      </Services>
    </Switch>
  );
};
