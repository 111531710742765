import { Accordion, TextField } from '@mui/material';
import { AccordionSummaryStyled } from './FullCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SingleCartItem } from './SingleCartItem';
import { Icon } from './Icon';
import { DBTransferOrderType } from '@prisma/client';
import { CartItemsProps } from './helper/interfaces';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../../hooks/useDebounce';
import { DBBranchesApi } from '@tyrio/dto';
import api from '@tyrio/api-factory';
import { useQuery } from 'react-query';

export const CartItems = ({
  expandedData,
  i,
  branchName,
  branchCity,
  branchId,
  handleDelete,
  handleRemarkChange,
  cartValues,
  type,
  setExpandedData,
  remarkValue,
  warehouseId,
}: CartItemsProps) => {
  const expandDataFunction = (type: DBTransferOrderType) => {
    const idx = expandedData[type as keyof typeof expandedData].indexOf(i);

    if (idx === -1) expandedData[type as keyof typeof expandedData].push(i);
    else {
      expandedData[type as keyof typeof expandedData].splice(idx, 1);
    }

    setExpandedData({ ...expandedData });
  };

  const [remarkVal, setRemarkVal] = useState<string | undefined>(remarkValue);

  const debouncedRemarkValue = useDebounce(remarkVal, 1000);

  useEffect(() => {
    handleRemarkChange(
      debouncedRemarkValue,
      branchId + '_' + branchName + '_' + branchCity + '_' + warehouseId,
      type
    );
  }, [
    branchCity,
    branchId,
    branchName,
    debouncedRemarkValue,
    handleRemarkChange,
    type,
    warehouseId,
  ]);

  const { data: branchData, isSuccess } = useQuery(
    [`${warehouseId}`, branchId, 'update_branch'],
    () =>
      api.fetch<DBBranchesApi['getOne']>(`get_branch_by_id`, {
        id: warehouseId,
      }),
    {
      enabled: !!branchId,
    }
  );

  return (
    <div>
      {isSuccess ? (
        <Accordion
          expanded={expandedData[type].includes(i)}
          square
          defaultExpanded
          sx={{
            padding: '8px',
            boxShadow: '0px 1px 0px #DFE3E8',
          }}
          elevation={expandedData[type].includes(i) ? 0 : 1}
        >
          <AccordionSummaryStyled
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => {
              expandDataFunction(type);
            }}
          >
            {type === DBTransferOrderType.INBOUND
              ? branchName
              : branchData.branchName}
            <Icon type={type} />
            {type === DBTransferOrderType.INBOUND
              ? branchData.branchName
              : branchName}
          </AccordionSummaryStyled>
          <div style={{ borderTop: '1px solid #E6E8F0' }}>
            {Object.entries(cartValues).map(([key, value], index: number) => {
              if (key === 'remark') {
                return null;
              }
              return (
                <SingleCartItem
                  item={value}
                  key={index}
                  handleDelete={handleDelete}
                  branchId={Number(branchId)}
                  branchName={branchName}
                  branchCity={branchCity}
                  type={type}
                  warehouseId={warehouseId}
                />
              );
            })}

            <TextField
              rows={3}
              key="remark"
              label="Remark"
              value={remarkVal}
              sx={{ margin: '24px 0' }}
              onChange={(e) => setRemarkVal(e.target.value)}
            />
          </div>
        </Accordion>
      ) : (
        <div></div>
      )}
    </div>
  );
};
