import { toast, ToastContainer } from 'react-toastify';

export const CartToast = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export const customToast = (text: string, type: 'success' | 'error') => {
  toast[type](`${text}`, {
    position: 'top-right',
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};
