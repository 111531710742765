export const couriers = [
  {
    id: '00000000-0000-1000-sc00-0000000000001',
    name: 'General Logistics System Croatia d.o.o.',
    abbreviation: 'GLS Croatia',
    shippingWebsite: 'https://gls-group.com/HR/hr/home/',
  },
  {
    id: '00000000-0000-1000-sc00-0000000000002',
    name: 'DPD Croatia.',
    abbreviation: 'DPD Croatia',
    shippingWebsite: 'https://www.dpd.com/hr/hr/',
  },
  {
    id: '00000000-0000-1000-sc00-0000000000003',
    name: 'GLS Croatia (Dropship).',
    abbreviation: 'GLS Croatia (Dropship)',
    shippingWebsite: 'https://gls-group.com/HR/hr/home/',
  },
  {
    id: '00000000-0000-1000-sc00-0000000000004',
    name: 'DPD Croatia (Dropship).',
    abbreviation: 'DPD Croatia (Dropship)',
    shippingWebsite: 'https://www.dpd.com/hr/hr/',
  },
];
