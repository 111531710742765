import styled from '@emotion/styled/macro';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Modal } from '@mui/material';
import { StockInLineItem } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { QuantityComponent } from '@tyrio/wms-ui-library';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Footer } from '../../../../components/Modal/Footer';
import { StockInContext } from '../../../../context/StockInContext';
import { useMoveStockInItems } from '../queries/move-stock-in-items';
import { CircularProgress } from '@mui/material';

interface IConfirmModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
}

export const ConfirmationModal = ({
  open,
  setOpen,
  id,
}: IConfirmModalProps) => {
  const { checkedLineItems, setCheckedLineItems, selectedOrderData } =
    useContext(StockInContext);

  const { moveStockInItems, isLoading } = useMoveStockInItems(
    id,
    checkedLineItems,
    setOpen,
    setCheckedLineItems
  );

  const handleQuantityChange = (updatedValue: number, idx: number) => {
    const updatedItems = [...(checkedLineItems ?? [])];

    updatedItems[idx] = {
      ...updatedItems[idx],
      received: updatedValue === 0 ? 1 : updatedValue,
      quantity: updatedValue,
      ordered: updatedValue,
    };

    setCheckedLineItems(updatedItems);
  };

  const handleClose = () => {
    setOpen(false);
    setCheckedLineItems([]);
  };

  const handleConfirm = () => {
    const lineItems =
      selectedOrderData?.lineItems as unknown as StockInLineItem[];

    const sortedCheckedLineItems = checkedLineItems
      .map((item) => JSON.stringify(item))
      .sort();
    const sortedLineItems = lineItems
      .map((item) => JSON.stringify(item))
      .sort();

    if (
      JSON.stringify(sortedCheckedLineItems) === JSON.stringify(sortedLineItems)
    )
      ToastHelper.showToast(
        'Split stock in',
        ToastType.WARNING,
        ToastMessageType.UPDATE,
        `You can't move all the items!`
      );
    else moveStockInItems();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <BoxStyled>
        <CloseWrapper>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </CloseWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between  ',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <TitleWrapper>
            <Title>{`MOVING ITEMS TO NEW STOCK IN`}</Title>
            <ProductCodes>{`Are you sure you want to move selected items to new stock in?`}</ProductCodes>
          </TitleWrapper>
        </Box>
        <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {checkedLineItems &&
            checkedLineItems.length > 0 &&
            checkedLineItems.map((item, idx) => {
              const lineItems =
                selectedOrderData?.lineItems as unknown as StockInLineItem[];
              const lineItem = lineItems.find(
                (i) => i.ean === item.ean && i.sku === item.sku
              );
              return (
                <RowWrapper>
                  <ProductDetails key={idx}>
                    <ProductName>{`${
                      item.brand ? item.brand.toUpperCase() : ''
                    } ${item.productName}`}</ProductName>
                    <EanIpcSkuWrapper>
                      <div>EAN: {`${item.ean} `}</div>
                      <div>IPC: {`${item.manufacturerCode} `}</div>
                      <div>SUPPLIER CODE: {`${item.sku} `}</div>
                    </EanIpcSkuWrapper>
                  </ProductDetails>

                  <QuantityComponent
                    quantity={lineItem ? lineItem.received : item.received}
                    maxAvailableQuantity={
                      lineItem ? lineItem.received : item.received
                    }
                    isDisabled={false}
                    cart={false}
                    showButton={false}
                    defaultQty={item.received}
                    index={idx}
                    handleClick={handleQuantityChange}
                    changeQtyOnClick={true}
                  />
                </RowWrapper>
              );
            })}
        </Box>
        {isLoading ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <Footer
            setOpen={setOpen}
            handleSubmit={handleConfirm}
            confirmText="CONFIRM"
          />
        )}
      </BoxStyled>
    </Modal>
  );
};

const ProductCodes = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #919eab;
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: Barlow;
  font-weight: 600;
  font-size: 20px;
`;

export const LocationTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const LocationTypeText = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-right: 20px;
`;

export const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 24;
  min-height: 300px;
  padding: 24px;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProductName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 370px;
`;

const EanIpcSkuWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  max-width: 400px;
  flex-direction: row;
  color: #919eab;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
