import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
// import { genericGenerateSpecialMarkings } from './operations.helpers';

export function calculateAutecPackageQuantity(input: RawImport, _rule: TRule) {
  const packageQuantity =
    parseFloat(input['Hauptmass']) >= 13 && parseFloat(input['Hauptmass']) <= 18
      ? 2
      : 1;

  return packageQuantity;
}

export function getAutecModel(input: RawImport, _rule: TRule) {
  const modelName = input['Design'].split('-')[0]; // Take only text before minus (Ex. instead of Zenit-si take Zenit)

  return modelName;
}
