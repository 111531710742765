import styled from '@emotion/styled/macro';
import {
  DBBranchStockListResponse,
  DBStockList,
  DBStockListApi,
  MainBranchLocations,
  PhotoShape,
} from '@tyrio/dto';
import { LandingPage, usersIcon } from '@tyrio/ui-library';
import { useCallback, useContext, useEffect, useState } from 'react';

// import { CircularProgress, Modal } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import api from '@tyrio/api-factory';
import _, { isEmpty, isEqual } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import { useAuth } from '../../../context/AuthContext';
import { CartContext } from '../../../context/CartContext';
import FilterProvider, {
  ActiveDrawer,
  ActiveStep,
  StockListInputShape,
} from '../../../context/StockListContext';
import { StockListOverview } from '../../../features/stock-list/StockListOverview';
import {
  useGetBranchesStock,
  useGetMainBranchesStock,
} from '../../../features/stock-list/components/Branches/queries/branches-stock';
import { useCartData } from '../../../features/stock-list/components/Cart/helper/cart-helper';
import { CartData } from '../../../features/stock-list/components/Cart/helper/interfaces';
import { useFilter } from '../../../hooks/useFilter';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Drawers } from '../../../components/Drawers/Drawer';
import { Menu } from './components/Menu/Menu';
import { Steps } from './components/Steps/Steps';
import { formatOutboundOrderItems } from './components/helper/update';

interface StockListProps {
  sidebarOpen: boolean;
}

interface OutboundOrdersFilterProps {
  branchId: number[];
}

const STOCK_LIST_KEY = '@@sl';
const StockList = ({ sidebarOpen }: StockListProps) => {
  const user = useAuth();
  const history = useHistory();
  const [input, setInput] = useLocalStorage<StockListInputShape>(
    STOCK_LIST_KEY,
    {
      activeStep: 'SEARCH',
      activeDrawer: '',
      dirty: false,
      selectedTableRow: null,
      shouldEnlarge: false,
      warehouseId: user.user?.currentBranchId?.toString(),
    }
  );

  //  if there is no data, landing page should be displayed, else stocklistoverview
  const isThereProducts = false;
  const [activeStep, setActiveStep] = useState<ActiveStep>(input.activeStep);
  const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>(
    input.activeDrawer
  );
  const [selectedTableRow, setSelectedTableRow] = useState<DBStockList | null>(
    input.selectedTableRow
  );

  const [selectedLocation, setSelectedLocation] =
    useState<MainBranchLocations | null>(null);
  const { filterValues, ...filtersHook } = useFilter({});
  const [cartItems, setCartItems] = useState<CartData[]>([]);
  const [orderSent, setOrderSent] = useState<boolean>(false);
  const [shouldDisableMenuItems, setShouldDisableMenuItems] = useState(false);
  const [onlyAvailable, setOnlyAvailable] = useState<boolean>(
    filterValues?.['onlyAvailable'] === 'true'
  );
  const [shouldEnlarge, setShouldEnlarge] = useState(input.shouldEnlarge);
  const [outboundOrdersFilter, setOutboundOrdersFilter] = useState<
    OutboundOrdersFilterProps | Record<string, never>
  >({});

  const [outboundOrders, setOutboundOrders] = useState<
    DBBranchStockListResponse[]
  >([]);
  const [outboundOrderItems, setOutboundOrderItems] = useState<
    DBBranchStockListResponse[]
  >([]);

  const [outboundError, setOutboundError] = useState<boolean>(false);

  const [isMainBranchSelected, setIsMainBranchSelected] = useState(true);

  const warehouseId = filterValues['warehouseId'] as string;

  // CART CONTEXT
  const { availableItems, setAvailableItems } = useContext(CartContext);

  // CART DATA
  const { newCartOutboundData, hasItems } = useCartData();

  // HOOK: FETCH SECONDARY (branch that is not warehouse) BRANCHES STOCK
  const { branchesStock, refetchBranchesStock, isLoading } =
    useGetBranchesStock(
      selectedTableRow?.ean ?? '1',
      warehouseId,
      availableItems,
      setAvailableItems
    );

  // HOOK: FETCH MAIN BRANCH STOCK
  const { mainBranchStock, refetchMainBranchesStock, mainBranchLoading } =
    useGetMainBranchesStock(
      selectedTableRow?.ean ?? '1',
      warehouseId,
      availableItems,
      setAvailableItems
    );

  const { data: filterOptions } = useQuery(
    ['get_filters'],
    () => api.fetch<DBStockListApi['getFilters']>(`get_filters`),
    {}
  );

  const setInputValue = useCallback(
    (newData: Partial<StockListInputShape>, hardSet?: boolean) => {
      setInput((oldValue) => {
        if (hardSet) {
          return Object.assign({}, { dirty: true, ...newData });
        }
        return _.cloneDeep(_.mergeWith(oldValue, { dirty: true }, newData));
      });
    },
    [setInput]
  );

  // UPDATE LOCAL STORAGE
  useEffect(() => {
    // this will update value on refresh
    window.onbeforeunload = function (event) {
      setInputValue(
        {
          activeStep,
          activeDrawer,
          dirty: false,
          selectedTableRow,
          shouldEnlarge,
          warehouseId: filterValues['warehouseId'] as string,
        },
        true
      );
      event.preventDefault();
      event.returnValue = '';
    };

    if (!isEqual(selectedTableRow, input.selectedTableRow))
      setInputValue(
        {
          ...input,
          selectedTableRow,
          warehouseId,
        },
        true
      );
  }, [
    activeDrawer,
    activeStep,
    filterValues,
    input,
    selectedTableRow,
    setInputValue,
    shouldEnlarge,
    warehouseId,
  ]);

  useEffect(() => {
    setOrderSent(false);
  }, [selectedTableRow, activeStep]);

  // UPDATE OUTBOUND ORDER ITEMS ARRAY WITH ITEMS FROM CART, THIS WILL HAPPEN ON PAGE REFRESH
  useEffect(() => {
    if (
      newCartOutboundData.length > outboundOrderItems.length &&
      !isEmpty(newCartOutboundData) &&
      isEmpty(outboundOrders) &&
      hasItems
    )
      setOutboundOrderItems(formatOutboundOrderItems(newCartOutboundData));
  }, [
    hasItems,
    newCartOutboundData,
    outboundOrderItems.length,
    outboundOrders,
    setOutboundOrderItems,
  ]);

  const userMainBranch = user.user?.currentBranchId ?? null;

  // This useEffect is only called when the page loads for the first time and
  // only if user don't have selected main branch or there is no branch selected in filterValues

  useEffect(() => {
    if (!userMainBranch) {
      setIsMainBranchSelected(false);
    }

    if (userMainBranch && !warehouseId) {
      setIsMainBranchSelected(true);
      filtersHook.setFilterValue({
        ...filterValues,
        searchType: 'sku',
        warehouseId: userMainBranch.toString(),
      });
    }
  }, [
    filterValues,
    filtersHook,
    refetchBranchesStock,
    refetchMainBranchesStock,
    userMainBranch,
    warehouseId,
  ]);

  useEffect(() => {
    if (selectedTableRow && mainBranchStock && warehouseId) {
      const main = (
        mainBranchStock as DBBranchStockListResponse[]
      )[0]?.lineItems.filter(
        (f) => f.stockListItemId === (selectedTableRow as DBStockList).id
      );
      if (
        main !== undefined &&
        main[0]?.reserved !== selectedTableRow?.reserved
      ) {
        refetchBranchesStock();
        refetchMainBranchesStock();
      }
    }
  }, [
    branchesStock,
    mainBranchStock,
    refetchBranchesStock,
    refetchMainBranchesStock,
    selectedTableRow,
    warehouseId,
  ]);

  useEffect(() => {
    if (!isLoading && !mainBranchLoading) {
      if (!mainBranchStock) refetchMainBranchesStock();
      if (!branchesStock) refetchBranchesStock();

      if (mainBranchStock && branchesStock) {
        if (isEmpty(mainBranchStock) || isEmpty(branchesStock))
          setOutboundOrders([]);
        else
          setOutboundOrders([
            ...(mainBranchStock as DBBranchStockListResponse[]),
            ...(branchesStock as DBBranchStockListResponse[]),
          ]);
      }
    }
  }, [
    branchesStock,
    isLoading,
    mainBranchLoading,
    mainBranchStock,
    refetchBranchesStock,
    refetchMainBranchesStock,
  ]);

  /* DO NOT DELETE - leave until everything is tested */
  // useEffect(() => {
  //   //IF THERE IS outboundOrderItems UPDATE mainBranch QTY
  //   if (
  //     mainBranchStock &&
  //     branchesStock &&
  //     outboundOrderItems.length > 0 &&
  //     !isEqual(outboundOrders, [
  //       ...(mainBranchStock as DBBranchStockListResponse[]),
  //       ...(branchesStock as DBBranchStockListResponse[]),
  //     ])
  //   ) {
  //     updateOutboundArray(
  //       mainBranchStock as DBBranchStockListResponse[],
  //       outboundOrderItems
  //     );
  //   }
  // }, [branchesStock, mainBranchStock, outboundOrderItems, outboundOrders]);

  return (
    <PageTemplateWrapper>
      {!isMainBranchSelected ? (
        <Alert
          severity="warning"
          style={{ height: '250px', position: 'relative', width: '100%' }}
        >
          <AlertTitle>Configuration Required: Main Branch</AlertTitle>
          <AlertContainer>
            To access the <strong>Stock List</strong> feature, you need to
            configure your main branch in your user settings. Follow these
            steps:
            <br />
            <StepsToSettings>
              <p>
                1. Go to <strong>Menu - Settings - Users</strong>.
              </p>
              <p>2. Select your user account.</p>
              <p>
                3. In the <strong>Branches</strong> tab, choose your{' '}
                <strong> Main branch</strong>.
              </p>
            </StepsToSettings>
            Once you've completed these steps, you can start using the{' '}
            <strong>Stock List</strong> feature.
          </AlertContainer>
          <ButtonContainer>
            <Button
              onClick={() =>
                history.push(
                  `/dashboard/company-settings/users/${user.user?.id}`
                )
              }
              color="warning"
            >
              Go to User Settings
            </Button>
          </ButtonContainer>
        </Alert>
      ) : (
        <FilterProvider
          value={{
            filterValues: filterValues,
            setFilterValue: filtersHook.setFilterValue,
            setFilterValues: filtersHook.setFilterValues,
            resetFilters: filtersHook.resetFilters,
            cartItems,
            setCartItems,
            orderSent,
            setOrderSent,
            selectedTableRow,
            setSelectedTableRow,
            activeStep,
            setActiveStep,
            activeDrawer,
            setActiveDrawer,
            onlyAvailable,
            setOnlyAvailable,
            sidebarOpen,
            outboundOrdersFilter,
            setOutboundOrdersFilter,
            shouldEnlarge,
            setShouldEnlarge,
            selectedLocation,
            setSelectedLocation,
            input,
            setInput,
            setOutboundOrders,
            outboundOrderItems,
            setOutboundOrderItems,
            outboundOrders,
            filterOptions: filterOptions ?? null,
            outboundError,
            setOutboundError,
          }}
        >
          <>
            <PageTemplateContent
              style={{
                width: shouldEnlarge
                  ? 'calc(100% - 60% - 70px)'
                  : 'calc(100% - 736px)',
                transition: 'all 0.3s ease',
              }}
            >
              {isThereProducts ? (
                <LandingPage
                  title={'Welcome to Stock List'}
                  subtitle={'Choose product category on right side'}
                  cyId={'create-new-stock-list-landing'}
                  buttonText={'Switch user'}
                  onClick={() => console.log('Stock list')}
                  headerText="Stock list"
                  buttonIcon={usersIcon}
                />
              ) : (
                <StockListOverview
                  setShouldDisableMenuItems={setShouldDisableMenuItems}
                />
              )}
            </PageTemplateContent>
            <PageTemplateContent
              style={{
                background: '#f5f6f8',
                minWidth: shouldEnlarge ? '60%' : '714px',
                maxWidth: shouldEnlarge ? '60%' : '714px',
                marginRight: '0px',
                height: 'calc(100svh - 44px)',
                zIndex: 99,
                transition: 'all 0.3s ease',
              }}
            >
              <MenuWrapper>
                <DrawerWrapper>
                  {activeDrawer &&
                    Drawers({
                      activeDrawer,
                      setActiveDrawer,
                      selectedTableRow,
                      modelImages: selectedTableRow?.product?.model
                        ?.photos as unknown as PhotoShape[],
                    })}
                  {activeStep && Steps(activeStep)}
                </DrawerWrapper>
                <Menu
                  shouldDisableMenuItems={shouldDisableMenuItems}
                  shouldEnlarge={shouldEnlarge}
                  setShouldEnlarge={setShouldEnlarge}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              </MenuWrapper>
            </PageTemplateContent>
          </>
          {/* )} */}
        </FilterProvider>
      )}
    </PageTemplateWrapper>
  );
};

// const LoadingPage = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const ModalWrapper = styled(Modal)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const ModalContent = styled.div`
//   width: 350px;
//   height: 250px;
//   background: white;
//   border-radius: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   padding: 20px;
//   text-align: center;
// `;

const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

const DrawerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const AlertContainer = styled.div`
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const StepsToSettings = styled.div`
  padding-left: 10px;
`;

export default StockList;
