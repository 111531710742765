import styled from '@emotion/styled';
import { useState } from 'react';

interface RimSizeCardProps {
  value: string;
  size: string;
  selectedValue?: string;
  handleClick: () => void;
}

export const RimSizeCard = ({
  value,
  size,
  selectedValue,
  handleClick,
}: RimSizeCardProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <CardContainer
      onClick={() => handleClick()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <BlueSection focused={hovered}>{value}</BlueSection>
      <TransparentSection focused={hovered} selected={selectedValue === value}>
        {size + value}
      </TransparentSection>
    </CardContainer>
  );
};

const CardContainer = styled.button`
  display: flex;
  width: 100%;
  max-width: 330px;
  height: 80px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  border: none;
  background: none;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const BlueSection = styled.div<{ focused: boolean }>`
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #1976d2;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const TransparentSection = styled.div<{ focused: boolean; selected: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  border-radius: 0px 8px 8px 0px;
  border: 2px solid
    ${(props) => (props.focused || props.selected ? '#1976d2' : '#dfe3e8')};
  border-left: none;
  background: ${(props) => props.selected && 'rgba(25, 118, 210, 0.15)'};
`;
