import { TRule } from '../../typings';

export const SUPERIOR_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['ArtNr']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Bestand']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
  {
    type: 'rule.operate',
    sourceKey: '',
    destination: 'product',
    targetKey: 'packageQuantity',
    operationName: 'calculateSuperiorPackageQuantity',
  },
];
