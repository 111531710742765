import * as z from "zod"
import { CompleteDBService, RelatedDBServiceModel, CompleteDBAppointments, RelatedDBAppointmentsModel } from "./index"

export const DBServiceAppointmentsModel = z.object({
  serviceId: z.string(),
  appointmentId: z.string(),
  quantity: z.number().int(),
})

export interface CompleteDBServiceAppointments extends z.infer<typeof DBServiceAppointmentsModel> {
  service: CompleteDBService
  appointment: CompleteDBAppointments
}

/**
 * RelatedDBServiceAppointmentsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBServiceAppointmentsModel: z.ZodSchema<CompleteDBServiceAppointments> = z.lazy(() => DBServiceAppointmentsModel.extend({
  service: RelatedDBServiceModel,
  appointment: RelatedDBAppointmentsModel,
}))
