import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 5px;
  padding: 0 16px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 16px 0;
  padding: 0 16px;
`;

export const ProductCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${tyrioUI.colors.black.B40};
  padding: 8px 0px;
  padding-left: 16px;
  border-radius: 8px;
`;

export const TotalWrapper = styled.div`
  background: #637381;
  padding: 0 8px 8px 8px;
  border-radius: 0 0 8px 8px;
`;

export const CreatedWrapper = styled.div`
  background: ${tyrioUI.colors.black.B20};
  color: ${tyrioUI.colors.black.B70};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  margin: 16px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  margin: 15px 0;
`;

export const PaymentTypeTitleTypography = styled.div`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const PaymentTypeCodeTypography = styled.div`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #919eab;
`;
export const PaymentNameTypography = styled.div`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
`;

export const PaymentDetailsWrapper = styled.div`
  background-color: #f9fafb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  padding: 5px;
  height: 120px;
`;

export const CardInfo = styled.div`
  font-family: Barlow;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
`;

export const InfoTypography = styled.div<{ color?: string; weight?: number }>`
  color: ${(props) => (props.color ? props.color : '')};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
`;

export const StockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  align-items: flex-end;
  width: 100%;
  max-width: 280px;
  min-width: 280px;
  justify-content: space-around;
  font-size: 12px;
  font-family: Barlow;
  font-weight: 500;
  gap: 15px;
`;

export const TableHeaderWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: end;
`;
