import styled from '@emotion/styled/macro';
import { Box, Grid, Tab, Tabs } from '@mui/material';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: React.CSSProperties;
}

export const TabContent = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Grid
      hidden={value !== index}
      {...other}
      style={{ height: 'calc(100% - 160px)', overflowY: 'auto' }}
    >
      {value === index && (
        <Box sx={{ paddingTop: '16px', height: '100%' }}>{children}</Box>
      )}
    </Grid>
  );
};

export const tabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const TabsWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1;
  border-color: divider;
  position: relative;
`;

export const TabsStyled = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1976D2',
  },
  '& .MuiButtonBase-root-MuiTab-root': {
    textTransform: 'unset',
  },
  width: '100%',
});

export const TabStyled = styled(Tab)({
  textTransform: 'none',
  '&:hover': {
    color: '#1976D2',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1976D2',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#1976D2',
    color: '#1976D2',
  },
});
