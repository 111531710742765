import * as z from "zod"
import { DBStockListImportStatusEnum } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBStockListImportLogModel = z.object({
  id: z.string(),
  clientId: z.string(),
  startedAt: z.date(),
  finishedAt: z.date().nullish(),
  ftpInfo: jsonSchema,
  request: jsonSchema,
  response: jsonSchema,
  errors: jsonSchema,
  status: z.nativeEnum(DBStockListImportStatusEnum).nullish(),
})

export interface CompleteDBStockListImportLog extends z.infer<typeof DBStockListImportLogModel> {
  client: CompleteDBClient
}

/**
 * RelatedDBStockListImportLogModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBStockListImportLogModel: z.ZodSchema<CompleteDBStockListImportLog> = z.lazy(() => DBStockListImportLogModel.extend({
  client: RelatedDBClientModel,
}))
