import * as z from "zod"
import { DBDCollectionOrderType } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBFile, RelatedDBFileModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBCollectionsModel = z.object({
  id: z.string(),
  clientId: z.string(),
  orderId: z.string(),
  orderType: z.nativeEnum(DBDCollectionOrderType),
  orderDetails: jsonSchema,
  stockOutItems: jsonSchema,
  recipient: z.string(),
  recipientSignatureId: z.string().nullish(),
})

export interface CompleteDBCollections extends z.infer<typeof DBCollectionsModel> {
  client: CompleteDBClient
  recipientSignature?: CompleteDBFile | null
}

/**
 * RelatedDBCollectionsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBCollectionsModel: z.ZodSchema<CompleteDBCollections> = z.lazy(() => DBCollectionsModel.extend({
  client: RelatedDBClientModel,
  recipientSignature: RelatedDBFileModel.nullish(),
}))
