import { FormController, generateFiscalizationForm } from '@tyrio/forms';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import { PageTemplateContent } from '../../../components/PageTemplate/PageTemplate';

const FiscalizationForm = () => {
  const { form, zodSchema } = useMemo(() => generateFiscalizationForm(), []);
  const history = useHistory();

  const handleOnCancel = () => {
    history.push(`/dashboard/fiscalization`);
  };

  return (
    <PageTemplateContent>
      <FormController<z.infer<typeof zodSchema>>
        form={form}
        isLoading={false}
        zodSchema={zodSchema}
        onSubmit={console.log}
        onCancel={handleOnCancel}
        title="company name"
        gridSpacing={4}
      />
    </PageTemplateContent>
  );
};

export default FiscalizationForm;
