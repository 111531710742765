import React, { useEffect, useRef, useState } from 'react';
import { getRecaptchaVerifier } from './helpers';
import {
  ConfirmationResult,
  RecaptchaVerifier,
  sendSignInLinkToEmail,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { fbAuth } from './fb';
import { appConfig } from '@tyrio/config';
import routeNames from '../../../../lib/routeNames';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import { useMutation, useQuery } from 'react-query';
import api, { TOKEN } from '@tyrio/api-factory';
import { DBUserApi, DBUserAuthApi } from '@tyrio/dto';
import { useAuth } from '../../../../context/AuthContext';
import { LoginUI } from './LoginStyledComponents';
import { useTranslation } from 'react-i18next';
import style from './TyrioLogin.module.scss';

import {
  Alert,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button/Button';
import { useDebounce } from '../../../../hooks/useDebounce';
import VerificationInput from 'react-verification-input';
import { FirebaseError } from 'firebase-admin/lib/utils/error';

interface FormShape {
  phone: string;
  email: string;
  countryCode: string;
  otp: string;
}
const TyrioLogin = () => {
  const { t } = useTranslation();
  const { refetchUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [, setIsExistingUser] = useState(true);
  const [codeSentCount, setCodeSentCount] = useState(1);
  const [emailTriggered, setEmailTriggered] = useState(false);
  const [existingUserResponse, setExistingUserResponseResponse] =
    useState(false);
  const INITIAL_ERROR_STATE = {
    phone: '',
    email: '',
  };
  const [error, setError] =
    useState<Record<string, string | null>>(INITIAL_ERROR_STATE);
  const [form, setForm] = useState<FormShape>({
    phone: '',
    email: '',
    countryCode: '+385',
    otp: '',
  });
  const [busy, setBusy] = useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);

  const reCaptcha = useRef<RecaptchaVerifier | null>();
  const phoneNumber = form.countryCode + form.phone;

  const debouncedPhoneNumber = useDebounce(phoneNumber, 400);
  const debouncedEmail = useDebounce(form.email, 400);

  const phoneQ = useQuery(
    ['get_user_by_phone', debouncedPhoneNumber],
    () =>
      api.fetch<DBUserApi['getOne']>(`get_user_by_phone`, {
        mobilePhone: debouncedPhoneNumber,
      }),
    {
      retry: false,
      enabled: !!(phoneNumber && phoneNumber.length >= 5),
      onSuccess: (data: boolean) => {
        setExistingUserResponseResponse(data);
        setIsExistingUser(true);
      },
      onError: () => {
        setIsExistingUser(false);
        setExistingUserResponseResponse(false);
      },
    }
  );

  const emailQ = useQuery(
    ['get_user_by_email', debouncedEmail],
    () =>
      api.fetch<DBUserApi['getOne']>(`get_user_by_email`, {
        email: debouncedEmail,
      }),
    {
      retry: false,
      enabled: !!(form.email && form.email.length >= 5),
      onSuccess: (data: boolean) => {
        setExistingUserResponseResponse(data);
        setIsExistingUser(true);
      },
      onError: () => {
        setIsExistingUser(false);
        setExistingUserResponseResponse(false);
      },
    }
  );

  useEffect(() => {
    reCaptcha.current = getRecaptchaVerifier('recaptcha-container', fbAuth);
  }, []);

  const handleSignIn = () => {
    let hasError = false;
    if (!form.phone && !form.email) {
      hasError = true;
      setError({
        ...error,
        phone: t('One of these fields is required !'),
        email: t('One of these fields is required !'),
      });
    }
    if (hasError) {
      setBusy(false);
      return;
    }
    if (!existingUserResponse) {
      setErrorMessage(
        `No existing user with this ${
          isDisabled['email'] ? 'phone number' : 'email address'
        }!`
      );

      return;
    }

    if (!form.email && form.phone) signInWithPhone();
    if (form.email && !form.phone) signInWithEmail();
  };
  const signInWithPhone = async () => {
    setBusy(true);

    if (!reCaptcha.current) return;
    if (phoneNumber === '' || phoneNumber.length < 10) return;

    const phoneSigninResponse = await signInWithPhoneNumber(
      fbAuth,
      phoneNumber,
      reCaptcha.current
    );
    setConfirmationResult(phoneSigninResponse);
    setBusy(false);
  };
  const signInWithEmail = async () => {
    setEmailTriggered(true);
    const actionCodeSettings = {
      url: appConfig.appUrl + routeNames.dashboardOverview(),
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(fbAuth, form.email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', form.email);
        ToastHelper.showToast(
          'Login',
          ToastType.SUCCESS,
          undefined,
          'The Email link has been successfully sent!'
        );
      })
      .catch((error) => {
        console.log('Something went wrong !', error.code, error.message);
        if (
          window.confirm(
            `Something went wrong ! Error: ${error.message} Please reload page and try login again! `
          )
        ) {
          window.location.reload();
        }
        setEmailTriggered(false);
      });
  };

  const loginMutation = useMutation(
    ['token_validate'],
    (t: string) =>
      api.fetch<DBUserAuthApi['validateToken']>('token_validate', {
        token: t,
        type: 'phone_number',
      }),
    {}
  );

  // Validate OTP
  const validateOtp = async () => {
    setBusy(true);
    if (form.otp === '' || !confirmationResult) return;
    confirmationResult
      .confirm(form.otp)
      .then(async (result) => {
        const idTokenResult = await result.user.getIdTokenResult();
        const data = await loginMutation.mutateAsync(idTokenResult.token);
        TOKEN.set(data.token);
        await refetchUser();

        window.location.href = routeNames.dashboardOverview();
        setBusy(false);
      })
      .catch((error: FirebaseError) => {
        if (error?.code) {
          switch (error.code) {
            case 'auth/invalid-verification-code':
              setErrorMessage('Invalid verification code');
              break;
            default:
              setErrorMessage('Something went wrong. Please try again.');
              break;
          }
        }
        setBusy(false);
      });
  };
  const isDisabled = {
    phone: form.email.length > 0,
    email: form.phone.length > 0,
  };
  const handleFooterText = () => {
    let footerText;
    if (isDisabled['email']) {
      footerText =
        'We will send you OTP verification code on your phone after you click SEND';
      return footerText;
    }

    if (isDisabled['phone']) {
      footerText =
        'We will send you verification link on your email after you click SEND';
      return footerText;
    }
    return 'Please choose your method for login!';
  };

  return (
    <LoginUI.Container>
      <LoginUI.LoginHeader>
        <LoginUI.TypographyTitle gutterBottom variant="h4">
          {t('Login')}
        </LoginUI.TypographyTitle>
        {busy && <CircularProgress size={35} />}
      </LoginUI.LoginHeader>
      <div id="recaptcha-container" />
      {!confirmationResult && (
        <LoginUI.LoginBody>
          <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ width: '100%' }}>
            <Grid item xs={5} sm={3}>
              <FormControl fullWidth style={{ minWidth: '93px' }}>
                <InputLabel id="country-code-label">
                  {t('Country code')}
                </InputLabel>
                <Select
                  labelId="country-code-select-label"
                  id="country-code-select"
                  defaultValue="+385"
                  label={t('Country code')}
                  onChange={(e) => {
                    setError(INITIAL_ERROR_STATE);
                    setErrorMessage(null);
                    setForm({ ...form, countryCode: e.target.value });
                  }}
                  disabled={isDisabled['phone']}
                >
                  <MenuItem value="+1">+1</MenuItem>
                  <MenuItem value="+381">+381</MenuItem>
                  <MenuItem value="+385">+385</MenuItem>
                  <MenuItem value="+387">+387</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={7} sm={9}>
              <TextField
                fullWidth
                value={form.phone}
                onChange={(e) => {
                  setError(INITIAL_ERROR_STATE);
                  setErrorMessage(null);
                  setForm((v) => ({ ...v, phone: e.target.value }));
                }}
                id="phone-number"
                label={t('Phone number')}
                type="string"
                error={!!error['phone']}
                helperText={error['phone']}
                variant="outlined"
                disabled={isDisabled['phone']}
              />
            </Grid>
            <LoginUI.DividerContainer>
              <LoginUI.Border />
              <LoginUI.Divider>{t('or login with')}</LoginUI.Divider>
              <LoginUI.Border />
            </LoginUI.DividerContainer>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={form.email}
                onChange={(e) => {
                  setError(INITIAL_ERROR_STATE);
                  setErrorMessage(null);
                  setForm((v) => ({ ...v, email: e.target.value }));
                }}
                id="email"
                label={t('E-mail')}
                type="email"
                error={!!error['email']}
                helperText={error['email']}
                variant="outlined"
                disabled={isDisabled['email']}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  handleSignIn();
                }}
                color="info"
                size="large"
                fullWidth
                variant="contained"
                disabled={
                  emailTriggered || (!phoneQ.isSuccess && !emailQ.isSuccess)
                }
                disableElevation
              >
                {t('SEND')}
              </Button>
            </Grid>
          </Grid>
          <LoginUI.Spacer />
          <LoginUI.LoginFooter>
            <LoginUI.TypographyFooter>
              {t(handleFooterText())}
            </LoginUI.TypographyFooter>
          </LoginUI.LoginFooter>
        </LoginUI.LoginBody>
      )}
      {confirmationResult && (
        <>
          <LoginUI.TypographyOTP>
            {t('Enter OTP code we sent on your phone')}
          </LoginUI.TypographyOTP>
          <LoginUI.TypographyOTP>{phoneNumber}</LoginUI.TypographyOTP>
          <VerificationInput
            autoFocus
            value={form.otp}
            onChange={(value) => {
              setForm({ ...form, otp: value });
            }}
            removeDefaultStyles
            validChars="0-9"
            placeholder=""
            classNames={{
              container: style['container'],
              character: style['character'],
              characterSelected: style['character--selected'],
            }}
          />
          <Button
            type="submit"
            onClick={validateOtp}
            variant="contained"
            disabled={form.otp.length !== 6}
            color="info"
            fullWidth
            style={{ marginBottom: '20px' }}
            disableElevation
          >
            {t('Login')}
          </Button>
          <Divider />
          <LoginUI.OTPFooter>
            {t('Didn’t receive the OTP code?')}
            {codeSentCount > 1 && (
              <>
                <br />
                Code resent!
              </>
            )}
            <Button
              color="info"
              disabled={codeSentCount === 4}
              onClick={async () => {
                setCodeSentCount((v) => v + 1);
              }}
            >
              {t('Send again')}
            </Button>
            {codeSentCount === 4 && (
              <Alert severity="warning" style={{ width: '100%' }}>
                <b>
                  {t(
                    'You have reached the limit for code retries. Please wait a bit and try again!'
                  )}
                </b>
              </Alert>
            )}
          </LoginUI.OTPFooter>
        </>
      )}
      {errorMessage && (
        <Alert severity="error" style={{ width: '100%', marginTop: 16 }}>
          {t(errorMessage)}
        </Alert>
      )}
    </LoginUI.Container>
  );
};

export default TyrioLogin;
