import api from '@tyrio/api-factory';
import { DBEdiOrderApi } from '@tyrio/dto';
import { useMutation } from 'react-query';

export const useEDIOrderMutation = () => {
  return useMutation(
    'edi_order',

    (data: DBEdiOrderApi['create_edi_order']['request']) =>
      api.fetch<DBEdiOrderApi['create_edi_order']>('edi_order', { ...data }),
    {}
  );
};
