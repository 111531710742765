import { CircularProgress } from '@mui/material';
import { DBProductApi } from '@tyrio/dto';
import { ProductDetailsGenerator } from '@tyrio/products';
import { tyrioIcons } from '@tyrio/ui-library';
import { UnselectedRowWarning, wmsIcons } from '@tyrio/wms-ui-library';
import { useContext } from 'react';
import { LoaderWrapper } from '../../../../components/PageTemplate/PageTemplate';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { usePosCartCtx } from '../../../../context/PosCartContext';
import { POSContext } from '../../../../context/POSContext';
import { AddItemsFooter } from '../../components/AddItemsFooter';
import { AdImg, AdWrapper, ContentWrapper, Wrapper } from './Details.style';
import DetailsStock from './DetailsStock';

export const Details = () => {
  const { setActiveDrawer, selectedTableRow } = useContext(POSContext);
  const { cartData } = usePosCartCtx();

  const isLoading = false;

  const renderDetails = () => {
    return (
      <Wrapper>
        {selectedTableRow && selectedTableRow.product && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <ProductDetailsGenerator
              product={
                selectedTableRow.product as unknown as DBProductApi['getOne']['response']
              }
              stockListItemId={selectedTableRow.id ?? ''}
              isFavoriteByUser={selectedTableRow.isFavorite}
              setActiveDrawer={setActiveDrawer}
              selectedTableRow={selectedTableRow}
            />

            <AdWrapper>
              <AdImg src={tyrioIcons.smallContiAd} alt="continental-ad" />
            </AdWrapper>

            <ContentWrapper>
              <DetailsStock stockType={selectedTableRow.stockType} />
            </ContentWrapper>
          </div>
        )}

        {isLoading && (
          <div style={{ marginTop: '20px' }}>
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          </div>
        )}

        {!selectedTableRow && (
          <UnselectedRowWarning
            icon={wmsIcons.details}
            text="product details"
          />
        )}
      </Wrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posDetails"
      children={renderDetails()}
      showSwitch={false}
      checked={false}
      shouldShowTitle={false}
      footer={selectedTableRow && cartData.length > 0 && <AddItemsFooter />}
    />
  );
};
