import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Divider,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
} from '@mui/material';
import { DBWarehouseBins, DBWarehouseShelfs } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBProductApi,
  DBStockListApi,
  LocationType,
  MainBranchLocations,
  UpdateStockItem,
} from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../../query-client';
import { useGetAllWarehouseLocations } from '../ProductDetails/queries/main-branch-stock';
import { ChooseLocation } from './ChooseLocation';
import { Footer } from '../../../../components/Modal/Footer';
import { LocationName } from './LocationName';
import {
  BoxStyled,
  CloseWrapper,
  Title,
  TitleWrapper,
} from './style/common.style';
import { isEmpty, sumBy } from 'lodash';
import { StockListContext } from '../../../../context/StockListContext';
import { useAuth } from '../../../../context/AuthContext';

interface IEditStockProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  product: DBProductApi['getOne']['response'];
  locations: MainBranchLocations[];
}

export const EditStockModal = ({
  open,
  setOpen,
  product,
  locations,
}: IEditStockProps) => {
  const { user } = useAuth();
  const { input } = useContext(StockListContext);
  const [shelves, setShelves] = useState<Record<string, DBWarehouseShelfs>>({});
  const [bins, setBins] = useState<Record<string, DBWarehouseBins>>({});
  const [locationType, setLocationType] = useState<
    Record<string, LocationType>
  >({});

  const [editStockPayload, setEditStockPayload] = useState<UpdateStockItem[]>(
    []
  );

  const [shelvesDropdownData, setShelvesDropdownData] = useState<
    TyrioSelectInputOption[]
  >([]);
  const [binsDropdownData, setBinsDropdownData] = useState<
    TyrioSelectInputOption[]
  >([]);

  const { isSuccess } = useGetAllWarehouseLocations(
    setShelves,
    setBins,
    Number(input.warehouseId) ?? Number(user?.mainBranchId)
  );

  useEffect(() => {
    if (locations) {
      const formInitialPayload = locations.map((location) => {
        return {
          key: location?.locationMeta.qrCode ?? '', // old location
          location: {
            id: '',
            qrCode: '',
          },
          ean: location?.ean ?? '',
          productionYear: location?.productionYear ?? '',
          oldQuantity: location?.quantity ?? 0,
          quantity: location.quantity ?? 0,
          reserved: location.reserved ?? 0,
          putaway: location.putaway ?? 0,
          dot: location.dot ?? 'XXXX',
          branchId: location.clientStockList.branchId ?? 1,
          moveToNewLocation: false,
          quantityOnNewLocation: 0,
          reservedOnNewLocation: 0,
          putawayOnNewLocation: 0,
          locationType: 'SHELF' as unknown as LocationType,
          shouldSkip: true,
          oldPutaway: 0,
          oldReserved: 0,
        };
      });
      setEditStockPayload(formInitialPayload);
    }
  }, [locations]);

  useEffect(() => {
    if (isSuccess) {
      const shelvesData: TyrioSelectInputOption[] = [];
      const binsData: TyrioSelectInputOption[] = [];
      // shelves data
      Object.entries(shelves).forEach((key, _value) => {
        shelvesData.push({
          label: key[0],
          value: key[1].id,
        });
      });
      setShelvesDropdownData(shelvesData);
      // bins data
      Object.entries(bins).forEach((key, _value) => {
        binsData.push({
          label: key[0],
          value: key[1].id,
        });
      });
      setBinsDropdownData(binsData);
    }
  }, [bins, isSuccess, shelves]);

  const mainFields = ['putaway', 'reserved'];
  const secondaryFields = ['reservedOnNewLocation', 'putawayOnNewLocation'];

  const handleChange = (
    index: number,
    field: string,
    value:
      | string
      | number
      | LocationType
      | { id: string; qrCode: string }
      | boolean
  ) => {
    if ([...mainFields, ...secondaryFields].includes(field)) {
      const otherLocationsWithSameItem = locations.filter(
        (l) =>
          l.ean === locations[index].ean &&
          l.dot === locations[index].dot &&
          l.locationMeta.qrCode !== locations[index].locationMeta.qrCode
      );

      if (!isEmpty(otherLocationsWithSameItem)) {
        let newPutawayValue = sumBy(otherLocationsWithSameItem, 'putaway');
        let newReservedValue = sumBy(otherLocationsWithSameItem, 'reserved');

        if (mainFields.includes(field)) {
          newPutawayValue += Number(value);
          newReservedValue += Number(value);
        }

        if (secondaryFields.includes(field)) {
          newPutawayValue += locations[index].putaway ?? 0;
          newReservedValue += locations[index].reserved;
        }

        if (field === 'putaway' || field === 'putawayOnNewLocation') {
          editStockPayload[index].oldPutaway = newPutawayValue;
        } else {
          editStockPayload[index].oldReserved = newReservedValue;
        }
      }
    }

    setEditStockPayload((prevState) => {
      const newEditStockItems = [...prevState];
      newEditStockItems[index] = {
        ...newEditStockItems[index],
        [field]: value,
        shouldSkip: false,
      };
      return newEditStockItems;
    });
  };

  const editStockListItem = useMutation(
    () => {
      return api.fetch<DBStockListApi['updateOne']>(
        'edit_stock_list_item',
        editStockPayload
      );
    },
    {
      mutationKey: 'edit_stock_list_item',
      onSuccess: () => {
        ToastHelper.showToast(
          'Item',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        queryClient.refetchQueries('get_stock_list_products');
        queryClient.refetchQueries('main_branch_stock');
        setOpen(false);
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error?.response?.data?.error.name;
          ToastHelper.showToast(
            'Product',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <BoxStyled>
        <CloseWrapper>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{ cursor: 'pointer' }}
          />
        </CloseWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between  ',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <TitleWrapper>
            <Title>{`${product.model?.brand}  ${product.productName}`}</Title>
            <ProductCodes>{`SKU: ${product.sku} EAN: ${product.ean} IPC: ${product.manufacturerCode}`}</ProductCodes>
          </TitleWrapper>
        </Box>
        <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
        <Box style={{ overflowY: 'auto', maxHeight: '450px', height: '100%' }}>
          {locations &&
            locations.length > 0 &&
            editStockPayload.length > 0 &&
            locations.map((location, index) => (
              <div key={location.locationMeta.qrCode}>
                <LocationName
                  zoneColor={location.locationMeta.zoneColor}
                  qrCode={location.locationMeta.qrCode}
                />
                <StockOnCurrentLocationWrapper>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="quantityOnStock"
                    label="Quantity on stock"
                    placeholder="Quantity on stock"
                    sx={{ width: '40%' }}
                    value={
                      editStockPayload[index].quantity !== 0
                        ? editStockPayload[index].quantity
                            .toString()
                            .replace(/^0+/, '')
                        : 0
                    }
                    type="number"
                    onChange={(e) =>
                      handleChange(index, 'quantity', Number(e.target.value))
                    }
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="reservedStock"
                    label="Reserved from stock"
                    placeholder="Reserved from stock"
                    sx={{ width: '40%' }}
                    value={
                      editStockPayload[index].reserved !== 0
                        ? editStockPayload[index].reserved
                            .toString()
                            .replace(/^0+/, '')
                        : 0
                    }
                    type="number"
                    onChange={(e) =>
                      handleChange(index, 'reserved', Number(e.target.value))
                    }
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="putawayStock"
                    label="Putaway from stock"
                    placeholder="Putaway from stock"
                    sx={{ width: '40%' }}
                    value={
                      editStockPayload[index].putaway !== 0
                        ? editStockPayload[index].putaway
                            .toString()
                            .replace(/^0+/, '')
                        : 0
                    }
                    type="number"
                    onChange={(e) =>
                      handleChange(index, 'putaway', Number(e.target.value))
                    }
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        id="moveToNewLocation"
                        color="info"
                        disabled={false}
                        defaultChecked={
                          editStockPayload[index].moveToNewLocation
                        }
                        onClick={() =>
                          handleChange(
                            index,
                            'moveToNewLocation',
                            !editStockPayload[index].moveToNewLocation
                          )
                        }
                      />
                    }
                    label="Move"
                  />
                </StockOnCurrentLocationWrapper>
                {editStockPayload[index].moveToNewLocation && (
                  <div>
                    <ChooseLocation
                      locationType={locationType}
                      setLocationType={setLocationType}
                      value={
                        locationType[location.locationMeta.qrCode] === 'SHELF'
                          ? shelvesDropdownData.find(
                              (item) =>
                                item.label ===
                                editStockPayload[index].location.qrCode
                            )
                          : binsDropdownData.find(
                              (item) =>
                                item.label ===
                                editStockPayload[index].location.qrCode
                            )
                      }
                      qrCode={location.locationMeta.qrCode}
                      shelvesDropdownData={shelvesDropdownData}
                      binsDropdownData={binsDropdownData}
                      handleChange={(newValue: TyrioSelectInputOption) =>
                        handleChange(index, 'location', {
                          id: newValue.value.toString(),
                          qrCode: newValue.label.toString(),
                        })
                      }
                    />
                    <FormWrapper>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="newLocationQuantity"
                        label="New location quantity"
                        placeholder="New location quantity"
                        sx={{ marginBottom: '15px' }}
                        value={
                          editStockPayload[index].quantityOnNewLocation ?? 0
                        }
                        type="number"
                        onChange={(e) =>
                          handleChange(
                            index,
                            'quantityOnNewLocation',
                            Number(e.target.value)
                          )
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="newLocationReserved"
                        label="New location reserved"
                        placeholder="New location reserved"
                        sx={{ marginBottom: '15px' }}
                        value={
                          editStockPayload[index].reservedOnNewLocation ?? 0
                        }
                        type="number"
                        onChange={(e) =>
                          handleChange(
                            index,
                            'reservedOnNewLocation',
                            Number(e.target.value)
                          )
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="newLocationPutaway"
                        label="New location putaway"
                        placeholder="New location putaway"
                        sx={{ marginBottom: '15px' }}
                        value={
                          editStockPayload[index].putawayOnNewLocation !== 0
                            ? editStockPayload[index].putawayOnNewLocation
                                .toString()
                                .replace(/^0+/, '')
                            : 0
                        }
                        type="number"
                        onChange={(e) =>
                          handleChange(
                            index,
                            'putawayOnNewLocation',
                            Number(e.target.value)
                          )
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                      />
                    </FormWrapper>
                  </div>
                )}
              </div>
            ))}
        </Box>
        <Footer
          setOpen={setOpen}
          handleSubmit={() => editStockListItem.mutate()}
        />
      </BoxStyled>
    </Modal>
  );
};

const FormWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 700px;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ProductCodes = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #919eab;
`;

const StockOnCurrentLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  gap: 10px;
`;
