/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-concat */

import styled from '@emotion/styled';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';
import { DBPriceRound, DBRebateCalculationType } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBClientRebateCalculationApi } from '@tyrio/dto';
import SourceContainer from '@tyrio/forms';
import {
  CancelModal,
  DeleteModal,
  RouteRouterPrompt,
  Title,
  ToastHelper,
  ToastMessageType,
  ToastType,
  backIcon,
} from '@tyrio/ui-library';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { queryClient } from '../../../query-client';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';
import { RulesList } from '../price-calculation/components/Rules/RulesList';
import {
  IJsonLogic,
  INewCondition,
  RulesContext,
} from '../price-calculation/context/RulesContext';
import { ComponentType } from '../price-calculation/helper/enum';
import CheckRebatesModal from './CheckRebates/CheckRebates';
import { CustomerRebatesForm } from './CustomerRebatesForm';
import { updateRules } from './helper/RulesHelper';

const CustomerRebatesOverview = () => {
  const history = useHistory();
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { rebateId } = useParams<{ rebateId: string }>();

  // RULES LIST DATA
  const [rules, setRules] = useState<any>([]);
  const newRuleCondition = useRef<INewCondition[]>([]);
  const updatedConditions = useRef<IJsonLogic[]>([
    { jsonLogic: '', ruleIndex: null },
  ]);
  // REGISTERS IF CHANGE HAPPENED IN RULE LIST
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState<any>([]);

  const {
    data: rebateGroupData,
    isFetching: isRebateDataFetching,
    refetch: getRebateById,
  } = useQuery(
    ['get_rebate_by_id', rebateId, 'update_rebate'],
    () =>
      api.fetch<DBClientRebateCalculationApi['getOne']>(`get_rebate_by_id`, {
        id: rebateId,
      }),
    {
      enabled: !!rebateId,
      onSuccess: (res) => {
        setRules(res?.rules);
        updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
      },
    }
  );

  const updateRebateGroupStatus = useMutation(
    () =>
      api.fetch<DBClientRebateCalculationApi['updateOne']>(
        'change_rebate_status',
        {
          id: rebateId,
        }
      ),
    {
      mutationKey: 'update_rebate_status',
      onSuccess: () => {
        queryClient.refetchQueries('get_rebates');
        getRebateById();
        ToastHelper.showToast(
          'Customer rebate',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Customer rebate',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const deleteCustomerRebateMutation = useMutation(
    () =>
      api.fetch<DBClientRebateCalculationApi['getOne']>(
        'delete_customer_rebate',
        {
          id: rebateId,
        }
      ),
    {
      mutationKey: 'delete_customer_rebate',
      onSuccess: () => {
        queryClient.refetchQueries('get_rebates');
        history.push(`/dashboard/customer-rebates`);
        ToastHelper.showToast(
          'Customer rebates',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Customer rebates',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const createRebate = useMutation(
    (data: DBClientRebateCalculationApi['create']['requestBody']) => {
      return api.fetch<DBClientRebateCalculationApi['create']>(
        'create_customer_rebate',
        {
          ...data,
        }
      );
    },
    {
      mutationKey: 'create_customer_rebate',
      onSuccess: (item) => {
        queryClient.refetchQueries('get_rebates');
        ToastHelper.showToast(
          'Customer rebate',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/customer-rebates/${item.id}`);
        getRebateById();
        setIsSaving(false);
        updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        const errorMessage = data.response.data.error.name;
        ToastHelper.showToast(
          'Customer rebate',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          errorMessage ?? 'An error occurred!'
        );
      },
    }
  );

  const updateRebate = useMutation(
    (data: DBClientRebateCalculationApi['create']['requestBody']) => {
      return api.fetch<DBClientRebateCalculationApi['updateOne']>(
        'update_customer_rebate',
        {
          id: rebateId,
          ...data,
        }
      );
    },
    {
      mutationKey: 'update_customer_rebate',
      onSuccess: (item) => {
        queryClient.refetchQueries('get_rebates');
        ToastHelper.showToast(
          'Customer rebate',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        setRules(item?.rules);
        updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
        history.push(`/dashboard/customer-rebates/${item.id}`);
        getRebateById();
        setIsSaving(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        const errorMessage = data.response.data.error.name;
        ToastHelper.showToast(
          'Customer rebate',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          errorMessage ?? 'An error occurred!'
        );
      },
    }
  );

  const [calculationType, setCalculationType] = useState(
    rebateGroupData?.calculationType || DBRebateCalculationType.INVOICE
  );

  const generateDefaultValues = useCallback(() => {
    if (!rebateGroupData) {
      return {
        groupName: '',
        groupType: '',
        rebate: 0,
        discount: 0,
        priceRound: DBPriceRound.NO_ROUND,
        calculationType: DBRebateCalculationType.INVOICE,
        rules: [],
      };
    }

    return {
      groupName: rebateGroupData.groupName,
      groupType: rebateGroupData.groupType,
      rebate: rebateGroupData.rebate,
      discount: rebateGroupData.discount,
      priceRound: rebateGroupData.priceRound,
      calculationType: rebateGroupData.calculationType,
      rules: rebateGroupData.rules,
    };
  }, [rebateGroupData]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...generateDefaultValues() } as any,
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: true,
    resolver: (formValues) => {
      const validate = ['groupType', 'rebate', 'discount'];
      const errors: Record<string, string> = {};
      if (formValues['groupName'].trim() === '')
        errors['groupName'] = 'This field is required!';
      validate.forEach((item, index) => {
        if (formValues[validate[index]] === '') {
          errors[validate[index]] = 'This field is required!';
        }
      });

      if (rules) {
        rules.forEach((item: any, ruleIndex: number) => {
          if (item?.ruleDescription === '') {
            errors['ruleDescription' + '_' + ruleIndex] =
              'This field is required!';
          }

          if (
            item?.conditions === '' &&
            updatedConditions.current.find((c) => c.ruleIndex === item.id)
              ?.jsonLogic === ''
          ) {
            errors['conditions'] = 'This field is required!';
          }

          if (item?.actions.length === 0)
            errors['actions'] = 'This field is required!';

          if (item?.actions && item?.actions.length > 0) {
            const validateActionKeys = [
              'discount',
              'rebates',
              'price_range_max',
              'price_range_min',
            ];

            item?.actions.forEach((action: any, actionIndex: number) => {
              validateActionKeys.forEach((key) => {
                if (!action?.[key]) {
                  errors[key + '_' + ruleIndex + '_' + actionIndex] =
                    'This field is required!';
                }
              });

              if (
                action?.price_range_min &&
                action?.price_range_max &&
                Number(action?.price_range_min) >
                  Number(action?.price_range_max)
              ) {
                errors[
                  'price_range_max' + '_' + ruleIndex + '_' + actionIndex
                ] = 'Price range max must be greater than price range min!';
              }
            });
          }
        });
      }

      return {
        values: formValues,
        errors,
      };
    },
  });

  useEffect(() => {
    if (Object.values(errors).length > 0) {
      ToastHelper.showToast(
        'Customer rebates',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        'Something went wrong. Please check your input data.'
      );
    }
    setError(errors);
  }, [errors, handleSubmit]);

  const onSubmit = (
    data: DBClientRebateCalculationApi['create']['requestBody']
  ) => {
    setIsSaving(true);
    const payload: DBClientRebateCalculationApi['create']['requestBody'] = {
      groupName: data.groupName,
      groupType: data.groupType,
      rebate: data.rebate,
      discount: data.discount,
      calculationType: calculationType,
      priceRound: data.priceRound,
      rules: updateRules(updatedConditions, rules, newRuleCondition),
    };

    if (rebateId) {
      updateRebate.mutate(payload);
      setDirty(false);
    } else {
      createRebate.mutate(payload);
      setDirty(false);
    }
  };

  const handleDeleteCustomerRebate = () => {
    if (rebateId) {
      setIsDeleteModalVisible(true);
    }
  };

  const handleUpdateStatus = () => {
    if (rebateId) updateRebateGroupStatus.mutate();
  };

  const handleCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else history.push(`/dashboard/customer-rebates`);
  };

  useEffect(() => {
    reset(generateDefaultValues());
    rebateGroupData && setCalculationType(rebateGroupData.calculationType);
    if (rebateGroupData === undefined) {
      setRules([]);
      updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
    } else {
      setRules(rebateGroupData?.rules);
      updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
    }
  }, [rebateGroupData, generateDefaultValues, reset]);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <PageTemplateContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Container>
        <CheckRebatesModal
          isOpen={isOpen}
          close={handleClose}
          rebateId={rebateId}
        />
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => {
              setIsCancelModalVisible(false);
            }}
            RBAction={() => {
              setIsCancelModalVisible(false);
              history.push(`/dashboard/customer-rebates`);
            }}
          />
        )}
        {!isCancelModalVisible && (
          <RouteRouterPrompt
            when={(isDirty && !isSaving) || (dirty && !isSaving)}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() => true}
            setRulesAreDirty={setDirty}
          />
        )}
        {isDeleteModalVisible && (
          <DeleteModal
            LBAction={() => setIsDeleteModalVisible(false)}
            RBAction={() => {
              deleteCustomerRebateMutation.mutate();
              setIsDeleteModalVisible(false);
            }}
            itemName={rebateGroupData?.groupName || ''}
          />
        )}
        <HeaderWrapper>
          <Title>
            <BackButton
              onClick={() => history.push(`/dashboard/customer-rebates`)}
            >
              <img src={backIcon} alt="back-icon" />
            </BackButton>
            {rebateGroupData
              ? rebateGroupData.groupName
              : 'Create new rebate group'}
          </Title>

          {rebateGroupData && (
            <Grid sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Button
                variant="text"
                color="info"
                startIcon={<PriceCheckIcon />}
                onClick={handleOpen}
              >
                CHECK REBATE
              </Button>
              <FormControlLabel
                control={
                  <Switch
                    id="switch"
                    color="info"
                    onChange={handleUpdateStatus}
                    checked={rebateGroupData.isActive}
                    defaultChecked={true}
                  />
                }
                label="Active"
              />
            </Grid>
          )}
        </HeaderWrapper>
        {isRebateDataFetching ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <>
            <CustomerRebatesForm
              register={register}
              control={control}
              errors={errors}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              calculationType={calculationType}
              setCalculationType={setCalculationType}
            />
            <RulesContext.Provider
              value={{
                rules,
                setRules,
                componentType: ComponentType.customer_rebates,
                updatedConditions,
                setDirty,
                error,
                setError,
                newRuleCondition,
              }}
            >
              <DndProvider backend={HTML5Backend}>
                <RulesList />
              </DndProvider>
            </RulesContext.Provider>
          </>
        )}
      </Container>
      <SourceContainerDivider>
        <SourceContainer
          data={{
            data_source: 'source',
            created: 'created',
            last_edited: 'edited',
          }}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={handleCancel}
          onDelete={handleDeleteCustomerRebate}
        />
      </SourceContainerDivider>
    </PageTemplateContent>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackButton = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
  width: 30px;
`;

const SourceContainerDivider = styled.div`
  position: relative;
  margin-top: 100px;
  width: 100%;

  @media (max-width: 1000px) {
    margin-top: 240px;
  }

  @media (max-width: 880px) {
    margin-top: 300px;
  }
`;

export default CustomerRebatesOverview;
