import { Button, CircularProgress } from '@mui/material';
import { DBTransferOrderType } from '@prisma/client';
import { DBProductApi } from '@tyrio/dto';
import { ProductDetailsGenerator } from '@tyrio/products';
import { UnselectedRowWarning, wmsIcons } from '@tyrio/wms-ui-library';
import { useContext, useEffect, useState } from 'react';
import { LoaderWrapper } from '../../../../components/PageTemplate/PageTemplate';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { useAuth } from '../../../../context/AuthContext';
import { StockListContext } from '../../../../context/StockListContext';
import { InboundOutboundOrders } from '../InboundOrders/InboundOrders';
import { StyledDivider } from '../InboundOrders/InboundOrders.style';
import { EditStockModal } from '../modals/EditStockModal';
import { ProductDetailsStock } from './ProductDetailsStock';
import { useGetMainBranchStock } from './queries/main-branch-stock';

const ProductDetails = () => {
  const { filterValues, setActiveDrawer, selectedTableRow } =
    useContext(StockListContext);
  const { user } = useAuth();

  const [isFavorite, setIsFavorite] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { stockData, refetchBranchesStock, isLoading } = useGetMainBranchStock(
    selectedTableRow?.ean ?? '',
    filterValues ? (filterValues['warehouseId'] as string) : ''
  );

  useEffect(() => {
    if (selectedTableRow && user) {
      const isFavoriteByUser = selectedTableRow.favoritedBy.includes(user.id);
      setIsFavorite(isFavoriteByUser ?? false);
      refetchBranchesStock();
    }
  }, [refetchBranchesStock, selectedTableRow, user, user?.id]);

  const shouldShowModal =
    isEditModalOpen &&
    selectedTableRow &&
    selectedTableRow.product &&
    stockData?.locations &&
    stockData.locations.length > 0;

  const renderChild = () => {
    return (
      <div
        id="product_details"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {shouldShowModal && (
          <EditStockModal
            open={isEditModalOpen}
            setOpen={setIsEditModalOpen}
            product={
              selectedTableRow.product as DBProductApi['getOne']['response']
            }
            locations={stockData?.locations}
          />
        )}
        {selectedTableRow && selectedTableRow.product && (
          <ProductDetailsGenerator
            product={
              selectedTableRow.product as DBProductApi['getOne']['response']
            }
            stockListItemId={selectedTableRow.id}
            isFavoriteByUser={isFavorite}
            setActiveDrawer={setActiveDrawer}
          />
        )}

        {isLoading && (
          <div style={{ marginTop: '20px' }}>
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          </div>
        )}

        {stockData?.locations && stockData.locations.length > 0 && (
          <ProductDetailsStock
            branchName={stockData?.mainBranchName}
            item={stockData?.locations}
          />
        )}

        {selectedTableRow && <StyledDivider />}

        <InboundOutboundOrders type={DBTransferOrderType.INBOUND} key={1} />

        <InboundOutboundOrders type={DBTransferOrderType.OUTBOUND} key={2} />

        {!selectedTableRow && (
          <UnselectedRowWarning
            icon={wmsIcons.details}
            text="product details"
          />
        )}

        <div
          style={{
            position: 'sticky',
            top: '100%',
            height: '80px',
            display: 'flex',
            paddingBottom: '16px',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
            }}
          >
            <Button
              title="Edit"
              color="info"
              variant="outlined"
              onClick={() => setIsEditModalOpen(true)}
            >
              EDIT STOCK
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <SideMenuLayout
      type="details"
      children={renderChild()}
      shouldShowTitle={false}
    />
  );
};

export default ProductDetails;
