import { CancelModal } from '../Modal/Modal';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

interface RouteRouterPromptProps {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  setRulesAreDirty?: (a: boolean) => void;
}

const RouteRouterPrompt = ({
  when,
  navigate,
  shouldBlockNavigation,
  setRulesAreDirty,
}: RouteRouterPromptProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    if (setRulesAreDirty) setRulesAreDirty(false);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      {modalVisible && (
        <CancelModal
          LBAction={closeModal}
          RBAction={() => {
            handleConfirmNavigationClick();
          }}
        />
      )}
    </>
  );
};

export default RouteRouterPrompt;
