/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Drawer, Grid } from '@mui/material';
import React, { useCallback } from 'react';
import x from '../../assets/x-icon.svg';
import { useForm } from 'react-hook-form';
import { CategoryDropdownInput, MultiselectChipInput } from '@tyrio/ui-library';
import Button from '@mui/material/Button/Button';
import {
  BRANDS_LIST,
  SEASON_DROPDOWN_DATA,
  SPECIAL_MARKING_TYPE_DROPDOWN_DATA,
  WHEEL_POSITIONS_DROPDOWN_DATA,
} from '@tyrio/shared-vars';
import qs from 'qs';

interface FilterSidebarProps {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
  filterValues: qs.ParsedQs;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
}

const SidebarInternal = ({
  isOpen,
  setOpen,
  filterValues,
  setFilterValue,
}: FilterSidebarProps) => {
  const getFilterValuesFromInitial = useCallback(() => {
    const data = {
      category: filterValues?.['categoryId'],
      season: filterValues?.['season'],
      brand: filterValues?.['brand'],
      wheelPosition: filterValues?.['wheelPosition'],
      specialMarking: filterValues?.['specialMarking'],
    };

    return data as any;
  }, [filterValues]);
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: getFilterValuesFromInitial(),
  });

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(false);
    };

  const onSubmit = ({ category, ...data }: Record<string, any>) => {
    const categoryId = category?.id ?? category;

    const payload = {
      categoryId,
      ...data,
    };

    setFilterValue(payload);
    setOpen(false);
  };

  if (!isOpen) return null;
  return (
    <Drawer anchor={'left'} open={isOpen} onClose={toggleDrawer()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Header>
            <Title>Filter</Title>
            <CloseButton onClick={() => setOpen(false)}>
              <img src={x} alt="" />
            </CloseButton>
          </Header>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CategoryDropdownInput
                label="Category"
                id="category"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <MultiselectChipInput
                title="Seasons"
                control={control}
                id="season"
                data={SEASON_DROPDOWN_DATA}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiselectChipInput
                title="Brands"
                control={control}
                id="brand"
                data={BRANDS_LIST}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiselectChipInput
                title="Wheel positions"
                control={control}
                id="wheelPosition"
                data={WHEEL_POSITIONS_DROPDOWN_DATA}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiselectChipInput
                title="Special markings"
                control={control}
                id="specialMarking"
                data={SPECIAL_MARKING_TYPE_DROPDOWN_DATA}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="button"
                onClick={() => {
                  reset();
                  setFilterValue({
                    categoryId: undefined,
                    season: undefined,
                    brand: undefined,
                    wheelPosition: undefined,
                    specialMarking: undefined,
                  });
                  setOpen(false);
                }}
                style={{ border: '1px solid #1B2130' }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" type="submit">
                Filter
              </Button>
            </Grid>
          </Grid>
        </Wrapper>
      </form>
    </Drawer>
  );
};

const FilterSidebar = ({
  isOpen,
  setOpen,
  filterValues,
  setFilterValue,
}: FilterSidebarProps) => {
  if (!isOpen) return null;
  return (
    <SidebarInternal
      isOpen={isOpen}
      setOpen={setOpen}
      filterValues={filterValues}
      setFilterValue={setFilterValue}
    />
  );
};

export const Wrapper = styled.div`
  height: 100%;
  background: white;
  border-radius: 22px;
  padding: 20px;
  margin-right: 10px;
  max-width: 80vw;
  width: 425px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export default FilterSidebar;
