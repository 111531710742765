import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { IProduct } from '@tyrio/dto';
import { useVehicleCtx } from '../../../../../context/VehicleContext';
import { toString } from 'lodash';

export const Dropdown = ({
  label,
  value,
  onChange,
  menuItems,
  width,
}: {
  label: string;
  value: string | undefined;
  onChange: (value: string) => void;
  menuItems: { label: string; value: string }[];
  width?: string;
}) => {
  return (
    <FormControl style={{ width }}>
      <InputLabel id="rim-size-label">{label}</InputLabel>
      <Select
        fullWidth
        labelId="rim-size-label"
        id="rim-size"
        value={value}
        label={label}
        onChange={({ target: { value } }) => {
          onChange(value);
        }}
      >
        {menuItems.map((item) => {
          return (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const ProductDropdown = ({ products }: { products?: IProduct[] }) => {
  const { setSelectedProduct, selectedProduct } = useVehicleCtx();

  return (
    <Dropdown
      label="Choose rim size"
      value={toString(selectedProduct?.uid)}
      onChange={(value) => {
        setSelectedProduct(products?.find((f) => f.uid === value) ?? null);
      }}
      width={'100%'}
      menuItems={
        (products ?? []).map((e) => {
          return { label: e.productName, value: e.uid };
        }) ?? []
      }
    />
  );
};

export const EtDropdown = () => {
  const { selectedProduct, setSelectedEt, selectedEt } = useVehicleCtx();

  if (selectedProduct?.et && selectedProduct?.et?.length > 1)
    return (
      <Dropdown
        label="ET"
        value={toString(selectedEt)}
        onChange={(value) => {
          setSelectedEt(Number(value));
        }}
        width="20%"
        menuItems={(selectedProduct?.et ?? []).map((e) => {
          return { label: e.toString(), value: e.toString() };
        })}
      />
    );
  else return null;
};
