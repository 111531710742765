import { WorkOrderContextProvide } from '../../../context/WorkOrderContext';
import { WorkOrdersDashboardPage } from '../../../features/work-orders-dashboard/WorkOrdersDashboardPage';

export const WorkOrdersDashboard = () => {
  return (
    <WorkOrderContextProvide>
      <WorkOrdersDashboardPage />
    </WorkOrderContextProvide>
  );
};
