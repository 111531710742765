import { FormShape } from '../../services/constants/service.form';

export const textsForm: FormShape = {
  key: 'main_settings',
  title: 'Texts',
  inputs: [
    {
      id: 'headerText',
      width: '100%',
      type: 'input.string',
      label: 'Header text',
      hasPadding: false,
    },
    {
      id: 'mainTermsText',
      width: '100%',
      type: 'input.string',
      label: 'Main terms text',
      hasPadding: false,
    },
    {
      id: 'paymentTermsText',
      width: '100%',
      type: 'input.string',
      label: 'Payment terms text',
      hasPadding: false,
    },
    {
      id: 'footerText',
      width: '100%',
      type: 'input.string',
      label: 'Footer text',
      hasPadding: false,
    },
  ],
};
