import { DBPartnerApi } from '@tyrio/dto';

import {
  LandingPage,
  SearchableVerticalList,
  SearchableVerticalListDataType,
} from '@tyrio/ui-library';
import React, { useEffect, useState } from 'react';
import api from '@tyrio/api-factory';
import { useHistory, useLocation } from 'react-router-dom';
import { tyrioIcons } from '@tyrio/ui-library';
import { useQuery } from 'react-query';
import { useDebounce } from '../../../hooks/useDebounce';
import { ExpandableLayout } from '../../../layouts/ExpandableLayout';
import routeNames from '../../../lib/routeNames';
import { pageSize } from '../../../../../constants/pagination.constants';

interface PartnersProps {
  children: React.ReactNode;
}
const Partners = ({ children }: PartnersProps) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const location = useLocation();

  const debouncedSearch = useDebounce(search, 700);

  const searchPartners = async (debouncedSearch: string, page: number) => {
    return await api.fetch<DBPartnerApi['list']>('get_partners', {
      search: debouncedSearch,
      page,
      pageSize,
    });
  };

  useEffect(() => {
    setPage(1);
  }, [debouncedSearch, setPage]);

  const {
    data: partnersData,
    isFetching,
    isError,
  } = useQuery(['get_partners', { debouncedSearch, page }], () =>
    searchPartners(debouncedSearch, page)
  );

  const [selected, setSelected] = useState<Record<string, boolean>>(
    location.pathname.split('/').pop() !== 'partners'
      ? { [location.pathname.split('/').pop()]: true }
      : {}
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      setSelected((prevValue) => ({
        ...prevValue,
        [item.id]: !prevValue[item.id],
      }));
      history.push(`/dashboard/partners/${item.id}`);
    }
    if (type === 'new') history.push(`/dashboard/partners/new`);
  };

  return (
    <ExpandableLayout
      sidebarComponent={
        <SearchableVerticalList
          header="Partners"
          type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
          handleItemClick={(item) => handleClick('itemClicked', item)}
          data={
            partnersData?.data?.map((partner) => ({
              id: partner.id,
              name: partner.companyDisplayName ?? partner.companyOfficialName,
              type:
                partner.customerPartnerType && partner.supplierPartnerType
                  ? 'CUSTOMER, SUPPLIER'
                  : partner.customerPartnerType
                  ? 'CUSTOMER'
                  : partner.supplierPartnerType
                  ? 'SUPPLIER'
                  : '',
              active: partner.active,
              isSelected: selected[partner.id],
            })) ?? []
          }
          path={routeNames.dashboard()}
          onCreateNew={() => handleClick('new', {})}
          onSearchChange={setSearch}
          isFetching={isFetching}
          isError={isError}
          count={partnersData?.count}
          onChangePage={(page) => setPage(page)}
          pageNumber={page}
        />
      }
      landingPage={
        <LandingPage
          icon={tyrioIcons.menuIcon4}
          title={'Welcome to Partners list'}
          subtitle={'Edit partner from list or add new.'}
          buttonText={'Add new partner'}
          onClick={() => handleClick('new', {})}
        />
      }
    >
      {children}
    </ExpandableLayout>
  );
};

export default Partners;
