/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */

import styled from '@emotion/styled';
import { TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { tyrioUI } from '@tyrio/ui-library';
import QueryBuilder from '../../../../components/QueryBuilder/QueryBuilder';
import { Config } from '../../helper/config';
import { IJsonLogic, RulesContext } from '../../context/RulesContext';

interface IConditions {
  conditions: string;
  index: number;
}

const Conditions = ({ conditions, index }: IConditions) => {
  const [priceCalculationJson, setPriceCalculationJson] =
    useState<string>(conditions);

  const { rules, setRules, updatedConditions, error, newRuleCondition } =
    useContext(RulesContext);

  const [rulesDescription, setRulesDescription] = useState(
    rules[index]?.ruleDescription
  );

  useEffect(() => {
    setPriceCalculationJson(conditions);
  }, [conditions, index]);

  const updateRuleDescription = (ruleDescription: string) => {
    rules[index] = {
      actions: rules[index]?.actions,
      conditions: rules[index]?.conditions,
      name: rules[index]?.name,
      ruleDescription: ruleDescription,
      id: rules[index]?.id,
      newConditions: rules[index]?.newConditions,
    };

    setRules((rules) => {
      return [...rules];
    });
  };

  useEffect(() => {
    updateRuleDescription(rulesDescription?.trim() as string);
  }, [rulesDescription]);

  useEffect(() => {
    const ruleId = rules[index].id;

    const isNull = updatedConditions.current.find(
      (a: IJsonLogic) => a.ruleIndex === null
    );
    const findIdx = updatedConditions.current.find(
      (a: IJsonLogic) => a.ruleIndex === ruleId
    );

    if (isNull) {
      isNull.ruleIndex = ruleId;
    } else if (findIdx === undefined) {
      updatedConditions.current = [
        ...updatedConditions.current,
        { jsonLogic: '', ruleIndex: ruleId },
      ];
    }
  }, [index]);

  return (
    <ConditionsWrapper>
      <Typography fontSize={18} fontWeight={600}>
        Conditions
      </Typography>

      <TextField
        id="rules_description"
        variant="outlined"
        label="Rules description"
        fullWidth
        value={rulesDescription}
        style={{ width: '50%', marginTop: '16px' }}
        onChange={(e) => {
          setRulesDescription(e.target.value);
        }}
        error={error['ruleDescription' + '_' + index] !== undefined}
        helperText={error['ruleDescription' + '_' + index]}
        InputProps={{ style: { background: 'white' } }}
      />

      <QueryBuilder
        config={Config()}
        jsonTree={
          priceCalculationJson === '' ? {} : JSON.parse(priceCalculationJson)
        }
        updatedConditions={updatedConditions}
        index={rules[index].id}
        newRuleCondition={newRuleCondition}
      />
    </ConditionsWrapper>
  );
};

export default Conditions;

export const ConditionsWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background: ${tyrioUI.colors.black.B10};
  border: 1px solid #dfe3e8;
  border-radius: 16px;
`;
