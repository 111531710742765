import { Divider, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useContext } from 'react';
import CustomDrawerContainer from '../../../../components/Drawers/CustomDrawerContainer';
import {
  ActiveDrawer,
  StockListContext,
} from '../../../../context/StockListContext';
import { Wrapper } from '../../../../components/Drawers/ImageGallery/ImageGallery.style';
import { wmsIcons } from '@tyrio/wms-ui-library';
import {
  Name,
  QrWrapper,
  TextWrapper,
  NameWrapper,
} from './ItemLocationInfo.style';
import { startCase } from 'lodash';

interface ItemLocationInfoProps {
  activeDrawer: ActiveDrawer;
  setActiveDrawer: (a: ActiveDrawer) => void;
}

export const ItemLocationInfo = ({
  activeDrawer,
  setActiveDrawer,
}: ItemLocationInfoProps) => {
  // TODO: send this as props if we need to use this component for POS
  const { selectedLocation } = useContext(StockListContext);

  const parsedLocationInfo =
    selectedLocation?.locationMeta.displayName.split('-');

  if (selectedLocation === null || parsedLocationInfo === undefined)
    return null;

  const returnItem = (
    mainString: string,
    subString: string,
    minWidth: string
  ) => {
    return (
      <Name style={{ minWidth }}>
        <Typography fontWeight={400} fontSize={16} color="#919EAB">
          {mainString}
        </Typography>

        <Typography fontWeight={700} fontSize={30} color="#212B36">
          {subString}
        </Typography>
      </Name>
    );
  };

  const renderLocationInfo = () => {
    return (
      <Wrapper style={{ justifyContent: 'flex-start', padding: 0 }}>
        <QrWrapper>
          <QRCodeSVG value={selectedLocation.locationMeta.qrCode} />

          <TextWrapper>
            <Typography
              fontWeight={700}
              fontSize={28}
              color={selectedLocation.locationMeta.zoneColor}
            >
              {startCase(selectedLocation.locationMeta.subzoneStagingType)}
            </Typography>
            <NameWrapper>
              {returnItem('Zone', `${parsedLocationInfo[0]}`, '55px')}
              {returnItem('', '-', '24px')}
              {returnItem('Rack', `${parsedLocationInfo[1]}`, '36px')}
              {returnItem('', '-', '24px')}
              {returnItem('Shelf', `${parsedLocationInfo[2]}`, '37px')}
              {parsedLocationInfo[3] && returnItem('', '-', '24px')}
              {parsedLocationInfo[3] &&
                returnItem('Bin', `${parsedLocationInfo[3]}`, '24px')}
            </NameWrapper>

            <Typography fontWeight={400} fontSize={16} color="#919EAB">
              {selectedLocation.locationMeta.shelfDescription}
            </Typography>
          </TextWrapper>
        </QrWrapper>

        <Divider
          sx={{
            width: '100%',
            borderWidth: '2px',
            borderColor: '#E6E8F0',
          }}
        />
      </Wrapper>
    );
  };

  return (
    <CustomDrawerContainer
      open={activeDrawer === 'LOCATION_INFO'}
      child={renderLocationInfo()}
      title="ITEM LOCATION INFO"
      icon={wmsIcons.locationL}
      viewBox="0 0 20 28"
      iconStyle={{ height: '24px', width: '24px' }}
      onClick={() => setActiveDrawer('')}
    />
  );
};
