/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, FormControl, MenuItem, TextField } from '@mui/material';
import { DBProductCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import { useCallback, useEffect, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useQuery } from 'react-query';

interface CategoryDropdownProps {
  control: Control<any, any>;
  id: string;
  isEditDisabled?: boolean;
  isMain?: boolean;
  label: string;
  isCreated?: boolean;
  categoryId?: number;
}
export const CategoryDropdownInput = ({
  id,
  control,
  isEditDisabled,
  isMain,
  label,
  isCreated,
  categoryId,
}: CategoryDropdownProps) => {
  const { data: mainCategories, refetch } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(
      isMain ? 'main_categories' : `all_categories`
    )
  );

  useEffect(() => {
    if (isCreated) refetch();
  }, [isCreated, refetch]);

  const shapedCategories = useMemo(() => {
    const response: Record<number, DBProductCategory> = {};
    mainCategories?.forEach((c) => {
      response[c.id] = c;
    });
    return response;
  }, [mainCategories]);

  const getParentNames = useCallback(
    (c: DBProductCategory, existing: string[]): string[] => {
      if (!c.parent_category_id) return [c.name, ...existing];

      return getParentNames(shapedCategories[c.parent_category_id], [
        c.name,
        ...existing,
      ]);
    },
    [shapedCategories]
  );
  const categories = useMemo(() => {
    return (
      mainCategories
        ?.map((c) => {
          const names = getParentNames(c, []);
          return {
            label: names.join(' > '),
            id: c.id,
            main: !c.parent_category_id,
          };
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1)) || []
    );
  }, [mainCategories, getParentNames]);

  return (
    <FormControl fullWidth>
      <Controller
        name={id}
        control={control}
        render={({ field, formState: { errors } }: any) => {
          let value = field.value;

          if (typeof field.value === 'number')
            value = categories.find((c) => c.id === field.value);
          if (typeof field.value === 'string')
            value = categories.find((c) => c.id === parseInt(field.value, 10));

          return (
            <Autocomplete
              fullWidth
              disablePortal
              id={id}
              disabled={isEditDisabled}
              options={categories.length > 0 ? categories : []}
              onChange={(e, x) => field.onChange(x)}
              value={value}
              getOptionLabel={(data) => data.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors[id]}
                  helperText={errors[id]}
                  label={label}
                />
              )}
              renderOption={(item, el) => (
                <MenuItem {...item} disabled={el.id === categoryId}>
                  {el.main ? <strong>{el.label}</strong> : el.label}
                </MenuItem>
              )}
            />
          );
        }}
      />
    </FormControl>
  );
};
