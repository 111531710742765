import { TableCell, TableRow } from '@mui/material';

interface SingleTableRowProps {
  children: JSX.Element;
}

export const PosRowCell = ({ children }: SingleTableRowProps) => {
  return (
    <TableRow>
      <TableCell sx={{ border: 0, padding: 0 }}>{children}</TableCell>
    </TableRow>
  );
};
