import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';

export const useUpdateActivePaymentMethod = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_payment_status'],
    (body: DBPaymentTypesApi['activeStatus']['requestBody']) =>
      api.fetch<DBPaymentTypesApi['activeStatus']>('update_payment_status', {
        ...body,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_payment_by_id');
        queryClient.refetchQueries('get_payment_method');

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },

      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateActiveStatusPaymentMethod: mutate };
};
