import styled from '@emotion/styled';
import { FormControl, FormControlLabel, Grid, Switch } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormGridProps,
  GetValueProps,
  TyrioFormError,
  TyrioSwitchInput,
} from '../types';

interface TyrioInputSwitchProps {
  id: string;
  item: TyrioSwitchInput;
  gridProps?: FormGridProps;
  error?: TyrioFormError;
  control: Control;
  onChangeValue?: (x: GetValueProps) => void;
}
export const TyrioInputSwitch = ({
  id,
  item,
  gridProps,
  control,
  onChangeValue,
}: TyrioInputSwitchProps) => {
  const { t } = useTranslation();
  return (
    <GridContainer
      item
      {...gridProps}
      label={!item.label || item.label === 'Active'}
      hasPadding={item.hasPadding ?? true}
    >
      <FormControl fullWidth>
        <Controller
          name={id}
          control={control}
          render={({ field }) => {
            return (
              <SwitchContainer
                control={
                  <Switch
                    value={field.value}
                    checked={field.value}
                    color="info"
                    defaultChecked={field.value ?? true}
                    onChange={(e) => {
                      field.onChange(e.target.checked);

                      if (onChangeValue)
                        onChangeValue({ value: e.target.checked, id });
                    }}
                  />
                }
                label={item.label ?? t('Active')}
                disabled={item.disabled}
                color={'#1976d2'}
                alignLabelLeft={!!item.label}
              />
            );
          }}
        />
      </FormControl>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)<{ label: boolean; hasPadding?: boolean }>`
  padding-bottom: ${(props) => (props.hasPadding ? '22px' : null)};
  display: flex;
  align-items: ${(props) => !props.label && 'center'};
`;

const SwitchContainer = styled(FormControlLabel)<{ alignLabelLeft: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.alignLabelLeft ? 'flex-start' : 'flex-end'};
`;
