import SourceContainer from './lib/components/SourceContainter';

import {
  default as checkIfValidEmail,
  default as checkIfValidVat,
} from './lib/utils';

export * from './lib/FormController';
export * from './lib/forms';
export { generateUsersForm } from './lib/forms/users_form';
export { generatePartnerForm } from './lib/forms/partner_form';
export * from './lib/inputs/InputPhone';
export * from './lib/inputs/InputVies';
export * from './lib/types';
export { checkIfValidEmail, checkIfValidVat };
export { HeaderIcon } from './lib/components/ListHeaderIcon';

export { TyrioInputSelect } from './lib/inputs/InputSelect';
export { TyrioInputString } from './lib/inputs/InputString';
export { TyrioInputRadio } from './lib/inputs/InputRadio';
export { TyrioInputCheckbox } from './lib/inputs/InputCheckbox';
export { TyrioInputSwitch } from './lib/inputs/InputSwtich';

export * from './lib/inputs/InputMultiselect';
export { useGetPartnerDetails } from './lib/hooks/getPartnerDetails';

export default SourceContainer;
