import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel } from "./index"

export const DBCompanyContactModel = z.object({
  id: z.string(),
  clientId: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  businessPhone: z.string(),
  mobilePhone: z.string(),
  remark: z.string(),
})

export interface CompleteDBCompanyContact extends z.infer<typeof DBCompanyContactModel> {
  client?: CompleteDBClient | null
}

/**
 * RelatedDBCompanyContactModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBCompanyContactModel: z.ZodSchema<CompleteDBCompanyContact> = z.lazy(() => DBCompanyContactModel.extend({
  client: RelatedDBClientModel.nullish(),
}))
