import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import { PriceComparisonContext } from '../context';

interface Props {
  supplierOrderId: string;
  orderReferenceSuffix: string;
  ritamResponse?: string;
}
export const OrderSentSuccess = ({
  supplierOrderId,
  orderReferenceSuffix,
  ritamResponse = '',
}: Props) => {
  const state = 'success';
  const ctx = useContext(PriceComparisonContext);

  return (
    <DescriptionWrapper>
      {state === 'success' && (
        <>
          <InfoWrapper>
            <SuccessIconWrapper>
              <SuccessIcon />
            </SuccessIconWrapper>
            <DisplayText>
              Order sent {ctx.data.reference}
              {orderReferenceSuffix}
            </DisplayText>
          </InfoWrapper>
          <InfoWrapperSubheader>
            Supplier order number: <b>{supplierOrderId}</b>
          </InfoWrapperSubheader>
          <InfoWrapperSubheader>{ritamResponse}</InfoWrapperSubheader>
        </>
      )}
    </DescriptionWrapper>
  );
};

export const SuccessIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #4caf50;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
export const SuccessIcon = styled(CheckIcon)`
  margin: 0;
  padding: 0;
  height: 24px;
  width: 24px;
  color: #4caf50;
`;
export const DisplayText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 133.4%;
  color: #253858;
`;
export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 16px 24px 16px;
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.5);
`;
export const InfoWrapperSubheader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;

  color: #919eab;
  b {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.3px;

    color: #212b36;
  }
`;
