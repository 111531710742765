import { TRule } from '../../typings';
import { GLOBAL_RULESET } from '../global';

export const GUNDLACH_CATALOG_RULESET: TRule[] = [
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.H',
    condition: 'equals',
    conditionValues: ['PKW', 'OFF-ROAD', 'LLKW', 'STAHL', 'RDKS', 'ALU'],
    conditionOutputs: ['30', '31', '32', '73', '88', '72'],
    targetKey: 'category_id',
    outputType: 'forced_integer',
    destination: 'model',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.I',
    condition: 'equals',
    conditionValues: [
      'GANZJAHR',
      'SOMMER',
      'WINTER',
      'ALLWETTER',
      'ALL TERRAIN',
    ],
    conditionOutputs: [
      'allseason',
      'summer',
      'winter',
      'allseason',
      'allseason',
    ],
    targetKey: 'season',
    outputType: 'string',
    destination: 'model',
    ignoreForCategories: [72, 73], // Wheels
  },
  // Width Height rule
  {
    type: 'rule.map',
    sourceKey: 'c.K',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [30, 31, 32], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c.X',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|32',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'height',
    outputValue: '#{c.L}',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.M',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Y',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.width',
    matchValue: ['.00', '.50'],
    replaceValue: ['', '.5'],
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.height',
    matchValue: ['.00', '.50'],
    replaceValue: ['', '.5'],
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.rimDiameter',
    matchValue: ['.00', '.50'],
    replaceValue: ['', '.5'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.B',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.V',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    targetKey: 'construction',
    destination: 'product',
    outputType: 'string',
    template: 'R',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.N',
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.P',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AN',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AM',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AO',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AP',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AJ',
    targetKey: 'vehicleClass',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AQ',
    targetKey: 'eprelId',
    destination: 'product',
    postProcess: ['getEprelIdFromUrl'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AQ',
    targetKey: 'eprelUrl',
    destination: 'product',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.Z',
  //   targetKey: 'holeNumber',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.AA',
  //   targetKey: 'holeCircle',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'pcd',
    template: '#{c.Z}x#{c.AA}',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.pcd',
    matchValue: ['.00', '.50', '.70'],
    replaceValue: ['', '.5', '.7'],
    applyOnlyForCategories: [72, 73], // Wheels
  },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 't.model.category_id',
  //   condition: 'in',
  //   conditionValue: '72|73',
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'et',
  //   outputValue: '#{c.AB}',
  //   outputType: 'number',
  // },

  {
    type: 'rule.map_array',
    sourceKey: 'c.AB',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '72|73',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'cb',
    outputValue: '#{c.AE}',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.AD',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.map',
    sourceKey: 'c.R',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '72|73',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'modelName',
    outputValue: '#{c.AC}',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.D',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'model',
    operationName: 'getGundlachSpecialMarkingsAndModelName',
    ignoreForCategories: [72, 73], // Wheels
  },

  ...GLOBAL_RULESET,
];
