import * as z from "zod"
import { DBVatChargeTypeEnum, DBCurrency, DBParnerDeliveryTermsTypes, DBTrafficType } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBInboundInvoiceNumber, RelatedDBInboundInvoiceNumberModel, CompleteDBPartner, RelatedDBPartnerModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBInboundInvoiceModel = z.object({
  id: z.string(),
  clientId: z.string(),
  createdAt: z.date(),
  branchId: z.number().int(),
  date: z.date(),
  supplierName: z.string(),
  vatChargeType: z.nativeEnum(DBVatChargeTypeEnum),
  currency: z.nativeEnum(DBCurrency),
  exchangeRate: z.number().int(),
  supplierInvoice: z.string(),
  issueDate: z.date(),
  delay: z.number().int(),
  dueDate: z.date(),
  internalRemark: z.string(),
  deliveryTermsType: z.nativeEnum(DBParnerDeliveryTermsTypes).nullish(),
  transactionNature: z.number().int().nullish(),
  transportMode: z.nativeEnum(DBTrafficType).nullish(),
  dispatchingCountryId: z.string().nullish(),
  summary: jsonSchema,
  items: jsonSchema,
  invoiceNumberId: z.string(),
  variableCost: jsonSchema,
  stockIns: jsonSchema,
  partnerOib: z.string().nullish(),
  erpId: z.string().nullish(),
  internalReference: z.string().nullish(),
  partnerId: z.string().nullish(),
  branchName: z.string(),
  createdBy: jsonSchema,
  customSummary: jsonSchema,
})

export interface CompleteDBInboundInvoice extends z.infer<typeof DBInboundInvoiceModel> {
  client: CompleteDBClient
  invoiceNumber: CompleteDBInboundInvoiceNumber
  partner?: CompleteDBPartner | null
}

/**
 * RelatedDBInboundInvoiceModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBInboundInvoiceModel: z.ZodSchema<CompleteDBInboundInvoice> = z.lazy(() => DBInboundInvoiceModel.extend({
  client: RelatedDBClientModel,
  invoiceNumber: RelatedDBInboundInvoiceNumberModel,
  partner: RelatedDBPartnerModel.nullish(),
}))
