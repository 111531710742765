import api from '@tyrio/api-factory';
import { DBBranchStockListResponse } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetBranchesStock = (ean: string, warehouseId: string) => {
  const newEan =
    ean === ''
      ? JSON.parse(localStorage.getItem('@@sl')?.toString() ?? '')
          ?.selectedTableRow?.ean
      : ean;

  const { data, refetch, isLoading } = useQuery(['branches_stock_list'], () =>
    api.fetch<{
      request: {
        ean: string;
        warehouseId: string;
      };
      requestBody: {
        ean: string;
        warehouseId: string;
      };
      response: DBBranchStockListResponse[];
    }>('branches_stock_list', {
      ean: newEan ?? '',
      warehouseId: warehouseId,
    })
  );
  return { branchesStock: data, refetchBranchesStock: refetch, isLoading };
};
