import seasonAllIcon from '../../../assets/productDetailsNew/season-all.svg';
import seasonSummerIcon from '../../../assets/productDetailsNew/season-summer.svg';
import seasonWinterIcon from '../../../assets/productDetailsNew/season-winter.svg';

interface SeasonIconInterface {
  season: string;
}

const SeasonIcon = ({ season }: SeasonIconInterface) => {
  const generateSeasonIcon = (season: string) => {
    if (season === 'summer') return seasonSummerIcon;
    if (season === 'winter') return seasonWinterIcon;
    return seasonAllIcon;
  };

  return (
    <img
      src={generateSeasonIcon(season)}
      alt="season-icon"
      width="32px"
      style={{ marginBottom: '10px' }}
    />
  );
};

export default SeasonIcon;
