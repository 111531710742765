import styled from '@emotion/styled/macro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useState } from 'react';

export interface PosItemRowBackground {
  default: string;
  hover: string;
}

interface PosAccordion {
  title: string;
  background: PosItemRowBackground;
  isExpanded: boolean;
  children: JSX.Element;
  onExpand: () => void;
}

const PosAccordion = (props: PosAccordion) => {
  const { title, background, isExpanded, children, onExpand } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <AccordionStyled
      square
      disableGutters
      expanded={isExpanded}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <AccordionSummaryStyled
        backgrounds={background}
        expandIcon={<ExpandIconStyled />}
        onClick={onExpand}
        ishovered={`${isHovered}`}
      >
        <AccordionHeader>
          <Title>{title}</Title>
        </AccordionHeader>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
    </AccordionStyled>
  );
};

const AccordionStyled = styled(Accordion)`
  padding: 0;
  box-shadow: none;
`;

const AccordionSummaryStyled = styled(AccordionSummary)<{
  backgrounds: PosItemRowBackground;
  ishovered: string;
}>`
  background: ${(props) =>
    props.ishovered === 'false'
      ? props.backgrounds.default
      : props.backgrounds.hover};
  color: white;
  flex-direction: row-reverse;
  height: 70px;
`;

const AccordionHeader = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

const Title = styled(Typography)`
  margin-left: 10px;
`;

const ExpandIconStyled = styled(ExpandMoreIcon)`
  color: white;
`;

export default PosAccordion;
