import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';

export const useUpdatePaymentMethod = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_payment_method'],
    (body: DBPaymentTypesApi['updateOne']['requestBody']) =>
      api.fetch<DBPaymentTypesApi['updateOne']>('update_payment_method', {
        ...body,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_payment_method');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updatePaymentMethod: mutate };
};
