import { useAuth } from '../../context/AuthContext';
import UserDetailsForm from '../../features/user-form/UserDetailsForm';

export const UsersForm = () => {
  const { user, permissionList } = useAuth();
  const clientId = user?.clientId ?? '';

  return (
    <UserDetailsForm
      clientId={clientId}
      permissions={permissionList.settings?.users}
      loggedUser={user?.id ?? ''}
    />
  );
};
