import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { VULKAL_PRICELIST_RULESET } from './vulkal/pricelist';
import { VULKAL_STOCKLIST_RULESET } from './vulkal/stocklist';
import { TRule } from '../typings';

export const VULKAL_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: VULKAL_PRICELIST_RULESET,
  stockRuleset: VULKAL_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'VULKAL',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    VPCsRabatom: {
      ',': '.',
    },
    Akcija: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter((item) => !['DOT'].includes(item['Naziv'])),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter((item) => !['DOT'].includes(item['Naziv'])),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
