import { FormControlLabel, Switch } from '@mui/material';
import { BackButton, HeaderWrapper, Title } from '@tyrio/forms';
import { backIcon } from '@tyrio/ui-library';

export const TitleWrapper = ({
  title,
  active,
  onChangeStatus,
}: {
  title?: string;
  active?: boolean;
  onChangeStatus?: () => void;
}) => {
  return (
    <HeaderWrapper style={{ padding: '0 16px' }}>
      <Title>
        <BackButton onClick={() => console.log('cancel')}>
          <img src={backIcon} alt="back-icon" />
        </BackButton>
        {title ?? ''}
      </Title>
      {
        <FormControlLabel
          control={<Switch data-cy="switch" color="info" />}
          label={'Active'}
          onChange={onChangeStatus}
          checked={active}
        />
      }
    </HeaderWrapper>
  );
};
