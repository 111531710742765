import * as z from "zod"
import { CompleteDBClientSupplierOrderItem, RelatedDBClientSupplierOrderItemModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBTransferOrderItem, RelatedDBTransferOrderItemModel, CompleteDBStockInItems, RelatedDBStockInItemsModel } from "./index"

export const DBOrderDocumentModel = z.object({
  id: z.string(),
  description: z.string(),
  url: z.string(),
  name: z.string().nullish(),
  type: z.string().nullish(),
  reference: z.string().nullish(),
  s3id: z.string().nullish(),
  orderItemId: z.string().nullish(),
  customerOrderItemId: z.string().nullish(),
  transferOrderItemId: z.string().nullish(),
  stockInOrderItemId: z.string().nullish(),
})

export interface CompleteDBOrderDocument extends z.infer<typeof DBOrderDocumentModel> {
  orderItem?: CompleteDBClientSupplierOrderItem | null
  customerOrderItem?: CompleteDBCustomerOrderItem | null
  transferOrderItem?: CompleteDBTransferOrderItem | null
  stockInOrderItem?: CompleteDBStockInItems | null
}

/**
 * RelatedDBOrderDocumentModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBOrderDocumentModel: z.ZodSchema<CompleteDBOrderDocument> = z.lazy(() => DBOrderDocumentModel.extend({
  orderItem: RelatedDBClientSupplierOrderItemModel.nullish(),
  customerOrderItem: RelatedDBCustomerOrderItemModel.nullish(),
  transferOrderItem: RelatedDBTransferOrderItemModel.nullish(),
  stockInOrderItem: RelatedDBStockInItemsModel.nullish(),
}))
