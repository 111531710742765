import styled from '@emotion/styled';
import { Button, Grid, TextField } from '@mui/material';
import { appConfig } from '@tyrio/config';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface GeocodeProps {
  address: string;
  zipCode: string;
  city: string;
  country: string;
}
interface MapBoxProps {
  longitude: number;
  setLongitude: (longitude: number) => void;
  latitude: number;
  setLatitude: (latitude: number) => void;
  generateGeocodeProps: GeocodeProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>;
}

export const MapBox = ({
  longitude,
  setLongitude,
  latitude,
  setLatitude,
  generateGeocodeProps,
  trigger,
}: MapBoxProps) => {
  const { t } = useTranslation();

  const accessToken = appConfig.mapBox;
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);

  const [zoom, setZoom] = useState(5.5);
  const [pinLngLat, setPinLngLat] = useState({ lat: latitude, lng: longitude });

  const [error, setError] = useState<Record<string, string>>({
    latitude: '',
    longitude: '',
  });

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current ?? '',
      style: 'mapbox://styles/mapbox/streets-v12',
      accessToken: accessToken,
      center: [pinLngLat.lng, pinLngLat.lat],
      zoom: zoom,
      projection: {
        name: 'equirectangular',
      },
      minZoom: 2,
    });

    marker.current = new mapboxgl.Marker({
      draggable: true,
      anchor: 'center',
      color: '#47525de0',
    })
      .setLngLat([longitude, latitude])
      .addTo(map.current);
  });

  useEffect(() => {
    if (!map.current) return;
    map.current.on('move', () => {
      if (map.current) {
        setLongitude(parseFloat(map.current.getCenter().lng.toFixed(4)));
        setLatitude(parseFloat(map.current.getCenter().lat.toFixed(4)));
        setZoom(parseFloat(map.current.getZoom().toFixed(2)));
      }
    });
  }, [setLatitude, setLongitude]);

  useEffect(() => {
    if (marker.current) marker.current.on('dragend', onDragEnd);
  });

  const onDragEnd = () => {
    if (marker.current) {
      const lngLat = marker.current.getLngLat();
      setPinLngLat(lngLat);
      if (map.current) {
        map.current.flyTo({
          center: [lngLat.lng, lngLat.lat],
        });
        setError({ latitude: '', longitude: '' });
      }
    }
  };

  const onLngLatChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 0 | 1
  ) => {
    setError({ latitude: '', longitude: '' });
    if (type === 0) {
      const lat = e.target.value as unknown as number;
      if (lat < 85 && lat > -85) {
        setPinLngLat({
          ...pinLngLat,
          lat: lat,
        });
        if (marker.current) {
          marker.current.setLngLat([pinLngLat.lng, lat]);
          if (map.current) {
            map.current.flyTo({
              center: [pinLngLat.lng, lat],
            });
          }
        }
      } else {
        setError({
          ...error,
          latitude: 'Latitude must be between -85 and 85',
        });
      }
    } else if (type === 1) {
      const lng = e.target.value as unknown as number;
      if (lng < 180 && lng > -180) {
        setPinLngLat({
          ...pinLngLat,
          lng: lng,
        });
        if (marker.current) {
          marker.current.setLngLat([lng, pinLngLat.lat]);
          if (map.current) {
            map.current.flyTo({
              center: [lng, pinLngLat.lat],
            });
          }
        }
      } else {
        setError({
          ...error,
          longitude: 'Longitude must be between -180 and 180',
        });
      }
    }
  };

  const generateLocation = (searchAddress: string) => {
    const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
    axios
      .get(`${url}/${searchAddress}.json?access_token=${accessToken}`)
      .then((res) => {
        const lat_new = res.data.features[0].center[1];
        const lng_new = res.data.features[0].center[0];
        setLatitude(lat_new);
        setLongitude(lng_new);
        setPinLngLat({
          lat: lat_new,
          lng: lng_new,
        });
        if (marker.current) {
          marker.current.setLngLat([lng_new, lat_new]);
          if (map.current) {
            map.current.flyTo({
              center: [lng_new, lat_new],
            });
          }
        }
      })
      .catch((err) => {
        console.log({ err });
        throw err;
      });
  };

  const handleGenerateCoordinates = () => {
    trigger(['city', 'coutry', 'zipCode', 'address']);
    const { zipCode, address, city, country } = generateGeocodeProps;
    try {
      if (zipCode === '' || address === '' || city === '') throw error;
      const newAddress = zipCode + ' ' + address + ' ' + city + ' ' + country;
      const newAddress_UTF8 = newAddress.replaceAll(' ', '%20');
      generateLocation(newAddress_UTF8);
      ToastHelper.showToast('Map', ToastType.SUCCESS, ToastMessageType.UPDATE);
    } catch (e) {
      ToastHelper.showToast(
        'Please check Country, Zip code, City and Address fields!',
        ToastType.ERROR,
        ToastMessageType.CUSTOM_ERROR
      );
    }
  };

  return (
    <Map>
      <InputsContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={pinLngLat.lat}
              onChange={(e) => {
                onLngLatChange(e, 0);
              }}
              id="latitude"
              label={t('Latitude')}
              error={!!error['latitude']}
              helperText={t(error['latitude'])}
              variant="outlined"
              inputMode="decimal"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={pinLngLat.lng}
              onChange={(e) => {
                onLngLatChange(e, 1);
              }}
              id="longitude"
              label={t('Longitude')}
              error={!!error['longitude']}
              helperText={t(error['longitude'])}
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              onClick={handleGenerateCoordinates}
              disableElevation
            >
              Update map
            </Button>
          </Grid>
        </Grid>
      </InputsContainer>
      <MapWrapper>
        <MapContainer ref={mapContainer} className="map-container" />
      </MapWrapper>
    </Map>
  );
};

const Map = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  margin-top: 80px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const InputsContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const MapWrapper = styled.div`
  width: 100%;
  @media (max-width: 1000px) {
    margin-top: 16px;
  }
`;
const MapContainer = styled.div`
  height: 400px;
  .mapboxgl-control-container {
    display: none;
  }
`;
