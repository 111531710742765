import { DBRoleApi } from '@tyrio/dto';
import {
  SearchableVerticalList,
  SearchableVerticalListDataType,
} from '@tyrio/ui-library';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import api from '@tyrio/api-factory';

import routeNames from '../../../lib/routeNames';
import { pageSize } from '../../../../../constants/pagination.constants';
import { useAuth } from '../../../context/AuthContext';

interface DashboardRolesProps {
  switchMenu?: boolean;
  setSwitchMenu: (menu: boolean) => void;
}

const DashboardRoles = ({ switchMenu, setSwitchMenu }: DashboardRolesProps) => {
  const { user } = useAuth();
  const clientId = user?.clientId;
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const location = useLocation();

  const searchRoles = async (roleName: string, page: number) => {
    return await api.fetch<DBRoleApi['list']>('role_list', {
      search: roleName,
      page: page,
      pageSize: pageSize,
    });
  };

  const { isFetching, isError, data } = useQuery(
    ['get_roles_list', { search, page }],
    () => searchRoles(search, page),
    {}
  );

  if (clientId === undefined) {
    data?.data.filter((item) => {
      return item.clientId === null || undefined;
    });
  }

  const [selected, setSelected] = useState<Record<string, boolean>>(
    location.pathname.split('/').pop() !== 'roles'
      ? { [location.pathname.split('/').pop()]: true }
      : {}
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      setSelected((prevValue) => ({
        ...prevValue,
        [item.id]: !prevValue[item.id],
      }));
      history.push(`/dashboard/company-settings/roles/${item.id}`);
    }
    if (type === 'new') {
      history.push(`/dashboard/company-settings/roles/new`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchDebounced = useCallback(
    debounce(
      (search: string) => {
        setSearch(search);
      },
      300,
      { leading: false }
    ),
    [search, isFetching, isError]
  );

  return (
    <SearchableVerticalList
      type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
      header="User Roles"
      data={
        data?.data.map((item) => ({
          id: item.id,
          name: item.roleName,
          type: item.roleDescription,
          active: item.active,
          key: item.id,
          disabled:
            !item.active ||
            item.roleName === 'Super Admin' ||
            item.roleName === 'Admin' ||
            item.roleName === 'Developer' ||
            item.clientPredefined,
          isSelected: selected[item.id],
        })) || []
      }
      onCreateNew={() => handleClick('new', {})}
      handleItemClick={(item) => handleClick('itemClicked', item)}
      path={routeNames.dashboard()}
      isFetching={isFetching}
      isError={isError}
      onChangePage={(page) => setPage(page)}
      pageNumber={page}
      count={data?.count}
      onSearchChange={setSearchDebounced}
      setSwitchMenu={setSwitchMenu}
      switchMenu={switchMenu}
    />
  );
};

export default DashboardRoles;
