import { TRule } from '../../typings';
import {
  GLOBAL_PCD_RULES,
  GLOBAL_COMMARK_RULES,
  GLOBAL_GET_EU_DIRECTIVE_NO_RULE,
  GLOBAL_MODEL_UID_RULE,
  GLOBAL_PROCESS_SPECIAL_MARKING_RULE,
  GLOBAL_PRODUCT_NAME_RULE,
  GLOBAL_SIZE_RULE,
  GLOBAL_SKU_RULE,
  GLOBAL_THREEPMFS_RULES,
  GENERATE_PRODUCT_UUID_RULE,
  GLOBAL_ORIGIN_RULE,
  GLOBAL_MS_RULES,
  GLOBAL_HEIGHT_RULE,
  GLOBAL_ALLSEASON_RULE,
  GLOBAL_FIX_EPRELURL_RULE,
  GLOBAL_BRAND_NAME_RULE,
  GLOBAL_VEHICLE_CLASS_RULE,
  GLOBAL_CONSTRUCTION_RULES,
  GLOBAL_COLORCOMBO_RULE,
} from '../global';
// import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';

export const TYRIOCUSTOM_CATALOG_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: 'c.origin',
    targetKey: 'origin',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.global.operate',
    destination: 'model',
    targetKey: 'category_id',
    sourceKey: 'c.categorySlug',
    operationName: 'getCategoryNameFromSlug',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.categorySlug',
    targetKey: 'categorySlug',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.season',
    targetKey: 'season',
    destination: 'model',
    outputType: 'string',
  },

  {
    type: 'rule.map',
    sourceKey: 'c.usage',
    targetKey: 'usage',
    destination: 'model',
    outputType: 'string',
  },

  {
    type: 'rule.map',
    sourceKey: 'c.wheelPosition',
    targetKey: 'wheelPosition',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.size',
    targetKey: 'size',
    destination: 'product',
    outputType: 'string',
  },

  // Brand ima pod attribute, brand class i brand group.
  {
    type: 'rule.map',
    sourceKey: 'c.brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.modelName',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },

  // PRODUCT
  {
    type: 'rule.map',
    sourceKey: 'c.ean',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.manufacturerCode',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.productName2',
    targetKey: 'productName2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.height',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.width2',
    targetKey: 'width2',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.height2',
  //   targetKey: 'height2',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.construction',
    targetKey: 'construction',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.construction2',
    targetKey: 'construction2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.rimDiameter',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.rimDiameter2',
    targetKey: 'rimDiameter2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.loadIndex',
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.loadIndex2',
    targetKey: 'loadIndex2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.speedIndex',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.speedIndex2',
    targetKey: 'speedIndex2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.structure',
    targetKey: 'structure',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.ms',
    condition: 'equals',
    conditionValues: ['M+S', 'TRUE', 'FALSE', ''],
    conditionOutputs: [true, true, false, false],
    destination: 'product',
    targetKey: 'ms',
    outputType: 'boolean',
    ignoreForCategories: [8, 3, 2], // There's also a global rule for this.
  },
  /** TODO: Q: Do we need to handle the threepmfs rule? **/
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.flank',
    condition: 'equals',
    conditionValues: ['TRUE', 'FALSE'],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'flank',
    outputType: 'boolean',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.oem',
    condition: 'equals',
    conditionValues: ['TRUE', 'FALSE', ''],
    conditionOutputs: [true, false, false],
    destination: 'product',
    targetKey: 'oem',
    outputType: 'boolean',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.electric',
    condition: 'equals',
    conditionValues: ['TRUE', 'FALSE', ''],
    conditionOutputs: [true, false, ''],
    destination: 'product',
    targetKey: 'electric',
    outputType: 'boolean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.tubeType',
    targetKey: 'tubeType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.pr',
    targetKey: 'pr',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.rfd',
    targetKey: 'rfd',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.euDirectiveNumber',
    targetKey: 'euDirectiveNumber',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.rollingResistance',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.wetGrip',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.noisePerformance',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.noiseClassType',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.vehicleClass',
    targetKey: 'vehicleClass',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.eprelId',
    targetKey: 'eprelId',
    destination: 'product',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.eprelUrl',
    targetKey: 'eprelUrl',
    destination: 'product',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.weight',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.et',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.cb',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.sidewall',
    targetKey: 'sidewall',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.compound',
    targetKey: 'compound',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.pcd',
  //   targetKey: 'pcd',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateTyrioCustomSpecialMarkings',
    applyOnlyForCategories: [1, 30, 31, 32, 33, 34, 35], // carTyres
  },
  // {
  //   type: 'rule.global.operate',
  //   targetKey: 'pcd',
  //   destination: 'product',
  //   operationName: 'generatePcdEt',
  // },
  {
    type: 'rule.map_array',
    sourceKey: 'c.pcd',
    targetKey: 'pcd',
    delimiter: '|',
    destination: 'product',
  },
  // {
  //   type: 'rule.global.operate',
  //   targetKey: 'color',
  //   destination: 'product',
  //   operationName: 'generateColorCombo',
  // },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.color',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  /** Global ruleset copied over */
  ...GLOBAL_PCD_RULES,
  ...GLOBAL_COMMARK_RULES,
  ...GLOBAL_MS_RULES,
  ...GLOBAL_THREEPMFS_RULES,
  GLOBAL_GET_EU_DIRECTIVE_NO_RULE,
  GLOBAL_PROCESS_SPECIAL_MARKING_RULE,
  ...GLOBAL_HEIGHT_RULE,
  ...GLOBAL_ALLSEASON_RULE,
  ...GLOBAL_COLORCOMBO_RULE,
  GLOBAL_FIX_EPRELURL_RULE,
  GLOBAL_BRAND_NAME_RULE,
  ...GLOBAL_VEHICLE_CLASS_RULE,
  ...GLOBAL_CONSTRUCTION_RULES,
  GENERATE_PRODUCT_UUID_RULE,
  GLOBAL_SKU_RULE,
  GLOBAL_ORIGIN_RULE,
  // These 3 must always be the last and in this order.
  // If you need to change it, update the ProductItemPage logic too
  GLOBAL_SIZE_RULE,
  // If there is size in custom pricat use that one
  {
    type: 'rule.condition',
    sourceKey: 'c.size',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.size',
    destination: 'product',
    targetKey: 'size',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.size2',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.size2',
    destination: 'product',
    targetKey: 'size2',
    outputType: 'string',
  },
  GLOBAL_PRODUCT_NAME_RULE,
  GLOBAL_MODEL_UID_RULE,
];
