import styled from '@emotion/styled';
import {
  Alert,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { StatusBadge } from '../supplier-form/SupplierAdminForm';
import { CompanyLogoImage } from '../supplier-form/SupplierClientForm';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import { DBSupplierApi, DBSupplierConnectionResponse } from '@tyrio/dto';
import { DBConnectionType } from '@prisma/client';
import api from '@tyrio/api-factory';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from '../../hooks/useDebounce';
import { useAuth } from '../../context/AuthContext';

interface SelectSupplierModalProps {
  supplierId: string;
  setSupplierId: (value: string) => void;
  onClose: () => void;
  onConfirm: () => void;
}

const SelectSupplierModal = ({
  supplierId,
  setSupplierId,
  onClose,
  onConfirm,
}: SelectSupplierModalProps) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { user } = useAuth();

  const clientId = user?.clientId || undefined;

  const { data, isFetching } = useQuery(
    ['supplier_list', { debouncedSearch }],
    () => searchSuppliers(search),
    {}
  );

  const searchSuppliers = async (search: string) => {
    return await api.fetch<DBSupplierApi['list']>('suppliers', {
      search: search,
      clientId: clientId,
    });
  };

  const getConnectionTypes = (connections: DBSupplierConnectionResponse[]) => {
    const rezultat: string[] = [];
    connections.forEach((connection) => {
      connection.connectionTypes.forEach((i: DBConnectionType) => {
        if (!rezultat.includes(i.toString())) {
          rezultat.push(i);
        }
      });
    });
    return rezultat.join(' - ').toLowerCase();
  };

  const getSupplierName = () => {
    return data?.data.find((i) => i.id === supplierId)?.companyOfficialName;
  };

  return (
    <ModalWrapper>
      <ModalContentWrapper>
        <ModalContent>
          <ModalHeader>
            <ModalHeaderText>Supplier Connections</ModalHeaderText>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
          </ModalHeader>
          <Divider light />
          <Grid
            sx={{
              padding: '28px 24px',
            }}
          >
            {isFetching ? (
              <Grid
                sx={{
                  paddingTop: '25%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid>
                <Grid>
                  <TextField
                    placeholder="Search suppliers"
                    fullWidth
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: search !== '' && (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: 'pointer' }}
                        >
                          <CloseIcon
                            onClick={() => {
                              setSearch('');
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    autoFocus={true}
                  />
                </Grid>
                <Grid
                  sx={{
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <Alert
                    severity="info"
                    iconMapping={{
                      info: <PanToolAltIcon fontSize="inherit" />,
                    }}
                  >
                    {supplierId ? (
                      <Typography>
                        <b>Selected supplier: </b> {getSupplierName()}
                      </Typography>
                    ) : (
                      <Typography>
                        <b>
                          Please select supplier, than you can define the
                          connection details!
                        </b>
                      </Typography>
                    )}
                  </Alert>
                </Grid>
                <Grid
                  sx={{
                    height: '400px',
                    overflow: 'scroll',
                    marginBottom: '15px',
                  }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '20px',
                    }}
                  >
                    {data?.data.map((item) => (
                      <Tooltip
                        title={
                          item.active
                            ? ''
                            : 'You can not choose an deactivated supplier!'
                        }
                        followCursor={!item.active}
                      >
                        <SupplierCard
                          isSelectedSupplier={supplierId === item.id}
                          isActive={item.active}
                          onClick={() => {
                            if (item.active) setSupplierId(item.id);
                          }}
                        >
                          <Grid sx={{ width: '100%' }}>
                            <Grid
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Grid>
                                <Chip
                                  size="small"
                                  avatar={
                                    <Avatar
                                      sx={{
                                        backgroundColor: item.isTyrioClient
                                          ? '#1565C0'
                                          : '#E65100',
                                      }}
                                    >
                                      <Typography sx={{ color: 'white' }}>
                                        {item.isTyrioClient ? 'T' : 'E'}
                                      </Typography>
                                    </Avatar>
                                  }
                                  label={item.isTyrioClient ? 'Tyrio' : 'Ext'}
                                  sx={{
                                    backgroundColor: 'white',
                                    border: `1px solid ${
                                      item.isTyrioClient ? '#1565C0' : '#E65100'
                                    }`,
                                    color: `${
                                      item.isTyrioClient ? '#1565C0' : '#E65100'
                                    }`,
                                  }}
                                />
                              </Grid>
                              <Grid>
                                <StatusBadge
                                  color={item.active ? '#56C489' : '#E64B4B'}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '40px',
                              }}
                            >
                              {item.companyLogo ? (
                                <CompanyLogoImage
                                  src={item.companyLogo.fileUrl}
                                  alt={item.companyShortName}
                                />
                              ) : (
                                <Grid
                                  sx={{
                                    width: '50px',
                                    height: '50px',
                                    background:
                                      'linear-gradient(140.03deg, #37496C 1.85%, #1B2130 85.49%)',
                                    borderRadius: '50%',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '28px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {item.companyOfficialName[0].toUpperCase()}
                                </Grid>
                              )}
                            </Grid>
                            <Grid
                              sx={{
                                marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  fontSize: '18px',
                                  lineHeight: '28px',
                                  letterSpacing: '0.3px',
                                }}
                              >
                                {item.companyShortName}
                              </Typography>
                            </Grid>
                            <Grid
                              sx={{
                                marginTop: '2px',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  letterSpacing: '0.3px',
                                  color: '#919EAB',
                                }}
                              >
                                {getConnectionTypes(item.supplierConnections)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </SupplierCard>
                      </Tooltip>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    color="error"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    color="info"
                    disabled={supplierId === ''}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    CONFIRM
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ModalContent>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const SupplierCard = styled.div<{
  isSelectedSupplier: boolean;
  isActive: boolean;
}>`
  cursor: pointer;
  display: flex;
  width: 425px;
  height: 145px;
  background: ${(props) => (props.isSelectedSupplier ? '#F0FAF4' : '#FFFFFF')};
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  padding: 16px;
  opacity: ${(props) => (props.isActive ? 'unset' : '0.5')};
  &:hover {
    border-color: ${(props) => props.isActive && '#b8bbbf'};
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  background-color: #fefefe;
  margin: auto;
  max-width: 918px;
  width: 100%;
  height: 730px;
  box-shadow: 0px 25px 50px rgba(100, 116, 139, 0.25);
  border-radius: 16px;
`;

const ModalContent = styled.div`
  width: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 24px;
`;

const ModalHeaderText = styled.span`
  font-sx: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
`;

export default SelectSupplierModal;
