import * as z from "zod"
import { CompleteDBInboundInvoice, RelatedDBInboundInvoiceModel } from "./index"

export const DBInboundInvoiceNumberModel = z.object({
  id: z.string(),
  supplierInvoice: z.string(),
  number: z.number().int(),
})

export interface CompleteDBInboundInvoiceNumber extends z.infer<typeof DBInboundInvoiceNumberModel> {
  inboundInvoices: CompleteDBInboundInvoice[]
}

/**
 * RelatedDBInboundInvoiceNumberModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBInboundInvoiceNumberModel: z.ZodSchema<CompleteDBInboundInvoiceNumber> = z.lazy(() => DBInboundInvoiceNumberModel.extend({
  inboundInvoices: RelatedDBInboundInvoiceModel.array(),
}))
