import styled from '@emotion/styled/macro';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Tooltip } from '@mui/material';
import { DBProductApi, PhotoShape, PosItem } from '@tyrio/dto';
import electricIcon from '../../assets/productDetailsNew/electric.svg';
import threePmfsIcon from '../../assets/productDetailsNew/eprel-3pmfs.svg';
import iceGripIcon from '../../assets/productDetailsNew/eprel-icegrip.svg';
import rfdIcon from '../../assets/productDetailsNew/rfd.svg';
import FavoriteProduct from './ProductComponents/FavoriteProduct';
import NoisePerformance from './ProductComponents/NoisePerformance';
import PerformanceCategory, {
  CategoryLevels,
} from './ProductComponents/PerformanceCategory';
import ProductDescription from './ProductComponents/ProductDescription';
import SeasonIcon from './ProductComponents/SeasonIcon';
import PhotoIcon from '@mui/icons-material/Photo';

type ActiveDrawer = '' | 'GALLERY' | 'PRINT';

interface ProductDetailsGeneratorProps {
  product: DBProductApi['getOne']['response'];
  setActiveDrawer: (active: ActiveDrawer) => void;
  stockListItemId: string;
  isFavoriteByUser: boolean;
  selectedTableRow?: PosItem;
}

const ProductDetailsGenerator = ({
  product,
  setActiveDrawer,
  stockListItemId,
  isFavoriteByUser,
  selectedTableRow,
}: ProductDetailsGeneratorProps) => {
  const hasPhotos =
    product?.model?.photos &&
    (product?.model?.photos as unknown as PhotoShape[]).length > 0;
  return (
    <Container>
      <TopWrapper>
        {product.rollingResistance &&
        product.noisePerformance &&
        product.wetGrip ? (
          <Tooltip
            placement="top"
            title={'Click to preview and print Eprel/EU Label'}
            style={{ cursor: 'pointer' }}
            onClick={() => setActiveDrawer('PRINT')}
            children={
              <PerformanceWrapper>
                <PerformanceCategory
                  category={product.rollingResistance as CategoryLevels}
                  type="rollingResistance"
                />

                <PerformanceCategory
                  category={product.wetGrip as CategoryLevels}
                  type="wetGrip"
                />

                <NoisePerformance value={product.noisePerformance} />

                <SpecIconsWrapper>
                  {product.threepmfs && (
                    <img src={threePmfsIcon} alt="three-pmfs" />
                  )}
                  {product.iceGrip && <img src={iceGripIcon} alt="ice-grip" />}
                </SpecIconsWrapper>
              </PerformanceWrapper>
            }
          />
        ) : (
          <div style={{ width: '80px' }} />
        )}

        <ImageWrapper>
          {hasPhotos ? (
            <Image
              src={(product?.model?.photos as unknown as PhotoShape[])[0].url}
              alt="tyre"
              style={{ height: '230px', width: '200px' }}
            />
          ) : (
            <NoImage style={{ height: '230px', width: '200px' }}>
              <PhotoIcon
                color="disabled"
                sx={{ height: '80%', width: '80%' }}
              />
              <div style={{ color: 'gray' }}>No image</div>
            </NoImage>
          )}

          {hasPhotos && (
            <ImageOverlay onClick={() => setActiveDrawer('GALLERY')}>
              <ZoomInIcon
                style={{ width: '60px', height: '60px', color: '#565873' }}
              />
            </ImageOverlay>
          )}
        </ImageWrapper>

        <MarkingsWrapper>
          <FavoriteProduct
            stockListItemId={stockListItemId}
            isFavoriteByUser={isFavoriteByUser}
            selectedTableRow={selectedTableRow}
          />

          {product.model?.season && (
            <SeasonIcon season={product.model.season} />
          )}

          {product.electric && <Img src={electricIcon} alt="electric-icon" />}

          {product.rfd && <Img src={rfdIcon} alt="rfd-icon" />}
        </MarkingsWrapper>
      </TopWrapper>

      <ProductDescription product={product} />
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  gap: 24px;
  width: 100%;
  min-height: 364px;
  display: flex;
  flex-direction: column;
  border: 1px dashed #dfe3e8;
  border-radius: 16px;
  max-width: 100%;
`;

const Img = styled.img`
  width: 32px;
  margin-bottom: 10px;
`;

const SpecIconsWrapper = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-between;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PerformanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MarkingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  max-height: 230px;
  max-width: 200px;
  &:hover img {
    opacity: 0.4;
  }
`;

const Image = styled.img`
  object-fit: contain;
  height: 230px;
`;

const ImageOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default ProductDetailsGenerator;
