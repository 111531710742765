import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 16px;
  flex-direction: column;
  overflow: scroll;
  justify-content: space-between;
`;
