import styled from '@emotion/styled';

export const MenuItemWrapper = styled.div<{
  background: string;
  hoverColor?: string;
  status: string;
  disabled: boolean;
  selected: boolean;
  border: string;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.selected ? '90%' : '90px')};
  margin-right: ${(props) => props.selected && '11px'};
  padding-left: ${(props) => props.selected && '15px'};
  width: 100%;
  border-radius: 8px;
  background: ${(props) => props.background};
  justify-content: center;
  align-items: center;
  padding: 13px 0;
  border: 1px solid ${(props) => props.border};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  &:hover {
    border: 1px solid
      ${(props) => (props.disabled ? props.background : props.hoverColor)};
    border-left: ${(props) => props.selected && 'white'};
  }

  &:hover span {
    color: ${(props) =>
      props.status === 'DEFAULT' && !props.disabled ? props.hoverColor : null};
  }

  &:hover .MuiBadge-badge {
    color: white;
  }

  &:hover svg {
    color: ${(props) =>
      props.status === 'DEFAULT' && !props.disabled ? props.hoverColor : null};
  }

  // if tab is selected
  border-bottom-left-radius: ${(props) => props.selected && 0};
  border-top-left-radius: ${(props) => props.selected && 0};
  border-left: ${(props) => props.selected && 'white'};
`;
