import api from '@tyrio/api-factory';
import { DBUserApi } from '@tyrio/dto';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import { useQuery } from 'react-query';
import {
  DocumentDetailsWrapper,
  DocumentNameWrapper,
  DocumentReferenceWrapper,
  FileIconNameWrapper,
  ReservedNumberWrapper,
  ReservedTextWrapper,
  ReservedWrapper,
} from './ItemReservationInfo.style';

export interface CartReservedItem {
  reserved: number;
  user: string;
  id: string;
}

interface CartReservedItemsProps {
  reserved: number;
  handleHoverState: (a: string, b: number) => void;
  index: number;
  userIds: string[];
}

const CartReservedItems = ({
  reserved,
  handleHoverState,
  index,
  userIds,
}: CartReservedItemsProps) => {
  const { data: users } = useQuery(['get_user_by_ids'], () =>
    api.fetch<DBUserApi['getMany']>(`get_user_by_ids`, {
      userIds: userIds,
    })
  );

  return (
    <DocumentDetailsWrapper
      onMouseLeave={() => handleHoverState('leave', index)}
    >
      <ReservedWrapper>
        <ReservedTextWrapper>Reserved</ReservedTextWrapper>
        <ReservedNumberWrapper>{reserved}</ReservedNumberWrapper>
      </ReservedWrapper>
      <FileIconNameWrapper>
        <WmsIcon icon={wmsIcons.cart} alt="cart-icon" color="info" />

        <ReservedWrapper style={{ alignItems: 'flex-start', gap: '5px' }}>
          <DocumentNameWrapper>In Cart</DocumentNameWrapper>
          <DocumentReferenceWrapper
            style={{
              maxWidth: '300px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {users &&
              users
                .map(
                  (user: {
                    id: string;
                    firstName: string;
                    lastName: string;
                  }) => {
                    return user.firstName + ' ' + user.lastName;
                  }
                )
                .join(', ')}
          </DocumentReferenceWrapper>
        </ReservedWrapper>
      </FileIconNameWrapper>
    </DocumentDetailsWrapper>
  );
};

export default CartReservedItems;
