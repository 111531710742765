import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';

export function globalZbrFields(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error('globalZbrFields requires rule to be of type TOperateRule');

  let construction =
    input['Speed'].includes('W') || input['Speed'].includes('Z')
      ? 'ZR'
      : input['Trenner'];
  const structure =
    construction === '-' || construction === 'B' ? 'Diagonal' : '';

  if (input['Gruppe'] === '000088') {
    construction = '';
  }

  let axle = '';
  switch (input['Front/Rear'].toUpperCase()) {
    case 'FRONT':
    case 'F':
    case 'FR':
      axle = 'front';
      break;
    case 'REAR':
    case 'R':
    case 'RE':
      axle = 'rear';
      break;
    case 'F/R':
    case 'FRONT/REAR':
      axle = 'frontrear';
      break;
    default:
      axle = '';
  }

  const model = input['Profil']
    .replace('M+S', '')
    .replace('TL/TT', '')
    .replace('TT/TL', '')
    .replace('TT', '')
    .replace('TL', '')
    .replace('Front/Rear', '')
    .replace('Front', '')
    .replace('Rear', '')
    .replace('Front', '')
    .replace('WWW', '')
    .replace('WW', '')
    .replace('RF.', '')
    .replace('RF', '')
    .replace('REINF.', '')
    .replace('REINF', '')
    .replace('Reinf.', '')
    .replace('Reinf', '')
    .replace(/ *\([^)]*\) */g, '');

  const sidewall = input['Zusatz']?.toLowerCase().includes('weiss') ? 'WW' : '';

  const rfd =
    input['Profil'].includes('RF') ||
    input['Profil'].toLowerCase().includes('reinf')
      ? 'REINF'
      : '';

  const data = {
    product: {
      construction: construction,
      structure: structure,
      rfd: rfd,
    },
    model: {
      wheelPosition: axle,
      modelName: model,
      sidewall: sidewall,
    },
  } as Partial<UnfinishedData>;

  return data;
}
