import styled from '@emotion/styled';
import Popover from '@mui/material/Popover';
import { YearChip } from '@tyrio/wms-ui-library';
import pasteIcon from './assets/paste.png';
import printerIcon from './assets/printer.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface PopoverComponentProps {
  id: string;
  anchorEl: HTMLButtonElement;
  setAnchorEl: (value: HTMLButtonElement | null) => void;
  shouldShowOrderAgain?: boolean;
}

const PopoverComponent = ({
  anchorEl,
  id,
  setAnchorEl,
  shouldShowOrderAgain = true,
}: PopoverComponentProps) => {
  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          padding: '16px',
          boxShadow: 'initial',
          border: '1px solid #c4cdd5',
        },
      }}
    >
      <StyledPopover elementsSize={shouldShowOrderAgain ? 3 : 2}>
        <PopoverItemSoon>
          <IconTextWrapper>
            <PopoverIcon src={pasteIcon} alt="paste icon" />
            Copy items
          </IconTextWrapper>

          <YearChip year={'soon'} color="default" customColor="#ED6C02" />
        </PopoverItemSoon>

        {shouldShowOrderAgain && (
          <PopoverItemSoon>
            <IconTextWrapper>
              <ShoppingCartIcon
                width="27px"
                style={{
                  marginRight: '10px',
                  objectFit: 'contain',
                }}
              />
              Order again
            </IconTextWrapper>

            <YearChip year={'soon'} color="default" customColor="#ED6C02" />
          </PopoverItemSoon>
        )}

        <PopoverItemSoon>
          <IconTextWrapper>
            <PopoverIcon src={printerIcon} alt="printer icon" />
            Print order
          </IconTextWrapper>
          <YearChip year={'soon'} color="default" customColor="#ED6C02" />
        </PopoverItemSoon>
      </StyledPopover>
    </Popover>
  );
};

const StyledPopover = styled.div<{ elementsSize: number }>`
  width: 262px;
  height: ${(props) => props.elementsSize * 50}px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IconTextWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const PopoverItem = styled.div`
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  padding: 15px;

  &:hover {
    background: rgb(0, 0, 0, 0.05);
    cursor: pointer;
    border-radius: 8px;
  }
`;

const PopoverItemSoon = styled(PopoverItem)`
  color: #919eab;
  opacity: 0.5;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  &:hover {
    cursor: not-allowed;
  }
`;

const PopoverIcon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 10px;
`;

export default PopoverComponent;
