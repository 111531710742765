/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '@tyrio/api-factory';
import { DBDispatchApi } from '@tyrio/dto';
import { useQuery } from 'react-query';
import { prepOrders } from '../components/mapping';

export const useGetDispatch = (
  enabled: boolean,
  setWorkOrders: any,
  setReadyWorkOrders: any
) => {
  const { data, error, isFetched, refetch, status, isLoading, isFetching } =
    useQuery(
      ['get_dispatch'],
      () => api.fetch<DBDispatchApi['list']>('dispatch_orders'),
      {
        enabled,
        refetchInterval: 180000,
        onSuccess: (res) => {
          const data = prepOrders(res.data, 'DISPATCH');

          setWorkOrders((prevState: any) => ({
            ...prevState,
            DISPATCH: data.openOrders,
          }));

          setReadyWorkOrders((prevState: any) => ({
            ...prevState,
            DISPATCH: data.readyOrders,
          }));
        },
      }
    );

  return {
    data: data,
    isFetched,
    error,
    refetch,
    status,
    showLoader: isLoading || isFetching,
  };
};
