import {
  SearchableVerticalList,
  SearchableVerticalListDataType,
  SearchableVerticalListDataProps,
} from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useGetPaymentMethods } from '../dashboard/payment-methods/query/get-payment-method';
import { useDebounce } from '../../hooks/useDebounce';
import routeNames from '../../lib/routeNames';

interface PaymentMethodsProps {
  switchMenu?: boolean;
  setSwitchMenu: (menu: boolean) => void;
}

const PaymentMethods = ({ switchMenu, setSwitchMenu }: PaymentMethodsProps) => {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [, setSelected] = useState<Record<string, boolean>>({});

  const debouncedSearch = useDebounce(search, 500);
  const pageSize = 10;

  const { paymentData, count, isFetching, isError } = useGetPaymentMethods(
    debouncedSearch,
    page,
    pageSize
  );

  const handleClick = (
    type: string,
    item?: SearchableVerticalListDataProps
  ) => {
    if (type === 'itemClicked' && item !== undefined) {
      history.push(`/dashboard/company-settings/payment-method/${item.id}`);
    }
    if (type === 'new') {
      history.push(`/dashboard/company-settings/payment-method/new`);
    }
  };

  useEffect(() => {
    if (location.pathname === path) setSelected({});
  }, [debouncedSearch, location.pathname, path]);

  return (
    <SearchableVerticalList
      header="Payment methods"
      type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
      data={
        paymentData?.map((item) => ({
          id: item.id,
          name: item.name,
          active: item.isActive,
        })) || []
      }
      path={routeNames.dashboard()}
      isFetching={isFetching}
      isError={isError}
      onSearchChange={setSearch}
      onCreateNew={() => handleClick('new')}
      handleItemClick={(item) => {
        handleClick('itemClicked', item);
      }}
      onChangePage={(page) => setPage(page)}
      count={count}
      pageNumber={page}
      pageSize={pageSize}
      switchMenu={switchMenu}
      setSwitchMenu={setSwitchMenu}
    />
  );
};

export default PaymentMethods;
