import styled from '@emotion/styled';
import { usePosCtx } from '../../../../../../src/context/POSContext';
import { usePosCartCtx } from '../../../../../context/PosCartContext';
import { usePosCartData } from '../helpers/cart-data';
import { DeliveryTypeItem } from './DeliveryTypeItem';
import { ReservedItem, Wrap } from './ReservedItem';

export const Table = () => {
  const { modalData } = usePosCartData();
  const { input } = usePosCartCtx();
  const { selectedCustomer } = usePosCtx();

  return (
    <TableWrapper>
      <TableHeader>
        <ProductNameWrapper>
          <Text name={'true'}>Product name</Text>
        </ProductNameWrapper>
        <Wrap>
          <PriceWrapper>
            <Text name={'false'}>Price</Text>
            <Text name={'false'}>Discount</Text>
            <Text name={'false'}>Rebate</Text>
          </PriceWrapper>

          <TotalWrapper>
            <Text name={'false'}>Qty</Text>
            <Text name={'false'}>Total</Text>
            <div></div>
          </TotalWrapper>
        </Wrap>
      </TableHeader>
      <RowsWrapper>
        {modalData &&
          modalData.map((item) => (
            <ReservedItem
              key={item.key}
              productName={item?.req.productName ?? ''}
              productBrand={item.req.productBrand ?? ''}
              productDescription={item?.req.productDescription ?? ''}
              price={item?.req.price}
              discount={selectedCustomer?.rebateCalculation?.discount ?? 0}
              rebate={selectedCustomer?.rebateCalculation?.rebate ?? 0}
              quantity={item?.req.quantity ?? 0}
              itemKey={item.key}
              stockType={item.stockType}
              realQuantity={item.req.realQuantity}
              inclusiveVat={item?.req.inclusiveVat ?? 0}
              serviceCategory={item.req.serviceCategory}
              pcd={item?.req?.pcd}
              et={item?.req?.et}
            />
          ))}
        {input.delivery_method.deliveryType !== undefined && (
          <DeliveryTypeItem
            key={input.delivery_method.deliveryType.id}
            name={input.delivery_method.deliveryType.delivery}
            range={input.delivery_method.deliveryType.text}
            price={input.delivery_method.deliveryType.price}
            quantity={1}
          />
        )}
      </RowsWrapper>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableHeader = styled.div`
  border-bottom: 1px solid #dfe3e8;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

const ProductNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div<{ name: string }>`
  font-family: Barlow;
  font-size: 12px;
  font-weight: 400;
  width: ${(props) => (props.name === 'false' ? '60px' : '')};
  text-align: center;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 180px;
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 180px;
`;

const RowsWrapper = styled.div`
  max-height: 600px;
`;
