// import { sharedVars } from '@tyrio/shared-vars';
import { DBBusinessType, DBCurrency } from '@prisma/client';
import { isEUCountry } from '@tyrio/dto';
import _ from 'lodash';
import { FormShape, TyrioSelectInputOption } from '../types';
import { createInput } from '../utils';
import { zodPartnerObject } from './zodObjects';

export const generatePartnerForm = (
  disableEditFields: boolean,
  countries: TyrioSelectInputOption[],
  suppliers: TyrioSelectInputOption[],
  rebates: TyrioSelectInputOption[],
  customerTypes: TyrioSelectInputOption[],
  currencies: TyrioSelectInputOption[],
  invoiceTypes: TyrioSelectInputOption[],
  supplierTypes: TyrioSelectInputOption[],
  deliveryTermsTypes: TyrioSelectInputOption[],
  transportModes: TyrioSelectInputOption[],
  isUpdate: boolean,
  selectedType: {
    customer: boolean;
    supplier: boolean;
  },
  countryId: string,
  vatPostingGroup: string
) => {
  const isCustomer = selectedType.customer;
  const isSupplier = selectedType.supplier;

  const isTypeSelected = _.isUndefined(selectedType);

  const isCustomerHidden = isTypeSelected || !isCustomer;
  const isSupplierHidden = isTypeSelected || !isSupplier;

  const zodSchema = zodPartnerObject;

  const isEu = isEUCountry(countryId);
  const isForeign = vatPostingGroup === 'foreign';

  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: 'Main',
        inputs: [
          createInput({
            id: 'companyRegistrationNumber',
            type: 'input.partner',
            width: { xs: 12, lg: 12 },
            isVisible: isUpdate,
          }),
          createInput({
            id: 'companyOfficialName',
            label: 'Company Official Name',
            type: 'input.string',
            required: true,
            disabled: disableEditFields,
            width: { xs: 12, lg: 6 },
          }),
          createInput({
            id: 'businessType',
            width: { xs: 12, lg: 3 },
            type: 'input.select',
            label: 'Business Type *',
            disabled: disableEditFields,
            options: [
              {
                value: DBBusinessType.DOO,
                label: 'd.o.o.',
              },
              {
                value: DBBusinessType.JDOO,
                label: 'j.d.o.o.',
              },
              {
                value: DBBusinessType.OBRT,
                label: 'Sole Proprietorship',
              },
            ],
          }),
          createInput({
            id: 'vatPostingGroup',
            label: 'VAT Posting Group *',
            type: 'input.select',
            width: { xs: 12, lg: 3 },
            required: true,
            options: [
              {
                value: 'domestic',
                label: 'Domestic',
              },
              {
                value: 'eu',
                label: 'EU',
              },
              {
                value: 'foreign',
                label: 'Foreign',
              },
            ],
          }),
          createInput({
            id: 'companyDisplayName',
            label: 'Company Display Name',
            type: 'input.string',
            width: { xs: 12, lg: 4 },
          }),
          createInput({
            id: 'viesVatNumber',
            label: 'VAT Number',
            type: 'input.vies',
            width: { xs: 12, lg: 4 },
          }),
          createInput({
            id: 'countryId',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Country *',
            disabled: disableEditFields,
            options: countries,
          }),
          createInput({
            id: 'zipCode',
            label: 'Zip Code',
            type: 'input.string',
            disabled: disableEditFields,
            width: { xs: 12, lg: 3 },
            required: true,
          }),
          createInput({
            id: 'city',
            label: 'City',
            type: 'input.string',
            disabled: disableEditFields,
            width: { xs: 12, lg: 3 },
            required: true,
          }),
          createInput({
            id: 'address',
            label: 'Address',
            type: 'input.string',
            disabled: disableEditFields,
            width: { xs: 12, lg: 6 },
            required: true,
          }),
          createInput({
            id: 'remark',
            label: 'Remark',
            type: 'input.string',
            width: { xs: 12, lg: 12 },
          }),
          createInput({
            id: 'erpId',
            label: 'Erp ID',
            type: 'input.string',
            width: { xs: 12, lg: 12 },
          }),
        ],
      },
      {
        tabTitle: 'Contacts',
        inputs: [
          {
            id: 'partnerContacts',
            width: { xs: 12 },
            type: 'form.repeater',
            addRepeaterButtonText: 'Add new',
            deleteRepeaterButtonText: 'Delete',
            inputs: [
              createInput({
                id: 'labelContacts',
                width: { xs: 12 },
                type: 'label.section',
                dynamicLabel: {
                  parent: 'partnerContacts',
                  children: ['firstName', 'lastName'],
                },
              }),
              createInput({
                id: 'department',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'Department',
              }),
              createInput({
                id: 'firstName',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'First Name',
                required: true,
              }),
              createInput({
                id: 'lastName',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'Last Name ',
                required: true,
              }),

              createInput({
                id: 'email',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'E-mail ',
                required: true,
              }),
              createInput({
                id: 'businessPhone',
                width: { xs: 12, lg: 4 },
                type: 'input.phone',
                label: 'Business Phone',
              }),
              createInput({
                id: 'mobilePhone',
                width: { xs: 12, lg: 4 },
                type: 'input.phone',
                label: 'Mobile Phone',
              }),
              createInput({
                id: 'remark',
                width: { xs: 12 },
                type: 'input.string',
                label: 'Remark',
              }),
            ],
          },
        ],
      },
      {
        tabTitle: 'Banks',
        inputs: [
          {
            id: 'partnerBanks',
            width: { xs: 12 },
            type: 'form.repeater',
            addRepeaterButtonText: 'Add new',
            deleteRepeaterButtonText: 'Delete',
            inputs: [
              createInput({
                id: 'labelBanks',
                width: { xs: 12 },
                type: 'label.section',
                dynamicLabel: {
                  parent: 'partnerBanks',
                  children: ['bankName'],
                },
              }),
              createInput({
                id: 'bankName',
                width: { xs: 12, lg: 3 },
                type: 'input.string',
                label: 'Bank name',
                required: true,
              }),
              createInput({
                id: 'iban',
                width: { xs: 12, lg: 3 },
                type: 'input.string',
                label: 'IBAN',
                required: true,
              }),
              createInput({
                id: 'swift',
                width: { xs: 12, lg: 3 },
                type: 'input.string',
                label: 'Swift ',
                required: true,
              }),
              createInput({
                id: 'currency',
                width: { xs: 12, lg: 3 },
                type: 'input.select',
                label: 'Client Currency',
                options: [
                  {
                    value: DBCurrency.EUR,
                    label: '€',
                  },
                  {
                    value: DBCurrency.BAM,
                    label: 'BAM',
                  },
                  {
                    value: DBCurrency.RSD,
                    label: 'RSD',
                  },
                  {
                    value: DBCurrency.USD,
                    label: 'USD',
                  },
                ],
              }),
            ],
          },
        ],
      },
      {
        tabTitle: 'Settings',
        inputs: [
          createInput({
            id: 'partnerType',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Partner type',
          }),
          createInput({
            id: 'customerPartnerType',
            width: { xs: 6, lg: 2 },
            type: 'input.switch',
            label: 'Customer',
          }),
          createInput({
            id: 'supplierPartnerType',
            width: { xs: 6, lg: 2 },
            type: 'input.switch',
            label: 'Supplier',
          }),
          createInput({
            id: 'customerSettings',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Customer settings',
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'customerType',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            options: customerTypes,
            label: 'Customer type',
            required: false,
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'customerSettings',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Payment terms',
            inlineLable: true,
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'rebateCalculationId',
            width: { xs: 9, lg: 3 },
            type: 'input.select',
            options: rebates,
            label: 'Rebate group',
            required: false,
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'paymentDelay',
            width: { xs: 6, lg: 2 },
            type: 'input.string',
            label: 'Payment delay',
            required: false,
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'creditLimit',
            width: { xs: 6, lg: 2 },
            type: 'input.string',
            inputType: 'number',
            label: 'Credit limit',
            required: false,
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'currency',
            width: { xs: 6, lg: 2 },
            type: 'input.select',
            options: currencies,
            label: 'Currency',
            required: false,
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'customerVat',
            width: { xs: 10, lg: 3 },
            type: 'input.select',
            label: 'Customer VAT',
            options: [],
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'customerSettings',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Invoice',
            inlineLable: true,
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'invoices',
            width: { xs: 12, lg: 4 },
            type: 'input.multiselect',
            options: invoiceTypes,
            label: 'Invoice type',
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'emails',
            width: { xs: 12, lg: 8 },
            type: 'input.string',
            label: 'E-mail',
            hidden: isCustomerHidden,
          }),
          createInput({
            id: 'customerSettings',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Supplier settings',
            hidden: isSupplierHidden,
          }),
          createInput({
            id: 'supplierType',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Supplier type',
            options: supplierTypes.filter((item) => item.label !== 'Factory'),
            hidden: isSupplierHidden,
          }),
          createInput({
            id: 'supplierId',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Supplier connection',
            options: suppliers,
            hidden: isSupplierHidden,
          }),
          createInput({
            id: 'customerSettings',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Intrastat',
            inlineLable: true,
            hidden: isSupplierHidden || !isEu || isForeign,
          }),
          createInput({
            id: 'deliveryTermsType',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Delivery terms and conditions',
            options: deliveryTermsTypes,
            hidden: isSupplierHidden || !isEu || isForeign,
          }),
          createInput({
            id: 'transactionNature',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Nature of transaction',
            options: [
              11, 12, 21, 22, 23, 31, 32, 33, 34, 41, 42, 51, 52, 60, 71, 72,
              80, 91, 99,
            ].map((item) => ({
              label: item.toString(),
              value: item.toString(),
            })),
            hidden: isSupplierHidden || !isEu || isForeign,
          }),
          createInput({
            id: 'transportMode',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Mode of transport',
            options: transportModes,
            hidden: isSupplierHidden || !isEu || isForeign,
          }),
          createInput({
            id: 'customerSettings',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Payment terms',
            inlineLable: true,
            hidden: isSupplierHidden,
          }),
          createInput({
            id: 'paymentDelay',
            width: { xs: 3, lg: 3 },
            type: 'input.string',
            label: 'Payment delay',
            required: false,
            hidden: isSupplierHidden,
          }),
          createInput({
            id: 'creditLimit',
            width: { xs: 3, lg: 3 },
            type: 'input.string',
            label: 'Credit limit',
            required: false,
            hidden: isSupplierHidden,
          }),
          createInput({
            id: 'paymentTerms',
            width: { xs: 3, lg: 3 },
            type: 'input.select',
            label: 'Currency',
            options: currencies,
            hidden: isSupplierHidden,
          }),
          createInput({
            id: 'supplierVat',
            width: { xs: 3, lg: 3 },
            type: 'input.select',
            label: 'Supplier VAT',
            options: [],
            hidden: isSupplierHidden,
          }),
        ],
      },
      {
        tabTitle: 'Locations',
        inputs: [
          {
            id: 'partnerLocations',
            width: { xs: 12 },
            type: 'form.repeater',
            addRepeaterButtonText: 'Add new',
            deleteRepeaterButtonText: 'Delete',
            inputs: [
              createInput({
                id: 'labelLocations',
                width: { xs: 12 },
                type: 'label.section',
                dynamicLabel: {
                  parent: 'partnerLocations',
                  children: ['locationName'],
                },
              }),
              createInput({
                id: 'locationName',
                width: { xs: 12, lg: 8 },
                type: 'input.string',
                label: 'Location name',
                required: true,
              }),
              createInput({
                id: 'invoiceAddress',
                width: { xs: 6, lg: 2 },
                type: 'input.switch',
                label: 'Invoice address',
              }),
              createInput({
                id: 'shippingAddress',
                width: { xs: 6, lg: 2 },
                type: 'input.switch',
                label: 'Shipping address',
              }),
              createInput({
                id: 'zipCodeLocation',
                width: { xs: 12, lg: 3 },
                type: 'input.string',
                label: 'Zip code',
                required: true,
              }),
              createInput({
                id: 'cityLocation',
                width: { xs: 12, lg: 3 },
                type: 'input.string',
                label: 'City ',
                required: true,
              }),
              createInput({
                id: 'addressLocation',
                width: { xs: 12, lg: 6 },
                type: 'input.string',
                label: 'Address ',
                required: true,
              }),
              createInput({
                id: 'emailLocation',
                width: { xs: 12, lg: 6 },
                type: 'input.string',
                label: 'E-mail ',
              }),
              createInput({
                id: 'businessPhoneLocation',
                width: { xs: 12, lg: 6 },
                type: 'input.phone',
                label: 'Business phone',
              }),
              createInput({
                id: 'erpId',
                label: 'Erp ID',
                type: 'input.string',
                width: { xs: 12, lg: 12 },
              }),
            ],
          },
        ],
      },
    ],
  };
  return { form, zodSchema };
};
