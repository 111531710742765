import {
  DBCharacterType,
  DBCountry,
  DBCurrency,
  DBDecimalSeparator,
  DBLanguage,
  DBTimeZone,
} from '@prisma/client';

export const countries: DBCountry[] = [
  {
    id: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    name: 'Afghanistan',
    native_name: 'Afghanistan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AF',
    intrastat_code: 'AF',
    calling_code: 93,
    TLD: 'af',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '47b321ed-f0a5-140c-d3ef-2ac9c4dfeadf',
    name: 'Albania',
    native_name: 'Albania',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AL',
    intrastat_code: 'AL',
    calling_code: 355,
    TLD: 'al',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd0cd3e4b-3879-5108-a334-66cf05286a65',
    name: 'Algeria',
    native_name: 'Algeria',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DZ',
    intrastat_code: 'DZ',
    calling_code: 213,
    TLD: 'dz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4adaafc3-b57a-af97-5892-0a5434e10da1',
    name: 'American Samoa',
    native_name: 'American Samoa',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AS',
    intrastat_code: 'AS',
    calling_code: 1,
    TLD: 'as',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '73e6690a-6022-331d-b5b1-bce22342dfeb',
    name: 'Andorra',
    native_name: 'Andorra',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AD',
    intrastat_code: 'AD',
    calling_code: 376,
    TLD: 'ad',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '124b24c8-26cb-792b-1ac6-123898e9e67e',
    name: 'Angola',
    native_name: 'Angola',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AO',
    intrastat_code: 'AO',
    calling_code: 244,
    TLD: 'ao',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '9db352e8-196e-2503-bd52-0dc8f0570e3d',
    name: 'Anguilla',
    native_name: 'Anguilla',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AI',
    intrastat_code: 'AI',
    calling_code: 1,
    TLD: 'ai',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '67e923b6-e851-8c60-89dc-38aa385bd7e7',
    name: 'Antarctica',
    native_name: 'Antarctica',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AQ',
    intrastat_code: 'AQ',
    calling_code: 672,
    TLD: 'aq',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e7fc01c8-c013-928e-9ea3-c724521406e8',
    name: 'Antigua and Barbuda',
    native_name: 'Antigua and Barbuda',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AG',
    intrastat_code: 'AG',
    calling_code: 1,
    TLD: 'ag',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b502acbf-dd88-6bc4-db08-7ecdd9622a39',
    name: 'Argentina',
    native_name: 'Argentina',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AR',
    intrastat_code: 'AR',
    calling_code: 54,
    TLD: 'ar',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '07f1c74a-cfbf-76ad-146b-73887b12da16',
    name: 'Armenia',
    native_name: 'Armenia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AM',
    intrastat_code: 'AM',
    calling_code: 374,
    TLD: 'am',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '9bb322b4-2cef-5dde-2280-8004d88fe933',
    name: 'Aruba',
    native_name: 'Aruba',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AW',
    intrastat_code: 'AW',
    calling_code: 297,
    TLD: 'aw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'eda4591c-6910-7fc1-ab74-c15f76c1d7fe',
    name: 'Australia',
    native_name: 'Australia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AU',
    intrastat_code: 'AU',
    calling_code: 61,
    TLD: 'au',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5c1a0ec0-9637-1c16-2373-66389026c6cb',
    name: 'Austria',
    native_name: 'Austria',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AT',
    intrastat_code: 'AT',
    calling_code: 43,
    TLD: 'at',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },

  {
    id: 'b9e2fb54-f03c-8fce-b9cd-aa23ee5bb305',
    name: 'Azerbaijan',
    native_name: 'Azerbaijan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AZ',
    intrastat_code: 'AZ',
    calling_code: 994,
    TLD: 'az',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '63100e77-db9a-cd5d-cc4e-b9193951b56e',
    name: 'Bahamas',
    native_name: 'Bahamas',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BS',
    intrastat_code: 'BS',
    calling_code: 1,
    TLD: 'bs',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '02ce1b4e-b767-de05-6c33-d8829d4f3582',
    name: 'Bahrain',
    native_name: 'Bahrain',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BH',
    intrastat_code: 'BH',
    calling_code: 973,
    TLD: 'bh',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '91dc3a87-f5d5-9586-ec41-2cb3b788aa02',
    name: 'Bangladesh',
    native_name: 'Bangladesh',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BD',
    intrastat_code: 'BD',
    calling_code: 880,
    TLD: 'bd',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b1480a99-f605-dff5-c0a6-22a91fbd1986',
    name: 'Barbados',
    native_name: 'Barbados',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BB',
    intrastat_code: 'BB',
    calling_code: 1,
    TLD: 'bb',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2ac08100-7631-595d-64d9-0ea4efdd2a25',
    name: 'Belarus',
    native_name: 'Belarus',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BY',
    intrastat_code: 'BY',
    calling_code: 375,
    TLD: 'by',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '450239b4-d4cb-2c18-99a3-62e600570a29',
    name: 'Belgium',
    native_name: 'Belgium',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BE',
    intrastat_code: 'BE',
    calling_code: 32,
    TLD: 'be',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '29b0b566-9c5f-6e78-8fa5-99189254641e',
    name: 'Belize',
    native_name: 'Belize',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BZ',
    intrastat_code: 'BZ',
    calling_code: 501,
    TLD: 'bz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '872531f0-1e93-d3ae-6d9c-901a96354e0a',
    name: 'Benin',
    native_name: 'Benin',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BJ',
    intrastat_code: 'BJ',
    calling_code: 229,
    TLD: 'bj',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '93097410-8412-634f-9621-e12c94975a5d',
    name: 'Bermuda',
    native_name: 'Bermuda',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BM',
    intrastat_code: 'BM',
    calling_code: 1,
    TLD: 'bm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '8e1a59f4-34fe-99d0-4306-84d73b8d8ee1',
    name: 'Bhutan',
    native_name: 'Bhutan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BT',
    intrastat_code: 'BT',
    calling_code: 975,
    TLD: 'bt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5acd440e-ff46-45e3-09ef-143b5ad5648b',
    name: 'Bolivia (Plurinational State of)',
    native_name: 'Bolivia (Plurinational State of)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BO',
    intrastat_code: 'BO',
    calling_code: 591,
    TLD: 'bo',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'cae2315a-a76a-437e-d112-25194426d1f9',
    name: 'Bonaire, Sint Eustatius and Saba',
    native_name: 'Bonaire, Sint Eustatius and Saba',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BQ',
    intrastat_code: 'BQ',
    calling_code: 0,
    TLD: 'bq',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '612ba040-9704-ee7b-448f-d68915efd16b',
    name: 'Bosnia and Herzegovina',
    native_name: 'Bosnia and Herzegovina',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BA',
    intrastat_code: 'BA',
    calling_code: 387,
    TLD: 'ba',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '426d2ce1-a1ab-016f-4f7f-39f740241d9a',
    name: 'Botswana',
    native_name: 'Botswana',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BW',
    intrastat_code: 'BW',
    calling_code: 267,
    TLD: 'bw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '9c54546e-084c-d4a7-dc84-75e7dcab02db',
    name: 'Bouvet Island',
    native_name: 'Bouvet Island',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BV',
    intrastat_code: 'BV',
    calling_code: 0,
    TLD: 'bv',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4c28f999-a472-c4f8-b7dc-79fcc8a46b13',
    name: 'Brazil',
    native_name: 'Brazil',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BR',
    intrastat_code: 'BR',
    calling_code: 55,
    TLD: 'br',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '574a96bd-e981-f51d-a374-d2d6f3b0f60d',
    name: 'British Indian Ocean Territory',
    native_name: 'British Indian Ocean Territory',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IO',
    intrastat_code: 'IO',
    calling_code: 246,
    TLD: 'io',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '550d83ff-b4d2-d084-cac1-2e5e042d9422',
    name: 'Brunei Darussalam',
    native_name: 'Brunei Darussalam',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BN',
    intrastat_code: 'BN',
    calling_code: 673,
    TLD: 'bn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'ab01e378-7f36-4cb2-9734-542efeed7c26',
    name: 'Bulgaria',
    native_name: 'Bulgaria',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BG',
    intrastat_code: 'BG',
    calling_code: 359,
    TLD: 'bg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '921ce924-6dfe-8bee-542f-36a427226e6f',
    name: 'Burkina Faso',
    native_name: 'Burkina Faso',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BF',
    intrastat_code: 'BF',
    calling_code: 226,
    TLD: 'bf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '858071bb-cd9e-da6b-3740-d054eac55b36',
    name: 'Burundi',
    native_name: 'Burundi',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BI',
    intrastat_code: 'BI',
    calling_code: 257,
    TLD: 'bi',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'a93427e2-ee39-757f-6edd-54c8177f9e00',
    name: 'Cambodia',
    native_name: 'Cambodia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KH',
    intrastat_code: 'KH',
    calling_code: 855,
    TLD: 'kh',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '218fa829-18ef-352b-3952-6b37f0d58304',
    name: 'Cameroon',
    native_name: 'Cameroon',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CM',
    intrastat_code: 'CM',
    calling_code: 237,
    TLD: 'cm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '9f272ddd-0b64-72cb-9191-9c9678b64cd9',
    name: 'Canada',
    native_name: 'Canada',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CA',
    intrastat_code: 'CA',
    calling_code: 1,
    TLD: 'ca',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '99a59b09-b0fe-bfae-9f4e-440b6fe2722f',
    name: 'Cape Verde',
    native_name: 'Cape Verde',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CV',
    intrastat_code: 'CV',
    calling_code: 238,
    TLD: 'cv',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '63379307-2198-1e3c-e038-e57ce8e909dc',
    name: 'Cayman Islands',
    native_name: 'Cayman Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KY',
    intrastat_code: 'KY',
    calling_code: 1,
    TLD: 'ky',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '32c87870-379f-2928-ae47-85ddacdfe88a',
    name: 'Central African Republic',
    native_name: 'Central African Republic',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CF',
    intrastat_code: 'CF',
    calling_code: 236,
    TLD: 'cf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '87fd1e41-fe04-454c-8df2-a8c1e897ca8b',
    name: 'Chad',
    native_name: 'Chad',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TD',
    intrastat_code: 'TD',
    calling_code: 235,
    TLD: 'td',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '22fd9ac5-e7c2-58bd-ff87-f50e1f32e8fa',
    name: 'Chile',
    native_name: 'Chile',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CL',
    intrastat_code: 'CL',
    calling_code: 56,
    TLD: 'cl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '511bf9a3-e3d5-4b9c-fc40-19fb62534bf0',
    name: 'China',
    native_name: 'China',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CN',
    intrastat_code: 'CN',
    calling_code: 86,
    TLD: 'cn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f2fd50be-fd64-41a6-322c-c283754a37f8',
    name: 'Christmas Island',
    native_name: 'Christmas Island',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CX',
    intrastat_code: 'CX',
    calling_code: 61,
    TLD: 'cx',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'bbc31ce9-383d-00ab-04cb-262ef3217066',
    name: 'Cocos Islands (or Keeling Islands)',
    native_name: 'Cocos Islands (or Keeling Islands)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CC',
    intrastat_code: 'CC',
    calling_code: 61,
    TLD: 'cc',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b982c4a4-ad2f-a053-3306-22699f196738',
    name: 'Colombia',
    native_name: 'Colombia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CO',
    intrastat_code: 'CO',
    calling_code: 57,
    TLD: 'co',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '49b9d8cf-4ea3-3a02-c59b-9968a1b716c3',
    name: 'Comoros',
    native_name: 'Comoros',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KM',
    intrastat_code: 'KM',
    calling_code: 269,
    TLD: 'km',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '8330cd85-68a4-b61d-d2bc-1a992df285a6',
    name: 'Congo',
    native_name: 'Congo',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CG',
    intrastat_code: 'CG',
    calling_code: 242,
    TLD: 'cg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'a57f09b0-90fe-5ad9-f1f3-746a8249cf76',
    name: 'Congo, Democratic Republic of',
    native_name: 'Congo, Democratic Republic of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CD',
    intrastat_code: 'CD',
    calling_code: 243,
    TLD: 'cd',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '07ad8472-051f-7d79-135f-34e055dc2b82',
    name: 'Cook Islands',
    native_name: 'Cook Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CK',
    intrastat_code: 'CK',
    calling_code: 682,
    TLD: 'ck',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2d4fd8a3-88b1-450c-013c-afba77c0adfb',
    name: 'Costa Rica',
    native_name: 'Costa Rica',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CR',
    intrastat_code: 'CR',
    calling_code: 506,
    TLD: 'cr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '86424cc8-b33b-35b9-5d32-534603eccf98',
    name: "Cote d'Ivoire",
    native_name: "Cote d'Ivoire",
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CI',
    intrastat_code: 'CI',
    calling_code: 225,
    TLD: 'ci',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '274e7e37-6d32-f25b-4a5f-daf91b949567',
    name: 'Croatia',
    native_name: 'Croatia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HR',
    intrastat_code: 'HR',
    calling_code: 385,
    TLD: 'hr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '71576559-70e3-451e-52aa-bd9c7d93d166',
    name: 'Cuba',
    native_name: 'Cuba',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CU',
    intrastat_code: 'CU',
    calling_code: 53,
    TLD: 'cu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'baf77c5e-a353-726d-6aad-edefc0f0ce7c',
    name: 'Curaçao',
    native_name: 'Curaçao',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CW',
    intrastat_code: 'CW',
    calling_code: 599,
    TLD: 'cw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2c32a0e5-327b-4805-51b9-2262ebc5f909',
    name: 'Cyprus',
    native_name: 'Cyprus',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CY',
    intrastat_code: 'CY',
    calling_code: 357,
    TLD: 'cy',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4a44da57-dbbc-a603-74cf-cf8d3e55d56d',
    name: 'Czech Republic',
    native_name: 'Czech Republic',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CZ',
    intrastat_code: 'CZ',
    calling_code: 420,
    TLD: 'cz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '038d131f-4348-8f8a-68b5-46685c85730d',
    name: 'Denmark',
    native_name: 'Denmark',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DK',
    intrastat_code: 'DK',
    calling_code: 45,
    TLD: 'dk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '8035f1b0-bc22-efe7-68c5-b3449a4b0348',
    name: 'Djibouti',
    native_name: 'Djibouti',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DJ',
    intrastat_code: 'DJ',
    calling_code: 253,
    TLD: 'dj',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e9ac6646-4003-b3ca-23f6-cc81de3ea7f4',
    name: 'Dominica',
    native_name: 'Dominica',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DM',
    intrastat_code: 'DM',
    calling_code: 1,
    TLD: 'dm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f032bf49-9588-c0fc-13c1-3c152cf7aa93',
    name: 'Dominican Republic',
    native_name: 'Dominican Republic',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DO',
    intrastat_code: 'DO',
    calling_code: 1,
    TLD: 'do',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'cde97d3b-1759-18e8-11a5-2b649ae36475',
    name: 'Ecuador',
    native_name: 'Ecuador',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'EC',
    intrastat_code: 'EC',
    calling_code: 593,
    TLD: 'ec',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '84596736-ebed-ff11-0438-b2e165842527',
    name: 'Egypt',
    native_name: 'Egypt',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'EG',
    intrastat_code: 'EG',
    calling_code: 20,
    TLD: 'eg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b35e144e-2cd0-702c-a79a-fae73229cded',
    name: 'El Salvador',
    native_name: 'El Salvador',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SV',
    intrastat_code: 'SV',
    calling_code: 503,
    TLD: 'sv',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '685ed988-ddda-3510-b49f-ac932d408bc2',
    name: 'Equatorial Guinea',
    native_name: 'Equatorial Guinea',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GQ',
    intrastat_code: 'GQ',
    calling_code: 240,
    TLD: 'gq',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '66bfdbfa-3a3f-77fc-b165-0f24b7d573da',
    name: 'Eritrea',
    native_name: 'Eritrea',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ER',
    intrastat_code: 'ER',
    calling_code: 291,
    TLD: 'er',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4d7a97df-edd0-2654-9aaa-85a6655784d4',
    name: 'Estonia',
    native_name: 'Estonia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'EE',
    intrastat_code: 'EE',
    calling_code: 372,
    TLD: 'ee',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '41b5343f-233f-dc06-4935-700531ee2adc',
    name: 'Eswatini',
    native_name: 'Eswatini',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SZ',
    intrastat_code: 'SZ',
    calling_code: 268,
    TLD: 'sz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '0d5745ac-2b99-5ffd-bfd5-0d0410188e2d',
    name: 'Ethiopia',
    native_name: 'Ethiopia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ET',
    intrastat_code: 'ET',
    calling_code: 251,
    TLD: 'et',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '295237fc-4491-b0fc-c07e-f4b84613babf',
    name: 'Falkland Islands',
    native_name: 'Falkland Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FK',
    intrastat_code: 'FK',
    calling_code: 500,
    TLD: 'fk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c2043c5a-155e-3fa4-07c4-0dd377540065',
    name: 'Faroe Islands',
    native_name: 'Faroe Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FO',
    intrastat_code: 'FO',
    calling_code: 298,
    TLD: 'fo',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '359ec9f3-7cfa-2e19-3b24-917f4ef4b5c7',
    name: 'Fiji',
    native_name: 'Fiji',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FJ',
    intrastat_code: 'FJ',
    calling_code: 679,
    TLD: 'fj',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'ec388e15-3710-8e25-cde8-49d7eef27d15',
    name: 'Finland',
    native_name: 'Finland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FI',
    intrastat_code: 'FI',
    calling_code: 358,
    TLD: 'fi',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3c0f75fd-3b73-79eb-13a4-12293811e632',
    name: 'France',
    native_name: 'France',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FR',
    intrastat_code: 'FR',
    calling_code: 33,
    TLD: 'fr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7edb6fe1-9e16-f328-e678-e4ce4f2f6368',
    name: 'French Guiana',
    native_name: 'French Guiana',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GF',
    intrastat_code: 'GF',
    calling_code: 0,
    TLD: 'gf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '49740cf2-fa24-3f82-f9d7-203f4971c13c',
    name: 'French Polynesia',
    native_name: 'French Polynesia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PF',
    intrastat_code: 'PF',
    calling_code: 689,
    TLD: 'pf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c25da878-962b-bd10-7e12-d562e4f53271',
    name: 'French Southern Territories',
    native_name: 'French Southern Territories',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TF',
    intrastat_code: 'TF',
    calling_code: 0,
    TLD: 'tf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'adc74b42-f43d-be14-4a88-19b96ba51f8b',
    name: 'Gabon',
    native_name: 'Gabon',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GA',
    intrastat_code: 'GA',
    calling_code: 241,
    TLD: 'ga',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7bab11b3-23f1-de6d-1ad7-e3841f0bca8c',
    name: 'Gambia',
    native_name: 'Gambia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GM',
    intrastat_code: 'GM',
    calling_code: 220,
    TLD: 'gm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '30ba5c6b-b697-9b64-13ff-cfcf3bd4e248',
    name: 'Georgia',
    native_name: 'Georgia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GE',
    intrastat_code: 'GE',
    calling_code: 995,
    TLD: 'ge',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c1b450ae-7828-385e-df13-41ab5f49e0c3',
    name: 'Germany',
    native_name: 'Germany',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DE',
    intrastat_code: 'DE',
    calling_code: 49,
    TLD: 'de',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '04fd2702-f240-2dbb-5d3f-2f6930e30236',
    name: 'Ghana',
    native_name: 'Ghana',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GH',
    intrastat_code: 'GH',
    calling_code: 233,
    TLD: 'gh',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '22ba4964-56a5-7e0c-08f2-ccfe2c73a296',
    name: 'Gibraltar',
    native_name: 'Gibraltar',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GI',
    intrastat_code: 'GI',
    calling_code: 350,
    TLD: 'gi',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e5f240e1-341e-d3c9-cd11-7664fcd87a5c',
    name: 'Greece',
    native_name: 'Greece',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GR',
    intrastat_code: 'GR',
    calling_code: 30,
    TLD: 'gr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f27a307b-ca77-9d44-ae52-bdb5eac77f46',
    name: 'Greenland',
    native_name: 'Greenland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GL',
    intrastat_code: 'GL',
    calling_code: 299,
    TLD: 'gl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3e245b7d-4c73-8666-03f4-f68976276551',
    name: 'Grenada',
    native_name: 'Grenada',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GD',
    intrastat_code: 'GD',
    calling_code: 1,
    TLD: 'gd',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '61ef090f-597f-93f6-283f-aea1e85ef927',
    name: 'Guadeloupe',
    native_name: 'Guadeloupe',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GP',
    intrastat_code: 'GP',
    calling_code: 0,
    TLD: 'gp',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '70267749-b730-bc65-525a-8cfd5d905c12',
    name: 'Guam',
    native_name: 'Guam',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GU',
    intrastat_code: 'GU',
    calling_code: 1,
    TLD: 'gu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'fcb17790-cfc7-7652-dcb1-c72028ed26e2',
    name: 'Guatemala',
    native_name: 'Guatemala',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GT',
    intrastat_code: 'GT',
    calling_code: 502,
    TLD: 'gt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7d6a66eb-1e8f-ce4f-4211-92617db943e5',
    name: 'Guernsey',
    native_name: 'Guernsey',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GG',
    intrastat_code: 'GG',
    calling_code: 44,
    TLD: 'gg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '83bd8755-7137-d21a-06d8-8a5a88777047',
    name: 'Guinea',
    native_name: 'Guinea',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GN',
    intrastat_code: 'GN',
    calling_code: 224,
    TLD: 'gn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '6340c275-5156-a2ab-8df0-505b997c36c0',
    name: 'Guinea-Bissau',
    native_name: 'Guinea-Bissau',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GW',
    intrastat_code: 'GW',
    calling_code: 245,
    TLD: 'gw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4af4a0ea-a9a2-7801-ec3f-a80b45584a5a',
    name: 'Guyana',
    native_name: 'Guyana',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GY',
    intrastat_code: 'GY',
    calling_code: 592,
    TLD: 'gy',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd8dca245-c24d-ab8e-46fa-271cdf02216e',
    name: 'Haiti',
    native_name: 'Haiti',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HT',
    intrastat_code: 'HT',
    calling_code: 509,
    TLD: 'ht',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c81a1fab-3e6e-76a1-5d23-c5bdeb8c7dfe',
    name: 'Heard Island and McDonald Islands',
    native_name: 'Heard Island and McDonald Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HM',
    intrastat_code: 'HM',
    calling_code: 0,
    TLD: 'hm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '13d9b034-b081-6abb-0432-6f8f9b25cfb5',
    name: 'Holy See (Vatican City State)',
    native_name: 'Holy See (Vatican City State)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'VA',
    intrastat_code: 'VA',
    calling_code: 379,
    TLD: 'va',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '44912311-6655-dbe8-2665-75bfcbe4febc',
    name: 'Honduras',
    native_name: 'Honduras',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HN',
    intrastat_code: 'HN',
    calling_code: 504,
    TLD: 'hn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '54414f10-5836-622c-7935-a76b6253c277',
    name: 'Hong Kong',
    native_name: 'Hong Kong',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HK',
    intrastat_code: 'HK',
    calling_code: 852,
    TLD: 'hk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '850211d7-72e4-731d-f586-9817ade536db',
    name: 'Hungary',
    native_name: 'Hungary',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HU',
    intrastat_code: 'HU',
    calling_code: 36,
    TLD: 'hu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '0d0bbb73-bc61-8231-c36c-deaa04dc5e2b',
    name: 'Iceland',
    native_name: 'Iceland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IS',
    intrastat_code: 'IS',
    calling_code: 354,
    TLD: 'is',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '462aa5b1-8144-268e-ff47-ce6375f0160d',
    name: 'India',
    native_name: 'India',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IN',
    intrastat_code: 'IN',
    calling_code: 91,
    TLD: 'in',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '60f99570-52e7-28c7-c5ce-785c2221c95f',
    name: 'Indonesia',
    native_name: 'Indonesia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ID',
    intrastat_code: 'ID',
    calling_code: 62,
    TLD: 'id',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '9d0920f6-79fb-4f10-302b-954a47ccbb9e',
    name: 'Iran, Islamic Republic of',
    native_name: 'Iran, Islamic Republic of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IR',
    intrastat_code: 'IR',
    calling_code: 98,
    TLD: 'ir',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f24701b3-f8a4-2453-4535-80de94c2974a',
    name: 'Iraq',
    native_name: 'Iraq',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IQ',
    intrastat_code: 'IQ',
    calling_code: 964,
    TLD: 'iq',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '50135aa0-2011-8e2e-cf03-357c2ea101c3',
    name: 'Ireland',
    native_name: 'Ireland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IE',
    intrastat_code: 'IE',
    calling_code: 353,
    TLD: 'ie',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '0b176516-65d1-f0f5-1602-e2f8b47f1982',
    name: 'Isle of Man',
    native_name: 'Isle of Man',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IM',
    intrastat_code: 'IM',
    calling_code: 44,
    TLD: 'im',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '8ba575a5-12fe-5674-5b18-6f5a302019bd',
    name: 'Israel',
    native_name: 'Israel',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IL',
    intrastat_code: 'IL',
    calling_code: 972,
    TLD: 'il',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c3771d14-0359-f628-e161-587d8306d67e',
    name: 'Italy',
    native_name: 'Italy',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IT',
    intrastat_code: 'IT',
    calling_code: 39,
    TLD: 'it',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2d730758-4490-5fdf-3a29-460fecd3a9e9',
    name: 'Jamaica',
    native_name: 'Jamaica',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'JM',
    intrastat_code: 'JM',
    calling_code: 1,
    TLD: 'jm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2b50650c-1a2e-00e5-03b8-ec0b591b4b4f',
    name: 'Japan',
    native_name: 'Japan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'JP',
    intrastat_code: 'JP',
    calling_code: 81,
    TLD: 'jp',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'ff74c415-7f17-21d3-6085-b3c120a19389',
    name: 'Jersey',
    native_name: 'Jersey',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'JE',
    intrastat_code: 'JE',
    calling_code: 44,
    TLD: 'je',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '21dcf2ac-04e6-ccd5-d638-73bcd147adff',
    name: 'Jordan',
    native_name: 'Jordan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'JO',
    intrastat_code: 'JO',
    calling_code: 962,
    TLD: 'jo',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7afda34f-de9b-231a-c2fa-d3f20848831a',
    name: 'Kazakhstan',
    native_name: 'Kazakhstan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KZ',
    intrastat_code: 'KZ',
    calling_code: 7,
    TLD: 'kz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '1957cf79-b373-be5b-265a-55cf395787af',
    name: 'Kenya',
    native_name: 'Kenya',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KE',
    intrastat_code: 'KE',
    calling_code: 254,
    TLD: 'ke',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '94ebbbdf-9f1e-f596-5a73-4f0070bf03d0',
    name: 'Kiribati',
    native_name: 'Kiribati',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KI',
    intrastat_code: 'KI',
    calling_code: 686,
    TLD: 'ki',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '63ba4b9c-02a2-3e36-4524-536c4ec9c038',
    name: "Korea, Democratic People's Republic of",
    native_name: "Korea, Democratic People's Republic of",
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KP',
    intrastat_code: 'KP',
    calling_code: 850,
    TLD: 'kp',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c1004a4e-2fb8-b672-811f-4292186b06a6',
    name: 'Korea, Republic of',
    native_name: 'Korea, Republic of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KR',
    intrastat_code: 'KR',
    calling_code: 82,
    TLD: 'kr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'cdc5e3ca-6253-82c0-e4be-40dee481098d',
    name: 'Kosovo',
    native_name: 'Kosovo',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'XK',
    intrastat_code: 'XK',
    calling_code: 383,
    TLD: 'xk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'fecb5a82-fc15-198d-9b6e-6cab87b4a90d',
    name: 'Kuwait',
    native_name: 'Kuwait',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KW',
    intrastat_code: 'KW',
    calling_code: 965,
    TLD: 'kw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '0c550294-20f5-9e00-36aa-86de031eaef7',
    name: 'Kyrgyzstan',
    native_name: 'Kyrgyzstan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KG',
    intrastat_code: 'KG',
    calling_code: 996,
    TLD: 'kg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '69792fd6-61e0-5a39-9efd-1985172fd147',
    name: "Lao People's Democratic Republic",
    native_name: "Lao People's Democratic Republic",
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LA',
    intrastat_code: 'LA',
    calling_code: 856,
    TLD: 'la',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '574a93fc-effa-ca36-b9ed-45c458c10177',
    name: 'Latvia',
    native_name: 'Latvia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LV',
    intrastat_code: 'LV',
    calling_code: 371,
    TLD: 'lv',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b680a322-85b6-7ed8-10ec-859dfbf69169',
    name: 'Lebanon',
    native_name: 'Lebanon',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LB',
    intrastat_code: 'LB',
    calling_code: 961,
    TLD: 'lb',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3fa75791-97b3-7562-2137-1ea67eb8083a',
    name: 'Lesotho',
    native_name: 'Lesotho',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LS',
    intrastat_code: 'LS',
    calling_code: 266,
    TLD: 'ls',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '6c9177ef-900a-f5e8-2c93-e04646b48d14',
    name: 'Liberia',
    native_name: 'Liberia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LR',
    intrastat_code: 'LR',
    calling_code: 231,
    TLD: 'lr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f3bf8840-ac35-1987-65c1-710060569bb7',
    name: 'Libya',
    native_name: 'Libya',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LY',
    intrastat_code: 'LY',
    calling_code: 218,
    TLD: 'ly',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'eef20d9a-59ba-78da-94a3-faa926663dce',
    name: 'Liechtenstein',
    native_name: 'Liechtenstein',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LI',
    intrastat_code: 'LI',
    calling_code: 423,
    TLD: 'li',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'bb041318-0e4e-c6e1-a4a4-b0d1a84696dd',
    name: 'Lithuania',
    native_name: 'Lithuania',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LT',
    intrastat_code: 'LT',
    calling_code: 370,
    TLD: 'lt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'aeef7d5d-7720-3ab0-06dc-08aa9af33ce5',
    name: 'Luxembourg',
    native_name: 'Luxembourg',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LU',
    intrastat_code: 'LU',
    calling_code: 352,
    TLD: 'lu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3cfb22b0-b8cd-3800-bb59-72dd533b1088',
    name: 'Macao',
    native_name: 'Macao',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MO',
    intrastat_code: 'MO',
    calling_code: 853,
    TLD: 'mo',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e7317758-973c-236e-636f-469f22d8b8f5',
    name: 'Madagascar',
    native_name: 'Madagascar',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MG',
    intrastat_code: 'MG',
    calling_code: 261,
    TLD: 'mg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b91b0fae-3f46-5c11-3659-e4197f59038e',
    name: 'Malawi',
    native_name: 'Malawi',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MW',
    intrastat_code: 'MW',
    calling_code: 265,
    TLD: 'mw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '927cd685-551c-0eb3-179f-395f8020b014',
    name: 'Malaysia',
    native_name: 'Malaysia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MY',
    intrastat_code: 'MY',
    calling_code: 60,
    TLD: 'my',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '05122c67-a6ed-882a-30e8-76f4a4cf23fc',
    name: 'Maldives',
    native_name: 'Maldives',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MV',
    intrastat_code: 'MV',
    calling_code: 960,
    TLD: 'mv',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5b82c9c9-dc1d-6150-8ae7-66dbc41710a1',
    name: 'Mali',
    native_name: 'Mali',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ML',
    intrastat_code: 'ML',
    calling_code: 223,
    TLD: 'ml',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '412996e7-8540-a95a-5db2-9bf9ddd9af2c',
    name: 'Malta',
    native_name: 'Malta',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MT',
    intrastat_code: 'MT',
    calling_code: 356,
    TLD: 'mt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7c801709-2af5-e9e6-38e0-aabadae90421',
    name: 'Marshall Islands',
    native_name: 'Marshall Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MH',
    intrastat_code: 'MH',
    calling_code: 692,
    TLD: 'mh',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e7803dca-ef1e-71f3-e525-8655650e7002',
    name: 'Martinique',
    native_name: 'Martinique',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MQ',
    intrastat_code: 'MQ',
    calling_code: 0,
    TLD: 'mq',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c877110b-2e36-2dc0-0b7c-cb064241b547',
    name: 'Mauritania',
    native_name: 'Mauritania',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MR',
    intrastat_code: 'MR',
    calling_code: 222,
    TLD: 'mr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '198f56e6-f3cf-61f8-21a6-09023c781644',
    name: 'Mauritius',
    native_name: 'Mauritius',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MU',
    intrastat_code: 'MU',
    calling_code: 230,
    TLD: 'mu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7046cfca-e989-24e6-890e-c19d9d5ad9e7',
    name: 'Mayotte',
    native_name: 'Mayotte',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'YT',
    intrastat_code: 'YT',
    calling_code: 262,
    TLD: 'yt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd1616854-e2bb-7a31-db9f-52f089b703ca',
    name: 'Melilla',
    native_name: 'Melilla',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'XL',
    intrastat_code: 'XL',
    calling_code: 0,
    TLD: 'xl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e7288830-2f76-6718-0869-41fd65b4fb4f',
    name: 'Mexico',
    native_name: 'Mexico',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MX',
    intrastat_code: 'MX',
    calling_code: 52,
    TLD: 'mx',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '1a2d5928-8649-14e5-3efe-7392113fc1b6',
    name: 'Micronesia, Federated States of',
    native_name: 'Micronesia, Federated States of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FM',
    intrastat_code: 'FM',
    calling_code: 691,
    TLD: 'fm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3efe8a74-c1fc-25cc-a7fc-d5e9aa6e48c1',
    name: 'Moldova, Republic of',
    native_name: 'Moldova, Republic of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MD',
    intrastat_code: 'MD',
    calling_code: 373,
    TLD: 'md',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5c7a406c-a6f1-8581-5aa1-fe870cbcfd37',
    name: 'Monaco',
    native_name: 'Monaco',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MC',
    intrastat_code: 'MC',
    calling_code: 377,
    TLD: 'mc',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '9503beb4-4656-1478-1950-c44e795942ec',
    name: 'Mongolia',
    native_name: 'Mongolia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MN',
    intrastat_code: 'MN',
    calling_code: 976,
    TLD: 'mn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'edcaf1ea-8109-8a4d-199b-8db52074bcd8',
    name: 'Montenegro',
    native_name: 'Montenegro',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ME',
    intrastat_code: 'ME',
    calling_code: 382,
    TLD: 'me',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd7754ce7-c802-7208-7d34-d6c4e8d50e6d',
    name: 'Montserrat',
    native_name: 'Montserrat',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MS',
    intrastat_code: 'MS',
    calling_code: 1,
    TLD: 'ms',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '25106e3b-ba81-c05c-a480-fbb77a032f10',
    name: 'Morocco',
    native_name: 'Morocco',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MA',
    intrastat_code: 'MA',
    calling_code: 212,
    TLD: 'ma',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7b986628-c5ad-8fec-268f-ae53c1ed7757',
    name: 'Mozambique',
    native_name: 'Mozambique',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MZ',
    intrastat_code: 'MZ',
    calling_code: 258,
    TLD: 'mz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c53c0919-321d-9ddd-f858-b0741886307d',
    name: 'Myanmar',
    native_name: 'Myanmar',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MM',
    intrastat_code: 'MM',
    calling_code: 95,
    TLD: 'mm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3ac4c382-66e2-df76-c07f-a946bbe86717',
    name: 'Namibia',
    native_name: 'Namibia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NA',
    intrastat_code: 'NA',
    calling_code: 264,
    TLD: 'na',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '389b3f73-c6d7-bb70-bd7d-b7d709810232',
    name: 'Nauru',
    native_name: 'Nauru',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NR',
    intrastat_code: 'NR',
    calling_code: 674,
    TLD: 'nr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '00235a28-9338-555c-7100-820661386d37',
    name: 'Nepal',
    native_name: 'Nepal',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NP',
    intrastat_code: 'NP',
    calling_code: 977,
    TLD: 'np',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '220c4c01-1a76-fa47-d7d7-ab455f15d56e',
    name: 'Netherlands',
    native_name: 'Netherlands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NL',
    intrastat_code: 'NL',
    calling_code: 31,
    TLD: 'nl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'ac16fa24-cbff-ae49-c54e-7dea928ddaea',
    name: 'New Caledonia',
    native_name: 'New Caledonia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NC',
    intrastat_code: 'NC',
    calling_code: 687,
    TLD: 'nc',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5bb2450e-1c3a-d6ec-4a5d-c5c5a758e19e',
    name: 'New Zealand',
    native_name: 'New Zealand',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NZ',
    intrastat_code: 'NZ',
    calling_code: 64,
    TLD: 'nz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2a6320ed-2d82-d930-9fe3-474d0fb81031',
    name: 'Nicaragua',
    native_name: 'Nicaragua',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NI',
    intrastat_code: 'NI',
    calling_code: 505,
    TLD: 'ni',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd037a76f-46e1-3e71-d3dc-707d45708fb3',
    name: 'Niger',
    native_name: 'Niger',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NE',
    intrastat_code: 'NE',
    calling_code: 227,
    TLD: 'ne',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'ba847b5b-55e5-4976-6e55-763fbbf44792',
    name: 'Nigeria',
    native_name: 'Nigeria',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NG',
    intrastat_code: 'NG',
    calling_code: 234,
    TLD: 'ng',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '57fb39ae-0b3c-a30d-adff-c4db0ea3fb06',
    name: 'Niue',
    native_name: 'Niue',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NU',
    intrastat_code: 'NU',
    calling_code: 683,
    TLD: 'nu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd3a7eca4-24b5-030a-d232-46b8cb223cbe',
    name: 'Norfolk Island',
    native_name: 'Norfolk Island',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NF',
    intrastat_code: 'NF',
    calling_code: 0,
    TLD: 'nf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'efecf9b0-95f7-464d-3c2a-cbc0db4a72f3',
    name: 'Northern Mariana Islands',
    native_name: 'Northern Mariana Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MP',
    intrastat_code: 'MP',
    calling_code: 1,
    TLD: 'mp',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '947540ee-8829-fe66-8add-c6672346b4a3',
    name: 'Norway',
    native_name: 'Norway',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NO',
    intrastat_code: 'NO',
    calling_code: 47,
    TLD: 'no',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd72a7dae-9c73-fc4d-afe8-4faa7174dd55',
    name: 'Oman',
    native_name: 'Oman',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'OM',
    intrastat_code: 'OM',
    calling_code: 968,
    TLD: 'om',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '09d5c9de-b653-babb-7634-a320f2371376',
    name: 'Pakistan',
    native_name: 'Pakistan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PK',
    intrastat_code: 'PK',
    calling_code: 92,
    TLD: 'pk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'fab5fe33-d03d-2c62-8d22-7cdf43da982f',
    name: 'Palau',
    native_name: 'Palau',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PW',
    intrastat_code: 'PW',
    calling_code: 680,
    TLD: 'pw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '80b13af0-5fab-ddd5-a095-20b78015693e',
    name: 'Palestine, State of',
    native_name: 'Palestine, State of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PS',
    intrastat_code: 'PS',
    calling_code: 970,
    TLD: 'ps',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f436789a-a349-8106-7e69-1162437be698',
    name: 'Panama',
    native_name: 'Panama',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PA',
    intrastat_code: 'PA',
    calling_code: 507,
    TLD: 'pa',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4014e408-b898-50c5-d87c-3d071d84bcc5',
    name: 'Papua New Guinea',
    native_name: 'Papua New Guinea',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PG',
    intrastat_code: 'PG',
    calling_code: 675,
    TLD: 'pg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4b68196a-9913-6c43-bd7e-7acbfb5e81ef',
    name: 'Paraguay',
    native_name: 'Paraguay',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PY',
    intrastat_code: 'PY',
    calling_code: 595,
    TLD: 'py',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '88d01e13-e906-0072-0b03-5155114d8ddd',
    name: 'Peru',
    native_name: 'Peru',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PE',
    intrastat_code: 'PE',
    calling_code: 51,
    TLD: 'pe',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '8fcbe1b8-f4ad-8039-7395-7e5a08332266',
    name: 'Philippines',
    native_name: 'Philippines',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PH',
    intrastat_code: 'PH',
    calling_code: 63,
    TLD: 'ph',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'a886f89b-31bc-1768-b49b-3152dfd49c06',
    name: 'Pitcairn',
    native_name: 'Pitcairn',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PN',
    intrastat_code: 'PN',
    calling_code: 64,
    TLD: 'pn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '080daea0-5d6e-cd07-ca33-6e10dcaa1a4c',
    name: 'Poland',
    native_name: 'Poland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PL',
    intrastat_code: 'PL',
    calling_code: 48,
    TLD: 'pl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b1c9dbe5-7b3a-10a9-639f-199beed8f6c6',
    name: 'Portugal',
    native_name: 'Portugal',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PT',
    intrastat_code: 'PT',
    calling_code: 351,
    TLD: 'pt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '97f8a339-f7b0-bf2a-89f0-e492db9718c2',
    name: 'Puerto Rico',
    native_name: 'Puerto Rico',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PR',
    intrastat_code: 'PR',
    calling_code: 1,
    TLD: 'pr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '35292987-54b6-aa46-57f3-4a590868b962',
    name: 'Qatar',
    native_name: 'Qatar',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'QA',
    intrastat_code: 'QA',
    calling_code: 974,
    TLD: 'qa',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c45e7dc4-6d34-a17a-ced4-ab6928e6bb0e',
    name: 'Macedonia (Republic of North)',
    native_name: 'Macedonia (Republic of North)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MK',
    intrastat_code: 'MK',
    calling_code: 389,
    TLD: 'mk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '85afe5a0-f654-b006-5922-0180221d1e28',
    name: 'Romania',
    native_name: 'Romania',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'RO',
    intrastat_code: 'RO',
    calling_code: 40,
    TLD: 'ro',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'a3226212-e767-658d-8b1b-9055732d5640',
    name: 'Russian Federation',
    native_name: 'Russian Federation',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'RU',
    intrastat_code: 'RU',
    calling_code: 7,
    TLD: 'ru',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '08f5a181-2348-a26f-845a-e945f0991843',
    name: 'Rwanda',
    native_name: 'Rwanda',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'RW',
    intrastat_code: 'RW',
    calling_code: 250,
    TLD: 'rw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '985e3319-991d-d264-6c5e-098c64b15d11',
    name: 'Réunion',
    native_name: 'Réunion',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'RE',
    intrastat_code: 'RE',
    calling_code: 262,
    TLD: 're',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '44eedfe6-3bb0-c4d0-d163-e7a6c3591e1a',
    name: 'Saint Barthélemy',
    native_name: 'Saint Barthélemy',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BL',
    intrastat_code: 'BL',
    calling_code: 590,
    TLD: 'bl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '68d8ed65-4b30-0117-36a4-0fe26cce40e3',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    native_name: 'Saint Helena, Ascension and Tristan da Cunha',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SH',
    intrastat_code: 'SH',
    calling_code: 290,
    TLD: 'sh',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '1d71129f-979a-fe58-3b99-1cf212b0619e',
    name: 'Saint Kitts and Nevis',
    native_name: 'Saint Kitts and Nevis',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'KN',
    intrastat_code: 'KN',
    calling_code: 1,
    TLD: 'kn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f8829a3e-0038-9e93-8688-3692803e4c69',
    name: 'Saint Lucia',
    native_name: 'Saint Lucia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LC',
    intrastat_code: 'LC',
    calling_code: 1,
    TLD: 'lc',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b0845f0e-f26b-7e85-0602-374ba2eae18d',
    name: 'Saint Martin (French part)',
    native_name: 'Saint Martin (French part)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MF',
    intrastat_code: 'MF',
    calling_code: 590,
    TLD: 'mf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c602582c-95f1-8939-a9d2-6fc195959a99',
    name: 'Saint Pierre and Miquelon',
    native_name: 'Saint Pierre and Miquelon',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PM',
    intrastat_code: 'PM',
    calling_code: 508,
    TLD: 'pm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '53bcfe46-164f-369c-679a-ba00c5880cb0',
    name: 'Saint Vincent and the Grenadines',
    native_name: 'Saint Vincent and the Grenadines',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'VC',
    intrastat_code: 'VC',
    calling_code: 1,
    TLD: 'vc',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '0e9feae1-9daa-0206-c117-630f08fc7ebd',
    name: 'Samoa',
    native_name: 'Samoa',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'WS',
    intrastat_code: 'WS',
    calling_code: 685,
    TLD: 'ws',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c1fa738f-f310-27ef-10fa-cc0e55fc5819',
    name: 'San Marino',
    native_name: 'San Marino',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SM',
    intrastat_code: 'SM',
    calling_code: 378,
    TLD: 'sm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '72b44610-cdfb-0903-1520-184bd020da3a',
    name: 'Sao Tome and Principe',
    native_name: 'Sao Tome and Principe',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ST',
    intrastat_code: 'ST',
    calling_code: 239,
    TLD: 'st',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'abc1f168-d182-8f87-e8b0-8bb90596a343',
    name: 'Saudi Arabia',
    native_name: 'Saudi Arabia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SA',
    intrastat_code: 'SA',
    calling_code: 966,
    TLD: 'sa',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3cb4b4fe-0d55-d933-0598-d6de42720a4f',
    name: 'Senegal',
    native_name: 'Senegal',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SN',
    intrastat_code: 'SN',
    calling_code: 221,
    TLD: 'sn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'edd8646c-2969-35a9-51a7-55c98fcf40d6',
    name: 'Serbia',
    native_name: 'Serbia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'RS',
    intrastat_code: 'RS',
    calling_code: 381,
    TLD: 'rs',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '57106d96-b706-b851-a2e0-15f5b3067abe',
    name: 'Seychelles',
    native_name: 'Seychelles',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SC',
    intrastat_code: 'SC',
    calling_code: 248,
    TLD: 'sc',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '6887c178-aadd-4e75-4b2e-dedd452b49a3',
    name: 'Sierra Leone',
    native_name: 'Sierra Leone',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SL',
    intrastat_code: 'SL',
    calling_code: 232,
    TLD: 'sl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '33be8c75-b2f0-11bd-f7ce-8e807f7719ce',
    name: 'Singapore',
    native_name: 'Singapore',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SG',
    intrastat_code: 'SG',
    calling_code: 65,
    TLD: 'sg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e03bc3bd-957c-5dec-79d1-a5c88d5f7a21',
    name: 'Sint Maarten (Dutch part)',
    native_name: 'Sint Maarten (Dutch part)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SX',
    intrastat_code: 'SX',
    calling_code: 1,
    TLD: 'sx',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b1ca571b-2d9e-c27b-a20a-f2c9d6f159fc',
    name: 'Slovakia',
    native_name: 'Slovakia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SK',
    intrastat_code: 'SK',
    calling_code: 421,
    TLD: 'sk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '87ff2e4f-e4bb-c85c-22be-2af47475bc33',
    name: 'Slovenia',
    native_name: 'Slovenia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SI',
    intrastat_code: 'SI',
    calling_code: 386,
    TLD: 'si',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '6f995880-7b9c-413d-2077-51064c4d8731',
    name: 'Solomon Islands',
    native_name: 'Solomon Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SB',
    intrastat_code: 'SB',
    calling_code: 677,
    TLD: 'sb',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '19413dfa-001c-40a8-d6e7-1633cec9b132',
    name: 'Somalia',
    native_name: 'Somalia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SO',
    intrastat_code: 'SO',
    calling_code: 252,
    TLD: 'so',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e7b1e8d0-8feb-3cfc-3d43-9fc69b40d7f1',
    name: 'South Africa',
    native_name: 'South Africa',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ZA',
    intrastat_code: 'ZA',
    calling_code: 27,
    TLD: 'za',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2d3be939-97f5-2e82-62ea-65f011ee91e6',
    name: 'South Georgia and South Sandwich Islands',
    native_name: 'South Georgia and South Sandwich Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GS',
    intrastat_code: 'GS',
    calling_code: 0,
    TLD: 'gs',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '73b12470-23f4-6163-2e1e-8c567ab2e258',
    name: 'South Sudan',
    native_name: 'South Sudan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SS',
    intrastat_code: 'SS',
    calling_code: 211,
    TLD: 'ss',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b2378174-d915-df1f-c99d-0f3f443e0c83',
    name: 'Spain',
    native_name: 'Spain',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ES',
    intrastat_code: 'ES',
    calling_code: 34,
    TLD: 'es',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e40bd086-9840-966c-a076-31dd8d13a952',
    name: 'Sri Lanka',
    native_name: 'Sri Lanka',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LK',
    intrastat_code: 'LK',
    calling_code: 94,
    TLD: 'lk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7eac5413-9e57-86de-5f36-9b5f6f394d95',
    name: 'Sudan',
    native_name: 'Sudan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SD',
    intrastat_code: 'SD',
    calling_code: 249,
    TLD: 'sd',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5095f160-3ccc-146b-0057-0bb118652a20',
    name: 'Suriname',
    native_name: 'Suriname',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SR',
    intrastat_code: 'SR',
    calling_code: 597,
    TLD: 'sr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '40fb04b1-62b6-0f5c-7b7a-a17827539d9c',
    name: 'Svalbard and Jan Mayen',
    native_name: 'Svalbard and Jan Mayen',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SJ',
    intrastat_code: 'SJ',
    calling_code: 47,
    TLD: 'sj',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f437fa5b-ef5f-c9dc-83e9-9baee9738e42',
    name: 'Swaziland',
    native_name: 'Swaziland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SZ',
    intrastat_code: 'SZ',
    calling_code: 268,
    TLD: 'sz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3a7e56d7-ba22-2fa2-434d-fa91d51b7f07',
    name: 'Sweden',
    native_name: 'Sweden',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SE',
    intrastat_code: 'SE',
    calling_code: 46,
    TLD: 'se',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd4f18ca9-52ab-f307-0e26-0664e4b467d0',
    name: 'Switzerland',
    native_name: 'Switzerland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CH',
    intrastat_code: 'CH',
    calling_code: 41,
    TLD: 'ch',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'dc321129-0772-8341-daec-d9997390921f',
    name: 'Syrian Arab Republic',
    native_name: 'Syrian Arab Republic',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SY',
    intrastat_code: 'SY',
    calling_code: 963,
    TLD: 'sy',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'ab37c410-9f04-f217-b48e-aad599e43e61',
    name: 'Taiwan',
    native_name: 'Taiwan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TW',
    intrastat_code: 'TW',
    calling_code: 886,
    TLD: 'tw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b2045611-eed2-f564-b485-8cc3a968f725',
    name: 'Tajikistan',
    native_name: 'Tajikistan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TJ',
    intrastat_code: 'TJ',
    calling_code: 992,
    TLD: 'tj',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '708b9c32-cb69-f970-6827-315d6eaeeaef',
    name: 'Tanzania, United Republic of',
    native_name: 'Tanzania, United Republic of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TZ',
    intrastat_code: 'TZ',
    calling_code: 255,
    TLD: 'tz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'a0de7b94-86d1-de24-e4b4-13250a437694',
    name: 'Thailand',
    native_name: 'Thailand',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TH',
    intrastat_code: 'TH',
    calling_code: 66,
    TLD: 'th',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '8e0faae7-49c1-ddcb-e3e8-992352e06778',
    name: 'Timor-Leste',
    native_name: 'Timor-Leste',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TL',
    intrastat_code: 'TL',
    calling_code: 670,
    TLD: 'tl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'c7c6c696-a296-43aa-6374-2d10cc803924',
    name: 'Togo',
    native_name: 'Togo',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TG',
    intrastat_code: 'TG',
    calling_code: 228,
    TLD: 'tg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'a7c70077-2d74-e2e1-b356-599d1f6b0b0d',
    name: 'Tokelau',
    native_name: 'Tokelau',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TK',
    intrastat_code: 'TK',
    calling_code: 690,
    TLD: 'tk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd45d7925-f8a4-e2a8-3e94-3168099a16f0',
    name: 'Tonga',
    native_name: 'Tonga',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TO',
    intrastat_code: 'TO',
    calling_code: 676,
    TLD: 'to',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'e3e4685a-2e85-79f9-2d47-e440e19b1250',
    name: 'Trinidad and Tobago',
    native_name: 'Trinidad and Tobago',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TT',
    intrastat_code: 'TT',
    calling_code: 1,
    TLD: 'tt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '846a1cd9-ef43-771a-22a4-4a4d15f15e27',
    name: 'Tunisia',
    native_name: 'Tunisia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TN',
    intrastat_code: 'TN',
    calling_code: 216,
    TLD: 'tn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '0bd3f69a-8b74-c33b-38bc-ecfed3a7ef75',
    name: 'Turkey',
    native_name: 'Turkey',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TR',
    intrastat_code: 'TR',
    calling_code: 90,
    TLD: 'tr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '3b3c72f5-a065-5cbb-b7b9-966d3f0072cd',
    name: 'Turkmenistan',
    native_name: 'Turkmenistan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TM',
    intrastat_code: 'TM',
    calling_code: 993,
    TLD: 'tm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '8205b8d0-86d6-454f-46eb-9509798a6625',
    name: 'Turks and Caicos Islands',
    native_name: 'Turks and Caicos Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TC',
    intrastat_code: 'TC',
    calling_code: 1,
    TLD: 'tc',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5f98db9c-b4a3-55c4-fc5d-2cf6f9000b14',
    name: 'Tuvalu',
    native_name: 'Tuvalu',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'TV',
    intrastat_code: 'TV',
    calling_code: 688,
    TLD: 'tv',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'd03e036a-6dd8-86c2-c769-b05002766d18',
    name: 'Uganda',
    native_name: 'Uganda',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'UG',
    intrastat_code: 'UG',
    calling_code: 256,
    TLD: 'ug',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'fd5b9507-012f-42b4-113f-fd3076802464',
    name: 'Ukraine',
    native_name: 'Ukraine',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'UA',
    intrastat_code: 'UA',
    calling_code: 380,
    TLD: 'ua',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'dd4cef1c-11a6-eb60-fcd1-5037d410e00f',
    name: 'United Arab Emirates',
    native_name: 'United Arab Emirates',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AE',
    intrastat_code: 'AE',
    calling_code: 971,
    TLD: 'ae',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '2bece331-1974-ad06-6b27-1a0f0507af98',
    name: 'United Kingdom (Northern Ireland)',
    native_name: 'United Kingdom (Northern Ireland)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GB',
    intrastat_code: 'GB',
    calling_code: 44,
    TLD: 'gb',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '199dceee-6927-3407-a699-37d3db52a6a7',
    name: 'United States of America',
    native_name: 'United States of America',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'US',
    intrastat_code: 'US',
    calling_code: 1,
    TLD: 'us',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5610a1fd-c319-0576-9122-9c7060eb02ca',
    name: 'United States Minor Outlying Islands',
    native_name: 'United States Minor Outlying Islands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'UM',
    intrastat_code: 'UM',
    calling_code: 0,
    TLD: 'um',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7a56b665-d240-d318-07b6-c2fea60dc363',
    name: 'Uruguay',
    native_name: 'Uruguay',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'UY',
    intrastat_code: 'UY',
    calling_code: 598,
    TLD: 'uy',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '7746c8f3-a1f2-5674-faa3-4937ebd9238a',
    name: 'Uzbekistan',
    native_name: 'Uzbekistan',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'UZ',
    intrastat_code: 'UZ',
    calling_code: 998,
    TLD: 'uz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '66d3e079-e327-7bf8-de58-323245665c1b',
    name: 'Vanuatu',
    native_name: 'Vanuatu',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'VU',
    intrastat_code: 'VU',
    calling_code: 678,
    TLD: 'vu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '5d26597e-59ee-9f5e-3f3b-114c4e3438ac',
    name: 'Venezuela, Bolivarian Republic of',
    native_name: 'Venezuela, Bolivarian Republic of',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'VE',
    intrastat_code: 'VE',
    calling_code: 58,
    TLD: 've',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'f02b46a1-0100-1a0c-033c-35ae2c1f68bc',
    name: 'Viet Nam',
    native_name: 'Viet Nam',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'VN',
    intrastat_code: 'VN',
    calling_code: 84,
    TLD: 'vn',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'debbfd7f-842d-ad90-0aaa-6f08388407b0',
    name: 'Virgin Islands (British)',
    native_name: 'Virgin Islands (British)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'VG',
    intrastat_code: 'VG',
    calling_code: 1,
    TLD: 'vg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '4a8789cb-b623-f1da-5c00-7b967e478dc5',
    name: 'Virgin Islands (U.S.)',
    native_name: 'Virgin Islands (U.S.)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'VI',
    intrastat_code: 'VI',
    calling_code: 1,
    TLD: 'vi',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '064fa0eb-ba98-7254-bc66-5fbf69d9779d',
    name: 'Wallis and Futuna',
    native_name: 'Wallis and Futuna',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'WF',
    intrastat_code: 'WF',
    calling_code: 681,
    TLD: 'wf',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: 'b6171498-8c79-47a5-4753-9a72dee1ecbe',
    name: 'Western Sahara',
    native_name: 'Western Sahara',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'EH',
    intrastat_code: 'EH',
    calling_code: 212,
    TLD: 'eh',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '1fb4b949-a6af-51a3-7e4e-c929717ee41f',
    name: 'Yemen',
    native_name: 'Yemen',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'YE',
    intrastat_code: 'YE',
    calling_code: 967,
    TLD: 'ye',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '95ae3c9c-11a2-22ad-4dff-dbf9440b497e',
    name: 'Zambia',
    native_name: 'Zambia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ZM',
    intrastat_code: 'ZM',
    calling_code: 260,
    TLD: 'zm',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
  {
    id: '388742de-4f79-c917-cbfa-41cdacd8b3b5',
    name: 'Zimbabwe',
    native_name: 'Zimbabwe',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ZW',
    intrastat_code: 'ZW',
    calling_code: 263,
    TLD: 'zw',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: '',
    vatRates: [],
  },
];
