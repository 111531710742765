import { DBWarehouseBins, DBWarehouseShelfs } from '@prisma/client';
import api from '@tyrio/api-factory';
import { GetAllLocationsConvertedResponse, MainBranchStock } from '@tyrio/dto';
import { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';

export const useGetMainBranchStock = (ean: string, branchId: string) => {
  const { data, refetch, isLoading } = useQuery(
    ['main_branch_stock', { ean: ean ?? '', warehouseId: branchId ?? '' }],
    () =>
      api.fetch<{
        request: {
          ean: string;
          warehouseId: string;
        };
        requestParams: {
          ean: string;
          warehouseId: string;
        };
        requestBody: never;
        response: MainBranchStock;
      }>('main_branch_stock', {
        ean: ean ?? '',
        warehouseId: branchId ?? '',
      }),
    { enabled: !!ean && !!branchId }
  );
  return { stockData: data, refetchBranchesStock: refetch, isLoading };
};

export const useGetAllWarehouseLocations = (
  setShelves: Dispatch<SetStateAction<Record<string, DBWarehouseShelfs>>>,
  setBins: Dispatch<SetStateAction<Record<string, DBWarehouseBins>>>,
  selectedWarehouse: number
) => {
  const { data, isSuccess } = useQuery(
    ['get_selected_Warehouse_locatons', selectedWarehouse],
    () =>
      api.fetch<{
        request: { warehouseId: number };
        requestParams: { warehouseId: number };
        requestBody: never;
        response: GetAllLocationsConvertedResponse;
      }>('get_selected_Warehouse_locatons', { warehouseId: selectedWarehouse }),
    {
      onSuccess(response) {
        setShelves(response.shelfs);
        setBins(response.bins);
      },
    }
  );
  return { stockData: data, isSuccess };
};
