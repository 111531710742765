import React from 'react';
import styled from '@emotion/styled';

interface Props {
  supplierName: string;
}

export const RenderFooterSupplierTitle = ({ supplierName }: Props) => {
  return (
    <Wrapper>
      <SupplierName>{supplierName}</SupplierName>
    </Wrapper>
  );
};

const SupplierName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #212b36;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
