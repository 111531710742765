import { tyrioIcons } from '@tyrio/ui-library';
import { wmsIcons } from '@tyrio/wms-ui-library';

export const MenuList = [
  {
    icon: wmsIcons.search,
    text: 'SEARCH',
    disabled: false,
  },
  {
    icon: wmsIcons.details,
    text: 'DETAILS',
    disabled: false,
  },
  {
    icon: wmsIcons.branches,
    text: 'BRANCHES',
    disabled: false,
  },
  {
    icon: tyrioIcons.stepMenuSuppliers,
    text: 'SUPPLIERS',
    disabled: false,
  },
  {
    icon: tyrioIcons.services,
    text: 'SERVICES',
    disabled: false,
  },
  {
    icon: tyrioIcons.tyreHotel,
    text: 'TYRE HOTEL',
    disabled: true,
  },
  {
    icon: wmsIcons.cart,
    text: 'CART',
    disabled: false,
  },
];
