import { DBDispatchOrdersResponse, IWorkOrderLineItem } from '@tyrio/dto';
import { useGetCollection } from '../queries/get-collection';
import { useGetDispatch } from '../queries/get-dispatch';
import { useGetReadyShipping } from '../queries/get-shipping';
import { LineItem } from './LineItem';
import { CircularProgress, Divider, Typography } from '@mui/material';
import _ from 'lodash';
import { useGetFitting } from '../queries/get-fittings';
import { NextDayDiv, LoaderWrapper } from '../styles/styles';
import { ILineItem, useWorkOrderCtx } from '../../../context/WorkOrderContext';
import { EmptyList } from './EmptyList';

export const prepOrders = (
  data: DBDispatchOrdersResponse[],
  type: 'DISPATCH' | 'COLLECTION'
) => {
  return data.reduce<{
    openOrders: ILineItem[];
    readyOrders: ILineItem[];
  }>(
    (acc, item) => {
      if (item.ready > 0) {
        const orderDetails = item.readyOrders.map((r) => {
          return {
            lineItems: r.lineItems as unknown as IWorkOrderLineItem[],
            deliveryMethod: '',
            orderNumber: r.reference ?? '',
            reference: r.customerOrderNumber ?? '',
            deliveryType: type,
            branchName: r.branchName ?? item.destination ?? '',
            customer: item.destination ?? '',
            orderId: r.orderId,
          };
        });

        acc.readyOrders.push({
          title: item.destination,
          subtitle: item.destinationAddress,
          orderNr: '',
          quantity: item.ready,
          orderDetails,
        });
      }

      if (item.open > 0) {
        const orderDetails = item.openOrders.map((r) => {
          return {
            lineItems: r.lineItems as unknown as IWorkOrderLineItem[],
            deliveryMethod: '',
            orderNumber: r.reference ?? '',
            reference: r.customerOrderNumber ?? '',
            deliveryType: type,
            branchName: r.branchName ?? item.destination ?? '',
            customer: item.destination ?? '',
            orderId: r.orderId,
          };
        });

        acc.openOrders.push({
          title: item.destination,
          subtitle: item.destinationAddress,
          orderNr: '',
          quantity: item.open,
          orderDetails,
        });
      }
      return acc;
    },
    { openOrders: [], readyOrders: [] }
  );
};

const CustomText = ({ text }: { text: string }) => {
  return (
    <Typography
      fontSize={14}
      color="#919EAB"
      component={'div'}
      style={{ marginTop: '20px', marginBottom: '5px' }}
    >
      {text}
    </Typography>
  );
};

export const DataMapping = (title: string) => {
  const {
    workOrders,
    setWorkOrders,
    setReadyWorkOrders,
    readyWorkOrder,
    filteredWorkOrders,
  } = useWorkOrderCtx();

  const isDispatch = title === 'DISPATCH';
  const isShipping = title === 'SHIPPING';
  const isCollection = title === 'COLLECTION';
  const isFitting = title === 'FITTING';

  const dispatchData = useGetDispatch(
    isDispatch,
    setWorkOrders,
    setReadyWorkOrders
  );

  const collectionData = useGetCollection(
    isCollection,
    setWorkOrders,
    setReadyWorkOrders
  );

  const shippingData = useGetReadyShipping(
    isShipping,
    setWorkOrders,
    setReadyWorkOrders
  );

  const fittingData = useGetFitting(
    isFitting,
    setWorkOrders,
    setReadyWorkOrders
  );

  const data =
    filteredWorkOrders && !_.isEmpty(filteredWorkOrders)
      ? filteredWorkOrders
      : workOrders;

  if (isDispatch) {
    if (dispatchData.showLoader) return <Loader />;
    if (dispatchData.status !== 'success') return null;

    const openOrders = data?.['DISPATCH'] ?? [];
    const readyOrders = readyWorkOrder?.['DISPATCH'] ?? [];

    return mapItems(
      openOrders ?? [],
      readyOrders ?? [],
      'DISPATCH',
      <CustomText text="Ready to dispatch orders" />
    );
  }

  if (isShipping) {
    if (shippingData.showLoader) return <Loader />;
    const openOrders = data?.['SHIPPING'] ?? [];
    const readyOrders = readyWorkOrder?.['SHIPPING'] ?? [];

    return mapItems(
      openOrders ?? [],
      readyOrders ?? [],
      'SHIPPING',
      <CustomText text="Ready to ship orders" />
    );
  }

  if (isCollection) {
    if (collectionData.showLoader) return <Loader />;
    if (collectionData.status !== 'success') return null;

    const openOrders = data?.['COLLECTION'] ?? [];
    const readyOrders = readyWorkOrder?.['COLLECTION'] ?? [];

    return mapItems(
      openOrders,
      readyOrders,
      'COLLECTION',
      <CustomText text="Ready to collect orders" />
    );
  }

  if (isFitting && fittingData) {
    if (fittingData.showLoader) return <Loader />;
    const openOrders = data?.['FITTING'] ?? [];
    const readyOrders = readyWorkOrder?.['FITTING'] ?? [];

    return mapItems(
      openOrders,
      readyOrders,
      'FITTING',
      <NextDayDiv>NEXT DAY</NextDayDiv>,
      false
    );
  }
  return null;
};

const mapItems = (
  openOrdersData: ILineItem[],
  readyOrdersData: ILineItem[],
  type: 'SHIPPING' | 'DISPATCH' | 'FITTING' | 'COLLECTION',
  customerComponent?: JSX.Element,
  hasDivider?: boolean
) => {
  const showDivider = hasDivider ?? true;

  const openOrders =
    !openOrdersData || openOrdersData.length === 0 ? (
      <EmptyList type={type} />
    ) : (
      openOrdersData.map((item) => (
        <LineItem
          title={item.title}
          subtitle={item.subtitle}
          orderNr={item.orderNr ?? ''}
          quantity={item.quantity}
          orderDetails={item?.orderDetails}
        />
      ))
    );

  const readyOrders =
    !readyOrdersData || readyOrdersData.length === 0 ? (
      <EmptyList type={type} />
    ) : (
      readyOrdersData.map((item) => (
        <LineItem
          title={item.title}
          subtitle={item.subtitle}
          orderNr={item.orderNr ?? ''}
          quantity={item.quantity}
          disabled={true}
          orderDetails={item?.orderDetails}
          showReady={type !== 'FITTING'}
        />
      ))
    );

  return (
    <>
      {openOrders}
      {customerComponent && customerComponent}
      {showDivider && <Divider sx={{ borderWidth: '2px' }} />}
      {readyOrders}
    </>
  );
};

const Loader = () => {
  return (
    <LoaderWrapper>
      <CircularProgress />
    </LoaderWrapper>
  );
};
