import api from '@tyrio/api-factory';
import { DBProductApi, DBProductModelApi } from '@tyrio/dto';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../../components/PageNotFound/PageNotFound';
import ProductItemPage from './ProductItemPage';
import { v4 } from 'uuid';
import { useAuth } from '../../../context/AuthContext';

export const ProductWrapper = () => {
  const params = useParams();

  const { user } = useAuth();

  const pathId = _.get(params, 'id');

  const pathModelId = _.get(params, 'productModelUid');

  const productId = pathId !== 'new' ? pathId : undefined;
  const productModelId = pathModelId !== 'new' ? pathModelId : undefined;

  const [ready, setReady] = useState(false);

  const { data: productData, isFetching: isFetchingProduct } = useQuery(
    ['get_product_by_id', productId],
    () =>
      api.fetch<DBProductApi['getOne']>(`get_product_by_id`, {
        id: productId,
      }),
    {
      enabled: !!productId,
      cacheTime: 0,
    }
  );

  const { data: modelData, isFetching: isFetchingModel } = useQuery(
    ['get_product_model_by_id', productModelId],
    () =>
      api.fetch<DBProductModelApi['getOne']>(`get_product_model_by_id`, {
        id: productModelId,
      }),
    {
      enabled: !!productModelId,
    }
  );

  useEffect(() => {
    if (!ready) {
      let tempReady = true;

      if (!!productId && !productData) tempReady = false;

      if (!!productModelId && !modelData) tempReady = false;

      setReady(tempReady);
    }
  }, [
    isFetchingModel,
    isFetchingProduct,
    modelData,
    productData,
    productId,
    productModelId,
    ready,
  ]);

  if (!ready) return <div>LOADING...</div>;

  if (!user?.adminId && pathId === 'new') return <PageNotFound />;

  const generatedOrOldProductId = productId ?? v4();

  return (
    <ProductItemPage
      productId={generatedOrOldProductId}
      product={productData}
      model={modelData}
      permissions={
        !user?.adminId
          ? { edit: false, create: false, delete: false, view: true }
          : { edit: true, create: true, delete: true, view: true }
      }
    />
  );
};
