import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';

interface HeaderProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  altText: string;
  text: string;
  viewBox?: string;
}

export const Header = ({
  icon,
  altText,
  text,
  viewBox = '0 0 24 24',
}: HeaderProps) => {
  return (
    <DeliveryTypeWrapper>
      <WmsIcon
        icon={icon}
        alt={altText}
        sx={{ color: tyrioUI.colors.black.B40, width: '30px', height: '30px' }}
        viewBox={viewBox}
      />

      <Text>{text}</Text>
    </DeliveryTypeWrapper>
  );
};

const DeliveryTypeWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  color: #919eab;
  align-items: center;
`;

const Text = styled.div`
  font-family: Barlow;
  font-size: 16px;
  font-weight: 400;
`;
