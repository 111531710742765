import {
  DBDeliveryTypeEnum,
  DBShippingDays,
  DBSupplierClientShippingSettings,
} from '@prisma/client';

export const supplierClientShippingSettings: DBSupplierClientShippingSettings[] =
  [
    {
      id: '78bae301-9048-4285-b949-c639c3881681',
      supplierClientSettingsId: '33b2ab3a-f07f-426d-a637-da5647ddfc5e',
      isActive: false,
      minOrderQty: 1,
      minQtySurchange: 2.0,
      defaultCostPerPackage: 3.0,
      dropShipping: false,
      cod: false,
      codSurchange: null,
      deliveryToIsland: false,
      islandSurchange: null,
      daysToDeliver: 1,
      maxDaysToDeliver: 2,
      nextShippingDays: [
        DBShippingDays.MON,
        DBShippingDays.TUE,
        DBShippingDays.SAT,
      ],
      locationClosedFrom: null,
      locationClosedUntil: null,
      hideStockNonWorkingDays: false,
      showBeforeReopened: null,
      defaultShippingOption: DBDeliveryTypeEnum.TRUCK,
    },
    {
      id: '45a200f9-7d1e-4380-97ac-3f34b9260c18',
      supplierClientSettingsId: '07c91a78-e661-47df-800e-f197ea7b7022',
      isActive: false,
      minOrderQty: 1,
      minQtySurchange: 2.0,
      defaultCostPerPackage: 3.0,
      dropShipping: false,
      cod: false,
      codSurchange: null,
      deliveryToIsland: false,
      islandSurchange: null,
      daysToDeliver: 1,
      maxDaysToDeliver: 2,
      nextShippingDays: [
        DBShippingDays.MON,
        DBShippingDays.TUE,
        DBShippingDays.SAT,
      ],
      locationClosedFrom: null,
      locationClosedUntil: null,
      hideStockNonWorkingDays: false,
      showBeforeReopened: null,
      defaultShippingOption: DBDeliveryTypeEnum.TRUCK,
    },
  ];
