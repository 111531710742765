import api from '@tyrio/api-factory';
import { DBPartnerApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetPartnerDetails = (identificator: string) => {
  return useQuery(
    ['get_sudreg_response', identificator],
    () =>
      api.fetch<DBPartnerApi['sudreg']>('get_sudreg_response', {
        identificator,
      }),
    { enabled: !!identificator && identificator.length === 11 }
  );
};
