import { tyrioIcons } from '@tyrio/ui-library';

export const cardArray = [
  {
    key: 'SHIPPING',
    title: 'SHIPPING',
    image: tyrioIcons.shipping,
  },
  {
    key: 'FITTING',
    title: 'FITTING',
    image: tyrioIcons.fitting,
  },
  {
    key: 'DISPATCH',
    title: 'DISPATCH',
    image: tyrioIcons.dispatch,
  },
  {
    key: 'COLLECTION',
    title: 'COLLECTION',
    image: tyrioIcons.collection,
  },
];
