/* eslint-disable @typescript-eslint/no-explicit-any */
import { OutboundOrder } from '../../../../../features/stock-list/components/Outbound/OutboundOrder';
import React from 'react';
import { Branches } from '../../../../../features/stock-list/components/Branches/Branches';
import { Cart } from '../../../../../features/stock-list/components/Cart/Cart';
import { PasteList } from '../../../../../features/stock-list/components/PasteList/PasteList';
import ProductDetails from '../../../../../features/stock-list/components/ProductDetails/ProductDetails';
import { Search } from '../../../../../features/stock-list/components/Search/Search';

const FieldComponent: any = {
  INBOUND: Branches,
  DETAILS: ProductDetails,
  SEARCH: Search,
  CART: Cart,
  PASTE_LIST: PasteList,
  OUTBOUND: OutboundOrder,
};

export const Steps = (step: string) => {
  if (Object.keys(FieldComponent).find((a) => a === step) === undefined) {
    console.log('Error - Step passed in is not expected!');
    return;
  }

  if (step in Object.keys(FieldComponent)) {
    console.log('Error - Step passed in is not expected!');
  }
  return React.createElement(FieldComponent[step]);
};
