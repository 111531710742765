import { FieldValues, useForm } from 'react-hook-form';
import {
  LoaderWrapper,
  PageTemplateContainer,
} from '../../components/PageTemplate/PageTemplate';
import { renderFields } from '../services/components/RenderFormFields';
import { TitleWrapper } from '../services/components/TitleWrapper';
import SourceContainer from '@tyrio/forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { CancelModal, tyrioIcons } from '@tyrio/ui-library';
import { ShippingCourierFormShape } from './constants/shipping-courier.form';
import { initialValues } from './constants/initial-values';
import { ShippingCourierValidation } from './constants/validation';
import { useQuery, useQueryClient } from 'react-query';
import api from '@tyrio/api-factory';
import { ClientShippingCourierApi } from '@tyrio/dto';
import styled from '@emotion/styled';
import { CircularProgress, Typography } from '@mui/material';
import { useUpdateShippingCouriers } from './query/update';
import { useUpdateStatus } from './query/update-status';

export const ShippingCourierForm = () => {
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { form } = ShippingCourierFormShape();
  const queryClient = useQueryClient();

  const { data: courierData, isFetching } = useQuery(
    ['get_one_client_shipping_courier', id],
    () =>
      api.fetch<ClientShippingCourierApi['getOne']>(
        'get_one_client_shipping_courier',
        { id: id }
      ),
    { enabled: !!id }
  );
  const defaultValue = useCallback(() => {
    return initialValues(courierData);
  }, [courierData]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: defaultValue() as FieldValues,
    resolver: zodResolver(ShippingCourierValidation),
  });

  const doCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else history.push('/dashboard/company-settings/shipping-couriers');
  };

  useEffect(() => {
    reset(defaultValue());
  }, [defaultValue, reset]);

  const imgSrc =
    courierData?.shippingCourier.abbreviation === 'GLS Croatia' ||
    courierData?.shippingCourier.abbreviation === 'GLS Croatia (Dropship)'
      ? tyrioIcons.gls
      : tyrioIcons.dpd;

  const { updateShippingCouriers } = useUpdateShippingCouriers(queryClient);
  const { updateStatus } = useUpdateStatus(queryClient);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (payload: any) => {
    updateShippingCouriers({
      id: id,
      clientNumber: payload.clientNumber,
      username: payload.username,
      password: payload.password,
    });
  };

  const handleChangeStatus = () => {
    updateStatus({ id: id, active: !courierData?.active });
  };
  return (
    <PageTemplateContainer style={{ height: '95vh' }}>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            history.push('/dashboard/company-settings/shipping-couriers');
            setIsCancelModalVisible(false);
          }}
        />
      )}

      {isFetching ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <>
          <TitleWrapper
            title={courierData?.shippingCourier.abbreviation}
            onChangeStatus={handleChangeStatus}
            active={courierData?.active ?? false}
          />
          <CompanyLogoImage
            src={imgSrc}
            alt={courierData?.shippingCourier.abbreviation}
          />
          <TypographyStyled>
            {courierData?.shippingCourier.name}
          </TypographyStyled>
          <Divider />
          <TypographyStyled style={{ fontWeight: 600 }}>
            Enter your account details
          </TypographyStyled>
          <div
            style={{ position: 'relative', height: '100%', marginTop: '10px' }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              key={id}
              style={{ padding: '16px 24px' }}
            >
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                form.map((fields: any) => {
                  if (
                    courierData?.shippingCourier.abbreviation ===
                      'DPD Croatia' ||
                    courierData?.shippingCourier.abbreviation ===
                      'DPD Croatia (Dropship)'
                  ) {
                    fields.inputs.splice(0, 1);
                  }
                  return (
                    <div style={{ paddingBottom: '25px' }} key={fields.key}>
                      {renderFields(
                        fields,
                        register,
                        control,
                        errors,
                        true,
                        'couriers'
                      )}
                    </div>
                  );
                })
              }

              <Divider />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TypographyStyled>
                  Don’t have a {courierData?.shippingCourier.abbreviation}{' '}
                  account? -{' '}
                </TypographyStyled>
                <StyledLink
                  href={courierData?.shippingCourier?.shippingWebsite}
                  target="blank"
                >
                  Click here
                </StyledLink>
              </div>
              <SourceContainer
                data={{
                  data_source: '',
                  created: '',
                  last_edited: '',
                }}
                onCancel={doCancel}
                customStyle={{
                  background: 'white',
                  zIndex: 1,
                  bottom: 0,
                  paddingBottom: '16px',
                }}
                disabledDelete={true}
                onSubmit={handleSubmit(onSubmit)}
              />
            </form>
          </div>{' '}
        </>
      )}
    </PageTemplateContainer>
  );
};

const CompanyLogoImage = styled.img`
  width: 120px;
  margin-bottom: 8px;
`;

const TypographyStyled = styled(Typography)`
  color: #212b36;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.3px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #dfe3e8;
  margin-top: 12px;
  margin-bottom: 40px;
`;

const StyledLink = styled.a`
  color: #1976d2;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.3px;
`;
