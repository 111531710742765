import styled from '@emotion/styled';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { DBStockInItemStatus } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  ActivePurchaseAndTransferOrdersList,
  DBBranchesApi,
  DBStockInListApi,
  DBStockInOrderItemCountByStatus,
  InboundInvoiceApi,
} from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import _, { isEmpty } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useInboundInvoiceCtx } from '../../../context/InboundInvoiceContext';
import { StockInContext } from '../../../context/StockInContext';
import TransferDatePicker from '../../transfer-orders/filter/TransferDatePicker';
import StockInSearchTypeInput from './StockInSearchTypeInput';
import TabsFilter from './TabsFilter';

interface StockInOrderTableFiltersProps {
  isSidebarOpen: boolean;
  countOrders: DBStockInOrderItemCountByStatus[];
}

const StockInTableFilters = ({
  countOrders,
  isSidebarOpen,
}: StockInOrderTableFiltersProps) => {
  const history = useHistory();
  const [branches, setBranches] = useState<TyrioSelectInputOption[]>([]);
  const [suppliersData, setSuppliersData] = useState<TyrioSelectInputOption[]>(
    []
  );

  const {
    checked,
    checkedSupplier,
    filterValues: filters,
    setFilterValue: setFilters,
  } = useContext(StockInContext);

  const { setInput, input } = useInboundInvoiceCtx();

  const listItems = [
    { value: 'docId', label: 'Stock IN number' },
    { value: 'ean', label: 'EAN' },
    { value: 'ipc', label: 'IPC' },
    { value: 'purchaseOrder', label: 'Purchase order' },
    { value: 'reference', label: 'Reference' },
    { value: 'sku', label: 'SKU' },
  ];

  const isSmallWindowWidth = window.innerWidth < 2000;

  useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const dropdownItems = [{ value: 'all', label: 'All' }];
        data.data.forEach((item) =>
          dropdownItems.push({
            label: item.branchName,
            value: item.id.toString(),
          })
        );

        setBranches(dropdownItems);
      },
    }
  );

  useQuery(
    ['get_all_stock_in_suppliers'],
    async () => {
      return await api.fetch<DBStockInListApi['getSuppliers']>(
        'get_all_stock_in_suppliers',
        { sortAlphabetically: true }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.map(
          (item: ActivePurchaseAndTransferOrdersList) => ({
            label: item.source,
            value: item.id,
          })
        );

        dropdownItems.push({
          value: 'all',
          label: 'All',
        });

        setSuppliersData(dropdownItems);
      },
    }
  );

  const { data, isLoading, isFetching } = useQuery(
    [
      'get_prepared_invoices',
      Object.keys(checked).join(',').replace(/^"|"$/g, ''),
    ],
    async () => {
      return await api.fetch<InboundInvoiceApi['getPrepared']>(
        'get_prepared_invoices',
        {
          stockInIds: Object.keys(checked).join(',')
            ? Object.keys(checked).join(',').replace(/^"|"$/g, '')
            : '',
          type: 'stockIn',
        }
      );
    },
    {
      enabled: !!(
        !isEmpty(checked) && Object.keys(checked).join(',').length > 0
      ),
      onSuccess(data: InboundInvoiceApi['getPrepared']['response']) {
        console.log(data, '===> PREPARED INBOUND INVOICE DATA');
        setInput({
          ids: Object.keys(checked).join(','),
          checkedStockIns: checked,
          checkedSupplier,
          preparedInvoices: data ?? null,
          filteredSupplier: filters['supplierId']?.toString() ?? '',
          type: 'stockIn',
        });
      },
    }
  );

  const prepareInvoices = useCallback(() => {
    console.log('*** is loading data ***', isLoading);
    console.log('*** is fetching data ***', isFetching);
    if (!isLoading && !isFetching) {
      if (data && !data?.partner)
        ToastHelper.showToast(
          'Inbound invoice',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          `We can't find partner for selected supplier: ${checkedSupplier.name}`
        );
      else if (data) history.push('inbound-invoice');
    }
  }, [checkedSupplier.name, data, history, isFetching, isLoading]);

  const getBranchesAutocompleteDefaultValue = () => {
    if (filters['branchId']) {
      const selectedBranch = branches.find(
        (el) => el.value === filters['branchId']
      );
      return { value: filters['branchId'], label: selectedBranch?.label || '' };
    }
    return { value: 'all', label: 'All' };
  };

  const getSupplierAutocompleteDefaultValue = () => {
    if (input.filteredSupplier !== 'all') {
      const selectedSupplier = suppliersData.find(
        (el) => el.value === input.filteredSupplier
      );
      return {
        value: input.filteredSupplier,
        label: selectedSupplier?.label || '',
      };
    } else if (filters['supplierId']) {
      const selectedSupplier = suppliersData.find(
        (el) => el.value === filters['supplierId']
      );
      return {
        value: filters['supplierId'],
        label: selectedSupplier?.label || '',
      };
    }
    return { value: 'all', label: 'All' };
  };

  return (
    <Grid>
      <Grid
        container
        spacing={1.5}
        paddingLeft="16px"
        paddingTop="21px"
        paddingRight="16px"
      >
        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 6 : 4}>
          <StockInSearchTypeInput
            filters={filters}
            setFilters={setFilters}
            listItems={listItems}
          />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 3 : 1.5}>
          <Autocomplete
            disablePortal
            id="branchId"
            defaultValue={
              getBranchesAutocompleteDefaultValue() as unknown as TyrioSelectInputOption
            }
            getOptionLabel={(option) => {
              if (typeof option === 'string')
                return branches.find((el) => el.value === option)?.label || '';
              else
                return (
                  branches.find((el) => el.value === option.value)?.label || ''
                );
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_event, newValue) => {
              setFilters({
                ...filters,
                branchId: newValue ? newValue.value.toString() : 'all',
              });
            }}
            options={branches}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Branch" />}
          />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 3 : 1.5}>
          <Autocomplete
            disablePortal
            id="supplier"
            defaultValue={
              getSupplierAutocompleteDefaultValue() as unknown as TyrioSelectInputOption
            }
            getOptionLabel={(option) => {
              if (typeof option === 'string')
                return (
                  suppliersData.find((el) => el.value === option)?.label || ''
                );
              else
                return (
                  suppliersData.find((el) => el.value === option.value)
                    ?.label || ''
                );
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_event, newValue) => {
              setFilters({
                ...filters,
                supplierId: newValue ? newValue.value.toString() : 'all',
              });
              setInput((prevState) => ({
                ...prevState,
                filteredSupplier: newValue?.value.toString() ?? 'all',
              }));
            }}
            options={suppliersData}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Supplier" />}
          />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 8 : 2.5}>
          <TransferDatePicker
            isSidebarOpen={isSidebarOpen}
            filters={filters}
            setFilters={setFilters}
          />
        </Grid>

        <Grid
          item
          xs={isSidebarOpen && isSmallWindowWidth ? 4 : 2.5}
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <InboundButton
            disabled={_.size(checked) < 1 || isLoading}
            onClick={prepareInvoices}
          >
            {isLoading || isFetching ? (
              <CircularProgress
                style={{ height: '20px', width: '20px' }}
                sx={{ color: 'white' }}
              />
            ) : (
              'INBOUND INVOICE'
            )}
          </InboundButton>
        </Grid>
      </Grid>

      <TabFilterContainer container>
        <TabFilterWrapper item>
          <TabsFilter
            filters={filters}
            setFilters={setFilters}
            countOrders={countOrders}
          />
          <FormControlLabel
            sx={{ marginLeft: '50px' }}
            label="Cancelled"
            control={
              <Switch
                color="info"
                checked={
                  filters['orderStatus'] === DBStockInItemStatus.CANCELLED
                }
                onChange={() => {
                  const value =
                    filters['orderStatus'] !== DBStockInItemStatus.CANCELLED
                      ? (DBStockInItemStatus.CANCELLED as keyof typeof DBStockInItemStatus)
                      : 'all';
                  setFilters({
                    ...filters,
                    orderStatus: value,
                  });
                }}
              />
            }
          />
        </TabFilterWrapper>
      </TabFilterContainer>
    </Grid>
  );
};

const TabFilterContainer = styled(Grid)`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabFilterWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: red;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const InboundButton = styled(Button)`
  width: 160px;
  height: 55px;
  background: ${(props) =>
    !props.disabled ? '#1976D2' : 'rgb(0, 0, 0, 0.12)'};
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  font-family: Roboto;
  border: ${(props) => (!props.disabled ? '1px solid #1976D2' : '')};
  color: ${(props) => (!props.disabled ? 'white' : '')};

  &:hover {
    background: ${(props) =>
      !props.disabled ? '#1976D2' : 'rgb(0, 0, 0, 0.12)'};
    border: ${(props) => (!props.disabled ? '1px solid #1976D2' : '')};
    color: ${(props) => (!props.disabled ? 'white' : '')};
  }
`;

export default StockInTableFilters;
