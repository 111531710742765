import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { TYRIOCUSTOM_CATALOG_RULESET } from './tyriocustom/catalog';
import { TRule } from '../typings';

export const TYRIOCUSTOM_SUPPLIER: SupplierShape = {
  weight: 1000,
  override: true,
  ruleset: TYRIOCUSTOM_CATALOG_RULESET,
  pricelistRuleset: [] as TRule[],
  stockRuleset: [] as TRule[],
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    width: {
      ',': '.',
      '"': '',
    },
    height: {
      ',': '.',
      '"': '',
    },
    rimDiameter: {
      ',': '.',
    },
    width2: {
      ',': '.',
    },
    rimDiameter2: {
      ',': '.',
    },
    cb: {
      ',': '.',
    },
    et: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      dirtyFields: '{}',
      origin: '',
    },
    model: {
      dirtyFields: '{}',
      usage: null,
      dataSource: 'TYRIO CUSTOM PRICAT',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file;

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    // console.log(`** Attempting to import ${json.length} lines.`);
    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
