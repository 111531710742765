import InboundInvoiceProvider from '../../context/InboundInvoiceContext';
import PurchaseOrdersProvider from '../../context/PurchaseOrdersContext';
import PurchaseOrderOverview from '../../features/purchase-orders/PurchaseOrderOverview';

interface PurchaseOrdersProps {
  sidebarOpen: boolean;
}

const PurchaseOrders = ({ sidebarOpen }: PurchaseOrdersProps) => {
  return (
    <InboundInvoiceProvider>
      <PurchaseOrdersProvider>
        <PurchaseOrderOverview sidebarOpen={sidebarOpen} />
      </PurchaseOrdersProvider>
    </InboundInvoiceProvider>
  );
};

export default PurchaseOrders;
