import styled from '@emotion/styled';
import { Typography } from '@mui/material';

interface StockListHeaderProps {
  branchName: string;
  onlyAvailable: boolean;
  text1?: 'On stock' | 'Price';
  fontWeight?: string;
}

export const StockListHeader = ({
  branchName,
  onlyAvailable,
  text1 = 'On stock',
  fontWeight = '500',
}: StockListHeaderProps) => {
  return (
    <TitleWrapper>
      <StorageName>
        <Typography variant="subtitle1" style={{ fontSize: '16px' }}>
          {branchName}
        </Typography>
      </StorageName>
      <StockWrapper onlyAvailable={onlyAvailable}>
        {!onlyAvailable && (
          <>
            <Typography variant="body2" fontWeight={fontWeight}>
              {text1}
            </Typography>
            <Typography variant="body2" fontWeight={fontWeight}>
              Reserved
            </Typography>
          </>
        )}
        <Typography variant="body2" fontWeight={fontWeight}>
          Available
        </Typography>
      </StockWrapper>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StorageName = styled.div`
  width: calc(100% - 240px);
  padding-left: 12px;
`;

export const StockWrapper = styled.div<{ onlyAvailable: boolean }>`
  display: flex;
  flex-direction: row;
  max-width: ${(props) => (props.onlyAvailable ? '80px' : '240px')};
  width: 100%;
  justify-content: space-around;
`;
