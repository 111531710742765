import _ from 'lodash';
import { Resource } from '@tyrio/dto';

const getMinAndMaxHour = (data: Resource[]) => {
  let minHour = 23;
  let maxHour = 0;

  data.forEach((resource) => {
    Object.values(resource.workingTime).forEach((wt) => {
      const start = _.get(wt, 'work.start');
      const end = _.get(wt, 'work.end');

      const startSplitted = start.split(':');
      const endSplitted = end.split(':');

      const startNumber = Number(startSplitted[0]);
      const endNumber = Number(endSplitted[0]);

      if (startNumber && startNumber < minHour) minHour = startNumber;
      if (endNumber && endNumber > maxHour) maxHour = endNumber;
    });
  });

  return { minHour, maxHour };
};

export default getMinAndMaxHour;
