import api from '@tyrio/api-factory';
import { WheelSizeApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useState } from 'react';
import { useQuery } from 'react-query';

interface GetBrandsProps {
  brand: string;
}

export const useGetModels = (props: GetBrandsProps) => {
  const [models, setModels] = useState<TyrioSelectInputOption[]>([]);

  const { brand } = props;
  useQuery(
    ['get_models', brand],
    () =>
      api.fetch<WheelSizeApi['getOneModel']>('get_models', {
        model: brand,
      }),
    {
      enabled: !!brand,
      onSuccess(data) {
        setModels(
          data.data
            ? data.data.map((item) => ({
                value: item.slug,
                label: item.name,
              }))
            : []
        );
      },
    }
  );

  return { models };
};
