import { AppointmentReq, GetServiceDetailsRes, ServiceInfo } from '@tyrio/dto';
import _ from 'lodash';
import { usePosCartData } from '../../helpers/cart-data';
import { usePosCartCtx } from '../../../../../../context/PosCartContext';

type AppointmentsShape = Record<string, AppointmentReq>;

export const useAppointmentsHelper = () => {
  const { servicesData } = usePosCartData();
  const { appointmentDetails } = usePosCartCtx();

  const initServiceCategories = (dbServiceCategories: GetServiceDetailsRes) => {
    const updated: AppointmentsShape = {};

    const appointments =
      (appointmentDetails['appointments'] as unknown as Record<
        string,
        AppointmentReq
      >) ?? {};

    if (dbServiceCategories) {
      Object.values(dbServiceCategories).forEach((item) => {
        const services: Record<string, ServiceInfo> = {};

        const cartService = servicesData.find(
          (service) => service.req.serviceCategory === item.id
        );

        let categoryDuration = 0;

        item.services.forEach((service) => {
          const totalDuration =
            (cartService?.req.quantity as number) * service.serviceDuration;

          categoryDuration += totalDuration;

          services[service.id] = {
            ...service,
            qty: cartService?.req.quantity as number,
            totalDuration: totalDuration,
          };
        });

        const existedItem = appointments[item.id];

        if (existedItem) {
          updated[item.id] = existedItem;
        } else {
          updated[item.id] = {
            id: item.id,
            name: item.name,
            duration: categoryDuration,
            rounded: Math.ceil(categoryDuration / 10) * 10,
            services: services,
          };
        }
      });
    }

    return { updated };
  };

  const updateCategoryDuration = (
    appointments: Record<string, AppointmentReq>,
    item: AppointmentReq,
    val: number
  ) => {
    const updated = appointments;

    updated[item.id].rounded = val;

    return { updated };
  };

  const updateServiceQty = (
    appointments: Record<string, AppointmentReq>,
    categoryId: string,
    serviceId: string,
    val: number
  ) => {
    const updated = appointments;

    const service = `${categoryId}.services.${serviceId}`;

    const serviceObj = _.get(updated, `${service}`) as unknown as ServiceInfo;

    const serviceDuration = val * serviceObj.serviceDuration;

    _.set(updated, `${service}.qty`, val);
    _.set(updated, `${service}.totalDuration`, serviceDuration);

    const category = _.get(updated, `${categoryId}.services`);

    const categoryDuration = _.sumBy(Object.values(category), `totalDuration`);

    _.set(updated, `${categoryId}.duration`, categoryDuration);

    const categoryDurationRounded = Math.ceil(categoryDuration / 10) * 10;

    _.set(updated, `${categoryId}.rounded`, categoryDurationRounded);

    return { updated };
  };

  const getAppointments = () => {
    return appointmentDetails?.['appointments'] as unknown as Record<
      string,
      AppointmentReq
    >;
  };

  return {
    initServiceCategories,
    updateCategoryDuration,
    updateServiceQty,
    getAppointments,
  };
};
