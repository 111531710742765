import api from '@tyrio/api-factory';
import { DBServiceCategoryApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetServiceCategoryById = (id: string, loadData: boolean) => {
  const { data, refetch } = useQuery(
    ['get_service_category_by_id', id],
    () =>
      api.fetch<DBServiceCategoryApi['getOne']>(`get_service_category_by_id`, {
        id,
      }),
    {
      enabled: !!id && loadData,
    }
  );
  return { data, refetch };
};
