import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import Partners from '../pages/dashboard/wms/Partners';
import PartnerDetailsForm from '../pages/dashboard/wms/partners/PartnerDetailsForm';

export const PartnerRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <Partners>
        {location.pathname.split('/').pop() === 'new' ? (
          <Route exact path={`${path}/new`}>
            <PartnerDetailsForm />
          </Route>
        ) : (
          <Route exact path={`${path}/:partnerId`}>
            <PartnerDetailsForm />
          </Route>
        )}
      </Partners>
    </Switch>
  );
};
