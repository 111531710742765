import styled from '@emotion/styled';
import { backIcon } from '@tyrio/ui-library';
import { useHistory } from 'react-router-dom';
import { PageTemplateWrapper } from '../../components/PageTemplate/PageTemplate';
import {
  BackButton,
  HeaderText,
  HeaderWrapperTop,
} from '../purchase-orders/PurchaseOrderOverview';
// import { AppointmentsCalendar } from '../pos/steps/Cart/Steps/Appointment/calendar/Calendar';
import { AppointmentsCalendarV2 } from '../pos/steps/Cart/Steps/Appointment/calendar/CalendarV2';

const AppointmentsOverview = () => {
  const history = useHistory();

  return (
    <PageTemplateWrapper
      style={{
        justifyContent: 'space-between',
        right: 0,
        position: 'absolute',
      }}
    >
      <Container>
        <HeaderWrapperTop>
          <BackButton onClick={() => history.push('/dashboard')}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>Appointments</HeaderText>
        </HeaderWrapperTop>
        <ContentContainer>
          <AppointmentsCalendarV2 />
        </ContentContainer>
      </Container>
    </PageTemplateWrapper>
  );
};

export default AppointmentsOverview;

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  padding: 16px;
  overflow: hidden;
`;
