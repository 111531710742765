import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Divider, Modal, TextField } from '@mui/material';
import { DBWarehouseBins, DBWarehouseShelfs } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBStockListApi, LocationType } from '@tyrio/dto';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioSelectInputOption,
} from '@tyrio/ui-library';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../../query-client';
import { useAuth } from '../../../../context/AuthContext';
import { StockListContext } from '../../../../context/StockListContext';
import { useGetAllWarehouseLocations } from '../ProductDetails/queries/main-branch-stock';
import {
  BoxStyled,
  CloseWrapper,
  Title,
  TitleWrapper,
} from './style/common.style';
import { Footer } from '../../../../components/Modal/Footer';
import { LocationTypes } from './LocationType';
import { AxiosError } from 'axios';

interface IAddStockListItem {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddStockListItem = ({ open, setOpen }: IAddStockListItem) => {
  const { user } = useAuth();
  const { input } = useContext(StockListContext);

  const [shelves, setShelves] = useState<Record<string, DBWarehouseShelfs>>({});
  const [bins, setBins] = useState<Record<string, DBWarehouseBins>>({});

  const [locationType, setLocationType] = useState<LocationType>('SHELF');
  const [selectedLocation, setSelectedLocation] =
    useState<TyrioSelectInputOption | null>(null);

  const [payload, setPayload] = useState<
    DBStockListApi['create']['requestBody'][]
  >([
    {
      branchId:
        Number(input.warehouseId) ?? Number(user?.currentBranchId) ?? '',
      clientId: user?.clientId ?? '',
      locationId: undefined,
      ean: '',
      productionYear: '',
      dot: '',
      quantity: 0,
      demo: false,
      qrCode: '',
    },
  ]);

  const [shelvesDropdownData, setShelvesDropdownData] = useState<
    TyrioSelectInputOption[]
  >([]);
  const [binsDropdownData, setBinsDropdownData] = useState<
    TyrioSelectInputOption[]
  >([]);

  const { isSuccess } = useGetAllWarehouseLocations(
    setShelves,
    setBins,
    Number(input.warehouseId) ?? Number(user?.mainBranchId)
  );

  useEffect(() => {
    if (isSuccess) {
      const shelvesData: TyrioSelectInputOption[] = [];
      const binsData: TyrioSelectInputOption[] = [];
      // shelves data
      Object.entries(shelves ?? []).forEach((key, _value) => {
        shelvesData.push({
          label: key[0],
          value: key[1].id,
        });
      });
      setShelvesDropdownData(shelvesData);
      // bins data
      Object.entries(bins ?? []).forEach((key, _value) => {
        binsData.push({
          label: key[0],
          value: key[1].id,
        });
      });
      setBinsDropdownData(binsData);
    }
  }, [bins, isSuccess, shelves]);

  const createStockListItem = useMutation(
    (data: DBStockListApi['createMore']['requestBody']) => {
      return api.fetch<DBStockListApi['createMore']>(
        'create_stock_list_items',
        data
      );
    },
    {
      mutationKey: 'create_stock_list_items',
      onSuccess: () => {
        ToastHelper.showToast(
          'Item',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        queryClient.refetchQueries('get_stock_list_products');
        queryClient.refetchQueries('main_branch_stock');
        setOpen(false);
      },

      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error?.response?.data?.error.name;
          ToastHelper.showToast(
            'Product',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const handleSubmit = () => {
    if (payload[0].ean.length === 13 && payload[0].dot.length === 4)
      createStockListItem.mutateAsync(payload);
    else
      ToastHelper.showToast(
        'Product',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        'Incorrect ean or dot'
      );
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <BoxStyled>
        <CloseWrapper>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{ cursor: 'pointer' }}
          />
        </CloseWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between  ',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <TitleWrapper>
            <Title>ADD NEW ITEM TO YOUR WAREHOUSE</Title>
          </TitleWrapper>
        </Box>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        <FormWrapper>
          <TextField
            fullWidth
            variant="outlined"
            id="ean"
            label="Ean"
            placeholder="Ean"
            sx={{ marginBottom: '15px' }}
            value={payload[0].ean}
            onChange={(e) =>
              setPayload((prevState) => [
                {
                  ...prevState[0],
                  ean: e.target.value,
                },
              ])
            }
            InputProps={{
              inputProps: {
                max: 13,
              },
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            id="productionYear"
            label="Production Year"
            placeholder="Production Year"
            sx={{ marginBottom: '15px' }}
            value={payload[0].productionYear}
            onChange={(e) =>
              setPayload((prevState) => [
                { ...prevState[0], productionYear: e.target.value },
              ])
            }
            InputProps={{
              inputProps: {
                max: 4,
              },
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            id="dot"
            label="Dot"
            placeholder="Dot"
            sx={{ marginBottom: '15px' }}
            value={payload[0].dot}
            onChange={(e) =>
              setPayload((prevState) => [
                {
                  ...prevState[0],
                  dot: e.target.value,
                },
              ])
            }
            InputProps={{
              inputProps: {
                max: 4,
              },
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            id="quantity"
            label="Quantity"
            placeholder="Quantity"
            sx={{ marginBottom: '15px' }}
            value={
              payload[0].quantity !== 0
                ? payload[0].quantity.toString().replace('/^0+/', '')
                : 0
            }
            type="number"
            onChange={(e) =>
              setPayload((prevState) => [
                {
                  ...prevState[0],
                  quantity: Number(e.target.value),
                },
              ])
            }
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
          <LocationTypes
            value={locationType.toLowerCase()}
            setLocationType={setLocationType}
          />
          {locationType === 'SHELF' ? (
            <Autocomplete
              disablePortal
              disableClearable
              id="chooseShelf"
              value={selectedLocation ?? undefined}
              options={shelvesDropdownData}
              sx={{ width: '100%' }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_event, newValue) => {
                setSelectedLocation({
                  label: newValue.label,
                  value: newValue.value,
                });
                setPayload((prevState) => [
                  {
                    ...prevState[0],
                    qrCode: newValue.label,
                  },
                ]);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose shelf"
                  variant="outlined"
                />
              )}
            />
          ) : (
            <Autocomplete
              disablePortal
              disableClearable
              id="chooseBin"
              value={selectedLocation ?? undefined}
              options={binsDropdownData}
              sx={{ width: '100%' }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_event, newValue) => {
                setSelectedLocation({
                  label: newValue.label,
                  value: newValue.value,
                });
                setPayload((prevState) => [
                  {
                    ...prevState[0],
                    qrCode: newValue.label,
                  },
                ]);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Choose bin" variant="outlined" />
              )}
            />
          )}
        </FormWrapper>
        <Footer setOpen={setOpen} handleSubmit={handleSubmit} />
      </BoxStyled>
    </Modal>
  );
};

const FormWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
