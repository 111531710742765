import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { VANDENBAN_CATALOG_RULESET } from './vandenban/catalog';
import { VANDENBAN_PRICELIST_RULESET } from './vandenban/pricelist';
import { VANDENBAN_STOCKLIST_RULESET } from './vandenban/stocklist';

export const VANDENBAN_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: VANDENBAN_CATALOG_RULESET,

  pricelistRuleset: VANDENBAN_PRICELIST_RULESET,
  stockRuleset: VANDENBAN_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
      origin: '',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'VANDENBAN',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    Type: {
      '(DOT 2022)': '',
      '(DEMO)': '',
      DEMO: '',
      'DOT 2022': '',
      '()': '',
      '*': ' * ',
    },
    'Manufacturer code': {
      '-demo': '',
      '-DEMO': '',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    // console.log('READ FILE', file);
    const json = await csv2json({ delimiter: ';' }).fromString(file);
    // json.filter((item) => item['EAN code'] === '4019238023374'),
    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
