import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioFormError, TyrioRadioInput } from '../types';

interface TyrioInputRadioProps {
  id: string;
  item: TyrioRadioInput;
  gridProps?: FormGridProps;
  error?: TyrioFormError;
  control: Control;
}
export const TyrioInputRadio = ({
  item,
  gridProps,
  id,
  control,
  error,
}: TyrioInputRadioProps) => {
  const { t } = useTranslation();
  return (
    <GridContainer item {...gridProps}>
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <FormLabel id={id}>{t(`${item.label}`)}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              row
              value={field.value}
              onChange={(event, value) => field.onChange(value)}
            >
              {item.options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio color="info" />}
                  label={t(option.label)}
                  disabled={option.disabled}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
      {error?.message && (
        <FormHelperText error={true}>{t(error?.message)}</FormHelperText>
      )}
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
`;
