import styled from '@emotion/styled';
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { useState } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { FormGridProps, TyrioFormError, TyrioPhoneInput } from '../types';
import { checkIfValidPhoneNumber } from '../utils';
import './style-overrides/input_phone.css';

interface TyrioInputPhoneStringProps {
  item: TyrioPhoneInput;
  id: string;
  gridProps: FormGridProps;
  control: Control;
  error?: TyrioFormError;
  register: UseFormRegister<FieldValues>;
  defaultCode?: string;
}
export const TyrioInputPhoneString = ({
  item,
  id,
  gridProps,
  control,
  error,
  defaultCode = 'hr',
}: TyrioInputPhoneStringProps) => {
  const { t } = useTranslation();
  const [notValid, setNotValid] = useState('');
  const handleValidation = (e: string) => {
    if (!checkIfValidPhoneNumber(e) || e.length < 12 || e.length > 13) {
      setNotValid('Number must be between 12 and 13 digits!');
    } else setNotValid('');
    return e;
  };

  return (
    <GridContainer data-cy={'phoneNumber'} item {...gridProps}>
      <FormControl
        fullWidth
        error={!!notValid || !!error?.message}
        style={{ marginBottom: (notValid || error?.message) && '-23px' }}
      >
        <Controller
          name={id}
          control={control}
          render={({ field }) => {
            return (
              <PhoneInput
                disabled={item.disabled}
                specialLabel={t(`${item.label}`)}
                inputStyle={{
                  width: '100%',
                  height: 56,
                  borderRadius: 8,
                  border:
                    notValid !== '' || error?.message
                      ? '1px solid #d32f2f'
                      : '',
                  color: item.disabled
                    ? `darkgray`
                    : error?.message || notValid
                    ? `${tyrioUI.colors.secondary}`
                    : `${tyrioUI.colors.primary}`,
                }}
                containerStyle={{
                  color: item.disabled
                    ? `${tyrioUI.colors.darkGrey}`
                    : error?.message || notValid
                    ? `${tyrioUI.colors.secondary}`
                    : `${tyrioUI.colors.primary}`,
                }}
                country={defaultCode}
                value={field.value}
                onChange={(a) => {
                  field.onChange(handleValidation('+' + a));
                }}
              />
            );
          }}
        />
        {error?.message ? (
          <FormHelperText>{t(error.message)}</FormHelperText>
        ) : (
          <FormHelperText>{t(notValid)}</FormHelperText>
        )}
      </FormControl>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
`;
