import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { GrandTotal } from '../../../components/Cart/GrandTotal';
import { formatCurrencyWithSymbol } from '../../../helpers/currency-format';

interface TableFooterProps {
  isVatIncluded: boolean;
  totalQty: number;
  purchasePrice: number;
  variableCost: number;
  customs: number;
  subTotalExlVat?: number;
  grandTotal?: number;
  vat?: number;
}

export const TableFooter = ({
  isVatIncluded,
  totalQty,
  purchasePrice,
  variableCost,
  customs,
  subTotalExlVat = 0,
  grandTotal = 0,
  vat = 0,
}: TableFooterProps) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <PriceWrapper
          background={'#F9FAFB'}
          style={{ borderBottomLeftRadius: '16px' }}
        >
          <Text fontSize={12} fontWeight={600}>
            ITEMS QUANTITY
          </Text>
          <Typography fontSize={12}>{`${totalQty} pcs`}</Typography>
          <Text fontSize={10} fontWeight={400}>
            {' '}
          </Text>
        </PriceWrapper>

        <PriceWrapper background={'#F9FAFB'}>
          <Text fontSize={12} fontWeight={600}>
            PURCHASE AMOUNT
          </Text>
          <Typography fontSize={12}>
            {formatCurrencyWithSymbol(Number(purchasePrice))}
          </Typography>
          <Text fontSize={10} fontWeight={400}>
            {isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          </Text>
        </PriceWrapper>

        <Divider />

        <PriceWrapper background={'#F9FAFB'}>
          <Text fontSize={12} fontWeight={600}>
            VARIABLE COST
          </Text>
          <Typography fontSize={12}>
            {formatCurrencyWithSymbol(Number(variableCost))}
          </Typography>
          <Text fontSize={10} fontWeight={400}>
            {isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          </Text>
        </PriceWrapper>
        <PriceWrapper background={'#F9FAFB'}>
          <Text fontSize={12} fontWeight={600}>
            CUSTOMS
          </Text>
          <Typography fontSize={12}>
            {formatCurrencyWithSymbol(customs)}
          </Typography>
          <Text fontSize={10} fontWeight={400}>
            {isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          </Text>
        </PriceWrapper>
      </ContentWrapper>
      <GrandTotal
        subTotalExlVat={subTotalExlVat}
        vat={vat}
        grandTotal={grandTotal}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #ffffff;
  width: 100%;
  height: 90px;
  position: sticky;
  bottom: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  max-width: 64%;
  width: 100%;
`;

const PriceWrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: start;
  background: ${(props) => props.background};
  padding: 10px;
`;

const Text = styled(Typography)`
  color: ${tyrioUI.colors.black.B80};
`;

const Divider = styled.div`
  border-right: 1px solid #dfe3e8;
  margin-top: 8px;
  margin-bottom: 8px;
`;
