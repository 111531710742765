import * as z from "zod"
import { Code } from ".prisma/client"
import { CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBWarehouseShelfs, RelatedDBWarehouseShelfsModel, CompleteDBStockListItemLocation, RelatedDBStockListItemLocationModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBWarehouseBinsModel = z.object({
  id: z.string(),
  warehouseAreaId: z.string(),
  shelfId: z.string(),
  code: z.nativeEnum(Code),
  displayName: z.string(),
  qrCode: z.string(),
  description: z.string(),
  settings: jsonSchema,
  meta: jsonSchema,
})

export interface CompleteDBWarehouseBins extends z.infer<typeof DBWarehouseBinsModel> {
  warehouseArea: CompleteDBBranchWarehouseAreas
  shelf: CompleteDBWarehouseShelfs
  bins: CompleteDBStockListItemLocation[]
}

/**
 * RelatedDBWarehouseBinsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWarehouseBinsModel: z.ZodSchema<CompleteDBWarehouseBins> = z.lazy(() => DBWarehouseBinsModel.extend({
  warehouseArea: RelatedDBBranchWarehouseAreasModel,
  shelf: RelatedDBWarehouseShelfsModel,
  bins: RelatedDBStockListItemLocationModel.array(),
}))
