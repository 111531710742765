import styled from '@emotion/styled';
import { Button, Modal, Typography } from '@mui/material';
import { damagedIcon } from '@tyrio/ui-library';
import { Dispatch, SetStateAction, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface DamagedModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  productName: string;
  supplierName: string;
}

const mock_data: string[] = [
  'https://www.goodyear.eu/en_gb/consumer/learn/understandingyourtyre/_jcr_content/root/responsivegrid/responsivegrid_34560/image_107003403.coreimg.85.900.png/1610364173421/goodyear-vector4seasons-gen3-suv--view-3-3-4-30-deg-lr-shadow-small-resize.png',
  'https://www.maxxis.com/uk/wp-content/uploads/sites/4/2021/02/tyre-image-ma919.jpg',
  'https://m.media-amazon.com/images/I/61NLLINuwaL._AC_SS450_.jpg',
  'https://www.maxxis.com/uk/wp-content/uploads/sites/4/2021/02/tyre-image-ma919.jpg',
  'https://www.goodyear.eu/en_gb/consumer/learn/understandingyourtyre/_jcr_content/root/responsivegrid/responsivegrid_34560/image_107003403.coreimg.85.900.png/1610364173421/goodyear-vector4seasons-gen3-suv--view-3-3-4-30-deg-lr-shadow-small-resize.png',
];

export const DamagedModal = ({
  open,
  setOpen,
  productName,
  supplierName,
}: DamagedModalProps) => {
  const [focusedImage, setFocusedImage] = useState<number>(0);
  const zip = new JSZip();

  const downloadImages = () => {
    let count = 0;
    mock_data.forEach(async (imgUrl: string) => {
      const image = await fetch(imgUrl);
      const imageBlog = await image.blob();
      zip.file(`tyrio-tire-${count}.jpg`, imageBlog, { binary: true });
      count++;
      if (count === mock_data.length) {
        zip.generateAsync({ type: 'blob' }).then(function (images) {
          saveAs(images, 'damaged-product-images.zip');
        });
      }
    });
  };

  return (
    <Modal
      open={open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalWrapper>
        <CloseIconWrapper>
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
            sx={{ cursor: 'pointer' }}
          />
        </CloseIconWrapper>
        <HeaderWrapper>
          <DamagedIcon src={damagedIcon} alt="damaged-icon" />
          <Typography
            fontSize={20}
            fontWeight={600}
            letterSpacing={0.3}
            fontFamily={'Barlow'}
          >
            {'DAMAGED ITEM PHOTO'}
          </Typography>
          <Typography
            fontSize={16}
            letterSpacing={0.3}
            fontWeight={400}
            fontFamily={'Barlow'}
            color="#212B36"
          >
            {supplierName} {productName}
          </Typography>
        </HeaderWrapper>

        <FocusedImage src={mock_data[focusedImage]} />
        <ImagesWrapper>
          {mock_data.map((p, index) => {
            return (
              <SingleImageWrapper focused={index === focusedImage} key={index}>
                <UnfocusedImage
                  src={p}
                  onClick={() => setFocusedImage(index)}
                />
              </SingleImageWrapper>
            );
          })}
        </ImagesWrapper>

        <ButtonWrapper>
          <StyledButton
            variant="outlined"
            color="info"
            size="large"
            onClick={downloadImages}
          >
            DOWNLOAD IMAGES
          </StyledButton>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 550px;
  background: white;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22),
    0px 19px 38px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  height: 90%;
  padding: 32px;
`;

const CloseIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  height: 20%;
  width: 100%;
`;

const DamagedIcon = styled.img`
  width: 40%;
  height: 40%;
`;

const StyledButton = styled(Button)`
  width: 179px;
  height: 60px;
`;

export const FocusedImage = styled.img`
  width: 50%;
  height: 40%;
  cursor: pointer;
`;

export const UnfocusedImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 90px;
  height: 100%;
  gap: 20px;

  @keyframes slideIn {
    from {
      left: calc(100% - 45px);
      width: 0;
    }
    to {
      left: 0;
      width: 100%;
    }
  }
`;

export const SingleImageWrapper = styled.div<{ focused: boolean }>`
  border-bottom: ${(props) => (props.focused ? '2px solid #1976D2' : null)};
  opacity: ${(props) => (props.focused ? 1 : 0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  width: 80px;
  height: 100%;
  &:hover {
    opacity: 1;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 24px;
  margin-top: 16px;
`;

export default DamagedModal;
