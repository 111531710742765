import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const DocumentsWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

export const DocumentDetailsWrapper = styled.div`
  border: 1px solid #d2d4d2;
  border-radius: 8px;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 22px;
  justify-content: space-between;
  &:hover {
    border: 1px solid #1976d2;
    cursor: pointer;

    #icon_button {
      background: rgba(25, 118, 210, 0.04);
      svg {
        color: #1976d2;
      }
    }
  }
`;

export const ReservedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ReservedTextWrapper = styled.div`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.3px;
`;

export const ReservedNumberWrapper = styled.div`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: red;
`;

export const DocumentNameWrapper = styled.div`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`;

export const DocumentReferenceWrapper = styled.div`
  font-weight: 500;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
`;

export const FileIconNameWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
`;

export const IconsWrapper = styled.div<{ isHovered?: boolean }>`
    width: 100%;
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    gap: ${(props) => (props.isHovered ? '5px' : '5px')}
    cursor: ${(props) => (props.isHovered ? 'pointer' : 'none')}
`;

export const Image = styled.img``;

export const IconButtonStyled = styled(IconButton)`
  color: #919eab;
  &:hover {
    svg {
      color: #1976d2;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  gap: 32px;
  border-radius: 16px;
  padding: 16px 8px;
  height: 90%;
  width: 100%;
`;
