/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-concat */

import styled from '@emotion/styled';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';
import SourceContainer, { TyrioSelectInputOption } from '@tyrio/forms';
import {
  CancelModal,
  DeleteModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
  backIcon,
} from '@tyrio/ui-library';

import { DBMargin, DBPriceRound } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBClientPriceCalculationApi,
  DBSupplierClientSettingsApi,
} from '@tyrio/dto';
import { RouteRouterPrompt } from '@tyrio/ui-library';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { queryClient } from '../../../query-client';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';
import { updateRules } from '../customer-rebates/helper/RulesHelper';
import { PriceCalculationForm } from './PriceCalculationForm';
import { RulesList } from './components/Rules/RulesList';
import {
  IJsonLogic,
  INewCondition,
  RulesContext,
} from './context/RulesContext';
import { ComponentType } from './helper/enum';
import CheckRulesModal from './components/CheckRules/CheckRules';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

const PriceCalculationOverview = () => {
  const history = useHistory();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { priceCalculationId } = useParams<{ priceCalculationId: string }>();
  const [suppliersData, setSuppliersData] =
    useState<TyrioSelectInputOption[]>();

  const [rules, setRules] = useState<any>([]);
  const newRuleCondition = useRef<INewCondition[]>([]);

  const updatedConditions = useRef<IJsonLogic[]>([
    { jsonLogic: '', ruleIndex: null },
  ]);
  // REGISTERS IF CHANGE HAPPENED IN RULE LIST
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState<any>([]);

  const { data: calculationData } = useQuery(
    ['get_price_calculations'],
    async () =>
      await api.fetch<DBClientPriceCalculationApi['list']>(
        'get_price_calculations'
      )
  );

  console.log({ rules });

  const {
    data: priceCalculationData,
    isFetching: isPriceCalculationDataFetching,
    refetch: getPriceCalculationById,
  } = useQuery(
    [
      'get_price_calculation_by_id',
      priceCalculationId,
      'update_price_calculation',
    ],
    () =>
      api.fetch<DBClientPriceCalculationApi['getOne']>(
        `get_price_calculation_by_id`,
        {
          id: priceCalculationId,
        }
      ),
    {
      enabled: !!priceCalculationId,
      onSuccess: (res) => {
        const rules: any = res?.rules;

        if (rules && (rules as []).length > 0) {
          rules?.forEach((r: any) => {
            newRuleCondition.current = [
              ...newRuleCondition.current,
              { id: r.id, tree: r?.newConditions },
            ];
          });
        }

        // refetchSuppliers();
        setRules(res?.rules);

        updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
      },
    }
  );

  const { refetch: refetchSuppliers } = useQuery(
    ['get_supplier_client_settings'],
    async () => {
      return await api.fetch<DBSupplierClientSettingsApi['list']>(
        'get_supplier_client_settings',
        {
          search: '',
          pageSize: 100,
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries('get_price_calculations');

        const dropdownItems = data?.data.map((item) => ({
          label: item.supplier?.companyShortName || '',
          value: item.id,
        }));

        setSuppliersData(dropdownItems);
      },
    }
  );

  const updatePriceCalculationGroupStatus = useMutation(
    () =>
      api.fetch<DBClientPriceCalculationApi['updateOne']>(
        'change_price_calculation_status',
        {
          id: priceCalculationId,
        }
      ),
    {
      mutationKey: 'change_price_calculation_status',
      onSuccess: () => {
        queryClient.refetchQueries('get_price_calculations');
        refetchSuppliers();
        getPriceCalculationById();
        ToastHelper.showToast(
          'Price Calculation',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Price Calculation',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const deletePriceCalculationMutation = useMutation(
    () =>
      api.fetch<DBClientPriceCalculationApi['getOne']>(
        'delete_price_calculation',
        {
          id: priceCalculationId,
        }
      ),
    {
      mutationKey: 'delete_price_calculation',
      onSuccess: () => {
        queryClient.refetchQueries('get_price_calculations');
        history.push(`/dashboard/price-calculation`);
        ToastHelper.showToast(
          'Price calculation',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Price calculation',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const createPriceCalculation = useMutation(
    (data: DBClientPriceCalculationApi['create']['requestBody']) => {
      return api.fetch<DBClientPriceCalculationApi['create']>(
        'create_price_calculation',
        {
          ...data,
        }
      );
    },
    {
      mutationKey: 'create_price_calculation',
      onSuccess: (item) => {
        queryClient.refetchQueries('get_price_calculations');
        ToastHelper.showToast(
          'Price calculation',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/price-calculation/${item.id}`);
        getPriceCalculationById();
        queryClient.refetchQueries('get_basic_calculation');
        setIsSaving(false);
        setDirty(false);
        updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        const errorMessage = data.response.data.error.name;
        ToastHelper.showToast(
          'Price Calculation',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          errorMessage ?? 'An error occurred!'
        );
      },
    }
  );

  const updatePriceCalculation = useMutation(
    (data: DBClientPriceCalculationApi['create']['requestBody']) => {
      return api.fetch<DBClientPriceCalculationApi['updateOne']>(
        'update_price_calculation',
        {
          id: priceCalculationId,
          ...data,
        }
      );
    },
    {
      mutationKey: 'update_price_calculation',
      onSuccess: (item) => {
        queryClient.refetchQueries('get_price_calculations');
        ToastHelper.showToast(
          'Price Calculation',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        history.push(`/dashboard/price-calculation/${item.id}`);
        getPriceCalculationById();
        setIsSaving(false);
        setDirty(false);
        updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        const errorMessage = data.response.data.error.name;
        ToastHelper.showToast(
          'Price Calculation',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          errorMessage ?? 'An error occurred!'
        );
      },
    }
  );

  const onSubmit = (
    data: DBClientPriceCalculationApi['create']['requestBody']
  ) => {
    setIsSaving(true);
    const payload: DBClientPriceCalculationApi['create']['requestBody'] = {
      supplierId: data.supplierId !== 'all' ? data.supplierId : null,
      margin: data.margin,
      marginType: data.marginType,
      priceRound: data.priceRound,
      useSuggestedRetailPrice: data.useSuggestedRetailPrice,
      rules: updateRules(updatedConditions, rules, newRuleCondition),
    };
    if (payload.rules === null) return;

    if (priceCalculationId) updatePriceCalculation.mutate(payload);
    else createPriceCalculation.mutate(payload);
  };

  const generateDefaultValues = useCallback(() => {
    //We don't have price calculation so default data is for BASIC price calculation and supplierId is null.
    if (!priceCalculationData) {
      return {
        supplierId: null,
        margin: 0,
        marginType: DBMargin.EUR,
        priceRound: DBPriceRound.NO_ROUND,
        useSuggestedRetailPrice: false,
        rules: [],
      };
    }

    if (priceCalculationData && priceCalculationData.supplierId === null) {
      return {
        supplierId: null,
        margin: priceCalculationData.margin,
        marginType: priceCalculationData.marginType,
        priceRound: priceCalculationData.priceRound,
        useSuggestedRetailPrice: priceCalculationData.useSuggestedRetailPrice,
        rules: priceCalculationData.rules,
      };
    } else {
      return {
        supplierId: priceCalculationData.supplierId,
        margin: priceCalculationData.margin,
        marginType: priceCalculationData.marginType,
        priceRound: priceCalculationData.priceRound,
        useSuggestedRetailPrice: priceCalculationData.useSuggestedRetailPrice,
        rules: priceCalculationData.rules,
      };
    }
  }, [priceCalculationData]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...generateDefaultValues() } as any,
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: true,
    resolver: (formValues) => {
      const errors: Record<string, string> = {};
      if (
        formValues['supplierId'] === '' ||
        formValues['supplierId'] === undefined
      )
        errors['supplierId'] = 'This field is required!';

      if (formValues['margin'] <= 0)
        errors['margin'] = 'Margin needs to be greater than 0!';

      if (rules) {
        rules.forEach((item: any, ruleIndex: number) => {
          if (item?.ruleDescription === '') {
            errors['ruleDescription' + '_' + ruleIndex] =
              'This field is required!';
          }

          if (
            item?.conditions === '' &&
            updatedConditions.current.find((c) => c.ruleIndex === item.id)
              ?.jsonLogic === ''
          ) {
            errors['conditions'] = 'This field is required!';
          }

          if (item?.actions.length === 0)
            errors['actions'] = 'This field is required!';

          if (item?.actions && item?.actions.length > 0) {
            const validateActionKeys = [
              'margin',
              'marginType',
              'price_range_max',
              'price_range_min',
            ];

            item?.actions.forEach((action: any, actionIndex: number) => {
              validateActionKeys.forEach((key) => {
                if (!action?.[key]) {
                  errors[key + '_' + ruleIndex + '_' + actionIndex] =
                    'This field is required!';
                }
              });

              if (
                action?.price_range_min &&
                action?.price_range_max &&
                Number(action?.price_range_min) >
                  Number(action?.price_range_max)
              ) {
                errors[
                  'price_range_max' + '_' + ruleIndex + '_' + actionIndex
                ] = 'Price range max must be greater than price range min!';
              }
            });
          }
        });
      }

      return {
        values: formValues,
        errors,
      };
    },
  });

  useEffect(() => {
    if (Object.values(errors).length > 0) {
      ToastHelper.showToast(
        'Price calculation',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        'Something went wrong. Please check your input data.'
      );
    }
    setError(errors);
  }, [errors, handleSubmit]);

  const handleDeletePriceCalculation = () => {
    if (priceCalculationId) {
      setIsDeleteModalVisible(true);
    }
  };

  const onChangeStatus = () => {
    if (priceCalculationId) updatePriceCalculationGroupStatus.mutate();
  };

  const handleCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else history.push(`/dashboard/price-calculation`);
  };

  useEffect(() => {
    reset(generateDefaultValues());
    if (priceCalculationData === undefined) {
      setRules([]);
      updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
    } else {
      setRules(priceCalculationData?.rules);
      updatedConditions.current = [{ jsonLogic: '', ruleIndex: null }];
    }
  }, [generateDefaultValues, reset, priceCalculationData]);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <PageTemplateContent>
      <Container>
        <CheckRulesModal
          isOpen={isOpen}
          close={handleClose}
          priceCalculationData={priceCalculationData}
        />
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => {
              setIsCancelModalVisible(false);
            }}
            RBAction={() => {
              setIsCancelModalVisible(false);
              history.push(`/dashboard/price-calculation`);
            }}
          />
        )}
        {!isCancelModalVisible && (
          <RouteRouterPrompt
            when={(isDirty && !isSaving) || (dirty && !isSaving)}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() => true}
            setRulesAreDirty={setDirty}
          />
        )}
        {isDeleteModalVisible && (
          <DeleteModal
            LBAction={() => setIsDeleteModalVisible(false)}
            RBAction={() => {
              deletePriceCalculationMutation.mutate();
              setIsDeleteModalVisible(false);
            }}
            itemName={`${priceCalculationData?.name}` || ''}
          />
        )}
        <HeaderWrapper>
          <Title>
            <BackButton
              onClick={() => history.push(`/dashboard/price-calculation`)}
            >
              <img src={backIcon} alt="back-icon" />
            </BackButton>
            {priceCalculationData
              ? priceCalculationData.name
              : calculationData?.count === 0
              ? 'Create Basic Price Calculation'
              : 'Create new Price Calculation'}
          </Title>

          {priceCalculationData && (
            <Grid sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Button
                variant="text"
                color="info"
                startIcon={<PriceCheckIcon />}
                onClick={handleOpen}
              >
                CHECK PRICE
              </Button>
              <FormControlLabel
                control={
                  <Switch
                    data-cy="switch"
                    color="info"
                    onChange={onChangeStatus}
                    checked={priceCalculationData?.isActive}
                    disabled={false}
                  />
                }
                label={'Active'}
              />
            </Grid>
          )}
        </HeaderWrapper>

        {isPriceCalculationDataFetching ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <>
            <PriceCalculationForm
              suppliersData={suppliersData ?? []}
              errors={errors}
              control={control}
              register={register}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              getValues={getValues}
              isBasicCalculation={
                calculationData?.count === 0 ||
                priceCalculationData?.name === 'Basic Price Calculation'
                  ? true
                  : false
              }
              priceCalculationId={priceCalculationId}
            />
            <RulesContext.Provider
              value={{
                rules,
                setRules,
                componentType: ComponentType.price_calculation,
                updatedConditions,
                setDirty,
                error,
                setError,
                newRuleCondition,
              }}
            >
              <DndProvider backend={HTML5Backend}>
                <RulesList />
              </DndProvider>
            </RulesContext.Provider>
          </>
        )}
      </Container>
      <SourceContainer
        data={{
          data_source: 'source',
          created: 'created',
          last_edited: 'edited',
        }}
        disableSave={!isDirty}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleCancel}
        onDelete={handleDeletePriceCalculation}
      />
    </PageTemplateContent>
  );
};

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const BackButton = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
  width: 30px;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #212b36;
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  padding-bottom: 100px;
`;

export default PriceCalculationOverview;
