import styled from '@emotion/styled';
import { Box, Checkbox, Grid } from '@mui/material';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useAuth } from '../../../../../../context/AuthContext';
import { Dispatch, SetStateAction, useState } from 'react';
import { AddressProps } from '../../../../../../context/PosCartContext';
import { AddressTextField } from '../../../../../customer-orders/components/AddressCard/AddressTextField';
import { InputPhone } from '../../../../../customer-orders/components/AddressCard/InputPhone';
import { customToast } from '../../../../../stock-list/components/Cart/CartToast';
import { SelectList } from '../../../../../supplier-form/helpers/components';
import { checkIfValidPhoneNumber } from '../../../../../supplier-form/helpers/regex';
import { useGetCountries } from '../../../../query/get-countries';
import { InfoFooter } from '../Footer';

interface AddressFormProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  itemIndex: number;
  isNew: boolean;
  newDeliveryAddress: AddressProps[];
  setNewDeliveryAddress: Dispatch<SetStateAction<AddressProps[]>>;
}

export const AddressForm = ({
  setOpen,
  itemIndex,
  isNew,
  newDeliveryAddress,
  setNewDeliveryAddress,
}: AddressFormProps) => {
  const { client } = useAuth();

  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);
  const [newAddress, setNewAddress] = useState<AddressProps>(
    !isNew ? newDeliveryAddress[itemIndex] : ({} as unknown as AddressProps)
  );
  const [isMobilePhoneValid, setIsMobilePhoneValid] = useState(true);

  useGetCountries(setCountries, countries);

  const handleChange = (attribute: string, value: string) => {
    setNewAddress((prevState) => ({
      ...prevState,
      [attribute]: value,
    }));
  };

  const handleSubmit = () => {
    let shouldSaveAddress = true;
    if (newAddress.mobilePhone !== '' && newAddress.mobilePhone !== undefined) {
      const numberLength = newAddress.mobilePhone.length;
      if (
        numberLength > 3 &&
        (!checkIfValidPhoneNumber('+' + newAddress.mobilePhone) ||
          numberLength < 12 ||
          numberLength > 15)
      ) {
        setIsMobilePhoneValid(false);
        shouldSaveAddress = false;
      } else {
        shouldSaveAddress = true;
        setIsMobilePhoneValid(true);
      }
    }
    if (shouldSaveAddress) {
      customToast('Address successfully saved.', 'success');
      setOpen(false);
      const address = [...newDeliveryAddress];
      newAddress.fromCustomer = false;
      if (!isNew) address.splice(itemIndex, 1, newAddress);
      else address.push(newAddress);
      setNewDeliveryAddress(address);
    }
  };

  return (
    <FormWrapper>
      <AddressTextField
        id="companyName"
        placeholder="Company name"
        value={newAddress.companyName ?? ''}
        handleChange={(e) => handleChange('companyName', e.target.value)}
        disabled={newAddress?.fromCustomer}
        color="#1976D2"
        customPadding={'16px 8px'}
        topLabelBorder={5}
        fontSize={'16px'}
        fontWeight={400}
      />
      <Grid
        container
        display="flex"
        rowGap={2}
        justifyContent={'space-between'}
      >
        <Box sx={{ width: '49%' }}>
          <AddressTextField
            id="firstName"
            placeholder="First name"
            value={newAddress?.firstName ?? ''}
            handleChange={(e) => handleChange('firstName', e.target.value)}
            customPadding={'16px 8px'}
            topLabelBorder={5}
            disabled={false}
            fontSize={'16px'}
            fontWeight={400}
          />
        </Box>
        <Box sx={{ width: '49%' }}>
          <AddressTextField
            id="lastName"
            placeholder="Last name"
            value={newAddress?.lastName ?? ''}
            handleChange={(e) => handleChange('lastName', e.target.value)}
            customPadding={'16px 8px'}
            topLabelBorder={5}
            disabled={false}
            fontSize={'16px'}
            fontWeight={400}
          />
        </Box>
      </Grid>
      <Box sx={{ width: '42%' }}>
        <SelectList
          label="Country"
          value={newAddress?.country ?? client?.address?.countryId ?? ''}
          dropdownData={countries}
          disabled={newAddress.fromCustomer}
          onChange={(e) => handleChange('country', e.target.value)}
          name={'country'}
        />
      </Box>
      <AddressTextField
        id="address"
        placeholder="Address"
        value={newAddress.address ?? ''}
        handleChange={(e) => handleChange('address', e.target.value)}
        disabled={newAddress.fromCustomer}
        customPadding={'16px 8px'}
        topLabelBorder={5}
        fontSize={'16px'}
        fontWeight={400}
      />
      <Grid
        container
        display="flex"
        rowGap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box sx={{ width: '33%' }}>
          <AddressTextField
            id="zip"
            placeholder="Zip code"
            value={newAddress.zip ?? ''}
            handleChange={(e) => handleChange('zip', e.target.value)}
            disabled={newAddress.fromCustomer}
            customPadding={'16px 8px'}
            topLabelBorder={5}
            fontSize={'16px'}
            fontWeight={400}
          />
        </Box>

        <Box sx={{ width: '65%' }}>
          <AddressTextField
            id="city"
            placeholder="City"
            value={newAddress.city ?? ''}
            handleChange={(e) => {
              handleChange('city', e.target.value);
            }}
            disabled={newAddress.fromCustomer}
            customPadding={'16px 8px'}
            topLabelBorder={5}
            fontSize={'16px'}
            fontWeight={400}
          />
        </Box>
      </Grid>
      <InputPhone
        value={newAddress?.mobilePhone ?? ''}
        handleChange={(e) => handleChange('mobilePhone', e)}
        isValid={isMobilePhoneValid}
      />
      <CheckBoxWrapper>
        <Checkbox
          defaultChecked={false}
          value={false}
          disabled={false}
          color="info"
        />
        <div>Save address for later usage</div>
      </CheckBoxWrapper>

      <InfoFooter
        text1={'CANCEL'}
        text2={'SUBMIT'}
        onCancel={() => setOpen(false)}
        onSubmit={handleSubmit}
        shouldShowDivider={false}
      />
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`;
