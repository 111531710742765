/* eslint-disable @typescript-eslint/no-explicit-any */
import TextField from '@mui/material/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import _ from 'lodash';

type InputOption = { label: string; value: string };
interface InputProps {
  control: Control;
  id: string;
  title: string;
  data: string[] | InputOption[];
  disabled?: boolean;
}

export const MultiselectChipInput = ({
  control,
  id,
  title,
  data,
  disabled = false,
}: InputProps) => {
  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value } }: any) => {
        return (
          <Autocomplete
            disableCloseOnSelect
            multiple
            key={title}
            id={title}
            disabled={disabled}
            getOptionLabel={(option: string | InputOption) => {
              const current =
                typeof option === 'string' ? option : option.label;

              const foundInputOption = _.find(data, (item: any) => {
                const f = typeof item === 'string' ? item : item.value;

                return f === current;
              }) as any;

              return foundInputOption?.label || current;
            }}
            defaultValue={[]}
            disablePortal
            filterSelectedOptions
            options={data.map((item) =>
              typeof item === 'string' ? item : item.value
            )}
            value={value}
            onChange={(__, value, reason) => {
              if (reason === 'clear') onChange([]);
              else onChange(value);
            }}
            renderInput={(params) => {
              return <TextField {...params} label={title} />;
            }}
          />
        );
      }}
    />
  );
};
