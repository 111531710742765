import {
  DBClientRebateCalculation,
  DBPriceRound,
  DBRebateCalculationType,
  DBRebateGroupType,
} from '@prisma/client';

export const clientRebateCalculation: DBClientRebateCalculation[] = [
  {
    id: '8a27c2c8-045a-4b27-9f16-9e52e8a97fad',
    clientId: '00000000-0000-1000-b000-0000000000022',
    groupName: 'B2B-A',
    groupType: DBRebateGroupType.FLEET,
    rebate: 30,
    discount: 20,
    calculationType: DBRebateCalculationType.INVOICE,
    priceRound: DBPriceRound.ROUND,
    rules: [],
    isActive: true,
    createdAt: new Date(),
  },
  {
    id: '3f07f1c6-1da9-49f6-b5f0-03ef722a1ac4',
    clientId: '00000000-0000-1000-b000-0000000000022',
    groupName: 'B2B-B',
    groupType: DBRebateGroupType.RETAIL,
    rebate: 50,
    discount: 30,
    calculationType: DBRebateCalculationType.SELLING_PRICE,
    priceRound: DBPriceRound.ROUND_DOWN,
    rules: [],
    isActive: true,
    createdAt: new Date(),
  },
];
