/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { Rating } from '@mui/material';
import { ComingSoonMini } from '@tyrio/ui-library';

interface ProductReviewsProps {
  rating?: number;
  numberReviewes?: number;
  content?: string;
}

const ProductReviews = ({
  rating,
  numberReviewes,
  content,
}: ProductReviewsProps) => {
  return (
    <Container>
      {rating && numberReviewes && content ? (
        <>
          <Header>
            <div>{rating}/10</div>
            <Rating precision={0.5} value={rating / 2} readOnly />
          </Header>
          <Description>based on {numberReviewes} reviews</Description>
          <Content>{content}</Content>
        </>
      ) : (
        <ComingSoonMini text={'No reviews yet'} />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  color: #212b36;
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 14px;

  color: #919eab;
`;

const Content = styled.div`
  color: #919eab;
  font-weight: 300;
  font-size: 14px;
`;

export default ProductReviews;
