import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { PIRELLI_CATALOG_RULESET } from './pirelli/catalog';
import { PIRELLI_STOCKLIST_RULESET } from './pirelli/stocklist';
import { PIRELLI_PRICELIST_RULESET } from './pirelli/pricelist';

export const PIRELLI_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: PIRELLI_CATALOG_RULESET,
  pricelistRuleset: PIRELLI_PRICELIST_RULESET,
  stockRuleset: PIRELLI_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    purchasePrice: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
      origin: 'IT',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'PIRELLI EDI B4',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file
      .toString()
      .match(/[^\r\n]+/g)
      ?.slice(2)
      .join('\n');

    // Check if 'parsed' is a string
    if (typeof parsed !== 'string') {
      throw new Error('PIRELLI CATALOG IS NOT LOADED');
    }

    // Continue with the rest of your code
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);
    const list = json.filter((item) =>
      ['C0', 'L0', 'LS'].includes(item.VEHICLE_TYPE)
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
