import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { SUPERIOR_CATALOG_RULESET } from './superior/catalog';
import { SUPERIOR_PRICELIST_RULESET } from './superior/pricelist';
import { SUPERIOR_STOCKLIST_RULESET } from './superior/stocklist';

export const SUPERIOR_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: SUPERIOR_CATALOG_RULESET,
  pricelistRuleset: SUPERIOR_PRICELIST_RULESET,
  stockRuleset: SUPERIOR_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'SUPERIOR',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    Breite: {
      ',': '.',
    },
    Netto: {
      ',': '.',
    },
    Design: {
      'Pr�zision': 'Präzision',
    },
    Lochkreis: {
      ',': '.',
    },
    ET: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const list = json.filter(
      (item) =>
        item.LochAnzahl &&
        item.LochAnzahl !== '0' &&
        item.Lochkreis &&
        item.Lochkreis !== '0' &&
        item.Hauptmass &&
        item.Hauptmass !== '0'
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const list = json.filter(
      (item) =>
        item.LochAnzahl &&
        item.LochAnzahl !== '0' &&
        item.Lochkreis &&
        item.Lochkreis !== '0' &&
        item.Hauptmass &&
        item.Hauptmass !== '0'
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const list = json.filter(
      (item) =>
        item.LochAnzahl &&
        item.LochAnzahl !== '0' &&
        item.Lochkreis &&
        item.Lochkreis !== '0' &&
        item.Hauptmass &&
        item.Hauptmass !== '0'
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
