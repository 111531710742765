import styled from '@emotion/styled';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, Divider, Grid, TextField, Tooltip } from '@mui/material';
import { FieldValues, SetFieldValue, UseFormRegister } from 'react-hook-form';
import {
  FormGridProps,
  TyrioAddNewPartnerInput,
  TyrioFormError,
} from '../types';
// import { tyrioClientVerified } from '@tyrio/ui-library';

interface TyrioAddNewPartnerProps {
  item: TyrioAddNewPartnerInput;
  gridProps: FormGridProps;
  setValue: SetFieldValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  error?: TyrioFormError;
  handleCustomSubmit: () => void;
}
export const TyrioAddNewPartner = ({
  item,
  gridProps,
  register,
  error,
  handleCustomSubmit,
}: TyrioAddNewPartnerProps) => {
  return (
    <Grid item {...gridProps}>
      {item.isVisible && (
        <Container>
          {!item.disabled && (
            <>
              <Header>ADD NEW PARTNER</Header>
              <Description>
                Enter your company registration number (OIB) to import data from
                national court registry
              </Description>
            </>
          )}

          <div style={{ display: 'flex' }}>
            <FormWrapper>
              <TextField
                id={item.id}
                label="Company Registration Number "
                required
                disabled={item.disabled}
                error={!!error?.message}
                helperText={error?.message}
                {...register(item.id)}
              />
            </FormWrapper>
            {item.disabled ? (
              <ImageContainer>
                {/* <img
                style={{
                  height: 36,
                  objectFit: 'contain',
                }}
                src={tyrioClientVerified}
                alt="tyrioGeneratedClient"
              /> */}
              </ImageContainer>
            ) : (
              <Tooltip title={'Fill your company data'}>
                <Button
                  sx={{ marginLeft: '10px;' }}
                  onClick={handleCustomSubmit}
                >
                  <CachedIcon />
                </Button>
              </Tooltip>
            )}
          </div>

          <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
        </Container>
      )}
    </Grid>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
`;

const FormWrapper = styled.div`
  display: flex;
  width: 50%;
`;

const ImageContainer = styled.div`
  height: 56px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
