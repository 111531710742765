import styled from '@emotion/styled/macro';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Pagination from '@mui/material/Pagination/Pagination';
import api from '@tyrio/api-factory';
import { DBProductModelApi } from '@tyrio/dto';
import { Filter, FilterSidebar, Header, Search } from '@tyrio/products';
import { tyrioUI } from '@tyrio/ui-library';
import { ChangeEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../../components/PageTemplate/PageTemplate';
import { useAuth } from '../../../../context/AuthContext';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useFilter } from '../../../../hooks/useFilter';
import ProductsTable from './ProductsTable';

const ProductModelList = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const { filterValues, ...filtersHook } = useFilter({
    searchFilter: 'modelName',
    pageSize: '10',
    page: '1',
  });

  const debouncedSearch = useDebounce(filterValues?.['search'], 700);

  const filters = [
    {
      key: 'noDescription',
      label: 'No description',
    },
    {
      key: 'showInactive',
      label: 'Show inactive',
    },
  ];

  const { isFetching, data } = useQuery(
    ['product_models', filterValues, debouncedSearch],
    () => searchProducts(filterValues)
  );

  const searchProducts = async (filterValues: qs.ParsedQs) => {
    return await api.fetch<DBProductModelApi['list']>('product_models', {
      ...filterValues,
    });
  };

  const count = data?.count || 0;

  const pageSize = parseInt((filterValues['pageSize'] || '10') as string, 10);

  const totalPageNumber = count
    ? count / pageSize - Math.floor(count / pageSize) === 0
      ? count / pageSize
      : Math.floor(count / pageSize) + 1
    : 1;

  return (
    <PageTemplateWrapper>
      <FilterSidebar
        filterValues={filterValues}
        setFilterValue={filtersHook.setFilterValue}
        isOpen={open}
        setOpen={setOpen}
      />

      <PageTemplateContent>
        <Header
          title="Product Model List"
          onClickNew={() => history.push('/dashboard/product-models/new')}
          user={user}
        />
        <Search
          filters={filters}
          setSearchInput={(value) =>
            filtersHook.setFilterValue({ search: value })
          }
          searchInput={filterValues['search'] as string}
          setSearchFilter={(value) =>
            filtersHook.setFilterValue({ searchFilter: value })
          }
          searchFilter={filterValues['searchFilter'] as string}
          selectedFilters={filterValues}
          setSelectedFilters={filtersHook.setFilterValue}
          dataCount={count}
          itemsPerPage={pageSize}
        />

        <Filter
          onOpen={() => setOpen(true)}
          filterValues={filterValues}
          setFilterValue={filtersHook.setFilterValue}
        />

        <ProductsTable
          data={data?.data || []}
          searchFilter={filterValues['searchFilter'] as string}
          searchInput={filterValues['search'] as string}
          filterValues={filterValues}
          sortKey={filterValues['sortKey'] as string}
          updateKeyParamValues={(data) => filtersHook.setFilterValue(data)}
          sortParam={filterValues['sortParam'] as 'asc' | 'desc'}
        />
        {isFetching && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
        <StyledBox>
          <Pagination
            count={totalPageNumber}
            onChange={(e: ChangeEvent<unknown>, page: number) => {
              filtersHook.setFilterValue({ page: `${page}` });
            }}
            page={parseInt(filterValues['page'] as string, 10)}
            sx={{
              button: {
                '&.css-d3ksqy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected':
                  {
                    backgroundColor: `${tyrioUI.colors.lightBlue}`,
                    color: 'white',
                  },
              },
            }}
          />
          <FormController>
            <InputLabel id="page-size-select-label">Rows per page</InputLabel>
            <Select
              fullWidth
              labelId="page-size-select-label"
              id="page-size-select"
              value={filterValues['pageSize']}
              label="Rows per page"
              onChange={(data) =>
                filtersHook.setFilterValue({ pageSize: data.target.value })
              }
              style={{ height: '40px' }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormController>
        </StyledBox>
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: center;
  padding: 16px;
  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

const FormController = styled(FormControl)`
  position: absolute;
  right: 24px;
  width: 110px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export default ProductModelList;
