// will be moved to ui library
import * as _ from 'lodash';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { tyrioUI } from '@tyrio/ui-library';
import styled from '@emotion/styled';
import qs from 'qs';
import { SPECIAL_MARKING_TYPE_MAP } from '@tyrio/shared-vars';

interface FilterProps {
  onOpen: () => void;
  filterValues: qs.ParsedQs;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
}

const Filter = ({ onOpen, filterValues, setFilterValue }: FilterProps) => {
  const get = (path: string) => _.get(filterValues, path, []) as string[];
  const handleDelete = (path: string, key: string) => {
    console.info(
      'Update.',
      path,
      key,
      get(path).filter((elem) => elem !== key)
    );
    setFilterValue({
      [path]: get(path).filter((elem) => elem !== key),
    });
  };

  return (
    <Container>
      <IconButton onClick={onOpen}>
        <MoreVertIcon />
      </IconButton>

      <Stack direction="row" flexWrap="wrap" spacing={2}>
        {get('brand').length > 0 && (
          <>
            <Item>Brand:</Item>
            {get('brand').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('brand', elem)}
                key={elem}
                style={{ marginBottom: 8 }}
              />
            ))}
          </>
        )}

        {get('season').length > 0 && (
          <>
            <Item>Season:</Item>
            {get('season').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('season', elem)}
                key={elem}
                style={{ marginBottom: 8 }}
              />
            ))}
          </>
        )}

        {get('wheelPosition').length > 0 && (
          <>
            <Item>Wheel position:</Item>
            {get('wheelPosition').map((elem) => (
              <Chip
                label={elem.toUpperCase()}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('wheelPosition', elem)}
                key={elem}
                style={{ marginBottom: 8 }}
              />
            ))}
          </>
        )}

        {get('specialMarking').length > 0 && (
          <>
            <Item>Special markings:</Item>
            {get('specialMarking').map((elem) => (
              <Chip
                style={{ marginBottom: 8 }}
                label={SPECIAL_MARKING_TYPE_MAP[elem].label}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('specialMarking', elem)}
                key={SPECIAL_MARKING_TYPE_MAP[elem].label}
              />
            ))}
          </>
        )}
      </Stack>
    </Container>
  );
};

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-style: italic;
  margin-bottom: 8px !important;
`;

const Container = styled.div`
  width: 100%;
  padding: ${tyrioUI.spacing.l}px;
  display: flex;
  align-items: center;
`;

export default Filter;
