/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { ComingSoonMini } from '@tyrio/ui-library';

interface ProductDescriptionProps {
  name?: string | null;
  description?: string | null;
  content?: string | null;
}
const ShortProductDescription = ({
  name,
  description,
  content,
}: ProductDescriptionProps) => {
  return (
    <Container>
      {description ? (
        <>
          <ProductName>{name}</ProductName>
          <ProductNameDescription>{description}</ProductNameDescription>
          <DescriptionContent>{content}</DescriptionContent>
        </>
      ) : (
        <ComingSoonMini text="No description yet" />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DescriptionContent = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #919eab;
`;

const ProductName = styled.div`
  color: #212b36;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const ProductNameDescription = styled.div`
  color: #919eab;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export default ShortProductDescription;
