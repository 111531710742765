import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

export const PIRELLI_CATALOG_RULESET: TRule[] = [
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateBridgestoneSpecialMarkings',
  },
  ...EDI_WHEEL_B4_RULESET,
  {
    type: 'rule.replace',
    targetKey: 't.product.euDirectiveNumber',
    matchValue: ['No.'],
    replaceValue: [''],
  },
  ...GLOBAL_RULESET,
];
