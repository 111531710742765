import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { tyrioMUI } from '@tyrio/ui-library';
import hrLocale from 'date-fns/locale/en-US';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import initializeI18n from '@tyrio/intl';
import { appConfig } from '@tyrio/config';

import EN from './locales/en/translation.json';
import HR from './locales/hr/translation.json';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
initializeI18n(appConfig, EN, HR);
// TODO: Localization + Locale combination
root.render(
  <StrictMode>
    <LocalizationProvider locale={hrLocale} dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={tyrioMUI.muiTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </LocalizationProvider>
  </StrictMode>
);
