import styled from '@emotion/styled';
import { FormControlLabel, Grid, Switch } from '@mui/material';

interface AppointmentsCronProps {
  appointmentsCron: boolean;
  setAppointmentsCron: (val: boolean) => void;
}

const AppointmentsCron = (props: AppointmentsCronProps) => {
  const { appointmentsCron, setAppointmentsCron } = props;

  return (
    <Wrapper>
      <Grid display="flex" alignItems="center" justifyContent="center">
        <FormControlLabel
          label="Appointments cron"
          control={
            <Switch
              checked={appointmentsCron}
              onChange={(e) => {
                setAppointmentsCron(e.target.checked);
              }}
            />
          }
        />
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px;
`;

export default AppointmentsCron;
