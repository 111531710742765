import CachedIcon from '@mui/icons-material/Cached';
import { Box, Button, Tooltip } from '@mui/material';
import { TyrioSelectInputOption, useGetPartnerDetails } from '@tyrio/forms';
import { useAuth } from '../../../../../../../src/context/AuthContext';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { usePosCartCtx } from '../../../../../../context/PosCartContext';
import { POSContext } from '../../../../../../context/POSContext';
import { useFilter } from '../../../../../../hooks/useFilter';
import { AddressTextField } from '../../../../../customer-orders/components/AddressCard/AddressTextField';
import { InputPhone } from '../../../../../customer-orders/components/AddressCard/InputPhone';
import { SelectList } from '../../../../../supplier-form/helpers/components';
import {
  companyInputFields,
  customerInputFields,
} from '../../../../constants/cart-customer-constants';
import { useGetCountries } from '../../../../query/get-countries';
import {
  CustomerFormWrapper,
  RowWrapper,
} from '../../../../styles/CustomerForm.style';

interface CustomerFormProps {
  activeSwitch: boolean;
  setActiveSwitch: Dispatch<SetStateAction<boolean>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

export const CustomerForm = ({
  activeSwitch,
  setIsDirty,
}: CustomerFormProps) => {
  // CONTEXT DATA
  const { client } = useAuth();
  const { customerDetails, setCustomerDetails, input, setInput } =
    usePosCartCtx();
  const { selectedCustomer, setSelectedCustomer } = useContext(POSContext);
  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);
  const [shouldDisableEdit, setShouldDisableEdit] = useState(
    input.isCustomerEditDisabled
  );
  const { filterValues, setFilterValue } = useFilter();

  //   CONSTANTS
  const activeForm = activeSwitch ? companyInputFields : customerInputFields;

  //   QUERY
  const { findCountryCode } = useGetCountries(setCountries, countries);

  //   FUNCTIONS
  const handleChange = (id: string, value: string) => {
    setIsDirty(true);
    setCustomerDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const { data, refetch } = useGetPartnerDetails(
    customerDetails['generateVat']
  );

  const handleUpdatePartnerData = () => {
    refetch();
    if (data) {
      setShouldDisableEdit(true);

      setCustomerDetails({
        first_name: '',
        last_name: '',
        country: 'HR',
        address: data.sjediste.ulica + ' ' + data.sjediste.kucni_broj,
        zip_code: data.sjediste.sifra_naselja.toString(),
        city: data.sjediste.naziv_naselja,
        mobile_phone: '',
        email: '',
        passport: '',
        customer_remark: '',
        internal_remark: '',
        company_name: data.tvrtka.ime,
        vat: customerDetails['generateVat'],
      });
      setInput((prevState) => ({
        ...prevState,
        isCustomerSwitchActive: customerDetails['vat'] ? true : false,
        isTyrioCustomer: false,
        isSudRegCustomer: customerDetails['vat'] ? true : false,
      }));
      setSelectedCustomer(null);
      setFilterValue({
        ...filterValues,
        customer: undefined,
      });
    } else {
      toast.error(
        'Error when fetching company data. Please check if the OIB / Vat number is correct!',
        {
          position: 'bottom-right',
          autoClose: 1000,
          theme: 'light',
        }
      );
      setCustomerDetails({
        ...customerDetails,
        generateVat: '',
      });
    }
  };

  return (
    <CustomerFormWrapper>
      {activeForm.map((fields, idx) => {
        return (
          <RowWrapper key={idx}>
            {fields.map((field, idx) => {
              if (field.id === 'mobile_phone')
                return (
                  <Box
                    sx={{ width: field.customWidth }}
                    key={`${field.id}-${idx}`}
                  >
                    <InputPhone
                      value={customerDetails[field.id] ?? ''}
                      handleChange={(value) =>
                        handleChange('mobile_phone', value)
                      }
                      isValid={field.helperText === ''}
                      disabled={field.disabled}
                      defaultCode={
                        findCountryCode(customerDetails['country']) ?? 'hr'
                      }
                    />
                  </Box>
                );
              else if (field.id === 'country')
                return (
                  <Box
                    sx={{ width: field.customWidth }}
                    key={`${field.id}-${idx}`}
                  >
                    <SelectList
                      label="Country"
                      value={
                        customerDetails['country'] ??
                        client?.address?.countryId ??
                        ''
                      }
                      dropdownData={countries}
                      // disabled={
                      //   (selectedCustomer !== null && activeSwitch) ||
                      //   field.disabled
                      // }
                      onChange={({ target: { name, value } }) => {
                        handleChange(name, value);
                      }}
                      helperText={field.helperText}
                      name={field.id}
                    />
                  </Box>
                );
              else
                return (
                  <Box
                    sx={{
                      width: field.customWidth,
                      display: 'flex',
                      borderBottom:
                        field.id === 'generateVat' ? '1px solid #DFE3E8' : '',
                      paddingBottom: field.id === 'generateVat' ? '15px' : '',
                      marginBottom: field.id === 'generateVat' ? '7px' : '',
                    }}
                    key={`${field.id}-${idx}`}
                  >
                    <AddressTextField
                      id={field.id}
                      placeholder={field.placeholder}
                      value={customerDetails[field.id] ?? ''}
                      handleChange={({ target: { id, value } }) => {
                        if (field.id === 'generateVat')
                          handleChange(id, value.toString().trim());
                        else handleChange(id, value);
                      }}
                      disabled={
                        (field?.disabled &&
                          activeSwitch &&
                          selectedCustomer !== null) ||
                        (field.disabled && shouldDisableEdit)
                      }
                      customPadding={'16px 8px'}
                      topLabelBorder={5}
                      helperText={field?.helperText}
                      required={field.required}
                      fontSize={'16px'}
                      fontWeight={400}
                      error={field.helperText !== '' && field.id !== 'passport'}
                    />
                    {field.id === 'generateVat' && (
                      <Tooltip title={'Fill your company data'}>
                        <Button
                          sx={{ marginLeft: '10px;' }}
                          onClick={() => {
                            if (customerDetails['generateVat'])
                              handleUpdatePartnerData();
                          }}
                        >
                          <CachedIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                );
            })}
          </RowWrapper>
        );
      })}
    </CustomerFormWrapper>
  );
};
