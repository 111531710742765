import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { QueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

export const useCreatePaymentMethod = (queryClient: QueryClient) => {
  const history = useHistory();
  const { data, mutate } = useMutation(
    ['create_payment_method'],
    (body: DBPaymentTypesApi['create']['requestBody']) =>
      api.fetch<DBPaymentTypesApi['create']>('create_payment_method', {
        ...body,
      }),
    {
      onSuccess: (res) => {
        queryClient.refetchQueries('get_payment_method');
        history.push(`/dashboard/company-settings/payment-method/${res.id}`);
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully created payment method.'
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Payment method',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  return { data, createPayment: mutate };
};
