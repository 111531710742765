import styled from '@emotion/styled';
import {
  CategoryLevelsOld,
  generateColorOld,
} from '../ProductDetails/ProductComponents/PerformanceCategory';
interface EprelClassOldInterface {
  width: string;
  category: CategoryLevelsOld;
  productClass: CategoryLevelsOld;
  transparent?: boolean;
}

const EprelClassOld = ({
  width,
  category,
  productClass,
  transparent,
}: EprelClassOldInterface) => {
  return (
    <Container>
      <ClassWrapper>
        <MainBox
          color={transparent ? 'white' : generateColorOld(category)}
          width={width}
          transparent={transparent}
        >
          <div>{category}</div>
        </MainBox>
        <SideBox
          transparent={transparent}
          style={{
            background: transparent
              ? 'transparent'
              : generateColorOld(category),
          }}
        ></SideBox>
      </ClassWrapper>
      {productClass === category && (
        <SelectedWrapper>
          <SelectedStart />
          <SelectedClass>{productClass}</SelectedClass>
        </SelectedWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 3px;
  justify-content: space-between;
  position: relative;
  font-family: Helvetica, Sans-Serif;
`;

const ClassWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: 1.5px dashed black;
  margin-bottom: 7px;
`;

const SelectedWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  margin-top: -18px;
`;

const SelectedStart = styled.div`
  width: 18px;
  height: 37px;
  border-top: 18.5px solid transparent;
  border-right: 15px solid black;
  border-bottom: 18.5px solid transparent;
`;

const SelectedClass = styled.div`
  height: 37px;
  width: 37px;
  text-align: center;
  background: black;
  text-transform: uppercase;
  line-height: 37px;
  font-size:33px;
  font-weight: 600;
  color: white;
`;

const MainBox = styled.div<{
  color: string;
  width?: string;
  transparent?: boolean;
}>`
  width: ${(props) => props.width};
  height: 19px;
  display: flex;
  padding-left:${(props) => (props.transparent ? '0px' : '7px')}
  align-items: center;
  color: ${(props) => (props.transparent ? 'black' : 'white')};
  background: ${(props) => props.color};
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: ${(props) => !props.transparent && '0 0 2px #000'};
  line-height: 19px;
  justify-content: ${(props) => (props.transparent ? 'center' : 'flex-end')};
  border: ${(props) => (props.transparent ? 0 : ' 1px solid black')};
  border-right: 0;
  margin-top: -10px;
  z-index: 99;
`;

const SideBox = styled.div<{
  transparent?: boolean;
}>`
  width: 13px;
  height: 13px;
  border: 1px solid black;
  border-left: 0;
  border-bottom: 0;
  border: ${(props) => props.transparent && 0};
  transform: rotate(45deg);
  z-index: 9;
  margin-top: -7px;
  margin-left: -6px;
`;

export default EprelClassOld;
