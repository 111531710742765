import * as z from "zod"
import { DBCurrency } from ".prisma/client"
import { CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel } from "./index"

export const DBSupplierClientPriceCalculationSettingsModel = z.object({
  id: z.string(),
  supplierClientSettingsId: z.string(),
  supplierCurrency: z.nativeEnum(DBCurrency),
  ecoTax: z.boolean(),
  rebate: z.number().nullish(),
  yearlyBonus: z.number().nullish(),
})

export interface CompleteDBSupplierClientPriceCalculationSettings extends z.infer<typeof DBSupplierClientPriceCalculationSettingsModel> {
  supplierClientSettings: CompleteDBSupplierClientSettings
}

/**
 * RelatedDBSupplierClientPriceCalculationSettingsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierClientPriceCalculationSettingsModel: z.ZodSchema<CompleteDBSupplierClientPriceCalculationSettings> = z.lazy(() => DBSupplierClientPriceCalculationSettingsModel.extend({
  supplierClientSettings: RelatedDBSupplierClientSettingsModel,
}))
