import {
  LandingPage,
  SearchableVerticalList,
  SearchableVerticalListDataType,
  SearchableVerticalListDataProps,
} from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDebounce } from '../../../hooks/useDebounce';
import { ExpandableLayout } from '../../../layouts/ExpandableLayout';
import routeNames from '../../../lib/routeNames';
import { has, isNumber } from 'lodash';
import { useGetServiceCategory } from './query/get-service-category';
import { useAuth } from '../../../context/AuthContext';
import { SettingsPayload } from '@tyrio/dto';
import { ConfigurationRequired } from './ConfigurationRequired';

interface ServicesProps {
  children: React.ReactNode;
}

const Services = ({ children }: ServicesProps) => {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const { user } = useAuth();

  const pricePerHour = (
    user?.client?.generalSettings as unknown as SettingsPayload
  )?.pricePerHour;

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [, setSelected] = useState<Record<string, boolean>>({});

  const debouncedSearch = useDebounce(search, 500);
  const pageSize = 10;

  const { data, isFetching, isError } = useGetServiceCategory(
    debouncedSearch,
    page,
    pageSize,
    undefined
  );

  const handleClick = (
    type: string,
    item?: SearchableVerticalListDataProps
  ) => {
    if (type === 'itemClicked' && item !== undefined) {
      if (has(item, 'children')) {
        history.push(`/dashboard/services-category/${item.id}`);
      } else
        history.push(`/dashboard/services-category/${item.id}?services=true`);
    }
    if (type === 'new') {
      history.push(`/dashboard/services-category/new`);
    }
  };

  useEffect(() => {
    setPage(1);
    if (location.pathname === path) setSelected({});
  }, [debouncedSearch, location.pathname, path]);

  return (
    <div>
      {!isNumber(pricePerHour) ? (
        <ConfigurationRequired />
      ) : (
        <ExpandableLayout
          sidebarComponent={
            <SearchableVerticalList
              header="Services"
              type={SearchableVerticalListDataType.WITH_CHILDREN}
              data={
                data?.data.map((item) => ({
                  id: item.id,
                  name: item.name,
                  children:
                    item.services?.map((i) => ({
                      id: i.id,
                      name: i.name,
                    })) || [],
                })) || []
              }
              path={routeNames.dashboard()}
              isFetching={isFetching}
              isError={isError}
              onSearchChange={setSearch}
              onCreateNew={() => handleClick('new')}
              handleItemClick={(item) => {
                handleClick('itemClicked', item);
              }}
              onChangePage={(page) => setPage(page)}
              count={data?.count}
              pageNumber={page}
            />
          }
          landingPage={
            <LandingPage
              title={'Welcome to Services'}
              subtitle={'Choose services from the list or create new.'}
              buttonText={'Add new service'}
              onClick={() => handleClick('new')}
            />
          }
        >
          {children}
        </ExpandableLayout>
      )}
    </div>
  );
};

export default Services;
