import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

interface HeaderIconProps {
  title: string;
  icon: string;
  onClick: () => void;
}

export const HeaderIcon = ({ title, icon, onClick }: HeaderIconProps) => {
  return (
    <Tooltip
      title={title}
      componentsProps={{
        tooltip: { style: { background: '#1B2130' } },
        arrow: { style: { color: '#1B2130' } },
      }}
      followCursor
      arrow
    >
      <Image src={icon} alt="basic-calculation" onClick={onClick} />
    </Tooltip>
  );
};

const Image = styled.img`
  margin-right: 8px;
  cursor: pointer;
  height: 30px;
  width: 30px;
`;
