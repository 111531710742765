const generateRoute = (
  unsafeRoute: string,
  params: Record<string, string | null> = {}
) => {
  let route = unsafeRoute;
  Object.keys(params).forEach((key) => {
    if (!params[key]) route = route.replace(`/:${key}`, '');
    else route = route.replace(`:${key}`, params[key] as string);
  });

  return route;
};
const routeNames = {
  root: (params?: Record<string, string>): string => generateRoute('/', params),
  signin: (params?: Record<string, string>): string =>
    generateRoute('/sign-in', params),
  signup: (params?: Record<string, string>): string =>
    generateRoute('/sign-up', params),
  createClient: (params?: Record<string, string>): string =>
    generateRoute('/create-client', params),
  noPermission: (params?: Record<string, string>): string =>
    generateRoute('/no-permission-page', params),
  dashboard: (params?: Record<string, string>): string =>
    generateRoute('/dashboard', params),
  dashboardOverview: (params?: Record<string, string>): string =>
    generateRoute('/dashboard/overview', params),
  dashboardAdmin: (params?: Record<string, string>): string =>
    generateRoute('/dashboard/admin', params),
  dashboardSupplier: (params?: Record<string, string>): string =>
    generateRoute('/dashboard/supplier', params),
  editAdmin: (params: Record<string, string> = { id: '' }): string =>
    generateRoute('/admin/:id', params),
  roles: (params?: Record<string, string>): string =>
    generateRoute('/dashboard/roles', params),
  categories: (params?: Record<string, string>): string =>
    generateRoute('/dashboard/categories', params),
  editCategories: (params?: Record<string, string>): string =>
    generateRoute('/dashboard/categories/:id', params),
  products: (params?: Record<string, string>): string =>
    generateRoute('/dashboard/products/:productModelUid', params),
  editProduct: (params: Record<string, string> = { id: '' }): string =>
    generateRoute('/dashboard/products/:productModelUid/:id', params),
  editCategory: (params: Record<string, string> = { id: '' }): string =>
    generateRoute('/dashboard/category/:id', params),
};

export default routeNames;
