import { TRule } from '../../typings';

export const GUNDLACH_STOCK_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN code']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Stock_NL_CM']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Manufacturer code']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
];
