import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import InfoIcon from '@mui/icons-material/Info';

export const Info = ({
  title,
  onClick,
}: {
  title?: string;
  onClick?: () => void;
}) => {
  return (
    <Div
      onClick={() => {
        onClick && onClick();
      }}
    >
      <InfoIcon color="info" />
      <Typography
        color={tyrioUI.colors.lightBlue}
        fontWeight={400}
        fontSize={14}
      >
        {title ?? ''}
      </Typography>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
`;
