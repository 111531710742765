import api from '@tyrio/api-factory';
import { DBStockInListApi, StockInLineItem } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { queryClient } from '../../../../../query-client';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';

export const useMoveStockInItems = (
  id: string,
  items: StockInLineItem[],
  setOpen: Dispatch<SetStateAction<boolean>>,
  setCheckedLineItems: Dispatch<SetStateAction<StockInLineItem[]>>
) => {
  const { mutateAsync, isLoading } = useMutation(
    () => {
      return api.fetch<DBStockInListApi['moveItems']>('move_stock_in_items', {
        id,
        items,
      });
    },
    {
      mutationKey: 'move_stock_in_items',
      onSuccess: () => {
        ToastHelper.showToast(
          'Stock IN',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE,
          'Items successfully moved to new stock in!'
        );

        queryClient.refetchQueries('get_stock_in_list');
        queryClient.refetchQueries('get_stock_in_by_id');

        setOpen(false);
        setCheckedLineItems([]);
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error?.response?.data?.error.name;
          ToastHelper.showToast(
            'Product',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'Error moving items!'
          );
        }
        throw error;
      },
    }
  );

  return { moveStockInItems: mutateAsync, isLoading };
};
