import styled from '@emotion/styled';
import {
  CircularProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Typography,
} from '@mui/material';
import api from '@tyrio/api-factory';
import { DBStockListApi } from '@tyrio/dto';
import { Header, tyrioUI } from '@tyrio/ui-library';
import { SingleTableRow } from '@tyrio/wms-ui-library';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import NoDataFound from '../../components/NoData/NoDataFound';
import { useAuth } from '../../context/AuthContext';
import { StockListContext } from '../../context/StockListContext';
import { useWS } from '../../context/WSContext';
import { StockWrapper } from './components/Branches/SingleStorage';
import { getReservedAndUpcomingValues } from './components/Cart/helper/socket-helper';
import { AddStockListItem } from './components/modals/AddStockListItemModal';
import Filter from './Filter/Filter';
import FilterFooter from './Filter/FilterFooter';
import { SidebarFilter } from './Filter/SidebarFilter';
import { ImportStockList } from './components/modals/ImportStockList';

interface StockListOverviewProps {
  setShouldDisableMenuItems: (item: boolean) => void;
}

export const StockListOverview = ({
  setShouldDisableMenuItems,
}: StockListOverviewProps) => {
  const { user } = useAuth();
  const { ref, inView } = useInView();

  const ws = useWS();
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const [open, setOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const {
    filterValues,
    selectedTableRow: selectedProduct,
    setSelectedTableRow: setSelectedProduct,
    setActiveStep,
    activeStep,
    setActiveDrawer,
    onlyAvailable,
    setOutboundOrders,
    input,
  } = useContext(StockListContext);

  const {
    data: stockProductData,
    isFetching: isFetchingStockProducts,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['get_stock_list_products', { filterValues }],
    async ({ pageParam = 1 }) => {
      return api.fetch<DBStockListApi['list']>('get_stock_list_products', {
        filters: {
          ...filterValues,
        },
        page: pageParam,
        pageSize: 15,
      });
    },
    {
      enabled: true, // enable initial fetch
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.length > 0 ? pages.length + 1 : undefined;
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const isEmptyStockData = useMemo(
    () => stockProductData?.pages?.[0]?.length === 0,
    [stockProductData]
  );

  const selectedBranch = Number(filterValues?.['warehouseId']);
  const isSelectedProductLocationCorrect =
    selectedProduct?.branchId === selectedBranch;

  useEffect(() => {
    if (isEmptyStockData) {
      setOutboundOrders([]);
      setShouldDisableMenuItems(true);
      setSelectedProduct(null);
    }

    if (
      !isEmptyStockData &&
      !isFetchingStockProducts &&
      !isFetchingNextPage &&
      stockProductData?.pages
    ) {
      const firstProduct = stockProductData.pages?.[0][0];

      setShouldDisableMenuItems(false);

      if (selectedProduct === null) {
        if (input.selectedTableRow) {
          setSelectedProduct(input.selectedTableRow);
          setActiveStep(input.activeStep);
        } else {
          setSelectedProduct(firstProduct);
          if (activeStep !== 'DETAILS') setActiveStep('DETAILS');
        }
      }
    }
  }, [
    isEmptyStockData,
    stockProductData?.pages,
    input.selectedTableRow,
    input.activeStep,
    selectedProduct,
    setSelectedProduct,
    activeStep,
    setActiveStep,
    isFetchingNextPage,
    isFetchingStockProducts,
    setShouldDisableMenuItems,
    setOutboundOrders,
    isSelectedProductLocationCorrect,
  ]);

  useEffect(() => {
    // only refetch when user is near the bottom of the table
    // and when there's more data to fetch
    if (inView && hasNextPage && !isFetchingNextPage && tableBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const getValues = useCallback(
    (key: string) => {
      const cartItems = ws?.state.cart?.userCart;
      const warehouseId = Number(filterValues?.['warehouseId'] as string);

      const { reserved, upcoming } = getReservedAndUpcomingValues(
        cartItems,
        key,
        warehouseId
      );

      return { reserved, upcoming };
    },
    [ws, filterValues]
  );

  const renderFilters = () => {
    return (
      <FilterHeaderWrapper>
        <FilterWrapper>
          <Filter onOpen={() => setOpen(true)} />
        </FilterWrapper>
      </FilterHeaderWrapper>
    );
  };

  return (
    <Wrapper>
      <Header
        title="Stock List"
        addButton={() => setIsAddModalOpen(true)}
        importButton={() => setIsImportModalOpen(true)}
      />

      <SidebarFilter isOpen={open} setOpen={setOpen} />

      {isAddModalOpen && (
        <AddStockListItem open={isAddModalOpen} setOpen={setIsAddModalOpen} />
      )}

      {isImportModalOpen && (
        <ImportStockList
          isOpen={isImportModalOpen}
          setOpen={setIsImportModalOpen}
        />
      )}

      <TableWrapper id="stock-list-table">
        <Table
          id="stock_list_table"
          style={{
            whiteSpace: 'nowrap',
            minWidth: !isEmptyStockData ? '1050px' : '',
            marginBottom: '70px',
          }}
        >
          {!isEmptyStockData ? (
            <TableHeaderWrapper>
              {renderFilters()}
              <StockWrapper onlyAvailable={onlyAvailable}>
                {!onlyAvailable && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ color: tyrioUI.colors.black.B90 }}
                    >
                      On stock
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: tyrioUI.colors.black.B90 }}
                    >
                      Reserved
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: tyrioUI.colors.black.B90 }}
                    >
                      Upcoming
                    </Typography>
                  </>
                )}

                <Typography
                  variant="body2"
                  sx={{ color: tyrioUI.colors.black.B90 }}
                >
                  Available
                </Typography>
              </StockWrapper>
            </TableHeaderWrapper>
          ) : (
            <div style={{ borderBottom: '1px solid #dfe3e8' }} />
          )}

          <TableBody ref={tableBodyRef} id="stock-list-table">
            {isFetchingStockProducts && !isFetchingNextPage ? (
              <CircularProgress />
            ) : isEmptyStockData ? (
              <NoDataFound />
            ) : (
              stockProductData?.pages.map((page, index) => (
                <Fragment key={`Data-part-${index}`}>
                  {page.map((product, idx) => {
                    let { reserved, upcoming } = getValues(
                      product.ean + '_' + product.dot
                    );

                    reserved += product.reserved;
                    upcoming += product.upcoming;

                    return (
                      <SingleTableRow
                        data={product}
                        reserved={reserved > 0 ? reserved : product.reserved}
                        upcoming={upcoming > 0 ? upcoming : product.upcoming}
                        userId={user?.id ?? ''}
                        key={`${product.id} - ${idx}`}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        setActiveStep={setActiveStep}
                        setActiveDrawer={setActiveDrawer}
                        onlyAvailable={onlyAvailable}
                      />
                    );
                  })}
                </Fragment>
              ))
            )}
          </TableBody>

          <div ref={ref} />

          {!isEmptyStockData && isFetchingNextPage && (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}

          <TableFooter>
            <FilterFooter />
          </TableFooter>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100%;
`;

const FilterHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterWrapper = styled.div`
  width: 100%;
`;

const LoaderWrapper = styled.div`
  margin-top: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  align-items: center;
`;

const TableHeaderWrapper = styled(TableHead)`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-bottom: 1px solid #dfe3e8;
  background: white;
  position: sticky;
  top: 0;
`;

const TableWrapper = styled.div`
  height: calc(100svh - 230px);
  overflow-y: auto;
  margin-bottom: 64px;
`;
