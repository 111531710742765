/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Overview } from './Overview';
import { Details } from './Details';
import { Upload } from './Upload';

const FieldComponent: any = {
  UPLOAD: Upload,
  WAREHOUSE: Overview,
  DETAILS: Details,
};

export const Steps = (step: string) => {
  if (Object.keys(FieldComponent).find((a) => a === step) === undefined) {
    console.log('Error - Step passed in is not expected!');
    return;
  }

  if (step in Object.keys(FieldComponent)) {
    console.log('Error - Step passed in is not expected!');
  }
  return React.createElement(FieldComponent[step]);
};
