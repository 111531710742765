import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { WmsIcon } from '@tyrio/wms-ui-library';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  // padding: 16px;
  width: 100%;
  background: white;
  height: 100%;
  border-radius: 16px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const AnimationDiv = styled.div`
  background: white;
  height: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  animation: fadein 0.7s;
  animation-iteration-count: 1;
  width: 100%;
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Text = styled(Typography)`
  color: #b9c4ce;
  font-size: 14px;

  &:hover {
    color: #db1e1e;
  }
`;

export const WmsIconStyled = styled(WmsIcon)`
  width: 16px;
  height: 16px;
  color: #b9c4ce;

  &:hover {
    color: #db1e1e;
  }
`;

export const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-bottom: 18px;
  cursor: pointer;
  gap: 14px;
  padding-right: 38px;

  &:hover {
    #delete_text,
    #delete_icon {
      color: #db1e1e;
    }
  }
`;

export const ChildrenWrapper = styled.div`
  height: 97%;
  overflow-y: auto;
  position: relative;
  padding: 0 8px;
`;
