import React from 'react';
import styled from '@emotion/styled';

interface StickyTableTotalCellProps {
  price: number;
  qty: number;
  availableNumber?: number;
  hasBackorder?: boolean;
  isHovered?: boolean;
  shouldSetBackgroundToWhite?: boolean;
  hideTotal?: boolean;
}
export const StickyTableTotalCell = ({
  price,
  qty,
  availableNumber,
  hideTotal = false,
}: StickyTableTotalCellProps) => {
  const shouldBeGreen = () => {
    return hideTotal || qty === availableNumber;
  };
  return (
    <Wrapper
      style={{
        backgroundColor: shouldBeGreen() ? '#e0f4ea' : '#F8CECE',
      }}
    >
      <PriceText>
        {price.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </PriceText>
      <QtyText>
        {qty}
        {!hideTotal && !!availableNumber && <>/{availableNumber}</>} kom
      </QtyText>
    </Wrapper>
  );
};

const PriceText = styled.p`
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 28px;
  font-weight: 500;
`;

const QtyText = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-weight: 400;
`;

const Wrapper = styled.div`
  background: #e0f4ea;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
