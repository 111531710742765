import { CartStockListItem } from '@tyrio/dto';
import { FEATURE_FLAGS, isFlagEnabled } from '@tyrio/feature-flags';
import { useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles.scss';
import { queryClient } from './query-client';
import AuthProvider from './src/context/AuthContext';
import CartProvider, { IAvailable } from './src/context/CartContext';
import PosCartProvider from './src/context/PosCartContext';
import { Timer } from './src/context/TimerClass';
import { useTimerContextData } from './src/context/TimerContext';
import { WSProvider } from './src/context/WSContext';
import { useLocalStorage } from './src/hooks/useLocalStorage';
import { RootComponent } from './src/root';
import { rootStore } from './store';

export function App() {
  // STOCK LIST
  const [input, setInput] = useLocalStorage<Record<string, IAvailable[]>>(
    '@@aI',
    {}
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] =
    useState<boolean>(false);
  const [availableItems, setAvailableItems] =
    useState<Record<string, IAvailable[]>>(input);
  const [unavailableQuantitesData, setUnavailableQuantitesData] = useState<
    Record<string, CartStockListItem | number>[]
  >([]);

  const stockListTimerCtx = useTimerContextData(setOpenSessionExpiredModal);
  const stockListTimer = new Timer(stockListTimerCtx, '@TYR_INT');

  // POS
  const [openPosSessionExpiredModal, setOpenPosSessionExpiredModal] =
    useState<boolean>(false);

  const posTimerCtx = useTimerContextData(setOpenPosSessionExpiredModal);
  const posTimer = new Timer(posTimerCtx, '@TYR_POS_INT');

  useEffect(() => {
    setInput(availableItems);
  }, [availableItems, setInput]);

  return (
    <ReduxProvider store={rootStore}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <WSProvider>
            <PosCartProvider
              value={{
                openSessionExpiredModal: openPosSessionExpiredModal,
                setOpenSessionExpiredModal: setOpenPosSessionExpiredModal,
                timer: posTimer,
              }}
            >
              <CartProvider
                value={{
                  openModal,
                  setOpenModal,
                  openSessionExpiredModal,
                  setOpenSessionExpiredModal,
                  availableItems,
                  setAvailableItems,
                  unavailableQuantitesData,
                  setUnavailableQuantitesData,
                  timer: stockListTimer,
                }}
              >
                <RootComponent />
                <ToastContainer />

                {isFlagEnabled(FEATURE_FLAGS.REACT_QUERY_DEVTOOLS_ENABLED) && (
                  <ReactQueryDevtools position="bottom-right" />
                )}
              </CartProvider>
            </PosCartProvider>
          </WSProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ReduxProvider>
  );
}

export default App;
