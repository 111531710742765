import { DBDeliveryLocation, DeliveryType } from '@prisma/client';
import { z } from 'zod';

const zoneSettingsSchema = z.object({
  name: z.string().optional(),
  daysToDeliver: z
    .number({ required_error: 'This field is required!' })
    .min(1, { message: 'This field is required!' })
    .default(0),
  maxDaysToDeliver: z
    .number({ required_error: 'This field is required!' })
    .min(1, { message: 'This field is required!' })
    .default(0),
  minOrderQuantity: z
    .number({ required_error: 'This field is required!' })
    .min(1, { message: 'This field is required!' })
    .default(0),
  maxPackageWeight: z
    .number({ required_error: 'This field is required!' })
    .min(1, { message: 'This field is required!' })
    .default(0),
  cashOnDelivery: z.boolean().optional(),
  cardOnDelivery: z.boolean().optional(),
  zipCodes: z.string().optional(),
});

const priceListSchema = z.object({
  deliveryPrice: z.number().default(0).optional(),
  freeDelivery: z.boolean().optional().default(false),
  minOrderQuantity2: z.number().optional().default(0),
  minOrderAmount: z.number().optional().default(0),
});

const surchargesSchema = z.object({
  CODSurcharge: z.string().optional(),
  CCODSurcharge: z.string().optional(),
  minQuantitySurcharge: z.number().optional(),
});

const zoneSchema = z.array(
  z.object({
    zoneSettings: zoneSettingsSchema,
    priceList: priceListSchema,
    surcharges: surchargesSchema.optional(),
  })
);

export const DeliveryMethodValidation = z
  .object({
    code: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    name: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    description: z.string().optional(),
    deliveryTypeEnum: z
      .enum(
        [
          DeliveryType.WMS_COLLECTION,
          DeliveryType.WMS_DISPATCH,
          DeliveryType.WMS_SHIPPING,
          DeliveryType.WMS_FITTING,
          '',
        ],
        { required_error: 'This field is required!' }
      )
      .or(z.string().max(1))
      .superRefine((value, ctx) => {
        if (value === '') {
          ctx.addIssue({
            message: 'This field is required!',
            code: z.ZodIssueCode.custom,
          });
        }
      }),
    sortOrder: z.string().optional(),
    dbDeliveryCouriers: z
      .array(z.object({ label: z.string(), value: z.string() }))
      .optional(),

    deliveryLocation: z
      .enum([DBDeliveryLocation.DOMESTIC, DBDeliveryLocation.INTERNATIONAL, ''])
      .or(z.string().max(1).default(''))
      .optional(),
    daysToDeliver: z.number().optional().default(0),
    maxDaysToDeliver: z.number().optional().default(0),
    minOrderQuantity: z.number().optional().default(0),
    maxPackageWeight: z.number().optional().default(0),
    cashOnDelivery: z.boolean().optional().default(false),
    cardOnDelivery: z.boolean().optional().default(false),
    blacklistZIPCodes: z.string().optional().default(''),
    whitelistZIPCodes: z.string().optional().default(''),
    deliveryPrice: z.number().optional().default(0),
    freeDelivery: z.boolean().optional().default(false),
    minOrderQuantity2: z.number().optional().default(0),
    minOrderAmount: z.number().optional().default(0),
    CODSurcharge: z.string().optional().default(''),
    CCODSurcharge: z.string().optional().default(''),
    minQuantitySurcharge: z.number().optional().default(0),
    zones: zoneSchema,
  })
  .refine(
    (data) =>
      !(
        data.deliveryTypeEnum === 'WMS_SHIPPING' && data.deliveryLocation === ''
      ),
    { message: 'This field is required!', path: ['deliveryLocation'] }
  )
  .refine(
    (data) =>
      !(
        data.daysToDeliver === 0 &&
        (data.deliveryLocation === DBDeliveryLocation.DOMESTIC ||
          data.deliveryTypeEnum !== DeliveryType.WMS_SHIPPING) &&
        data.deliveryTypeEnum !== DeliveryType.WMS_FITTING
      ),
    { message: 'This field is required!', path: ['daysToDeliver'] }
  )
  .refine(
    (data) =>
      !(
        data.maxDaysToDeliver === 0 &&
        (data.deliveryLocation === DBDeliveryLocation.DOMESTIC ||
          data.deliveryTypeEnum !== DeliveryType.WMS_SHIPPING) &&
        data.deliveryTypeEnum !== DeliveryType.WMS_FITTING
      ),
    { message: 'This field is required!', path: ['maxDaysToDeliver'] }
  )
  .refine(
    (data) =>
      !(
        data.minOrderQuantity === 0 &&
        (data.deliveryLocation === DBDeliveryLocation.DOMESTIC ||
          data.deliveryTypeEnum !== DeliveryType.WMS_SHIPPING) &&
        data.deliveryTypeEnum !== DeliveryType.WMS_FITTING
      ),
    { message: 'This field is required!', path: ['minOrderQuantity'] }
  )
  .refine(
    (data) =>
      !(
        data.maxPackageWeight === 0 &&
        (data.deliveryLocation === DBDeliveryLocation.DOMESTIC ||
          data.deliveryTypeEnum !== DeliveryType.WMS_SHIPPING) &&
        data.deliveryTypeEnum !== DeliveryType.WMS_FITTING
      ),
    { message: 'This field is required!', path: ['maxPackageWeight'] }
  )
  .superRefine((data, ctx) => {
    if (data.whitelistZIPCodes && data.blacklistZIPCodes) {
      const blacklistCodes = data.blacklistZIPCodes.split(',');
      const whitelistCodes = data.whitelistZIPCodes.split(',');

      if (blacklistCodes.some((item) => whitelistCodes.includes(item))) {
        ctx.addIssue({
          message: 'Blacklist and Whitelist can`t have same zip codes!',
          code: z.ZodIssueCode.custom,
          path: ['blacklistZIPCodes'],
        });
        ctx.addIssue({
          message: 'Blacklist and Whitelist can`t have same zip codes!',
          code: z.ZodIssueCode.custom,
          path: ['whitelistZIPCodes'],
        });
      }
    }
  });
