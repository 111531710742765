import styled from '@emotion/styled/macro';
import { tyrioUI } from '@tyrio/ui-library';
import { Dot, TitleWrapper } from '../Generation';
import { Styles } from '../Modifications';
import { get, startCase } from 'lodash';
import { HeaderText } from '../../../steps/Search/SelectStepper';
import { useVehicleCtx } from '../../../../../context/VehicleContext';
import { Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export const VehicleDetails = () => {
  const {
    selectedBrand,
    selectedModel,
    wheelsApiData,
    generationDetails,
    clearVehicleContext,
  } = useVehicleCtx();
  const value = wheelsApiData ? wheelsApiData[0] : [];

  return (
    <Wrapper>
      <Img src={generationDetails.image ?? ''} alt="car" />

      <TitleWrapper>
        <HeaderText style={Styles.headerStyle}>
          <Span>{selectedBrand?.toUpperCase() ?? ''}</Span>
          <Dot style={DotStyle(tyrioUI.colors.black.B100)} />
          <Span>{startCase(selectedModel ?? '')?.toUpperCase()}</Span>
          <Dot style={DotStyle(tyrioUI.colors.black.B100)} />
          <Span>
            {get(value, 'generation.name', '')} {generationDetails.name}
          </Span>
        </HeaderText>

        <Typography style={TextStyle('B70', 14).textStyle} component={'div'}>
          {get(value, 'engine.capacity', '')}ccm <Dot />{' '}
          {get(value, 'engine.power.kW', '')} kW/{' '}
          {get(value, 'engine.power.PS', '')} PS
        </Typography>

        <Typography style={TextStyle('B100', 20).textStyle} component={'div'}>
          PCD: {get(value, 'technical.bolt_pattern', '')}{' '}
          <Dot style={DotStyle(tyrioUI.colors.black.B100)} />
          CB:
          {get(value, 'technical.centre_bore', '')}
        </Typography>
      </TitleWrapper>

      <CancelIcon
        color="disabled"
        onClick={clearVehicleContext}
        style={{ cursor: 'pointer' }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${tyrioUI.colors.black.B40};
  border-radius: 8px;
  margin-top: 16px;
  padding: 16px;
  justify-content: space-between;
`;

const Img = styled.img`
  width: 100px;
  height: 70px;
  object-fit: contain;
`;

const Span = styled.span`
  text-align: center;
`;

const TextStyle = (color?: 'B100' | 'B70', fontSize?: number) => {
  return {
    textStyle: {
      alignItems: 'center',
      display: 'flex',
      color: tyrioUI.colors.black[color ?? 'B100'],
      fontSize: `${fontSize}px`,
    },
  };
};

const DotStyle = (background: string) => {
  return { background };
};
