import api from '@tyrio/api-factory';
import { DBTrackInfoApi, OrderTypesEnum } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetTrackingInfo = (
  orderId: string,
  orderType: OrderTypesEnum
) => {
  const { data, refetch } = useQuery(
    [orderId],
    () =>
      api.fetch<DBTrackInfoApi['getOrderTrackingInfo']>(
        'get_order_track_info',
        {
          orderId,
          orderType,
        }
      ),
    {
      enabled: !!orderId,
    }
  );

  return {
    trackingInfoData: data,
    refetchTrackingInfo: refetch,
  };
};
