import { Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import TextField from '@mui/material/TextField/TextField';
import {
  BRANDS_LIST,
  BUDGET_BRANDS,
  ECONOMY_BRANDS,
  FAVORITE_SIZES,
  MID_BRANDS,
  PREMIUM_BRANDS,
  SEASON_DROPDOWN_DATA,
} from '@tyrio/shared-vars';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { set } from 'lodash';
import { useContext, useState } from 'react';
import { PriceComparisonContext } from '../context';
import { CategoryInput } from '../elements/CategoryInput';

export const DimensionArea = ({
  errors,
  setErrors,
}: {
  errors: Record<string, boolean>;
  setErrors: (val: Record<string, boolean>) => void;
}) => {
  const ctx = useContext(PriceComparisonContext);
  const [brandList, setBrandList] = useState(BRANDS_LIST);

  const getValue = (key: string, isChecked: boolean) => {
    const keys = ['premium', 'mid', 'economy', 'budget'];
    const remainingKeys = keys.filter((k) => k !== key);

    if (!isChecked) setBrandList(BRANDS_LIST);

    const obj = remainingKeys.reduce<Record<string, boolean>>((acc, item) => {
      set(acc, item, false);
      return acc;
    }, {});

    const value = isChecked
      ? {
          [key]: true,
          ...obj,
        }
      : { premium: false, mid: false, economy: false, budget: false };

    return value;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CategoryInput id="categorySelect" />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }}>
          Choose brands for comparison
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value="premium"
          onChange={() => {
            const isPremium = !ctx.data.dimensions?.brandClasses?.['premium'];
            if (isPremium) setBrandList(PREMIUM_BRANDS);

            const value = getValue('premium', isPremium);

            ctx.setInputValue({
              dimensions: {
                ...ctx.data.dimensions,
                brandClasses: {
                  ...value,
                },
                brands: isPremium ? PREMIUM_BRANDS : [],
              },
            });
          }}
          checked={ctx.data.dimensions?.brandClasses?.['premium']}
          control={<Checkbox />}
          label="Premium class"
        />
        <FormControlLabel
          value="mid"
          onChange={() => {
            const isMid = !ctx.data.dimensions?.brandClasses?.['mid'];
            if (isMid) setBrandList(MID_BRANDS);

            const value = getValue('mid', isMid);

            ctx.setInputValue({
              dimensions: {
                ...ctx.data.dimensions,
                brandClasses: {
                  ...value,
                },
                brands: isMid ? MID_BRANDS : [],
              },
            });
          }}
          checked={ctx.data.dimensions?.brandClasses?.['mid']}
          control={<Checkbox />}
          label="Mid class"
        />
        <FormControlLabel
          value="economy"
          onChange={() => {
            const isEconomy = !ctx.data.dimensions?.brandClasses?.['economy'];
            if (isEconomy) setBrandList(ECONOMY_BRANDS);

            const value = getValue('economy', isEconomy);

            ctx.setInputValue({
              dimensions: {
                ...ctx.data.dimensions,
                brandClasses: {
                  ...value,
                },
                brands: isEconomy ? ECONOMY_BRANDS : [],
              },
            });
          }}
          checked={ctx.data.dimensions?.brandClasses?.['economy']}
          control={<Checkbox />}
          label="Economy class"
        />
        <FormControlLabel
          value="budget"
          onChange={() => {
            const isBudget = !ctx.data.dimensions?.brandClasses?.['budget'];
            if (isBudget) setBrandList(BUDGET_BRANDS);

            const value = getValue('budget', isBudget);

            ctx.setInputValue({
              dimensions: {
                ...ctx.data.dimensions,
                brandClasses: {
                  ...value,
                },
                brands: isBudget ? BUDGET_BRANDS : [],
              },
            });
          }}
          checked={ctx.data.dimensions?.brandClasses?.['budget']}
          control={<Checkbox />}
          label="Budget class"
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disableCloseOnSelect
          multiple
          id={'brands'}
          defaultValue={[]}
          disablePortal
          filterSelectedOptions
          options={brandList}
          value={ctx.data.dimensions?.brands || []}
          onChange={(__, value, reason) => {
            ctx.setInputValue(
              {
                ...ctx.data,
                dimensions: {
                  ...ctx.data.dimensions,
                  brands: reason === 'clear' ? [] : value,
                },
              },
              true
            );
          }}
          renderInput={(params) => {
            return <TextField {...params} label={'Brands'} />;
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }}>
          Choose seasons for comparison
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disableCloseOnSelect
          multiple
          id={'season'}
          defaultValue={[]}
          disablePortal
          filterSelectedOptions
          options={SEASON_DROPDOWN_DATA as TyrioSelectInputOption[]}
          value={ctx.data.dimensions?.season || []}
          onChange={(__, value, reason) => {
            ctx.setInputValue(
              {
                ...ctx.data,
                dimensions: {
                  ...ctx.data.dimensions,
                  season: reason === 'clear' ? [] : value,
                },
              },
              true
            );
          }}
          renderInput={(params) => {
            return <TextField {...params} label={'Seasons'} />;
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }}>
          Choose dimensions for comparison
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value="sizeFavourites"
          onChange={() => {
            const isFavorite = !ctx.data.dimensions?.sizeFavourites;

            ctx.setInputValue({
              dimensions: {
                ...ctx.data.dimensions,
                sizeFavourites: isFavorite,
                sizes: isFavorite ? FAVORITE_SIZES : [],
              },
            });
          }}
          checked={ctx.data.dimensions?.sizeFavourites}
          control={<Checkbox />}
          label="Favourites"
        />
        <FormControlLabel
          value="topSellers"
          onChange={() => {
            ctx.setInputValue({
              dimensions: {
                ...ctx.data.dimensions,
                topSellers: !ctx.data.dimensions?.topSellers,
              },
            });
          }}
          checked={ctx.data.dimensions?.topSellers}
          control={<Checkbox />}
          label="Top selling 20 dimensions"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disableCloseOnSelect
          multiple
          id={'dimensions'}
          defaultValue={[]}
          disablePortal
          filterSelectedOptions
          options={ctx.dimensions ?? []}
          loading={ctx.isFetchingDimensions}
          value={ctx.data.dimensions?.sizes || []}
          getOptionLabel={(option) => option || ''}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(__, value, reason) => {
            ctx.setInputValue(
              {
                ...ctx.data,
                dimensions: {
                  ...ctx.data.dimensions,
                  sizes: reason === 'clear' ? [] : value,
                },
              },
              true
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={errors?.['dimensions'] ?? undefined}
                label={'Dimensions *'}
                onError={() => console.log('error')}
                helperText={errors?.['dimensions'] && 'This field is required!'}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value="skipRunFlat"
          onChange={() => {
            ctx.setInputValue({
              dimensions: {
                ...ctx.data.dimensions,
                skipRunFlat: !ctx.data.dimensions?.skipRunFlat,
              },
            });
          }}
          checked={ctx.data.dimensions?.skipRunFlat}
          control={<Checkbox />}
          label="Do not show Run Flat tyres"
        />
      </Grid>
    </Grid>
  );
};
