import styled from '@emotion/styled';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DBBranchType } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBBranchesApi, DBTransferOrderItemCountByStatus } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import { useState } from 'react';
import { useQuery } from 'react-query';
import qs from 'qs';
import TabsFilter from './TabsFilter';
import TransferDatePicker from './TransferDatePicker';
import TransferSearchTypeInput from './TransferSearchTypeInput';

interface TransferOrderTableFiltersProps {
  isSidebarOpen: boolean;
  filters: qs.ParsedQs;
  setFilters: (data: Partial<qs.ParsedQs>) => void;
  setPage: (data: number) => void;
  countOrders: DBTransferOrderItemCountByStatus[];
}

const TransferOrderTableFilters = ({
  filters,
  setFilters,
  setPage,
  countOrders,
  isSidebarOpen,
}: TransferOrderTableFiltersProps) => {
  const [branches, setBranches] = useState<TyrioSelectInputOption[]>([]);

  const isSmallWindowWidth = window.innerWidth < 1600;

  useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const dropdownItems = [{ value: 'all', label: 'All' }];
        data.data
          .filter((item) => item.branchType.includes(DBBranchType.WAREHOUSE))
          .forEach((item) =>
            dropdownItems.push({
              label: item.branchName,
              value: item.id.toString(),
            })
          );

        setBranches(dropdownItems);
      },
    }
  );

  const getSourceDefaultValue = () => {
    if (filters['source']) {
      const selectedBranch = branches.find(
        (el) => el.value === filters['source']
      );
      return { value: filters['source'], label: selectedBranch?.label || '' };
    }
    return { value: 'all', label: 'All' };
  };

  const getDestinationDefaultValue = () => {
    if (filters['destination']) {
      const selectedBranch = branches.find(
        (el) => el.value === filters['destination']
      );
      return {
        value: filters['destination'],
        label: selectedBranch?.label || '',
      };
    }
    return { value: 'all', label: 'All' };
  };

  return (
    <Grid>
      <Grid
        container
        spacing={1.5}
        maxWidth="1200px"
        paddingLeft="16px"
        paddingTop="21px"
        paddingRight="16px"
      >
        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 12 : 4.5}>
          <TransferSearchTypeInput filters={filters} setFilters={setFilters} />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 3 : 2.5}>
          <Autocomplete
            disablePortal
            id="source"
            defaultValue={
              getSourceDefaultValue() as unknown as TyrioSelectInputOption
            }
            getOptionLabel={(option) => {
              if (typeof option === 'string')
                return branches.find((el) => el.value === option)?.label || '';
              else
                return (
                  branches.find((el) => el.value === option.value)?.label || ''
                );
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, newValue) => {
              setFilters({
                ...filters,
                source: newValue?.value.toString() ?? 'all',
              });
            }}
            options={branches}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Source" />}
          />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 3 : 2}>
          <Autocomplete
            disablePortal
            id="destination"
            defaultValue={
              getDestinationDefaultValue() as unknown as TyrioSelectInputOption
            }
            onChange={(event, newValue) => {
              setFilters({
                ...filters,
                destination: newValue?.value.toString() ?? 'all',
              });
            }}
            options={branches}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField {...params} label="Destination" />
            )}
          />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 6 : 3}>
          <TransferDatePicker
            isSidebarOpen={isSidebarOpen}
            filters={filters}
            setFilters={setFilters}
          />
        </Grid>
      </Grid>

      <TabFilterContainer container>
        <TabFilterWrapper item>
          <TabsFilter
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
            countOrders={countOrders}
          />
        </TabFilterWrapper>
      </TabFilterContainer>
    </Grid>
  );
};

const TabFilterContainer = styled(Grid)`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabFilterWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: red;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default TransferOrderTableFilters;
