import styled from '@emotion/styled';
import React from 'react';
import { PageTemplateWrapper } from '../../components/PageTemplate/PageTemplate';

interface DashboardApiKeysProps {
  children: React.ReactNode;
}

const DashboardApiKey = ({ children }: DashboardApiKeysProps) => {
  return (
    <PageTemplateWrapper>
      <Container>{children}</Container>
    </PageTemplateWrapper>
  );
};

const Container = styled.div`
  background: white;
  width: 100%;
  border-radius: 22px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export default DashboardApiKey;
