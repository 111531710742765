import styled from '@emotion/styled';
import { useContext } from 'react';
import { InboundInvoiceContext } from '../../../context/InboundInvoiceContext';
import { Item, ProductDescription, Wrap } from './Item';

const renderText = (text: string, width: string) => {
  return (
    <div style={{ width, display: 'flex', justifyContent: 'center' }}>
      <Text width={width}>{text}</Text>
    </div>
  );
};

export const Table = () => {
  const { preparedInvoices } = useContext(InboundInvoiceContext);

  return (
    <TableWrapper>
      <TableHeader>
        <ProductNameWrapper>
          <Text width={''}>Product name</Text>
        </ProductNameWrapper>
        <Wrap>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: 'calc(100% - 230px)',
              minWidth: 'calc(100% - 230px)',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            {renderText('Qty', '50px')}
            {renderText('Purchase price', '80px')}
            {renderText('Rebate', '60px')}

            {renderText('Variable cost', '40px')}

            {preparedInvoices?.vatChargeType === 'V05D' &&
              renderText('Customs', '60px')}
            {renderText('Total', '40px')}
          </div>
          <DiscountWrapper>
            <Text width={''}>Margin</Text>
            <Text width={''}>Selling price</Text>
            <Text width={''}>Total</Text>
          </DiscountWrapper>
        </Wrap>
      </TableHeader>
      <RowsWrapper>
        {preparedInvoices &&
          preparedInvoices.items.length > 0 &&
          preparedInvoices?.items.map((item, index) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              key={index}
            >
              <div
                style={{
                  backgroundColor: '#F9FAFB',
                  width: '100%',
                  height: '24px',
                  borderTop: '1px solid #dfe3e8',
                  borderBottom: '1px solid #dfe3e8',
                  display: 'flex',
                  gap: '40px',
                  padding: '5px 0 5px 16px',
                }}
              >
                <div style={{ display: 'flex', gap: '5px' }}>
                  <ProductDescription>Reference:</ProductDescription>
                  <ProductDescription style={{ color: 'black' }}>
                    {item.reference}
                  </ProductDescription>
                </div>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <ProductDescription>Purchase order:</ProductDescription>
                  <ProductDescription style={{ color: 'black' }}>
                    {item.purchaseOrder}
                  </ProductDescription>
                </div>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <ProductDescription>Supplier order:</ProductDescription>
                  <ProductDescription style={{ color: 'black' }}>
                    {item.supplierOrder}
                  </ProductDescription>
                </div>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <ProductDescription>Stock in order:</ProductDescription>
                  <ProductDescription style={{ color: 'black' }}>
                    {item.stockInOrder}
                  </ProductDescription>
                </div>
              </div>
              {item.lineItems.length > 0 &&
                item.lineItems.map((lineItem, idx) => (
                  <Item
                    key={idx}
                    lineItem={lineItem}
                    productIdx={idx}
                    itemIdx={index}
                  />
                ))}
            </div>
          ))}
      </RowsWrapper>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableHeader = styled.div`
  border-bottom: 1px solid #dfe3e8;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 22;
`;

const ProductNameWrapper = styled.div`
  width: calc(100% - 400px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div<{ width: string }>`
  font-family: Barlow;
  font-size: 12px;
  font-weight: 500;
  min-width: ${(props) =>
    props.width !== '' ? (props.width ? props.width : '') : '70px'};
  width: ${(props) => (props.width !== '' ? '' : '100%')};
  text-align: center;
  color: #454f5b;
`;

const DiscountWrapper = styled.div`
  min-width: 230px;
  width: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const RowsWrapper = styled.div`
  max-height: calc(100svh - 255px);
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;
