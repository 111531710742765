import Popover from '@mui/material/Popover';

interface LocationProductsProps {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLButtonElement | null;
  children: JSX.Element;
}

const LocationProducts = (props: LocationProductsProps) => {
  const { open, anchorEl, handleClose, children } = props;

  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginLeft: '10px' }}
      >
        {children}
      </Popover>
    </div>
  );
};

export default LocationProducts;
