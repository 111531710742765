import api from '@tyrio/api-factory';
import { DBStockLocationsApi } from '@tyrio/dto';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { WarehouseContext } from '../../../context/WarehouseContext';

export const useGetWarehouse = (id: string) => {
  const { setWarehouse, setActiveStep } = useContext(WarehouseContext);

  const warehouse = useQuery(
    ['warehouse', { id }],
    async () => {
      return await api.fetch<DBStockLocationsApi['warehouse']>('warehouse', {
        id: id,
      });
    },
    {
      enabled: !!id,
      onSuccess: (d) => {
        if (d) {
          setWarehouse(d);
          setActiveStep('WAREHOUSE');
        }
      },
    }
  );

  return { warehouse };
};
