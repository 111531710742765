import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const REIFENMUELLER_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'PKW',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '30',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Offroad',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c.Untergruppencode',
  //   condition: 'contains',
  //   conditionValue: 'Kleinreifen',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'category_id',
  //   outputValue: '31',
  //   outputType: 'forced_integer',
  // },
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Transporter',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '32',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Alufelgen',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '72',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Stahlfelgen',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '73',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: "c.['Herstellerk�rzel']",
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Sommer',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'summer',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Winter',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'winter',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Ganzjahr',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'allseason',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Untergruppencode',
    condition: 'contains',
    conditionValue: 'Sonstige',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'summer',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.map',
    sourceKey: "c.['Profilcode/Modellcode']",
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: "c.['Prim�re EAN']",
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Nr.']",
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.Breite',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Querschnittsverh�ltnis']",
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.height',
    condition: 'equals',
    conditionValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'height',
    outputValue: 'R',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Durchmesser',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Bauart',
    targetKey: 'construction',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article Beschreibung"]',
    condition: 'contains',
    conditionValue: ' XL ',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: 'XL',
    outputType: 'string',
  },

  // Load index and Speed Index
  {
    type: 'rule.map',
    sourceKey: "c.['Load Index 1 Einzelbereifung']",
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Speedindex',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'globalGetReifenMuellerSpecialMarkings',
    applyOnlyForCategories: [30, 31, 32, 33, 50], // carTyres
  },
  // ThreePMFs
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c["3PMSF Mark"]',
    condition: 'equals',
    conditionValues: ['Yes', 'No'],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
  },
  // // iceGrip
  // {
  //   type: 'rule.condition_boolean',
  //   sourceKey: 'c["ice grip"]',
  //   condition: 'equals',
  //   conditionValues: ['J', 'N'],
  //   conditionOutputs: [true, false],
  //   destination: 'product',
  //   targetKey: 'iceGrip',
  //   outputType: 'boolean',
  // },
  // Globalni fieldovi
  {
    type: 'rule.map',
    sourceKey: 'c["Rollwiderstand"]',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Nasshaftung"]',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Reifenger�usch"]',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Reifenger�uschklasse"]',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c["EU regulation"]',
  //   targetKey: 'euDirectiveNumber',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.["URL 2"]',
    targetKey: 'eprelId',
    destination: 'product',
    postProcess: ['getEprelIdFromUrl'],
  },
  {
    type: 'rule.map',
    sourceKey: 't.product.eprelId',
    targetKey: 'eprelUrl',
    destination: 'product',
    postProcess: ['getEprelUrlFromId'],
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'pcd',
    template: '#{c.Lochanzahl}x#{c.Lochkreisdurchmesser}',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c["Einpresstiefe"]',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Mittenlochkreis"]',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'instalationKit',
    template: 'partialKit',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c["image url"]',
  //   targetKey: 'originalPhotos',
  //   destination: 'model',
  //   outputType: 'string',
  // },
  // // Tube Type - moto
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'TT',
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'tubeType',
  //   outputValue: 'TT',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'TL',
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'tubeType',
  //   outputValue: 'TL',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'TL/TT',
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'tubeType',
  //   outputValue: 'TL/TT',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // // Axle - moto
  // {
  //   type: 'rule.template',
  //   destination: 'product',
  //   targetKey: 'wheelPosition',
  //   template: 'front/rear',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'front',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'wheelPosition',
  //   outputValue: 'front',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'Front',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'wheelPosition',
  //   outputValue: 'front',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'rear',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'wheelPosition',
  //   outputValue: 'rear',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'Rear',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'wheelPosition',
  //   outputValue: 'rear',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'front/rear',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'wheelPosition',
  //   outputValue: 'front/rear',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'Front/rear',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'wheelPosition',
  //   outputValue: 'front/rear',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: 'Front/Rear',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'wheelPosition',
  //   outputValue: 'front/rear',
  //   outputType: 'string',
  //   applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  // },
  // // Sidewall
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c.profile',
  //   condition: 'contains',
  //   conditionValue: 'WW',
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'sidewall',
  //   outputValue: 'WW',
  //   outputType: 'string',
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c.profile',
  //   condition: 'contains',
  //   conditionValue: 'WWW',
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'sidewall',
  //   outputValue: 'WWW',
  //   outputType: 'string',
  // },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c["Farbe"]',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c["Farbe"]',
    condition: 'equals',
    conditionValues: ['AS', 'BG'],
    conditionOutputs: ['artctic silver', 'black glossy'],
    destination: 'model',
    targetKey: 'color',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  // // Flank
  // // {
  // //   type: 'rule.condition',
  // //   sourceKey: 'c["article information"]',
  // //   condition: 'contains',
  // //   conditionValue: ' FP',
  // //   outputLogic: 'custom',
  // //   destination: 'product',
  // //   targetKey: 'flank',
  // //   outputValue: true,
  // //   outputType: 'boolean',
  // // },
  ...GLOBAL_RULESET,
];
