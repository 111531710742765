import { TRule } from '../../typings';

export const REIFENMUELLER_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['Prim�re EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Nr.']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Lagerbestand']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.operate',
    sourceKey: "c.['Breite']",
    destination: 'product',
    targetKey: 'packageQuantity',
    operationName: 'calculateReifenMuellerPackageQuantity',
  },
];
