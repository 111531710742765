import { TRule } from '../../typings';

export const GUMAX_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: "c.['qty']",
  //   destination: 'product',
  //   targetKey: 'qty',
  //   outputType: 'number',
  // },
  {
    type: 'rule.operate',
    sourceKey: "c['zaliha']",
    destination: 'product',
    targetKey: 'qty',
    operationName: 'getGumaxQty',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['itemID']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
