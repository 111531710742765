import * as z from "zod"
import { DBUserRole } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBUser, RelatedDBUserModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBRoleModel = z.object({
  id: z.string(),
  roleName: z.string(),
  roleDescription: z.string(),
  clientId: z.string().nullish(),
  active: z.boolean(),
  permissions: jsonSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  type: z.nativeEnum(DBUserRole),
  clientPredefined: z.boolean(),
})

export interface CompleteDBRole extends z.infer<typeof DBRoleModel> {
  client?: CompleteDBClient | null
  DBUser: CompleteDBUser[]
}

/**
 * RelatedDBRoleModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBRoleModel: z.ZodSchema<CompleteDBRole> = z.lazy(() => DBRoleModel.extend({
  client: RelatedDBClientModel.nullish(),
  DBUser: RelatedDBUserModel.array(),
}))
