export const TRANSLATION_MAP = new Map([
  ['price_range_min', 'Price range min'],
  ['price_range_max', 'Price range max'],
  ['margin', 'Margin'],
  ['rebates', 'Rebates %'],
  ['discount', 'Cache discount %'],
]);

export const DEFAULT_OBJECT = {
  'Price calculation': {
    price_range_min: '',
    price_range_max: '',
    margin: '',
    useSuggestedRetailPrice: false,
  },
  'Customer rebates': {
    price_range_min: '',
    price_range_max: '',
    rebates: '',
    discount: '',
    useSuggestedRetailPrice: false,
  },
};
