/* eslint-disable @typescript-eslint/no-empty-function */
import { PartnerResponse, PosItem, PosItemResponse } from '@tyrio/dto';
import React from 'react';
import { createContext, Dispatch, SetStateAction } from 'react';

export type ActiveStep =
  | 'SEARCH'
  | 'DETAILS'
  | 'BRANCHES'
  | 'SUPPLIERS'
  | 'SERVICES'
  | 'TYRE HOTEL'
  | 'CART'
  | 'WHEEL_DETAILS';

export type ActiveDrawer =
  | 'GALLERY'
  | 'LOCATION_INFO'
  | 'PRINT'
  | 'RESERVATION'
  | '';

export interface BadgeContent {
  BRANCHES: number;
  SUPPLIERS: number;
  CART: number;
}

// Can be nullable until we add these states to POS.tsx
type POSContextInterface = {
  isBranchesStockExpanded: boolean;
  setIsBranchesStockExpanded: Dispatch<SetStateAction<boolean>>;
  isSupplierStockExpanded: boolean;
  setIsSupplierStockExpanded: Dispatch<SetStateAction<boolean>>;
  myStockItems: PosItemResponse;
  setMyStockItems: Dispatch<SetStateAction<PosItemResponse>>;
  branchesStockItems: PosItemResponse;
  setBranchesStockItems: Dispatch<SetStateAction<PosItemResponse>>;
  supplierStockItems: PosItemResponse;
  setSupplierStockItems: Dispatch<SetStateAction<PosItemResponse>>;
  realTimeStock?: number;
  realTimePrice?: number;
  shouldEnlarge: boolean;
  setShouldEnlarge: Dispatch<SetStateAction<boolean>>;
  activeStep: ActiveStep;
  setActiveStep: Dispatch<SetStateAction<ActiveStep>>;
  selectedTableRow: PosItem | null;
  setSelectedTableRow: (row: PosItem | null) => void;
  onlyAvailable?: boolean;
  setOnlyAvailable?: Dispatch<SetStateAction<boolean>>;
  sidebarOpen?: boolean;
  shouldShowDot: boolean;
  setShouldShowDot: Dispatch<SetStateAction<boolean>>;
  activeDrawer: ActiveDrawer;
  setActiveDrawer: Dispatch<SetStateAction<ActiveDrawer>>;
  badgeContent: BadgeContent;
  setBadgeContent: Dispatch<SetStateAction<BadgeContent>>;
  selectedCustomer: PartnerResponse | null;
  setSelectedCustomer: Dispatch<SetStateAction<PartnerResponse | null>>;
  selectedWarehouseId: number | null;
  setSelectedWarehouseId: Dispatch<SetStateAction<number | null>>;
  isVatIncluded: boolean;
  setIsVatIncluded: Dispatch<SetStateAction<boolean>>;
  filterValues: qs.ParsedQs;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
  isSupplierNameVisible: boolean;
  setIsSupplierNameVisible: Dispatch<SetStateAction<boolean>>;
};

export const POSContext = createContext<POSContextInterface>({
  isBranchesStockExpanded: false,
  setIsBranchesStockExpanded: () => {},
  isSupplierStockExpanded: false,
  setIsSupplierStockExpanded: () => {},
  myStockItems: { matchedSets: [], unmatchedItems: [] },
  setMyStockItems: () => {},
  branchesStockItems: { matchedSets: [], unmatchedItems: [] },
  setBranchesStockItems: () => {},
  supplierStockItems: { matchedSets: [], unmatchedItems: [] },
  setSupplierStockItems: () => {},
  realTimeStock: 0,
  realTimePrice: 0,
  shouldEnlarge: false,
  setShouldEnlarge: () => {},
  activeStep: 'SEARCH',
  setActiveStep: () => {},
  selectedTableRow: null,
  setSelectedTableRow: () => {},
  sidebarOpen: false,
  shouldShowDot: false,
  setShouldShowDot: () => {},
  activeDrawer: '',
  setActiveDrawer: () => {},
  badgeContent: { BRANCHES: 0, SUPPLIERS: 0, CART: 0 },
  setBadgeContent: () => {},
  selectedCustomer: null,
  setSelectedCustomer: () => null,
  selectedWarehouseId: null,
  setSelectedWarehouseId: () => {},
  isVatIncluded: true,
  setIsVatIncluded: () => {},
  filterValues: {},
  setFilterValue: () => {},
  isSupplierNameVisible: false,
  setIsSupplierNameVisible: () => {},
});

interface POSProviderProps {
  children: React.ReactNode;
  value: POSContextInterface;
}

const POSProvider = ({ children, value }: POSProviderProps) => {
  return <POSContext.Provider value={value}>{children}</POSContext.Provider>;
};

export default POSProvider;

export const usePosCtx = () => React.useContext(POSContext);
