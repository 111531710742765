import styled from '@emotion/styled';

export const QrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 22px 0 0px 0;
  padding: 0 62px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 6px solid #db1e1e;
  align-items: center;
  padding: 12px 24px;
  width: calc(100% - 128px);
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50px;
  height: 100%;
  justify-content: flex-end;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
`;
