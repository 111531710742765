import { PermissionsValues } from '@tyrio/forms';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import RoleUpsertForm from '../features/role-form/RoleUpsertForm';
import DashboardRolesList from '../pages/dashboard/Roles';

interface RoleRouterProps {
  permissions: PermissionsValues;
  clientId: string;
}

export const RoleRouter = ({ permissions, clientId }: RoleRouterProps) => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <DashboardRolesList clientId={clientId}>
        {location.pathname.split('/').pop() === 'new' ? (
          <Route exact path={`${path}/new`}>
            <RoleUpsertForm permissions={permissions} clientId={clientId} />
          </Route>
        ) : (
          <Route exact path={`${path}/:roleId`}>
            <RoleUpsertForm permissions={permissions} clientId={clientId} />
          </Route>
        )}
      </DashboardRolesList>
    </Switch>
  );
};
