import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener, Modal, Typography } from '@mui/material';
import { WmsIcon } from '@tyrio/wms-ui-library';
import { FunctionComponent, SVGProps } from 'react';
import {
  HeadingWrapper,
  TitleWrapper,
} from '../SideMenuLayout/SideMenuLayout.style';

interface CustomDrawerContainerProps {
  child: JSX.Element;
  open: boolean;
  onClick: () => void;
  title?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  viewBox?: string;
  iconStyle?: object;
}

const CustomDrawerContainer = ({
  child,
  open,
  onClick,
  title,
  icon,
  viewBox,
  iconStyle,
}: CustomDrawerContainerProps) => {
  if (open)
    return (
      <ClickAwayListener onClickAway={onClick}>
        <AnimationDiv>
          <ContentWrapper>
            <ModalWrapper>
              <Border>
                <HeadingWrapper
                  style={{ width: '100%', paddingBottom: '20px' }}
                >
                  {title ? (
                    <TitleWrapper style={{ paddingBottom: 0 }}>
                      <WmsIcon
                        icon={icon}
                        color="info"
                        viewBox={viewBox}
                        sx={iconStyle}
                      />
                      <Typography variant="subtitle1" fontSize={18}>
                        {title}
                      </Typography>
                    </TitleWrapper>
                  ) : (
                    <div />
                  )}

                  <CloseIcon onClick={onClick} sx={{ cursor: 'pointer' }} />
                </HeadingWrapper>

                {child}
              </Border>
            </ModalWrapper>
          </ContentWrapper>
        </AnimationDiv>
      </ClickAwayListener>
    );
  return null;
};

const AnimationDiv = styled.div`
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  animation: slideIn 0.5s;
  -webkit-animation: slideIn 0.5s;
  animation-iteration-count: 1;

  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  @keyframes slideIn {
    from {
      right: 0;
      left: 100%;
      width: 0;
    }
    to {
      left: 0;
      width: 100%;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: 100%;
  @keyframes slideIn {
    from {
      left: calc(100% - 45px);
      width: 0;
    }
    to {
      left: 0;
      width: 100%;
    }
  }
`;

export const StyledModal = styled(Modal)`
  background: white;
  width: 600px;
  height: 100%;
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  align-items: flex-end;
  height: 100%;
  padding: 16px;
  width: 100%;
  @keyframes slideIn {
    from {
      left: calc(100% - 45px);
      width: 0;
    }
    to {
      left: 0;
      width: 100%;
    }
  }
`;

export const Border = styled.div`
  border: 1px solid #1976d2;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`;

export default CustomDrawerContainer;
