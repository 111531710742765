import { TRule } from '../../typings';

export const RADCENTAR_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.eanCod",
    destination: 'product',
    targetKey: 'ean',
    outputType: 'string',
  },
  {
    type: 'rule.operate',
    sourceKey: "c.articleNo",
    destination: 'product',
    targetKey: 'supplierCode',
    operationName: 'getRadcentarManufacturerCode',
  },
  {
    type: 'rule.operate',
    sourceKey: "c.articleNo",
    destination: 'product',
    targetKey: 'manufacturerCode',
    operationName: 'getRadcentarManufacturerCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.manufacturer",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.quantity",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
