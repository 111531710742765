/* eslint-disable @typescript-eslint/no-explicit-any */

import api from '@tyrio/api-factory';
import { DBPurchaseAndTransferOrderDocuments } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useFetchInboundOutboundData = (
  ean: string,
  warehouseId: string
) => {
  const { data, refetch: refetchOrders } = useQuery(
    ['get_transfer_data_by_ean', ean, warehouseId],
    () =>
      api.fetch<{
        requestBody: never;
        requestParams: { ean: string; warehouseId: string };
        request: { ean: string; warehouseId: string };
        response: DBPurchaseAndTransferOrderDocuments;
      }>(`get_transfer_data_by_ean`, {
        ean: ean,
        warehouseId,
      })
  );
  return { data, refetchOrders };
};
