/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  keyframes,
  MenuItem,
  Select,
} from '@mui/material';
import Button from '@mui/material/Button/Button';
import { InputSelectType } from '@tyrio/dto';
import { SPECIAL_MARKING_TYPE_DROPDOWN_DATA } from '@tyrio/shared-vars';
import { startCase } from 'lodash';
import qs from 'qs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { StockListContext } from '../../../context/StockListContext';
import { getAutocompleteSelectCheckboxes } from '../../form-helper';

interface FilterSidebarProps {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
}

const SidebarInternal = ({ isOpen, setOpen }: FilterSidebarProps) => {
  const { filterValues, setFilterValue, setFilterValues, filterOptions } =
    useContext(StockListContext);
  const history = useHistory();
  const [subcategories, setSubcategories] = useState<InputSelectType[]>([]);
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);

  const getFilterValuesFromInitial = useCallback(() => {
    const data = {
      categoryId: filterValues?.['categoryId'],
      subcategoryId: filterValues?.['subcategoryId'],
      season: filterValues?.['season'],
      brand: filterValues?.['brand'],
      wheelPosition: filterValues?.['wheelPosition'],
      specialMarking: filterValues?.['specialMarking'],
    };

    return data as any;
  }, [filterValues]);

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getFilterValuesFromInitial(),
  });

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(false);
    };

  const onSubmit = (data: qs.ParsedQs) => {
    setFilterValue(data);
    setOpen(false);
  };

  const resetFilterData = () => {
    reset({
      categoryId: undefined,
      subcategoryId: undefined,
      brand: undefined,
      season: undefined,
      wheelPosition: undefined,
      specialMarking: undefined,
    });
    setFilterValues({});
    history.push({ pathname: '/dashboard/stock-list', search: '' });
    setOpen(false);
  };

  useEffect(() => {
    isCategoryChanged && setValue('subcategoryId', undefined);
    if (filterOptions?.subcategories && watch('categoryId') !== undefined) {
      const subcategoriesList = filterOptions?.subcategories.filter(
        (item) => item.parentCategory === Number(watch('categoryId'))
      );
      setSubcategories(subcategoriesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions?.subcategories, setValue, watch, watch('categoryId')]);

  return (
    <Drawer
      anchor={'left'}
      open={isOpen}
      onClose={toggleDrawer()}
      PaperProps={{
        sx: {
          marginLeft: '70px',
          display: 'inline-block',
          overflow: 'hidden',
          maxWidth: '250px',
          height: '100%',
          animation: `${slideIn} 2s`,
        },
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          borderRadius: 0,
          width: '250px',
        }}
      >
        <Wrapper>
          <Header>
            <Title>Filter</Title>
            <CloseButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon style={{ cursor: 'pointer' }} />
            </CloseButton>
          </Header>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="categoryId">Category</InputLabel>
                <Select
                  labelId="categoryId"
                  id="categoryId"
                  value={filterValues?.['categoryId']}
                  label="Category"
                  onChange={(e) => {
                    setValue('categoryId', e.target.value);
                    setIsCategoryChanged(true);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { top: '130px' },
                    },
                  }}
                >
                  {filterOptions?.categories
                    .map((item: any) => ({
                      label: item.name,
                      value: item.id,
                    }))
                    .map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={12}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="subcategoryId">Subcategory</InputLabel>
                <Select
                  labelId="subcategoryId"
                  id="subcategoryId"
                  value={watch('subcategoryId')}
                  label="Subcategory"
                  disabled={subcategories.length === 0}
                  onChange={(e) => {
                    setValue('subcategoryId', e.target.value);
                  }}
                >
                  {subcategories
                    .map((item: any) => ({
                      label: item.name,
                      value: item.id,
                    }))
                    .map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {getAutocompleteSelectCheckboxes(
              'Brand',
              'brand',
              'Brand',
              control,
              { lg: 12, xs: 12 },
              filterOptions?.brands
                .map((item: string, index) => ({
                  label: item,
                  value: index,
                }))
                .map((item) => item.label.toString()) || []
            )}

            {getAutocompleteSelectCheckboxes(
              'Season',
              'season',
              'Season',
              control,
              { lg: 12, xs: 12 },
              filterOptions?.seasons
                .map((item: string, index) => ({
                  label: item,
                  value: index,
                }))
                .map((item) => item.label.toString()) || []
            )}

            {getAutocompleteSelectCheckboxes(
              'Wheel Position',
              'wheelPosition',
              'Wheel Position',
              control,
              { lg: 12, xs: 12 },
              filterOptions?.wheelPositions
                .map((item: string, index) => ({
                  label: item,
                  value: index,
                }))
                .map((item) => item.label.toString()) || []
            )}

            {getAutocompleteSelectCheckboxes(
              'Special Markings',
              'specialMarking',
              'Special Markings',
              control,
              { lg: 12, xs: 12 },
              // filters?.specialMarkings
              //   .map((item: string, index) => ({
              //     label: item,
              //     value: index,
              //   }))
              //   .map((item) => item.label.toString()) || []
              /* we'll be using these for now */
              SPECIAL_MARKING_TYPE_DROPDOWN_DATA.map((item: any) =>
                startCase(item.value.toString())
              ) || []
            )}

            <Grid item xs={6} style={{ marginTop: '15px' }}>
              <Button
                fullWidth
                type="button"
                onClick={resetFilterData}
                style={{ border: '1px solid #1B2130' }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6} style={{ marginTop: '15px' }}>
              <Button fullWidth variant="contained" type="submit">
                Filter
              </Button>
            </Grid>
          </Grid>
        </Wrapper>
      </form>
    </Drawer>
  );
};

export const SidebarFilter = ({ isOpen, setOpen }: FilterSidebarProps) => {
  if (!isOpen) return null;
  return <SidebarInternal isOpen={isOpen} setOpen={setOpen} />;
};

const Wrapper = styled.div`
  height: 100%;
  background: white;
  border-radius: 22px;
  padding: 20px;
  margin-right: 10px;
  max-width: 80vw;
  width: 250px;
  border-radius: 0;
  transition: width 1s ease-in-out;
  width: auto;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
`;

const Header = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const slideIn = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }`;

export default SidebarFilter;
