import { DBBusinessType } from '@prisma/client';
import * as z from 'zod';
import { FormShape, TyrioSelectInputOption } from '../types';
import { createInput } from '../utils';

export const generateMainInfoForm = (
  editPermission: boolean,
  countries: TyrioSelectInputOption[]
) => {
  const onlyLettersRegex = /^[a-zšđčćž ]+$/gi;

  const zodSchema = z.object({
    //Company info:
    businessType: z.string().trim().min(1, {
      message: 'This field is required!',
    }),
    businessCode: z.string().optional(),
    vatObligee: z.string().nonempty('This field is required!'),
    intrastat: z.string().nonempty('This field is required!'),
    vatNumber: z.string().trim().min(1, {
      message: 'This field is required!',
    }),
    registrationNumber: z.string().optional(),
    viesVatNumber: z.string().optional(),
    officialName: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    shortName: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    companyEmail: z.string().optional(),
    companyWebSite: z.string().optional(),
    companyBusinessPhone: z.string().trim().min(6, {
      message: 'This field is required!',
    }),

    //Address:
    countryId: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    zipCode: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    city: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    address: z.string().trim().min(2, {
      message: 'This field is required!',
    }),

    //Responsible person:
    responsiblePersonJobTile: z
      .string()
      .trim()
      .min(2, { message: 'This field is required!' })
      .regex(onlyLettersRegex, 'Only letters are accepted!'),
    responsiblePersonFirstName: z
      .string()
      .trim()
      .min(2, { message: 'This field is required!' })
      .regex(onlyLettersRegex, 'Only letters are accepted!'),
    responsiblePersonLastName: z
      .string()
      .trim()
      .min(2, { message: 'This field is required!' })
      .regex(onlyLettersRegex, 'Only letters are accepted!'),
    responsiblePersonEmail: z
      .string()
      // .email({ message: 'Email format is invalid!' })
      .optional(),
    responsiblePersonBusinessPhone: z.string().optional(),
    responsiblePersonMobilePhone: z.string().optional(),
  });

  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: '',
        inputs: [
          createInput({
            id: 'businessType',
            width: { xs: 12, lg: 3 },
            type: 'input.select',
            label: 'Business Type',
            options: [
              {
                value: DBBusinessType.DOO,
                label: 'd.o.o.',
              },
              {
                value: DBBusinessType.JDOO,
                label: 'j.d.o.o.',
              },
              {
                value: DBBusinessType.OBRT,
                label: 'Sole Proprietorship',
              },
            ],
          }),
          createInput({
            id: 'businessCode',
            width: { xs: 12, lg: 2.5 },
            type: 'input.string',
            label: 'Business code',
          }),
          createInput({
            id: 'vatObligee',
            width: { xs: 12, lg: 2.5 },
            type: 'input.radio',
            label: 'VAT obligee *',
            initialValue: 'YES',

            options: [
              {
                label: 'YES',
                value: 'YES',
              },
              {
                label: 'NO',
                value: 'NO',
              },
            ],
          }),
          createInput({
            id: 'intrastat',
            width: { xs: 12, lg: 2.5 },
            type: 'input.radio',
            label: 'Intrastat *',
            initialValue: 'YES',
            options: [
              {
                label: 'YES',
                value: 'YES',
              },
              {
                label: 'NO',
                value: 'NO',
              },
            ],
          }),
          createInput({
            id: 'vatNumber',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'VAT number',
            disabled: true,
          }),
          createInput({
            id: 'registrationNumber',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'Company Registration Number',
          }),
          createInput({
            id: 'viesVatNumber',
            width: { xs: 12, lg: 4 },
            type: 'input.vies',
            label: 'VIES VAT number',
          }),
          createInput({
            id: 'officialName',
            width: { xs: 12, lg: 7 },
            type: 'input.string',
            label: 'Company Official Name',
            disabled: editPermission,
          }),
          createInput({
            id: 'shortName',
            width: { xs: 12, lg: 5 },
            type: 'input.string',
            label: 'Company Short Name',
            disabled: true,
          }),
          createInput({
            id: 'countryId',
            width: { xs: 12, lg: 2.5 },
            type: 'input.select',
            label: 'Country',
            options: countries,
            disabled: true,
          }),
          createInput({
            id: 'zipCode',
            width: { xs: 12, lg: 2.5 },
            type: 'input.string',
            label: 'Zip Code',
          }),
          createInput({
            id: 'city',
            width: { xs: 12, lg: 3 },
            type: 'input.string',
            label: 'City',
          }),
          createInput({
            id: 'address',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'Address',
          }),
          createInput({
            id: 'companyEmail',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'E-mail',
          }),
          createInput({
            id: 'companyWebSite',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'Web site',
          }),
          createInput({
            id: 'companyBusinessPhone',
            width: { xs: 12, lg: 4 },
            type: 'input.phone',
            label: 'Business Phone *',
          }),
          createInput({
            id: 'responsiblePerson',
            width: { xs: 12 },
            type: 'label.section',
            label: 'Responsible person',
            inlineLable: true,
          }),
          createInput({
            id: 'responsiblePersonJobTile',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'Job Title *',
          }),
          createInput({
            id: 'responsiblePersonFirstName',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'First Name *',
          }),
          createInput({
            id: 'responsiblePersonLastName',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'Last Name *',
          }),
          createInput({
            id: 'responsiblePersonEmail',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'E-mail',
          }),
          createInput({
            id: 'responsiblePersonBusinessPhone',
            width: { xs: 12, lg: 4 },
            type: 'input.phone',
            label: 'Business Phone',
          }),
          createInput({
            id: 'responsiblePersonMobilePhone',
            width: { xs: 12, lg: 4 },
            type: 'input.phone',
            label: 'Mobile Phone',
          }),
        ],
      },
    ],
  };

  return { form, zodSchema };
};

export const generateFiscalizationForm = () => {
  const zodSchema = z.object({});

  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: 'Certificate',
        inputs: [],
      },
      {
        tabTitle: 'Business offices',
        inputs: [],
      },
    ],
  };

  return { form, zodSchema };
};
