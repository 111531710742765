import * as z from "zod"
import { DBPriceType, PaymentTypeEnum } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBPaymentTypesModel = z.object({
  id: z.string(),
  clientId: z.string(),
  code: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  priceType: z.nativeEnum(DBPriceType).nullish(),
  fiscalType: z.string().nullish(),
  paymentGateway: z.string().nullish(),
  isActive: z.boolean(),
  isFiscalizationEnabled: z.boolean(),
  isTyrioDefault: z.boolean(),
  paymentType: z.nativeEnum(PaymentTypeEnum).nullish(),
  cashDiscount: z.boolean().nullish(),
  restrictions: jsonSchema,
  cardSettings: jsonSchema,
  deleted: z.boolean().nullish(),
})

export interface CompleteDBPaymentTypes extends z.infer<typeof DBPaymentTypesModel> {
  client: CompleteDBClient
  orders: CompleteDBCustomerOrderItem[]
}

/**
 * RelatedDBPaymentTypesModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBPaymentTypesModel: z.ZodSchema<CompleteDBPaymentTypes> = z.lazy(() => DBPaymentTypesModel.extend({
  client: RelatedDBClientModel,
  orders: RelatedDBCustomerOrderItemModel.array(),
}))
