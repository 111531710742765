import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import {
  LandingPage,
  SearchableVerticalList,
  SearchableVerticalListDataType,
} from '@tyrio/ui-library';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ExpandableLayout } from '../../layouts/ExpandableLayout';
import routeNames from '../../lib/routeNames';

interface DashboardCategoryProps {
  children: React.ReactNode;
}

const DashboardCategory = ({ children }: DashboardCategoryProps) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const { isFetching, isError, data } = useQuery(
    ['category_list', { search }],
    () => searchProductCategories(search),
    {}
  );

  const slicedData = data?.slice((page - 1) * pageSize, page * pageSize);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchDebounced = useCallback(
    debounce(
      (search: string) => {
        setSearch(search);
      },
      700,
      { leading: false }
    ),
    [search, isFetching, isError, data]
  );

  const searchProductCategories = async (search: string) => {
    return await api.fetch<DBProductCategoryApi['list']>('category', {
      search: search,
    });
  };

  const history = useHistory();

  return (
    <ExpandableLayout
      sidebarComponent={
        <SearchableVerticalList
          header="Categories"
          type={SearchableVerticalListDataType.WITH_CHILDREN}
          handleItemClick={(item) =>
            history.push(`/dashboard/categories/${item.id}`)
          }
          data={
            slicedData?.map((item) => ({
              id: item.id.toString(),
              name: item.name,
              children:
                item.children?.map((i) => ({
                  id: i.id.toString(),
                  name: i.name,
                })) || [],
            })) || []
          }
          isSearch={search !== ''}
          path={routeNames.dashboard()}
          onCreateNew={() => history.push(`/dashboard/categories/new`)}
          onSearchChange={setSearchDebounced}
          pageNumber={page}
          onChangePage={(page) => setPage(page)}
          count={data?.length}
          isFetching={isFetching}
          isError={isError}
        />
      }
      landingPage={
        <LandingPage
          title={'Welcome to Category settings'}
          subtitle={'Choose category from the list or create new.'}
          buttonText={'Add new category'}
          onClick={() => history.push(`/dashboard/categories/new`)}
        />
      }
    >
      {children}
    </ExpandableLayout>
  );
};

export default DashboardCategory;
