import { DBServiceCode } from '@prisma/client';

export const serviceCodes: DBServiceCode[] = [
  {
    id: '00000000-0000-1000-s100-0000000000001',
    code: 1,
  },
  {
    id: '00000000-0000-1000-s100-0000000000002',
    code: 1,
  },
];
