import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DBUserRole } from '@prisma/client';
import { motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import SettingsBar from './SettingsBar';
import { ClickAwayListener } from '@mui/material';

interface FooterProps {
  loggedUser: LoggedUser;
  isOpen: boolean;
  onLogout: () => void;
  isSettingsBarOpen: boolean;
  setIsSettingsBarOpen: Dispatch<SetStateAction<boolean>>;
}

export interface LoggedUser {
  firstName: string;
  lastName: string;
  location: string;
  version: string;
  role: string;
  roleType: DBUserRole;
}

const Footer = ({
  loggedUser,
  isOpen,
  onLogout,
  isSettingsBarOpen,
  setIsSettingsBarOpen,
}: FooterProps) => {
  return isOpen ? (
    <Container role={loggedUser.roleType}>
      <Wrapper>
        <HeaderWrapper>
          <Initials>
            {loggedUser.firstName.charAt(0)}
            {loggedUser.lastName.charAt(0)}
          </Initials>
          <NameRole>
            <Name>
              {loggedUser.firstName} {loggedUser.lastName}
            </Name>
            <Role> {loggedUser.role.toUpperCase()}</Role>
          </NameRole>
        </HeaderWrapper>
        <MoreVertIcon
          height={40}
          sx={{
            cursor: 'pointer',
            fontSize: '25px',
            marginBottom: '2px',
          }}
          onClick={() => setIsSettingsBarOpen(!isSettingsBarOpen)}
        />
        {isSettingsBarOpen && (
          <ClickAwayListener onClickAway={() => setIsSettingsBarOpen(false)}>
            <SettingsBarWrapper>
              <SettingsBar loggedUser={loggedUser} onLogout={onLogout} />
            </SettingsBarWrapper>
          </ClickAwayListener>
        )}
      </Wrapper>
    </Container>
  ) : (
    <Container role={loggedUser.roleType}>
      <Initials>
        {loggedUser.firstName.charAt(0)}
        {loggedUser.lastName.charAt(0)}
      </Initials>
    </Container>
  );
};

const Container = styled(motion.div)<{ role: string }>`
  width: 100%;
  height: 100px;
  background: ${(props) =>
    props.role === DBUserRole.ADMIN ? '#ffffff0f' : '#db1e1e'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
`;

const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 36px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    justify-content: space-between;
  }
`;

const Initials = styled(motion.div)`
  border: 2px solid white;
  border-radius: 100px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

const NameRole = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  height: 36px;
`;

const Role = styled(motion.span)`
  color: #bcc7d1;
  width: 110px;
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
  letter-spacing: 0.3px;
  text-align: left;
`;

const Name = styled(motion.span)`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.3px;
`;

const SettingsBarWrapper = styled(motion.div)`
  position: absolute;
  left: 255px;
  z-index: 20;
  bottom: 400px;

  @media (max-width: 768px) {
    right: 306px;
    left: unset;
  }
`;

const HeaderWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
`;

export default Footer;
