import * as z from "zod"
import { WheelHoleValue, ConcaveValue } from ".prisma/client"
import { CompleteDBProductCategory, RelatedDBProductCategoryModel, CompleteDBImport, RelatedDBImportModel, CompleteDBProduct, RelatedDBProductModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBProductModelModel = z.object({
  uid: z.string(),
  category_id: z.number().int(),
  importKey: z.string(),
  deprecated: z.boolean(),
  season: z.string().nullish(),
  brand: z.string().nullish(),
  modelName: z.string().nullish(),
  usage: z.string().nullish(),
  color: z.string().nullish(),
  colorGroup: z.string().nullish(),
  colorFinish: z.string().array(),
  wheelPosition: z.string().nullish(),
  descTitle: z.string().nullish(),
  descSubtitle: z.string().nullish(),
  descIntro: z.string().nullish(),
  descContent: z.string().nullish(),
  sidewall: z.string(),
  wheelHoles: z.nativeEnum(WheelHoleValue).nullish(),
  concave: z.nativeEnum(ConcaveValue).nullish(),
  videoUrl: z.string().nullish(),
  remark: z.string().nullish(),
  dataSource: z.string().nullish(),
  created: z.date(),
  updated: z.date(),
  active: z.boolean(),
  weightMap: jsonSchema,
  productMeta: jsonSchema,
  dirtyFields: jsonSchema,
  originalPhotos: jsonSchema,
  photos: jsonSchema,
})

export interface CompleteDBProductModel extends z.infer<typeof DBProductModelModel> {
  category: CompleteDBProductCategory
  importObject: CompleteDBImport
  DBProduct: CompleteDBProduct[]
}

/**
 * RelatedDBProductModelModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBProductModelModel: z.ZodSchema<CompleteDBProductModel> = z.lazy(() => DBProductModelModel.extend({
  category: RelatedDBProductCategoryModel,
  importObject: RelatedDBImportModel,
  DBProduct: RelatedDBProductModel.array(),
}))
