/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import { authReducer } from './src/features/auth/store/auth.reducer';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const combinedReducers = combineReducers([authReducer]);

export const rootStore = createStore(combinedReducers, composeEnhancers());

export type AppState = ReturnType<typeof combineReducers>;
