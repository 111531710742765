import styled from '@emotion/styled';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import api from '@tyrio/api-factory';
import { ConnectionShape, DBSupplierClientSettingsApi } from '@tyrio/dto';
import { backIcon } from '@tyrio/ui-library';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import { useAuth } from '../../../context/AuthContext';
import Order from '../../../features/orders/Order';
import { OrderDataInterface } from '../../price-compare/types';

export interface SupplierOrderDataInterface {
  partialOrderData: OrderDataInterface;
}

const PREFIX_VALUE = 'PO-';
const SupplierOrderOverview = ({
  partialOrderData,
}: SupplierOrderDataInterface) => {
  const history = useHistory();
  const [refNo, setRefNo] = useState('');
  const [connection, setConnection] = useState<ConnectionShape | undefined>(
    undefined
  );

  const getClientSupplierSettingsByClientId = async () => {
    return await api.fetch<DBSupplierClientSettingsApi['list']>(
      'get_supplier_client_settings'
    );
  };

  const { isFetching, data } = useQuery(
    ['supplier_client_settings'],
    () => getClientSupplierSettingsByClientId(),
    {}
  );

  const { user } = useAuth();

  const parsedOrderData = useMemo(() => {
    return Object.keys(partialOrderData).map((supplierId) => {
      const supplier = data?.data.find(
        (clientSupplier) => clientSupplier.supplierId === supplierId
      );
      const connections =
        typeof supplier?.connections === 'string'
          ? JSON.parse(supplier.connections)
          : supplier?.connections;

      const connection = connections?.find((con: ConnectionShape) =>
        con.connectionType.includes('ORDER')
      );

      setConnection(connection);

      return {
        connection,
        supplierId: supplier?.supplierId,
        orderData: partialOrderData[supplierId],
      };
    });
  }, [data, partialOrderData]);

  if (isFetching) {
    return (
      <PageTemplateWrapper>
        <PageTemplateContent>
          <HeaderWrapper>
            <BackButton
              onClick={() => history.push('/dashboard/price-comparison')}
            >
              <img src={backIcon} alt="back-icon" />
            </BackButton>
            <HeaderText>Order overview</HeaderText>
          </HeaderWrapper>
          <ReferenceWrapper>
            <Grid container spacing={2}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </ReferenceWrapper>
        </PageTemplateContent>
      </PageTemplateWrapper>
    );
  }

  return (
    <PageTemplateWrapper>
      <PageTemplateContent>
        <HeaderWrapper>
          <BackButton
            onClick={() => history.push('/dashboard/price-comparison')}
          >
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>Order overview</HeaderText>
        </HeaderWrapper>
        {connection ? (
          <Grid>
            <ReferenceWrapper>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    value={refNo}
                    prefix={'PO-'}
                    onChange={(e) => setRefNo(e.target.value)}
                    label="Reference"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {PREFIX_VALUE}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </ReferenceWrapper>
            <OrdersContainer>
              {parsedOrderData.map((data) => (
                <Order
                  supplierId={data.supplierId || ''}
                  products={data.orderData}
                  connection={data.connection}
                  client={user?.client}
                  refNo={refNo}
                />
              ))}
            </OrdersContainer>
          </Grid>
        ) : (
          <Grid sx={{ width: '100%', height: '100vh', padding: '16px' }}>
            <Alert severity="error">
              <AlertTitle>Error during order creation</AlertTitle>
              Order can not be completed, because supplier doesn't have any
              ORDER connection configuration.{' '}
              <strong>
                Please check ORDER connection type in supplier settings.
              </strong>
            </Alert>
          </Grid>
        )}
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};

const ReferenceWrapper = styled.div`
  padding: 16px 16px 0 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  padding: 16px 16px 32px 16px;
  border-bottom: 1px solid #dfe3e8;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-left: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 5px;
  height: 22px;
`;

const OrdersContainer = styled.div`
  padding: 16px;
`;

export default SupplierOrderOverview;
