import { PosCartContext } from '../../../../../../context/PosCartContext';
import { useContext, useState } from 'react';
import { SideMenuLayout } from '../../../../../../components/SideMenuLayout/SideMenuLayout';
import { InfoFooter } from '../Footer';
import { VehicleForm } from './VehicleForm';
import {
  Text,
  WmsIconStyled,
} from '../../../../../../components/SideMenuLayout/SideMenuLayout.style';
import {
  DeleteWrapper,
  MainWrapper,
} from '../../../../styles/CustomerForm.style';
import { wmsIcons } from '@tyrio/wms-ui-library';
import { CancelModal, DiscardModal } from '@tyrio/ui-library';

export const Vehicle = () => {
  const { setActiveInfo, vehicleDetails, setInput, setVehicleDetails, input } =
    useContext(PosCartContext);
  const [isDirty, setIsDirty] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);

  const handleSubmit = () => {
    setInput((prevState) => ({
      ...prevState,
      vehicle: vehicleDetails,
    }));
    setActiveInfo('');
  };

  const handleCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else setActiveInfo('');
  };

  const handleDiscard = () => {
    setInput((prevState) => ({
      ...prevState,
      vehicle: {},
    }));
    setVehicleDetails({});
    setIsDiscardModalVisible(false);
    setActiveInfo('');
    setIsDirty(false);
  };

  const renderCustomer = () => {
    return (
      <MainWrapper>
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              setVehicleDetails(input.vehicle);
              setIsCancelModalVisible(false);
              setActiveInfo('');
              setIsDirty(false);
            }}
          />
        )}
        {isDiscardModalVisible && (
          <DiscardModal
            LBAction={() => setIsDiscardModalVisible(false)}
            RBAction={handleDiscard}
          />
        )}
        <VehicleForm setIsDirty={setIsDirty} />
        <DeleteWrapper onClick={() => setIsDiscardModalVisible(true)}>
          <Text id="delete_text">Discard</Text>
          <WmsIconStyled icon={wmsIcons.bin} id="delete_icon" />
        </DeleteWrapper>
      </MainWrapper>
    );
  };

  return (
    <SideMenuLayout
      type="vehicle"
      children={renderCustomer()}
      showSwitch={false}
      switchLabel="Vehicle"
      shouldShowTitle={true}
      footer={
        <InfoFooter
          text1="CANCEL"
          text2="SUBMIT"
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      }
      viewBox={'0 0 32 32'}
    />
  );
};
