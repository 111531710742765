import styled from '@emotion/styled';
import { TableCell, TableRow } from '@mui/material';
import { DBStockList } from '@tyrio/dto';
import { generateSpecialMarkings } from '@tyrio/products';
import { tyrioUI } from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import {
  ActiveDrawer,
  ProductsQuantity,
} from '../ProductsQuantity/ProductsQuantity';
import { YearChip } from '../YearChip/YearChip';

export enum SelectedProductStatuses {
  FAVORITE,
  SELECTED,
  NORMAL,
}

type ActiveStep =
  | 'SEARCH'
  | 'DETAILS'
  | 'INBOUND'
  | 'OUTBOUND'
  | 'PASTE_LIST'
  | 'PRINT'
  | 'CART';

interface SingleTableRowProps {
  data: DBStockList;
  reserved: number;
  upcoming: number;
  userId: string;
  selectedProduct: DBStockList | null;
  setSelectedProduct: (id: DBStockList | null) => void;
  setActiveStep: (active: ActiveStep) => void;
  setActiveDrawer: (drawer: ActiveDrawer) => void;
  onlyAvailable: boolean;
}

export const SingleTableRow = ({
  data,
  reserved,
  upcoming,
  userId,
  selectedProduct,
  setSelectedProduct,
  setActiveStep,
  setActiveDrawer,
  onlyAvailable,
}: SingleTableRowProps) => {
  const { id, quantity, product, dot } = data;

  const model = product?.model;
  const category = model?.category;

  const available = quantity - reserved;
  const favorite = data.favoritedBy.includes(userId);

  const [hovered, setHovered] = useState(false);

  let status: SelectedProductStatuses =
    selectedProduct?.id === id
      ? SelectedProductStatuses.SELECTED
      : favorite
      ? SelectedProductStatuses.FAVORITE
      : SelectedProductStatuses.NORMAL;

  const handleActive = (id: string) => {
    setActiveStep('DETAILS');
    if (id === selectedProduct?.id) {
      status = favorite
        ? SelectedProductStatuses.FAVORITE
        : SelectedProductStatuses.NORMAL;
    } else setSelectedProduct(data);
  };

  useEffect(() => {
    if (selectedProduct?.id === data.id) setSelectedProduct(data);
  }, [data, selectedProduct?.id, setSelectedProduct]);

  const shouldRenderRow = (available: number) => {
    return !onlyAvailable || (onlyAvailable && available > 0);
  };

  return model && category && product && shouldRenderRow(available) ? (
    <TableRowStyled
      status={status}
      onClick={() => handleActive(id)}
      isSelected={selectedProduct?.id === id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <StyledTableCell size="small" sx={{ maxWidth: '150px' }}>
        {model?.brand}
        <CellDetails>{model.season}</CellDetails>
      </StyledTableCell>
      <StyledTableCell size="small" sx={{ maxWidth: '150px' }}>
        {category.parent_category_name
          ? category.parent_category_name
          : category.name}
        <CellDetails>
          {category.parent_category_name ? category.name : ''}
        </CellDetails>
      </StyledTableCell>
      <StyledTableCell size="small" style={{ overflowX: 'auto' }}>
        {product.productName}
        <SpecialMarkings
          style={{
            color:
              status === SelectedProductStatuses.SELECTED || hovered
                ? '#e75524'
                : '#919eab',
          }}
        >
          {generateSpecialMarkings(product)?.map((item) => item + ' ')}
        </SpecialMarkings>
      </StyledTableCell>
      <StyledTableCell>
        <YearChip year={dot ?? ''} color="default" />
      </StyledTableCell>
      <StyledTableCell
        padding="none"
        sx={{
          maxWidth: onlyAvailable ? '80px' : '320px',
          minWidth: onlyAvailable ? '80px' : '320px',
        }}
      >
        <ProductsQuantity
          onStock={quantity}
          reserved={reserved}
          available={available < 0 ? 0 : available}
          background={
            status === SelectedProductStatuses.FAVORITE
              ? tyrioUI.colors.yellow.B30
              : status === SelectedProductStatuses.SELECTED
              ? tyrioUI.colors.blue.B20
              : ''
          }
          setActiveDrawer={setActiveDrawer}
          onlyAvailable={onlyAvailable}
          upcoming={upcoming}
        />
      </StyledTableCell>
    </TableRowStyled>
  ) : null;
};

export const TableRowStyled = styled(TableRow)<{
  status?: SelectedProductStatuses;
  isSelected: boolean;
}>`
  display: flex;
  width: 100%;
  cursor: pointer;
  background: ${(props) =>
    props.status === SelectedProductStatuses.FAVORITE
      ? tyrioUI.colors.yellow.B10
      : props.status === SelectedProductStatuses.SELECTED
      ? tyrioUI.colors.blue.B10
      : 'white'};

  border-bottom: 1px solid
    ${(props) =>
      props.status === SelectedProductStatuses.FAVORITE
        ? tyrioUI.colors.yellow.B100
        : props.status === SelectedProductStatuses.SELECTED
        ? tyrioUI.colors.blue.B100
        : tyrioUI.colors.black.B40};
  align-items: center;

  &:hover {
    background: ${tyrioUI.colors.black.B10};
    border-bottom: 1px solid ${tyrioUI.colors.black.B40};

    #quantity_wrapper {
      background: ${tyrioUI.colors.black.B30};
    }

    #product_name_details {
      color: #e75524;
      font-weight: 500;
    }
  }

  #product_name_details {
    color: ${(props) =>
      props.status === SelectedProductStatuses.SELECTED
        ? '#e75524'
        : '#919eab'};
  }
`;

const StyledTableCell = styled(TableCell)`
  border: 0;
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
`;

export const CellDetails = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #919eab;
`;

const SpecialMarkings = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #919eab;
  &:hover {
    color: #e75524;
  }
`;
