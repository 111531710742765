import api from '@tyrio/api-factory';
import { DBStockInItemsResponse, DBStockInListApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { queryClient } from './../../../../../query-client';
import { DBStockInItemStatus } from '@prisma/client';

export const useStornStockIn = (
  selectedOrderData: DBStockInItemsResponse | null
) => {
  const { mutateAsync } = useMutation(
    () => {
      return api.fetch<DBStockInListApi['updateOne']>(
        'change_stock_in_status',
        {
          orderId: selectedOrderData?.id || '',
          status: DBStockInItemStatus.CANCELLED,
        }
      );
    },
    {
      mutationKey: 'change_stock_in_status',
      onSuccess: () => {
        queryClient.refetchQueries('get_stock_in_list');
        queryClient.refetchQueries('get_si_count_by_status');
        ToastHelper.showToast(
          'Stock in Order ',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Stock in order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );
  return { stornStockInOrder: mutateAsync };
};
