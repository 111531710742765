import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useGetCategories = () => {
  const [categories, setCategories] = useState<TyrioSelectInputOption[]>([]);

  useQuery(
    ['category_list'],
    async () => {
      return await api.fetch<DBProductCategoryApi['list']>('category', {
        search: '',
      });
    },
    {
      onSuccess: (d) => {
        const temp: TyrioSelectInputOption[] = [];
        temp.push({
          value: 'all',
          label: 'all',
        });
        d.forEach((item) => {
          const obj = {
            value: item.slug,
            label: item.name,
          };

          temp.push(obj);
        });

        setCategories(temp);
      },
    }
  );

  return {
    categories,
  };
};
