import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const GUMAX_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.label_type',
    condition: 'equals',
    conditionValues: ['C1', 'C2'],
    conditionOutputs: ['30', '32'],
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.sezona',
    condition: 'equals',
    conditionValues: ['summer', 'winter', 'allseason'],
    conditionOutputs: ['summer', 'winter', 'allseason'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // Model
  {
    type: 'rule.operate',
    sourceKey: 'c.name',
    destination: 'model',
    targetKey: 'modelName',
    operationName: 'getGumaxModelName',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.EAN',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.sku',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.height',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.inch',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
    {
      type: 'rule.template',
      destination: 'product',
      targetKey: 'construction',
      template: 'R',
    },
  // Load index and Speed Index
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalGetGumaxLoadSpeedIndex',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'getGumaxSpecialMarkings',
  },

  {
    type: 'rule.map',
    sourceKey: 'c.roll_resistance',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.braking_distance',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.noise_db',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.sound_type',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  //   {
  //     type: 'rule.map',
  //     sourceKey: 'c["C Class"]',
  //     targetKey: 'vehicleClass',
  //     destination: 'product',
  //     outputType: 'string',
  //   },
  //   {
  //     type: 'rule.map',
  //     sourceKey: 'c["Eprel_code"]',
  //     targetKey: 'eprelId',
  //     destination: 'product',
  //     outputType: 'string',
  //   },
  //   {
  //     type: 'rule.condition',
  //     sourceKey: 'c.["Eprel_code"]',
  //     condition: 'equals',
  //     conditionValue: '0',
  //     outputValue: '',
  //     outputLogic: 'custom',
  //     destination: 'product',
  //     targetKey: 'eprelId',
  //     outputType: 'string',
  //   },
  //   {
  //     type: 'rule.map',
  //     sourceKey: 't.product.eprelId',
  //     targetKey: 'eprelUrl',
  //     destination: 'product',
  //     postProcess: ['getEprelUrlFromId'],
  //   },
  //   {
  //     type: 'rule.condition',
  //     sourceKey: 'c.["PR/RF"]',
  //     condition: 'contains',
  //     conditionValue: 'XL',
  //     outputValue: 'XL',
  //     outputLogic: 'custom',
  //     destination: 'product',
  //     targetKey: 'rfd',
  //     outputType: 'string',
  //   },
  {
    type: 'rule.condition',
    sourceKey: 'c.name.$',
    condition: 'contains',
    conditionValue: 'XL',
    outputValue: 'XL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputType: 'string',
  },
  //   {
  //     type: 'rule.condition',
  //     sourceKey: 'c.["PR/RF"]',
  //     condition: 'contains',
  //     conditionValue: 'PR',
  //     outputLogic: 'map_from_import',
  //     conditionMapSourceKey: 'c.["PR/RF"]',
  //     destination: 'product',
  //     targetKey: 'pr',
  //     outputType: 'string',
  //   },
  //   {
  //     type: 'rule.condition',
  //     sourceKey: 'c.["PR/RF"]',
  //     condition: 'contains',
  //     conditionValue: 'pr',x
  //     outputLogic: 'map_from_import',
  //     conditionMapSourceKey: 'c.["PR/RF"]',
  //     destination: 'product',
  //     targetKey: 'pr',
  //     outputType: 'string',
  //   },
  {
    type: 'rule.map_array',
    sourceKey: 'c.image',
    targetKey: 'originalPhotos',
    delimiter: '|',
    destination: 'model',
  },

  ...GLOBAL_RULESET,

];
