import * as z from "zod"
import { CompleteDBProductCategory, RelatedDBProductCategoryModel, CompleteDBServiceCategory, RelatedDBServiceCategoryModel, CompleteDBServiceCode, RelatedDBServiceCodeModel, CompleteDBServiceAppointments, RelatedDBServiceAppointmentsModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBServiceModel = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  servicePrice: jsonSchema,
  serviceDuration: z.number().int().nullish(),
  subcategory: jsonSchema.array(),
  rimDiameter: jsonSchema.array(),
  rft: z.boolean().nullish(),
  deleted: z.boolean().nullish(),
  active: z.boolean(),
  erpId: z.string().nullish(),
  productCategoryId: z.number().int().nullish(),
  serviceCategoryId: z.string(),
  code: z.string().nullish(),
  serviceCodeId: z.string().nullish(),
  canDelete: z.boolean().nullish(),
})

export interface CompleteDBService extends z.infer<typeof DBServiceModel> {
  productCategory?: CompleteDBProductCategory | null
  serviceCategory: CompleteDBServiceCategory
  serviceCode?: CompleteDBServiceCode | null
  appointments: CompleteDBServiceAppointments[]
}

/**
 * RelatedDBServiceModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBServiceModel: z.ZodSchema<CompleteDBService> = z.lazy(() => DBServiceModel.extend({
  productCategory: RelatedDBProductCategoryModel.nullish(),
  serviceCategory: RelatedDBServiceCategoryModel,
  serviceCode: RelatedDBServiceCodeModel.nullish(),
  appointments: RelatedDBServiceAppointmentsModel.array(),
}))
