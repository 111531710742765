import * as z from "zod"
import { CompleteDBTransferOrderItem, RelatedDBTransferOrderItemModel } from "./index"

export const DBClientTransferOrderModel = z.object({
  id: z.string(),
  orderNumber: z.number().int(),
})

export interface CompleteDBClientTransferOrder extends z.infer<typeof DBClientTransferOrderModel> {
  transferOrderItems: CompleteDBTransferOrderItem[]
}

/**
 * RelatedDBClientTransferOrderModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientTransferOrderModel: z.ZodSchema<CompleteDBClientTransferOrder> = z.lazy(() => DBClientTransferOrderModel.extend({
  transferOrderItems: RelatedDBTransferOrderItemModel.array(),
}))
