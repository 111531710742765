import api from '@tyrio/api-factory';
import { DBPosWheelsApi } from '@tyrio/dto';
import { WheelsFilterType } from '../../../../../context/VehicleContext';
import { useQuery } from 'react-query';

export const useGetWheelsFilter = (
  cb: string,
  pcd: string,
  rimDiameter: number[],
  enabled: boolean,
  setBackendFilterData: (a: WheelsFilterType) => void,
  et: number[]
) => {
  const {
    data,
    isFetched,
    isError,
    isLoading,
    refetch,
    isRefetching,
    isFetching,
    status,
    isIdle,
  } = useQuery(
    ['get_wheels_search_filters'],
    () =>
      api.fetch<DBPosWheelsApi['getFilter']>('get_wheels_search_filters', {
        cb,
        pcd,
        rimDiameter,
        et,
      }),

    {
      enabled,
      onSuccess: (res) => {
        setBackendFilterData(res);
      },
    }
  );

  return {
    filterData: data,
    isFetched,
    isError,
    isLoading,
    refetchFilterData: refetch,
    isRefetching,
    isFetching,
    status,
    showLoader: isLoading || isRefetching || isFetching || isIdle || !enabled,
  };
};
