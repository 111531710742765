import { FormShape, TyrioSelectInputOption } from '../types';
import { createInput } from '../utils';
import { zodCategoryObject } from './zodObjects';

export const generateCategoryForm = (categories: TyrioSelectInputOption[]) => {
  const zodSchema = zodCategoryObject;

  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: 'Main',
        inputs: [
          createInput({
            id: 'name',
            width: { xs: 6 },
            type: 'input.string',
            label: 'Name',
          }),
          createInput({
            id: 'slug',
            width: { xs: 6 },
            type: 'input.string',
            label: 'Slug',
          }),
          createInput({
            id: 'parentCategory',
            width: { xs: 4 },
            type: 'input.select',
            options: categories,
            label: 'Choose main category',
          }),
          createInput({
            id: 'taricCode',
            width: { xs: 5 },
            type: 'input.string',
            label: 'Taric code',
          }),
          createInput({
            id: 'remark',
            width: { xs: 12 },
            type: 'input.string',
            label: 'Remark',
          }),
        ],
      },
    ],
  };

  return { form, zodSchema };
};
