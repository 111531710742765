import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const ZBR_CATALOG_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c['Made in']",
    targetKey: 'origin',
    destination: 'product',
    outputType: 'string',
  },
  // Category types
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Gruppe',
    condition: 'equals',
    conditionValues: [
      '000088',
      '000084',
      '000040',
      '000076',
      '000077',
      '000044',
      '000043',
      '000045',
      '000046',
      '000047',
      '000040',
      '000075',
      '000072',
      '000069',
      '000070',
      '000067',
      '000068',
      '000071',
      '000078',
      '000079',
      '000054',
      '000055',
      '000060',
      '000061',
      '000062',
      '000064',
      '000063',
      '000065',
      '000050',
      '000052',
    ],
    conditionOutputs: [
      '77',
      '83',
      '39',
      '40',
      '40',
      '37',
      '37',
      '37',
      '37',
      '37',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
      '36',
    ],
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.Hersteller',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition',
    sourceKey: 'c.Profil',
    condition: 'contains',
    conditionValue: 'M+S',
    outputValue: 'allseason',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // Model
  {
    type: 'rule.map',
    sourceKey: 'c.Profil',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.EAN',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Artnr',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.IPC',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.IPC',
    destination: 'product',
    targetKey: 'manufacturerCode',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.Breite',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [77],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Dimension',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Zoll',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c['TL/TT']",
    targetKey: 'tubeType',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [77],
  },
  // Weight
  {
    type: 'rule.map',
    sourceKey: 'c.weight',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  // Load index and Speed Index
  {
    type: 'rule.map',
    sourceKey: 'c.Load',
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Speed',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [77],
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.speedIndex',
    matchValue: ['X'],
    replaceValue: [''],
    ignoreForCategories: [77],
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'productName2',
    template: '',
  },
  // {
  //   type: 'rule.global.operate',
  //   destination: 'product',
  //   targetKey: 'specialMarking',
  //   operationName: 'getZbrSpecialMarkings',
  // },

  // Construction, Structure, Axle, Model, Sidewall, RFD
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalZbrFields',
  },

  ...GLOBAL_RULESET,
];
