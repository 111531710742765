import * as z from "zod"
import { DBRebateGroupType, DBRebateCalculationType, DBPriceRound } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBPartner, RelatedDBPartnerModel, CompleteDBBranch, RelatedDBBranchModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBClientRebateCalculationModel = z.object({
  id: z.string(),
  clientId: z.string(),
  groupName: z.string(),
  groupType: z.nativeEnum(DBRebateGroupType),
  rebate: z.number(),
  discount: z.number(),
  calculationType: z.nativeEnum(DBRebateCalculationType),
  priceRound: z.nativeEnum(DBPriceRound),
  rules: jsonSchema,
  isActive: z.boolean(),
  createdAt: z.date(),
})

export interface CompleteDBClientRebateCalculation extends z.infer<typeof DBClientRebateCalculationModel> {
  client: CompleteDBClient
  partners: CompleteDBPartner[]
  branches: CompleteDBBranch[]
}

/**
 * RelatedDBClientRebateCalculationModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientRebateCalculationModel: z.ZodSchema<CompleteDBClientRebateCalculation> = z.lazy(() => DBClientRebateCalculationModel.extend({
  client: RelatedDBClientModel,
  partners: RelatedDBPartnerModel.array(),
  branches: RelatedDBBranchModel.array(),
}))
