import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';
import { WHEEL_CATEGORY_SLUGS } from './slugs';
// import { remove } from 'lodash';

export function globalGetTyrooSpecialMarkings(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetTyrooSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = input['list designation 2'];

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () =>
      ['RFT', 'ROF'].some((substr) =>
        input['Tyroo goods group name'].toUpperCase().includes(substr)
      ),
    true
  );

  return data;
}

export function globalGetTyrooConstruction(
  input: RawImport,
  rule: TRule,
  data: UnfinishedData
) {
  const construction = WHEEL_CATEGORY_SLUGS.includes(data.model['categorySlug'])
    ? ''
    : input['Tyroo goods group name'].toUpperCase().includes('DIAGONAL') ||
      input['Tyroo goods group name'] === 'Geländesport NHS' ||
      input['Tyroo goods group name'] === 'Mofa/Mopedreifen'
    ? '-'
    : 'R';

  data.product['construction'] = construction;

  return data;
}

export function calculateTyrooPackageQuantity(
  input: RawImport,
  _rule: TRule,
  data: UnfinishedData
) {
  let packageQuantity = 1;
  if (input['quantity per package']) {
    packageQuantity = parseFloat(input['quantity per package']);
  }
  if (
    [36, 37, 38, 39, 40, 41, 42].includes(data.model['category_id']) &&
    parseFloat(data.product['width']) >= 200
  ) {
    packageQuantity = 2;
  }
  if (
    [36, 37, 38, 39, 40, 41, 42].includes(data.model['category_id']) &&
    parseFloat(data.product['width']) < 200
  ) {
    packageQuantity = 3;
  }

  return packageQuantity;
}

export function getTyrooEprelId(
  input: RawImport,
  _rule: TRule,
  _data: UnfinishedData
) {
  let eprelId = '';

  if (input['EPREL-DB-link'] && input['EPREL-DB-link'].length >= 52) {
    eprelId = input['EPREL-DB-link'].slice(46, 52);
  }

  return eprelId;
}

export function getTyrooMotoModel(
  input: RawImport,
  _rule: TRule,
  data: UnfinishedData
) {
  // console.log("PRIJE :" + input['list designation 2'])
  const substringsToRemove = [
    ' FRONT/REAR',
    'F/R',
    ' FRONT',
    ' REAR',
    ' TL/TT',
    ' TL',
    ' TT',
  ];

  const pattern = substringsToRemove
    .map((substring) => substring.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'))
    .join('|');

  const regex = new RegExp(pattern, 'gi');

  let modelName = input['list designation 2'].replace(regex, '').trim();

  // Check if it ends with F (as in FRONT) or R (as in Rear) and remove it
  if (
    modelName.toUpperCase().endsWith(' F') ||
    modelName.toUpperCase().endsWith(' R')
  ) {
    modelName = modelName.slice(0, -2);
  }

  data.model['modelName'] = modelName;
  // console.log("POSLI :" + modelName)
  return data;
}

export function getTyrooPCD(
  input: RawImport,
  _rule: TRule,
  data: UnfinishedData
) {
  let pcd: string[] = [];

  if (input['rim number of holes'] && input['rim pitch circle 1']) {
    pcd = [
      input['rim number of holes'] +
        'x' +
        input['rim pitch circle 1'].replace('.30', '.3').replace('.00', ''),
    ];
  }

  data.product['pcd'] = pcd;

  return data;
}
