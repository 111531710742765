import _ from 'lodash';
import { useFilter } from '../../../../../hooks/useFilter';
import { HeaderText } from '../../../steps/Search/SelectStepper';
import { Card, CardWrapper, Container, StyledCheckbox } from './BrandSelector';

interface ColorGroupSelectorProps {
  label: string;
  options: string[];
}

export const ColorGroupSelector = ({
  label,
  options,
}: ColorGroupSelectorProps) => {
  const { ...filtersHook } = useFilter();

  const get = (path: string) =>
    _.get(filtersHook.filterValues, path, []) as string[];

  const initialSelectedValues: string[] = get('color');

  let selectedValues = initialSelectedValues;

  const handleSelectValue = (isSelected: boolean, checkboxKey: string) => {
    if (isSelected) {
      selectedValues.push(checkboxKey);
    } else selectedValues = selectedValues.filter((el) => el !== checkboxKey);

    filtersHook.setFilterValue({
      ...filtersHook.filterValues,
      color: selectedValues,
    });
  };

  return (
    <Container>
      <HeaderText>{label}</HeaderText>

      <CardWrapper>
        {options.map((value, key) => {
          const isSelected = selectedValues.includes(value);

          return (
            <Card
              onClick={() => {
                handleSelectValue(!isSelected, value);
              }}
              selected={isSelected}
              key={key}
              width={180}
              style={{ fontSize: '14px' }}
            >
              <StyledCheckbox
                type="checkbox"
                key={`checkbox_${value}`}
                checked={isSelected}
                onChange={() => null}
                style={{ marginRight: '10px' }}
              />
              {value}
            </Card>
          );
        })}
      </CardWrapper>
    </Container>
  );
};
