/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { DeliveryType } from '@prisma/client';
import { ClientShippingResponse } from '@tyrio/dto';
import {
  Control,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { LoaderWrapper } from '../../components/PageTemplate/PageTemplate';
import { useGetShippingCouriers } from '../../pages/dashboard/shipping-couriers/query/get-shipping-couriers';
import { renderFields } from '../services/components/RenderFormFields';
import { FormShape } from '../services/constants/service.form';
import { DeliveryMethodFormShape } from './constants/delivery-method.form';
import { useGetDeliveryTypeById } from './querys/get-one';

interface DeliveryMethodFormProps {
  isNew: boolean;
  deliveryType: DeliveryType;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: {
    [x: string]: any;
  };
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (payload: FieldValues) => void;
}

export const DeliveryMethodForm = ({
  isNew,
  deliveryType,
  register,
  control,
  errors,
  handleSubmit,
  onSubmit,
}: DeliveryMethodFormProps) => {
  const { couriersData } = useGetShippingCouriers();

  const couriers = couriersData.filter(
    (courier: ClientShippingResponse) => courier.active
  );

  const { id } = useParams<{ id: string }>();
  const { form } = DeliveryMethodFormShape(couriers, deliveryType);
  const { isFetchingDelivery } = useGetDeliveryTypeById(id);

  return (
    <Wrapper>
      {isFetchingDelivery ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <div style={{ height: '100%' }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            key={id}
            style={{ padding: '16px 24px' }}
          >
            {form.map((fields: FormShape) => {
              if (!isNew && fields.key === 'code_name')
                fields.inputs[0].disabled = true;

              // if (!isNew && fields.key === 'delivery_type')
              //   fields.inputs[0].disabled = true;

              return (
                <div style={{ paddingBottom: '25px' }} key={fields.key}>
                  {renderFields(
                    fields,
                    register,
                    control,
                    errors,
                    false,
                    'delivery'
                  )}
                </div>
              );
            })}
          </form>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`;
