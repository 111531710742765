import * as z from "zod"
import { DBRepeatUnit } from ".prisma/client"
import { CompleteDBSupplierConnection, RelatedDBSupplierConnectionModel } from "./index"

export const DBFtpConnectionModel = z.object({
  id: z.string(),
  supplierFileName: z.string(),
  fileLocation: z.string(),
  ftpHost: z.string(),
  ftpPort: z.string(),
  ftpUserName: z.string(),
  ftpPassword: z.string(),
  startUpdateTime: z.date(),
  repeatEvery: z.number().int().nullish(),
  repeatUntil: z.nativeEnum(DBRepeatUnit),
  supplierConnectionId: z.string().nullish(),
  remark: z.string(),
})

export interface CompleteDBFtpConnection extends z.infer<typeof DBFtpConnectionModel> {
  supplierConnection?: CompleteDBSupplierConnection | null
}

/**
 * RelatedDBFtpConnectionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBFtpConnectionModel: z.ZodSchema<CompleteDBFtpConnection> = z.lazy(() => DBFtpConnectionModel.extend({
  supplierConnection: RelatedDBSupplierConnectionModel.nullish(),
}))
