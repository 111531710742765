import styled from '@emotion/styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import { FunctionComponent, SVGProps, useState } from 'react';

interface InformationCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: string;
  onClick: () => void;
  disabled: boolean;
}

export const InformationCard = ({
  icon,
  text,
  onClick,
  disabled,
}: InformationCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Wrapper
      id="information_card"
      disabled={disabled}
      onClick={() => {
        !disabled && onClick();
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <InfoWrapper>
        <WmsIcon
          icon={icon}
          sx={{
            color: isHovered ? '#1976D2' : tyrioUI.colors.black.B40,
            width: '30px',
            height: '30px',
          }}
          viewBox={'0 0 32 32'}
          stroke={isHovered ? '#1976D2' : tyrioUI.colors.black.B40}
        />
        <Text ishovered={`${isHovered}`}>{text}</Text>
      </InfoWrapper>
      {isHovered && (
        <Button
          endIcon={<AddCircleOutlineIcon color="info" />}
          color="info"
          onClick={onClick}
        >
          ADD
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled: boolean }>`
  border: 1px dashed #e6e6e6;
  border-radius: 8px;
  height: 74px !important;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  cursor: pointer;
  &:hover {
    border: 1px solid #1976d2;
    background: rgb(25, 117, 210, 0.04);
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`;

const Text = styled(Typography)<{ ishovered: string }>`
  color: ${(props) => (props.ishovered === 'true' ? 'black' : '#919eab')};
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
`;
