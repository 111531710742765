import api from '@tyrio/api-factory';
import { IWheelsApiData, WheelSizeApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useSearchByModel = (
  make: string,
  model: string,
  generation: string,
  modification: string,
  enabled: boolean,
  onSuccess: (data: IWheelsApiData[]) => void
) => {
  const { data, isFetched, isError, isLoading } = useQuery(
    ['search_by_model'],
    () =>
      api.fetch<WheelSizeApi['getSearch']>('search_by_model', {
        make,
        model,
        generation,
        modification,
      }),

    {
      enabled,
      onSuccess: (res) => {
        const data = res.data;
        onSuccess(data);
      },
    }
  );

  return {
    data: data?.data ?? [],
    isFetched,
    isError,
    isLoading,
  };
};
