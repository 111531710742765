/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import { Alert, AlertTitle, colors } from '@mui/material';
import { DBDeliveryLocation, DeliveryType } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBCountryApi, DeliveryZones } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { renderFields } from '../services/components/RenderFormFields';
import { FormShape } from '../services/constants/service.form';
import { ZONES_INITIAL_DATA } from './constants/initial-values';
import { DeliveryPriceListFormShape } from './constants/price-list.form';
import { ZonesForm } from './ZonesForm';

interface FormContainerProps {
  title: string;
  fields: any;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: {
    [x: string]: any;
  };
}

const FormContainer = ({
  title,
  fields,
  register,
  control,
  errors,
}: FormContainerProps) => {
  return (
    <FormWrapper>
      <Title>{title}</Title>
      {fields.map((item: FormShape, index: number) => {
        return (
          <div
            key={`${fields.key}-${index}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
            }}
          >
            {renderFields(item, register, control, errors, false, 'delivery')}
          </div>
        );
      })}
    </FormWrapper>
  );
};

interface PriceListProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: {
    [x: string]: any;
  };
  watch: UseFormWatch<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (payload: FieldValues) => void;
  zonesData: DeliveryZones[];
}

export const PriceList = ({
  register,
  control,
  errors,
  watch,
  handleSubmit,
  onSubmit,
  zonesData,
}: PriceListProps) => {
  const { id } = useParams<{ id: string }>();
  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);
  const [zones, setZones] = useState<DeliveryZones[]>(zonesData);

  const deliveryType = watch('deliveryTypeEnum');
  const deliveryLocation = watch('deliveryLocation');

  const { mainSettingsForm, priceForm, surchargeForm, collectionSurcharge } =
    DeliveryPriceListFormShape(
      deliveryType,
      watch('cashOnDelivery'),
      watch('cardOnDelivery'),
      watch('freeDelivery'),
      countries
    );

  const [formInputs, setFormInputs] = useState<{
    settings: FormShape[];
    price: FormShape[];
    surcharge: FormShape[];
  } | null>({
    settings: [],
    price: [],
    surcharge: [],
  });

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setCountries(dropdownItems);
      },
    }
  );

  useEffect(() => {
    if (deliveryType === DeliveryType.WMS_DISPATCH) {
      setFormInputs({
        settings: mainSettingsForm,
        price: priceForm,
        surcharge: surchargeForm,
      });
    } else if (deliveryType === DeliveryType.WMS_COLLECTION) {
      setFormInputs({
        settings: mainSettingsForm,
        price: priceForm,
        surcharge: collectionSurcharge,
      });
    } else if (deliveryLocation === DBDeliveryLocation.DOMESTIC) {
      setFormInputs({
        settings: mainSettingsForm,
        price: priceForm,
        surcharge: surchargeForm,
      });
    } else setFormInputs(null);
  }, [
    collectionSurcharge,
    deliveryLocation,
    deliveryType,
    mainSettingsForm,
    priceForm,
    surchargeForm,
  ]);

  const addNewZone = () => {
    const newZone = {
      ...(ZONES_INITIAL_DATA as unknown as DeliveryZones),
    };

    setZones((currentZones) => [...currentZones, newZone]);
  };

  const deleteZone = (id: number) => {
    zones.splice(id);
  };

  return (
    <div>
      {watch('deliveryTypeEnum') !== '' ? (
        <Container>
          <form
            key={id}
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            {formInputs && formInputs.settings.length > 0 && (
              <FormContainer
                title={formInputs.settings[0].title ?? 'Default Settings'}
                fields={formInputs.settings}
                errors={errors}
                control={control}
                register={register}
              />
            )}
            {formInputs && formInputs.price.length > 0 && (
              <FormContainer
                title={formInputs.price[0].title ?? 'Default Prices'}
                fields={formInputs.price}
                errors={errors}
                control={control}
                register={register}
              />
            )}
            {formInputs && formInputs.surcharge.length > 0 && (
              <FormContainer
                title={formInputs.surcharge[0].title ?? 'Default Surcharge'}
                fields={formInputs.surcharge}
                errors={errors}
                control={control}
                register={register}
              />
            )}

            {deliveryType === 'WMS_SHIPPING' && (
              <ZonesForm
                errors={errors}
                control={control}
                register={register}
                zonesForm={zones}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                watch={watch}
                deleteZone={deleteZone}
              />
            )}
          </form>

          {deliveryType === 'WMS_SHIPPING' && (
            <div style={{ marginTop: '25px' }}>
              <ActionButton onClick={addNewZone}>
                {zones.length > 0 ? 'ADD NEW +' : 'ADD NEW ZONE +'}
              </ActionButton>
            </div>
          )}
        </Container>
      ) : (
        <Alert severity="warning">
          <AlertTitle>
            {
              'You can`t add price list information if you haven`t selected the delivery type!'
            }
          </AlertTitle>
          {'Please select delivery type!'}
        </Alert>
      )}
    </div>
  );
};

const FormWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid #dfe3e8;
  background: #f9fafb;
  padding: 16px;
`;

const Title = styled.div`
  color: #212b36;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${colors.lightBlue[800]};
  border: 1px solid rgba(25, 118, 210, 0.5);
  border-radius: 8px;
  background: none;
  cursor: pointer;
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: 36px;
  :hover {
    background: rgba(25, 118, 210, 0.04);
    border: 1px solid #356df0;
  }
`;
