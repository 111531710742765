import styled from '@emotion/styled/macro';
import { QRCode } from 'antd';
import { DocumentType } from '../../../context/PrintContext';
import '../style.css';

interface PdfHeaderProps {
  logoUrl?: string;
  orderNumber: string;
  documentType: DocumentType;
  headerText: string;
}

export const PdfHeader = ({
  logoUrl,
  orderNumber,
  documentType,
  headerText,
}: PdfHeaderProps) => {
  return (
    <Wrapper className="header">
      <HeaderWrapper>
        {logoUrl && (
          <img
            src={logoUrl}
            alt="Documents look logo"
            style={{
              maxWidth: '320px',
              maxHeight: '60px',
              width: '100%',
              height: '100%',
            }}
          />
        )}

        <HeaderText>{headerText}</HeaderText>
      </HeaderWrapper>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'start',
            marginRight: '5px',
          }}
        >
          <DispatchNote>{documentType}</DispatchNote>
          <DispatchNoteNumber>{orderNumber}</DispatchNoteNumber>
        </div>

        <QRCode
          value={orderNumber}
          size={90}
          bordered={false}
          includeMargin={false}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 150px;
`;

const HeaderWrapper = styled.div`
  max-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 400px;
`;

const DispatchNote = styled.div`
  color: #637381;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
`;

const DispatchNoteNumber = styled.div`
  color: #454f5b;
  font-family: Barlow;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
`;

const HeaderText = styled.div`
  color: #000;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: start;
  width: 100%;
`;
