import { DBSupplierContact } from '@prisma/client';
import { suppliers } from './suppliers';

export const supplier_contacts: DBSupplierContact[] = [
  {
    id: '00000000-0000-0000-0000-000000000001',
    supplierId: '00000000-0000-0000-0000-000000000001',
    firstName: 'Haris',
    lastName: 'Pandzic',
    email: 'haris.pandzic@continental.com',
    businessPhone: '+38762111111',
    mobilePhone: '+38762111112',
    remark: 'haris pandzic continental remark',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    supplierId: '00000000-0000-0000-0000-000000000001',
    firstName: 'Emir',
    lastName: 'Brackovic',
    email: 'emir.brackovic@continental.com',
    businessPhone: '+38762111113',
    mobilePhone: '+38762111114',
    remark: 'emir brackovic continental remark',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    supplierId: suppliers[1].id,
    firstName: 'Adna',
    lastName: 'Pasic',
    email: 'adna.pasic@michelin.com',
    businessPhone: '+38762111114',
    mobilePhone: '+38762111115',
    remark: '',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000004',
    supplierId: suppliers[2].id,
    firstName: 'Nedim',
    lastName: 'Muhamedagic',
    email: 'nedim.muhamedagic@pirelli.com',
    businessPhone: '+387621111122',
    mobilePhone: '+387621111121',
    remark: '',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000005',
    supplierId: suppliers[3].id,
    firstName: 'Adna',
    lastName: 'Alicic',
    email: 'adna.alicic@goodyear.com',
    businessPhone: '+387621111129',
    mobilePhone: '+3876211111209',
    remark: '',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000006',
    supplierId: suppliers[4].id,
    firstName: 'Youseff',
    lastName: 'Aly',
    email: 'youseff.aly@dunlop.com',
    businessPhone: '+3876211111200',
    mobilePhone: '+38762111112090',
    remark: '',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000007',
    supplierId: suppliers[5].id,
    firstName: 'Neira',
    lastName: 'Piranic',
    email: 'neira@yokohama.com',
    businessPhone: '+38762111112045',
    mobilePhone: '+387621111120987',
    remark: '',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000008',
    supplierId: suppliers[6].id,
    firstName: 'Faruk',
    lastName: 'Tahtovic',
    email: 'faruk@toyotires.com',
    businessPhone: '+387621111120427',
    mobilePhone: '+3876211111209872',
    remark: '',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000009',
    supplierId: suppliers[7].id,
    firstName: 'Ismet',
    lastName: 'Ferhatbegovic',
    email: 'ismet@toyotires.com',
    businessPhone: '+387621111120467',
    mobilePhone: '+3876211111209809',
    remark: '',
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000010',
    supplierId: suppliers[8].id,
    firstName: 'Haris',
    lastName: 'Pandzic',
    email: 'harsi@conintentalbih.com',
    businessPhone: '+387631111120467',
    mobilePhone: '+387631111120980',
    remark: '',
    createdAt: new Date(),
  },
];
