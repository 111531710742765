import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { tyrioUI } from '@tyrio/ui-library';

interface FilterContainerProps {
  onOpen: () => void;
  children?: JSX.Element;
}

const FilterContainer = ({ onOpen, children }: FilterContainerProps) => {
  return (
    <Container>
      <Tooltip title="Choose additional filters" placement="top">
        <IconButton onClick={onOpen}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Stack direction="row" flexWrap="wrap" spacing={3} sx={{ width: '100%' }}>
        {children}
      </Stack>
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  padding: ${tyrioUI.spacing.l}px;
  display: flex;
  align-items: center;
`;

export default FilterContainer;
