import { PosCartContext } from '../../../../../../context/PosCartContext';
import { useContext, useState } from 'react';
import { SideMenuLayout } from '../../../../../../components/SideMenuLayout/SideMenuLayout';
import { InfoFooter } from '../Footer';
import { CustomerForm } from './CustomerForm';
import { wmsIcons } from '@tyrio/wms-ui-library';
import {
  Text,
  WmsIconStyled,
} from '../../../../../../components/SideMenuLayout/SideMenuLayout.style';
import {
  DeleteWrapper,
  MainWrapper,
} from '../../../../styles/CustomerForm.style';
import { POSContext } from '../../../../../../context/POSContext';
import { customToast } from '../../../../../stock-list/components/Cart/CartToast';
import { CancelModal, DiscardModal } from '@tyrio/ui-library';
import {
  companyInputFields,
  customerInputFields,
} from '../../../../constants/cart-customer-constants';
import {
  checkIfValidPhoneNumber,
  emailRegex,
} from '../../../../../supplier-form/helpers/regex';
import { isEmpty } from 'lodash';

export const Customer = () => {
  const { selectedCustomer } = useContext(POSContext);
  const {
    setActiveInfo,
    input,
    setInput,
    customerDetails,
    setCustomerDetails,
  } = useContext(PosCartContext);
  const [switchChecked, setSwitchChecked] = useState(
    selectedCustomer !== null || input.isCustomerSwitchActive
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);

  //   CONSTANTS
  const activeForm = switchChecked ? companyInputFields : customerInputFields;

  const handleCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else setActiveInfo('');
  };

  const handleSubmit = () => {
    if (isEmpty(customerDetails)) {
      customToast('No changes were made, please cancel instead.', 'error');
    } else {
      if (validateForm()) {
        setInput((prevState) => ({
          ...prevState,
          customer: customerDetails,
          isCustomerEditDisabled: true,
          isCustomerSwitchActive: customerDetails['vat'] ? true : false,
          isSudRegCustomer: customerDetails['vat'] ? true : false,
        }));
        customToast('Customer successfully saved.', 'success');
        setActiveInfo('');
      }
    }
  };

  const handleDiscard = () => {
    setInput((prevState) => ({
      ...prevState,
      customer: {},
      isCustomerEditDisabled: false,
      isCustomerSwitchActive: false,
    }));
    setCustomerDetails({});
    setIsDiscardModalVisible(false);
    setActiveInfo('');
    setIsDirty(false);
  };

  const validateForm = () => {
    let shouldSaveCustomer = true;
    activeForm.forEach((fields) => {
      fields.forEach((field) => {
        // validate required fields
        if (
          field.required &&
          (customerDetails[field.id] === undefined ||
            customerDetails[field.id] === '')
        ) {
          field.helperText = 'This field is required!';
          shouldSaveCustomer = false;
        } else {
          field.helperText = '';
        }

        // country validation
        if (
          field.required &&
          field.id === 'country' &&
          customerDetails[field.id] === undefined
        ) {
          field.helperText = 'Field is required!';
          shouldSaveCustomer = false;
        } else if (field.id === 'country') {
          field.helperText = '';
          shouldSaveCustomer = true;
          field.disabled = true;
        }

        if (
          field.id === 'mobile_phone' &&
          customerDetails[field.id] !== undefined &&
          customerDetails[field.id] !== ''
        ) {
          // validate mobile phone
          const numberLength = customerDetails[field.id].length;
          if (
            numberLength > 3 &&
            (!checkIfValidPhoneNumber('+' + customerDetails[field.id]) ||
              numberLength < 12 ||
              numberLength > 15)
          ) {
            field.helperText = 'Number must be between 12 and 15 digits!';
            shouldSaveCustomer = false;
          } else field.helperText = '';
        }
        // validate email
        if (field.id === 'email') {
          if (
            customerDetails[field.id] &&
            customerDetails[field.id] !== '' &&
            !emailRegex.test(customerDetails[field.id])
          ) {
            field.helperText = 'Email format is incorrect!';
            shouldSaveCustomer = false;
          } else {
            field.helperText = '';
          }
        }
      });
    });
    return shouldSaveCustomer;
  };

  const renderCustomer = () => {
    return (
      <MainWrapper>
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              setCustomerDetails(input.customer);
              setIsCancelModalVisible(false);
              setActiveInfo('');
              setIsDirty(false);
            }}
          />
        )}
        {isDiscardModalVisible && (
          <DiscardModal
            LBAction={() => setIsDiscardModalVisible(false)}
            RBAction={handleDiscard}
          />
        )}
        <CustomerForm
          activeSwitch={switchChecked}
          setActiveSwitch={setSwitchChecked}
          setIsDirty={setIsDirty}
        />

        <DeleteWrapper onClick={() => setIsDiscardModalVisible(true)}>
          <Text id="delete_text">Discard</Text>
          <WmsIconStyled icon={wmsIcons.bin} id="delete_icon" />
        </DeleteWrapper>
      </MainWrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posCustomer"
      children={renderCustomer()}
      showSwitch={true}
      switchLabel="Company"
      checked={false}
      shouldShowTitle={true}
      switchChecked={switchChecked}
      switchDisabled={selectedCustomer !== null || input.isCustomerSwitchActive}
      setSwitchChecked={setSwitchChecked}
      footer={
        <InfoFooter
          text1="CANCEL"
          text2="SUBMIT"
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      }
      viewBox={'0 0 32 32'}
    />
  );
};
