import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { RONAL_CATALOG_RULESET } from './ronal/catalog';
import { RONAL_PRICELIST_RULESET } from './ronal/pricelist';
import { RONAL_STOCKLIST_RULESET } from './ronal/stocklist';

export const RONAL_SUPPLIER: SupplierShape = {
  weight: 101,
  override: true,
  ruleset: RONAL_CATALOG_RULESET,
  pricelistRuleset: RONAL_PRICELIST_RULESET,
  stockRuleset: RONAL_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      origin: 'DE',
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'RONAL',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    Width: {
      ',': '.',
    },
    'Weight, kg': {
      ',': '.',
    },
    'Center bore': {
      ',': '.',
    },
    Offset: {
      ',': '.',
    },
    'Bolt circle': {
      ',': '.',
    },
    'Your purchase price': {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter((item) => !!item['EAN Code']);

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const headers = [
      'manufacturerId',
      'manufacturerCode',
      'colC',
      'productName',
      'vehicleBrand',
      'color',
      'modelName',
      'brand',
      'size',
      'et',
      'pcd',
      'cb',
      'quantity',
      'colN',
      'colO',
      'colP',
      'ean',
    ];
    const fileWithHeader = `${headers.join(';')}\n${file}`;

    // console.log('READ FILE RONAL:', fileWithHeader);
    const json = await csv2json({ delimiter: ';' }).fromString(fileWithHeader);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    // console.log('JSON :', json.length);

    const list = json.filter((item) => !!item['EAN Code']);

    // console.log('LIST :', list.length);

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
