import api from '@tyrio/api-factory';
import {
  DBPosWheelsApi,
  IFilterData,
  SearchFilters,
  SearchWheelsResponse,
} from '@tyrio/dto';
import { IVehicleFilter } from '../../../../../context/VehicleContext';
import { useQuery } from 'react-query';
import { get } from 'lodash';

export const getWheelsQueryFunction = (
  page: number,
  pageSize: number,
  filter: SearchFilters
) => {
  return api.fetch<DBPosWheelsApi['list']>('get_wheels', {
    page,
    pageSize,
    filter: {
      ...filter,
    },
  });
};

export const useGetWheels = (
  onSuccess: (a: SearchWheelsResponse[], b?: IFilterData) => void,
  page: number,
  pageSize: number,
  cb: string,
  pcd: string,
  et: number[],
  filter: IVehicleFilter,
  enabled: boolean,
  warehouseId?: string
) => {
  const central_bore = filter.cb ? filter.cb : [cb];
  const brand = get(filter, 'brand', undefined) ?? undefined;
  const modelName = get(filter, 'modelName', undefined) ?? undefined;
  const colorFinish = get(filter, 'colorFinish', undefined) ?? undefined;
  const availability = get(filter, 'availability', undefined) ?? undefined;

  const {
    data,
    isFetched,
    isError,
    isLoading,
    refetch,
    status,
    isRefetching,
    isFetching,
  } = useQuery(
    [`get_wheels_${filter}`],
    () =>
      getWheelsQueryFunction(page, pageSize, {
        cb: central_bore,
        pcd,
        et,
        width: filter.width ?? undefined,
        rimDiameter: filter.rimDiameter ?? undefined,
        colorGroup: filter.colorGroup ?? undefined,
        brand,
        modelName,
        colorFinish,
        availability,
        warehouseId,
      }),
    {
      enabled,
      keepPreviousData: true,
      onSuccess: (res) => {
        if (onSuccess) onSuccess(res.data, res?.filterData);
      },
    }
  );

  return {
    data: data?.data,
    isFetched,
    isError,
    isLoading,
    refetch,
    status,
    isRefetching,
    hasNextPage: data?.hasNextPage ?? false,
    count: data?.count ?? 0,
    showLoader: isRefetching || isLoading || isFetching,
  };
};
