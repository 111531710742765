import React from 'react';

import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import AdminUpsertForm from '../features/admin-form/AdminUpsertForm';
import DashboardAdmin from '../pages/dashboard/Admin';
export const AdminRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <DashboardAdmin>
        {location.pathname.split('/').pop() === 'new' ? (
          <Route exact path={`${path}/new`}>
            <AdminUpsertForm />
          </Route>
        ) : (
          <Route exact path={`${path}/:adminId`}>
            <AdminUpsertForm />
          </Route>
        )}
      </DashboardAdmin>
    </Switch>
  );
};
