import { TRule } from '../../typings';

export const GMPITALY_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['ean_code']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['gmp_code']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'brand',
    template: 'GMP ITALY',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['price']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
