import { ENDPOINTS } from '@tyrio/api-factory';
import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';
// import { queryClient } from '../../../../query-client';
import { WarehouseContext } from '../../../context/WarehouseContext';

export const useUploadWarehouse = () => {
  const { setWarehouse, clearSelected } = useContext(WarehouseContext);

  return useMutation(
    (data: FormData) =>
      axios.post(`${ENDPOINTS.upload_warehouse.uri}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@@apiToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      mutationKey: 'upload_warehouse',
      onSuccess: (d) => {
        setWarehouse(d.data);
        // queryClient.refetchQueries('warehouse');
        // queryClient.refetchQueries('client_warehouses');
        clearSelected();
      },
    }
  );
};
