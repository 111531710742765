/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material';
import { DBProductCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBProductApi,
  DBProductCategoryApi,
  DBProductModelApi,
} from '@tyrio/dto';
import SourceContainer, { PermissionsValues } from '@tyrio/forms';
import {
  GLOBAL_MODEL_UID_RULE,
  GLOBAL_PRODUCT_NAME_RULE,
  GLOBAL_RULESET,
  GLOBAL_SIZE_RULE,
  ImportRulesBase,
  BICYCLE_SIZE_RULE,
} from '@tyrio/rule-handler';
import {
  COMPOUND_DROPDOWN_DATA,
  CONSTRUCTION_DROPDOWN_DATA,
  COUNTRY_DROPDOWN_DATA,
  EU_DIRECTIVE_NUMBER_DROPDOWN_DATA,
  HOLE_CIRCLE_DROPDOWN_DATA,
  HOLE_NUMBER_DROPDOWN_DATA,
  INSTALLATION_KIT_DROPDOWN_DATA,
  NOISE_CLASS_TYPE_DROPDOWN_DATA,
  PR_DROPDOWN_DATA,
  RFD_DROPDOWN_DATA,
  RFD_KEYS,
  ROLLING_RESISTANCE_DROPDOWN_DATA,
  SPECIAL_MARKING,
  SPECIAL_MARKING_WO_SIDEWALL_DROPDOWN_DATA,
  SPEED_INDEX_DROPDOWN_DATA,
  STRUCTURE_DROPDOWN_DATA,
  TUBE_TYPE_DROPDOWN_DATA,
  VEHICLE_CLASS_DROPDOWN_DATA,
  WET_GRIP_DROPDOWN_DATA,
  WHEEL_HEIGHT_DROPDOWN_DATA,
  WHEEL_RIM_DIAMETER_DROPDOWN_DATA,
  WHEEL_WIDTH_DROPDOWN_DATA,
  splitPCD,
} from '@tyrio/shared-vars';
import {
  CancelModal,
  DeleteModal,
  RouteRouterPrompt,
  ToastHelper,
  ToastMessageType,
  ToastType,
  backIcon,
} from '@tyrio/ui-library';
import _, { get, startCase } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import {
  getAutocompleteSelectChip,
  getAutocompleteSingleField,
  getCheckbox,
  getTextField,
} from '../../form-helper';
import ChangeModel from './components/ChangeModel';
import FormSectionWithTitle, {
  FormSectionWithLabel,
} from './components/FormSectionWithTitle';
import ProductSidebar from './components/ProductSidebar';
interface ProductItemPageProps {
  product?: DBProductApi['getOne']['response'];
  model?: DBProductModelApi['getOne']['response'];
  permissions?: PermissionsValues;
  productId: string;
}

const ProductItemPage = ({
  permissions,
  product,
  model,
  productId,
}: ProductItemPageProps) => {
  const isEditDisabled = !permissions?.edit || false;
  const history = useHistory();
  const historyState = history.location.state as Record<string, any>;

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const customDirty =
    useRef<Record<string, boolean>>(
      product?.dirtyFields as Record<string, boolean>
    ) || {};
  const x = useMemo(() => new ImportRulesBase(), []);

  const { data: mainCategories } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  );

  const shapedCategories = useMemo(() => {
    const response: Record<number, DBProductCategory> = {};
    mainCategories?.forEach((c) => {
      response[c.id] = c;
    });
    return response;
  }, [mainCategories]);

  const mutation = useMutation(
    (data: DBProductApi['upsert']['requestBody']) =>
      api.fetch('upsert_product', { ...data, uid: productId }),
    {
      mutationKey: 'upsert_product',
      onSuccess: (data: any) => {
        ToastHelper.showToast(
          'Product',
          ToastType.SUCCESS,
          product?.uid ? ToastMessageType.UPDATE : ToastMessageType.CREATE
        );

        if (data.uid && !product?.uid) {
          history.goBack();
        }
      },
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  const handleDeleteProduct = () => {
    if (product?.uid) setIsDeleteModalVisible(true);
  };

  const deleteProductMutation = useMutation(
    () =>
      api.fetch<DBProductApi['getOne']>('delete_product', {
        id: product?.uid,
      }),
    {
      mutationKey: 'delete_product',
      onSuccess: () => {
        history.push(
          `/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1`
        );
        ToastHelper.showToast(
          'Product',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Product',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const productAttributesToSkip = ['uid'];

  const generateDefaultValues = () => {
    const stateData = {};
    if (historyState?.['data']) {
      Object.assign(stateData, historyState?.['data']);
    }
    let productOrState: DBProductApi['getOne']['response'] | undefined;

    if (Object.keys(stateData).length > 0)
      productOrState = stateData as
        | DBProductApi['getOne']['response']
        | undefined;
    else if (product) productOrState = product;
    if (
      productOrState?.dirtyFields &&
      typeof productOrState?.dirtyFields === 'string'
    )
      productOrState.dirtyFields = JSON.parse(
        productOrState.dirtyFields || '{}'
      );

    const unfinishedData = {
      model: {
        ...(model || {}),
      },
      product: {
        uid: productId,
        ean: '',
        rfd: [],
        specialMarking: [],
        ...(productOrState || {}),
      },
    };

    unfinishedData.product.rfd = Object.values(
      _.get(unfinishedData, 'product.rfd', {}) || {}
    )
      .map((item: any) => item.value)
      .join(' ');

    unfinishedData.product.holeCircle = Object.values(
      _.get(unfinishedData, 'product.holeCircle', {}) || {}
    ).map((item: any) => item.value);

    unfinishedData.product.holeNumber = Object.values(
      _.get(unfinishedData, 'product.holeNumber', {}) || {}
    ).map((item: any) => item.value);

    // unfinishedData.product.pcd = Object.values(
    //   _.get(unfinishedData, 'product.pcd', {}) || {}
    // ).map((item: any) => item.value);

    unfinishedData.product.specialMarking = Object.values(
      _.get(unfinishedData, 'product.specialMarking', {}) || {}
    )
      .map((item: any) => item.value)
      .join(' ');

    x._handleRules(GLOBAL_RULESET, {}, unfinishedData);

    if (!productOrState)
      return {
        modelName: model,
        ms: unfinishedData.product.ms,
        threepmfs: unfinishedData.product.threepmfs,
        iceGrip: unfinishedData.product.iceGrip,
      };

    const { productMeta } = productOrState;

    const rfd = Object.values(_.get(productMeta, 'rfd', {})).map(
      (item: any) => ({
        label: [item.name, item.description].join(' - '),
        value: item.name,
      })
    );

    const holeCircle = productOrState.holeCircle.map((item) => ({
      label: item,
      key: item,
    }));

    const holeNumber = productOrState.holeNumber?.map((item) => ({
      label: item,
      key: item,
    }));
    // const pcd = productOrState.pcd.map((item) => ({
    //   label: item,
    //   key: item,
    // }));

    const specialMarking = Object.values(
      _.get(productMeta, 'specialMarking', {})
    ).map((item: any) => ({
      label: [item.key, item.description].join(' - '),
      value: item.key,
    }));

    return {
      ...productOrState,
      specialMarking,
      rfd,
      productMeta,
      holeCircle,
      holeNumber,
      // pcd,
      modelName: productOrState.model || model,
    };
  };

  const {
    handleSubmit,
    register,
    control,
    watch,
    unregister,
    setValue,
    formState: { isSubmitting, errors, dirtyFields, isDirty, ...restFormState },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      productName2: '',
      ...generateDefaultValues(),
      dataSource: 'Catalog',
    } as any,
    resolver: (formValues) => {
      if (!formValues['modelName']) {
        return {
          values: formValues,
          errors: {
            modelName: 'Model name must be set before continuing.',
          },
        };
      }

      const { modelName, ...values } = formValues;

      const normalized = { ...values, productModelUid: modelName.uid };

      const category_id = modelName?.category_id;

      const itemAttrs = shapedCategories[category_id]?.item_attributes || {};

      const errors: Record<string, string> = {};

      Object.keys(itemAttrs).forEach((key) => {
        const validationCode = get(itemAttrs, `${key}`, 0);

        if (formValues['ean'].length !== 13) {
          errors['ean'] = 'EAN length must be 13 numbers';
        }

        if (
          (key === 'eprelId' || key === 'eprelURL') &&
          formValues.euDirectiveNumber === '1222/2009'
        )
          return;

        if (
          validationCode > 0 &&
          !productAttributesToSkip.includes(key) &&
          !normalized[key]
        ) {
          errors[key] = `${startCase(key)} must be set before continuing`;
        }

        //This part of code works to prevent that when the user enters "space" several times
        if (
          typeof formValues[key] === 'string' &&
          formValues[key].length !== 0 &&
          formValues[key].trim().length === 0
        ) {
          errors[key] = 'You can not enter space as valid value of this field';
        }
      });

      return {
        values: formValues,
        errors,
      };
    },
  });

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length > 0) {
      ToastHelper.showToast(
        'Product Item',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        'Fields ' +
          Object.keys(errors).map((err) => ' ' + startCase(err)) +
          ' have errors.'
      );
    }
  }, [isSubmitting, errors]);

  const onSubmit = (data: any) => {
    setIsSaving(true);
    const {
      rfd,
      specialMarking,
      modelName,
      holeNumber,
      holeCircle,
      pcd,
      ...values
    } = data;
    const rfdMeta: Record<string, any> = {};
    rfd?.forEach((entry: any) => {
      rfdMeta[entry.value] = _.get(RFD_KEYS, entry.value);
    });

    const specialMarkingMeta: Record<string, any> = {};
    specialMarking?.forEach((entry: any) => {
      specialMarkingMeta[entry.value] = _.get(SPECIAL_MARKING, entry.value);
    });
    // Check if fields need to be unregistered

    // Cast to numbers
    ['noisePerformance'].forEach((key) => {
      values[key] = Number(values[key]);
    });

    console.log('mutating', values);
    mutation.mutate({
      ...values,
      productModelUid: modelName.uid,
      specialMarking: specialMarking?.map((item: any) => item.value).join(' '),
      holeCircle: holeCircle?.map((item: any) => item.key) ?? [],
      holeNumber: holeNumber?.map((item: any) => item.key) ?? [],
      pcd: pcd ?? [],
      rfd: rfd?.map((item: any) => item.value).join(' '),
      dirtyFields: customDirty?.current || {},
      productMeta: {
        specialMarking: specialMarkingMeta,
        rfd: rfdMeta,
      },
    });
  };

  const modelSelectValue = watch('modelName');
  const euDirectiveValue = watch('euDirectiveNumber');
  // const structureValue = watch('structure');

  const shouldShowField = useCallback(
    (key: string) => {
      const attrs = modelSelectValue?.category.item_attributes || {};

      const shouldShow = attrs[key] > -1;
      if (!shouldShow && dirtyFields[key]) {
        unregister(key);
      }

      if (
        (key === 'eprelId' || key === 'eprelURL') &&
        euDirectiveValue === '1222/2009'
      )
        return false;

      return shouldShow;
    },
    [modelSelectValue, euDirectiveValue]
  );

  useEffect(() => {
    const subscription = watch((dirtyValue, changedField) => {
      if (changedField.type === 'change' && changedField.name) {
        if (typeof customDirty.current === 'undefined')
          customDirty.current = {};
        customDirty.current[changedField.name] = true;
      }
      if (changedField.type === 'change' && changedField.name === 'weight') {
        let shouldChange = false;
        let weightVal = dirtyValue['weight'];

        if (weightVal.indexOf(',') > -1) {
          weightVal = weightVal.replaceAll(',', '.');
          shouldChange = true;
        }

        const cutDots = () => {
          let foundSeparator = false;
          return weightVal
            .split('')
            .map((char: string) => {
              if (char === '.' && !foundSeparator) {
                foundSeparator = true;
                shouldChange = true;
                return char;
              }
              if (char === '.' && foundSeparator) {
                shouldChange = true;
                return null;
              }
              return char;
            })
            .filter((x: string | null) => !!x)
            .join('');
        };

        const chars = cutDots();
        if (shouldChange) {
          setValue('weight', chars, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
      }

      const { modelName, ...value } = dirtyValue;

      if (!modelName) return;

      const unfinishedData = {
        model: {
          ...(modelName || {}),
        },
        product: { ean: '', ...value, uid: productId },
      };

      unfinishedData.product.rfd = _.get(unfinishedData.product, 'rfd', [])
        .map((item: any) => item.value)
        .join(' ');

      unfinishedData.product.specialMarking = _.get(
        unfinishedData.product,
        'specialMarking',
        []
      )
        .map((item: any) => item.value)
        .join(' ');

      const ruleset = GLOBAL_RULESET.slice(0, GLOBAL_RULESET.length - 3);

      if (!customDirty?.current?.['size']) ruleset.push(GLOBAL_SIZE_RULE);
      if (!customDirty?.current?.['size2']) ruleset.push(BICYCLE_SIZE_RULE);

      ruleset.push(GLOBAL_PRODUCT_NAME_RULE);
      ruleset.push(GLOBAL_MODEL_UID_RULE);

      if (typeof unfinishedData.product.pcd === 'string') {
        unfinishedData.product.pcd = unfinishedData.product.pcd.split(',');
      }

      x._handleRules(ruleset, {}, unfinishedData);

      const {
        product: {
          productName,
          pcd: rawPcd,
          size,
          size2,
          sku,
          ms,
          threepmfs,
          iceGrip,
          commMark,
          oem,
          runFlat,
          seal,
          silent,
          flank,
          electric,
        },
      } = unfinishedData;

      const { holeNumber, holeCircle } = splitPCD(rawPcd);

      const vals: Record<string, boolean> = {
        ms,
        threepmfs,
        iceGrip,
        commMark,
        oem,
        runFlat,
        seal,
        silent,
        flank,
        electric,
      };

      const weightMap = unfinishedData?.product?.weightMap || {};

      Object.keys(vals).forEach((k) => {
        if (vals[k] !== value[k] && !customDirty?.current?.[k]) {
          setValue(k, vals[k], {
            shouldDirty: false,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
      });

      if (productName !== value.productName) {
        setValue('productName', productName, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }

      if (
        size !== value.size &&
        shouldShowField('size') &&
        !customDirty?.current?.['size']
      ) {
        setValue('size', size, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }

      if (
        size2 !== value.size2 &&
        shouldShowField('size2') &&
        !customDirty?.current?.['size2']
      ) {
        setValue('size2', size2, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }

      const holeNumberJoined = holeNumber.join('');
      const valHoleNumberJoined =
        value.holeNumber &&
        value.holeNumber.map((item: { key: string }) => item.key).join('');
      if (
        holeNumberJoined !== valHoleNumberJoined &&
        shouldShowField('holeNumber') &&
        !customDirty?.current?.['holeNumber']
      ) {
        setValue(
          'holeNumber',
          holeNumber?.map((n) => ({ label: n, key: n })),
          {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          }
        );
      }

      const holeCircleJoined = holeCircle.join('');
      const valHoleCircleJoined =
        value.holeCircle &&
        value.holeCircle.map((item: { key: string }) => item.key).join('');
      if (
        holeCircleJoined !== valHoleCircleJoined &&
        shouldShowField('holeCircle') &&
        !customDirty?.current?.['holeCircle']
      ) {
        setValue(
          'holeCircle',
          holeCircle.map((n) => ({ label: n, key: n })),
          {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          }
        );
      }

      // if (
      //   pcd !== value.pcd &&
      //   shouldShowField('pcd') &&
      //   !customDirty?.current?.['pcd']
      // ) {
      //   setValue('pcd', pcd, {
      //     shouldDirty: true,
      //     shouldTouch: true,
      //     shouldValidate: true,
      //   });
      // }

      if (sku !== value.sku) {
        setValue('sku', sku, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, shouldShowField]);

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = '';
      };
    }
  }, [isDirty]);

  // Field mapping

  const categoryId = useMemo(() => model?.category_id, [model?.category_id]);

  const isRequiredField = useCallback(
    (fieldKey: string) => {
      if (!categoryId) return false;
      if (Object.keys(shapedCategories).length === 0) return false;
      const categoryItemAttributes =
        (shapedCategories[categoryId].item_attributes as Record<
          string,
          number
        >) || {};

      if (
        (fieldKey === 'eprelId' || fieldKey === 'eprelURL') &&
        euDirectiveValue === '1222/2009'
      )
        return false;

      return categoryItemAttributes[fieldKey] >= 1;
    },
    [categoryId, shapedCategories, euDirectiveValue]
  );

  const renderField = useCallback(
    (field: string, xs: number, lg?: number, title?: string) => {
      const FIELDS: Record<string, JSX.Element | null | false> = {
        width:
          shouldShowField('width') &&
          getAutocompleteSingleField(
            'Width',
            'width',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {
              freeSolo: true,
            },
            WHEEL_WIDTH_DROPDOWN_DATA,
            isEditDisabled
          ),

        width2:
          shouldShowField('width2') &&
          getAutocompleteSingleField(
            'Width 2',
            'width2',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {
              freeSolo: true,
            },
            WHEEL_WIDTH_DROPDOWN_DATA,
            isEditDisabled
          ),

        height:
          shouldShowField('height') &&
          getAutocompleteSingleField(
            'Height',
            'height',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {
              freeSolo: true,
            },
            WHEEL_HEIGHT_DROPDOWN_DATA,
            isEditDisabled
          ),

        construction:
          shouldShowField('construction') &&
          getAutocompleteSingleField(
            'Construction',
            'construction',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {
              // getOptionLabel: (option: { label: string }) => option.label
            },
            CONSTRUCTION_DROPDOWN_DATA,
            isEditDisabled
          ),

        rimDiameter:
          shouldShowField('rimDiameter') &&
          getAutocompleteSingleField(
            'Rim Diameter',
            'rimDiameter',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {
              freeSolo: true,
            },
            WHEEL_RIM_DIAMETER_DROPDOWN_DATA,
            isEditDisabled
          ),

        size:
          shouldShowField('size') &&
          getTextField(
            'Size',
            'size',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['size'] || customDirty?.current?.['size']
              ? 'Entered manually.'
              : 'Autogenerated from product attributes.',
            {
              disabled: isEditDisabled,
              defaultValue: ' ',
              shrink: true,
            }
          ),

        size2:
          shouldShowField('size2') &&
          getTextField(
            'Size 2',
            'size2',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['size2'] || 'Autogenerated from product attributes.',
            {
              disabled: isEditDisabled,
              defaultValue: ' ',
              shrink: true,
            }
          ),

        // pcd:
        //   shouldShowField('pcd') &&
        //   getReactTagInput(
        //     'PCD',
        //     'pcd',
        //     isRequiredField,
        //     errors,
        //     control,
        //     { xs, lg },
        //
        //     (opt: any) => opt.label,
        //     isEditDisabled
        //   ),

        pcd:
          shouldShowField('pcd') &&
          getTextField(
            'PCD',
            'pcd',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['pcd'] || 'TODO: Missing chip component',
            {
              disabled: isEditDisabled,
              defaultValue: ' ',
              shrink: true,
            }
          ),

        loadIndex:
          shouldShowField('loadIndex') &&
          getTextField(
            'Load Index',
            'loadIndex',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['loadIndex'],
            {
              type: 'number',
              disabled: isEditDisabled,
              shrink: true,
              inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
            }
          ),

        //TODO
        speedIndex:
          shouldShowField('speedIndex') &&
          getAutocompleteSingleField(
            'Speed index',
            'speedIndex',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            SPEED_INDEX_DROPDOWN_DATA,
            isEditDisabled
          ),

        loadIndex2:
          shouldShowField('loadIndex2') &&
          getTextField(
            'Load Index 2',
            'loadIndex2',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['loadIndex2'],
            { type: 'number', disabled: isEditDisabled, shrink: true }
          ),

        speedIndex2:
          shouldShowField('speedIndex2') &&
          getAutocompleteSingleField(
            'Speed index 2',
            'speedIndex2',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            SPEED_INDEX_DROPDOWN_DATA,
            isEditDisabled
          ),

        rfd:
          shouldShowField('rfd') &&
          getAutocompleteSelectChip(
            'Reinforced RFD',
            'rfd',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            RFD_DROPDOWN_DATA,
            (opt) => opt.label,
            isEditDisabled
          ),

        pr:
          shouldShowField('pr') &&
          getAutocompleteSingleField(
            'Plyrate Number PR',
            'pr',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            PR_DROPDOWN_DATA,
            isEditDisabled
          ),

        tubeType:
          shouldShowField('tubeType') &&
          getAutocompleteSingleField(
            'Tube type',
            'tubeType',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            TUBE_TYPE_DROPDOWN_DATA,
            isEditDisabled
          ),

        holeCircle:
          shouldShowField('holeCircle') &&
          getAutocompleteSelectChip(
            'Hole circle',
            'holeCircle',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            HOLE_CIRCLE_DROPDOWN_DATA,
            (opt) => opt.label,
            isEditDisabled || true
          ),

        holeNumber:
          shouldShowField('holeNumber') &&
          getAutocompleteSelectChip(
            'Hole number',
            'holeNumber',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            HOLE_NUMBER_DROPDOWN_DATA,
            (opt) => opt.label,
            isEditDisabled || true
          ),

        vehicleBrand:
          shouldShowField('vehicleBrand') &&
          getTextField(
            'Vehicle brand',
            'vehicleBrand',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['vehicleBrand'],
            { disabled: isEditDisabled, shrink: true }
          ),

        rimDiameter2:
          shouldShowField('rimDiameter2') &&
          getAutocompleteSingleField(
            'Rim Diameter 2',
            'rimDiameter2',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {
              freeSolo: true,
            },
            WHEEL_RIM_DIAMETER_DROPDOWN_DATA,
            isEditDisabled
          ),

        et:
          shouldShowField('et') &&
          getTextField(
            'ET',
            'et',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['et'],
            {
              type: 'number',
              disabled: isEditDisabled,
              shrink: true,
            }
          ),

        cb:
          shouldShowField('cb') &&
          getTextField(
            'CB',
            'cb',
            isRequiredField,
            errors,
            register,
            { xs, lg },
            errors['cb'],
            {
              type: 'number',
              disabled: isEditDisabled,
              shrink: true,
            }
          ),

        rollingResistance:
          shouldShowField('rollingResistance') &&
          getAutocompleteSingleField(
            'Rolling Resistance',
            'rollingResistance',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            ROLLING_RESISTANCE_DROPDOWN_DATA(euDirectiveValue),
            isEditDisabled
          ),

        wetGrip:
          shouldShowField('wetGrip') &&
          getAutocompleteSingleField(
            'Wet grip',
            'wetGrip',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            WET_GRIP_DROPDOWN_DATA(euDirectiveValue),
            isEditDisabled
          ),
        noisePerformance:
          shouldShowField('noisePerformance') &&
          getTextField(
            'Noise Performance',
            'noisePerformance',
            isRequiredField,
            errors,
            register,
            {
              xs,
              lg,
            },
            errors['noisePerformance'],
            {
              type: 'number',
              disabled: isEditDisabled,
              shrink: true,
              inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
            }
          ),

        noiseClassType:
          shouldShowField('noiseClassType') &&
          getAutocompleteSingleField(
            'Noise class type',
            'noiseClassType',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            NOISE_CLASS_TYPE_DROPDOWN_DATA(euDirectiveValue),
            isEditDisabled
          ),

        euDirectiveNumber:
          shouldShowField('euDirectiveNumber') &&
          getAutocompleteSingleField(
            'EU Directive Number',
            'euDirectiveNumber',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            EU_DIRECTIVE_NUMBER_DROPDOWN_DATA,
            isEditDisabled
          ),

        eprelId:
          shouldShowField('eprelId') &&
          getTextField(
            'Eprel ID',
            'eprelId',
            isRequiredField,
            errors,
            register,
            {
              xs,
              lg,
            },
            errors['eprelId'],
            { disabled: isEditDisabled, shrink: true }
          ),

        eprelURL:
          shouldShowField('eprelURL') &&
          getTextField(
            'Eprel URL',
            'eprelURL',
            isRequiredField,
            errors,
            register,
            {
              xs,
              lg,
            },
            errors['eprelURL'],
            { disabled: isEditDisabled, shrink: true }
          ),

        structure:
          shouldShowField('structure') &&
          getAutocompleteSingleField(
            'Structure',
            'structure',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            STRUCTURE_DROPDOWN_DATA,
            isEditDisabled
          ),

        vehicleClass:
          shouldShowField('vehicleClass') &&
          getAutocompleteSingleField(
            'Vehicle Class',
            'vehicleClass',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            VEHICLE_CLASS_DROPDOWN_DATA,
            isEditDisabled
          ),
        installationKit:
          shouldShowField('installationKit') &&
          getAutocompleteSingleField(
            'Installation kit',
            'installationKit',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            INSTALLATION_KIT_DROPDOWN_DATA,
            isEditDisabled
          ),
        specialMarking: shouldShowField('specialMarking') && (
          <Grid item xs={xs}>
            <Controller
              name={'specialMarking'}
              control={control}
              render={({ field }: any) => {
                return (
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    fullWidth
                    disabled={isEditDisabled}
                    getOptionLabel={(option) => option.label}
                    disablePortal
                    filterSelectedOptions
                    value={field.value}
                    aria-required={isRequiredField('specialMarking')}
                    options={SPECIAL_MARKING_WO_SIDEWALL_DROPDOWN_DATA}
                    onChange={(e, x) => {
                      field.onChange(x);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={isRequiredField('specialMarking')}
                        error={errors['specialMarking']}
                        helperText={errors['specialMarking']}
                        label="Special Marking"
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
        ),
        ms:
          shouldShowField('ms') &&
          getCheckbox(
            'M+S',
            'ms',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        threepmfs:
          shouldShowField('threepmfs') &&
          getCheckbox(
            '3PMFS',
            'threepmfs',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        iceGrip:
          shouldShowField('iceGrip') &&
          getCheckbox(
            'Ice grip',
            'iceGrip',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        commMark:
          shouldShowField('commMark') &&
          getCheckbox(
            'Comm Mark',
            'commMark',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        demo:
          shouldShowField('demo') &&
          getCheckbox(
            'Demo',
            'demo',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),

        oem:
          shouldShowField('oem') &&
          getCheckbox(
            'OEM',
            'oem',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            true
          ),
        runFlat:
          shouldShowField('runFlat') &&
          getCheckbox(
            'Run flat',
            'runFlat',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        seal:
          shouldShowField('seal') &&
          getCheckbox(
            'Seal',
            'seal',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        silent:
          shouldShowField('silent') &&
          getCheckbox(
            'Silent',
            'silent',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        flank:
          shouldShowField('flank') &&
          getCheckbox(
            'Flank',
            'flank',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),
        electric:
          shouldShowField('electric') &&
          getCheckbox(
            'Electric',
            'electric',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),

        other:
          shouldShowField('other') &&
          getCheckbox(
            'Other',
            'other',
            isRequiredField,
            errors,
            control,
            {
              xs,
              lg,
            },
            isEditDisabled
          ),

        spacer: <Grid item xs={xs} />,

        compound:
          shouldShowField('compound') &&
          getAutocompleteSingleField(
            'Compound',
            'compound',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            COMPOUND_DROPDOWN_DATA,
            isEditDisabled
          ),

        title: title ? (
          <Grid item xs={xs}>
            <FormSectionWithTitle label={title} />
          </Grid>
        ) : (
          <div></div>
        ),

        smallTitle: title ? (
          <Grid item xs={xs}>
            <FormSectionWithLabel label={title} />
          </Grid>
        ) : (
          <div></div>
        ),
      };

      return FIELDS[field];
    },
    [
      control,
      errors,
      isRequiredField,
      register,
      shouldShowField,
      euDirectiveValue,
    ]
  );

  const categoryAttributeMappingBase: Record<
    number,
    Array<{ key: string; xs: number; lg?: number; title?: string }>
  > = {
    // CAR TYRES
    1: [
      // { key: 'title', xs: 12, title: 'Attributes' },

      { key: 'width', xs: 12, lg: 2 },
      { key: 'height', xs: 12, lg: 2 },
      { key: 'construction', xs: 12, lg: 2 },
      { key: 'rimDiameter', xs: 12, lg: 2 },
      { key: 'size', xs: 12, lg: 4 },
      { key: 'loadIndex', xs: 12, lg: 3 },
      { key: 'speedIndex', xs: 12, lg: 3 },
      { key: 'spacer', xs: 12, lg: 6 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'EU Labels' },
      { key: 'euDirectiveNumber', xs: 12, lg: 3 },
      { key: 'rollingResistance', xs: 12, lg: 3 },
      { key: 'wetGrip', xs: 12, lg: 2 },
      { key: 'noisePerformance', xs: 12, lg: 2 },
      { key: 'noiseClassType', xs: 12, lg: 2 },
      { key: 'vehicleClass', xs: 12, lg: 3 },
      { key: 'eprelId', xs: 12, lg: 3 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'Optional Attributes' },
      { key: 'rfd', xs: 12, lg: 3 },
      { key: 'pr', xs: 12, lg: 3 },
      { key: 'tubeType', xs: 12, lg: 3 },
      { key: 'compound', xs: 12, lg: 3 },
      { key: 'specialMarking', xs: 12, lg: 12 },
      { key: 'ms', xs: 12, lg: 2 },
      { key: 'threepmfs', xs: 12, lg: 2 },
      { key: 'iceGrip', xs: 12, lg: 2 },
      { key: 'commMark', xs: 12, lg: 2 },
      { key: 'oem', xs: 12, lg: 2 },
      { key: 'runFlat', xs: 12, lg: 2 },
      { key: 'seal', xs: 12, lg: 2 },
      { key: 'silent', xs: 12, lg: 2 },
      { key: 'flank', xs: 12, lg: 2 },
      { key: 'electric', xs: 12, lg: 2 },
    ],
    // WHEELS
    8: [
      { key: 'width', xs: 12, lg: 3 },
      { key: 'rimDiameter', xs: 12, lg: 3 },
      { key: 'size', xs: 12, lg: 4 },
      { key: 'spacer', xs: 12, lg: 2 },
      { key: 'holeNumber', xs: 12, lg: 3 },
      { key: 'holeCircle', xs: 12, lg: 3 },
      { key: 'pcd', xs: 12, lg: 4 },
      { key: 'et', xs: 12, lg: 1 },
      { key: 'cb', xs: 12, lg: 1 },
      { key: 'installationKit', xs: 12, lg: 4 },
      { key: 'vehicleBrand', xs: 12, lg: 8 },
    ],
    // MOTO
    2: [
      { key: 'width', xs: 12, lg: 2 },
      { key: 'height', xs: 12, lg: 2 },
      { key: 'construction', xs: 12, lg: 2 },
      { key: 'rimDiameter', xs: 12, lg: 2 },
      { key: 'size', xs: 12, lg: 4 },
      { key: 'loadIndex', xs: 12, lg: 3 },
      { key: 'speedIndex', xs: 12, lg: 3 },
      { key: 'structure', xs: 12, lg: 3 },
      { key: 'tubeType', xs: 12, lg: 2 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'Optional Attributes' },
      { key: 'rfd', xs: 12, lg: 4 },
      { key: 'compound', xs: 12, lg: 4 },
      { key: 'spacer', xs: 12, lg: 4 },
      // { key: 'specialMarking', xs:12, lg: 12 },
      { key: 'ms', xs: 12, lg: 2 },
      { key: 'oem', xs: 12, lg: 2 },
    ],
    // ATV
    3: [
      { key: 'width', xs: 12, lg: 2 },
      { key: 'height', xs: 12, lg: 2 },
      { key: 'construction', xs: 12, lg: 2 },
      { key: 'rimDiameter', xs: 12, lg: 2 },
      { key: 'size', xs: 12, lg: 4 },
      { key: 'loadIndex', xs: 12, lg: 3 },
      { key: 'speedIndex', xs: 12, lg: 3 },
      { key: 'structure', xs: 12, lg: 3 },
      { key: 'tubeType', xs: 12, lg: 2 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'Optional Attributes' },
      { key: 'rfd', xs: 12, lg: 4 },
      { key: 'compound', xs: 12, lg: 4 },
    ],
    // BIKE
    5: [
      { key: 'width', xs: 12, lg: 2 },
      { key: 'rimDiameter', xs: 12, lg: 2 },
      { key: 'size', xs: 12, lg: 3 },
      { key: 'spacer', xs: 12, lg: 5 },
      { key: 'width2', xs: 12, lg: 2 },
      { key: 'rimDiameter2', xs: 12, lg: 2 },
      { key: 'size2', xs: 12, lg: 3 },
      { key: 'spacer', xs: 12, lg: 5 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'Optional Attributes' },
      { key: 'tubeType', xs: 12, lg: 4 },
      { key: 'compound', xs: 12, lg: 4 },
    ],
    // AGRO
    7: [
      { key: 'width', xs: 12, lg: 2 },
      { key: 'height', xs: 12, lg: 2 },
      { key: 'construction', xs: 12, lg: 2 },
      { key: 'rimDiameter', xs: 12, lg: 2 },
      { key: 'size', xs: 12, lg: 4 },
      { key: 'loadIndex', xs: 12, lg: 3 },
      { key: 'speedIndex', xs: 12, lg: 3 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'Optional Attributes' },
      { key: 'loadIndex2', xs: 12, lg: 3 },
      { key: 'speedIndex2', xs: 12, lg: 3 },
      { key: 'tubeType', xs: 12, lg: 2 },
      { key: 'rfd', xs: 12, lg: 4 },
      { key: 'ms', xs: 12, lg: 2 },
    ],
    // TERETNE
    4: [
      { key: 'width', xs: 12, lg: 2 },
      { key: 'height', xs: 12, lg: 2 },
      { key: 'construction', xs: 12, lg: 2 },
      { key: 'rimDiameter', xs: 12, lg: 2 },
      { key: 'size', xs: 12, lg: 4 },
      { key: 'loadIndex', xs: 12, lg: 3 },
      { key: 'speedIndex', xs: 12, lg: 3 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'EU Labels' },
      { key: 'euDirectiveNumber', xs: 12, lg: 3 },
      { key: 'rollingResistance', xs: 12, lg: 3 },
      { key: 'wetGrip', xs: 12, lg: 2 },
      { key: 'noisePerformance', xs: 12, lg: 2 },
      { key: 'noiseClassType', xs: 12, lg: 2 },
      { key: 'vehicleClass', xs: 12, lg: 3 },
      { key: 'eprelId', xs: 12, lg: 3 },
      { key: 'smallTitle', xs: 12, lg: 12, title: 'Optional Attributes' },
      { key: 'loadIndex2', xs: 12, lg: 3 },
      { key: 'speedIndex2', xs: 12, lg: 3 },
      { key: 'spacer', xs: 12, lg: 6 },
      { key: 'rfd', xs: 12, lg: 3 },
      { key: 'pr', xs: 12, lg: 3 },
      { key: 'tubeType', xs: 12, lg: 3 },
      { key: 'spacer', xs: 12, lg: 3 },
      { key: 'ms', xs: 12, lg: 2 },
      { key: 'threepmfs', xs: 12, lg: 2 },
      { key: 'iceGrip', xs: 12, lg: 2 },
      { key: 'commMark', xs: 12, lg: 2 },
    ],
    // ZRACNICE
    9: [{ key: 'rimDiameter', xs: 12, lg: 2 }],
  };

  const categoryAttributeMappingDefault = [
    { key: 'width', xs: 12, lg: 3 },
    { key: 'height', xs: 12, lg: 3 },
    { key: 'size', xs: 12, lg: 3 },
  ];

  const categoryAttributeMapping = (key: number) =>
    key
      ? categoryAttributeMappingBase[key] || categoryAttributeMappingDefault
      : undefined;

  const getParentCategory = useCallback(
    (shapedCategories: Record<number, any>, catId?: number): any => {
      if (Object.keys(shapedCategories).length === 0 || !catId)
        return undefined;
      const selectedCategory = shapedCategories[catId];

      if (selectedCategory?.parent_category_id) {
        return getParentCategory(
          shapedCategories,
          selectedCategory.parent_category_id
        );
      }

      return selectedCategory;
    },
    []
  );

  const parentCategory = useMemo(
    () => getParentCategory(shapedCategories, categoryId),
    [categoryId, getParentCategory, shapedCategories]
  );

  return (
    <PageTemplateWrapper style={{ height: '100%' }}>
      <PageTemplateContent style={{ padding: '16px' }}>
        {!isCancelModalVisible && (
          <RouteRouterPrompt
            when={isDirty && !isSaving}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() => true}
          />
        )}
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              history.goBack();
              // history.push(`/dashboard/product-models/`);
              setIsCancelModalVisible(false);
            }}
          />
        )}
        {isDeleteModalVisible && (
          <DeleteModal
            LBAction={() => setIsDeleteModalVisible(false)}
            RBAction={() => {
              deleteProductMutation.mutate();
              setIsDeleteModalVisible(false);
            }}
            itemName={product?.productName || ''}
          />
        )}
        <TitleWrapper>
          <Title>
            <img
              src={backIcon}
              alt="back"
              style={{
                paddingBottom: 0,
                height: '22px',
                width: '16px',
                cursor: 'pointer',
              }}
              onClick={() => history.goBack()}
            />
            <TitleText>
              {product ? product.productName : 'Create new product item'}
            </TitleText>
          </Title>

          <FormControlLabel
            control={
              <Switch
                id="active"
                color="info"
                disabled={!permissions?.edit}
                defaultChecked={product?.ean ? product?.active : true}
                {...register('active')}
              />
            }
            label="Active"
          />
        </TitleWrapper>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2} paddingTop={2}>
            <Grid item xs={12}>
              <ChangeModel control={control} isEditDisabled={isEditDisabled} />
            </Grid>

            {!!modelSelectValue && (
              <>
                {shouldShowField('sku') &&
                  getTextField(
                    'SKU',
                    'sku',
                    isRequiredField,
                    errors,
                    register,
                    { xs: 12, lg: 4 },
                    'Unique value',
                    {
                      disabled: true,
                      helperText: errors['sku'],
                      shrink: true,
                    }
                  )}

                {shouldShowField('ean') &&
                  getTextField(
                    'EAN',
                    'ean',
                    isRequiredField,
                    errors,
                    register,
                    { xs: 12, lg: 4 },
                    'Unique value',
                    { disabled: isEditDisabled, shrink: true }
                  )}

                {shouldShowField('manufacturerCode') &&
                  getTextField(
                    'Manufacturer Code',
                    'manufacturerCode',
                    isRequiredField,
                    errors,
                    register,
                    {
                      xs: 12,
                      lg: 4,
                    },
                    'IPC/ CAI - unique for brand',
                    { disabled: isEditDisabled, shrink: true }
                  )}

                {shouldShowField('productName') &&
                  getTextField(
                    'Product name',
                    'productName',
                    isRequiredField,
                    errors,
                    register,
                    { xs: 12, lg: 12 },
                    'Auto generated from model and item attributes',
                    { disabled: true, shrink: true }
                  )}

                {shouldShowField('productName2') &&
                  getTextField(
                    'Product name 2',
                    'productName2',
                    isRequiredField,
                    errors,
                    register,
                    { xs: 12, lg: 12 },
                    'Manual entry - overrides original product name - leave empty if original name is ok',
                    { disabled: isEditDisabled, shrink: true }
                  )}

                {getAutocompleteSingleField(
                  'Origin',
                  'origin',
                  isRequiredField,
                  errors,
                  control,
                  { xs: 12, lg: 4 },
                  {},
                  COUNTRY_DROPDOWN_DATA,
                  isEditDisabled
                )}

                {shouldShowField('weight') && (
                  <Grid xs={12} lg={4}>
                    <TextField
                      fullWidth
                      disabled={isEditDisabled}
                      label="Weight"
                      id="weight"
                      sx={{
                        marginLeft: '16px',
                        marginTop: '16px',
                        paddingRight: '16px',
                      }}
                      error={!!errors['weight']}
                      helperText={errors['weight']}
                      required={isRequiredField('weight')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">kg</InputAdornment>
                        ),
                      }}
                      {...register('weight')}
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <FormSectionWithTitle label={'Attributes'} />
            </Grid>
            {parentCategory &&
              categoryAttributeMapping(parentCategory?.id)?.map((item) => {
                return renderField(item.key, item.xs, item.lg, item.title);
              })}
            {shouldShowField('remark') &&
              getTextField(
                'Remark',
                'remark',
                isRequiredField,
                errors,
                register,
                { xs: 12, lg: 12 },
                errors['remark'],
                { disabled: isEditDisabled, shrink: true }
              )}
          </Grid>

          <FooterWrapper>
            <SourceContainer
              data={{
                data_source: '',
                created: '',
                last_edited: '',
              }}
              onCancel={() =>
                permissions?.edit
                  ? setIsCancelModalVisible(true)
                  : history.goBack()
              }
              onDelete={() => handleDeleteProduct()}
              permissions={permissions}
            />
          </FooterWrapper>
        </form>
      </PageTemplateContent>
      <ProductSidebarWrapper>
        <ProductSidebar product={product} />
      </ProductSidebarWrapper>
    </PageTemplateWrapper>
  );
};

const FooterWrapper = styled.div`
  margin-top: 80px;
  width: 100%;

  @media (max-width: 880px) {
    margin-top: 150px;
  }

  @media (max-width: 768px) {
    margin-top: 80px;
  }

  @media (max-width: 450px) {
    margin-top: 150px;
  }
`;

const ProductSidebarWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled.div`
  margin-left: 16px;
  font-weight: 700;
  font-size: 24px;
`;

export default ProductItemPage;
