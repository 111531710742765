import styled from '@emotion/styled';
import { Avatar, Chip, Grid } from '@mui/material';
import { CartStockListItem, PosItem, defaultDot } from '@tyrio/dto';
import { generateSpecialMarkings } from '@tyrio/products';
import { tyrioUI } from '@tyrio/ui-library';
import { useContext, useEffect, useMemo, useState } from 'react';
import { POSContext } from '../../../../context/POSContext';
import { isEqual } from 'lodash';
import { DBProduct } from '@prisma/client';
import { usePosCartCtx } from '../../../../context/PosCartContext';
import { formatCurrencyWithoutSymbol } from '../../../../helpers/currency-format';
import { usePosCartData } from '../../steps/Cart/helpers/cart-data';
import { useCartData } from '../../../stock-list/components/Cart/helper/cart-helper';
import { useAuth } from '../../../../context/AuthContext';

export enum PosItemRowStatus {
  FAVORITE,
  SELECTED,
  NORMAL,
}
interface SingleTableRowProps {
  posItem: PosItem;
  showBrand?: boolean;
}

export const RowItem = ({ posItem, showBrand = true }: SingleTableRowProps) => {
  const [hovered, setHovered] = useState(false);
  const {
    selectedTableRow,
    setSelectedTableRow,
    setActiveStep,
    setBadgeContent,
    isVatIncluded,
  } = useContext(POSContext);
  const { user } = useAuth();
  const { setCartData } = usePosCartCtx();
  const { modalData } = usePosCartData();
  const { modalData: stockListData } = useCartData();

  const isSelected =
    selectedTableRow?.product.ean === posItem.product.ean &&
    selectedTableRow.features.outletDot === posItem.features.outletDot &&
    selectedTableRow.stockType === posItem.stockType;

  const favorite = posItem.isFavorite;

  const status: PosItemRowStatus = isSelected
    ? PosItemRowStatus.SELECTED
    : favorite
    ? PosItemRowStatus.FAVORITE
    : PosItemRowStatus.NORMAL;

  const handleActive = (posItem: PosItem) => {
    setActiveStep('DETAILS');
    setCartData([]);
    setSelectedTableRow(posItem);

    if (!isEqual(posItem, selectedTableRow))
      setBadgeContent((prevState) => ({
        BRANCHES: 0,
        SUPPLIERS: 0,
        CART: prevState.CART,
      }));
  };

  const generatedKey = useMemo(() => {
    const stockType =
      posItem.stockType === 'CURRENT'
        ? 'ON STOCK'
        : posItem.stockType === 'SUPPLIER'
        ? 'SUPPLIERS STOCK'
        : 'BRANCHES STOCK';

    return `${posItem.product?.ean}_${posItem.features.outletDot}_?_${stockType}`;
  }, [posItem.features.outletDot, posItem.product?.ean, posItem.stockType]);

  const reserved = useMemo(() => {
    // pos reserved
    const reservedPosItems = modalData.filter((item) => {
      const splitKey = item.key.toString().split('_');
      const splitGeneratedKey = generatedKey.toString().split('_');

      const eanMatched = splitKey[0] === splitGeneratedKey[0];

      // we should only match dot if stock type is not suppliers stock
      // because for suppliers we dont have dot
      const dotMatched =
        splitKey[3] !== 'SUPPLIERS STOCK'
          ? splitKey[1] === splitGeneratedKey[1] || splitKey[1] === 'XXXX'
          : true;

      const stockTypeMatched =
        ((splitKey[3] === 'BRANCHES STOCK' ||
          splitKey[3] === 'UPCOMING ON STOCK') &&
          splitGeneratedKey[3] === 'BRANCHES STOCK') ||
        splitKey[3] === splitGeneratedKey[3];

      return eanMatched && dotMatched && stockTypeMatched;
    });

    let posReserved = reservedPosItems.reduce(
      (acc, curr) => acc + curr.req.reserved,
      0
    );

    // stock list reserved
    stockListData.forEach((item) => {
      const generatedKey = `${posItem.product.ean}_${posItem.features.outletDot}_${user?.mainBranchId}`;

      if (item[generatedKey])
        posReserved += (item[generatedKey] as unknown as CartStockListItem)
          .quantity;
    });

    return posReserved;
  }, [
    modalData,
    stockListData,
    generatedKey,
    posItem.product.ean,
    posItem.features.outletDot,
    user?.mainBranchId,
  ]);

  const avatarBg =
    posItem.quantity >= 4 ? 'transparent' : tyrioUI.colors.yellow.B100;

  const textColor =
    posItem.quantity > 4 ? '#212B36' : posItem.quantity < 4 ? 'white' : 'black';

  useEffect(() => {
    isSelected && setSelectedTableRow(posItem);
  }, [isSelected, posItem, setSelectedTableRow]);

  return (
    <TableRowStyled
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      status={status}
      onClick={() => handleActive(posItem)}
    >
      {showBrand && (
        <StyledTableCell sx={{ minWidth: '150px', maxWidth: '150px' }}>
          {posItem.product?.model?.brand}
          <CellDetails>{posItem.product?.model?.season}</CellDetails>
        </StyledTableCell>
      )}

      <StyledTableCell sx={{ maxWidth: '150px', minWidth: '150px' }}>
        {posItem.product.model?.category?.parent_category_name
          ? posItem.product.model?.category.parent_category_name
          : posItem.product.model?.category?.name}
        <CellDetails>{posItem.product.model?.category?.name}</CellDetails>
      </StyledTableCell>
      <StyledTableCell style={{ overflowX: 'auto', minWidth: '400px' }}>
        {posItem.product.productName}
        <SpecialMarkings
          style={{
            color:
              status === PosItemRowStatus.SELECTED || hovered
                ? '#e75524'
                : '#919eab',
          }}
        >
          {generateSpecialMarkings(
            posItem.product as unknown as DBProduct
          )?.map((item) => item + ' ')}
        </SpecialMarkings>
      </StyledTableCell>
      <StyledTableCell
        style={{
          overflowX: 'auto',
          minWidth: '50px',
          maxWidth: '150px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {posItem.features.outletDot !== defaultDot && (
          <Chip
            label={`DOT ${posItem.features.outletDot}`}
            color="error"
            variant="outlined"
            size="small"
          />
        )}
      </StyledTableCell>
      <StyledTableCell
        padding="none"
        sx={{
          maxWidth: '230px',
          minWidth: '230px',
        }}
      >
        <QtyPriceWrapper
          id="quantity_wrapper"
          background={
            status === PosItemRowStatus.SELECTED ? tyrioUI.colors.blue.B20 : ''
          }
        >
          <>
            <NumberWrapper color={'black'}>
              {!isVatIncluded
                ? posItem.price && !isNaN(Number(posItem.price))
                  ? formatCurrencyWithoutSymbol(Number(posItem.price ?? 0))
                  : (0).toFixed(2)
                : posItem.inclusiveVatPrice &&
                  !isNaN(Number(posItem.inclusiveVatPrice))
                ? formatCurrencyWithoutSymbol(posItem.inclusiveVatPrice)
                : (0).toFixed(2)}
            </NumberWrapper>
            <NumberWrapper>
              {!isVatIncluded
                ? posItem.cashPrice && !isNaN(Number(posItem.cashPrice))
                  ? formatCurrencyWithoutSymbol(Number(posItem.cashPrice ?? 0))
                  : (0).toFixed(2)
                : posItem.vatCashPrice && !isNaN(Number(posItem.vatCashPrice))
                ? formatCurrencyWithoutSymbol(posItem.vatCashPrice)
                : (0).toFixed(2)}
            </NumberWrapper>
            <NumberWrapper>
              <Avatar
                sx={{
                  background: avatarBg,
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  letterSpacing: '0.3px',
                  color: textColor,
                  width:
                    posItem.quantity.toString().length > 4
                      ? 'fit-content'
                      : '40px',
                  height: '40px',
                  position: 'inherit',
                }}
              >
                {posItem.stockType === 'SUPPLIER' &&
                posItem.quantity - posItem.reserved > 50
                  ? '50+'
                  : posItem.quantity - posItem.reserved - (reserved ?? 0)}
              </Avatar>
            </NumberWrapper>
          </>
        </QtyPriceWrapper>
      </StyledTableCell>
    </TableRowStyled>
  );
};

const TableRowStyled = styled(Grid)<{
  status?: PosItemRowStatus;
}>`
  display: flex;
  width: 100%;
  cursor: pointer;
  background: ${(props) =>
    props.status === PosItemRowStatus.FAVORITE
      ? tyrioUI.colors.yellow.B10
      : props.status === PosItemRowStatus.SELECTED
      ? tyrioUI.colors.blue.B10
      : 'white'};

  border-bottom: 1px solid
    ${(props) =>
      props.status === PosItemRowStatus.FAVORITE
        ? tyrioUI.colors.yellow.B100
        : props.status === PosItemRowStatus.SELECTED
        ? tyrioUI.colors.blue.B100
        : tyrioUI.colors.black.B40};

  height: 72px;
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
  align-items: center;
  &:hover {
    background: ${tyrioUI.colors.black.B10};
    border-bottom: 1px solid ${tyrioUI.colors.black.B40};
    #quantity_wrapper {
      background: ${tyrioUI.colors.black.B30};
    }
    #product_name_details {
      color: #e75524;
      font-weight: 500;
    }
  }
  #product_name_details {
    color: ${(props) =>
      props.status === PosItemRowStatus.SELECTED ? '#e75524' : '#919eab'};
  }
`;

const StyledTableCell = styled(Grid)`
  border: 0;
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  padding: 6px 16px;
  &:last-child {
    padding-right: 0;
  }
`;

export const CellDetails = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #919eab;
`;

const SpecialMarkings = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #919eab;
  &:hover {
    color: #e75524;
  }
`;

const QtyPriceWrapper = styled.div<{
  background?: string;
}>`
  width: 100%;
  max-width: 320px;
  height: 100%;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.background ? props.background : '#f4f6f8')};
  border-radius: 8px 0px 0px 8px;
  &:hover {
    background: #ecf0f4;
  }
`;

const NumberWrapper = styled.div<{
  color?: string;
}>`
  display: flex;
  width: 100%;
  max-width: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.color === 'red' ? '600' : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
`;
