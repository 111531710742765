/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DBPaymentStatusEnum } from '@prisma/client';
import { DBCardType } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  getInstalmentValues,
  parseEnumToArray,
} from '../../../../../helpers/enum-parser';
import pencil_alt from '../assets/pencil-alt.svg';
import {
  PaymentNameTypography,
  PaymentTypeTitleTypography,
} from '../styles/OrderTabs.style';

interface IPaymentDetailsModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
  selectedOrderData: any;
  setSelectedOrderData: any;
}

export const PaymentDetailsModal = ({
  open,
  handleClose,
  setOpen,
  selectedOrderData,
  setSelectedOrderData,
}: IPaymentDetailsModal) => {
  const paymentStatus = parseEnumToArray(DBPaymentStatusEnum);
  const cardTypes = parseEnumToArray(DBCardType);
  const instalmentValues = getInstalmentValues();
  const [initial, _setTest] = useState(selectedOrderData);

  const handleSubmit = () => {
    setOpen(false);
    ToastHelper.showToast(
      'Payment details',
      ToastType.SUCCESS,
      ToastMessageType.UPDATE,
      'Successful payment details editing! To save your edits, click Save!'
    );
  };

  const showCardType =
    selectedOrderData.payment?.paymentType === 'CARD_POS' ||
    selectedOrderData.payment?.paymentType === 'CARD_ONLINE' ||
    selectedOrderData.payment?.paymentType === 'CARD_ON_DELIVERY';

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
          height: '550px',
          padding: '16px',
        }}
      >
        <CloseWrapper>
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
            sx={{ cursor: 'pointer' }}
          />
        </CloseWrapper>
        <Box
          sx={{
            padding: '16px',
            border: '1px solid #DFE3E8',
            display: 'flex',
            borderRadius: '8px',
            justifyContent: 'space-between  ',
            marginTop: '20px',
          }}
        >
          <Grid style={{ width: '100%', height: '100%' }}>
            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PaymentTypeTitleTypography>
                Payment type:
              </PaymentTypeTitleTypography>
              <Tooltip title="Edit payment info">
                <img src={pencil_alt} alt="pencil_alt" />
              </Tooltip>
            </Grid>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '12px',
              }}
            >
              <PaymentNameTypography>
                {selectedOrderData.payment?.name}
              </PaymentNameTypography>
            </Grid>

            <Grid container style={{ marginTop: '16px' }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Payment status</InputLabel>
                  <Select
                    label="Payment status"
                    value={selectedOrderData.paymentStatus ?? ''}
                    onChange={(e) => {
                      setSelectedOrderData({
                        ...selectedOrderData,
                        paymentStatus: e.target.value,
                      });
                    }}
                  >
                    {paymentStatus.map((item) => (
                      <MenuItem value={item.value} key={item.label}>
                        <Typography> {item.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '8px' }}>
              {showCardType && (
                <>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel>Card type</InputLabel>
                      <Select
                        label="Card type"
                        value={
                          selectedOrderData.paymentDetails?.cardInfo
                            ?.cardType ?? ''
                        }
                        onChange={(e) => {
                          setSelectedOrderData({
                            ...selectedOrderData,
                            paymentDetails: {
                              cardInfo: {
                                ...selectedOrderData.paymentDetails?.cardInfo,
                                cardType: e.target.value || '',
                              },
                            },
                          });
                        }}
                      >
                        {cardTypes.map((item) => (
                          <MenuItem value={item.value} key={item.label}>
                            <Typography> {item.label}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Instalment</InputLabel>
                      <Select
                        label="Instalment"
                        value={
                          selectedOrderData.paymentDetails?.cardInfo
                            ?.instalment ?? ''
                        }
                        onChange={(e) => {
                          setSelectedOrderData({
                            ...selectedOrderData,
                            paymentDetails: {
                              cardInfo: {
                                ...selectedOrderData.paymentDetails?.cardInfo,
                                instalment: e.target.value,
                              },
                            },
                          });
                        }}
                      >
                        {instalmentValues.map((item) => (
                          <MenuItem value={item.value} key={item.label}>
                            <Typography> {item.label}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container style={{ marginTop: '14px' }}>
              <Grid item xs={12}>
                <TextField
                  label="Transaction ID"
                  value={
                    selectedOrderData.paymentDetails?.cardInfo?.transactionId ??
                    ''
                  }
                  onChange={(e) => {
                    setSelectedOrderData({
                      ...selectedOrderData,
                      paymentDetails: {
                        cardInfo: {
                          ...selectedOrderData.paymentDetails?.cardInfo,
                          transactionId: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <ButtonsWrapper>
          <Button
            style={{
              width: '100%',
              height: '60px',
              backgroundColor: 'rgba(25, 118, 210, 1)',
              color: 'white',
            }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
          <Button
            color="info"
            style={{
              width: '100%',
              height: '60px',
              border: '1px solid rgba(25, 118, 210, 1)',
            }}
            onClick={() => {
              setSelectedOrderData({
                ...selectedOrderData,
                paymentStatus: initial?.paymentStatus,
                paymentDetails: {
                  cardInfo: initial.paymentDetails.cardInfo,
                },
              });
              setOpen(false);
            }}
          >
            CANCEL
          </Button>
        </ButtonsWrapper>
      </Box>
    </Modal>
  );
};

const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 100%;
  gap: 20px;
`;
