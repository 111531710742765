import styled from '@emotion/styled';
import Box from '@mui/material/Box/Box';

interface FormSectionWithTitleProps {
  label: string;
}

const FormSectionWithTitle = ({ label }: FormSectionWithTitleProps) => {
  return <LabelSectionBox>{label}</LabelSectionBox>;
};

export const FormSectionWithLabel = ({ label }: FormSectionWithTitleProps) => {
  return (
    <Container>
      <LineSpacerFirst />
      <Label>{label}</Label>
      <LineSpacerSecond />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

const LineSpacerFirst = styled.div`
  height: 1px;
  background-color: #dfe3e8;
  width: 100px;
`;

const LineSpacerSecond = styled.div`
  height: 1px;
  background-color: #dfe3e8;
  flex: 1;
`;

const Label = styled.div`
  padding: 0 8px;
`;

export const LabelSectionBox = styled(Box)`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: #212b36;
  margin-bottom: 25px;
  margin-top: 20px;
  border-bottom: 1px solid #dfe3e8;
`;

export default FormSectionWithTitle;
