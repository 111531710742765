export enum SearchableVerticalListDataType {
  WITH_CHILDREN,
  WITHOUT_CHILDREN,
}

export interface SearchableVerticalListDataProps {
  id: string;
  name: string;
  type?: string;
  active?: boolean;
  children?: { name: string; id: string }[] | [];
}
