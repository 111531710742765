import styled from '@emotion/styled';
import Grid from '@mui/material/Grid/Grid';
import Dropzone from 'react-dropzone';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Button from '@mui/material/Button/Button';
import { tyrioUI } from '@tyrio/ui-library';
import imageIcon from '../../assets/image-icon.svg';
import { ToastContainer, toast } from 'react-toastify';

const NewCard = () => {
  return (
    <Grid item xs={4}>
      <Container>
        <Dropzone
          onDrop={() => {
            toast('You have successfully uploaded image !');
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Wrapper {...getRootProps()}>
              <input {...getInputProps()} />
              <img src={imageIcon} alt="dropzone-icon" />
              <Title>Add new image</Title>
              <Text>
                Drop files here,{' '}
                <span style={{ color: tyrioUI.colors.lightBlue }}>browse</span>{' '}
                files or import from:
              </Text>
            </Wrapper>
          )}
        </Dropzone>
        <ButtonContainer>
          <StyledButton
            onClick={() => alert('This feature is not spported for now')}
          >
            <ImportantDevicesIcon />
            device
          </StyledButton>
          <StyledButton
            onClick={() => alert('This feature is not spported for now')}
          >
            <CameraAltIcon />
            camera
          </StyledButton>
        </ButtonContainer>
        <ToastContainer />
      </Container>
    </Grid>
  );
};

export default NewCard;

const Container = styled.div`
  height: 420px;
  border-radius: 8px;
  border: 1px dashed #e6e8f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${tyrioUI.spacing.l}px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledButton = styled(Button)`
  color: #919eab;
  display: flex;
  flex-direction: column;
  text-transform: lowercase;
  :hover {
    color: ${tyrioUI.colors.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 112px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
`;

const Text = styled.p`
  font-weight: 400;
  width: 50%;
  text-align: center;
  font-size: 16px;
`;
