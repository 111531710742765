import { DBFtpConnection } from '@prisma/client';

export const ftp_connections: DBFtpConnection[] = [
  {
    id: '0256f16a-fc82-4803-a5c2-22b6fa7bb99e',
    supplierFileName: '',
    fileLocation: '/demo/GMP_CATALOG.csv',
    ftpHost: 'files.000webhost.com',
    ftpPort: '21',
    ftpUserName: 'haristyrio',
    ftpPassword: 'harisharis',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: 'a85ca99b-fa29-4ff5-81dc-9c977c6a9613',
    remark: '',
  },
  {
    id: '0256f14a-fc82-4303-a5c2-22b6fa7bc99e',
    supplierFileName: '',
    fileLocation: '/demo/GMP_PRICAT.csv',
    ftpHost: 'files.000webhost.com',
    ftpPort: '21',
    ftpUserName: 'haristyrio',
    ftpPassword: 'harisharis',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: 'a85ca99b-fa29-4ff5-81dc-9c977c6a9614',
    remark: '',
  },
  {
    id: '7ef52444-ede4-47a4-9a95-de39a3ee2d7d',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/CONTINENTAL_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '21',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '35515fc6-76b1-4190-a051-a3f04cc88de0',
    remark: '',
  },
  {
    id: '0e104b81-bd88-49f3-b8d3-f1b54b5dcd6c',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/CONTINENTAL_STOCKLIST.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '21',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '987a41a2-8eaa-4e01-a3a3-e430b85b6da3',
    remark: '',
  },
  {
    id: '42a34ff4-0d8f-4854-9af8-d235e4476476',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/CONTINENTAL_PRICELIST.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '21',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '452096cb-3267-4f70-822c-f950aca99cc9',
    remark: '',
  },
  {
    id: '97571dba-f69d-49d1-b932-9a4bb8dfbaf4',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/VDB_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '21',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '9310aa7a-f798-4a50-94cd-f935409ef1fd',
    remark: '',
  },
  {
    id: '6a3d781b-077c-4804-8fa4-c39bae85120e',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/VDB_STOCK_PRICE.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '21',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '2c22183f-fc5b-4f4e-a75c-3a53e8ebe91f',
    remark: '',
  },
  {
    id: 'e107eb18-1f3e-47fe-9d2e-fc72e95b69c2',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/GOODYEAR_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '88d22d9b-fad4-421c-bbb3-7ead759bcffb',
    remark: '',
  },
  {
    id: '9fc86a28-e182-4e89-af31-10f2bf5d4c80',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/MICHELIN_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '2028a06f-c970-4234-9936-beedd7b27c32',
    remark: '',
  },
  {
    id: '34981dc4-426a-46a1-93d1-7fe33430bd91',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/PIRELLI_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: 'fd551397-1def-49c8-bf59-32b48a280912',
    remark: '',
  },
  {
    id: 'be2f34a9-fc44-4858-ac71-62d558e89718',
    supplierFileName: '',
    fileLocation: '',
    ftpHost: 'nerminh17.sg-host.com/tyrio/GUNDLACH_PRICAT.csv',
    ftpPort: '',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: 'c1be9f8e-5ea5-48cb-9c56-d53048e87ac9',
    remark: '',
  },
  {
    id: 'becd2bbb-bc36-4dec-a7c2-2d5480c18eff',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/VREDESTEIN_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '21',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 6,
    repeatUntil: 'HOURS',
    supplierConnectionId: '91ed6e94-01ec-476b-9571-3a9576974183',
    remark: '',
  },
  {
    id: 'c9bc511a-1109-4475-8722-c46d601d8d80',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/KUMHO_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 6,
    repeatUntil: 'HOURS',
    supplierConnectionId: '5a8fb9f3-b06d-4b5c-a8ae-663fb485bec1',
    remark: '',
  },
  {
    id: 'bf8fc451-ddfc-4335-9b69-ee115143a1f7',
    supplierFileName: '',
    fileLocation: 'nerminh17.sg-host.com/tyrio/BRIDGESTONE_PRICAT.csv',
    ftpHost: 'ftp.nerminh17.sg-host.com',
    ftpPort: '',
    ftpUserName: 'nedimovski@nerminh17.sg-host.com',
    ftpPassword: '!B$YeC4GqLDFSkzB',
    startUpdateTime: new Date(),
    repeatEvery: 5,
    repeatUntil: 'HOURS',
    supplierConnectionId: '931b2bbf-b9da-4392-913c-9a5cebf941e6',
    remark: '',
  },
];
