import { Divider, Typography } from '@mui/material';
import { DBTransferOrderType } from '@prisma/client';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import { useContext } from 'react';
import { StockListContext } from '../../../../context/StockListContext';
import { StockWrapper, StorageName, TitleWrapper } from './InboundOrders.style';

enum title {
  OUTBOUND = 'OUTBOUND ORDER',
  INBOUND = 'INBOUND ORDERS',
}

export const RenderTitle = ({ type }: { type: DBTransferOrderType }) => {
  const { onlyAvailable } = useContext(StockListContext);
  const showTitle = title[type];
  return (
    <>
      <TitleWrapper>
        <StorageName>
          <WmsIcon
            icon={
              type === DBTransferOrderType.INBOUND
                ? wmsIcons.inbound
                : wmsIcons.outbound
            }
            viewBox="0 0 20 19"
            sx={{ color: '#637381' }}
          />
          <Typography variant="subtitle1" style={{ fontSize: '16px' }}>
            {showTitle}
          </Typography>
        </StorageName>
        <StockWrapper onlyAvailable={onlyAvailable}>
          {!onlyAvailable && <Typography variant="body2">Reserved</Typography>}
          <Typography variant="body2">Available</Typography>
        </StockWrapper>
      </TitleWrapper>
      <Divider />
    </>
  );
};
