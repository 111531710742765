import api from '@tyrio/api-factory';
import { DBSupplierClientSettingsApi } from '@tyrio/dto';
import { useQuery } from 'react-query';
import SupplierOrderOverview, {
  SupplierOrderDataInterface,
} from './SupplierOrderOverview';

export const OrderOverviewWrapper = (props: SupplierOrderDataInterface) => {
  const supplierIds = Object.keys(props.partialOrderData);
  console.log({ supplierIds });

  const { isFetching, isError, data } = useQuery(
    ['supplier_client_settings_list'],
    () => getClientSupplierSettingsByClientId(),
    {}
  );

  const getClientSupplierSettingsByClientId = async () => {
    return await api.fetch<DBSupplierClientSettingsApi['list']>(
      'get_supplier_client_settings',
      { search: '', includeB2B: 'true' }
    );
  };

  // const {
  //   data: client_data,
  //   isFetching: isClientDataFetching,
  //   refetch: getClientById,
  // } = useQuery(['get_client_id'], () =>
  //   api.fetch<DBClientApi['getOne']>(`client_id`, {})
  // );

  console.log({ isFetching, isError, data });
  return <SupplierOrderOverview partialOrderData={props.partialOrderData} />;
};
