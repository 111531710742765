import styled from '@emotion/styled';
import { CategoryLevels } from '../ProductDetails/ProductComponents/PerformanceCategory';
import { Tooltip } from '@mui/material';
import { DBProductApi } from '@tyrio/dto';
import { QRCodeSVG } from 'qrcode.react';
import threePmfsIcon from '../../assets/productDetailsNew/eprel-3pmfs.png';
import iceGripIcon from '../../assets/productDetailsNew/eprel-icegrip.png';
import fuelIcon from '../../assets/eprel/eprel-fuel.gif';
import EprelLogo from '../../assets/eprel/eprel-logo.gif';
import noiseIcon from '../../assets/eprel/eprel-noise.gif';
import wetIcon from '../../assets/eprel/eprel-wet.gif';
import EprelClass from './EprelClass';

interface EuLabelGeneratorProps {
  product: DBProductApi['getOne']['response'];
}

const EuLabelGenerator = ({ product }: EuLabelGeneratorProps) => {
  return (
    <Container>
      <EprelTop>
        <EprelLogoImg src={EprelLogo} alt="eprel-logo" />

        {product.eprelId && (
          <Tooltip
            children={
              <a
                href={`https://eprel.ec.europa.eu/screen/product/tyres/${product.eprelId}`}
                target="_blank"
                rel="noreferrer"
              >
                <QRCodeSVG
                  value={`https://eprel.ec.europa.eu/screen/product/tyres/${product.eprelId}`}
                  style={{ height: '43px', width: '43px' }}
                />
              </a>
            }
            title={'Redirect to Eprel'}
          />
        )}
      </EprelTop>
      <InfoRow>
        <b>{product.model?.brand}</b>
        <span>{product.manufacturerCode}</span>
      </InfoRow>
      <SecondInfoRow>
        <span>
          {`${product.size} ${product.loadIndex} ${product.speedIndex}`}
        </span>
        <span>{product.vehicleClass}</span>
      </SecondInfoRow>
      <Categories>
        <CategoryWrapperLeft>
          <Icon
            src={fuelIcon}
            alt="eprel-fuel-icon"
            style={{ width: '60px', marginLeft: '31px', marginTop: '-2px' }}
          />
          <ClassWrapper>
            <EprelClass
              width="30px"
              productClass={product.rollingResistance as CategoryLevels}
              category={CategoryLevels.A}
              type={'rollingResistance'}
            />
            <EprelClass
              width="38px"
              productClass={product.rollingResistance as CategoryLevels}
              category={CategoryLevels.B}
              type={'rollingResistance'}
            />
            <EprelClass
              width="45px"
              productClass={product.rollingResistance as CategoryLevels}
              category={CategoryLevels.C}
              type={'rollingResistance'}
            />
            <EprelClass
              width="53px"
              productClass={product.rollingResistance as CategoryLevels}
              category={CategoryLevels.D}
              type={'rollingResistance'}
            />
            <EprelClass
              width="61px"
              productClass={product.rollingResistance as CategoryLevels}
              category={CategoryLevels.E}
              type={'rollingResistance'}
            />
          </ClassWrapper>
        </CategoryWrapperLeft>
        <CategoryWrapperRight>
          <Icon
            src={wetIcon}
            alt="eprel-wet-icon"
            style={{ width: '76px', marginTop: '-2px', marginLeft: '24px' }}
          />
          <ClassWrapper>
            <EprelClass
              width="30px"
              productClass={product.wetGrip as CategoryLevels}
              category={CategoryLevels.A}
              type={'wetGrip'}
            />
            <EprelClass
              width="38px"
              productClass={product.wetGrip as CategoryLevels}
              category={CategoryLevels.B}
              type={'wetGrip'}
            />
            <EprelClass
              width="45px"
              productClass={product.wetGrip as CategoryLevels}
              category={CategoryLevels.C}
              type={'wetGrip'}
            />
            <EprelClass
              width="53px"
              productClass={product.wetGrip as CategoryLevels}
              category={CategoryLevels.D}
              type={'wetGrip'}
            />
            <EprelClass
              width="61px"
              productClass={product.wetGrip as CategoryLevels}
              category={CategoryLevels.E}
              type={'wetGrip'}
            />
          </ClassWrapper>
        </CategoryWrapperRight>
      </Categories>
      <Footer>
        <NoisePerformanceWrapper>
          <NoiseNumber>{product.noisePerformance}</NoiseNumber>
          <NoiseCategoryWrapper>
            <NoiseCategory category={product.noiseClassType === 'A'}>
              A
            </NoiseCategory>
            <NoiseCategory category={product.noiseClassType === 'B'}>
              B
            </NoiseCategory>
            <NoiseCategory category={product.noiseClassType === 'C'}>
              C
            </NoiseCategory>
          </NoiseCategoryWrapper>
        </NoisePerformanceWrapper>

        {product.threepmfs && (
          <ImageWrapper>
            <img
              src={threePmfsIcon}
              alt="three-pmfs"
              style={{
                objectFit: 'cover',
                width: '100%',
              }}
            />
          </ImageWrapper>
        )}
        {product.iceGrip && (
          <ImageWrapper>
            <img
              src={iceGripIcon}
              alt="ice-grip"
              style={{
                objectFit: 'cover',
                width: '100%',
              }}
            />
          </ImageWrapper>
        )}
        <EuDirectiveNumber>{product.euDirectiveNumber}</EuDirectiveNumber>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 272.91px;
  height: 415.73px;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background: white;
  border: 1px solid #878485;
  font-family: Arial, Helvetica, sans-serif;
`;

const EprelTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const EprelLogoImg = styled.img`
  width: 213px;
  object-fit: contain;
`;

const InfoRow = styled.div`
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  border-bottom: 1px solid #878485;
  margin-top: 3px;
  line-height: 15px;
`;

const SecondInfoRow = styled.div`
  display: flex;
  font-size: 15px;
  margin-top: 8px;
  justify-content: space-between;
  padding-bottom: 2px;
  border-bottom: 1px solid #878485;
  line-height: 15px;
`;

const EuDirectiveNumber = styled.div`
  position: absolute;
  right: 0px;
  font-size: 8px;
  margin-right: -15px;
  bottom: 10px;
  transform: rotate(90deg);
`;

const Categories = styled.div`
  display: flex;
  margin-top: 10px;
  padding-bottom: 3px;
  border-bottom: 1px solid #878485;
`;

const CategoryWrapperLeft = styled.div`
  width: 50%;
  padding-right: 10px;
  border-right: 1px solid #999;
`;

const ClassWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -9px;
`;

const CategoryWrapperRight = styled.div`
  width: 50%;
  padding-left: 7px;
`;

const Footer = styled.div`
  display: flex;
  position: relative;
  height: 70px;
  margin-top: 9px;
  justify-content: center;
`;

const Icon = styled.img`
  margin-left: 10px;
  margin-bottom: 10px;
`;

const NoiseNumber = styled.b`
  position: absolute;
  font-size: 15px;
  left: 25px;
  top: 16px;
`;

const NoiseCategoryWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: baseline;
  left: 36px;
  top: 53px;
`;

const ImageWrapper = styled.div`
  margin-left: 5px;
  margin-top: 10px;
  width: 65px;
`;

const NoiseCategory = styled.div<{ category: boolean }>`
  line-height: 18px;
  align-content: end;
  font-size: ${(props) => (props.category ? '18px' : '12px')};
  font-weight: ${(props) => (props.category ? '700' : '400')};
`;

const NoisePerformanceWrapper = styled.div`
  position: relative;
  background: url(${noiseIcon}) no-repeat center;
  background-size: 100%;
  box-sizing: border-box;
  width: 90px;
`;

export default EuLabelGenerator;
