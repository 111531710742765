import styled from '@emotion/styled';
import { TyrioLogo } from '@tyrio/ui-library';
const DashboardOverview = () => {
  return (
    <Container>
      <TextWrapper>
        <img
          src={TyrioLogo}
          alt="tyrio-logo"
          style={{
            filter: 'invert(20%)',
            width: '100%',
            maxWidth: '400px',
            padding: '32px',
          }}
        />
      </TextWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  font-weight: 600;
  font-size: 36px;
`;

export default DashboardOverview;
