import { CardWrapper } from '../SearchPOS/ReusableSelectors/BrandSelector';

import { Content, HeaderText } from '../../steps/Search/SelectStepper';
import {
  VehicleSteps,
  useVehicleCtx,
} from '../../../../context/VehicleContext';
import { useGetVehicleGenerations } from './query/get-generations';
import styled from '@emotion/styled';
import { IVehicleGeneration, VehicleBodies } from '@tyrio/dto';
import { CircularProgress, Typography } from '@mui/material';
import { Styles } from './Modifications';
import { startCase } from 'lodash';

export const Generation = () => {
  const {
    setActiveStep,
    selectedModel,
    selectedBrand,
    selectedGeneration,
    setSelectedGeneration,
    valueChanges,
    updateValueChanges,
    setGenerationDetails,
  } = useVehicleCtx();

  const { vehicleGenerations, isFetched, isLoading, isRefetching } =
    useGetVehicleGenerations(
      selectedBrand ?? '',
      selectedModel ?? '',
      valueChanges[VehicleSteps.MODEL] ?? true
    );

  if (isLoading || isRefetching) {
    return (
      <Content
        height={'700px'}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '700px',
        }}
      >
        <CircularProgress />
      </Content>
    );
  }

  return (
    <Content
      height={`calc(100vh - 202px)`}
      id="vehicle_generation"
      style={{ justifyContent: 'flex-start' }}
    >
      <TitleWrapper id="title_wrapper">
        <HeaderText style={Styles.headerStyle}>
          {selectedBrand?.toUpperCase()} <Dot id="dot" />
          {startCase(selectedModel ?? '')?.toUpperCase()}
        </HeaderText>
        <HeaderText>SELECT GENERATION</HeaderText>
      </TitleWrapper>

      <CardWrapper>
        {isFetched &&
          vehicleGenerations.map((value: IVehicleGeneration, key: number) => {
            return value.bodies.map((v: VehicleBodies) => {
              const isSelected = v.slug === selectedGeneration;

              return (
                <Card
                  style={{
                    justifyContent: 'center',
                    width: '49%',
                  }}
                  onClick={() => {
                    updateValueChanges(
                      VehicleSteps.GENERATIONS,
                      selectedGeneration !== value?.slug
                    );
                    updateValueChanges(VehicleSteps.MODEL, false);

                    setSelectedGeneration(value.slug);
                    setActiveStep(VehicleSteps.MODIFICATIONS);

                    setGenerationDetails({
                      name: v.name,
                      slug: v.slug,
                      image: v.image,
                    });
                  }}
                  selected={isSelected}
                  key={key + v.slug}
                >
                  <Typography>{value.name}</Typography>
                  <TimeWrapper>{`${value.start} - ${value.end}`}</TimeWrapper>
                  <img
                    src={v.image}
                    alt="generation"
                    height={150}
                    width={230}
                    style={{ objectFit: 'contain' }}
                  />
                  <div style={{ fontWeight: '400', fontFamily: 'Barlow' }}>
                    {v.name}
                  </div>
                </Card>
              );
            });
          })}
      </CardWrapper>
    </Content>
  );
};

const Card = styled.button<{
  selected: boolean;
  width?: number;
}>`
  width: ${(props) => (props.width ? props.width + 'px' : '270px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 260px;
  border-radius: 8px;
  margin-top: 16px;
  padding: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  background: ${(props) =>
    props.selected ? 'rgba(25, 118, 210, 0.15)' : 'none'};
  border: ${(props) =>
    props.selected ? '2px solid #1976d2' : '1px solid #dfe3e8'};

  &:hover {
    border: 2px solid #1976d2;
  }
`;

const TimeWrapper = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: #b1b4ba;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

export const Dot = styled.div`
  height: 5px;
  width: 5px;
  background-color: #919eab;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
`;
