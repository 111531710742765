import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { StockListContext } from '../../../../context/StockListContext';
import { usePrevious } from '../../../../hooks/usePrevious';
import { BranchesProp, WarehouseTransfer } from './WarehouseItems';
import { LineItems, SingleBranch } from './helper/interfaces';
import {
  StyleTableHeadCell,
  textStyle,
  textStyleOther,
} from './styles/Warehouse.style';

export const Warehouse = () => {
  const { outboundOrdersFilter, shouldEnlarge, outboundOrders } =
    useContext(StockListContext);

  const sliceOrders = useMemo(() => {
    return outboundOrders.slice(1, outboundOrders.length);
  }, [outboundOrders]);

  const prev = usePrevious(sliceOrders);
  const enlargedTableWidth = Number(window.innerWidth * 0.6 - 228);

  const [otherBranches, setOtherBranches] = useState(sliceOrders);

  const otherBranchNames = useMemo(() => {
    return otherBranches.map((m: SingleBranch) => m.branchName);
  }, [otherBranches]);

  const [filteredBranches, setFilteredBranches] = useState<unknown[]>([]);

  useEffect(() => {
    if (outboundOrdersFilter?.branchId?.length > 0)
      setFilteredBranches(
        sliceOrders.filter((o) =>
          outboundOrdersFilter.branchId?.includes(o.branchId)
        )
      );
  }, [outboundOrdersFilter.branchId, sliceOrders]);

  const filterBranches = useCallback(() => {
    if (outboundOrdersFilter?.branchId?.length > 0) {
      if (filteredBranches !== undefined)
        setOtherBranches([...filteredBranches]);
    } else setOtherBranches(sliceOrders);
  }, [filteredBranches, outboundOrdersFilter?.branchId?.length, sliceOrders]);

  useMemo(() => {
    filterBranches();
  }, [filterBranches]);

  useEffect(() => {
    if (!isEqual(prev, sliceOrders)) setOtherBranches(sliceOrders);
  }, [prev, sliceOrders]);

  return (
    <TableContainer
      component={Paper}
      style={{
        boxShadow: 'none',
        overflow: 'auto',
        width: shouldEnlarge ? enlargedTableWidth : 568,
        maxWidth: '100%',
      }}
    >
      <Table style={{}}>
        <TableHead
          style={{
            marginTop: '30px',
            border: 'none',
          }}
        >
          <TableRow style={{ display: 'flex' }}>
            <StyleTableHeadCell
              style={{
                position: 'sticky',
                left: 0,
                marginRight: '10px',
              }}
            >
              <Typography sx={{ ...textStyle }}>
                {outboundOrders[0]?.branchName?.toUpperCase()}
              </Typography>
            </StyleTableHeadCell>

            {otherBranchNames.map((name: string) => (
              <StyleTableHeadCell key={name}>
                <Typography sx={{ ...textStyleOther }}>
                  {name.toUpperCase()}
                </Typography>
              </StyleTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {outboundOrders[0]?.lineItems.map((m: LineItems) => {
            const newArray: (LineItems | BranchesProp)[] = [];
            otherBranches.forEach((o: SingleBranch) => {
              const c: LineItems | undefined = o.lineItems.find(
                (a: LineItems) => a.dot === m.dot
              );

              if (c === undefined) {
                newArray.push({ subBranchId: o.branchId });
              } else newArray.push(c);
            });

            return (
              <WarehouseTransfer
                branches={newArray}
                mainBranch={m}
                key={m.stockListItemId}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
