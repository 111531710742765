import api from '@tyrio/api-factory';
import { DBAppointmentApi } from '@tyrio/dto';
import { useQuery } from 'react-query';
import { usePosCartCtx } from '../../../../../../../context/PosCartContext';
import { useDebounce } from '../../../../../../../hooks/useDebounce';

export const useCheckAppointments = () => {
  const { customerDetails } = usePosCartCtx();

  const phoneNumber = `+${customerDetails['mobile_phone']}`;

  const debouncedSearch = useDebounce(phoneNumber, 1000);

  const query = useQuery(
    [debouncedSearch],
    () =>
      api.fetch<DBAppointmentApi['checkAppointments']>('check_appointments', {
        phoneNumber,
      }),
    {
      enabled: !!phoneNumber,
    }
  );

  return {
    ...query,
  };
};
