import { TRule } from '../../typings';

export const GUMAX_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.brand",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.SKU",
    destination: 'product',
    targetKey: 'manufacturerCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['itemID']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.brand',
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['netnet_eur']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
  {
    type: 'rule.operate',
    sourceKey: "c['name']",
    destination: 'product',
    targetKey: 'dot',
    operationName: 'getGumaxDOT',
  },
];
