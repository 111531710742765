import api from '@tyrio/api-factory';
import { WheelSizeApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetVehicleGenerations = (
  make: string,
  model: string,
  enabled: boolean
) => {
  const { data, isFetched, isError, isLoading, isRefetching } = useQuery(
    ['get_generations'],
    () =>
      api.fetch<WheelSizeApi['getOneGeneration']>('get_generations', {
        make,
        model,
      }),

    { enabled }
  );

  return {
    vehicleGenerations: data?.data ?? [],
    isFetched,
    isError,
    isLoading,
    isRefetching,
  };
};
