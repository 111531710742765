import { SearchOutlined } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DBClientSupplierOrderItemFilters } from '@tyrio/dto';
import { useState } from 'react';
import { SEARCH_TYPE } from '../../customer-orders/helpers/filters-data';
import { ORDER_FILTERS } from '../helpers/filters-data';
import { ComponentType } from '../helpers/types';

interface SearchTypeInputProps {
  filters: DBClientSupplierOrderItemFilters;
  setFilters: (value: DBClientSupplierOrderItemFilters) => void;
  componentType: ComponentType;
  setFilterValue?: (data: Partial<qs.ParsedQs>) => void;
}

const SearchTypeInput = ({
  filters,
  setFilters,
  componentType,
  setFilterValue,
}: SearchTypeInputProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const listItems =
    componentType === ComponentType.purchase ? ORDER_FILTERS : SEARCH_TYPE;

  const getSelectFieldStyle = () => {
    const borderWeight = isClicked ? 2 : 1;
    const bordersStyle =
      isHovered || isClicked ? `${borderWeight}px solid black` : '';
    return {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: bordersStyle,
    };
  };

  const getTextFieldStyle = () => {
    const borderWeight = isClicked ? 2 : 1;
    const bordersStyle =
      isHovered || isClicked ? `${borderWeight}px solid black` : '';
    return {
      borderLeft: 'none',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderRight: bordersStyle,
      borderTop: bordersStyle,
      borderBottom: bordersStyle,
    };
  };

  const getSearchFieldProps = () => {
    return {
      onMouseOver: () => setIsHovered(true),
      onMouseOut: () => setIsHovered(false),
      onFocus: () => setIsClicked(true),
      onBlur: () => {
        setIsClicked(false);
        setIsHovered(false);
      },
    };
  };

  return (
    <Grid>
      <FormControl
        sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
      >
        <InputLabel sx={{ width: '20%' }}>Search type</InputLabel>
        <Select
          label="Search type"
          {...getSearchFieldProps()}
          sx={{
            width: '30%',
            '& fieldset': {
              ...getSelectFieldStyle(),
            },
          }}
          value={filters.searchType ? filters.searchType : 'orderNumber'}
          onChange={(e) => {
            setFilters({
              ...filters,
              searchType: e.target.value,
            });
            setFilterValue &&
              setFilterValue({
                selected: undefined,
                searchFilter: e.target.value,
              });
          }}
        >
          {listItems.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          variant="outlined"
          placeholder="Search orders"
          type={'text'}
          {...getSearchFieldProps()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            width: '70%',
            '& fieldset': {
              ...getTextFieldStyle(),
            },
          }}
          value={filters.searchKeyword}
          onChange={(e) => {
            setFilters({
              ...filters,
              searchKeyword: e.target.value,
              searchType: filters.searchType
                ? filters.searchType
                : 'orderNumber',
            });
            setFilterValue &&
              setFilterValue({
                selected: undefined,
                searchKeyword: e.target.value,
                page: '1',
              });
          }}
        />
      </FormControl>
    </Grid>
  );
};

export default SearchTypeInput;
