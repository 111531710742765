/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Modal, Tooltip, Typography } from '@mui/material';
import { DBBillingAddress, DBDeliveryAddress } from '@prisma/client';
import { CompanyInfo, PhoneNumberDetails } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { CountryData } from 'react-phone-input-2';
import { checkIfValidPhoneNumber } from '../../supplier-form/helpers/regex';
import pencil_alt from '../assets/pencil-alt.svg';
import {
  AddressProps,
  AddressType,
  EditAddressProps,
} from '../components/AddressCard/AddressCard';
import { AddressTextField } from '../components/AddressCard/AddressTextField';
import { InputPhone } from '../components/AddressCard/InputPhone';
import {
  LastEditData,
  LastEditTypographyHeader,
  RowWrapper,
  Title,
} from '../styles/AddressCard.style';
import _ from 'lodash';

interface IAddressModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  addressData: DBDeliveryAddress | DBBillingAddress | null;
  addressType: AddressType;
  disabled: boolean;
  handleChange: (e: any) => void;
  newAddress: AddressProps;
  setNewAddress: Dispatch<SetStateAction<AddressProps>>;
  editedMeta: EditAddressProps;
  editedAddress: any;
  setEditedAddress: any;
}

export const EditAddressModal = ({
  open,
  setOpen,
  addressData,
  addressType,
  disabled,
  handleChange,
  newAddress,
  editedMeta,
  setNewAddress,
  setEditedAddress,
  editedAddress,
}: IAddressModal) => {
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [initial, _setInitial] = useState(newAddress);

  const handleSubmit = () => {
    const emptyFieldsExists = Object.values(newAddress).some((x) => x === '');
    if (emptyFieldsExists) {
      window.confirm('All fields are required.');
    } else {
      setOpen(false);
      ToastHelper.showToast(
        'Address',
        ToastType.SUCCESS,
        ToastMessageType.UPDATE,
        'Successful address editing! To save your edits, click Save!'
      );
    }
  };

  const handleValidation = (e: string) => {
    if (!checkIfValidPhoneNumber(e) || e.length > 13) {
      setIsPhoneNumberValid(false);
      return false;
    } else {
      setIsPhoneNumberValid(true);
      return true;
    }
  };

  const handlePhoneNumberChange = (value: string, country: CountryData) => {
    const isValid = handleValidation('+' + value);

    if (isValid) {
      const address = {
        ...newAddress,
        phoneNumberDetails: {
          countryCode: country.dialCode,
          phoneNumber: value.slice(country.dialCode.length),
        },
      };
      if (addressType === AddressType.billing) {
        setEditedAddress({ ...editedAddress, billingAddress: address });
      } else {
        setEditedAddress({ ...editedAddress, deliveryAddress: address });
      }
      setNewAddress(address);
    }
  };

  const locationDetails = `${
    addressData?.zip !== '' ? addressData?.zip + ',' : ''
  } ${addressData?.city !== '' ? addressData?.city + ',' : ''} ${
    addressData?.country
  }`;

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '900px',
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
          height: '700px',
          padding: '24px',
        }}
      >
        <CloseWrapper>
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
            sx={{ cursor: 'pointer' }}
          />
        </CloseWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between  ',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <div style={{ width: '430px' }}>
            <TitleWrapper>ORIGINAL ADDRESS</TitleWrapper>
            <Box
              sx={{
                padding: '16px',
                border: '1px solid #DFE3E8',
                borderRadius: '8px',
                height: '250px',
              }}
            >
              <Title>{addressType}:</Title>
              <div style={{ marginTop: '20px' }}>
                <AddressDetails style={{ color: '#1976D2' }}>
                  {
                    (addressData?.companyInfo as unknown as CompanyInfo)
                      ?.companyName
                  }
                </AddressDetails>
                {addressType === AddressType.billing && (
                  <AddressDetails>{`VAT: ${
                    (addressData?.companyInfo as unknown as CompanyInfo)?.vat
                  }`}</AddressDetails>
                )}
                <AddressDetails
                  style={{ fontSize: '16px', fontWeight: 500 }}
                >{`${addressData?.firstName?.toUpperCase()} ${addressData?.lastName?.toUpperCase()}`}</AddressDetails>
                <AddressDetails>{addressData?.address}</AddressDetails>
                <AddressDetails>{`${locationDetails}`}</AddressDetails>
                <AddressDetails>
                  {`${
                    (
                      addressData?.phoneNumberDetails as unknown as PhoneNumberDetails
                    )?.countryCode
                  } ${
                    (
                      addressData?.phoneNumberDetails as unknown as PhoneNumberDetails
                    )?.phoneNumber
                  }`}
                </AddressDetails>
                {addressType === AddressType.billing && (
                  <AddressDetails style={{ color: '#1976D2' }}>{`${
                    (addressData as unknown as DBBillingAddress)?.email
                  }`}</AddressDetails>
                )}
              </div>
            </Box>
            <Box
              sx={{
                background: '#F4F6F8',
                width: '100%',
                padding: '10px',
                marginTop: '10px',
                borderRadius: '8px',
              }}
            >
              <Grid>
                <LastEditTypographyHeader>Last edit: </LastEditTypographyHeader>
                <LastEditData>
                  {editedMeta['editedBy'] ?? ''}
                  {' - '}
                  {moment(editedMeta['updatedAt']).format(
                    'DD.MM.YYYY - HH:mm:ss'
                  )}
                </LastEditData>
              </Grid>
            </Box>
          </div>
          <div style={{ width: '430px', height: '550px' }}>
            <TitleWrapper>EDITED ADDRESS</TitleWrapper>
            <Box
              style={{
                padding: '16px',
                border: '1px solid #DFE3E8',
                borderRadius: '8px',
                height: '500px',
              }}
            >
              <RowWrapper>
                <Title>{addressType}:</Title>

                <Tooltip title="Edit address">
                  <img src={pencil_alt} alt="pencil_alt" />
                </Tooltip>
              </RowWrapper>
              <FormWrapper>
                <AddressTextField
                  id="companyName"
                  placeholder="Company name"
                  value={newAddress?.companyInfo?.companyName}
                  handleChange={handleChange}
                  disabled={false}
                  color="#1976D2"
                  customPadding="10px 8px"
                />
                {addressType === AddressType.billing &&
                  newAddress.companyInfo?.vat && (
                    <Box sx={{ display: 'flex' }}>
                      <AddressTextField
                        id="vat"
                        placeholder="VAT"
                        value={newAddress.companyInfo?.vat}
                        handleChange={handleChange}
                        disabled={disabled}
                        customPadding="10px 8px"
                      />
                    </Box>
                  )}

                <Grid
                  container
                  display="flex"
                  rowGap={2}
                  justifyContent={'space-between'}
                >
                  <Box sx={{ width: '49%' }}>
                    <AddressTextField
                      id="firstName"
                      placeholder="First name"
                      value={newAddress.firstName}
                      handleChange={handleChange}
                      disabled={disabled}
                      customPadding="10px 8px"
                    />
                  </Box>
                  <Box sx={{ width: '49%' }}>
                    <AddressTextField
                      id="lastName"
                      placeholder="Last name"
                      value={newAddress.lastName}
                      handleChange={handleChange}
                      disabled={disabled}
                      customPadding="10px 8px"
                    />
                  </Box>
                </Grid>

                <AddressTextField
                  id="address"
                  placeholder="Address"
                  value={newAddress.address ?? ''}
                  handleChange={handleChange}
                  disabled={disabled}
                  customPadding="10px 8px"
                />

                <Grid
                  container
                  display="flex"
                  rowGap={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box sx={{ width: '25%' }}>
                    <AddressTextField
                      id="zip"
                      placeholder="Zip code"
                      value={newAddress.zip}
                      handleChange={handleChange}
                      disabled={disabled}
                      customPadding="10px 8px"
                    />
                  </Box>

                  <Box sx={{ width: '65%' }}>
                    <AddressTextField
                      id="city"
                      placeholder="City"
                      value={newAddress?.city}
                      handleChange={handleChange}
                      disabled={disabled}
                      customPadding="10px 8px"
                    />
                  </Box>
                  <Typography>{newAddress?.country}</Typography>
                </Grid>

                <InputPhone
                  value={
                    _.toString(
                      newAddress?.phoneNumberDetails?.countryCode +
                        newAddress?.phoneNumberDetails?.phoneNumber
                    ) ?? ''
                  }
                  handleChange={handlePhoneNumberChange}
                  isValid={isPhoneNumberValid}
                />

                {addressType === AddressType.billing && (
                  <AddressTextField
                    id="email"
                    placeholder="Email"
                    value={newAddress.email}
                    handleChange={handleChange}
                    disabled={disabled}
                    color="#1976D2"
                    customPadding="10px 8px"
                  />
                )}
              </FormWrapper>
            </Box>
          </div>
        </Box>
        <ButtonsWrapper>
          <Button
            color="info"
            style={{
              width: '100%',
              height: '60px',
              border: '1px solid rgba(25, 118, 210, 1)',
            }}
            onClick={() => {
              setOpen(false);
              setNewAddress(initial);
            }}
          >
            CANCEL
          </Button>
          <Button
            style={{
              width: '100%',
              height: '60px',
              backgroundColor: 'rgba(25, 118, 210, 1)',
              color: 'white',
            }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </ButtonsWrapper>
      </Box>
    </Modal>
  );
};

const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const TitleWrapper = styled.div`
  font-family: Barlow;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

const FormWrapper = styled.div`
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AddressDetails = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  cursor: default;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: sticky;
  top: 100%;
  gap: 20px;
`;
