import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const NOWEGUMY_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.global.operate',
    destination: 'model',
    targetKey: 'category_id',
    operationName: 'getNoweGumyCategory',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.manufacturer',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.season',
    condition: 'equals',
    conditionValues: ['summer', 'winter', 'all-season'],
    conditionOutputs: ['summer', 'winter', 'allseason'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'width',
    operationName: 'generateNoweGumySize',
  },
  // Model name
  {
    type: 'rule.global.operate',
    destination: 'model',
    targetKey: 'modelName',
    operationName: 'generateNoweGumyModelName',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.ean',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.cai',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.model',
    condition: 'contains',
    conditionValue: ' XL ',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: 'XL',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.name',
    condition: 'contains',
    conditionValue: ' XL ',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: 'XL',
    outputType: 'string',
  },
  // Load index and Speed Index
  {
    type: 'rule.map',
    sourceKey: 'c.load_index',
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.speed_index',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'globalGetNoweGumySpecialMarkings',
    applyOnlyForCategories: [30, 31, 32, 33, 50], // carTyres
  },
  // // ThreePMFs
  // {
  //   type: 'rule.condition_boolean',
  //   sourceKey: 'c["3PMSF Mark"]',
  //   condition: 'equals',
  //   conditionValues: ['Yes', 'No'],
  //   conditionOutputs: [true, false],
  //   destination: 'product',
  //   targetKey: 'threepmfs',
  //   outputType: 'boolean',
  // },
  // // // iceGrip
  // // {
  // //   type: 'rule.condition_boolean',
  // //   sourceKey: 'c["ice grip"]',
  // //   condition: 'equals',
  // //   conditionValues: ['J', 'N'],
  // //   conditionOutputs: [true, false],
  // //   destination: 'product',
  // //   targetKey: 'iceGrip',
  // //   outputType: 'boolean',
  // // },
  // // Globalni fieldovi
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c["Rollwiderstand"]',
  //   targetKey: 'rollingResistance',
  //   destination: 'product',
  //   outputType: 'string',
  //   applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c["Nasshaftung"]',
  //   targetKey: 'wetGrip',
  //   destination: 'product',
  //   outputType: 'string',
  //   applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c["Reifenger�usch"]',
  //   targetKey: 'noisePerformance',
  //   destination: 'product',
  //   outputType: 'number',
  //   applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c["Reifenger�uschklasse"]',
  //   targetKey: 'noiseClassType',
  //   destination: 'product',
  //   outputType: 'string',
  //   applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  // },
  // // {
  // //   type: 'rule.map',
  // //   sourceKey: 'c["EU regulation"]',
  // //   targetKey: 'euDirectiveNumber',
  // //   destination: 'product',
  // //   outputType: 'string',
  // // },
  // PR
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'pr',
    operationName: 'getNoweGumyPR',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.eprel_id',
    targetKey: 'eprelId',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 't.product.eprelId',
    targetKey: 'eprelUrl',
    destination: 'product',
    postProcess: ['getEprelUrlFromId'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.photo',
    targetKey: 'originalPhotos',
    destination: 'model',
    outputType: 'string',
  },

  // Weight
  {
    type: 'rule.map',
    sourceKey: 'c.weight',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  // Tube Type - moto
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'tubeType',
    template: 'TT',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.name',
    condition: 'contains',
    conditionValue: ' TT',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'tubeType',
    outputValue: 'TT',
    outputType: 'string',
    applyOnlyForCategories: [48], // quad
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.name',
    condition: 'contains',
    conditionValue: 'TL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'tubeType',
    outputValue: 'TL',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39, 48], // motoTyres & quads
  },
  // Axle
  {
    type: 'rule.global.operate',
    destination: 'model',
    targetKey: 'wheelPosition',
    operationName: 'getNoweGumyAxle',
  },
  // Sidewall
  {
    type: 'rule.condition',
    sourceKey: 'c.model',
    condition: 'contains',
    conditionValue: 'WW',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'sidewall',
    outputValue: 'WW',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.model',
    condition: 'contains',
    conditionValue: 'ww',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'sidewall',
    outputValue: 'WW',
    outputType: 'string',
  },
  // EU Label / eprel
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'euDirectiveNumber',
    operationName: 'getNoweGumyEprel',
  },
  ...GLOBAL_RULESET,
];
