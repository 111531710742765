import { Enum } from '../app/src/features/supplier-form/helpers/types';
import { TyrioSelectInputOption } from '@tyrio/forms';
import { capitalize, startCase } from 'lodash';
import {
  DBBranchType,
  DBConnectionProtocol,
  DBCurrency,
  DBDeliveryTypeEnum,
  DBFileFormat,
  DBOrderStatus,
  DBPaymentStatusEnum,
  DBSupplierApiConnectionType,
} from '@prisma/client';
import { DBCustomerOrderStatusEnum } from '@tyrio/dto';

export function parseEnumToArray(e: Enum): TyrioSelectInputOption[] {
  return Object.values(e).map((item) => parseEnumToObject(e, item));
}

export function parseEnumToStringArray(e: Enum): string[] {
  return Object.values(e).map(
    (item) => parseEnumToObject(e, item).value as string
  );
}

export type InputOption = { label: string; value: string };

export function parseEnumToInputOption(e: Enum): InputOption[] {
  return Object.values(e).map((item) => {
    return { label: startCase(item), value: item };
  });
}

export function parseEnumToObject(
  e: Enum,
  value: string
): TyrioSelectInputOption {
  return {
    value: value,
    label: getDropdownLabel(e, value),
  };
}

export function getDropdownLabel(e: Enum, value: string) {
  if (e === DBConnectionProtocol) return value.toUpperCase();

  if (e === DBFileFormat) return `.${value.toLowerCase()}`;

  if (e === DBCurrency)
    return getCurrencySymbol(value as DBCurrency) + ' - ' + value.toUpperCase();

  if (
    e === DBOrderStatus ||
    DBBranchType ||
    DBSupplierApiConnectionType ||
    DBCustomerOrderStatusEnum ||
    DBPaymentStatusEnum
  ) {
    if (value === undefined) return capitalize(value);
    else return capitalize(value.replace(/_/g, ' '));
  }

  if (e === DBDeliveryTypeEnum) return value.toUpperCase();

  return capitalize(value);
}

function getCurrencySymbol(currency: DBCurrency) {
  if (currency === DBCurrency.BAM) return 'KM';

  if (currency === DBCurrency.EUR) return '€';

  if (currency === DBCurrency.RSD) return 'DIN';

  if (currency === DBCurrency.USD) return '$';

  return '';
}

export function getInstalmentValues() {
  const arr = [];
  for (let index = 1; index < 25; index++) {
    const element = {
      value: index,
      label: index,
    };
    arr.push(element);
  }

  return arr;
}
