/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import {
  IJsonLogic,
  INewCondition,
  IRules,
} from '../../price-calculation/context/RulesContext';
import { isEmpty, isEqual, isNumber } from 'lodash';
import { ToastHelper, ToastType, ToastMessageType } from '@tyrio/ui-library';

// UPDATE RULES CONDITIONS
export const updateRules = (
  updatedJsonLogic: { current: IJsonLogic[] },
  rules: IRules[],
  newRuleCondition: { current: INewCondition[] }
) => {
  updatedJsonLogic.current.map((jl: IJsonLogic) => {
    const updateRule = rules.find(
      (r: IRules) => r.id === jl.ruleIndex
    ) as IRules;

    const conditionChanged = !isEqual(updateRule?.conditions, jl?.jsonLogic);
    if (
      conditionChanged &&
      jl?.jsonLogic !== '' &&
      jl?.jsonLogic !== undefined &&
      updateRule !== undefined
    )
      updateRule.conditions = jl?.jsonLogic;
  });

  rules.map((singleRule) => {
    const condition = newRuleCondition.current.find(
      (r: INewCondition) => r.id === singleRule.id
    );
    singleRule.newConditions = condition?.tree;
  });

  // CHECK IF THERE ARE INVALID RULES AND REMOVE THEM
  const invalidRulesIndex = rules
    .map((r, index) => {
      if (
        (isEmpty(r?.actions) && r?.conditions === '') ||
        (!isEmpty(r?.actions) && isEmpty(r?.conditions)) ||
        (isEmpty(r?.actions) && !isEmpty(r?.conditions))
      ) {
        return index;
      } else return null;
    })
    .filter((f) => isNumber(f));

  if (invalidRulesIndex.length > 0) {
    ToastHelper.showToast(
      'Rules list',
      ToastType.WARNING,
      ToastMessageType.CUSTOM_ERROR,
      'You must add conditions and actions to each rule.'
    );

    return null;
  }
  return rules;
};
