/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import CancelIcon from '@mui/icons-material/Cancel';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  Alert,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import api, { ENDPOINTS } from '@tyrio/api-factory';
import { DBProductModelApi, PhotoShape } from '@tyrio/dto';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  tyrioIcons,
  tyrioUI,
} from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UseFormRegister } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryClient } from '../../../../../query-client';
import { LoaderWrapper } from '../../../../components/PageTemplate/PageTemplate';
import { ExpiredSessionModal } from '../../../../components/Timer/modals/ExpiredSession';
import { useAuth } from '../../../../context/AuthContext';
import { AccectableFormats } from '../../../../pages/company-settings/documents-look/style';
import { getTextField } from '../../../form-helper';
import FormSectionWithTitle from './FormSectionWithTitle';

interface ProductMediaProps {
  isRequired: (fieldKey: string) => boolean;
  errors: any;
  register: UseFormRegister<any>;
  isEditDisabled: boolean;
  uid: string;
  photos?: PhotoShape[];
  modelPhotos: Record<string, PhotoShape>;
  setModelPhotos: Dispatch<SetStateAction<Record<string, PhotoShape>>>;
}

export const ProductMedia = (props: ProductMediaProps) => {
  const { user } = useAuth();
  const params = useParams();
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/svg': ['.svg'],
      'image/webp': ['.webp'],
    },
    maxSize: 3 * 1024 * 1024,
    maxFiles: 1,
    onDrop: (acceptedFiles) => onImageChange(acceptedFiles),
  });

  const pathId = _.get(params, 'id');

  const [isHovered, setIsHovered] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState<Record<string, boolean>>(
    {}
  );
  const [isCancelHovered, setIsCancelHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteImageUrl, setDeleteImageUrl] = useState('');

  // image upload mutation
  const { mutate: uploadImage, isLoading } = useMutation(
    (formData: {
      method: string;
      url: string;
      data: FormData;
      headers: {
        Authorization: string;
        'Content-Type': string;
      };
    }) => axios.request(formData),
    {
      mutationKey: 's3_add_product_image',
      onSuccess: () => {
        queryClient.refetchQueries('get_product_model_by_id');
        ToastHelper.showToast(
          'Product model image',
          ToastType.SUCCESS,
          ToastMessageType.UPLOAD,
          'Image succesfully uploaded!'
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Error',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occured.'
          );
        }
        throw error;
      },
    }
  );

  // delete image mutation
  const { mutate: deleteImage, isLoading: isDeleteLoading } = useMutation(
    (url: string) =>
      api.fetch<DBProductModelApi['deleteImage']>('delete_product_image', {
        uid: props.uid,
        url,
      }),
    {
      mutationKey: 'delete_product_image',
      onSuccess: () => {
        queryClient.refetchQueries('get_product_model_by_id');
        ToastHelper.showToast(
          'Product model image',
          ToastType.SUCCESS,
          ToastMessageType.DELETE,
          'Image succesfully deleted!'
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Error',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occured.'
          );
        }
        throw error;
      },
    }
  );

  const onImageChange = (acceptedFiles: File[]) => {
    if (acceptedFiles !== null && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      const token = window.localStorage.getItem('@@apiToken');

      form.append('file', file, 'product-image');

      const options = {
        method: 'PUT',
        url: `${ENDPOINTS.upload_product_image.uri}/${props?.uid}`,
        data: form,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data;',
        },
      };

      uploadImage(options);
    }
  };

  const sortedPhotos = useMemo(() => {
    if (props.photos && props.photos.length > 0)
      return props.photos.sort((a, b) =>
        a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1
      );
    return [];
  }, [props.photos]);

  const handleFavorite = (url: string) => {
    if (_.isEmpty(props.modelPhotos)) return;

    const currentFavorite = Object.values(props.modelPhotos).find(
      (item) => item.isFavorite === true
    );

    if (currentFavorite && currentFavorite.url === url) {
      props.setModelPhotos((prevValue) => ({
        ...prevValue,
        [url]: {
          ...currentFavorite,
          isFavorite: false,
        },
      }));
    } else {
      Object.values(props.modelPhotos).forEach((photo) => {
        props.setModelPhotos((prevValue) => ({
          ...prevValue,
          [photo.url]: {
            ...photo,
            isFavorite: false,
          },
        }));
      });
      props.setModelPhotos((prevValue) => ({
        ...prevValue,
        [url]: {
          ...prevValue[url],
          isFavorite: !prevValue[url].isFavorite,
        },
      }));
    }
  };

  const handleDelete = (url: string) => {
    setIsModalOpen(true);
    setDeleteImageUrl(url);
  };

  return (
    <Wrapper>
      {isModalOpen && (
        <ExpiredSessionModal
          text={'Image will be permanently removed'}
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onContinue={() => {
            deleteImage(deleteImageUrl);
            setIsModalOpen(false);
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FormSectionWithTitle label="Product videos" />
        </Grid>
        {getTextField(
          'Video URL',
          'videoUrl',
          props.isRequired,
          props.errors,
          props.register,
          { xs: 12, lg: 12 },
          'Example: https://youtu.be/JQQC5k6AyJ0',
          { disabled: props.isEditDisabled, shrink: true }
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FormSectionWithTitle label="Product images" />
        </Grid>

        {!sortedPhotos ||
          (sortedPhotos.length === 0 && user?.clientId && (
            <Grid item xs={12} lg={12}>
              <Alert severity="warning" sx={{ width: '100%' }}>
                There are no images currently for this product model.
              </Alert>
            </Grid>
          ))}

        {pathId === 'new' && (
          <Grid item xs={12} lg={12}>
            <Alert severity="warning" sx={{ width: '100%' }}>
              First create product model, then you can add images.
            </Alert>
          </Grid>
        )}

        {!isLoading && !isDeleteLoading ? (
          <Grid
            item
            xs={12}
            lg={12}
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(224px, 1fr))',
              gap: '10px',
              columnGap: '10px',
            }}
          >
            <input style={{ display: 'none' }} {...getInputProps()} />

            {sortedPhotos.map((photo, idx) => (
              <ImageWrapper
                key={`${photo.slug}-${idx}`}
                onMouseOver={() =>
                  setIsImageHovered((prevValue) => ({
                    ...prevValue,
                    [photo.url]: true,
                  }))
                }
                onMouseLeave={() =>
                  setIsImageHovered((prevValue) => ({
                    ...prevValue,
                    [photo.url]: false,
                  }))
                }
              >
                {(props.modelPhotos[photo.url] &&
                  props.modelPhotos[photo.url]['isFavorite']) ||
                isImageHovered[photo.url] ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      width: '100%',
                      height: '20px',
                    }}
                  >
                    {props.modelPhotos[photo.url] &&
                    props.modelPhotos[photo.url]['isFavorite'] ? (
                      <Tooltip
                        placement="top"
                        title={`${
                          user?.adminId
                            ? 'Remove from favorites'
                            : 'Only admin can remove image from favorites'
                        }`}
                      >
                        <FavoriteIcon
                          sx={{
                            color: '#FFDB80',
                            float: 'left',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            user?.adminId && handleFavorite(photo.url)
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="top"
                        title={`${
                          user?.adminId
                            ? 'Add to favorites'
                            : 'Only admin can add image to favorites'
                        }`}
                      >
                        <FavoriteBorderIcon
                          sx={{ float: 'left', cursor: 'pointer' }}
                          onClick={() =>
                            user?.adminId && handleFavorite(photo.url)
                          }
                        />
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '22px',
                    }}
                  />
                )}

                {isImageHovered[photo.url] && (
                  <div
                    style={{
                      position: 'absolute',
                      cursor: 'pointer',
                      bottom: '273px',
                      right: '-5px',
                      background: 'white',
                      height: '20px',
                    }}
                    onMouseOver={() => setIsCancelHovered(true)}
                    onMouseLeave={() => setIsCancelHovered(false)}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        user?.adminId
                          ? 'Delete the image'
                          : 'Only admin can delete the image'
                      }
                    >
                      <CancelIcon
                        color={!isCancelHovered ? 'disabled' : 'error'}
                        onClick={() => user?.adminId && handleDelete(photo.url)}
                      />
                    </Tooltip>
                  </div>
                )}

                <div
                  style={{
                    height: '170px',
                    width: '100%',
                    minHeight: '170px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      maxHeight: '170px',
                      maxWidth: '80%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={photo.url}
                      alt="Product"
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </div>
                </div>

                <ImageDetails>
                  <Divider sx={{ marginBottom: '5px' }} />
                  <ImageDetailsText>{photo.slug}</ImageDetailsText>
                  {photo.size && (
                    <ImageDetailsText>
                      {`Size: ${photo.size.toFixed(2)} KB`}
                    </ImageDetailsText>
                  )}

                  {photo.uploadedBy && (
                    <ImageDetailsText>
                      {`Uploaded: ${photo.uploadedBy} ${moment(
                        photo.uploadDate
                      ).format('DD.MM.YYYY')}`}
                    </ImageDetailsText>
                  )}
                </ImageDetails>
              </ImageWrapper>
            ))}

            {user?.adminId && pathId !== 'new' && (
              <AddImageWrapper
                onMouseOver={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...getRootProps()}
              >
                <WmsIconStyled
                  icon={tyrioIcons.addProductImage}
                  viewBox={'0 0 80 80'}
                  ishovered={isHovered}
                  sx={{ width: '90px', height: '90px' }}
                />

                <div style={{ color: !isHovered ? '#919EAB' : 'black' }}>
                  Add new image
                </div>

                <AccectableFormats
                  style={{ color: !isHovered ? '#919EAB' : 'black' }}
                >
                  Drop image or click to browse
                </AccectableFormats>
                <AccectableFormats
                  style={{ color: !isHovered ? '#919EAB' : 'black' }}
                >
                  SVG, PNG, JPG or GIF (max. 3MB)
                </AccectableFormats>
              </AddImageWrapper>
            )}
          </Grid>
        ) : (
          <LoaderWrapper style={{ flexDirection: 'column', gap: '10px' }}>
            <CircularProgress />
            {isLoading ? 'Uploading image' : 'Deleting image'}
          </LoaderWrapper>
        )}
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const AddImageWrapper = styled.div`
  height: 285px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #e6e6e6;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${tyrioUI.colors.blue.B100};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 285px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #e6e6e6;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${tyrioUI.colors.blue.B100};
  }
`;

const ImageDetails = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const ImageDetailsText = styled(Typography)`
  font-size: 11px;
  color: #919eab;
  font-family: Barlow;
`;

export const WmsIconStyled = styled(WmsIcon)<{ ishovered: boolean }>`
  cursor: pointer;
  color: ${(props) =>
    !props.ishovered ? '#b9c4ce' : tyrioUI.colors.blue.B100};
`;
