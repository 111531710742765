import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { StockListSortingType } from '@tyrio/dto';
import { parseEnumToInputOption } from '../../../../../../helpers/enum-parser';

export enum SuppliersSortingType {
  defaultSorting = 'Default Sorting',
  priceAscending = 'Price ascending',
  priceDescending = 'Price descending',
}

export const SortingDropdown = ({
  sortingValue,
  setSortingValue,
}: {
  sortingValue: string;
  setSortingValue: (a: string) => void;
}) => {
  const sortingOptions = parseEnumToInputOption(SuppliersSortingType);

  return (
    <FormControl size="small" style={{ width: 'fit-content' }}>
      <InputLabel id="sorting">Sorting</InputLabel>
      <Select
        labelId="sorting"
        id="sorting"
        value={sortingValue}
        label="Sorting"
        onChange={(e) => setSortingValue(e.target.value)}
        style={{ width: '190px' }}
        defaultValue={StockListSortingType.defaultSorting}
      >
        {sortingOptions.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
