import styled from '@emotion/styled';
import CachedIcon from '@mui/icons-material/Cached';
import { FormControl, Grid, TextField } from '@mui/material';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioButtonInput, TyrioFormError } from '../types';

interface TyrioInputButtonProps {
  item: TyrioButtonInput;
  id: string;
  gridProps: FormGridProps;
  error?: TyrioFormError;
  register: UseFormRegister<FieldValues>;
}

export const TyrioInputButton = ({
  item,
  id,
  error,
  gridProps,
  register,
}: TyrioInputButtonProps) => {
  const { t } = useTranslation();
  return (
    <GridContainer item {...gridProps}>
      <FormControl fullWidth>
        <Container>
          <TextField
            fullWidth
            error={!!error?.message}
            helperText={error?.message}
            id={id}
            disabled={item.disabled}
            label={t(`${item.label}`)}
            {...register(id)}
          />
          <ButtonContainer>
            <Button>
              {t('AUTO FILL')} <CachedIcon />
            </Button>
          </ButtonContainer>
        </Container>
      </FormControl>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 8.3%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 150px;
  margin-left: 16px;
`;

const Button = styled.div`
  width: 140px;
  height: 42px;
  color: #1976d2;
  background: none;
  font-weight: 500;
  font-size: 15px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1976d2;
`;
