/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  DBPriceRound,
  DBRebateCalculationType,
  DBRebateGroupType,
} from '@prisma/client';
import { DBClientRebateCalculationApi } from '@tyrio/dto';
import {
  Control,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { parseEnumToInputOption } from '../../../../helpers/enum-parser';
import { getAutocompleteSingleField, getTextField } from '../form-helper';
import { FormSectionWithLabel } from '../products/pages/components/FormSectionWithTitle';

export const CustomerRebatesForm = ({
  control,
  register,
  errors,
  onSubmit,
  handleSubmit,
  calculationType,
  setCalculationType,
}: {
  control: Control;
  register: UseFormRegister<FieldValues>;
  errors: Record<string, string>;
  onSubmit: (
    data: DBClientRebateCalculationApi['create']['requestBody']
  ) => void;
  handleSubmit: UseFormHandleSubmit<any>;
  calculationType: DBRebateCalculationType;
  setCalculationType: (val: DBRebateCalculationType) => void;
}) => {
  const roundOptions = parseEnumToInputOption(DBPriceRound);
  const rebateGroupTypeOptions = parseEnumToInputOption(DBRebateGroupType);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Grid container spacing={2} sx={{ marginTop: '15px' }}>
          {getTextField(
            'Group name %',
            'groupName',
            () => true,
            errors,
            register,
            { lg: 4, xl: 4, xs: 6, bgcolor: 'white' },
            errors['groupName']
          )}

          {getAutocompleteSingleField(
            'Rebate Group Type',
            'groupType',
            () => true,
            errors,
            control,
            { lg: 4, xs: 6, bgcolor: 'white' },
            {},
            rebateGroupTypeOptions
          )}
        </Grid>

        <DefaultRebateSettingsContainer>
          <FormSectionWithLabel label="DEFAULT REBATE SETTINGS" />
          <Subtitle>
            This default rebate settings is valid for all product and suppliers
            that don't have defined custom rebate settings
          </Subtitle>
          <Grid container spacing={2}>
            {getTextField(
              'Rebate %',
              'rebate',
              () => true,
              errors,
              register,
              { lg: 3, xs: 12 },
              errors['rebate'],
              {
                type: 'number',
                // shrink: true,
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                },
                style: { background: 'white' },
              },
              {},
              '#f9fafb'
            )}

            {getTextField(
              'Cash Discount %',
              'discount',
              () => true,
              errors,
              register,
              { lg: 3, xs: 12 },
              errors['discount'],
              {
                type: 'number',
                // shrink: true,
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                },
                style: { background: 'white' },
              },
              {},
              '#f9fafb'
            )}

            {getAutocompleteSingleField(
              'Selling Price Rounding',
              'priceRound',
              () => true,
              errors,
              control,
              { lg: 6, xs: 12, style: { background: 'none' } },
              {},
              roundOptions
            )}
          </Grid>

          <RadioGrid item lg={12} xs={12}>
            <FormControl>
              <RadioGroupWrapper
                row
                defaultValue={calculationType}
                onChange={(e) => {
                  setCalculationType(e.target.value as DBRebateCalculationType);
                }}
              >
                <FormControlLabel
                  value={DBRebateCalculationType.SELLING_PRICE}
                  control={<Radio color="info" />}
                  label="Calculate rebate in selling price"
                />
                <StyledRadioControlLabel
                  value={DBRebateCalculationType.INVOICE}
                  control={<Radio color="info" />}
                  label="Calculate rebate on invoice"
                />
              </RadioGroupWrapper>
            </FormControl>
          </RadioGrid>
        </DefaultRebateSettingsContainer>
      </FormContainer>
    </form>
  );
};

const FormContainer = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  margin-bottom: 16px;
`;

const RadioGroupWrapper = styled(RadioGroup)`
  display: flex;
  justify-content: left;
`;

const StyledRadioControlLabel = styled(FormControlLabel)`
  @media (max-width: 1660px) {
    margin-right: 45px;
  }
`;

const DefaultRebateSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dfe3e8;
  background: #f9fafb;
  border-radius: 16px;
  margin-top: 16px;
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const RadioGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;
