import styled from '@emotion/styled';

interface SelectAllHeaderProps {
  text: string;
  onClick: () => void;
}

export const SelectAllHeader = ({ text, onClick }: SelectAllHeaderProps) => {
  return (
    <Wrapper>
      <div>{text}</div>
      <SelectAll onClick={onClick}>Select all</SelectAll>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`;

const SelectAll = styled.div`
  color: #1976d2;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`;
