import LineItem from '../LineItem/LineItem';
import { SearchableVerticalListItem } from './SearchableVerticalList.types';

interface ItemsWithoutChildrenProps {
  // eslint-disable-next-line
  data: any[]; //TODO DEMO
  onItemClick: (item: SearchableVerticalListItem) => void;
}

const ItemsWithoutChildren = ({
  data,
  onItemClick,
}: ItemsWithoutChildrenProps) => {
  return (
    <div>
      {data.map((item) => (
        <LineItem
          onClick={() => onItemClick(item)}
          key={item.id}
          disabled={item.disabled}
          name={item.name}
          type={item?.type ?? ''}
          active={item.active ?? true}
          isSelected={item.isSelected ?? false}
        />
      ))}
    </div>
  );
};

export default ItemsWithoutChildren;
