import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import api from '@tyrio/api-factory';
import { ClientImportStatePayload, DBSupplierImportApi } from '@tyrio/dto';
import moment from 'moment';
import { useMutation } from 'react-query';

// eslint-disable-next-line
const safeParseDate = (data?: any) => {
  if (!data) return 'N/A';
  const date = moment(data);

  return date.format('LLL');
};

export const ImportStatusClientTable = ({
  status,
}: {
  status: Partial<ClientImportStatePayload>;
}) => {
  const initJobsMutation = useMutation(
    ['import_stock_price'],
    ({
      supplierId,
      connectionId,
      clientId,
    }: DBSupplierImportApi['import_stock']['request']) =>
      api.fetch<DBSupplierImportApi['import_stock']>('import_stock_price', {
        supplierId,
        connectionId,
        clientId,
      }),
    {}
  );

  return (
    <div>
      <Accordion
        sx={{
          boxShadow: 'none',
          border: '1px dashed #DFE3E8',
          marginBottom: 2,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{status?.clientName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Source
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Connection name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Last scheduled
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Last started
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Last finished
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Items found
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Valid items
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Next schedule
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    ></Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(status.activeImports ?? {})
                  .sort((ak, bk) => {
                    const a = status?.activeImports?.[ak] ?? {};
                    const b = status?.activeImports?.[bk] ?? {};

                    if (!a || !b || !a.supplierName || !b.supplierName)
                      return 0;
                    return a.supplierName?.localeCompare(b.supplierName) ?? 0;
                  })
                  .map((supplierId) => {
                    const item = status?.activeImports?.[supplierId] ?? {};
                    return Object.keys(item?.connections ?? {})
                      .sort((connectionAKey, connectionBKey) => {
                        console.log({
                          clientAKey: connectionAKey,
                          clientBKey: connectionBKey,
                        });

                        const itemA = item?.connections?.[connectionAKey] ?? {};

                        const itemB = item?.connections?.[connectionBKey] ?? {};

                        if (!itemA || !itemB) return 0;

                        const a = [itemA.status?.source, itemA.name]
                          .filter((x) => !!x)
                          .join(' ');
                        const b = [itemB.status?.source, itemB.name]
                          .filter((x) => !!x)
                          .join(' ');

                        return b.localeCompare(a);
                      })
                      .map((connectionId) => {
                        const connection = item?.connections?.[connectionId];

                        if (!connection) return null;
                        return (
                          <TableRow
                            key={`${connection.name}${connection.status?.source}`}
                          >
                            <TableCell>{connection.status?.source}</TableCell>
                            <TableCell>{connection.name}</TableCell>
                            <TableCell>
                              {safeParseDate(
                                connection.status?.lastScheduled
                              ) ?? 'N/A'}
                            </TableCell>
                            <TableCell>
                              {safeParseDate(connection.status?.lastStarted) ??
                                'N/A'}
                            </TableCell>
                            <TableCell>
                              {safeParseDate(connection.status?.lastFinished) ??
                                'N/A'}
                            </TableCell>
                            <TableCell>
                              {connection.status?.itemsFound ?? 'N/A'}
                            </TableCell>
                            <TableCell>
                              {connection.status?.validItems ?? 'N/A'}
                            </TableCell>
                            <TableCell>
                              {safeParseDate(connection.status?.nextSchedule) ??
                                'N/A'}
                            </TableCell>
                            <TableCell>{connection.status?.status}</TableCell>
                            <TableCell
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <MenuItem
                                disabled={
                                  connection.status?.status === 'running'
                                }
                                onClick={() => {
                                  if (connection.status?.status === 'running')
                                    return;
                                  initJobsMutation.mutate({
                                    clientId: status.clientId,
                                    supplierId: supplierId,
                                    connectionId: connectionId,
                                  });
                                }}
                              >
                                {connection.status?.status !== 'running' && (
                                  <RefreshIcon sx={{ marginRight: '8px' }} />
                                )}{' '}
                                Update now
                              </MenuItem>
                              {connection.status?.status === 'running' && (
                                <MenuItem
                                  onClick={() => {
                                    initJobsMutation.mutate({
                                      clientId: status.clientId,
                                      supplierId: supplierId,
                                      connectionId: connectionId,
                                    });
                                  }}
                                >
                                  <RefreshIcon sx={{ marginRight: '8px' }} />
                                </MenuItem>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      });
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
