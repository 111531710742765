/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Customer } from './Customer/Customer';
import { DeliveryMethod } from './DeliveryMethod/DeliveryMethod';
import { Vehicle } from './Vehicle/Vehicle';
import { Payment } from './Payment/Payment';
import { Appointment } from './Appointment/Appointment';

const FieldComponent: any = {
  CUSTOMER: Customer,
  DELIVERY_METHOD: DeliveryMethod,
  VEHICLE: Vehicle,
  PAYMENT: Payment,
  APPOINTMENT: Appointment,
};

export const InfoSteps = (step: string) => {
  if (Object.keys(FieldComponent).find((a) => a === step) === undefined) {
    console.log('Error - Step passed in is not expected!');
    return;
  }

  if (step in Object.keys(FieldComponent)) {
    console.log('Error - Step passed in is not expected!');
  }
  return React.createElement(FieldComponent[step]);
};
