import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, IconButton, Typography } from '@mui/material';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import * as _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { StockListContext } from '../../../context/StockListContext';

interface FilterProps {
  onOpen: () => void;
}

const Filter = ({ onOpen }: FilterProps) => {
  const { filterValues, setFilterValue } = useContext(StockListContext);
  const location = useLocation();
  const [shouldDeleteSubcategory, setShouldDeleteSubcategory] = useState(false);
  const get = (path: string) => _.get(filterValues, path, []) as string[];
  const handleDelete = (path: string, key: string) => {
    console.info(
      'Update.',
      path,
      key,
      get(path).filter((elem) => elem !== key)
    );
    setFilterValue({
      [path]: get(path).filter((elem) => elem !== key),
    });
  };

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const { data: category_data } = useQuery(
    ['get_category_id', Number(filterValues?.['categoryId'])],
    () =>
      api.fetch<DBProductCategoryApi['getOne']>(`category_id`, {
        id: Number(filterValues?.['categoryId']),
      }),
    {
      enabled: !!filterValues?.['categoryId'],
    }
  );

  const { data: subcategory_data } = useQuery(
    ['get_category_id', Number(filterValues?.['subcategoryId'])],
    () =>
      api.fetch<DBProductCategoryApi['getOne']>(`category_id`, {
        id: Number(filterValues?.['subcategoryId']),
      }),
    {
      enabled: !!filterValues?.['subcategoryId'],
    }
  );

  useEffect(() => {
    if (searchParams.get('categoryId') === null)
      setShouldDeleteSubcategory(true);
  }, [searchParams]);

  useEffect(() => {
    if (shouldDeleteSubcategory) {
      setFilterValue({ subcategoryId: undefined });
      setShouldDeleteSubcategory(false);
    }
  }, [setFilterValue, shouldDeleteSubcategory]);

  return (
    <Container>
      <IconWrapper>
        <IconButton onClick={onOpen}>
          <MoreVertIcon />
        </IconButton>
        <Typography fontSize={14} fontWeight={500}>
          Filter
        </Typography>
      </IconWrapper>

      <FilterWrapper>
        {category_data && filterValues?.['categoryId'] && (
          <>
            <Item>Category:</Item>
            <Chip
              label={category_data.name}
              variant="outlined"
              color="info"
              onDelete={() => {
                setFilterValue({ categoryId: undefined });
              }}
              key={Number(filterValues?.['categoryId'])}
              style={{ marginBottom: 8, marginLeft: '5px' }}
            />
          </>
        )}

        {subcategory_data && filterValues?.['subcategoryId'] && (
          <>
            <Item>Subcategory:</Item>
            <Chip
              label={subcategory_data.name}
              variant="outlined"
              color="info"
              onDelete={() =>
                setFilterValue({
                  subcategoryId: undefined,
                })
              }
              key={Number(filterValues?.['subcategoryId'])}
              style={{ marginBottom: 8, marginLeft: '5px' }}
            />
          </>
        )}

        {get('brand').length > 0 && (
          <>
            <Item>Brand:</Item>
            {get('brand').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('brand', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('season').length > 0 && (
          <>
            <Item>Season:</Item>
            {get('season').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('season', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('wheelPosition').length > 0 && (
          <>
            <Item>Wheel position:</Item>
            {get('wheelPosition').map((elem) => (
              <Chip
                label={elem.toUpperCase()}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('wheelPosition', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('specialMarking').length > 0 && (
          <>
            <Item>Special markings:</Item>
            {get('specialMarking').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                color="info"
                onDelete={() => handleDelete('specialMarking', elem)}
                key={elem}
              />
            ))}
          </>
        )}
      </FilterWrapper>
    </Container>
  );
};

const Item = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  color: gray;
  font-style: italic;
  margin-bottom: 8px !important;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const FilterWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  width: 100%;
  max-width: 700px;
  overflow-x: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
`;

export default Filter;
