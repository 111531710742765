import * as z from "zod"
import { CompleteDBUser, RelatedDBUserModel } from "./index"

export const DBAdminModel = z.object({
  id: z.string(),
  active: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
  userId: z.string().nullish(),
})

export interface CompleteDBAdmin extends z.infer<typeof DBAdminModel> {
  user?: CompleteDBUser | null
}

/**
 * RelatedDBAdminModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBAdminModel: z.ZodSchema<CompleteDBAdmin> = z.lazy(() => DBAdminModel.extend({
  user: RelatedDBUserModel.nullish(),
}))
