import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { INTERPNEU_CATALOG_RULESET } from './interpneu/catalog';
import { INTERPNEU_PRICELIST_RULESET } from './interpneu/pricelist';
import { INTERPNEU_STOCKLIST_RULESET } from './interpneu/stocklist';

export const INTERPNEU_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: INTERPNEU_CATALOG_RULESET,

  pricelistRuleset: INTERPNEU_PRICELIST_RULESET,
  stockRuleset: INTERPNEU_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'INTERPNEU',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    price: {
      ',': '.',
    },
    weight: {
      ',': '.',
    },
    rimSize: {
      ',': '.',
    },
    width: {
      ',': '.',
    },
    height_rimSize: {
      ',': '.',
    },
    LI_ET: {
      ',': '.',
    },
    Purchase_Price_EUR_Netto: {
      ',': '.',
    },
    stock: {
      ',': '.',
    },
    RFD_color: {
      '�': 'a',
      'ÔøΩ': 'a',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const headers = [
      'supplierCode',
      'ean',
      'manufacturerCode',
      'stock',
      'price',
    ];
    const fileWithHeader = `${headers.join('|')}\n${file}`;

    // console.log('READ FILE', fileWithHeader);
    const json = await csv2json({ delimiter: '|' }).fromString(fileWithHeader);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const headers = [
      'supplierCode',
      'ean',
      'manufacturerCode',
      'stock',
      'price',
    ];
    const fileWithHeader = `${headers.join('|')}\n${file}`;

    // console.log('READ FILE', fileWithHeader);
    const json = await csv2json({ delimiter: '|' }).fromString(fileWithHeader);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const headers = [
      'sapCode',
      'supplierCode',
      'ean',
      'manufacturerCode',
      'stock',
      'price',
      'currency',
      'category',
      'brand',
      'articleNameSupplier',
      'articleGroup',
      'vehicleType',
      'season',
      'width',
      'height_rimSize',
      'rimSize',
      'LIET',
      'SI',
      'model',
      'runFlat',
      'RFD_color',
      'specialMarking_wheelModel',
      'vehicleBrand',
      'flank',
      'sidewall',
      'construction',
      'axle',
      'dot_ECE',
      'imageID',
      'euLabel',
      '3PMSF',
      'euDirectiveNumber',
      'iceGrip',
      'eprelID',
      'ms',
      'seal',
      'noiseOptimized',
      'TL/TT',
      'quality',
      'colAN',
      'colAO',
      'colAP',
      'colAQ',
      'colAR',
      'colAS',
      'vcolAT',
      'colAU',
      'colAV',
      'colAW',
      'colAX',
      'colAY',
      'rollingResistance',
      'wetGrip',
      'noisePerformance',
      'noiseClassType',
      'colBD',
      'colBE',
      'weight',
      'colBG',
      'colBH',
      'colBI',
      'colBJ',
      'colBK',
      'colBL',
      'colBM',
      'colBN',
      'colBO',
      'colBP',
      'colBQ',
      'colBR',
      'colBS',
      'colBT',
      'colBU',
      'colBV',
      'colBW',
    ];
    const fileWithHeader = `${headers.join('|')}\n${file}`;

    const json = await csv2json({ delimiter: '|' }).fromString(fileWithHeader);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          item['brand'].toUpperCase() !== 'STAHLFELGE' &&
          !item['model'].includes('demo') &&
          !item['specialMarking_wheelModel'].includes('demo')
      ),
      this.preprocessKeys
    );
  },
};
