import styled from '@emotion/styled/macro';
import { ArrowBack, ArrowForward, Cancel } from '@mui/icons-material';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import Inventory from '@mui/icons-material/Inventory';
import {
  Alert,
  Button,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { DBPriceCompareApi } from '@tyrio/dto';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  tyrioUI,
} from '@tyrio/ui-library';
import { InputRef, Table } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import _ from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import { FlowHeader } from './FlowHeader';
import { SwitchHeaders } from './SwitchHeaders';
import {
  DataIndex,
  DataTypeCode,
  getColumnSearchPropsCode,
} from './TableComponents/ColumnSearchProps';
import { RenderFooterCell } from './TableComponents/RenderFooterCell';
import { RenderFooterSupplierTitle } from './TableComponents/RenderFooterSupplierTitle';
import { RenderTableCell } from './TableComponents/RenderTableCell';
import { RenderTableCode } from './TableComponents/RenderTableCode';
import { RenderTableProductName } from './TableComponents/RenderTableProductName';
import { RenderTotalCell } from './TableComponents/RenderTotalCell';
import { RenderTotalFooterCell } from './TableComponents/RenderTotalFooterCell';
import { PriceComparisonContext } from './context';
import { useSyncedPrices } from './utils';

const COLUMN_WIDTH = 120;
const PRODUCT_NAME_WIDTH = 250;
const TOTAL_COLUMN_WIDTH = 160;

export const PriceCompareTableV2 = ({
  RES,
}: {
  RES: DBPriceCompareApi['compareCode']['response'];
}) => {
  const ctx = useContext(PriceComparisonContext);
  const [, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const qty = useMemo(() => ctx.data.qty || {}, [ctx.data.qty]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [tableRenderReady, setTableRenderReady] = useState(false);
  const [spacerColumnWidth, setSpacerColumnWidth] = useState<number>(0);

  const {
    prices,
    lowestPrices,
    supplierMap,
    productEanMap,
    mainSupplierId,
    suppliers,
    safeSetQty,
  } = useSyncedPrices(RES);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const finalTotalRequested = useMemo(() => {
    const req = ctx.data.code?.list || {};

    return Object.keys(req)
      .filter((requestedProductId) => !!productEanMap[requestedProductId])
      .reduce((acc, curr) => acc + req[curr], 0);
  }, [ctx.data.code?.list, productEanMap]);

  const verticalTotals = useMemo(() => {
    const totalValues: Record<string, { qty: number; price: number }> = {};
    const finalTotalValue: { qty: number; price: number } = {
      qty: 0,
      price: 0,
    };
    let totalFilled = true;

    const productsSum: Record<string, number> = {};

    const products = ctx.data.codeResponse?.products || {};
    Object.keys(products).forEach((productId) => {
      productsSum[productId] = 0;
    });

    Object.values(qty || {}).forEach((shape) => {
      Object.keys(shape).forEach((productId) => {
        productsSum[productId] += shape[productId];
      });
    });

    Object.keys(productsSum || {}).forEach((productId) => {
      const product = ctx.data.codeResponse?.products?.[productId];
      if (product) {
        const key =
          ctx.data.code?.searchType === 'ean'
            ? product.ean
            : product.manufacturerCode;

        const requested = ctx.data.code?.list?.[key] ?? 0;

        const current = productsSum[productId] ?? 0;
        if (totalFilled && requested > current) {
          totalFilled = false;
        }
      }
    });

    suppliers.forEach((supplier) => {
      let supplierQtySum = 0;
      let supplierPriceSum = 0;
      const supplierId = supplier.supplierId;
      const productQty = qty[supplierId] || {};
      Object.keys(productQty).forEach((productId) => {
        supplierQtySum += productQty[productId] || 0;
        supplierPriceSum +=
          productQty[productId] *
          (prices?.[productId]?.[supplierId]?.value || 0);
      });
      finalTotalValue.qty += supplierQtySum;
      finalTotalValue.price += supplierPriceSum;

      totalValues[supplierId] = {
        qty: supplierQtySum,
        price: supplierPriceSum,
      };
    });
    return { totalValues, finalTotalValue, finalTotalRequested, totalFilled };
  }, [
    ctx.data.codeResponse?.products,
    ctx.data.code?.searchType,
    ctx.data.code?.list,
    qty,
    suppliers,
    finalTotalRequested,
    prices,
  ]);

  const totals = useMemo(() => {
    const totalValues: {
      sum: Record<string, number>;
      price: Record<string, number>;
    } = {
      sum: {},
      price: {},
    };

    Object.keys(qty).forEach((supplierId) => {
      Object.keys(qty[supplierId]).forEach((productId) => {
        if (!totalValues.sum[productId]) totalValues.sum[productId] = 0;
        if (!totalValues.price[productId]) totalValues.price[productId] = 0;
        let price = RES.prices?.[productId]?.[supplierId]?.value || 0;

        const priceObject = RES.prices?.[productId]?.[supplierId];

        if (ctx.data.includeEcoTax && price && priceObject?.ecoTax)
          price += priceObject.ecoTax;
        if (ctx.data.includeBonus && price && priceObject?.bonus)
          price -= price * (priceObject.bonus / 100);

        if (ctx.data.includeDeliveryCost && price && priceObject?.shipping)
          price += priceObject.shipping;

        totalValues.sum[productId] += qty[supplierId][productId];

        totalValues.price[productId] += qty[supplierId][productId] * price;
      });
    });
    return totalValues;
  }, [
    RES.prices,
    ctx.data.includeBonus,
    ctx.data.includeDeliveryCost,
    ctx.data.includeEcoTax,
    qty,
  ]);

  const CODE_COLUMNS: ColumnsType<DataTypeCode> = useMemo(
    () => [
      {
        title: 'Code',
        width: 150,
        dataIndex: 'ean',
        key: 'name',
        fixed: 'left',
        ...getColumnSearchPropsCode(
          'ean',
          searchInput,
          searchedColumn,
          handleSearch,
          handleReset,
          setSearchText,
          setSearchedColumn
        ),
        render: (__data, row) => (
          <RenderTableCode
            manufacturerCode={row.manufacturerCode}
            ean={row.ean}
          />
        ),
      },
    ],
    [searchedColumn]
  );

  const PRODUCT_NAME_COLUMNS: ColumnsType<DataTypeCode> = useMemo(
    () => [
      {
        title: 'Product Name',
        width: PRODUCT_NAME_WIDTH + spacerColumnWidth,
        dataIndex: 'productName',
        key: 'productName',
        fixed: 'left',
        ...getColumnSearchPropsCode(
          'productName',
          searchInput,
          searchedColumn,
          handleSearch,
          handleReset,
          setSearchText,
          setSearchedColumn
        ),
        sorter: (a, b) => a.productName.localeCompare(b.productName),
        render: (__data, row) => {
          return (
            <RenderTableProductName
              brand={row.brand}
              productName={row.productName}
            />
          );
        },
      },
    ],
    [searchedColumn, spacerColumnWidth]
  );

  const SUPPLIER_COLUMNS: ColumnsType<DataTypeCode> = suppliers.map(
    (s, idx) => ({
      title: ctx.data.showSupplierNames
        ? s.name
        : s.mainSupplier
        ? 'Main supplier'
        : `Supplier ${idx + 1}`,
      width: COLUMN_WIDTH,
      dataIndex: 'ean',
      key: s.supplierId,
      fixed: s.mainSupplier ? 'left' : false,
      sorter: (a, b) => {
        const a_data = prices?.[a.uid]?.[s.supplierId]?.value || 0;
        const b_data = prices?.[b.uid]?.[s.supplierId]?.value || 0;

        return a_data - b_data;
      },
      render: (__data, row) => {
        const supplierId = s.supplierId;

        const data = prices[row.uid][supplierId];
        const mainSupplierPrice =
          prices?.[row.uid]?.[mainSupplierId]?.value ?? null;

        return (
          <RenderTableCell
            lowestPrices={lowestPrices[row.uid]}
            isManufacturer={s.supplierType === 'MANUFACTURER'}
            shouldShowStockZero={
              s.supplierType === 'MANUFACTURER' && !!ctx.data.showStockZero
            }
            inputValue={qty?.[supplierId]?.[row.uid]}
            setInputValue={(value) => safeSetQty(supplierId, row.uid, value)}
            mainSupplierPrice={mainSupplierPrice}
            price={data?.value}
            priceObject={data}
            quantity={data?.quantity}
            open={ctx.data.openRow === row.uid}
            shouldShowPercentages={!!ctx.data.showPercentages}
          />
        );
      },
    })
  );

  const TOTAL_COLUMNS: ColumnsType<DataTypeCode> = useMemo(
    () => [
      {
        title: 'Total',
        key: 'operation',
        fixed: 'right',
        className: 'total',
        width: TOTAL_COLUMN_WIDTH,
        sorter: (a, b) => {
          const a_totalPrice = totals.price[a.uid] || 0;
          const b_totalPrice = totals.price[b.uid] || 0;

          return a_totalPrice - b_totalPrice;
        },
        render: (_data, row) => {
          const productId = row.uid;

          const totalPrice = totals.price[productId] || 0;
          const totalSum = totals.sum[productId] || 0;

          const key =
            ctx.data.code?.searchType === 'ean'
              ? row.ean
              : row.manufacturerCode;

          return (
            <RenderTotalCell
              price={totalPrice}
              quantity={totalSum}
              requestedQuantity={ctx.data.code?.list?.[key]}
            />
          );
        },
      },
    ],
    [ctx.data.code?.list, ctx.data.code?.searchType, totals]
  );

  const columns = useMemo(() => {
    let constructedColumns: ColumnsType<DataTypeCode> = [];

    if (ctx.data.showCode)
      constructedColumns = [...constructedColumns, ...CODE_COLUMNS];

    constructedColumns = [...constructedColumns, ...PRODUCT_NAME_COLUMNS];
    constructedColumns = [...constructedColumns, ...SUPPLIER_COLUMNS];

    // if (spacerColumnWidth > 0)
    //   constructedColumns = [...constructedColumns, spacerColumn];

    constructedColumns = [...constructedColumns, ...TOTAL_COLUMNS];

    return constructedColumns;
  }, [
    CODE_COLUMNS,
    PRODUCT_NAME_COLUMNS,
    SUPPLIER_COLUMNS,
    TOTAL_COLUMNS,
    ctx.data.showCode,
    // spacerColumn,
    // spacerColumnWidth,
  ]);

  const data: DataTypeCode[] = useMemo(
    () =>
      Object.values(RES.products || {}).map((p) => ({
        uid: p.uid,
        key: p.uid,
        productName: p.productName,
        brand: p.model?.brand ?? '',
        ean: p.ean,
        manufacturerCode: p.manufacturerCode,
      })),
    [RES.products]
  );

  const fillOrder = useCallback(
    ({ stockZero }: { stockZero?: boolean }) => {
      const req = ctx.data.code?.list || {};
      const res = ctx.data.codeResponse;

      /**
       * Fill main supplier first
       */
      let newQty: Record<string, Record<string, number>> = {};

      Object.keys(req).forEach((k) => {
        const p = productEanMap[k];
        if (!p || !res) return;
        const price = res.prices[p.uid];
        const priceArray = Object.keys(price)
          .map((k) => {
            return {
              supplierId: k,
              price: price[k].value,
              qty: price[k].quantity,
            };
          })
          .sort((a, b) => {
            const first = a.price ?? 0;
            const second = b.price ?? 0;

            if (first === second) {
              if (a.supplierId === mainSupplierId) return -1;
              if (b.supplierId === mainSupplierId) return -1;
            }

            return first - second;
          })
          .filter((shape) => {
            return !(shape.qty === 0 && !stockZero);
          });

        const priceArrayElement = priceArray[0];
        if (priceArrayElement) {
          const supplier = supplierMap[priceArrayElement.supplierId];
          const value = req[p.ean];

          let cleanValue: number;
          if (supplier.supplierType === 'MANUFACTURER' && stockZero) {
            cleanValue = value;
          } else {
            cleanValue = Math.min(value, priceArrayElement.qty);
          }

          newQty = _.merge(newQty, {
            [priceArrayElement.supplierId]: { [p.uid]: cleanValue },
          });
        }
      });

      ctx.setInputValue({ qty: newQty, isOrderAutomaticallyFilled: true });
      if (!ctx.data.showStockZero && stockZero)
        ctx.setInputValue({ showStockZero: true });
      ToastHelper.showToast(
        '',
        ToastType.SUCCESS,
        ToastMessageType.CUSTOM_ERROR,
        'Successfully filled quantities in your order.'
      );
    },
    [ctx, mainSupplierId, productEanMap, supplierMap]
  );

  const updateTableDimensions = useCallback(() => {
    if (wrapperRef.current) {
      const SUPPLIER_OFFSETS = suppliers.length * COLUMN_WIDTH;
      const PRODUCT_NAME_OFFSET = PRODUCT_NAME_WIDTH;
      const CODE_OFFSET = ctx.data.showCode ? 150 : 0;
      const TOTAL_OFFSET = TOTAL_COLUMN_WIDTH;

      const offsets =
        CODE_OFFSET + PRODUCT_NAME_OFFSET + SUPPLIER_OFFSETS + TOTAL_OFFSET;

      const finalWidth =
        Math.max(0, wrapperRef.current?.clientWidth - offsets) - 2;
      console.log('UPDATING DIMENSIONS', {
        SUPPLIER_OFFSETS,
        PRODUCT_NAME_OFFSET,
        CODE_OFFSET,
        TOTAL_OFFSET,
        offsets,
        finalWidth,
      });
      setSpacerColumnWidth(finalWidth);
      setTableRenderReady(true);
    }
  }, [ctx.data.showCode, suppliers.length]);

  useEffect(() => {
    updateTableDimensions();
  }, [ctx.data.showCode, suppliers.length, updateTableDimensions]);

  useEffect(() => {
    window.addEventListener('resize', updateTableDimensions, {});

    return () => {
      window.removeEventListener('resize', updateTableDimensions);
    };
  }, [updateTableDimensions]);

  return (
    <PageTemplateWrapper
      style={{
        paddingRight: ctx.canSidebarOpen && ctx.data.sidebarOpen ? 450 : 0,
        transition: 'all .3s ease',
      }}
    >
      <PageTemplateContent>
        <FlowHeader />
        {!ctx.data.isOrderAutomaticallyFilled && !ctx.hasPlacedOrders && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <List sx={{ width: '100%' }}>
                  <ListItemButton
                    onClick={() => fillOrder({ stockZero: false })}
                  >
                    <ListItemAvatar>
                      <Inventory />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Fill with available stock"
                      secondary="Fills your requested quantities for all available products in stock. Will not fill quantities for manufacturers if they don't have them in stock."
                    />
                  </ListItemButton>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List sx={{ width: '100%' }}>
                  <ListItemButton
                    onClick={() => fillOrder({ stockZero: true })}
                  >
                    <ListItemAvatar>
                      <HourglassTopIcon />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Fill with stock zero"
                      secondary="Fills your requested quantities for all available products in stock. It will fill the quantity if a manufacturer has the lowest price, even if it doesn't have items in stock."
                    />
                  </ListItemButton>
                </List>
              </Grid>
            </Grid>
          </Grid>
        )}
        <SwitchHeaders />
        <Wrapper ref={wrapperRef}>
          {tableRenderReady && (
            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 9 * COLUMN_WIDTH }}
              rowClassName={(record) => {
                return classNames('pc_row', {
                  open: record.uid === ctx.data.openRow,
                });
              }}
              pagination={{
                style: {
                  paddingLeft: 16,
                  paddingRight: 16,
                },
                defaultPageSize: 50,
                size: 'default',
                responsive: true,
                totalBoundaryShowSizeChanger: 10,
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    ctx.setInputValue({ openRow: record.uid });
                  },
                };
              }}
              summary={() => {
                const OFFSET = ctx.data.showCode ? 1 : 0;
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="footerTitles">
                      {ctx.data.showCode && <Table.Summary.Cell index={0} />}
                      <Table.Summary.Cell index={OFFSET} />
                      {suppliers.map((s, idx) => (
                        <Table.Summary.Cell
                          key={s.supplierId}
                          index={idx + 1 + OFFSET}
                        >
                          <RenderFooterSupplierTitle
                            supplierName={
                              ctx.data.showSupplierNames
                                ? s.name
                                : s.mainSupplier
                                ? 'Main supplier'
                                : `Supplier ${idx + 1}`
                            }
                          />
                        </Table.Summary.Cell>
                      ))}
                      <Table.Summary.Cell index={suppliers.length + OFFSET + 1}>
                        <RenderFooterSupplierTitle supplierName={'Total'} />
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      {ctx.data.showCode && (
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={OFFSET}>
                        <h1>Total:</h1>
                      </Table.Summary.Cell>
                      {suppliers.map((s, idx) => (
                        <Table.Summary.Cell
                          key={s.supplierId}
                          index={idx + 1 + OFFSET}
                        >
                          <RenderFooterCell
                            price={
                              verticalTotals.totalValues[s.supplierId].price
                            }
                            quantity={
                              verticalTotals.totalValues[s.supplierId].qty
                            }
                          />
                        </Table.Summary.Cell>
                      ))}
                      <Table.Summary.Cell index={suppliers.length + OFFSET + 1}>
                        <RenderTotalFooterCell
                          price={verticalTotals.finalTotalValue.price}
                          quantity={verticalTotals.finalTotalValue.qty}
                          requestedQuantity={verticalTotals.finalTotalRequested}
                          isFulfilled={verticalTotals.totalFilled}
                        />
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          )}
        </Wrapper>
        <BottomFixed>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
              <Button
                variant={'outlined'}
                color={'info'}
                startIcon={<ArrowBack color={'info'}></ArrowBack>}
                sx={{ marginRight: 2 }}
                onClick={() => {
                  ctx.setInputValue({ screen: 'intro' });
                }}
              >
                Back
              </Button>
              <Button
                variant={'outlined'}
                color={'warning'}
                startIcon={<Cancel color={'warning'}></Cancel>}
                onClick={() => {
                  ctx.reset();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {!verticalTotals.totalFilled && (
                <Alert
                  severity="warning"
                  sx={{ marginRight: 2, padding: '0 16px' }}
                >
                  You have not filled the entire order.
                </Alert>
              )}
              {ctx.hasPlacedOrders && (
                <Alert
                  severity="warning"
                  sx={{ marginRight: 2, padding: '0 16px' }}
                >
                  You've already started the order process.
                </Alert>
              )}

              <Button
                variant={'contained'}
                color={'info'}
                disabled={verticalTotals.finalTotalValue.qty === 0}
                onClick={() => ctx.processOrder()}
                endIcon={<ArrowForward />}
                style={{ marginRight: `${tyrioUI.spacing.l}px` }}
                disableElevation
              >
                {ctx.hasPlacedOrders ? 'Back to orders' : 'Order preview'}
              </Button>
            </Grid>
          </Grid>
        </BottomFixed>
        <BottomSpacer />
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};
const BottomFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const BottomSpacer = styled.div`
  display: block;
  height: 100px;
  width: 100%;
`;
const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  * {
    font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
  }

  .ant-table {
    border-radius: 0;
  }

  .ant-table-container {
    border-radius: 0;
  }
  .ant-table-content {
    table {
      border-radius: 0;
    }
  }

  .ant-table-thead {
    tr {
      th {
        border-radius: 0;
      }
    }
  }
  .pc_row {
    & > .ant-table-cell {
      &:nth-child(2n) {
        background: #f9fafb;
      }
    }
    &.open {
      & > .ant-table-cell {
        background: #fff8e5;
      }
      &:hover {
        & > .ant-table-cell {
          background: #fff8e5;
        }
        background: #fff8e5;
      }
    }
  }
  .footerTitles {
    & > .ant-table-cell {
      background-color: #ecf0f4;
    }
  }
`;
