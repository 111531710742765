/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
import * as OPERATIONS from './rules/operations';
import _, {
  get as _get,
  merge as _merge,
  zipObjectDeep as _zipObjectDeep,
} from 'lodash';
import {
  TCombineRule,
  TConditionalBooleanRule,
  TConditionalRule,
  TGlobalOperateRule,
  TMapArrayRule,
  TMapArrayTemplateRule,
  TMapRule,
  TOperateRule,
  TRegexpRule,
  TReplaceRule,
  TRule,
  TSimpleExpRule,
  TTemplateRule,
} from './rules/typings';
import { convertOutputValue } from './rules/helpers';
import { PostProcessHelpers } from './rules/postprocess.helpers';
import { RawImport, UnfinishedData } from '@tyrio/dto';

const DEFAULT_OUTPUT_TYPE = 'string';

export class ImportRulesBase {
  public _handleConditionalBooleanRule(
    rule: TConditionalBooleanRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    let output: string | boolean | number | undefined = '';
    const value = this._getValue(rule.sourceKey, data, unfinished);
    if (rule.conditionOutputs.length !== rule.conditionValues.length) {
      throw new Error(
        'Condition outputs and condition values array must be of the same length!'
      );
    }
    switch (rule.condition) {
      case 'equals':
        rule.conditionValues.forEach((conditionValue, idx) => {
          if (value === conditionValue) {
            output = rule.conditionOutputs[idx];
          }
        });

        break;
      case 'contains':
        rule.conditionValues.forEach((conditionValue, idx) => {
          if (typeof value === 'string' && value.indexOf(conditionValue)) {
            output = rule.conditionOutputs[idx];
          }
        });

        break;

      case 'startsWith':
        rule.conditionValues.forEach((conditionValue, idx) => {
          if (typeof value === 'string' && value.startsWith(conditionValue)) {
            output = rule.conditionOutputs[idx];
          }
        });

        break;
      case 'endsWith':
        rule.conditionValues.forEach((conditionValue, idx) => {
          if (typeof value === 'string' && value.endsWith(conditionValue)) {
            output = rule.conditionOutputs[idx];
          }
        });
        break;
      default:
        break;
    }

    output = convertOutputValue(
      output,
      rule.outputType || DEFAULT_OUTPUT_TYPE,
      rule.postProcess
    );

    unfinished[rule.destination] = {
      ...unfinished[rule.destination],
      ..._zipObjectDeep([rule.targetKey], [output]),
    };
  }

  private _getValue(key: string, data: RawImport, unfinished: UnfinishedData) {
    if (key.startsWith('{{')) return key.replace('{{', '').replace('}}', '');

    const set = {
      c: data,
      t: unfinished,
    };

    let value = _get(set, key);
    if (value === 'undefined') value = undefined;

    if (typeof value === 'undefined') return undefined;
    if (typeof value === 'string') return value as string;
    if (typeof value === 'boolean') return value as boolean;
    if (typeof value === 'number') return value as number;

    // console.error(`Unknown type of ${key}/${value} - ${typeof value}`);
    return value;
  }

  private _replaceValuesFromTemplate(
    template: string,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    const regex = /#{([[\]a-z0-9A-Z_.+| ,'"]+)}/g;

    const matches = template.match(regex) || [];
    let value = template;

    matches.forEach((match) => {
      let cleanKey = match.substring(2, match.length - 1);

      if (cleanKey.indexOf('|') > -1) {
        /** We're mapping a boolean value */
        const booleanKeyValues = cleanKey.split('|');
        cleanKey = booleanKeyValues[0];
        const val = this._getValue(cleanKey, data, unfinished) as boolean;
        let output = '';
        const booleanMapping = booleanKeyValues[1].split(',');

        if (val) {
          output = booleanMapping[0];
        } else {
          output = booleanMapping[1];
        }

        if (!output) {
          output = '';
        }
        value = value.replace(match, output);
      } else {
        /** We're mapping standard values */
        let val = this._getValue(cleanKey, data, unfinished) as string;

        if (!val) {
          val = '';
        }
        value = value.replace(match, val);
      }
    });

    value = value.replace(/ +(?= )/g, '');

    return value;
  }

  public _handleTemplateRule(
    rule: TTemplateRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    const template = rule.template;

    const value = this._replaceValuesFromTemplate(template, data, unfinished);

    unfinished[rule.destination] = this._zipMap(rule, unfinished, value);
  }

  public _handleConditionalRule(
    rule: TConditionalRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    const value = this._getValue(rule.sourceKey, data, unfinished) as string;

    switch (rule.condition) {
      case 'equals':
        // eslint-disable-next-line eqeqeq
        if (value == rule.conditionValue) {
          this._handleConditionalOutput(rule, data, unfinished);
        }
        break;
      case 'not.equals':
        // eslint-disable-next-line eqeqeq
        if (value != rule.conditionValue) {
          this._handleConditionalOutput(rule, data, unfinished);
        }
        break;
      case 'in':
        if (rule.conditionValue.split('|').includes(`${value}`)) {
          this._handleConditionalOutput(rule, data, unfinished);
        }
        break;
      case 'contains':
        if (value?.indexOf(rule.conditionValue) > -1) {
          this._handleConditionalOutput(rule, data, unfinished);
        }
        break;

      case 'startsWith':
        if (value?.startsWith(rule.conditionValue)) {
          this._handleConditionalOutput(rule, data, unfinished);
        }
        break;
      case 'endsWith':
        if (value?.endsWith(rule.conditionValue)) {
          this._handleConditionalOutput(rule, data, unfinished);
        }
        break;
      default:
        throw new Error(
          `Unhandled case - ${rule.sourceKey}=>${rule.targetKey} | ${rule.condition}`
        );
    }
  }

  private _handleConditionalOutput = (
    rule: TConditionalRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) => {
    if (rule.outputLogic === 'map_from_import') {
      this._handleMapRule(
        {
          sourceKey: rule.sourceKey,
          targetKey: rule.targetKey,
          destination: rule.destination,
          postProcess: rule.postProcess,
          outputType: rule.outputType,
          type: 'rule.map',
        },
        data,
        unfinished
      );
    }

    if (rule.outputLogic === 'append') {
      unfinished[rule.destination] = {
        ...unfinished[rule.destination],
        ..._zipObjectDeep(
          [rule.targetKey],
          [unfinished[rule.destination][rule.targetKey] + rule.outputValue]
        ),
      };
    }

    if (rule.outputLogic === 'custom') {
      let value;
      if (typeof rule.outputValue === 'string') {
        value = this._replaceValuesFromTemplate(
          rule.outputValue,
          data,
          unfinished
        );
      } else {
        value = rule.outputValue;
      }

      const convertedOutputTemplateValue = convertOutputValue(
        value,
        rule.outputType || DEFAULT_OUTPUT_TYPE,
        rule.postProcess
      );

      unfinished[rule.destination] = {
        ...unfinished[rule.destination],
        ..._zipObjectDeep([rule.targetKey], [convertedOutputTemplateValue]),
      };
    }
  };

  public _handleSimpleExpRule(
    rule: TSimpleExpRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    const { expression } = rule;

    const rawInputValue = this._getValue(rule.sourceKey, data, unfinished);

    if (typeof rawInputValue !== 'string')
      throw new Error(
        `Error parsing simple expression rule. ${rawInputValue} is not a string.`
      );

    const hits = [...expression.matchAll(/{[a-zA-Z]+}/g)];

    const constructable: Record<string, string> = {};

    let hitIdx = 0;

    for (const hit of hits) {
      const key = hit[0];
      const hitLength = key.length - 2;
      const idx = (hit['index'] || 0) - hitIdx * 2;
      const parsedInputValue = rawInputValue.replace('{', '').replace('}', '');

      constructable[key[1]] = parsedInputValue.substring(idx, idx + hitLength);
      hitIdx++;
    }

    const zipped = _zipObjectDeep(
      rule.outputMapping,
      rule.matchMappingIdx.map((matchIdx, idx) => {
        const retValue = constructable?.[matchIdx];
        if (!retValue) {
          throw new Error(
            `No value found for ${matchIdx}. Constructable output: ${JSON.stringify(
              constructable
            )}`
          );
        }

        return convertOutputValue(
          retValue,
          rule.outputTypes?.[idx] || DEFAULT_OUTPUT_TYPE
        );
      })
    );

    unfinished[rule.destination] = {
      ...unfinished[rule.destination],
      ..._zipObjectDeep([rule.targetKey], [zipped]),
    };
  }

  public _handleMapRule(
    rule: TMapRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    const value = this._getValue(rule.sourceKey, data, unfinished);

    if (typeof value !== 'undefined' || value !== undefined) {
      unfinished[rule.destination] = this._zipMap(rule, unfinished, value);
    }
  }

  public _zipMap(
    rule: TMapRule | TTemplateRule | TMapArrayTemplateRule,
    unfinished: UnfinishedData,
    value: string | number | boolean
  ) {
    if (typeof value === 'string') value = value.replace(/ +(?= )/g, '').trim();

    return {
      ...unfinished[rule.destination],
      ..._zipObjectDeep(
        [rule.targetKey],
        [
          convertOutputValue(
            value,
            rule.outputType || DEFAULT_OUTPUT_TYPE,
            rule.postProcess
          ),
        ]
      ),
    };
  }

  public _handleCombineRule(
    rule: TCombineRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    const values: string[] = [];

    rule.sourceKeys.forEach((sourceKey) => {
      if (this._getValue(sourceKey, data, unfinished)) {
        values.push(this._getValue(sourceKey, data, unfinished) as string);
      }
    });

    let value = values
      .filter((x) => !!x)
      .join(rule.delimiter)
      .trim();

    if (rule.postProcess) {
      rule.postProcess.forEach((helper) => {
        value = PostProcessHelpers[helper](value);
      });
    }

    unfinished[rule.destination][rule.targetKey] = value;
  }

  public _handleRegexRule(
    rule: TRegexpRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    unfinished[rule.destination][rule.targetKey] = OPERATIONS.runRegexOperation(
      data,
      rule
    );
  }

  public _handleGlobalOperateRule(
    rule: TGlobalOperateRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    // eslint-disable-next-line
    unfinished = _merge(
      unfinished,
      // eslint-disable-next-line
      OPERATIONS[rule.operationName](data, rule, unfinished)
    );
  }

  public _handleOperateRule(
    rule: TOperateRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    unfinished[rule.destination][rule.targetKey] = OPERATIONS[
      rule.operationName
    ](data, rule, unfinished);
  }

  public _handleReplaceRule(
    rule: TReplaceRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    if (rule.matchValue.length !== rule.replaceValue.length) {
      throw new Error(
        'Lengths of matchValue and replaceValue arrays need to be the same.'
      );
    }
    const key = rule.targetKey.slice(2);
    let rawValue = '';
    if (rule.targetKey.startsWith('c')) {
      rawValue = _.get(data, key);
    } else if (rule.targetKey.startsWith('t')) {
      rawValue = _.get(unfinished, key);
    }

    rule.matchValue.forEach((matchValue, idx) => {
      if (typeof rawValue === 'string') {
        // Added check if rawValue is string - AS 10.10.2023.
        rawValue = rawValue.replaceAll(matchValue, rule.replaceValue[idx]);
      }
    });

    if (rule.targetKey.startsWith('c')) {
      data[key] = rawValue;
    } else if (rule.targetKey.startsWith('t')) {
      const keySplit = key.split('.');
      const destination = keySplit[0] as 'model' | 'product';
      const destinationKey = keySplit[1];
      unfinished[destination][destinationKey] = rawValue;
    }
  }

  public _handleMapArrayRule(
    rule: TMapArrayRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    let key = '';
    if (rule.sourceKey.startsWith('c.' || rule.sourceKey.startsWith('t.'))) {
      key = rule.sourceKey.slice(2);
    } else if (
      rule.sourceKey.startsWith('c[') ||
      rule.sourceKey.startsWith('t[')
    ) {
      key = rule.sourceKey.slice(3).slice(0, rule.sourceKey.length - 5);
    }

    let rawValue = '';
    if (rule.sourceKey.startsWith('c')) {
      rawValue = _.get(data, key);
    } else if (rule.sourceKey.startsWith('t')) {
      rawValue = _.get(unfinished, key);
    }

    const outputList: any[] = (rawValue ?? '')
      ?.split(rule.delimiter)
      .filter((x) => !!x);

    outputList.forEach((output, idx) => {
      if (outputList[idx])
        outputList[idx] =
          convertOutputValue(
            output,
            rule.outputType || DEFAULT_OUTPUT_TYPE,
            rule.postProcess
          ) ?? '';
    });

    unfinished[rule.destination][rule.targetKey] = outputList;
  }

  public _handleMapArrayTemplateRule(
    rule: TMapArrayTemplateRule,
    data: RawImport,
    unfinished: UnfinishedData
  ) {
    const keys = rule.sourceKey.split(rule.delimiter).filter((x) => !!x);

    unfinished[rule.destination][rule.targetKey] = keys.map((key) =>
      this._replaceValuesFromTemplate(`#{${key}}`, data, unfinished)
    );
  }

  _handleRules(
    ruleset: TRule[],
    json: RawImport,
    unfinishedData: UnfinishedData
  ) {
    ruleset.forEach((rule) => {
      const categoryId =
        unfinishedData.model?.['category']?.id ||
        unfinishedData.model?.['category_id'];
      const parentCategoryId =
        unfinishedData.model?.['category']?.parent_category_id;

      if (rule.applyOnlyForCategories) {
        if (
          !rule.applyOnlyForCategories.includes(categoryId) &&
          !rule.applyOnlyForCategories.includes(parentCategoryId)
        )
          return;
      }

      if (rule.ignoreForCategories) {
        if (
          rule.ignoreForCategories.includes(categoryId) ||
          rule.ignoreForCategories.includes(parentCategoryId)
        )
          return;
      }

      switch (rule.type) {
        case 'rule.map':
          /**DONE*/
          this._handleMapRule(rule, json, unfinishedData);
          break;
        case 'rule.combine':
          /**DONE*/
          this._handleCombineRule(rule, json, unfinishedData);
          break;
        case 'rule.operate':
          this._handleOperateRule(rule, json, unfinishedData);
          break;
        case 'rule.global.operate':
          this._handleGlobalOperateRule(rule, json, unfinishedData);
          break;
        case 'rule.regex':
          this._handleRegexRule(rule, json, unfinishedData);
          break;
        case 'rule.simple_exp':
          this._handleSimpleExpRule(rule, json, unfinishedData);
          break;
        case 'rule.condition':
          this._handleConditionalRule(rule, json, unfinishedData);
          break;
        case 'rule.condition_boolean':
          this._handleConditionalBooleanRule(rule, json, unfinishedData);
          break;
        case 'rule.template':
          this._handleTemplateRule(rule, json, unfinishedData);
          break;
        case 'rule.replace':
          this._handleReplaceRule(rule, json, unfinishedData);
          break;
        case 'rule.map_array':
          this._handleMapArrayRule(rule, json, unfinishedData);
          break;
        case 'rule.map_array_template':
          this._handleMapArrayTemplateRule(rule, json, unfinishedData);
          break;
        default:
          console.log('UNHANDLED RULE', rule, unfinishedData);
          break;
      }

      if (unfinishedData.product['euDirectiveNumber'] === 'undefined') {
        console.log(rule, json, unfinishedData);
        throw new Error('HAI');
      }
    });
  }
}
