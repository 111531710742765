import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';

interface IOptionsArray {
  value: string;
  name: string;
}

export const SearchFilter = ({
  optionsArray,
  onChange,
  selectValue,
  textFieldValue,
  filterFunction,
}: {
  optionsArray: IOptionsArray[];
  onChange: (val: string, key: string) => void;
  selectValue: string | undefined;
  textFieldValue: string | undefined;
  filterFunction?: () => void;
}) => {
  return (
    <Wrapper>
      <FormControl sx={{ maxWidth: '200px' }}>
        <InputLabel>Search type</InputLabel>
        <Select
          label="Search type"
          sx={{ borderRadius: '8px 0 0 8px', borderRight: 0 }}
          value={selectValue}
          onChange={(e) => {
            // setFilterValue({ searchType: e.target.value });
            onChange(e.target.value as string, 'searchType');
          }}
        >
          {optionsArray.map((m) => (
            <MenuItem value={m.value} key={m.value}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip
        title={filterFunction ? 'Press enter to search!' : ''}
        placement="top"
        arrow={true}
      >
        <TextField
          disabled={selectValue === ''}
          onChange={(e) => {
            onChange(e.target.value as string, 'searchKeyword');
          }}
          onKeyDown={(e) => {
            const enter = e.keyCode === 13;
            if (enter && filterFunction) filterFunction();
          }}
          value={textFieldValue}
          InputProps={{
            style: {
              borderRadius: '0 8px 8px 0',
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;
