import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const cartType = {
  empty: {
    icon: wmsIcons.cart,
    title: 'Empty cart',
    subtitle: 'add some products',
    color: '#ECF0F4',
  },
  order_sent: {
    icon: CheckCircleOutlineRoundedIcon,
    title: 'Order sent!',
    subtitle: '',
    color: '#3EB274',
  },
};

export const CartDetails = ({ type }: { type: 'empty' | 'order_sent' }) => {
  const { icon, color, title, subtitle } = cartType[type];
  return (
    <Wrapper>
      <WmsIcon
        icon={icon}
        sx={{ color: color, width: '200px', height: '200px' }}
      />
      <Typography fontWeight={700} style={{ fontSize: '36px' }}>
        {title}
      </Typography>
      <Typography
        variant="h6"
        fontWeight={500}
        color="#B9C4CE"
        style={{ fontSize: '20px' }}
      >
        {subtitle}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;
