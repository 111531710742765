import { TRule } from '../../typings';

export const WHEELTRADE_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['ean']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['part_number']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['brand']",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.operate',
    sourceKey: "c.['your_net_price']",
    destination: 'product',
    targetKey: 'priceEUR',
    operationName: 'calculateWheelTradePrice',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.suggested_retail_price',
    destination: 'product',
    targetKey: 'suggestedRetailPrice',
    outputType: 'number',
  },
];
