import * as z from 'zod';
import { FormShape } from '../types';
import { createInput } from '../utils';

// TODO: zod localization messages
export const generateExampleForm = () => {
  const zodSchema = z.object({
    first_name: z.string().min(2, {
      message: 'First name must be longer than 2 characters',
    }),
    checkbox: z.boolean(),
    example_radio: z.string(),
    pid: z.string().nullable(),
    school: z.string(),
    example_switch: z.boolean(),
  });

  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: 'Personal Info',
        inputs: [
          createInput({
            id: 'first_name',
            width: { xs: 3 },
            type: 'input.string',
            label: 'First Name',
          }),
          createInput({
            id: 'checkbox',
            width: { xs: 3 },
            type: 'input.checkbox',
            label: 'Pristajem',
          }),
          createInput({
            id: 'example_radio',
            width: { xs: 3 },
            type: 'input.radio',
            label: 'Gender',
            initialValue: 'male',
            options: [
              {
                label: 'Male',
                value: 'male',
              },
              {
                label: 'Female',
                value: 'female',
              },
            ],
          }),
        ],
      },
      {
        tabTitle: 'User details',
        inputs: [
          createInput({
            id: 'pid',
            width: { xs: 3 },
            type: 'input.string',
            label: 'Personal Identification Number',
          }),
          createInput({
            id: 'school',
            width: { xs: 6 },
            type: 'input.select',
            label: 'School',
            initialValue: 'osnovna',
            options: [
              {
                value: 'osnovna',
                label: 'Osnovna',
              },
              {
                value: 'srednja',
                label: 'Srednja',
              },
              {
                value: 'fakultet',
                label: 'Fakultet',
              },
            ],
          }),
          createInput({
            id: 'example_switch',
            width: { xs: 3 },
            type: 'input.switch',
            color: 'info',
            label: 'Active',
            value: true,
          }),
        ],
      },
    ],
  };

  return { form, zodSchema };
};
