import { DBPaymentTypes } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { SelectAllHeader } from '../components/SelectAllHeader';
import { SwitchCard } from '../components/SwitchCard';
import {
  handleActive,
  handleAllActive,
} from '../helpers/handle-active-methods';
import { CardsWrapper } from './CommonStyle';

interface PaymentMethodsBranchTabProps {
  setPayloadShape: Dispatch<SetStateAction<Record<string, boolean>>>;
  selectedPayments?: Record<string, boolean>;
}

export const PaymentMethodsBranchTab = ({
  setPayloadShape,
  selectedPayments,
}: PaymentMethodsBranchTabProps) => {
  const [paymentMethods, setPaymentMethods] = useState<DBPaymentTypes[]>([]);
  const [isActive, setIsActive] = useState<Record<string, boolean>>(
    selectedPayments ?? {}
  );
  const [areAllActive, setAreAllActive] = useState(false);

  useQuery(
    ['get_payment_method'],
    async () => {
      return await api.fetch<DBPaymentTypesApi['list']>('get_payment_method');
    },
    {
      onSuccess: (data) => {
        setPaymentMethods(data.data.filter((item) => item.isActive === true));
      },
    }
  );

  useEffect(() => {
    setPayloadShape(isActive);
  }, [isActive, setPayloadShape]);

  return (
    <div>
      <SelectAllHeader
        text={'Assign available payment methods'}
        onClick={() =>
          handleAllActive(
            isActive,
            setIsActive,
            areAllActive,
            setAreAllActive,
            paymentMethods
          )
        }
      />
      <CardsWrapper>
        {paymentMethods.length > 0 &&
          paymentMethods.map((item) => (
            <SwitchCard
              key={item.id}
              name={item.name}
              description={item.description ?? ''}
              onClick={() =>
                handleActive(item.id, setIsActive, setAreAllActive)
              }
              isActive={isActive[item.id]}
            />
          ))}
      </CardsWrapper>
    </div>
  );
};
