/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { ChangeEvent } from 'react';

interface IStyledPagination {
  totalPageNumber: number;
  page: number;
  setPage: (val: number) => void;
  selectedOrderData: any;
  pageSize: number;
  handleChangePageSize: (event: SelectChangeEvent) => void;
  menuItems: number[];
}

const StyledPagination = ({
  totalPageNumber,
  page,
  setPage,
  selectedOrderData,
  pageSize,
  handleChangePageSize,
  menuItems,
}: IStyledPagination) => {
  return (
    <PaginationContainer>
      <Pagination
        count={totalPageNumber}
        page={page}
        onChange={(_e: ChangeEvent<unknown>, page: number) => {
          setPage(page);
        }}
        sx={{
          button: {
            '&.css-d3ksqy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected':
              {
                backgroundColor: `${tyrioUI.colors.lightBlue}`,
                color: 'white',
              },
          },
        }}
      />

      {selectedOrderData === null && (
        <FormController>
          <InputLabel id="page-size-select-label">Rows per page</InputLabel>
          <Select
            fullWidth
            labelId="page-size-select-label"
            id="page-size-select"
            value={pageSize.toString()}
            label="Rows per page"
            onChange={handleChangePageSize}
            style={{ height: '40px' }}
          >
            {menuItems.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormController>
      )}
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 100%;
  padding: 20px;
  margin-top: 15px;
`;

const FormController = styled(FormControl)`
  position: absolute;
  right: 16px;
  width: 110px;
`;

export default StyledPagination;
