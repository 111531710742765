import { Badge, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DBTransferOrderItemCountByStatus,
  DBTransferOrderStatusEnum,
  TransferOrderStatusShape,
} from '@tyrio/dto';
import qs from 'qs';
import { useState } from 'react';
import { TRANSFER_ORDER_STATUSES } from '../../purchase-orders/helpers/filters-data';
import { getTransferOrderStatusColor } from '../../purchase-orders/helpers/generate-color';

interface TabsFilterProps {
  filters: qs.ParsedQs;
  setFilters: (value: Partial<qs.ParsedQs>) => void;
  setPage: (data: number) => void;
  countOrders: DBTransferOrderItemCountByStatus[];
}

const TabsFilter = (props: TabsFilterProps) => {
  const [shouldResetTabs, setShouldResetTabs] = useState(false);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: DBTransferOrderStatusEnum | 'all'
  ) => {
    props.setFilters({
      ...props.filters,
      orderStatus: newValue,
    });
    props.setPage(1);
    setShouldResetTabs(false);
  };

  const getOrderCount = (orderStatus: DBTransferOrderStatusEnum) => {
    const filteredOrder = props.countOrders.filter(
      (item) =>
        (item.orderStatus as unknown as TransferOrderStatusShape).status ===
        orderStatus
    );

    return filteredOrder.reduce(
      (acc, currentValue) => acc + currentValue._count,
      0
    );
  };

  // leave this commented for now
  // useEffect(() => {
  //   props.setFilters({
  //     ...props.filters,
  //     orderStatus: 'all',
  //   });

  //   setShouldResetTabs(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.filters['searchKeyword']]);

  return (
    <Grid
      sx={{
        width: '100%',
        overflow: 'auto',
      }}
    >
      <TabsStyled
        value={shouldResetTabs ? 'all' : props.filters['orderStatus']}
        onChange={handleChange}
        scrollButtons={false}
        variant="scrollable"
      >
        {TRANSFER_ORDER_STATUSES.map((status) => {
          const orderCount = getOrderCount(
            DBTransferOrderStatusEnum[
              status.value as keyof typeof DBTransferOrderStatusEnum
            ]
          );

          const color =
            getTransferOrderStatusColor[
              DBTransferOrderStatusEnum[
                status.value as keyof typeof DBTransferOrderStatusEnum
              ]
            ];

          return (
            <TabStyled
              value={status.value}
              iconPosition="start"
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
                '&:hover': {
                  color: '#1976D2',
                  opacity: 1,
                },
              }}
              key={status.label}
              icon={
                <Grid
                  sx={{
                    width: status.width,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    {status.label}
                  </Typography>

                  {orderCount > 0 && (
                    <Badge
                      badgeContent={orderCount}
                      sx={{
                        '& .MuiBadge-badge': {
                          color: color !== '#FFFFFF' ? 'white' : 'gray',
                          backgroundColor: color,
                          border:
                            color !== '#FFFFFF' ? 'none' : '1px solid #DFE3E8',
                        },
                      }}
                    />
                  )}
                </Grid>
              }
            />
          );
        })}
      </TabsStyled>
    </Grid>
  );
};

const TabsStyled = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1976D2',
  },
});

const TabStyled = styled(Tab)({
  '&.Mui-selected': {
    color: '#1976D2',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#1976D2',
    color: '#1976D2',
  },
});

export default TabsFilter;
