/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import { TyrioTab, TyrioTabs } from '@tyrio/ui-library';
import { intersection, isEmpty } from 'lodash';
import { useContext } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { InboundInvoiceContext } from '../../../context/InboundInvoiceContext';
import { Footer } from './Footer';
import { Intrastat } from './Intrastat';
import { Main } from './Main';
import { VariableCost } from './VariableCost';

interface InboundInvoiceTabsProps {
  errors: FieldErrors;
  control: Control;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<any>;
  onSubmit: (data: any) => void;
  handleSubmit: any;
  watch: UseFormWatch<any>;
  isLoading: boolean;
}

export const InboundInvoiceTabs = ({
  errors,
  control,
  register,
  setValue,
  onSubmit,
  handleSubmit,
  watch,
  isLoading,
}: InboundInvoiceTabsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { setInput, isEU } = useContext(InboundInvoiceContext);

  const mainTabRequiredFields = [
    'branchId',
    'date',
    'supplierName',
    'vatChargeType',
    'currency',
    'supplierInvoiceNumber',
  ];

  const intrastatRequiredFields = isEU
    ? [
        'deliveryTermsType',
        'transactionNature',
        'transportMode',
        'dispatchingCountryId',
      ]
    : [];

  const keys = Object.keys(errors);

  const errorInMain = !isEmpty(intersection(keys, mainTabRequiredFields));

  const errorInIntrastat = isEU
    ? !isEmpty(intersection(keys, intrastatRequiredFields))
    : false;

  const onCancel = () => {
    setInput((prevState) => ({
      ...prevState,
      ids: '',
      checkedStockIns: {},
      checkedSupplier: { id: '', name: '' },
      preparedInvoices: null,
    }));
    history.goBack();
  };

  return (
    <Wrapper>
      <div
        style={{
          borderRadius: '16px',
          paddingLeft: '8px',
          paddingTop: '8px',
        }}
      >
        <TyrioTabs>
          <TyrioTab title={t('Main')} key="main" errored={errorInMain}>
            <Main
              errors={errors}
              control={control}
              register={register}
              setValue={setValue}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              watch={watch}
            />
          </TyrioTab>
          <TyrioTab title={t('Variable cost')} key="variable-cost">
            <VariableCost
              errors={errors}
              register={register}
              watch={watch}
              setValue={setValue}
            />
          </TyrioTab>
          <TyrioTab
            title={t('Intrastat')}
            isVisible={isEU}
            key="intrastat"
            errored={errorInIntrastat}
          >
            <Intrastat
              errors={errors}
              control={control}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
            />
          </TyrioTab>
        </TyrioTabs>
      </div>

      <FooterWrapper>
        <Footer
          onSubmit={handleSubmit(onSubmit)}
          onCancel={onCancel}
          isLoading={isLoading}
        />
      </FooterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  border-radius: 16px;
`;

const FooterWrapper = styled.div`
  position: sticky;
  top: 100%;
`;
