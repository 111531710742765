/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '@tyrio/api-factory';
import { DBShipAndPrintApi, PrintBarcodeBody } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { useMutation } from 'react-query';

export const usePrintBarcode = (onSuccess: () => void) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: PrintBarcodeBody) =>
      api.fetch<DBShipAndPrintApi['printBarcode']>('print_barcode', {
        ...data,
      }),
    {
      mutationKey: 'print_barcode',
      onSuccess: () => {
        onSuccess();

        ToastHelper.showToast(
          'Print',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Print', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { printBarcode: mutateAsync, isLoading };
};
