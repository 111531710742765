import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { PNEUMATIK_PRICELIST_RULESET } from './pneumatik/pricelist';
import { PNEUMATIK_STOCKLIST_RULESET } from './pneumatik/stocklist';
import { TRule } from '../typings';

export const PNEUMATIK_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: PNEUMATIK_PRICELIST_RULESET,
  stockRuleset: PNEUMATIK_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'PNEUMATIK',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {},
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ',' }).fromString(file);

    return handlePreprocessConversions(
      json.filter((item) => !['DOT'].includes(item['Naziv'])),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ',' }).fromString(file);

    return handlePreprocessConversions(
      json.filter((item) => !['DOT'].includes(item['Naziv'])),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ',' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
