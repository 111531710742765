export const RFD_KEYS = {
  XL: {
    id: 1,
    name: 'XL',
    description: 'Extra Load',
    extraLoad: true,
  },
  REINF: {
    id: 2,
    name: 'REINF',
    description: 'Reinforced',
    extraLoad: true,
  },
  RFD: {
    id: 3,
    name: 'RFD',
    description: 'Reinforced',
    extraLoad: true,
  },
  SL: {
    id: 4,
    name: 'SL',
    description: 'Standard Load',
    extraLoad: false,
  },
  HL: {
    id: 5,
    name: 'HL',
    description: 'High Load',
    extraLoad: true,
  },
  LL: {
    id: 6,
    name: 'LL',
    description: 'Low Load',
    extraLoad: false,
  },
};
