import styled from '@emotion/styled';
import { SearchOutlined } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CachedIcon from '@mui/icons-material/Cached';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DBClientApiKeyApi } from '@tyrio/dto';
import { DeleteModal } from '@tyrio/ui-library';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate, getDaysDifference } from '../../../../helpers/date-helper';
import {
  getSuccessMessage,
  useChangeStatus,
  useClientApiKey,
  useDeleteKey,
  useFetchKeys,
  useGenerateApiKey,
} from './Mutations';

const ApiKeyOverview = ({ clientId }: { clientId: string }) => {
  const history = useHistory();

  //STATES
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState<
    DBClientApiKeyApi['getOne']['response'] | undefined
  >(undefined);

  //QUERIES AND MUTATIONS
  const { apiKeysData, refetch } = useFetchKeys(search, clientId);
  const { clientKey, refetchClientKey } = useClientApiKey(clientId);
  const createKey = useGenerateApiKey(clientId ? refetchClientKey : refetch);
  const changeStatus = useChangeStatus(clientId ? refetchClientKey : refetch);
  const deleteKey = useDeleteKey(clientId ? refetchClientKey : refetch);

  const handleOpen = () => {
    setIsModalOpen(true);
    createKey.mutate();
  };

  const handleClose = () => setIsModalOpen(false);

  const copyApiKey = async (key: string) => {
    await navigator.clipboard.writeText(key);
    getSuccessMessage('API key successfully copied in clipboard!');
  };

  const array = clientKey ? [clientKey] : apiKeysData;

  const pageText = clientId
    ? 'Welcome to the Tyrio API Key Management page for Tyrio clients. On this page, you can manage all API keys for clients on the Tyrio app. To revoke or activate an existing API key, click on the appropriate icon in action section in table.'
    : 'Welcome to the Tyrio API Key Management page for Tyrio Admins. On this page, you can manage all API keys for clients on the Tyrio app. To revoke or activate an existing API key, click on the appropriate icon in action section in table.';

  const noKeysText = clientId
    ? "You don't have any generated API key!"
    : 'There is no clients that have API keys!';

  return (
    <Grid>
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '15px',
              }}
            >
              API key generation
            </Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
          </Grid>
          {createKey.status === 'loading' && (
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '200px',
              }}
            >
              <CircularProgress />
              <Typography sx={{ marginTop: '15px' }}>
                Generating an API key...
              </Typography>
            </Grid>
          )}
          {createKey.status === 'error' && (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Alert severity="error" sx={{ marginTop: '15px' }}>
                <AlertTitle>An error ocurred!</AlertTitle>
                There is an error during creation new API key. Please contact
                Tyrio admin or try again!
              </Alert>
              <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                marginTop="15px"
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  startIcon={<CancelIcon />}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  startIcon={<CachedIcon />}
                  onClick={() => {
                    createKey.mutate();
                  }}
                >
                  TRY AGAIN
                </Button>
              </Stack>
            </Grid>
          )}
          {createKey.status === 'success' && (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <Alert severity="success">
                <AlertTitle>API key generated successfully!</AlertTitle>
                Keep this key secure and do not share it with anyone. Use this
                key to authenticate and access the API's services.
              </Alert>
              <Grid>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <ContentCopyIcon
                        onClick={() => {
                          copyApiKey(createKey.data.apiKey);
                        }}
                        color="info"
                        sx={{
                          cursor: 'pointer',
                          height: '15px',
                        }}
                      />
                    ),
                  }}
                  value={createKey.data.apiKey}
                />
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  startIcon={<CheckCircleIcon />}
                  onClick={handleClose}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
      {isDeleteModalVisible && selectedApiKey && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            deleteKey.mutate(selectedApiKey.apiKey);
            setIsDeleteModalVisible(false);
          }}
          itemName={`${selectedApiKey?.client.shortName} api key`}
        />
      )}
      <HeaderWrapper>
        <ArkeyBackIosIconStyled onClick={() => history.push('/dashboard')} />
        <HeaderText>API keys</HeaderText>
      </HeaderWrapper>
      <Grid sx={{ marginBottom: '20px' }}>
        <Typography sx={{ marginBottom: '15px' }}>{pageText}</Typography>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Typography>
            Please be careful when revoking API keys as it{' '}
            <strong>will affect</strong> the some functionalities.
          </Typography>
        </Alert>
      </Grid>
      <Grid>
        {!clientId && array && (
          <TextField
            fullWidth
            placeholder="Search clients"
            sx={{ marginBottom: '15px' }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
        {!array ||
          (array.length === 0 && <Alert color="info">{noKeysText}</Alert>)}
        {array && array?.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: '#ECF0F4',
                  }}
                >
                  <TableCell>Secret keys</TableCell>
                  <TableCell align="left">Client name</TableCell>
                  <TableCell align="left">Last used</TableCell>
                  <TableCell align="left">Activation date</TableCell>
                  <TableCell align="left">Expiration date</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {array?.map((key) => (
                  <TableRow key={key.apiKey}>
                    <TableCell component="th" scope="key">
                      <Grid
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '300px',
                        }}
                      >
                        <ApiKey apiKey={key.apiKey} />
                      </Grid>
                    </TableCell>
                    <TableCell align="left">{key.client.shortName}</TableCell>
                    <TableCell align="left">
                      {key.lastUsed ? (
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                          <span>{formatDate(key.lastUsed)}</span>
                          <span
                            style={{
                              fontWeight: '400',
                              fontSize: '12px',
                              color: '#919EAB',
                            }}
                          >
                            {key.lastPurpose?.toString().replace(/_/g, ' ')}
                          </span>
                        </Grid>
                      ) : (
                        'Never'
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {moment(key.createdAt).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell align="left">
                      {formatDate(key.expiresAt)} -
                      {getDaysDifference(key.expiresAt) === 0 && (
                        <strong> Today</strong>
                      )}
                      {getDaysDifference(key.expiresAt) < 0 && (
                        <strong> Expired</strong>
                      )}
                      {getDaysDifference(key.expiresAt) > 0 && (
                        <strong>
                          {' '}
                          in {getDaysDifference(key.expiresAt)} days
                        </strong>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {clientId ? (
                        key.isActive ? (
                          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                            <FiberManualRecordIcon
                              color="success"
                              sx={{ height: '12px' }}
                            />
                            <span>Active</span>
                          </Grid>
                        ) : (
                          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                            <FiberManualRecordIcon
                              color="error"
                              sx={{ height: '12px' }}
                            />
                            <span>Inactive</span>
                          </Grid>
                        )
                      ) : (
                        <Tooltip title="Toggle key status" placement="top">
                          <Switch
                            size="small"
                            color="success"
                            defaultChecked={key.isActive}
                            onChange={() => {
                              changeStatus.mutate(key.apiKey);
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" alignItems="center">
                        <Tooltip title="Copy key" placement="top">
                          <ContentCopyIcon
                            onClick={() => {
                              copyApiKey(key.apiKey);
                            }}
                            color="info"
                            sx={{
                              cursor: 'pointer',
                              height: '15px',
                              marginRight: '5px',
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete key" placement="top">
                          <DeleteIcon
                            color="error"
                            sx={{ cursor: 'pointer', height: '20px' }}
                            onClick={() => {
                              setSelectedApiKey(key);
                              setIsDeleteModalVisible(true);
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {clientId && !array && (
          <Grid
            sx={{
              marginTop: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="outlined"
              color="info"
              startIcon={<KeyIcon />}
              onClick={handleOpen}
            >
              ADD NEW API KEY
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const ApiKey = ({ apiKey }: { apiKey: string }) => {
  const [isVisible, setIsVisible] = useState(false);
  const password = '●●●●●●●●●●●●●●●●●●●●';
  const iconSize = { cursor: 'pointer', height: '20px', marginRight: '15px' };

  const changeVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title="Change visibility" placement="top">
        {isVisible ? (
          <VisibilityOffIcon sx={iconSize} onClick={changeVisibility} />
        ) : (
          <VisibilityIcon sx={iconSize} onClick={changeVisibility} />
        )}
      </Tooltip>
      <span> {isVisible ? apiKey : password}</span>
    </Grid>
  );
};

const HeaderWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const HeaderText = styled(Grid)`
  margin-left: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  cursor: default;
`;

const ArkeyBackIosIconStyled = styled(ArrowBackIosIcon)`
  cursor: pointer;
`;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '22px',
  boxShadow: 24,
  p: 4,
  padding: '16px',
};

export default ApiKeyOverview;
