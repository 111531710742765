import api, { ENDPOINTS } from '@tyrio/api-factory';
import {
  DBClientSupplierOrderItemApi,
  DBCustomerOrderItemApi,
  DBOrderDocumentApi,
  DBTransferOrderItemApi,
} from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export function useDocuments(
  selectedOrderData:
    | DBCustomerOrderItemApi['getOne']['response']
    | DBClientSupplierOrderItemApi['getOne']['response']
    | DBTransferOrderItemApi['getOne']['response']
    | null,
  refetch: () => void,
  setIsUploadInProcess: (item: boolean) => void,
  orderType: string
) {
  const uploadOrderDocuments = useMutation(
    (data: {
      data: FormData;
      headers: {
        Authorization: string;
        'Content-Type': string;
        type: string;
      };
    }) =>
      axios.put(
        `${ENDPOINTS.upload_order_document.uri}${selectedOrderData?.id}`,
        data.data,
        {
          headers: data.headers,
        }
      ),
    {
      mutationKey: 'upload_order_document',
      onSuccess: () => {
        refetch();
        setIsUploadInProcess(false);
        ToastHelper.showToast(
          'Related documents',
          ToastType.SUCCESS,
          ToastMessageType.UPLOAD
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            `${orderType} order`,
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  // delete order document
  const deleteOrderDocument = useMutation(
    (id: string) =>
      api.fetch<DBOrderDocumentApi['getOne']>('delete_order_document', {
        id: id ?? '',
      }),
    {
      mutationKey: 'delete_order_document',
      onSuccess: () => {
        refetch();
        ToastHelper.showToast(
          'Related document',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Related document',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  return {
    uploadOrderDocuments,
    deleteOrderDocument,
  };
}
