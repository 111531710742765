import { TRule } from '../../typings';

export const RONAL_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN Code']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.operate',
    sourceKey: "c['Wheel number']",
    destination: 'product',
    targetKey: 'supplierCode',
    operationName: 'getRonalManufacturerCode',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'brand',
    template: 'RONAL',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Your purchase price"]',
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Listed price incl. fitting where needed"]',
    destination: 'product',
    targetKey: 'suggestedRetailPrice',
    outputType: 'number',
  },
];
