import styled from '@emotion/styled';
import { Button, Snackbar, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import { DBProduct, DBUserRole } from '@prisma/client';
import { DBProductApi } from '@tyrio/dto';
import { SpecialMarkingItem } from '@tyrio/shared-vars';
import { cloneIcon, editIcon, tyrioIcons } from '@tyrio/ui-library';
import _, { startCase } from 'lodash';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import euLabelRollingResistance from '../assets/euLabelFuel.svg';
import euLabelNoise from '../assets/euLabelNoise.svg';
import euLabelWet from '../assets/euLabelWet.svg';
interface ProductItemsProps {
  data: DBProductApi['getOne']['response'][];
  roleType?: DBUserRole;
  modelSidewall?: string;
}

const ProductItems = ({ data, roleType }: ProductItemsProps) => {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);

  const history = useHistory();
  const [snackbarOpen, setSnackbarOpen] = useState<string | null>(null);

  const generateMarkingColor = (key: string) => {
    if (key === 'Not for Highway Service') return '#DB1E1E';
    if (key === 'Electric') return '#38A169';
    if (key === 'Ice Grip') return '#E75524';
    return '#055C88';
  };

  const generateSpecialMarkingAndTooltips = useCallback(
    (product: DBProduct) => {
      const productMeta = product['productMeta'];

      const specialMarkingList = Object.values(
        productMeta?.['specialMarking' as keyof DBProduct['productMeta']] || {}
      ) as SpecialMarkingItem[];

      const groupedByKey = _.groupBy(specialMarkingList, 'shortDescription');

      const specialMarkingKeys = Object.values(groupedByKey)
        .filter((item) => !['NHS', 'RFT'].includes(item[0].key))
        .map((item) => {
          const keys = item.map((obj) => obj.key).join(', ');
          const { shortDescription, description } = item[0];
          const color = generateMarkingColor(item[0].key);

          return {
            key: keys,
            name: startCase(shortDescription),
            description: `${keys} - ${description}`,
            color,
          };
        });

      const keys: Record<string, { show: boolean; color?: string }> = {
        'Run Flat': {
          show: !!product.runFlat,
        },
        'Self Seal': {
          show: !!product.seal,
        },
        '3PMFS': { show: !!product.threepmfs },
        'Ice Grip': { show: !!product.iceGrip },
        'Comm Mark': { show: !!product.commMark },
        Flank: { show: !!product.flank },
        Silent: { show: !!product.silent },
        Electric: { show: !!product.electric, color: '#38A169' },
        'Not for Highway Service': {
          show: (product?.specialMarking?.indexOf('NHS') || -1) > -1,
          color: '#DB1E1E',
        },
      };

      Object.keys(keys).forEach((key) => {
        if (keys[key].show) {
          specialMarkingKeys.push({
            key: key,
            name: key,
            description: key,
            color: keys[key].color || generateMarkingColor('default'),
          });
        }
      });

      const finalMarkingData = _.uniqBy(specialMarkingKeys, 'name');

      return Object.values(finalMarkingData).map((k) => {
        const children = (
          <ProductMarkingItem
            style={{
              color: k.color,
            }}
          >
            {k.name}
          </ProductMarkingItem>
        );
        if (!k.description) return children;

        if (Object.values(specialMarkingKeys).length > 0)
          return <Tooltip children={children} title={k.description} />;
        else return null;
      });
    },
    []
  );

  return (
    <Container cellPadding={0} cellSpacing={0}>
      {data.map((el) => (
        <StyledTableRow key={el.productName}>
          <StyledTableCell isSafari={isSafari} w={'3%'}>
            <Checkbox
              onClick={(e) => e.stopPropagation()}
              color="info"
              style={{ padding: 0, margin: 0 }}
            />
          </StyledTableCell>
          <StyledTableCell isSafari={isSafari} w={'10%'}>
            {el.sku}
          </StyledTableCell>
          <Tooltip
            children={
              <StyledTableCell
                isSafari={isSafari}
                w={'10%'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigator.clipboard
                    .writeText(el.ean)
                    .then(() => {
                      setSnackbarOpen(`EAN ${el.ean} copied to clipboard!`);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              >
                {el.ean}
              </StyledTableCell>
            }
            title="Copy EAN to clipboard"
            style={{ cursor: 'pointer' }}
          />

          <StyledTableCell isSafari={isSafari} w={'30%'}>
            {el.productName}
          </StyledTableCell>
          {el.rollingResistance && el.wetGrip && el.noisePerformance && (
            <StyledTableCell
              isSafari={isSafari}
              w={'12%'}
              style={{ padding: 0 }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconContainer>
                  <Icon
                    src={euLabelRollingResistance}
                    alt="rollingResistance"
                  />
                  <IconText>{el.rollingResistance}</IconText>
                </IconContainer>
                <IconContainer>
                  <Icon src={euLabelWet} alt="wetGrip" />
                  <IconText>{el.wetGrip}</IconText>
                </IconContainer>
                <IconContainer>
                  <Icon src={euLabelNoise} alt="noisePerformance" />
                  <IconText> {el.noisePerformance}</IconText>
                </IconContainer>
              </div>
            </StyledTableCell>
          )}
          {generateSpecialMarkingAndTooltips(el) !== null && (
            <StyledTableCell isSafari={isSafari} w={'15%'}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {generateSpecialMarkingAndTooltips(el)}
              </div>
            </StyledTableCell>
          )}
          {roleType === DBUserRole.ADMIN && (
            <StyledTableCell
              isSafari={isSafari}
              w={'5%'}
              style={{ padding: 0 }}
            >
              <StyledButton
                variant="text"
                className="copy"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/dashboard/products/${el?.productModelUid}/new`,
                    {
                      data: { ...el, uid: undefined, ean: '' },
                    }
                  );
                }}
              >
                <img src={cloneIcon} alt="" style={{ marginRight: '5px' }} />
                Clone
              </StyledButton>
            </StyledTableCell>
          )}
          <StyledTableCell isSafari={isSafari} w={'7%'}>
            <img
              src={el.active ? tyrioIcons.greenBadge : tyrioIcons.redBadge}
              alt="active-badge"
              width="8px"
            />
          </StyledTableCell>
          <StyledTableCell isSafari={isSafari} w={'3%'}>
            <Tooltip
              children={
                <img
                  src={editIcon}
                  alt="edit-icon"
                  onClick={() => {
                    history.push(
                      `/dashboard/products/${el.productModelUid}/${el.uid}`
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                />
              }
              title={'Edit/View Product'}
            />
          </StyledTableCell>
        </StyledTableRow>
      ))}
      <Snackbar
        open={!!snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(null)}
        message={snackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </Container>
  );
};

const Container = styled.table`
  width: 100%;
`;

const StyledButton = styled(Button)`
  border: none;
  visibility: hidden;
`;

const ProductMarkingItem = styled.div`
  margin-right: 5px;
  color: #055c88;
  font-weight: 600;
  padding: 2px;
  &:hover {
    background: rgb(0, 0, 0, 0.05);
    border-radius: 5px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 16px;
`;

const IconText = styled.p`
  color: #212b36;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledTableRow = styled(TableRow)(() => ({
  width: '100%',
  height: '50px',
  borderBottom: '1px solid lightgrey',
  '&:nth-of-type(even)': {
    backgroundColor: 'rgb(236, 240, 244, 0.3)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  ':hover': {
    background: '#fff8e5',
    '.copy': {
      visibility: 'visible',
    },
  },
}));

const StyledTableCell = styled(TableCell)(
  (props: { w: string; isSafari: boolean }) => ({
    borderBottom: 0,
    width: props.isSafari ? props.w : '100%',
    maxWidth: props.w,
    height: '50px',
    boxSizing: 'border-box',
  })
);

export default ProductItems;
