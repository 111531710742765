import { DBSupplierClientPaymentSettings } from '@prisma/client';

export const supplierClientPaymentSettings: DBSupplierClientPaymentSettings[] =
  [
    {
      id: '22b3b035-6567-4f1b-9344-f671155d17eb',
      supplierClientSettingsId: '07c91a78-e661-47df-800e-f197ea7b7022',
      supplierIBAN: 'DEMO',
      supplierSwift: 'DEMO',
      supplierCurrency: 'EUR',
      remark: 'DEMO',
    },
    {
      id: '399c9551-e308-42a7-b396-ba28e9ec90af',
      supplierClientSettingsId: '33b2ab3a-f07f-426d-a637-da5647ddfc5e',
      supplierIBAN: 'DEMO',
      supplierSwift: 'DEMO',
      supplierCurrency: 'EUR',
      remark: 'DEMO',
    },
  ];
