import { FieldValues, UseFormReset } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useGetPartnerDetails } from '../hooks/getPartnerDetails';

interface GeneratePartnerDataProps<T extends object> {
  companyId: string;
  initialValues: Partial<T> | undefined;
  reset: UseFormReset<FieldValues>;
  setShouldDisableEdit: (item: boolean) => void;
}

export function GeneratePartnerData<T extends object>({
  companyId,
  initialValues,
  reset,
  setShouldDisableEdit,
}: GeneratePartnerDataProps<T>) {
  const {
    data,
    isLoading: isLoadingPartnerDetails,
    refetch,
  } = useGetPartnerDetails(companyId);

  const handleUpdatePartnerData = () => {
    refetch();

    if (data && !isLoadingPartnerDetails) {
      const businessType = data.pravni_oblik.vrsta_pravnog_oblika.kratica;

      const isNotSole =
        businessType === 'd.o.o.' || businessType === 'j.d.o.o.';

      const customInitialValues = {
        ...initialValues,
        companyOfficialName: data.tvrtka.ime,
        businessType: isNotSole
          ? businessType === 'd.o.o.'
            ? 'DOO'
            : 'JDOO'
          : 'OBRT',
        zipCode: data.sjediste.sifra_naselja.toString(),
        city: data.sjediste.naziv_naselja,
        address: data.sjediste.ulica + ' ' + data.sjediste.kucni_broj,
        viesValidated: true,
        viesVatNumber: companyId ?? '',
        countryId: '274e7e37-6d32-f25b-4a5f-daf91b949567',
      };

      reset(customInitialValues);

      setShouldDisableEdit(true);
    }

    if (data === null && !isLoadingPartnerDetails)
      toast.error(
        'Error when fetching company data. Please check if the OIB / Vat number is correct!',
        {
          position: 'bottom-right',
          autoClose: 1000,
          theme: 'light',
        }
      );
  };

  return handleUpdatePartnerData;
}
