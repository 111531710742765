import styled from '@emotion/styled';
import _ from 'lodash';
import { QRCodeSVG } from 'qrcode.react';
import { getTitle } from '../../helper/helper';
import LeftIcon from './../../assets/arrow-left.svg';
import RightIcon from './../../assets/arrow-right.svg';
import { PrintSize } from './PrintSticker';

export type VerticalStickerType = 'left' | 'right';

interface LocationStickerProps {
  location: string;
  qrCode: string;
  direction: VerticalStickerType;
  paperSize: PrintSize;
}

const VerticalSticker = (props: LocationStickerProps) => {
  const { location, qrCode, direction, paperSize } = props;

  const title = getTitle(location, true);

  const icon = direction === 'left' ? LeftIcon : RightIcon;

  return (
    <Container isZebra={paperSize === 'zebra'}>
      <QrCodeWrapper>
        <QRCodeSVG value={qrCode} style={{ height: 110, width: 110 }} />
      </QrCodeWrapper>
      <RackTitle>{title}</RackTitle>
      <FinalLabel>{_.last(location)}</FinalLabel>
      <Location>{location}</Location>
      <Img src={icon} />
    </Container>
  );
};

const Container = styled.div<{ isZebra: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  ${({ isZebra }) =>
    isZebra
      ? `
        transform: rotate(-90deg) scale(1.1);
        transform-origin: center;
        overflow: hidden;
      `
      : `
        width: 35mm;
        height: 90mm;
        padding: 10px 10px 10px 10px;
      `}
`;

const QrCodeWrapper = styled.div`
  display: flex;
`;

const RackTitle = styled.span`
  font-family: Helvetica;
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
`;

const FinalLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica;
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  background-color: black;
  color: white;
  height: 55px;
  width: 100%;
`;

const Location = styled.span`
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
`;

const Img = styled.img`
  height: 35px;
`;

export default VerticalSticker;
