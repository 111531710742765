import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const RADCENTAR_CATALOG_RULESET: TRule[] = [
  // EAN
  {
    type: 'rule.map',
    sourceKey: "c.eanCod",
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  // Category types
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'category_id',
    template: '72',
    outputType: 'forced_integer',
  },
  // Manufacturer code
  {
    type: 'rule.operate',
    sourceKey: "c.articleNo",
    destination: 'product',
    targetKey: 'manufacturerCode',
    operationName: 'getRadcentarManufacturerCode',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.manufacturer',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.map',
    sourceKey: 'c.design',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.inch',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.pcdTotal',
    targetKey: 'pcd',
    delimiter: '|',
    destination: 'product',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.offset',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.NB",
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.finishEN',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  // Instalation kit
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'partialKit',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['OE-Fitment']",
    condition: 'equals',
    conditionValue: '1',
    outputLogic: 'custom',
    outputValue: 'oem',
    destination: 'product',
    targetKey: 'installationKit',
    outputType: 'string',
  },
  // Weight
  {
    type: 'rule.map',
    sourceKey: "c['wheelKg']",
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  ...GLOBAL_RULESET,
];
