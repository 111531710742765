import api from '@tyrio/api-factory';
import { DBCustomPriceCatalogApi } from '@tyrio/dto';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

interface UpdatePriceProps {
  setErrorText: (val: string) => void;
}

export const useUpdatePrice = (props: UpdatePriceProps) => {
  const { setErrorText } = props;
  const queryClient = useQueryClient();

  const updatePrice = useMutation(
    (req: DBCustomPriceCatalogApi['updateOne']['request']) =>
      api.fetch<DBCustomPriceCatalogApi['updateOne']>('cpc_update_price', {
        ...req,
      }),
    {
      mutationKey: 'cpc_update_price',
      onError: (error) => {
        const errorData = error as AxiosError<unknown>;
        setErrorText((errorData.response?.data as ErrorProps)?.error?.name);
      },
      onSuccess: () => {
        queryClient.refetchQueries('cpc_get_catalog');
      },
    }
  );

  return { updatePrice };
};

interface ErrorProps {
  error: { name: string };
}
