import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { MICHELIN_CATALOG_RULESET } from './michelin/catalog';
import { MICHELIN_PRICELIST_RULESET } from './michelin/pricelist';
import { MICHELIN_STOCKLIST_RULESET } from './michelin/stocklist';

export const MICHELIN_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: MICHELIN_CATALOG_RULESET,
  pricelistRuleset: MICHELIN_PRICELIST_RULESET,
  stockRuleset: MICHELIN_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {},
  hardCodes: {},
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
      origin: 'FR',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'MICHELIN EDI PRICAT',
      dirtyFields: '{}',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    const list = json.filter(
      (item) =>
        ['C0', 'L0', 'L4', 'CB', 'CC'].includes(item.VEHICLE_TYPE) &&
        ['EV'].includes(item.PROD_GRP_2)
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
