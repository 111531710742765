import { Grid } from '@mui/material';
import api from '@tyrio/api-factory';
import {
  DBClientSupplierOrderItemFilters,
  DBCustomerOrderItemFilters,
  DBShopTypesApi,
} from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import _ from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';
import DateTimeRangePicker from '../../features/purchase-orders/components/DateTimeRangePicker';
import MultiSelectInput from '../../features/purchase-orders/components/MultiSelectInput';
import SearchTypeInput from '../../features/purchase-orders/components/SearchTypeInput';
import { SALES_CHANNELS } from '../../features/purchase-orders/helpers/filters-data';
import { ComponentType } from '../../features/purchase-orders/helpers/types';

interface SuppliersProps {
  value: string;
  label: string;
}

interface OrdersHeaderProps {
  isSidebarOpen: boolean;
  filters: DBClientSupplierOrderItemFilters | DBCustomerOrderItemFilters;
  setFilters: (
    value: DBClientSupplierOrderItemFilters | DBCustomerOrderItemFilters
  ) => void;
  suppliers?: SuppliersProps[];
  componentType: ComponentType;
  setFilterValue?: (data: Partial<qs.ParsedQs>) => void;
  filterValues?: Partial<qs.ParsedQs>;
}

const OrdersHeader = ({
  isSidebarOpen,
  filters,
  setFilters,
  suppliers,
  componentType,
  setFilterValue,
  filterValues,
}: OrdersHeaderProps) => {
  const [shopTypes, setShopTypes] = useState<TyrioSelectInputOption[]>([]);

  const salesChannelInitialValues = (
    _.get(filterValues, `salesChannelKeys`, []) as string[]
  ).map((item) => {
    const type = shopTypes.find((i) => i.value === item);

    if (type) return type;

    return {
      label: '',
      value: '',
    };
  });

  const isPurchase = componentType === ComponentType.purchase;

  useQuery(
    'get_client_shop_types',
    () => api.fetch<DBShopTypesApi['list']>('get_client_shop_types'),
    {
      onSuccess: (data) => {
        const dropdownData = data.map((item) => ({
          value: item.code,
          label: `${item.code} - ${item.shopName}`,
        }));

        setShopTypes([...dropdownData, { value: 'ritam', label: 'Ritam' }]);
      },
    }
  );

  return (
    <Grid
      container
      spacing={1.5}
      sx={
        !isPurchase
          ? {
              width: '100%',
              padding: '16px 16px 0 16px',
            }
          : null
      }
    >
      <Grid
        item
        xs={isSidebarOpen ? (isPurchase ? 6 : 5) : isPurchase ? 4.5 : 7}
      >
        <SearchTypeInput
          filters={filters}
          setFilters={setFilters}
          componentType={componentType}
          setFilterValue={setFilterValue}
        />
      </Grid>

      <Grid item xs={isSidebarOpen ? (isPurchase ? 6 : 4) : 2.5}>
        <MultiSelectInput
          attributeKey="salesChannelKeys"
          label="Sales Channel"
          values={isPurchase ? SALES_CHANNELS : shopTypes}
          filters={filters}
          setFilters={setFilters}
          defaultValue={isPurchase ? SALES_CHANNELS : salesChannelInitialValues}
          disabled={isPurchase}
          setFilterValue={setFilterValue}
        />
      </Grid>
      {isPurchase && (
        <Grid item xs={isSidebarOpen ? 6 : 2.5}>
          <MultiSelectInput
            attributeKey="supplierIds"
            label="Supplier"
            values={suppliers as SuppliersProps[]}
            filters={filters}
            setFilters={setFilters}
          />
        </Grid>
      )}
      <Grid item xs={isSidebarOpen ? (isPurchase ? 6 : 3) : 2.5}>
        <DateTimeRangePicker
          isSidebarOpen={isSidebarOpen}
          filters={filters}
          setFilters={setFilters}
          componentType={componentType}
        />
      </Grid>
    </Grid>
  );
};

export default OrdersHeader;
