import api from '@tyrio/api-factory';
import { DBPosTypesApi, PosItemStockType } from '@tyrio/dto';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { BadgeContent } from '../../../context/POSContext';

export const useGetBranchesStock = (
  ean: string,
  warehouseId?: string,
  badgeContent?: BadgeContent,
  setBadgeContent?: (a: BadgeContent) => void,
  selectedStock?: PosItemStockType,
  shouldShowDot?: boolean,
  customerId?: string
) => {
  const {
    data: warehouseStock,
    isLoading: warehouseStockLoading,
    isFetching: warehouseStockFetching,
  } = useQuery(
    [
      `get_branches_stock_${ean}_${selectedStock}_${shouldShowDot}_{${warehouseId}}`,
      { shouldShowDot, warehouseId },
    ],
    () =>
      api.fetch<DBPosTypesApi['getBranchesStock']>(`get_branches_stock`, {
        ean,
        inWarehouse: false,
        shouldShowDot,
        warehouseId,
        customerId,
      }),
    {
      enabled: !isEmpty(ean),
      onSuccess: (res) => {
        let sum = 0;
        res.map((r) =>
          r.lineItems.map((item) => (sum += item.quantity - item.reserved))
        );
        if (setBadgeContent && badgeContent)
          setBadgeContent({ ...badgeContent, BRANCHES: sum });
      },
    }
  );

  const {
    data: onStock,
    isFetching: isOnStockFetching,
    isLoading: onStockLoading,
    isFetching: onStockFetching,
  } = useQuery(
    [
      `get_pos_on_stock_${warehouseId}_${ean}_${shouldShowDot}`,
      { shouldShowDot },
    ],
    () =>
      api.fetch<DBPosTypesApi['getBranchesStock']>(`get_pos_on_stock`, {
        warehouseId,
        ean,
        shouldShowDot,
        customerId,
      }),
    {
      enabled: selectedStock === 'CURRENT',
    }
  );

  const {
    data: upcomingStock,
    isLoading: upcomingStockLoading,
    isFetching: upcomingStockFetching,
  } = useQuery(
    [
      `get_upcoming_on_stock_${warehouseId}_${ean}_${shouldShowDot}`,
      { shouldShowDot },
    ],
    () =>
      api.fetch<DBPosTypesApi['getBranchesStock']>(`get_upcoming_on_stock`, {
        warehouseId,
        ean,
        shouldShowDot,
        customerId,
      }),
    {
      enabled: selectedStock === 'CURRENT',
    }
  );

  return {
    warehouseStock,
    onStock,
    upcomingStock,
    isOnStockFetching,
    showLoader:
      warehouseStockLoading ||
      onStockLoading ||
      upcomingStockLoading ||
      warehouseStockFetching ||
      onStockFetching ||
      upcomingStockFetching,
  };
};
