import api from '@tyrio/api-factory';
import { DBCustomPriceCatalogApi } from '@tyrio/dto';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import { useMutation, useQueryClient } from 'react-query';

export const useDeletePrice = () => {
  const queryClient = useQueryClient();

  const deletePrice = useMutation(
    (req: DBCustomPriceCatalogApi['delete']['request']) =>
      api.fetch<DBCustomPriceCatalogApi['checkEAN']>('cpc_delete', {
        ...req,
      }),
    {
      mutationKey: 'cpc_delete',
      onSuccess: () => {
        queryClient.refetchQueries('cpc_get_catalog');
      },
      onError: () => {
        ToastHelper.showToast(
          'Custom prices',
          ToastType.ERROR,
          undefined,
          'There is an error during deleting price status!'
        );
      },
    }
  );

  return { deletePrice };
};
