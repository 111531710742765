/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { has, isEmpty, isEqual, isNull } from 'lodash';
import { CartObject, CartStockListItem } from '@tyrio/dto';

function findNestedObj(
  entireObj: Record<string, CartObject>,
  keyToFind: string,
  userId?: string | null
) {
  let sum = 0;
  let sumOutbound = 0;

  const inbound: number[] = [];
  const outbound: number[] = [];
  const userKeys: string[] = [];

  if (entireObj === undefined || isNull(entireObj))
    return { inboundReserved: 0, outboundReserved: 0 };

  const splitKey = keyToFind.split('_');
  const branchId = splitKey[2];

  Object.entries(entireObj).map(([key, value]) => {
    if (userId !== undefined && key === userId) return;
    else
      Object.entries(value.inbound).map(([, value]) => {
        if (has(value, keyToFind)) {
          inbound.push((value[keyToFind] as CartStockListItem).quantity);
          if (key !== userId) userKeys.push(key);
        }
      });

    Object.entries(value.outbound).map(([key1, value]) => {
      const branch = key1.split('_')[3];
      if (isEqual(branch, branchId)) {
        const newKey =
          splitKey[0] + '_' + splitKey[1] + '_' + key1.split('_')[0];
        if (has(value, newKey)) {
          inbound.push((value[newKey] as CartStockListItem)?.quantity);
          if (key !== userId) userKeys.push(key);
        }
      }
      if (has(value, keyToFind)) {
        outbound.push((value[keyToFind] as CartStockListItem).quantity);
        if (key !== userId) userKeys.push(key);
      }
    });
  });

  if (!isEmpty(inbound)) {
    inbound.map((f) => (sum += f));
    if (isNaN(sum)) sumOutbound = 0;
  }
  if (!isEmpty(outbound)) {
    outbound.map((f) => (sumOutbound += f));
    if (isNaN(sumOutbound)) sumOutbound = 0;
  }

  return { inboundReserved: sum, outboundReserved: sumOutbound, userKeys };
}

// TODO: replace this with getReservedAndUpcomingValues
export const findReservedValue = (
  ean_year_branch: string,
  ws: any,
  userId?: string | null
) => {
  const cartItems = ws?.state.cart?.userCart;
  const val = findNestedObj(cartItems, ean_year_branch, userId);
  return val;
};

const getValues = (
  entireObj: Record<string, CartObject>,
  keyToFind: string, //ean_dot
  warehouseId?: number,
  userId?: string | null
) => {
  let reserved = 0;
  let upcoming = 0;

  if (entireObj === undefined || isNull(entireObj))
    return { reserved, upcoming };

  Object.entries(entireObj).map(([key, value]) => {
    if (key === 'undefined') return;
    Object.entries(value.inbound).map(([inboundKey, inboundValue]) => {
      const splitKey = inboundKey.split('_');
      const branch = splitKey[0];
      const upBranch = splitKey[3];
      if (Number(branch) === warehouseId) {
        const newKey = keyToFind + '_' + warehouseId;
        reserved += (inboundValue[newKey] as CartStockListItem)?.quantity ?? 0;
      }
      if (Number(upBranch) === warehouseId) {
        const newKey = keyToFind + '_' + splitKey[0];
        upcoming += (inboundValue[newKey] as CartStockListItem)?.quantity ?? 0;
      }
    });

    Object.entries(value.outbound).map(([outboundKey, outboundValue]) => {
      const splitKey = outboundKey.split('_');
      const branch = splitKey[3];
      const upBranch = splitKey[0];
      if (Number(branch) === warehouseId) {
        const newKey = keyToFind + '_' + splitKey[0];
        reserved += (outboundValue[newKey] as CartStockListItem)?.quantity ?? 0;
      }

      if (Number(upBranch) === warehouseId) {
        const newKey = keyToFind + '_' + warehouseId;
        upcoming += (outboundValue[newKey] as CartStockListItem)?.quantity ?? 0;
      }
    });
  });

  return { reserved, upcoming };
};

export const getReservedAndUpcomingValues = (
  cartItems: Record<string, CartObject>,
  ean_year: string,
  warehouseId?: number,
  userId?: string | null
) => {
  const val = getValues(cartItems, ean_year, warehouseId, userId);
  return val;
};
