/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import { DBDeliveryTypeEnum, DBOrderStatus } from '@prisma/client';
import { DBClientSupplierOrderItemApi } from '@tyrio/dto';
import { DELIVERY_TYPE_NAMES } from '@tyrio/shared-vars';
import { tyrioUI } from '@tyrio/ui-library';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { parseEnumToArray } from '../../../../../../helpers/enum-parser';
import { InfoCard } from '../../../../components/InfoCard/InfoCard';
import StyledPagination from '../../../../components/Pagination/Pagination';
import { useDebounce } from '../../../../hooks/useDebounce';
import SelectInput, {
  getLogoByDeliveryType,
} from '../../components/SelectInput';
import { usePagination } from '../../helpers/pagination';
import OrderDetailsTable from '../OrderDetailsTable';
import OrderDetailsContainer from './OrderDetailsContainer';
import { useOrderDetails } from './useOrderDetails';

interface PurchaseOrderDetailsOrderTabProps {
  selectedOrderData: DBClientSupplierOrderItemApi['getOne']['response'] | null;
  setSelectedOrderData: (
    value: DBClientSupplierOrderItemApi['getOne']['response'] | null
  ) => void;
}

export const PurchaseOrderDetailsOrderTab = ({
  selectedOrderData,
  setSelectedOrderData,
}: PurchaseOrderDetailsOrderTabProps) => {
  const orderStatuses = parseEnumToArray(DBOrderStatus);
  const deliveryType = parseEnumToArray(DBDeliveryTypeEnum);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    // data: orderStatus,
    handleRefetchOrderDetails,
    fetching: fetchingOrderDetails,
  } = useOrderDetails(selectedOrderData?.orderId);

  const debouncedSearch = useDebounce(search, 500);

  const filteredData = (selectedOrderData?.lineItems as any)?.filter(
    (element: any) =>
      element.productName
        .toLowerCase()
        .includes(debouncedSearch.toLowerCase()) ||
      element.ean.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
      element.sku.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
      element.ipc.toLowerCase().includes(debouncedSearch.toLowerCase())
  );

  useEffect(() => {
    setPage(1);
  }, [debouncedSearch, pageSize]);

  const totalPageNumber = filteredData.length
    ? filteredData.length / pageSize -
        Math.floor(filteredData.length / pageSize) ===
      0
      ? filteredData.length / pageSize
      : Math.floor(filteredData.length / pageSize) + 1
    : 1;

  const handleChangePageSize = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
  };

  const tableData = usePagination(filteredData, pageSize, page);

  const orderDetailsContainerStyledProps = {
    width: '100%',
    borderRadius: '16px',
    border: 1,
    borderColor: 'divider',
  };

  const orderDetailsBoxStyledProps = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  };

  return (
    selectedOrderData && (
      <Grid
        sx={{
          maxHeight: 'calc(100svh - 259px)',
          paddingTop: '10px',
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={1.5}>
          <SelectInputWrapper>
            <Grid item style={{ width: '60%', paddingLeft: '12px' }}>
              <SelectInput
                valueName="orderStatus"
                attributeKey={DBOrderStatus}
                label="Order status"
                dropdownData={orderStatuses}
                selectedOrderData={selectedOrderData}
                setSelectedOrderData={setSelectedOrderData}
              />
            </Grid>
          </SelectInputWrapper>
          <SelectInputWrapper>
            <Grid item style={{ width: '60%', paddingLeft: '10px' }}>
              <SelectInput
                valueName="deliveryType"
                attributeKey={DBDeliveryTypeEnum}
                label="Delivery type"
                dropdownData={deliveryType}
                selectedOrderData={selectedOrderData}
                setSelectedOrderData={setSelectedOrderData}
              />
            </Grid>
          </SelectInputWrapper>
        </Grid>
        <Grid container sx={{ marginTop: '25px', gap: '10px' }}>
          <Grid item xs={5.8} sx={orderDetailsContainerStyledProps}>
            <OrderDetailsContainer
              leftChildren={
                <Grid sx={orderDetailsBoxStyledProps}>
                  <OrderDetailsTitleSubtitle>
                    Supplier
                  </OrderDetailsTitleSubtitle>
                  <OrderDetailsContent>
                    {selectedOrderData.supplier?.companyShortName}
                  </OrderDetailsContent>
                  <OrderDetailsTitleSubtitle>
                    Supplier ref. {selectedOrderData.orderReference}
                  </OrderDetailsTitleSubtitle>
                </Grid>
              }
              rightChildren={
                <Grid sx={orderDetailsBoxStyledProps}>
                  <OrderDetailsTitleSubtitle>
                    Reference
                  </OrderDetailsTitleSubtitle>
                  <OrderDetailsContent>
                    {selectedOrderData.reference}
                  </OrderDetailsContent>
                  <OrderDetailsTitleSubtitle>
                    {/* Supplier ref. {selectedOrderData.orderReference} */}
                  </OrderDetailsTitleSubtitle>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={5.8} sx={orderDetailsContainerStyledProps}>
            <OrderDetailsContainer
              leftChildren={
                <Grid sx={orderDetailsBoxStyledProps}>
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <OrderDetailsTitleSubtitle>
                      Delivery type
                    </OrderDetailsTitleSubtitle>
                    {selectedOrderData.metaOrderDropShipping && (
                      <Tooltip title="Drop shipping" placement="top">
                        <Chip
                          size="small"
                          label="DS"
                          sx={{
                            cursor: 'pointer',
                            bgcolor: '#454F5B',
                            color: 'white',
                          }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={getLogoByDeliveryType(
                        DBDeliveryTypeEnum[
                          selectedOrderData.deliveryType as keyof typeof DBDeliveryTypeEnum
                        ]
                      )}
                      style={{ height: '17px', marginRight: '7px' }}
                      alt=""
                    />
                    <OrderDetailsContent>
                      {DELIVERY_TYPE_NAMES[selectedOrderData.deliveryType]}
                    </OrderDetailsContent>
                  </Grid>
                  <OrderDetailsTitleSubtitle>
                    {moment(selectedOrderData.estimatedDeliveryDateFrom).format(
                      'DD.MM.YYYY'
                    )}
                    {' - '}
                    {selectedOrderData.estimatedDeliveryDateTo
                      ? moment(
                          selectedOrderData.estimatedDeliveryDateTo
                        ).format('DD.MM.YYYY')
                      : 'Unknown'}
                  </OrderDetailsTitleSubtitle>
                </Grid>
              }
              rightChildren={
                <Grid sx={orderDetailsBoxStyledProps}>
                  <OrderDetailsTitleSubtitle>
                    Delivery location
                  </OrderDetailsTitleSubtitle>
                  <OrderDetailsContent>
                    {
                      JSON.parse(
                        JSON.stringify(selectedOrderData.deliveryAddress)
                      ).city
                    }
                  </OrderDetailsContent>
                  <OrderDetailsTitleSubtitle>
                    {
                      JSON.parse(
                        JSON.stringify(selectedOrderData.deliveryAddress)
                      ).address_line_2
                    }
                  </OrderDetailsTitleSubtitle>
                </Grid>
              }
            />
          </Grid>
        </Grid>
        <InfoCard
          title="Supplier remark"
          subtitle={selectedOrderData.supplierRemark as string}
        />
        <InfoCard
          title="Internal remark"
          subtitle={selectedOrderData.remark as string}
        />

        <Grid>
          <OrderDetails>
            <Divider sx={{ marginTop: 2 }} textAlign={'left'}>
              <DividerLabel>ORDERED PRODUCTS</DividerLabel>
            </Divider>

            <SearchContainer>
              <TextField
                id="search-orders"
                variant="outlined"
                label="Search list"
                sx={{ width: '430px' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                onClick={handleRefetchOrderDetails}
                disabled={fetchingOrderDetails}
                endIcon={
                  fetchingOrderDetails ? <CircularProgress size={15} /> : null
                }
                hidden={true}
              >
                Sync status with supplier
              </Button>
              {/*<Button disabled endIcon={<FileUploadOutlinedIcon />}>*/}
              {/*  EXPORT*/}
              {/*</Button>*/}
            </SearchContainer>

            <OrderDetailsTable
              data={tableData || []}
              page={page}
              pageSize={pageSize}
              meta={selectedOrderData?.orderLineItemMeta as any}
              setSelectedOrderData={setSelectedOrderData}
              selectedOrderData={selectedOrderData}
            />

            <Footer>
              <PaginationContainer>
                <StyledPagination
                  totalPageNumber={totalPageNumber}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  selectedOrderData={null}
                  handleChangePageSize={handleChangePageSize}
                  menuItems={[10, 25, 50]}
                />
              </PaginationContainer>

              <TextField
                id="internal-remark"
                variant="outlined"
                label="Internal remark"
                fullWidth
                style={{ marginTop: '16px' }}
                value={selectedOrderData.remark}
                onChange={(e) => {
                  selectedOrderData &&
                    setSelectedOrderData({
                      ...selectedOrderData,
                      remark: e.target.value,
                    });
                }}
              />
            </Footer>
          </OrderDetails>
        </Grid>
      </Grid>
    )
  );
};

const OrderDetailsTitleSubtitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const OrderDetailsContent = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const OrderDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${tyrioUI.spacing.l}px;
`;

const PaginationContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DividerLabel = styled.label`
  color: ${tyrioUI.colors.darkGrey};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const SelectInputWrapper = styled.div`
  width: 50%;
  padding-top: 12px;
`;

// export default PurchaseOrderDetailsOrderTab;
