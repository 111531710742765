import api from '@tyrio/api-factory';
import { DBDeliveryTypesApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';

export const useUpdateActiveDeliveryMethod = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['change_delivery_method_status'],
    (body: DBDeliveryTypesApi['changeStatus']['requestBody']) =>
      api.fetch<DBDeliveryTypesApi['changeStatus']>(
        'change_delivery_method_status',
        {
          ...body,
        }
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_delivery_method');
        queryClient.refetchQueries('get_client_delivery_types');

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },

      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateActiveStatusDeliveryMethod: mutate };
};
