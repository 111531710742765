import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { DBCustomPriceCatalog } from '@prisma/client';
import { CustomPriceMeta, DBProductApi } from '@tyrio/dto';
import { ProductDescription } from '@tyrio/products';
import _ from 'lodash';
import { useState } from 'react';
import { currency } from '../price-calculation/helper/parser';
import { useUpdatePrice } from './queries/update-price';

interface ICheckRulesModal {
  open: boolean;
  close: () => void;
  item?: DBCustomPriceCatalog;
}

export default function UpdatePrice(props: ICheckRulesModal) {
  const { open, close, item } = props;

  //STATES
  const [newPrice, setNewPrice] = useState<number | null>(null);
  const [errorText, setErrorText] = useState('');

  //MUTATIONS
  const { updatePrice } = useUpdatePrice({ setErrorText });

  //FUNCTIONS
  const onClose = () => {
    setNewPrice(null);
    setErrorText('');
    updatePrice.reset();
    close();
  };

  const error = () => {
    return (
      <Alert severity="error" sx={{ marginTop: '10px' }}>
        {errorText}
      </Alert>
    );
  };

  return (
    <Grid>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={onClose}
      >
        <Container>
          <Grid>
            <Header>
              <Typography variant="h6" component="h2">
                Update custom price
              </Typography>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </Header>
          </Grid>
          {item && (
            <Grid>
              {_.isEmpty(item.productMeta) ? (
                <Typography>EAN: {item.ean}</Typography>
              ) : (
                <Grid>
                  {item?.productMeta && (
                    <ProductDescription
                      product={
                        item?.productMeta as unknown as DBProductApi['getOne']['response']
                      }
                    />
                  )}
                </Grid>
              )}
              {updatePrice.status === 'idle' && (
                <Grid>
                  {item.calculatedPrice && (
                    <Grid
                      sx={{ display: 'flex', gap: '7px', marginTop: '15px' }}
                    >
                      <FormControl fullWidth>
                        <InputLabel>Calculated price</InputLabel>
                        <OutlinedInput
                          endAdornment={
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          }
                          label="Calculated price"
                          value={item.calculatedPrice}
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid sx={{ display: 'flex', gap: '7px', marginTop: '15px' }}>
                    <FormControl fullWidth>
                      <InputLabel>Current price</InputLabel>
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        }
                        label="Current price"
                        value={item.price}
                        disabled
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>New price</InputLabel>
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        }
                        label="New price"
                        value={newPrice}
                        type="number"
                        onChange={(e) => {
                          setNewPrice(Number(e.target.value));
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Button
                    variant="contained"
                    color="info"
                    fullWidth
                    sx={{ marginTop: '10px' }}
                    onClick={() => {
                      if (newPrice === 0 || newPrice === null) {
                        setErrorText('Price must be greater than 0!');
                        return;
                      }
                      updatePrice.mutate({
                        ean: item.ean,
                        price: newPrice,
                        meta: item.meta as unknown as CustomPriceMeta,
                      });
                    }}
                  >
                    UPDATE PRICE
                  </Button>
                  {!_.isEmpty(errorText) && error()}
                </Grid>
              )}
              {updatePrice.status === 'loading' && (
                <Grid
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <CircularProgress size={25} />
                </Grid>
              )}
              {updatePrice.status === 'success' && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                  sx={{ marginTop: '10px' }}
                >
                  Price successfully updated!
                </Alert>
              )}
            </Grid>
          )}
        </Container>
      </Modal>
    </Grid>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 450px;
  background-color: white;
  box-shadow: 24;
  padding: 16px;
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
