import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function getGundlachSpecialMarkingsAndModelName(
  input: RawImport,
  rule: TRule
): Partial<UnfinishedData> {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'getGundlachSpecialMarkingsAndModelName requires rule to be of type TGlobalOperateRule'
    );

  const inputValues = input['E'].replace('*', ' * ');

  return genericGenerateSpecialMarkings(inputValues, () => false, false);
}
