import { TRule } from '../../typings';

export const INTERSPRINT_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: 'c.eancode',
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.itemcode',
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['brand description']",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['nett-price']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
