import styled from '@emotion/styled/macro';
import { CustomModal } from '../../../../components/Modal/Modal';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useWorkOrderCtx } from '../../../../context/WorkOrderContext';
import { ModalContent } from './ModalContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tyrioIcons } from '@tyrio/ui-library';
import { IModalData } from '@tyrio/dto';

export const LineItemsModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { modalData } = useWorkOrderCtx();

  const component = () => {
    return (
      <div>
        {modalData.map((data) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {data.orderNumber}
            </AccordionSummary>
            <AccordionDetails>
              <ModalContent
                handleClose={handleClose}
                modalData={data as IModalData}
              ></ModalContent>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };

  return (
    <div style={{ background: 'white' }}>
      <CustomModal
        open={open}
        handleClose={handleClose}
        child={
          <Wrapper>
            <IconWrapper>
              <img
                src={tyrioIcons.XIcon}
                alt="close"
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
              />
            </IconWrapper>
            {modalData?.length > 1 ? (
              component()
            ) : (
              <ModalContent
                handleClose={handleClose}
                modalData={modalData?.[0] as IModalData}
              ></ModalContent>
            )}
          </Wrapper>
        }
      />
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
