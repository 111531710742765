import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { REIFENMUELLER_PRICELIST_RULESET } from './reifenmueller/pricelist';
import { REIFENMUELLER_STOCKLIST_RULESET } from './reifenmueller/stocklist';
import { REIFENMUELLER_CATALOG_RULESET } from './reifenmueller/catalog';
// import { TRule } from '../typings';

export const REIFENMUELLER_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: REIFENMUELLER_CATALOG_RULESET,
  pricelistRuleset: REIFENMUELLER_PRICELIST_RULESET,
  stockRuleset: REIFENMUELLER_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
      productMeta: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'REIFEN MUELLER',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    'Herstellerk�rzel': {
      'BF-GOODRICH': 'BFGOODRICH',
      'BF-Goodrich': 'BFGOODRICH',
    },
    'VK-Preis': {
      ',': '.',
    },
    'Querschnittsverh�ltnis': {
      ',': '.',
    },
    'Profilcode/Modellcode': {
      'Nova-Force 4�4 HP': 'Nova-Force 4x4 HP',
    },
    Mittenlochkreis: {
      ',': '.',
    },
    Lochkreisdurchmesser: {
      ',': '.',
    },
    Einpresstiefe: {
      ',': '.',
    },
    Breite: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const currentYear = parseFloat(String(new Date().getFullYear()).slice(-2));

    // console.log(json);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !(
            item['Dot-Nr.'].toUpperCase().includes('DOT') &&
            !item['Dot-Nr.'].toUpperCase().includes(currentYear) &&
            !item['Dot-Nr.'].toUpperCase().includes(currentYear - 1) &&
            !item['Dot-Nr.'].toUpperCase().includes(currentYear - 2)
          ) &&
          !item['DEMO'].toUpperCase().includes('DEMO') &&
          !item['Gebraucht %'].toUpperCase().includes('GEBRAUCHT') &&
          item['Prim�re EAN'] !== '8681212850014'
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const currentYear = parseFloat(String(new Date().getFullYear()).slice(-2));

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !(
            item['Dot-Nr.'].toUpperCase().includes('DOT') &&
            !item['Dot-Nr.'].toUpperCase().includes(currentYear) &&
            !item['Dot-Nr.'].toUpperCase().includes(currentYear - 1) &&
            !item['Dot-Nr.'].toUpperCase().includes(currentYear - 2)
          ) &&
          !item['DEMO'].toUpperCase().includes('DEMO') &&
          !item['Gebraucht %'].toUpperCase().includes('GEBRAUCHT') &&
          item['Prim�re EAN'] !== '8681212850014' &&
          item['Herstellerk�rzel'] !== 'Deli Tire'
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
