import styled from '@emotion/styled';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';

interface CartHeaderProps {
  isVatIncluded?: boolean;
  setIsVatIncluded?: (a: boolean) => void;
  onDelete: () => void;
  text: string;
  shouldShowVat?: boolean;
  showEmptyCart?: boolean;
}

export const CartHeader = ({
  isVatIncluded,
  setIsVatIncluded,
  onDelete,
  text,
  shouldShowVat = false,
  showEmptyCart = true,
}: CartHeaderProps) => {
  return (
    <HeadingWrapper>
      <TitleWrapper>
        <WmsIcon icon={wmsIcons.cart} color="info" />
        <Typography variant="subtitle1" fontSize={18}>
          {text}
        </Typography>
      </TitleWrapper>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {shouldShowVat && (
          <FormControlLabel
            control={
              <Switch
                data-cy="switch"
                color="info"
                onChange={() => {
                  setIsVatIncluded && setIsVatIncluded(!isVatIncluded);
                }}
                checked={isVatIncluded}
                disabled={false}
              />
            }
            label={isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          />
        )}
        {showEmptyCart && (
          <DeleteWrapper onClick={onDelete}>
            <Text id="delete_text">Empty cart</Text>
            <WmsIconStyled icon={wmsIcons.bin} id="delete_icon" />
          </DeleteWrapper>
        )}
      </div>
    </HeadingWrapper>
  );
};

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const Text = styled(Typography)`
  color: #b9c4ce;
  font-size: 14px;

  &:hover {
    color: #db1e1e;
  }
`;

export const WmsIconStyled = styled(WmsIcon)`
  width: 16px;
  height: 16px;
  color: #b9c4ce;

  &:hover {
    color: #db1e1e;
  }
`;

export const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  gap: 14px;

  &:hover {
    #delete_text,
    #delete_icon {
      color: #db1e1e;
    }
  }
`;
