import { DBProductCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBProductApi, DBProductCategoryApi } from '@tyrio/dto';
import { useQuery } from 'react-query';
import { DropdownValues } from '../constants/service.form';
import { differenceWith, isEqual } from 'lodash';

export const useGetSubcategoryByCategoryId = (
  categoryId: string,
  setSubcategories: (a: DropdownValues[]) => void,
  oldSubcategories: DropdownValues[]
) => {
  const { data, refetch } = useQuery(
    ['get_subcategories', categoryId],
    () =>
      api.fetch<DBProductCategoryApi['getSubcategory']>(`get_subcategories`, {
        categoryId,
      }),
    {
      enabled: !!categoryId,
      onSuccess: (data: DBProductCategoryApi['getSubcategory']['response']) => {
        const items = data.map((item) => ({
          label: item.name,
          value: item.id.toString(),
        }));

        const result = differenceWith(items, oldSubcategories, (obj1, obj2) =>
          isEqual(obj1, obj2)
        );

        setSubcategories(result);
      },
    }
  );
  return { subcategories: data, refetch };
};

export const useGetMainCategories = (
  setProductCategories: (a: DropdownValues[]) => void
) => {
  useQuery(
    ['main_categories_list'],
    () => api.fetch<DBProductCategoryApi['list']>('main_categories'),
    {
      onSuccess: (data: DBProductCategory[]) => {
        const items = data.map((item) => ({
          label: item.name,
          value: item.id.toString(),
        }));

        setProductCategories(items);
      },
    }
  );
};

export const useGetRims = (
  categoryId: string[],
  setRims: (a: DropdownValues[]) => void,
  oldRims: DropdownValues[]
) => {
  useQuery(
    ['get_rim_sizes', categoryId],
    () =>
      api.fetch<DBProductApi['getRimSizes']>(`get_rim_sizes`, {
        categoryId,
      }),
    {
      enabled: !!categoryId && categoryId.length > 0,
      onSuccess: (data: DBProductApi['getRimSizes']['response']) => {
        const items = data.map((item) => ({
          label: item.rimDiameter,
          value: item.rimDiameter,
        }));

        const result = differenceWith(items, oldRims, (obj1, obj2) =>
          isEqual(obj1, obj2)
        );

        setRims(result);
      },
    }
  );
};
