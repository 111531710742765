import * as z from "zod"
import { CompleteDBDeliveryTypes, RelatedDBDeliveryTypesModel, CompleteDBClientShippingCourier, RelatedDBClientShippingCourierModel } from "./index"

export const DBClientDeliveryCouriersModel = z.object({
  id: z.string(),
  deliveryTypeId: z.string(),
  clientCourierId: z.string(),
})

export interface CompleteDBClientDeliveryCouriers extends z.infer<typeof DBClientDeliveryCouriersModel> {
  deliveryType: CompleteDBDeliveryTypes
  clientCourier: CompleteDBClientShippingCourier
}

/**
 * RelatedDBClientDeliveryCouriersModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientDeliveryCouriersModel: z.ZodSchema<CompleteDBClientDeliveryCouriers> = z.lazy(() => DBClientDeliveryCouriersModel.extend({
  deliveryType: RelatedDBDeliveryTypesModel,
  clientCourier: RelatedDBClientShippingCourierModel,
}))
