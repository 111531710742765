import api from '@tyrio/api-factory';
import { DBFeatureConfigApi } from '@tyrio/dto';
import { useQuery } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollToTop/scrollToTop';
import { RootRouter } from './routers/RootRouter';

export const RootComponent = () => {
  useQuery('featureConfig', () =>
    api.fetch<DBFeatureConfigApi['list']>('feature_config')
  );

  return (
    <Router>
      <ScrollToTop />
      <RootRouter />
    </Router>
  );
};
