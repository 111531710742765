import { DBLanguage } from '@prisma/client';

export const languageOptions: { label: string; value: DBLanguage }[] = [
  {
    label: 'Bosnian',
    value: 'Bosnian',
  },
  {
    label: 'Croatian',
    value: 'Croatian',
  },
  {
    label: 'English',
    value: 'English',
  },
];

export const languagesList: DBLanguage[] = languageOptions.map((l) => l.value);
