/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';

import { Autocomplete, FormControl, MenuItem, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { PriceComparisonContext } from '../context';
import { orderBy } from 'lodash';

export interface TyrioSelectInputOption {
  label: string;
  value: string | number;
  mainCategory?: boolean;
  parentCategoryId?: number;
}

interface CategoryDropdownProps {
  id: string;
  error?: string;
}
export const CategoryInput = ({ id, error }: CategoryDropdownProps) => {
  const ctx = useContext(PriceComparisonContext);

  const { data: mainCategories } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  );

  const { mainCategoryValues, subCategoryValues } = (
    mainCategories ?? []
  ).reduce<{
    mainCategoryValues: TyrioSelectInputOption[];
    subCategoryValues: TyrioSelectInputOption[];
  }>(
    (acc, category) => {
      if (category.parent_category_id === null) {
        acc.mainCategoryValues.push({
          label: category.name,
          value: category.id,
          mainCategory: !category.parent_category_id,
        });
      } else {
        acc.subCategoryValues.push({
          label: category.name,
          value: category.id,
          mainCategory: !category.parent_category_id,
          parentCategoryId: category.parent_category_id,
        });
      }
      return acc;
    },
    { mainCategoryValues: [], subCategoryValues: [] }
  );

  const subcategory = useMemo(() => {
    const id = ctx.data.dimensions?.category?.value;
    return subCategoryValues.filter(
      (f: TyrioSelectInputOption) => f.parentCategoryId === id
    );
  }, [ctx.data.dimensions?.category?.value, subCategoryValues]);

  return (
    <Div>
      <FormControl>
        <Autocomplete
          fullWidth
          disablePortal
          id={id}
          options={orderBy(mainCategoryValues, 'label')}
          onChange={(e, x) => {
            ctx.setInputValue({
              dimensions: { ...ctx.data.dimensions, category: x },
            });
            ctx.setInputValue({
              dimensions: { ...ctx.data.dimensions, subcategory: null },
            });
          }}
          value={ctx.data.dimensions?.category ?? null}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error}
              label="Category"
            />
          )}
          renderOption={(item, el) => <MenuItem {...item}>{el.label}</MenuItem>}
        />
      </FormControl>

      <FormControl>
        <Autocomplete
          fullWidth
          disablePortal
          id={'subcategory'}
          options={orderBy(subcategory, 'label')}
          onChange={(e, x) => {
            ctx.setInputValue({
              dimensions: { ...ctx.data.dimensions, subcategory: x },
            });
          }}
          value={ctx.data?.dimensions?.subcategory ?? null}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error}
              label="Subcategory"
            />
          )}
          renderOption={(item, el) => <MenuItem {...item}>{el.label}</MenuItem>}
        />
      </FormControl>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
