import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { KUMHO_CATALOG_RULESET } from './kumho/catalog';
import { KUMHO_PRICELIST_RULESET } from './kumho/pricelist';
import { KUMHO_STOCKLIST_RULESET } from './kumho/stocklist';

export const KUMHO_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: KUMHO_CATALOG_RULESET,
  pricelistRuleset: KUMHO_PRICELIST_RULESET,
  stockRuleset: KUMHO_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    WEIGHT: {
      ',': '.',
    },
    WIDTH_MM: {
      ',': '.',
    },

    ASPECT_RATIO: { ',': '.' },
    RIM_INCH: { ',': '.' },
    NOISE_PERFORMANCE: { 't.b.a.': '' },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: {
    model: {},
    product: {},
  },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      dirtyFields: '{}',
      dataSource: 'KUMHO EDI PRICAT',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'KUMHO EDI PRICAT',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    const list = json.filter((item) =>
      ['C0', 'L0', 'L4', 'T0', 'LS'].includes(item.VEHICLE_TYPE)
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
