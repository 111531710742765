import styled from '@emotion/styled/macro';
import { CircularProgress, Typography } from '@mui/material';
import { tyrioIcons, tyrioUI } from '@tyrio/ui-library';
import { WheelDetailsHeader } from './WheelDetails/WheelDetailsHeader';
import { useGetWheelDetails } from './query/get-wheel-details';
import {
  get,
  isEmpty,
  isNull,
  isUndefined,
  nth,
  sortBy,
  startCase,
  toString,
  intersection,
} from 'lodash';
import { useVehicleCtx } from '../../../../context/VehicleContext';
import {
  Dropdown,
  EtDropdown,
  ProductDropdown,
} from './WheelDetails/ProductDropdown';
import { useContext, useEffect, useMemo, useState } from 'react';
import DetailsStock from '../../steps/Details/DetailsStock';
import { IProduct, PhotoShape } from '@tyrio/dto';
import PhotoIcon from '@mui/icons-material/Photo';
import { COLOR_GROUP } from '@tyrio/shared-vars';
import { model_colors } from './constants/color';
import { RecommendedWheelSizes } from './RecommendedSizes';
import { POSContext } from '../../../../context/POSContext';
import { BlockRounded } from '@mui/icons-material';

export const WheelDetails = () => {
  // CONTEXT
  const {
    selectedWheel,
    selectedProductModel,
    setSelectedProductModel,
    filter,
    setSelectedProduct,
    setDetailsFilter,
    detailsFilter,
    selectedProduct,
    apiData,
    setSelectedEt,
  } = useVehicleCtx();

  const { setBadgeContent } = useContext(POSContext);

  // DATA

  const selectedRim = useMemo(() => {
    return detailsFilter.rimDiameter;
  }, [detailsFilter.rimDiameter]);

  const rimSizes = useMemo(() => {
    return sortBy(get(selectedProductModel, 'availableRimSizes', []));
  }, [selectedProductModel]);

  const isCartVisible = selectedProduct !== null;

  const [selectedCustomColor, setSelectedCustomColor] = useState('');

  // QUERY
  const {
    data: wheelDetails,
    isFetched,
    refetch: refetchWheelDetails,
    showLoader,
  } = useGetWheelDetails(
    selectedWheel?.model.modelName ?? '',
    selectedWheel?.model.brand ?? '',
    true,
    setSelectedProductModel,
    apiData?.pcd,
    selectedWheel?.model?.color,
    apiData?.et ?? [],
    apiData?.rimDiameter ?? []
  );

  // FUNCTIONS
  const checkIfEmpty = (val: string[] | null) => {
    return isEmpty(val) || isNull(val);
  };

  // HOOKS
  const menuItems = useMemo(() => {
    const products: IProduct[] = [];

    const isEmptyWidth = checkIfEmpty(filter.width);
    const isEmptyCb = checkIfEmpty(filter.cb);

    selectedProductModel?.DBProduct.forEach((product) => {
      const { rimDiameter, width, cb } = product;

      if (rimDiameter !== nth(detailsFilter.rimDiameter, 0)) return;

      const widthMatch =
        !isEmptyWidth && filter.width?.includes(toString(width));

      const cbMatch = !isEmptyCb && filter.cb?.includes(toString(cb));

      if (
        (widthMatch && cbMatch) ||
        (widthMatch && isEmptyCb) ||
        (cbMatch && isEmptyWidth) ||
        (isEmptyWidth && isEmptyCb)
      ) {
        products.push(product);
      }
    });

    return products;
  }, [
    detailsFilter.rimDiameter,
    filter.cb,
    filter.width,
    selectedProductModel?.DBProduct,
  ]);

  useEffect(() => {
    if (
      filter.rimDiameter?.length === 1 &&
      isEmpty(detailsFilter.rimDiameter)
    ) {
      setDetailsFilter({
        ...detailsFilter,
        rimDiameter: [...filter.rimDiameter],
      });
    }
  }, [detailsFilter, filter.rimDiameter, setDetailsFilter]);

  useEffect(() => {
    refetchWheelDetails();
  }, [refetchWheelDetails, selectedWheel]);

  const resetDetailsTab = () => {
    setSelectedProduct(null);
    setSelectedEt(null);
    setBadgeContent((prevState) => ({
      ...prevState,
      SUPPLIERS: 0,
      BRANCHES: 0,
    }));
  };

  return (
    <ContentWrapper>
      <MainContentWrapper>
        <WheelDetailsHeader
          modelName={selectedWheel?.model.modelName ?? ''}
          brand={selectedWheel?.model.brand ?? ''}
          pcd={get(apiData, 'pcd', '')}
          cb={get(apiData, 'cb', '')}
          et={apiData?.et.sort() ?? []}
          image={selectedProductModel?.photos}
        />

        <Wrapper shouldCenter={showLoader}>
          {showLoader ? (
            <CircularProgress />
          ) : (
            <>
              <Details>
                <Typography
                  fontWeight={400}
                  color={`${tyrioUI.colors.black.B70}`}
                  fontSize={12}
                >
                  Selected color
                </Typography>
                <Typography fontWeight={400} fontSize={14}>
                  {startCase(selectedProductModel?.color ?? '')}
                </Typography>
              </Details>

              <ImagesWrapper>
                {isFetched &&
                  !isUndefined(wheelDetails) &&
                  wheelDetails.map((item, idx) => (
                    <ImageWrapper
                      key={item.uid + idx}
                      selected={item.uid === selectedProductModel?.uid}
                      onClick={() => {
                        setSelectedProductModel(item);
                        resetDetailsTab();

                        if (
                          isEmpty(
                            intersection(item.availableRimSizes, selectedRim)
                          ) ||
                          isEmpty(item.availableRimSizes)
                        ) {
                          setDetailsFilter({ rimDiameter: [] });
                        }
                      }}
                    >
                      {!isEmpty(item.photos) ? (
                        <Img
                          src={
                            (item?.photos as PhotoShape[])[0]?.url ??
                            tyrioIcons.tyreImg
                          }
                          alt="wheel"
                        />
                      ) : (
                        <PhotoIcon
                          color="disabled"
                          sx={{ height: '80px', width: '80px' }}
                        />
                      )}

                      <Typography
                        fontWeight={400}
                        color={`${tyrioUI.colors.black.B100}`}
                        fontSize={12}
                        textAlign={'center'}
                      >
                        {startCase(item?.color ?? '')}
                      </Typography>
                    </ImageWrapper>
                  ))}
              </ImagesWrapper>

              {selectedProductModel?.colorGroup === COLOR_GROUP.custom && (
                <DropdownWrapper>
                  <Dropdown
                    label="Select custom color"
                    value={selectedCustomColor}
                    onChange={(value) => setSelectedCustomColor(value)}
                    menuItems={
                      model_colors[selectedWheel?.model?.brand ?? ''] ?? []
                    }
                    width="100%"
                  />
                </DropdownWrapper>
              )}

              <FilterWrapper style={{ minHeight: '78px' }}>
                <Typography
                  fontWeight={400}
                  color={`${tyrioUI.colors.black.B70}`}
                  fontSize={12}
                >
                  Choose rim diameter
                </Typography>

                <RimWrapper>
                  {rimSizes.length > 0 ? (
                    rimSizes.map((r: string | null, idx: number) => (
                      <RimCard
                        key={idx}
                        selected={
                          detailsFilter.rimDiameter?.length === 1 &&
                          detailsFilter.rimDiameter?.includes(r ?? '')
                        }
                        onClick={() => {
                          if (detailsFilter.rimDiameter[0] !== r) {
                            resetDetailsTab();
                          }

                          setDetailsFilter({
                            ...detailsFilter,
                            rimDiameter: [r ?? ''],
                          });
                        }}
                      >
                        {r + "''"}
                      </RimCard>
                    ))
                  ) : (
                    <BlockRounded color="warning" />
                  )}
                </RimWrapper>
              </FilterWrapper>

              {selectedRim && !isEmpty(selectedRim) && (
                <FilterWrapper>
                  <Typography
                    fontWeight={400}
                    color={`${tyrioUI.colors.black.B70}`}
                    fontSize={14}
                  >
                    Recommended size and wheel offset
                  </Typography>
                  <RecommendedWheelSizes
                    apiData={apiData}
                    selectedRimSize={selectedRim}
                  />
                </FilterWrapper>
              )}

              {selectedProductModel && !isEmpty(selectedRim) && (
                <DropdownWrapper>
                  <ProductDropdownWrapper
                    fullWidth={
                      (selectedProduct?.et &&
                        selectedProduct?.et?.length <= 1) ??
                      true
                    }
                  >
                    <ProductDropdown products={menuItems ?? []} />
                    {selectedProduct && (
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={tyrioUI.colors.black.B70}
                      >
                        EAN: {selectedProduct?.ean} SKU: {selectedProduct?.sku}
                      </Typography>
                    )}
                  </ProductDropdownWrapper>
                  <EtDropdown />
                </DropdownWrapper>
              )}
            </>
          )}
        </Wrapper>
      </MainContentWrapper>

      {isCartVisible && <DetailsStock stockType="CURRENT" isWheels={true} />}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  height: 85vh;
`;

const MainContentWrapper = styled.div`
  border-radius: 8px;
  border: 1px dashed ${tyrioUI.colors.black.B40};
  padding: 16px;
  margin-bottom: 16px;
`;

const Wrapper = styled.div<{ shouldCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ shouldCenter }) => (shouldCenter ? 'center' : null)};
  align-items: ${({ shouldCenter }) =>
    shouldCenter ? 'center' : 'space-between'};
  min-height: 308px;
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 0;
  gap: 16px;
`;

const ImageWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${tyrioUI.colors.lightBlue};
  border-color: ${({ selected }) => !selected && `white`};
  border-radius: 8px;
  padding: 6px 10px;
  max-width: 110px;

  &:hover {
    border-bottom: 1px solid ${tyrioUI.colors.lightBlue};
    border-radius: ${({ selected }) => !selected && '0'};
  }
`;

const Img = styled.img`
  object-fit: contain;
  width: 80px;
  height: 80px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-top: 16px;
  align-items: flex-end;
`;

const RimWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const RimCard = styled.div<{ selected?: boolean }>`
  display: flex;
  border: 1px solid ${tyrioUI.colors.black.B30};
  border-radius: 4px;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${({ selected }) => selected && 'white'};
  background: ${({ selected }) => selected && `${tyrioUI.colors.lightBlue}`};
  border-color: ${({ selected }) => selected && `${tyrioUI.colors.lightBlue}`};
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 16px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 16px 0;
`;

const ProductDropdownWrapper = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '80%')};
`;
