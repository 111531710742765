import api from '@tyrio/api-factory';
import { DBCustomPriceCatalogApi } from '@tyrio/dto';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

interface AddPriceProps {
  setErrorText: (val: string) => void;
}

export const useAddPrice = (props: AddPriceProps) => {
  const { setErrorText } = props;
  const queryClient = useQueryClient();

  const addPrice = useMutation(
    (req: DBCustomPriceCatalogApi['create']['request']) =>
      api.fetch<DBCustomPriceCatalogApi['create']>('cpc_add_price', {
        ...req,
      }),
    {
      mutationKey: 'cpc_add_price',
      onError: (error) => {
        const errorData = error as AxiosError<unknown>;
        setErrorText((errorData.response?.data as ErrorProps)?.error?.name);
      },
      onSuccess: () => {
        queryClient.refetchQueries('cpc_get_catalog');
      },
    }
  );

  return { addPrice };
};

interface ErrorProps {
  error: { name: string };
}
