/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * We need to be able to control how we split the special markings string since
 * we expect that every entry there is separated by a blank space, but some special
 * markings (like Run Flat) have a space in the name, so we have to handle it
 */

import { UnfinishedData } from '@tyrio/dto';
import { get as _get } from 'lodash';

import { RFD_KEYS, SPECIAL_MARKING } from '@tyrio/shared-vars';

export const splitSpecialMarkingString = (s: string) => {
  const runFlatHash = '112233SPLIT112233';

  const split = s
    .replace('Run Flat', runFlatHash)
    .split(' ')
    .map((item) => {
      if (item === runFlatHash) return item.replace(runFlatHash, 'Run Flat');
      return item;
    });

  return split as Array<keyof typeof SPECIAL_MARKING>;
};

export const genericGenerateSpecialMarkings = (
  input: string,
  runFlatCheckFunction = () => true,
  generateModelName = false
): Partial<UnfinishedData> => {
  // Check if 'input' is undefined or null - AS 04.10.2023.
  if (input === undefined || input === null) {
    // Handle the case where 'input' is undefined or null - AS 04.10.2023.
    return {
      product: {
        specialMarking: '',
        rfd: '',
        productMeta: {},
      },
      model: {
        productMeta: {},
        sidewall: '',
      } as any,
    };
  }

  const inputValues = input.replace('*', ' * ').split(' ');

  const modelNameArray: string[] = [];
  const rfdMarkingsArray: string[] = [];
  const specialMarkingsArray: string[] = [];

  const SPECIAL_MARKINGS_CONTAINING_BRACKETS = ['(+)'];

  inputValues
    .reverse()
    .filter((x) => !!x)
    .forEach((unsafeInputVal: string) => {
      let inputVal = unsafeInputVal;
      if (
        unsafeInputVal.includes('(') &&
        !SPECIAL_MARKINGS_CONTAINING_BRACKETS.includes(unsafeInputVal)
      ) {
        const cleanedOutValue = unsafeInputVal
          .replace('(', '')
          .replace(')', '');
        const existsInSpecialMarkingList = _get(
          SPECIAL_MARKING,
          cleanedOutValue
        );
        if (existsInSpecialMarkingList) {
          inputVal = cleanedOutValue;
        }
      }

      const markingMatch =
        _get(SPECIAL_MARKING, inputVal) ||
        _get(SPECIAL_MARKING, inputVal.toUpperCase()) ||
        _get(SPECIAL_MARKING, inputVal.toLowerCase());

      const rfdMatch =
        _get(RFD_KEYS, inputVal) ||
        _get(RFD_KEYS, inputVal.toUpperCase()) ||
        _get(RFD_KEYS, inputVal.toLowerCase());

      if (markingMatch) {
        specialMarkingsArray.push(markingMatch.name);
      } else if (rfdMatch) {
        rfdMarkingsArray.push(rfdMatch.name);
      } else {
        modelNameArray.unshift(inputVal);
      }
    });

  let hasRunFlatValue = false;

  const productMeta: {
    specialMarking: Record<string, any>;
    rfd: Record<string, any>;
  } = {
    specialMarking: {},
    rfd: {},
  };

  const modelMeta: Record<string, any> = {};
  specialMarkingsArray.forEach((m) => {
    const M = m.toUpperCase();
    const marking =
      _get(SPECIAL_MARKING, M) ||
      _get(SPECIAL_MARKING, M.toUpperCase()) ||
      _get(SPECIAL_MARKING, M.toLowerCase());

    const types = marking?.type || [];

    if (marking?.type.includes('sidewall')) {
      modelMeta[M] = marking;
    } else {
      productMeta.specialMarking[M] = marking;
    }

    if (types.includes('runFlat')) {
      hasRunFlatValue = true;
    }
  });

  rfdMarkingsArray.forEach((r) => {
    const R = r.toUpperCase();
    productMeta.rfd[R] = _get(RFD_KEYS, R);
  });

  if (!hasRunFlatValue && runFlatCheckFunction()) {
    specialMarkingsArray.push('Run Flat');
  }

  const sidewallString = Object.keys(_get(modelMeta, 'sidewall', {})).join(' ');

  const output = {
    product: {
      specialMarking: specialMarkingsArray.join(' '),
      rfd: rfdMarkingsArray.join(' '),
      productMeta,
    },
    model: {
      productMeta: modelMeta,
      sidewall: sidewallString,
    } as any,
  };

  if (generateModelName) {
    output.model.modelName = modelNameArray.join(' ');
  }

  return output;
};
