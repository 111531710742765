import * as z from "zod"
import { CompleteDBService, RelatedDBServiceModel } from "./index"

export const DBServiceCodeModel = z.object({
  id: z.string(),
  code: z.number().int(),
})

export interface CompleteDBServiceCode extends z.infer<typeof DBServiceCodeModel> {
  service: CompleteDBService[]
}

/**
 * RelatedDBServiceCodeModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBServiceCodeModel: z.ZodSchema<CompleteDBServiceCode> = z.lazy(() => DBServiceCodeModel.extend({
  service: RelatedDBServiceModel.array(),
}))
