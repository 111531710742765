import { useReducer } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  SetFieldValue,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';

import styled from '@emotion/styled/macro';
import { Box, Button, Grid, Typography } from '@mui/material';

import { getSwitchField, getWorkingHoursField } from '../../../form-helper';

export interface WorkingTimeProps {
  index: number;
  errors: FieldErrors;
  register: UseFormRegister<FieldValues>;
  control: Control;
  getValues: UseFormGetValues<FieldValues>;
  setValue: SetFieldValue<FieldValues>;
}

const WorkingTime = ({
  index,
  errors,
  control,
  getValues,
  setValue,
}: WorkingTimeProps) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const days = [
    { label: 'Monday', key: 'monday' },
    { label: 'Tuesday', key: 'tuesday' },
    { label: 'Wednesday', key: 'wednesday' },
    { label: 'Thursday', key: 'thursday' },
    { label: 'Friday', key: 'friday' },
    { label: 'Saturday', key: 'saturday' },
    { label: 'Sunday', key: 'sunday' },
  ];

  const applyMondayHoursToAll = () => {
    const mondayValues = getValues(`boxes[${index}].workingTime.monday`);

    days.forEach(({ key }) => {
      if (key !== 'monday') {
        setValue(
          `boxes[${index}].workingTime.${key}.work.start`,
          mondayValues.work.start
        );
        setValue(
          `boxes[${index}].workingTime.${key}.work.end`,
          mondayValues.work.end
        );
        setValue(
          `boxes[${index}].workingTime.${key}.break.start`,
          mondayValues.break.start
        );
        setValue(
          `boxes[${index}].workingTime.${key}.break.end`,
          mondayValues.break.end
        );
        setValue(
          `boxes[${index}].workingTime.${key}.isOpen`,
          mondayValues.isOpen
        );
      }

      forceUpdate();
    });
  };

  return (
    <WorkingTimeContainer>
      <HeaderContainer>
        <DefaultWorkingHours>Default working hours</DefaultWorkingHours>
        <SameDaysButton onClick={applyMondayHoursToAll}>
          All days have same hours
        </SameDaysButton>
      </HeaderContainer>

      {days.map((day) => (
        <DayRow key={day.key}>
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={2} xs={3}>
              {getSwitchField(
                day.label,
                `boxes[${index}].workingTime.${day.key}.isOpen`,
                errors,
                control,
                {},
                { color: 'info' }
              )}
            </Grid>

            {getValues(`boxes[${index}].workingTime.${day.key}.isOpen`) ? (
              <Grid item lg={10} xs={9}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={6} xs={6}>
                    {getWorkingHoursField(
                      'Working hours',
                      control,
                      `boxes[${index}].workingTime.${day.key}.work.start`,
                      `boxes[${index}].workingTime.${day.key}.work.end`,
                      {}
                    )}
                  </Grid>

                  <Grid item lg={6} xs={6}>
                    {getWorkingHoursField(
                      'Break time',
                      control,
                      `boxes[${index}].workingTime.${day.key}.break.start`,
                      `boxes[${index}].workingTime.${day.key}.break.end`,
                      {}
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <ClosedMessage>Closed</ClosedMessage>
            )}
          </Grid>
        </DayRow>
      ))}
    </WorkingTimeContainer>
  );
};
const ClosedMessage = styled(Typography)(() => ({
  color: '#e22a2a',
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
  margin: 'auto',
}));

const WorkingTimeContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0px',
}));

const HeaderContainer = styled(Box)(() => ({
  alignSelf: 'stretch',
  borderBottom: `1px solid  #dfe3e8`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  lineHeight: '150%',
  padding: '8px 12px',
  marginBottom: '20px',
}));

const DefaultWorkingHours = styled(Typography)({
  color: '#212b36',
  letterSpacing: '0.3px',
  flexGrow: 1,
  flexBasis: 'auto',
  fontFamily: 'Barlow, sans-serif',
  fontWeight: 500,
  fontSize: '16px',
});

const SameDaysButton = styled(Button)({
  fontFeatureSettings: "'clig' off, 'liga' off",
  justifyContent: 'center',
  color: '#1976d2',
  whiteSpace: 'nowrap',
  letterSpacing: '0.15px',
  margin: 'auto 0',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  cursor: 'pointer',
});

const DayRow = styled(Box)(() => ({
  marginBottom: '12px',
  paddingBottom: '12px',
  paddingTop: '12px',
  borderBottom: `1px solid #dfe3e8`,
}));

export default WorkingTime;
