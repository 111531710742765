import { TRule } from '../../typings';

export const ATRAXION_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['Eancode']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Prijs']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Id']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.operate',
    sourceKey: "c.['Breedte_b']",
    destination: 'product',
    targetKey: 'packageQuantity',
    operationName: 'calculateAtraxionPackageQuantity',
  },
];
