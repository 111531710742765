import api from '@tyrio/api-factory';
import {
  DBStockLocationsApi,
  UpdateWarehouseReq,
  WarehouseBranchResponse,
} from '@tyrio/dto';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import _ from 'lodash';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { WarehouseContext } from '../../../context/WarehouseContext';

interface CustomObject {
  id: string;
}

export const useUpdateWarehouse = () => {
  const queryClient = useQueryClient();
  const { warehouse, setWarehouse, req, clearSelected } =
    useContext(WarehouseContext);

  const check = (att: string, obj: CustomObject) => {
    return (
      _.get(req['create'], att)?.includes(obj.id) ||
      _.get(req['update'], att)?.includes(obj.id)
    );
  };

  const isIncluded = (arr: string) => {
    return (_.get(warehouse, arr) as unknown as CustomObject[]).filter((item) =>
      check(arr, item)
    );
  };

  const data = {
    ...warehouse,
    zones: isIncluded('zones'),
    subzones: isIncluded('subzones'),
    floors: isIncluded('floors'),
    racks: isIncluded('racks'),
    sections: isIncluded('sections'),
    shelfs: isIncluded('shelfs'),
    bins: isIncluded('bins'),
  };

  const updateStatus = useMutation(
    (id: string) =>
      api.fetch<DBStockLocationsApi['updateOne']>('update_warehouse', {
        id: id,
        warehouse: data as WarehouseBranchResponse,
        meta: req as UpdateWarehouseReq,
      }),
    {
      mutationKey: 'update_warehouse',
      onSuccess: (d) => {
        queryClient.refetchQueries('cpc_get_catalog');
        if (d) {
          setWarehouse(d);
          clearSelected();
          ToastHelper.showToast(
            'Warehouse',
            ToastType.SUCCESS,
            undefined,
            'Warehouse successfully updated!'
          );
        }
      },
      onError: () => {
        ToastHelper.showToast(
          'Warehouse',
          ToastType.ERROR,
          undefined,
          'There is an error during updating warehouse!'
        );
      },
    }
  );

  return { updateStatus };
};
