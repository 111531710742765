import * as z from "zod"
import { CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBWarehouseRacks, RelatedDBWarehouseRacksModel, CompleteDBWarehouseShelfs, RelatedDBWarehouseShelfsModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBWarehouseSectionsModel = z.object({
  id: z.string(),
  warehouseAreaId: z.string(),
  rackId: z.string(),
  code: z.number().int(),
  displayName: z.string(),
  qrCode: z.string(),
  description: z.string(),
  settings: jsonSchema,
  meta: jsonSchema,
})

export interface CompleteDBWarehouseSections extends z.infer<typeof DBWarehouseSectionsModel> {
  warehouseArea: CompleteDBBranchWarehouseAreas
  rack: CompleteDBWarehouseRacks
  shelfs: CompleteDBWarehouseShelfs[]
}

/**
 * RelatedDBWarehouseSectionsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWarehouseSectionsModel: z.ZodSchema<CompleteDBWarehouseSections> = z.lazy(() => DBWarehouseSectionsModel.extend({
  warehouseArea: RelatedDBBranchWarehouseAreasModel,
  rack: RelatedDBWarehouseRacksModel,
  shelfs: RelatedDBWarehouseShelfsModel.array(),
}))
