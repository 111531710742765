import * as z from "zod"
import { DBMargin, DBPriceRound } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBClientPriceCalculationModel = z.object({
  id: z.string(),
  clientId: z.string(),
  supplierId: z.string().nullish(),
  margin: z.number(),
  marginType: z.nativeEnum(DBMargin),
  priceRound: z.nativeEnum(DBPriceRound),
  useSuggestedRetailPrice: z.boolean(),
  rules: jsonSchema,
  isActive: z.boolean(),
  createdAt: z.date(),
  name: z.string(),
})

export interface CompleteDBClientPriceCalculation extends z.infer<typeof DBClientPriceCalculationModel> {
  client: CompleteDBClient
  supplier?: CompleteDBSupplierClientSettings | null
}

/**
 * RelatedDBClientPriceCalculationModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientPriceCalculationModel: z.ZodSchema<CompleteDBClientPriceCalculation> = z.lazy(() => DBClientPriceCalculationModel.extend({
  client: RelatedDBClientModel,
  supplier: RelatedDBSupplierClientSettingsModel.nullish(),
}))
