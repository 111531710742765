import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBCountry, RelatedDBCountryModel } from "./index"

export const DBAddressModel = z.object({
  id: z.string(),
  city: z.string(),
  zipCode: z.string(),
  address1: z.string(),
  address2: z.string().nullish(),
  clientId: z.string().nullish(),
  countryId: z.string(),
})

export interface CompleteDBAddress extends z.infer<typeof DBAddressModel> {
  client?: CompleteDBClient | null
  country?: CompleteDBCountry | null
}

/**
 * RelatedDBAddressModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBAddressModel: z.ZodSchema<CompleteDBAddress> = z.lazy(() => DBAddressModel.extend({
  client: RelatedDBClientModel.nullish(),
  country: RelatedDBCountryModel.nullish(),
}))
