import * as z from "zod"
import { DBUnitOfMeasurment } from ".prisma/client"
import { CompleteDBService, RelatedDBServiceModel, CompleteDBClient, RelatedDBClientModel, CompleteDBServiceCategoryCode, RelatedDBServiceCategoryCodeModel, CompleteDBFittingBoxSettings, RelatedDBFittingBoxSettingsModel } from "./index"

export const DBServiceCategoryModel = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  vatRate: z.string(),
  unitOfMeasurment: z.nativeEnum(DBUnitOfMeasurment),
  generalLedgeAcc: z.string(),
  deleted: z.boolean().nullish(),
  active: z.boolean(),
  clientId: z.string(),
  code: z.string().nullish(),
  serviceCategoryCodeId: z.string().nullish(),
  canDelete: z.boolean().nullish(),
})

export interface CompleteDBServiceCategory extends z.infer<typeof DBServiceCategoryModel> {
  services: CompleteDBService[]
  client: CompleteDBClient
  serviceCategoryCode?: CompleteDBServiceCategoryCode | null
  box: CompleteDBFittingBoxSettings[]
}

/**
 * RelatedDBServiceCategoryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBServiceCategoryModel: z.ZodSchema<CompleteDBServiceCategory> = z.lazy(() => DBServiceCategoryModel.extend({
  services: RelatedDBServiceModel.array(),
  client: RelatedDBClientModel,
  serviceCategoryCode: RelatedDBServiceCategoryCodeModel.nullish(),
  box: RelatedDBFittingBoxSettingsModel.array(),
}))
