import { InboundInvoiceResponse } from '@tyrio/dto';
import { VAT } from '../../../context/InboundInvoiceContext';

export const initialValues = (
  preparedInvoices?: InboundInvoiceResponse,
  transportModeData?: { value: number; label: string }[]
) => {
  if (!preparedInvoices)
    return {
      branchId: '',
      date: new Date(),
      supplierName: '',
      vatChargeType: VAT.E1,
      currency: 'EUR',
      exchangeRate: 1,
      supplierInvoiceNumber: '',
      invoiceIssueDate: null,
      delay: 0,
      invoiceDueDate: null,
      internalRemark: '',
      domestic: 0,
      domesticOther: 0,
      international: 0,
      internationalOther: 0,
      totalVarCost: 0,
      calculationType: 'quantity',
      deliveryTermsAndConditions: '',
      natureOfTransaction: '',
      modeOfTransport: '',
      dispatchingCountry: '',
    };

  return {
    branchId: preparedInvoices.branchId.toString(),
    date: preparedInvoices.date,
    supplierName: preparedInvoices?.partner?.companyOfficialName ?? '',
    vatChargeType: preparedInvoices.vatChargeType === 'V05' ? VAT.R1 : VAT.E1,
    currency: preparedInvoices.partner?.currency ?? 'EUR',
    exchangeRate: 1,
    supplierInvoiceNumber: '',
    invoiceIssueDate: null,
    delay: preparedInvoices.partner?.paymentDelay,
    invoiceDueDate: null,
    internalRemark: '',
    totalVarCost: preparedInvoices.transportCost ?? 0,
    domestic: preparedInvoices.transportCost,
    domesticOther: 0,
    international: 0,
    internationalOther: 0,
    calculationType: 'quantity',
    deliveryTermsAndConditions: preparedInvoices.partner?.deliveryTermsType,
    natureOfTransaction:
      preparedInvoices.partner?.transactionNature?.toString(),
    modeOfTransport:
      transportModeData?.find((item) =>
        item.label
          .toLowerCase()
          .includes(
            preparedInvoices.partner?.transportMode
              ?.toLowerCase()
              .replaceAll('_', ' ') ?? ''
          )
      )?.value ?? '',
    dispatchingCountry: preparedInvoices.partner?.countryId,
  };
};
