import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { DBProductApi, DBStockList, PosItem } from '@tyrio/dto';
import { EprelToPrint, ProductEprelGenerator } from '@tyrio/products';
import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { ActiveDrawer } from '../../../context/StockListContext';
import CustomDrawerContainer from '../CustomDrawerContainer';

interface PreviewEprelProps {
  activeDrawer: ActiveDrawer;
  setActiveDrawer: (a: ActiveDrawer) => void;
  selectedTableRow: DBStockList | PosItem;
}

const PreviewEprel = ({
  activeDrawer,
  setActiveDrawer,
  selectedTableRow,
}: PreviewEprelProps) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const renderEprel = () => {
    return (
      <Wrapper>
        <div style={{ display: 'none' }}>
          <EprelToPrint
            ref={componentRef}
            product={
              selectedTableRow?.product as DBProductApi['getOne']['response']
            }
          />
        </div>
        <ProductEprelGenerator
          product={
            selectedTableRow?.product as DBProductApi['getOne']['response']
          }
        />
        <ReactToPrint
          trigger={() => (
            <Button
              variant="outlined"
              color="info"
              sx={{ width: '200px', height: '60px' }}
            >
              Print label
            </Button>
          )}
          content={() => componentRef.current}
          bodyClass="print-container"
        />

        <PrintInfo>
          Click on PRINT will print 4 labels on format A4 21.0 x 29.7 cm
        </PrintInfo>
      </Wrapper>
    );
  };

  return (
    <CustomDrawerContainer
      open={activeDrawer === 'PRINT'}
      child={renderEprel()}
      onClick={() => setActiveDrawer('')}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  gap: 32px;
  border-radius: 16px;
  padding: 60px 0;
  height: 90%;
  width: 100%;
`;

const PrintInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #919eab;
`;

export default PreviewEprel;
