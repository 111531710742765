import { wmsIcons } from '@tyrio/wms-ui-library';

export const MenuList = [
  {
    icon: wmsIcons.search,
    text: 'SEARCH',
    disabled: false,
  },
  {
    icon: wmsIcons.details,
    text: 'DETAILS',
    disabled: false,
  },
  {
    icon: wmsIcons.inbound,
    text: 'INBOUND',
    disabled: false,
  },
  {
    icon: wmsIcons.outbound,
    text: 'OUTBOUND',
    disabled: false,
  },
  {
    icon: wmsIcons.list,
    text: 'PASTE LIST',
    disabled: false,
  },
  {
    icon: wmsIcons.print,
    text: 'PRINT',
    disabled: true,
  },
  {
    icon: wmsIcons.cart,
    text: 'CART',
    disabled: false,
  },
];
