import {
  LandingPage,
  SearchableVerticalList,
  SearchableVerticalListDataType,
  tyrioIcons,
} from '@tyrio/ui-library';

import api from '@tyrio/api-factory';
import { DBSupplierApi } from '@tyrio/dto';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import { ExpandableLayout } from '../../layouts/ExpandableLayout';
import routeNames from '../../lib/routeNames';
import { pageSize } from '../../../../constants/pagination.constants';

interface DashboardAdminProps {
  children: React.ReactNode;
}

const DashboardAdminSupplier = ({ children }: DashboardAdminProps) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(search, 500);
  const location = useLocation();

  const { isFetching, isError, data } = useQuery(
    ['supplier_list', { debouncedSearch, page }],
    () => searchSuppliers(search, page),
    {}
  );

  const searchSuppliers = async (search: string, page: number) => {
    return await api.fetch<DBSupplierApi['list']>('suppliers', {
      search: search,
      page: page,
      pageSize: pageSize,
    });
  };

  const [selected, setSelected] = useState<Record<string, boolean>>(
    location.pathname.split('/').pop() !== 'supplier'
      ? { [location.pathname.split('/').pop()]: true }
      : {}
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      setSelected((prevValue) => ({
        ...prevValue,
        [item.id]: !prevValue[item.id],
      }));
      history.push(`/dashboard/supplier/${item.id}`);
    }
    if (type === 'new') {
      history.push(`/dashboard/supplier/new`);
    }
  };

  return (
    <ExpandableLayout
      sidebarComponent={
        <SearchableVerticalList
          header="Suppliers Settings"
          type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
          data={
            data?.data.map((item) => ({
              id: item.id,
              name: item.companyShortName,
              type: item.supplierType,
              active: item.active,
              disabled: !item.active,
              isSelected: selected[item.id],
            })) || []
          }
          path={routeNames.dashboard()}
          isFetching={isFetching}
          isError={isError}
          onSearchChange={setSearch}
          onCreateNew={() => handleClick('new', {})}
          handleItemClick={(item) => handleClick('itemClicked', item)}
          onChangePage={(page) => setPage(page)}
          count={data?.count}
          pageNumber={page}
        />
      }
      landingPage={
        <LandingPage
          icon={tyrioIcons.supplierIcon}
          title={'Welcome to Supplier settings'}
          subtitle={'Choose supplier from the list or create new.'}
          buttonText={'Add new supplier'}
          onClick={() => handleClick('new', {})}
        />
      }
    >
      {children}
    </ExpandableLayout>
  );
};

export default DashboardAdminSupplier;
