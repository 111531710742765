import api from '@tyrio/api-factory';
import { DBServiceCategoryApi, DBServicesApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { useHistory } from 'react-router-dom';

// UPDATE MAIN CATEGORY FOR SERVICES
export const useDeleteServiceCategory = (queryClient: QueryClient) => {
  const history = useHistory();
  const { mutate } = useMutation(
    ['delete_service_category'],
    (id: DBServiceCategoryApi['deleteOne']['requestBody']) =>
      api.fetch<DBServiceCategoryApi['deleteOne']>('delete_service_category', {
        ...id,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_service_categories');
        history.push('/dashboard/services-category');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.DELETE,
          ''
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { deleteServiceCategory: mutate };
};

// UPDATE SINGLE SERVICE
export const useDeleteService = (queryClient: QueryClient) => {
  const history = useHistory();
  const { mutate } = useMutation(
    ['delete_services'],
    (id: DBServicesApi['getOne']['requestBody']) =>
      api.fetch<DBServicesApi['getOne']>('delete_services', {
        ...id,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_service_categories');
        history.push('/dashboard/services-category');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.DELETE,
          ''
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { deleteService: mutate };
};
