import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBWarehouseCacheModel = z.object({
  id: z.string(),
  clientId: z.string(),
  warehouse: jsonSchema,
})

export interface CompleteDBWarehouseCache extends z.infer<typeof DBWarehouseCacheModel> {
  client: CompleteDBClient
}

/**
 * RelatedDBWarehouseCacheModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWarehouseCacheModel: z.ZodSchema<CompleteDBWarehouseCache> = z.lazy(() => DBWarehouseCacheModel.extend({
  client: RelatedDBClientModel,
}))
