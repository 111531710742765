import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
import {
  genericGenerateSpecialMarkings,
  splitSpecialMarkingString,
} from './operations.helpers';

export function generateKumhoSpecialMarkings(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = [
    input['DESCRIPTION_2'],
    ...input['PROD_INFO'].split('/'),
    input['RUN_FLAT'],
  ].join(' ');

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => ['XRP'].includes(input['RUN_FLAT']),
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }
  const splitSpecialMarkings = splitSpecialMarkingString(
    data.product['specialMarking']
  );

  if (input['FLANK'] === 'X') {
    splitSpecialMarkings.push('FSL');
  }

  data.product['specialMarking'] = [...new Set(splitSpecialMarkings)]
    .join(' ')
    .replace(/ +(?= )/g, '');

  return data;
}
