import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { BRIDGESTONE_CATALOG_RULESET } from './bridgestone/catalog';
import { BRIDGESTONE_STOCKLIST_RULESET } from './bridgestone/stocklist';
import { BRIDGESTONE_PRICELIST_RULESET } from './bridgestone/pricelist';

export const BRIDGESTONE_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: BRIDGESTONE_CATALOG_RULESET,
  pricelistRuleset: BRIDGESTONE_PRICELIST_RULESET,
  stockRuleset: BRIDGESTONE_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    DESIGN_1: {
      FIRESTONE: '',
    },
    purchasePrice: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'BRIDGESTONE EDI B4',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return handlePreprocessConversions(
      // json.slice(1067, 1068),
      json,
      this.preprocessKeys
    );
  },
};
