import styled from '@emotion/styled';
import { CircularProgress, Pagination } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { companyBranchesIconeBlack } from '../../../assets';
import backIcon from '../../../assets/vertical-list/back-icon.svg';
import { colors } from '../../../core';
import FetchDataDescription, {
  FetchDataDesctiptionTypes,
} from '../FetchDataDescription/FetchDataDescription';
import ItemsWithChildren from './ItemsWithChildren';
import ItemsWithoutChildren from './ItemsWithoutChildren';
import { SearchableVerticalListItem } from './SearchableVerticalList.types';
import {
  SearchableVerticalListDataProps,
  SearchableVerticalListDataType,
} from './types';

interface SearchableVerticalListProps {
  header: string;
  type: SearchableVerticalListDataType;
  data: SearchableVerticalListDataProps[];
  isSearch?: boolean;
  handleItemClick: (item: SearchableVerticalListItem) => void;
  path: string;
  onCreateNew?: () => void;
  onSearchChange: (e: string) => void;
  isFetching: boolean;
  isError: boolean;
  onChangePage?: (page: number) => void;
  count?: number;
  pageSize: number;
  pageNumber?: number;
  switchMenu?: boolean;
  setSwitchMenu?: (menu: boolean) => void;
  renderIcon?: React.ReactNode;
}

export const SearchableVerticalList = ({
  header,
  type,
  data,
  isSearch,
  handleItemClick,
  path,
  onCreateNew,
  onSearchChange,
  isFetching,
  isError,
  onChangePage,
  count,
  pageSize,
  pageNumber,
  switchMenu,
  setSwitchMenu,

  renderIcon,
}: SearchableVerticalListProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const totalPageNumber = count
    ? count / pageSize - Math.floor(count / pageSize) === 0
      ? count / pageSize
      : Math.floor(count / pageSize) + 1
    : 1;

  const withCreateButton =
    !!onCreateNew && !location.pathname.toString().includes('/new');

  if (data?.length === 0 && !isError && !isFetching && count && count !== 0) {
    onChangePage && onChangePage(1);
  }

  return (
    <>
      <Container>
        <HeaderWrapperTop>
          <div>
            <BackButton
              onClick={
                !switchMenu && setSwitchMenu
                  ? () => {
                      setSwitchMenu(true);
                      history.push(`${path}/company-settings`);
                    }
                  : () => {
                      history.push(path);
                    }
              }
            >
              <Image src={backIcon} alt="back-icon" />
            </BackButton>
            <HeaderText>{t(header)}</HeaderText>
          </div>
          {setSwitchMenu && header === 'Branches' && (
            <Image src={companyBranchesIconeBlack} alt="branches-icon" />
          )}
          {/* Top right corner icon in header */}
          {renderIcon && <div>{renderIcon}</div>}
        </HeaderWrapperTop>
        <HeaderWrapperBottom>
          <HeaderSearch
            id={`search-${path}`}
            data-cy="search"
            withCreateButton={withCreateButton}
            type="search"
            placeholder={t('Search list')}
            onChange={(e) => {
              onSearchChange(e.target.value);
              if (onChangePage) onChangePage(1);
            }}
          />
          {withCreateButton && (
            <HeaderButton data-cy="newRole" onClick={() => onCreateNew?.()}>
              {t('New')} +
            </HeaderButton>
          )}
        </HeaderWrapperBottom>
        {isFetching && (
          <ContentWrapper>
            <CircularProgress />
          </ContentWrapper>
        )}
        {isError && (
          <ContentWrapper>
            <FetchDataDescription type={FetchDataDesctiptionTypes.FetchError} />
          </ContentWrapper>
        )}
        {data?.length === 0 && !isError && !isFetching && (
          <ContentWrapper>
            <FetchDataDescription type={FetchDataDesctiptionTypes.NoData} />
          </ContentWrapper>
        )}

        {data && data?.length > 0 && !isError && !isFetching && (
          <List>
            {type === SearchableVerticalListDataType.WITHOUT_CHILDREN ? (
              <ItemsWithoutChildren onItemClick={handleItemClick} data={data} />
            ) : (
              <ItemsWithChildren
                onItemClick={handleItemClick}
                data={data}
                isSearch={isSearch ?? false}
              />
            )}
          </List>
        )}
      </Container>
      <PaginationWrapper>
        <Pagination
          count={totalPageNumber}
          sx={{
            bottom: '35px',
            position: 'relative',
            backgroundColor: 'white',
            width: '350px',
            display: 'flex',
            justifyContent: 'center',
            button: {
              '&.css-d3ksqy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected':
                {
                  backgroundColor: colors.lightBlue,
                  color: 'white',
                },
            },
          }}
          onChange={(e: ChangeEvent<unknown>, page: number) => {
            if (onChangePage) onChangePage(page);
          }}
          page={pageNumber}
        />
      </PaginationWrapper>
    </>
  );
};

SearchableVerticalList.defaultProps = {
  pageSize: 10,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const HeaderWrapperTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderWrapperBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 17px;
  @media (max-width: 485px) {
    flex-direction: column;
  }
`;

const Image = styled.img``;

const HeaderButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${colors.lightBlue};
  border: 1px solid rgba(25, 118, 210, 0.5);
  border-radius: 8px;
  background: none;
  cursor: pointer;
  padding: 6px 16px;
  gap: 8px;
  width: 100%;
  max-width: 90px;
  height: 38px;
  margin-left: 5px;
  @media (max-width: 485px) {
    max-width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
  :hover {
    background: rgba(25, 118, 210, 0.04);
    border: 1px solid #356df0;
  }
`;

const HeaderSearch = styled.input<{ withCreateButton: boolean }>`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: ${(props) => (props.withCreateButton ? '245px' : '100%')};
  padding: 6px 4px 6px 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
`;
const List = styled.div`
  margin-bottom: 50px;
  position: relative;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

const ContentWrapper = styled.div`
  margin-top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SearchableVerticalList;
