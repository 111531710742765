import { range } from 'lodash';
import { parseEnumToInputOption } from '../../../../../helpers/enum-parser';
import { FormShape } from '../../services/constants/service.form';
import { DBPriceType, PaymentTypeEnum } from '@prisma/client';

export const AcceptedCards = {
  Visa: 'Visa',
  Mastercard: 'Mastercard',
  Maestro: 'Maestro',
  Diners: 'Diners',
  Amex: 'Amex',
};

export const PaymentMethodFormShape = (
  isFiscalTypeEnabled: boolean,
  shouldApplyCashDiscount: boolean
) => {
  const payment = parseEnumToInputOption(PaymentTypeEnum);
  const priceType = parseEnumToInputOption(DBPriceType);
  const acceptedCards = parseEnumToInputOption(AcceptedCards);

  const fiscalizationOptions = [
    { label: 'G – gotovina', value: 'G' },
    { label: 'K – kartice', value: 'K' },
    { label: 'C – ček', value: 'C' },
    { label: 'T – transakcijski račun', value: 'T' },
    { label: 'O – ostalo', value: 'O' },
  ];

  const paymentGateway = [
    { label: 'no payment gateway', value: 'no payment gateway' },
  ];

  const installementRange = range(1, 25);
  const installements = installementRange.map((r) => {
    return { label: r.toString(), value: r.toString() };
  });

  const mainForm: FormShape[] = [
    {
      key: 'code_name',
      inputs: [
        {
          id: 'code',
          width: '30%',
          type: 'input.string',
          label: 'Code *',
          hasPadding: false,
        },
        {
          id: 'name',
          width: 'calc(70% - 16px)',
          type: 'input.string',
          label: 'Name *',
          hasPadding: false,
        },
      ],
    },
    {
      inputs: [
        {
          id: 'description',
          width: '100%',
          type: 'input.string',
          label: 'Description',
          hasPadding: false,
        },
      ],
    },
    {
      key: 'payment_type',
      inputs: [
        {
          id: 'paymentType',
          width: '22%',
          type: 'input.select',
          options: payment,
          label: 'Payment type *',
          hasPadding: false,
        },
        {
          id: 'priceType',
          width: '22%',
          type: 'input.select',
          options: priceType,
          label: 'Price type',
          hasPadding: false,
          skipField: true,
        },
        {
          id: 'cashDiscount',
          width: 'fit-content',
          type: 'input.switch',
          label: 'Apply cash discount',
          hasPadding: false,
        },
        {
          id: 'restrictions.minAmount',
          width: '13%',
          type: 'input.string',
          label: 'Min amount',
          hasPadding: false,
          inputType: 'number',
          skipField: !shouldApplyCashDiscount,
        },
        {
          id: 'restrictions.maxAmount',
          width: '13%',
          type: 'input.string',
          label: 'Max amount',
          hasPadding: false,
          inputType: 'number',
          skipField: !shouldApplyCashDiscount,
        },
        {
          id: 'isFiscalizationEnabled',
          width: 'fit-content',
          type: 'input.switch',
          label: 'Fiscalization',
          hasPadding: false,
        },
        {
          id: 'fiscalType',
          width: '22%',
          type: 'input.select',
          options: fiscalizationOptions,
          label: 'Fiscal type',
          hasPadding: false,
          skipField: !isFiscalTypeEnabled,
        },
      ],
    },
  ];

  const cardPayment: FormShape = {
    key: 'card_payment',
    inputs: [
      {
        id: 'paymentGateway',
        width: '25%',
        type: 'input.select',
        options: paymentGateway,
        label: 'Payment Gateway',
        hasPadding: false,
      },
      {
        id: 'cardSettings.installements',
        width: '15%',
        type: 'input.select',
        options: installements,
        label: 'Installements',
        hasPadding: false,
        skipField: true,
      },
      {
        id: 'cardSettings.acceptedCards',
        width: 'calc(60% - 32px)',
        type: 'input.multiselect',
        options: acceptedCards,
        label: 'Accepted cards',
        hasPadding: false,
      },
    ],
  };

  return {
    form: mainForm,
    cardPayment,
  };
};
