import { DBApiConnection, DBRepeatUnit } from '@prisma/client';

export const api_connections: DBApiConnection[] = [
  {
    id: '6aa903c6-7be9-4112-9299-408eb81a80ab',
    apiUrl: 'https://api-gmpitalia.comunichiamo.eu/order',
    apiKey: '530e1f385a180d03ec185552cac49ab7c471776b',
    apiClientId: '2aec4bd91e83ec58a48d',
    apiClientSecret: '914be07dd8816f29acb38ea7e5a40e63b9613248',
    startUpdateTime: new Date(),
    repeatEvery: 1,
    repeatUntil: DBRepeatUnit.HOURS,
    supplierConnectionId: '5a4b5192-7692-42de-bdc6-ed6899758b5a',
    remark: '',
    connectionType: 'GMP_ITALIA',
  },
];
