import styled from '@emotion/styled/macro';
import { ArrowBack } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, Divider, Grid, Tooltip } from '@mui/material';
import {
  DBClientSupplierOrderItemApi,
  DBCustomerOrderItemResponse,
  SSE_ENTITY_KEYS,
} from '@tyrio/dto';
import { CancelModal, tyrioUI } from '@tyrio/ui-library';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useWS } from '../../../context/WSContext';
import { ComponentType } from '../../../features/purchase-orders/helpers/types';
import { OrderSidebarLayoutButtons } from './OrderSidebarLayoutButtons';
import { PriceComparisonContext } from '../../../pages/price-compare/components/context';
import { SalesOrdersFlowContext } from '../../../features/sales-orders/SalesOrdersFlowContext';
import SaveIcon from '@mui/icons-material/Save';
import { ExpiredSessionModal } from '../../Timer/modals/ExpiredSession';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface PurchaseOrderSidebarTabsProps {
  selectedOrderData:
    | DBCustomerOrderItemResponse
    | DBClientSupplierOrderItemApi['getOne']['response']
    | null;

  closeOrder: () => void;
  setFavorite: () => void;
  setLockStatus?: () => void;
  handleSave: () => void;
  orderTabs: JSX.Element;
  isDirty: boolean;
  isFetching?: boolean;
  componentType: ComponentType;
  handleNextStep?: (step: string) => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isUploadInProcess: boolean;
  isProcessOrder?: boolean;
  preparePurchaseOrder?: () => void;
  canContinueProcessOrder?: boolean;
  showGoBackToCustomerOrders?: boolean;
  setOpenPrintModal?: (a: boolean) => void;
  isWarningModalOpen?: boolean;
  setIsWarningModalOpen?: (a: boolean) => void;
  invisibleButton?: boolean;
}

export const OrderSidebarLayout = ({
  selectedOrderData,
  closeOrder,
  setFavorite,
  setLockStatus,
  handleSave,
  orderTabs,
  isDirty,
  componentType,
  handleNextStep,
  setIsDirty,
  isProcessOrder,
  isUploadInProcess,
  preparePurchaseOrder,
  canContinueProcessOrder,
  showGoBackToCustomerOrders = false,
  setOpenPrintModal,
  isWarningModalOpen,
  setIsWarningModalOpen,
  invisibleButton = false,
}: PurchaseOrderSidebarTabsProps) => {
  const { user } = useAuth();
  const { state, socket } = useWS();

  const ctx = useContext(PriceComparisonContext);
  const soctx = useContext(SalesOrdersFlowContext);

  const iconsStyledProps = { width: '20px', height: '20px', cursor: 'pointer' };
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const handleOnCancel = () => {
    if (isDirty && setIsCancelModalVisible) {
      setIsCancelModalVisible(true);
    } else if (isUploadInProcess) {
      window.alert(`You can't close order details while uploading documents!`);
    } else closeOrder();
  };

  const handleOrderClose = () => {
    if (setIsWarningModalOpen && isProcessOrder) setIsWarningModalOpen(true);
    else closeOrder();
  };

  const handleClose = () => {
    if (isUploadInProcess) {
      window.alert(`You can't close order details while uploading documents!`);
    } else {
      closeOrder();
      setIsDirty(false);
    }

    const entity =
      state.locks &&
      state.locks[`${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${selectedOrderData?.id}`];

    const lockedBy = entity?.userId;

    if (lockedBy === user?.id)
      socket?.emit('unlock', {
        entityName: SSE_ENTITY_KEYS.CUSTOMER_ORDER,
        entityId: selectedOrderData?.id,
      });
  };

  const isFavorited = () => {
    if (user) return selectedOrderData?.favoritedBy.includes(user.id);
    return false;
  };

  const lockEntity =
    state.locks &&
    state.locks[`${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${selectedOrderData?.id}`];

  const isDisabled = !(
    lockEntity === undefined ||
    lockEntity === null ||
    lockEntity?.userId === user?.id
  );

  const isPurchaseOrderScreen = soctx.screen === 'purchase_order';

  return (
    <Container id="order_sidebar_layout">
      <ExpiredSessionModal
        text={'Your order is in process'}
        subtitle={'if you continue all changes will be lost'}
        isOpen={isWarningModalOpen ?? false}
        onContinue={() => {
          handleClose();
        }}
        onCancel={() => {
          if (setIsWarningModalOpen) setIsWarningModalOpen(false);
        }}
      />
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            setIsCancelModalVisible(false);
            closeOrder();
          }}
        />
      )}
      <Header>
        <BasicOrderInfoContainer>
          {componentType === ComponentType.customer
            ? _.get(selectedOrderData, 'details.number')
            : selectedOrderData?.orderNumber}
          <TimeInfo>
            {moment(selectedOrderData?.orderedAt).format(
              'DD.MM.YYYY - H:mm:ss'
            )}
          </TimeInfo>
        </BasicOrderInfoContainer>

        <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {componentType === ComponentType.customer &&
            (lockEntity === undefined ||
              lockEntity === null ||
              lockEntity?.userId === user?.id) && (
              <Grid>
                {lockEntity ? (
                  <Tooltip placement="top" title="Unlock order">
                    <LockOutlinedIcon
                      sx={{ ...iconsStyledProps }}
                      onClick={setLockStatus}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title="Lock order">
                    <LockOpenOutlinedIcon
                      sx={{ ...iconsStyledProps }}
                      onClick={setLockStatus}
                    />
                  </Tooltip>
                )}
              </Grid>
            )}
          {isFavorited() ? (
            <Tooltip placement="top" title="Remove from favorites">
              <FavoriteIcon
                sx={{ ...iconsStyledProps, color: '#FFDB80' }}
                onClick={setFavorite}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Add to favorites">
              <FavoriteBorderIcon sx={iconsStyledProps} onClick={setFavorite} />
            </Tooltip>
          )}
          {!invisibleButton && (
            <CloseIcon
              sx={iconsStyledProps}
              onClick={() => {
                if (!invisibleButton) handleOrderClose();
              }}
            />
          )}
        </Grid>
      </Header>

      <TabsWrapper>{orderTabs}</TabsWrapper>
      <FooterButtons>
        {isProcessOrder ? (
          <Grid
            container
            spacing={2}
            sx={{ padding: '16px', paddingBottom: '0px' }}
          >
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
              <Button
                variant={'outlined'}
                color={'error'}
                startIcon={
                  <CloseIcon color={invisibleButton ? 'disabled' : 'error'} />
                }
                sx={{ marginRight: 2 }}
                disabled={invisibleButton}
                onClick={() => {
                  handleOrderClose();
                }}
              >
                Cancel
              </Button>
            </Grid>
            {!isPurchaseOrderScreen && (
              <>
                {isDirty && (
                  <Grid
                    item
                    xs={3}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={handleSave}
                      style={{ gap: '11px' }}
                      disabled={isUploadInProcess || isDisabled || !isDirty}
                      disableElevation
                    >
                      <SaveIcon /> Save
                    </Button>
                  </Grid>
                )}

                <Grid
                  item
                  xs={!isDirty ? 6 : 3}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Tooltip
                    id="tooltip"
                    title={
                      isDirty
                        ? 'To continue you will have to save changes first'
                        : ''
                    }
                  >
                    <span>
                      <Button
                        variant={'contained'}
                        color={'info'}
                        id="continue_button"
                        disabled={!canContinueProcessOrder || isDirty}
                        onClick={() => {
                          preparePurchaseOrder && preparePurchaseOrder();
                        }}
                        endIcon={<KeyboardArrowRightIcon />}
                        style={{ marginRight: `${tyrioUI.spacing.l}px` }}
                        disableElevation
                      >
                        Checkout
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        ) : showGoBackToCustomerOrders ? (
          <ReturnButtonWrapper>
            <Button
              variant={'outlined'}
              color={'info'}
              startIcon={<ArrowBack color={'info'}></ArrowBack>}
              onClick={() => {
                if (ctx.mode === 'sales_orders')
                  soctx.setScreen('process_order');
              }}
            >
              Back
            </Button>
            <NavLink
              to={`/dashboard/customer-orders?selected=${selectedOrderData?.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant={'outlined'}
                color="info"
                style={{ gap: '16px' }}
                disableElevation
              >
                Close
              </Button>
            </NavLink>
          </ReturnButtonWrapper>
        ) : componentType === ComponentType.customer ? (
          OrderSidebarLayoutButtons(
            selectedOrderData?.id ?? '',
            closeOrder,
            handleOnCancel,
            handleSave,
            isUploadInProcess || isDisabled,
            handleNextStep,
            'CLOSE'
          ).customer
        ) : (
          OrderSidebarLayoutButtons(
            selectedOrderData?.id ?? '',
            closeOrder,
            handleOnCancel,
            handleSave,
            isUploadInProcess,
            undefined,
            undefined,
            () => {
              setOpenPrintModal && setOpenPrintModal(true);
            }
          ).purchase
        )}
      </FooterButtons>
    </Container>
  );
};

export const Container = styled.div`
  background: #fff;
  width: 100%;
  height: calc(100svh - 44px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const BasicOrderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  background: #454f5b;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: 700;
  font-size: 24px;
  color: white;
  top: 0;
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: sticky;
  top: 100%;
  background: white;
  border-radius: 8px;
`;

const TabsWrapper = styled.div`
  max-height: calc(100svh - 44px - 60px);
  height: 100%;
`;

const ReturnButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: space-between;
`;

export default OrderSidebarLayout;
