import { TRule } from '../../typings';

export const RONAL_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c['ean']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c['manufacturerCode']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c['quantity']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  // {
  //   type: 'rule.operate',
  //   sourceKey: 'c.size',
  //   destination: 'product',
  //   targetKey: 'packageQuantity',
  //   operationName: 'calculateRonalPackageQuantity',
  // },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
