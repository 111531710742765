import * as z from "zod"
import { CompleteDBSupplier, RelatedDBSupplierModel, CompleteDBClient, RelatedDBClientModel, CompleteDBSupplierClientContacts, RelatedDBSupplierClientContactsModel, CompleteDBSupplierClientB2BSettings, RelatedDBSupplierClientB2BSettingsModel, CompleteDBSupplierClientPaymentSettings, RelatedDBSupplierClientPaymentSettingsModel, CompleteDBSupplierClientShippingSettings, RelatedDBSupplierClientShippingSettingsModel, CompleteDBSupplierClientPriceCalculationSettings, RelatedDBSupplierClientPriceCalculationSettingsModel, CompleteDBSupplierClientPackageCost, RelatedDBSupplierClientPackageCostModel, CompleteDBClientPriceCalculation, RelatedDBClientPriceCalculationModel, CompleteDBPartner, RelatedDBPartnerModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBSupplierClientSettingsModel = z.object({
  id: z.string(),
  supplierId: z.string(),
  clientId: z.string(),
  isActive: z.boolean(),
  connections: jsonSchema,
  priceCalculationId: z.string().nullish(),
  branchEdiConfiguration: jsonSchema,
})

export interface CompleteDBSupplierClientSettings extends z.infer<typeof DBSupplierClientSettingsModel> {
  supplier: CompleteDBSupplier
  client: CompleteDBClient
  supplierClientContacts: CompleteDBSupplierClientContacts[]
  supplierClientB2BSettings?: CompleteDBSupplierClientB2BSettings | null
  supplierClientPaymentSettings?: CompleteDBSupplierClientPaymentSettings | null
  supplierClientShippingSettings: CompleteDBSupplierClientShippingSettings[]
  supplierClientPriceCalculationSettings?: CompleteDBSupplierClientPriceCalculationSettings | null
  supplierClientPackageCost: CompleteDBSupplierClientPackageCost[]
  priceCalculation?: CompleteDBClientPriceCalculation | null
  partner?: CompleteDBPartner | null
}

/**
 * RelatedDBSupplierClientSettingsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierClientSettingsModel: z.ZodSchema<CompleteDBSupplierClientSettings> = z.lazy(() => DBSupplierClientSettingsModel.extend({
  supplier: RelatedDBSupplierModel,
  client: RelatedDBClientModel,
  supplierClientContacts: RelatedDBSupplierClientContactsModel.array(),
  supplierClientB2BSettings: RelatedDBSupplierClientB2BSettingsModel.nullish(),
  supplierClientPaymentSettings: RelatedDBSupplierClientPaymentSettingsModel.nullish(),
  supplierClientShippingSettings: RelatedDBSupplierClientShippingSettingsModel.array(),
  supplierClientPriceCalculationSettings: RelatedDBSupplierClientPriceCalculationSettingsModel.nullish(),
  supplierClientPackageCost: RelatedDBSupplierClientPackageCostModel.array(),
  priceCalculation: RelatedDBClientPriceCalculationModel.nullish(),
  partner: RelatedDBPartnerModel.nullish(),
}))
