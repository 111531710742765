/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useState } from 'react';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import { Alert, Button, Divider, Grid, Tooltip } from '@mui/material';
import { ImportTextArea } from './DataIntroComponents/ImportTextArea';
import styled from '@emotion/styled';
import { TyrioTab, TyrioTabs, tyrioUI } from '@tyrio/ui-library';
import { SupplierSelectArea } from './DataIntroComponents/SupplierSelectArea';
import { ArrowForward, Cancel } from '@mui/icons-material';
import { DimensionArea } from './DataIntroComponents/DimensionArea';
import { PriceComparisonContext } from './context';
import { FlowHeader } from './FlowHeader';
import { CompareDimensionsData } from '../types';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { isEmpty } from 'lodash';

export const PriceComparisonIntro = () => {
  const ctx = useContext(PriceComparisonContext);
  const [errors, setErrors] = useState<Record<string, boolean>>({
    dimensions: false,
  });

  const handleSubmit = useCallback(async () => {
    try {
      if (!ctx.data.supplierTransferList?.right)
        throw new Error('No selected suppliers.');

      if (ctx.data.type === 'code') {
        if (!ctx.data.code?.searchType)
          throw new Error('No selected code type.');

        const codes = ctx.data.code?.list || {};
        const data = {
          supplierIds: ctx.data?.supplierTransferList.right.filter(
            (item) => item !== ctx.data.mainSupplier?.id
          ),
          mainSupplier: ctx.data.mainSupplier?.id,
          codes: Object.keys(codes).map((code) => ({
            value: code,
            qty: codes[code],
          })),
          codeType: ctx.data.code?.searchType,
        };

        await ctx.mutateCode(data);
      }

      if (ctx.data.type === 'dimension') {
        const data: CompareDimensionsData = {
          supplierIds: ctx.data?.supplierTransferList.right.filter(
            (item) => item !== ctx.data.mainSupplier?.id
          ),
          mainSupplier: ctx.data.mainSupplier?.id,
          dimensions: ctx.data.dimensions?.sizes ?? [],
          brands: ctx.data.dimensions?.brands,
          classes: ctx.data.dimensions?.brandClasses,
          sizeFavorites: ctx.data.dimensions?.sizeFavourites,
          categoryId: `${ctx.data.dimensions?.category?.value}`,
          seasons: ctx.data.dimensions?.season?.map((s) => `${s.value}`) ?? [],
          shouldShowRunFlat:
            ctx.data.dimensions?.skipRunFlat === undefined
              ? ctx.data.dimensions?.skipRunFlat
              : !ctx.data.dimensions?.skipRunFlat,
          subcategoryId: `${ctx.data.dimensions?.subcategory?.value}`,
        };

        await ctx.mutateDimensions(data);
      }
    } catch (e: any) {
      if ('message' in e) {
        alert(e.message);
      } else {
        console.log(e);
      }
    }
  }, [ctx]);

  const existingResponse = ctx.data.lastFetchSource === ctx.data.type;
  const isFetchingPriceOrCode =
    ctx.isFetchingCodeRequest || ctx.isFetchingDimensionsRequest;

  return (
    <PageTemplateWrapper>
      <PageTemplateContent>
        <FlowHeader />

        <TyrioTabs
          onTabChange={(data) => {
            ctx.setInputValue({
              type: data.currentTabIdx === 0 ? 'code' : 'dimension',
            });
          }}
        >
          <TyrioTab
            initial={ctx.data.type === 'code'}
            title={'Compare by code'}
          >
            <PageLayout>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <ImportTextArea />
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" variant="middle">
                    {'>>'}
                  </Divider>
                </Grid>
                <Grid item xs={5}>
                  <SupplierSelectArea />
                </Grid>
              </Grid>
            </PageLayout>
          </TyrioTab>
          <TyrioTab
            initial={ctx.data.type === 'dimension'}
            title={'Compare by dimension'}
          >
            <PageLayout>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <DimensionArea errors={errors} setErrors={setErrors} />
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" variant="middle">
                    {'>>'}
                  </Divider>
                </Grid>
                <Grid item xs={5}>
                  <SupplierSelectArea />
                </Grid>
              </Grid>
            </PageLayout>
          </TyrioTab>
        </TyrioTabs>
        <BottomFixed>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant={'outlined'}
                color={'info'}
                startIcon={<Cancel color={'info'} />}
                onClick={() => {
                  ctx.reset();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={9}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {ctx.data.supplierTransferList?.right.length === 0 &&
                !ctx.data.mainSupplier && (
                  <Alert
                    severity="warning"
                    sx={{ marginRight: 2, padding: '0 16px' }}
                  >
                    You must select at least one supplier or main supplier
                    before continuing.
                  </Alert>
                )}
              {existingResponse && (
                <Button
                  variant={'outlined'}
                  color={'info'}
                  onClick={() => ctx.setInputValue({ screen: 'table' })}
                  endIcon={<ArrowForward />}
                  style={{ marginRight: `${tyrioUI.spacing.l}px` }}
                >
                  Back to order in progress
                </Button>
              )}
              <Tooltip
                title={
                  existingResponse
                    ? `There's an existing active order. Running compare prices again will fetch new prices.`
                    : ''
                }
                placement={'top'}
              >
                <Button
                  variant={'contained'}
                  color={'info'}
                  onClick={() => {
                    if (
                      ctx.data.type === 'dimension' &&
                      (!ctx.data.dimensions?.sizes ||
                        isEmpty(ctx.data.dimensions?.sizes))
                    ) {
                      setErrors({ dimensions: true });
                    } else handleSubmit();
                  }}
                  endIcon={
                    isFetchingPriceOrCode ? (
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                    ) : (
                      <ArrowForward />
                    )
                  }
                  disabled={
                    isFetchingPriceOrCode ||
                    (ctx.data.supplierTransferList?.right.length === 0 &&
                      !ctx.data.mainSupplier)
                  }
                  disableElevation
                  style={{ marginRight: `${tyrioUI.spacing.l}px` }}
                >
                  {existingResponse ? 'Refetch prices' : 'Compare prices'}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </BottomFixed>
        <BottomSpacer />
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};

const BottomFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const BottomSpacer = styled.div`
  display: block;
  height: 100px;
  width: 100%;
`;

const PageLayout = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
`;
