import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, IconButton, Typography } from '@mui/material';
import * as _ from 'lodash';
import {
  IVehicleFilter,
  useVehicleCtx,
} from '../../../../../context/VehicleContext';
import {
  Container,
  FilterWrapper,
  IconWrapper,
  Item,
} from '../../FilterSidebarList';

interface FilterProps {
  onOpen: () => void;
}

export const FilterDetails = ({ onOpen }: FilterProps) => {
  const { filter, setFilter } = useVehicleCtx();

  const get = (path: string) => _.get(filter, path, []) as string[];

  const handleDelete = (path: keyof IVehicleFilter, key: string) => {
    filter[path] = get(path).filter((elem) => elem !== key);
    setFilter({ ...filter });
  };

  return (
    <Container style={{ height: '50px' }}>
      <IconWrapper>
        <IconButton onClick={onOpen}>
          <MoreVertIcon />
        </IconButton>
        <Typography fontSize={14} fontWeight={500}>
          Filter
        </Typography>
      </IconWrapper>

      <FilterWrapper key="filter_wrapper">
        {constants.map((k) => {
          const path = k.key as unknown as keyof IVehicleFilter;
          const value = get(path);

          return (
            value?.length > 0 && (
              <>
                <Item>{k.name}:</Item>
                {value.map((elem) => (
                  <Chip
                    label={elem}
                    variant="outlined"
                    color="info"
                    size="small"
                    onDelete={() => handleDelete(path, elem)}
                    key={elem}
                    style={{ marginBottom: 8, marginLeft: '5px' }}
                  />
                ))}
              </>
            )
          );
        })}
      </FilterWrapper>
    </Container>
  );
};

const constants = [
  { key: 'brand', name: 'Brand' },
  { key: 'modelName', name: 'Model name' },
  { key: 'rimDiameter', name: 'Rim size' },
  { key: 'colorGroup', name: 'Color group' },
  { key: 'colorFinish', name: 'Color finish' },
  { key: 'width', name: 'Width' },
  { key: 'cb', name: 'Center bore' },
  { key: 'availability', name: 'Availability' },
];
