import api from '@tyrio/api-factory';
import {
  DBStockInItemsResponse,
  DBStockInListApi,
  GetOrderPricesRes,
  StockInLineItem,
} from '@tyrio/dto';
import _ from 'lodash';
import { useMutation } from 'react-query';

interface GetOrderProps {
  selectedOrderData: DBStockInItemsResponse;
  setSelectedOrderData: (item: DBStockInItemsResponse | null) => void;
}

export const useGetOrderPrices = (props: GetOrderProps) => {
  const { selectedOrderData, setSelectedOrderData } = props;

  const { data, status, mutateAsync } = useMutation(
    (id: string) =>
      api.fetch<DBStockInListApi['getOrderPrices']>('get_order_prices', {
        id,
      }),
    {
      mutationKey: 'get_order_prices',
      onSuccess: (d: GetOrderPricesRes) => {
        const priceMeta: GetOrderPricesRes = {};
        (selectedOrderData.lineItems as unknown as StockInLineItem[]).forEach(
          (item) => {
            if (d[item.ean]) priceMeta[item.ean] = d[item.ean];
          }
        );

        if (!_.isEmpty(priceMeta)) {
          setSelectedOrderData({
            ...selectedOrderData,
            priceMeta: priceMeta,
          });
        }
      },
    }
  );

  return { data, status, mutateAsync };
};
