import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { usePrintContext } from '../../../context/PrintContext';
import '../style.css';

export const PdfFooter = ({ footerText }: { footerText?: string }) => {
  const { numberOfPages } = usePrintContext();
  return (
    <Wrapper className="footer" hasFooterText={footerText !== ''}>
      <Upper>
        <PageInfo>Created with Tyrio software</PageInfo>
        <PageInfo>{`Page 1 of ${numberOfPages}`}</PageInfo>
      </Upper>
      {footerText && (
        <JustifyCenter>
          <Bottom>{footerText}</Bottom>
        </JustifyCenter>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hasFooterText?: boolean }>`
  height: ${(props) => (props.hasFooterText ? '104px' : '28px')};
  margin-top: 40px;
`;

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Upper = styled.div`
  height: 28px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const Bottom = styled.div`
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  width: 600px;

  text-align: center;
  font-family: Barlow;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
`;

const PageInfo = styled(Typography)`
  color: var(--Primary-Black-black-90, #454f5b);
  text-align: center;
  font-family: Barlow;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
`;
