import styled from '@emotion/styled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BuildIcon from '@mui/icons-material/Build';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Typography } from '@mui/material';
import { cartIcons } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { AppointmentItemLabel } from '../../pos/steps/Cart/Steps/Appointment/Appointment';
import { AppointmentItem, ShelfMeta } from '@tyrio/dto';

interface AppointmentCardProps {
  appointments: AppointmentItem[];
}

export const formatDate = (date: Date, format: 'date' | 'time') => {
  return moment(date).format(format === 'date' ? 'DD.MM.YYYY' : 'HH:mm');
};

export const AppointmentList = ({ appointments }: AppointmentCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const isAllCompleted = appointments?.some(
    (item) =>
      _.has(item, 'appointmentDateTo') && !_.isNull(item['appointmentDateTo'])
  );

  const isWarning = appointments?.some(
    (item) =>
      _.isUndefined(item['appointmentDateTo']) ||
      _.isNull(item['appointmentDateTo'])
  );

  const getIconColor = () => {
    if (isWarning) return '#E75524';

    return '#1976d2';
  };

  const getTextColor = () => {
    if (isWarning) return '#E75524';

    if (isHovered) return 'black';

    return '#919eab;';
  };

  const getBtnColor = () => {
    if (isWarning) return 'warning';

    return 'info';
  };

  const getItemStyle = (item: AppointmentItem) => {
    if (!item.appointmentDateTo) return 'warning';

    return 'primary';
  };

  const isItemWarning = (item: AppointmentItem) => {
    return (
      _.isUndefined(item.appointmentDateTo) || _.isNull(item.appointmentDateTo)
    );
  };

  const iconsProps = {
    sx: { opacity: '0.2' },
  };

  return (
    <Wrapper
      warning={isWarning}
      allCompleted={isAllCompleted}
      onMouseOver={() => {
        if (!isAllCompleted) setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isAllCompleted ? (
        <InfoWrapper2>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignContent="center">
              <WmsIcon
                icon={cartIcons.appointment}
                sx={{
                  color: getIconColor(),
                  width: '25px',
                  height: '25px',
                  marginRight: '12px',
                }}
                viewBox={'0 0 32 32'}
              />
              <Text2 textColor={getTextColor()}>Appointment</Text2>
            </Box>
            <Box>
              <EditIcon
                color="primary"
                sx={{
                  '&:hover': {
                    color: isWarning ? '#E75524' : '#1976d2',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            {appointments.map((item) => {
              if (item.appointmentDateFrom) {
                const appointmentDateFrom = formatDate(
                  item.appointmentDateFrom,
                  'date'
                );

                const appointmentTimeFrom = formatDate(
                  item.appointmentDateFrom,
                  'time'
                );

                const appointmentTimeTo = item.appointmentDateTo
                  ? formatDate(item.appointmentDateTo, 'time')
                  : '';

                return (
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginTop="10px"
                    >
                      <AppointmentItemLabel
                        icon={
                          <DateRangeIcon
                            color={getItemStyle(item)}
                            {...iconsProps}
                          />
                        }
                        isWarning={isItemWarning(item)}
                        label={appointmentDateFrom}
                        showBadge={true}
                        isActive={item.isActive}
                      />
                      <AppointmentItemLabel
                        icon={
                          <AccessTimeIcon
                            color={getItemStyle(item)}
                            {...iconsProps}
                          />
                        }
                        isWarning={isItemWarning(item)}
                        label={`${appointmentTimeFrom} - ${appointmentTimeTo}`}
                      />
                      <AppointmentItemLabel
                        icon={
                          <BuildIcon
                            color={getItemStyle(item)}
                            {...iconsProps}
                          />
                        }
                        isWarning={isItemWarning(item)}
                        label={
                          (item.reservedBox?.meta as unknown as ShelfMeta)
                            ?.subzoneName as string
                        }
                      />
                      {item.workOrders && (
                        <AppointmentItemLabel
                          icon={
                            <DescriptionIcon
                              color={getItemStyle(item)}
                              {...iconsProps}
                            />
                          }
                          isWarning={isItemWarning(item)}
                          label={item.workOrders?.orderNumber as string}
                        />
                      )}
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginTop={1}
                  >
                    <WarningIcon color="warning" />
                    <Text2 textColor={getTextColor()}>
                      Appointment missing
                    </Text2>
                    <Button
                      endIcon={<AddCircleOutlineIcon color={getBtnColor()} />}
                      color={getBtnColor()}
                      onClick={() => {
                        //   setSelectedAppointment(item.id);
                      }}
                    >
                      ADD
                    </Button>
                  </Box>
                );
              }
            })}
          </Box>
        </InfoWrapper2>
      ) : (
        <InfoWrapper>
          <WmsIcon
            icon={cartIcons.appointment}
            sx={{
              color: getIconColor(),
              width: '30px',
              height: '30px',
            }}
            viewBox={'0 0 32 32'}
          />
          <Text textColor={getTextColor()}>Appointment</Text>
        </InfoWrapper>
      )}
      {isHovered && !isAllCompleted && (
        <Button
          endIcon={<AddCircleOutlineIcon color={getBtnColor()} />}
          color={getBtnColor()}
        >
          ADD
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  warning: boolean;
  allCompleted: boolean;
}>`
  border: 1px dashed ${(props) => (props.warning ? `#E75524` : `#e6e6e6`)};
  border-radius: 8px;
  height: ${(props) => (props.allCompleted ? `unset` : `74px !important`)};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  ${(props) =>
    !props.allCompleted &&
    `
    &:hover {
      border: 1px dashed ${props.warning ? `#E75524` : `#1976d2`};
      background: ${
        props.warning ? `rgba(231, 85, 36, 0.04)` : `rgba(25, 117, 210, 0.04)`
      };
    }
  `}
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const InfoWrapper2 = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
`;

const Text = styled(Typography)<{ textColor: string }>`
  color: ${(props) => props.textColor};
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
`;

const Text2 = styled(Typography)<{ textColor: string }>`
  color: ${(props) => props.textColor};
  font-family: Barlow;
  font-weight: 400;
  font-size: 14px;
`;
