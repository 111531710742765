import styled from '@emotion/styled';
import _ from 'lodash';
import { QRCodeSVG } from 'qrcode.react';
import { getTitle, isShelf } from '../../helper/helper';
import ArrowUp from './../../assets/arrow-up.svg';

interface LocationStickerProps {
  location: string;
  qrCode: string;
}

const HorizontalSticker = (props: LocationStickerProps) => {
  const { location, qrCode } = props;

  const isShelfLocation = isShelf(qrCode);

  const title = getTitle(location, isShelfLocation);

  return (
    <Container>
      <QrCodeWrapper>
        <QRCodeSVG value={qrCode} style={{ height: 110, width: 110 }} />
      </QrCodeWrapper>
      <LocationInfoWrapper>
        <RackInfoWrapper>
          <RackTitle>{title}</RackTitle>
          <FinalLabel>{_.last(location)}</FinalLabel>
        </RackInfoWrapper>
        <LocationWrapper>
          <LocationIcon>
            <Location>{location}</Location>
            <ArrowUpIcon src={ArrowUp} />
          </LocationIcon>
        </LocationWrapper>
      </LocationInfoWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 90mm;
  height: 35mm;
  background: #fff;
`;

const QrCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0 10px;
`;

const LocationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const RackInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RackTitle = styled.span`
  font-family: Helvetica;
  font-size: 55px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
`;

const FinalLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica;
  font-size: 55px;
  font-weight: 700;
  line-height: 50px;
  background: black;
  color: white;
  width: 75px;
  height: 75px;
`;

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LocationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Location = styled.span`
  font-family: Helvetica;
  font-size: 30px;
  font-weight: 50;
  line-height: 50px;
`;

const ArrowUpIcon = styled.img`
  padding-right: 10px;
  height: 20px;
`;

export default HorizontalSticker;
