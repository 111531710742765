/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { AccordionSummary, Button, Divider } from '@mui/material';
import { DBTransferOrderType } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBStockList, DBTransferOrderItemApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../../../../src/app/query-client';
import { CartContext } from '../../../../context/CartContext';
import { StockListContext } from '../../../../context/StockListContext';
import { useWS } from '../../../../context/WSContext';
import { ExpandCollapseButtons } from '../../../supplier-form/helpers/components';
import { Countdown } from '../../../../components/Timer/Countdown';
import { CartItems } from './CartItems';
import {
  formatDataForTransferOrder,
  removeItemFromOutbounds,
  useCartData,
} from './helper/cart-helper';
import { CartItem, ExpandedDataProps } from './helper/interfaces';
import { AxiosError } from 'axios';

export type CartType = [string, Record<string, any>];

export const FullCart = ({
  inbound,
  outbound,
}: {
  inbound: CartType[];
  outbound: CartType[];
}) => {
  const ws = useWS();

  const {
    setOrderSent,
    selectedTableRow,
    outboundOrderItems,
    setOutboundOrderItems,
  } = useContext(StockListContext);
  const { timer } = useContext(CartContext);
  const { numberOfCartItems } = useCartData();

  const o = useMemo(() => {
    return outbound.map((d: CartType) => {
      const n = d[0];
      const m = d[1]['remark'];
      return { [n]: m };
    });
  }, [outbound]);

  const remark = useMemo(() => {
    return {
      inbound: inbound.map((d: CartType) => {
        const n = d[0];
        const m = d[1]['remark'];
        return { [n]: m };
      }),

      outbound: o,
    };
  }, [inbound, o]);

  const [expandedData, setExpandedData] = useState<ExpandedDataProps>({
    INBOUND: [],
    OUTBOUND: [],
  });

  const displayTime = timer?.getDisplayTime();
  const { minutes, seconds } = displayTime ?? { minutes: 0, seconds: 0 };

  const handleDelete = async (
    item: CartItem,
    branchId: number,
    branchName: string,
    type: DBTransferOrderType,
    branchCity: string,
    warehouseId: string
  ) => {
    const itemId = item.ean + '_' + item.dot + '_' + branchId;
    if (type === DBTransferOrderType.INBOUND) {
      ws.socket?.emit('inbound-remove-item-from-cart', {
        branchId: branchId,
        branchName: branchName,
        branchCity: branchCity,
        warehouseId: warehouseId,
        itemId: itemId,
      });
    } else {
      // CHANGE QTY ON MAIN BRANCH AFTER ITEM IS DELETED FROM CART
      // leave until everything is tested
      // const order = outboundOrders[0]?.lineItems.filter(
      //   (singleItem: DBBranchStockLineItems) =>
      //     singleItem.ean === item.ean && item.dot === singleItem.dot
      // )[0];

      // if (!isEmpty(order)) {
      //   order.quantity += item.quantity;
      // }

      // REMOVE ITEM FROM outboundOrderItems ARRAY
      if (!isEmpty(outboundOrderItems)) {
        removeItemFromOutbounds(
          outboundOrderItems,
          branchId,
          Number(warehouseId),
          item
        );
      }

      ws.socket?.emit('outbound-remove-item-from-cart', {
        branchId: branchId,
        branchName: branchName,
        branchCity: branchCity,
        warehouseId: warehouseId,
        itemId: itemId,
      });
    }
    if (numberOfCartItems > 1) timer?.restart();
    else timer?.clear();
  };

  const createTransferOrder = useMutation(
    (req: DBTransferOrderItemApi['create']['request'][]) =>
      api.fetch<DBTransferOrderItemApi['createMany']>(
        'create_many_transfer_orders',
        {
          req,
        }
      ),
    {
      mutationKey: 'create_many_transfer_orders',
      onSuccess: () => {
        queryClient.refetchQueries('get_stock_list_products');
        setOrderSent(true);
        ToastHelper.showToast(
          'Transfer order',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Transfer order successfully created!'
        );

        // REMOVE EVERYTHING FROM CART
        ws.socket?.emit('remove-all-cart-items', {});
        setOutboundOrderItems([]);
        timer?.clear();
      },
      onError: (err: AxiosError) => {
        ToastHelper.showToast(
          'Transfer order',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          (err?.response?.data as any)?.error.name ?? 'Something went wrong!'
        );
      },
    }
  );

  const handleSendOrder = () => {
    const sendInbound = formatDataForTransferOrder(
      inbound,
      (selectedTableRow as DBStockList)?.id
    );
    const sendOutbound = formatDataForTransferOrder(
      outbound,
      (selectedTableRow as DBStockList)?.id
    );

    const req: DBTransferOrderItemApi['create']['requestBody'] = {
      items: sendInbound.filter((s) => !isEmpty(s.lineItems)),
      type: DBTransferOrderType.INBOUND,
    };

    const reqOutbound: DBTransferOrderItemApi['create']['requestBody'] = {
      items: sendOutbound.filter((s) => !isEmpty(s.lineItems)),
      type: DBTransferOrderType.OUTBOUND,
    };

    const body = [req, reqOutbound].filter((item) => !isEmpty(item.items));

    createTransferOrder.mutate(body);

    if (
      !isEmpty(inbound) &&
      isEmpty(req.items) &&
      !isEmpty(outbound) &&
      isEmpty(reqOutbound.items)
    ) {
      ToastHelper.showToast(
        'Transfer order',
        ToastType.WARNING,
        ToastMessageType.CUSTOM_ERROR,
        'Cart items quantity must be greater than 0!'
      );
    }
  };

  const handleRemarkChange = useCallback(
    (remarkVal: string, branch: string, type: DBTransferOrderType) => {
      if (type === DBTransferOrderType.INBOUND)
        ws.socket?.emit('inbound-add-remark', {
          branch,
          remark: remarkVal,
        });
      else {
        ws.socket?.emit('outbound-add-remark', {
          branch,
          remark: remarkVal,
        });
      }
    },
    [ws.socket]
  );

  const expandCollapseAll = () => {
    const data = [...expandedData.INBOUND, ...expandedData.OUTBOUND];
    const a =
      data.length > 0
        ? {
            INBOUND: [],
            OUTBOUND: [],
          }
        : {
            INBOUND: inbound.map((_item, index: number) => index) ?? [],
            OUTBOUND: outbound.map((_item, index: number) => index) ?? [],
          };
    setExpandedData(a);
  };

  const getOutputData = useCallback(
    (m: CartType, index: number, type: string) => {
      return {
        branch: m[0].split('_'),
        branchName: m[0].split('_')[1],
        branchCity: m[0].split('_')[2],
        warehouseId: m[0].split('_')[3],
        remarkValue:
          type === 'inbound'
            ? remark?.inbound[index][m[0]]
            : remark?.outbound[index][m[0]],
      };
    },
    [remark.inbound, remark.outbound]
  );

  return (
    <CartItemsWrapper>
      <ExpandCollapseButtons
        color={
          [...expandedData.INBOUND, ...expandedData.OUTBOUND].length > 0
            ? 'warning'
            : 'success'
        }
        flag={[...expandedData.INBOUND, ...expandedData.OUTBOUND].length > 0}
        onExpandedDataChange={() => expandCollapseAll()}
      />
      <div style={{ height: '70%', overflow: 'scroll' }}>
        {inbound.map((m: CartType, i: number) => {
          const { branch, branchName, branchCity, warehouseId, remarkValue } =
            getOutputData(m, i, 'inbound');

          return (
            <CartItems
              expandedData={expandedData}
              i={i}
              branchName={branchName}
              branchCity={branchCity}
              branchId={branch[0]}
              handleDelete={handleDelete}
              remarkValue={remarkValue}
              handleRemarkChange={handleRemarkChange}
              cartValues={m[1]}
              type={DBTransferOrderType.INBOUND}
              setExpandedData={setExpandedData}
              warehouseId={warehouseId}
              key={i}
            />
          );
        })}

        {outbound.map((m: CartType, i: number) => {
          const { branch, branchName, branchCity, warehouseId, remarkValue } =
            getOutputData(m, i, 'outbound');

          return (
            <CartItems
              expandedData={expandedData}
              i={i}
              branchName={branchName}
              branchCity={branchCity}
              branchId={branch[0]}
              handleDelete={handleDelete}
              remarkValue={remarkValue}
              handleRemarkChange={handleRemarkChange}
              cartValues={m[1]}
              type={DBTransferOrderType.OUTBOUND}
              setExpandedData={setExpandedData}
              warehouseId={warehouseId}
              key={i}
            />
          );
        })}
      </div>

      <FooterWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Divider
            variant="fullWidth"
            sx={{
              borderWidth: '2px',
              margin: '24px 0 0px 0',
              borderColor: '#E6E8F0',
            }}
          />

          <Countdown cart={true} minutes={minutes} seconds={seconds} />

          <ButtonWrapper>
            <Button
              variant="contained"
              color="info"
              onClick={handleSendOrder}
              sx={{ width: '380px', height: '60px' }}
              disableElevation
            >
              Send order
            </Button>
          </ButtonWrapper>
        </div>
      </FooterWrapper>
    </CartItemsWrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RemarkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  background: white;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 0;
  & .MuiAccordionSummary-content {
    gap: 15px;
  }
`;

const CartItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FooterWrapper = styled.div`
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
