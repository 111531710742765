import styled from '@emotion/styled';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { usePosCartCtx } from '../../../../../context/PosCartContext';
import { useEffect, useState } from 'react';
import QuantityComponent from '../../../components/StockItemDetails/QuantityComponent/QuantityComponent';
import { useCalculatePrice } from '../helpers/calculation';
import { usePosCartData } from '../helpers/cart-data';
import { usePosCtx } from '../../../../../context/POSContext';
import { formatCurrencyWithoutSymbol } from '../../../../../helpers/currency-format';

interface DeliveryTypeItemProps {
  name: string;
  range: string;
  price: number;
  quantity: number;
}

export const DeliveryTypeItem = ({
  name,
  range,
  price,
  quantity = 1,
}: DeliveryTypeItemProps) => {
  const { isVatIncluded, selectedCustomer } = usePosCtx();
  const { modalData } = usePosCartData();
  const { vat } = useCalculatePrice(
    modalData,
    price,
    isVatIncluded,
    selectedCustomer?.rebateCalculation?.rebate ?? 0,
    selectedCustomer?.rebateCalculation?.discount ?? 0
  );
  const { input, setInput, setDeliveryPrice } = usePosCartCtx();

  const [packageQty, setPackageQty] = useState(1);

  const handleDelete = () => {
    setInput((prevState) => ({
      ...prevState,
      delivery_method: {
        address: input.delivery_method.address,
        deliveryType: undefined,
      },
    }));
    setDeliveryPrice(0);
  };

  useEffect(() => {
    setDeliveryPrice((prevState) => {
      if (prevState !== price * packageQty) return price * packageQty;
      return prevState;
    });
  }, [packageQty, price, setDeliveryPrice]);

  const deliveryPrice = !isVatIncluded ? price : price + price * (vat / 100);

  return (
    <Wrapper>
      <ProductDetailsWrapper>
        <ProductDetailsText>{`${name}`}</ProductDetailsText>
        <ProductDescription>{range}</ProductDescription>
      </ProductDetailsWrapper>
      <Wrap>
        <PricesWrapper>
          <Text>{formatCurrencyWithoutSymbol(deliveryPrice ?? 0)}</Text>
          <Text>{formatCurrencyWithoutSymbol(0)}%</Text>
          <Text>{formatCurrencyWithoutSymbol(0)}%</Text>
        </PricesWrapper>
        <QuantityComponent
          quantity={quantity}
          maxAvailableQuantity={10}
          showButton={false}
          cart={true}
          changeCartItemQuantity={setPackageQty}
          minQuantity={1}
        />
        <Text>
          {formatCurrencyWithoutSymbol(Number(deliveryPrice * packageQty) ?? 0)}
        </Text>
        <CancelRoundedIcon
          style={{ cursor: 'pointer' }}
          sx={{ color: '#B9C4CE' }}
          onClick={handleDelete}
        />
      </Wrap>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-height: 72px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #dfe3e8;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70px;
  gap: 10px;
  white-space: nowrap;
  overflow-x: auto;
  width: calc(100% - 400px);
`;

const Text = styled.div`
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  width: 60px;
`;

const ProductDetailsText = styled.div`
  font-family: Barlow;
  font-weight: 400;
  max-height: 40px;
`;

const ProductDescription = styled.div`
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #919eab;
`;

const PricesWrapper = styled.div`
  width: 240px;
  display: flex;
  justify-content: space-around;
`;

export const Wrap = styled.div`
  min-width: 400px;
  max-width: 400px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;
