import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  PageTemplateSidebar,
  PageTemplateWrapper,
} from '../components/PageTemplate/PageTemplate';
import styled from '@emotion/styled';

interface ExpandableLayoutProps {
  sidebarComponent: React.ReactNode;
  children: React.ReactNode;
  landingPage?: React.ReactNode;
  modal?: React.ReactNode;
}

export const ExpandableLayout = ({
  sidebarComponent,
  children,
  landingPage,
  modal,
}: ExpandableLayoutProps) => {
  const location = useLocation();
  const { path } = useRouteMatch();

  const isDefaultLocation =
    location.pathname === path ||
    location.pathname === '/dashboard/company-settings/branches' ||
    location.pathname === '/dashboard/company-settings/roles' ||
    location.pathname === '/dashboard/company-settings/payment-method' ||
    location.pathname === '/dashboard/company-settings/users' ||
    location.pathname === '/dashboard/company-settings/delivery-method' ||
    location.pathname === '/dashboard/company-settings/shipping-couriers';

  //Special case for new menu system - client dashboard / company settings

  return (
    <PageTemplateWrapper>
      {modal}
      <SidebarWrapper default={isDefaultLocation}>
        <PageTemplateSidebar>{sidebarComponent}</PageTemplateSidebar>
      </SidebarWrapper>
      <ContentWrapper default={isDefaultLocation}>
        {!isDefaultLocation ? children : landingPage}
      </ContentWrapper>
    </PageTemplateWrapper>
  );
};

const SidebarWrapper = styled.div<{ default: boolean }>`
  @media (max-width: 768px) {
    width: 100%;
    display: ${(prop) => !prop.default && 'none'};
  }
`;

const ContentWrapper = styled.div<{ default: boolean }>`
  width: 100%;
  @media (max-width: 768px) {
    display: ${(prop) => prop.default && 'none'};
  }
`;
