import { CartData } from '../../../../../context/PosCartContext';

export const useCalculateData = (items: CartData[]) => {
  let totalQuantity = 0;
  let totalPrice = 0;
  let avgPrice = 0;

  if (items.length === 0) return { totalQuantity, totalPrice, avgPrice };

  // total quantity of the added items
  totalQuantity = items.reduce((acc, curr) => acc + curr.req.quantity, 0);
  // total price of added items
  totalPrice = items.reduce((acc, curr) => acc + curr.req.price, 0);

  // average price
  avgPrice = totalPrice / totalQuantity;
  avgPrice = Number(avgPrice.toFixed(2));

  return { totalQuantity, totalPrice, avgPrice };
};

// add function for calculation modal data prices
export const useCalculatePrice = (
  items: CartData[],
  delivery: number,
  isVatIncluded?: boolean,
  rebate?: number,
  discount?: number
) => {
  let total = 0;
  let grandTotal = 0;
  let vat = 0;
  let deliveryPrice = 0;

  vat =
    items.length > 0
      ? ((items[0].req.inclusiveVat - items[0].req.price) * 100) /
        items[0].req.price
      : 0;
  vat = Math.round(vat);

  deliveryPrice = !isVatIncluded ? delivery : delivery + delivery * (vat / 100);

  const prices = items.reduce(
    (acc, curr) => {
      const { stockType, req } = curr;
      const { price, inclusiveVat, quantity } = req;
      let finalPrice = isVatIncluded ? inclusiveVat ?? 0 : price;
      let inclusiveVatWithDiscount = inclusiveVat;
      let priceWithDiscount = price;

      if (rebate && rebate !== 0) {
        const decRebate = rebate / 100;

        finalPrice -= finalPrice * decRebate;
        inclusiveVatWithDiscount -= inclusiveVatWithDiscount * decRebate;
        priceWithDiscount -= priceWithDiscount * decRebate;
      }

      if (discount && discount !== 0) {
        const decDiscount = discount / 100;

        finalPrice -= finalPrice * decDiscount;
        inclusiveVatWithDiscount -= inclusiveVatWithDiscount * decDiscount;
        priceWithDiscount -= priceWithDiscount * decDiscount;
      }

      const totalPrice = finalPrice * quantity;

      if (stockType === 'SERVICES') {
        acc.servicesPrice += totalPrice;
        acc.servicesInclVat += inclusiveVat * quantity;
        acc.servicesExclVat += price * quantity;
      } else {
        acc.itemsPrice += totalPrice;
        acc.itemsInclVat += inclusiveVatWithDiscount * quantity;
        acc.subTotalExlVat += priceWithDiscount * quantity;
      }

      return acc;
    },
    {
      itemsPrice: 0,
      servicesPrice: 0,
      itemsInclVat: 0,
      servicesInclVat: 0,
      servicesExclVat: 0,
      subTotalExlVat: 0,
    }
  );

  prices.servicesPrice += deliveryPrice;
  total = prices.itemsPrice + prices.servicesPrice;
  grandTotal =
    prices.itemsInclVat +
    prices.servicesInclVat +
    delivery +
    delivery * (vat / 100);

  return {
    itemsPrice: prices.itemsPrice,
    servicesPrice: prices.servicesPrice,
    total,
    subTotalExlVat: prices.subTotalExlVat + prices.servicesExclVat + delivery,
    grandTotal,
    vat,
  };
};
