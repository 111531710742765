import styled from '@emotion/styled';
import { AlertTitle, Alert, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

export const ConfigurationRequired = () => {
  const history = useHistory();
  return (
    <StyledAlert severity="warning">
      <AlertTitle>Configuration Required</AlertTitle>
      <AlertWrapper>
        <div>
          To access the <strong>Services</strong> feature, you need to to set up
          the <strong>price per labor hour</strong> within the company settings.
          Please follow these steps (or simply click the button below):
          <br />
          <div>
            <p>
              1. Navigate to{' '}
              <strong>
                Menu {'>'} Settings {'>'} Company settings
              </strong>
              .
            </p>
            <p>
              2. Choose the <strong>General</strong> option.
            </p>
            <p>
              3. Enter the <strong> price per labor hour</strong> in the
              designated input field.
            </p>
          </div>
          Once you've completed these steps, you can start using the{' '}
          <strong>Services</strong> feature.
        </div>
        <div style={{ position: 'absolute', right: '10px', bottom: '10px' }}>
          <Button
            onClick={() => history.push(`/dashboard/company-settings/general`)}
            color="warning"
          >
            Go to General Settings
          </Button>
        </div>
      </AlertWrapper>
    </StyledAlert>
  );
};

const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`;

const StyledAlert = styled(Alert)`
  height: 250px;
  margin: 20px;
  width: calc(100% - 40px);
  position: relative;
`;
