import { MainBranchLocations } from '@tyrio/dto';
import { useWS } from '../../../../../context/WSContext';
import { useCallback } from 'react';
import { getReservedAndUpcomingValues } from '../../Cart/helper/socket-helper';

export const useLocations = (locations: MainBranchLocations[]) => {
  const ws = useWS();

  const alreadyUpdated: string[] = [];
  const locationsAfterUpdate: MainBranchLocations[] = [];
  const updatedLocationsIds: string[] = [];

  const getReserved = useCallback(
    (key: string, warehouseId: number) => {
      const { reserved } = getReservedAndUpcomingValues(
        ws?.state?.cart?.userCart,
        key,
        warehouseId
      );
      return reserved;
    },
    [ws?.state?.cart?.userCart]
  );

  const sortedLocations = locations.sort(function (a, b) {
    if (a.quantity - a.reserved > b.quantity - b.reserved) return -1;
    else if (a.quantity - a.reserved < b.quantity - b.reserved) return 1;
    else return 0;
  });

  sortedLocations.forEach((singleLocation, index) => {
    const available = singleLocation.quantity - singleLocation.reserved;
    const selectedItem = singleLocation.ean + '_' + singleLocation.dot;
    const warehouseId = singleLocation.clientStockList.branchId;

    const cartReserved = getReserved(selectedItem, warehouseId);

    // there is only one suitable location
    if (
      available >= cartReserved &&
      !alreadyUpdated.includes(selectedItem) &&
      cartReserved > 0
    ) {
      alreadyUpdated.push(selectedItem);
      singleLocation = {
        ...singleLocation,
        reserved: singleLocation.reserved + cartReserved,
      };

      locationsAfterUpdate.push(singleLocation);
      updatedLocationsIds.push(
        singleLocation.shelfId + singleLocation.clientStockList.id
      );
    }
    // multiple suitable locations
    else if (
      available < cartReserved &&
      !alreadyUpdated.includes(selectedItem) &&
      cartReserved > 0
    ) {
      const updatedLocations = multipleSuitableLocations(
        locations,
        singleLocation,
        index,
        cartReserved,
        selectedItem
      );
      alreadyUpdated.push(selectedItem);

      locationsAfterUpdate.push(...updatedLocations);
      updatedLocationsIds.push(
        ...updatedLocations.map((a) => a.shelfId + a.clientStockList.id)
      );
    }
    // item is not reserved, copy existing location
    else {
      if (
        !updatedLocationsIds.includes(
          singleLocation.shelfId + singleLocation.clientStockList.id
        )
      ) {
        locationsAfterUpdate.push(singleLocation);
        alreadyUpdated.push(selectedItem);
        updatedLocationsIds.push(
          singleLocation.shelfId + singleLocation.clientStockList.id
        );
      }
    }
  });

  // sort alphabetically
  const finalSort = locationsAfterUpdate.sort((a, b) => {
    if (a.locationMeta.qrCode > b.locationMeta.qrCode) return 1;
    else if (a.locationMeta.qrCode < b.locationMeta.qrCode) return -1;
    return 0;
  });

  return finalSort;
};

const multipleSuitableLocations = (
  locations: MainBranchLocations[],
  singleLocation: MainBranchLocations,
  index: number,
  reservedQty: number,
  selectedItem: string
) => {
  const anotherLocations = locations.filter(
    (l, idx) =>
      l.clientStockList.id === singleLocation.clientStockList.id &&
      index !== idx
  );

  let take = reservedQty;
  const updatedLocations: MainBranchLocations[] = [];

  [...anotherLocations, singleLocation].forEach((location) => {
    const selectedItem1 = singleLocation.ean + '_' + singleLocation.dot;
    if (selectedItem !== selectedItem1) return;
    if (take <= 0) return;
    const difference = location.quantity - location.reserved;

    if (difference <= 0) return;

    if (take - difference > 0) {
      take -= difference;
      location = { ...location, reserved: location.reserved + difference };

      updatedLocations.push(location);
    } else {
      const difference = take;
      take -= difference;
      location = { ...location, reserved: location.reserved + difference };

      updatedLocations.push(location);
    }
  });

  return updatedLocations;
};
