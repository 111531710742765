import styled from '@emotion/styled';

interface ItemProps {
  label: string;
  text: string;
  header?: boolean;
}

export const Item = (props: ItemProps) => {
  const { label, text, header } = props;

  return (
    <Container>
      <Label>{label}</Label>
      <Text header={header}>{text}</Text>
    </Container>
  );
};

const Container = styled.span`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const Text = styled.span<{ header?: boolean }>`
  font-size: 18px;
  font-weight: ${(props) => (props.header ? '600' : '500')};
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
`;
