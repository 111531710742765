import api from '@tyrio/api-factory';
import { DBDeliveryTypesApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetDeliveryTypeById = (id: string) => {
  const { data, refetch, isFetching } = useQuery(
    ['get_delivery_method', id],
    () =>
      api.fetch<DBDeliveryTypesApi['getOne']>(`get_delivery_method`, {
        id,
      }),
    {
      enabled: !!id,
    }
  );
  return { data, refetch, isFetchingDelivery: isFetching };
};
