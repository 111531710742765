import styled from '@emotion/styled';
import { DBServicesApi } from '@tyrio/dto';
import { wmsIcons } from '@tyrio/wms-ui-library';
import { Header } from '../../common/Header';
import { Card } from '../Recommended/Card';

interface OldTyresDisposalProps {
  items: DBServicesApi['recommended']['response'];
}

export const OldTyresDisposal = ({ items }: OldTyresDisposalProps) => {
  const pairs = [];
  for (let i = 0; i < items.length; i += 2) {
    if (items[i] && items[i + 1]) pairs.push([items[i], items[i + 1]]);
    else pairs.push([items[i]]);
  }

  return (
    <Wrapper>
      <Header
        icon={wmsIcons.details}
        altText={'details'}
        text={'Old tyres disposal'}
      />

      <TableWrapper>
        {pairs.map((pair, index) => (
          <Row key={index}>
            {pair.map((item, i) => (
              <Cell key={i}>
                <Card key={i} service={item} isTyreDisposal={true} />
              </Cell>
            ))}
          </Row>
        ))}
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border: 1px dashed #e6e6e6;
  border-radius: 8px;
  padding: 8px;
`;

const TableWrapper = styled.div`
  display: table;
  width: 100%;
`;

const Row = styled.div`
  display: table-row;
`;

const Cell = styled.div`
  display: table-cell;
  padding: 8px;
  max-width: 150px;
  max-height: 150px;
`;
