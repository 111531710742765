import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const INTERPNEU_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.category',
    condition: 'equals',
    conditionValues: [
      'PKW-SO',
      'PKW-WI',
      'PKW-AS',
      'OFF-ROAD-SO',
      'OFF-ROAD-WI',
      'OFF-ROAD-AS',
      'LLKW-SO',
      'LLKW-WI',
      'LLKW-AS',
      'ALUFELGEN',
      'STAHLFELGEN',
    ],
    conditionOutputs: [
      '30',
      '30',
      '30',
      '31',
      '31',
      '31',
      '32',
      '32',
      '32',
      '72',
      '73',
    ],
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'season',
    template: 'summer',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.season',
    condition: 'equals',
    conditionValues: ['S', 'W', 'A'],
    conditionOutputs: ['summer', 'winter', 'allseason'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // Model - rule for wheels
  {
    type: 'rule.map',
    sourceKey: "c.['specialMarking_wheelModel']",
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // Model - rule for carTyres
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|32',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'modelName',
    outputValue: '#{c.model}',
    outputType: 'string',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.ean',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.manufacturerCode',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'height',
    template: '',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|32', //carTyres
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'height',
    outputValue: '#{c.height_rimSize}',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'construction',
    template: '',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|32', //carTyres
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'construction',
    outputValue: '#{c.construction}',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.rimSize',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|32', //carTyres
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: '#{c.RFD_color}',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.rfd',
    matchValue: ['RF'],
    replaceValue: ['XL'],
  },
  // Weight
  {
    type: 'rule.map',
    sourceKey: 'c.weight',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  // Load index and Speed Index
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|32', //carTyres
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'loadIndex',
    outputValue: '#{c.LIET}',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|32', //carTyres
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'speedIndex',
    outputValue: '#{c.SI}',
    outputType: 'string',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'getIntersprintSpecialMarkings',
  },
  // ThreePMFs
  {
    type: 'rule.condition',
    sourceKey: 'c.3PMSF',
    condition: 'equals',
    conditionValue: '3PMSF',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'threepmfs',
    outputValue: true,
    outputType: 'boolean',
  },
  // Globalni fieldovi
  {
    type: 'rule.map',
    sourceKey: 'c.wetGrip',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.rollingResistance',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.noisePerformance',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.noiseClassType',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.euDirectiveNumber',
    targetKey: 'euDirectiveNumber',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'vehicleClass',
    template: '',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.euDirectiveNumber',
    condition: 'equals',
    conditionValue: '2020/740',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'eprelID',
    outputValue: '#{c.eprelID}',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 't.product.eprelId',
  //   targetKey: 'eprelUrl',
  //   destination: 'product',
  //   postProcess: ['getEprelUrlFromId'],
  // },

  // WHEELS
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalGetInterpneuPCD',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '72|73', //wheels
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rimDiameter',
    outputValue: '#{c.height_rimSize}',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.LIET',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '72|73', //wheels
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'cb',
    outputValue: '#{c.vehicleBrand}',
    outputType: 'number',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '72|73', //wheels
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'color',
    outputValue: '#{c.RFD_color}',
    outputType: 'string',
  },
  // ECE nedostaje u catalogu?
  // {
  //   type: 'rule.condition',
  //   sourceKey: 't.model.category_id',
  //   condition: 'in',
  //   conditionValue: '72|73', //wheels
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'ECE',
  //   outputValue: '#{c.dot_ECE}',
  //   outputType: 'string',
  // },

  ...GLOBAL_RULESET,
];
