import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {
  CheckRebateRes,
  RebateCalculationMetaRule,
  RebateCalculationType,
} from '@tyrio/dto';
import { ProductDescription } from '@tyrio/products';
import _ from 'lodash';
import { useState } from 'react';
import RuleDetails from '../../price-calculation/components/CheckRules/RuleDetails';
import { currency } from '../../price-calculation/helper/parser';
import { useGetRebates } from '../../price-calculation/queries/get-rebates';
import { useGetSuppliers } from '../../price-calculation/queries/get-suppliers';
import { useGetRebate } from '../queries';

interface ICheckRulesModal {
  isOpen: boolean;
  close: () => void;
  rebateId?: string;
}

export default function CheckRebatesModal(props: ICheckRulesModal) {
  const { isOpen, close, rebateId } = props;

  //STATES
  const [ean, setEan] = useState('');
  const [supplierId, setSupplierId] = useState('');
  const [rebateCalcId, setRebateCalcId] = useState(rebateId);
  const [data, setData] = useState<CheckRebateRes>();
  const [errorText, setErrorText] = useState('');

  //MUTATIONS
  const { getRebate } = useGetRebate({ setData, setErrorText });
  const { suppliersData } = useGetSuppliers({
    enabled: true,
  });
  const { rebatesData } = useGetRebates({
    enabled: !rebateId,
  });

  //FUNCTIONS
  const onClose = () => {
    setEan('');
    setSupplierId('');
    setRebateCalcId('');
    setData(undefined);
    setErrorText('');
    close();
  };

  const getItem = (label: string, content: string, sx?: SxProps<Theme>) => {
    return (
      <ItemContainer>
        <Item>{label}</Item>
        <Typography sx={sx}>{content}</Typography>
      </ItemContainer>
    );
  };

  const getAppliedRule = () => {
    return data?.meta?.rules?.find(
      (item) => item.id === data.meta?.appliedRuleId
    );
  };

  const getRuleAtt = (att: string) => {
    let conf: {
      ruleName?: string;
      priceTitle?: string;
      price?: string;
      rebate?: string;
      rulesCount?: number;
    } = {};

    let calculatedRebate = 0;

    if (data?.meta?.calculatedPrice && data.meta?.rebateCalculation?.rebate)
      calculatedRebate = Number(
        (
          (data?.meta?.calculatedPrice / 100) *
          data.meta?.rebateCalculation.rebate
        ).toFixed(2)
      );

    switch (data?.meta?.priceType) {
      case RebateCalculationType.DEFAULT_WITH_REBATE: {
        conf = {
          ruleName: 'default rebate',
          priceTitle: 'PRICE',
          price: `${data.meta.calculatedPrice}${currency}`,
          rulesCount: data.meta.rules?.length,
          rebate: `-${data.meta.rebateCalculation?.rebate}% - (${calculatedRebate}${currency})`,
        };
        break;
      }
      case RebateCalculationType.RULE_WITH_ACTION_REBATE: {
        const applied = getAppliedRule();

        if (data?.meta?.calculatedPrice && applied?.rebate)
          calculatedRebate = Number(
            ((data?.meta?.calculatedPrice / 100) * applied?.rebate).toFixed(2)
          );

        conf = {
          ruleName: 'action rebate',
          priceTitle: 'PRICE',
          price: `${data.meta.calculatedPrice}${currency}`,
          rulesCount: data.meta.rules?.length,
          rebate: `-${
            getAppliedRule()?.rebate
          }% - (${calculatedRebate}${currency})`,
        };
        break;
      }
      case RebateCalculationType.RULE_WITH_DEFAULT_REBATE_NO_RANGE: {
        conf = {
          ruleName: 'default rebate',
          priceTitle: 'PRICE',
          price: `${data.meta.calculatedPrice}${currency}`,
          rulesCount: data.meta.rules?.length,
          rebate: `-${data.meta.rebateCalculation?.rebate}% - (${calculatedRebate}${currency})`,
        };
        break;
      }
      case RebateCalculationType.RULE_WITH_DEFAULT_REBATE_NO_RULES: {
        conf = {
          ruleName: 'default rebate',
          priceTitle: 'PRICE',
          price: `${data.meta.calculatedPrice}${currency}`,
          rulesCount: data.meta.rules?.length,
          rebate: `-${data.meta.rebateCalculation?.rebate}% - (${calculatedRebate}${currency})`,
        };
        break;
      }

      default:
        break;
    }

    return _.get(conf, att);
  };

  const error = () => {
    return (
      <Alert severity="error" sx={{ marginTop: '10px' }}>
        {errorText}
      </Alert>
    );
  };

  const check = () => {
    if (_.isEmpty(ean)) {
      setErrorText('You must enter an EAN!');
      return false;
    }

    if (ean.length !== 13) {
      setErrorText('EAN must have 13 numbers!');
      return false;
    }

    if (_.isEmpty(supplierId)) {
      setErrorText('You must select an supplier!');
      return false;
    }

    if (rebateId === undefined && rebateCalcId === undefined) {
      setErrorText('You must select an rebate calculation!');
      return false;
    }

    return true;
  };

  return (
    <Grid>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isOpen}
        onClose={onClose}
      >
        <Container>
          <Grid>
            <Header>
              <Typography variant="h6" component="h2">
                Check rebate calculation
              </Typography>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </Header>
            {data && (
              <PriceTypeTypography style={{ fontSize: '14px' }}>
                {`${
                  data.meta?.rebateCalculation?.groupType
                } - ${data?.meta?.rebateCalculation?.groupName?.toUpperCase()}`}
              </PriceTypeTypography>
            )}
          </Grid>
          <Grid>
            {(data === undefined || !_.isEmpty(errorText)) && (
              <Grid>
                <TextField
                  label="EAN"
                  variant="outlined"
                  value={ean}
                  type="number"
                  onChange={(e) => {
                    setEan(e.target.value);
                    setErrorText('');
                  }}
                />
                {suppliersData && (
                  <FormControl fullWidth sx={{ marginTop: '10px' }}>
                    <InputLabel>Supplier</InputLabel>
                    <Select
                      value={supplierId}
                      label="Supplier"
                      onChange={(e) => {
                        setSupplierId(e.target.value);
                        setErrorText('');
                      }}
                    >
                      {suppliersData.map((s) => (
                        <MenuItem value={s.value}>{s.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!rebateId && rebatesData && (
                  <FormControl fullWidth sx={{ marginTop: '10px' }}>
                    <InputLabel>Rebate calculation</InputLabel>
                    <Select
                      value={rebateCalcId}
                      label="Rebate calculation"
                      onChange={(e) => {
                        setRebateCalcId(e.target.value);
                        setErrorText('');
                      }}
                    >
                      {rebatesData.map((s) => (
                        <MenuItem value={s.value}>{s.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  sx={{ marginTop: '10px' }}
                  onClick={() => {
                    if (!check()) return;

                    getRebate.mutate({
                      ean: ean,
                      rebateCalculationId: rebateId
                        ? rebateId
                        : rebateCalcId ?? '',
                      priceMeta: {
                        supplierId: supplierId,
                        isCheckPrice: true,
                      },
                    });
                  }}
                >
                  Check price
                </Button>
                {!_.isEmpty(errorText) && error()}
              </Grid>
            )}
            {data && ean !== '' && (
              <Grid>
                <Grid sx={{ marginBottom: '10px', marginTop: '15px' }}>
                  {data.product && (
                    <ProductDescription product={data.product} />
                  )}
                </Grid>
                <PriceTypeContainer>
                  <PriceTypeTypography>
                    FINAL REBATE CALCULATION
                  </PriceTypeTypography>
                  {getItem('APPLIED RULE', getRuleAtt('ruleName'), {
                    color: '#56C489',
                  })}
                  {getItem(getRuleAtt('priceTitle'), getRuleAtt('price'))}
                  {data.meta?.rules &&
                    data.meta?.rules?.length > 0 &&
                    getItem('RULES COUNT', getRuleAtt('rulesCount'))}
                  {getRuleAtt('rebate') !== '' &&
                    getItem('REBATE', getRuleAtt('rebate'))}
                </PriceTypeContainer>
                <FinalPriceContainer>
                  <Total>PRICE WITH REBATE:</Total>
                  <Total>
                    {data.price}
                    {currency}
                  </Total>
                </FinalPriceContainer>
                {(!data?.meta?.rules || data?.meta?.rules?.length === 0) && (
                  <Grid sx={{ marginTop: '15px' }}>
                    <Alert severity="info">
                      This price calculation doesn't have any rule for apply!
                    </Alert>
                  </Grid>
                )}
                {data?.meta?.rules && data.meta.rules.length > 0 && (
                  <Grid sx={{ marginTop: '15px' }}>
                    {data.meta.rules.map((rule: RebateCalculationMetaRule) => {
                      return (
                        <RuleDetails
                          type="rbt"
                          rule={rule}
                          appliedRuleId={data.meta?.appliedRuleId}
                        />
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      </Modal>
    </Grid>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: white;
  box-shadow: 24;
  padding: 16px;
  border-radius: 16px;
`;

const PriceTypeContainer = styled.div`
  background-color: #ecf0f4;
  padding: 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const FinalPriceContainer = styled.div`
  background: #637381;
  padding: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceTypeTypography = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const ItemContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Item = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #454f5b;
`;

const Total = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  color: #fff;
`;
