import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { WmsIcon } from '../WmsIcon/WmsIcon';

export const UnselectedRowWarning = ({
  icon,
  text,
  emptyData,
}: {
  icon: object;
  text?: string;
  emptyData?: string;
}) => {
  return (
    <Wrapper>
      <WmsIcon
        icon={icon}
        sx={{ color: '#B9C4CE', width: '200px', height: '200px' }}
      ></WmsIcon>
      <Typography
        variant="h6"
        fontWeight={500}
        color="#B9C4CE"
        style={{ fontSize: '20px', width: '70%' }}
      >
        {text
          ? `Please select product on the left side to see ${text}`
          : emptyData}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;
