import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useCheckEAN } from './queries/check-ean';
import { ProductDescription } from '@tyrio/products';
import { DBProductApi } from '@tyrio/dto';
import { currency } from '../price-calculation/helper/parser';
import { useAddPrice } from './queries/add-price';
import _ from 'lodash';
import CheckIcon from '@mui/icons-material/Check';

interface ICheckRulesModal {
  open: boolean;
  close: () => void;
}

export default function AddNewPrice(props: ICheckRulesModal) {
  const { open, close } = props;

  //STATES
  const [ean, setEan] = useState('');
  const [newPrice, setNewPrice] = useState<number | null>(null);
  const [errorText, setErrorText] = useState('');

  //MUTATIONS
  const { checkEan } = useCheckEAN({ setErrorText });
  const { addPrice } = useAddPrice({ setErrorText });

  //FUNCTIONS
  const onClose = () => {
    setEan('');
    setNewPrice(null);
    setErrorText('');
    checkEan.reset();
    addPrice.reset();
    close();
  };

  const error = () => {
    return (
      <Alert severity="error" sx={{ marginTop: '10px' }}>
        {errorText}
      </Alert>
    );
  };

  const check = () => {
    if (_.isEmpty(ean)) {
      setErrorText('You must enter an EAN!');
      return false;
    }

    if (ean.length !== 13) {
      setErrorText('EAN must have 13 numbers!');
      return false;
    }

    return true;
  };

  return (
    <Grid>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={onClose}
      >
        <Container>
          <Grid>
            <Header>
              <Typography variant="h6" component="h2">
                Add new custom price
              </Typography>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </Header>
          </Grid>
          {checkEan.data === undefined && (
            <Grid>
              <TextField
                label="EAN"
                variant="outlined"
                value={ean}
                type="number"
                onChange={(e) => {
                  setEan(e.target.value);
                  setErrorText('');
                }}
              />
              <Button
                variant="contained"
                color="info"
                fullWidth
                sx={{ marginTop: '10px' }}
                onClick={() => {
                  if (!check()) return;

                  checkEan.mutate({ ean: ean });
                }}
              >
                Check EAN
              </Button>
              {!_.isEmpty(errorText) && error()}
            </Grid>
          )}
          {checkEan.data && (
            <Grid>
              <Grid>
                {checkEan.data.productInfo ? (
                  <ProductDescription
                    product={
                      checkEan.data
                        .productInfo as DBProductApi['getOne']['response']
                    }
                  />
                ) : (
                  <Alert severity="warning" sx={{ marginTop: '10px' }}>
                    Product is not found in product catalog!
                  </Alert>
                )}
              </Grid>
              {addPrice.status === 'idle' && (
                <Grid>
                  {checkEan.data.price === null && (
                    <Alert severity="warning" sx={{ marginTop: '10px' }}>
                      There is no calculated price!
                    </Alert>
                  )}
                  <Grid sx={{ display: 'flex', gap: '7px', marginTop: '15px' }}>
                    {checkEan.data.price && (
                      <FormControl fullWidth>
                        <InputLabel>Current price</InputLabel>
                        <OutlinedInput
                          endAdornment={
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          }
                          label="Current price"
                          value={checkEan.data.price}
                          disabled
                        />
                      </FormControl>
                    )}
                    <FormControl fullWidth>
                      <InputLabel>New price</InputLabel>
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        }
                        label="New price"
                        value={newPrice}
                        type="number"
                        onChange={(e) => {
                          setNewPrice(Number(e.target.value));
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Button
                    variant="contained"
                    color="info"
                    fullWidth
                    sx={{ marginTop: '10px' }}
                    onClick={() => {
                      if (newPrice === 0 || newPrice === null) {
                        setErrorText('Price must be greater than 0!');
                        return;
                      }

                      addPrice.mutate({
                        ean: ean,
                        calculatedPrice: checkEan.data.price
                          ? Number(checkEan.data.price)
                          : null,
                        newPrice: newPrice,
                        productMeta: checkEan.data.productInfo,
                      });
                    }}
                  >
                    ADD CUSTOM PRICE
                  </Button>
                  {!_.isEmpty(errorText) && error()}
                </Grid>
              )}
              {addPrice.status === 'loading' && (
                <Grid
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <CircularProgress size={25} />
                </Grid>
              )}
              {addPrice.status === 'success' && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                  sx={{ marginTop: '10px' }}
                >
                  Price successfully changed!
                </Alert>
              )}
            </Grid>
          )}
        </Container>
      </Modal>
    </Grid>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 450px;
  background-color: white;
  box-shadow: 24;
  padding: 16px;
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
