import {
  DBBusinessType,
  DBClient,
  DBClientType,
  DBCurrency,
  Prisma,
} from '@prisma/client';

export const clients: DBClient[] = [
  {
    id: '00000000-0000-1000-b000-0000000000011',
    clientType: DBClientType.RETAIL,
    businessType: DBBusinessType.DOO,
    vatNumber: '4005960377290',
    vies: true,
    euVatNumber: 'ATU99999999',
    officialName: 'Guma M Sarajevo',
    shortName: 'GumaM',
    slug: 'guma-m',
    remark: 'Sarajevo distributer',
    addressId: '00000000-0000-1000-ad00-000000000000',
    referralId: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    IBAN: 'NL20RABO9372718300',
    swift: 'swift 1',
    currency: DBCurrency.EUR,
    businessCode: '',
    vatObligee: 'YES',
    intrastat: 'YES',
    registrationNumber: '',
    viesVatNumber: '',
    companyEmail: '',
    companyWebSite: '',
    companyBusinessPhone: '+38724141444',
    responsiblePersonJobTile: 'Mr',
    responsiblePersonFirstName: 'Ismet',
    responsiblePersonLastName: 'Ferhatbegovic',
    responsiblePersonEmail: '',
    responsiblePersonBusinessPhone: '',
    responsiblePersonMobilePhone: '',
    isUpdated: true,
    generalSettings: {},
    printNodeApiKey: '',
    printers: {} as Prisma.JsonValue,
    stockListImportSettings: {} as Prisma.JsonValue,
  },
  {
    id: '00000000-0000-1000-b000-0000000000022',
    clientType: DBClientType.WHOLESALE,
    businessType: DBBusinessType.OBRT,
    vatNumber: '5094729048303',
    vies: false,
    euVatNumber: 'EE999999999',
    officialName: 'Continental Gume BiH',
    shortName: 'Makbel',
    slug: 'continental-bih',
    remark: 'Glavni distributer u BiH',
    addressId: '00000000-0000-1000-ad00-000000000001',
    referralId: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    IBAN: 'NL06INGB4083166800',
    swift: 'swift 2',
    currency: DBCurrency.EUR,
    businessCode: '',
    vatObligee: 'YES',
    intrastat: 'YES',
    registrationNumber: '',
    viesVatNumber: '',
    companyEmail: '',
    companyWebSite: '',
    companyBusinessPhone: '+38724141444',
    responsiblePersonJobTile: 'Mr',
    responsiblePersonFirstName: 'Ismet',
    responsiblePersonLastName: 'Ferhatbegovic',
    responsiblePersonEmail: '',
    responsiblePersonBusinessPhone: '',
    responsiblePersonMobilePhone: '',
    isUpdated: true,
    generalSettings: {} as Prisma.JsonValue,
    printNodeApiKey: '',
    printers: {} as Prisma.JsonValue,
    stockListImportSettings: {} as Prisma.JsonValue,
  },
];
