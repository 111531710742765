import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function getDeldoSpecialMarkingsAndModelName(
  input: RawImport,
  rule: TRule
): Partial<UnfinishedData> {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'getDeldoSpecialMarkingsAndModelName requires rule to be of type TGlobalOperateRule'
    );

  const inputValues = [
    input['Pattern'].replace('i*cept', 'i-cept'),
    input['Spec'],
  ].join(' ');

  // Cinturato P7 (*)(MOE)

  const regexMatches = [...inputValues.matchAll(/\(([^)]+)\)/g)];

  let cleanInput = inputValues;
  regexMatches.forEach((m) => {
    cleanInput = cleanInput.replace(m[0], ` ${m[1]} `);
    // console.log('MATCH', m[0], m[1], cleanInput);
  });
  // Cinturato P7 * MOE
  cleanInput = cleanInput.replaceAll(/ +(?= )/g, '');

  return genericGenerateSpecialMarkings(cleanInput, () => false, true);
}

export function globalGetDeldoLoadSpeedIndex(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetDeldoLoadIndex requires rule to be of type TOperateRule'
    );

  const loadIndexMatches = input['Loadindex'].match(/\d+/g);
  const speedIndexMatches = input['Loadindex'].match(/[a-zA-Z]+/g);

  const data = {
    product: {
      loadIndex: loadIndexMatches ? loadIndexMatches[0] : '',
      speedIndex: speedIndexMatches
        ? speedIndexMatches[0].replace('XL', '')
        : '',
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}
