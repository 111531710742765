import api, { TOKEN } from '@tyrio/api-factory';
import { appConfig } from '@tyrio/config';
import { DBUserAuthApi } from '@tyrio/dto';
import 'firebase/auth';
import { getAuth, signInWithEmailLink, signOut } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import routeNames from '../app/src/lib/routeNames';

export const initFirebase = (): firebase.app.App => {
  return firebase.initializeApp(appConfig.firebase);
};

export const isSignInWithLink = () => {
  const auth = getAuth();

  const fetchAuth = async (token: string) => {
    const res = await api.fetch<DBUserAuthApi['validateToken']>(
      'token_validate',
      {
        token,
        type: 'email',
      }
    );
    TOKEN.set(res.token);
  };

  let email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt(
      'Please provide your email ! You probably opened the mail on another device, we disabled that option to prevent session fixation attacks. Please insert your email again. '
    );
  }
  if (email)
    signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        window.localStorage.removeItem('emailForSignIn');
        const user = result.user;
        const idTokenResult = await user.getIdTokenResult();
        await fetchAuth(idTokenResult.token);
        window.location.href = routeNames.dashboardOverview();
      })
      .catch((error) => {
        console.log('Login with link failed, error: ', error);
      });
};

export const firebaseSignOut = () => {
  const auth = getAuth();
  signOut(auth);
};

export const onLogout = () => {
  // NEED TO KEEP THIS BECAUSE OF THE CART
  const availableItems = localStorage.getItem('@@aI');
  window.localStorage.clear();
  localStorage.setItem('@@aI', availableItems as string);
  window.onbeforeunload = null;
  window.onunload = null;
  window.location.href = '/sign-in';
  firebaseSignOut();
};
