import { ReactComponent as customer } from './svg-changeable/customer.svg';
import { ReactComponent as truck } from './svg-changeable/truck.svg';
import { ReactComponent as appointment } from './svg-changeable/appointment.svg';
import { ReactComponent as vehicle } from './svg-changeable/vehicle.svg';
import { ReactComponent as payment } from './svg-changeable/payment.svg';
import { ReactComponent as addressCard } from './svg-changeable/address-card.svg';
import { ReactComponent as editAddress } from './svg-changeable/pencil-alt.svg';
import { ReactComponent as posCustomer } from './posCustomer.svg';
import { ReactComponent as serviceBox } from './svg-changeable/service-box.svg';

export {
  customer,
  truck,
  appointment,
  vehicle,
  payment,
  addressCard,
  editAddress,
  posCustomer,
  serviceBox,
};
