import styled from '@emotion/styled/macro';
import { FormControl, FormLabel, TextField } from '@mui/material';

export const RitamApiSettings = ({
  ritamUsername,
  setRitamUsername,
  ritamPassword,
  setRitamPassword,
}: {
  ritamUsername: string;
  ritamPassword: string;
  setRitamPassword: (a: string) => void;
  setRitamUsername: (a: string) => void;
}) => {
  return (
    <Wrapper>
      <FormLabel>Ritam api settings:</FormLabel>
      <RitamApiWrapper>
        <FormControl style={{ width: '42%' }}>
          <TextField
            label="Ritam api username:"
            sx={{ marginTop: '12px' }}
            onChange={({ target: { value } }) => {
              setRitamUsername(value);
            }}
            type="text"
            value={ritamUsername}
          />
        </FormControl>

        <FormControl style={{ width: '42%' }}>
          <TextField
            label="Ritam api password:"
            sx={{ marginTop: '12px' }}
            onChange={({ target: { value } }) => {
              setRitamPassword(value);
            }}
            type="text"
            value={ritamPassword}
          />
        </FormControl>
      </RitamApiWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const RitamApiWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
