import { DeliveryType } from '@prisma/client';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { FormShape } from '../../services/constants/service.form';

// TODO: add method for generating these forms
export const DeliveryPriceListFormShape = (
  deliveryType: DeliveryType,
  cashOnDelivery?: boolean,
  cardOnDelivery?: boolean,
  freeDelivery?: boolean,
  countries?: TyrioSelectInputOption[]
) => {
  const mainSettings: FormShape[] = [
    {
      key: 'main_settings',
      title: deliveryType === 'WMS_SHIPPING' ? 'Default Settings' : 'Settings',
      inputs: [
        {
          id: 'daysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxDaysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Max days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'minOrderQuantity',
          width: '14%',
          type: 'input.string',
          label: 'Min order amount *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxPackageWeight',
          width: '14%',
          type: 'input.string',
          label: 'Max package per weight *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'cashOnDelivery',
          width: '17%',
          type: 'input.switch',
          label: 'Cash on delivery',
          hasPadding: false,
          skipField: deliveryType === 'WMS_COLLECTION',
        },
        {
          id: 'cardOnDelivery',
          width: '17%',
          type: 'input.switch',
          label: 'Card on delivery',
          hasPadding: false,
          skipField: deliveryType === 'WMS_COLLECTION',
        },
      ],
    },
    {
      inputs: [
        {
          id: 'blacklistZIPCodes',
          width: '100%',
          type: 'input.string',
          label: 'Blacklist ZIP Codes',
          hasPadding: false,
          skipField: deliveryType !== 'WMS_SHIPPING',
        },
      ],
    },
    {
      inputs: [
        {
          id: 'whitelistZIPCodes',
          width: '100%',
          type: 'input.string',
          label: 'Whitelist ZIP Codes',
          hasPadding: false,
          skipField: deliveryType !== 'WMS_SHIPPING',
        },
      ],
    },
  ];

  const parcelInternational: FormShape[] = [
    {
      key: 'parcel_international_settings',
      title: deliveryType === 'WMS_SHIPPING' ? 'Default Settings' : 'Settings',
      inputs: [
        {
          id: 'daysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxDaysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Max days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'minOrderQuantity',
          width: '14%',
          type: 'input.string',
          label: 'Min order amount *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxPackageWeight',
          width: '14%',
          type: 'input.string',
          label: 'Max package per weight *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'cashOnDelivery',
          width: '17%',
          type: 'input.switch',
          label: 'Cash on delivery',
          hasPadding: false,
        },
        {
          id: 'cardOnDelivery',
          width: '17%',
          type: 'input.switch',
          label: 'Card on delivery',
          hasPadding: false,
        },
      ],
    },
    {
      inputs: [
        {
          id: 'countries',
          width: '100%',
          type: 'input.multiselect',
          label: 'Choose Countries',
          options:
            countries?.map((item: TyrioSelectInputOption) => {
              return {
                label: item.label,
                value: item.value,
              };
            }) ?? [],
          hasPadding: false,
        },
      ],
    },
  ];

  const price: FormShape[] = [
    {
      inputs: [
        {
          id: 'priceType',
          width: '100%',
          type: 'input.radio',
          label: '',
          options: [
            { label: 'Price per order', value: 'pricePerOrder' },
            { label: 'Price per package', value: 'pricePerPackage' },
            {
              label: 'Price per weight',
              value: 'pricePerWeight',
              disabled: true,
            },
            {
              label: 'Price per product category',
              value: 'pricePerProductCategory',
              disabled: true,
            },
          ],
        },
      ],
    },
    {
      key: 'delivery_price',
      inputs: [
        {
          id: 'deliveryPrice',
          width: '30%',
          type: 'input.string',
          label: 'Delivery price',
          inputType: 'number',
        },
      ],
    },
    {
      key: 'divider',
      inputs: [
        {
          id: 'divider',
          type: 'input.divider',
          width: '100%',
        },
      ],
    },
    {
      key: 'price_setting',
      title: 'Default Prices',
      inputs: [
        {
          id: 'freeDelivery',
          width: '17%',
          type: 'input.switch',
          label: 'Free delivery',
        },
        {
          id: 'minOrderQuantity2',
          width: '14%',
          type: 'input.string',
          label: 'Min Order Quantity',
          hasPadding: false,
          skipField: !freeDelivery,
          inputType: 'number',
        },
        {
          id: 'minOrderAmount',
          width: '14%',
          type: 'input.string',
          label: 'Min Order Amount',
          hasPadding: false,
          skipField: !freeDelivery,
          inputType: 'number',
        },
      ],
    },
  ];

  const surcharge: FormShape[] = [
    {
      key: 'surcharge',
      title:
        deliveryType === 'WMS_SHIPPING' ? 'Default Surcharges' : 'Surcharges',
      inputs: [
        {
          id: 'CODSurcharge',
          width: '30%',
          type: 'input.string',
          label: 'COD Surcharge',
          disabled: cardOnDelivery,
        },
        {
          id: 'CODSurchargeDisabled',
          width: '30%',
          type: 'input.string',
          label: 'CCOD Surcharge',
          disabled: cashOnDelivery,
        },
        {
          id: 'minQuantitySurcharge',
          width: '30%',
          type: 'input.string',
          label: 'Min Quantity Surcharge',
          inputType: 'number',
        },
      ],
    },
  ];

  const localDispatchSettings: FormShape[] = [
    {
      key: 'settings',
      title: 'Settings',
      inputs: [
        {
          id: 'daysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxDaysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Max days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'minOrderQuantity',
          width: '14%',
          type: 'input.string',
          label: 'Min order amount *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxPackageWeight',
          width: '14%',
          type: 'input.string',
          label: 'Max package per weight *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'cashOnDelivery',
          width: '17%',
          type: 'input.switch',
          label: 'Cash on delivery',
          hasPadding: false,
        },
        {
          id: 'cardOnDelivery',
          width: '17%',
          type: 'input.switch',
          label: 'Card on delivery',
          hasPadding: false,
        },
      ],
    },
  ];

  const collectionSettings: FormShape[] = [
    {
      key: 'settings',
      title: 'Settings',
      inputs: [
        {
          id: 'daysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxDaysToDeliver',
          width: '14%',
          type: 'input.string',
          label: 'Max days to deliver *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'minOrderQuantity',
          width: '14%',
          type: 'input.string',
          label: 'Min order amount *',
          hasPadding: false,
          inputType: 'number',
        },
        {
          id: 'maxPackageWeight',
          width: '14%',
          type: 'input.string',
          label: 'Max package per weight *',
          hasPadding: false,
          inputType: 'number',
        },
      ],
    },
  ];

  const collectionSurcharge: FormShape[] = [
    {
      key: 'surcharge',
      title: 'Surcharges',
      inputs: [
        {
          id: 'minQuantitySurcharge',
          width: '30%',
          type: 'input.string',
          label: 'Min Quantity Surcharge',
          inputType: 'number',
        },
      ],
    },
  ];

  return {
    mainSettingsForm: mainSettings,
    parcelInternational,
    priceForm: price,
    surchargeForm: surcharge,
    localDispatchSettings,
    collectionSettings,
    collectionSurcharge,
  };
};
