import api from '@tyrio/api-factory';
import { WheelSizeApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetVehicleModels = (model: string, enabled: boolean) => {
  const { data, isFetched, isError, isLoading, isRefetching } = useQuery(
    ['get_models'],
    () => api.fetch<WheelSizeApi['getOneModel']>('get_models', { model }),
    {
      enabled,
    }
  );

  return {
    vehicleModels: data?.data ?? [],
    isFetched,
    isError,
    isLoading,
    isRefetching,
  };
};
