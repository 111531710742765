import { countries } from './countries';

export const addresses = [
  {
    id: '00000000-0000-1000-ad00-000000000000',
    city: 'Zagreb',
    countryId: countries[0].id,
    zipCode: '10000',
    address1: 'Slavonska Avenija 33',
    address2: '',
    clientId: null,
  },
  {
    id: '00000000-0000-1000-ad00-000000000001',
    city: 'Sarajevo',
    countryId: countries[0].id,
    zipCode: '71000',
    address1: 'Trg Grada Prato 4',
    address2: '',
    clientId: null,
  },
];
