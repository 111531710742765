import styled from '@emotion/styled';
import {
  DBStockInItemsResponse,
  StockInDestinationMeta,
  StockInOrderMeta,
} from '@tyrio/dto';
import _ from 'lodash';

interface DeliveryProps {
  orderMeta: StockInOrderMeta;
  destinationMeta: StockInDestinationMeta;
  prefix: string;
  selectedOrderData: DBStockInItemsResponse;
  receivedFromMeta: StockInDestinationMeta;
}

const StyledDelivery = ({
  orderMeta,
  destinationMeta,
  prefix,
  selectedOrderData,
  receivedFromMeta,
}: DeliveryProps) => {
  return (
    <DeliveryWrapper>
      <DeliveryContainer>
        <SourceItem>
          <Label>
            {selectedOrderData.orderType !== 'TRANSFER_ORDER'
              ? `Supplier`
              : 'Warehouse'}{' '}
          </Label>
          <BranchName>
            {selectedOrderData.orderType !== 'TRANSFER_ORDER'
              ? selectedOrderData.orderType === 'PURCHASE_ORDER' &&
                orderMeta.supplierName
                ? orderMeta.supplierName
                : 'Unknown'
              : receivedFromMeta.branchName}
          </BranchName>
          <BranchAddress>
            Order:
            {orderMeta.orderNumber
              ? `${prefix}${orderMeta.orderNumber}`
              : 'Unmatched'}
          </BranchAddress>
        </SourceItem>

        <SourceItem>
          <Label>Reference</Label>
          <BranchName>{orderMeta.reference}</BranchName>
          <BranchAddress>{''}</BranchAddress>
        </SourceItem>

        <SourceItem>
          <Label>Destination warehouse</Label>
          <BranchName>
            {destinationMeta && destinationMeta.branchName}
          </BranchName>
          <BranchAddress>
            {!_.isEmpty(destinationMeta) &&
            destinationMeta.address &&
            (destinationMeta.zipCode || destinationMeta.city)
              ? ` ${destinationMeta.address}, ${
                  destinationMeta.zipCode
                }  ${destinationMeta.city.toUpperCase()} `
              : 'No info'}
          </BranchAddress>
        </SourceItem>
      </DeliveryContainer>
    </DeliveryWrapper>
  );
};

const DeliveryWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
`;

const DeliveryContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 10px;
`;

const SourceItem = styled.div`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100px;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

const BranchName = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #212b36;
`;

const BranchAddress = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #212b36;
`;

export default StyledDelivery;
