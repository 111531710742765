import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, Grid, Typography } from '@mui/material';
import {
  PriceCalculationMetaRule,
  RebateCalculationMetaRule,
} from '@tyrio/dto';
import { getMarginType } from '../../helper/parser';
import _ from 'lodash';

interface RuleDetailsProps {
  rule: PriceCalculationMetaRule | RebateCalculationMetaRule;
  appliedRuleId?: string;
  type: 'rrp' | 'rbt';
}

const RuleDetails = (props: RuleDetailsProps) => {
  const { rule, appliedRuleId, type } = props;
  return (
    <Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid sx={{ display: 'flex' }}>
          {rule.isApplied ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginLeft: '3px' }}>{rule.ruleName}</Typography>
            {appliedRuleId === rule.id && rule.inRange && (
              <Typography>*</Typography>
            )}
            <Typography sx={{ marginLeft: '3px' }}>
              {` - ${rule.description}`}
            </Typography>
          </Grid>
        </Grid>
        {type === 'rrp' && (
          <Grid>
            {_.get(rule, 'useSuggestedRetailPrice') ? (
              <Grid>
                {
                  <Chip
                    label="suggested retail price"
                    size="small"
                    color="warning"
                  />
                }
              </Grid>
            ) : (
              _.get(rule, 'margin') !== null && (
                <Typography>{`+${_.get(rule, 'margin')}${getMarginType(
                  _.get(rule, 'marginType')
                )}`}</Typography>
              )
            )}
          </Grid>
        )}
        {!rule.inRange && (
          <Grid>
            {<Chip label="not in range" size="small" color="error" />}
          </Grid>
        )}
        {type === 'rbt' && rule.inRange && (
          <Typography>{`-${_.get(rule, 'rebate')}%`}</Typography>
        )}
      </Grid>
      <CheckedRules>
        <Chip
          size="small"
          label={rule.operator}
          color="info"
          sx={{ marginRight: '5px' }}
        />
        <Grid>
          {Object.keys(rule.checks).map((check) => {
            return (
              <Chip
                size="small"
                variant="outlined"
                label={check}
                color={rule.checks[check] ? 'success' : 'error'}
                sx={{ marginRight: '5px' }}
              />
            );
          })}
        </Grid>
      </CheckedRules>
    </Grid>
  );
};

const CheckedRules = styled.div`
  margin-left: 27px;
  margin-top: 3px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export default RuleDetails;
