import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';

export const PaymentFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`;

export const PaymentCardWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 8px;
  border: 1px solid ${tyrioUI.colors.black.B40};
  border-radius: 8px;
  background: ${(props) =>
    props.selected ? 'rgba(25, 118, 210, 0.04)' : 'white'};

  border-color: ${(props) =>
    props.selected
      ? `${tyrioUI.colors.lightBlue}`
      : `${tyrioUI.colors.black.B40}`};

  &:hover {
    border-color: ${tyrioUI.colors.lightBlue};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 50px;
`;
