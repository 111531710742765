/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Drawer, Grid } from '@mui/material';
import React from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

interface FilterDrawerContainerProps {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
  children: JSX.Element;
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmit: ({ category, ...data }: Record<string, any>) => void;
  title: string;
}

const FilterDrawerContainer = ({
  isOpen,
  setOpen,
  children,
  handleSubmit,
  onSubmit,
  title,
}: FilterDrawerContainerProps) => {
  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(false);
    };

  if (!isOpen) return null;
  return (
    <Drawer anchor={'left'} open={isOpen} onClose={toggleDrawer()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Header>
            <Title>{title}</Title>
            <CloseButton
              onClick={() => setOpen(false)}
              style={{ cursor: 'pointer' }}
            >
              <CloseIcon />
            </CloseButton>
          </Header>
          <Grid
            container
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            {children}
          </Grid>
        </Wrapper>
      </form>
    </Drawer>
  );
};

const Wrapper = styled.div`
  height: 100%;
  background: white;
  padding: 20px;
  max-width: 80vw;
  width: 425px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export default FilterDrawerContainer;
