import { TRule } from '../../typings';

export const INTERTYRE_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['eancode']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['itemcode']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['available']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['packing unit']",
    destination: 'product',
    targetKey: 'packageQuantity',
    outputType: 'number',
  },
];
