import React, { useMemo } from 'react';
import styled from '@emotion/styled';

interface OrderAccordionHeaderCounterProps {
  fulfilled: number;
  requested: number;
}
export const OrderAccordionHeaderCounter = ({
  fulfilled,
  requested,
}: OrderAccordionHeaderCounterProps) => {
  const statusColor = useMemo(() => {
    if (fulfilled === requested) return '#56C489';
    if (fulfilled < requested) return '#E86032';
    if (fulfilled > requested) return '#DFE3E8';
    return 'gray';
  }, [fulfilled, requested]);
  return (
    <CounterWrapper statusColor={statusColor}>
      {fulfilled} / {requested}
    </CounterWrapper>
  );
};

const CounterWrapper = styled.div<{ statusColor: string }>`
  background: ${(props) => props.statusColor};
  border-radius: 8px;
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  margin-right: 16px;
`;
