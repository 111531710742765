import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { GOODYEAR_CATALOG_RULESET } from './goodyear/catalog';
import { GOODYEAR_PRICELIST_RULESET } from './goodyear/pricelist';
import { SupplierShape } from './supplier-types';
import { GOODYEAR_STOCKLIST_RULESET } from './goodyear/stocklist';

export const GOODYEAR_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: GOODYEAR_CATALOG_RULESET,
  pricelistRuleset: GOODYEAR_PRICELIST_RULESET,
  stockRuleset: GOODYEAR_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    WEIGHT: {
      ',': '.',
    },
    WIDTH_MM: {
      ',': '.',
    },
    PROD_INFO: {
      MOEXTENDED: 'MOE',
    },
    ASPECT_RATIO: { ',': '.' },
    RIM_INCH: { ',': '.' },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: {
    model: {},
    product: {},
  },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      dirtyFields: '{}',
      origin: 'DE',
    },
    model: {
      category_id: 30 as unknown as string,
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'GOODYEAR EDI PRICAT',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    const list = json.filter((item) =>
      ['C0', 'L0', 'L4'].includes(item.VEHICLE_TYPE)
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
