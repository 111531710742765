import * as z from "zod"
import { DBShippingDays, DBDeliveryTypeEnum } from ".prisma/client"
import { CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel, CompleteDBSupplierClientPackageCost, RelatedDBSupplierClientPackageCostModel } from "./index"

export const DBSupplierClientShippingSettingsModel = z.object({
  id: z.string(),
  supplierClientSettingsId: z.string(),
  isActive: z.boolean(),
  minOrderQty: z.number().int().nullish(),
  minQtySurchange: z.number().nullish(),
  defaultCostPerPackage: z.number().nullish(),
  dropShipping: z.boolean(),
  cod: z.boolean(),
  codSurchange: z.number().nullish(),
  deliveryToIsland: z.boolean(),
  islandSurchange: z.number().nullish(),
  daysToDeliver: z.number().int().nullish(),
  maxDaysToDeliver: z.number().int().nullish(),
  nextShippingDays: z.nativeEnum(DBShippingDays).array(),
  locationClosedFrom: z.date().nullish(),
  locationClosedUntil: z.date().nullish(),
  hideStockNonWorkingDays: z.boolean(),
  showBeforeReopened: z.number().int().nullish(),
  defaultShippingOption: z.nativeEnum(DBDeliveryTypeEnum),
})

export interface CompleteDBSupplierClientShippingSettings extends z.infer<typeof DBSupplierClientShippingSettingsModel> {
  supplierClientSettings: CompleteDBSupplierClientSettings
  supplierClientPackageCost: CompleteDBSupplierClientPackageCost[]
}

/**
 * RelatedDBSupplierClientShippingSettingsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierClientShippingSettingsModel: z.ZodSchema<CompleteDBSupplierClientShippingSettings> = z.lazy(() => DBSupplierClientShippingSettingsModel.extend({
  supplierClientSettings: RelatedDBSupplierClientSettingsModel,
  supplierClientPackageCost: RelatedDBSupplierClientPackageCostModel.array(),
}))
