import { DBSupplierClientB2BSettings } from '@prisma/client';
// import { supplierClientSettings } from './supplier-client-settings';

export const supplierClientB2BSettings: DBSupplierClientB2BSettings[] = [
  {
    id: '54385d0e-47ae-4b0b-86cb-b28e933fd818',
    supplierClientSettingsId: '07c91a78-e661-47df-800e-f197ea7b7022',
    b2bUrl: 'DEMO',
    b2bUserName: 'DEMO',
    b2bPassword: 'DEMO',
  },
  {
    id: '235f5ff3-03f3-4201-b3ba-6b372d46d1b1',
    supplierClientSettingsId: '33b2ab3a-f07f-426d-a637-da5647ddfc5e',
    b2bUrl: 'DEMO',
    b2bUserName: 'DEMO',
    b2bPassword: 'DEMO',
  },
];
