import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
// import { genericGenerateSpecialMarkings } from './operations.helpers';

export function getRadcentarManufacturerCode(input: RawImport, _rule: TRule) {
  const manufacturerCode = input['articleNo'].trim().replace(/\s+$/, '');

  return manufacturerCode;
}

// export function calculateRonalPackageQuantity(input: RawImport, _rule: TRule) {
//   console.log('SIZE', input['size'])
//   const packageQuantity =
//     parseFloat(input['size'].toLowerCase().split('x')[1].trim()) >= 13 &&
//     parseFloat(input['size'].toLowerCase().split('x')[1].trim()) <= 18
//       ? 2
//       : 1;

//   return packageQuantity;
// }
