export const colors = {
  primary: '#1B2130',
  secondary: '#DB1E1E',
  lightBlue: '#1976d2',
  lightGrey: '#F4F6F8',
  darkGrey: '#919eab',

  black: {
    B10: '#F9FAFB',
    B20: '#F4F6F8',
    B30: '#ECF0F4',
    B40: '#DFE3E8',
    B50: '#C4CDD5',
    B70: '#919EAB',
    B80: '#637381',
    B90: '#454F5B',
    B100: ' #212B36',
  },

  yellow: {
    B100: '#FFCC4D',
    B30: '#FFF4D9',
    B10: '#FFFBF2',
  },

  blue: {
    B100: '#1976D2',
    B20: '#C8EBFD',
    B10: '#E4F5FE',
  },

  green: {
    B100: '#4CAF50',
    B10: '#3EB274',
    B30: '#E0F4EA',
    B90: '#3EB274',
  },

  red: {
    B30: '#D32F2F',
  },

  orange: {
    O100: '#E75524',
  },
};

export const spacing = {
  xs: 2,
  s: 4,
  m: 8,
  l: 16,
  xl: 32,
};

export const fontSize = {
  title: 24,
  smallText: 12,
};

export const borderRadius = {
  m: 8,
  l: 16,
};
