import api from '@tyrio/api-factory';
import { DBSettingsApi, SettingsPayload } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { QueryClient, useMutation } from 'react-query';

export const useSettings = (queryClient: QueryClient) => {
  const mutation = useMutation(
    (req: SettingsPayload) =>
      api.fetch<DBSettingsApi['create']>('set_settings', req),
    {
      mutationKey: 'set_settings',
      onSuccess: () => {
        queryClient.refetchQueries('me');
        ToastHelper.showToast(
          'Settings',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE_SETTINGS
        );
      },
    }
  );

  return { set: mutation };
};
