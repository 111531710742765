import { DBSupplierClientPriceCalculationSettings } from '@prisma/client';

export const supplierClientPriceCalculationSettings: DBSupplierClientPriceCalculationSettings[] =
  [
    {
      id: '8a27c2c8-045a-4b27-9f16-9e52e8a97fad',
      supplierClientSettingsId: '07c91a78-e661-47df-800e-f197ea7b7022',
      supplierCurrency: 'EUR',
      ecoTax: false,
      rebate: 1.0,
      yearlyBonus: 1.0,
    },
    {
      id: '3f07f1c6-1da9-49f6-b5f0-03ef722a1ac4',
      supplierClientSettingsId: '33b2ab3a-f07f-426d-a637-da5647ddfc5e',
      supplierCurrency: 'EUR',
      ecoTax: false,
      rebate: 1.0,
      yearlyBonus: 1.0,
    },
  ];
