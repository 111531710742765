import styled from '@emotion/styled/macro';
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { DBStockInItemsResponse, PriceMeta, StockInLineItem } from '@tyrio/dto';
import { damagedIcon } from '@tyrio/ui-library';
import { useContext, useState } from 'react';
import { StockInContext } from '../../../context/StockInContext';
import DamagedModal from '../modals/DamagedModal';

interface IDetailsTableItem {
  item: StockInLineItem;
  priceMeta: Record<string, PriceMeta>;
  disableCheck: boolean;
}

const DetailsTableItem = ({
  item,
  priceMeta,
  disableCheck,
}: IDetailsTableItem) => {
  const {
    selectedOrderData,
    setSelectedOrderData,
    checkedLineItems,
    setCheckedLineItems,
  } = useContext(StockInContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <ProductItem>
      <DamagedModal
        open={isModalVisible}
        setOpen={setIsModalVisible}
        productName={item.productName}
        supplierName={item.brand ? item.brand.toUpperCase() : ''}
      />

      {selectedOrderData?.status !== 'COMPLETED' && (
        <Grid item lg={2}>
          <FormControlLabel
            label=""
            control={
              <Checkbox
                color="info"
                checked={checkedLineItems.includes(item)}
                value={checkedLineItems.includes(item)}
                onChange={() => {
                  if (checkedLineItems.includes(item)) {
                    if (checkedLineItems.length === 1) setCheckedLineItems([]);
                    else {
                      const index = checkedLineItems.indexOf(item);
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const removed = checkedLineItems.splice(index, index);
                    }
                  } else checkedLineItems.push(item);
                }}
                disabled={disableCheck}
              />
            }
            style={{ margin: 0 }}
          />
        </Grid>
      )}

      <ProductDetailsWrapper>
        <ProductDetails>
          <div
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '370px',
            }}
          >{`${item.brand ? item.brand.toUpperCase() : ''} ${
            item.productName
          }`}</div>
          <EanIpcSkuWrapper>
            <div>EAN: {`${item.ean} `}</div>
            <div>IPC: {`${item.manufacturerCode} `}</div>
            <div>SUPPLIER CODE: {`${item.sku} `}</div>
          </EanIpcSkuWrapper>
        </ProductDetails>

        {item.damaged && (
          <DamagedWrapper onClick={() => setIsModalVisible(true)}>
            <img src={damagedIcon} alt="damaged-icon" />
            <div style={{ fontSize: '12px' }}>DAMAGED</div>
          </DamagedWrapper>
        )}
      </ProductDetailsWrapper>

      <Wrapper background={'#f4f6f8'} id="quantity_wrapper">
        <TextField
          variant="outlined"
          size="small"
          disabled={selectedOrderData?.status === 'CONFIRMED'}
          type="number"
          sx={{ marginLeft: '10px', backgroundColor: 'white' }}
          value={priceMeta[item.ean]?.purchasePrice}
          onChange={(e) => {
            if (selectedOrderData?.priceMeta) {
              setSelectedOrderData({
                ...selectedOrderData,
                priceMeta: {
                  ...(selectedOrderData?.priceMeta as unknown as PriceMeta),
                  [item.ean]: {
                    suggestedRetailPrice: 0,
                    purchasePrice: Number(e.target.value),
                  },
                },
              } as DBStockInItemsResponse);
            }
          }}
        />
        <NumberWrapper>{item.ordered}</NumberWrapper>
        <NumberWrapper>{item.backordered}</NumberWrapper>

        <NumberWrapper>
          <StyledAvatar
            color={item.ordered === item.received ? '#3EB274' : '#FFCC4D'}
          >
            {item.received}
          </StyledAvatar>
        </NumberWrapper>
      </Wrapper>
    </ProductItem>
  );
};

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const EanIpcSkuWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  max-width: 400px;
  flex-direction: row;
  color: #919eab;
`;

const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 16px;
  padding-right: 0;
  border-bottom: 1px solid #dfe3e8;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 300px);
`;

const Wrapper = styled.div<{
  background?: string;
}>`
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.background ? props.background : '#f4f6f8')};
  border-radius: 8px 0px 0px 8px;

  &:hover {
    background: #ecf0f4;
  }
`;

const NumberWrapper = styled.div<{
  color?: string;
  clickable?: boolean;
}>`
  display: flex;
  width: 100%;
  max-width: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.color === 'green' ? '600' : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  &:hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  }
`;

const DamagedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledAvatar = styled(Avatar)<{ color: string }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: white;
  width: 40px;
  height: 40px;
  position: inherit;
  background: ${(props) => props.color};
`;

export default DetailsTableItem;
