import { DBBranchType, Prisma } from '@prisma/client';
import { clients } from './clients';

const branchList = [
  {
    branchName: 'Poslovnica Zagreb',
    branchType: [DBBranchType.POS, DBBranchType.HEADQUARTER],
    mainWarehouse: undefined,
    isActive: true,
    country: {
      connect: {
        id: '274e7e37-6d32-f25b-4a5f-daf91b949567', //Croatia
      },
    },
    client: {
      connect: {
        id: clients[1].id,
      },
    },
    zipCode: '71000',
    city: 'Zagreb',
    address: 'Ferhadija 1',
    email: 'poslovnica-sarajevo@tyrio.com',
    webSite: 'www.poslovnica-sarajevo.ba',
    businessPhone: '+38712345678',
    qualifiedPersonJobTitle: 'Manager',
    qualifiedPersonFirstName: 'Haris',
    qualifiedPersonLastName: 'Pandzic',
    qualifiedPersonEmail: 'haris.pandzic@poslovnicasarajevo.ba',
    qualifiedPersonBusinessPhone: '+38712345678',
    qualifiedPersonMobilePhone: '+38712345678',
    latitude: 29.076738226271466,
    longitude: 31.098924946223306,
    branchNumber: 1,
  },
  {
    branchName: 'Poslovnica Split',
    branchType: [
      DBBranchType.POS,
      DBBranchType.HEADQUARTER,
      DBBranchType.WAREHOUSE,
    ],
    isActive: true,
    country: {
      connect: {
        id: '274e7e37-6d32-f25b-4a5f-daf91b949567', //Croatia
      },
    },
    client: {
      connect: {
        id: clients[1].id,
      },
    },
    zipCode: '71000',
    city: 'Split',
    address: 'Ferhadija 1',
    email: 'poslovnica-mostar@tyrio.com',
    webSite: 'www.poslovnica-mostar.ba',
    businessPhone: '+38712345678',
    qualifiedPersonJobTitle: 'Manager',
    qualifiedPersonFirstName: 'Emir',
    qualifiedPersonLastName: 'Brackovic',
    qualifiedPersonEmail: 'emir.brackovic@poslovnicasarajevo.ba',
    qualifiedPersonBusinessPhone: '+38712345678',
    qualifiedPersonMobilePhone: '+38712345678',
    latitude: 43.51576618919335,
    longitude: 16.483039307850497,
    branchNumber: 2,
  },
  {
    branchName: 'Poslovnica Kastela',
    branchType: [DBBranchType.POS],
    isActive: true,
    country: {
      connect: {
        id: '274e7e37-6d32-f25b-4a5f-daf91b949567', //Croatia
      },
    },
    client: {
      connect: {
        id: clients[1].id,
      },
    },
    zipCode: '71000',
    city: 'Split',
    address: 'Ferhadija 1',
    email: 'poslovnica-mostar@tyrio.com',
    webSite: 'www.poslovnica-mostar.ba',
    businessPhone: '+38712345678',
    qualifiedPersonJobTitle: 'Manager',
    qualifiedPersonFirstName: 'Emir',
    qualifiedPersonLastName: 'Brackovic',
    qualifiedPersonEmail: 'emir.brackovic@poslovnicasarajevo.ba',
    qualifiedPersonBusinessPhone: '+38712345678',
    qualifiedPersonMobilePhone: '+38712345678',
    latitude: 43.51576618919335,
    longitude: 16.483039307850497,
    branchNumber: 3,
  },
  {
    branchName: 'Poslovnica Trogir',
    branchType: [DBBranchType.POS],
    isActive: true,
    country: {
      connect: {
        id: '274e7e37-6d32-f25b-4a5f-daf91b949567', //Croatia
      },
    },
    client: {
      connect: {
        id: clients[1].id,
      },
    },
    zipCode: '71000',
    city: 'Trogir',
    address: 'Ferhadija 1',
    email: 'poslovnica-mostar@tyrio.com',
    webSite: 'www.poslovnica-mostar.ba',
    businessPhone: '+38712345678',
    qualifiedPersonJobTitle: 'Manager',
    qualifiedPersonFirstName: 'Emir',
    qualifiedPersonLastName: 'Brackovic',
    qualifiedPersonEmail: 'emir.brackovic@poslovnicasarajevo.ba',
    qualifiedPersonBusinessPhone: '+38712345678',
    qualifiedPersonMobilePhone: '+38712345678',
    latitude: 43.51576618919335,
    longitude: 16.483039307850497,
    branchNumber: 4,
  },
  {
    branchName: 'Poslovnica Sibenik',
    branchType: [DBBranchType.POS],
    isActive: true,
    country: {
      connect: {
        id: '274e7e37-6d32-f25b-4a5f-daf91b949567', //Croatia
      },
    },
    client: {
      connect: {
        id: clients[1].id,
      },
    },
    zipCode: '71000',
    city: 'Sibenik',
    address: 'Ferhadija 1',
    email: 'poslovnica-mostar@tyrio.com',
    webSite: 'www.poslovnica-mostar.ba',
    businessPhone: '+38712345678',
    qualifiedPersonJobTitle: 'Manager',
    qualifiedPersonFirstName: 'Emir',
    qualifiedPersonLastName: 'Brackovic',
    qualifiedPersonEmail: 'emir.brackovic@poslovnicasarajevo.ba',
    qualifiedPersonBusinessPhone: '+38712345678',
    qualifiedPersonMobilePhone: '+38712345678',
    latitude: 43.51576618919335,
    longitude: 16.483039307850497,
    branchNumber: 5,
  },
  {
    branchName: 'Poslovnica Rijeka',
    branchType: [DBBranchType.POS],
    isActive: true,
    country: {
      connect: {
        id: '274e7e37-6d32-f25b-4a5f-daf91b949567', //Croatia
      },
    },
    client: {
      connect: {
        id: clients[1].id,
      },
    },
    zipCode: '71000',
    city: 'Rijeka',
    address: 'Ferhadija 1',
    email: 'poslovnica-mostar@tyrio.com',
    webSite: 'www.poslovnica-mostar.ba',
    businessPhone: '+38712345678',
    qualifiedPersonJobTitle: 'Manager',
    qualifiedPersonFirstName: 'Emir',
    qualifiedPersonLastName: 'Brackovic',
    qualifiedPersonEmail: 'emir.brackovic@poslovnicasarajevo.ba',
    qualifiedPersonBusinessPhone: '+38712345678',
    qualifiedPersonMobilePhone: '+38712345678',
    latitude: 43.51576618919335,
    longitude: 16.483039307850497,
    branchNumber: 6,
  },
  {
    branchName: 'Poslovnica Zadar',
    branchType: [DBBranchType.POS],
    isActive: true,
    country: {
      connect: {
        id: '274e7e37-6d32-f25b-4a5f-daf91b949567', //Croatia
      },
    },
    client: {
      connect: {
        id: clients[1].id,
      },
    },
    zipCode: '71000',
    city: 'Zadar',
    address: 'Ferhadija 1',
    email: 'poslovnica-mostar@tyrio.com',
    webSite: 'www.poslovnica-mostar.ba',
    businessPhone: '+38712345678',
    qualifiedPersonJobTitle: 'Manager',
    qualifiedPersonFirstName: 'Emir',
    qualifiedPersonLastName: 'Brackovic',
    qualifiedPersonEmail: 'emir.brackovic@poslovnicasarajevo.ba',
    qualifiedPersonBusinessPhone: '+38712345678',
    qualifiedPersonMobilePhone: '+38712345678',
    latitude: 43.51576618919335,
    longitude: 16.483039307850497,
    branchNumber: 7,
  },
];

export const branches: Prisma.DBBranchUpsertArgs[] = branchList.map(
  (item, index) => ({
    where: {
      id: index + 1,
    },
    create: {
      ...item,
    },
    update: {
      ...item,
    },
  })
);
