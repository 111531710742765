import { CircularProgress } from '@mui/material';
import { DBBusinessType } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBClientApi, DBCountryApi } from '@tyrio/dto';
import {
  FormController,
  generateMainInfoForm,
  PermissionsValues,
  TyrioSelectInputOption,
} from '@tyrio/forms';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../../components/PageTemplate/PageTemplate';

interface MainInfoFormProps {
  clientId: string;
  permissions: PermissionsValues;
}

const MainInfoForm = ({ clientId, permissions }: MainInfoFormProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const queryClient = useQueryClient();

  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setCountries(dropdownItems);
      },
    }
  );

  // GET client by id
  const {
    data: client_data,
    isFetching: isClientDataFetching,
    refetch: getClientById,
  } = useQuery(
    ['get_client_id', clientId, 'update_client'],
    () =>
      api.fetch<DBClientApi['getOne']>(`client_id`, {
        id: clientId as string,
      }),
    {
      enabled: !!clientId,
    }
  );

  // UPDATE client mutation
  const updateClientMutation = useMutation(
    (client: DBClientApi['updateOne']['request']) =>
      api.fetch<DBClientApi['updateOne']>('update_client', {
        ...client,
      }),
    {
      mutationKey: 'update_client',
      onSuccess: () => {
        queryClient.refetchQueries('client_list');
        queryClient.refetchQueries('get_client_id');
        getClientById();
        ToastHelper.showToast(
          'Client',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  const handleClientFormSubmit = (data: z.infer<typeof zodSchema>) => {
    const client_data: DBClientApi['updateOne']['request'] = {
      id: clientId,
      businessType:
        DBBusinessType[data.businessType as keyof typeof DBBusinessType],
      businessCode: data.businessCode,
      vatObligee: data.vatObligee,
      intrastat: data.intrastat,
      registrationNumber: data.registrationNumber,
      viesVatNumber: data.viesVatNumber,
      officialName: data.officialName,
      companyEmail: data.companyEmail,
      companyWebSite: data.companyWebSite,
      companyBusinessPhone: data.companyBusinessPhone,
      address: {
        address1: data.address,
        city: data.city,
        zipCode: data.zipCode,
      },
      responsiblePersonJobTile: data.responsiblePersonJobTile,
      responsiblePersonFirstName: data.responsiblePersonFirstName,
      responsiblePersonLastName: data.responsiblePersonLastName,
      responsiblePersonEmail: data.responsiblePersonEmail,
      responsiblePersonBusinessPhone: data.responsiblePersonBusinessPhone,
      responsiblePersonMobilePhone: data.responsiblePersonMobilePhone,
    };
    updateClientMutation.mutate(client_data);
  };

  const { form, zodSchema } = useMemo(
    () => generateMainInfoForm(!permissions.edit, countries),
    [permissions.edit, countries]
  );

  const handleOnCancel = () => {
    history.push(`/dashboard/company-settings`);
  };

  return (
    <PageTemplateContent>
      {isClientDataFetching && client_data?.id ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <FormController<z.infer<typeof zodSchema>>
          permissions={permissions}
          form={form}
          key={`${client_data?.id}`}
          initialValues={{
            businessType: client_data?.businessType,
            businessCode: client_data?.businessCode || '',
            vatObligee: client_data?.vatObligee || '',
            intrastat: client_data?.intrastat || '',
            vatNumber: client_data?.vatNumber,
            registrationNumber: client_data?.registrationNumber || '',
            viesVatNumber: client_data?.euVatNumber || '',
            officialName: client_data?.officialName,
            shortName: client_data?.shortName,
            countryId: client_data?.address.countryId || '',
            zipCode: client_data?.address.zipCode,
            city: client_data?.address.city,
            address: client_data?.address.address1,
            companyEmail: client_data?.companyEmail || '',
            companyWebSite: client_data?.companyWebSite || '',
            companyBusinessPhone: client_data?.companyBusinessPhone || '',
            responsiblePersonJobTile:
              client_data?.responsiblePersonJobTile || '',
            responsiblePersonFirstName:
              client_data?.responsiblePersonFirstName || '',
            responsiblePersonLastName:
              client_data?.responsiblePersonLastName || '',
            responsiblePersonEmail: client_data?.responsiblePersonEmail || '',
            responsiblePersonBusinessPhone:
              client_data?.responsiblePersonBusinessPhone || '',
            responsiblePersonMobilePhone:
              client_data?.responsiblePersonMobilePhone || '',
          }}
          zodSchema={zodSchema}
          onSubmit={(data) => handleClientFormSubmit(data)}
          onCancel={handleOnCancel}
          title={t('Company Info')}
          objectId={client_data?.id}
          cantDelete={true}
        />
      )}
    </PageTemplateContent>
  );
};

export default MainInfoForm;
