import { DBCustomerOrderStatusEnum } from '@tyrio/dto';
import { getDropdownLabel } from '../../../../helpers/enum-parser';
import { CustomerOrderStatusColors } from '../../features/purchase-orders/helpers/generate-color';
import {
  CenterStatus,
  OrderStatus,
} from '../../features/purchase-orders/PurchaseOrderTable';
interface OrderStatusProps {
  status: DBCustomerOrderStatusEnum;
}

export const OrdersStatus = ({ status }: OrderStatusProps) => {
  return (
    <CenterStatus>
      <OrderStatus
        color={CustomerOrderStatusColors[status]}
        textColor={status === 'NEW' ? '#919EAB' : 'white'}
        border={status === 'NEW' ? true : false}
      >
        {getDropdownLabel(DBCustomerOrderStatusEnum, status).toUpperCase()}
      </OrderStatus>
    </CenterStatus>
  );
};
