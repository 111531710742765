import React, { useCallback, useContext, useMemo, useState } from 'react';
import * as _ from 'lodash';
import styled from '@emotion/styled/macro';
import {
  Chip,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { PriceComparisonContext } from './context';
import { backIcon } from '@tyrio/ui-library';
import Button from '@mui/material/Button/Button';
import { useHistory } from 'react-router-dom';
import {
  Category,
  Quiz,
  Visibility,
  VisibilityOff,
  Warning,
} from '@mui/icons-material';
import { eanClipboard } from './tempdata';
import { SalesOrdersFlowContext } from '../../../features/sales-orders/SalesOrdersFlowContext';

export const FlowHeader = ({
  hasPlacedCustomerOrders,
}: {
  hasPlacedCustomerOrders?: boolean;
}) => {
  const ctx = useContext(PriceComparisonContext);
  const soctx = useContext(SalesOrdersFlowContext);
  const history = useHistory();
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [warningsOpen, setWarningsOpen] = useState(false);
  const missingProducts = useMemo(() => {
    if (ctx.data.lastFetchSource !== 'code') return [];
    const req = Object.keys(ctx.data.code?.list || {});
    const productsList = Object.values(ctx.data.codeResponse?.products || {});

    return req.filter((codeItem) => {
      return !productsList.find(
        (p) =>
          p.ean === codeItem ||
          p.manufacturerCode === codeItem ||
          p.sku === codeItem
      );
    });
  }, [
    ctx.data.code?.list,
    ctx.data.codeResponse?.products,
    ctx.data.lastFetchSource,
  ]);

  const missingSizes = useMemo(() => {
    if (ctx.data.lastFetchSource !== 'dimension') return [];
    const req = ctx.data.dimensions?.sizes || [];
    const productsList = Object.values(
      ctx.data.codeResponse?.products || {}
    ).map((p) => p.size);

    return _.difference(req, productsList);
  }, [
    ctx.data.codeResponse?.products,
    ctx.data.dimensions?.sizes,
    ctx.data.lastFetchSource,
  ]);

  const clear = useCallback(() => {
    ctx.setInputValue(
      { ...ctx.data, qty: {}, isOrderAutomaticallyFilled: false },
      true
    );
  }, [ctx]);

  return (
    <>
      <Wrapper id="flow_header">
        <Grid container>
          <Grid item xs={2} sx={{ minHeight: '80px' }}>
            <BackButton>
              <Button
                onClick={(e) => {
                  if (ctx.mode === 'purchase_orders') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (ctx.data.screen === 'intro') history.goBack();
                    if (ctx.data.screen === 'table')
                      ctx.setInputValue({ screen: 'intro' });
                    if (ctx.data.screen === 'order')
                      ctx.setInputValue({ screen: 'table' });
                  }
                  if (ctx.mode === 'sales_orders') {
                    if (hasPlacedCustomerOrders) {
                      history.push(
                        `/dashboard/customer-orders?selected=${soctx.customerOrderId}`
                      );
                    } else {
                      soctx.setScreen('process_order');
                    }
                  }
                }}
              >
                <img src={backIcon} alt="Go Back" />
              </Button>

              {ctx.mode === 'purchase_orders' && (
                <>
                  {ctx.data.screen === 'intro' && <h2>Price comparison</h2>}
                  {ctx.data.screen === 'table' && <h2>Price comparison</h2>}
                  {ctx.data.screen === 'order' && <h2>Order</h2>}
                </>
              )}
              {ctx.mode === 'sales_orders' && <h2>Order</h2>}
            </BackButton>
          </Grid>
          <Grid item xs={2}>
            {ctx.data.screen === 'table' && (
              <List sx={{ width: '100%' }}>
                <ListItemButton
                  onClick={() =>
                    ctx.setInputValue({
                      showSupplierNames: !ctx.data.showSupplierNames,
                    })
                  }
                >
                  <ListItemAvatar>
                    {ctx.data.showSupplierNames ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary="Privacy"
                    secondary={
                      ctx.data.showSupplierNames
                        ? 'Hide supplier names'
                        : 'Show supplier names'
                    }
                  />
                </ListItemButton>
              </List>
            )}
          </Grid>
          <Grid item xs={2}>
            {ctx.data.screen === 'table' &&
              (missingProducts.length > 0 || missingSizes.length > 0) && (
                <List sx={{ width: '100%' }}>
                  <ListItemButton
                    onClick={() => setWarningsOpen((open) => !open)}
                  >
                    <ListItemAvatar>
                      <Warning color={!warningsOpen ? 'warning' : 'primary'} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Missing products"
                      secondary="See all warnings"
                    />
                  </ListItemButton>
                </List>
              )}
          </Grid>
          <Grid item xs={2}>
            {ctx.data.screen === 'table' && (
              <List sx={{ width: '100%' }}>
                <ListItemButton
                  disabled={ctx.hasPlacedOrders}
                  onClick={() => clear()}
                >
                  <ListItemAvatar>
                    <HighlightOffIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Clear"
                    secondary="Clear all quantities"
                  />
                </ListItemButton>
              </List>
            )}
          </Grid>
          <Grid item xs={2}>
            {ctx.data.screen === 'table' && (
              <List sx={{ width: '100%' }}>
                <ListItemButton
                  onClick={() =>
                    ctx.setInputValue({ sidebarOpen: !ctx.data.sidebarOpen })
                  }
                  disabled={!ctx.canSidebarOpen}
                >
                  <ListItemAvatar>
                    <Category />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Product details"
                    secondary={
                      !ctx.canSidebarOpen
                        ? 'Select a product from the list'
                        : ctx.data.sidebarOpen
                        ? 'Hide product details'
                        : 'Show product details'
                    }
                  />
                </ListItemButton>
              </List>
            )}
          </Grid>
          {ctx.mode === 'purchase_orders' && (
            <Grid item xs={2}>
              <List sx={{ width: '100%' }}>
                <ListItemButton
                  onClick={() => setInstructionsOpen((open) => !open)}
                >
                  <ListItemAvatar>
                    <Quiz color={instructionsOpen ? 'secondary' : 'primary'} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={instructionsOpen ? 'Close help' : 'Open help'}
                    secondary="Instructions how to use"
                  />
                </ListItemButton>
              </List>
            </Grid>
          )}
        </Grid>
        {warningsOpen && (
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Missing products
              </Typography>
              <Typography>
                There are some products missing from your order.
              </Typography>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              {[...missingProducts, ...missingSizes].map((p) => (
                <Chip
                  sx={{ marginRight: 1, marginBottom: 1 }}
                  key={p}
                  color="warning"
                  label={p}
                />
              ))}
            </Grid>
          </Grid>
        )}
        {instructionsOpen && (
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={4}>
              <Typography variant="h4">1. Input data</Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                convallis, ipsum id fermentum sagittis, lectus nunc feugiat
                augue, at fringilla nisi lectus ut est. Sed laoreet lacinia
                tempor. Curabitur eleifend tempus lorem vel ullamcorper. Sed
                efficitur purus quis lacus maximus vestibulum.{' '}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">2. Input data</Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                convallis, ipsum id fermentum sagittis, lectus nunc feugiat
                augue, at fringilla nisi lectus ut est. Sed laoreet lacinia
                tempor. Curabitur eleifend tempus lorem vel ullamcorper. Sed
                efficitur purus quis lacus maximus vestibulum.{' '}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">3. Input data</Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                convallis, ipsum id fermentum sagittis, lectus nunc feugiat
                augue, at fringilla nisi lectus ut est. Sed laoreet lacinia
                tempor. Curabitur eleifend tempus lorem vel ullamcorper. Sed
                efficitur purus quis lacus maximus vestibulum.{' '}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">4. Input data</Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                convallis, ipsum id fermentum sagittis, lectus nunc feugiat
                augue, at fringilla nisi lectus ut est. Sed laoreet lacinia
                tempor. Curabitur eleifend tempus lorem vel ullamcorper. Sed
                efficitur purus quis lacus maximus vestibulum.{' '}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">5. Input data</Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                convallis, ipsum id fermentum sagittis, lectus nunc feugiat
                augue, at fringilla nisi lectus ut est. Sed laoreet lacinia
                tempor. Curabitur eleifend tempus lorem vel ullamcorper. Sed
                efficitur purus quis lacus maximus vestibulum.{' '}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">6. Input data</Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                convallis, ipsum id fermentum sagittis, lectus nunc feugiat
                augue, at fringilla nisi lectus ut est. Sed laoreet lacinia
                tempor.{' '}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigator.clipboard.writeText(eanClipboard).catch((e) => {
                      console.log(e);
                    });
                  }}
                  style={{ fontWeight: '600' }}
                >
                  Helikopter
                </span>{' '}
                eleifend tempus lorem vel ullamcorper. Sed efficitur purus quis
                lacus maximus vestibulum.{' '}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Wrapper>
      <Divider />
    </>
  );
};
const Wrapper = styled.div`
  padding: 0 2rem;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  button {
    margin-right: 16px;
    width: 32px;
    min-width: 32px;
    border-radius: 16px;
  }
  h2 {
    margin: 0;
  }
`;
