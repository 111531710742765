import styled from '@emotion/styled/macro';

export const TitleWrapper = styled.div`
  color: #212b36;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.3px;
  padding: 16px;
`;

export const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
`;

export const SectionWrapper = styled.div<{ height: string }>`
  height: ${(props) => props.height ?? '100%'};
  width: 100%;
  background: #f9fafb;
  border-radius: 8px;
  border: 1px solid #dfe3e8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 8px;
  gap: 10px;
`;

export const LogoImageWrapper = styled.div`
  width: 450px;
  max-width: 80%;
  height: 300px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  border-style: dashed;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const UploadLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;

export const AccectableFormats = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const UploadLogoText = styled.div`
  border-bottom: 1px solid #1652f7;
  padding-bottom: 0;
  color: #1652f7;
  &:hover {
    cursor: pointer;
  }
`;

export const Barlow = styled.div`
  font-family: Barlow;
  font-size: 18px;
  font-weight: 600px;
  color: black;
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 8px;
  gap: 10px;
`;

export const SourceContainerDivider = styled.div`
  position: sticky;
  top: 100%;
`;
