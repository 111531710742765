import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import { DBPaymentStatusEnum } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBBranchesApi,
  DBCustomerOrderStatusEnum,
  DBPaymentTypesApi,
  DBSupplierClientSettingsApi,
} from '@tyrio/dto';
import { MultiselectChipInput } from '@tyrio/ui-library';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  InputOption,
  parseEnumToInputOption,
} from '../../../../../helpers/enum-parser';
import FilterDrawerContainer from '../../../components/SidebarFilters/FilterDrawer';

interface OrderFiltersProps {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
  filterValues: qs.ParsedQs;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
  setFiltersWithLabel?: (data: Record<string, InputOption[]>) => void;
}

const OrderFilters = ({
  isOpen,
  setOpen,
  filterValues,
  setFilterValue,
  setFiltersWithLabel,
}: OrderFiltersProps) => {
  const [branchesData, setBranchesData] = useState<InputOption[]>([]);
  const [suppliersData, setSuppliersData] = useState<InputOption[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<InputOption[]>([]);
  const ORDER_STATUS_DATA = parseEnumToInputOption(DBCustomerOrderStatusEnum);
  const PAYMENT_STATUS_DATA = parseEnumToInputOption(DBPaymentStatusEnum);

  useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.data.map((item) => ({
          label: item.branchName.toUpperCase(),
          value: item.id.toString(),
        }));
        setBranchesData(dropdownItems);
      },
    }
  );

  useQuery(
    ['get_supplier_client_settings'],
    async () => {
      return await api.fetch<DBSupplierClientSettingsApi['list']>(
        'get_supplier_client_settings',
        {
          search: '',
          pageSize: 100,
        }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.data.map((item) => ({
          label: item.supplier?.companyShortName || '',
          value: item.supplier?.id ?? '',
        }));

        setSuppliersData(dropdownItems);
      },
    }
  );

  useQuery(
    'get_client_payment_types',
    () => api.fetch<DBPaymentTypesApi['list']>('get_client_payment_types'),
    {
      onSuccess: (data) => {
        const dropdownData = data.data.map((item) => ({
          value: item.code,
          label: `${item.code} - ${item.name}`,
        }));

        setPaymentTypes(dropdownData);
      },
    }
  );

  useEffect(() => {
    setFiltersWithLabel &&
      setFiltersWithLabel({
        branches: branchesData,
        suppliers: suppliersData,
      });
  }, [branchesData, setFiltersWithLabel, suppliersData]);

  const getFilterValuesFromInitial = useCallback(() => {
    const data = {
      myOrders: filterValues?.['myOrders'],
      category: filterValues?.['category_id'],
      paymentMethod: filterValues?.['paymentMethod'],
      branch: filterValues?.['branch'],
      suppliers: filterValues?.['suppliers'],
      orderStatus: filterValues?.['orderStatus'],
      paymentStatus: filterValues?.['paymentStatus'],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data as any;
  }, [filterValues]);

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: getFilterValuesFromInitial(),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = ({ category, ...data }: Record<string, any>) => {
    const category_id = category?.id;

    const payload = {
      category_id,
      ...data,
    };

    setFilterValue(payload);
    setOpen(false);
  };

  useEffect(() => {
    const data = {
      myOrders: filterValues?.['myOrders'],
      category: filterValues?.['category_id'],
      paymentMethod: filterValues?.['paymentMethod'],
      branch: filterValues?.['branch'],
      suppliers: filterValues?.['suppliers'],
      orderStatus: filterValues?.['orderStatus'],
      paymentStatus: filterValues?.['paymentStatus'],
    };
    reset(data);
  }, [filterValues, reset]);

  return (
    <FilterDrawerContainer
      isOpen={isOpen}
      setOpen={setOpen}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      title={'Filter Orders'}
    >
      <Container>
        <SelectFieldsWrapper>
          <StyledGrid style={{ marginBottom: '16px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="info"
                  checked={!!filterValues?.['myOrders']}
                  onClick={(e) => {
                    setFilterValue({
                      myOrders:
                        _.get(e, 'target.checked') === true
                          ? _.get(e, 'target.checked')
                          : undefined,
                    });
                  }}
                />
              }
              label={'Show only my orders'}
              key={'myOrders'}
            />
          </StyledGrid>

          <StyledGrid>
            <MultiselectChipInput
              title="Method of payment"
              control={control}
              id="paymentMethod"
              data={paymentTypes}
            />
          </StyledGrid>

          <StyledGrid>
            <MultiselectChipInput
              title="Suppliers"
              control={control}
              id="suppliers"
              data={suppliersData}
            />
          </StyledGrid>

          <StyledGrid></StyledGrid>

          <StyledGrid>
            <MultiselectChipInput
              title="Branches"
              control={control}
              id="branch"
              data={branchesData}
            />
          </StyledGrid>

          <StyledGrid>
            <MultiselectChipInput
              title="Order status"
              control={control}
              id="orderStatus"
              data={ORDER_STATUS_DATA}
            />
          </StyledGrid>

          <StyledGrid>
            <MultiselectChipInput
              title="Payment status"
              control={control}
              id="paymentStatus"
              data={PAYMENT_STATUS_DATA}
            />
          </StyledGrid>
        </SelectFieldsWrapper>

        <ButtonContainer>
          <Button
            fullWidth
            startIcon={<CancelIcon />}
            type="button"
            color="info"
            variant="outlined"
            sx={{ marginRight: '5px' }}
            onClick={() => {
              reset();
              setFilterValue({
                myOrders: undefined,
                category_id: undefined,
                suppliers: undefined,
                branch: undefined,
                paymentStatus: undefined,
                orderStatus: undefined,
                paymentMethod: undefined,
              });
              setOpen(false);
            }}
          >
            Reset
          </Button>

          <Button fullWidth variant="contained" type="submit">
            Filter
          </Button>
        </ButtonContainer>
      </Container>
    </FilterDrawerContainer>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SelectFieldsWrapper = styled.div`
  width: 100%;
`;
const StyledGrid = styled(Grid)`
  margin-top: 16px;
`;

const ButtonContainer = styled(Grid)`
  width: 100%;
  display: flex;
  margin-top: 16px;
`;

export default OrderFilters;
