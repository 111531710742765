import { Alert, Button, CircularProgress, Typography } from '@mui/material';
import { CustomModal } from '../../../components/Modal/Modal';
import { useWS } from '../../../context/WSContext';
import { useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { DBWorkOrdersApi } from '@tyrio/dto';
import { useAuth } from '../../../context/AuthContext';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export const WorkOrdersModal = ({
  open,
  handleClose,
  isLoading,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  isLoading: boolean;
  data?: DBWorkOrdersApi['updateAfterInvoiceMany']['response'];
}) => {
  const { state } = useWS();
  const { user } = useAuth();
  const history = useHistory();

  const userId = user?.id;

  const text = useMemo(() => {
    if (userId)
      return Object.values(state?.workOrdersUpdate?.[userId] ?? {}).map(
        (a) => a?.response
      );
    else return [];
  }, [state?.workOrdersUpdate, userId]);

  const mapData = (
    data: DBWorkOrdersApi['updateAfterInvoiceMany']['response']
  ) => {
    if (_.isEmpty(data) || !data)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );

    return (data ?? []).map((item) => {
      const res = item?.response ?? [];

      const errors = item?.errors;
      const message = item?.message;

      return (
        <div>
          <Alert
            color={errors ? 'error' : message ? 'warning' : 'success'}
            icon={
              errors ? (
                <ErrorOutlineIcon />
              ) : message ? (
                <WarningAmberIcon />
              ) : null
            }
          >
            <Typography>
              Customer order: {item?.customerOrderReference ?? ''}
            </Typography>

            {errors ? (
              <Typography>Errors: {errors.join(',')}</Typography>
            ) : message ? (
              <Typography>Message: {item?.message ?? ''}</Typography>
            ) : (
              <>
                <Typography>
                  Updated work order number:{' '}
                  {res?.[0]?.updatedWorkOrder.orderNumber ?? ''}
                </Typography>
                <Typography>
                  Ritam response: {res?.[0]?.ritamResponse ?? ''}
                </Typography>
              </>
            )}
          </Alert>
        </div>
      );
    });
  };

  return (
    <div style={{ background: 'white' }} id="work_order_modal">
      <CustomModal
        open={open}
        handleClose={handleClose}
        removeScroll={true}
        child={
          <Wrapper>
            <ContentWrapper>
              <Typography align="center" fontSize={22}>
                {isLoading
                  ? 'Work orders update is in process. Please wait...'
                  : 'Work orders updated'}
              </Typography>

              {isLoading ? mapData(text) : mapData(data ?? [])}
            </ContentWrapper>
            <ButtonWrapper>
              <Button
                color="info"
                variant="contained"
                disabled={isLoading}
                style={{ width: '220px', height: '60px' }}
                onClick={() => {
                  history.push(
                    `/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&orderStatus=new`
                  );
                }}
              >
                OK
              </Button>
            </ButtonWrapper>
          </Wrapper>
        }
      />
    </div>
  );
};

const Wrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentWrapper = styled.div`
  height: calc(100% - 70px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;
