import { Prisma } from '@prisma/client';

const currentYear = new Date();
const nextYear = new Date(
  currentYear.setFullYear(currentYear.getFullYear() + 1)
);

const apiKeysBody: Prisma.DBClientApiKeyCreateInput[] = [
  {
    apiKey: 'dev-api-key',
    client: {
      connect: {
        id: '00000000-0000-1000-b000-0000000000022',
      },
    },
    createdAt: currentYear,
    expiresAt: nextYear,
    isActive: true,
  },
];

export const apiKeys: Prisma.DBClientApiKeyUpsertArgs[] = apiKeysBody.map(
  (item) => ({
    where: {
      clientId: item.client.connect?.id,
    },
    create: {
      ...item,
    },
    update: { ...item },
  })
);
