/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import { DBProductCategory } from '@prisma/client';
import { Autocomplete, FormControl, MenuItem, TextField } from '@mui/material';

export interface TyrioSelectInputOption {
  label: string;
  value: string | number;
  mainCategory?: boolean;
}

interface CategoryDropdownProps {
  id: string;
  error?: string;
  value: TyrioSelectInputOption | null;
  onChange: (x: TyrioSelectInputOption | null) => void;
}
export const CategoryDropdownInputSingle = ({
  id,
  error,
  value,
  onChange,
}: CategoryDropdownProps) => {
  const { data: mainCategories } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  );

  const shapedCategories = useMemo(() => {
    const response: Record<number, DBProductCategory> = {};
    mainCategories?.forEach((c) => {
      response[c.id] = c;
    });
    return response;
  }, [mainCategories]);

  const getParentNames = useCallback(
    (c: DBProductCategory, existing: string[]): string[] => {
      if (!c.parent_category_id) return [c.name, ...existing];

      return getParentNames(shapedCategories[c.parent_category_id], [
        c.name,
        ...existing,
      ]);
    },
    [shapedCategories]
  );
  const categories = useMemo(() => {
    return (
      mainCategories
        ?.map((c) => {
          const names = getParentNames(c, []);
          return {
            label: names.join(' > '),
            value: c.id,
            mainCategory: !c.parent_category_id,
          };
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1)) || []
    );
  }, [getParentNames, mainCategories]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        fullWidth
        disablePortal
        id={id}
        options={categories}
        onChange={(e, x) => onChange(x)}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            label="Category"
          />
        )}
        renderOption={(item, el) => (
          <MenuItem {...item}>
            {el.mainCategory ? <strong>{el.label}</strong> : el.label}
          </MenuItem>
        )}
      />
    </FormControl>
  );
};
