import styled from '@emotion/styled';
import { Radio, Typography } from '@mui/material';
import { formatCurrencyWithSymbol } from '../../../../../../../helpers/currency-format';
import { usePosCtx } from '../../../../../../../context/POSContext';
import { useCalculatePrice } from '../../../helpers/calculation';
import { usePosCartData } from '../../../helpers/cart-data';

interface DeliveryCardProps {
  delivery: string;
  text: string;
  onClick: (index: string) => void;
  price: number;
  icon: string;
  disabled: boolean;
  isChecked: boolean;
  itemIndex: string;
}

export const DeliveryCard = ({
  delivery,
  text,
  onClick,
  price,
  icon,
  disabled,
  isChecked,
  itemIndex,
}: DeliveryCardProps) => {
  const { isVatIncluded } = usePosCtx();
  const { modalData } = usePosCartData();

  const { vat } = useCalculatePrice(modalData, price);
  const deliveryPrice = !isVatIncluded ? price : price + (price * vat) / 100;
  return (
    <Wrapper
      disabled={disabled}
      onClick={() => {
        !disabled && onClick(itemIndex);
      }}
    >
      <InfoWrapper>
        <Radio
          checked={isChecked}
          color={'info'}
          onClick={() => {
            !disabled && onClick(itemIndex);
          }}
        />
        <TextWrapper>
          <Text color={'black'} fontWeight={'400'} fontSize={'16'}>
            {delivery}
          </Text>
          <Text color={'#919eab'} fontWeight={'400'} fontSize={'14'}>
            {text}
          </Text>
        </TextWrapper>
      </InfoWrapper>
      {icon && <Image src={icon} alt={'delivery-icon'} />}
      <Price
        background={deliveryPrice === 0 ? '#3EB274' : '#ECF0F4'}
        color={deliveryPrice === 0 ? 'white' : 'black'}
        fontWeight={deliveryPrice === 0 ? '600' : '400'}
      >
        {deliveryPrice !== 0 ? formatCurrencyWithSymbol(deliveryPrice) : 'FREE'}
      </Price>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled: boolean }>`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  height: 74px !important;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  cursor: pointer;
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`;

const Text = styled(Typography)<{
  fontWeight: string;
  fontSize: string;
  color: string;
}>`
  color: ${(props) => props.color}
  font-family: Barlow;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.div<{
  background: string;
  color: string;
  fontWeight: string;
}>`
  min-width: 72px;
  height: 30px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Barlow;
  font-weight: ${(props) => props.fontWeight};
  font-size: 16px;
  padding: 5px;
`;

const Image = styled.img`
  width: 72px;
  height: 45px;
`;
