import * as z from "zod"

export const DBBrandModel = z.object({
  uid: z.string(),
  slug: z.string(),
  name: z.string(),
  brandClass: z.number().int().nullish(),
  brandGroup: z.number().int().nullish(),
  logoUrl: z.string(),
})
