import { DBStockInItemStatus } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBStockInItemsResponse, DBStockInListApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';

export const useUpdateStockIn = (
  selectedOrderData: DBStockInItemsResponse | null,
  setSelectedOrderData: Dispatch<SetStateAction<DBStockInItemsResponse | null>>,
  refetchData: () => void,
  userId: string
) => {
  const { mutate } = useMutation(
    (request: DBStockInListApi['updateOne']['request']) =>
      api.fetch<DBStockInListApi['updateOne']>('update_stock_in', {
        orderId: selectedOrderData?.id,
        ...request,
      }),
    {
      mutationKey: 'update_stock_in',
      onSuccess: (data: DBStockInItemsResponse) => {
        data &&
          setSelectedOrderData({
            ...data,
            status: data.status,
          });
        refetchData();
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          if (
            errorMessage ===
              `You can't cancel this order because it's completed!` &&
            selectedOrderData
          )
            setSelectedOrderData({
              ...selectedOrderData,
              status: DBStockInItemStatus.COMPLETED,
            });

          ToastHelper.showToast(
            'Stock IN order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const { mutate: updateStatus } = useMutation(
    (id: string) =>
      api.fetch<DBStockInListApi['getOne']>('change_stock_in_favorite_status', {
        orderId: id,
      }),
    {
      mutationKey: 'change_stock_in_favorite_status',
      onSuccess: () => {
        const isFavorited = selectedOrderData?.favoritedBy.includes(userId);
        refetchData();
        selectedOrderData &&
          setSelectedOrderData({
            ...selectedOrderData,
            favoritedBy: isFavorited
              ? selectedOrderData.favoritedBy.filter((item) => item !== userId)
              : [...selectedOrderData.favoritedBy, userId],
          });
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          undefined,
          `Order successfully ${
            isFavorited ? 'removed from' : 'added to'
          } favorites`
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Order',
          ToastType.ERROR,
          undefined,
          'An error occured!'
        );
      },
    }
  );

  return { updateOrder: mutate, updateFavoriteStatusMutation: updateStatus };
};
