import { createContext, PropsWithChildren, useContext } from 'react';
import { useQuery } from 'react-query';
import api from '@tyrio/api-factory';
import { DBRoleApi, DBUserApi } from '@tyrio/dto';
import {
  PermissionChild,
  PermissionItem,
  PermissionList,
} from '../../../types/types';
import { camelCase } from 'lodash';
import { DBAddress, DBClient } from '@prisma/client';

type UserContextInterface = {
  user: DBUserApi['getOne']['response'] | null;
  userLoading?: boolean;
  userAuthenticated: boolean;
  userError?: unknown | null;
  // TODO: Fix when we refactor permissions
  // eslint-disable-next-line
  permissionList: any;
  refetchUser: () => void;
  userRole?: string;
  roleId?: string;
  roleName?: string;
  clientId?: string;
  client?: (DBClient & { address?: DBAddress }) | null | undefined;
};

export const UserContext = createContext<UserContextInterface>({
  user: null,
  userLoading: false,
  userError: null,
  permissionList: {},
  refetchUser: () => null,
  userAuthenticated: false,
});

const useAuthProviderData = () => {
  const {
    isFetching,
    isLoading,
    error,
    data: user,
    refetch: refetchUser,
  } = useQuery(['me'], () => api.fetch<DBUserApi['getOne']>('get_user'), {
    initialData: null,
    retry: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const userRole = user?.userRole;
  const roleId = user?.roleId;
  const clientId = user?.clientId ? user.clientId : '';

  const { data: role_data } = useQuery(
    ['role_id', roleId],
    () =>
      api.fetch<DBRoleApi['getOne']>(`role_id`, {
        id: roleId ?? '',
      }),
    {
      enabled: !!roleId,
    }
  );

  // eslint-disable-next-line
  let permissionList: any = {};

  Object.values(role_data?.permissions || {}).forEach((el: PermissionItem) => {
    permissionList = { ...permissionList, [camelCase(el.label)]: el.values };
    if (el.childs) {
      const children = (Array.isArray(el.childs)
        ? el.childs
        : Object.values(el.childs)) as unknown as PermissionChild[];

      children?.forEach((child: PermissionChild) => {
        permissionList = {
          ...permissionList,
          [camelCase(el.label)]: {
            ...permissionList[camelCase(el.label) as keyof PermissionList],
            [camelCase(child.label)]: child.values,
          },
        };
      });
    }
  });

  return {
    user: user ?? null,
    userRole,
    roleId,
    roleName: role_data?.roleName,
    permissionList,
    clientId,
    client: user?.client,
    userError: error,
    userLoading: isFetching || isLoading,
    userAuthenticated: !!(user && user.email),
    refetchUser,
  };
};

const AuthProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => {
  const data = useAuthProviderData();
  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export default AuthProvider;
export const useAuth = () => useContext(UserContext);
