import { DBFile } from '@prisma/client';

export const files: DBFile[] = [
  {
    id: 'ccb395be-2352-4da5-8511-13b8b7d3fdb4',
    fileName: 'supplier-logo/1681803741299',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803741299',
    key: 'supplier-logo/1681803741299',
  },
  {
    id: '5e38574d-78fa-4f64-95b3-224e06651960',
    fileName: 'supplier-logo/1681803753549',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803753549',
    key: 'supplier-logo/1681803753549',
  },
  {
    id: 'cf3a5dc1-45f0-4e4b-8f5a-d6ecc9ef3a5a',
    fileName: 'supplier-logo/1681803778733',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803778733',
    key: 'supplier-logo/1681803778733',
  },
  {
    id: '1789fd1a-4f50-4bc9-9cd3-725f97a71e6d',
    fileName: 'supplier-logo/1681803794463',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803794463',
    key: 'supplier-logo/1681803794463',
  },
  {
    id: 'e98b28cf-8344-40c6-b774-04b93461e45b',
    fileName: 'supplier-logo/1681803817174',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803817174',
    key: 'supplier-logo/1681803817174',
  },
  {
    id: 'f1319c0e-33cb-4d49-8a4e-40cc9a8ddc78',
    fileName: 'supplier-logo/1681803824882',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803824882',
    key: 'supplier-logo/1681803824882',
  },
  {
    id: 'f4ffa54d-8e55-480e-a2ba-18e73e622e07',
    fileName: 'supplier-logo/1681803837958',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803837958',
    key: 'supplier-logo/1681803837958',
  },
  {
    id: '3f6ab3a7-7ac9-42fa-8844-092f221498c9',
    fileName: 'supplier-logo/1681803900070',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803900070',
    key: 'supplier-logo/1681803900070',
  },
  {
    id: 'e23b5b0d-7c3b-4033-ae76-a03a35878db4',
    fileName: 'supplier-logo/1681803981184',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681803981184',
    key: 'supplier-logo/1681803981184',
  },
  {
    id: 'dd22bff3-1cec-4816-86d8-9779b5b0290d',
    fileName: 'supplier-logo/1681804018275',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681804018275',
    key: 'supplier-logo/1681804018275',
  },
  {
    id: '4091db0b-78fb-47c9-a3b3-143fba0240fe',
    fileName: 'supplier-logo/1681804044903',
    fileUrl:
      'https://d-tyrio-storage-189d46b.s3.amazonaws.com/supplier-logo/1681804044903',
    key: 'supplier-logo/1681804044903',
  },
];
