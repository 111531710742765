import { TRule } from '../../typings';

export const VANDENBAN_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Stock_CM']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Manufacturer_code']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Package_quantity']",
    destination: 'product',
    targetKey: 'packageQuantity',
    outputType: 'number',
  },
  {
    type: 'rule.condition',
    sourceKey: "c.['Package_quantity']",
    condition: 'equals',
    conditionValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'packageQuantity',
    outputValue: 1,
    outputType: 'number',
  },
];
