import { Typography } from '@mui/material';

import {
  CardWrapper,
  CartTitle,
  CardQuantity,
  CartImageWrapper,
  Content,
} from '../styles/styles';
import { DataMapping } from './mapping';

export const Card = ({
  quantity,
  title,
  image,
}: {
  quantity: number;
  title: string;
  image: string;
}) => {
  return (
    <CardWrapper>
      <CartTitle>
        <CartImageWrapper>
          <img src={image} alt="" />
          <Typography fontSize={20} fontWeight={600}>
            {title}
          </Typography>
        </CartImageWrapper>
        <CardQuantity noItems={quantity === 0}>{quantity}</CardQuantity>
      </CartTitle>

      <Content>{DataMapping(title)}</Content>
    </CardWrapper>
  );
};
