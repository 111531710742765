/* eslint-disable @typescript-eslint/no-explicit-any */
//TODO: Fix all any-s in this component
import styled from '@emotion/styled';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { DBProductCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBProductModelApi,
  DBProductModelPayload,
  PhotoShape,
} from '@tyrio/dto';
import SourceContainer, { PermissionsValues } from '@tyrio/forms';
import {
  BRAND_DROPDOWN_DATA,
  // COLOR_DROPDOWN_DATA,
  COLOR_FINISH_DROPDOWN_DATA,
  COLOR_GROUP_DROPDOWN_DATA,
  SEASON_DROPDOWN_DATA,
  SIDEWALL_DROPDOWN_DATA,
  SPECIAL_MARKING,
  WHEEL_POSITIONS_DROPDOWN_DATA,
} from '@tyrio/shared-vars';
import {
  backIcon,
  CancelModal,
  CategoryDropdownInput,
  DeleteModal,
  RouteRouterPrompt,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
} from '@tyrio/ui-library';
import _, { get, isEmpty, startCase } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { queryClient } from '../../../../query-client';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import {
  getAutocompleteSelectChip,
  getAutocompleteSingleField,
  getTextField,
} from '../../form-helper';
import FormSectionWithTitle from './components/FormSectionWithTitle';
import { ProductMedia } from './components/ProductMedia';
import ProductSidebar from './components/ProductSidebar';

interface ProductModelPageProps {
  permissions: PermissionsValues;
  model?: DBProductModelPayload;
  shapedCategories: Record<number, DBProductCategory>;
  modelPhotos: Record<string, PhotoShape>;
  setModelPhotos: Dispatch<SetStateAction<Record<string, PhotoShape>>>;
}
const ProductModelPage = ({
  permissions,
  model,
  shapedCategories,
  modelPhotos,
  setModelPhotos,
}: ProductModelPageProps) => {
  const history = useHistory();
  const isEditDisabled = !permissions?.edit || false;
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const mutation = useMutation(
    (data: DBProductModelApi['upsert']['requestBody']) =>
      api.fetch('create_model', { ...data, newModel: !model }),
    {
      mutationKey: 'create_model',
      onSuccess: (data: any) => {
        queryClient.refetchQueries('get_product_model_by_id');
        ToastHelper.showToast(
          'Product model',
          ToastType.SUCCESS,
          model?.uid ? ToastMessageType.UPDATE : ToastMessageType.CREATE
        );

        if (data.uid && !model?.uid) history.goBack();
      },
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  const handleDeleteProductModel = () => {
    if (model?.uid) setIsDeleteModalVisible(true);
  };

  const deleteProductModelMutation = useMutation(
    () =>
      api.fetch<DBProductModelApi['getOne']>('delete_product_model', {
        id: model?.uid,
      }),
    {
      mutationKey: 'delete_product_model',
      onSuccess: () => {
        history.push(
          `/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1`
        );
        ToastHelper.showToast(
          'Product model',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Product model',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const modelAttributesToSkip = ['uid', 'dataSource'];
  const generateDefaultValues = () => {
    if (!model) return {};

    return {
      ...model,
      category_id: {
        label: shapedCategories[model.category_id].name,
        id: shapedCategories[model.category_id].id,
        main: !shapedCategories[model.category_id].parent_category_id,
      },
      sidewall: Object.values(_.get(model.productMeta, 'sidewall', {})).map(
        (item: any) => {
          return {
            label: [item.key, item.description].join(' - '),
            value: item.key,
          };
        }
      ),
    };
  };

  const {
    handleSubmit,
    register,
    watch,
    unregister,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...generateDefaultValues(),
    } as any,
    resolver: (formValues) => {
      if (!formValues['category_id']) {
        return {
          values: formValues,
          errors: {
            category_id: 'Category must be set before continuing.',
          },
        };
      }

      const { category_id, ...values } = formValues;

      const normalized: any = { ...values, category_id: category_id.id };

      const modelAttrs =
        shapedCategories[category_id.id]?.model_attributes || {};

      const errors: Record<string, string> = {};

      Object.keys(modelAttrs).forEach((key) => {
        const validationCode = get(modelAttrs, `${key}`, 0);

        if (
          validationCode > 0 &&
          !modelAttributesToSkip.includes(key) &&
          !normalized[key]
        ) {
          errors[key] = `${startCase(key)} must be set before continuing`;
        }

        //This part of code works to prevent that when the user enters "space" several times
        if (
          typeof formValues[key] === 'string' &&
          formValues[key].length !== 0 &&
          formValues[key].trim().length === 0
        ) {
          errors[key] = 'You can not enter space as valid value of this field';
        }
      });

      return {
        values: formValues,
        errors,
      };
    },

    shouldFocusError: false,
    shouldUseNativeValidation: false,
  });

  const categoryIdFormValue = watch('category_id');

  const categoryId = useMemo(
    () => model?.category_id || categoryIdFormValue?.id,
    [categoryIdFormValue?.id, model?.category_id]
  );
  const isRequiredField = useCallback(
    (fieldKey: string) => {
      if (!categoryId) return false;
      const categoryModelAttributes =
        (shapedCategories[categoryId].model_attributes as Record<
          string,
          number
        >) || {};

      return categoryModelAttributes[fieldKey] >= 1;
    },
    [categoryId, shapedCategories]
  );

  const onSubmit = (data: any) => {
    setIsSaving(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sidewall, DBProduct, category, category_id, ...restModel } = data;

    const sidewallMeta: Record<string, any> = {};
    const newSidewallArray: string[] = [];
    sidewall?.forEach((entry: any) => {
      sidewallMeta[entry.value] = _.get(SPECIAL_MARKING, entry.value);
      newSidewallArray.push(entry.value);
    });

    const photoArray: PhotoShape[] = [];

    Object.values(modelPhotos).forEach((photo) => photoArray.push(photo));

    // sort photos so that favorite photo is always first
    photoArray.sort((a, b) =>
      a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1
    );

    const payload = {
      ...restModel,
      productMeta: {
        sidewall: sidewallMeta,
      },
      sidewall: newSidewallArray.join(' '),
      category_id: category_id.id,
      photos: photoArray ?? [],
    };

    mutation.mutate(payload);
  };

  const categorySelectValue = watch('category_id');

  const shouldShowField = useCallback(
    (key: string) => {
      const attrs: any =
        shapedCategories[categorySelectValue?.id]?.model_attributes || {};

      const shouldShow = attrs[key] > -1;
      if (!shouldShow && dirtyFields[key]) unregister(key);

      return shouldShow;
    },

    [categorySelectValue?.id, dirtyFields, shapedCategories, unregister]
  );

  useEffect(() => {
    if (isDirty)
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = '';
      };
  }, [isDirty]);

  const renderField = useCallback(
    (field: string, xs: number, lg?: number, title?: string) => {
      const FIELDS: Record<string, JSX.Element | null | false> = {
        brand: getAutocompleteSingleField(
          'Brand',
          'brand',
          isRequiredField,
          errors,
          control,
          { xs, lg },
          {},
          BRAND_DROPDOWN_DATA,
          isEditDisabled
        ),
        modelName: getTextField(
          'Model name',
          'modelName',
          isRequiredField,
          errors,
          register,
          {
            xs,
            lg,
          },
          undefined,
          { disabled: isEditDisabled, shrink: true }
        ),
        season:
          shouldShowField('season') &&
          getAutocompleteSingleField(
            'Season',
            'season',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            SEASON_DROPDOWN_DATA,
            isEditDisabled
          ),
        wheelPosition:
          shouldShowField('wheelPosition') &&
          getAutocompleteSingleField(
            'Wheel Position',
            'wheelPosition',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            WHEEL_POSITIONS_DROPDOWN_DATA,
            isEditDisabled
          ),
        sidewall:
          shouldShowField('sidewall') &&
          getAutocompleteSelectChip(
            'Sidewall',
            'sidewall',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            SIDEWALL_DROPDOWN_DATA,
            (opt) => opt.label,
            isEditDisabled
          ),
        color:
          shouldShowField('color') &&
          getTextField(
            'Color',
            'color',
            isRequiredField,
            errors,
            register,
            {
              xs,
              lg,
            },
            undefined,
            { disabled: isEditDisabled, shrink: true }
          ),
        // getAutocompleteSingleField(
        //   'Color',
        //   'color',
        //   isRequiredField,
        //   errors,
        //   control,
        //   { xs, lg },
        //   {
        //     freeSolo: true,
        //   },
        //   COLOR_DROPDOWN_DATA,
        //   isEditDisabled
        // ),

        colorGroup:
          shouldShowField('colorGroup') &&
          getAutocompleteSingleField(
            'Color Group',
            'colorGroup',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            COLOR_GROUP_DROPDOWN_DATA,
            isEditDisabled
          ),

        colorFinish:
          shouldShowField('colorFinish') &&
          getAutocompleteSingleField(
            'Color Finish',
            'colorFinish',
            isRequiredField,
            errors,
            control,
            { xs, lg },
            {},
            COLOR_FINISH_DROPDOWN_DATA,
            isEditDisabled
          ),

        usage:
          shouldShowField('usage') &&
          getTextField(
            'Usage',
            'usage',
            isRequiredField,
            errors,
            register,
            {
              xs,
              lg,
            },
            undefined,
            { disabled: isEditDisabled, shrink: true }
          ),

        remark:
          shouldShowField('remark') &&
          getTextField(
            'Remark',
            'remark',
            isRequiredField,
            errors,
            register,
            {
              xs,
              lg,
            },
            undefined,
            { disabled: isEditDisabled, shrink: true }
          ),
        title: title ? (
          <Grid item xs={xs}>
            <FormSectionWithTitle label={title} />
          </Grid>
        ) : (
          <div />
        ),
      };

      return FIELDS[field];
    },
    [
      control,
      errors,
      isEditDisabled,
      isRequiredField,
      register,
      shouldShowField,
    ]
  );

  const categoryAttributeMappingBase: Record<
    number,
    Array<{ key: string; xs: number; lg?: number; title?: string }>
  > = {
    // CAR TYRES
    1: [
      { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
      { key: 'season', xs: 12, lg: 4 },
      { key: 'wheelPosition', xs: 12, lg: 4 },
      { key: 'sidewall', xs: 12, lg: 4 },
      { key: 'usage', xs: 12, lg: 12 },
      { key: 'remark', xs: 12, lg: 12 },
    ],
    // WHEELS
    8: [
      { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
      { key: 'color', xs: 12, lg: 3 },
      { key: 'colorGroup', xs: 12, lg: 3 },
      { key: 'colorFinish', xs: 12, lg: 3 },
      { key: 'season', xs: 12, lg: 3 },
      { key: 'remark', xs: 12, lg: 12 },
    ],
    // MOTO
    2: [
      { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
      { key: 'wheelPosition', xs: 12, lg: 4 },
      { key: 'sidewall', xs: 12, lg: 4 },
      { key: 'season', xs: 12, lg: 4 },
      { key: 'usage', xs: 12, lg: 12 },
      { key: 'remark', xs: 12, lg: 12 },
    ],
    // ATV
    3: [
      { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
      { key: 'wheelPosition', xs: 12, lg: 4 },
      { key: 'sidewall', xs: 12, lg: 4 },
      { key: 'season', xs: 12, lg: 4 },
      { key: 'usage', xs: 12, lg: 12 },
      { key: 'remark', xs: 12, lg: 12 },
    ],
    // BIKE
    5: [
      { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
      { key: 'wheelPosition', xs: 12, lg: 4 },
      { key: 'sidewall', xs: 12, lg: 4 },
      { key: 'color', xs: 12, lg: 4 },
      { key: 'usage', xs: 12, lg: 12 },
      { key: 'remark', xs: 12, lg: 12 },
    ],
    // AGRO
    7: [
      { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
      { key: 'season', xs: 12, lg: 6 },
      { key: 'wheelPosition', xs: 12, lg: 6 },
      { key: 'usage', xs: 12, lg: 12 },
      { key: 'remark', xs: 12, lg: 12 },
    ],
    // TERETNE
    4: [
      { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
      { key: 'season', xs: 12, lg: 4 },
      { key: 'wheelPosition', xs: 12, lg: 4 },
      { key: 'sidewall', xs: 12, lg: 4 },
      { key: 'usage', xs: 12, lg: 12 },
      { key: 'remark', xs: 12, lg: 12 },
    ],
  };

  const categoryAttributeMappingDefault = [
    { key: 'title', xs: 12, lg: 12, title: 'Attributes' },
    { key: 'color', xs: 12, lg: 6 },
    { key: 'usage', xs: 12, lg: 12 },
    { key: 'remark', xs: 12, lg: 12 },
  ];

  const categoryAttributeMapping = (key: number) =>
    key
      ? categoryAttributeMappingBase[key] || categoryAttributeMappingDefault
      : undefined;

  const getParentCategory = useCallback(
    (catId: number, shapedCategories: Record<number, any>): any => {
      if (!shapedCategories || !catId) return undefined;
      const selectedCategory = shapedCategories[catId];
      if (selectedCategory?.parent_category_id) {
        return getParentCategory(
          selectedCategory.parent_category_id,
          shapedCategories
        );
      }

      return selectedCategory;
    },
    []
  );

  const parentCategory = useMemo(
    () => getParentCategory(categoryId, shapedCategories),
    [categoryId, getParentCategory, shapedCategories]
  );

  return (
    <PageTemplateWrapper style={{ height: '100%' }}>
      <PageTemplateContent
        style={{ padding: '16px', height: '100%', width: 'calc(100% - 380px)' }}
      >
        {!isCancelModalVisible && (
          <RouteRouterPrompt
            when={isDirty && !isSaving}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() => true}
          />
        )}
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              history.goBack();
              setIsCancelModalVisible(false);
            }}
          />
        )}
        {isDeleteModalVisible && (
          <DeleteModal
            LBAction={() => setIsDeleteModalVisible(false)}
            RBAction={() => {
              deleteProductModelMutation.mutate();
              setIsDeleteModalVisible(false);
            }}
            itemName={model?.modelName || ''}
          />
        )}
        <TitleWrapper>
          <Title>
            <img
              src={backIcon}
              alt="back"
              style={{
                paddingBottom: 0,
                width: '16px',
                height: '22px',
                cursor: 'pointer',
              }}
              onClick={() => history.goBack()}
            />
            <TitleText>
              {model ? model.modelName : 'Create new product model'}
            </TitleText>
          </Title>

          <FormControlLabel
            control={
              <Switch
                id="active"
                color="info"
                disabled={!permissions.edit}
                defaultChecked={model?.uid ? model?.active : true}
                {...register('active')}
              />
            }
            label="Active"
          />
        </TitleWrapper>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <TyrioTabs>
            <TyrioTab title="Main" key="main" errored={!isEmpty(errors)}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <FormSectionWithTitle label="Product model main attributes" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CategoryDropdownInput
                    label="Category *"
                    id="category_id"
                    control={control}
                    isEditDisabled={isEditDisabled}
                  />
                </Grid>
                {renderField('brand', 12, 6)}

                {renderField('modelName', 12, 12)}
                <Grid item xs={12} lg={4}></Grid>
              </Grid>
              <Grid container spacing={2}>
                {parentCategory &&
                  categoryAttributeMapping(parentCategory?.id)?.map((item) =>
                    renderField(item.key, item.xs, item.lg, item.title)
                  )}
              </Grid>
            </TyrioTab>
            <TyrioTab title="Media" key="media">
              <ProductMedia
                isRequired={isRequiredField}
                errors={errors}
                register={register}
                isEditDisabled={isEditDisabled}
                uid={model?.uid ?? ''}
                photos={(model?.photos as unknown as PhotoShape[]) ?? []}
                modelPhotos={modelPhotos}
                setModelPhotos={setModelPhotos}
              />
            </TyrioTab>
            <TyrioTab title="Description" key="description">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <FormSectionWithTitle label="Product Description" />
                </Grid>
                {getTextField(
                  'Title',
                  'descTitle',
                  isRequiredField,
                  errors,
                  register,
                  {
                    xs: 12,
                    lg: 12,
                  },
                  undefined,
                  { disabled: isEditDisabled, shrink: true }
                )}
                {getTextField(
                  'Subtitle',
                  'descSubtitle',
                  isRequiredField,
                  errors,
                  register,
                  {
                    xs: 12,
                    lg: 12,
                  },
                  undefined,
                  { disabled: isEditDisabled, shrink: true }
                )}
                {getTextField(
                  'Description intro',
                  'descIntro',
                  isRequiredField,
                  errors,
                  register,
                  { xs: 12, lg: 12 },
                  undefined,
                  {
                    multiline: true,
                    rows: 4,
                    maxRows: 7,
                    disabled: isEditDisabled,
                    shrink: true,
                  }
                )}
                {getTextField(
                  'Description content',
                  'descContent',
                  isRequiredField,
                  errors,
                  register,
                  { xs: 12, lg: 12 },
                  undefined,
                  {
                    multiline: true,
                    rows: 6,
                    maxRows: 10,
                    disabled: isEditDisabled,
                    shrink: true,
                  }
                )}
              </Grid>
            </TyrioTab>
          </TyrioTabs>

          <SourceContainterDivider>
            <SourceContainer
              data={{
                data_source: '',
                created: '',
                last_edited: '',
              }}
              onCancel={() =>
                permissions?.edit
                  ? setIsCancelModalVisible(true)
                  : history.goBack()
              }
              onDelete={handleDeleteProductModel}
              permissions={permissions}
            />
          </SourceContainterDivider>
        </form>
      </PageTemplateContent>
      {model && (
        <ProductSidebarWrapper>
          <ProductSidebar model={model} />
        </ProductSidebarWrapper>
      )}
    </PageTemplateWrapper>
  );
};

const ProductSidebarWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const SourceContainterDivider = styled.div`
  margin-top: 80px;
  width: 100%;

  @media (max-width: 880px) {
    margin-top: 150px;
  }

  @media (max-width: 768px) {
    margin-top: 80px;
  }

  @media (max-width: 450px) {
    margin-top: 150px;
  }
`;

const TitleText = styled.div`
  margin-left: 16px;
  font-weight: 700;
  font-size: 24px;
`;

export default ProductModelPage;
