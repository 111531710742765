/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { css, Global } from '@emotion/react';
import { Column, useBlockLayout, usePagination, useTable } from 'react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TableCell from './Cells/TableCell';
import { TableCellInterface } from './temp';
import ArticleCell from './Cells/ArticleCell';
import _ from 'lodash';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from '@mui/material';
import { StickyTableTotalCell } from './Cells/TotalCell';
import FooterCell from './Cells/FooterCell';
import { tyrioUI } from '@tyrio/ui-library';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { PriceComparisonShape } from '@tyrio/dto';
import { filterPaginated } from './stickyTableHelper';
import { DBProduct, DBSupplierType } from '@prisma/client';
import classNames from 'classnames';

interface StickyTableProps {
  rawData: PriceComparisonShape;
  columns: Array<Column<TableCellInterface>>;
  data: TableCellInterface[];
  shouldShowDifference: boolean;
  onChangeSelectedRow: (id: string) => void;
  // Reserved
  onChangeValues: (values: Record<string, Record<string, number>>) => void;
  initialQtyValues: { value: string; qty: number }[];
  shouldShowCode: boolean;
}

export default function StickyTable(props: StickyTableProps) {
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortState, setSortState] = useState({
    sortKey: 'articleName',
    sortDirection: 'DESC',
  });
  const tableRef = useRef(null);

  const productEanToIdMap = useMemo(() => {
    const x: Record<string, DBProduct> = {};
    Object.values(props.rawData.products).forEach((product) => {
      x[product.ean] = product;
    });
    return x;
  }, [props.rawData.products]);

  const generateInitialVals = useCallback(() => {
    const newValues: any = {};

    (props.initialQtyValues || []).forEach((entry) => {
      const ean = entry.value;
      const qty = entry.qty;
      const product = productEanToIdMap[ean];

      if (!product) return; // Add this to errors?

      const productSuppliers = _.get(
        props.rawData,
        `prices.${product.uid}`,
        {}
      );

      const supplierWithLowestPrice = Object.keys(productSuppliers)
        .map((key) => ({
          id: key,
          value: productSuppliers[key].value,
          quantity: productSuppliers[key].quantity,
        }))
        .filter((item) => item.quantity >= 0)
        .sort((a, b) => b.value - a.value)
        .pop();

      const supplierWithLowestPriceId = supplierWithLowestPrice?.id;
      const mainSupplierId = Object.keys(props.rawData.mainSupplier || {})[0];

      const suppId =
        supplierWithLowestPriceId === mainSupplierId
          ? 'mainSupplier'
          : supplierWithLowestPriceId;

      let qtyLimit = Infinity;
      if (supplierWithLowestPriceId) {
        const supplierType =
          props.rawData.suppliers[supplierWithLowestPriceId]?.supplierType;

        if (supplierType !== DBSupplierType.MANUFACTURER) {
          qtyLimit =
            props.rawData.prices[product.uid][supplierWithLowestPriceId]
              .quantity;
        }
      }
      if (suppId) {
        newValues[product.uid] = {
          [suppId]: Math.min(qty, qtyLimit),
        };
      }
    });

    return newValues;
  }, [productEanToIdMap, props.initialQtyValues, props.rawData]);

  const [values, setValues] = useState<Record<string, Record<string, number>>>(
    generateInitialVals()
  );

  useEffect(() => {
    props.onChangeValues(values);
  }, [values, props]);

  const { columns, data } = props;
  const defaultColumn = {
    minWidth: 100,
    maxWidth: 600,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: rowsCompleteList,
    prepareRow,
  } = useTable(
    { columns, data, defaultColumn },
    useBlockLayout,
    // useSticky,
    usePagination
  );

  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [tableOptions, setTableOptions] = useState({
    left: true,
    right: true,
    canScroll: false,
    extraArticleWidth: 0,
  });

  const footerGroups = headerGroups.slice().reverse();

  const rows = useMemo(
    () =>
      filterPaginated(
        rowsCompleteList,
        page,
        itemsPerPage,
        sortState.sortKey,
        sortState.sortDirection
      ),
    [
      itemsPerPage,
      page,
      rowsCompleteList,
      sortState.sortKey,
      sortState.sortDirection,
    ]
  );
  const count = useMemo(() => {
    return Math.ceil(rowsCompleteList.length / itemsPerPage);
  }, [itemsPerPage, rowsCompleteList.length]);
  useEffect(() => {
    if (rows.length === 0 && page > 0) {
      setPage(page - 1);
    }
  }, [page, rows.length, rowsCompleteList.length]);

  const generateRowForFooter = useCallback(() => {
    const response: Record<string, { value: number; numberOfItems: number }> =
      {};

    props.columns.forEach((col) => {
      let numOfItems = 0;
      let valueOfElem = 0;
      Object.keys(values).forEach((value) => {
        if (values[value][`${col.accessor}`]) {
          if (col.accessor === 'mainSupplier') {
            numOfItems += values[value][`${col.accessor}`];
            const mainSupplierId = Object.keys(
              props.rawData.mainSupplier || {}
            )[0];
            const priceToBe = props.rawData.prices[value][mainSupplierId];
            if (priceToBe && priceToBe.value)
              valueOfElem += priceToBe.value * values[value][`${col.accessor}`];
          } else {
            numOfItems += values[value][`${col.accessor}`];

            const priceToBe = props.rawData.prices[value][`${col.accessor}`];
            if (priceToBe && priceToBe.value)
              valueOfElem += priceToBe.value * values[value][`${col.accessor}`];
          }
        }
      });
      response[`${col.accessor}`] = {
        value: valueOfElem,
        numberOfItems: numOfItems,
      };
    });

    return response;
  }, [props.columns, props.rawData.mainSupplier, props.rawData.prices, values]);

  const footerRows = useMemo(
    () => generateRowForFooter(),
    [generateRowForFooter]
  );

  const handleChangeSort = (columnId: string) => {
    if (columnId !== 'total')
      setSortState({
        sortKey: columnId,
        sortDirection: sortState.sortDirection === 'ASC' ? 'DESC' : 'ASC',
      });
  };

  const getNumberOfAvailableItemsForReserved = useMemo(() => {
    return Object.values(props.initialQtyValues).reduce(
      (acc, curr) => acc + curr.qty,
      0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rawData, values]);

  const totalValue = useMemo(() => {
    return Object.values(footerRows)
      .map((item) => item.value)
      .reduce((acc, curr) => acc + curr, 0);
  }, [footerRows]);
  const totalNumOfItems = useMemo(() => {
    return Object.values(footerRows)
      .map((item) => item.numberOfItems)
      .reduce((acc, curr) => acc + curr, 0);
  }, [footerRows]);

  const initialRequestedValues = useMemo(() => {
    const initialQty = props.initialQtyValues;

    const shape: Record<string, number> = {};

    initialQty.forEach((item) => {
      shape[productEanToIdMap[item.value].uid] = item.qty;
    });

    return shape;
  }, [productEanToIdMap, props.initialQtyValues]);

  const handleScrollPositions = useCallback(
    (e: any) => {
      const max =
        e.nativeEvent?.target?.scrollWidth - e.nativeEvent?.target?.offsetWidth;

      const FIXED_WIDTHS = 330 + 150; // Article name + Total column width
      const SUPPLIER_WIDTHS = props.columns.length * 130; // Supplier columns * width of column
      const CLIENT_WIDTH = e.nativeEvent.target.clientWidth;
      let extraWidth = 0;

      if (max === 0) {
        const currentWidth = FIXED_WIDTHS + SUPPLIER_WIDTHS;

        while (extraWidth + currentWidth <= CLIENT_WIDTH) {
          extraWidth += 130;
        }
        extraWidth += 161;

        if (props.shouldShowCode) {
          // extraWidth += 72;
        }
      }

      if (max === 0) {
        setTableOptions({
          ...tableOptions,
          left: true,
          right: true,
          extraArticleWidth: extraWidth,
        });
        return;
      }
      const pos = Math.ceil(e.nativeEvent?.target?.scrollLeft);

      const leftPosition = props.shouldShowCode ? 150 : 0;

      if (pos <= leftPosition) {
        setTableOptions({
          left: true,
          right: false,
          canScroll: true,
          extraArticleWidth: extraWidth,
        });
      } else if (
        pos > leftPosition &&
        pos < max &&
        (tableOptions.left || tableOptions.right)
      ) {
        setTableOptions({
          left: false,
          right: false,
          canScroll: true,
          extraArticleWidth: extraWidth,
        });
      } else if (pos === max) {
        setTableOptions({
          left: false,
          right: true,
          canScroll: true,
          extraArticleWidth: extraWidth,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.shouldShowCode,
      props.columns,
      tableOptions.left,
      tableOptions.right,
      tableOptions.canScroll,
      tableOptions.extraArticleWidth,
    ]
  );

  useEffect(() => {
    if (tableRef.current) {
      handleScrollPositions({ nativeEvent: { target: tableRef.current } });
    }
  }, [handleScrollPositions]);

  return (
    <div
      style={{
        width: '100%',

        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Global
        styles={css`
          body {
            overflow-x: hidden;
          }
        `}
      />
      <ScrollerBg
        hasMainSupplier={!!props.rawData.mainSupplier}
        left={tableOptions.left}
        right={tableOptions.right}
        count={props.columns.length - 2}
        shouldShowCode={props.shouldShowCode}
      />
      <TablesWrapper>
        <Styles
          tableOptions={tableOptions}
          ref={tableRef}
          onScroll={handleScrollPositions}
          style={{ width: '100%' }}
          showCode={props.shouldShowCode}
          count={props.columns.length - 2}
          hasMainSupplier={!!props.rawData.mainSupplier}
        >
          <StyledTable {...getTableProps()} className={'table sticky'}>
            <StyledHeader className="header">
              {headerGroups.map((headerGroup) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                  key={`sticky_table_header_group_${headerGroup.id}`}
                >
                  {headerGroup.headers
                    .slice(0, headerGroup.headers.length - 1)
                    .map((column) => (
                      <div
                        {...column.getHeaderProps()}
                        className={classNames('th', column.id)}
                        onClick={() => handleChangeSort(column.id)}
                        key={`sticky_table_header_cell_${column.id}`}
                      >
                        <ArticleHeader
                          key={`sticky_table_header_article_${column.id}`}
                          style={{
                            alignItems:
                              column.id === 'articleName' ||
                              column.id === 'code'
                                ? 'left'
                                : 'center',
                          }}
                          title={column.Header?.toString() || ''}
                        >
                          <ArticleHeaderTitle
                            key={`sticky_table_header_article_title_${column.id}`}
                            title={column.Header?.toString() || ''}
                          >
                            {column.render('Header')}
                            {column.id !== 'code' && column.id !== 'total' && (
                              <ArrowsContainer>
                                <ArrowsWrapper>
                                  <KeyboardArrowUp
                                    style={{
                                      height:
                                        column.id === sortState.sortKey &&
                                        sortState.sortDirection === 'ASC'
                                          ? '20px'
                                          : '15px',
                                      width:
                                        column.id === sortState.sortKey &&
                                        sortState.sortDirection === 'ASC'
                                          ? '20px'
                                          : '15px',
                                      opacity:
                                        (column.id === sortState.sortKey &&
                                          sortState.sortDirection === 'ASC') ||
                                        column.id !== sortState.sortKey
                                          ? 1
                                          : 0.3,
                                      color: '#6B7280',
                                    }}
                                  />
                                </ArrowsWrapper>
                                <ArrowsWrapper>
                                  <KeyboardArrowDown
                                    style={{
                                      height:
                                        column.id === sortState.sortKey &&
                                        sortState.sortDirection !== 'ASC'
                                          ? '20px'
                                          : '15px',
                                      width:
                                        column.id === sortState.sortKey &&
                                        sortState.sortDirection !== 'ASC'
                                          ? '20px'
                                          : '15px',
                                      opacity:
                                        (column.id === sortState.sortKey &&
                                          sortState.sortDirection !== 'ASC') ||
                                        column.id !== sortState.sortKey
                                          ? 1
                                          : 0.3,
                                      color: '#6B7280',
                                    }}
                                  />
                                </ArrowsWrapper>
                              </ArrowsContainer>
                            )}
                          </ArticleHeaderTitle>
                        </ArticleHeader>
                      </div>
                    ))}
                </div>
              ))}
            </StyledHeader>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row, rowIdx) => {
                prepareRow(row);
                if (
                  selectedRow === rowIdx &&
                  row.cells[0].value &&
                  row.cells[0].value['id']
                )
                  props.onChangeSelectedRow(row.cells[0].value['id']);
                let mainSuppValue: number | undefined = undefined;
                const rv: number[] = [];

                Object.keys(row.values).forEach((value) => {
                  if (
                    value !== 'articleName' &&
                    value !== 'code' &&
                    value !== 'total'
                  ) {
                    if (row.values[value]) rv.push(row.values[value]['value']);
                  }
                });

                const rowValues: number[] = rv.filter(
                  (item, index) => rv.indexOf(item) === index
                );
                const lowestVal = rowValues ? rowValues.sort().at(0) : 0;
                const secondLowestVal = rowValues ? rowValues.sort().at(1) : 0;

                return (
                  <>
                    <Row
                      {...row.getRowProps()}
                      className="tr trHover"
                      onClick={() => {
                        setSelectedRow(rowIdx);
                      }}
                      key={`sticky_table_body_row_${row.id}`}
                    >
                      {row.cells
                        .slice(0, row.cells.length - 1)
                        .map((cell, cellIdx) => {
                          if (cell.column.id === 'mainSupplier' && cell.value) {
                            mainSuppValue = cell.value.value;
                          }
                          let hasCodeColumn = false;
                          if (row.cells.find((c) => c.column.id === 'code'))
                            hasCodeColumn = true;

                          return (
                            <Cell
                              {...cell.getCellProps()}
                              pair={
                                hasCodeColumn
                                  ? cellIdx === 0 || cellIdx % 2 !== 0
                                  : cellIdx % 2 === 0
                              }
                              className={classNames('td', cell.column.id)}
                              key={`sticky_table_body_cell_${cellIdx}_${cell.column.id}`}
                            >
                              {cell.column.id === 'articleName' ? (
                                <ArticleCell
                                  title={cell.value.className}
                                  subtitle={cell.value.name}
                                  key={`sticky_table_article_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                />
                              ) : cell.column.id === 'mainSupplier' ? (
                                <TableCell
                                  key={`sticky_table_main_supplier_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                  isSelected={selectedRow === rowIdx}
                                  value={
                                    cell && cell.value
                                      ? cell.value.value
                                      : undefined
                                  }
                                  isLowestValue={
                                    cell.value && cell.value.value === lowestVal
                                  }
                                  isSecondLowest={
                                    cell.value &&
                                    cell.value.value === secondLowestVal
                                  }
                                  percentage={undefined}
                                  numberOfAvailableItems={
                                    cell.value ? cell.value.quantity : undefined
                                  }
                                  // These 2 props exist BELOW too!
                                  numberValue={
                                    values?.[row.values['articleName']['id']]?.[
                                      cell.column.id
                                    ]
                                  }
                                  onNumberChange={(e) => {
                                    let numberValue = parseInt(
                                      e.target.value,
                                      10
                                    );

                                    if (isNaN(numberValue) || !numberValue) {
                                      numberValue = 0;
                                    }
                                    setValues({
                                      ...values,
                                      [row.values['articleName']['id']]: {
                                        ...(values[
                                          row.values['articleName']['id']
                                        ] || {}),
                                        [cell.column.id]: numberValue,
                                      },
                                    });
                                  }}
                                />
                              ) : cell.column.id === 'total' ? (
                                <div className="totalContent">
                                  <StickyTableTotalCell
                                    key={`sticky_table_total_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                    price={row.cells.reduce((acc, curr) => {
                                      const r = curr?.row?.original?.[
                                        'articleName'
                                      ] as unknown as Record<string, string>;
                                      if (
                                        r['id'] &&
                                        curr?.value &&
                                        'value' in curr.value
                                      ) {
                                        const qty =
                                          values?.[r['id']]?.[curr.column.id] ||
                                          0;

                                        return acc + curr.value.value * qty;
                                      }

                                      return acc;
                                    }, 0)}
                                    qty={row.cells.reduce((acc, curr) => {
                                      const r = curr?.row?.original?.[
                                        'articleName'
                                      ] as unknown as Record<string, string>;
                                      const qty =
                                        values?.[r['id']]?.[curr.column.id] ||
                                        0;
                                      return acc + qty;
                                    }, 0)}
                                    availableNumber={
                                      initialRequestedValues[
                                        (row.original?.['articleName'] as any)
                                          ?.id
                                      ]
                                    }
                                    isHovered={false}
                                  />
                                </div>
                              ) : cell.column.id === 'code' ? (
                                <ArticleCell
                                  title={cell?.value?.ean}
                                  subtitle={cell?.value?.manufacturerCode}
                                  type={1}
                                  key={`sticky_table_code_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                ></ArticleCell>
                              ) : (
                                <TableCell
                                  key={`sticky_table_normal_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                                  isSelected={selectedRow === rowIdx}
                                  value={
                                    cell && cell.value
                                      ? cell.value.value
                                      : undefined
                                  }
                                  isLowestValue={
                                    cell.value && cell.value.value === lowestVal
                                  }
                                  isSecondLowest={
                                    cell.value &&
                                    cell.value.value === secondLowestVal
                                  }
                                  percentage={
                                    props.shouldShowDifference &&
                                    mainSuppValue &&
                                    cell.value &&
                                    cell.value.value
                                      ? cell.value.value / mainSuppValue -
                                        1.0000001
                                      : undefined
                                  }
                                  numberOfAvailableItems={
                                    cell.value ? cell.value.quantity : undefined
                                  }
                                  // These 2 props exist above too!
                                  numberValue={
                                    values?.[row.values['articleName']['id']]?.[
                                      cell.column.id
                                    ]
                                  }
                                  onNumberChange={(e) => {
                                    let numberValue = parseInt(
                                      e.target.value,
                                      10
                                    );

                                    if (isNaN(numberValue) || !numberValue) {
                                      numberValue = 0;
                                    }

                                    const supplier =
                                      props.rawData.suppliers[cell.column.id];

                                    const maxValue = cell.value
                                      ? cell.value.quantity
                                      : undefined;
                                    const value =
                                      supplier.supplierType ===
                                      DBSupplierType.DISTRIBUTER
                                        ? Math.min(numberValue, maxValue)
                                        : numberValue;
                                    setValues({
                                      ...values,
                                      [row.values['articleName']['id']]: {
                                        ...(values[
                                          row.values['articleName']['id']
                                        ] || {}),
                                        [cell.column.id]: value,
                                      },
                                    });
                                  }}
                                />
                              )}
                            </Cell>
                          );
                        })}
                    </Row>
                    {/*<RowSeparator />*/}
                  </>
                );
              })}
              <div className="footer">
                {footerGroups.map((footerGroup) => (
                  <div
                    {...footerGroup.getHeaderGroupProps()}
                    className={'tr'}
                    key={`sticky_table_footer_group_${footerGroup.id}`}
                  >
                    {footerGroup.headers
                      .slice(0, footerGroup.headers.length - 1)
                      .map((column, colIdx) => {
                        return (
                          <div
                            {...column.getHeaderProps()}
                            className={classNames('td', column.id)}
                            key={`sticky_table_footer_row_${footerGroup.id}_${column.id}_${colIdx}`}
                          >
                            {column.id === 'articleName' ||
                            column.id === 'code' ? (
                              <ArticleHeader
                                key={`sticky_table_footer_header_article_code_cell_${column.id}_${colIdx}`}
                                title={''}
                              >
                                <ArticleHeaderTitle
                                  key={`sticky_table_footer_header_article_code_empty_cell_${column.id}_${colIdx}`}
                                  title={column.Header?.toString() || ''}
                                >
                                  {' '}
                                </ArticleHeaderTitle>
                              </ArticleHeader>
                            ) : (
                              <ArticleHeader
                                key={`sticky_table_footer_normal_cell_${column.id}_${colIdx}`}
                                style={{
                                  alignItems: 'center',
                                }}
                                title={column.Header?.toString() || ''}
                              >
                                <ArticleHeaderTitle
                                  key={`sticky_table_footer_header_cell_${column.id}_${colIdx}`}
                                  title={column.Header?.toString() || ''}
                                >
                                  {column.render('Header')}
                                </ArticleHeaderTitle>
                              </ArticleHeader>
                            )}
                          </div>
                        );
                      })}
                  </div>
                ))}

                {footerGroups.map((footerGroup) => (
                  <div
                    {...footerGroup.getHeaderGroupProps()}
                    className="tr footerrow"
                    key={`sticky_table_footer_values_${footerGroup.id}`}
                  >
                    {footerGroup.headers
                      .slice(0, footerGroup.headers.length - 1)
                      .map((column, colIdx) => {
                        let hasCodeColumn = false;
                        if (footerGroup.headers.find((el) => el.id === 'code'))
                          hasCodeColumn = true;

                        const cellValue = _.get(footerRows, column.id);

                        if (column.id === 'code')
                          return (
                            <div {...column.getHeaderProps()} className="td">
                              {' '}
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  height: '100%',
                                }}
                              ></div>{' '}
                            </div>
                          );

                        if (column.id === 'articleName')
                          return (
                            <div {...column.getHeaderProps()} className="td">
                              <TotalTextWrapper>Total</TotalTextWrapper>
                            </div>
                          );
                        return (
                          <div {...column.getHeaderProps()} className="td">
                            {column.id === 'total' ? (
                              <StickyTableTotalCell
                                price={totalValue}
                                qty={totalNumOfItems}
                                availableNumber={
                                  getNumberOfAvailableItemsForReserved
                                }
                              />
                            ) : (
                              <FooterCell
                                key={`footer_value_cell_${cellValue.numberOfItems}_${column.id}`}
                                value={cellValue.value}
                                numberOfItems={cellValue.numberOfItems}
                                pair={
                                  hasCodeColumn
                                    ? colIdx % 2 !== 0
                                    : colIdx % 2 === 0
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                ))}
              </div>
            </div>
          </StyledTable>
        </Styles>
        <div className="totalColumn">
          <StyledHeader className="header">
            {headerGroups.map((headerGroup) => {
              const column =
                headerGroup.headers[headerGroup.headers.length - 1];
              return (
                <div>
                  <div
                    onClick={() => handleChangeSort(column.id)}
                    key={`sticky_table_header_cell_${column.id}`}
                  >
                    <ArticleHeader
                      key={`sticky_table_header_article_${column.id}`}
                      style={{
                        alignItems: 'center',
                      }}
                      title={column.Header?.toString() || ''}
                    >
                      <ArticleHeaderTitle
                        key={`sticky_table_header_article_title_${column.id}`}
                        title={column.Header?.toString() || ''}
                      >
                        {column.render('Header')}
                      </ArticleHeaderTitle>
                    </ArticleHeader>
                  </div>
                </div>
              );
            })}
          </StyledHeader>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row, rowIdx) => {
              // prepareRow(row);
              if (
                selectedRow === rowIdx &&
                row.cells[0].value &&
                row.cells[0].value['id']
              )
                props.onChangeSelectedRow(row.cells[0].value['id']);

              const rv: number[] = [];

              Object.keys(row.values).forEach((value) => {
                if (
                  value !== 'articleName' &&
                  value !== 'code' &&
                  value !== 'total'
                ) {
                  if (row.values[value]) rv.push(row.values[value]['value']);
                }
              });

              const cell = row.cells.pop();
              const cellIdx = 123456;

              if (!cell) return null;

              return (
                <>
                  <Row
                    onClick={() => {
                      setSelectedRow(rowIdx);
                    }}
                    key={`sticky_table_body_row_${row.id}`}
                  >
                    <Cell
                      pair={false}
                      className={classNames('td', cell.column.id)}
                      key={`sticky_table_body_cell_${cellIdx}_${cell.column.id}`}
                    >
                      <div
                        className={classNames('totalContent', {
                          open: selectedRow === rowIdx,
                        })}
                      >
                        <StickyTableTotalCell
                          key={`sticky_table_total_cell_${cellIdx}_${rowIdx}_${cell.column.id}`}
                          price={row.cells.reduce((acc, curr) => {
                            const r = curr?.row?.original?.[
                              'articleName'
                            ] as unknown as Record<string, string>;
                            if (
                              r['id'] &&
                              curr?.value &&
                              'value' in curr.value
                            ) {
                              const qty =
                                values?.[r['id']]?.[curr.column.id] || 0;

                              return acc + curr.value.value * qty;
                            }

                            return acc;
                          }, 0)}
                          qty={row.cells.reduce((acc, curr) => {
                            const r = curr?.row?.original?.[
                              'articleName'
                            ] as unknown as Record<string, string>;
                            const qty =
                              values?.[r['id']]?.[curr.column.id] || 0;
                            return acc + qty;
                          }, 0)}
                          availableNumber={
                            initialRequestedValues[
                              (row.original?.['articleName'] as any)?.id
                            ]
                          }
                          isHovered={false}
                        />
                      </div>
                    </Cell>
                  </Row>
                  <RowSeparator />
                </>
              );
            })}
            <div className="footer">
              {footerGroups.map((footerGroup) => {
                const column = footerGroup.headers.pop();
                const colIdx = 123456;
                if (!column) return null;

                return (
                  <div
                    className={classNames('td', column.id)}
                    style={{ width: '100%' }}
                    key={`sticky_table_footer_row_${footerGroup.id}_${column.id}_${colIdx}`}
                  >
                    <ArticleHeader
                      key={`sticky_table_footer_normal_cell_${column.id}_${colIdx}`}
                      style={{
                        width: '100%',
                        alignItems: 'center',
                      }}
                      title={column.Header?.toString() || ''}
                    >
                      <ArticleHeaderTitle
                        key={`sticky_table_footer_header_cell_${column.id}_${colIdx}`}
                        title={column.Header?.toString() || ''}
                      >
                        {column.render('Header')}
                      </ArticleHeaderTitle>
                    </ArticleHeader>
                  </div>
                );
              })}

              {footerGroups.map((footerGroup) => {
                const column = footerGroup.headers.pop();
                if (!column) return null;
                return (
                  <div
                    {...column.getHeaderProps()}
                    style={{ width: '100%' }}
                    className="td mainTotal"
                  >
                    <StickyTableTotalCell
                      price={totalValue}
                      qty={totalNumOfItems}
                      availableNumber={getNumberOfAvailableItemsForReserved}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </TablesWrapper>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <CenteredGridItem>
            <Pagination
              count={count}
              page={page + 1}
              onChange={(evt, page) => {
                setPage(page - 1);
              }}
              sx={{
                button: {
                  '&.css-d3ksqy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected':
                    {
                      backgroundColor: `${tyrioUI.colors.lightBlue}`,
                      color: 'white',
                    },
                },
              }}
            />
          </CenteredGridItem>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="rows-per-page">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page"
              id="rows-per-page"
              value={itemsPerPage}
              label="Rows per page"
              onChange={(e) => {
                if (typeof e.target.value === 'number') {
                  setItemsPerPage(e.target.value);
                } else {
                  setItemsPerPage(parseInt(e.target.value, 10));
                }
              }}
              sx={{ height: '40px', width: '100px' }}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={rowsCompleteList.length}>All</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

const StyledTable = styled.div``;
const StyledHeader = styled.div``;

const Cell = styled.div<{ pair: boolean }>`
  padding: 16px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: ${(props) => (!props.pair ? '#F9FAFB' : 'white')};
`;
const Row = styled.div`
  &:hover {
    > * {
      background: #fff8e5 !important;
    }
  }
`;

const ArticleHeader = styled.div<{ title: string }>`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ecf0f4;
  overflow-y: ${(props) => props.title && props.title.length >= 30 && 'scroll'};
  overflow-x: ${(props) => props.title && props.title.length >= 30 && 'hidden'};
  padding: ${(props) =>
    props.title && props.title.length >= 30 ? '5px' : '20px 16px;'};
`;

const ArticleHeaderTitle = styled.div<{ title?: string }>`
  color: #212b36;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(props) => props.title && props.title.length >= 30 && '100%'};
`;

const ArrowsContainer = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  width: 10px;
  height: 22px;
  margin-left: ${tyrioUI.spacing.s}px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ArrowsWrapper = styled.div`
  height: 15px;
  display: flex;
  align-items: center;
`;

const ScrollerBg = styled.div<{
  left: boolean;
  right: boolean;
  count: number;
  hasMainSupplier: boolean;
  shouldShowCode: boolean;
}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 72px;
  top: 0;
  width: 100%; // ${(props) => `${480 + props.count * 130}`}px!important;
  z-index: 1000;
  background: ${(props) => `linear-gradient(to right, rgba(0, 0, 0, ${
    props.left ? 0 : 0.1
  }) 0%, rgba(0, 0, 0, 0)),
    linear-gradient(to left, rgba(0, 0, 0, ${
      props.right ? 0 : 0.1
    }) 0%, rgba(0, 0, 0, 0))`};

  background-size: 20px 100%, 20px 100%;
  background-repeat: no-repeat;
  background-position: ${(props) => {
      let base = 330;
      if (props.hasMainSupplier) {
        base += 130;
      }
      if (props.shouldShowCode) {
        base += 150;
      }
      return base;
    }}px,
    calc(100% - 150px);
  transition: all 0.11s ease;

  pointer-events: none;
  background-attachment: scroll, scroll;
`;

const TablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .totalColumn {
    min-width: 150px;

    .total {
      padding: 0;
      display: flex;
      .totalContent {
        width: 100%;
        height: 74px;
        &.open {
          height: 132px;
        }
        > div {
          padding: 16px 20px;
        }
      }
    }

    .mainTotal {
      height: 69px !important;
      min-height: 69px !important;
    }
  }
`;

const RowSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: rgb(224 224 224);
`;
const Styles = styled.div<{
  count: number;
  showCode: boolean;
  tableOptions: Record<string, any>;
  hasMainSupplier: boolean;
}>`
  position: relative;
  overflow-x: scroll;
  z-index: 1;

  .header,
  .footer {
    background-color: rgb(236, 240, 244);
  }

  .header,
  .body {
    .tr {
      display: flex;
      width: ${(props) => {
        let base = 330 - 150;

        base += props.count * 130;

        // if (props.showCode) base += 150;

        return base;
      }}px!important;

      .th,
      .td {
        width: 130px;
        max-width: 130px;
        min-width: 130px !important;
        flex: 1;

        &.code {
          width: 150px !important;
          max-width: 150px !important;
          min-width: 150px !important;
          position: sticky;
          left: 0px;
          > div {
            width: 100%;
            max-width: 100%;
          }
        }

        &.mainSupplier {
          position: sticky;
          z-index: 100;
          left: ${(props) => {
            let base = 330;

            if (props.showCode) {
              base += 150;
            }
            return base;
          }}px!important;
        }

        &.articleName {
          position: sticky;
          z-index: 100;
          left: ${(props) => (props.showCode ? 150 : 0)}px;

          min-width: 330px !important;
          > div {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .footerrow {
    .td {
      &:first-child {
        position: sticky;
        left: 0;
        min-width: ${(props) => {
          let base = 330;

          if (props.showCode) {
            base += 20;
          }
          return base;
        }}px!important;
      }

      &:nth-child(2) {
        ${(props) =>
          props.hasMainSupplier
            ? `
        position: sticky;
        left: ${330 + (props.showCode ? 150 : 0)}px!important;
      `
            : `position: sticky;
        left: ${200 + (props.showCode ? 150 : 0)}px!important;`}
      }
    }
  }
`;

const CenteredGridItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TotalTextWrapper = styled.div`
  margin: 0;
  padding: ${tyrioUI.spacing.l}px;
  text-align: right;
  background-color: white;
  font-size: ${tyrioUI.fontSize.title}px;
  font-weight: 700;
`;
