import api from '@tyrio/api-factory';
import { DBWorkOrdersApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { queryClient } from 'apps/app/src/app/query-client';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const useCancelWorkOrder = () => {
  const mutation = useMutation(
    ['cancel_work_order'],
    (body: DBWorkOrdersApi['cancelOne']['requestBody']) =>
      api.fetch<DBWorkOrdersApi['cancelOne']>('cancel_work_order', { ...body }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('customer_order');

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE,
          'Successfully cancelled work order.'
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Work orders',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }

        throw error;
      },
    }
  );

  return {
    ...mutation,
  };
};
