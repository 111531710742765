import * as z from "zod"
import { CompleteDBClientStockList, RelatedDBClientStockListModel, CompleteDBUser, RelatedDBUserModel, CompleteDBReservationSession, RelatedDBReservationSessionModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBReservationsItemsModel = z.object({
  id: z.string(),
  clientStockListId: z.string(),
  productName: z.string(),
  ean: z.string(),
  ipc: z.string(),
  sku: z.string(),
  dot: z.string().nullish(),
  productionYear: z.string().nullish(),
  branchName: z.string(),
  branchCity: z.string().nullish(),
  branchId: z.number().int(),
  createdAt: z.date(),
  quantity: z.number().int(),
  documentInfo: jsonSchema,
  cartInfo: jsonSchema,
  reservedById: z.string().nullish(),
})

export interface CompleteDBReservationsItems extends z.infer<typeof DBReservationsItemsModel> {
  clientStockList: CompleteDBClientStockList
  reservedBy?: CompleteDBUser | null
  cartSessions: CompleteDBReservationSession[]
}

/**
 * RelatedDBReservationsItemsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBReservationsItemsModel: z.ZodSchema<CompleteDBReservationsItems> = z.lazy(() => DBReservationsItemsModel.extend({
  clientStockList: RelatedDBClientStockListModel,
  reservedBy: RelatedDBUserModel.nullish(),
  cartSessions: RelatedDBReservationSessionModel.array(),
}))
