import { DBServiceCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBServiceCategoryApi } from '@tyrio/dto';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { SelectAllHeader } from '../components/SelectAllHeader';
import { SwitchCard } from '../components/SwitchCard';
import {
  handleActive,
  handleAllActive,
} from '../helpers/handle-active-methods';
import { CardsWrapper } from './CommonStyle';

interface ServicesBranchTabProps {
  setPayloadShape: Dispatch<SetStateAction<Record<string, boolean>>>;
  selectedServices: Record<string, boolean>;
}

export const ServicesBranchTab = ({
  setPayloadShape,
  selectedServices,
}: ServicesBranchTabProps) => {
  const [services, setServices] = useState<DBServiceCategory[]>([]);
  const [isActive, setIsActive] = useState<Record<string, boolean>>(
    selectedServices ?? {}
  );
  const [areAllActive, setAreAllActive] = useState(false);

  useQuery(
    ['get_service_categories'],
    async () => {
      return await api.fetch<DBServiceCategoryApi['list']>(
        'get_service_categories'
      );
    },
    {
      onSuccess: (data) => {
        setServices(data.data.filter((item) => item.active === true));
      },
    }
  );

  useEffect(() => {
    setPayloadShape(isActive);
  }, [isActive, selectedServices, setPayloadShape]);

  return (
    <div>
      <SelectAllHeader
        text={'Assign available services'}
        onClick={() =>
          handleAllActive(
            isActive,
            setIsActive,
            areAllActive,
            setAreAllActive,
            services
          )
        }
      />
      <CardsWrapper>
        {services.length > 0 &&
          services.map((item) => (
            <SwitchCard
              key={item.id}
              name={item.name}
              description={item.description ?? ''}
              onClick={() =>
                handleActive(item.id, setIsActive, setAreAllActive)
              }
              isActive={isActive[item.id]}
            />
          ))}
      </CardsWrapper>
    </div>
  );
};
