import api from '@tyrio/api-factory';
import { DBAppointmentApi } from '@tyrio/dto';
import { useQuery } from 'react-query';
import { usePosCartCtx } from '../../../../../../../context/PosCartContext';
import { usePosCartData } from '../../../helpers/cart-data';
import { useAppointmentsHelper } from '../appointment-helper';

export const useGetServiceDetails = () => {
  const { initServiceCategories } = useAppointmentsHelper();
  const { appointmentDetails, setAppointmentDetails } = usePosCartCtx();
  const { servicesData } = usePosCartData();

  const serviceIds = servicesData.map((item) => item.key);

  const query = useQuery(
    [serviceIds],
    () =>
      api.fetch<DBAppointmentApi['getServiceDetails']>('getServiceDetails', {
        ids: serviceIds,
      }),
    {
      enabled: !!serviceIds,
      onSuccess: (data) => {
        const { updated } = initServiceCategories(data);

        setAppointmentDetails({
          ...appointmentDetails,
          appointments: updated as unknown as string,
        });
      },
    }
  );

  return {
    ...query,
  };
};
