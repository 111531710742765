/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IFilterData,
  IProduct,
  IWheelsApiData,
  SearchWheelsResponse,
  Wheel,
  WheelDetails,
  WheelModelDetails,
} from '@tyrio/dto';
import { get, isNull } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

export enum VehicleSteps {
  'BRAND' = 'BRAND',
  'MODEL' = 'MODEL',
  'GENERATIONS' = 'GENERATIONS',
  'MODIFICATIONS' = 'MODIFICATIONS',
  'WHEELS_FILTER' = 'WHEELS_FILTER',
}

type GenerationDetails = {
  name: string | null;
  slug: string | null;
  image: string | null;
};

export const defaultFilterValue = {
  rimDiameter: null,
  colorGroup: null,
  width: null,
  cb: null,
  brand: null,
  modelName: null,
  colorFinish: null,
  availability: null,
};

export interface IVehicleFilter {
  rimDiameter: string[] | null;
  colorGroup: string[] | null;
  width: string[] | null;
  cb: string[] | null;
  brand: string[] | null;
  modelName: string[] | null;
  colorFinish: string[] | null;
  availability: string[] | null;
}

export interface IApiData {
  cb: string;
  pcd: string;
  wheels: Wheel[];
  et: number[];
  rimDiameter: number[];
}

type Brand = Record<string, Record<string, string[]>>;

export type WheelsFilterType = Record<string, (string | number)[] | Brand>;

interface IDetailsFilter {
  rimDiameter: string[];
}

export interface VehicleContextShape {
  // VEHICLE SEARCH
  vehicleBrand: string | null;
  setVehicleBrand: (val: string | null) => void;
  clearVehicleContext: () => void;
  activeStep: string | null;
  setActiveStep: (val: string | null) => void;
  selectedBrand: string | null;
  setSelectedBrand: (val: string | null) => void;
  selectedModel: string | null;
  setSelectedModel: (val: string | null) => void;
  selectedGeneration: string | null;
  setSelectedGeneration: (val: string | null) => void;
  selectedModification: string | null;
  setSelectedModification: (val: string | null) => void;
  valueChanges: Record<string, boolean | null>;
  setValueChanges: (val: Record<string, boolean | null>) => void;
  updateValueChanges: (val: VehicleSteps, b: boolean) => void;
  wheelsApiData: IWheelsApiData[] | null;
  setWheelsApiData: (val: IWheelsApiData[] | null) => void;
  generationDetails: GenerationDetails;
  setGenerationDetails: (val: GenerationDetails) => void;

  // WHEELS SEARCH
  filter: IVehicleFilter;
  setFilter: (val: IVehicleFilter) => void;
  selectedWheel: SearchWheelsResponse | null;
  setSelectedWheel: (val: SearchWheelsResponse | null) => void;
  backendFilterData: WheelsFilterType | null;
  setBackendFilterData: (val: WheelsFilterType | null) => void;
  selectedProductModel: WheelModelDetails | null;
  setSelectedProductModel: (val: WheelModelDetails | null) => void;
  // extractApiData: (extractRim: boolean, extractEt: boolean) => IApiData | null;
  selectedProduct: IProduct | null;
  setSelectedProduct: (val: IProduct | null) => void;
  detailsFilter: IDetailsFilter;
  setDetailsFilter: (val: IDetailsFilter) => void;
  selectedEt: number | null;
  setSelectedEt: (val: number | null) => void;
  clearOnBackButton: () => void;
  posWheelsFilterData: IFilterData | null;
  setPosWheelsFilterData: (val: IFilterData | null) => void;
  apiData: IApiData | null;
  page: number;
  setPage: (val: number) => void;
  wheelsData: SearchWheelsResponse[] | null;
  setWheelsData: (val: SearchWheelsResponse[] | null) => void;
}

export const VehicleContext = React.createContext<VehicleContextShape>({
  // VEHICLE SEARCH
  vehicleBrand: null,
  setVehicleBrand: () => null,
  clearVehicleContext: () => null,
  activeStep: null,
  setActiveStep: () => null,
  selectedBrand: null,
  setSelectedBrand: () => null,
  selectedModel: null,
  setSelectedModel: () => null,
  selectedGeneration: null,
  setSelectedGeneration: () => null,
  selectedModification: null,
  setSelectedModification: () => null,
  valueChanges: {},
  setValueChanges: () => null,
  updateValueChanges: () => null,
  /*** final result from wheel search api ***/
  wheelsApiData: null,
  setWheelsApiData: () => null,
  generationDetails: { name: null, slug: null, image: null },
  setGenerationDetails: () => null,

  // WHEELS SEARCH
  filter: { ...defaultFilterValue },
  setFilter: () => null,
  selectedWheel: null,
  setSelectedWheel: () => null,
  /*** right side filter data ***/
  backendFilterData: null,
  setBackendFilterData: () => null,
  selectedProductModel: null,
  setSelectedProductModel: () => null,
  // extractApiData: () => null,
  selectedProduct: null,
  setSelectedProduct: () => null,
  detailsFilter: { rimDiameter: [] },
  setDetailsFilter: () => null,
  selectedEt: null,
  setSelectedEt: () => null,
  clearOnBackButton: () => null,
  posWheelsFilterData: null,
  setPosWheelsFilterData: () => null,
  apiData: null,
  page: 1,
  setPage: () => null,
  wheelsData: null,
  setWheelsData: () => null,
});

export const VehicleContextProvide = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [vehicleBrand, setVehicleBrand] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState<string | null>(
    VehicleSteps.BRAND
  );
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const [selectedGeneration, setSelectedGeneration] = useState<string | null>(
    null
  );
  const [selectedModification, setSelectedModification] = useState<
    string | null
  >(null);

  const [valueChanges, setValueChanges] = useState<
    Record<string, boolean | null>
  >({
    [VehicleSteps.BRAND]: null,
    [VehicleSteps.GENERATIONS]: null,
    [VehicleSteps.MODEL]: null,
    [VehicleSteps.MODIFICATIONS]: null,
    [VehicleSteps.WHEELS_FILTER]: null,
  });

  const [wheelsApiData, setWheelsApiData] = useState<IWheelsApiData[] | null>(
    null
  );
  const [generationDetails, setGenerationDetails] = useState<GenerationDetails>(
    {
      name: null,
      slug: null,
      image: null,
    }
  );

  const [wheelsData, setWheelsData] = useState<SearchWheelsResponse[] | null>(
    null
  );

  const [filter, setFilter] = useState<IVehicleFilter>(defaultFilterValue);

  const [selectedWheel, setSelectedWheel] =
    useState<SearchWheelsResponse | null>(null);

  const [backendFilterData, setBackendFilterData] =
    useState<WheelsFilterType | null>(null);

  const [selectedProductModel, setSelectedProductModel] =
    useState<WheelModelDetails | null>(null);

  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [detailsFilter, setDetailsFilter] = useState<IDetailsFilter>({
    rimDiameter: [],
  });
  const [selectedEt, setSelectedEt] = useState<number | null>(null);

  const [posWheelsFilterData, setPosWheelsFilterData] =
    useState<IFilterData | null>(null);

  const [page, setPage] = useState<number>(1);

  // RESET CONTEXT
  const clearVehicleContext = () => {
    setVehicleBrand(null);
    setActiveStep(VehicleSteps.BRAND);
    setSelectedBrand(null);
    setSelectedModel(null);
    setSelectedGeneration(null);
    setWheelsApiData(null);
    setGenerationDetails({ name: null, slug: null, image: null });
    setFilter(defaultFilterValue);
    setSelectedWheel(null);
    setBackendFilterData(null);
    setSelectedProductModel(null);
    setSelectedProduct(null);
    setDetailsFilter({ rimDiameter: [] });
    setSelectedEt(null);
    setPosWheelsFilterData(null);
    setPage(1);
    setWheelsData(null);
  };

  const clearOnBackButton = () => {
    setFilter(defaultFilterValue);
    setBackendFilterData(null);
    setWheelsApiData(null);
    setPosWheelsFilterData(null);
    setPage(1);
    setWheelsData(null);
  };

  const updateValueChanges = (step: VehicleSteps, val: boolean) => {
    setValueChanges((prevState) => ({ ...prevState, [step]: val }));
  };

  const processData = (data: Wheel, prop: string, array: number[]) => {
    const key = prop as unknown as keyof WheelDetails;

    const front = data.front[key];
    const rear = data.rear[key];

    if (!isNull(front)) array.push(Number(front));
    if (!isNull(rear)) array.push(Number(rear));
  };

  const extractApiData = useCallback(() => {
    if (wheelsApiData) {
      const value = wheelsApiData ? wheelsApiData[0] : null;
      if (!value) return null;

      const pcd = get(value, 'technical.bolt_pattern', '');
      const cb = get(value, 'technical.centre_bore', '');

      const et: number[] = [];
      const rimDiameter: number[] = [];

      const wheels = value.wheels;
      wheels?.forEach((m) => {
        processData(m, 'rim_diameter', rimDiameter);
        processData(m, 'rim_offset', et);
      });

      return { pcd, cb, wheels: value?.wheels ?? [], et, rimDiameter };
    }
    return null;
  }, [wheelsApiData]);

  const apiData: IApiData | null = useMemo(() => {
    return extractApiData();
  }, [extractApiData]);

  return (
    <VehicleContext.Provider
      value={{
        clearVehicleContext,
        vehicleBrand,
        setVehicleBrand,
        activeStep,
        setActiveStep,
        selectedBrand,
        setSelectedBrand,
        selectedModel,
        setSelectedModel,
        selectedGeneration,
        setSelectedGeneration,
        selectedModification,
        setSelectedModification,
        valueChanges,
        setValueChanges,
        updateValueChanges,
        wheelsApiData,
        setWheelsApiData,
        generationDetails,
        setGenerationDetails,
        filter,
        setFilter,
        selectedWheel,
        setSelectedWheel,
        backendFilterData,
        setBackendFilterData,
        selectedProductModel,
        setSelectedProductModel,
        // extractApiData,
        selectedProduct,
        setSelectedProduct,
        detailsFilter,
        setDetailsFilter,
        selectedEt,
        setSelectedEt,
        clearOnBackButton,
        posWheelsFilterData,
        setPosWheelsFilterData,
        apiData,
        page,
        setPage,
        wheelsData,
        setWheelsData,
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
};

export const useVehicleCtx = () => React.useContext(VehicleContext);
