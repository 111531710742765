import { useMemo } from 'react';
import { IApiData } from '../../../../context/VehicleContext';
import styled from '@emotion/styled/macro';
import { Dot } from '../SearchVehiclePOS/Generation';
import { Typography } from '@mui/material';
import { tyrioIcons, tyrioUI } from '@tyrio/ui-library';
import AcUnitIcon from '@mui/icons-material/AcUnit';

export const RecommendedWheelSizes = ({
  apiData,
  selectedRimSize,
}: {
  apiData: IApiData | null;
  selectedRimSize: string[];
}) => {
  const recommendedSizesData = useMemo(() => {
    if (!apiData?.wheels || !apiData.wheels.length || !selectedRimSize.length) {
      return null;
    }

    const wheels: {
      is_recommended_for_winter: boolean;
      details: {
        rim_diameter: number;
        rim_offset: number | null;
        tire: string;
        rim_width: number | null;
      };
    }[] = [];

    apiData.wheels.forEach((wheel) => {
      const {
        front: { rim_diameter, rim_offset, tire, rim_width },
        rear: {
          rim_diameter: rear_rim_diameter,
          rim_offset: rear_rim_offset,
          tire: rear_tire,
          rim_width: rear_rim_width,
        },
      } = wheel;

      if (rim_diameter && selectedRimSize.includes(rim_diameter.toString())) {
        wheels.push({
          is_recommended_for_winter: wheel.is_recommended_for_winter,
          details: { rim_diameter, rim_offset, tire, rim_width },
        });
      }

      if (
        rear_rim_diameter &&
        selectedRimSize.includes(rear_rim_diameter.toString())
      ) {
        wheels.push({
          is_recommended_for_winter: wheel.is_recommended_for_winter,
          details: {
            rim_diameter: rear_rim_diameter,
            rim_offset: rear_rim_offset,
            tire: rear_tire,
            rim_width: rear_rim_width,
          },
        });
      }
    });

    if (!wheels.length) return <Warning />;
    else
      return wheels?.map((m: any, idx: number) => {
        const {
          is_recommended_for_winter,
          details: { rim_diameter, rim_offset, tire, rim_width },
        } = m;

        return (
          <WheelWrapper key={idx}>
            {is_recommended_for_winter && <AcUnitIcon color="info" />}
            {tire}
            <Dot /> {rim_width} x {rim_diameter} <Dot />
            ET {Number(rim_offset) - 3} - {Number(rim_offset) + 3}
          </WheelWrapper>
        );
      });
  }, [apiData?.wheels, selectedRimSize]);

  return <RecommendedSize>{recommendedSizesData}</RecommendedSize>;
};

export const Warning = () => {
  return (
    <Div>
      <Img src={tyrioIcons.warning} alt="" />
      <Typography
        color={tyrioUI.colors.orange.O100}
        fontWeight={400}
        fontSize={14}
      >
        Selected rim diameter is not recommended for your car
      </Typography>
    </Div>
  );
};

const RecommendedSize = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px 0px;
  justify-content: space-between;
`;

const WheelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 48%;
  // justify-content: space-between;
  font-size: 14px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Img = styled.img`
  object-fit: contain;
  width: 20px;
  height: 20px;
`;
