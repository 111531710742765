import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { APOLLOVREDESTEIN_CATALOG_RULESET } from './apollovredestein/catalog';
import { APOLLOVREDESTEIN_STOCKLIST_RULESET } from './apollovredestein/stocklist';
import { APOLLOVREDESTEIN_PRICELIST_RULESET } from './apollovredestein/pricelist';

export const APOLLOVREDESTEIN_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: APOLLOVREDESTEIN_CATALOG_RULESET,
  pricelistRuleset: APOLLOVREDESTEIN_PRICELIST_RULESET,
  stockRuleset: APOLLOVREDESTEIN_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    WEIGHT: {
      ',': '.',
    },
    WIDTH_MM: {
      ',': '.',
    },
    ASPECT_RATIO: { ',': '.' },
    RIM_INCH: { ',': '.' },
    purchasePrice: { ',': '.' },
    suggestedRetailPrice: { ',': '.' },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'APOLLOVREDESTEIN EDI B4',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return handlePreprocessConversions(
      json.filter((item) => ['C0', 'LS', 'L0'].includes(item['VEHICLE_TYPE'])),
      this.preprocessKeys
    );
  },
};
