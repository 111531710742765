import { DBTransferOrderType } from '@prisma/client';
import { DBStockList } from '@tyrio/dto';
import { useContext } from 'react';
import { StockListContext } from '../../../../context/StockListContext';
import { Wrapper } from './InboundOrders.style';
import PurchaseOrderItem from './PurchaseOrderItem';
import { RenderTitle } from './Title';
import TransferOrderItem from './TransferOrderItem';
import { useFetchInboundOutboundData } from './fetch-data';
import { sum } from 'lodash';

interface InboundOutboundOrdersProps {
  type: DBTransferOrderType;
}

export const InboundOutboundOrders = ({ type }: InboundOutboundOrdersProps) => {
  const { selectedTableRow, filterValues } = useContext(StockListContext);

  const { data: responseData } = useFetchInboundOutboundData(
    (selectedTableRow as DBStockList)?.ean,
    (filterValues?.['warehouseId'] as string) ?? ''
  );

  const inboundCount = sum([
    responseData?.inbound?.transfer.length,
    responseData?.inbound?.purchase.length,
  ]);

  const outboundCount = responseData?.outbound?.transfer.length ?? 0;

  return (
    <div>
      {type === 'INBOUND' && inboundCount > 0 && responseData !== undefined && (
        <Wrapper>
          <RenderTitle type="INBOUND" />
          {responseData.inbound.transfer.map((item, index) => (
            <TransferOrderItem item={item} key={index} />
          ))}
          {responseData.inbound.purchase.map((item) => (
            <PurchaseOrderItem item={item} />
          ))}
        </Wrapper>
      )}

      {type === 'OUTBOUND' && outboundCount > 0 && responseData !== undefined && (
        <Wrapper>
          <RenderTitle type="OUTBOUND" />
          {responseData.outbound.transfer.map((item, index) => (
            <TransferOrderItem item={item} key={index} />
          ))}
        </Wrapper>
      )}
    </div>
  );
};
