import styled from '@emotion/styled';
import { Badge } from '@mui/material';

interface LocationNameProps {
  zoneColor: string;
  qrCode: string;
}

export const LocationName = ({ zoneColor, qrCode }: LocationNameProps) => {
  return (
    <Wrapper>
      <LocationDetails>
        <Badge
          sx={{
            '.MuiBadge-badge': {
              background: zoneColor,
            },
          }}
          badgeContent={''}
        />
        <QRCode>{qrCode}</QRCode>
      </LocationDetails>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const LocationDetails = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-left: 10px;
`;

const QRCode = styled.div`
  font-family: Barlow;
  font-weight: 500;
  font-size: 16px;
`;
