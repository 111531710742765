import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const WHEELTRADE_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'category_id',
    template: '72',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.map',
    sourceKey: 'c.model',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.ean',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["part_number"]',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.size',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.pcd',
    targetKey: 'pcd',
    delimiter: ', ',
    destination: 'product',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.et',
    targetKey: 'et',
    delimiter: ',',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["center_bore"]',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.colour',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  // {
  //   type: 'rule.global.operate',
  //   targetKey: 'color',
  //   destination: 'product',
  //   operationName: 'generateColorCombo',
  // },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'noKit',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.photo|c.photo1|c.photo2|c.photo3|c.photo4|c.photo5',
    targetKey: 'originalPhotos',
    delimiter: '|',
    destination: 'model',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.youtube_link',
    targetKey: 'videoUrl',
    destination: 'model',
  },
  ...GLOBAL_RULESET,
];
