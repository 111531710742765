import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useFilter } from '../../../../hooks/useFilter';
interface StepperCardProps {
  value: string;
  setSelectedValue: (item: string) => void;
  selectedValue: string | null;
  setCurrentStepIndex: () => void;
  label?: string;
  wheelSubmit?: () => void;
  maxWidth?: string;
  disabled?: boolean;
  customHeight?: string;
  //Label prop will be used only when user choosing category
}

export const StepperCard = ({
  value,
  setSelectedValue,
  selectedValue,
  setCurrentStepIndex,
  label,
  wheelSubmit,
  maxWidth,
  disabled,
  customHeight,
}: StepperCardProps) => {
  const fullValue = label ? value + '|' + label : value;
  const { setFilterValue } = useFilter();

  return (
    <CardContainer
      id="card_container"
      disabled={disabled ?? false}
      customHeight={customHeight}
      onClick={() => {
        setSelectedValue(fullValue);
        setCurrentStepIndex();
        setFilterValue({
          searchKeyword: undefined,
          searchType: undefined,
        });
        wheelSubmit && wheelSubmit();
      }}
      selected={fullValue === selectedValue}
      maxWidth={maxWidth}
    >
      {label ?? value}
    </CardContainer>
  );
};

const CardContainer = styled(Button)<{
  selected?: boolean;
  maxWidth?: string;
  customHeight?: string;
}>`
  display: flex;
  margin: 5px;
  height: ${({ customHeight }) => customHeight ?? '80px'};
  padding: 6px 19px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '130px')};
  border-radius: 8px;
  border: 1px solid #dfe3e8;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: ${(props) => props.selected && '#1976d2'};
  color: ${(props) => props.selected && 'white'};
  &:hover {
    border: 2px solid #1976d2;
    background: none;
    color: ${(props) => props.selected && 'black'};
  }
  cursor: pointer;
`;
