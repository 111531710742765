import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';

interface ProductInfoProps {
  productName: string;
  quantity: number;
  price: number;
  ean: string;
  ipc: string;
  sku: string;
  inquiryData?: {
    ean: string;
    qty: number;
    deliveryDate?: string;
  };
}
const ProductInfo = ({
  productName,
  quantity,
  price,
  ean,
  ipc,
  sku,
  inquiryData,
}: ProductInfoProps) => {
  const parsedDeliveryDate =
    !!inquiryData?.deliveryDate &&
    typeof inquiryData?.deliveryDate === 'string' &&
    inquiryData?.deliveryDate !== '9999-12-31'
      ? inquiryData?.deliveryDate
      : null;

  const qty = inquiryData?.qty ?? 0;

  return (
    <Container>
      {productName}
      <DetailsContainer>
        <DetailsWrapper>
          <Detail>EAN: {ean}</Detail>
          <Detail> IPC: {ipc} </Detail>
          <Detail> SKU: {sku} </Detail>
          {parsedDeliveryDate && (
            <Detail> EST. DELIVERY: {parsedDeliveryDate} </Detail>
          )}
        </DetailsWrapper>
        <Quantities>
          <div>
            {price.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              currencyDisplay: 'symbol',
            })}
          </div>
          {!!inquiryData && quantity > qty && (
            <Tooltip
              title={`Supplier does not have enough items in stock!`}
              placement={'top'}
            >
              <b className="not_enough">{quantity}</b>
            </Tooltip>
          )}
          {!!inquiryData && quantity <= qty && (
            <Tooltip
              title={`Supplier has enough items in stock!`}
              placement={'top'}
            >
              <b className="has_enough">{quantity}</b>
            </Tooltip>
          )}
          {!inquiryData && <b>{quantity}</b>}
          <div>
            {(quantity * price).toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              currencyDisplay: 'symbol',
            })}
          </div>
        </Quantities>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 76px;
  border-bottom: 1px solid #dfe3e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Quantities = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;

  b {
    &.not_enough {
      color: ${tyrioUI.colors.secondary};
    }
    &.has_enough {
      color: ${tyrioUI.colors.green};
    }
  }
`;

const DetailsWrapper = styled.div`
  font-weight: 400;
  display: flex;
  width: 50%;
  justify-content: space-between;
  font-size: 12px;
  color: #919eab;
`;

const Detail = styled.div``;

export default ProductInfo;
