import api from '@tyrio/api-factory';
import { DBOrderDocumentApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { useMutation } from 'react-query';

export const useDeleteOrderDocument = (refetch: () => void) => {
  const { mutate } = useMutation(
    (id: string) =>
      api.fetch<DBOrderDocumentApi['getOne']>('delete_order_document', {
        id: id ?? '',
      }),
    {
      mutationKey: 'delete_order_document',
      onSuccess: () => {
        refetch();
        ToastHelper.showToast(
          'Related document',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Related document',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  return { deleteOrderDocument: mutate };
};
