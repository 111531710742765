import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { RADCENTAR_CATALOG_RULESET } from './radcentar/catalog';
import { RADCENTAR_PRICELIST_RULESET } from './radcentar/pricelist';
import { RADCENTAR_STOCKLIST_RULESET } from './radcentar/stocklist';

export const RADCENTAR_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: RADCENTAR_CATALOG_RULESET,
  pricelistRuleset: RADCENTAR_PRICELIST_RULESET,
  stockRuleset: RADCENTAR_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      origin: 'DE',
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'RADCENTAR',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    NB: {
      ',': '.',
    },
    offset: {
      ',': '.',
    },
    'pcdTotal': {
      ',': '.',
    },
    'numberOfHoles': {
      ',': '.',
    },
    width: {
      ',': '.',
    },
    'cartonKg': {
      ',': '.',
    },
    'wheelKg': {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter((item) => !!item['eanCod']);

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter((item) => !!item['eanCod'] && item['quantity'] > 0);

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter((item) => !!item['eanCod']);

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
