import * as z from "zod"
import { DBImportStatus } from ".prisma/client"
import { CompleteDBProduct, RelatedDBProductModel, CompleteDBProductModel, RelatedDBProductModelModel, CompleteDBSupplierStock, RelatedDBSupplierStockModel } from "./index"

export const DBImportModel = z.object({
  id: z.string(),
  status: z.nativeEnum(DBImportStatus),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteDBImport extends z.infer<typeof DBImportModel> {
  products: CompleteDBProduct[]
  models: CompleteDBProductModel[]
  DBSupplierStock: CompleteDBSupplierStock[]
}

/**
 * RelatedDBImportModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBImportModel: z.ZodSchema<CompleteDBImport> = z.lazy(() => DBImportModel.extend({
  products: RelatedDBProductModel.array(),
  models: RelatedDBProductModelModel.array(),
  DBSupplierStock: RelatedDBSupplierStockModel.array(),
}))
