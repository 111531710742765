import styled from '@emotion/styled';

enum Type {
  ArticleTitle,
  ArticleCode,
}
interface ArticleCellData {
  title: string;
  subtitle: string;
  type?: Type;
}

export default function ArticleCell(props: ArticleCellData) {
  const { title, subtitle } = props;
  let type = Type.ArticleTitle;
  if (props.type) {
    type = props.type;
  }
  return type === 0 ? (
    <div>
      <TitleWrapper>{title}</TitleWrapper>
      <SubtitleWrapper>{subtitle}</SubtitleWrapper>
    </div>
  ) : (
    <div>
      <SubtitleWrapper>{title}</SubtitleWrapper>
      <TitleWrapper>{subtitle}</TitleWrapper>
    </div>
  );
}

const TitleWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  color: #919eab;
`;

const SubtitleWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  color: #212b36;
  overflow-wrap: break-word;
`;
