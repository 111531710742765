import { DBProductModel } from '@prisma/client';
import { SPECIAL_MARKING, SpecialMarkingType } from '@tyrio/shared-vars';

// export const getSidewallValuesFromModel = (name: string) => {};
export const getSpecialMarkingFromName = (
  modelName?: string | null
): Partial<Record<SpecialMarkingType, string[]>> => {
  if (!modelName) return {};

  const markingMap: Record<string, string[]> = {};
  Object.values(SPECIAL_MARKING).forEach((marking) => {
    if (modelName.split(' ').includes(marking.key)) {
      marking.type.forEach((type: string) => {
        if (!markingMap[type]) markingMap[type] = [];
        markingMap[type].push(marking.key);
      });
    }
  });

  return markingMap;
};

export const getProductMetadataString = (model: DBProductModel) => {
  const { season, color, wheelPosition } = model;

  let keys: string[] = [];
  if (season) {
    keys.push(season);
  }

  const specialMarking = getSpecialMarkingFromName(model.modelName);

  if (specialMarking.sidewall) {
    keys = [...keys, ...specialMarking.sidewall];
  }

  if (color) {
    keys.push(color);
  }

  if (wheelPosition) {
    keys.push(wheelPosition);
  }
  return keys.join(' - ');
};

export const getAllSpecialMarkings = () => {
  const markingData: { label: string; value: string }[] = [];
  Object.values(SPECIAL_MARKING).forEach((marking) => {
    markingData.push({ label: marking.key, value: marking.key });
  });
  return markingData;
};
