import styled from '@emotion/styled';
import { comingSoon } from '@tyrio/ui-library';

const ComingSoon = () => {
  return (
    <Container>
      <img
        src={comingSoon}
        alt="coming-soon"
        style={{ width: '100%', maxWidth: '768px', padding: '32px' }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ComingSoon;
