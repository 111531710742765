import { parseEnumToInputOption } from '../../../../../helpers/enum-parser';
import { DropdownValues, FormShape } from './service.form';
import { zodServiceCategoryObject } from './validation';
import { DBUnitOfMeasurment } from '@prisma/client';

export const ServiceCategoryFormHelper = (vatRate: DropdownValues[]) => {
  const unitOfMeasurement = parseEnumToInputOption(DBUnitOfMeasurment);

  const form: FormShape[] = [
    {
      isGroup: false,
      inputs: [
        {
          id: 'name',
          width: '100%',
          type: 'input.string',
          label: 'Name',
        },
      ],
    },
    {
      isGroup: false,
      inputs: [
        {
          id: 'description',
          width: '100%',
          type: 'input.string',
          label: 'Description',
        },
      ],
    },
    {
      isGroup: true,
      title: 'Default settings',
      inputs: [
        {
          id: 'vatRate',
          width: '30%',
          type: 'input.select',
          options: vatRate,
          label: 'VAT rate *',
        },
        {
          id: 'unitOfMeasurment',
          width: '20%',
          type: 'input.select',
          options: unitOfMeasurement,
          label: 'Unit of measurement *',
        },
        {
          id: 'generalLedgeAcc',
          width: '45%',
          type: 'input.string',
          label: 'General ledger account',
        },
      ],
    },
  ];

  return {
    form,
    zodSchema: zodServiceCategoryObject,
  };
};
