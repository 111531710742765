import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { colors } from '../index';
import { TyrioTab, TyrioTabProps } from './TyrioTab';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export interface TabChangeDetails {
  previousTabIdx: number;
  currentTabIdx: number;
}

interface TyrioTabsProps {
  children: React.ReactElement<TyrioTabProps, typeof TyrioTab>[];
  onTabChange?: (details: TabChangeDetails) => boolean | void;
  canTabChange?: (details: TabChangeDetails) => boolean;
  canChangeTab?: boolean;
}

interface TyrioTabsRefProps {
  selectTab: (x: number) => void;
  selectedIdx: number;
}

export const TyrioTabs: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<TyrioTabsProps> &
    React.RefAttributes<TyrioTabsRefProps | undefined>
> = React.forwardRef(
  ({ children, onTabChange, canTabChange, canChangeTab }, ref) => {
    const location = useLocation();
    /** Get initially selectedIdx tab */
    const initialSelectedTabIndex = useMemo(
      () => children.findIndex((child) => child?.props?.initial),
      [children]
    );
    const ableToChangeTab = useMemo(
      () => (canChangeTab !== undefined ? canChangeTab : true),
      [canChangeTab]
    );

    const [selectedIdx, setSelectedIdx] = useState(
      initialSelectedTabIndex < 0 ? 0 : initialSelectedTabIndex
    );

    const selectTab = useCallback(
      (tabIdx: number) => {
        if (canTabChange) {
          const canChange = canTabChange({
            previousTabIdx: selectedIdx,
            currentTabIdx: tabIdx,
          });

          if (!canChange) {
            return;
          }
        }
        if (ableToChangeTab) {
          const shouldBlockSwitch = onTabChange
            ? onTabChange({
                previousTabIdx: selectedIdx,
                currentTabIdx: tabIdx,
              })
            : false;
          if (shouldBlockSwitch) return;

          setSelectedIdx(tabIdx);
        }
      },
      [ableToChangeTab, canTabChange, onTabChange, selectedIdx]
    );

    const internalSelectTab = useCallback(
      (tabIndex: number) => {
        if (!ableToChangeTab) return;
        selectTab(tabIndex);
      },
      [ableToChangeTab, selectTab]
    );

    useImperativeHandle(ref, () => ({
      selectTab,
      selectedIdx,
    }));

    useEffect(() => {
      if (location.pathname.toString().includes('/new')) setSelectedIdx(0);
    }, [initialSelectedTabIndex, location.pathname]);

    /** If the children change, ensure that all children are the TyrioTab component */
    //
    // useEffect(() => {
    //   children.forEach((child) => {
    //     if (child.type.name !== 'TyrioTab') {
    //       throw new Error(
    //         `TyrioTabs component can only receive TyrioTab components as children. You have passed ${child.type.name}.`
    //       );
    //     }
    //   });
    // }, [children]);

    const titles = React.useMemo(
      () =>
        children.map((child) => ({
          title: child.props.title as string,
          disabled: child.props.disabled as boolean,
          isVisible: _.isUndefined(child.props.isVisible)
            ? true
            : (child.props.isVisible as boolean),
        })),
      [children]
    );

    const errors = React.useMemo(
      () =>
        children.map((child) => {
          return {
            key: child.props.title,
            error: child.props.errored,
          };
        }),
      [children]
    );

    return (
      <>
        {titles[0].title !== '' && (
          <TabWrapper>
            {titles
              .filter((item) => item.isVisible)
              .map((item, idx) => {
                return (
                  <TabElement
                    disabled={item.disabled}
                    key={item.title}
                    type="button"
                    data-cy={`tab_${item.title}`}
                    selected={selectedIdx === idx}
                    onClick={() => internalSelectTab(idx)}
                    errored={errors.find((k) => k.key === item.title)?.error}
                  >
                    {item.title}
                    {selectedIdx === idx && (
                      <TabBorder
                        layoutId="underline"
                        errored={
                          errors.find((k) => k.key === item.title)?.error
                        }
                      />
                    )}
                  </TabElement>
                );
              })}
          </TabWrapper>
        )}

        {React.Children.map(children, (child, idx) => (
          <motion.div
            key={child.props.title}
            style={{
              opacity: idx === selectedIdx ? 1 : 0,
              height: idx === selectedIdx ? 'auto' : 0,
              pointerEvents: idx === selectedIdx ? 'all' : 'none',
              display: idx === selectedIdx ? 'flex' : 'none',
            }}
          >
            {React.cloneElement(child, { ...child.props })}
          </motion.div>
        ))}
      </>
    );
  }
);

export type TyrioTabsRefType = React.ElementRef<typeof TyrioTabs>;

/** Styles */
const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${colors.black.B40};
  padding-bottom: 2px;
  overflow-x: auto;
`;

const TabElement = styled(motion.button)<{
  selected?: boolean;
  errored?: boolean;
  customId?: string;
}>`
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  position: relative;
  background-color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  ${(props) =>
    props.errored
      ? `color: ${colors.secondary};`
      : props.selected
      ? `color: ${colors.lightBlue};`
      : `color: ${colors.primary};`}
`;

const TabBorder = styled(motion.div)<{ errored?: boolean }>`
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${(props) =>
    props.errored ? 'red' : `${colors.lightBlue}`};
`;

export { TyrioTab };
