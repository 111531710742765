import * as z from "zod"
import { DBCraneTypeEnum } from ".prisma/client"
import { CompleteDBWarehouseShelfs, RelatedDBWarehouseShelfsModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBServiceCategory, RelatedDBServiceCategoryModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBFittingBoxSettingsModel = z.object({
  id: z.string(),
  shelfId: z.string(),
  branchId: z.number().int(),
  craneType: z.nativeEnum(DBCraneTypeEnum),
  active: z.boolean(),
  blackList: z.boolean(),
  workingTime: jsonSchema,
  vipBox: z.boolean(),
  stepper: z.number().int(),
  minLength: z.number().int(),
  description: z.string(),
  category: z.string().nullish(),
})

export interface CompleteDBFittingBoxSettings extends z.infer<typeof DBFittingBoxSettingsModel> {
  shelf: CompleteDBWarehouseShelfs
  branch: CompleteDBBranch
  serviceCategories: CompleteDBServiceCategory[]
}

/**
 * RelatedDBFittingBoxSettingsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBFittingBoxSettingsModel: z.ZodSchema<CompleteDBFittingBoxSettings> = z.lazy(() => DBFittingBoxSettingsModel.extend({
  shelf: RelatedDBWarehouseShelfsModel,
  branch: RelatedDBBranchModel,
  serviceCategories: RelatedDBServiceCategoryModel.array(),
}))
