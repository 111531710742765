import styled from '@emotion/styled';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, ListItemButton, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchableVerticalListItem } from './SearchableVerticalList.types';
import { SearchableVerticalListDataProps } from './types';

interface ItemsWithChildrenProps {
  data: SearchableVerticalListDataProps[];
  onItemClick: (item: SearchableVerticalListItem) => void;
  isSearch: boolean;
}

export const ItemsWithChildren = ({
  data,
  isSearch,
  onItemClick,
}: ItemsWithChildrenProps) => {
  const location = useLocation();
  const id = location.pathname.split('/').pop();

  const [opens, setOpens] = useState<Record<string, boolean>>({});
  //Initial selected state
  const [selected, setSelected] = useState<Record<string, boolean>>({
    [id]: true,
  });

  //Special case when the user clicks on the cancel button on the Category page
  useEffect(() => {
    if (id === 'categories') {
      setSelected({});
      setOpens({});
    }
  }, [id]);

  useEffect(() => {
    let obj = {};
    if (isSearch) {
      data.forEach((item) => {
        obj = { ...obj, [item.id]: true };
      });
      setOpens(obj);
    }
  }, [data, isSearch]);

  const handleChildClick = (
    child: { name: string; id: string },
    parentId: string
  ) => {
    setOpens((prevValue) => ({
      ...prevValue,
      [child.id]: !prevValue[child.id],
    }));
    setSelected({
      [parentId]: true,
      [child.id]: true,
    });
    onItemClick(child);
  };

  return (
    <>
      {data.map((item) => (
        <div key={item.id}>
          <ParentListButton
            selected={selected[item.id]}
            key={item.id}
            onClick={() => {
              onItemClick(item);
              setSelected({ [item.id]: true });
            }}
          >
            <ParentItemName primary={item.name} />
            {item.children?.length !== 0 && (
              <ExpandButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpens({ [item.id]: !opens[item.id] });
                }}
              >
                {opens[item.id] ? <ExpandLess /> : <ExpandMore />}
              </ExpandButton>
            )}
          </ParentListButton>
          <Collapse in={opens[item.id]} timeout="auto" unmountOnExit>
            {item.children?.map((child) => (
              <List component="div" disablePadding key={child.id}>
                <ChildListButton
                  sx={{ pl: 5 }}
                  selected={selected[child.id]}
                  onClick={() => handleChildClick(child, item.id)}
                >
                  <ChildItemName primary={child.name} />
                </ChildListButton>
              </List>
            ))}
          </Collapse>
        </div>
      ))}
    </>
  );
};

const ParentListButton = styled(ListItemButton)`
  height: 56px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const ChildListButton = styled(ParentListButton)`
  height: 48px;
`;

const ParentItemName = styled(ListItemText)`
  text-transform: uppercase;
`;

const ChildItemName = styled(ListItemText)`
  text-transform: capitalize;
`;

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  :hover& {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 100%;
  }
`;

export default ItemsWithChildren;
