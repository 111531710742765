import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

export const BRIDGESTONE_CATALOG_RULESET: TRule[] = [
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateBridgestoneSpecialMarkings',
  },
  ...EDI_WHEEL_B4_RULESET,
  {
    type: 'rule.global.operate',
    targetKey: 'category_id',
    destination: 'model',
    operationName: 'getBridgestoneCategory',
  },
  ...GLOBAL_RULESET,
];
