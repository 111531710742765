import styled from '@emotion/styled';
import { Checkbox, Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { IVehicleFilter } from '../../../../../context/VehicleContext';

export const CheckboxCard = ({
  text,
  onChange,
  checkboxId,
  isChecked,
  isDisabled,
  notRecommended,
}: {
  text: string;
  onChange: (
    name: keyof IVehicleFilter,
    // checked: boolean,
    value: string
  ) => void;
  checkboxId: string;
  isChecked: boolean;
  isDisabled: boolean;
  notRecommended: boolean;
}) => {
  const disabled = isDisabled && !isChecked;

  return (
    <SingleCardWrapper selected={isChecked} disabled={disabled}>
      <Checkbox
        checked={isChecked}
        disabled={disabled ?? false}
        color="info"
        onChange={({ target: { name } }) => {
          onChange(name as keyof IVehicleFilter, text);
        }}
        name={checkboxId}
      />
      <Typography
        color={disabled ? 'slategrey' : notRecommended ? '#E75524' : 'black'}
      >
        {text}
      </Typography>
    </SingleCardWrapper>
  );
};

const SingleCardWrapper = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  border-radius: 8px;
  border: 1px solid
    ${({ selected }) =>
      selected ? `${tyrioUI.colors.lightBlue}` : `${tyrioUI.colors.black.B40}`};
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  background: ${({ selected, disabled }) =>
    selected
      ? `#1976D20A`
      : disabled
      ? `${tyrioUI.colors.black.B10}`
      : 'transparent'};
`;
