/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import { CircularProgress, FormControlLabel, Switch } from '@mui/material';
import { DBBranchType, DBServiceCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBBranchesApi,
  DBCountryApi,
  DBFittingBoxExtendedSettings,
} from '@tyrio/dto';
import { FEATURE_FLAGS, isFlagEnabled } from '@tyrio/feature-flags';
import SourceContainer, { TyrioSelectInputOption } from '@tyrio/forms';
import {
  backIcon,
  CancelModal,
  DeleteModal,
  RouteRouterPrompt,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
} from '@tyrio/ui-library';
import _, { isEmpty, startCase } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { queryClient } from '../../../query-client';
import { LoaderWrapper } from '../../components/PageTemplate/PageTemplate';
import { useAuth } from '../../context/AuthContext';
import ComingSoon from '../../pages/dashboard/ComingSoon';
import { MapBox } from '../company/pages/MapBox';
import { useGetRebates } from '../price-calculation/queries/get-rebates';
import {
  checkIfValidPhoneNumber,
  emailRegex,
} from '../supplier-form/helpers/regex';
import { initialValues } from './helpers/initial-values';
import BranchPrinters from './tabs-content/BranchPrinters';
import { DeliveryMethodsBranchTab } from './tabs-content/DeliveryMethods';
import FittingBoxes from './tabs-content/fitting-boxes/FittingBoxes';
import MainBranchesTab from './tabs-content/Main';
import { PaymentMethodsBranchTab } from './tabs-content/PaymentMethods';
import { useAddFittingBox } from './tabs-content/queries/add-fitting-box';
import { useGetFittingBoxSettings } from './tabs-content/queries/get-fitting-boxes';
import { useUpdateFittingBox } from './tabs-content/queries/update-fitting-box';
import { ServicesBranchTab } from './tabs-content/Services';

const COUNTRIES = ['Croatia'];

const BranchesOverview = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const clientPrinters = !isEmpty(user?.client?.printers)
    ? JSON.parse(user?.client?.printers as string)
    : {};

  const history = useHistory();
  const { branchId } = useParams<{ branchId: string }>();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [longitude, setLongitude] = useState(16.440193);
  const [latitude, setLatitude] = useState(43.508133);
  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);

  const [isSaving, setIsSaving] = useState(false);

  const [services, setServices] = useState<Record<string, boolean>>({});
  const [paymentMethods, setPaymentMethods] = useState<Record<string, boolean>>(
    {}
  );
  const [deliveryMethods, setDeliveryMethods] = useState<
    Record<string, boolean>
  >({});

  const { data: countries_data, isFetching: countries_isFetching } = useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data
          .filter((i) => COUNTRIES.includes(i.name))
          .map((item: DBCountryApi['getOne']['response']) => ({
            label: t(item.name),
            value: item.id,
            code: item.iso_code,
          }));
        setCountries(dropdownItems);
      },
    }
  );

  const { rebatesData } = useGetRebates({
    enabled: true,
  });

  const {
    data: branch_data,
    isFetching: isBranchDataFetching,
    refetch: getBranchById,
  } = useQuery(
    ['get_branch_by_id', branchId, 'update_branch'],
    () =>
      api.fetch<DBBranchesApi['getOne']>(`get_branch_by_id`, {
        id: branchId,
      }),
    {
      enabled: !!branchId,
    }
  );
  const { fittingBoxSettingsData, refetchFittingBoxSettings } =
    useGetFittingBoxSettings(branchId);

  const generateDefaultValues = useCallback(() => {
    return initialValues(branch_data, rebatesData, fittingBoxSettingsData);
  }, [branch_data, fittingBoxSettingsData, rebatesData]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isDirty },
    reset,
    unregister,
    watch,
    trigger,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...generateDefaultValues() } as any,
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: true,

    resolver: (formValues) => {
      //Required fields
      const validate = [
        'branchName',
        'branchType',
        'zipCode',
        'city',
        'address',
        'jobTitle',
        'firstName',
        'lastName',
      ];

      const errors: Record<string, string> = {};
      validate.forEach((item, index) => {
        if (formValues[validate[index]] === '') {
          errors[validate[index]] = 'This field is required!';
        }
      });

      if (_.isEmpty(formValues['branchType']))
        errors['branchType'] = 'This field is required!';
      if (
        !formValues['branchType'].includes('WAREHOUSE') &&
        !formValues['mainWarehouse']
      )
        errors['branchType'] =
          'The branch must be a warehouse or have an assigned warehouse!';

      // email fields validation
      const validateMails = ['email', 'mail'];

      validateMails.forEach((item, index) => {
        if (
          formValues[validateMails[index]] !== '' &&
          !emailRegex.test(formValues[validateMails[index]])
        )
          errors[validateMails[index]] = 'Email format is incorrect!';
      });

      const validatePhoneNumbers = [
        'businessPhone',
        'userBusinessPhone',
        'userMobilePhone',
      ];

      validatePhoneNumbers.forEach((numberField) => {
        const numberLength = formValues[numberField]?.length;
        if (numberLength > 4) {
          if (
            !checkIfValidPhoneNumber(formValues[numberField]) ||
            numberLength < 12 ||
            numberLength > 15
          )
            errors[numberField] = 'Number must be between 12 and 15 digits!';
        }
      });

      return {
        values: formValues,
        errors,
      };
    },
  });

  useEffect(() => {
    reset(generateDefaultValues());
  }, [branch_data, fittingBoxSettingsData, generateDefaultValues, reset]);

  //DELETE branch mutation
  const deleteBranchMutation = useMutation(
    () => api.fetch<DBBranchesApi['getOne']>('delete_branch', { id: branchId }),
    {
      mutationKey: 'delete_branch',
      onSuccess: () => {
        queryClient.refetchQueries('get_branches');
        history.push(`/dashboard/company-settings/branches`);
        ToastHelper.showToast(
          'Branch',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Branch',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const { updateFittingBox } = useUpdateFittingBox(refetchFittingBoxSettings);
  const { addFittingBox } = useAddFittingBox(refetchFittingBoxSettings);
  const createBranch = useMutation(
    (data: DBBranchesApi['create']['requestBody']) => {
      return api.fetch<DBBranchesApi['create']>('create_branch', {
        ...data,
      });
    },
    {
      mutationKey: 'create_branch',
      onSuccess: (item) => {
        queryClient.refetchQueries('get_branches');
        ToastHelper.showToast(
          'Branch',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/company-settings/branches/${item.id}`);
        getBranchById();
        setIsSaving(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        const errorMessage = data.response.data.error.name;
        ToastHelper.showToast(
          'Branch',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          errorMessage ?? 'An error occurred!'
        );
      },
    }
  );

  const updateBranch = useMutation(
    (data: DBBranchesApi['create']['requestBody']) => {
      return api.fetch<DBBranchesApi['updateOne']>('update_branch', {
        id: branchId,
        ...data,
      });
    },
    {
      mutationKey: 'update_branch',
      onSuccess: (item) => {
        queryClient.refetchQueries('get_branches');
        ToastHelper.showToast(
          'Branch',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        history.push(`/dashboard/company-settings/branches/${item.id}`);
        getBranchById();
        setIsSaving(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        const errorMessage = data.response.data.error.name;
        ToastHelper.showToast(
          'Branch',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          errorMessage ?? 'An error occurred!'
        );
      },
    }
  );

  useEffect(() => {
    if (Object.values(errors)?.length > 0)
      ToastHelper.showToast(
        'Branch',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        Object.values(errors)?.length === 1
          ? 'Field ' + startCase(Object.keys(errors)[0]) + ' has errors.'
          : 'Fields ' +
              Object.keys(errors).map((err) => ' ' + startCase(err)) +
              ' have errors.'
      );
  }, [errors, handleSubmit]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    setIsSaving(true);

    const payload: DBBranchesApi['create']['requestBody'] = {
      branchName: data.branchName,
      branchType: data.branchType.map((item: DBBranchType) =>
        item.replace(/ /g, '_')
      ),
      shortName: data.shortName ?? '',
      sortOrder: data.sortOrder ?? '',
      mainWarehouseId: data.mainWarehouse || undefined,
      isActive: data.active,
      countryId: countries_data?.find((i) => i.name === 'Croatia')?.id ?? '',
      zipCode: data.zipCode,
      city: data.city,
      address: data.address,
      email: data.email,
      webSite: data.webSite,
      businessPhone: data.businessPhone,
      erpId: data?.erpId ?? '',
      qualifiedPersonJobTitle: data.jobTitle,
      qualifiedPersonFirstName: data.firstName,
      qualifiedPersonLastName: data.lastName,
      qualifiedPersonEmail: data.mail,
      qualifiedPersonBusinessPhone: data.userBusinessPhone,
      qualifiedPersonMobilePhone: data.userMobilePhone,
      latitude: Number(latitude),
      longitude: Number(longitude),
      services,
      paymentMethods,
      printers: {
        wmsShipping_a4Documents: data.wmsShipping_a4Documents,
        wmsShipping_thermalLabels: data.wmsShipping_thermalLabels,
        wmsShipping_posReceipts: data.wmsShipping_posReceipts,
        wmsStockIn_a4Documents: data.wmsStockIn_a4Documents,
        wmsStockIn_thermalLabels: data.wmsStockIn_thermalLabels,
        wmsStockIn_posReceipts: data.wmsStockIn_posReceipts,
        wmsDispatch_a4Documents: data.wmsDispatch_a4Documents,
        wmsDispatch_thermalLabels: data.wmsDispatch_thermalLabels,
        wmsDispatch_posReceipts: data.wmsDispatch_posReceipts,
        wmsFitting_a4Documents: data.wmsFitting_a4Documents,
        wmsFitting_thermalLabels: data.wmsFitting_thermalLabels,
        wmsFitting_posReceipts: data.wmsFitting_posReceipts,
        wmsCollection_a4Documents: data.wmsCollection_a4Documents,
        wmsCollection_posReceipts: data.wmsCollection_posReceipts,
        wmsCollection_thermalLabels: data.wmsCollection_thermalLabels,
        wmsDeclaration_a4Documents: data.wmsDeclaration_a4Documents,
        wmsDeclaration_posReceipts: data.wmsDeclaration_posReceipts,
        wmsDeclaration_thermalLabels: data.wmsDeclaration_thermalLabels,
      },
      deliveryMethods,
      rebateCalculationId: rebatesData.find(
        (item) => item.value === data.rebateGroup
      )?.value,
    };

    console.log('CHECK -> ', data);

    if (data?.boxes) {
      console.log('CHECK 2 -> ', data.boxes);
      for (const box of data.boxes) {
        box.stepper = parseInt(box.stepper);
        box.minLength = parseInt(box.minLength);
        box.branchId = parseInt(branchId);

        if (box?.id) {
          const originalBox = fittingBoxSettingsData?.data.find(
            (b) => b.id === box.id
          );
          if (!originalBox) return;
          let hasChanges = false;

          const fieldsToCompare: (keyof DBFittingBoxExtendedSettings)[] = [
            'shelfId',
            'branchId',
            'craneType',
            'active',
            'blackList',
            'stepper',
            'minLength',
            'description',
            'category',
          ];
          hasChanges = fieldsToCompare.some(
            (field) => box[field] !== originalBox[field]
          );

          if (!hasChanges)
            if (
              JSON.stringify(box.workingTime) !==
              JSON.stringify(originalBox.workingTime)
            )
              hasChanges = true;

          if (
            !hasChanges &&
            !!originalBox?.serviceCategories &&
            box.serviceCategories?.length !==
              originalBox.serviceCategories?.length
          ) {
            hasChanges = true;
          } else {
            const boxServiceCategoryIds = box.serviceCategories
              .map((sc: DBServiceCategory) => sc.id)
              .sort();
            const originalBoxServiceCategoryIds = (
              originalBox.serviceCategories ?? []
            )
              .map((sc) => sc.id)
              .sort();
            if (
              !boxServiceCategoryIds.every(
                (id: string, index: number) =>
                  id === originalBoxServiceCategoryIds[index]
              )
            ) {
              hasChanges = true;
            }
          }

          if (hasChanges) updateFittingBox.mutate(box);
        } else {
          delete box.id;
          await addFittingBox.mutateAsync(box);
        }
      }
    }

    if (branchId) updateBranch.mutate(payload);
    else createBranch.mutate(payload);
  };

  const handleDeleteBranch = () => {
    setIsDeleteModalVisible(true);
  };

  const handleCancelBranch = () => {
    isDirty && setIsCancelModalVisible(true);
    !isDirty && history.push('/dashboard/company-settings/branches');
  };

  const updateBranchStatus = useMutation(
    () =>
      api.fetch<DBBranchesApi['updateOne']>('change_branch_status', {
        id: branchId,
      }),
    {
      mutationKey: 'update_branch_status',
      onSuccess: () => {
        queryClient.refetchQueries('get_branches');
        getBranchById();
        ToastHelper.showToast(
          'Branch',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Branch',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const handleUpdateStatus = () => {
    if (branchId) updateBranchStatus.mutate();
  };

  const branchTypeValue = watch('branchType');
  const selectedCountryId = watch('country');

  const shouldDisableField = useCallback(
    (key: string) => {
      if (branchTypeValue !== undefined) {
        const shouldDisable = branchTypeValue.includes('WAREHOUSE');
        if (shouldDisable && watch(key) !== '') {
          unregister(key);
          setValue(key, '');
        }

        return shouldDisable;
      }
      return false;
    },
    [branchTypeValue, setValue, unregister, watch]
  );

  const [generateGeocodeProps, setGenerateGeocodeProps] = useState({
    address: '',
    zipCode: '',
    city: '',
    country: '',
  });

  useEffect(() => {
    if (
      history.location.pathname === '/dashboard/company-settings/branches/new'
    )
      reset(generateDefaultValues());
  }, [
    history.location.key,
    reset,
    generateDefaultValues,
    history.location.pathname,
  ]);

  return (
    <BranchesLayout>
      {(branchId && !branch_data) ||
      isBranchDataFetching ||
      countries_isFetching ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <div style={{ height: '100%' }}>
          {!isCancelModalVisible && (
            <RouteRouterPrompt
              when={isDirty && !isSaving}
              navigate={(path) => history.push(path)}
              shouldBlockNavigation={() => true}
            />
          )}
          {isCancelModalVisible && (
            <CancelModal
              LBAction={() => setIsCancelModalVisible(false)}
              RBAction={() => {
                history.push(`/dashboard/company-settings/branches`);
                setIsCancelModalVisible(false);
              }}
            />
          )}
          {isDeleteModalVisible && (
            <DeleteModal
              LBAction={() => setIsDeleteModalVisible(false)}
              RBAction={() => {
                deleteBranchMutation.mutate();
                setIsDeleteModalVisible(false);
              }}
              itemName={branch_data?.branchName ? branch_data.branchName : ''}
            />
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
            onChange={(e: any) => {
              if (e.target.id === ':rj:') {
                setGenerateGeocodeProps({
                  ...generateGeocodeProps,
                  country: e.target['value'],
                });
              } else
                setGenerateGeocodeProps({
                  ...generateGeocodeProps,
                  [e.target.id]: e.target['value'],
                });
            }}
          >
            <TitleWrapper>
              <Title>
                <img
                  src={backIcon}
                  alt="back"
                  style={{
                    paddingBottom: 0,
                    width: '16px',
                    height: '22px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    history.push('/dashboard/company-settings/branches');
                  }}
                />
                <TitleText>
                  {branchId ? branch_data?.branchName : 'Create new branch'}
                </TitleText>
              </Title>

              <FormControlLabel
                control={
                  <Switch
                    {...register('active')}
                    id="active"
                    color="info"
                    defaultChecked={
                      branch_data?.id ? branch_data?.isActive : true
                    }
                    onChange={handleUpdateStatus}
                  />
                }
                label={t('Active')}
              />
            </TitleWrapper>

            <TyrioTabs>
              <TyrioTab title={t('Main')} key="main" errored={!isEmpty(errors)}>
                <MainBranchesTab
                  branchId={branchId}
                  errors={errors}
                  register={register}
                  control={control}
                  selectedCountryId={selectedCountryId}
                  shouldDisableField={shouldDisableField}
                  rebatesData={rebatesData}
                  countries={countries}
                />
                <MapBox
                  longitude={branch_data?.longitude ?? 16.440193}
                  setLongitude={setLongitude}
                  latitude={branch_data?.latitude ?? 43.508133}
                  setLatitude={setLatitude}
                  generateGeocodeProps={generateGeocodeProps}
                  trigger={trigger}
                />
              </TyrioTab>

              <TyrioTab title={t('Services')} key="services">
                {isFlagEnabled(FEATURE_FLAGS.SHOW_BRANCH_SERVICES) ? (
                  <ServicesBranchTab
                    setPayloadShape={setServices}
                    selectedServices={
                      branch_data && branch_data?.services
                        ? (branch_data?.services as unknown as Record<
                            string,
                            boolean
                          >)
                        : {}
                    }
                  />
                ) : (
                  <ComingSoon />
                )}
              </TyrioTab>

              <TyrioTab title={t('Payment methods')} key="paymentMehods">
                {isFlagEnabled(FEATURE_FLAGS.SHOW_BRANCH_PAYMENT_METHODS) ? (
                  <PaymentMethodsBranchTab
                    setPayloadShape={setPaymentMethods}
                    selectedPayments={
                      branch_data && branch_data?.paymentMethods
                        ? (branch_data?.paymentMethods as unknown as Record<
                            string,
                            boolean
                          >)
                        : {}
                    }
                  />
                ) : (
                  <ComingSoon />
                )}
              </TyrioTab>

              <TyrioTab title={t('Delivery methods')} key="deliveryMethods">
                {isFlagEnabled(FEATURE_FLAGS.SHOW_BRANCH_DELIVERY_METHODS) ? (
                  <DeliveryMethodsBranchTab
                    setPayloadShape={setDeliveryMethods}
                    selectedDeliveries={
                      branch_data && branch_data?.deliveryMethods
                        ? (branch_data?.deliveryMethods as unknown as Record<
                            string,
                            boolean
                          >)
                        : {}
                    }
                  />
                ) : (
                  <ComingSoon />
                )}
              </TyrioTab>

              <TyrioTab title={t('Printers')} key="printers">
                {clientPrinters ? (
                  <BranchPrinters errors={errors} control={control} />
                ) : (
                  <p>Setup default printers in Printers tab</p>
                )}
              </TyrioTab>

              <TyrioTab title={t('Boxes')} key="boxes">
                <FittingBoxes
                  boxes={fittingBoxSettingsData?.data}
                  branchId={branchId}
                  errors={errors}
                  register={register}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  refetchFittingBoxSettings={refetchFittingBoxSettings}
                />
              </TyrioTab>
            </TyrioTabs>
          </form>
          <SourceContainerDivider>
            <SourceContainer
              data={{
                data_source: 'source',
                created: 'created',
                last_edited: 'edited',
              }}
              customStyle={{ position: 'unset' }}
              onCancel={handleCancelBranch}
              onDelete={handleDeleteBranch}
              onSubmit={handleSubmit(onSubmit)}
            />
          </SourceContainerDivider>
        </div>
      )}
    </BranchesLayout>
  );
};

const BranchesLayout = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  padding: 16px;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled.div`
  margin-left: 20px;
  font-weight: 700;
  font-size: 24px;
`;

const SourceContainerDivider = styled.div`
  position: sticky;
  top: 100%;
`;

export default BranchesOverview;
