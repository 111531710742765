/* eslint-disable @typescript-eslint/no-explicit-any */
import { DBRepeatUnit, DBSupplierClientSettings } from '@prisma/client';

export const supplierClientSettings: DBSupplierClientSettings[] = [
  {
    id: '33b2ab3a-f07f-426d-a637-da5647ddfc5e',
    supplierId: '00000000-0000-0000-0000-000000000009',
    clientId: '00000000-0000-1000-b000-0000000000022',
    isActive: false,
    branchEdiConfiguration: {},
    connections: [
      {
        adminConnectionId: '35515fc6-76b1-4190-a051-a3f04cc88de0',
        type: 'FTP',
        connectionType: ['CATALOG'],
        connectionDetails: {
          startUpdateTime: new Date() as any,
          repeatEvery: 5,
          repeatUnit: DBRepeatUnit.HOURS,
          ftpHost: 'ftp.nerminh17.sg-host.com',
          ftpPort: 21,
          fileName: 'nerminh17.sg-host.com/tyrio/CONTI_PRICELIST.csv',
          username: 'nedimovski@nerminh17.sg-host.com',
          password: '!B$YeC4GqLDFSkzB',
          remark: '',
        },
      },
      {
        adminConnectionId: '987a41a2-8eaa-4e01-a3a3-e430b85b6da3',
        type: 'FTP',
        connectionType: ['STOCK'],
        connectionDetails: {
          startUpdateTime: new Date() as any,
          repeatEvery: 5,
          repeatUnit: DBRepeatUnit.HOURS,
          ftpHost: 'ftp.nerminh17.sg-host.com',
          ftpPort: 21,
          fileName: 'nerminh17.sg-host.com/tyrio/CONTI_STOCKLIST.csv',
          username: 'nedimovski@nerminh17.sg-host.com',
          password: '!B$YeC4GqLDFSkzB',
          remark: '',
        },
      },
      {
        adminConnectionId: '452096cb-3267-4f70-822c-f950aca99cc9',
        type: 'FTP',
        connectionType: ['PRICELIST'],
        connectionDetails: {
          startUpdateTime: new Date() as any,
          repeatEvery: 5,
          repeatUnit: DBRepeatUnit.HOURS,
          ftpHost: 'ftp.nerminh17.sg-host.com',
          ftpPort: 21,
          fileName: 'nerminh17.sg-host.com/tyrio/CONTI_PRICELIST.csv',
          username: 'nedimovski@nerminh17.sg-host.com',
          password: '!B$YeC4GqLDFSkzB',
          remark: '',
        },
      },
      {
        adminConnectionId: '19f2d907-99a5-402b-a813-b1a68427cf62',
        type: 'HTTPS',
        connectionType: ['ORDER'],
        connectionDetails: {
          url: 'https://directqual.conti.de/cgi-bin/C1-xml.cgi',
          username: '07297379.C1',
          password: 'HPS87bdQ',
          customerId: '0007297379',
          remark: 'Conti order',
        },
      },
    ],
    priceCalculationId: null,
  },
  {
    id: '07c91a78-e661-47df-800e-f197ea7b7022',
    supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
    clientId: '00000000-0000-1000-b000-0000000000022',
    isActive: false,
    branchEdiConfiguration: {},
    connections: [
      {
        adminConnectionId: '9310aa7a-f798-4a50-94cd-f935409ef1fd',
        type: 'FTP',
        connectionType: ['CATALOG'],
        connectionDetails: {
          startUpdateTime: new Date() as any,
          repeatEvery: 5,
          repeatUnit: DBRepeatUnit.HOURS,
          ftpHost: 'ftp.nerminh17.sg-host.com',
          ftpPort: 21,
          fileName: 'nerminh17.sg-host.com/tyrio/VDB_PRICAT.csv',
          username: 'nedimovski@nerminh17.sg-host.com',
          password: '!B$YeC4GqLDFSkzB',
          remark: '',
        },
      },
      {
        adminConnectionId: '2c22183f-fc5b-4f4e-a75c-3a53e8ebe91f',
        type: 'FTP',
        connectionType: ['STOCK', 'PRICELIST'],
        connectionDetails: {
          startUpdateTime: new Date() as any,
          repeatEvery: 5,
          repeatUnit: DBRepeatUnit.HOURS,
          ftpHost: 'ftp.nerminh17.sg-host.com',
          ftpPort: 21,
          fileName: 'nerminh17.sg-host.com/tyrio/VDB_STOCK_PRICE.csv',
          username: 'nedimovski@nerminh17.sg-host.com',
          password: '!B$YeC4GqLDFSkzB',
          remark: '',
        },
      },
      {
        adminConnectionId: 'de607bcd-1bd1-4d92-a422-a5283c1be163',
        type: 'HTTPS',
        connectionType: ['ORDER'],
        connectionDetails: {
          url: 'https://webservice.vandenban.nl/webservices_test',
          username: '801134',
          password: 'anto110nio',
          customerId: '801134',
          remark: 'Order remark',
        },
      },
    ],
    priceCalculationId: null,
  },
  {
    id: '07c91a78-e661-44df-800f-f297ea7b7023',
    supplierId: 'd80ae0db-ffa8-4a96-9624-dc16da96be01',
    clientId: '00000000-0000-1000-b000-0000000000022',
    isActive: false,
    branchEdiConfiguration: {},
    connections: [
      {
        adminConnectionId: 'a85ca99b-fa29-4ff5-81dc-9c977c6a9613',
        type: 'FTP',
        connectionType: ['CATALOG'],
        connectionDetails: {
          startUpdateTime: new Date() as any,
          repeatEvery: 5,
          repeatUnit: DBRepeatUnit.HOURS,
          ftpHost: 'files.000webhost.com',
          ftpPort: 21,
          fileName: '/demo/GMP_CATALOG.csv',
          username: 'haristyrio',
          password: 'harisharis',
          remark: '',
        },
      },
      {
        adminConnectionId: 'a85ca99b-fa29-4ff5-81dc-9c977c6a9614',
        type: 'FTP',
        connectionType: ['STOCK', 'PRICELIST'],
        connectionDetails: {
          startUpdateTime: new Date() as any,
          repeatEvery: 5,
          repeatUnit: DBRepeatUnit.HOURS,
          ftpHost: 'files.000webhost.com',
          ftpPort: 21,
          fileName: '/demo/GMP_PRICAT.csv',
          username: 'haristyrio',
          password: 'harisharis',
          remark: '',
        },
      },
      {
        adminConnectionId: '6aa903c6-7be9-4112-9299-408eb81a80ab',
        type: 'API',
        connectionType: ['ORDER'],
        connectionDetails: {
          apiUrl: 'https://api-gmpitalia.comunichiamo.eu/order',
          apiKey: '530e1f385a180d03ec185552cac49ab7c471776b',
          apiClientId: '2aec4bd91e83ec58a48d',
          apiClientSecret: '914be07dd8816f29acb38ea7e5a40e63b9613248',
          startUpdateTime: new Date() as any,
          repeatEvery: 1,
          repeatUntil: DBRepeatUnit.HOURS,
          supplierConnectionId: '5a4b5192-7692-42de-bdc6-ed6899758b5a',
          connectionType: 'GMP_ITALIA',
          remark: '',
        },
      },
    ],
    priceCalculationId: null,
  },
];
