import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const AUTEC_CATALOG_RULESET: TRule[] = [
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.EAN',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.ArtNr',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Category types
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'category_id',
    template: '72',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.Hersteller',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.operate',
    sourceKey: 'c.Design',
    destination: 'model',
    targetKey: 'modelName',
    operationName: 'getAutecModel',
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Wintertauglich',
    condition: 'equals',
    conditionValues: ['ja'],
    conditionOutputs: ['winter'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.Breite',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.width',
    matchValue: ['.0'],
    replaceValue: [''],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Hauptmass',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'pcd',
    template: '#{c.LochAnzahl}x#{c.Lochkreis}',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.ET',
    targetKey: 'et',
    delimiter: ',',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Mittenbohrung',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.Farbe',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  // Photos
  {
    type: 'rule.map',
    sourceKey: 'c.BildName',
    targetKey: 'originalPhotos',
    destination: 'model',
    outputType: 'string',
  },
  // Instalation kit
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'partialKit',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["OE Nabendeckel"]',
    condition: 'equals',
    conditionValue: 'ja',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'installationKit',
    outputValue: 'oem',
    outputType: 'string',
  },
  ...GLOBAL_RULESET,
];
