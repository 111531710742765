import api, { ENDPOINTS } from '@tyrio/api-factory';
import { PosApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../context/AuthContext';
import { POSContext } from '../../../context/POSContext';

export const useGetPosData = (
  filterValues: qs.ParsedQs,
  shouldLoadMore: boolean,
  setShouldLoadMore: Dispatch<SetStateAction<boolean>>,
  setHideLoadMore: Dispatch<SetStateAction<boolean>>
) => {
  const { user } = useAuth();
  const { isBranchesStockExpanded, isSupplierStockExpanded } =
    useContext(POSContext);

  // this prevents myStock and branchesStock from refetching on suppliers load more
  const otherFilterValues = _.omit(filterValues, 'supplierStockPage');

  const {
    setMyStockItems,
    setBranchesStockItems,
    supplierStockItems,
    setSupplierStockItems,
    selectedWarehouseId,
  } = useContext(POSContext);

  const myStockItemsQueryKey = 'get_my_stock_pos_list';
  const branchesStockItemsQueryKey = 'get_branches_stock_pos_list';
  const supplierStockItemsQueryKey = 'get_supplier_stock_pos_list';

  const formSets =
    filterValues?.['size']?.length === 2 &&
    (filterValues?.['categoryId'] === '1' ||
      filterValues?.['categoryId'] === '2');

  const queryFn = (key: keyof typeof ENDPOINTS) => {
    return api.fetch<PosApi['list']>(key, {
      query: {
        ...filterValues,
        formSets: _.toString(formSets),
      },
      warehouseId:
        selectedWarehouseId?.toString() ?? user?.currentBranchId?.toString(),
    });
  };

  const {
    status: myStockItemsStatus,
    isRefetching: myStockRefetching,
    isFetching: myStockFetching,
  } = useQuery(
    [myStockItemsQueryKey, otherFilterValues],
    () => queryFn(myStockItemsQueryKey),
    {
      onSuccess: (res) => {
        setMyStockItems(res);
      },
    }
  );

  const {
    status: branchesStockItemsStatus,
    isRefetching: branchesRefetching,
    isFetching: branchesFetching,
  } = useQuery(
    [branchesStockItemsQueryKey, otherFilterValues],
    () => queryFn(branchesStockItemsQueryKey),
    {
      enabled: isBranchesStockExpanded,
      onSuccess: (res) => {
        setBranchesStockItems({ ...res });
      },
    }
  );

  const {
    status: supplierStockItemsStatus,
    isRefetching: suppliersRefetching,
    isFetching: suppliersFetching,
  } = useQuery(
    [supplierStockItemsQueryKey, filterValues],
    () => queryFn(supplierStockItemsQueryKey),
    {
      enabled: isSupplierStockExpanded,
      onSuccess: (res) => {
        if (!_.isEqual(supplierStockItems, res) && shouldLoadMore) {
          // all data is loaded so there's no need for load more button
          if (res.unmatchedItems.length === 0 && res.matchedSets.length === 0)
            setHideLoadMore(true);
          else setHideLoadMore(false);

          setSupplierStockItems({
            matchedSets: [
              ...supplierStockItems.matchedSets,
              ...res.matchedSets,
            ],
            unmatchedItems: [
              ...supplierStockItems.unmatchedItems,
              ...res.unmatchedItems,
            ],
          });
          setShouldLoadMore(false);
        } else {
          setSupplierStockItems(res);
          setHideLoadMore(false);
        }
      },
      onError: (err: unknown) => {
        if (err instanceof AxiosError)
          ToastHelper.showToast(
            'Supplier stock',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            err?.response?.data?.error?.name ?? ''
          );
      },
    }
  );

  const showMyStockLoader =
    myStockItemsStatus === 'loading' || myStockFetching || myStockRefetching;

  const showBranchesLoader =
    branchesStockItemsStatus === 'loading' ||
    branchesRefetching ||
    branchesFetching;

  const showSuppliersLoader =
    supplierStockItemsStatus === 'loading' ||
    suppliersRefetching ||
    suppliersFetching;

  return {
    showMyStockLoader,
    showBranchesLoader,
    showSuppliersLoader,
  };
};
