import styled from '@emotion/styled/macro';
import { PhotoShape } from '@tyrio/dto';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

interface ImageGalleryGlobalProps {
  modelImages: PhotoShape[];
}

export const ImageGalleryGlobal = ({
  modelImages,
}: ImageGalleryGlobalProps) => {
  const [activeImageIdx, setActiveImageIdx] = useState(0);

  return (
    <Wrapper>
      <MainImage src={modelImages[activeImageIdx].url} />
      <Scrollbars
        style={{
          width: '100%',
          height: 200,
          paddingRight: 16,
          marginRight: 16,
        }}
      >
        <GalleryRow>
          {modelImages.map((img, idx) => (
            <GalleryImage
              onClick={() => setActiveImageIdx(idx)}
              src={img.url}
              key={`${img.url}-${idx}`}
            />
          ))}
        </GalleryRow>
      </Scrollbars>
    </Wrapper>
  );
};

const GalleryRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const GalleryImage = styled.img<{ active?: boolean }>`
  max-height: 200px;
  object-fit: cover;

  tab-index: 0;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  ${(props) =>
    props.active
      ? `
    opacity: 0.45;
  `
      : ``}
`;
const MainImage = styled.img`
  width: auto;
  min-height: 400px;
  max-height: 400px;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
