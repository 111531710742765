import styled from '@emotion/styled';
import { FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioDatepickerInput, TyrioFormError } from '../../';

interface TyrioInputDatePickerProps {
  id: string;
  item: TyrioDatepickerInput;
  gridProps: FormGridProps;
  error?: TyrioFormError;
  control: Control;
  register: UseFormRegister<FieldValues>;
}

export const TyrioInputDatePicker = ({
  item,
  id,
  gridProps,
  error,
  control,
}: TyrioInputDatePickerProps) => {
  const { t } = useTranslation();
  return (
    <GridContainer item {...gridProps}>
      <FormControl fullWidth error={!!error}>
        <Controller
          name={id}
          control={control}
          render={({ field }) => {
            return (
              <DatePicker
                openTo="year"
                disabled={item.disabled}
                toolbarPlaceholder={null}
                views={['year', 'month', 'day']}
                label={t(`${item.label}`)}
                inputFormat="dd.MM.yyyy"
                disableFuture={true}
                disablePast={false}
                value={field.value || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={false}
                    helperText={error?.message}
                    style={{
                      width: '100%',
                      height: 56,
                      color: '#d32f2f',
                      borderRadius: 8,
                    }}
                  />
                )}
                onChange={(e) => field.onChange(e)}
              />
            );
          }}
        />
        {error?.message && <FormHelperText>{t(error?.message)}</FormHelperText>}
      </FormControl>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
`;
