import * as z from "zod"
import { CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBBillingAddressModel = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  address: z.string(),
  zip: z.string(),
  city: z.string(),
  country: z.string(),
  email: z.string(),
  phoneNumberDetails: jsonSchema,
  companyInfo: jsonSchema,
  editedAddress: jsonSchema,
})

export interface CompleteDBBillingAddress extends z.infer<typeof DBBillingAddressModel> {
  customerOrderItem: CompleteDBCustomerOrderItem[]
}

/**
 * RelatedDBBillingAddressModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBBillingAddressModel: z.ZodSchema<CompleteDBBillingAddress> = z.lazy(() => DBBillingAddressModel.extend({
  customerOrderItem: RelatedDBCustomerOrderItemModel.array(),
}))
