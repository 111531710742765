/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import {
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DBMargin, DBPriceRound } from '@prisma/client';
import { DBClientPriceCalculationApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { parseEnumToInputOption } from '../../../../helpers/enum-parser';
import { getAutocompleteSingleField, getCheckbox } from '../form-helper';
import { FormSectionWithLabel } from '../products/pages/components/FormSectionWithTitle';
import _ from 'lodash';

interface PriceCalculationFormProps {
  suppliersData: TyrioSelectInputOption[];
  control: Control;
  register: UseFormRegister<FieldValues>;
  errors: Record<string, string>;
  onSubmit: (
    data: DBClientPriceCalculationApi['create']['requestBody']
  ) => void;
  handleSubmit: UseFormHandleSubmit<any>;
  getValues: UseFormGetValues<any>;
  isBasicCalculation: boolean;
  priceCalculationId?: string;
}

export const PriceCalculationForm = ({
  suppliersData,
  control,
  register,
  errors,
  onSubmit,
  handleSubmit,
  getValues,
  isBasicCalculation,
  priceCalculationId,
}: PriceCalculationFormProps) => {
  const marginOptions = [
    { value: DBMargin.EUR, label: '€' },
    { value: DBMargin.PERCENTAGE, label: '%' },
  ];
  const roundOptions = parseEnumToInputOption(DBPriceRound);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer style={{ marginTop: 0 }}>
        <DefaultRebateSettingsContainer>
          <FormSectionWithLabel
            label={
              isBasicCalculation
                ? 'DEFAULT PRICE CALCULATION FOR ALL SUPPLIERS'
                : 'DEFAULT PRICE CALCULATION FOR THIS SUPPLIER'
            }
          />

          <Subtitle>
            {isBasicCalculation
              ? 'This default price calculation is valid for all product and suppliers that don’t have defined custom price calculation'
              : 'This default price calculation is valid for all products that don’t have defined custom price calculation'}
          </Subtitle>
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: '8px',
            }}
          >
            {getAutocompleteSingleField(
              'Supplier',
              'supplierId',
              () => true,
              errors,
              control,
              {
                lg: 3,
                xs: 6,
                bgcolor: '#f9fafb',
              },
              {},
              suppliersData,
              isBasicCalculation || !_.isUndefined(priceCalculationId),
              '',
              '#f9fafb'
            )}

            <Grid
              item
              lg={3}
              xs={6}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Grid style={{ display: 'flex', width: '100%' }}>
                <Grid style={{ width: '100%' }}>
                  <TextField
                    id="margin"
                    key="margin"
                    label="Margin"
                    variant="outlined"
                    style={{ background: 'white', width: '100%' }}
                    InputProps={{
                      style: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                      type: 'number',
                      inputProps: {
                        min: 0,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      },
                    }}
                    error={!!errors['margin']}
                    {...register('margin')}
                  />
                </Grid>
                <Grid style={{ width: '100%' }}>
                  <Select
                    id="marginType"
                    key="marginType"
                    value={getValues('marginType')}
                    defaultValue={getValues('marginType')}
                    error={!!errors['marginType']}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderLeft: 0,
                      width: '100%',
                      background: 'white',
                    }}
                    {...register('marginType')}
                  >
                    {marginOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              {errors['margin'] && (
                <FormHelperText sx={{ color: '#d32f2f', marginLeft: '5px' }}>
                  {errors['margin']}
                </FormHelperText>
              )}
            </Grid>

            {getAutocompleteSingleField(
              'Price rounding',
              'priceRound',
              () => true,
              errors,
              control,
              { lg: 3, xs: 6 },
              {},
              roundOptions
            )}

            {getCheckbox(
              'Use Suggested Retail Price',
              'useSuggestedRetailPrice',
              () => true,
              {},
              control,
              {
                lg: 3,
                xs: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '5px',
              }
            )}
          </Grid>
        </DefaultRebateSettingsContainer>
      </FormContainer>
    </form>
  );
};

const FormContainer = styled.div`
  margin-top: 16px;
  padding-top: 16px;
`;

const DefaultRebateSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  background: #f9fafb;
  border-radius: 16px;
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
