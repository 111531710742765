import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';


export function getGumiImpexNoisePerformance(input: RawImport, _rule: TRule) {
  const noisePerformance = Number(input['Buka'].replace(/\D/g, '').replace(/^0+/, ''))

  return noisePerformance;
}

export function getGumiImpexNoiseClassType(input: RawImport, _rule: TRule) {
  const noiseClass = input['Buka'].replace(/[A-Za-z0-9]/g, '').replace(/\s/g, '');

  if (noiseClass === ')') {
    return 'A'
  }
  if (noiseClass === '))') {
    return 'B'
  }
  if (noiseClass === ')))') {
    return 'A'
  }

  return ''
}

export function globalGetGumiImpexLoadSpeedIndex(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetGumaxLoadIndex requires rule to be of type TOperateRule'
    );

  // Match the numeric load index at the beginning and the speed index at the end
  const loadIndexMatch = input['Li_Si'].match(/^\d+/);
  const speedIndexMatch = input['Li_Si'].match(/[A-Z]+$/);

  // Ensure type safety by handling potential nulls
  const loadIndex = loadIndexMatch ? loadIndexMatch[0] : '';
  const speedIndex = speedIndexMatch ? speedIndexMatch[0] : '';

  let loadIndex2 = '';
  if (loadIndex && speedIndex) {
    const loadIndexPattern = `${loadIndex}/`;
    const indexInName = input['Naziv'].indexOf(loadIndexPattern);
    if (indexInName !== -1) {
      const nextThreeChars = input['Naziv'].slice(indexInName + loadIndexPattern.length, indexInName + loadIndexPattern.length + 3);
      loadIndex2 = nextThreeChars.replace(/\D/g, ''); // Leave only numbers
    }
  }

  const data = {
    product: {
      loadIndex,
      speedIndex,
      loadIndex2,
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}

export function getGumiImpexSpecialMarkings(
  input: RawImport,
  rule: TRule,
  _data?: UnfinishedData,
): Partial<UnfinishedData> {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'getGumiImpexSpecialMarkings requires rule to be of type TGlobalOperateRule'
    );
  let cleanedName = '';

  // Create a regex pattern to remove brand, rim, model, and li_si
  const regexPattern = new RegExp(`(\b${input['Brand']}\b|\b${input['Promjer']}\b|\b${input['Dezen']}\b|\b${input['Li_Si']}\b)`, 'gi');

  // Get the part after the rim and remove all matches of the other fields
  const indexOfRim = input['Naziv'].indexOf(input['Promjer']);
  if (indexOfRim !== -1) {
    const partAfterRim = input['Naziv'].slice(indexOfRim + input['Promjer'].length).trim();
    cleanedName = partAfterRim.replace(regexPattern, '').replace(/\s+/g, ' ').trim();
  }

  const runflatMarkings = [
    'RFT',
    'Run Flat',
    'RunOnFlat',
    'DSST',
    'EMT',
    'HRS',
    'MOE',
    'AOE',
    'RSC',
    'SSR',
    'XRP',
    'ZP',
    'ZPS',
    'ROF',
    'BSR',
  ]

  return genericGenerateSpecialMarkings(cleanedName, () => runflatMarkings.some(marking => input['Naziv'].includes(marking)), false);
}