import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { formatCurrencyWithSymbol } from '../../helpers/currency-format';

interface GrandTotalProps {
  subTotalExlVat: number;
  vat: number;
  grandTotal: number;
}

export const GrandTotal = ({
  subTotalExlVat,
  vat,
  grandTotal,
}: GrandTotalProps) => {
  const vatAmount = (vat / 100) * subTotalExlVat;

  return (
    <TotalWrapper>
      <TextWrapper>
        <Text style={{ color: 'white' }} fontSize={12}>
          Subtotal excl. VAT:
        </Text>
        <Typography fontSize={12} color={'white'}>
          {formatCurrencyWithSymbol(subTotalExlVat)}
        </Typography>
      </TextWrapper>
      <TextWrapper>
        <Text style={{ color: 'white' }} fontSize={12}>
          {`VAT (${vat}%):`}
        </Text>
        <Typography fontSize={12} color={'white'}>
          {formatCurrencyWithSymbol(vatAmount ?? 0)}
        </Typography>
      </TextWrapper>
      <TextWrapper>
        <Text style={{ color: 'white' }} fontWeight={600}>
          GRAND TOTAL:
        </Text>
        <Typography fontSize={16} color={'white'}>
          {formatCurrencyWithSymbol(Number(grandTotal))}
        </Typography>
      </TextWrapper>
    </TotalWrapper>
  );
};

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 36%;
  width: 100%;
  align-items: start;
  background: #454f5b;
  color: white;
  border-bottom-right-radius: 16px;
  padding: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Text = styled(Typography)`
  color: ${tyrioUI.colors.black.B80};
`;
