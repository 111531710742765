import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import SupplierAdminForm from '../features/supplier-form/SupplierAdminForm';
import SupplierClientForm from '../features/supplier-form/SupplierClientForm';
import DashboardAdminSupplier from '../pages/dashboard/SuplierAdmin';
import DashboardClientSupplier from '../pages/dashboard/SupplierClient';
import { useAuth } from '../context/AuthContext';

export const SupplierRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const { user } = useAuth();

  const clientId = user?.clientId || undefined;

  return (
    <Switch>
      {clientId ? (
        <DashboardClientSupplier>
          {location.pathname.split('/').pop() === 'new' ? (
            <Route exact path={`${path}/new`}>
              <SupplierClientForm />
            </Route>
          ) : (
            <Route exact path={`${path}/:supplierId`}>
              <SupplierClientForm />
            </Route>
          )}
        </DashboardClientSupplier>
      ) : (
        <DashboardAdminSupplier>
          {location.pathname.split('/').pop() === 'new' ? (
            <Route exact path={`${path}/new`}>
              <SupplierAdminForm />
            </Route>
          ) : (
            <Route exact path={`${path}/:supplierId`}>
              <SupplierAdminForm />
            </Route>
          )}
        </DashboardAdminSupplier>
      )}
    </Switch>
  );
};
