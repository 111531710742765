import api from '@tyrio/api-factory';
import {
  DBClientPriceCalculationApi,
  DBClientPriceCalculationResponse,
} from '@tyrio/dto';
import {
  basicCalculationIcon,
  LandingPage,
  SearchableVerticalList,
  SearchableVerticalListDataType,
} from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import { ExpandableLayout } from '../../layouts/ExpandableLayout';
import routeNames from '../../lib/routeNames';

interface PriceCalculationProps {
  children: React.ReactNode;
}

const PriceCalculation = ({ children }: PriceCalculationProps) => {
  const history = useHistory();
  const location = useLocation();

  const pageSize = 10;

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const [page, setPage] = useState(1);

  const [selected, setSelected] = useState<Record<string, boolean>>(
    location.pathname.split('/').pop() !== 'price-calculation'
      ? { [location.pathname.split('/').pop()]: true }
      : {}
  );

  const {
    isFetching,
    isError,
    data: calculationData,
  } = useQuery(
    ['get_price_calculations', { debouncedSearch, page }],
    () => searchCalculations(search, page),
    {}
  );

  const searchCalculations = async (search: string, page: number) => {
    return await api.fetch<DBClientPriceCalculationApi['list']>(
      'get_price_calculations',
      {
        search,
        page,
        pageSize,
      }
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      history.push(`/dashboard/price-calculation/${item.id}`);
    }
    if (type === 'new') {
      history.push(`/dashboard/price-calculation/new`);
    }
  };

  useEffect(() => {
    if (location.pathname.split('/').pop() !== 'price-calculation')
      setSelected({ [location.pathname.split('/').pop()]: true });
    else setSelected({});
  }, [location.pathname]);

  const { data: basicCalculation, isFetching: isBasicCalculationDataFetching } =
    useQuery(['get_basic_calculation'], () =>
      api.fetch<DBClientPriceCalculationApi['getOne']>(`get_basic_calculation`)
    );

  const getName = (item: DBClientPriceCalculationResponse) => {
    if (item.supplierId === null) return item.name;

    return item.supplier?.supplier.companyShortName as string;
  };

  const getSubtitle = (item: DBClientPriceCalculationResponse) => {
    if (item.supplierId === null) return '';

    return item.supplier?.supplier.supplierType as string;
  };

  function sortArray(data: DBClientPriceCalculationResponse[]) {
    const basicPriceCalculation = data.filter((obj) => obj.supplierId === null);
    const otherObjects = data.filter((obj) => obj.supplierId !== null);

    otherObjects.sort((a, b) => a.name.localeCompare(b.name));

    const sortedData = basicPriceCalculation.concat(otherObjects);

    return sortedData.map((item) => ({
      id: item.id,
      name: getName(item),
      type: getSubtitle(item),
      active: item.isActive,
      disabled: false,
      isSelected: selected[item.id],
    }));
  }

  return (
    <ExpandableLayout
      sidebarComponent={
        <SearchableVerticalList
          header="Price calculation"
          type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
          data={sortArray(calculationData?.data ?? [])}
          path={routeNames.dashboard()}
          isFetching={isFetching && isBasicCalculationDataFetching}
          isError={isError}
          onSearchChange={setSearch}
          onCreateNew={() => handleClick('new', {})}
          handleItemClick={(item) => handleClick('itemClicked', item)}
          onChangePage={(page) => setPage(page)}
          count={calculationData?.count}
          pageNumber={page}
        />
      }
      landingPage={
        <LandingPage
          icon={!basicCalculation ? basicCalculationIcon : ''}
          title={'Welcome to Price calculation'}
          subtitle={
            calculationData?.count !== 0
              ? 'Choose price calculation from the list or create new.'
              : 'First you need to create Basic Price Calculation.'
          }
          buttonText={
            calculationData?.count !== 0
              ? 'Add new price calculation'
              : 'Add basic price calculation'
          }
          onClick={() => handleClick('new', {})}
        />
      }
    >
      {children}
    </ExpandableLayout>
  );
};

export default PriceCalculation;
