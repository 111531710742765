/* eslint-disable @typescript-eslint/no-explicit-any */
import { DBClient } from '@prisma/client';

export const getGmpData = (data: any, client: DBClient | null | undefined) => {
  return {
    alias: data.supplierRemark ?? '',
    notes: data.remark ?? '',
    shipping_address: {
      company: client?.officialName ?? '',
      country: data.deliveryAddress?.country ?? '',
      address: data.deliveryAddress?.address_line_2 ?? '',
      municipality: data.deliveryAddress?.city ?? '',
      zip_code: data.deliveryAddress?.zipCode ?? '',
      city: data.deliveryAddress?.city ?? '',
      province: data.deliveryAddress?.city ?? '',
    },
    items: data.lineItems.map((item: { ipc: string; quantity: number }) => ({
      code: item.ipc,
      quantity: item.quantity,
    })),
    connection: data.connection,
  };
};
