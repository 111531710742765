import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { tyrioMUI, tyrioUI } from '@tyrio/ui-library';

const LoginHeader = styled.div`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div`
  max-width: 550px;
  width: 100%;
`;
const LoginBody = styled.form``;

const LoginFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const TypographyTitle = styled(Typography)`
  height: 34px;
  letter-spacing: 0.3px;
  color: #212b36;
  font-weight: 500;
  font-size: 30px;
`;
const TypographyOTP = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 18px;
`;
const TypographyError = styled(Typography)`
  color: ${tyrioMUI.muiTheme.palette.error.main};
  variant: subtitle1;
  text-align: center;
`;
const TypographyFooter = styled(Typography)`
  width: 60%;
  text-align: center;
  color: #212b36;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
`;
const OTPError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  background: #f44336;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #ffffff;
  margin-bottom: 20px;
`;
const OTPFooter = styled(Typography)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
`;
const Divider = styled.span`
  padding-left: 10px;
  padding-right: 10px;
  color: #919eab;
  min-width: 110px; //for english
  // min-width: 125px; for croatian
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 25px;
  margin-top: 25px;
`;
const Border = styled.div`
  border-bottom: 1px solid #dfe3e8;
  width: 100%;
`;
const Spacer = styled.div`
  background-color: ${tyrioMUI.muiTheme.palette.grey['300']};
  height: 1px;
  width: 100%;
  margin: ${tyrioUI.spacing.xl}px 0;
`;
const ExistingUserError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  background: #f44336;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #ffffff;
  margin-top: 20px;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const SuccessSent = styled.div``;
const Text = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 18px;
`;
const CheckEmail = styled(Typography)`
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
`;

const RecaptchaWrapper = styled.div<{ isVerified: boolean }>`
  display: ${(props) => (!props.isVerified ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 30px;
`;

const Recaptcha = styled.div<{ notChecked: boolean | null }>`
  display: ${(props) => (props.notChecked ? '' : 'none')};
  width: 305px;
  height: 79px;
`;

const OTPContainer = styled.div``;

export const LoginUI = {
  LoginHeader,
  Container,
  LoginBody,
  LoginFooter,
  TypographyTitle,
  TypographyOTP,
  TypographyError,
  TypographyFooter,
  OTPError,
  OTPFooter,
  Divider,
  DividerContainer,
  Border,
  Spacer,
  ExistingUserError,
  LoadingContainer,
  SuccessSent,
  Text,
  CheckEmail,
  RecaptchaWrapper,
  Recaptcha,
  OTPContainer,
};
