import { DBUserApi } from '@tyrio/dto';
import { isEmpty } from 'lodash';
import { useAuth } from '../../../../../context/AuthContext';
import { useWS } from '../../../../../context/WSContext';

export const usePosCartData = () => {
  const ws = useWS();
  const { user } = useAuth();
  const condition =
    ws.state.posCart !== undefined &&
    ws.state.posCart?.userCart[
      (user as DBUserApi['getOne']['response'])?.id
    ] !== undefined &&
    !isEmpty(ws.state.posCart?.userCart);

  const onStockData = condition
    ? ws.state.posCart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
        ?.onStock
    : [];

  const upcomingOnStockData = condition
    ? ws.state.posCart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
        ?.upcomingOnStock
    : [];

  const branchesData = condition
    ? ws.state.posCart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
        ?.branches
    : [];

  const suppliersData = condition
    ? ws.state.posCart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
        ?.suppliers
    : [];

  const servicesData = condition
    ? ws.state.posCart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
        ?.services
    : [];

  const modalData = [
    ...onStockData,
    ...upcomingOnStockData,
    ...branchesData,
    ...suppliersData,
    ...servicesData,
  ];

  return {
    onStockData,
    upcomingOnStockData,
    branchesData,
    suppliersData,
    servicesData,
    modalData,
  };
};
