import styled from '@emotion/styled';
import { DBProductApi } from '@tyrio/dto';
import { CategoryLevelsOld } from '../ProductDetails/ProductComponents/PerformanceCategory';
import EprelClassOld from './EprelClassOld';
import euFlag from '../../assets/eprel/eu-flag.png';
import noiseLevelA from '../../assets/eprel/noise-lv1.png';
import noiseLevelB from '../../assets/eprel/noise-lv2.png';
import noiseLevelC from '../../assets/eprel/noise-lv3.png';
import rollingResistanceOld from '../../assets/eprel/rolling-resistance-old.png';
import wetGripOld from '../../assets/eprel/wet-grip-old.png';

interface EuLabelOldGeneratorProps {
  product: DBProductApi['getOne']['response'];
}

const EuLabelOldGenerator = ({ product }: EuLabelOldGeneratorProps) => {
  const generateNoiseLevel = () => {
    if (product.noiseClassType === '1' || product.noiseClassType === 'A')
      return noiseLevelA;
    if (product.noiseClassType === '2' || product.noiseClassType === 'B')
      return noiseLevelB;
    if (product.noiseClassType === '3' || product.noiseClassType === 'C')
      return noiseLevelC;
    return undefined;
  };
  return (
    <Container>
      <RollingResistanceWrapper>
        <RollingResistance
          src={rollingResistanceOld}
          alt="rolling-resistance-icon"
        />
      </RollingResistanceWrapper>
      <ClassWrapper>
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.A}
          productClass={product.rollingResistance as CategoryLevelsOld}
        />
        <EprelClassOld
          width={'28px'}
          category={CategoryLevelsOld.B}
          productClass={product.rollingResistance as CategoryLevelsOld}
        />
        <EprelClassOld
          width={'36px'}
          category={CategoryLevelsOld.C}
          productClass={product.rollingResistance as CategoryLevelsOld}
        />
        <EprelClassOld
          width={'42px'}
          category={CategoryLevelsOld.D}
          productClass={product.rollingResistance as CategoryLevelsOld}
        />
        <EprelClassOld
          width={'50px'}
          category={CategoryLevelsOld.E}
          productClass={product.rollingResistance as CategoryLevelsOld}
        />
        <EprelClassOld
          width={'58px'}
          category={CategoryLevelsOld.F}
          productClass={product.rollingResistance as CategoryLevelsOld}
        />
        <EprelClassOld
          width={'66px'}
          category={CategoryLevelsOld.G}
          productClass={product.rollingResistance as CategoryLevelsOld}
        />
      </ClassWrapper>

      <WetGripWrapper>
        <WetGrip src={wetGripOld} alt="rolling-resistance-icon" />
      </WetGripWrapper>
      <WetClassWrapper>
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.A}
          productClass={product.wetGrip as CategoryLevelsOld}
          transparent={true}
        />
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.B}
          productClass={product.wetGrip as CategoryLevelsOld}
          transparent={true}
        />
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.C}
          productClass={product.wetGrip as CategoryLevelsOld}
          transparent={true}
        />
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.D}
          productClass={product.wetGrip as CategoryLevelsOld}
          transparent={true}
        />
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.E}
          productClass={product.wetGrip as CategoryLevelsOld}
          transparent={true}
        />
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.F}
          productClass={product.wetGrip as CategoryLevelsOld}
          transparent={true}
        />
        <EprelClassOld
          width={'20px'}
          category={CategoryLevelsOld.G}
          productClass={product.wetGrip as CategoryLevelsOld}
          transparent={true}
        />
      </WetClassWrapper>
      <NoiseWrapper>
        {generateNoiseLevel() && (
          <WetClass src={generateNoiseLevel()} alt="noise-icon" />
        )}
      </NoiseWrapper>
      <NoiseValue>
        <SelectedStart />
        <SelectedClass>
          {product.noisePerformance}
          <span style={{ fontSize: '18px' }}>dB</span>
        </SelectedClass>
      </NoiseValue>
      <EuFlag src={euFlag} alt="eu-flag" />
      <EuDirective>
        {product.euDirectiveNumber} - <b>{product.vehicleClass}</b>
      </EuDirective>
    </Container>
  );
};

const Container = styled.div`
  width: 272.91px;
  height: 402px;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background: white;
  border: 1px solid #878485;
  font-family: Arial, Helvetica, sans-serif;
  border: 1.5px solid #54c5ed;
  position: relative;
`;

const EuDirective = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 10px;
  left: 60px;
`;
const EuFlag = styled.img`
  position: absolute;
  left: 17px;
  bottom: 13px;
  width: 32px;
`;

const NoiseValue = styled.div`
  position: absolute;
  top: 280px;
  left: 152px;
  height: 90px;
  width: 100px;
  border: 1.5px solid #54c5ed;
  border-right: 3.5px solid #54c5ed;
  border-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NoiseWrapper = styled.div`
  position: absolute;
  top: 280px;
  left: 52px;
  border: 3.5px solid #54c5ed;
  border-radius: 15px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 90px;
  width: 100px;
`;

const WetClass = styled.img`
  width: 120px;
  margin-top: 2px;
  margin-left: -8px;
`;

const RollingResistanceWrapper = styled.div`
  position: absolute;
  height: 82px;
  width: 94px;
  left: 53px;
  top: 20px;
  border: 3.5px solid #54c5ed;
  border-bottom: 1px solid transparent;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 999;
  background: white;
`;

const RollingResistance = styled.img`
  width: 100%;
  z-index: 999;
`;

const ClassWrapper = styled.div`
  position: absolute;
  padding-top: 23px;
  border: 3.5px solid #54c5ed;
  border-left: 1px solid #54c5ed;
  border-right: 1px solid #54c5ed;
  left: 14px;
  top: 99px;
  width: 133px;
  z-index: 99;
  height: 175px;
`;

const WetGripWrapper = styled.div`
  position: absolute;
  height: 82px;
  width: 94px;
  left: 159px;
  top: 20px;
  border: 3.5px solid #54c5ed;
  border-bottom: 1px solid transparent;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 999;
  background: white;
`;

const WetGrip = styled.img`
  width: 100%;
`;

const WetClassWrapper = styled.div`
  position: absolute;
  padding-top: 23px;
  border: 3.5px solid #54c5ed;
  border-left: 1px solid #54c5ed;
  border-right: 1px solid #54c5ed;
  left: 159px;
  top: 99px;
  width: 94px;
  z-index: 9;
  height: 175px;
`;

const SelectedStart = styled.div`
  width: 18px;
  height: 37px;
  border-top: 18.5px solid transparent;
  border-right: 15px solid black;
  border-bottom: 18.5px solid transparent;
`;

const SelectedClass = styled.div`
  height: 37px;
  width: 87px;
  text-align: center;
  background: black;
  line-height: 37px;
  font-size: 27px;
  font-weight: 600;
  color: white;
`;

export default EuLabelOldGenerator;
