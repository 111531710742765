/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { ConditionsAndActions } from '../Conditions/ConditionsAndActions';
import { useRef } from 'react';
import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';
import { useDrop, useDrag } from 'react-dnd';
import { IRules } from '../../context/RulesContext';

interface ISingleRule {
  rule: IRules;
  index: number;
  expandedData: number[];
  setExpandedData: (a: number[]) => void;
  handleRulesDelete: (a: number) => void;
  swapRules: (a: number, b: number) => void;
  componentType: string;
}
export const SingleRule = ({
  rule,
  index,
  expandedData,
  setExpandedData,
  handleRulesDelete,
  swapRules,
  componentType,
}: ISingleRule) => {
  const ref = useRef<any>(null);

  const moveCard = (dragIndex: number, index: number) => {
    swapRules(dragIndex, index);
  };

  const [{ handlerId, canDrop }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver({ shallow: true }),
        id: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id: index, index: index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  const background = canDrop ? '#19c5d214' : '';

  drag(drop(ref));

  return (
    <AccordionStyled
      expanded={expandedData.includes(index)}
      square
      defaultExpanded
    >
      <AccordionSummary
        ref={ref}
        data-handler-id={handlerId}
        style={{ opacity, background }}
        expandIcon={<ExpandMore />}
        onClick={() => {
          setExpandedData(
            expandedData.includes(index)
              ? expandedData.filter((i: number) => i !== index)
              : [...expandedData, index]
          );
        }}
      >
        <Typography>
          {rule.name}
          {rule.ruleDescription !== '' && rule.ruleDescription !== undefined
            ? ' - ' + rule.ruleDescription
            : null}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ConditionsAndActions
          rule={rule}
          index={index}
          componentType={componentType}
        />
        <ButtonWrapper>
          <Button
            variant="text"
            color="error"
            onClick={() => handleRulesDelete(index)}
          >
            Delete
          </Button>
        </ButtonWrapper>
      </AccordionDetails>
    </AccordionStyled>
  );
};

export const RulesWrapper = styled.div`
  margin-top: 20px;
`;

const AccordionStyled = styled(Accordion)({
  boxShadow: '0px 1px 0px #DFE3E8',
  '& .MuiAccordionDetails-root': {
    padding: '8px 0 0 0 !important',
  },

  '& .MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: `1px solid ${tyrioUI.colors.black.B40}`,
  },
});

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
`;
