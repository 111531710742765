import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';

export const SingleUnitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 0 11px 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;

  &:hover {
    background: #f9fafb;

    div:first-of-type {
      opacity: 1;
    }

    #quantity_wrapper {
      background: ${tyrioUI.colors.black.B30};
    }

    #quantity_component {
      background: white;
    }
  }
`;

export const ComponentYearWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  max-width: 220px;
  width: 100%;
`;

export const PriceText = styled.p<{ sale?: boolean }>`
  color: ${(props) =>
    props.sale ? tyrioUI.colors.secondary : tyrioUI.colors.black.B100};
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 300px;
  width: 100%;
  justify-content: space-between;
`;

export const ChipWrapper = styled.div`
  display: flex;
  width: 100px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
`;

export const OldPrice = styled.p`
  color: ${tyrioUI.colors.black.B100};
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.3px;
  text-decoration: line-through;
  margin: 0;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  width: 100%;
`;
