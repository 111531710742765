import styled from '@emotion/styled';

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
`;
