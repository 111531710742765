import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
// import { AppointmentsCalendar } from '../../calendar/Calendar';
import styled from '@emotion/styled';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { backIcon } from 'libs/ui-library/src/assets';
import { AppointmentsCalendarV2 } from '../../calendar/CalendarV2';

interface IAddStockListItem {
  close: Dispatch<SetStateAction<boolean>>;
}

export const Calendar = ({ close }: IAddStockListItem) => {
  return (
    <Container>
      <Box display="flex" alignItems="center">
        <BackButton onClick={() => close(true)} data-cy="back-button">
          <img src={backIcon} alt="back-icon" />
        </BackButton>
        <HeaderText>Appointments</HeaderText>
        <InsertInvitationIcon fontSize="large" />
      </Box>
      <Box sx={{ marginTop: '20px', overflow: 'hidden' }}>
        <AppointmentsCalendarV2 onClose={close} />
      </Box>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderText = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.30000001192092896px;
  margin-right: 5px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
  display: flex;
  margin-left: -5px;
`;
