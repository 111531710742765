import styled from '@emotion/styled/macro';
import { formatCurrencyWithSymbol } from '../../../helpers/currency-format';

interface CostComparisonProps {
  expectedCost: number;
  calculatedCost: number;
}

export const CostComparison = ({
  expectedCost,
  calculatedCost,
}: CostComparisonProps) => {
  const color =
    calculatedCost > expectedCost
      ? '#DB1E1E'
      : calculatedCost < expectedCost
      ? '#FFCC4D'
      : '#1976D2';

  return (
    <Wrapper>
      <JustifyBetween>
        <Text>Expected variable cost</Text>
        <Text>Calculated variable cost</Text>
      </JustifyBetween>
      <JustifyBetween>
        <Cost>{formatCurrencyWithSymbol(expectedCost)}</Cost>
        <Cost color={color} weight={'500'}>
          {formatCurrencyWithSymbol(calculatedCost)}
        </Cost>
      </JustifyBetween>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #f9fafb;
`;

const JustifyBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  color: #919eab;
  text-overflow: ellipsis;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const Cost = styled.div<{ color?: string; weight?: string }>`
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
`;
