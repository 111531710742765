import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function getInterpneuSpecialMarkings(
  input: RawImport,
  rule: TRule
): Partial<UnfinishedData> {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'getInterpneupecialMarkings requires rule to be of type TGlobalOperateRule'
    );

  const inputValues = [
    input['specialMarking_wheelModel']
      .replace('(+)', '(TYRIOCHANGE)')
      .replace('+/MO', '* MO')
      .replace('+', '*')
      .replace('(TYRIOCHANGE)', '(+)'),
    input['flank'].replace('FLS', 'FSL'),
    input['RFD_color'],
    input['sidewall'],
    input['runFlat'],
  ].join(' ');

  const data = genericGenerateSpecialMarkings(
    inputValues,
    () =>
      [
        'PKW-SO',
        'PKW-WI',
        'PKW-AS',
        'OFF-ROAD-SO',
        'OFF-ROAD-WI',
        'OFF-ROAD-AS',
        'LLKW-SO',
        'LLKW-WI',
        'LLKW-AS',
      ].includes(input['category']) &&
      input['runFlat'] !== null &&
      input['runFlat'].trim() !== '' &&
      !['SEAL', 'SEALGUARD', 'SLT'].includes(input['runFlat']),
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }

  return data;
}

export function globalGetInterpneuPCD(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetInterpneuPCD requires rule to be of type TOperateRule'
    );

  const pcdList: string[] = [];

  if (['ALUFELGEN', 'STAHLFELGEN'].includes(input['category'])) {
    const rimSize = input['rimSize'];
    if (rimSize !== null && rimSize !== '') {
      const parts = rimSize.split('+');
      parts.forEach((part) => {
        const subparts = part.split('/').map((subpart) => subpart.trim());
        const prefix = subparts[0];
        const sizes = subparts.slice(1).join('x');
        pcdList.push(`${prefix}x${sizes}`);
      });
    }
  }

  const data = {
    product: {
      pcd: pcdList,
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}
