import { Autocomplete, TextField } from '@mui/material';
import { LocationType } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { Dispatch, SetStateAction } from 'react';
import { LocationTypes } from './LocationType';

interface ChooseLocationProps {
  locationType: Record<string, LocationType>;
  setLocationType: Dispatch<SetStateAction<Record<string, LocationType>>>;
  qrCode: string;
  value: TyrioSelectInputOption | undefined;
  shelvesDropdownData: TyrioSelectInputOption[];
  binsDropdownData: TyrioSelectInputOption[];
  handleChange: (input: TyrioSelectInputOption) => void;
}

export const ChooseLocation = ({
  locationType,
  setLocationType,
  qrCode,
  value,
  shelvesDropdownData,
  binsDropdownData,
  handleChange,
}: ChooseLocationProps) => {
  return (
    <div>
      <LocationTypes
        value={locationType[qrCode]}
        setValue={setLocationType}
        index={qrCode.toString()}
      />

      {locationType[qrCode] === 'SHELF' ||
      locationType[qrCode] === undefined ? (
        <Autocomplete
          disablePortal
          disableClearable
          id="chooseShelf"
          options={shelvesDropdownData}
          sx={{ width: '100%' }}
          value={value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_event, newValue) => handleChange(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Choose shelf" variant="outlined" />
          )}
        />
      ) : (
        <Autocomplete
          disablePortal
          disableClearable
          id="chooseBin"
          options={binsDropdownData}
          sx={{ width: '100%' }}
          value={value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(_event, newValue) => handleChange(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Choose bin" variant="outlined" />
          )}
        />
      )}
    </div>
  );
};
