import styled from '@emotion/styled';
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { PriceCalculationRoundingType } from '@tyrio/dto';
import { useGetDeliveryMethods } from '../../../../pages/dashboard/delivery-methods/querys/get-delivery-methods';
import { DBDeliveryTypes } from '@prisma/client';
import { useTranslation } from 'react-i18next';

interface PriceCalculationSettingsProps {
  price: PriceCalculationRoundingType | '';
  setPrice: (val: PriceCalculationRoundingType | '') => void;
  pricePerHour: number | null;
  setPricePerHour: (val: number) => void;
  defaultDeliveryType?: string;
  setDefaultDeliveryType: (val: string) => void;
}

const PriceCalculationSettings = (props: PriceCalculationSettingsProps) => {
  const {
    price,
    setPrice,
    pricePerHour,
    setPricePerHour,
    defaultDeliveryType,
    setDefaultDeliveryType,
  } = props;

  const { t } = useTranslation();
  const onChange = (val: PriceCalculationRoundingType) => {
    setPrice(val);
  };

  const { deliveryMethodData } = useGetDeliveryMethods();

  const formDropdownData = (data?: DBDeliveryTypes[]) => {
    if (!data) return [];

    return data
      .filter((item) => item.isActive)
      .map((d) => {
        return { label: d.name, value: d.id };
      });
  };

  const priceCalculationRoundingTypes = [
    {
      key: 'lower',
      label: 'Use lower price',
    },
    {
      key: 'average',
      label: 'Use average price',
    },
    {
      key: 'higher',
      label: 'Use higher price',
    },
  ];

  return (
    <Container>
      <Grid>
        <PageTitle>{t('General settings')}</PageTitle>
        <SettingContainer>
          <FormControl>
            <FormLabel>Price calculation settings on stock in</FormLabel>
            <RadioGroup defaultValue={price}>
              {priceCalculationRoundingTypes.map((item) => {
                return (
                  <FormControlLabel
                    value={item.key}
                    control={<Radio />}
                    label={item.label}
                    onChange={() => {
                      onChange(item.key as PriceCalculationRoundingType);
                    }}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Services settings:</FormLabel>
            <TextField
              label="Price per hour"
              sx={{ width: '50%', marginTop: '12px' }}
              onChange={({ target: { value } }) => {
                setPricePerHour(parseFloat(value));
              }}
              type="number"
              value={pricePerHour}
            />
          </FormControl>

          <FormControl>
            <InputLabel id="page-size-select-label">
              Default delivery type for transfer orders:
            </InputLabel>
            <Select
              fullWidth
              labelId="page-size-select-label"
              id="page-size-select"
              label="Default delivery type for transfer orders:"
              value={defaultDeliveryType}
              onChange={({ target: { value } }) => {
                setDefaultDeliveryType(value);
              }}
              style={{ width: '50%' }}
            >
              {formDropdownData(deliveryMethodData).map((item) => {
                return (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </SettingContainer>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const PageTitle = styled.h1`
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #212b36;
  margin-bottom: 20px;
  width: 100%;
`;

const SettingContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  gap: 25px;
`;

export default PriceCalculationSettings;
