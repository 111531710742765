import { TRule } from '../../typings';

export const MICHELIN_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EANUCC ARTICLE ID']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['QUANTITY VALUE']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['SUPPLIERS ARTICLE ID']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
];
