import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface DisplayTimeShape {
  [key: string]: {
    minutes: number;
    seconds: number;
  };
}

export type StorageType = '@TYR_INT' | '@TYR_POS_INT' | '';

export type TimerContextShape = {
  startTimer: (storageType: StorageType, a?: boolean) => void;
  clearTimer: (storageType: StorageType) => void;
  timer: React.MutableRefObject<NodeJS.Timeout | null>;
  displayTime: DisplayTimeShape;
  restartTimer: (storageType: StorageType) => void;
  getDisplayTime: (storageType: StorageType) => {
    minutes: number;
    seconds: number;
  } | null;
};

export const TimerContext = React.createContext<TimerContextShape>({
  startTimer: () => null,
  clearTimer: () => null,
  timer: { current: null },
  displayTime: {},
  restartTimer: () => null,
  getDisplayTime: () => null,
});

const storeIntervalStorage = (ms: number, storageType: StorageType) =>
  window.localStorage.setItem(storageType, `${ms}`);

const removeIntervalStorage = (storageType: StorageType) =>
  window.localStorage.removeItem(storageType);

const readIntervalStorage = (storageType: StorageType) => {
  const ms = window.localStorage.getItem(storageType);
  if (ms) return Number(ms);
  return null;
};

const TEN_MINUTES = 1000 * 60 * 10;
// const TEN_MINUTES = 20000;

export const useTimerContextData = (
  setOpenSessionExpiredModal: Dispatch<SetStateAction<boolean>>
): TimerContextShape => {
  const [displayTime, setDisplayTime] = useState<DisplayTimeShape>({
    '@TYR_INT': {
      minutes: 10,
      seconds: 0,
    },
    '@TYR_POS_INT': {
      minutes: 10,
      seconds: 0,
    },
  });

  const [type, setType] = useState<StorageType>('');

  const timer = useRef<NodeJS.Timeout | null>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const timerAutoStarted = useRef(false);

  const leftoverTimerTime = useRef(TEN_MINUTES);

  const startTimer = useCallback(
    (storageType: StorageType, continueTimer?: boolean) => {
      setType(storageType);
      if (!continueTimer) {
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = null;
        }
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
          removeIntervalStorage(storageType);
        }
      }
      interval.current = setInterval(
        () =>
          setDisplayTime((prevState: DisplayTimeShape) => {
            let newSeconds = prevState[storageType].seconds - 1;
            let newMinutes = prevState[storageType].minutes;

            if (newSeconds === -1) {
              newSeconds = 59;
              newMinutes = prevState[storageType].minutes - 1;
              if (newMinutes < 0) newMinutes = 0;
            }
            storeIntervalStorage(
              newMinutes * 1000 * 60 + newSeconds * 1000,
              storageType
            );
            // storeIntervalStorage(
            //   newMinutes * 20000 + newSeconds * 1000,
            //   storageType
            // );
            const timerInStorage = readIntervalStorage(storageType);

            if (timerInStorage) {
              leftoverTimerTime.current = timerInStorage;
            }
            if (timerInStorage === 1000) {
              timer.current = setTimeout(() => {
                setOpenSessionExpiredModal(true);

                removeIntervalStorage(storageType);
                if (interval.current) clearInterval(interval.current);
                timerAutoStarted.current = false;
              }, leftoverTimerTime.current);
            }
            return {
              ...prevState,
              [storageType]: {
                minutes: newMinutes,
                seconds: newSeconds,
              },
            };
          }),
        1000
      );
    },
    [setOpenSessionExpiredModal]
  );

  const clearTimer = useCallback((storageType: StorageType) => {
    setType(storageType);
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
      removeIntervalStorage(storageType);
    }
    timerAutoStarted.current = false;
  }, []);

  function restartTimer(storageType: StorageType) {
    setType(storageType);
    storeIntervalStorage(TEN_MINUTES, storageType);
    const timerInStorage = readIntervalStorage(storageType);
    if (timerInStorage) {
      leftoverTimerTime.current = timerInStorage;

      const minutes = parseInt(`${leftoverTimerTime.current / 1000 / 60}`, 10);
      const seconds = leftoverTimerTime.current / 1000 - minutes * 60;

      setDisplayTime((prevState: DisplayTimeShape) => ({
        ...prevState,
        [storageType]: {
          minutes,
          seconds,
        },
      }));

      if (!timerAutoStarted.current) {
        startTimer(storageType, true);
        timerAutoStarted.current = true;
      }
    }
  }

  function getDisplayTime(storageType: StorageType) {
    return displayTime[storageType];
  }

  useEffect(() => {
    const timerInStorage = readIntervalStorage(type);
    if (timerInStorage) {
      leftoverTimerTime.current = timerInStorage;

      const minutes = parseInt(`${leftoverTimerTime.current / 1000 / 60}`, 10);
      const seconds = leftoverTimerTime.current / 1000 - minutes * 60;

      setDisplayTime((prevState: DisplayTimeShape) => ({
        ...prevState,
        [type]: {
          minutes,
          seconds,
        },
      }));

      if (!timerAutoStarted.current) {
        startTimer(type, true);
        timerAutoStarted.current = true;
      }
    }
  }, [startTimer, type]);

  return {
    startTimer,
    clearTimer,
    timer,
    displayTime,
    restartTimer,
    getDisplayTime,
  };
};

export const useCartTimer = () => useContext(TimerContext);
