import styled from '@emotion/styled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';
import { SSE_ENTITY_KEYS } from '@tyrio/dto';
import { useHistory } from 'react-router-dom';
import { useWS } from '../../../context/WSContext';

export const OrderSidebarLayoutButtons = (
  orderId: string,
  closeOrder: () => void,
  handleOnCancel: () => void,
  handleSave: () => void,
  isButtonDisabled: boolean,
  _handleNextStep?: (step: string) => void,
  cancelText?: string,
  handlePrint?: () => void
) => {
  const history = useHistory();
  const { state, socket } = useWS();

  const buttons = {
    customer: (
      <>
        <Button
          variant="text"
          color="error"
          onClick={() => handleOnCancel()}
          disabled={isButtonDisabled}
        >
          {cancelText ?? 'Cancel'}
        </Button>

        <ButtonWrapper>
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            color="info"
            id="process_order_button"
            onClick={() => {
              closeOrder();

              const entity =
                state.locks &&
                state.locks[`${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${orderId}`];

              if (!entity)
                socket?.emit('lock', {
                  entityName: SSE_ENTITY_KEYS.CUSTOMER_ORDER,
                  entityId: orderId,
                });

              history.push(`sales-orders/${orderId}`);
            }}
            style={{ gap: '16px' }}
            disableElevation
          >
            Process the order
            <ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />
          </Button>
        </ButtonWrapper>
      </>
    ),
    purchase: (
      <>
        <Wrapper>
          <Button
            variant="text"
            color="error"
            onClick={handleOnCancel}
            disabled={isButtonDisabled}
          >
            Cancel
          </Button>
          <Button variant="outlined" color="info" onClick={handlePrint}>
            PRINT BARCODE
          </Button>
        </Wrapper>
        <Button
          variant="contained"
          color="info"
          onClick={handleSave}
          disabled={isButtonDisabled}
          disableElevation
        >
          Save
        </Button>
      </>
    ),
  };
  return buttons;
};

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;
