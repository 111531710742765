import { ReactComponent as cart } from '../assets/menu-icons/cart_icon.svg';
import { ReactComponent as details } from '../assets/menu-icons/details_icon.svg';
import { ReactComponent as list } from '../assets/menu-icons/list_icon.svg';
import { ReactComponent as branches } from '../assets/menu-icons/branches_icon.svg';
import { ReactComponent as print } from '../assets/menu-icons/print_icon.svg';
import { ReactComponent as search } from '../assets/menu-icons/search_icon.svg';
import { ReactComponent as close } from '../assets/icons/close.svg';
import { ReactComponent as check_mark } from '../assets/icons/check_mark.svg';
import { ReactComponent as bin } from '../assets/icons/Trash.svg';
import { ReactComponent as hourglass } from '../assets/icons/hourglass.svg';
import { ReactComponent as arrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as location } from '../assets/icons/location.svg';
import { ReactComponent as locationL } from '../assets/icons/locationL.svg';
import inboundOrder from '../assets/menu-icons/inbound_order.svg';
import { ReactComponent as outbound } from '../assets/menu-icons/outbound_order.svg';
import { ReactComponent as inbound } from '../assets/icons/inbound.svg';
import { ReactComponent as document } from '../assets/icons/carbon_document.svg';
import { ReactComponent as truck } from '../assets/icons/truck.svg';
import { ReactComponent as expandIcon } from '../assets/icons/popover/expand.svg';
import { ReactComponent as car } from '../assets/menu-icons/car.svg';

export {
  cart,
  details,
  list,
  branches,
  print,
  search,
  close,
  check_mark,
  bin,
  hourglass,
  arrowRight,
  location,
  locationL,
  inbound,
  outbound,
  document,
  truck,
  inboundOrder,
  expandIcon,
  car,
};
