import { TRule } from '../../typings';

export const ZPERFORMANCE_PRICELIST_RULESET: TRule[] = [
  // {
  //   type: 'rule.map',
  //   sourceKey: "c.['EANCode']",
  //   destination: 'product',
  //   targetKey: 'ean',
  // },
  {
    type: 'rule.map',
    sourceKey: "c.['Articlenumber']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Producer']",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.["Dealer price"]',
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
