import * as z from "zod"
import { CompleteDBClientShippingCourier, RelatedDBClientShippingCourierModel } from "./index"

export const DBShippingCourierModel = z.object({
  id: z.string(),
  name: z.string(),
  abbreviation: z.string(),
  shippingWebsite: z.string(),
})

export interface CompleteDBShippingCourier extends z.infer<typeof DBShippingCourierModel> {
  DBClientShippingCourier: CompleteDBClientShippingCourier[]
}

/**
 * RelatedDBShippingCourierModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBShippingCourierModel: z.ZodSchema<CompleteDBShippingCourier> = z.lazy(() => DBShippingCourierModel.extend({
  DBClientShippingCourier: RelatedDBClientShippingCourierModel.array(),
}))
