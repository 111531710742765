import { Prisma } from '@prisma/client';
import { DBTransferOrderStatusEnum } from '@tyrio/dto';

interface OrderStatus {
  status: DBTransferOrderStatusEnum;
  subStatus: string;
}

export function parseStatus(json: Prisma.JsonValue) {
  return json as unknown as OrderStatus;
}
