import { useAuth } from '../../context/AuthContext';
import RoleUpsertForm from '../../features/role-form/RoleUpsertForm';

const RoleForm = () => {
  const { user, permissionList } = useAuth();
  const clientId = user?.clientId;

  return (
    <RoleUpsertForm
      clientId={clientId ?? ''}
      permissions={permissionList.settings?.roles}
    />
  );
};

export default RoleForm;
