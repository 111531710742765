import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import styled from '@emotion/styled';
import api from '@tyrio/api-factory';
import { DBSupplierApi } from '@tyrio/dto';
import { RULESET_KEYS } from '@tyrio/shared-vars';

export interface CatalogImportPayload {
  supplierShape: string;
  supplierId: string;
  connectionId: string;
}

interface CatalogSidebarProps {
  onCatalogImport: (data: CatalogImportPayload) => void;
}
export const CatalogSidebar = ({ onCatalogImport }: CatalogSidebarProps) => {
  const [supplierShape, setSupplierShape] = useState<string | null>(null);
  const [supplierId, setSupplierId] = useState<string | null>(null);
  const [connectionId, setConnectionId] = useState<string | null>(null);

  const { data: suppliersList, isLoading: fetchingSuppliersList } = useQuery(
    ['admin_suppliers'],
    () => api.fetch<DBSupplierApi['list']>('suppliers')
  );

  const {
    data: supplierDetails,
    isLoading: isLoadingSupplierDetails,
    mutate: getSupplierDetails,
  } = useMutation(['get_supplier_id', supplierId], (id: string) =>
    api.fetch<DBSupplierApi['getOne']>(`supplier_id`, {
      id,
    })
  );

  useEffect(() => {
    if (supplierId) {
      getSupplierDetails(supplierId);
    }
  }, [getSupplierDetails, supplierId]);

  const ruleset = RULESET_KEYS as Record<string, string>;
  return (
    <div>
      <Typography variant="h5" gutterBottom={true}>
        <strong>Catalog import configuration</strong>
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            1. Select supplier configuration
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="supplier_config_select">
              Supplier Configuration
            </InputLabel>
            <Select
              labelId="supplier_config_select"
              id="supplier_config_select"
              value={supplierShape}
              label="Supplier Configuration"
              onChange={(e) => setSupplierShape(e.target?.value)}
            >
              {Object.keys(ruleset).map((k) => (
                <MenuItem key={k} value={ruleset[k]}>
                  {k}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom={true} variant="subtitle1">
            2. Select supplier
          </Typography>

          {fetchingSuppliersList && (
            <Centered>
              <CircularProgress />
            </Centered>
          )}
          <FormControl fullWidth>
            <InputLabel id="supplier_select">Supplier</InputLabel>
            <Select
              labelId="supplier_select"
              id="supplier_select"
              disabled={!suppliersList}
              value={supplierId}
              label="Supplier"
              onChange={(e) => setSupplierId(e.target?.value)}
            >
              {suppliersList?.data.map((s) => (
                <MenuItem value={s.id}>{s.companyShortName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom={true} variant="subtitle1">
            3. Choose connection
          </Typography>
          {isLoadingSupplierDetails && (
            <Centered>
              <CircularProgress />
            </Centered>
          )}
          <FormControl fullWidth>
            <InputLabel id="supplier_connection_select">
              Supplier Connection
            </InputLabel>
            <Select
              labelId="supplier_connection_select"
              id="supplier_connection_select"
              disabled={
                !supplierDetails || !supplierDetails?.supplierConnections
              }
              value={connectionId}
              label="Supplier Connection"
              onChange={(e) => setConnectionId(e.target?.value)}
            >
              {supplierDetails?.supplierConnections?.map((c) => (
                <MenuItem value={c.id}>
                  [{c.connectionProtocol}] {c.connectionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            disabled={!supplierId || !connectionId || !supplierShape}
            onClick={() => {
              if (supplierId && supplierShape && connectionId)
                onCatalogImport({ supplierId, supplierShape, connectionId });
            }}
          >
            Import
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const Centered = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
