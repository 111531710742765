import React, { useContext, useEffect } from 'react';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { PriceComparisonContext } from './context';

export const SwitchHeaders = () => {
  const ctx = useContext(PriceComparisonContext);

  useEffect(() => {
    ctx.setInputValue({
      includeEcoTax: true,
      includeDeliveryCost: true,
    });
    //It is empty array because we want this useEffect runs only once, so we can set default values to ecoTax and deliveryCost switch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="info"
              onClick={() =>
                ctx.setInputValue({ showCode: !ctx.data.showCode })
              }
              checked={!!ctx.data.showCode}
              value={!!ctx.data.showCode}
              id="showCode"
            />
          }
          label="Show code"
        />
        <FormControlLabel
          control={
            <Switch
              color="info"
              onClick={() =>
                ctx.setInputValue({
                  showStockZero: !ctx.data.showStockZero,
                })
              }
              checked={ctx.data.showStockZero}
              id="showStockZero"
            />
          }
          label="Show STOCK 0"
        />
        <FormControlLabel
          control={
            <Switch
              color="info"
              onClick={() =>
                ctx.setInputValue({
                  showPercentages: !ctx.data.showPercentages,
                })
              }
              checked={ctx.data.showPercentages}
              id="showPercentages"
            />
          }
          label="Show percentages"
        />
        <FormControlLabel
          control={
            <Switch
              color="info"
              onClick={() =>
                ctx.setInputValue({
                  includeEcoTax: !ctx.data.includeEcoTax,
                })
              }
              checked={ctx.data.includeEcoTax}
              id="includeEcoTax"
            />
          }
          label="Include ECO tax"
        />
        <FormControlLabel
          control={
            <Switch
              color="info"
              onClick={() =>
                ctx.setInputValue({
                  includeDeliveryCost: !ctx.data.includeDeliveryCost,
                })
              }
              checked={ctx.data.includeDeliveryCost}
              id="includeDeliveryCost"
            />
          }
          label="Include delivery cost"
        />
        <FormControlLabel
          control={
            <Switch
              color="info"
              onClick={() =>
                ctx.setInputValue({
                  includeBonus: !ctx.data.includeBonus,
                })
              }
              checked={ctx.data.includeBonus}
              id="includeBonus"
            />
          }
          label="Include bonus"
        />
      </Grid>
    </Grid>
  );
};
