import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { Wrapper } from '../Details/Details.style';
import { WheelDetails } from '../../components/WheelsSearch/WheelDetails';
import { AddItemsFooter } from '../../components/AddItemsFooter';
import { useVehicleCtx } from '../../../../context/VehicleContext';

export const WheelDetailStep = () => {
  const { selectedProduct } = useVehicleCtx();

  const isCartVisible = selectedProduct !== null ? true : false;

  const renderDetails = () => {
    return (
      <Wrapper>
        <WheelDetails />
      </Wrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posDetails"
      children={renderDetails()}
      showSwitch={false}
      checked={false}
      shouldShowTitle={false}
      footer={isCartVisible ? <AddItemsFooter /> : null}
    />
  );
};
