import { DBServiceCategoryApi, DBServicesApi } from '@tyrio/dto';
import { keys } from 'lodash';

export const InitialValues = (
  isNew: boolean,
  isServiceCategory: boolean,
  selectedProductCategoryId?: string,
  serviceData?: DBServicesApi['getOne']['response'],
  serviceCategoryData?: DBServiceCategoryApi['getOne']['response']
) => {
  if (isNew) {
    if (isServiceCategory)
      return {
        code: '',
        category: '',
        name: '',
        description: '',
        vatRate: '',
        unitOfMeasurment: '',
        generalLedgeAcc: '',
      };
    else
      return {
        code: '',
        category: '',
        name: '',
        description: '',
        erpId: '',
        radioButton: '',
        servicePrice: {},
        serviceDuration: '',
        productCategoryId: '',
        subCategoryId: [],
        rimDiameter: [],
        rft: false,
      };
  }

  if (serviceData) {
    return {
      code: serviceData?.code,
      category: serviceData?.serviceCategoryId,
      name: serviceData?.name,
      description: serviceData?.description,
      erpId: serviceData?.erpId,
      radioButton: keys(serviceData?.servicePrice)[0],
      servicePrice: serviceData?.servicePrice,
      serviceDuration: serviceData?.serviceDuration,
      productCategoryId:
        selectedProductCategoryId ?? serviceData?.productCategoryId?.toString(),
      subCategoryId: serviceData?.subcategory,
      rimDiameter: serviceData?.rimDiameter,
      rft: serviceData?.rft,
    };
  }

  if (serviceCategoryData) {
    return {
      code: serviceCategoryData?.code,
      category: '',
      name: serviceCategoryData?.name,
      description: serviceCategoryData?.description ?? '',
      vatRate: serviceCategoryData?.vatRate,
      unitOfMeasurment: serviceCategoryData?.unitOfMeasurment,
      generalLedgeAcc: serviceCategoryData?.generalLedgeAcc,
    };
  }

  return {};
};
