/* eslint-disable @typescript-eslint/no-explicit-any */
import { DBClient } from '@prisma/client';
import { FtpConnectionDetail } from '@tyrio/dto';
import { getCurrentDate } from '../../../../../helpers/date-helper';
import OrderDetailsGenerator from '../OrderDetailsGenerator';

export const getFtpData = (data: any, client: DBClient | null | undefined) => {
  const products = data.lineItems;
  const clientName = client?.officialName ?? '';
  const branchDetails = {
    address:
      data.deliveryAddress.address_line_1 +
        ' - ' +
        data.deliveryAddress.address_line_2 ?? '',
    zipCode: data.deliveryAddress.zipCode ?? '',
    city: data.deliveryAddress.city ?? '',
    mobilePhone: '' ?? '',
  };
  const emailContent = OrderDetailsGenerator({
    products,
    clientName,
    branchDetails,
  });
  const blob = new Blob([emailContent]);
  const formData = new FormData();
  const fileName = `ORDER - ${clientName} - ${getCurrentDate()}`;
  const file = new File([blob], fileName);
  formData.append('file', file, fileName);
  formData.append(
    'body',
    JSON.stringify({
      host: (data.connection as FtpConnectionDetail).connectionDetails.ftpHost,
      fileLocation: (data.connection as FtpConnectionDetail).connectionDetails
        .fileName,
      ftpPort: 21,
      username: (data.connection as FtpConnectionDetail).connectionDetails
        .username,
      password: (data.connection as FtpConnectionDetail).connectionDetails
        .password,
    })
  );

  return formData;
};
