import * as z from "zod"
import { DBDeliveryCustomerOrdersTypeEnum, DBDeliveryType, DeliveryType, DBDeliveryLocation } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBClientDeliveryCouriers, RelatedDBClientDeliveryCouriersModel, CompleteDBTransferOrderItem, RelatedDBTransferOrderItemModel, CompleteDBDeliveryZone, RelatedDBDeliveryZoneModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBDeliveryTypesModel = z.object({
  id: z.string(),
  clientId: z.string(),
  code: z.string(),
  name: z.string(),
  isActive: z.boolean(),
  isTyrioDefault: z.boolean(),
  carrierIntegration: z.nativeEnum(DBDeliveryCustomerOrdersTypeEnum).nullish(),
  deliveryType: z.nativeEnum(DBDeliveryType).nullish(),
  deliveryTypeEnum: z.nativeEnum(DeliveryType).nullish(),
  sortOrder: z.string().nullish(),
  description: z.string().nullish(),
  deliveryLocation: z.nativeEnum(DBDeliveryLocation).nullish(),
  defaultSettings: jsonSchema,
  defaultPrices: jsonSchema,
  defaultSurcharges: jsonSchema,
})

export interface CompleteDBDeliveryTypes extends z.infer<typeof DBDeliveryTypesModel> {
  client: CompleteDBClient
  orders: CompleteDBCustomerOrderItem[]
  dbDeliveryCouriers: CompleteDBClientDeliveryCouriers[]
  DBTransferOrderItem: CompleteDBTransferOrderItem[]
  zones: CompleteDBDeliveryZone[]
}

/**
 * RelatedDBDeliveryTypesModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBDeliveryTypesModel: z.ZodSchema<CompleteDBDeliveryTypes> = z.lazy(() => DBDeliveryTypesModel.extend({
  client: RelatedDBClientModel,
  orders: RelatedDBCustomerOrderItemModel.array(),
  dbDeliveryCouriers: RelatedDBClientDeliveryCouriersModel.array(),
  DBTransferOrderItem: RelatedDBTransferOrderItemModel.array(),
  zones: RelatedDBDeliveryZoneModel.array(),
}))
