import styled from '@emotion/styled/macro';
import CancelIcon from '@mui/icons-material/Cancel';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  addDocument,
} from '@tyrio/ui-library';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { WarehouseContext } from '../../../context/WarehouseContext';
import Location from '../components/location/Location';
import { bytesToSize, getCurrent, replaceFromStart } from '../helper/helper';
import { useUploadWarehouse } from '../query/upload-warehouse';
import { useGetWarehouseBranches } from '../query/warehouse-branches';
import { Container } from './style/styled';
import { useCreateVirtualVirtual } from '../query/create-virtual-location';

export const Upload = () => {
  const [branch, setBranch] = useState<number | undefined | null>();

  const { warehouseBranches } = useGetWarehouseBranches();
  const { mutate: createVirtual, data: virtualData } =
    useCreateVirtualVirtual();

  const { warehouse, setWarehouse, setActiveStep, selected, clearSelected } =
    useContext(WarehouseContext);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    maxSize: 25 * 1024 * 1024,
  });

  const { mutate, data } = useUploadWarehouse();

  const save = (isPreview: boolean) => {
    if (!branch) {
      ToastHelper.showToast(
        'Import warehouse',
        ToastType.ERROR,
        ToastMessageType.CREATE,
        'Branch is missing, try again!'
      );
      return;
    }

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', file, file.name);
      formData.append(
        'body',
        JSON.stringify({
          branchId: branch,
          isPreview: isPreview,
        })
      );
    });
    mutate(formData);
  };

  return (
    <Container>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Grid>
          {_.isEmpty(acceptedFiles) && (
            <Grid>
              <Grid>
                {warehouseBranches.status === 'success' &&
                  warehouseBranches.data && (
                    <FormControl fullWidth>
                      <InputLabel>Branch</InputLabel>
                      <Select
                        value={branch}
                        label="Branch"
                        onChange={(e) => {
                          setBranch(Number(e.target.value));
                        }}
                      >
                        {warehouseBranches.data
                          .filter((item) =>
                            item.branchType.includes('WAREHOUSE')
                          )
                          .map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.branchName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
              </Grid>
              {branch &&
                warehouseBranches &&
                warehouseBranches.data &&
                warehouseBranches.data.filter((item) =>
                  item.branchType.includes('WAREHOUSE')
                ).length > 0 && (
                  <Grid>
                    <SelectDocumentWrapper disabled={false}>
                      <input style={{ display: 'none' }} {...getInputProps()} />
                      <ImgWrapper>
                        <Image
                          src={addDocument}
                          alt="add-document-icon"
                          onClick={open}
                        />
                      </ImgWrapper>
                      <TextWrapper>
                        <SelectDocumentText>
                          Upload warehouse
                        </SelectDocumentText>
                        <DropFilesText>
                          <div>Drop files or </div>
                          <BrowseTextWrapper {...getRootProps()}>
                            browse
                          </BrowseTextWrapper>
                          <div>through your machine</div>
                        </DropFilesText>
                      </TextWrapper>
                    </SelectDocumentWrapper>
                    <Divider sx={{ marginTop: '10px', marginBottom: '10px' }}>
                      <Chip label="OR" size="small" />
                    </Divider>
                    <Button
                      fullWidth
                      color="info"
                      size="large"
                      variant="contained"
                      onClick={() => {
                        createVirtual(branch);
                        if (virtualData) setActiveStep('WAREHOUSE');
                      }}
                    >
                      Create VIRTUAL location
                    </Button>
                  </Grid>
                )}
            </Grid>
          )}
          {acceptedFiles[0] && _.isEmpty(warehouse) && (
            <Grid>
              <PriceTypeContainer>
                <PriceTypeTypography>IMPORT INFO</PriceTypeTypography>
                <ItemContainer>
                  <Item>BRANCH</Item>
                  <Typography>
                    {warehouseBranches.data?.find((item) => item.id === branch)
                      ?.branchName ?? (
                      <strong style={{ color: 'red' }}>
                        BRANCH IS MISSING
                      </strong>
                    )}
                  </Typography>
                </ItemContainer>
                <ItemContainer>
                  <Item>DOCUMENT NAME</Item>
                  <Typography>{acceptedFiles[0].name}</Typography>
                </ItemContainer>
                <ItemContainer>
                  <Item>SIZE</Item>
                  <Typography>{bytesToSize(acceptedFiles[0].size)}</Typography>
                </ItemContainer>
                <ItemContainer>
                  <Item>LAST MODIFIED</Item>
                  <Typography>
                    {moment(acceptedFiles[0].lastModified).format(
                      'DD.MM.YYYY HH:mm'
                    )}
                  </Typography>
                </ItemContainer>
              </PriceTypeContainer>
            </Grid>
          )}
          {!selected.locations.zone && !_.isEmpty(warehouse) && (
            <Alert severity="info" sx={{ marginTop: '10px' }}>
              <AlertTitle>No selected location</AlertTitle>
              Please select zone to see location details
            </Alert>
          )}
          {acceptedFiles[0] && _.isNull(warehouse) && (
            <Button
              fullWidth
              variant="contained"
              color="info"
              startIcon={<WarehouseIcon />}
              sx={{ marginTop: '10px' }}
              onClick={() => {
                save(true);
              }}
            >
              PREVIEW WAREHOUSE
            </Button>
          )}
          {selected.locations.zone && (
            <Grid>
              <Location
                location={replaceFromStart(
                  getCurrent(selected, 'displayName'),
                  getCurrent(selected, 'displayName').length
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid>
          {acceptedFiles[0] && !_.isNull(warehouse) && (
            <Grid sx={{ display: 'flex', gap: '10px' }}>
              <Button
                fullWidth
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                sx={{ marginTop: '10px' }}
                onClick={() => {
                  setActiveStep('UPLOAD');
                  setWarehouse(null);
                  clearSelected();
                  acceptedFiles.shift();
                }}
              >
                DISCARD
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="success"
                startIcon={<WarehouseIcon />}
                sx={{ marginTop: '10px' }}
                onClick={() => {
                  save(false);
                  if (data) setActiveStep('WAREHOUSE');
                }}
              >
                SAVE WAREHOUSE
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const SelectDocumentWrapper = styled.div<{ disabled: boolean }>`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  border-style: dashed;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  position: sticky;
  top: 0;
  margin-top: 10px;
`;

const ImgWrapper = styled.div`
  padding-top: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
`;

const SelectDocumentText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 137.5%;
`;

const DropFilesText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6b7280;
  display: flex;
  gap: 5px;
`;

const BrowseTextWrapper = styled.div`
  border-bottom: 1px solid #1652f7;
  padding-bottom: 0;
  color: #1652f7;
  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img``;

const PriceTypeContainer = styled.div`
  background-color: #ecf0f4;
  padding: 12px;
  border-radius: 8px;
`;

const PriceTypeTypography = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const ItemContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Item = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #454f5b;
`;
