/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { CheckPriceRes, DBUserApi } from '@tyrio/dto';
import {
  QuantityComponent,
  SingleStorageItem,
  StockListHeader,
} from '@tyrio/wms-ui-library';
import { has } from 'lodash';
import { useCallback, useContext } from 'react';
import { AddedItemsButton } from '../../../../components/Button/AddedItemsButton';
import NoItemsFound from '../../../../components/NoItems/NoItemsFound';
import { useAuth } from '../../../../context/AuthContext';
import { CartContext } from '../../../../context/CartContext';
import { StockListContext } from '../../../../context/StockListContext';
import { useWS } from '../../../../context/WSContext';
import { CartToast, customToast } from '../Cart/CartToast';
import { useCartData } from '../Cart/helper/cart-helper';
import { getReservedAndUpcomingValues } from '../Cart/helper/socket-helper';

export type User = DBUserApi['getOne']['response'];
export interface ProductInCartProps {
  id: string;
  quantity: number;
}
export interface ItemProps {
  stockListItemId: string;
  reserved: number;
  quantity: number;
  productionYear: string | null;
  productName: string;
  ean: string;
  sku: string;
  manufacturerCode: string;
  dot?: string;
  uid?: string;
  price: number | null;
  calculatedPrice: Record<string, Partial<CheckPriceRes>>;
}
export interface SingleStorageProps {
  branchId: number;
  branchName: string;
  branchCity?: string;
  item: ItemProps[];
  stockLocationId: string;
}

export const SingleStorage = (props: SingleStorageProps) => {
  const ws = useWS();

  const { user } = useAuth();
  const { numberOfCartItems } = useCartData();

  const { branchName, branchId, item, branchCity } = props;

  const { setActiveDrawer, onlyAvailable, setOrderSent, selectedTableRow } =
    useContext(StockListContext);

  const { timer } = useContext(CartContext);

  const warehouseId = selectedTableRow?.branchId;

  // FUNCTIONS
  // ADD ITEM TO CART
  const handleClick = async (newQuantity: number, index: number) => {
    ws.socket?.emit('inbound-add-to-cart', {
      stockListItemId: item[index].stockListItemId,
      branchId: branchId,
      branchName: branchName,
      branchCity: branchCity,
      warehouseId: warehouseId,
      req: {
        quantity: newQuantity,
        ean: item[index].ean,
        ipc: item[index].manufacturerCode,
        sku: item[index].sku,
        dot: item[index].dot,
        uid: item[index].uid,
        productionYear: item[index].productionYear,
        productName: item[index].productName,
        createdAt: new Date(),
        price: item[index].price,
        calculatedPrice: item[index].calculatedPrice,
      },
    });

    customToast('Product added to cart!', 'success');
    timer?.restart();
    setOrderSent(false);
  };

  // DELETE FROM CART
  const handleDelete = async (itemId: string) => {
    ws.socket?.emit('inbound-remove-item-from-cart', {
      branchId: branchId,
      branchName: branchName,
      branchCity: branchCity,
      warehouseId: warehouseId,
      itemId: itemId,
    });

    customToast('Product removed from cart!', 'success');
    if (numberOfCartItems > 1) timer?.restart();
    else timer?.clear();
  };

  const isProductInCart = (ean_year: string, branch: string) => {
    let itIs = false;
    let qty = 0;

    if (ws.state.cart?.userCart[(user as User)?.id] !== undefined)
      itIs = has(
        ws?.state?.cart?.userCart[(user as User)?.id]?.inbound[branch as any],
        ean_year
      );

    if (itIs) {
      qty = (
        ws?.state?.cart?.userCart[(user as User)?.id]?.inbound[branch as any][
          ean_year
        ] as any
      ).quantity;
    }

    return { itIs, qty };
  };

  const shouldRenderRow = (available: number) => {
    return !onlyAvailable || (onlyAvailable && available > 0);
  };

  const getValues = useCallback(
    (key: string) => {
      const cartItems = ws?.state.cart?.userCart;

      const s = key.split('_');
      const { reserved } = getReservedAndUpcomingValues(
        cartItems,
        s[0].concat('_', s[1]),
        Number(s[2])
      );

      return reserved;
    },
    [ws]
  );

  const shouldRenderItems =
    item.filter((item) => item.quantity - item.reserved > 0).length === 0 &&
    onlyAvailable;

  return (
    <Wrapper>
      <StockListHeader branchName={branchName} onlyAvailable={onlyAvailable} />
      <Divider />
      <CartToast />
      {!shouldRenderItems ? (
        item.map((p, index) => {
          const key = p.ean + '_' + p.dot + '_' + branchId;

          const reserved = getValues(key) + p.reserved;
          const available = p.quantity - reserved;

          const showFlag =
            p.quantity === 0 && p.reserved === 0 && available === 0;

          const { itIs: isProductInCart1, qty } = isProductInCart(
            key,
            branchId + '_' + branchName + '_' + branchCity + '_' + warehouseId
          );

          return (
            !showFlag &&
            shouldRenderRow(available) && (
              <SingleStorageItem
                onStock={p.quantity}
                reserved={reserved > 0 ? reserved : p.reserved}
                available={available < 0 ? 0 : available}
                component={
                  isProductInCart1 ? (
                    <AddedItemsButton
                      quantity={qty}
                      onClick={() => handleDelete(key)}
                    />
                  ) : (
                    <QuantityComponent
                      quantity={0}
                      index={index}
                      isDisabled={available === 0}
                      handleClick={handleClick}
                      maxAvailableQuantity={available < 0 ? 0 : available}
                      cart={false}
                      key={p.stockListItemId}
                    />
                  )
                }
                year={p.dot ?? ''}
                chipColor={
                  parseInt(p.productionYear ?? '') <= 2018 ? 'error' : 'success'
                }
                key={index}
                setActiveDrawer={setActiveDrawer}
                onlyAvailable={onlyAvailable}
              />
            )
          );
        })
      ) : (
        <NoItemsFound />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const StockWrapper = styled.div<{ onlyAvailable: boolean }>`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  align-items: flex-end;
  max-width: ${(props) => (props.onlyAvailable ? '80px' : '320px')};
  width: 100%;
  justify-content: space-around;
`;
