import React from 'react';
import styled from '@emotion/styled';

interface Props {
  price: number | null;
  quantity: number | null;
}

export const RenderFooterCell = ({ price, quantity }: Props) => {
  const safePrice = price || 0;

  return (
    <Wrapper>
      <Price>
        {safePrice.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </Price>

      <Qty>{quantity} pcs</Qty>
    </Wrapper>
  );
};

const Price = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #212b36;
  margin-bottom: 2px;
`;

const Qty = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #212b36;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
