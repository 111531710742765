import * as z from "zod"
import { DBClientApiKeyPurpose } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel } from "./index"

export const DBClientApiKeyModel = z.object({
  apiKey: z.string(),
  clientId: z.string(),
  createdAt: z.date(),
  expiresAt: z.date(),
  isActive: z.boolean(),
  lastUsed: z.date().nullish(),
  lastPurpose: z.nativeEnum(DBClientApiKeyPurpose).nullish(),
})

export interface CompleteDBClientApiKey extends z.infer<typeof DBClientApiKeyModel> {
  client: CompleteDBClient
}

/**
 * RelatedDBClientApiKeyModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientApiKeyModel: z.ZodSchema<CompleteDBClientApiKey> = z.lazy(() => DBClientApiKeyModel.extend({
  client: RelatedDBClientModel,
}))
