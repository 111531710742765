export function getMarginType(val?: string | null) {
  if (val === '€' || val === 'EUR') {
    return '€';
  }
  if (val === '%' || val === 'PERCENTAGE') {
    return '%';
  }

  return '';
}

export const currency = '€';
