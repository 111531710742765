/* eslint-disable array-callback-return */

import {
  CartStockListItem,
  DBBranchStockListResponse,
  DBUserApi,
} from '@tyrio/dto';
import { useAuth } from '../../../../../context/AuthContext';
import { useWS } from '../../../../../context/WSContext';
import { compact, flatten, isEmpty, isEqual, keys } from 'lodash';
import { CartType } from '../FullCart';
import { CartItem } from './interfaces';

interface IWarehouse {
  warehouseId: number;
}
type OutboundOrderItemsType = DBBranchStockListResponse & IWarehouse;

export const formatDataForTransferOrder = (
  cartData: CartType[],
  selectedTableRowId: string
) => {
  const send = cartData.map((d: CartType) => {
    const branch = d[0].split('_');
    const branchCity = branch[2];
    const warehouseId = branch[3];
    let remark1 = '';

    const formLineItems = Object.entries(d[1]).map(([key, value]) => {
      if (key === 'remark') {
        remark1 = value;
        return;
      }
      if (value.quantity === 0) return;
      return {
        ean: value.ean,
        manufacturerCode: value.ipc,
        productName: value.productName,
        productionYear: value.productionYear,
        uid: value.uid,
        quantity: value.quantity,
        received: 0,
        sku: value.sku,
        connectedToItem: selectedTableRowId,
        dot: value.dot,
        price: value.price,
        calculatedPrice: value.calculatedPrice,
      };
    });

    const obj = {
      inboundBranchId: Number(warehouseId),
      outboundBranchId: Number(branch[0]),
      outboundBranchDetails: {
        branchName: branch[1],
        branchCity: branchCity,
        // TODDO: add zipCode
      },
      remark: remark1,
      lineItems: compact(formLineItems),
    };
    return obj;
  });

  return send;
};

// GET AND MANIPULATE ALL CART DATA FROM SOCKETS
export const useCartData = () => {
  const ws = useWS();
  const { user } = useAuth();
  const condition =
    ws.state !== null &&
    ws.state.cart !== undefined &&
    ws.state.cart !== null &&
    ws.state.cart?.userCart[(user as DBUserApi['getOne']['response'])?.id] !==
      undefined &&
    !isEmpty(ws.state.cart?.userCart);

  const otherUsersCart =
    ws.state !== null &&
    ws.state.cart !== undefined &&
    ws.state.cart !== null &&
    ws.state.cart?.userCart !== undefined
      ? Object.entries(ws.state.cart?.userCart).filter(
          ([key]) => key !== (user as DBUserApi['getOne']['response'])?.id
        )
      : [];

  const otherUsersInbound = [];
  const otherUsersOutbound = [];

  for (const data of otherUsersCart) {
    const a = Object.entries(data[1].inbound).map((a) => a);
    const b = Object.entries(data[1].outbound).map((a) => a);
    if (!isEmpty(a)) otherUsersInbound.push(...a);
    if (!isEmpty(b)) otherUsersOutbound.push(...b);
  }

  const newCartItems = condition
    ? Object.entries(
        ws.state.cart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
          ?.inbound
      ).map((a) => a)
    : [];

  const newCartOutboundData = condition
    ? Object.entries(
        ws.state.cart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
          ?.outbound
      )
    : [];

  const outboundValues = condition
    ? Object.values(
        ws.state.cart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
          ?.outbound
      )
    : [];

  const inboundValues = condition
    ? Object.values(
        ws.state.cart?.userCart[(user as DBUserApi['getOne']['response'])?.id]
          ?.inbound
      )
    : [];

  const modalData = [...inboundValues, ...outboundValues];

  let numberOfCartItems = 0;

  if (!isEmpty(newCartItems)) {
    const b = newCartItems.map((data) => {
      if (Object.hasOwn(data[1], 'remark')) return (keys(data[1]).length -= 1);
      else return keys(data[1]).length;
    });
    numberOfCartItems += b.reduce((a, b) => a + b, 0);
  }
  if (!isEmpty(newCartOutboundData)) {
    const a = newCartOutboundData.map((data) => {
      if (Object.hasOwn(data[1], 'remark')) return (keys(data[1]).length -= 1);
      else return keys(data[1]).length;
    });
    numberOfCartItems += a.reduce((a, b) => a + b, 0);
  }

  const hasItems = newCartItems.length > 0 || newCartOutboundData.length > 0;

  const otherUsersData = [...otherUsersInbound, ...otherUsersOutbound];

  otherUsersData.map((m) => m.splice(0, 1));

  const modalDataOtherUsers: (
    | string
    | Record<string, string | CartStockListItem>
  )[] = flatten(otherUsersData);

  return {
    newCartItems,
    newCartOutboundData,
    hasItems,
    numberOfCartItems,
    modalData,
    otherUsersCartInboundData: otherUsersInbound,
    otherUsersCartOutboundData: otherUsersOutbound,
    modalDataOtherUsers,
  };
};

// WHEN REMOVING ITEM FROM CART WE MUST REMOVE IT FROM outboundOrderItems
export const removeItemFromOutbounds = (
  outboundOrderItems: OutboundOrderItemsType[],
  branchId: number,
  warehouseId: number,
  item: CartItem
) => {
  const orderItem = outboundOrderItems.filter(
    (order) =>
      order.branchId === branchId && order.warehouseId === Number(warehouseId)
  )[0];
  if (!isEmpty(orderItem)) {
    const lineItemIdx = orderItem?.lineItems.findIndex(
      (i) => i.ean === item.ean && i.dot === item.dot && i.branchId === branchId
    );
    if (orderItem.lineItems.length === 1) {
      const idx = outboundOrderItems.findIndex((m) => isEqual(m, orderItem));

      outboundOrderItems.splice(idx, 1);
    } else {
      orderItem.lineItems.splice(lineItemIdx, 1);
    }
  }
};
