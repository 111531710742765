import md5 from 'md5';
import accounting from 'accounting-js';
// import { isNull } from 'lodash';
/**
 * Cleans up string values like "215.00" => "215", "220,50" -> "220"
 * @param key
 */
export const convertToIntegerString = (key: unknown) => {
  if (typeof key !== 'string')
    throw new Error(
      `Can't run convertToIntegerString on ${key}. This function must receive a string.`
    );

  if (key.indexOf('.') > -1) return key.split('.')[0];
  if (key.indexOf(',') > -1) return key.split(',')[0];
  return key;
};

export const convertToDecimalNumber = (key: unknown) => {
  if (typeof key !== 'string')
    throw new Error(
      `Can't run convertToDecimalNumber on ${key}. This function must receive a string.`
    );

  const keyToNumber = accounting.unformat(key);

  if (isNaN(keyToNumber))
    throw new Error(
      `Can't convert ${key} to a decimal number. The passed string must be convertable to a number.`
    );

  return keyToNumber.toFixed(2);
};

export const convertToString = (key: unknown) => {
  return String(key);
};

export const trimZeroes = (key: unknown) => {
  if (typeof key !== 'string')
    throw new Error(
      `Received value is not a string. Can't trim zeroes. ${key}`
    );
  let value = key;

  while (value.startsWith('0')) {
    value = value.substring(1);
  }

  return value;
};

export const trimTrailingZeroes = (key: unknown) => {
  if (typeof key !== 'string') {
    throw new Error(
      `Received value is not a string. Can't trim trailing zeroes. ${key}`
    );
  }

  // Remove trailing zeros and then remove the decimal point if there are no decimal digits
  let value = key.replace(/0+$/, ''); // Remove trailing zeros
  value = value.replace(/\.$/, '');  // Remove the decimal point if it's at the end

  return value;
};

export const getEprelIdFromUrl = (key: unknown) => {
  if (typeof key !== 'string')
    throw new Error(
      `Received value is not a string. Can't get id from something that isn't an url. ${key}`
    );
  return key.substring(30);
};

export const getEprelUrlFromId = (id: unknown) => {
  if (typeof id !== 'string')
    throw new Error(
      `Received value is not a string. Can't construct url from something that isn't a string. ${id}`
    );

  let url = '';

  if (id !== '0' && id !== '') {
    url = `https://eprel.ec.europa.eu/screen/product/tyres/${id}`;
  }
  return url;
};

export function convertToMD5(key: unknown) {
  if (typeof key !== 'string' && typeof key !== 'number')
    throw new Error(
      `Can't convert ${key} (type: ${typeof key}) to an MD5 string.`
    );

  return md5(`${key}`);
}

export const log = (key: unknown) => {
  console.log('**PLACEHOLDER LOG RULE**', key);
  return String(key);
};

export const PostProcessHelpers = {
  convertToIntegerString,
  log,
  trimZeroes,
  trimTrailingZeroes,
  convertToDecimalNumber,
  convertToString,
  convertToMD5,
  getEprelIdFromUrl,
  getEprelUrlFromId,
};
