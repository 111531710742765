import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const GUMIIMPEX_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.condition',
    sourceKey: "c['Podkategorija']",
    condition: 'contains',
    conditionValue: 'OSOBNE',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '30',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Podkategorija']",
    condition: 'contains',
    conditionValue: 'KOMBI',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '32',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Podkategorija']",
    condition: 'contains',
    conditionValue: '4 x 4',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Podkategorija']",
    condition: 'contains',
    conditionValue: '4x4',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Podkategorija']",
    condition: 'contains',
    conditionValue: '4 X 4',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Podkategorija']",
    condition: 'contains',
    conditionValue: '4X4',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Podkategorija']",
    condition: 'contains',
    conditionValue: 'TERETNE',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '50',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Namjena',
    condition: 'equals',
    conditionValues: ['Summer', 'Winter', 'WholeYear', 'TERETNE'],
    conditionOutputs: ['summer', 'winter', 'allseason', 'summer'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Dezen',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c['EAN']",
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c['SAP_kod']",
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Sirina',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
    postProcess: ['trimTrailingZeroes'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Visina',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
    postProcess: ['trimTrailingZeroes'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Promjer',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'construction',
    template: 'R',
  },
  // Load index and Speed Index
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalGetGumiImpexLoadSpeedIndex',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'getGumiImpexSpecialMarkings',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Prijanjanje_na_mokroj_cesti]',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Prijanjanje_na_mokroj_cesti"]',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.operate',
    sourceKey: 'c.Buka',
    destination: 'product',
    targetKey: 'noisePerformance',
    operationName: 'getGumiImpexNoisePerformance',
  },
  {
    type: 'rule.operate',
    sourceKey: 'c.Buka',
    destination: 'product',
    targetKey: 'getGumiImpexNoiseClassType',
    operationName: 'getGumiImpexNoiseClassType',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Naziv',
    condition: 'contains',
    conditionValue: 'XL ',
    outputValue: 'XL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.Slika1',
    targetKey: 'originalPhotos',
    delimiter: '|',
    destination: 'model',
    outputType: 'string',
  },

  ...GLOBAL_RULESET,

];
