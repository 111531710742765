import * as z from "zod"
import { CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel } from "./index"

export const DBSupplierClientContactsModel = z.object({
  id: z.string(),
  supplierClientSettingsId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  bussinesPhone: z.string(),
  mobilePhone: z.string(),
  remark: z.string(),
  createdAt: z.date(),
})

export interface CompleteDBSupplierClientContacts extends z.infer<typeof DBSupplierClientContactsModel> {
  supplierClientSettings: CompleteDBSupplierClientSettings
}

/**
 * RelatedDBSupplierClientContactsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierClientContactsModel: z.ZodSchema<CompleteDBSupplierClientContacts> = z.lazy(() => DBSupplierClientContactsModel.extend({
  supplierClientSettings: RelatedDBSupplierClientSettingsModel,
}))
