import api from '@tyrio/api-factory';
import { DBPosWheelsApi, WheelModelDetails } from '@tyrio/dto';
import _ from 'lodash';
import { useQuery } from 'react-query';

export const useGetWheelDetails = (
  modelName: string,
  brand: string,
  enabled: boolean,
  setSelectedProductModel: (val: WheelModelDetails | null) => void,
  pcd?: string,
  color?: string | null,
  et?: number[],
  rimDiameter?: number[]
) => {
  const min = _.min(et ?? []);
  const max = _.max(et ?? []);

  const minRimSize = _.min(rimDiameter ?? []);

  const { data, isFetched, isError, isLoading, refetch, isRefetching } =
    useQuery(
      ['get_wheel_details'],
      () =>
        api.fetch<DBPosWheelsApi['getWheelDetails']>('get_wheel_details', {
          modelName,
          brand,
          pcd,
          et_min: min,
          et_max: max,
          minRimSize,
        }),

      {
        enabled,
        onSuccess: (res) => {
          const item = res.find((r) => r.color === color) ?? null;
          setSelectedProductModel(item);
        },
      }
    );

  return {
    data,
    isFetched,
    isError,
    refetch,
    showLoader: isRefetching || isLoading,
  };
};
