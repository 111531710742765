import styled from '@emotion/styled';
import { toNumber } from 'lodash';
import { tyrioUI } from '@tyrio/ui-library';

interface FooterCellData {
  value: number;
  numberOfItems: number;
  pair: boolean;
}

export default function FooterCell(props: FooterCellData) {
  const { value, numberOfItems, pair } = props;

  return (
    <Container style={{ backgroundColor: !pair ? '#F9FAFB' : 'white' }}>
      <ValueWrapper>
        {toNumber(value).toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </ValueWrapper>
      <NumOfItemsWrapper>{numberOfItems} pcs</NumOfItemsWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${tyrioUI.spacing.l}px;
`;
const ValueWrapper = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #212b36;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumOfItemsWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  color: #212b36;
  display: flex;
  justify-content: center;
  align-items: center;
`;
