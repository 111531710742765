import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetCategories = (id: string) => {
  const { data: category_data } = useQuery(
    ['get_category_id', id],
    () =>
      api.fetch<DBProductCategoryApi['getOne']>(`category_id`, {
        id: Number(id),
      }),
    {
      enabled: !!id,
    }
  );

  return { category_data: category_data };
};
