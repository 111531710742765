import { Circle, NotInterested } from '@mui/icons-material';
import { TextField, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { toNumber } from 'lodash';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

interface TableCellData {
  value?: string;
  isLowestValue?: boolean;
  isSecondLowest?: boolean;
  percentage?: number;
  isSelected: boolean;
  numberOfAvailableItems?: number;
  numberValue: number;
  onNumberChange?: StandardInputProps['onChange'];
}

export default function TableCell(props: TableCellData) {
  const {
    value,
    isLowestValue,
    isSecondLowest,
    percentage,
    isSelected,
    numberOfAvailableItems,
    numberValue,
    onNumberChange,
  } = props;

  return (
    <CellWrapper isOpened={isSelected}>
      {value ? (
        <div>
          {isLowestValue && (
            <FieldContainer>
              <LowestPriceWrapper>
                {toNumber(value).toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  currencyDisplay: 'symbol',
                })}
              </LowestPriceWrapper>
              {numberOfAvailableItems === 0 && (
                <Tooltip title={'Not available'} placement={'top'}>
                  <CircleWrapper>
                    <StyledCircle />
                  </CircleWrapper>
                </Tooltip>
              )}
            </FieldContainer>
          )}

          {isSecondLowest && (
            <FieldContainer>
              <LowestPriceWrapper style={{ background: '#0781bf' }}>
                {toNumber(value).toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  currencyDisplay: 'symbol',
                })}
              </LowestPriceWrapper>
              {numberOfAvailableItems === 0 && (
                <Tooltip title={'Not available'} placement={'top'}>
                  <CircleWrapper>
                    <StyledCircle />
                  </CircleWrapper>
                </Tooltip>
              )}
            </FieldContainer>
          )}

          {!isLowestValue && !isSecondLowest && (
            <FieldContainer>
              <LowestPriceWrapper
                style={{ background: 'none', color: '#212B36' }}
              >
                {toNumber(value).toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  currencyDisplay: 'symbol',
                })}
              </LowestPriceWrapper>
              {numberOfAvailableItems === 0 && (
                <Tooltip title={'Not available'} placement={'top'}>
                  <CircleWrapper>
                    <StyledCircle />
                  </CircleWrapper>
                </Tooltip>
              )}
            </FieldContainer>
          )}

          {percentage ? (
            <PercentageCellPart
              style={
                percentage <= 0 ? { color: '#3EB274' } : { color: '#D14343' }
              }
            >
              {percentage > 0 ? '+' : ''}
              {Math.round(percentage * 10000) / 100} {'%'}
            </PercentageCellPart>
          ) : (
            <PercentageCellPart
              style={{ marginTop: '10px' }}
            ></PercentageCellPart>
          )}
          {isSelected && (
            <SelectedWrapper>
              <InputWrapper>
                <CustomTextField
                  value={numberValue || 0}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  onChange={onNumberChange}
                />
              </InputWrapper>
              <NumberOfAvailableItems>
                {numberOfAvailableItems} pcs
              </NumberOfAvailableItems>
            </SelectedWrapper>
          )}
        </div>
      ) : (
        <Tooltip title={'Not available'} placement={'top'}>
          <NotAvailableWrapper>
            <NotInterested
              style={{
                height: '22px',
                width: '22px',
                color: '#6B7280',
              }}
            />
          </NotAvailableWrapper>
        </Tooltip>
      )}
    </CellWrapper>
  );
}

const CellWrapper = styled.div<{ isOpened: boolean }>`
  width: 100%;
  height: ${(props) => (!props.isOpened ? 42 : 100)}px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding-bottom: ${(props) => (props.isOpened ? '12px' : '0')};
`;
const PercentageCellPart = styled.div`
  color: #919eab;
  font-size: 14px;
  text-align: center;
`;

const LowestPriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #56c489;
  border-radius: 8px;
  color: white;
  padding: 2px 8px;
`;

const NotAvailableWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 8px;
`;
const NumberOfAvailableItems = styled.div`
  color: #919eab;
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin-top: 3px;
`;

const CustomTextField = styled(TextField)`
  width: 80px;
  padding: 0;
  margin: 0;

  input {
    padding: 8px 8px;
    text-align: center;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const CircleWrapper = styled.div`
  width: 30px;
  position: absolute;
  right: -20px;
`;

const StyledCircle = styled(Circle)`
  width: 100%;
  height: 10px;
  color: red;
`;

const SelectedWrapper = styled.div`
  width: 100%;
  height: 50px;
  padding-bottom: 5px;
`;
