import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

const removeQuestionmark = (s: string) =>
  s.startsWith('?') ? s.substring(1) : s;

export const useFilter = (initialShape: qs.ParsedQs = {}) => {
  const location = useLocation();
  const history = useHistory();

  const [filter, setFilter] = useState<qs.ParsedQs>({
    ...initialShape,
    ...qs.parse(removeQuestionmark(location.search)),
  });

  useEffect(() => {
    const parsed = qs.parse(removeQuestionmark(location.search));
    setFilter(parsed);
  }, [location.search]);

  const resetFilters = useCallback(() => setFilter({}), []);
  const setFilterValue = useCallback(
    (data: Partial<qs.ParsedQs>) => {
      const newFilterValue = { ...filter, ...data };
      const filterString = qs.stringify(newFilterValue);
      setFilter(newFilterValue);
      history.push({
        pathname: location.pathname,
        search: filterString,
      });
    },
    [filter, history, location.pathname]
  );

  return {
    filterValues: filter,
    setFilterValues: setFilter,
    resetFilters,
    setFilterValue,
  };
};
