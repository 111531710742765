import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { useCallback, useContext, useMemo } from 'react';
import { PosCartContext } from '../../../context/PosCartContext';
import { POSContext } from '../../../context/POSContext';
import { useWS } from '../../../context/WSContext';
import { customToast } from '../../stock-list/components/Cart/CartToast';
import { useVehicleCtx } from '../../../context/VehicleContext';
import { get } from 'lodash';
import {
  PosSearchActiveTab,
  usePosSearchContext,
} from './SearchPOS/SearchContext';
import { formatCurrencyWithSymbol } from '../../../helpers/currency-format';

export const AddItemsFooter = () => {
  const ws = useWS();
  const { selectedTableRow, isVatIncluded } = useContext(POSContext);
  const { cartData, setCartData, timer } = useContext(PosCartContext);
  const { selectedProduct } = useVehicleCtx();
  const { activeTab } = usePosSearchContext();

  const isWheels =
    activeTab === PosSearchActiveTab.wheels_search ? true : false;

  const ean = useMemo(() => {
    return isWheels
      ? get(selectedProduct, 'ean', '')
      : get(selectedTableRow?.product, 'ean', '');
  }, [isWheels, selectedProduct, selectedTableRow?.product]);

  const calculation = useMemo(() => {
    let totalQuantity = 0;
    let totalPrice = 0;
    let avgPrice = 0;

    const cartEan = cartData.filter((item) => item.req.ean === ean);

    const items = cartEan;

    if (items.length === 0) return { totalQuantity, totalPrice, avgPrice };

    // total quantity of the added items
    totalQuantity = items.reduce((acc, curr) => acc + curr.req.quantity, 0);

    // total price of added items
    totalPrice = Number(
      items
        .reduce((acc, curr) => {
          const finalPrice = !isVatIncluded
            ? curr.req.price
            : curr.req.inclusiveVat;
          return acc + finalPrice * curr.req.quantity;
        }, 0)
        .toFixed(2)
    );

    // average price
    if (totalPrice > 0 && totalQuantity > 0)
      avgPrice = totalPrice / totalQuantity;
    avgPrice = Number(avgPrice.toFixed(2));

    return { totalQuantity, totalPrice, avgPrice };
  }, [cartData, ean, isVatIncluded]);

  const handleClick = useCallback(() => {
    let addedToCart = false;
    cartData.forEach((item) => {
      if (item.req.quantity === 0) return;
      else {
        ws.socket?.emit('add-to-pos-cart', {
          key: item.key,
          req: {
            quantity: item.req.quantity,
            ean: item.req.ean,
            dot: item.req.dot,
            sku: item.req.sku,
            uid: item.req.uid,
            productName: item.req.productName,
            productBrand: item.req.productBrand,
            productDescription: item.req.productDescription,
            price: item.req.price,
            reserved: item.req.quantity,
            total: item.req.total,
            realQuantity: item.req.realQuantity,
            categoryId: item.req.categoryId,
            inclusiveVat: item.req.inclusiveVat,
            pcd: isWheels ? selectedProduct?.pcd : undefined,
            et: isWheels ? selectedProduct?.et : undefined,
          },
          stockType: item.stockType,
          branchId: item.branchId,
          supplierId: item.supplierId,
        });
        addedToCart = true;
      }
    });

    if (addedToCart) {
      timer?.restart();
      customToast('Product added to cart!', 'success');

      setCartData([]);
    }
  }, [
    cartData,
    isWheels,
    selectedProduct?.et,
    selectedProduct?.pcd,
    setCartData,
    timer,
    ws.socket,
  ]);

  const addedItem = useMemo(() => {
    const added = cartData.find(
      (item) => item.req.ean === ean && item.req.quantity > 0
    );
    return added !== undefined;
  }, [ean, cartData]);

  return (
    <div>
      {calculation.totalQuantity > 0 ? (
        <Wrapper isadded={addedItem}>
          <ContentWrapper>
            <PriceWrapper>
              <Text>Average price</Text>
              <Typography fontSize={20}>
                {formatCurrencyWithSymbol(calculation.avgPrice)}
              </Typography>
            </PriceWrapper>
            <PriceWrapper>
              <Text>Total quantity</Text>
              <Typography fontSize={20}>{calculation.totalQuantity}</Typography>
            </PriceWrapper>
            <PriceWrapper>
              <Text>Total price</Text>
              <Typography fontSize={20}>
                {formatCurrencyWithSymbol(calculation.totalPrice)}
              </Typography>
            </PriceWrapper>
          </ContentWrapper>
          <Button
            variant="contained"
            style={{
              borderRadius: 0,
              width: '128px',
              backgroundColor: addedItem
                ? tyrioUI.colors.lightBlue
                : tyrioUI.colors.green.B10,
            }}
            sx={{ boxShadow: 0 }}
            disableElevation
            onClick={handleClick}
          >
            {!addedItem ? 'ADD' : `ADD ${calculation.totalQuantity} pcs`}
          </Button>
        </Wrapper>
      ) : (
        <div />
      )}
    </div>
  );
};

const Wrapper = styled.div<{ isadded: boolean }>`
  display: flex;
  justify-content: space-between;
  background: ${(props) =>
    props.isadded ? tyrioUI.colors.black.B30 : tyrioUI.colors.green.B30};
  border-bottom-left-radius: 16px;
  width: 100%;
  height: 64px;
  position: sticky;
  bottom: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: calc(100% - 128px);
  justify-content: space-around;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 115px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${tyrioUI.colors.lightBlue};
`;

const Text = styled(Typography)`
  color: ${tyrioUI.colors.black.B80};
  font-size: 14px;
  font-weight: 400;
`;
