import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';

export type ActiveDrawer = 'GALLERY' | 'RESERVATION' | 'PRINT' | '';

export const ProductsQuantity = ({
  onStock,
  reserved,
  available,
  background,
  setActiveDrawer,
  onlyAvailable = false,
  upcoming,
  isModal,
}: {
  onStock?: number;
  reserved: number;
  available: number;
  background?: string;
  setActiveDrawer?: (drawer: ActiveDrawer) => void;
  onlyAvailable?: boolean;
  upcoming?: number;
  isModal?: boolean;
}) => {
  const avatarBg = onlyAvailable
    ? 'transparent'
    : available === 0
    ? '#E64B4B'
    : available < 4
    ? tyrioUI.colors.yellow.B100
    : 'transparent';

  const textColor = onlyAvailable
    ? '#212B36'
    : available === 0
    ? 'white'
    : available < 4
    ? 'white'
    : 'black';

  const avatarFontWeight = onlyAvailable ? 400 : 600;

  return (
    <Wrapper
      background={background}
      id="quantity_wrapper"
      onlyAvailable={onlyAvailable}
      stock={onStock}
      upcoming={upcoming}
    >
      {!onlyAvailable && (
        <>
          {onStock !== undefined && <NumberWrapper>{onStock}</NumberWrapper>}
          <NumberWrapper
            color={isModal ? 'black' : reserved > 0 ? 'red' : 'black'}
            onClick={() =>
              !isModal &&
              setActiveDrawer !== undefined &&
              setActiveDrawer('RESERVATION')
            }
            clickable={isModal ? false : true}
          >
            {reserved}
          </NumberWrapper>
          {upcoming !== undefined && <NumberWrapper>{upcoming}</NumberWrapper>}
        </>
      )}

      <NumberWrapper>
        <Avatar
          sx={{
            background: avatarBg,
            fontWeight: avatarFontWeight,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.3px',
            color: textColor,
            width: available.toString().length > 4 ? 'fit-content' : '40px',
            height: '40px',
            position: 'inherit',
          }}
        >
          {available}
        </Avatar>
      </NumberWrapper>
    </Wrapper>
  );
};

export const Wrapper = styled.div<{
  background?: string;
  onlyAvailable: boolean;
  stock: number | undefined;
  upcoming: number | undefined;
}>`
  width: 100%;
  max-width: ${(props) =>
    props.onlyAvailable
      ? '80px'
      : props.stock !== undefined
      ? '320px'
      : '160px'};
  height: 100%;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.background ? props.background : '#f4f6f8')};
  border-radius: 8px 0px 0px 8px;

  &:hover {
    background: #ecf0f4;
  }
`;

export const NumberWrapper = styled.div<{
  color?: string;
  clickable?: boolean;
}>`
  display: flex;
  width: 100%;
  max-width: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.color === 'red' ? '600' : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  &:hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  }
`;
