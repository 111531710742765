import { DBSupplierClientContacts } from '@prisma/client';
// import { supplierClientSettings } from './supplier-client-settings';

export const supplierClientContacts: DBSupplierClientContacts[] = [
  // {
  //   id: '00000000-0000-0000-0000-000000000001',
  //   supplierClientSettingsId: supplierClientSettings[0].id,
  //   firstName: 'Haris',
  //   lastName: 'Pandzic',
  //   email: 'haris.pandzic@outlook.com',
  //   bussinesPhone: '38762209709',
  //   mobilePhone: '38762209709',
  //   remark: '',
  //   createdAt: new Date(),
  // },
];
