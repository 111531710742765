import { TyrioInputString } from './inputs/InputString';
import {
  FormGridProps,
  GetValueProps,
  TyrioFormError,
  TyrioFormInput,
} from './types';

import { Grid, Typography } from '@mui/material';
import {
  Control,
  FieldValues,
  SetFieldValue,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TyrioComponentImages } from './components/ProductImagesContainter';
import { TyrioUserPhoto } from './components/UserPhotoContainer';
import { TyrioAddNewPartner } from './inputs/AddNewPartner';
import { TyrioInputButton } from './inputs/InputButton';
import { TyrioInputCheckbox } from './inputs/InputCheckbox';
import { TyrioInputDatePicker } from './inputs/InputDatePicker';
import { TyrioInputMultiselect } from './inputs/InputMultiselect';
import { TyrioInputPhoneString } from './inputs/InputPhone';
import { TyrioInputRadio } from './inputs/InputRadio';
import { TyrioInputSelect } from './inputs/InputSelect';
import { TyrioInputSwitch } from './inputs/InputSwtich';
import { TyrioInputTextarea } from './inputs/InputTextarea';
import { ViesInputForm } from './inputs/InputVies';
import { TyrioLabelSection } from './inputs/LabelSection';
import { TyrioInputDivider } from './inputs/InputDivider';

interface TyrioFormMappingProps {
  item: TyrioFormInput;
  id: string;
  gridProps: FormGridProps;
  error?: TyrioFormError;
  register: UseFormRegister<FieldValues>;
  control: Control;
  idPrefix?: string;
  setValue: SetFieldValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  repKey?: string;
  watch?: UseFormWatch<FieldValues>;
  idx?: number;
  shouldDisableEdit?: boolean;
  handleCustomSubmit: () => void;
  onChangeValue?: (v: GetValueProps) => void;
}
export const TyrioFormMapping = ({
  item,
  id,
  gridProps,
  error,
  register,
  idPrefix,
  control,
  repKey,
  watch,
  idx,
  setValue,
  shouldDisableEdit,
  handleCustomSubmit,
  onChangeValue,
}: TyrioFormMappingProps) => {
  const { t } = useTranslation();

  if (idPrefix && !item.id.startsWith(idPrefix)) {
    item.id = (idPrefix || '') + item.id;
  }

  if (item.type === 'input.string') {
    return (
      <TyrioInputString
        id={id}
        register={register}
        error={error}
        item={item}
        gridProps={gridProps}
        customDisabled={shouldDisableEdit}
        helperText={item.helperText}
      />
    );
  }

  if (item.type === 'input.phone') {
    return (
      <TyrioInputPhoneString
        id={id}
        register={register}
        error={error}
        control={control}
        item={item}
        gridProps={gridProps}
      />
    );
  }

  if (item.type === 'input.checkbox') {
    return (
      <TyrioInputCheckbox
        id={id}
        item={item}
        error={error}
        register={register}
        gridProps={gridProps}
      />
    );
  }

  if (item.type === 'input.datepicker') {
    return (
      <TyrioInputDatePicker
        id={id}
        item={item}
        error={error}
        control={control}
        register={register}
        gridProps={gridProps}
      />
    );
  }

  if (item.type === 'input.select') {
    return (
      <TyrioInputSelect
        id={id}
        item={item}
        register={register}
        gridProps={gridProps}
        control={control}
        error={error}
        filter={watch && item.watchKey ? watch(item.watchKey) : undefined}
        customDisabled={shouldDisableEdit}
      />
    );
  }

  if (item.type === 'input.radio') {
    return (
      <TyrioInputRadio
        id={id}
        item={item}
        gridProps={gridProps}
        control={control}
        error={error}
      />
    );
  }

  if (item.type === 'input.switch') {
    return (
      <TyrioInputSwitch
        id={id}
        item={item}
        gridProps={gridProps}
        control={control}
        onChangeValue={onChangeValue}
      />
    );
  }

  if (item.type === 'label.section') {
    const idxValue = typeof idx === 'number' ? `${idx + 1}` : '';
    if (item.dynamicLabel) {
      item.label =
        item.dynamicLabel.children.reduce((acc, el) => {
          const val =
            watch &&
            watch(`${item.dynamicLabel?.parent as string}.${repKey}.${el}`);
          if (!acc) {
            return val;
          } else {
            return `${acc} ${val}`;
          }
        }, '') || `${t(`${getAccordionText(item.id)}`)} #${idxValue}`;
    }
    return <TyrioLabelSection item={item} gridProps={gridProps} />;
  }

  if (item.type === 'input.partner') {
    return (
      <TyrioAddNewPartner
        item={item}
        gridProps={gridProps}
        setValue={setValue}
        register={register}
        error={error}
        handleCustomSubmit={handleCustomSubmit}
      />
    );
  }

  if (item.type === 'input.textarea') {
    return <TyrioInputTextarea id={id} item={item} gridProps={gridProps} />;
  }

  if (item.type === 'component.images') {
    return <TyrioComponentImages gridProps={gridProps} />;
  }

  if (item.type === 'component.userPhoto') {
    return <TyrioUserPhoto gridProps={gridProps} />;
  }

  if (item.type === 'input.vies') {
    return (
      <ViesInputForm
        gridProps={gridProps}
        id={id}
        item={item}
        register={register}
        error={error}
      />
    );
  }

  if (item.type === 'input.multiselect') {
    return (
      <TyrioInputMultiselect
        gridProps={gridProps}
        item={item}
        control={control}
        id={id}
        filter={watch && item.watchKey ? watch(item.watchKey) : undefined}
      />
    );
  }

  console.warn(
    t(`Input of type ${(item as TyrioFormInput).type} is not supported.`)
  );

  if (item.type === 'input.button') {
    return (
      <TyrioInputButton
        gridProps={gridProps}
        id={id}
        item={item}
        register={register}
      />
    );
  }

  if (item.type === 'input.divider') {
    return <TyrioInputDivider />;
  }

  return (
    <Grid item {...gridProps}>
      <Typography
        variant="body2"
        sx={{
          height: 56,
          background: '#fff8e8',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        TODO: {(item as TyrioFormInput).id}/{(item as TyrioFormInput).type}
      </Typography>
    </Grid>
  );
};

const getAccordionText = (value: string) => {
  if (value === 'labelContact') {
    return 'Contact';
  }
  if (value === 'users') {
    return 'User';
  }

  return '';
};
