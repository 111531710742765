import styled from '@emotion/styled';

interface MinimalLocationProps {
  title: string;
  subtitle: string;
  color: string;
}

const LocationName = (props: MinimalLocationProps) => {
  const { title, subtitle, color } = props;

  return (
    <Container>
      <LocationColor color={color} />
      <LocationContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </LocationContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 10px;
  cursor: pointer;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.3px;
`;

const Subtitle = styled.span`
  font-size: 15px;
  color: #919eab;
`;

const LocationColor = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 6px;
  height: 45px;
`;

export default LocationName;
