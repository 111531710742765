import { DBSupplierLocation, DBSupplierLocationType } from '@prisma/client';
import { countries } from './countries';
import { suppliers } from './suppliers';

export const supplier_locations: DBSupplierLocation[] = [
  {
    id: '00000000-0000-0000-0000-000000000001',
    supplierId: suppliers[0].id,
    locationId: 'L001',
    locationName: 'Warehouse Sarajevo',
    slug: 'sarajevo',
    countryId: countries[0].id,
    zipCode: '71000',
    city: 'Sarajevo',
    adress: 'Srdjana Aleksica 1',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.CENTRAL_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    supplierId: suppliers[0].id,
    locationId: 'L002',
    locationName: 'Mostar',
    slug: 'mostar',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Mostar',
    adress: 'Fejiceva 1',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    supplierId: suppliers[1].id,
    locationId: 'L003',
    locationName: 'Sarajevo',
    slug: 'mostar-01',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000004',
    supplierId: suppliers[2].id,
    locationId: 'L004',
    locationName: 'Sarajevo',
    slug: 'mostar-02',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000005',
    supplierId: suppliers[3].id,
    locationId: 'L005',
    locationName: 'Sarajevo',
    slug: 'mostar-03',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000006',
    supplierId: suppliers[4].id,
    locationId: 'L006',
    locationName: 'Sarajevo',
    slug: 'mostar-04',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000007',
    supplierId: suppliers[5].id,
    locationId: 'L007',
    locationName: 'Sarajevo',
    slug: 'mostar-07',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000008',
    supplierId: suppliers[6].id,
    locationId: 'L008',
    locationName: 'Sarajevo',
    slug: 'mostar-08',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000009',
    supplierId: suppliers[7].id,
    locationId: 'L009',
    locationName: 'Sarajevo',
    slug: 'mostar-09',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: '00000000-0000-0000-0000-000000000010',
    supplierId: suppliers[8].id,
    locationId: 'L010',
    locationName: 'Sarajevo',
    slug: 'mostar-010',
    countryId: countries[0].id,
    zipCode: '88000',
    city: 'Sarajevo',
    adress: 'Ferhadija',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.SECONDARY_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: 'b57b685f-e0a6-4b68-bcc6-d4479b2c5240',
    supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
    locationId: '1',
    locationName: 'Sarajevo',
    slug: 'sarajevo-010',
    countryId: countries[0].id,
    zipCode: '71000',
    city: 'Sarajevo-011',
    adress: 'Sarajevo',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.CENTRAL_WAREHOUSE,
    createdAt: new Date(),
  },
  {
    id: 'd9720cdd-de2e-4033-805b-79e9ac066311',
    supplierId: 'd80ae0db-ffa8-4a96-9624-dc16da96be01',
    locationId: 'L011',
    locationName: 'Sarajevo',
    slug: 'sarajevo-011',
    countryId: countries[0].id,
    zipCode: '71000',
    city: 'Sarajevo-011',
    adress: 'Sarajevo',
    isHeadQuarter: true,
    supplierLocationType: DBSupplierLocationType.CENTRAL_WAREHOUSE,
    createdAt: new Date(),
  },
];
