import {
  DBCharacterType,
  DBCountry,
  DBCurrency,
  DBDecimalSeparator,
  DBLanguage,
  DBTimeZone,
} from '@prisma/client';

// EXAMPLE ID: "00000000-0000-1000-c000-000000000000";
// I THINK THIS FILE IS NOT IN USE
// WE SHOULD DELETE IT
export const countries: DBCountry[] = [
  {
    id: '00000000-0000-1000-c000-000000000000',
    name: 'Croatia',
    native_name: 'Hrvatska',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HR',
    intrastat_code: 'HR',
    calling_code: 385,
    TLD: 'hr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000001',
    name: 'Bosnia and Herzegovina',
    native_name: 'Bosna i Hercegovina',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BA',
    intrastat_code: 'BA',
    calling_code: 387,
    TLD: 'ba',
    language: DBLanguage.Bosnian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.BAM,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000002',
    name: 'Austria',
    native_name: 'Austria',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'AT',
    intrastat_code: 'AT',
    calling_code: 43,
    TLD: 'at',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000003',
    name: 'Belgium',
    native_name: 'Belgium',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BE',
    intrastat_code: 'BE',
    calling_code: 32,
    TLD: 'be',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000004',
    name: 'Bulgaria',
    native_name: 'Bulgaria',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'BG',
    intrastat_code: 'BG',
    calling_code: 359,
    TLD: 'bg',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000005',
    name: 'Czech Republic',
    native_name: 'Czech Republic',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'CZ',
    intrastat_code: 'CZ',
    calling_code: 420,
    TLD: 'cz',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000006',
    name: 'Denmark',
    native_name: 'Denmark',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DK',
    intrastat_code: 'DK',
    calling_code: 45,
    TLD: 'dk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000007',
    name: 'Estonia',
    native_name: 'Estonia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'EE',
    intrastat_code: 'EE',
    calling_code: 372,
    TLD: 'ee',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000008',
    name: 'Finland',
    native_name: 'Finland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FI',
    intrastat_code: 'FI',
    calling_code: 358,
    TLD: 'fi',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000009',
    name: 'France',
    native_name: 'France',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'FR',
    intrastat_code: 'FR',
    calling_code: 33,
    TLD: 'fr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000010',
    name: 'Germany',
    native_name: 'Germany',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'DE',
    intrastat_code: 'DE',
    calling_code: 49,
    TLD: 'de',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000011',
    name: 'Greece',
    native_name: 'Greece',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'GR',
    intrastat_code: 'GR',
    calling_code: 30,
    TLD: 'gr',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000012',
    name: 'Hungary',
    native_name: 'Hungary',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'HU',
    intrastat_code: 'HU',
    calling_code: 36,
    TLD: 'hu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000013',
    name: 'Ireland',
    native_name: 'Ireland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IE',
    intrastat_code: 'IE',
    calling_code: 353,
    TLD: 'ie',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000014',
    name: 'Italy',
    native_name: 'Italy',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'IT',
    intrastat_code: 'IT',
    calling_code: 39,
    TLD: 'it',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000015',
    name: 'Latvia',
    native_name: 'Latvia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LV',
    intrastat_code: 'LV',
    calling_code: 371,
    TLD: 'lv',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000016',
    name: 'Lithuania',
    native_name: 'Lithuania',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LT',
    intrastat_code: 'LT',
    calling_code: 370,
    TLD: 'lt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000017',
    name: 'Luxembourg',
    native_name: 'Luxembourg',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'LU',
    intrastat_code: 'LU',
    calling_code: 352,
    TLD: 'lu',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000018',
    name: 'Macedonia (Republic of North)',
    native_name: 'Macedonia (Republic of North)',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MK',
    intrastat_code: 'MK',
    calling_code: 389,
    TLD: 'mk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000019',
    name: 'Malta',
    native_name: 'Malta',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'MT',
    intrastat_code: 'MT',
    calling_code: 356,
    TLD: 'mt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000020',
    name: 'Montenegro',
    native_name: 'Montenegro',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ME',
    intrastat_code: 'ME',
    calling_code: 382,
    TLD: 'me',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000021',
    name: 'Netherlands',
    native_name: 'Netherlands',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'NL',
    intrastat_code: 'NL',
    calling_code: 31,
    TLD: 'nl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000022',
    name: 'Poland',
    native_name: 'Poland',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PL',
    intrastat_code: 'PL',
    calling_code: 48,
    TLD: 'pl',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000023',
    name: 'Portugal',
    native_name: 'Portugal',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'PT',
    intrastat_code: 'PT',
    calling_code: 351,
    TLD: 'pt',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000024',
    name: 'Romania',
    native_name: 'Romania',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'RO',
    intrastat_code: 'RO',
    calling_code: 40,
    TLD: 'ro',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000025',
    name: 'Serbia',
    native_name: 'Serbia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'RS',
    intrastat_code: 'RS',
    calling_code: 381,
    TLD: 'rs',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000026',
    name: 'Slovakia',
    native_name: 'Slovakia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SK',
    intrastat_code: 'SK',
    calling_code: 421,
    TLD: 'sk',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000027',
    name: 'Slovenia',
    native_name: 'Slovenia',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SI',
    intrastat_code: 'SI',
    calling_code: 386,
    TLD: 'si',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000028',
    name: 'Spain',
    native_name: 'Spain',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'ES',
    intrastat_code: 'ES',
    calling_code: 34,
    TLD: 'es',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
  {
    id: '00000000-0000-1000-c000-000000000029',
    name: 'Sweden',
    native_name: 'Sweden',
    time_zone: DBTimeZone.UTC_GMT_1,
    iso_code: 'SE',
    intrastat_code: 'SE',
    calling_code: 46,
    TLD: 'se',
    language: DBLanguage.Croatian,
    character_type: DBCharacterType.LATIN,
    currency: DBCurrency.EUR,
    decimal_separator: DBDecimalSeparator.COMMA,
    remark: 'Nothing ...',
    vatRates: [],
  },
];

export const countryTLDs = countries.map((c) => c.TLD);
