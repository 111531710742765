import { AuthAction } from './auth.actions';

export interface AuthState {
  featureConfig: number;
  isSupplierClientModalVisible: boolean;
}
const INITIAL_STATE: AuthState = {
  featureConfig: 0,
  isSupplierClientModalVisible: false,
};

export const authReducer = (state = INITIAL_STATE, action: AuthAction) => {
  switch (action.type) {
    case '@auth/set_supplier_client_modal_visibility':
      return {
        ...state,
        isSupplierClientModalVisible: !state.isSupplierClientModalVisible,
      };
    default:
      return state;
  }
};

export const changeSupplierClientModalVisibility = () => {
  return {
    type: '@auth/set_supplier_client_modal_visibility',
  };
};
