import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Divider, Tooltip } from '@mui/material';

import { tyrioUI } from '@tyrio/ui-library';
import { ProductDetailsDataInterface } from '@tyrio/dto';
import classNames from 'classnames';
import { CalendarDate } from 'calendar-date';

interface OrderSupplierBoxLineProps {
  data: ProductDetailsDataInterface;
  inquiryData?: {
    ean: string;
    qty: number;
    deliveryDate: string;
    minDeliveryDate?: string | null;
    maxDeliveryDate?: string | null;
    deliveryAcceptableRange: number;
  };
  orderComplete?: boolean;
  isScrolling: boolean;
}
export const OrderSupplierBoxLine = ({
  data,
  inquiryData,
  orderComplete,
  isScrolling,
}: OrderSupplierBoxLineProps) => {
  // DO WE NEED THIS?
  // const parsedDeliveryDate =
  //   !!inquiryData?.deliveryDate &&
  //   typeof inquiryData?.deliveryDate === 'string' &&
  //   inquiryData?.deliveryDate !== '9999-12-31'
  //     ? inquiryData?.deliveryDate
  //     : null;

  const qty = inquiryData?.qty ?? 0;
  const quantity = data.quantity;

  const tooltipMessage = useMemo(() => {
    if (!!inquiryData && quantity > qty)
      return {
        message: `Supplier does not have enough items in stock!`,
        classname: 'not_enough',
      };
    if (!!inquiryData && quantity <= qty)
      return {
        message: `Supplier has enough items in stock!`,
        classname: 'has_enough',
      };
    return {
      message: 'Supplier realtime stock unknown.',
      classname: 'unknown',
    };
  }, [inquiryData, qty, quantity]);

  const deliveryDate = useMemo(() => {
    if (
      typeof inquiryData?.deliveryDate !== 'string' ||
      inquiryData?.deliveryDate?.startsWith('9999') ||
      !inquiryData?.deliveryDate
    ) {
      return 'UNKNOWN';
    }

    const dateArray = [
      inquiryData?.minDeliveryDate,
      inquiryData?.maxDeliveryDate,
    ]
      .filter((dateString) => {
        return !(!dateString || dateString.startsWith('9999'));
      })
      .map((dateString) => {
        if (!dateString || dateString.startsWith('9999')) return dateString;

        try {
          return new CalendarDate(dateString);
        } catch (error) {
          return 'UNKNOWN';
        }
      });

    if (dateArray.includes('UNKNOWN')) return 'UNKNOWN';

    const days = (dateArray as CalendarDate[]).map((d) => d.day);
    const months = (dateArray as CalendarDate[]).map((d) => d.month);
    const years = (dateArray as CalendarDate[]).map((d) => d.year);

    const sameMonths = months[0] === months[1];
    const sameYears = years[0] === years[1];

    if (sameMonths && sameYears)
      return `${days[0]}-${days[1]}.${months[0]}.${years[0]}.`;

    if (!sameMonths && sameYears)
      return `${days[0]}.${months[0]}.-${days[1]}.${months[1]}.${years[0]}.`;

    return `${days[0]}.${months[0]}.${years[0]}.-${days[1]}.${months[1]}.${years[1]}.`;
  }, [
    inquiryData?.deliveryDate,
    inquiryData?.maxDeliveryDate,
    inquiryData?.minDeliveryDate,
  ]);

  return (
    <Wrapper orderComplete={!!orderComplete} isScrolling={isScrolling}>
      <h4>
        {data?.brand ?? ''} {data.productName}
      </h4>
      <Subheader>
        <SubheaderContents>
          <span>EAN: {data.ean}</span>
          <span>IPC: {data.ipc}</span>
          <span>SKU: {data.sku}</span>
          <span>
            Delivery date:{' '}
            <span
              className={classNames({
                'calendar-date': true,
                green: inquiryData?.deliveryAcceptableRange === 1,
                red: inquiryData?.deliveryAcceptableRange === 2,
              })}
            >
              {deliveryDate}
            </span>
          </span>
        </SubheaderContents>
        <Price>
          {data.price.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
          })}
        </Price>

        <Tooltip title={tooltipMessage.message} placement={'top'}>
          <Qty>
            <span className={`qtyText ${tooltipMessage.classname}`}>
              {data.quantity}
            </span>
          </Qty>
        </Tooltip>

        <Price>
          {(data.price * data.quantity).toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
          })}
        </Price>
      </Subheader>
      <Divider />
    </Wrapper>
  );
};

export const Wrapper = styled.div<{
  orderComplete: boolean;
  isScrolling: boolean;
}>`
  ${(props) => (props.orderComplete ? `opacity: 0.35;` : '')}
  ${(props) => (props.isScrolling ? ` padding-right: 16px;` : '')}

  margin-bottom: 8px;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    padding: 0;
    margin: 0 0 0px 0;
    color: #212b36;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    padding: 0;
    margin: 0 16px 0px 0;
    color: #919eab;
  }
`;

export const Price = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
  min-width: 80px;
  display: block;
  text-align: right;
`;
export const Qty = styled.div`
  cursor: pointer;
  span.qtyText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    min-width: 23px;
    text-align: right;

    align-items: center;
    letter-spacing: 0.3px;
    margin: 0 16px;
    display: block;
    color: #212b36;
    &.not_enough {
      color: ${tyrioUI.colors.secondary};
    }
    &.has_enough {
      color: ${tyrioUI.colors.green.B100};
    }
    &.unknown {
      color: ${tyrioUI.colors.secondary};
    }
  }
`;

export const Subheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 8px;
`;

export const SubheaderContents = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  .calendar-date {
    font-weight: bold;
    &.green {
      color: limegreen;
    }
    &.red {
      color: #db1e1e;
    }
  }
`;
