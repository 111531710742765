/* eslint-disable react/jsx-no-useless-fragment */
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TyrioLogo } from '../../../../src/index';
import { colors } from '../../../core';
import Header from '../Header/Header';

interface LandingPageProps {
  icon?: string;
  title: string;
  subtitle: string;
  buttonText?: string;
  onClick?: () => void;
  cyId?: string;
  headerText?: string;
  buttonIcon?: string;
  headerComponent?: React.ReactNode;
}

const LandingPage = ({
  icon,
  title,
  subtitle,
  buttonText,
  onClick,
  cyId,
  headerText,
  buttonIcon,
  headerComponent,
}: LandingPageProps) => {
  const { t } = useTranslation();

  return (
    <PageTemplateContent header={!!headerText}>
      {headerComponent && headerComponent}
      {headerText && !headerComponent && <Header title={headerText} />}
      <LandingPageWrapper>
        <img
          src={icon ? icon : TyrioLogo}
          alt="tyrio-logo"
          style={{
            filter: title.includes('Roles') ? 'invert(45%)' : 'invert(20%)',
            width: icon ? '250px' : '400px',
            height: icon && '250px',
            marginBottom: !icon ? '30px' : 0,
          }}
        />
        <TitleWrapper>{t(title)}</TitleWrapper>
        <SubtitleWrapper>{t(subtitle)}</SubtitleWrapper>
        {buttonText && onClick && (
          <ActionButton data-cy={cyId} onClick={onClick}>
            {t(buttonText)}
            {buttonIcon ? <img src={buttonIcon} alt={title} /> : ' +'}
          </ActionButton>
        )}
      </LandingPageWrapper>
    </PageTemplateContent>
  );
};

export const PageTemplateContent = styled.div<{ header?: boolean }>`
  background: white;
  height: 100%;
  border-radius: 22px;
  flex: 1;
  position: relative;
  padding-top: ${(props) => (props.header ? '' : '90px')};
`;

const LandingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 32px;
`;

const TitleWrapper = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const SubtitleWrapper = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${colors.lightBlue};
  border: 1px solid rgba(25, 118, 210, 0.5);
  border-radius: 8px;
  background: none;
  cursor: pointer;
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: 36px;
  :hover {
    background: rgba(25, 118, 210, 0.04);
    border: 1px solid #356df0;
  }
`;

export default LandingPage;
