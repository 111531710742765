import { Typography } from '@mui/material';

import {
  QuantityItem,
  LineItemWrapper,
  QuantityWrapper,
  Wrapper,
} from '../styles/styles';
import { ILineItem, useWorkOrderCtx } from '../../../context/WorkOrderContext';
import { IModalData } from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';

type Props = ILineItem & {
  disabled?: boolean;
  orderDetails: IModalData[];
  showReady?: boolean;
};

export const LineItem = ({
  title,
  subtitle,
  orderNr,
  quantity,
  disabled,
  date,
  time,
  orderDetails,
  showReady,
}: Props) => {
  const { setOpenModal, setModalData } = useWorkOrderCtx();

  return (
    <LineItemWrapper
      disabled={disabled}
      onClick={() => {
        setOpenModal(true);
        setModalData(orderDetails);
      }}
    >
      <QuantityWrapper>
        {date && time && (
          <div>
            <Typography fontSize={18} fontWeight={600} color={'#E75524'}>
              {time}
            </Typography>
            <Typography fontSize={14} fontWeight={400} color={'#919EAB'}>
              {date}
            </Typography>
          </div>
        )}
        <Wrapper hasMaxWidth={time !== undefined && date !== undefined}>
          <Typography fontSize={16} fontWeight={500}>
            {title}
          </Typography>
          <Typography fontSize={14} fontWeight={400} color={'#919EAB'}>
            {subtitle}
          </Typography>
        </Wrapper>
      </QuantityWrapper>
      <QuantityWrapper>
        <div>
          <Typography fontSize={14} fontWeight={400} color={'#919EAB'}>
            {orderNr}
          </Typography>
          {showReady && (
            <Typography
              fontSize={14}
              fontWeight={400}
              style={{
                background: tyrioUI.colors.green.B90,
                width: '60px',
                borderRadius: '8px',
                textAlign: 'center',
              }}
              color={'white'}
              component={'div'}
            >
              READY
            </Typography>
          )}
        </div>

        <QuantityItem>{quantity}</QuantityItem>
      </QuantityWrapper>
    </LineItemWrapper>
  );
};
