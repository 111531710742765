import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React from 'react';
import { spacing } from '../index';

export interface TyrioTabProps {
  title: string;
  children: React.ReactNode;
  selectTab?: (n: number) => void;
  initial?: boolean;
  errored?: boolean;
  disabled?: boolean;
  isVisible?: boolean;
}

export const TyrioTab = ({
  children,
  selectTab: _selectTab,
}: TyrioTabProps) => {
  return <Tab>{children}</Tab>;
};

const Tab = styled(motion.div)`
  padding-top: ${spacing.xl}px;
  width: 100%;
`;
