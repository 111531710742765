import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
import {
  genericGenerateSpecialMarkings,
  splitSpecialMarkingString,
} from './operations.helpers';

export function generateMichelinSpecialMarkings(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateMichelinSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = [input['PROD_INFO'], input['RUN_FLAT']].join(' ');

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => input['RUN_FLAT'] === 'ZP',
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }
  const splitSpecialMarkings = splitSpecialMarkingString(
    data.product['specialMarking']
  );

  // if (input['FLANK'] === 'Y') {
  //   splitSpecialMarkings.push('FR');
  // }

  if (input['DESCRIPTION_1'].toUpperCase().includes(' AC')) {
    splitSpecialMarkings.push('ACOUSTIC');
  }

  if (
    input['DESCRIPTION_1'].toUpperCase().includes(' SS') ||
    input['DESCRIPTION_1'].toUpperCase().includes(' SF')
  ) {
    splitSpecialMarkings.push('SELFSEAL');
  }

  if (
    input['DESIGN_1'].toUpperCase().includes('PILOT SPORT EV') ||
    input['DESIGN_1'].toUpperCase().includes('E PRIMACY')
  ) {
    splitSpecialMarkings.push('ELECTRIC');
  }

  data.product['specialMarking'] = [...new Set(splitSpecialMarkings)]
    .join(' ')
    .replace(/ +(?= )/g, '');

  return data;
}
