/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  VehicleSteps,
  useVehicleCtx,
} from '../../../../context/VehicleContext';
import { Brand } from './Brand';
import { Model } from './Model';
import { Generation } from './Generation';
import { Modifications } from './Modifications';
import { SearchFilter } from './WheelsSearchFilter/SearchFilter';

const FieldComponent: Record<string, () => JSX.Element> = {
  [VehicleSteps.BRAND]: Brand,
  [VehicleSteps.MODEL]: Model,
  [VehicleSteps.GENERATIONS]: Generation,
  [VehicleSteps.MODIFICATIONS]: Modifications,
  [VehicleSteps.WHEELS_FILTER]: SearchFilter,
};

export const SearchVehicleStep = () => {
  const { activeStep } = useVehicleCtx();

  if (
    !activeStep ||
    !Object.keys(FieldComponent).find((a) => a === activeStep)
  ) {
    return React.createElement(FieldComponent['BRAND']);
  }

  return React.createElement(FieldComponent[activeStep]);
};
