import { Button } from '@mui/material';
import { DBTransferOrderType } from '@prisma/client';
import { UnselectedRowWarning, wmsIcons } from '@tyrio/wms-ui-library';
import _, { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { CartContext } from '../../../../context/CartContext';
import { StockListContext } from '../../../../context/StockListContext';
import { useWS } from '../../../../context/WSContext';
import { CartToast, customToast } from '../Cart/CartToast';
import { InboundOutboundOrders } from '../InboundOrders/InboundOrders';
import { StyledDivider } from '../InboundOrders/InboundOrders.style';
import BranchDropdown from './BranchDropdown';
import { formDataForCart } from './helper/helper';
import { ButtonWrapper } from './styles/Warehouse.style';
import { Warehouse } from './Warehouse';

export const OutboundOrder = () => {
  const {
    outboundOrdersFilter,
    setOutboundOrdersFilter,
    selectedTableRow,
    outboundOrders,
    outboundOrderItems,
    filterValues,
    outboundError,
  } = useContext(StockListContext);
  const { timer } = useContext(CartContext);

  const ws = useWS();
  const warehouseId =
    filterValues !== undefined ? filterValues['warehouseId'] : '';

  const branches = useMemo(() => {
    return outboundOrders.slice(1, outboundOrders.length).map((m) => {
      return { label: m.branchName, value: m.branchId };
    });
  }, [outboundOrders]);

  const handleAddToCart = () => {
    if (!outboundError) {
      const send = formDataForCart(outboundOrderItems);

      ws.socket?.emit('outbound-add-to-cart', {
        outboundItems: send,
      });

      if (!_.isEmpty(outboundOrderItems))
        customToast('Product added to cart!', 'success');
      else customToast('You have to add products!', 'error');
      timer?.restart();
    } else customToast('Data has errors!', 'error');
  };

  const renderChild = () => {
    if (
      selectedTableRow === null ||
      selectedTableRow?.branchId !== Number(warehouseId)
    ) {
      return (
        <UnselectedRowWarning icon={wmsIcons.outbound} text="outbound orders" />
      );
    } else if (isEmpty(outboundOrders)) {
      return (
        <UnselectedRowWarning
          icon={wmsIcons.outbound}
          emptyData="No available items for this product."
        />
      );
    } else {
      return (
        <>
          <CartToast />
          <div style={{ height: 'calc(100% - 100px)', overflow: 'scroll' }}>
            <BranchDropdown
              attributeKey="branchId"
              label="Branch"
              values={branches}
              filters={outboundOrdersFilter}
              setFilters={setOutboundOrdersFilter}
            />
            <Warehouse />
            <StyledDivider />

            <InboundOutboundOrders type={DBTransferOrderType.OUTBOUND} />
          </div>

          <ButtonWrapper>
            <StyledDivider />
            <Button
              color="info"
              variant="contained"
              sx={{ width: '380px', height: '60px', marginTop: '10px' }}
              onClick={handleAddToCart}
              disableElevation
            >
              Add to cart
            </Button>
          </ButtonWrapper>
        </>
      );
    }
  };
  return (
    <SideMenuLayout
      type="outbound"
      children={renderChild()}
      viewBox={'0 0 20 19'}
    />
  );
};
