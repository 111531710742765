import * as z from "zod"
import { DBOrderStatus, DBDeliveryTypeEnum, DBSalesChannel, DBConnectionProtocol } from ".prisma/client"
import { CompleteDBSupplier, RelatedDBSupplierModel, CompleteDBClientSupplierOrder, RelatedDBClientSupplierOrderModel, CompleteDBOrderDocument, RelatedDBOrderDocumentModel, CompleteDBClient, RelatedDBClientModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBClientSupplierOrderItemModel = z.object({
  id: z.string(),
  orderStatus: z.nativeEnum(DBOrderStatus),
  orderNumber: z.string(),
  orderNumberPrefix: z.string().nullish(),
  orderNumberSufix: z.string().nullish(),
  supplierId: z.string(),
  reference: z.string().nullish(),
  orderReference: z.string().nullish(),
  deliveryAddress: jsonSchema,
  deliveryType: z.nativeEnum(DBDeliveryTypeEnum),
  estimatedDeliveryDateFrom: z.date().nullish(),
  estimatedDeliveryDateTo: z.date().nullish(),
  orderedAt: z.date(),
  supplierRemark: z.string().nullish(),
  remark: z.string().nullish(),
  lineItems: jsonSchema,
  orderLineItemMeta: jsonSchema,
  metaDeliveryLate: z.boolean(),
  metaOrderForStock: z.boolean(),
  metaOrderDropShipping: z.boolean(),
  trackingInfo: jsonSchema,
  orderId: z.string(),
  cancelReason: z.string().nullish(),
  emailConfig: jsonSchema,
  subtotal: z.number(),
  ecoTax: z.number(),
  delivery: z.number(),
  total: z.number(),
  clientId: z.string(),
  salesChannel: z.nativeEnum(DBSalesChannel),
  connectionProtocol: z.nativeEnum(DBConnectionProtocol),
  favoritedBy: z.string().array(),
  branchId: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  customerOrderId: z.string().nullish(),
  lastEditedBy: jsonSchema,
})

export interface CompleteDBClientSupplierOrderItem extends z.infer<typeof DBClientSupplierOrderItemModel> {
  supplier: CompleteDBSupplier
  order: CompleteDBClientSupplierOrder
  DBOrderDocument: CompleteDBOrderDocument[]
  client: CompleteDBClient
  branch: CompleteDBBranch
  customerOrder?: CompleteDBCustomerOrderItem | null
}

/**
 * RelatedDBClientSupplierOrderItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientSupplierOrderItemModel: z.ZodSchema<CompleteDBClientSupplierOrderItem> = z.lazy(() => DBClientSupplierOrderItemModel.extend({
  supplier: RelatedDBSupplierModel,
  order: RelatedDBClientSupplierOrderModel,
  DBOrderDocument: RelatedDBOrderDocumentModel.array(),
  client: RelatedDBClientModel,
  branch: RelatedDBBranchModel,
  customerOrder: RelatedDBCustomerOrderItemModel.nullish(),
}))
