import React, { useContext } from 'react';
import { TextField } from '@mui/material';
import { PriceComparisonContext } from '../context';

interface Props {
  clientSupplierId: string;
}
export const OrderRemarkFields = ({ clientSupplierId }: Props) => {
  const ctx = useContext(PriceComparisonContext);
  return (
    <>
      <TextField
        label="Supplier remark"
        value={ctx.data?.supplierRemarks?.[clientSupplierId]}
        onChange={(e) =>
          ctx.setInputValue({
            supplierRemarks: {
              ...(ctx.data.supplierRemarks ?? {}),
              [clientSupplierId]: e.target.value,
            },
          })
        }
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Internal remark"
        value={ctx.data?.internalRemarks?.[clientSupplierId]}
        onChange={(e) =>
          ctx.setInputValue({
            internalRemarks: {
              ...(ctx.data.internalRemarks ?? {}),
              [clientSupplierId]: e.target.value,
            },
          })
        }
      />
    </>
  );
};
