export interface PrintJobsPayload {
  contentType: string; // PrinterContentType
  content: string;
  printerId?: number;
  branchId?: number;
  documentType?: string; //DocumentType
  functionality?: string; //PrinterFunctionalities
  qty?: number; //number of copies made
  rotate?: number;
}

export enum PrinterContentType {
  PDF_URI = 'pdf_uri',
  PDF_BASE64 = 'pdf_base64',
  RAW_URI = 'raw_uri',
  RAW_BASE64 = 'raw_base64',
}

export enum PrinterFunctionalities {
  WMS_SHIPPING = 'wmsShipping',
  WMS_STOCK_IN = 'wmsStockIn',
  WMS_DISPATCH = 'wmsDispatch',
  WMS_FITTING = 'wmsFitting',
  WMS_COLLECTION = 'wmsCollection',
  WMS_DECLARATION = 'wmsDeclaration',
}

export enum DocumentType {
  A4_DOCUMENTS = 'a4Documents',
  POS_RECEIPTS = 'posReceipts',
  THERMAL_LABELS = 'thermalLabels',
}

interface PrinterResponse {
  capabilities?: {
    bins: string[];
    collate: boolean;
    color: boolean;
    copies: number;
    dpis: string[];
    duplex: boolean;
    extent: number[][];
    medias: string[];
    nup: number[];
    papers: Record<string, number[]>;
    printrate: {
      rate: number;
      unit: string;
    };
    supports_custom_paper_size: boolean;
  };
  computer: {
    createTimestamp: string;
    hostname: string;
    id: number;
    inet: string;
    inet6: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jre: any;
    name: string;
    state: string;
    version: string;
  };
  createTimestamp: string;
  default: boolean;
  description?: string;
  id: number;
  name: string;
  state: string;
}

interface PrintersApi {
  list: {
    request: never;
    requestBody: never;
    response: PrinterResponse[];
  };

  create: {
    request: PrintJobsPayload;
    requestBody: PrintJobsPayload;
    response: number;
  };
}

export { PrintersApi };
