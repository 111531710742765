import { Grid } from '@mui/material';
import Card from './Card';
import NewCard from './NewCard';
interface ProductImagesProps {
  data?: {
    title: string;
    source: string;
    type: string;
  }[];
}

const ProductImages = ({ data }: ProductImagesProps) => {
  return (
    <Grid container spacing={2} padding="16px" paddingRight="0px">
      {data?.map((image) => (
        <Card
          title={image.title}
          source={image.source}
          type={image.type}
          key={image.title}
        />
      ))}
      <NewCard />
    </Grid>
  );
};

export default ProductImages;
