import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TOKEN } from '@tyrio/api-factory';
import { useAuth } from './AuthContext';

import { AdminWSState } from '@tyrio/dto';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { appConfig } from '@tyrio/config';

interface WSAdminContextShape {
  state: AdminWSState;
  ping: () => void;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
}

export const WSAdminContext = React.createContext<WSAdminContextShape>({
  state: {
    admin: {},
    client: {},
  },
  ping: () => null,
  socket: null,
});

const socket = { current: io(appConfig.wsUrl) };

const useWSContextData = (): WSAdminContextShape => {
  const { user } = useAuth();
  const [state, setState] = useState<AdminWSState>({
    admin: {},
    client: {},
  });

  // const socket = useRef(io(appConfig.wsUrl));

  const handleMessage = useCallback((event: AdminWSState): void => {
    setState(event);
  }, []);

  const ping = useCallback(() => {
    socket.current.emit('ping');
  }, []);

  useEffect(() => {
    const s = socket.current;
    s.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    if (user && !s.connected) {
      s.on('connect', () => {
        s.emit('identify', { token: TOKEN.get() });
      });
      s.on('data', handleMessage);
    } else if (user && s.connected) {
      s.emit('identify', { token: TOKEN.get() });
      s.on('data', handleMessage);
    } else if (!user && socket.current.connected) {
      s.disconnect();
    }
  }, [handleMessage, user]);

  return { state, ping, socket: socket.current };
};

export const WSAdminProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const data = useWSContextData();
  return (
    <WSAdminContext.Provider value={data}>{children}</WSAdminContext.Provider>
  );
};

export const useWSAdmin = () => useContext(WSAdminContext);
