import { TRule } from '../../typings';

export const ZPERFORMANCE_STOCKLIST_RULESET: TRule[] = [
  // {
  //   type: 'rule.map',
  //   sourceKey: "c.['EANCode']",
  //   destination: 'product',
  //   targetKey: 'ean',
  // },
  {
    type: 'rule.map',
    sourceKey: "c.['Articlenumber']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Quantity']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
