import styled from '@emotion/styled';
import { DeliveryType } from '@prisma/client';
import {
  DBDeliveryTypesApi,
  DefaultPrices,
  DefaultSettings,
  DefaultSurcharges,
} from '@tyrio/dto';
import SourceContainer, { TyrioFormError } from '@tyrio/forms';
import {
  CancelModal,
  DeleteModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
} from '@tyrio/ui-library';
import _, { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { PageTemplateContainer } from '../../../components/PageTemplate/PageTemplate';
import { initialValues } from '../../../features/delivery-method/constants/initial-values';
import { DeliveryMethodValidation } from '../../../features/delivery-method/constants/zod-validation';
import { DeliveryMethodForm } from '../../../features/delivery-method/DeliveryMethodForm';
import { PriceList } from '../../../features/delivery-method/PriceList';
import { useUpdateActiveDeliveryMethod } from '../../../features/delivery-method/querys/change-status';
import { useCreateDeliveryMethod } from '../../../features/delivery-method/querys/create';
import { useGetDeliveryTypeById } from '../../../features/delivery-method/querys/get-one';
import { useUpdateDeliveryMethod } from '../../../features/delivery-method/querys/update-one';
import { TitleWrapper } from '../../../features/services/components/TitleWrapper';

export const DeliveryPage = ({ isNew }: { isNew: boolean }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { data } = useGetDeliveryTypeById(id);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { updateActiveStatusDeliveryMethod } =
    useUpdateActiveDeliveryMethod(queryClient);

  const { createDelivery } = useCreateDeliveryMethod(queryClient);
  const { updateDeliveryMethod } = useUpdateDeliveryMethod(queryClient);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
    watch,
    reset,
  } = useForm({
    defaultValues: { ...initialValues(data) } as FieldValues,
    resolver: (formValues) => {
      const errors: Record<string, TyrioFormError> = {};
      const validationResult = DeliveryMethodValidation.safeParse(formValues);

      // Check if there are errors
      if (validationResult.success === false) {
        const paths: string[] = [];

        validationResult.error.errors.forEach((error) => {
          const path = error.path[0].toString();
          paths.push(path);
          errors[path] = {
            message: 'This field is required',
            type: 'invalid_type',
            ref: null,
          };
        });
      }

      return {
        values: formValues,
        errors,
      };
    },
  });

  const deliveryType = watch('deliveryTypeEnum');

  const handleStatusChange = () => {
    updateActiveStatusDeliveryMethod({ id, active: !data?.isActive });
  };

  const doCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else history.push('/dashboard/company-settings/delivery-method');
  };

  const onSubmit = (payload: FieldValues) => {
    const defaultSettings: DefaultSettings = {
      daysToDeliver: payload['daysToDeliver'],
      maxDaysToDeliver: payload['maxDaysToDeliver'],
      minOrderQuantity: payload['minOrderQuantity'],
      maxPackageWeight: payload['maxPackageWeight'],
      cashOnDelivery: payload['cashOnDelivery'],
      cardOnDellivery: payload['cardOnDellivery'],
      blacklistZIPCodes: payload['blacklistZIPCodes'],
      whitelistZIPCodes: payload['whitelistZIPCodes'],
    };
    const defaultPrices: DefaultPrices = {
      priceType: payload['priceType'],
      deliveryPrice: payload['deliveryPrice'],
      freeDelivery: payload['freeDelivery'],
      minOrderAmount: payload['minOrderAmount'],
      minOrderQuantity2: payload['minOrderQuantity2'],
    };
    const defaultSurcharges: DefaultSurcharges = {
      CODSurcharge: payload['CODSurcharge'],
      CCODSurcharge: payload['CCODSurcharge'],
      minQuantitySurcharge: payload['minQuantitySurcharge'],
    };

    const body = {
      ...payload,
      deliveryLocation:
        deliveryType === DeliveryType.WMS_SHIPPING
          ? payload['deliveryLocation']
          : '',
      dbDeliveryCouriers:
        deliveryType === DeliveryType.WMS_SHIPPING
          ? payload['dbDeliveryCouriers']
          : [],
      defaultSettings,
      defaultPrices,
      defaultSurcharges,
      zones: payload['zones'],
    };

    if (isNew) {
      createDelivery(body as DBDeliveryTypesApi['create']['requestBody']);
    } else {
      updateDeliveryMethod({
        ...body,
        id: id,
      } as DBDeliveryTypesApi['updateOne']['requestBody']);
    }
  };

  useEffect(() => {
    if (Object.values(errors)?.length > 0)
      ToastHelper.showToast(
        'Delivery method',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        Object.values(errors)?.length === 1
          ? 'Field ' + startCase(Object.keys(errors)[0]) + ' has errors.'
          : 'Fields ' +
              Object.keys(errors).map((err) => ' ' + startCase(err)) +
              ' have errors.'
      );
  }, [errors, handleSubmit]);

  useEffect(() => {
    reset(initialValues(data));
  }, [data, reset]);

  const priceListRequiredFields =
    deliveryType === DeliveryType.WMS_FITTING
      ? []
      : [
          'daysToDeliver',
          'maxDaysToDeliver',
          'maxPackageWeight',
          'minOrderQuantity',
        ];

  const mainTabRequiredFields = [
    'code',
    'name',
    'deliveryTypeEnum',
    'deliveryLocation',
  ];

  const keys = Object.keys(errors);

  const errorInPriceList = !_.isEmpty(
    _.intersection(keys, priceListRequiredFields)
  );

  const errorInMainTab = !_.isEmpty(
    _.intersection(keys, mainTabRequiredFields)
  );

  return (
    <PageTemplateContainer style={{ height: '100%' }}>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            history.push('/dashboard/company-settings/delivery-method');
            setIsCancelModalVisible(false);
          }}
        />
      )}
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => setIsDeleteModalVisible(false)}
          itemName={''}
        />
      )}
      <TitleWrapper
        title={isNew ? 'Create new delivery method' : data?.name}
        onChangeStatus={handleStatusChange}
        active={isNew ? true : data?.isActive}
      />
      <div style={{ overflow: 'auto', height: '100%' }}>
        <TyrioTabs>
          <TyrioTab title={t('Main')} key="main" errored={errorInMainTab}>
            <DeliveryMethodForm
              isNew={isNew}
              deliveryType={deliveryType}
              register={register}
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
            />
          </TyrioTab>

          <TyrioTab
            title={t('Price List')}
            key="price-list"
            errored={errorInPriceList}
          >
            <PriceList
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              zonesData={initialValues(data).zones}
            />
          </TyrioTab>
        </TyrioTabs>
      </div>
      <SourceContainerDivider>
        <SourceContainer
          data={{
            data_source: '',
            created: '',
            last_edited: '',
          }}
          onCancel={doCancel}
          onDelete={() => setIsDeleteModalVisible(true)}
          onSubmit={handleSubmit(onSubmit)}
          customStyle={{
            zIndex: 1,
            bottom: '16px',
            paddingBottom: '16px',
          }}
          disabledDelete={true}
        />
      </SourceContainerDivider>
    </PageTemplateContainer>
  );
};

const SourceContainerDivider = styled.div`
  position: sticky;
  top: 100%;
  height: 150px;
`;
