import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const INTERTYRE_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'category_id',
    template: '72',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['group description']",
    condition: 'contains',
    conditionValue: 'STAAL',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '73',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: "c['brand description']",
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['brand description']",
    condition: 'contains',
    conditionValue: 'INTER ACTION',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'brand',
    outputValue: 'INTER ACTION',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.map',
    sourceKey: 'c.type',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.winterwheel',
    condition: 'equals',
    conditionValues: ['Y'],
    conditionOutputs: ['winter'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.eancode',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.itemcode',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.width',
    matchValue: ['.0'],
    replaceValue: [''],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.diameter',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  // Instalation kit
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'partialKit',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["brands specific"]',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'installationKit',
    outputValue: 'oem',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["brands blocked"]',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'installationKit',
    outputValue: 'oem',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'oem',
    outputType: 'string',
    applyOnlyForCategories: [73],
  },
  // {
  //   type: 'rule.template',
  //   destination: 'product',
  //   targetKey: 'pcd',
  //   template: '#{c.LochAnzahl}x#{c.Lochkreis}',
  // },
  {
    type: 'rule.template',
    template: '#{c.PCD},#{c.PCD2}',
    targetKey: 'pcd',
    destination: 'product',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.offset',
    targetKey: 'et',
    delimiter: ',',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.centerhole',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.colour',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  // Weight
  {
    type: 'rule.map',
    sourceKey: 'c.weight',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  // Images
  {
    type: 'rule.map',
    sourceKey: 'c.photolink',
    targetKey: 'originalPhotos',
    destination: 'model',
    outputType: 'string',
  },
  ...GLOBAL_RULESET,
];
