import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { DELDO_CATALOG_RULESET } from './deldo/catalog';
import { DELDO_PRICELIST_RULESET } from './deldo/pricelist';
import { DELDO_STOCKLIST_RULESET } from './deldo/stocklist';

export const DELDO_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: DELDO_CATALOG_RULESET,

  pricelistRuleset: DELDO_PRICELIST_RULESET,
  stockRuleset: DELDO_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'DELDO',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    Type: {
      '(DOT 2022)': '',
      '(DEMO)': '',
      DEMO: '',
      'DOT 2022': '',
      '()': '',
      '*': ' * ',
    },
    'Manufacturer code': {
      '-demo': '',
      '-DEMO': '',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    // console.log('READ FILE', file);
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter((item) => item['Vehicle'] !== 'Truck'),
      this.preprocessKeys
    );
  },
};
