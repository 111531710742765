/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, LinearProgress } from '@mui/material';
import Box from '@mui/system/Box/Box';
import { tyrioUI } from '@tyrio/ui-library';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PermissionsValues } from '../types';

interface SourceContainerProps {
  loading?: boolean;
  permissions?: PermissionsValues;
  data: {
    data_source: string;
    created: string;
    last_edited: string;
  };
  disabledDelete?: boolean;
  onDelete?: () => void;
  onCancel: () => void;
  onSubmit?: () => void;
  disabledCancel?: boolean;
  disableSave?: boolean;
  cantDelete?: boolean;
  customStyle?: Record<string, string | number>;
}

const SourceContainer = ({
  data,
  loading,
  permissions,
  disabledDelete,
  onDelete,
  onCancel,
  onSubmit,
  disabledCancel,
  disableSave,
  cantDelete,
  customStyle,
}: SourceContainerProps) => {
  const { t } = useTranslation();

  let isCreate = false;
  const editPermission = permissions ? !permissions.edit : false;
  const deletePermission = permissions ? !permissions.delete : false;
  const createPermission = permissions ? !permissions.create : false;

  const location = useLocation();
  const locationArray = location.pathname.split('/');
  if (locationArray[locationArray.length - 1] === 'new') isCreate = true;

  return (
    <Wrapper style={customStyle}>
      {/*
      TODO: Uncomment this after implementing log system 
      <SourceWrapper>
        <SourceColumn>
          <SourceHeader>Data Source:</SourceHeader>
          <SourceUserData>{data.data_source}</SourceUserData>
        </SourceColumn>
        <SourceColumn>
          <SourceHeader>Created:</SourceHeader>
          <SourceUserData>{data.created} </SourceUserData>
        </SourceColumn>
        <SourceColumn>
          <SourceHeader>Last Edited:</SourceHeader>
          <SourceUserData>{data.last_edited} </SourceUserData>
        </SourceColumn>
        <SourceColumn>
          <MoreVertIcon sx={{ opacity: 0.6 }} />
        </SourceColumn>
      </SourceWrapper> */}
      {loading ? <LoadingDivider color="success" /> : <Divider />}
      <ControlsContainter>
        <SourceContainerButton
          data-cy={'delete'}
          color="error"
          type="button"
          onClick={onDelete}
          disabled={disabledDelete || deletePermission || cantDelete}
        >
          {t('Delete')}
        </SourceContainerButton>

        <ButtonContainer>
          <SourceContainerButton
            data-cy={'cancel'}
            color="info"
            variant="outlined"
            style={{ marginRight: '16px' }}
            startIcon={<CancelIcon />}
            onClick={onCancel}
            disabled={disabledCancel}
          >
            {t('Cancel')}
          </SourceContainerButton>

          <SourceContainerButton
            data-cy={'save'}
            color="info"
            variant="contained"
            disabled={
              (isCreate ? createPermission : editPermission) || disableSave
            }
            type={onSubmit ? 'button' : 'submit'}
            onClick={onSubmit}
            disableElevation
          >
            {t('Save')}
          </SourceContainerButton>
        </ButtonContainer>
      </ControlsContainter>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  bottom: ${tyrioUI.spacing.l}px;
  left: ${tyrioUI.spacing.l}px;
  right: ${tyrioUI.spacing.l}px;
  height: fit-content;
`;

// const SourceWrapper = styled.div`
//   height: 56px;
//   background: #f4f6f8;
//   border-radius: 5px;
//   display: flex;
//   justify-content: space-between;
//   padding: ${tyrioUI.spacing.l}px;
// `;

// const SourceColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
// `;

// const SourceHeader = styled.div`
//   color: #919eab;
//   font-size: 14px;
//   font-weight: 500;
// `;

// const SourceUserData = styled.div`
//   color: #c4cdd5;
//   font-weight: 400;
//   font-size: 12px;
// `;

const ButtonContainer = styled.div`
  width: 230px;
  display: flex;

  @media (max-width: 880px) {
    width: 100%;
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    width: 230px;
    margin-top: 0;
  }

  @media (max-width: 450px) {
    width: 100%;
    margin-top: 16px;
  }
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #dfe3e8;
`;

const LoadingDivider = styled(LinearProgress)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ControlsContainter = styled.div`
  display: flex;
  width: 100%;
  padding: ${tyrioUI.spacing.l};
  justify-content: space-between;
  align-items: center;

  @media (max-width: 880px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const SourceContainerButton = styled(Button)`
  font-weight: 500;
  width: 100px;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  padding: 8px 22px 8px 22px;

  @media (max-width: 880px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export default SourceContainer;
