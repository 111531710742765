import api from '@tyrio/api-factory';
import { CheckPriceRes, PriceCalculationApi } from '@tyrio/dto';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface GetPriceProps {
  setData: (data: CheckPriceRes) => void;
  setErrorText: (val: string) => void;
}

export const useGetPrice = (props: GetPriceProps) => {
  const { setData, setErrorText } = props;

  const getPrice = useMutation(
    (req: PriceCalculationApi['create']['request']) =>
      api.fetch<PriceCalculationApi['create']>('get_price_cal_by_ean', {
        ...req,
      }),
    {
      mutationKey: 'get_price_cal_by_ean',
      onSuccess: (data) => {
        setData(data);
      },
      onError: (error) => {
        const errorData = error as AxiosError<unknown>;
        setErrorText((errorData.response?.data as ErrorProps)?.error?.name);
      },
    }
  );

  return { getPrice };
};

interface ErrorProps {
  error: { name: string };
}
