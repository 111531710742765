import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const VANDENBAN_CATALOG_RULESET: TRule[] = [
  // DEFAULT VALUES
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'pr',
    template: '',
  },
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'sidewall',
    template: '',
  },
  // ACTUAL VALUES
  {
    type: 'rule.condition',
    sourceKey: 'c.Vehicle',
    condition: 'equals',
    conditionValue: 'Personenwagen',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '30',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Vehicle',
    condition: 'equals',
    conditionValue: '4x4',

    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Vehicle',
    condition: 'equals',
    conditionValue: 'Light Truck',

    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '32',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Vehicle',
    condition: 'equals',
    conditionValue: 'Vrachtwagen',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '50',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Season',
    condition: 'equals',
    conditionValue: 'Zomer',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'summer',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Season',
    condition: 'equals',
    conditionValue: 'Winter',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'winter',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Season',
    condition: 'equals',
    conditionValue: 'All Season',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'allseason',
    outputType: 'string',
  },
  //"Type - izbaci iz naziva sve oznake koje imamo pod special marking i rfd
  // npr. U Type imamo value PREMIUM 6 VOL FR XL - kad izbacimo sve oznake ostane nam PREMIUM 6"
  {
    type: 'rule.map',
    sourceKey: 'c.Type',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  // Usage
  {
    type: 'rule.condition',
    sourceKey: 'c.Application',
    condition: 'contains',
    conditionValue: '00',
    outputValue: 'Worksite',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'usage',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Application',
    condition: 'contains',
    conditionValue: 'RH',
    outputValue: 'Regional',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'usage',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Application',
    condition: 'contains',
    conditionValue: 'LH',
    outputValue: 'Highway',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'usage',
    outputType: 'string',
  },
  // TODO: this seems broken
  {
    type: 'rule.condition',
    sourceKey: 'c.Application',
    condition: 'contains',
    conditionValue: 'CI',
    outputValue: 'Urban',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'usage',
    outputType: 'string',
  },
  // Wheelposition
  {
    type: 'rule.condition',
    sourceKey: 'c.Application',
    condition: 'startsWith',
    conditionValue: 'DR',
    outputValue: 'drive',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Application',
    condition: 'startsWith',
    conditionValue: 'ST',
    outputValue: 'steer',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Application',
    condition: 'startsWith',
    conditionValue: 'TR',
    outputValue: 'trailer',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c['EAN code']",
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c['Manufacturer code']",
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Height',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Weight',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Inch',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'construction',
    template: 'R',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'productName2',
    template: 'MANUAL INPUT',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.LI',
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.SI',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'getVandenbanSpecialMarkingsAndModelName',
  },
  // ThreePMFs
  {
    type: 'rule.condition',
    sourceKey: 'c.Snow',
    condition: 'equals',
    conditionValue: 'Ja',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'threepmfs',
    outputValue: true,
    outputType: 'boolean',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Snow',
    condition: 'equals',
    conditionValue: 'Nee',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'threepmfs',
    outputValue: false,
    outputType: 'boolean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Roll resistance"]',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },

  // Globalni fieldovi
  {
    type: 'rule.map',
    sourceKey: 'c["Braking distance"]',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Noise Db"]',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Sound type"]',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Label type"]',
    targetKey: 'vehicleClass',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["EPREL-ID"]',
    targetKey: 'eprelId',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["EPREL-link"]',
    targetKey: 'eprelUrl',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.operate',
  //   sourceKey: 'c["ImageURL"]',
  //   targetKey: 'originalPhotos',
  //   destination: 'model',
  //   operationName: 'mapImages',
  // },

  {
    type: 'rule.map_array',
    sourceKey: 'c["ImageURL"]|c["ImageURL2"]|c["ImageURL3"]',
    targetKey: 'originalPhotos',
    delimiter: '|',
    destination: 'model',
  },

  // Fields requiring other fields being populated

  ...GLOBAL_RULESET,
];
