import { FunctionComponent, SVGProps, useState } from 'react';
import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { cartIcons, tyrioUI } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import { formatCurrencyWithSymbol } from '../../../../../../../helpers/currency-format';

// TODO: refactor this component so it's more reusable
interface AddressCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: string;
  details: string;
  onClick: () => void;
  disabled: boolean;
  deliveryIcon?: string;
  price?: number;
  showDeliveryDetails?: boolean;
  detailsText?: string;
  showEditIcon?: boolean;
  borderStyle?: 'solid' | 'dashed';
  disableMaxWidth?: boolean;
  height?: string;
  fontSize?: string;
}

export const AddressCard = ({
  icon,
  text,
  onClick,
  disabled,
  details,
  deliveryIcon,
  price,
  showDeliveryDetails = false,
  detailsText,
  showEditIcon = true,
  borderStyle = 'solid',
  disableMaxWidth = false,
  height,
  fontSize,
}: AddressCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Wrapper
      disabled={disabled}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => !disabled && onClick()}
      borderStyle={borderStyle}
      disableMaxWidth={disableMaxWidth}
      height={height}
    >
      <InfoWrapper>
        <AddressWrapper>
          <WmsIcon
            icon={icon}
            sx={{ color: isHovered ? '#1976D2' : tyrioUI.colors.black.B40 }}
            viewBox={'0 0 32 32'}
            stroke={isHovered ? '#1976D2' : tyrioUI.colors.black.B40}
          />
          <Text fontSize={fontSize}>{text}</Text>
        </AddressWrapper>
        {showEditIcon && (
          <WmsIcon
            icon={cartIcons.editAddress}
            sx={{
              color: isHovered ? '#1976D2' : tyrioUI.colors.black.B40,
              width: '24px',
              height: '24px',
            }}
            viewBox={'0 0 24 24'}
            onClick={onClick}
            stroke={isHovered ? '#1976D2' : tyrioUI.colors.black.B40}
          />
        )}
      </InfoWrapper>
      {!showDeliveryDetails ? (
        <AddressDetails>
          <AddressDetailsText fontSize={fontSize}>{details}</AddressDetailsText>
        </AddressDetails>
      ) : (
        <DeliveryMethodWrapper>
          <AddressDetailsText>{details}</AddressDetailsText>
          {(deliveryIcon || price !== 0) && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {deliveryIcon && (
                <Image
                  src={deliveryIcon}
                  alt={'delivery-icon'}
                  style={{ width: '72px', height: '35px' }}
                />
              )}
              {price !== 0 && (
                <Price
                  background={price === 0 ? '#3EB274' : '#ECF0F4'}
                  color={price === 0 ? 'white' : 'black'}
                  fontWeight={price === 0 ? '600' : '400'}
                >
                  {price !== 0 ? formatCurrencyWithSymbol(price ?? 0) : 'FREE'}
                </Price>
              )}
            </div>
          )}
          {detailsText !== '' && <Text>{detailsText}</Text>}
        </DeliveryMethodWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  disabled: boolean;
  borderStyle: 'solid' | 'dashed';
  disableMaxWidth: boolean;
  height?: string;
}>`
  border: ${(props) => `1px ${props.borderStyle} #e6e6e6`};
  border-radius: 8px;
  height: ${(props) => (props.height ? props.height : '74px !important')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  cursor: pointer;
  padding: 16px;
  max-width: ${(props) => (props.disableMaxWidth ? '100%' : '485px')};
  width: 100%;
  &:hover {
    border: 1px solid #1976d2;
    background: rgb(25, 117, 210, 0.04);
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const AddressDetails = styled.div`
  display: flex;
  width: 100%;
`;

const Text = styled(Typography)<{ fontSize?: string }>`
  color: #919eab;
  font-family: Barlow;
  font-weight: 400;
  font-size: 12px;
  font-size: ${(props) => (props.fontSize ? '10px' : '12px')};
`;

const AddressDetailsText = styled(Typography)<{ fontSize?: string }>`
  font-family: Barlow;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')}
  overflow-x: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
`;

const Price = styled.div<{
  background: string;
  color: string;
  fontWeight: string;
}>`
  min-width: 69px;
  height: 24px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Barlow;
  font-weight: ${(props) => props.fontWeight};
  font-size: 16px;
  padding: 5px;
`;

const Image = styled.img`
  width: 72px;
  height: 45px;
`;

const DeliveryMethodWrapper = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
