import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { CONTINENTAL_CATALOG_RULESET } from './continental/catalog';
import { CONTINENTAL_PRICELIST_RULESET } from './continental/pricelist';
import { CONTINENTAL_STOCKLIST_RULESET } from './continental/stocklist';

export const CONTINENTAL_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: CONTINENTAL_CATALOG_RULESET,
  pricelistRuleset: CONTINENTAL_PRICELIST_RULESET,
  stockRuleset: CONTINENTAL_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: true,
  preprocessKeys: {
    WEIGHT: {
      ',': '.',
    },
    WIDTH_MM: {
      ',': '.',
    },
    ASPECT_RATIO: { ',': '.' },
    RIM_INCH: { ',': '.' },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
      origin: 'DE',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'CONTINENTAL EDI PRICAT',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return handlePreprocessConversions(
      json.filter(
        (item) => item['PROD_GRP_1'] === '03' || item['PROD_GRP_1'] === '3'
      ),
      this.preprocessKeys
    );
  },
};
