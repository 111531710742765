import React from 'react';
import {
  CompareCodeData,
  CompareDimensionsData,
  PCApiSupplierShape,
  PriceCompareInputShape,
} from '../types';
import { DBPriceCompareApi } from '@tyrio/dto';
import { UseMutateAsyncFunction } from 'react-query';

interface PriceComparisonContextShape {
  mode: 'sales_orders' | 'purchase_orders';
  data: Partial<PriceCompareInputShape>;
  hasPlacedOrders: boolean;
  setInputValue: (
    newData: Partial<PriceCompareInputShape>,
    hardSet?: boolean
  ) => void;
  clearDirtyForm: () => void;
  reset: () => void;
  suppliers: PCApiSupplierShape[];
  isFetchingSuppliers: boolean;
  dimensions: string[];
  isFetchingDimensions: boolean;
  mutateCode: UseMutateAsyncFunction<
    DBPriceCompareApi['compareCode']['response'],
    unknown,
    CompareCodeData,
    unknown
  >;
  mutateDimensions: UseMutateAsyncFunction<
    DBPriceCompareApi['compareCode']['response'],
    unknown,
    CompareDimensionsData,
    unknown
  >;
  processOrder: () => void;
  isFetchingCodeRequest: boolean;
  isFetchingDimensionsRequest: boolean;
  canSidebarOpen: boolean;
}

export const tomorrow = () => {
  // Get today's date
  const today = new Date();
  // Change the date by adding 1 to it (today + 1 = tomorrow)
  today.setDate(today.getDate() + 1);
  // return yyyy-mm-dd format
  return today.toISOString().split('T')[0];
};

export const PriceComparisonContext =
  React.createContext<PriceComparisonContextShape>({
    mode: 'purchase_orders',
    data: {
      reference: '',
      transportPriority: 'RUSH',
      requestedDeliveryCalendarDate: tomorrow(),
    },

    hasPlacedOrders: false,
    suppliers: [],
    isFetchingSuppliers: false,
    dimensions: [],
    isFetchingDimensions: false,
    setInputValue: () => null,
    clearDirtyForm: () => null,
    reset: () => null,
    mutateCode: () => new Promise((res) => res),
    mutateDimensions: () => new Promise((res) => res),
    processOrder: () => null,
    isFetchingCodeRequest: false,
    isFetchingDimensionsRequest: false,
    canSidebarOpen: false,
  });
