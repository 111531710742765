import * as z from "zod"
import { CompleteDBClientSupplierOrderItem, RelatedDBClientSupplierOrderItemModel } from "./index"

export const DBClientSupplierOrderModel = z.object({
  id: z.string(),
  orderNumber: z.number().int(),
})

export interface CompleteDBClientSupplierOrder extends z.infer<typeof DBClientSupplierOrderModel> {
  orders: CompleteDBClientSupplierOrderItem[]
}

/**
 * RelatedDBClientSupplierOrderModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientSupplierOrderModel: z.ZodSchema<CompleteDBClientSupplierOrder> = z.lazy(() => DBClientSupplierOrderModel.extend({
  orders: RelatedDBClientSupplierOrderItemModel.array(),
}))
