import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
// import { GMPITALY_CATALOG_RULESET } from './gmpitaly/catalog';
import { GMPITALY_PRICELIST_RULESET } from './gmpitaly/pricelist';
import { GMPITALY_STOCKLIST_RULESET } from './gmpitaly/stocklist';
import { TRule } from '../typings';

export const GMPITALY_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: GMPITALY_PRICELIST_RULESET,
  stockRuleset: GMPITALY_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'GMPITALY',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {

  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ',' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ',' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ',' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
