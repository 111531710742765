import { MatchOutputType } from './typings';
import { PostProcessHelpers } from './postprocess.helpers';

export const convertOutputValue = (
  output: string | boolean | number | undefined,
  outputType: MatchOutputType,
  postProcess?: Array<keyof typeof PostProcessHelpers>
) => {
  const getOutput = () => {
    switch (outputType) {
      case 'number':
        if (typeof output === 'string') {
          const keys = [',', '.'];
          const split = output.split('').filter((c) => keys.includes(c));

          if (split.length === 0) return Number(output);
          const decimalSeparator = split[split.length - 1];

          if (split.length === 1) {
            if (decimalSeparator === ',')
              return Number(output.replace(',', '.'));
          }

          if (split.length > 1) {
            if (decimalSeparator === ',')
              return Number(
                output
                  .replace(',', '{{}}')
                  .replace('.', '')
                  .replace('{{}}', '.')
              );
          }
        }
        if (typeof output === 'undefined') {
          return undefined;
        }
        return Number(output);
      case 'string':
        return String(output);
      case 'forced_integer':
        if (typeof output !== 'string')
          throw new Error(
            'convertOutputValue: can only convert strings to integers'
          );
        return parseInt(output, 10);
      case 'boolean':
        return !!output;
      default:
        return output;
    }
  };

  if (!postProcess || postProcess.length === 0) return getOutput();

  let processedOutput = getOutput();
  postProcess.forEach((helper) => {
    processedOutput = PostProcessHelpers[helper](output);
  });

  return processedOutput;
};
