import { DBSupplierClientPackageCost } from '@prisma/client';
// import { supplierClientSettings } from './supplier-client-settings';
// import { supplierClientShippingSettings } from './supplier-client-shipping-settings';

export const supplierClientPackageCost: DBSupplierClientPackageCost[] = [
  // {
  //   id: '00000000-0000-0000-0000-000000000001',
  //   supplierClientSettingsId: supplierClientSettings[0].id,
  //   supplierClientShippingSettingsId: supplierClientShippingSettings[0].id,
  //   categoryId: 1,
  //   rimDiameter: [1.33],
  //   width: [5.55],
  //   costPerPackage: 1.5,
  //   createdAt: new Date(),
  // },
];
