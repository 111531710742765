import { RawImport } from '@tyrio/dto';
import { TRule } from '../typings';

import {
  genericGenerateSpecialMarkings,
  splitSpecialMarkingString,
} from './operations.helpers';

export function generateBridgestoneSpecialMarkings(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateContinentalSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = [
    [input['DESCRIPTION_1'], input['DESCRIPTION_2']].join(''),
    input['PROD_INFO'].replace('BO', '*'),
    input['RUN_FLAT'],
  ].join(' ');

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => input['RUN_FLAT'] === 'SSR',
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }
  const splitSpecialMarkings = splitSpecialMarkingString(
    data.product['specialMarking']
  );

  const finalInput =
    input['DESCRIPTION_1']?.trim() ?? '' + input['DESCRIPTION_2']?.trim() ?? '';
  if (
    finalInput.toLowerCase().endsWith('sil') ||
    finalInput.toLowerCase().endsWith('silent')
  ) {
    splitSpecialMarkings.push('B-SILENT');
  }

  if (input['FLANK'] === 'Y') {
    splitSpecialMarkings.push('FSL');
  }

  if (input['DESIGN_1'].toLowerCase().includes('econtact')) {
    splitSpecialMarkings.push('ELECTRIC');
  }

  if (input['DESIGN_1'].toLowerCase().includes('driveguard')) {
    data.product['runFlat'] = true;
  }

  data.product['specialMarking'] = [...new Set(splitSpecialMarkings)]
    .join(' ')
    .replace(/ +(?= )/g, '');

  return data;
}

export function getBridgestoneCategory(input: RawImport, _rule: TRule) {
  if (input['VEHICLE_TYPE'] === 'C0' && input['PROD_GRP_2'].charAt(0) === 'P') {
    // CATEGORY PASSENGER
    return {
      model: {
        category_id: 30,
      },
    };
  }
  if (input['VEHICLE_TYPE'] === 'C0' && input['PROD_GRP_2'].charAt(0) === '4') {
    // CATEGORY SUV
    return {
      model: {
        category_id: 31,
      },
    };
  }
  if (input['VEHICLE_TYPE'] === 'L4') {
    // CATEGORY SUV 31
    return {
      model: {
        category_id: 31,
      },
    };
  }
  if (input['VEHICLE_TYPE'] === 'L0') {
    // CATEGORY SUV 31
    return {
      model: {
        category_id: 32,
      },
    };
  }

  return {};
}
