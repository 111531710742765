import styled from '@emotion/styled';

export const DashedDivider = () => {
  return <Container></Container>;
};

const Container = styled.div`
  width: '100%';
  border: 1px dashed #dfe3e8;
`;
