import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBProductCategory, RelatedDBProductCategoryModel } from "./index"

export const DBFavoriteCategoriesModel = z.object({
  clientId: z.string(),
  categoryId: z.number().int(),
})

export interface CompleteDBFavoriteCategories extends z.infer<typeof DBFavoriteCategoriesModel> {
  client: CompleteDBClient
  category: CompleteDBProductCategory
}

/**
 * RelatedDBFavoriteCategoriesModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBFavoriteCategoriesModel: z.ZodSchema<CompleteDBFavoriteCategories> = z.lazy(() => DBFavoriteCategoriesModel.extend({
  client: RelatedDBClientModel,
  category: RelatedDBProductCategoryModel,
}))
