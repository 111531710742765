import { FunctionComponent, SVGProps } from 'react';
import { AppointmentCard } from './AppointmentCard';
import { InformationCard } from './InformationCard';

interface InformationCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: string;
  onClick: () => void;
  disabled: boolean;
}

export const ListItem = (props: InformationCardProps) => {
  const { text } = props;

  if (text === 'Appointment') return <AppointmentCard {...props} />;

  return <InformationCard {...props} />;
};
