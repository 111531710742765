import { FieldValues, useForm } from 'react-hook-form';
import { PageTemplateContainer } from '../../components/PageTemplate/PageTemplate';
import { renderFields } from '../services/components/RenderFormFields';
import { PaymentMethodFormShape } from './constants/payment-method.form';
import { TitleWrapper } from '../services/components/TitleWrapper';
import SourceContainer from '@tyrio/forms';
import { PaymentTypeEnum } from '@prisma/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { PaymentValidation } from './constants/zod-validation';
import { initialValues } from './constants/initial-values';
import { useHistory, useParams } from 'react-router-dom';
import { useGetPaymentById } from './query/get-by-id';
import { useCallback, useEffect, useState } from 'react';
import { useCreatePaymentMethod } from './query/create';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { omit } from 'lodash';
import { useQueryClient } from 'react-query';
import { DropdownValues } from '../services/constants/service.form';
import { useUpdatePaymentMethod } from './query/update';
import { CancelModal, DeleteModal } from '@tyrio/ui-library';
import { useDeletePaymentMethod } from './query/delete';
import { useUpdateActivePaymentMethod } from './query/update-active-status';

export const PaymentMethodForm = ({ isNew }: { isNew: boolean }) => {
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const queryClient = useQueryClient();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // QUERY
  const { data } = useGetPaymentById(id);

  const defaultValue = useCallback(() => {
    return initialValues(data);
  }, [data]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
    watch,
    reset,
  } = useForm({
    defaultValues: defaultValue() as FieldValues,
    resolver: zodResolver(PaymentValidation),
  });

  const { form, cardPayment } = PaymentMethodFormShape(
    watch('isFiscalizationEnabled'),
    watch('cashDiscount')
  );

  useEffect(() => {
    reset(defaultValue());
  }, [defaultValue, reset]);

  // MUTATIONS
  const { createPayment } = useCreatePaymentMethod(queryClient);
  const { updatePaymentMethod } = useUpdatePaymentMethod(queryClient);
  const { deletePaymentMethod } = useDeletePaymentMethod(queryClient);
  const { updateActiveStatusPaymentMethod } =
    useUpdateActivePaymentMethod(queryClient);

  // VAR
  const paymentType = watch('paymentType');
  const isNoPaymentGateway = watch('paymentGateway') === 'no payment gateway';
  const isBankTransfer = paymentType === PaymentTypeEnum.BANK_TRANSFER;

  const doSubmit = (data: FieldValues) => {
    let acceptedCards = [];
    if (data['cardSettings']?.acceptedCards) {
      acceptedCards = data['cardSettings']?.acceptedCards.map(
        (a: DropdownValues) => a.value
      );
      data['cardSettings'].acceptedCards = acceptedCards;
    }

    if (isNew) {
      createPayment(data as DBPaymentTypesApi['create']['requestBody']);
    } else {
      updatePaymentMethod({
        id: id,
        ...omit(data, 'code'),
      } as DBPaymentTypesApi['updateOne']['requestBody']);
    }
  };

  const doCancel = () => {
    if (isDirty) {
      setIsCancelModalVisible(true);
    } else {
      history.push('/dashboard/company-settings/payment-method');
    }
  };

  const handleDelete = () => {
    deletePaymentMethod({ id });
  };

  const handleStatusChange = () => {
    updateActiveStatusPaymentMethod({ id, active: !data?.isActive });
  };

  return (
    <PageTemplateContainer style={{ height: '95vh' }}>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            history.push('/dashboard/company-settings/payment-method');
            setIsCancelModalVisible(false);
          }}
        />
      )}
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            handleDelete();
            setIsDeleteModalVisible(false);
          }}
          itemName={data?.name ?? ''}
        />
      )}

      <TitleWrapper
        title={data?.name ?? 'Create new payment method'}
        onChangeStatus={handleStatusChange}
        active={isNew ? true : data?.isActive}
      />

      <div style={{ position: 'relative', height: '100%' }}>
        <form
          onSubmit={handleSubmit(doSubmit)}
          key={id}
          style={{ padding: '16px 24px' }}
        >
          {form.map((fields) => {
            // DISABLE code AND paymentType UPDATE
            if (
              (fields.key === 'code_name' || fields.key === 'payment_type') &&
              ['code', 'paymentType'].indexOf(fields.inputs[0].id) > -1 &&
              !isNew
            )
              fields.inputs[0].disabled = true;

            return (
              <div style={{ paddingBottom: '25px' }} key={data?.id}>
                {renderFields(
                  fields,
                  register,
                  control,
                  errors,
                  false,
                  'payment'
                )}
              </div>
            );
          })}

          {paymentType === PaymentTypeEnum.CARD_POS &&
            renderFields(
              cardPayment,
              register,
              control,
              errors,
              isNoPaymentGateway,
              'payment'
            )}

          <SourceContainer
            data={{
              data_source: '',
              created: '',
              last_edited: '',
            }}
            onCancel={doCancel}
            onDelete={() => setIsDeleteModalVisible(true)}
            customStyle={{
              background: 'white',
              zIndex: 1,
              bottom: 0,
              paddingBottom: '16px',
            }}
            disabledDelete={data?.isTyrioDefault}
          />
        </form>
      </div>
    </PageTemplateContainer>
  );
};
