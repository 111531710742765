import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import CustomerRebatesOverview from '../features/customer-rebates/CustomerRebatesOverview';
import CustomerRebates from '../pages/dashboard/CustomerRebates';
export const CustomerRebatesRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <CustomerRebates>
        {location.pathname.split('/').pop() === 'new' ? (
          <Route exact path={`${path}/new`}>
            <CustomerRebatesOverview />
          </Route>
        ) : (
          <Route exact path={`${path}/:rebateId`}>
            <CustomerRebatesOverview />
          </Route>
        )}
      </CustomerRebates>
    </Switch>
  );
};
