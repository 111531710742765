import { defaultEnv } from './default.app';
import { AppConfig } from './config.app';

export const environment: AppConfig = {
  ...defaultEnv,
  production: true,
  apiUrl: 'https://p-api.tyrio-api.link/api',
  appUrl: 'https://p-app.tyrio-api.link/app',
  wsUrl: 'https://p-api.tyrio-api.link',
};
