import styled from '@emotion/styled';
import { Grid, Popover } from '@mui/material';
import { CheckAppointmentsRes } from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import moment from 'moment';
import { useState } from 'react';
import { AppointmentPopover } from './popover/AppointmentPopover';

interface AppointmentDetailsProps {
  appointment: CheckAppointmentsRes;
}

export const AppointmentDetails = (props: AppointmentDetailsProps) => {
  const { appointment } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          left: '-410px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid sx={{ width: '400px', padding: '16px' }}>
          <AppointmentPopover
            appointment={appointment}
            // onClose={handlePopoverClose}
          />
        </Grid>
      </Popover>
      <TimeLocationWrapper>
        <TimeText>
          {moment(appointment.appointmentDateFrom).format('HH:mm')}
        </TimeText>
        <DateDescriptionText>
          {moment(appointment.appointmentDateFrom).format('DD.MM')}
        </DateDescriptionText>
      </TimeLocationWrapper>
      <VehicleInfoCategoryWrapper>
        <CategoryNameText>{appointment.serviceType.name}</CategoryNameText>
        <DateDescriptionText>
          {`${appointment.vehicleInfo.licensePlateNumber} - ${appointment.vehicleInfo.brand} ${appointment.vehicleInfo.model}`}
        </DateDescriptionText>
      </VehicleInfoCategoryWrapper>
    </Container>
  );
};

const Container = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  &:hover {
    background: ${tyrioUI.colors.black.B10};
    border-radius: 8px;
  }
`;

const TimeText = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  color: #212b36;
`;

const DateDescriptionText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const CategoryNameText = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  color: #212b36;
`;

const TimeLocationWrapper = styled.span`
  display: flex;
  width: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VehicleInfoCategoryWrapper = styled.span`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
