import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { Code, DBWarehouseBins } from '@prisma/client';
import _ from 'lodash';
import { useContext } from 'react';
import { WarehouseContext } from '../../../context/WarehouseContext';
import { getCurrent } from '../helper/helper';

interface ZoneCardProps {
  bin: DBWarehouseBins;
}

export const BinCard = (props: ZoneCardProps) => {
  const { selected, setSelected } = useContext(WarehouseContext);

  const { bin } = props;

  const settings = (att: string) => {
    return _.get(bin.settings, att);
  };

  return (
    <ZoneCardContainer
      background={getCurrent(selected, 'color')}
      onClick={() => {
        setSelected({
          ...selected,
          locations: {
            ...selected?.locations,
            bin: { ...bin, code: bin.code as Code },
          },
        });
      }}
    >
      <CardHeader id="header">
        <ZoneColor color={getCurrent(selected, 'color')} />
        <CardHeaderText>BIN {bin.code}</CardHeaderText>
      </CardHeader>
      <DisplayName>{bin.displayName}</DisplayName>
      <ZoneContent>
        <Grid>
          <DescriptionItemWrapper>
            <ZoneShortDetailsLabel>Status:</ZoneShortDetailsLabel>
            <ZoneStatus>
              <ZoneStatusIcon isActive={settings('isActive')} />
              <ZoneShortDetailsValue>
                {settings('isActive') ? 'Active' : 'Inactive'}
              </ZoneShortDetailsValue>
            </ZoneStatus>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <ZoneShortDetailsLabel>Description:</ZoneShortDetailsLabel>
            <ZoneShortDetailsValue>{bin.description}</ZoneShortDetailsValue>
          </DescriptionItemWrapper>
        </Grid>
      </ZoneContent>
    </ZoneCardContainer>
  );
};

const ZoneCardContainer = styled.div<{ background: string }>`
  background: #fff;
  width: 215px;
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  &:hover {
    cursor: pointer;
    border: 1px solid #c9ccd1;

    #header {
      background: #c9ccd1;
    }
  }
`;

const CardHeader = styled.div`
  background: #dfe3e8;
  padding: 12px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  align-items: center;
  &:hover {
    background: #c9ccd1;
  }
`;

const CardHeaderText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;

const ZoneColor = styled.div<{ color: string }>`
  background: ${(prop) => prop.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
`;

const ZoneShortDetailsLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
  margin-right: 5px;
`;

const ZoneShortDetailsValue = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: black;
`;

const ZoneContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ZoneStatus = styled.div`
  display: flex;
  align-items: center;
`;

const ZoneStatusIcon = styled.div<{ isActive: boolean }>`
  background: ${(prop) => (prop.isActive ? '#2e7d32' : '#d32f2f')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 2px;
`;

const DescriptionItemWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const DisplayName = styled.div`
  padding: 6px;
  background-color: #13ad5c;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;
