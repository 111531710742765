import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { formatCurrencyWithSymbol } from '../../helpers/currency-format';
import { GrandTotal } from './GrandTotal';

interface CartFooterProps {
  isVatIncluded: boolean;
  amount1: number;
  amount2: number;
  subTotalExlVat?: number;
  grandTotal?: number;
  vat?: number;
  total: number;
  text1?: string;
  text2?: string;
  text3?: string;
  text4?: string;
}

export const CartFooter = ({
  isVatIncluded,
  amount1,
  amount2,
  subTotalExlVat = 0,
  grandTotal = 0,
  vat = 0,
  total,
  text1 = 'ITEMS',
  text2 = 'SERVICES',
  text3 = 'TOTAL',
  text4 = 'CASH DISCOUNT',
}: CartFooterProps) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <PriceWrapper
          background={'#F9FAFB'}
          style={{ borderBottomLeftRadius: '16px' }}
        >
          <Text fontSize={12} fontWeight={600}>
            {text1}
          </Text>
          <Typography fontSize={12}>
            {formatCurrencyWithSymbol(amount1)}
          </Typography>
          <Text fontSize={10} fontWeight={400}>
            {isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          </Text>
        </PriceWrapper>

        <Divider />

        <PriceWrapper background={'#F9FAFB'}>
          <Text fontSize={12} fontWeight={600}>
            {text2}
          </Text>
          <Typography fontSize={12}>
            {formatCurrencyWithSymbol(amount2)}
          </Typography>
          <Text fontSize={10} fontWeight={400}>
            {isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          </Text>
        </PriceWrapper>

        <Divider />

        <PriceWrapper background={'#F9FAFB'}>
          <Text fontSize={12} fontWeight={600}>
            {text3}
          </Text>
          <Typography fontSize={12}>
            {formatCurrencyWithSymbol(total)}
          </Typography>
          <Text fontSize={10} fontWeight={400}>
            {isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          </Text>
        </PriceWrapper>
        <PriceWrapper background="#ECF0F4">
          <Text fontSize={12} fontWeight={600}>
            {text4}
          </Text>
          <Typography fontSize={12}>{formatCurrencyWithSymbol(0)}</Typography>
          <Text fontSize={10} fontWeight={400}>
            {isVatIncluded ? 'inclusive VAT' : 'exclusive VAT'}
          </Text>
        </PriceWrapper>
      </ContentWrapper>
      <GrandTotal
        subTotalExlVat={subTotalExlVat}
        vat={vat}
        grandTotal={grandTotal}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #ffffff;
  width: 100%;
  height: 90px;
  position: sticky;
  bottom: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  max-width: 64%;
  width: 100%;
`;

const PriceWrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: start;
  background: ${(props) => props.background};
  padding: 10px;
`;

const Text = styled(Typography)`
  color: ${tyrioUI.colors.black.B80};
`;

const Divider = styled.div`
  border-right: 1px solid #dfe3e8;
  margin-top: 8px;
  margin-bottom: 8px;
`;
