import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import {
  ProductsTableProperties,
  RowDetail,
} from '../../../../context/PrintContext';
import { JustifyBetween } from '../MainInfo';
import { JustifyCenter } from '../PdfFooter';

interface TableRowProps {
  rowNumber: number;
  rowDetails: RowDetail;
  tableProps: ProductsTableProperties;
  index?: number;
}

export const TableRow = ({
  rowNumber,
  rowDetails,
  tableProps,
  index,
}: TableRowProps) => {
  const weightPerItem = Number(rowDetails.weight);
  const width = tableProps.columnCount * 66;
  return (
    <Wrapper index={index}>
      <Text style={{ width: '30px' }}>{rowNumber}</Text>
      <ProductDetailsWrapper width={width}>
        <ProductDetailsText>{`${rowDetails.name}`}</ProductDetailsText>
        <ProductDescription>{`${rowDetails.details}`}</ProductDescription>
      </ProductDetailsWrapper>
      <JustifyBetween style={{ width }}>
        {tableProps.showWeight && (
          <JustifyCenter>
            <Text>{weightPerItem.toFixed(2)}</Text>
          </JustifyCenter>
        )}

        {tableProps.showQuantity && (
          <JustifyCenter>
            <Text>{rowDetails.quantity}</Text>
          </JustifyCenter>
        )}

        {tableProps.showTotalWeight && (
          <JustifyCenter>
            <Text>{(rowDetails.quantity * weightPerItem).toFixed(2)} kg</Text>
          </JustifyCenter>
        )}
      </JustifyBetween>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ index?: number }>`
  height: 50px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-left: ${(props) =>
    props.index && props.index % 2 !== 0 ? '1px solid #e6e6e6' : ''};
  border-right: ${(props) =>
    props.index && props.index % 2 === 0 ? '1px solid #e6e6e6' : ''};
`;

const Text = styled(Typography)`
  color: #000;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const ProductDetailsWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  white-space: nowrap;
  overflow-x: auto;
  width: ${(props) => props.width && `calc(100% - ${props.width}px)`};
`;

const ProductDetailsText = styled.div`
  font-family: Barlow;
  font-weight: 400;
  max-height: 40px;
  font-size: 12px;
`;

const ProductDescription = styled.div`
  font-family: Barlow;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: #919eab;
`;
