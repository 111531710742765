import { CircularProgress, Tooltip } from '@mui/material';
import { tyrioIcons } from '@tyrio/ui-library';
import { useDropzone } from 'react-dropzone';
import {
  AccectableFormats,
  Barlow,
  LogoImageWrapper,
  LogoWrapper,
  SectionWrapper,
  UploadLogoText,
  UploadLogoWrapper,
} from './style';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';

interface UploadLogoProps {
  logoUrl: string;
  setLogoUrl: (logoUrl: string) => void;
  onImageChange: (acceptedFiles: File[]) => void;
  isUploading: boolean;
}

export const UploadLogo = ({
  logoUrl,
  setLogoUrl,
  onImageChange,
  isUploading,
}: UploadLogoProps) => {
  const [isCancelHovered, setIsCancelHovered] = useState(false);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/svg': ['.svg'],
    },
    maxSize: 3 * 1024 * 1024,
    maxFiles: 1,
    onDrop: (acceptedFiles) => onImageChange(acceptedFiles),
  });

  return (
    <SectionWrapper height={'220px'}>
      <input style={{ display: 'none' }} {...getInputProps()} />
      <LogoWrapper>
        <Barlow>Logo</Barlow>
        <LogoImageWrapper>
          {!isUploading ? (
            <UploadLogoWrapper>
              {logoUrl !== '' ? (
                <img
                  src={logoUrl}
                  alt="Documents look logo"
                  style={{
                    maxWidth: '420px',
                    maxHeight: '150px',
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                <UploadLogoWrapper>
                  <img
                    src={tyrioIcons.uploadDocument}
                    alt="Upload document icon"
                    style={{
                      width: '60px',
                      height: '60px',
                      padding: '8px',
                      borderRadius: '100%',
                      background: '#1976D21F',
                    }}
                    onClick={open}
                  />
                  <div style={{ display: 'flex' }}>
                    <UploadLogoText {...getRootProps()}>
                      Click to upload
                    </UploadLogoText>
                    <div
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: '400',
                        marginLeft: '5px',
                      }}
                    >
                      or drag and drop
                    </div>
                  </div>

                  <AccectableFormats>
                    SVG, PNG, JPG or GIF (max. 3MB , max width 1280 px)
                  </AccectableFormats>
                </UploadLogoWrapper>
              )}
            </UploadLogoWrapper>
          ) : (
            <CircularProgress />
          )}

          {logoUrl && !isUploading && (
            <div
              style={{
                position: 'absolute',
                cursor: 'pointer',
                bottom: '135px',
                right: '-10px',
                height: '20px',
              }}
              onMouseOver={() => setIsCancelHovered(true)}
              onMouseLeave={() => setIsCancelHovered(false)}
            >
              <Tooltip placement="top" title={'Delete the logo'}>
                <CancelIcon
                  color={!isCancelHovered ? 'disabled' : 'error'}
                  sx={{
                    background: 'white',
                    display: 'inline-block',
                    borderRadius: '50%',
                  }}
                  onClick={() => setLogoUrl('')}
                />
              </Tooltip>
            </div>
          )}
        </LogoImageWrapper>
      </LogoWrapper>
    </SectionWrapper>
  );
};
