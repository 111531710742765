import { Row } from 'react-table';
import { TableCellInterface } from './temp';
import _ from 'lodash';

export function filterPaginated(
  data: Row<TableCellInterface>[],
  page: number,
  itemsPerPage: number,
  sortKey?: string,
  sortDirection?: string
) {
  const startIdx = page * itemsPerPage;
  const sortDirIndex = sortDirection === 'ASC' ? 1 : -1;
  return data
    .sort((a, b) => {
      if (sortKey === 'articleName') {
        return (
          (_.get(a, 'values.articleName.name', 0) >
          _.get(b, 'values.articleName.name', 0)
            ? 1
            : -1) * sortDirIndex
        );
      } else if (sortKey === 'mainSupplier') {
        return (
          (_.get(a, 'values.mainSupplier.value', 0) >
          _.get(b, 'values.mainSupplier.value', 0)
            ? 1
            : -1) * sortDirIndex
        );
      } else {
        return (
          (_.get(a, `values.${sortKey}.value`, 0) >
          _.get(b, `values.${sortKey}.value`, 0)
            ? 1
            : -1) * sortDirIndex
        );
      }
    })
    .slice(startIdx, startIdx + itemsPerPage);
}
