import styled from '@emotion/styled';
import {
  CircularProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Typography,
} from '@mui/material';
import api from '@tyrio/api-factory';
import { DBStockListApi } from '@tyrio/dto';
import { Header, tyrioUI } from '@tyrio/ui-library';
import { SingleTableRow } from '@tyrio/wms-ui-library';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import NoDataFound from '../../components/NoData/NoDataFound';
import { useAuth } from '../../context/AuthContext';
import { StockListContext } from '../../context/StockListContext';
import { useWS } from '../../context/WSContext';
import { StockWrapper } from './components/Branches/SingleStorage';
import { getReservedAndUpcomingValues } from './components/Cart/helper/socket-helper';
import { AddStockListItem } from './components/modals/AddStockListItemModal';
import Filter from './Filter/Filter';
import FilterFooter from './Filter/FilterFooter';
import { SidebarFilter } from './Filter/SidebarFilter';
import { ImportStockList } from './components/modals/ImportStockList';

interface StockListOverviewProps {
  setShouldDisableMenuItems: (item: boolean) => void;
}

export const StockListOverview = ({
  setShouldDisableMenuItems,
}: StockListOverviewProps) => {
  const { user } = useAuth();
  const ws = useWS();
  const [open, setOpen] = useState(false);
  const { ref, inView } = useInView();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const pageSize = 15;

  const {
    filterValues,
    selectedTableRow: selectedProduct,
    setSelectedTableRow: setSelectedProduct,
    setActiveStep,
    activeStep,
    setActiveDrawer,
    onlyAvailable,
    setOutboundOrders,
    input,
  } = useContext(StockListContext);

  const {
    data: stockProductData,
    isFetching: isFetchingStockProducts,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['get_stock_list_products', { filterValues }],
    async ({ pageParam = 1 }) => {
      setPage(pageParam);

      return api.fetch<DBStockListApi['list']>('get_stock_list_products', {
        filters: {
          ...filterValues,
        },
        page: pageParam,
        pageSize: pageSize,
      });
    },
    {
      onSuccess: (res) => {
        if (res.pages[page - 1]?.length > 0) setHasNextPage(true);
        else setHasNextPage(false);
      },
    }
  );

  const isEmptyStockData = stockProductData?.pages?.[0]?.length === 0;
  const selectedBranch = Number(filterValues?.['warehouseId']);
  const isSelectedProductLocationCorrect =
    selectedProduct?.branchId === selectedBranch;

  useEffect(() => {
    if (isEmptyStockData) {
      setOutboundOrders([]);
      setShouldDisableMenuItems(true);
      setSelectedProduct(null);
    }

    if (
      !isEmptyStockData &&
      !isFetchingStockProducts &&
      !isFetchingNextPage &&
      stockProductData?.pages
    ) {
      const firstProduct = stockProductData.pages?.[0][0];
      const firstPage = stockProductData.pages?.[0];
      const isSelectedProductInFirstPage = firstPage.some(
        (product) => product.ean === selectedProduct?.product?.ean
      );

      setShouldDisableMenuItems(false);

      if (selectedProduct === null) {
        if (input.selectedTableRow) {
          setSelectedProduct(input.selectedTableRow);
          setActiveStep(input.activeStep);
        } else {
          setSelectedProduct(firstProduct);
          if (activeStep !== 'DETAILS') setActiveStep('DETAILS');
        }
      }

      if (
        (selectedProduct && !isSelectedProductInFirstPage) ||
        !isSelectedProductLocationCorrect
      ) {
        if (activeStep !== 'DETAILS') setActiveStep('DETAILS');
        setSelectedProduct(firstProduct);
      }
    }
  }, [
    isEmptyStockData,
    stockProductData?.pages,
    input.selectedTableRow,
    input.activeStep,
    selectedProduct,
    setSelectedProduct,
    activeStep,
    setActiveStep,
    isFetchingNextPage,
    isFetchingStockProducts,
    setShouldDisableMenuItems,
    setOutboundOrders,
    isSelectedProductLocationCorrect,
  ]);

  useEffect(() => {
    if (
      hasNextPage &&
      inView &&
      !isEmptyStockData &&
      !isFetchingStockProducts
    ) {
      fetchNextPage({ pageParam: page + 1 });
      setPage(page + 1);
    }
  }, [
    fetchNextPage,
    inView,
    isEmptyStockData,
    isFetchingStockProducts,
    page,
    setPage,
    stockProductData,
    hasNextPage,
  ]);

  const getValues = useCallback(
    (key: string) => {
      const cartItems = ws?.state.cart?.userCart;
      const warehouseId = Number(filterValues?.['warehouseId'] as string);

      const { reserved, upcoming } = getReservedAndUpcomingValues(
        cartItems,
        key,
        warehouseId
      );

      return { reserved, upcoming };
    },
    [ws, filterValues]
  );

  const renderFilters = () => {
    return (
      <FilterHeaderWrapper>
        <FilterWrapper>
          <Filter
            onOpen={() => {
              setOpen(true);
            }}
          />
        </FilterWrapper>
      </FilterHeaderWrapper>
    );
  };

  return (
    <Wrapper>
      <Header
        title="Stock List"
        addButton={() => setIsAddModalOpen(true)}
        importButton={() => setIsImportModalOpen(true)}
      />

      <SidebarFilter isOpen={open} setOpen={setOpen} />

      {isEmptyStockData && (
        <div style={{ marginTop: '20px' }}>{renderFilters()}</div>
      )}

      {isAddModalOpen && (
        <AddStockListItem open={isAddModalOpen} setOpen={setIsAddModalOpen} />
      )}

      {isImportModalOpen && (
        <ImportStockList
          isOpen={isImportModalOpen}
          setOpen={setIsImportModalOpen}
        />
      )}

      <TableWrapper id="stock-list-table">
        <Table
          id="stock_list_table"
          style={{
            whiteSpace: 'nowrap',
            minWidth: !isEmptyStockData ? '1050px' : '',
            marginBottom: '70px',
          }}
        >
          {!isEmptyStockData && !isFetchingStockProducts ? (
            <TableHeaderWrapper>
              {renderFilters()}
              <StockWrapper onlyAvailable={onlyAvailable}>
                {!onlyAvailable && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ color: tyrioUI.colors.black.B90 }}
                    >
                      On stock
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: tyrioUI.colors.black.B90 }}
                    >
                      Reserved
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: tyrioUI.colors.black.B90 }}
                    >
                      Upcoming
                    </Typography>
                  </>
                )}

                <Typography
                  variant="body2"
                  sx={{ color: tyrioUI.colors.black.B90 }}
                >
                  Available
                </Typography>
              </StockWrapper>
            </TableHeaderWrapper>
          ) : (
            <div style={{ borderBottom: '1px solid #dfe3e8' }} />
          )}

          <TableBody>
            {isFetchingStockProducts && !isFetchingNextPage ? (
              <CircularProgress />
            ) : isEmptyStockData ? (
              <NoDataFound />
            ) : (
              stockProductData?.pages.map((page, index) => (
                <Fragment key={`Data-part-${index}`}>
                  {page.map((product) => {
                    let { reserved, upcoming } = getValues(
                      product.ean + '_' + product.dot
                    );

                    reserved += product.reserved;
                    upcoming += product.upcoming;

                    return (
                      <SingleTableRow
                        data={product}
                        reserved={reserved > 0 ? reserved : product.reserved}
                        upcoming={upcoming > 0 ? upcoming : product.upcoming}
                        userId={user?.id ?? ''}
                        key={product.id}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        setActiveStep={setActiveStep}
                        setActiveDrawer={setActiveDrawer}
                        onlyAvailable={onlyAvailable}
                      />
                    );
                  })}
                </Fragment>
              ))
            )}
          </TableBody>

          {!isEmptyStockData &&
            !isFetchingNextPage &&
            !isFetchingStockProducts && <div ref={ref} />}

          {!isEmptyStockData && isFetchingNextPage && (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}

          <TableFooter>
            <FilterFooter />
          </TableFooter>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100%;
`;

const FilterHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterWrapper = styled.div`
  width: 100%;
`;

const LoaderWrapper = styled.div`
  margin-top: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  align-items: center;
`;

const TableHeaderWrapper = styled(TableHead)`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-bottom: 1px solid #dfe3e8;
  background: white;
  position: sticky;
  top: 0;
`;

const TableWrapper = styled.div`
  height: calc(100svh - 230px);
  overflow-y: auto;
  margin-bottom: 64px;
`;
