import { Popover } from '@mui/material';
import {
  IconTextWrapper,
  PopoverItem,
  PopoverItemSoon,
  StyledPopover,
} from './style/Menu.style';
import { Dispatch, SetStateAction } from 'react';
import { WmsIcon, YearChip, wmsIcons } from '@tyrio/wms-ui-library';
import SaveIcon from '@mui/icons-material/Save';

interface MenuPopoverProps {
  isMenuPopupVisible: boolean;
  setIsMenuPopupVisible: Dispatch<SetStateAction<boolean>>;
  shouldEnlarge: boolean;
  setShouldEnlarge: Dispatch<SetStateAction<boolean>>;
}

const MenuPopover = ({
  isMenuPopupVisible,
  setIsMenuPopupVisible,
  shouldEnlarge,
  setShouldEnlarge,
}: MenuPopoverProps) => {
  return (
    <Popover
      open={isMenuPopupVisible}
      onClose={() => setIsMenuPopupVisible(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <StyledPopover>
        <PopoverItem
          onClick={() => {
            setShouldEnlarge(!shouldEnlarge);
            setIsMenuPopupVisible(false);
          }}
        >
          <IconTextWrapper>
            <WmsIcon
              icon={wmsIcons.expandIcon}
              color="info"
              sx={{ marginRight: '16px', marginTop: '10px' }}
            />
            {!shouldEnlarge ? 'Expand right side' : 'Shrink right side'}
          </IconTextWrapper>
        </PopoverItem>

        <PopoverItemSoon>
          <SaveIcon
            style={{
              marginRight: '16px',
            }}
          />
          <IconTextWrapper>Save for later</IconTextWrapper>
          <YearChip year={'soon'} color="default" customColor="#ED6C02" />
        </PopoverItemSoon>
      </StyledPopover>
    </Popover>
  );
};

export default MenuPopover;
