import * as z from "zod"
import { ArchiveType } from ".prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBCatalogArchiveModel = z.object({
  uid: z.string(),
  type: z.nativeEnum(ArchiveType),
  importKey: z.string(),
  data: jsonSchema,
})
