import { TRule } from '../../typings';

export const RADCENTAR_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.eanCod",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.articleNo",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.articleNo",
    destination: 'product',
    targetKey: 'manufacturerCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.manufacturer",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.netPrice',
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
