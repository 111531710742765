import { DBSalesChannel } from '@prisma/client';

export const SEARCH_TYPE = [
  {
    value: 'orderNumber',
    label: 'Customer order number',
  },
  {
    value: 'customerName',
    label: 'Customer name',
  },
  {
    value: 'city',
    label: 'City',
  },
  {
    value: 'amount',
    label: 'Amount',
  },
  {
    value: 'notes',
    label: 'Notes',
  },
  {
    value: 'ean',
    label: 'EAN',
  },
];

export const SALES_CHANNELS_CUSTOMER = [
  {
    value: DBSalesChannel.B2B,
    label: 'Web shop B2B',
  },
  {
    value: DBSalesChannel.B2C,
    label: 'Web shop B2C',
  },
  {
    value: DBSalesChannel.TYRE_POS,
    label: 'Tyrio POS',
  },
  {
    value: DBSalesChannel.EBAY,
    label: 'eBay',
  },
];
