/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { UserRolePermissions } from '@tyrio/forms';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
interface TyrioPermissionsTableProps {
  id?: string;
  permissionData: UserRolePermissions[];
  fullAccessChange: (index: number, value: boolean, childs?: boolean) => void;
  permissionChange: (
    index: number,
    key: string,
    value: boolean,
    child?: boolean
  ) => void;
  fullAccessChildChange: (
    index: number,
    childIndex: number,
    value: boolean
  ) => void;
  permissionChildChange: (
    index: number,
    childIndex: number,
    key: string,
    value: boolean
  ) => void;
  disabled?: boolean;
}
export const TyrioPermissionsTable = ({
  id,
  permissionData,
  fullAccessChange,
  permissionChange,
  fullAccessChildChange,
  permissionChildChange,
  disabled,
}: TyrioPermissionsTableProps) => {
  const { t } = useTranslation();

  const [opens, setOpens] = useState<Record<string, boolean>>({});

  return (
    <Grid>
      <TableContainer component={Paper} style={{ borderRadius: 0 }}>
        <Table>
          <TableHead>
            <StyledTableRow sx={{ background: '#ECF0F4', height: 40 }} key={id}>
              <FirstTableCell />
              <StyledTableCell>{t('FullAccess')}</StyledTableCell>
              <StyledTableCell>{t('View')}</StyledTableCell>
              <StyledTableCell>{t('Create')}</StyledTableCell>
              <StyledTableCell>{t('Edit')}</StyledTableCell>
              <StyledTableCell>{t('Delete')}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {Object.keys(permissionData).map((key, index) => (
              <>
                <StyledTableRow
                  key={index}
                  sx={
                    opens[index]
                      ? { background: '#1976D214' }
                      : { background: 'white' }
                  }
                >
                  <StyledTableCell
                    style={{ textAlign: 'left', paddingLeft: '40px' }}
                  >
                    {permissionData[index].childs && (
                      <IconButton
                        aria-label="expand-table-permission-row"
                        size="small"
                        onClick={() => {
                          setOpens({
                            ...opens,
                            [index]: !opens[index],
                          });
                        }}
                        style={{ padding: 0, marginLeft: '-25px' }}
                      >
                        {opens[index] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    )}
                    {t(permissionData[index].label)}
                  </StyledTableCell>
                  <CheckboxCell>
                    <Checkbox
                      data-cy={`full-access-${key}`}
                      disabled={disabled}
                      key={'fullAccessCheckbox_' + index}
                      checked={
                        permissionData[index].values.view &&
                        permissionData[index].values.edit &&
                        permissionData[index].values.create &&
                        permissionData[index].values.delete
                      }
                      color="success"
                      onChange={(e) =>
                        fullAccessChange(
                          index,
                          e.target.checked,
                          !!permissionData[index].childs
                        )
                      }
                    />
                  </CheckboxCell>
                  <CheckboxCell>
                    <Checkbox
                      data-cy={`view-access-${key}`}
                      disabled={disabled}
                      key={'viewCheckbox_' + index}
                      checked={permissionData[index].values.view}
                      onChange={(e) => {
                        permissionChange(
                          index,
                          'view',
                          e.target.checked,
                          !!permissionData[index].childs
                        );
                      }}
                      color="info"
                    />
                  </CheckboxCell>
                  <CheckboxCell>
                    <Checkbox
                      data-cy={`create-access-${key}`}
                      disabled={disabled}
                      key={'createCheckbox_' + index}
                      checked={permissionData[index].values.create}
                      onChange={(e) =>
                        permissionChange(
                          index,
                          'create',
                          e.target.checked,
                          !!permissionData[index].childs
                        )
                      }
                      color="info"
                    />
                  </CheckboxCell>
                  <CheckboxCell>
                    <Checkbox
                      data-cy={`edit-access-${key}`}
                      disabled={disabled}
                      key={'editCheckbox_' + index}
                      checked={permissionData[index].values.edit}
                      onChange={(e) =>
                        permissionChange(
                          index,
                          'edit',
                          e.target.checked,
                          !!permissionData[index].childs
                        )
                      }
                      color="info"
                    />
                  </CheckboxCell>
                  <CheckboxCell>
                    <Checkbox
                      data-cy={`delete-access-${key}`}
                      disabled={disabled}
                      key={'deleteCheckbox_' + index}
                      checked={permissionData[index].values.delete}
                      onChange={(e) =>
                        permissionChange(
                          index,
                          'delete',
                          e.target.checked,
                          !!permissionData[index].childs
                        )
                      }
                      color="info"
                    />
                  </CheckboxCell>
                </StyledTableRow>
                <StyledTableRow key={index + '_colapsed_items'}>
                  <StyledTableCell colSpan={6}>
                    <Collapse in={opens[index]} timeout="auto" unmountOnExit>
                      {permissionData[index].childs &&
                        Object.keys(permissionData[index].childs!).map(
                          (key, childIndex) => (
                            <Table>
                              <TableBody>
                                <StyledTableRow>
                                  <FirstTableCell>
                                    {t(
                                      permissionData[index].childs![childIndex]
                                        .label
                                    )}
                                  </FirstTableCell>
                                  <CheckboxCell>
                                    <Checkbox
                                      checked={
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.view &&
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.edit &&
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.create &&
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.delete
                                      }
                                      color="success"
                                      disabled={disabled}
                                      key={'fullAccessChildCheckbox_' + index}
                                      onChange={(e) =>
                                        fullAccessChildChange(
                                          index,
                                          childIndex,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </CheckboxCell>
                                  <CheckboxCell>
                                    <Checkbox
                                      checked={
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.view
                                      }
                                      color="info"
                                      disabled={disabled}
                                      key={'viewChildCheckbox_' + index}
                                      onChange={(e) => {
                                        permissionChildChange(
                                          index,
                                          childIndex,
                                          'view',
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  </CheckboxCell>
                                  <CheckboxCell>
                                    <Checkbox
                                      checked={
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.create
                                      }
                                      color="info"
                                      disabled={disabled}
                                      key={'createChildCheckbox_' + index}
                                      onChange={(e) =>
                                        permissionChildChange(
                                          index,
                                          childIndex,
                                          'create',
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </CheckboxCell>
                                  <CheckboxCell>
                                    <Checkbox
                                      checked={
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.edit
                                      }
                                      color="info"
                                      disabled={disabled}
                                      key={'editChildCheckbox_' + index}
                                      onChange={(e) =>
                                        permissionChildChange(
                                          index,
                                          childIndex,
                                          'edit',
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </CheckboxCell>
                                  <CheckboxCell>
                                    <Checkbox
                                      checked={
                                        permissionData[index].childs![
                                          childIndex
                                        ].values.delete
                                      }
                                      color="info"
                                      disabled={disabled}
                                      key={'deleteChildCheckbox_' + index}
                                      onChange={(e) =>
                                        permissionChildChange(
                                          index,
                                          childIndex,
                                          'delete',
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </CheckboxCell>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          )
                        )}
                    </Collapse>
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const StyledTableCell = styled(TableCell)`
  text-align: center;
  padding: 0;
  margin: 0;
`;

const FirstTableCell = styled(TableCell)`
  width: 220px;
  margin: 0;
  padding: 0;
  padding-left: 80px;
`;

const CheckboxCell = styled(TableCell)`
  text-align: center;
  padding: 0;
  width: 100px;
  margin: 0;
`;

const StyledTableRow = styled(TableRow)`
  width: 100%;
`;
