export type ActiveStep =
  | 'CATEGORY'
  | 'WIDTH'
  | 'HEIGHT'
  | 'RIM_SIZE'
  | 'SEASON'
  | 'DESIRED_OPTION'
  | 'BRAND'
  | 'SUBCATEGORY'
  | 'HOLE_NUMBER'
  | 'HOLE_CIRCLE'
  | 'COLOR_RIMS';

const stepsForCarTires: Record<number, ActiveStep> = {
  0: 'CATEGORY',
  1: 'WIDTH',
  2: 'HEIGHT',
  3: 'RIM_SIZE',
  4: 'SEASON',
};

const stepsForMotoAtvTires: Record<number, ActiveStep> = {
  0: 'CATEGORY',
  1: 'DESIRED_OPTION',
  2: 'WIDTH',
  3: 'HEIGHT',
  4: 'RIM_SIZE',
  5: 'BRAND',
};

const stepsForTubesAndBicycle: Record<number, ActiveStep> = {
  0: 'CATEGORY',
  1: 'RIM_SIZE',
  2: 'SUBCATEGORY',
};

const stepsForWheels: Record<number, ActiveStep> = {
  0: 'CATEGORY',
  1: 'HOLE_NUMBER',
  2: 'HOLE_CIRCLE',
  3: 'COLOR_RIMS',
};

export const searchOptions: { value: string; name: string }[] = [
  { value: 'ean', name: 'EAN' },
  { value: 'sku', name: 'SKU' },
  { value: 'manufacturerCode', name: 'Manufacturer code' },
  { value: 'model', name: 'Model' },
  { value: 'productName', name: 'Product name' },
  { value: 'dimension', name: 'Dimension' },
  { value: 'specialMarking', name: 'Special marking' },
];

export const activeStepsByIndexAndCategory: Record<
  number,
  Record<number, ActiveStep>
> = {
  1: stepsForCarTires,
  4: stepsForCarTires,
  7: stepsForCarTires,
  2: stepsForMotoAtvTires,
  3: stepsForMotoAtvTires,
  9: stepsForTubesAndBicycle,
  5: stepsForTubesAndBicycle,
  8: stepsForWheels,
};

const favoriteCarWidths = [
  '155',
  '165',
  '175',
  '185',
  '195',
  '205',
  '215',
  '225',
  '235',
  '245',
  '255',
  '265',
  '275',
  '285',
  '295',
  '305',
];

const favoriteCargoTires = [
  '12',
  '13',
  '205',
  '215',
  '225',
  '235',
  '245',
  '265',
  '275',
  '285',
  '295',
  '305',
  '315',
  '365',
  '435',
  '445',
];

const favoriteMotoAtvFrontWidths = [
  '60',
  '70',
  '80',
  '90',
  '100',
  '110',
  '120',
  '125',
  '130',
  '140',
  '150',
  '160',
];

const favoriteMotoAtvRearWidths = [
  '125',
  '130',
  '140',
  '150',
  '160',
  '165',
  '170',
  '175',
  '180',
  '190',
  '195',
  '200',
];

const favoriteMotoAtvWidths = [
  '60',
  '70',
  '80',
  '90',
  '100',
  '110',
  '120',
  '125',
  '130',
  '140',
  '150',
  '160',
  '165',
  '170',
  '175',
  '180',
  '190',
  '195',
  '200',
];

export const favoriteWidthsByCategory: Record<string, string[]> = {
  '1': favoriteCarWidths,
  '4': favoriteCargoTires,
  '7': favoriteCarWidths,
  '2': favoriteMotoAtvWidths,
  '3': favoriteMotoAtvWidths,
  '2-front': favoriteMotoAtvFrontWidths,
  '3-front': favoriteMotoAtvFrontWidths,
  '2-rear': favoriteMotoAtvRearWidths,
  '3-rear': favoriteMotoAtvRearWidths,
};

export const topBrands = [
  'AVON',
  'BRIDGESTONE',
  'CONTINENTAL',
  'DUNLOP',
  'HEIDENAU',
  'MAXXIS',
  'METZELER',
  'MICHELIN',
  'MITAS',
  'PIRELLI',
  'SHINKO',
];
