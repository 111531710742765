import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { DBWarehouseSubzones } from '@prisma/client';
import _ from 'lodash';
import { useContext } from 'react';
import { WarehouseContext } from '../../../context/WarehouseContext';
import { format, getCurrent } from '../helper/helper';

interface ZoneCardProps {
  subzone: DBWarehouseSubzones;
  floorsCount?: number;
}

export const SubzoneCard = (props: ZoneCardProps) => {
  const { selected, setSelected } = useContext(WarehouseContext);

  const { subzone, floorsCount } = props;

  const settings = (att: string) => {
    return _.get(subzone.settings, att);
  };

  return (
    <ZoneCardContainer
      background={getCurrent(selected, 'color')}
      onClick={() => {
        setSelected({
          current: [...selected.current, 'floor', 'rack'],
          locations: {
            ...selected?.locations,
            subzone: subzone,
          },
        });
      }}
    >
      <CardHeader id="header">
        <ZoneColor color={getCurrent(selected, 'color')} />
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <CardHeaderText>SUBZONE {subzone.code}</CardHeaderText>
          {settings('stagingType') === 'FITTING' && (
            <BoxName>{subzone.name}</BoxName>
          )}
        </Grid>
      </CardHeader>
      <SubZoneType>{format(settings('stagingType'))}</SubZoneType>
      <SubZoneContent>
        <Grid>
          <DescriptionItemWrapper>
            <SubZoneShortDetailsLabel>Status:</SubZoneShortDetailsLabel>
            <SubZoneStatus>
              <SubZoneStatusIcon isActive={settings('isActive')} />
              <SubZoneShortDetailsValue>
                {settings('isActive') ? 'Active' : 'Inactive'}
              </SubZoneShortDetailsValue>
            </SubZoneStatus>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <SubZoneShortDetailsLabel>Subzone type:</SubZoneShortDetailsLabel>
            <SubZoneShortDetailsValue>
              {format(settings('type'))}
            </SubZoneShortDetailsValue>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <SubZoneShortDetailsLabel>Floors:</SubZoneShortDetailsLabel>
            <SubZoneShortDetailsValue>{floorsCount}</SubZoneShortDetailsValue>
          </DescriptionItemWrapper>
        </Grid>
      </SubZoneContent>
    </ZoneCardContainer>
  );
};

const ZoneCardContainer = styled.div<{ background: string }>`
  background: #fff;
  width: 215px;
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  &:hover {
    cursor: pointer;
    border: 1px solid #c9ccd1;

    #header {
      background: #c9ccd1;
    }
  }
`;

const CardHeader = styled.div`
  background: #dfe3e8;
  padding: 12px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  align-items: center;
  &:hover {
    background: #c9ccd1;
  }
`;

const CardHeaderText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;

const BoxName = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
  color: #e75524;
`;

const ZoneColor = styled.div<{ color: string }>`
  background: ${(prop) => prop.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
`;

const SubZoneShortDetailsLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
  margin-right: 5px;
`;

const SubZoneShortDetailsValue = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: black;
`;

const SubZoneContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubZoneStatus = styled.div`
  display: flex;
  align-items: center;
`;

const SubZoneStatusIcon = styled.div<{ isActive: boolean }>`
  background: ${(prop) => (prop.isActive ? '#2e7d32' : '#d32f2f')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 2px;
`;

const DescriptionItemWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const SubZoneType = styled.div`
  padding: 6px;
  background-color: #13ad5c;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;
