import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioSectionInput } from '../types';

interface TyrioLabelSectionProps {
  item: TyrioSectionInput;
  gridProps: FormGridProps;
}
export const TyrioLabelSection = ({
  item,
  gridProps,
}: TyrioLabelSectionProps) => {
  const { t } = useTranslation();
  const noMarginIds = [
    'users',
    'labelContact',
    'sectionLocation',
    'sectionConnection',
    'ftp',
    'labelLocations',
    'labelBanks',
    'labelContacts',
  ];
  return !item.hidden ? (
    <Grid item {...gridProps}>
      {item.inlineLable ? (
        <InlineBox>
          {item.label && <InlineText>{t(item.label)}</InlineText>}
        </InlineBox>
      ) : (
        <LabelSectionBox
          sx={{
            borderBottom: '1px solid #DFE3E8',
            marginBottom: '10px',
            marginTop: noMarginIds.includes(item?.id) ? '' : '40px',
          }}
        >
          {t(`${item.label}`)}
        </LabelSectionBox>
      )}
    </Grid>
  ) : null;
};

export const LabelSectionBox = styled(Box)`
  height: 28px;
  font-weight: 500;
  font-size: 18px;
  line-height: 0px;
  letter-spacing: 0.3px;
  color: #212b36;
  margin-bottom: 30px;
`;

export const InlineBox = styled.div`
  width: 100%;
  position: relative;
  padding-left: 10%;
  margin: -8px 0px 8px 0;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    background: #dfe3e8;
    z-index: 0;
  }
`;

export const InlineText = styled.span`
  background-color: white;
  font-size: 14px;
  padding: 0 8px;
  z-index: 20;
  position: relative;
`;
