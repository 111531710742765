import styled from '@emotion/styled/macro';
import { Switch, Typography } from '@mui/material';
import { tyrioIcons, tyrioUI } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import { Dot } from '../../SearchVehiclePOS/Generation';
import { StyledDivider } from '../../../../stock-list/components/InboundOrders/InboundOrders.style';
import PhotoIcon from '@mui/icons-material/Photo';
import { PhotoShape } from '@tyrio/dto';

interface IWheelDetailsHeader {
  modelName: string;
  brand: string;
  pcd: string;
  cb: string;
  et: number[];
  image?: PhotoShape[];
}

export const WheelDetailsHeader = ({
  modelName,
  brand,
  pcd,
  cb,
  et,
  image,
}: IWheelDetailsHeader) => {
  return (
    <ContentWrapper>
      <Wrapper>
        <ImageWrapper>
          <SwitchWrapper>
            <Switch color="info" disabled={true} />
            <Typography>Show car</Typography>
          </SwitchWrapper>
          <TyreImage>
            {image && image.length > 0 ? (
              <Img src={image[0]?.url} alt="wheel" />
            ) : (
              <PhotoIcon
                color="disabled"
                sx={{ height: '300px', width: '300px' }}
              />
            )}
          </TyreImage>
        </ImageWrapper>
        <IconWrapper>
          <WmsIcon icon={tyrioIcons.favoriteWheel} />
        </IconWrapper>
      </Wrapper>

      <Details>
        <NameDetails>
          <Typography fontWeight={500} fontSize={18}>
            {brand ?? ''}
          </Typography>
          <Typography
            fontWeight={600}
            color={`${tyrioUI.colors.orange.O100}`}
            fontSize={20}
          >
            {modelName ?? ''}
          </Typography>
        </NameDetails>

        <SpecificationDetails>
          <Typography
            fontWeight={400}
            color={`${tyrioUI.colors.black.B100}`}
            fontSize={14}
          >
            PCD: {pcd ?? ''}
          </Typography>
          <Dot />
          <Typography
            fontWeight={400}
            color={`${tyrioUI.colors.black.B100}`}
            fontSize={14}
          >
            CB: {cb ?? ''}
          </Typography>
        </SpecificationDetails>
      </Details>

      <StyledDivider />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  height: 370px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  height: fit-content;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 95%;
`;

const TyreImage = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  width: 5%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
`;

const Img = styled.img`
  object-fit: contain;
  height: 300px;
  width: 300px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

const NameDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: flex-end;
`;

const SpecificationDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
