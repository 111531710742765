import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import styles from './FetchDataDescription.module.scss';

export interface FetchDataDescriptionProps {
  type: FetchDataDesctiptionTypes;
}

export enum FetchDataDesctiptionTypes {
  NoData,
  FetchError,
}

const FetchDataDescription = ({ type }: FetchDataDescriptionProps) => {
  const icon =
    type === FetchDataDesctiptionTypes.FetchError ? (
      <ErrorIcon color="error" fontSize="large" />
    ) : (
      <WarningIcon color="warning" fontSize="large" />
    );
  const message =
    type === FetchDataDesctiptionTypes.FetchError
      ? 'An error occured. Try again!'
      : 'No data! Please, refine your search!';
  return (
    <div className={styles['content-wrapper']}>
      {icon}
      <b className={styles['description']}>{message}</b>
    </div>
  );
};

export default FetchDataDescription;
