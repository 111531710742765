import styled from '@emotion/styled';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import { FunctionComponent, SVGProps } from 'react';

interface SwitchCardProps {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  name: string;
  description: string;
  onClick: () => void;
  isActive: boolean;
}

export const SwitchCard = ({
  icon,
  name,
  description,
  onClick,
  isActive,
}: SwitchCardProps) => {
  return (
    <Wrapper>
      <InfoWrapper>
        <Text color={'black'}>{name}</Text>
        <Text color={`#919EAB`}>{description}</Text>
      </InfoWrapper>
      <div>
        {icon && (
          <WmsIcon
            icon={icon}
            sx={{
              color: tyrioUI.colors.black.B40,
              width: '30px',
              height: '30px',
            }}
            viewBox={'0 0 32 32'}
            stroke={tyrioUI.colors.black.B40}
          />
        )}
        <FormControlLabel
          control={
            <Switch
              data-cy="switch"
              color="info"
              onChange={onClick}
              checked={isActive}
              disabled={false}
            />
          }
          label={''}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  height: 74px !important;
  min-width: 350px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  &: hover {
    border: 1px solid #1976d2;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  flex-direction: column;
`;

const Text = styled(Typography)<{ color: string }>`
  color: ${(props) => props.color};
  font-family: Barlow;
  font-weight: ${(props) => (props.color === 'black' ? 500 : 400)};
  font-size: ${(props) => (props.color === 'black' ? '16px' : '14px')};
`;
