import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBShippingCourier, RelatedDBShippingCourierModel, CompleteDBClientDeliveryCouriers, RelatedDBClientDeliveryCouriersModel } from "./index"

export const DBClientShippingCourierModel = z.object({
  id: z.string(),
  clientId: z.string(),
  shippingCourierId: z.string(),
  username: z.string().nullish(),
  password: z.string().nullish(),
  clientNumber: z.string().nullish(),
  active: z.boolean().nullish(),
})

export interface CompleteDBClientShippingCourier extends z.infer<typeof DBClientShippingCourierModel> {
  client: CompleteDBClient
  shippingCourier: CompleteDBShippingCourier
  DBClientDeliveryCouriers: CompleteDBClientDeliveryCouriers[]
}

/**
 * RelatedDBClientShippingCourierModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientShippingCourierModel: z.ZodSchema<CompleteDBClientShippingCourier> = z.lazy(() => DBClientShippingCourierModel.extend({
  client: RelatedDBClientModel,
  shippingCourier: RelatedDBShippingCourierModel,
  DBClientDeliveryCouriers: RelatedDBClientDeliveryCouriersModel.array(),
}))
