import { Route, useRouteMatch } from 'react-router-dom';
import { ProductWrapper } from '../features/products/pages/ProductWrapper';

export const ProductRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Route exact path={`${path}/:productModelUid/:id`}>
      <ProductWrapper />
    </Route>
  );
};
