import api from '@tyrio/api-factory';
import { DBServiceCategoryApi, DBServicesApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { QueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// CREATE MAIN CATEGORY FOR SERVICES
export const useCreateServiceCategory = (queryClient: QueryClient) => {
  const history = useHistory();

  const { data, mutate } = useMutation(
    ['create_service_categories'],
    (body: DBServiceCategoryApi['create']['requestBody']) =>
      api.fetch<DBServiceCategoryApi['create']>('create_service_categories', {
        ...body,
      }),
    {
      onSuccess: (res) => {
        queryClient.refetchQueries('get_service_categories');
        history.push(`/dashboard/services-category/${res.id}`);
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully created service category.'
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Services',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  return { data, createServiceCategory: mutate };
};

// CREATE SINGLE SERVICE
export const useCreateService = (queryClient: QueryClient) => {
  const history = useHistory();

  const { data, mutate } = useMutation(
    ['create_services'],
    (body: DBServicesApi['create']['requestBody']) =>
      api.fetch<DBServicesApi['create']>('create_services', {
        ...body,
      }),
    {
      onSuccess: (res) => {
        queryClient.refetchQueries('get_service_categories');
        history.push(`/dashboard/services-category/${res.id}?services=true`);

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully created service.'
        );
      },

      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Services',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  return { data, createService: mutate };
};
