import { Alert, AlertTitle, Grid } from '@mui/material';

export const InfoCard = ({
  title,
  subtitle,
  border,
  borderRadius,
}: {
  title: string;
  subtitle: string;
  border?: string;
  borderRadius?: string;
}) => {
  return (
    <Grid
      container
      sx={{
        marginTop: '25px',
        border: border ? border : '',
        borderRadius: borderRadius ? borderRadius : '',
      }}
    >
      <Alert severity="info" sx={{ width: '100%', background: '#F9FAFB' }}>
        <AlertTitle>{title}</AlertTitle>
        {subtitle}
      </Alert>
    </Grid>
  );
};
