import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { HANKOOK_CATALOG_RULESET } from './hankook/catalog';
import { HANKOOK_STOCKLIST_RULESET } from './hankook/stocklist';
import { HANKOOK_PRICELIST_RULESET } from './hankook/pricelist';

export const HANKOOK_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: HANKOOK_CATALOG_RULESET,
  pricelistRuleset: HANKOOK_PRICELIST_RULESET,
  stockRuleset: HANKOOK_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    purchasePrice: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
      origin: 'KR',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'HANKOOK EDI B4',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    const list = json.filter((item) =>
      ['C0', 'L0', 'L4', 'T0', 'LS'].includes(item.VEHICLE_TYPE)
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
