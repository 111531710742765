import Alert from '@mui/material/Alert/Alert';
import AlertTitle from '@mui/material/AlertTitle/AlertTitle';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import styled from '@emotion/styled';
import { serializeError } from 'serialize-error';
interface ErrorPageProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function ErrorPage({ error, resetErrorBoundary }: ErrorPageProps) {
  const redirectToDashboard = () => {
    window.location.pathname = '/';
  };

  console.log(error);
  return (
    <Container>
      <StyledAlert severity="error">
        <div style={{ height: '40vh' }}>
          <AlertTitle>Error code: {error.name}</AlertTitle>
          Stack trace: {JSON.stringify(serializeError(error))}
        </div>
        <ButtonContainer>
          <Button
            onClick={resetErrorBoundary}
            variant="outlined"
            color="primary"
            style={{ marginRight: '16px' }}
          >
            Try again
          </Button>
          <Button
            onClick={redirectToDashboard}
            variant="contained"
            color="primary"
          >
            Redirect to dashboard
          </Button>
        </ButtonContainer>
      </StyledAlert>
    </Container>
  );
}

const Container = styled(Grid)`
  width: 100%;
  height: 100vh;
  padding: 16px;
`;

const StyledAlert = styled(Alert)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: monospace;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export default ErrorPage;
