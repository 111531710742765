import api from '@tyrio/api-factory';
import { DBStockListCronApi, ImportSettings } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetFtpSettings = (
  setFtpSettings: (val: ImportSettings) => void
) => {
  const query = useQuery(
    'getFtpSettings',
    () => api.fetch<DBStockListCronApi['get_settings']>('get_ftp_settings'),
    {
      onSuccess: (d) => {
        setFtpSettings(d);
      },
    }
  );

  return {
    ...query,
  };
};
