/* eslint-disable @typescript-eslint/no-explicit-any */

import { DBBranchStockListResponse } from '@tyrio/dto';
import { isEmpty } from 'lodash';

export const updateOutboundArray = (
  mainBranchOrders: DBBranchStockListResponse[],
  outboundOrderItems: any
) => {
  if (mainBranchOrders.length > 0) {
    const main = mainBranchOrders[0];
    for (const data of outboundOrderItems) {
      const branch = data.warehouseId;
      if (branch !== main.branchId) return;

      for (const item of data.lineItems) {
        const findItem = main.lineItems.filter(
          (singleItem) =>
            singleItem.ean === item.ean && singleItem.dot === item.dot // productionYear=> dot
        );

        if (!isEmpty(findItem)) {
          findItem[0].quantity -= item?.quantity;
        }
      }
    }
  }
};

// fill outboundOrderItems array with data from cart after refresh
export const formatOutboundOrderItems = (newCartOutboundData: any) => {
  const n = newCartOutboundData.map((n: any) => {
    const branch = n[0].split('_');
    const items = Object.values(n[1]);

    const newLineItems = items.map(
      ({ ipc: manufacturerCode, ...rest }: any) => ({
        manufacturerCode,
        ...rest,
        branchId: Number(branch[0]),
      })
    );

    const obj = {
      branchCity: branch[2],
      branchId: Number(branch[0]),
      branchName: branch[1],
      lineItems: newLineItems,
      stockLocationId: '',
      warehouseId: Number(branch[3]),
    };

    return obj;
  });

  return n;
};
