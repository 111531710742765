import { TRule } from '../../typings';

export const GUMIIMPEX_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['ZAPRIMLJENA_KOLICINA']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Oznaka3']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
