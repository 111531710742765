/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ImageGallery } from './ImageGallery/ImageGallery';
import { ItemLocationInfo } from '../../features/stock-list/components/ItemLocationInfo/ItemLocationInfo';
import { ItemReservationInfo } from '../../features/stock-list/components/ItemReservationInfo/ItemReservationInfo';
import PreviewEprel from './PreviewEprel/PreviewEprel';
import { ActiveDrawer } from '../../context/POSContext';
import { DBStockList, PhotoShape, PosItem } from '@tyrio/dto';

const FieldComponent: any = {
  GALLERY: ImageGallery,
  PRINT: PreviewEprel,
  LOCATION_INFO: ItemLocationInfo,
  RESERVATION: ItemReservationInfo,
};

interface DrawersProps {
  activeDrawer: string;
  setActiveDrawer: (a: ActiveDrawer) => void;
  selectedTableRow?: DBStockList | PosItem | null;
  modelImages?: PhotoShape[];
}

export const Drawers = ({
  activeDrawer,
  setActiveDrawer,
  selectedTableRow,
  modelImages,
}: DrawersProps) => {
  if (
    Object.keys(FieldComponent).find((a) => a === activeDrawer) === undefined
  ) {
    console.log('Error - Step passed in is not expected!');
    return;
  }

  if (activeDrawer in Object.keys(FieldComponent)) {
    console.log('Error - Step passed in is not expected!');
  }
  return React.createElement(FieldComponent[activeDrawer], {
    activeDrawer,
    setActiveDrawer,
    selectedTableRow,
    modelImages,
  });
};
