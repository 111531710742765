import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { wmsIcons, WmsIcon } from '@tyrio/wms-ui-library';

enum colors {
  default = '#919EAB',
  warning = '#DB1E1E',
}

interface CountdownProps {
  cart: boolean;
  minutes?: number | null;
  seconds?: number | null;
}

export const Countdown = ({ cart, minutes, seconds }: CountdownProps) => {
  let color = colors['default'];
  let type = 'default';

  const time = `${minutes?.toString().padStart(2, '0')}:${seconds
    ?.toString()
    .padStart(2, '0')}`;

  const text = cart
    ? `Products in cart are reserved for you for another ${time}`
    : `${time}`;

  if ((minutes as number) <= 1 && (seconds as number) <= 59) {
    color = colors['warning'];
    type = 'warning';
  }

  return (
    <Wrapper
      background={type === 'default' ? '#f9fafb' : '#FDF7F7'}
      cart={cart}
    >
      <WmsIcon
        icon={wmsIcons.hourglass}
        viewBox="0 0 10 16"
        sx={{ width: '16px', height: '16px', color }}
      />
      <Typography fontSize={12} sx={{ color }}>
        {text}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ background: string; cart: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background: ${(props) => props.background};
  border-radius: 8px;
  width: ${(props) => (props.cart ? '100%' : '90px')};
  height: ${(props) => (props.cart ? '32px' : '24px')};
  justify-content: center;
`;
