import { TRule } from '../../typings';

export const APOLLOVREDESTEIN_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['Ean']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Amount']",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'supplierCode',
    template: '',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
