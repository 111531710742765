import styled from '@emotion/styled';
import { SearchOutlined } from '@mui/icons-material';
import { Divider, InputAdornment, TextField } from '@mui/material';
import { DBService } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBServicesApi } from '@tyrio/dto';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { POSContext } from '../../../../context/POSContext';
import { useDebounce } from '../../../../hooks/useDebounce';
import { usePosCartData } from '../Cart/helpers/cart-data';
import { ServiceCard } from './ServiceCard';

export const Services = () => {
  const { selectedTableRow, selectedWarehouseId } = useContext(POSContext);
  const { modalData } = usePosCartData();
  const [services, setServices] = useState<DBService[]>([]);
  const [search, setSearch] = useState('');
  const [recommended, setRecommended] = useState<DBService[]>([]);
  const debouncedSearch = useDebounce(search, 500);

  useQuery(
    [
      'get_recommended_services',
      {
        branchId: selectedWarehouseId?.toString(),
        eans: [selectedTableRow?.product.ean],
      },
    ],
    async () => {
      return await api.fetch<DBServicesApi['recommended']>(
        'get_recommended_services',
        {
          branchId: selectedWarehouseId?.toString(),
          eans: [selectedTableRow?.product.ean ?? ''],
        }
      );
    },
    {
      enabled: !![selectedTableRow?.product.ean] && modalData.length > 0,
      onSuccess: (data) => {
        setRecommended(data);
      },
    }
  );

  useQuery(
    [
      'get_services_by_branch_id',
      selectedWarehouseId?.toString(),
      { search: debouncedSearch },
    ],
    async () => {
      return await api.fetch<DBServicesApi['list']>(
        'get_services_by_branch_id',
        {
          branchId: selectedWarehouseId?.toString(),
          productEans: selectedTableRow?.product.ean ?? '',
          search: debouncedSearch,
        }
      );
    },
    {
      onSuccess: (data) => {
        const filtered: DBService[] = data.reduce(
          (result: DBService[], value: DBService) => {
            if (!recommended.includes(value)) {
              result.push(value);
            }
            return result;
          },
          []
        );
        setServices(filtered);
      },
    }
  );

  const renderServices = () => {
    return (
      <div>
        <Wrapper>
          {/* Recommended services */}
          {recommended?.length > 0 && (
            <div style={{ width: '100%' }}>
              {recommended.map((item, index) => (
                <ServiceCard key={index} service={item} />
              ))}
              <Divider style={{ width: '100%', border: '2px solid #DFE3E8' }} />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              width: '100%',
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '50%',
              }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              disabled={services.length === 0 && search === ''}
            />
          </div>

          <Divider
            style={{
              width: '100%',
              border: '2px solid #DFE3E8',
            }}
          />
          {services.length > 0 ? (
            <ServicesWrapper>
              {services.map((item, index) => (
                <ServiceCard key={index} service={item} />
              ))}
            </ServicesWrapper>
          ) : (
            <div>{search !== '' ? 'No result' : 'No data'}</div>
          )}
        </Wrapper>
      </div>
    );
  };

  return (
    <SideMenuLayout
      type="services"
      children={renderServices()}
      showSwitch={false}
      checked={false}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
  width: 100%;
`;

const ServicesWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;
