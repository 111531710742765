import styled from '@emotion/styled/macro';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Typography,
} from '@mui/material';
import { DBCraneTypeEnum, DBServiceCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBFittingBoxExtendedSettings, DBServiceCategoryApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  SetFieldValue,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { JsonObject } from 'react-json-formatter';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { parseEnumToArray } from '../../../../../../helpers/enum-parser';
import {
  getAutocompleteSelectChip,
  getAutocompleteSingleField,
  getNumberInputField,
  getSwitchField,
  getTextField,
} from '../../../form-helper';
import {
  AddNewRepeaterFieldButton,
  DeleteRepeaterFieldButton,
  ExpandCollapseButtons,
} from '../../../supplier-form/helpers/components';
import { useDeleteFittingBox } from '../queries/delete-fitting-box';
import { useGetFittingWarehouseShalves } from '../queries/get-fitting-warehouse-shelves';
import WorkingTime from './WorkingTime';

export interface FittingBoxesProps {
  boxes: DBFittingBoxExtendedSettings[] | undefined;
  branchId: string;
  errors: FieldErrors;
  register: UseFormRegister<FieldValues>;
  control: Control;
  getValues: UseFormGetValues<FieldValues>;
  setValue: SetFieldValue<FieldValues>;
  refetchFittingBoxSettings: () => void;
}

export const FITTING_BOX_INITIAL_DATA: DBFittingBoxExtendedSettings = {
  id: '',
  shelfId: '',
  category: '',
  branchId: 0,
  craneType: DBCraneTypeEnum.FLOOR,
  active: false,
  workingTime: {
    monday: {
      work: {
        start: '',
        end: '',
      },
      break: {
        start: '',
        end: '',
      },
      isOpen: true,
    },
    tuesday: {
      work: {
        start: '',
        end: '',
      },
      break: {
        start: '',
        end: '',
      },
      isOpen: true,
    },
    wednesday: {
      work: {
        start: '',
        end: '',
      },
      break: {
        start: '',
        end: '',
      },
      isOpen: true,
    },
    thursday: {
      work: {
        start: '',
        end: '',
      },
      break: {
        start: '',
        end: '',
      },
      isOpen: true,
    },
    friday: {
      work: {
        start: '',
        end: '',
      },
      break: {
        start: '',
        end: '',
      },
      isOpen: true,
    },
    saturday: {
      work: {
        start: '',
        end: '',
      },
      break: {
        start: '',
        end: '',
      },
      isOpen: false,
    },
    sunday: {
      work: {
        start: '',
        end: '',
      },
      break: {
        start: '',
        end: '',
      },
      isOpen: false,
    },
  },
  vipBox: false,
  blackList: false,
  stepper: 0,
  minLength: 0,
  description: '',
  serviceCategories: [],
};

const FittingBoxes = ({
  branchId,
  boxes: initialBoxes,
  errors,
  register,
  control,
  getValues,
  setValue,
  refetchFittingBoxSettings,
}: FittingBoxesProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [expandedData, setExpandedData] = useState<number[]>([]);
  const [warehouseShelves, setWarehouseShelves] = useState<
    TyrioSelectInputOption[]
  >([]);
  const [boxes, setBoxes] = useState<DBFittingBoxExtendedSettings[]>(
    initialBoxes || []
  );
  const [serviceCategories, setServiceCategories] = useState<
    DBServiceCategory[]
  >([]);

  useGetFittingWarehouseShalves(branchId, setWarehouseShelves);
  const { deleteFittingBox } = useDeleteFittingBox(refetchFittingBoxSettings);

  useQuery(
    [
      'get_service_category_by_branch',
      location.pathname.split('/').pop().toString(),
    ],
    async () => {
      return await api.fetch<DBServiceCategoryApi['getByBranch']>(
        'get_service_category_by_branch',
        {
          branchId: location.pathname.split('/').pop().toString(),
        }
      );
    },
    {
      onSuccess: (data: DBServiceCategory[]) => {
        setServiceCategories(data);
      },
    }
  );

  useEffect(() => {
    if (initialBoxes) {
      setBoxes(initialBoxes);
    }
  }, [initialBoxes]);

  const addNewBox = () => {
    const newBoxIndex = boxes.length;
    const newBox = {
      ...(FITTING_BOX_INITIAL_DATA as DBFittingBoxExtendedSettings),
    };

    setBoxes((currentBoxes) => [...currentBoxes, newBox]);

    Object.keys(FITTING_BOX_INITIAL_DATA).forEach((key) => {
      const fieldName = `boxes[${newBoxIndex}].${key}`;
      const fieldValue = FITTING_BOX_INITIAL_DATA[key as keyof JsonObject];

      if (typeof fieldValue === 'object' && fieldValue !== null) {
        Object.keys(fieldValue).forEach((nestedKey) => {
          const nestedFieldName = `${fieldName}.${nestedKey}`;
          const nestedFieldValue = fieldValue[nestedKey];
          setValue(nestedFieldName, nestedFieldValue);
        });
      } else {
        setValue(fieldName, fieldValue);
      }
    });
  };

  const deleteBox = (box: DBFittingBoxExtendedSettings) => {
    if (box.id) {
      deleteFittingBox.mutate(box);
    }
  };

  return boxes.length === 0 ? (
    <>
      <Alert color="warning" sx={{ marginBottom: '25px' }}>
        This branch currently does not have any boxes.
      </Alert>

      <Grid container sx={{ marginTop: '25px' }}>
        <AddNewRepeaterFieldButton
          onAddNewRepeaterField={addNewBox}
          buttonText="ADD NEW BOX"
        />
      </Grid>
    </>
  ) : (
    <>
      <ExpandCollapseButtons
        color={expandedData.length > 0 ? 'warning' : 'success'}
        onExpandedDataChange={() => {
          if (boxes) {
            setExpandedData(
              expandedData.length > 0 ? [] : boxes.map((_, index) => index)
            );
          } else {
            setExpandedData([]);
          }
        }}
        flag={expandedData.length > 0}
      />
      {boxes
        // ?.sort((a, b) => {
        //   const numA = parseInt(
        //     (a.shelf?.meta.subzoneName as string)?.replace('BOX ', '')
        //   );
        //   const numB = parseInt(
        //     (b.shelf?.meta.subzoneName as string)?.replace('BOX ', '')
        //   );

        //   return numA - numB;
        // })
        .map((box: DBFittingBoxExtendedSettings, index) => {
          let name = `BOX ${index + 1}`;

          if (box.shelf !== undefined) {
            name = box.shelf?.meta.subzoneName as string;
          }

          return (
            <Accordion
              key={index}
              expanded={expandedData.includes(index)}
              square
              defaultExpanded
              sx={{
                boxShadow: '0px 1px 0px #DFE3E8',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  paddingLeft: '0px',
                }}
                onClick={() => {
                  setExpandedData(
                    expandedData.includes(index)
                      ? expandedData.filter((i) => i !== index)
                      : [...expandedData, index]
                  );
                }}
              >
                <BoxHeader>{`${name}`}</BoxHeader>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTextField(
                    'Description',
                    `boxes[${index}].description`,
                    () => true,
                    errors,
                    register,
                    { lg: 6, xs: 12 }
                  )}

                  {getAutocompleteSingleField(
                    t('Crane type'),
                    `boxes[${index}].craneType`,
                    () => false,
                    errors,
                    control,
                    { lg: 6, xs: 12 },
                    {},
                    parseEnumToArray(DBCraneTypeEnum)
                  )}

                  {getNumberInputField(
                    'Stepper',
                    `boxes[${index}].stepper`,
                    () => true,
                    errors,
                    register,
                    { lg: 6, xs: 12 }
                  )}

                  {getNumberInputField(
                    'Minimum length',
                    `boxes[${index}].minLength`,
                    () => true,
                    errors,
                    register,
                    { lg: 6, xs: 12 }
                  )}

                  {getAutocompleteSingleField(
                    t('Shelf'),
                    `boxes[${index}].shelfId`,
                    () => false,
                    errors,
                    control,
                    { lg: 6, xs: 12 },
                    {},
                    warehouseShelves
                  )}

                  {getAutocompleteSelectChip(
                    t('Service categories'),
                    `boxes[${index}].serviceCategories`,
                    () => false,
                    errors,
                    control,
                    { lg: 6, xs: 12 },
                    serviceCategories as DBServiceCategory[],
                    (option: { id: string; name: string }) => option.name,
                    false,
                    (option, value) => option.id === value.id
                  )}
                </Grid>

                <Grid
                  container
                  spacing={3}
                  justifyContent="space-between"
                  paddingTop={2}
                >
                  {getSwitchField(
                    'Active',
                    `boxes[${index}].active`,
                    errors,
                    control,
                    { lg: 3, xs: 12 },
                    { color: 'info' }
                  )}

                  {getSwitchField(
                    'VIP Box',
                    `boxes[${index}].vipBox`,
                    errors,
                    control,
                    { lg: 3, xs: 12 },
                    { color: 'info' }
                  )}

                  {getSwitchField(
                    'Black list',
                    `boxes[${index}].blackList`,
                    errors,
                    control,
                    { lg: 3, xs: 12 },
                    { color: 'info' }
                  )}
                </Grid>

                <Grid>
                  <WorkingTime
                    index={index}
                    errors={errors}
                    register={register}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </Grid>

                <Grid
                  container
                  display="flex"
                  sx={{ marginTop: '20px' }}
                  justifyContent="flex-end"
                >
                  <DeleteRepeaterFieldButton
                    onDeleteRepeaterField={() => deleteBox(box)}
                    index={index}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      <Grid container sx={{ marginTop: '25px' }}>
        <AddNewRepeaterFieldButton
          onAddNewRepeaterField={addNewBox}
          buttonText="ADD NEW BOX"
        />
      </Grid>
    </>
  );
};

export const BoxHeader = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '28px',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.3px',
  color: '#212B36',
  width: '100%',
  paddingLeft: '16px',
}));

export default FittingBoxes;
