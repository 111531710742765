import api from '@tyrio/api-factory';
import { DBTrackInfoApi } from '@tyrio/dto';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import { useMutation } from 'react-query';

export const useDeleteTrackingItem = (refetchTrackingInfo: () => void) => {
  const deleteTrackItem = useMutation(
    (req: { courierService: string; trackingNumber: string }) =>
      api.fetch<DBTrackInfoApi['delete']>('delete_track_info', {
        ...req,
      }),
    {
      mutationKey: 'delete_track_info',
      onSuccess: () => {
        refetchTrackingInfo();
      },
      onError: () => {
        ToastHelper.showToast(
          'Custom prices',
          ToastType.ERROR,
          undefined,
          'There is an error during deleting track item!'
        );
      },
    }
  );

  return { deleteTrackItem };
};
