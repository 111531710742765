import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import PriceCalculationOverview from '../features/price-calculation/PriceCalculationOverview';
import PriceCalculation from '../pages/dashboard/PriceCalculation';
export const PriceCalculationRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <PriceCalculation>
        {location.pathname.split('/').pop() === 'new' ? (
          <Route exact path={`${path}/new`}>
            <PriceCalculationOverview />
          </Route>
        ) : (
          <Route exact path={`${path}/:priceCalculationId`}>
            <PriceCalculationOverview />
          </Route>
        )}
      </PriceCalculation>
    </Switch>
  );
};
