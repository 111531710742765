import {
  Card,
  CardWrapper,
} from '../SearchPOS/ReusableSelectors/BrandSelector';

import { Content, HeaderText } from '../../steps/Search/SelectStepper';
import {
  VehicleSteps,
  useVehicleCtx,
} from '../../../../context/VehicleContext';
import { useGetVehicleModifications } from './query/get-modifications';
import { CircularProgress, Typography } from '@mui/material';
import { IModifications } from '@tyrio/dto';
import styled from '@emotion/styled';
import { Dot, TitleWrapper } from './Generation';
import { CSSProperties } from 'react';
import _, { startCase } from 'lodash';
import { tyrioUI } from '@tyrio/ui-library';

export const Modifications = () => {
  const {
    selectedModel,
    selectedBrand,
    selectedGeneration,
    selectedModification,
    setSelectedModification,
    valueChanges,
    updateValueChanges,
    setActiveStep,
  } = useVehicleCtx();

  const { vehicleModifications, isFetched, isLoading, isRefetching } =
    useGetVehicleModifications(
      selectedBrand ?? '',
      selectedModel ?? '',
      selectedGeneration ?? '',
      valueChanges[VehicleSteps.GENERATIONS] ?? true
    );

  if (isLoading || isRefetching) {
    return (
      <Content
        height={'700px'}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '700px',
        }}
      >
        <CircularProgress />
      </Content>
    );
  }

  return (
    <Content
      height={`calc(100vh - 202px)`}
      id="vehicle_modifications"
      style={{ justifyContent: 'flex-start' }}
    >
      <TitleWrapper>
        <HeaderText style={Styles.headerStyle}>
          {selectedBrand?.toUpperCase()} <Dot />
          {startCase(selectedModel ?? '')?.toUpperCase()}
        </HeaderText>
        <HeaderText>SELECT ENGINE TYPE</HeaderText>
      </TitleWrapper>

      <CardWrapper>
        {isFetched &&
          _.sortBy(vehicleModifications, 'name').map(
            (value: IModifications) => {
              const isSelected = selectedModification === value.slug;

              const bodyType = value.trim_body_types.join(', ');

              return (
                <Card
                  style={Styles.cardStyle}
                  onClick={() => {
                    updateValueChanges(
                      VehicleSteps.MODIFICATIONS,
                      selectedModification !== value?.slug
                    );

                    setSelectedModification(value.slug);
                    setActiveStep(VehicleSteps.WHEELS_FILTER);
                  }}
                  selected={isSelected}
                  key={value.slug}
                >
                  <Typography
                    fontSize={20}
                    component={'div'}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {value.name} {bodyType && <Dot />}
                    <span style={{ color: '#919EAB' }}>
                      {bodyType ? ` (${bodyType})` : null}
                    </span>
                  </Typography>

                  <SubtitleWrapper>
                    <div>
                      <Typography
                        fontSize={16}
                        color={'#919EAB'}
                        component={'div'}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {value.engine.capacity}ccm <Dot />{' '}
                        {value.engine.power.kW} kW/
                        {value.engine.power.PS} PS
                        <Dot />
                        {value.start_year} - {value.end_year}
                      </Typography>
                    </div>

                    <Typography fontSize={16} color={'#919EAB'}>
                      {value.engine.fuel?.toUpperCase()}
                    </Typography>
                  </SubtitleWrapper>
                </Card>
              );
            }
          )}
      </CardWrapper>
    </Content>
  );
};

const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const Styles: {
  cardStyle: CSSProperties;
  headerStyle: CSSProperties;
} = {
  cardStyle: {
    width: '100%',
    alignItems: 'flex-start',
    padding: '6px 24px',
    flexDirection: 'column',
  },
  headerStyle: {
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: tyrioUI.colors.black.B100,
  },
};
