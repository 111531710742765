import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { EDI_WHEEL_B4_RULESET } from './edi-b4/catalog';
import { EDI_WHEEL_B2_PRICELIST_RULESET } from './edi-b2/pricelist';
import { EDI_WHEEL_B2_STOCKLIST_RULESET } from './edi-b2/stocklist';

export const EDI_WHEEL_B4_SUPPLIER: SupplierShape = {
  weight: 101,
  override: true,
  ruleset: EDI_WHEEL_B4_RULESET,
  pricelistRuleset: EDI_WHEEL_B2_PRICELIST_RULESET,
  stockRuleset: EDI_WHEEL_B2_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    WEIGHT: {
      ',': '.',
    },
    WIDTH_MM: {
      ',': '.',
    },
    ASPECT_RATIO: { ',': '.' },
    RIM_INCH: { ',': '.' },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      dirtyFields: '{}',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'EDI WHEEL B4 PRICAT',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');
    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return json;
  },
  async preprocessCatalog(file: string) {
    const parsed = file.split('\n').slice(2).join('\n');

    const json = await csv2json({ delimiter: ';' }).fromString(parsed);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
// json.slice(1661, 1675),
