import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { AUTEC_CATALOG_RULESET } from './autec/catalog';
import { AUTEC_PRICELIST_RULESET } from './autec/pricelist';
import { AUTEC_STOCKLIST_RULESET } from './autec/stocklist';

export const AUTEC_SUPPLIER: SupplierShape = {
  weight: 100,
  override: true,
  ruleset: AUTEC_CATALOG_RULESET,
  pricelistRuleset: AUTEC_PRICELIST_RULESET,
  stockRuleset: AUTEC_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      origin: 'DE',
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'AUTEC',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    Netto: {
      ',': '.',
    },
    Lochkreis: {
      ',': '.',
    },
    ET: {
      ',': '.',
      '.0': '',
    },
    Mittenbohrung: {
      '.0': '',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const list = json.filter((item) => !!item.ArtNr);

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const list = json.filter((item) => !!item.ArtNr);

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    const list = json.filter(
      (item) =>
        !!item.LochAnzahl &&
        item.LochAnzahl !== '0' &&
        !!item.Lochkreis &&
        item.Lochkreis !== '0' &&
        !!item.Hauptmass &&
        item.Hauptmass !== '0'
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
