import { cartIcons, tyrioIcons } from '@tyrio/ui-library';
import { wmsIcons } from '@tyrio/wms-ui-library';

export const searchTypeOptions = [
  { value: 'sku', name: 'SKU' },
  { value: 'modelName', name: 'Model Name' },
  { value: 'ean', name: 'EAN' },
  { value: 'uid', name: 'UID' },
  { value: 'manufacturerCode', name: 'Manufacturer code' },
  { value: 'size', name: 'Size' },
  { value: 'productName', name: 'Product Name' },
  { value: 'brand', name: 'Brand' },
];

export const sideMenu = {
  search: {
    icon: wmsIcons.search,
    title: 'SEARCH PRODUCTS',
  },
  details: {
    icon: null,
    title: '',
  },
  inbound: {
    icon: wmsIcons.inbound,
    title: 'INBOUND ORDER',
  },
  outbound: {
    icon: wmsIcons.outbound,
    title: 'OUTBOUND ORDER',
  },
  pasteList: {
    icon: wmsIcons.list,
    title: 'PASTE ITEMS',
  },
  print: {
    icon: wmsIcons.print,
    title: 'PASTE ITEMS',
  },
  cart: {
    icon: wmsIcons.cart,
    title: 'CART',
  },
  posSearch: {
    icon: wmsIcons.search,
    title: 'SEARCH PRODUCTS',
  },
  posDetails: {
    icon: null,
    title: '',
  },
  branches: {
    icon: wmsIcons.branches,
    title: 'BRANCHES',
  },
  suppliers: {
    icon: tyrioIcons.stepMenuSuppliers,
    title: 'SUPPLIERS',
  },
  services: {
    icon: tyrioIcons.services,
    title: 'SERVICES',
  },
  posCart: {
    icon: wmsIcons.cart,
    title: 'CART',
  },
  customer: {
    icon: cartIcons.customer,
    title: 'CUSTOMER',
  },
  deliveryMethod: {
    icon: cartIcons.truck,
    title: 'DELIVERY',
  },
  posCustomer: {
    icon: cartIcons.posCustomer,
    title: 'CUSTOMER',
  },
  vehicle: {
    icon: wmsIcons.car,
    title: 'VEHICLE',
  },
  posPayment: {
    icon: cartIcons.payment,
    title: 'PAYMENT',
  },
  posAppointment: {
    icon: cartIcons.appointment,
    title: 'APPOINTMENT',
  },
};
