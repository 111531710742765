import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import { addDocument } from '@tyrio/ui-library';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import { bytesToSize } from '../../../warehouse/helper/helper';
import { useImportStockList } from '../../queries/import-stock-list';
import { CloseWrapper, Title, TitleWrapper } from './style/common.style';

interface IAddStockListItem {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const ImportStockList = ({ isOpen, setOpen }: IAddStockListItem) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    maxSize: 25 * 1024 * 1024,
  });

  const { mutate, status } = useImportStockList();

  const handleSubmit = () => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', file, file.name);
    });

    mutate(formData);
  };

  return (
    <Modal open={isOpen} onClose={() => setOpen(false)}>
      <BoxStyled>
        <CloseWrapper>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{ cursor: 'pointer' }}
          />
        </CloseWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between  ',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <TitleWrapper>
            <Title>IMPORT STOCK LIST</Title>
          </TitleWrapper>
        </Box>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        <FormWrapper>
          <Container>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Grid>
                {_.isEmpty(acceptedFiles) && (
                  <Grid>
                    <SelectDocumentWrapper disabled={false}>
                      <input style={{ display: 'none' }} {...getInputProps()} />
                      <ImgWrapper>
                        <Image
                          src={addDocument}
                          alt="add-document-icon"
                          onClick={open}
                        />
                      </ImgWrapper>
                      <TextWrapper>
                        <SelectDocumentText>
                          Upload warehouse
                        </SelectDocumentText>
                        <DropFilesText>
                          <div>Drop files or </div>
                          <BrowseTextWrapper {...getRootProps()}>
                            browse
                          </BrowseTextWrapper>
                          <div>through your machine</div>
                        </DropFilesText>
                      </TextWrapper>
                    </SelectDocumentWrapper>
                  </Grid>
                )}
                {acceptedFiles[0] && (
                  <Grid>
                    <PriceTypeContainer>
                      <PriceTypeTypography>IMPORT INFO</PriceTypeTypography>
                      <ItemContainer>
                        <Item>DOCUMENT NAME</Item>
                        <Typography>{acceptedFiles[0].name}</Typography>
                      </ItemContainer>
                      <ItemContainer>
                        <Item>SIZE</Item>
                        <Typography>
                          {bytesToSize(acceptedFiles[0].size)}
                        </Typography>
                      </ItemContainer>
                      <ItemContainer>
                        <Item>LAST MODIFIED</Item>
                        <Typography>
                          {moment(acceptedFiles[0].lastModified).format(
                            'DD.MM.YYYY HH:mm'
                          )}
                        </Typography>
                      </ItemContainer>
                    </PriceTypeContainer>
                  </Grid>
                )}
              </Grid>
              <Grid>
                {acceptedFiles[0] && (
                  <Grid sx={{ display: 'flex', gap: '10px' }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      startIcon={<UploadIcon />}
                      sx={{ marginTop: '10px' }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      IMPORT
                    </Button>
                  </Grid>
                )}
              </Grid>
              {status === 'loading' && (
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <CircularProgress size={20} sx={{ marginRight: '10px' }} />
                  Importing stock list...
                </Grid>
              )}
              {status === 'success' && (
                <Alert severity="success" sx={{ marginTop: '10px' }}>
                  Stock list successfully imported
                </Alert>
              )}
              {status === 'error' && (
                <Alert severity="error" sx={{ marginTop: '10px' }}>
                  Error during upload stock list
                </Alert>
              )}
            </Grid>
          </Container>
        </FormWrapper>
      </BoxStyled>
    </Modal>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 24;
  padding: 24px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 16px;
  flex-direction: column;
  overflow: scroll;
  justify-content: space-between;
`;

const SelectDocumentWrapper = styled.div<{ disabled: boolean }>`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  border-style: dashed;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  position: sticky;
  top: 0;
  margin-top: 10px;
`;

const ImgWrapper = styled.div`
  padding-top: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
`;

const SelectDocumentText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 137.5%;
`;

const DropFilesText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6b7280;
  display: flex;
  gap: 5px;
`;

const BrowseTextWrapper = styled.div`
  border-bottom: 1px solid #1652f7;
  padding-bottom: 0;
  color: #1652f7;
  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img``;

const PriceTypeContainer = styled.div`
  background-color: #ecf0f4;
  padding: 12px;
  border-radius: 8px;
`;

const PriceTypeTypography = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const ItemContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Item = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #454f5b;
`;
