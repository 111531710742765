export const RES: {
  // eslint-disable-next-line
  products: Record<string, any>;
  suppliers: Record<string, { name: string; supplierType: string }>;
  prices: Record<
    string,
    Record<
      string,
      {
        value: number | null;
        quantity: number;
        ecoTax: number;
        bonus: number;
        shipping: number;
      }
    >
  >;
  mainSupplier?: Record<string, { name: string; supplierType: string }>;
} = {
  products: {
    '89f7d146b66b3ec0306b0abef16955c3': {
      uid: '89f7d146b66b3ec0306b0abef16955c3',
      ean: '4019238020700',
      tyrio_generated_ean: false,
      manufacturerCode: '0358887',
      sku: 'S1556513CON55C3',
      productModelUid: '00de5413ad4d1b48987dbe402de0c3b0',
      productName: '155/65 R 13 73T EP eco',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '155',
      width2: null,
      height: '65',
      construction: 'R',
      rimDiameter: '13',
      rimDiameter2: null,
      size: '155/65 R 13',
      size2: null,
      loadIndex: '73',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'D',
      wetGrip: 'D',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481492',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481492',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '4.93',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:04:46.499Z',
      updated: '2023-01-06T15:04:46.499Z',
      active: true,
      weightMap: {
        ean: 40,
        pcd: 40,
        sku: 40,
        size: 40,
        model: 40,
        width: 40,
        height: 40,
        weight: 40,
        created: 40,
        eprelId: 40,
        updated: 40,
        wetGrip: 40,
        commMark: 40,
        eprelUrl: 40,
        specMark: 40,
        loadIndex: 40,
        weightMap: 40,
        speedIndex: 40,
        productMeta: 40,
        productName: 40,
        rimDiameter: 40,
        construction: 40,
        importObject: 40,
        productName2: 40,
        vehicleClass: 40,
        noiseClassType: 40,
        specialMarking: 40,
        manufacturerCode: 40,
        noisePerformance: 40,
        rollingResistance: 40,
      },
      productMeta: {
        rfd: {},
        specialMarking: {
          ECO: {
            id: 197,
            key: 'eco',
            icon: '',
            name: 'eco',
            type: ['specMark'],
            description: 'Eco tyre',
            shortDescription: 'Eco',
          },
        },
      },
      model: {
        uid: '00de5413ad4d1b48987dbe402de0c3b0',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'ContiEcoContact EP',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.759Z',
        updated: '2023-01-06T15:00:06.760Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '37ed8b83-45bd-4075-9387-9fe8d806b8c3',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '89f7d146b66b3ec0306b0abef16955c3',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 46.66,
          suggestedRetailPrice: 46.66,
          ecoTax: 0.2465,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '5b41a7af-c2c4-4ee6-9e88-55ec3bd73a0d',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '89f7d146b66b3ec0306b0abef16955c3',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 1,
          suggestedRetailPrice: 1,
          ecoTax: 0.2465,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'de0ee440-9897-435b-bb3f-e5bc6dd7177b',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '8f3604b8-e19d-4862-9bb9-795e989c4744',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '89f7d146b66b3ec0306b0abef16955c3',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.2465,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 10,
          productYear: 2022,
          importKey: '231db671-777e-4d64-baad-0964b2cbc9b0',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    '150d5b21b38314abb702eb358ccbfcbb': {
      uid: '150d5b21b38314abb702eb358ccbfcbb',
      ean: '4019238055825',
      tyrio_generated_ean: false,
      manufacturerCode: '0312005',
      sku: 'S1557014CONFCBB',
      productModelUid: 'f54c955e96d7970b6b1c80f97f3d9f10',
      productName: '155/70 R 14 77T 6 eco',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '155',
      width2: null,
      height: '70',
      construction: 'R',
      rimDiameter: '14',
      rimDiameter2: null,
      size: '155/70 R 14',
      size2: null,
      loadIndex: '77',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'C',
      wetGrip: 'B',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481329',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481329',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5.62',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:04:46.513Z',
      updated: '2023-01-06T15:04:46.513Z',
      active: true,
      weightMap: {
        ean: 40,
        pcd: 40,
        sku: 40,
        size: 40,
        model: 40,
        width: 40,
        height: 40,
        weight: 40,
        created: 40,
        eprelId: 40,
        updated: 40,
        wetGrip: 40,
        commMark: 40,
        eprelUrl: 40,
        specMark: 40,
        loadIndex: 40,
        weightMap: 40,
        speedIndex: 40,
        productMeta: 40,
        productName: 40,
        rimDiameter: 40,
        construction: 40,
        importObject: 40,
        productName2: 40,
        vehicleClass: 40,
        noiseClassType: 40,
        specialMarking: 40,
        manufacturerCode: 40,
        noisePerformance: 40,
        rollingResistance: 40,
      },
      productMeta: {
        rfd: {},
        specialMarking: {
          ECO: {
            id: 197,
            key: 'eco',
            icon: '',
            name: 'eco',
            type: ['specMark'],
            description: 'Eco tyre',
            shortDescription: 'Eco',
          },
        },
      },
      model: {
        uid: 'f54c955e96d7970b6b1c80f97f3d9f10',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'EcoContact 6',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.645Z',
        updated: '2023-01-06T15:00:06.645Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '467b47fa-90e6-426d-94a2-52e9aacb2012',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '150d5b21b38314abb702eb358ccbfcbb',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 52.67,
          suggestedRetailPrice: 52.67,
          ecoTax: 0.281,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'eb4cbb46-963d-45de-a0ed-aa0cd3339619',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '150d5b21b38314abb702eb358ccbfcbb',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 1,
          suggestedRetailPrice: 1,
          ecoTax: 0.281,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'de0ee440-9897-435b-bb3f-e5bc6dd7177b',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '4d123a43-745e-46b3-b0f7-c04fab74fc4f',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '150d5b21b38314abb702eb358ccbfcbb',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.281,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 0,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'c71b62f8-ccf0-4c72-9018-77e2db46e173',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '150d5b21b38314abb702eb358ccbfcbb',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.281,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 50,
          productYear: 2022,
          importKey: '231db671-777e-4d64-baad-0964b2cbc9b0',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    '1f5377d76fe1e8b33a59e7f4fd73cde8': {
      uid: '1f5377d76fe1e8b33a59e7f4fd73cde8',
      ean: '4019238055894',
      tyrio_generated_ean: false,
      manufacturerCode: '0311978',
      sku: 'S1656014CONCDE8',
      productModelUid: 'f54c955e96d7970b6b1c80f97f3d9f10',
      productName: '165/60 R 14 75H 6 eco',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '165',
      width2: null,
      height: '60',
      construction: 'R',
      rimDiameter: '14',
      rimDiameter2: null,
      size: '165/60 R 14',
      size2: null,
      loadIndex: '75',
      speedIndex: 'H',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'C',
      wetGrip: 'B',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481467',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481467',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5.7',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:04:46.521Z',
      updated: '2023-01-06T15:04:46.521Z',
      active: true,
      weightMap: {
        ean: 40,
        pcd: 40,
        sku: 40,
        size: 40,
        model: 40,
        width: 40,
        height: 40,
        weight: 40,
        created: 40,
        eprelId: 40,
        updated: 40,
        wetGrip: 40,
        commMark: 40,
        eprelUrl: 40,
        specMark: 40,
        loadIndex: 40,
        weightMap: 40,
        speedIndex: 40,
        productMeta: 40,
        productName: 40,
        rimDiameter: 40,
        construction: 40,
        importObject: 40,
        productName2: 40,
        vehicleClass: 40,
        noiseClassType: 40,
        specialMarking: 40,
        manufacturerCode: 40,
        noisePerformance: 40,
        rollingResistance: 40,
      },
      productMeta: {
        rfd: {},
        specialMarking: {
          ECO: {
            id: 197,
            key: 'eco',
            icon: '',
            name: 'eco',
            type: ['specMark'],
            description: 'Eco tyre',
            shortDescription: 'Eco',
          },
        },
      },
      model: {
        uid: 'f54c955e96d7970b6b1c80f97f3d9f10',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'EcoContact 6',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.645Z',
        updated: '2023-01-06T15:00:06.645Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '1b86117a-da8f-4a2a-b8e3-52ca28d35f8c',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '1f5377d76fe1e8b33a59e7f4fd73cde8',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.285,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 0,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '4475d966-5449-4850-982c-4ba1764e6890',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '1f5377d76fe1e8b33a59e7f4fd73cde8',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 65.12,
          suggestedRetailPrice: 65.12,
          ecoTax: 0.285,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'bd3d6cf3-6b7d-4b8a-a8c1-b6f2a494f240',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '1f5377d76fe1e8b33a59e7f4fd73cde8',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 1,
          suggestedRetailPrice: 1,
          ecoTax: 0.285,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'de0ee440-9897-435b-bb3f-e5bc6dd7177b',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '9add0a4d-80dd-458e-871e-10da6dba4bbf',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '1f5377d76fe1e8b33a59e7f4fd73cde8',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.285,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 50,
          productYear: 2022,
          importKey: '231db671-777e-4d64-baad-0964b2cbc9b0',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    bf132fff8eb3a4db670a018cd2ba75da: {
      uid: 'bf132fff8eb3a4db670a018cd2ba75da',
      ean: '4019238065794',
      tyrio_generated_ean: false,
      manufacturerCode: '0312306',
      sku: 'S1556514CON75DA',
      productModelUid: '06686f569f0774ece5a27f5b36e1abda',
      productName: '155/65 R 14 75T ULTRACONTACT',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '155',
      width2: null,
      height: '65',
      construction: 'R',
      rimDiameter: '14',
      rimDiameter2: null,
      size: '155/65 R 14',
      size2: null,
      loadIndex: '75',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'C',
      wetGrip: 'A',
      noisePerformance: 69,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '710342',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/710342',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5.3',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:04:46.507Z',
      updated: '2023-01-06T15:04:46.507Z',
      active: true,
      weightMap: {
        ean: 40,
        pcd: 40,
        sku: 40,
        size: 40,
        model: 40,
        width: 40,
        height: 40,
        weight: 40,
        created: 40,
        eprelId: 40,
        updated: 40,
        wetGrip: 40,
        commMark: 40,
        eprelUrl: 40,
        loadIndex: 40,
        weightMap: 40,
        speedIndex: 40,
        productMeta: 40,
        productName: 40,
        rimDiameter: 40,
        construction: 40,
        importObject: 40,
        productName2: 40,
        vehicleClass: 40,
        noiseClassType: 40,
        manufacturerCode: 40,
        noisePerformance: 40,
        rollingResistance: 40,
      },
      productMeta: { rfd: {}, specialMarking: {} },
      model: {
        uid: '06686f569f0774ece5a27f5b36e1abda',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'UltraContact',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.715Z',
        updated: '2023-01-06T15:00:06.715Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '67037d30-3704-4ab0-9a80-d6e19dd350e6',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'bf132fff8eb3a4db670a018cd2ba75da',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.265,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 7,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '467a2c00-5cd5-4515-ad61-a25ea9ff8db4',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'bf132fff8eb3a4db670a018cd2ba75da',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 1,
          suggestedRetailPrice: 1,
          ecoTax: 0.265,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'de0ee440-9897-435b-bb3f-e5bc6dd7177b',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'a14d1d82-b439-4f6a-a3cc-2c09240a4355',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'bf132fff8eb3a4db670a018cd2ba75da',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 52.97,
          suggestedRetailPrice: 52.97,
          ecoTax: 0.265,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '52920a25-5e1f-44d3-9e02-a4180bdcc077',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'bf132fff8eb3a4db670a018cd2ba75da',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.265,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 50,
          productYear: 2022,
          importKey: '231db671-777e-4d64-baad-0964b2cbc9b0',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    '844efcc444478cd4b197f4711e38889c': {
      uid: '844efcc444478cd4b197f4711e38889c',
      ean: '4019238258899',
      tyrio_generated_ean: false,
      manufacturerCode: '03520070000',
      sku: 'S145.007013.0CON889C',
      productModelUid: 'b57a0571cfff031e7ccd702e275e622e',
      productName: '145.00/70 R 13.0 71T ContiEcoContact 3',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '145.00',
      width2: null,
      height: '70',
      construction: 'R',
      rimDiameter: '13.0',
      rimDiameter2: null,
      size: '145.00/70 R 13.0',
      size2: null,
      loadIndex: '71',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'D',
      wetGrip: 'B',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481351',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481351',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '4,741',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T14:59:59.321Z',
      updated: '2023-01-06T14:59:59.321Z',
      active: true,
      weightMap: {
        ean: 1,
        pcd: 1,
        sku: 1,
        size: 1,
        model: 1,
        width: 1,
        height: 1,
        origin: 1,
        weight: 1,
        created: 1,
        eprelId: 1,
        updated: 1,
        wetGrip: 1,
        commMark: 1,
        eprelUrl: 1,
        tubeType: 1,
        loadIndex: 1,
        weightMap: 1,
        speedIndex: 1,
        productMeta: 1,
        productName: 1,
        rimDiameter: 1,
        construction: 1,
        importObject: 1,
        vehicleClass: 1,
        noiseClassType: 1,
        manufacturerCode: 1,
        noisePerformance: 1,
        euDirectiveNumber: 1,
        rollingResistance: 1,
      },
      productMeta: { rfd: {}, specialMarking: {} },
      model: {
        uid: 'b57a0571cfff031e7ccd702e275e622e',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'ContiEcoContact 3',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.738Z',
        updated: '2023-01-06T15:00:06.738Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: 'e09ef855-555b-4e67-a3e2-a4501ffadb53',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '844efcc444478cd4b197f4711e38889c',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.23705,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '8268f123-94ba-42e0-8d52-40325556d4ee',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '844efcc444478cd4b197f4711e38889c',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 47.73,
          suggestedRetailPrice: 47.73,
          ecoTax: 0.23705,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    '402513595d7671d37968e168ee4ee552': {
      uid: '402513595d7671d37968e168ee4ee552',
      ean: '4019238258912',
      tyrio_generated_ean: false,
      manufacturerCode: '0352009',
      sku: 'S1657013CONE552',
      productModelUid: 'b57a0571cfff031e7ccd702e275e622e',
      productName: '165/70 R 13 79T 3 eco',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '165',
      width2: null,
      height: '70',
      construction: 'R',
      rimDiameter: '13',
      rimDiameter2: null,
      size: '165/70 R 13',
      size2: null,
      loadIndex: '79',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'D',
      wetGrip: 'B',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '587943',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/587943',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5.97',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:04:46.533Z',
      updated: '2023-01-06T15:04:46.533Z',
      active: true,
      weightMap: {
        ean: 40,
        pcd: 40,
        sku: 40,
        size: 40,
        model: 40,
        width: 40,
        height: 40,
        weight: 40,
        created: 40,
        eprelId: 40,
        updated: 40,
        wetGrip: 40,
        commMark: 40,
        eprelUrl: 40,
        specMark: 40,
        loadIndex: 40,
        weightMap: 40,
        speedIndex: 40,
        productMeta: 40,
        productName: 40,
        rimDiameter: 40,
        construction: 40,
        importObject: 40,
        productName2: 40,
        vehicleClass: 40,
        noiseClassType: 40,
        specialMarking: 40,
        manufacturerCode: 40,
        noisePerformance: 40,
        rollingResistance: 40,
      },
      productMeta: {
        rfd: {},
        specialMarking: {
          ECO: {
            id: 197,
            key: 'eco',
            icon: '',
            name: 'eco',
            type: ['specMark'],
            description: 'Eco tyre',
            shortDescription: 'Eco',
          },
        },
      },
      model: {
        uid: 'b57a0571cfff031e7ccd702e275e622e',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'ContiEcoContact 3',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.738Z',
        updated: '2023-01-06T15:00:06.738Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '04ef843f-16a7-40b9-8bd7-d9c550b67701',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '402513595d7671d37968e168ee4ee552',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 49.43,
          suggestedRetailPrice: 49.43,
          ecoTax: 0.2985,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'b6b09d85-d3e7-45aa-b3c4-6e3963c8279f',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '402513595d7671d37968e168ee4ee552',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 1,
          suggestedRetailPrice: 1,
          ecoTax: 0.2985,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'de0ee440-9897-435b-bb3f-e5bc6dd7177b',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'fc1f7c79-525a-4002-b661-8538c9ed8d53',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '402513595d7671d37968e168ee4ee552',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.2985,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '617b2cee-b6e6-4053-8e2a-00f70ae9a7a2',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '402513595d7671d37968e168ee4ee552',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.2985,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 3,
          productYear: 2022,
          importKey: '231db671-777e-4d64-baad-0964b2cbc9b0',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    eb25272cc42aa133123cd695e1ff2ce5: {
      uid: 'eb25272cc42aa133123cd695e1ff2ce5',
      ean: '4019238318456',
      tyrio_generated_ean: false,
      manufacturerCode: '03526100000',
      sku: 'S165.007013.0CON2CE5',
      productModelUid: 'b57a0571cfff031e7ccd702e275e622e',
      productName: '165.00/70 R 13.0 83T ContiEcoContact 3 XL',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '165.00',
      width2: null,
      height: '70',
      construction: 'R',
      rimDiameter: '13.0',
      rimDiameter2: null,
      size: '165.00/70 R 13.0',
      size2: null,
      loadIndex: '83',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: 'XL',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'C',
      wetGrip: 'B',
      noisePerformance: 71,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481639',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481639',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5,723',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T14:59:59.348Z',
      updated: '2023-01-06T14:59:59.348Z',
      active: true,
      weightMap: {
        ean: 1,
        pcd: 1,
        rfd: 1,
        sku: 1,
        size: 1,
        model: 1,
        width: 1,
        height: 1,
        origin: 1,
        weight: 1,
        created: 1,
        eprelId: 1,
        updated: 1,
        wetGrip: 1,
        commMark: 1,
        eprelUrl: 1,
        tubeType: 1,
        loadIndex: 1,
        weightMap: 1,
        speedIndex: 1,
        productMeta: 1,
        productName: 1,
        rimDiameter: 1,
        construction: 1,
        importObject: 1,
        vehicleClass: 1,
        noiseClassType: 1,
        manufacturerCode: 1,
        noisePerformance: 1,
        euDirectiveNumber: 1,
        rollingResistance: 1,
      },
      productMeta: { rfd: {}, specialMarking: {} },
      model: {
        uid: 'b57a0571cfff031e7ccd702e275e622e',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'ContiEcoContact 3',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.738Z',
        updated: '2023-01-06T15:00:06.738Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: 'a5c7051c-9dfa-4c93-9645-b0216a75a96a',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'eb25272cc42aa133123cd695e1ff2ce5',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.28615,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '2109d2cd-732c-4787-a416-fe456fa482a4',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'eb25272cc42aa133123cd695e1ff2ce5',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 54.12,
          suggestedRetailPrice: 54.12,
          ecoTax: 0.28615,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    '54d9c61ca4f5dffc58e4b4421ed1ca0b': {
      uid: '54d9c61ca4f5dffc58e4b4421ed1ca0b',
      ean: '4019238528077',
      tyrio_generated_ean: false,
      manufacturerCode: '03561150000',
      sku: 'S125.008013.0CONCA0B',
      productModelUid: '49b4d04973a00969a99f2e5da6986dfd',
      productName: '125.00/80 R 13.0 65M Conti.eContact ELECTRIC',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '125.00',
      width2: null,
      height: '80',
      construction: 'R',
      rimDiameter: '13.0',
      rimDiameter2: null,
      size: '125.00/80 R 13.0',
      size2: null,
      loadIndex: '65',
      speedIndex: 'M',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: ' ELECTRIC',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: true,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'D',
      wetGrip: 'D',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481596',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481596',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '4,287',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:00:02.769Z',
      updated: '2023-01-06T15:00:02.769Z',
      active: true,
      weightMap: {
        ean: 1,
        pcd: 1,
        sku: 1,
        size: 1,
        model: 1,
        width: 1,
        height: 1,
        origin: 1,
        weight: 1,
        created: 1,
        eprelId: 1,
        updated: 1,
        wetGrip: 1,
        commMark: 1,
        electric: 1,
        eprelUrl: 1,
        tubeType: 1,
        loadIndex: 1,
        weightMap: 1,
        speedIndex: 1,
        productMeta: 1,
        productName: 1,
        rimDiameter: 1,
        construction: 1,
        importObject: 1,
        vehicleClass: 1,
        noiseClassType: 1,
        specialMarking: 1,
        manufacturerCode: 1,
        noisePerformance: 1,
        euDirectiveNumber: 1,
        rollingResistance: 1,
      },
      productMeta: { rfd: {}, specialMarking: {} },
      model: {
        uid: '49b4d04973a00969a99f2e5da6986dfd',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'Conti.eContact',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.894Z',
        updated: '2023-01-06T15:00:06.895Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: 'e362b983-614b-4536-82a4-2a7b77575729',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '54d9c61ca4f5dffc58e4b4421ed1ca0b',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 41.79,
          suggestedRetailPrice: 41.79,
          ecoTax: 0.21435,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '5883913b-045c-402a-b4f5-3ec6147341a4',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '54d9c61ca4f5dffc58e4b4421ed1ca0b',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.21435,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    '3ec6c893b6f63745a8006f571a097d07': {
      uid: '3ec6c893b6f63745a8006f571a097d07',
      ean: '4019238528084',
      tyrio_generated_ean: false,
      manufacturerCode: '03561170000',
      sku: 'S145.008013.0CON7D07',
      productModelUid: '49b4d04973a00969a99f2e5da6986dfd',
      productName: '145.00/80 R 13.0 75M Conti.eContact ELECTRIC',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '145.00',
      width2: null,
      height: '80',
      construction: 'R',
      rimDiameter: '13.0',
      rimDiameter2: null,
      size: '145.00/80 R 13.0',
      size2: null,
      loadIndex: '75',
      speedIndex: 'M',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: ' ELECTRIC',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: true,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'D',
      wetGrip: 'D',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481292',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481292',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5,287',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:00:02.772Z',
      updated: '2023-01-06T15:00:02.772Z',
      active: true,
      weightMap: {
        ean: 1,
        pcd: 1,
        sku: 1,
        size: 1,
        model: 1,
        width: 1,
        height: 1,
        origin: 1,
        weight: 1,
        created: 1,
        eprelId: 1,
        updated: 1,
        wetGrip: 1,
        commMark: 1,
        electric: 1,
        eprelUrl: 1,
        tubeType: 1,
        loadIndex: 1,
        weightMap: 1,
        speedIndex: 1,
        productMeta: 1,
        productName: 1,
        rimDiameter: 1,
        construction: 1,
        importObject: 1,
        vehicleClass: 1,
        noiseClassType: 1,
        specialMarking: 1,
        manufacturerCode: 1,
        noisePerformance: 1,
        euDirectiveNumber: 1,
        rollingResistance: 1,
      },
      productMeta: { rfd: {}, specialMarking: {} },
      model: {
        uid: '49b4d04973a00969a99f2e5da6986dfd',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'Conti.eContact',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.894Z',
        updated: '2023-01-06T15:00:06.895Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '42163c56-34b0-4caf-bcce-23408cda0428',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '3ec6c893b6f63745a8006f571a097d07',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 50.44,
          suggestedRetailPrice: 50.44,
          ecoTax: 0.26435,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '1afd0718-3521-4c38-94af-0a6f92b5e18b',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '3ec6c893b6f63745a8006f571a097d07',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.26435,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    d0c3eaabe9ab3070ebaa12ef4123d623: {
      uid: 'd0c3eaabe9ab3070ebaa12ef4123d623',
      ean: '4019238816990',
      tyrio_generated_ean: false,
      manufacturerCode: '03583240000',
      sku: 'S155.007013.0COND623',
      productModelUid: 'f54c955e96d7970b6b1c80f97f3d9f10',
      productName: '155.00/70 R 13.0 75T EcoContact 6',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '155.00',
      width2: null,
      height: '70',
      construction: 'R',
      rimDiameter: '13.0',
      rimDiameter2: null,
      size: '155.00/70 R 13.0',
      size2: null,
      loadIndex: '75',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'C',
      wetGrip: 'B',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481423',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481423',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5,373',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:00:04.193Z',
      updated: '2023-01-06T15:00:04.193Z',
      active: true,
      weightMap: {
        ean: 1,
        pcd: 1,
        sku: 1,
        size: 1,
        model: 1,
        width: 1,
        height: 1,
        origin: 1,
        weight: 1,
        created: 1,
        eprelId: 1,
        updated: 1,
        wetGrip: 1,
        commMark: 1,
        eprelUrl: 1,
        tubeType: 1,
        loadIndex: 1,
        weightMap: 1,
        speedIndex: 1,
        productMeta: 1,
        productName: 1,
        rimDiameter: 1,
        construction: 1,
        importObject: 1,
        vehicleClass: 1,
        noiseClassType: 1,
        manufacturerCode: 1,
        noisePerformance: 1,
        euDirectiveNumber: 1,
        rollingResistance: 1,
      },
      productMeta: { rfd: {}, specialMarking: {} },
      model: {
        uid: 'f54c955e96d7970b6b1c80f97f3d9f10',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'EcoContact 6',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.645Z',
        updated: '2023-01-06T15:00:06.645Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '497df74a-1d92-453e-aa59-fd5b88c3c6cb',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'd0c3eaabe9ab3070ebaa12ef4123d623',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 45.3,
          suggestedRetailPrice: 45.3,
          ecoTax: 0.26865,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '2bdedda0-f267-4211-89a8-721024709492',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'd0c3eaabe9ab3070ebaa12ef4123d623',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.26865,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 1,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    '865fad473ea839a7fe2e27ef9a74af3e': {
      uid: '865fad473ea839a7fe2e27ef9a74af3e',
      ean: '4019238817027',
      tyrio_generated_ean: false,
      manufacturerCode: '03582980000',
      sku: 'S155.008013.0CONAF3E',
      productModelUid: 'f54c955e96d7970b6b1c80f97f3d9f10',
      productName: '155.00/80 R 13.0 79T EcoContact 6',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '155.00',
      width2: null,
      height: '80',
      construction: 'R',
      rimDiameter: '13.0',
      rimDiameter2: null,
      size: '155.00/80 R 13.0',
      size2: null,
      loadIndex: '79',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'C',
      wetGrip: 'B',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481500',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481500',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '5,985',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:00:04.170Z',
      updated: '2023-01-06T15:00:04.170Z',
      active: true,
      weightMap: {
        ean: 1,
        pcd: 1,
        sku: 1,
        size: 1,
        model: 1,
        width: 1,
        height: 1,
        origin: 1,
        weight: 1,
        created: 1,
        eprelId: 1,
        updated: 1,
        wetGrip: 1,
        commMark: 1,
        eprelUrl: 1,
        tubeType: 1,
        loadIndex: 1,
        weightMap: 1,
        speedIndex: 1,
        productMeta: 1,
        productName: 1,
        rimDiameter: 1,
        construction: 1,
        importObject: 1,
        vehicleClass: 1,
        noiseClassType: 1,
        manufacturerCode: 1,
        noisePerformance: 1,
        euDirectiveNumber: 1,
        rollingResistance: 1,
      },
      productMeta: { rfd: {}, specialMarking: {} },
      model: {
        uid: 'f54c955e96d7970b6b1c80f97f3d9f10',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'EcoContact 6',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.645Z',
        updated: '2023-01-06T15:00:06.645Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '277e4d84-492f-4083-9384-b6a9ab54dd0c',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '865fad473ea839a7fe2e27ef9a74af3e',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 48.5,
          suggestedRetailPrice: 48.5,
          ecoTax: 0.29925,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '30a9e9e2-a3d6-4f35-85b7-608cdefa71e6',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: '865fad473ea839a7fe2e27ef9a74af3e',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.29925,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
    fb937711a666273d655b37e0a65443db: {
      uid: 'fb937711a666273d655b37e0a65443db',
      ean: '4019238817034',
      tyrio_generated_ean: false,
      manufacturerCode: '0358304',
      sku: 'S1757013CON43DB',
      productModelUid: 'f54c955e96d7970b6b1c80f97f3d9f10',
      productName: '175/70 R 13 82T 6 eco',
      productName2: '',
      importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
      deprecated: false,
      rfid: null,
      width: '175',
      width2: null,
      height: '70',
      construction: 'R',
      rimDiameter: '13',
      rimDiameter2: null,
      size: '175/70 R 13',
      size2: null,
      loadIndex: '82',
      speedIndex: 'T',
      loadIndex2: null,
      speedIndex2: null,
      specialMarking: '',
      specialMarkingType: null,
      ms: false,
      threepmfs: false,
      iceGrip: null,
      tubeType: 'TL',
      pr: '',
      rfd: '',
      structure: null,
      compound: null,
      commMark: true,
      runFlat: null,
      flank: false,
      seal: false,
      silent: false,
      electric: false,
      oem: false,
      other: false,
      demo: false,
      euDirectiveNumber: '2020/740',
      rollingResistance: 'B',
      wetGrip: 'B',
      noisePerformance: 70,
      noiseClassType: 'B',
      vehicleClass: 'C1',
      eprelId: '481303',
      eprelUrl: 'https://eprel.ec.europa.eu/qr/481303',
      holeNumber: null,
      holeCircle: null,
      pcd: 'x',
      et: null,
      cb: null,
      installationKit: null,
      vehicleBrand: null,
      origin: 'GERMANY',
      weight: '6.29',
      crossSellProducts: null,
      crossSellServices: null,
      relatedProducts: null,
      remark: null,
      dataSource: null,
      created: '2023-01-06T15:04:46.578Z',
      updated: '2023-01-06T15:04:46.578Z',
      active: true,
      weightMap: {
        ean: 40,
        pcd: 40,
        sku: 40,
        size: 40,
        model: 40,
        width: 40,
        height: 40,
        weight: 40,
        created: 40,
        eprelId: 40,
        updated: 40,
        wetGrip: 40,
        commMark: 40,
        eprelUrl: 40,
        specMark: 40,
        loadIndex: 40,
        weightMap: 40,
        speedIndex: 40,
        productMeta: 40,
        productName: 40,
        rimDiameter: 40,
        construction: 40,
        importObject: 40,
        productName2: 40,
        vehicleClass: 40,
        noiseClassType: 40,
        specialMarking: 40,
        manufacturerCode: 40,
        noisePerformance: 40,
        rollingResistance: 40,
      },
      productMeta: {
        rfd: {},
        specialMarking: {
          ECO: {
            id: 197,
            key: 'eco',
            icon: '',
            name: 'eco',
            type: ['specMark'],
            description: 'Eco tyre',
            shortDescription: 'Eco',
          },
        },
      },
      model: {
        uid: 'f54c955e96d7970b6b1c80f97f3d9f10',
        category_id: 30,
        importKey: '0c277831-be6c-44b9-8e54-5352d4901be3',
        deprecated: false,
        season: 'summer',
        brand: 'CONTINENTAL',
        modelName: 'EcoContact 6',
        usage: null,
        color: null,
        colorGroup: null,
        colorFinish: null,
        wheelPosition: null,
        descTitle: null,
        descSubtitle: null,
        descIntro: null,
        descContent: null,
        sidewall: '',
        videoUrl: null,
        remark: null,
        dataSource: 'CONTINENTAL EDI PRICAT',
        created: '2023-01-06T15:00:06.645Z',
        updated: '2023-01-06T15:00:06.645Z',
        active: true,
        weightMap: {
          brand: 1,
          season: 1,
          category: 1,
          modelName: 1,
          weightMap: 1,
          dataSource: 1,
          importObject: 1,
        },
        productMeta: {},
      },
      supplierStock: [
        {
          id: '85a7bd32-c664-4ccd-be13-a6d54dbca922',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'fb937711a666273d655b37e0a65443db',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 1,
          suggestedRetailPrice: 1,
          ecoTax: 0.3145,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'de0ee440-9897-435b-bb3f-e5bc6dd7177b',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'd4fc9be9-d291-42a9-8981-470e75c35876',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'fb937711a666273d655b37e0a65443db',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: 50.53,
          suggestedRetailPrice: 50.53,
          ecoTax: 0.3145,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: '2f8c8461-2476-4da3-a90c-2eea4306a3dd',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: '1fd0d535-14ae-49b7-9071-6c02428f2801',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'fb937711a666273d655b37e0a65443db',
          supplierId: '00000000-0000-0000-0000-000000000009',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.3145,
          packageQuantity: 1,
          transportCost: 2,
          quantity: null,
          productYear: 2022,
          importKey: 'bba829ca-8752-425c-a225-e082b2052ee8',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
        {
          id: 'e328e144-138a-4d69-a1ba-1ef844555cb7',
          clientId: '00000000-0000-1000-b000-0000000000022',
          productId: 'fb937711a666273d655b37e0a65443db',
          supplierId: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
          supplierWarehouseId: '00000000-0000-0000-0000-000000000002',
          supplierWarehouseZip: '88000',
          supplierProductCode: 'NOT_AVAILABLE',
          supplierProductName: 'TODO:GET_FROM_RAW',
          purchasePrice: null,
          suggestedRetailPrice: null,
          ecoTax: 0.3145,
          packageQuantity: 1,
          transportCost: 2,
          quantity: 3,
          productYear: 2022,
          importKey: '231db671-777e-4d64-baad-0964b2cbc9b0',
          demo: false,
          nextShippingDate: '2023-01-06T00:00:00.000Z',
          remark: 'Imported',
          active: true,
        },
      ],
    },
  },
  suppliers: {
    '00000000-0000-0000-0000-000000000009': {
      name: 'Continental',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000010': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000011': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000012': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000013': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000014': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000015': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000016': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
    '00000000-0000-0000-0000-000000000017': {
      name: 'Gundlach',
      supplierType: 'MANUFACTURER',
    },
  },
  prices: {
    '89f7d146b66b3ec0306b0abef16955c3': {
      '00000000-0000-0000-0000-000000000009': {
        value: 13.5,
        quantity: 0,
        ecoTax: 0.2465,
        bonus: 0.9,
        shipping: 2,
      },
      '00000000-0000-0000-0000-000000000010': {
        value: 12.5,
        quantity: 0,
        ecoTax: 0.2465,
        bonus: 0.9,
        shipping: 2,
      },
      '537e4b56-efba-4bb6-bdbb-9cee1dd38b53': {
        value: 12.5,
        quantity: 10,
        ecoTax: 0.2465,
        bonus: 0,
        shipping: 2,
      },
      '00000000-0000-0000-0000-000000000011': {
        value: 10.5,
        quantity: 10,
        ecoTax: 0.2465,
        bonus: 0,
        shipping: 2,
      },
    },
    '150d5b21b38314abb702eb358ccbfcbb': {
      '00000000-0000-0000-0000-000000000009': {
        value: null,
        quantity: 0,
        ecoTax: 0.281,
        bonus: 0,
        shipping: 2,
      },
      '537e4b56-efba-4bb6-bdbb-9cee1dd38b53': {
        value: null,
        quantity: 50,
        ecoTax: 0.281,
        bonus: 0,
        shipping: 2,
      },
    },
    '1f5377d76fe1e8b33a59e7f4fd73cde8': {
      '00000000-0000-0000-0000-000000000009': {
        value: 65.12,
        quantity: 0,
        ecoTax: 0.285,
        bonus: 1.3,
        shipping: 2,
      },
      '537e4b56-efba-4bb6-bdbb-9cee1dd38b53': {
        value: null,
        quantity: 50,
        ecoTax: 0.285,
        bonus: 0,
        shipping: 2,
      },
    },
    bf132fff8eb3a4db670a018cd2ba75da: {
      '00000000-0000-0000-0000-000000000009': {
        value: 52.97,
        quantity: 25000,
        ecoTax: 0.265,
        bonus: 1,
        shipping: 2,
      },
      '537e4b56-efba-4bb6-bdbb-9cee1dd38b53': {
        value: 55.9,
        quantity: 50,
        ecoTax: 0.265,
        bonus: 0,
        shipping: 2,
      },
    },
    '844efcc444478cd4b197f4711e38889c': {
      '00000000-0000-0000-0000-000000000009': {
        value: 47.73,
        quantity: 5,
        ecoTax: 0.23705,
        bonus: 0.9,
        shipping: 2,
      },
    },
    '402513595d7671d37968e168ee4ee552': {
      '00000000-0000-0000-0000-000000000009': {
        value: null,
        quantity: 0,
        ecoTax: 0.2985,
        bonus: 0,
        shipping: 2,
      },
      '537e4b56-efba-4bb6-bdbb-9cee1dd38b53': {
        value: null,
        quantity: 3,
        ecoTax: 0.2985,
        bonus: 0,
        shipping: 2,
      },
    },
    eb25272cc42aa133123cd695e1ff2ce5: {
      '00000000-0000-0000-0000-000000000009': {
        value: 54.12,
        quantity: 12,
        ecoTax: 0.28615,
        bonus: 1,
        shipping: 2,
      },
    },
    '54d9c61ca4f5dffc58e4b4421ed1ca0b': {
      '00000000-0000-0000-0000-000000000009': {
        value: null,
        quantity: 34,
        ecoTax: 0.21435,
        bonus: 0,
        shipping: 2,
      },
    },
    '3ec6c893b6f63745a8006f571a097d07': {
      '00000000-0000-0000-0000-000000000009': {
        value: null,
        quantity: 12,
        ecoTax: 0.26435,
        bonus: 0,
        shipping: 2,
      },
    },
    d0c3eaabe9ab3070ebaa12ef4123d623: {
      '00000000-0000-0000-0000-000000000009': {
        value: null,
        quantity: 1,
        ecoTax: 0.26865,
        bonus: 0,
        shipping: 2,
      },
    },
    '865fad473ea839a7fe2e27ef9a74af3e': {
      '00000000-0000-0000-0000-000000000009': {
        value: null,
        quantity: 0,
        ecoTax: 0.29925,
        bonus: 0,
        shipping: 2,
      },
    },
    fb937711a666273d655b37e0a65443db: {
      '537e4b56-efba-4bb6-bdbb-9cee1dd38b53': {
        value: null,
        quantity: 3,
        ecoTax: 0.3145,
        bonus: 0,
        shipping: 2,
      },
      '00000000-0000-0000-0000-000000000009': {
        value: null,
        quantity: 0,
        ecoTax: 0.3145,
        bonus: 0,
        shipping: 2,
      },
    },
  },
  mainSupplier: {
    '537e4b56-efba-4bb6-bdbb-9cee1dd38b53': {
      name: 'VAN',
      supplierType: 'DISTRIBUTER',
    },
  },
};

export const eanClipboard = `4019238065794	4
  4019238055825	4
  4019238055894	4
  4019238404517	4
  4019238032475	4
  4019238065640	4
  4019238547146	4
  4019238032956	4
  4019238065770	4
  4019238521122	4
  4019238368796	4
  4019238037364	4
  4019238065824	4
  4019238743944	4
  4019238811117	4
  4019238817171	4
  4019238020793	4
  4019238065688	4
  4019238559101	4
  4019238672985	4
  4019238817188	4
  4019238373622	4
  4019238817225	4
  4019238817164	4
  4019238055856	4
  4019238065787	4
  4019238065848	4
  4019238817041	4
  4019238817058	4
  4019238066333	4
  4019238500806	4
  4019238709797	4
  4019238373639	4
  4019238398939	4
  4019238014259	4
  4019238056037	4
  4019238023206	4
  4019238032468	4
  4019238547153	4
  4019238065763	4
  4019238817652	4
  4019238014990	4
  4019238065749	4
  4019238159530	4
  4019238331561	4
  4019238331578	4
  4019238817140	4
  4019238065756	4
  4019238817775	4
  4019238055832	4
  4019238065664	4
  4019238065657	4
  4019238014198	4
  4019238001228	4
  4019238055962	4
  4019238055993	4
  4019238065831	4
  4019238456561	4
  4019238486315	4
  4019238521177	4
  4019238572711	4
  4019238046472	4
  4019238055917	4
  4019238055924	4
  4019238061536	4
  4019238065725	4
  4019238065718	4
  4019238065732	4
  4019238314212	4
  4019238646269	4
  4019238061307	4
  4019238065862	4
  4019238065855	4
  4019238066029	4
  4019238243628	4
  4019238500820	4
  4019238521207	4
  4019238521214	4
  4019238551938	4
  4019238817706	4
  4019238033113	4
  4019238020755	4
  4019238066340	4
  4019238066357	4
  4019238331585	4
  4019238817102	4
  4019238817126	4
  4019238066524	4
  4019238066531	4
  4019238014181	4
  4019238817119	4
  4019238817133	4
  4019238066364	4
  4019238066371	4
  4019238022902	4
  4019238816488	4
  4019238817713	4
  4019238817669	4
  4019238065626	4
  4019238055870	4
  4019238055887	4
  4019238056006	4
  4019238066487	4
  4019238066500	4
  4019238077964	4
  4019238508017	4
  4019238791792	4
  4019238780963	4
  4019238673142	4
  4019238022216	4
  4019238022209	4
  4019238594720	4
  4019238015812	4
  4019238314281	4
  4019238817096	4
  4019238032963	4
  4019238066395	4
  4019238368802	4
  4019238521306	4
  4019238817676	4
  4019238055948	4
  4019238055931	4
  4019238066401	4
  4019238066418	4
  4019238368789	4
  4019238559118	4
  4019238817003	4
  4019238055849	4
  4019238065671	4
  4019238483550	4
  4019238590517	4
  4019238020724	4
  4019238033076	4
  4019238055979	4
  4019238055986	4
  4019238065701	4
  4019238065695	4
  4019238066586	4
  4019238814514	4
  4019238014204	4
  4019238016987	4
  4019238030563	4
  4019238066548	4
  4019238066555	4
  4019238458640	4
  4019238576511	4
  4019238020823	4
  4019238032062	4
  4019238033021	4
  4019238043709	4
  4019238046854	4
  4019238066043	4
  4019238066036	4
  4019238066050	4
  4019238066067	4
  4019238066074	4
  4019238424584	4
  4019238545425	4
  4019238552010	4
  4019238709780	4
  4019238014266	4
  4019238015157	4
  4019238019964	4
  4019238056068	4
  4019238066562	4
  4019238521290	4
  4019238715927	4
  4019238590524	4
  4019238005028	4
  4019238004878	4
  4019238032932	4
  4019238043808	4
  4019238039375	4
  4019238066593	4
  4019238066609	4
  4019238066616	4
  4019238066623	4
  4019238456080	4
  4019238495775	4
  4019238525885	4
  4019238525984	4
  4019238526097	4
  4019238545555	4
  4019238575859	4
  4019238653748	4
  4019238629927	4
  4019238656541	4
  4019238709278	4
  4019238723663	4
  4019238023497	4
  4019238817249	4
  4019238817263	4
  4019238023480	4
  4019238010534	4
  4019238020281	4
  4019238020298	4
  4019238032970	4
  4019238066081	4
  4019238066098	4
  4019238066104	4
  4019238466812	4
  4019238551990	4
  4019238768404	4
  4019238659146	4
  4019238754766	4
  4019238757446	4
  4019238015164	4
  4019238064841	4
  4019238816426	4
  4019238024289	4
  4019238013184	4
  4019238017151	4
  4019238005158	4
  4019238010749	4
  4019238008852	4
  4019238014921	4`;
