import React, { useContext } from 'react';
import { TransferList } from './TransferList';
import {
  Autocomplete,
  CircularProgress,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { PriceComparisonContext } from '../context';

export const SupplierSelectArea = () => {
  const ctx = useContext(PriceComparisonContext);

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Autocomplete
          disablePortal
          fullWidth
          id="combo-box-demo"
          options={ctx.suppliers.sort((a, b) => a.name.localeCompare(b.name))}
          disabled={ctx.isFetchingSuppliers}
          value={ctx.data.mainSupplier}
          onChange={(e, val) => ctx.setInputValue({ mainSupplier: val })}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Main supplier"
              InputLabelProps={{ shrink: !!ctx.data.mainSupplier }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={1}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {ctx.isFetchingSuppliers && <CircularProgress size={20} />}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }}>
          Select other suppliers
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <TransferList />
      </Grid>
    </Grid>
  );
};
