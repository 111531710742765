import { searchTypeOptions } from '../../../../constants/constants';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { SearchFilter } from './SearchFilter';
import { useContext, useMemo } from 'react';
import { StockListContext } from '../../../../context/StockListContext';

export const Search = () => {
  const { filterValues, setFilterValue } = useContext(StockListContext);

  const onChange = (value: string, key: string) => {
    setFilterValue({ [key]: value });
  };

  const { selectValue, textFieldValue } = useMemo(() => {
    const selectValue = filterValues?.['searchType'];
    const textFieldValue = filterValues?.['searchKeyword'];
    return { selectValue, textFieldValue };
  }, [filterValues]);

  return (
    <SideMenuLayout
      type="search"
      children={
        <SearchFilter
          optionsArray={searchTypeOptions}
          onChange={onChange}
          selectValue={selectValue as unknown as string}
          textFieldValue={textFieldValue as unknown as string}
        />
      }
    />
  );
};
