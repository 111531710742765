import { Autocomplete, TextField } from '@mui/material';
interface TransferMultiselectProps {
  attributeKey: string;
  label: string;
  values: {
    value: string | number;
    label: string;
  }[];
  filters: number[];
  setFilters: (value: number[]) => void;
  defaultValue?: {
    value: string | number;
    label: string;
  }[];
  disabled?: boolean;
}

const BranchDropdown = ({
  attributeKey,
  label,
  values,
  filters,
  setFilters,
  defaultValue,
  disabled,
}: TransferMultiselectProps) => {
  return (
    <Autocomplete
      id="tags-outlined"
      disableCloseOnSelect
      multiple
      fullWidth
      disablePortal
      disabled={disabled}
      options={values}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      style={{ marginTop: '27px' }}
      ChipProps={{
        clickable: true,
        style: {
          background: 'white',
          color: 'black',
          border: '1px solid gray',
        },
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(__, value) => {
        const unique = value.filter((obj, index) => {
          return index === value.findIndex((o) => obj.value === o.value);
        });
        setFilters({
          ...filters,
          [attributeKey]: unique.map((item) => item.value),
        });
      }}
    />
  );
};

export default BranchDropdown;
