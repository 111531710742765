import api from '@tyrio/api-factory';
import { ClientShippingCourierApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { QueryClient, useMutation } from 'react-query';

export const useAddShippingCouriers = (
  queryClient: QueryClient,
  setIsModalOpen: (value: boolean) => void
) => {
  const { data, mutate } = useMutation(
    ['create_client_shipping_couriers'],
    (body: ClientShippingCourierApi['create']['requestBody']) =>
      api.fetch<ClientShippingCourierApi['create']>(
        'create_client_shipping_couriers',
        {
          ...body,
        }
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_client_shipping_couriers');
        setIsModalOpen(false);
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully added shipping courier.'
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Shipping couriers',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  return { data, createShippingCourier: mutate };
};
