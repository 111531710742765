import * as z from 'zod';

import { DBBusinessType, DBClientType, DBCurrency } from '@prisma/client';
import { sharedVars } from '@tyrio/shared-vars';
import { DBUserModel } from '@tyrio/zod-schemas';
import { FormShape, TyrioSelectInputOption } from '../types';
import { createInput } from '../utils';
import { onlyNumbersRegex } from './zodObjects';

export const onlyLettersRegex = /^[a-zšđčćž ]+$/gi;

export const generateClientForm = (
  roles: TyrioSelectInputOption[],
  countries: TyrioSelectInputOption[],
  disableEditFields: boolean
) => {
  const charsAndNumbersRegex = /^[a-zšđčćž$&+,-:;=?@# |0-9]+$/gi;
  const zipCodeRegex = /^[A-Z0-9 ]*$/;

  const zodSchema = z.object({
    //Company info:
    clientType: z.string().trim().min(1, {
      message: 'This field is required!',
    }),
    businessType: z.string().trim().min(1, {
      message: 'This field is required!',
    }),
    vatNumber: z.string().trim().min(1, {
      message: 'This field is required!',
    }),
    vies: z.boolean().optional(),
    euVatNumber: z.string().trim().min(1, {
      message: 'This field is required!',
    }),
    slug: z
      .string()
      .trim()
      .min(2, {
        message: 'This field is required!',
      })
      .optional(),
    officialName: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    shortName: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    IBAN: z.string().optional(),
    swift: z.string().optional(),
    currency: z.string().optional(),

    //Address:
    address: z
      .string()
      .trim()
      .min(2, {
        message: 'This field is required!',
      })
      .regex(
        charsAndNumbersRegex,
        'Address must contain Letters and nothing other then numbers!'
      ),
    remark: z.string().optional(),
    referralId: z.string().optional(),
    city: z
      .string()
      .trim()
      .min(2, {
        message: 'This field is required!',
      })
      .regex(onlyLettersRegex, 'Only letters are accepted!'),
    countryId: z.string().trim().min(2, {
      message: 'This field is required!',
    }),
    zipCode: z
      .string()
      .trim()
      .min(2, {
        message: 'This field is required!',
      })
      .regex(zipCodeRegex, 'Format is not correct!'),

    //Users:
    users: z
      .record(
        DBUserModel.omit({
          createdAt: true,
          updatedAt: true,
          userRole: true,
        }).extend({
          id: z.string().optional(),
          firstName: z
            .string()
            .trim()
            .min(2, {
              message: 'This field is required!',
            })
            .regex(onlyLettersRegex, 'Only letters are accepted!'),
          lastName: z
            .string()
            .trim()
            .min(2, {
              message: 'This field is required!',
            })
            .regex(onlyLettersRegex, 'Only letters are accepted!'),
          email: z
            .string()
            .trim()
            .min(2, {
              message: 'This field is required!',
            })
            .email({ message: 'Email format is invalid!' }),
          personalIdentificationNumber: z
            .string()
            .trim()
            .min(2, {
              message: 'This field is required!',
            })
            .nullable(),
          mobilePhone: z.string().trim().min(6, {
            message: 'This field is required!',
          }),
          birthday: z.any().optional(),
          remark: z.string().optional(),
          roleId: z.string().trim().min(2, {
            message: 'This field is required!',
          }),
          language: z.string().trim().min(2, {
            message: 'This field is required!',
          }),
          pin: z
            .string({ required_error: 'This field is required!' })
            .regex(onlyNumbersRegex, 'Only numbers are accepted!')
            .min(4, { message: 'Number must be between 4 and 6 digits!' })
            .max(6, { message: 'Number must be between 4 and 6 digits!' })
            .nullable(),
          active: z.boolean().optional(),
        })
      )
      .optional(),

    //Contacts:
    companyContacts: z
      .record(
        z.object({
          id: z.string().optional(),
          firstName: z
            .string()
            .trim()
            .min(2, {
              message: 'This field is required!',
            })
            .regex(onlyLettersRegex, 'Only letters are accepted!'),
          lastName: z
            .string()
            .trim()
            .min(2, {
              message: 'This field is required!',
            })
            .regex(onlyLettersRegex, 'Only letters are accepted!'),
          email: z
            .string()
            .trim()
            .min(2, {
              message: 'This field is required!',
            })
            .email({ message: 'Email format is invalid!' }),
          businessPhone: z.string().optional(),
          mobilePhone: z.string().optional(),
          remark: z.string().optional(),
        })
      )
      .optional(),
  });

  const form: FormShape = {
    tabs: [
      {
        initial: true,
        tabTitle: 'Company',
        inputs: [
          createInput({
            id: 'clientType',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Client Type *',
            options: [
              {
                value: DBClientType.WHOLESALE,
                label: 'Wholesale',
              },
              {
                value: DBClientType.RETAIL,
                label: 'Retail',
              },
            ],
          }),
          createInput({
            id: 'businessType',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Business Type *',
            disabled: disableEditFields,
            options: [
              {
                value: DBBusinessType.DOO,
                label: 'd.o.o.',
              },
              {
                value: DBBusinessType.JDOO,
                label: 'j.d.o.o.',
              },
              {
                value: DBBusinessType.OBRT,
                label: 'Sole Proprietorship',
              },
            ],
          }),
          createInput({
            id: 'countryId',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Country *',
            disabled: disableEditFields,
            options: countries,
          }),
          createInput({
            id: 'vatNumber',
            width: { xs: 12, lg: 6 },
            type: 'input.vies',
            // disabled: disableEditFields,
            required: true,
            label: 'VAT number',
          }),
          createInput({
            id: 'euVatNumber',
            width: { xs: 12, lg: 6 },
            type: 'input.vies',
            disabled: disableEditFields,
            required: true,
            label: 'EU VAT number',
          }),
          createInput({
            id: 'officialName',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'Company Official Name *',
            disabled: disableEditFields,
          }),
          createInput({
            id: 'shortName',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'Company Short Name *',
            disabled: disableEditFields,
          }),
          createInput({
            id: 'zipCode',
            width: { xs: 12, lg: 3 },
            type: 'input.string',
            label: 'Zip Code *',
            disabled: disableEditFields,
          }),
          createInput({
            id: 'city',
            width: { xs: 12, lg: 3 },
            type: 'input.string',
            label: 'City *',
            disabled: disableEditFields,
          }),
          createInput({
            id: 'address',
            width: { xs: 12, lg: 6 },
            type: 'input.string',
            label: 'Address *',
            disabled: disableEditFields,
          }),
          createInput({
            id: 'payment',
            width: { xs: 12, lg: 12 },
            type: 'label.section',
            label: 'Payment Details',
          }),
          createInput({
            id: 'IBAN',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'Client IBAN',
          }),
          createInput({
            id: 'swift',
            width: { xs: 12, lg: 4 },
            type: 'input.string',
            label: 'Client Swift',
          }),
          createInput({
            id: 'currency',
            width: { xs: 12, lg: 4 },
            type: 'input.select',
            label: 'Client Currency',
            options: [
              {
                value: DBCurrency.EUR,
                label: '€',
              },
              {
                value: DBCurrency.BAM,
                label: 'BAM',
              },
              {
                value: DBCurrency.RSD,
                label: 'RSD',
              },
              {
                value: DBCurrency.USD,
                label: 'USD',
              },
            ],
          }),
          createInput({
            id: 'referral',
            width: { xs: 12 },
            type: 'input.string',
            label: 'Referral',
            disabled: true,
          }),
          createInput({
            id: 'remark',
            width: { xs: 12 },
            type: 'input.string',
            label: 'Remark',
          }),
        ],
      },
      {
        tabTitle: 'Contacts',
        inputs: [
          {
            id: 'companyContacts',
            width: { xs: 12 },
            type: 'form.repeater',
            addRepeaterButtonText: 'Add new contact',
            deleteRepeaterButtonText: 'Delete',
            inputs: [
              createInput({
                id: 'labelContact',
                width: { xs: 12 },
                type: 'label.section',
                dynamicLabel: {
                  parent: 'companyContacts',
                  children: ['firstName', 'lastName'],
                },
              }),
              createInput({
                id: 'firstName',
                width: { xs: 12, lg: 6 },
                type: 'input.string',
                label: 'First Name *',
              }),
              createInput({
                id: 'lastName',
                width: { xs: 12, lg: 6 },
                type: 'input.string',
                label: 'Last Name *',
              }),

              createInput({
                id: 'email',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'E-mail *',
              }),
              createInput({
                id: 'businessPhone',
                width: { xs: 12, lg: 4 },
                type: 'input.phone',
                label: 'Business Phone',
              }),
              createInput({
                id: 'mobilePhone',
                width: { xs: 12, lg: 4 },
                type: 'input.phone',
                label: 'Mobile Phone',
              }),
              createInput({
                id: 'remark',
                width: { xs: 12 },
                type: 'input.string',
                label: 'Remark',
              }),
            ],
          },
        ],
      },

      {
        tabTitle: 'Users',
        inputs: [
          {
            id: 'users',
            width: { xs: 12 },
            type: 'form.repeater',
            addRepeaterButtonText: 'Add new user',
            deleteRepeaterButtonText: 'Delete',
            inputs: [
              createInput({
                id: 'users',
                width: { xs: 12 },
                type: 'label.section',
                dynamicLabel: {
                  parent: 'users',
                  children: ['firstName', 'lastName'],
                },
              }),
              createInput({
                id: 'roleId',
                width: { xs: 12, lg: 4 },
                type: 'input.select',
                label: 'User Type *',
                options: roles,
              }),
              createInput({
                id: 'language',
                width: { xs: 12, lg: 4 },
                type: 'input.select',
                label: 'Language *',
                options: sharedVars.languages.languageOptions,
              }),
              createInput({
                id: 'active',
                width: { xs: 12, lg: 4 },
                type: 'input.switch',
                label: 'Active',
              }),
              createInput({
                id: 'firstName',
                width: { xs: 12, lg: 6 },
                type: 'input.string',
                label: 'First Name *',
              }),
              createInput({
                id: 'lastName',
                width: { xs: 12, lg: 6 },
                type: 'input.string',
                label: 'Last Name *',
              }),
              createInput({
                id: 'birthday',
                width: { xs: 12, lg: 4 },
                mode: 'calendardate',
                type: 'input.datepicker',
                label: 'Birthday',
              }),
              createInput({
                id: 'email',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'E-mail *',
              }),
              createInput({
                id: 'personalIdentificationNumber',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'Personal Identification Number *',
              }),
              createInput({
                id: 'pin',
                width: { xs: 12, lg: 4 },
                type: 'input.string',
                label: 'PIN *',
              }),
              createInput({
                id: 'mobilePhone',
                width: { xs: 12, lg: 4 },
                type: 'input.phone',
                label: 'Mobile Phone *',
              }),
              createInput({
                id: 'businessPhone',
                width: { xs: 12, lg: 4 },
                type: 'input.phone',
                label: 'Business Phone',
              }),
            ],
          },
        ],
      },
    ],
  };

  return { form, zodSchema };
};
