import * as z from "zod"
import { CompleteDBPartner, RelatedDBPartnerModel } from "./index"

export const DBPartnerContactModel = z.object({
  id: z.string(),
  partnerId: z.string().nullish(),
  department: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  businessPhone: z.string().nullish(),
  mobilePhone: z.string().nullish(),
  remark: z.string().nullish(),
})

export interface CompleteDBPartnerContact extends z.infer<typeof DBPartnerContactModel> {
  partner?: CompleteDBPartner | null
}

/**
 * RelatedDBPartnerContactModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBPartnerContactModel: z.ZodSchema<CompleteDBPartnerContact> = z.lazy(() => DBPartnerContactModel.extend({
  partner: RelatedDBPartnerModel.nullish(),
}))
