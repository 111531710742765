import {
  Card,
  CardWrapper,
} from '../SearchPOS/ReusableSelectors/BrandSelector';

import { Content, HeaderText } from '../../steps/Search/SelectStepper';
import {
  VehicleSteps,
  useVehicleCtx,
} from '../../../../context/VehicleContext';
import { useGetVehicleModels } from './query/get-models';
import { TitleWrapper } from './Generation';
import { VehicleModel } from '@tyrio/dto';
import { CircularProgress } from '@mui/material';

export const Model = () => {
  const {
    setActiveStep,
    setSelectedModel,
    selectedModel,
    selectedBrand,
    valueChanges,
    updateValueChanges,
  } = useVehicleCtx();

  const { vehicleModels, isFetched, isLoading, isRefetching } =
    useGetVehicleModels(selectedBrand ?? '', valueChanges['BRAND'] ?? true);

  if (isLoading || isRefetching) {
    return (
      <Content
        height={'700px'}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '700px',
        }}
      >
        <CircularProgress />
      </Content>
    );
  }

  return (
    <Content
      height={`calc(100vh - 202px)`}
      id="vehicle_model"
      style={{ justifyContent: 'flex-start' }}
    >
      <TitleWrapper>
        <HeaderText style={{ fontSize: '18px' }}>
          {selectedBrand?.toUpperCase()}
        </HeaderText>
        <HeaderText>SELECT MODEL</HeaderText>
      </TitleWrapper>

      <CardWrapper>
        {isFetched &&
          vehicleModels.map((value: VehicleModel) => {
            const isSelected = selectedModel === value.slug;

            return (
              <Card
                style={{ justifyContent: 'center', width: '49%' }}
                onClick={() => {
                  updateValueChanges(
                    VehicleSteps.MODEL,
                    selectedModel !== value?.slug
                  );
                  updateValueChanges(VehicleSteps.BRAND, false);

                  setSelectedModel(value.slug);
                  setActiveStep(VehicleSteps.GENERATIONS);
                }}
                selected={isSelected}
                key={value.slug}
              >
                {value?.name}
              </Card>
            );
          })}
      </CardWrapper>
    </Content>
  );
};
