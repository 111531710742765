import styled from '@emotion/styled';
import rollingResistanceIcon from '../../../assets/productDetailsNew/icon1.svg';
import wetGripIcon from '../../../assets/productDetailsNew/icon2.svg';

export enum CategoryLevels {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export enum CategoryLevelsOld {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export const generateColor = (
  category: CategoryLevels,
  type: 'rollingResistance' | 'wetGrip'
) => {
  if (category === CategoryLevels.A) {
    if (type === 'rollingResistance') return '#00A651';
    return '#0066B3';
  }

  if (category === CategoryLevels.B) {
    if (type === 'rollingResistance') return '#9ACA3C';
    return '#0080C6';
  }

  if (category === CategoryLevels.C) {
    if (type === 'rollingResistance') return '#FFF200';
    return '#4BA6DD';
  }

  if (category === CategoryLevels.D) {
    if (type === 'rollingResistance') return '#FDB913';
    return '#75BEE9';
  }

  if (type === 'rollingResistance') return '#ED1C24';
  return '#ABE1FA';
};

export const generateColorOld = (category: CategoryLevelsOld) => {
  if (category === CategoryLevelsOld.A) return '#00A54F';
  if (category === CategoryLevelsOld.B) return '#4DB749';
  if (category === CategoryLevelsOld.C) return '#A9FD00';
  if (category === CategoryLevelsOld.D) return '#F3FF15';
  if (category === CategoryLevelsOld.E) return '#FCAD08';
  if (category === CategoryLevelsOld.F) return '#FD4705';
  return '#FF0204';
};

interface PerformanceCategoryProps {
  category: CategoryLevels;
  type: 'rollingResistance' | 'wetGrip';
}

export const PerformanceCategory = ({
  category,
  type,
}: PerformanceCategoryProps) => {
  return (
    <Container>
      <BoxContainer>
        <MainBox color={generateColor(category, type)}>{category}</MainBox>
        <SideBox color={generateColor(category, type)}></SideBox>
      </BoxContainer>
      <Img
        src={type === 'rollingResistance' ? rollingResistanceIcon : wetGripIcon}
        alt="icon"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const BoxContainer = styled.div`
  display: flex;
`;

const Img = styled.img`
  width: 27px;
`;

const MainBox = styled.div<{ color: string }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${(props) => props.color};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const SideBox = styled.div<{ color: string }>`
  width: 9.5px;
  height: 32px;
  border-top: 16px solid transparent;
  border-left: 9.5px solid ${(props) => props.color};
  border-bottom: 16px solid transparent;
`;
export default PerformanceCategory;
