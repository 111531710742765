import DashboardPriceComparison from './PriceComparison';
import { useCallback, useState } from 'react';
import { DashboardPriceComparisonTable } from './PriceCompareTable';
import { DBPriceCompareApi, PriceComparisonShape } from '@tyrio/dto';
import { useMutation } from 'react-query';
import api from '@tyrio/api-factory';
import {
  CompareCodeData,
  CompareDimensionsData,
  OrderDataInterface,
} from '../../price-compare/types';

export interface PriceComparisonRootProps {
  onProcessOrderClicked: (data: OrderDataInterface) => void;
}

export const PriceComparisonRoot = (props: PriceComparisonRootProps) => {
  const [shouldProceed, setShouldProceed] = useState(false);
  const [errorNotFound, setErrorNotFound] = useState<string>('');
  const [dataWithQty, setDataWithQty] = useState<
    { value: string; qty: number }[]
  >([]);

  const [isComparisonByDimensions, setIsComparisonByDimensions] =
    useState(false);
  const { data, mutateAsync } = useMutation(
    ['price_comparison'],
    (payload: CompareCodeData) =>
      api.fetch<DBPriceCompareApi['compareCode']>('compare_prices_by_code', {
        supplierIds: payload.supplierIds,
        mainSupplier: payload.mainSupplier,
        codeType:
          payload.codeType.toLowerCase() === 'ean'
            ? 'ean'
            : payload.codeType.toLowerCase() === 'sku'
            ? 'sku'
            : 'manufacturerCode',
        codes: payload.codes,
      }),
    {}
  );

  const { data: dimensionsData, mutateAsync: mutateAsyncDimensions } =
    useMutation(
      ['price_comparison_dimensions'],
      (payload: CompareDimensionsData) =>
        api.fetch<DBPriceCompareApi['compareDimensions']>(
          'compare_prices_by_dimension',
          {
            supplierIds: payload.supplierIds,
            mainSupplier: payload.mainSupplier,
            dimensions: payload.dimensions,
            brands: payload.brands,
            classes: {},
            categoryId: payload.categoryId,
            seasons: payload.seasons,
            shouldShowRunFlat: payload.shouldShowRunFlat,
          }
        ),
      {}
    );

  const constructError = useCallback(
    (newData: CompareCodeData, receivedData: PriceComparisonShape) => {
      let errorText = '';

      if (newData.codes && data) {
        let notFoundProductsErrorText = 'Following products were not found: ';
        let doesNotHaveProduct = true;

        newData.codes.forEach((el, indexOfEl) => {
          let idOfProduct: string | undefined;

          if (newData.codeType.toLowerCase() === 'ean') {
            idOfProduct = Object.keys(receivedData.products).find(
              (product) => receivedData.products[product].ean === el.value
            );
          } else if (newData.codeType.toLowerCase() === 'sku') {
            idOfProduct = Object.keys(receivedData.products).find(
              (product) => receivedData.products[product].sku === el.value
            );
          } else {
            idOfProduct = Object.keys(receivedData.products).find(
              (product) =>
                receivedData.products[product].manufacturerCode === el.value
            );
          }

          if (!idOfProduct) {
            notFoundProductsErrorText += `${el.value}`;
            if (indexOfEl === newData.codes.length - 1)
              notFoundProductsErrorText += '.';
            else notFoundProductsErrorText += ', ';
            doesNotHaveProduct = false;
          }
        });
        if (!doesNotHaveProduct) errorText += notFoundProductsErrorText;
      }
      setErrorNotFound(errorText);
    },
    [data]
  );

  const handleSubmitData = useCallback(
    async (newData: CompareCodeData) => {
      if (newData) {
        await mutateAsync(newData).then((recData: unknown) => {
          constructError(newData, recData as PriceComparisonShape);
        });
      }
    },
    [constructError, mutateAsync]
  );
  const handleSubmitDimensionsData = useCallback(
    async (newData: CompareDimensionsData) => {
      if (newData) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        await mutateAsyncDimensions(newData).then((recData: unknown) => {
          // TODO: Do we need to show error?
        });
      }
    },
    [mutateAsyncDimensions]
  );
  return (
    <div>
      {!shouldProceed ? (
        <DashboardPriceComparison
          onComparePricesClicked={(newData, newDimensionData) => {
            setErrorNotFound('');
            if (newData) {
              setDataWithQty(newData.codes);
              handleSubmitData(newData).then(() => {
                setShouldProceed(true);
                setIsComparisonByDimensions(false);
              });
            } else if (newDimensionData) {
              handleSubmitDimensionsData(newDimensionData).then(() => {
                setShouldProceed(true);
                setIsComparisonByDimensions(true);
              });
            }
          }}
        />
      ) : data && !isComparisonByDimensions ? (
        <DashboardPriceComparisonTable
          onBack={() => {
            setShouldProceed(false);
          }}
          receivedData={data}
          nonReceivedData={errorNotFound}
          dataWithQty={dataWithQty}
          compareByDimensions={false}
          onProcessOrderClicked={props.onProcessOrderClicked}
        />
      ) : dimensionsData && isComparisonByDimensions ? (
        <DashboardPriceComparisonTable
          onBack={() => {
            setShouldProceed(false);
          }}
          receivedData={dimensionsData}
          nonReceivedData={errorNotFound}
          dataWithQty={undefined}
          compareByDimensions={true}
          onProcessOrderClicked={props.onProcessOrderClicked}
        />
      ) : (
        <div />
      )}
    </div>
  );
};
