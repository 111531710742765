import {
  SearchableVerticalList,
  SearchableVerticalListDataType,
} from '@tyrio/ui-library';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useDebounce } from '../../hooks/useDebounce';
import routeNames from '../../lib/routeNames';
import { DBUserApi } from '@tyrio/dto';
import api from '@tyrio/api-factory';
import { useQuery } from 'react-query';
import { useAuth } from '../../context/AuthContext';

interface UsersCompanySettingsProps {
  switchMenu?: boolean;
  setSwitchMenu: (menu: boolean) => void;
}

const UsersCompanySettings = ({
  switchMenu,
  setSwitchMenu,
}: UsersCompanySettingsProps) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const location = useLocation();
  const { user } = useAuth();

  const clientId = user?.clientId;

  const debouncedSearch = useDebounce(search, 1000);

  const searchUsers = async (debouncedSearch?: string, clientId?: string) => {
    return await api.fetch<DBUserApi['list']>('get_users', {
      search: debouncedSearch,
      clientId: clientId,
    });
  };

  const {
    data: users_data,
    isFetching,
    isError,
  } = useQuery(['get_users', { debouncedSearch, clientId }], () =>
    searchUsers(debouncedSearch, clientId ?? '')
  );

  const [selected, setSelected] = useState<Record<string, boolean>>(
    location.pathname.split('/').pop() !== 'admin'
      ? { [location.pathname.split('/').pop()]: true }
      : {}
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      setSelected((prevValue) => ({
        ...prevValue,
        [item.id]: !prevValue[item.id],
      }));
      history.push(`/dashboard/company-settings/users/${item.id}`);
    }
    if (type === 'new') {
      history.push(`/dashboard/company-settings/users/new`);
    }
  };

  return (
    <SearchableVerticalList
      header="Users"
      type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
      handleItemClick={(item) => handleClick('itemClicked', item)}
      data={
        users_data?.map((user) => ({
          id: user.id,
          name: user.firstName + ' ' + user.lastName,
          type: user.role.roleName,
          active: user.active,
          isSelected: selected[user.id],
        })) ?? []
      }
      path={routeNames.dashboard()}
      onCreateNew={() => handleClick('new', {})}
      onSearchChange={setSearch}
      isFetching={isFetching}
      isError={isError}
      setSwitchMenu={setSwitchMenu}
      switchMenu={switchMenu}
    />
  );
};

export default UsersCompanySettings;
