import { Auth, RecaptchaVerifier } from 'firebase/auth';

export const getRecaptchaVerifier = (containerOrId: string, auth: Auth) =>
  new RecaptchaVerifier(
    containerOrId,
    {
      size: 'invisible',
      callback: (_r: string) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
        // triggerLogin();
      },
      expiredCallback: (cb: unknown) => console.log('EXPIRED', cb),
      errorCallback: (cb: unknown) => console.log('ERROR', cb),
      'expired-callback': (cb: unknown) => console.log('EXPIRED2', cb),
      'error-callback': (cb: unknown) => console.log('ERROR2', cb),
    },
    auth
  );
