import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';
import {
  ActiveDrawer,
  ProductsQuantity,
} from '../ProductsQuantity/ProductsQuantity';
import { YearChip } from '../YearChip/YearChip';

interface SingleStorageItemProps {
  onStock: number;
  reserved: number;
  available: number;
  component: ReactJSXElement;
  year: string;
  chipColor:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  setActiveDrawer: (drawer: ActiveDrawer) => void;
  onlyAvailable: boolean;
}
export const SingleStorageItem = ({
  onStock,
  reserved,
  available,
  component,
  year,
  chipColor,
  setActiveDrawer,
  onlyAvailable,
}: SingleStorageItemProps) => {
  return (
    <SingleUnitWrapper>
      <ComponentYearWrapper>
        {component}
        {year && <YearChip year={year} color={chipColor} />}
      </ComponentYearWrapper>
      <ProductsQuantity
        onStock={onStock}
        reserved={reserved}
        available={available}
        setActiveDrawer={setActiveDrawer}
        onlyAvailable={onlyAvailable}
      />
    </SingleUnitWrapper>
  );
};

const SingleUnitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 0 11px 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;
  gap: 8px;

  &:hover {
    background: #f9fafb;

    div:first-of-type {
      opacity: 1;
    }

    #quantity_wrapper {
      background: ${tyrioUI.colors.black.B30};
    }

    #quantity_component {
      background: white;
    }
  }
`;

const ComponentYearWrapper = styled.div`
  min-width: calc(100% - 240px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
