import styled from '@emotion/styled';
import Grid from '@mui/material/Grid/Grid';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioFormError, TyrioTextareaInput } from '../types';
interface TyrioInputTextareaProps {
  id?: string;
  item: TyrioTextareaInput;
  gridProps: FormGridProps;
  error?: TyrioFormError;
}

// TODO move away from TinyMCE react
export const TyrioInputTextarea = ({
  id,
  item,
  gridProps,
}: TyrioInputTextareaProps) => {
  const { t } = useTranslation();
  return (
    <GridContainer item {...gridProps}>
      <Editor
        apiKey={'54u9rax2tboahret04mq704ah85i4vcreaug9xdrorno9nu6'}
        key={id || item.id}
        init={{
          height: 250,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'visualblocks',
            'code',
            'media',
            'table',
            'code',
          ],
          toolbar:
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ',
          placeholder: `${t('Write something')}`,
          statusbar: false,
        }}
      />
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
`;
