import companyBranchesIcone from '../../../../libs/ui-library/src/assets/company-settings-icons/branches-icon.svg';
import companyBranchesIconeBlack from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/branches-icon-b.svg';
import companyBranchesIconeWhite from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/branches-icon-w.svg';
import visibilityWhite from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/visibility-w.svg';
import visibilityBlack from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/visibility-b.svg';
import generalBlack from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/general-b.svg';
import generalWhite from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/general-w.svg';
import companyInfoIconeBlack from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/company-info-icon-b.svg';
import companyInfoIconeWhite from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/company-info-icon-w.svg';
import companySettingsIcon1 from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/settings-icon-1.svg';
import companySettingsIcon2 from '../../../../libs/ui-library/src/assets/company-settings-icons/settings-menu-icons/settings-icon-2.svg';
import adminIcon from '../../../../libs/ui-library/src/assets/menu-icons/admins-icon.svg';
import close from '../../../../libs/ui-library/src/assets/menu-icons/close.svg';
import logout from '../../../../libs/ui-library/src/assets/menu-icons/logout.svg';
import menuIcon1 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-1.svg';
import menuIcon2 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-2.svg';
import menuIcon3 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-3.svg';
import menuIcon4 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-4.svg';
import menuIcon5 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-5.svg';
import menuIcon6 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-6.svg';
import menuIcon7Black from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-7-b.svg';
import menuIcon7White from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-7-w.svg';
import menuIcon8 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-8.svg';
import menuIcon9 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-9.svg';
import menuIcon10 from '../../../../libs/ui-library/src/assets/menu-icons/menu-icon-10.svg';
import cloneIcon from '../../../../libs/ui-library/src/assets/other-icons/clone-icon.svg';
import globe from '../../../../libs/ui-library/src/assets/other-icons/globe.svg';
import tyreYear from '../../../../libs/ui-library/src/assets/pictures/tempYear.svg';
import tyreImg from '../../../../libs/ui-library/src/assets/pictures/tyre_img.svg';
import tyreSpecs from '../../../../libs/ui-library/src/assets/pictures/tyre_specs.svg';
import comingSoon from '../../../../libs/ui-library/src/assets/pictures/under-construction.png';
import noisePerformance from '../../../../libs/ui-library/src/assets/product-icons/noisePerformance.png';
import rollingResistance from '../../../../libs/ui-library/src/assets/product-icons/rollingResistance.png';
import wetGrip from '../../../../libs/ui-library/src/assets/product-icons/wetGrip.png';
import deliveryLate from '../../../../libs/ui-library/src/assets/purchase-orders/delivery-late.svg';
import favourite from '../../../../libs/ui-library/src/assets/purchase-orders/favourite.svg';
import noData from '../../../../libs/ui-library/src/assets/purchase-orders/no-data.png';
import addDocument from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/add-document.svg';
import arrow from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/arrow.svg';
import cancelFilled from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/cancel-filled.svg';
import documentIcon from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/file.svg';
import hoveredArrow from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/hovered-arrow.svg';
import hoveredEye from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/hovered-eye.svg';
import pdfFile from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/pdf-file.svg';
import addUsePhoto from '../../../../libs/ui-library/src/assets/user-photo/add-user-photo.svg';
import backIcon from '../../../../libs/ui-library/src/assets/vertical-list/back-icon.svg';
import greenBadge from '../../../../libs/ui-library/src/assets/vertical-list/green-badge.svg';
import redBadge from '../../../../libs/ui-library/src/assets/vertical-list/red-badge.svg';
import branches from '../../../../libs/ui-library/src/assets/company-settings-icons/branches-w.svg';
import supplierIcon from '../../../../libs/ui-library/src/assets/menu-icons/supplier-icon.svg';
import clientIcon from '../../../../libs/ui-library/src/assets/menu-icons/client-icon.svg';
import comingSoonIcon from '../../../../libs/ui-library/src/assets/menu-icons/coming-soon-icon.svg';
import cataloguesIcon from '../../../../libs/ui-library/src/assets/menu-icons/catalogues-icon.svg';
import logoutRed from '../../../../libs/ui-library/src/assets/menu-icons/logout-red.svg';
import concludedBy from '../../../../libs/ui-library/src/assets/customer-orders/concludedBy.svg';
import processedBy from '../../../../libs/ui-library/src/assets/customer-orders/processedBy.svg';
import editIcon from '../../../../libs/ui-library/src/assets/other-icons/edit.svg';
import basicCalculationIcon from '../../../../libs/ui-library/src/assets/price-calculation/basic.svg';
import deleteDocument from '../../../../libs/ui-library/src/assets/purchase-orders/order-documents/delete-document.svg';
import usersIcon from '../../../../libs/ui-library/src/assets/user-photo/users.svg';
import damagedIcon from '../assets/stock-in/damaged.svg';
import blackCart from '../assets/pos/cart-black.png';
import largeLandingAd from '../assets/pos/large-conti-ad.png';
import { ReactComponent as stepMenuSuppliers } from '../assets/pos/menu-icons/suppliers.svg';
import { ReactComponent as services } from '../assets/pos/menu-icons/wrench.svg';
import { ReactComponent as tyreHotel } from '../assets/pos/menu-icons/tyre-hotel.svg';
import smallContiAd from '../assets/pos/small-conti-ad.png';
import discount from '../assets/pos/discount.svg';
import payment from '../assets/payment-method/payment-method.svg';
import gls from './delivery-icons/gls.png';
import dpd from './delivery-icons/dpd.png';
import tyrioClientVerified from '../assets/pictures/tyrioclient.png';
import warning from '../assets/other-icons/warning.png';
import creditCard from '../assets/payment-method/creditCard.svg';
import printerWhite from '../assets/print-icons/print-white.svg';
import printerBlack from '../assets/print-icons/print-black.svg';
import deliveryIconWhite from '../assets/delivery/carbon_delivery.svg';
import deliveryIconBlack from '../assets/delivery/delivery-black.svg';
import deliverySmallBlack from '../assets/delivery/delivery-small.svg';
import deliverySmallWhite from '../assets/delivery/delivery-small-white.svg';
import integrationIcon from '../assets/integration/integration.svg';
import warehouse from '../assets/warehouse/warehouse.svg';
import document from '../assets/menu-icons/document.svg';
import documentBlack from '../assets/menu-icons/document-logo-black.png';
import uploadDocument from '../assets/logo/upload-document.png';
import { ReactComponent as speakerNote } from './speaker-note.svg';
import { ReactComponent as favoriteWheel } from './wheel-details/favorite-basic.svg';
import XIcon from './x-icon.svg';
import addImage from './product-icons/add-image.png';
import { ReactComponent as addProductImage } from './product-icons/add-image.svg';
import landingLogo from './logo/landing-logo.svg';
import barcodeIcon from './pos/barcode.png';

import collection from './work-orders/collection.svg';
import shipping from './work-orders/shipping.svg';
import fitting from './work-orders/fitting.svg';
import dispatch from './work-orders/dispatch.svg';

export {
  greenBadge,
  redBadge,
  companySettingsIcon1,
  companySettingsIcon2,
  addUsePhoto,
  menuIcon1,
  menuIcon2,
  menuIcon3,
  menuIcon4,
  menuIcon5,
  menuIcon6,
  menuIcon7White,
  menuIcon7Black,
  menuIcon8,
  menuIcon9,
  adminIcon,
  logout,
  cloneIcon,
  globe,
  backIcon,
  comingSoon,
  wetGrip,
  rollingResistance,
  noisePerformance,
  tyreImg,
  tyreSpecs,
  tyreYear,
  close,
  deliveryLate,
  favourite,
  noData,
  documentIcon,
  pdfFile,
  addDocument,
  arrow,
  hoveredArrow,
  hoveredEye,
  cancelFilled,
  companyBranchesIcone,
  companyBranchesIconeWhite,
  companyBranchesIconeBlack,
  companyInfoIconeWhite,
  companyInfoIconeBlack,
  branches,
  supplierIcon,
  clientIcon,
  comingSoonIcon,
  cataloguesIcon,
  logoutRed,
  concludedBy,
  processedBy,
  editIcon,
  basicCalculationIcon,
  deleteDocument,
  menuIcon10,
  usersIcon,
  damagedIcon,
  blackCart,
  largeLandingAd,
  stepMenuSuppliers,
  services,
  tyreHotel,
  smallContiAd,
  dpd,
  gls,
  discount,
  tyrioClientVerified,
  visibilityBlack,
  visibilityWhite,
  generalBlack,
  generalWhite,
  payment,
  warning,
  creditCard,
  printerWhite,
  printerBlack,
  deliveryIconWhite,
  deliveryIconBlack,
  deliverySmallBlack,
  deliverySmallWhite,
  integrationIcon,
  warehouse,
  document,
  documentBlack,
  uploadDocument,
  speakerNote,
  favoriteWheel,
  XIcon,
  addImage,
  addProductImage,
  landingLogo,
  collection,
  shipping,
  fitting,
  dispatch,
  barcodeIcon,
};
