import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { DBWarehouseSections, DBWarehouseShelfs } from '@prisma/client';
import _ from 'lodash';
import { useContext } from 'react';
import { WarehouseContext } from '../../../context/WarehouseContext';
import { getCurrent } from '../helper/helper';

interface ZoneCardProps {
  section: DBWarehouseSections;
  shelf: DBWarehouseShelfs;
}

export const ShelfCard = (props: ZoneCardProps) => {
  const { warehouse, selected, setSelected } = useContext(WarehouseContext);

  const { shelf, section } = props;

  const settings = (att: string) => {
    return _.get(shelf.settings, att);
  };

  const format = (att: string) => {
    return _.replace(att, /_/g, ' ');
  };

  const binCount = warehouse?.bins?.filter(
    (bin) => bin.shelfId === shelf.id
  ).length;

  return (
    <ZoneCardContainer
      background={getCurrent(selected, 'color')}
      onClick={() => {
        setSelected({
          current: [...selected.current, 'bin'],
          locations: {
            ...selected?.locations,
            section: section,
            shelf: shelf,
          },
        });
      }}
    >
      <CardHeader id="header">
        <ZoneColor color={getCurrent(selected, 'color')} />
        <RackHeader>
          <CardHeaderText>SHELF {shelf.code}</CardHeaderText>
          {settings('stagingType') === 'FITTING' && (
            <BoxName>{getCurrent(selected, 'stagingType')}</BoxName>
          )}
        </RackHeader>
      </CardHeader>
      <DisplayName>{shelf.displayName}</DisplayName>
      <RackContent>
        <Grid>
          <DescriptionItemWrapper>
            <RackShortDetailsLabel>Status:</RackShortDetailsLabel>
            <RackStatus>
              <RackStatusIcon isActive={settings('isActive')} />
              <RackShortDetailsValue>
                {settings('isActive') ? 'Active' : 'Inactive'}
              </RackShortDetailsValue>
            </RackStatus>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <RackShortDetailsLabel>Category:</RackShortDetailsLabel>
            <RackShortDetailsValue>
              {format(settings('productCategory'))}{' '}
            </RackShortDetailsValue>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <RackShortDetailsLabel>Mixed SKU:</RackShortDetailsLabel>
            <RackShortDetailsValue>
              {settings('mixedSKU') ? 'YES' : 'NO'}
            </RackShortDetailsValue>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <RackShortDetailsLabel>Hotel:</RackShortDetailsLabel>
            <RackShortDetailsValue>
              {settings('isHotel') ? 'YES' : 'NO'}
            </RackShortDetailsValue>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <RackShortDetailsLabel>Recommended:</RackShortDetailsLabel>
            <RackShortDetailsValue>
              {settings('isRecommended') ? 'YES' : 'NO'}
            </RackShortDetailsValue>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <RackShortDetailsLabel>Bins:</RackShortDetailsLabel>
            <RackShortDetailsValue>{binCount}</RackShortDetailsValue>
          </DescriptionItemWrapper>
        </Grid>
      </RackContent>
    </ZoneCardContainer>
  );
};

const ZoneCardContainer = styled.div<{ background: string }>`
  background: #fff;
  width: 203px;
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  &:hover {
    cursor: pointer;
    border: 1px solid #c9ccd1;

    #header {
      background: #c9ccd1;
    }
  }
`;

const CardHeader = styled.div`
  background: #dfe3e8;
  padding: 12px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  align-items: center;
  &:hover {
    background: #c9ccd1;
  }
`;

const CardHeaderText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;

const BoxName = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
  color: #e75524;
`;

const ZoneColor = styled.div<{ color: string }>`
  background: ${(prop) => prop.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
`;

const RackShortDetailsLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
  margin-right: 5px;
`;

const RackShortDetailsValue = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: black;
`;

const RackContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RackStatus = styled.div`
  display: flex;
  align-items: center;
`;

const RackStatusIcon = styled.div<{ isActive: boolean }>`
  background: ${(prop) => (prop.isActive ? '#2e7d32' : '#d32f2f')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 2px;
`;

const DescriptionItemWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const RackHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DisplayName = styled.div`
  padding: 6px;
  background-color: #13ad5c;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;
