import styled from '@emotion/styled/macro';
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Tooltip,
  ButtonGroup,
} from '@mui/material';
import { DBClientSupplierOrderItem, DBConnectionType } from '@prisma/client';
import {
  BranchesEdiShape,
  DBBranchResponse,
  DBClientSupplierOrderApi,
  DBSupplierClientSettingsPayload,
  ProductDetailsDataInterface,
} from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import * as _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAuth } from '../../../../context/AuthContext';
import EmailLinkGenerator from '../../../../features/orders/EmailLinkGenerator';
import { getEdiWheelData } from '../../../../features/orders/helpers/edi-wheel-api';
import { SalesOrdersFlowContext } from '../../../../features/sales-orders/SalesOrdersFlowContext';
import { useEDIOrderMutation } from '../../../../hooks/api/useEDIOrderMutation';
import { useInquiryMutation } from '../../../../hooks/api/useInquiryMutation';
import { useOrderMutation } from '../../../../hooks/api/useOrderMutation';
import { PriceComparisonContext } from '../context';
import { OrderBranchSelector } from './OrderBranchSelector';
import { OrderEmailFields } from './OrderEmailFields';
import { OrderRemarkFields } from './OrderRemarkFields';
import { OrderSentSuccess } from './OrderSentSuccess';
import { OrderSupplierBoxLine } from './OrderSupplierBoxLine';
import { OrderSupplierModal } from './OrderSupplierModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

interface OrderSupplierBoxProps {
  branches: DBBranchResponse[];
  clientSupplier: DBSupplierClientSettingsPayload;
  data: ProductDetailsDataInterface[];
  orderKey: number;
  hasMultipleOrderKeys: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getConnectionName = (type: string) => {
  switch (type) {
    case 'API':
      return 'API';
    case 'FTP':
      return 'FTP';
    case 'EMAIL':
      return 'Email';
    case 'HTTPS':
      return 'EDI';
    default:
      return 'B2B';
  }
};

const buttonOptions = ['Order', 'B2B'];

export const OrderSupplierBox = (props: OrderSupplierBoxProps) => {
  const ctx = useContext(PriceComparisonContext);
  const soctx = useContext(SalesOrdersFlowContext);

  const anchorRef = React.useRef<HTMLDivElement>(null);

  // const requestedCalendarDate = ctx.data.requestedDeliveryCalendarDate;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [supplierOrderNumber, setSupplierOrderNumber] = useState('');
  const [orderData, setOrderData] = useState<Partial<
    DBClientSupplierOrderApi['create']['request']
  > | null>(null);
  // const [variant, setVariant] = useState('B2B');

  // button states
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [buttonOpen, setButtonOpen] = useState(false);
  const [isB2BClicked, setIsB2BClicked] = useState(false);
  const [triggerOrderClick, setTriggerOrderClick] = useState(false);

  const [open, setOpen] = useState(true);
  const data = props.data;

  const { user } = useAuth();

  const selectedBranchId = useMemo(
    () => (ctx.data.orderDeliveryBranches ?? {})[props.clientSupplier.id],
    [ctx.data.orderDeliveryBranches, props.clientSupplier.id]
  );

  const selectedBranch = useMemo(
    () => props.branches.find((b) => b.id === selectedBranchId),
    [props.branches, selectedBranchId]
  );

  const inquiryMutation = useInquiryMutation(
    props.clientSupplier.supplierId,
    selectedBranchId,
    ctx.data.requestedDeliveryCalendarDate,
    ctx.data.transportPriority
  );

  const orderMutation = useOrderMutation();
  const ediOrderMutation = useEDIOrderMutation();

  const connection = useMemo(() => {
    const connections = Object.values(props.clientSupplier?.connections ?? {});
    return connections.find((c) =>
      c.connectionType?.includes(DBConnectionType.ORDER)
    );
  }, [props.clientSupplier?.connections]);

  console.log({ connection });

  const totals = useMemo(() => {
    let subtotal = 0;
    let shipping = 0;
    let ecoTax = 0;
    let total = 0;

    data?.forEach((entry) => {
      subtotal += entry.price * entry.quantity;
      shipping += entry.shipping * entry.quantity;
      ecoTax += entry.ecoTax * entry.quantity;
      total = subtotal + shipping + ecoTax;
    });
    return {
      subtotal,
      shipping,
      ecoTax,
      total,
    };
  }, [data]);

  const variant = useMemo(() => {
    if (!connection || isB2BClicked) return 'B2B';
    else return _.get(connection, 'type', '');
    // this is ok
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, isB2BClicked, selectedIdx]);

  // if (ctx.mode === 'sales_orders') {
  //   if (
  //     _.sum(_.values(soctx.data.requested)) > 0 &&
  //     ediOrderMutation.status !== 'success'
  //   )
  //     makeNewOrder = false;
  // }

  const clientSupplierId = props.clientSupplier.id;

  /** this allows to make multiple orders from same supplier */
  // const supplierId = props.clientSupplier.supplierId;
  // const orderItems = _.get(ctx.data.order, supplierId, []);
  // const orderResponse = ctx.data.orderResponse?.[clientSupplierId];

  // const orderLineItems = (orderResponse?.orderLines ?? []).map((item) => {
  //   return { ean: item.ean, quantity: Number(item.confirmedQuantity) };
  // });

  // const items = (orderItems ?? [])?.map((item) => {
  //   return { ean: item.ean, quantity: item.quantity };
  // });

  // const isEqual = _.isEqual(items, orderLineItems);

  const hasPlacedOrder =
    !!ctx.data.orderResponse?.[clientSupplierId] || orderMutation.isSuccess;

  const handleInquiryMutation = useCallback(
    (manualBranchId?: number) => {
      if (hasPlacedOrder) return Promise.resolve();

      const branchId = manualBranchId ?? selectedBranchId;
      if (variant === 'HTTPS' && !!branchId)
        return inquiryMutation.mutateAsync({
          connection,
          supplierId: props.clientSupplier.supplierId,
          branchId: branchId,
          supplierClientSettingsId: props.clientSupplier.id,
          items:
            data?.map((product) => ({
              quantity: product.quantity,
              ean: product.ean,
              ipc: product.ipc,
            })) ?? [],
          preferredDeliveryDate: ctx.data.requestedDeliveryCalendarDate,
          transportPriority: ctx.data.transportPriority ?? 'REPL',
        });
      return Promise.resolve();
    },
    // This disable is ok, I've handled the dependencies manually
    // eslint-disable-next-line
    [
      connection,
      data,
      hasPlacedOrder,
      inquiryMutation.mutateAsync,
      props.clientSupplier.id,
      props.clientSupplier.supplierId,
      selectedBranchId,
      variant,
      ctx.data.requestedDeliveryCalendarDate,
      ctx.data.transportPriority,
    ]
  );

  const emailLocation = EmailLinkGenerator({
    email:
      connection && connection.connectionDetails !== undefined
        ? connection.connectionDetails.supplierEmail
        : '',
    cc:
      ctx?.data?.ccEmail && ctx?.data?.ccEmail?.[props.clientSupplier.id]
        ? ctx?.data?.ccEmail?.[props.clientSupplier.id]
        : '',
    products: props.data,
    branchDetails: {
      address: selectedBranch?.address ?? '',
      zipCode: selectedBranch?.zipCode ?? '',
      city: selectedBranch?.city ?? '',
      mobilePhone: selectedBranch?.businessPhone ?? '',
    },
    clientName: user?.client?.officialName ?? '',
    deliveryNote: ctx?.data?.internalRemarks?.[props.clientSupplier.id] ?? '',
    reference: ctx?.data?.reference ?? '',
    countryId: props.clientSupplier.supplier?.countryId ?? '',
  });

  const removeValues = useCallback(
    (productIds: string[]) => {
      const supplierId = props.clientSupplier.supplierId;

      for (const product of productIds) {
        soctx.setQty(supplierId, product, 0, 'supplier');
      }
    },
    [props.clientSupplier.supplierId, soctx]
  );

  const handleOrderClicked = useCallback(async () => {
    if (!selectedBranch) {
      ToastHelper.showToast(
        'Please select a branch before continuing',
        ToastType.ERROR,
        ToastMessageType.ERROR
      );
      return;
    }

    const suffix = props.hasMultipleOrderKeys
      ? `-${props.orderKey.toString().padStart(2, '0')}`
      : '';

    let prefix = '';

    switch (ctx.mode) {
      case 'sales_orders':
        prefix = 'SO-';
        break;
      case 'purchase_orders':
        prefix = 'PO-';
        break;
    }

    const data = {
      reference: ctx?.data.reference ?? '',
      supplierId: props.clientSupplier.supplierId,
      supplierClientSettingsId: props.clientSupplier.id,
      lineItems: props.data,
      remark: ctx?.data?.internalRemarks?.[props.clientSupplier.id] ?? '',
      supplierRemark:
        ctx?.data?.supplierRemarks?.[props.clientSupplier.id] ?? '',
      orderNumberSufix: suffix,
      orderNumberPrefix: prefix,
      deliveryAddress: {
        address_line_1: selectedBranch?.branchName ?? '',
        address_line_2: selectedBranch?.address ?? '',
        city: selectedBranch?.city ?? '',
        country: selectedBranch?.country?.name ?? '',
        zipCode: selectedBranch?.zipCode ?? '',
      },
      transportPriority: ctx.data.transportPriority ?? 'REPL',
      preferredDeliveryDate: ctx.data.requestedDeliveryCalendarDate ?? '',
      connectionProtocol: variant,
      connection: connection ?? undefined,
      branchId: selectedBranch?.id ?? 0,
      rulesetKey: connection ? connection.rulesetKey : '',
      customerOrderId: soctx.customerOrderId ?? null,
    };

    setOrderData(data);

    if (variant === 'HTTPS' && selectedIdx === 0) {
      await handleInquiryMutation();

      const orderData = {
        ...data,
        connection,
      };

      const productIds = props.data.map((p) => p.uid);

      const res = await ediOrderMutation.mutateAsync(
        getEdiWheelData(
          { ...orderData, rulesetKey: connection.rulesetKey },
          user?.client
        )
      );

      ctx.setInputValue({
        orderResponse: {
          ...ctx.data.orderResponse,
          [props.clientSupplier.id]: res.purchaseOrder,
        },
      });

      removeValues(productIds);
    }

    if (variant === 'FTP') {
      const orderData = {
        ...data,
        connection,
        ftpConnection: connection,
      };

      const productIds = props.data.map((p) => p.uid);

      console.log({
        variant,
        connection,
        data: { ...orderData, rulesetKey: connection.rulesetKey },
      });
      const res = await ediOrderMutation.mutateAsync(
        getEdiWheelData(
          { ...orderData, rulesetKey: connection.rulesetKey },
          user?.client
        )
      );

      ctx.setInputValue({
        orderResponse: {
          ...ctx.data.orderResponse,
          [props.clientSupplier.id]: res.purchaseOrder,
        },
      });

      removeValues(productIds);
    }
    if (variant === 'EMAIL' && selectedIdx === 0) {
      window.open(emailLocation, '_blank');
      setIsModalOpen(true);
    }
    if (variant === 'B2B') {
      if (props.clientSupplier.supplierClientB2BSettings?.b2bUrl)
        window.open(
          props.clientSupplier.supplierClientB2BSettings?.b2bUrl,
          '_blank'
        );
      setIsModalOpen(true);
    }
  }, [
    connection,
    ctx,
    ediOrderMutation,
    emailLocation,
    handleInquiryMutation,
    props.clientSupplier.id,
    props.clientSupplier.supplierClientB2BSettings?.b2bUrl,
    props.clientSupplier.supplierId,
    props.data,
    props.hasMultipleOrderKeys,
    props.orderKey,
    removeValues,
    selectedBranch,
    selectedIdx,
    soctx.customerOrderId,
    user?.client,
    variant,
  ]);

  const handleSubmit = useCallback(async () => {
    if (orderData !== null) {
      let res: DBClientSupplierOrderItem | null = null;
      if (variant === 'EMAIL') {
        const data = {
          ...orderData,
          toEmail: connection.connectionDetails.supplierEmail,
          fromEmail: user?.client?.companyEmail || '',
          cc:
            ctx?.data?.ccEmail && ctx?.data?.ccEmail?.[props.clientSupplier.id]
              ? ctx?.data?.ccEmail?.[props.clientSupplier.id]
              : '',
          orderReference: supplierOrderNumber,
        };
        res = await orderMutation.mutateAsync({
          ...data,
        } as unknown as DBClientSupplierOrderApi['create']['request']);
      } else {
        res = await orderMutation.mutateAsync({
          ...orderData,
          orderReference: supplierOrderNumber,
        } as unknown as DBClientSupplierOrderApi['create']['request']);
      }
      ctx.setInputValue({
        orderResponse: {
          ...ctx.data.orderResponse,
          [props.clientSupplier.id]: {
            errorCode: '',
            orderId: supplierOrderNumber,
            orderLines: [],
            createdOrder: res,
          },
        },
      });
    }

    setIsModalOpen(false);
  }, [
    connection,
    ctx,
    orderData,
    orderMutation,
    props.clientSupplier.id,
    supplierOrderNumber,
    user?.client?.companyEmail,
    variant,
  ]);

  /* start of button functions */

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIdx(index);
    if (index === 1) setIsB2BClicked(true);
    else setIsB2BClicked(false);
    setTriggerOrderClick(true);

    setButtonOpen(false);
  };

  const handleToggle = () => {
    setButtonOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    )
      return;

    setButtonOpen(false);
  };

  /* end of button functions */

  useEffect(() => {
    const firstBranch = Number(
      Object.keys(props.clientSupplier.branchEdiConfiguration ?? {})
        .filter((key) => {
          const matchedBranch = props.branches.find(
            (branch) => branch.id === Number(key)
          );

          const config = _.get(
            props,
            `clientSupplier.branchEdiConfiguration.${key}`
          ) as unknown as BranchesEdiShape;

          return config?.partyId && matchedBranch?.isActive;
        })
        .pop()
    );
    if (
      !selectedBranchId &&
      !!props.branches[0] &&
      !inquiryMutation?.data &&
      !inquiryMutation.isLoading &&
      !inquiryMutation.error &&
      !isNaN(firstBranch)
    ) {
      handleInquiryMutation(firstBranch).catch(console.log);
    }
  }, [
    handleInquiryMutation,
    inquiryMutation,
    inquiryMutation?.data,
    inquiryMutation.error,
    inquiryMutation.isLoading,
    props,
    props.branches,
    selectedBranchId,
  ]);

  useEffect(() => {
    if (selectedBranchId) handleInquiryMutation().catch(console.log);
    // This is ok, the dependency array is handled properly
    // eslint-disable-next-line
  }, [
    selectedBranchId,
    ctx.data.requestedDeliveryCalendarDate,
    ctx.data.transportPriority,
  ]);

  useEffect(() => {
    if (triggerOrderClick) {
      handleOrderClicked();
      setTriggerOrderClick(false);
    }
  }, [triggerOrderClick, handleOrderClicked]);

  // useEffect(() => {
  //   if (!connection || isB2BClicked) setVariant('B2B');
  //   else setVariant(_.get(connection, 'type', ''));
  // }, [connection, isB2BClicked]);

  const allItemsReadyForOrder = useMemo(() => {
    let hasAllItems = true;

    for (let i = 0; i < props?.data?.length; i++) {
      const line = props.data[i];
      const ean = line.ean;
      const requestedQty = line.quantity;
      const availableQty = inquiryMutation.data?.[ean]?.qty ?? 0;

      if (
        availableQty < requestedQty ||
        !inquiryMutation.data?.[ean]?.deliveryDate ||
        (typeof inquiryMutation.data?.[ean]?.deliveryDate === 'string' &&
          inquiryMutation.data?.[ean]?.deliveryDate?.startsWith('9999'))
      ) {
        hasAllItems = false;
      }

      if (!hasAllItems) break;
    }
    return hasAllItems;
  }, [inquiryMutation.data, props.data]);

  if (!props.clientSupplier.supplier) return null;

  const busy =
    inquiryMutation.isLoading ||
    ediOrderMutation.isLoading ||
    orderMutation.isLoading;

  const ediOrderError = _.get(
    ediOrderMutation,
    'error.response.data.error.name'
  );

  const disableOrderButton =
    (variant === 'B2B' &&
      props.clientSupplier.supplierClientB2BSettings?.b2bUrl === '') ||
    busy ||
    hasPlacedOrder ||
    !selectedBranch;

  return (
    <Wrapper id="order_supplier_box">
      {isModalOpen && (
        <OrderSupplierModal
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
          orderNumber={supplierOrderNumber}
          setOrderNumber={setSupplierOrderNumber}
        />
      )}
      <OrderSupplierBoxHeader
        open={open}
        onClick={() => setOpen((val) => !val)}
      >
        <h3>{props.clientSupplier.supplier.companyShortName}</h3>
        {busy && (
          <div className="check">
            <CircularProgress size={16} sx={{ marginRight: 2 }} />
            <div>Checking latest quantities with supplier</div>
          </div>
        )}
        <div>
          <Chip label={variant} variant="outlined" />
        </div>
        <div style={{ paddingLeft: 5 }}>
          <Button
            size={'small'}
            onClick={(e) => {
              e.stopPropagation();
              handleInquiryMutation();
            }}
          >
            Refetch
          </Button>
        </div>
      </OrderSupplierBoxHeader>
      {open && (
        <Content>
          <Grid container spacing={2}>
            <Grid item xs={8} sx={{ position: 'relative' }}>
              <Scrollbars
                style={{
                  width: '100%',
                  height: 340,
                  paddingRight: 16,
                  marginRight: 16,
                }}
              >
                {props.data?.map((line, index) => (
                  <OrderSupplierBoxLine
                    key={index}
                    data={line}
                    orderComplete={busy || hasPlacedOrder}
                    inquiryData={inquiryMutation.data?.[line.ean]}
                    isScrolling={props.data.length > 5}
                  />
                ))}
              </Scrollbars>

              {_.get(inquiryMutation, 'error.response.data.error.name') && (
                <Alert severity={'error'}>
                  <AlertTitle>
                    Something went wrong when triggering an inquiry!
                  </AlertTitle>
                  {_.get(inquiryMutation, 'error.response.data.error.name')}
                </Alert>
              )}

              {hasPlacedOrder &&
                (ctx.data.orderResponse?.[props.clientSupplier.id] ||
                  supplierOrderNumber) && (
                  <OrderSentSuccess
                    supplierOrderId={
                      ctx.data.orderResponse?.[props.clientSupplier.id]
                        ? ctx.data.orderResponse?.[props.clientSupplier.id]
                            .orderId
                        : supplierOrderNumber
                    }
                    orderReferenceSuffix={
                      props.hasMultipleOrderKeys
                        ? `-${props.orderKey.toString().padStart(2, '0')}`
                        : ''
                    }
                    ritamResponse={ediOrderMutation?.data?.ritamResponse ?? ''}
                  />
                )}
              {!hasPlacedOrder && (
                <>
                  <OrderBranchSelector
                    clientSupplier={props.clientSupplier}
                    clientSupplierId={props.clientSupplier.id}
                    branches={props.branches}
                    defaultBranchErpId={
                      soctx.customerOrder?.branchErpId &&
                      !_.isEmpty(soctx.customerOrder?.branchErpId)
                        ? soctx.customerOrder?.branchErpId
                        : undefined
                    }
                  />

                  <OrderRemarkFields
                    clientSupplierId={props.clientSupplier.id}
                  />
                  {variant === 'EMAIL' && (
                    <OrderEmailFields
                      email={connection.connectionDetails.supplierEmail ?? ''}
                      clientSupplierId={props.clientSupplier.id}
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <SummaryWrapper>
                <div>
                  <span>Subtotal:</span>
                  <p>
                    {totals.subtotal.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>
                <div>
                  <span>Delivery:</span>
                  <p>
                    {totals.shipping.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>
                <div>
                  <span>Eco tax:</span>
                  <p>
                    {totals.ecoTax.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>

                <div className="strong">
                  <span>Total:</span>
                  <p>
                    {totals.total.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>
                <Tooltip
                  placement="top"
                  title={
                    variant === 'B2B' &&
                    props.clientSupplier.supplierClientB2BSettings?.b2bUrl ===
                      ''
                      ? 'B2B url is not defined'
                      : ''
                  }
                >
                  <span>
                    {variant !== 'B2B' ? (
                      <ButtonGroup
                        variant="contained"
                        color="info"
                        ref={anchorRef}
                        aria-label="Button group with a nested menu"
                        fullWidth
                        disableElevation
                      >
                        <Button
                          onClick={() => {
                            handleOrderClicked();
                            // if (ctx.mode === 'sales_orders')
                            //   soctx.setScreen('process_order');
                          }}
                          fullWidth
                          disabled={disableOrderButton}
                          sx={{
                            borderRight: 0,
                            lineHeight: '1.70',
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          id="purchase_order_button"
                        >
                          {ediOrderMutation.isLoading && (
                            <CircularProgress
                              size={15}
                              sx={{ position: 'absolute', left: '10%' }}
                            />
                          )}
                          {buttonOptions[selectedIdx]}
                        </Button>

                        <Button
                          aria-controls={
                            buttonOpen ? 'split-button-menu' : undefined
                          }
                          aria-expanded={buttonOpen ? 'true' : undefined}
                          sx={{ maxWidth: '10px' }}
                          onClick={handleToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <Button
                        disabled={disableOrderButton}
                        fullWidth
                        variant="contained"
                        color="info"
                        onClick={handleOrderClicked}
                        disableElevation
                      >
                        ORDER
                      </Button>
                    )}

                    <Popper
                      sx={{
                        zIndex: 1,
                        maxWidth: '400px',
                        minWidth: '300px',
                        width: '300px',
                      }}
                      open={buttonOpen}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom'
                                ? 'center top'
                                : 'center bottom',
                            width: '100%',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                id="split-button-menu"
                                autoFocusItem
                                sx={{ width: '100%' }}
                              >
                                {buttonOptions.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    selected={index === selectedIdx}
                                    onClick={(event) =>
                                      handleMenuItemClick(event, index)
                                    }
                                    style={{
                                      display: index === 0 ? 'none' : '',
                                    }}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </span>
                </Tooltip>
              </SummaryWrapper>
              {!allItemsReadyForOrder && inquiryMutation.isSuccess && (
                <div style={{ marginBottom: 16 }}>
                  <Alert severity="warning">
                    <AlertTitle>Not all items may be available.</AlertTitle>
                    After running an inquiry with the supplier, some items are
                    either unavailable or their delivery dates are unknown.
                    Please verify that the available quantities are OK before
                    continuing.
                  </Alert>
                </div>
              )}
              {ediOrderError && (
                <Alert severity={'error'}>
                  <AlertTitle>
                    Something went wrong when placing an order!
                  </AlertTitle>
                  {ediOrderError}
                </Alert>
              )}
            </Grid>
          </Grid>
        </Content>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  margin-bottom: 16px;
  max-width: 1800px;
`;

export const OrderSupplierBoxHeader = styled.div<{ open: boolean }>`
  border-radius: ${(props) =>
    !props.open ? '16px 16px 16px 16px' : '16px 16px 0px 0px'};
  padding: 8px 24px;
  background: #f3f5f7;
  display: flex;
  line-height: 1;
  cursor: pointer;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.3px;
    color: #212b36;
    margin: 0;
    padding: 0;
    flex: 1;
  }
  .check {
    padding: 0 16px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &:hover {
    background: #edeff0;
  }
`;
export const Content = styled.div`
  padding: 16px;
`;
export const SummaryWrapper = styled.div`
  flex: 1;

  /* Primary/Black/black-10 */

  background: #f9fafb;
  border-radius: 16px;
  padding: 200px 16px 16px 16px;
  margin-bottom: 16px;
  color: #212b36;
  div {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin-bottom: 4px;
    span {
      margin: 0;
      padding: 0;
      flex: 1;
    }
    p {
      margin: 0;
      padding: 0;
    }
    &.strong {
      font-weight: 600;
      margin-bottom: 36px;
    }
  }
`;
