import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Button, Grid } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled as muiStyled } from '@mui/material/styles';
import { DBWarehouseSections, DBWarehouseShelfs } from '@prisma/client';
import { WarehouseResponse } from '@tyrio/dto';
import _ from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { WarehouseContext } from '../../../context/WarehouseContext';
import { getCurrent } from '../helper/helper';
import { useWarehouseHelper } from '../helper/warehouse-helper';
import { ShelfCard } from './ShelfCard';

interface FloorCardProps {
  section: DBWarehouseSections;
  shelfs?: DBWarehouseShelfs[];
}

export default function SectionCard(props: FloorCardProps) {
  const { selected, warehouse } = React.useContext(WarehouseContext);

  const { initShelf, _setWarehouse } = useWarehouseHelper();

  const { section, shelfs } = props;

  const settings = (att: string) => {
    return _.get(section.settings, att);
  };

  const [expanded, setExpanded] = useState<string | false>(section.id);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div style={{ width: '100%', marginBottom: '16px' }}>
      <Accordion
        expanded={expanded === section.id}
        onChange={handleChange(section.id)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <FloorHeader>
            <ZoneColor color={getCurrent(selected, 'color')} />
            <FloorHeader>
              <CardHeaderText>SECTION {section.code}</CardHeaderText>
              {settings('stagingType') === 'FITTING' && (
                <BoxName>{getCurrent(selected, 'stagingType')}</BoxName>
              )}
            </FloorHeader>
          </FloorHeader>
          <FloorStatus>
            <FloorStatusIcon isActive={settings('isActive')} />
            <FloorShortDetailsValue>
              {settings('isActive') ? 'Active' : 'Inactive'}
            </FloorShortDetailsValue>
          </FloorStatus>
        </AccordionSummary>
        <DisplayName>{section.displayName}</DisplayName>
        <AccordionDetails>
          <Grid sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              color="info"
              startIcon={<AddIcon />}
              onClick={() => {
                const updated = warehouse as WarehouseResponse;

                const { shelf } = initShelf(section, updated);

                _setWarehouse({ shelf }, updated);
              }}
            >
              ADD SHELF
            </Button>
          </Grid>
          <RacksWrapper>
            {shelfs?.map((shelf) => {
              return <ShelfCard shelf={shelf} section={section} />;
            })}
          </RacksWrapper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const Accordion = muiStyled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = muiStyled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#dfe3e8',
  flexDirection: 'row-reverse',
  borderTopLeftRadius: '7px',
  borderTopRightRadius: '7px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  ':hover': {
    backgroundColor: '#c9ccd1',
  },
}));

const AccordionDetails = muiStyled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  width: '100%',
}));

const CardHeaderText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;

const BoxName = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
  color: #e75524;
`;

const ZoneColor = styled.div<{ color: string }>`
  background: ${(prop) => prop.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
`;

const FloorStatus = styled.div`
  display: flex;
  align-items: center;
`;

const FloorStatusIcon = styled.div<{ isActive: boolean }>`
  background: ${(prop) => (prop.isActive ? '#2e7d32' : '#d32f2f')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 2px;
`;
const FloorShortDetailsValue = styled.span`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: black;
`;

const FloorHeader = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DisplayName = styled.div`
  padding: 6px;
  background-color: #13ad5c;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;

const RacksWrapper = muiStyled(MuiAccordionDetails)(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
}));
