/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { DBStockList, DBStockListApi, MainBranchLocations } from '@tyrio/dto';
import qs from 'qs';
import { createContext, Dispatch, SetStateAction } from 'react';
import { CartData } from '../features/stock-list/components/Cart/helper/interfaces';

export type ActiveStep =
  | 'SEARCH'
  | 'DETAILS'
  | 'INBOUND'
  | 'OUTBOUND'
  | 'PASTE_LIST'
  | 'PRINT'
  | 'CART';

export type ActiveDrawer =
  | 'GALLERY'
  | 'LOCATION_INFO'
  | 'PRINT'
  | 'RESERVATION'
  | '';

export interface StockListInputShape {
  activeStep: ActiveStep;
  activeDrawer: ActiveDrawer;
  dirty: boolean;
  selectedTableRow: DBStockList | null;
  shouldEnlarge: boolean;
  warehouseId: string | null;
}

type StockListContextInterface = {
  filterValues: qs.ParsedQs | undefined;
  setFilterValues: Dispatch<SetStateAction<qs.ParsedQs>>;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
  resetFilters: () => void;
  cartItems: CartData[];
  setCartItems: Dispatch<SetStateAction<CartData[]>>;
  orderSent: boolean;
  setOrderSent: Dispatch<SetStateAction<boolean>>;
  selectedTableRow: DBStockList | null;
  setSelectedTableRow: (row: DBStockList | null) => void;
  activeStep: ActiveStep;
  setActiveStep: (active: ActiveStep) => void;
  activeDrawer: ActiveDrawer;
  setActiveDrawer: (active: ActiveDrawer) => void;
  onlyAvailable: boolean;
  setOnlyAvailable: Dispatch<SetStateAction<boolean>>;
  sidebarOpen: boolean;
  outboundOrdersFilter: any;
  setOutboundOrdersFilter: Dispatch<SetStateAction<any>>;
  shouldEnlarge: boolean;
  setShouldEnlarge: Dispatch<SetStateAction<boolean>>;
  selectedLocation: MainBranchLocations | null;
  setSelectedLocation: (row: MainBranchLocations | null) => void;
  input: StockListInputShape;
  setInput: (input: StockListInputShape) => void;
  outboundOrders: any[];
  setOutboundOrders: Dispatch<SetStateAction<any>>;
  outboundOrderItems: any[];
  setOutboundOrderItems: Dispatch<SetStateAction<any>>;
  filterOptions: DBStockListApi['getFilters']['response'] | null;

  outboundError: boolean;
  setOutboundError: Dispatch<SetStateAction<boolean>>;
};

export const StockListContext = createContext<StockListContextInterface>({
  filterValues: qs.parse(''),
  setFilterValues: () => {},
  setFilterValue: () => {},
  resetFilters: () => {},
  cartItems: [],
  setCartItems: () => {},
  orderSent: false,
  setOrderSent: () => {},
  selectedTableRow: null,
  setSelectedTableRow: () => {},
  activeStep: 'SEARCH',
  setActiveStep: () => {},
  activeDrawer: '',
  setActiveDrawer: () => {},
  onlyAvailable: false,
  setOnlyAvailable: () => {},
  sidebarOpen: false,
  outboundOrdersFilter: {},
  setOutboundOrdersFilter: () => {},
  shouldEnlarge: false,
  setShouldEnlarge: () => {},
  selectedLocation: null,
  setSelectedLocation: () => {},
  input: {
    activeStep: 'SEARCH',
    activeDrawer: '',
    dirty: false,
    selectedTableRow: null,
    shouldEnlarge: false,
    warehouseId: '',
  },
  setInput: () => {},
  outboundOrders: [],
  setOutboundOrders: () => {},
  outboundOrderItems: [],
  setOutboundOrderItems: () => {},
  filterOptions: null,

  outboundError: false,
  setOutboundError: () => {},
});

interface StockListProviderProps {
  children: React.ReactNode;
  value: StockListContextInterface;
}

const StockListProvider = ({ children, value }: StockListProviderProps) => {
  return (
    <StockListContext.Provider value={value}>
      {children}
    </StockListContext.Provider>
  );
};

export default StockListProvider;
