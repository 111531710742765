import * as z from "zod"
import { CompleteDBProductModel, RelatedDBProductModelModel, CompleteDBSupplierClientPackageCost, RelatedDBSupplierClientPackageCostModel, CompleteDBFavoriteCategories, RelatedDBFavoriteCategoriesModel, CompleteDBService, RelatedDBServiceModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBProductCategoryModel = z.object({
  id: z.number().int(),
  parent_category_id: z.number().int().nullish(),
  parent_category_name: z.string().nullish(),
  name: z.string(),
  slug: z.string(),
  is_tyrio_catalog: z.boolean(),
  taric_code: z.string(),
  remark: z.string(),
  model_attributes: jsonSchema,
  item_attributes: jsonSchema,
  non_deletable: z.boolean().nullish(),
  active: z.boolean(),
})

export interface CompleteDBProductCategory extends z.infer<typeof DBProductCategoryModel> {
  parent_category?: CompleteDBProductCategory | null
  children: CompleteDBProductCategory[]
  models: CompleteDBProductModel[]
  packageCosts: CompleteDBSupplierClientPackageCost[]
  favoritedByClients: CompleteDBFavoriteCategories[]
  services: CompleteDBService[]
}

/**
 * RelatedDBProductCategoryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBProductCategoryModel: z.ZodSchema<CompleteDBProductCategory> = z.lazy(() => DBProductCategoryModel.extend({
  parent_category: RelatedDBProductCategoryModel.nullish(),
  children: RelatedDBProductCategoryModel.array(),
  models: RelatedDBProductModelModel.array(),
  packageCosts: RelatedDBSupplierClientPackageCostModel.array(),
  favoritedByClients: RelatedDBFavoriteCategoriesModel.array(),
  services: RelatedDBServiceModel.array(),
}))
