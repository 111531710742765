import styled from '@emotion/styled';
import { Divider, Grid, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useContext } from 'react';
import { WarehouseContext } from '../../../../context/WarehouseContext';
import { getCurrent } from '../../helper/helper';

interface LocationProps {
  location: string;
}

const Location = (props: LocationProps) => {
  const { location } = props;

  const { selected } = useContext(WarehouseContext);

  const parsedLocationInfo = location.split('-');

  const item = (title: string, subtitle: string, minWidth: string) => {
    const getColor = (char: string) => {
      if (char === '-') return 'black';

      if (char === '*') return '#ECF0F4';

      return '#212B36';
    };

    return (
      <Name style={{ minWidth }}>
        <Typography fontWeight={400} fontSize={16} color="#919EAB">
          {title}
        </Typography>

        <Grid
          sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}
        >
          {subtitle.split('').map((item) => (
            <Typography fontWeight={700} fontSize={30} color={getColor(item)}>
              {item === '*' ? 0 : item}
            </Typography>
          ))}
        </Grid>
      </Name>
    );
  };

  return (
    <Wrapper
      style={{
        justifyContent: 'flex-start',
        padding: 0,
      }}
    >
      <QrWrapper>
        <QRCodeSVG value={getCurrent(selected, 'qrCode')} />

        <TextWrapper>
          {selected.current.includes('subzone') &&
            getCurrent(selected, 'stagingType') && (
              <Typography
                fontWeight={700}
                fontSize={28}
                color={getCurrent(selected, 'color')}
              >
                {getCurrent(selected, 'stagingType')}
              </Typography>
            )}
          <NameWrapper>
            {item('Zone', `${parsedLocationInfo[0]}`, '55px')}
            {item('', '-', '24px')}
            {item('Rack', `${parsedLocationInfo[1]}`, '36px')}
            {item('', '-', '24px')}
            {item('Shelf', `${parsedLocationInfo[2]}`, '37px')}
            {item('', '-', '24px')}
            {item('Bin', `${parsedLocationInfo[3]}`, '24px')}
          </NameWrapper>

          <Typography fontWeight={400} fontSize={16} color="#919EAB">
            {selected.locations['zone']?.description}
          </Typography>
        </TextWrapper>
      </QrWrapper>

      <Divider
        sx={{
          width: '100%',
          borderWidth: '2px',
          borderColor: '#E6E8F0',
          marginTop: '20px',
        }}
      />
    </Wrapper>
  );
};

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50px;
  height: 100%;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 16px;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
`;

export const QrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 140px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* border-left: 6px solid #db1e1e; */
  align-items: center;
  padding: 12px 24px;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
`;

export default Location;
