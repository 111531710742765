import { TRule } from '../../typings';

export const REIFENMUELLER_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['Prim�re EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Nr.']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Herstellerk�rzel']",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['VK-Preis']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
