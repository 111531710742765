import styled from '@emotion/styled/macro';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { DotTrackingOptions } from '@tyrio/dto';

interface DotTrackingSettingsProps {
  dotTracking: DotTrackingOptions | '';
  isDotTrackingMandatory: boolean | undefined;
  setDotTracking: (val: DotTrackingOptions | '') => void;
  setIsDotTrackingMandatory: (val: boolean | undefined) => void;
}

const DotTrackingSettings = (props: DotTrackingSettingsProps) => {
  const {
    dotTracking,
    isDotTrackingMandatory,
    setDotTracking,
    setIsDotTrackingMandatory,
  } = props;

  const onDotTrackingOptionChange = (val: DotTrackingOptions) => {
    setDotTracking(val);

    if (val === 'noDotTracking') {
      setIsDotTrackingMandatory(false);
    }
  };

  const handleIsDotTrackingMandatoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDotTrackingMandatory(event.target.checked);
  };

  const dotTrackingOptions = [
    {
      key: 'weekOfProd',
      label: 'Week of production (ex. 1523)',
    },
    {
      key: 'yearOfProd',
      label: 'Year of production (ex. XX23)',
    },
    {
      key: 'noDotTracking',
      label: 'Do not track DOT (ex. XXXX)',
    },
  ];

  return (
    <DOTTrackingContainer>
      <Grid>
        <DOTTrackingSettingContainer>
          <FormControl>
            <FormLabel>DOT Tracking:</FormLabel>
            <RadioGroup defaultValue={dotTracking}>
              {dotTrackingOptions.map((item) => {
                return (
                  <FormControlLabel
                    value={item.key}
                    control={<Radio />}
                    label={item.label}
                    onChange={() => {
                      onDotTrackingOptionChange(item.key as DotTrackingOptions);
                    }}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {['weekOfProd', 'yearOfProd'].includes(dotTracking) && (
            <FormControlLabel
              control={
                <Switch
                  value={!!isDotTrackingMandatory}
                  checked={!!isDotTrackingMandatory}
                  onChange={handleIsDotTrackingMandatoryChange}
                />
              }
              label="Is DOT Tracking Mandatory"
            />
          )}
        </DOTTrackingSettingContainer>
      </Grid>
    </DOTTrackingContainer>
  );
};

const DOTTrackingContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const DOTTrackingSettingContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  gap: 25px;
`;

export default DotTrackingSettings;
