import styled from '@emotion/styled';
import { tyrioUI } from '@tyrio/ui-library';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

export const MonthlySales = () => {
  //TODO: Remove this tempData after real one is provided.
  // This is just a placeholder.
  const tempData = [
    { date: '08/22', value: 20, percentage: -10 },
    { date: '07/22', value: 20, percentage: 10 },
    { date: '06/22', value: 20, percentage: 20 },
    { date: '05/22', value: 20, percentage: 30 },
    { date: '04/22', value: 20, percentage: -40 },
    { date: '03/22', value: 20, percentage: -10 },
    { date: '02/22', value: 20, percentage: 60 },
    { date: '01/22', value: 20, percentage: -50 },
    { date: '12/21', value: 20, percentage: -40 },
    { date: '11/21', value: 20, percentage: -30 },
    { date: '10/21', value: 20, percentage: 50 },
    { date: '09/21', value: 20, percentage: 50 },
  ];

  return (
    <Container>
      <ComingSoon>
        <Message>Coming soon</Message>
        <AccordionStyled expanded={true} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordion-monthly-sales"
            id="accordion-monthly-sales"
          >
            <Typography>Monthly sales</Typography>
          </AccordionSummary>
          <MonthlySalesContainer>
            <MonthlySalesWrapper>
              {tempData.map((value) => (
                <SmallBox key={`monthly_prices_${value.value}_${value.date}`}>
                  <DateSmallBoxWrapper>{value.date}</DateSmallBoxWrapper>
                  <MainValueSmallBoxWrapper>
                    {value.value}
                  </MainValueSmallBoxWrapper>
                  <PercentageSmallBoxWrapper
                    style={{
                      color: value.percentage < 0 ? '#E95C5C' : '#56C489',
                    }}
                  >
                    {value.percentage > 0 ? (
                      <ArrowDropDown
                        style={{
                          color: '#56C489',
                          width: '15px',
                          height: '15px',
                        }}
                      />
                    ) : (
                      <ArrowDropUp
                        style={{
                          color: '#E95C5C',
                          width: '15px',
                          height: '15px',
                        }}
                      />
                    )}
                    {Math.abs(value.percentage)}
                    {'%'}
                  </PercentageSmallBoxWrapper>
                </SmallBox>
              ))}
            </MonthlySalesWrapper>
          </MonthlySalesContainer>
        </AccordionStyled>
      </ComingSoon>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85px;
  left: 0;
  top: 150px;
  color: white;
  z-index: 999;
  font-weight: 600;
  font-size: 36px;
  background: rgba(0, 0, 0, 0.6);
`;

const ComingSoon = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
  z-index: 100;
`;

const AccordionStyled = styled(Accordion)`
  padding: 0;
  border: 0;
  margin: 0;
  box-shadow: none;
`;

const MonthlySalesWrapper = styled.div`
  width: 100%;
  padding: ${tyrioUI.spacing.l}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const SmallBox = styled.div`
  border: 1px solid #dfe3e8;
  border-radius: ${tyrioUI.borderRadius.m}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 20%;
  margin: 4px;
`;

const DateSmallBoxWrapper = styled.div`
  color: #919eab;
  font-size: 14px;
`;

const MainValueSmallBoxWrapper = styled.div`
  color: #212b36;
  font-size: 16px;
`;

const PercentageSmallBoxWrapper = styled.div`
  color: #56c489;
  font-size: 14px;
`;

const MonthlySalesContainer = styled.div`
  width: 100%;
`;
