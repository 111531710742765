import { useQuery } from 'react-query';
import api from '@tyrio/api-factory';
import { DBFittingOrdersListApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';

export const useGetFittingWarehouseShalves = (
  branchId: string,
  setShelves: (a: TyrioSelectInputOption[]) => void
) => {
  useQuery(
    ['get_fitting_orders'],
    () =>
      api.fetch<DBFittingOrdersListApi['getFittingWarehouseShelves']>(
        'get_fitting_orders',
        {
          branchId,
        }
      ),
    {
      onSuccess: (data) => {
        const dropdownItems = data.map((shelf) => ({
          label: shelf.displayName,
          value: shelf.id,
          name: shelf.displayName,
        }));

        setShelves(dropdownItems);
      },
    }
  );
};
