import * as z from 'zod';

export const onlyNumbersRegex = /^[0-9]*$/;
export const onlyLettersRegex = /^[a-zšđčćž ]+$/gi;

export const zodAdminObject = z.object({
  roleId: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  language: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  firstName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  lastName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  email: z
    .string()
    .trim()
    .min(2, {
      message: 'This field is required!',
    })
    .email({ message: 'Email is not in correct format!' }),
  mobilePhone: z
    .string()
    .trim()
    .min(12, { message: 'Number must be between 12 and 13 digits!' })
    .max(13, { message: 'Number must be between 12 and 13 digits!' }),
  birthday: z.date().optional().nullish(),
  remark: z.string().optional(),
  userRole: z.string().optional(),
});

export const zodCompanyContactObject = z.object({
  clientId: z.string(),
  firstName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  lastName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  email: z
    .string()
    .trim()
    .min(2, {
      message: 'This field is required!',
    })
    .email({ message: 'Email is not in correct format!' }),
  businessPhone: z
    .string()
    .trim()
    .min(8, { message: 'Number must be between 8 and 9 digits!' })
    .max(9, { message: 'Number must be between 8 and 9 digits!' }),
  mobilePhone: z
    .string()
    .trim()
    .min(8, { message: 'Number must be between 8 and 9 digits!' })
    .max(9, { message: 'Number must be between 8 and 9 digits!' }),
  remark: z.string().optional(),
});

export const zodAddressObject = z.object({
  city: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  countryId: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  zipCode: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  address1: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  address2: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  birthday: z.date(),
  remark: z.string().optional(),
});

export const zodCategoryObject = z.object({
  name: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  slug: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  parentCategory: z.any(),
  taricCode: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  remark: z.string().optional(),
});

export const zodUserObject = z.object({
  firstName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  lastName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  email: z
    .string()
    .trim()
    .min(2, {
      message: 'This field is required!',
    })
    .email({ message: 'Email is not in correct format!' }),
  mobilePhone: z
    .string({ required_error: 'This field is required!' })
    .min(12, { message: 'Number must be between 12 and 15 digits!' })
    .max(15, { message: 'Number must be between 12 and 15 digits!' }),
  personalIdentificationNumber: z
    .string()
    .trim()
    .min(2, {
      message: 'This field is required!',
    })
    .nonempty(),
  businessPhone: z
    .string()
    .trim()
    .min(12, { message: 'Number must be between 12 and 15 digits!' })
    .max(15, { message: 'Number must be between 12 and 15 digits!' })
    .optional(),
  birthday: z.date().nullish().optional(),
  remark: z.string().optional(),
  role: z.string().nonempty({ message: 'This field is required!' }),
  language: z.string().nonempty({ message: 'This field is required!' }),
  mainBranchId: z.number({
    required_error: 'This field is required!',
    invalid_type_error: 'This field is required!',
  }),
  currentBranchId: z
    .number({
      required_error: 'This field is required!',
      invalid_type_error: 'This field is required!',
    })
    .optional(),
  secondaryBranches: z.any().optional(),
  pin: z
    .string({ required_error: 'This field is required!' })
    .regex(onlyNumbersRegex, 'Only numbers are accepted!')
    .min(4, { message: 'Number must be between 4 and 6 digits!' })
    .max(6, { message: 'Number must be between 4 and 6 digits!' }),
});

const zodPartnerContactsObject = z.object({
  id: z.string().optional(),
  department: z.string().optional(),
  firstName: z
    .string()
    .trim()
    .min(2, {
      message: 'This field is required!',
    })
    .regex(onlyLettersRegex, 'Only letters are accepted!'),
  lastName: z
    .string()
    .trim()
    .min(2, {
      message: 'This field is required!',
    })
    .regex(onlyLettersRegex, 'Only letters are accepted!'),
  email: z
    .string()
    .trim()
    .min(2, {
      message: 'This field is required!',
    })
    .email({ message: 'Email format is invalid!' }),
  businessPhone: z.string().optional().nullable(),
  mobilePhone: z.string().optional().nullable(),
  remark: z.string().optional(),
});

const zodPartnerBanksObject = z.object({
  id: z.string().optional(),
  bankName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  iban: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  swift: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  currency: z.string().optional(),
});

const zodPartnerLocationsObject = z.object({
  id: z.string().optional(),
  locationName: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  invoiceAddress: z.boolean().optional(),
  shippingAddress: z.boolean().optional(),
  zipCodeLocation: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  cityLocation: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  addressLocation: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  emailLocation: z.string().trim().optional(),
  businessPhoneLocation: z.string().optional(),
  erpId: z.any().optional(),
});

export const zodPartnerObject = z.object({
  // MAIN TAB
  companyOfficialName: z
    .string()
    .min(2, {
      message: 'This field is required!',
    })
    .max(255, {
      message: 'This field cannot exceed 255 characters',
    }),
  businessType: z
    .string()
    .min(2, { message: 'This field is required!' })
    .max(255, { message: 'Business Type cannot exceed 255 characters' }),
  companyDisplayName: z.string().max(255).optional(),
  countryId: z.string().trim().min(2, {
    message: 'This field is required!',
  }),
  vatPostingGroup: z
    .string()
    .min(2, {
      message: 'This field is required!',
    })
    .max(255, {
      message: 'This field cannot exceed 255 characters',
    }),
  viesValidated: z.boolean().optional(),
  companyRegistrationNumber: z.string().optional(),
  viesVatNumber: z.string().max(255),
  zipCode: z
    .string()
    .min(2, {
      message: 'This field is required!',
    })
    .max(255, {
      message: 'This field cannot exceed 255 characters',
    }),
  city: z
    .string()
    .min(2, {
      message: 'This field is required!',
    })
    .max(255, {
      message: 'This field cannot exceed 255 characters',
    }),
  address: z
    .string()
    .min(2, {
      message: 'This field is required!',
    })
    .max(255, {
      message: 'This field cannot exceed 255 characters',
    }),
  remark: z.string().optional(),
  erpId: z.any().optional(),

  // CONTACTS TAB
  partnerContacts: z.record(zodPartnerContactsObject).optional(),

  // BANKS TAB
  partnerBanks: z.record(zodPartnerBanksObject).optional(),

  // SETTINGS TAB TODO
  customerPartnerType: z.boolean().optional(),
  supplierPartnerType: z.boolean().optional(),
  rebateCalculationId: z.string().optional(),
  customerType: z.any().optional(),
  paymentDelay: z.any().optional(),
  creditLimit: z.any().optional(),
  currency: z.any().optional(),
  invoices: z.any().array().optional(),
  emails: z.any().optional(),
  supplierType: z.any().optional(),
  supplierId: z.string().optional(),
  deliveryTermsType: z.any().optional(),
  transactionNature: z.any().optional(),
  transportMode: z.any().optional(),
  paymentTerms: z.any().optional(),
  customerVat: z.any().optional(),
  supplierVat: z.any().optional(),

  // LOCATIONS TAB
  partnerLocations: z.record(zodPartnerLocationsObject).optional(),

  // TYRIO B2B TAB
  // exclusivePartnership: z.boolean().optional(),
  // createB2BAccount: z.boolean().optional(),
  // language: z
  //   .string()
  //   .min(2, { message: 'This field is required!' })
  //   .refine((value) => value in DBLanguage, { message: 'Invalid language' }),
  // firstNameB2B: z
  //   .string()
  //   .min(2, { message: 'This field is required!' })
  //   .max(255, { message: 'First Name cannot exceed 255 characters' }),
  // lastNameB2B: z
  //   .string()
  //   .min(2, { message: 'This field is required!' })
  //   .max(255, { message: 'Last Name cannot exceed 255 characters' }),
  // emailB2B: z
  //   .string()
  //   .min(2, { message: 'This field is required!' })
  //   .email({ message: 'Invalid email format' }),
  // mobilePhoneB2B: z
  //   .string()
  //   .min(12, { message: 'Number must be between 12 and 13 digits!' })
  //   .max(13, { message: 'Number must be between 12 and 13 digits!' }),
  // businessPhoneB2B: z.string().optional(),
  // remarkB2B: z.string().optional(),
});
