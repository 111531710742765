import { Typography } from '@mui/material';
import { PurchaseOrdersByEanResponse } from '@tyrio/dto';
import { ProductsQuantity, WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import moment from 'moment';
import { useContext } from 'react';
import { StockListContext } from '../../../../context/StockListContext';
import {
  DocumentTitleWrapper,
  DocumentWrapper,
  ItemWrapper,
  ItemWrapperDiv,
  divStyle,
} from './InboundOrders.style';

interface PurchaseOrderItemProps {
  item: PurchaseOrdersByEanResponse;
}

const PurchaseOrderItem = ({ item }: PurchaseOrderItemProps) => {
  const { onlyAvailable, setActiveDrawer } = useContext(StockListContext);

  const currentDate = moment(new Date());
  const deliveryDate = moment(item.deliveryTime);

  const isDeliveryLateColor =
    currentDate > deliveryDate ? '#DB1E1E' : '#47BE7E';

  return (
    <ItemWrapper>
      <ItemWrapperDiv>
        <a
          href={item.documents?.[0]?.url}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: 'initial',
          }}
          download
        >
          <DocumentWrapper>
            <WmsIcon
              icon={wmsIcons.document}
              id="document_svg"
              sx={{ cursor: 'pointer' }}
            />

            <DocumentTitleWrapper>
              <Typography fontSize={16} fontWeight={400} id="document_name">
                {item.orderName}
              </Typography>

              <Typography fontSize={12} fontWeight={400} color="#919EAB">
                {`${moment(item.createdAt).format('DD.MM - h:mm')}`}
              </Typography>
            </DocumentTitleWrapper>
          </DocumentWrapper>
        </a>
        <div>
          <Typography fontSize={16} fontWeight={400}>
            {item.supplierName}
          </Typography>

          <div style={{ ...divStyle }}>
            <WmsIcon
              icon={wmsIcons.truck}
              sx={{
                color: isDeliveryLateColor,
                width: '16px',
                height: '14px',
              }}
              viewBox="0 0 16 14"
            />

            <Typography
              fontSize={12}
              fontWeight={400}
              color={isDeliveryLateColor}
            >
              {moment(item.deliveryTime).format('DD.MM.YYYY')}
            </Typography>
          </div>
        </div>
      </ItemWrapperDiv>

      <ProductsQuantity
        reserved={item.reserved | 0}
        available={item.available | 0}
        setActiveDrawer={setActiveDrawer}
        onlyAvailable={onlyAvailable}
      />
    </ItemWrapper>
  );
};

export default PurchaseOrderItem;
