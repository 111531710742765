import api from '@tyrio/api-factory';
import { DBClientRebateCalculationApi } from '@tyrio/dto';
import { useState } from 'react';
import { useQuery } from 'react-query';

interface GetRebatesProps {
  enabled: boolean;
}

export interface DropdownItems {
  label: string;
  value: string;
}

export const useGetRebates = (props: GetRebatesProps) => {
  const [rebatesData, setRebateData] = useState<DropdownItems[]>([]);
  const { enabled } = props;

  const rebates = useQuery(
    ['get_rebates'],
    async () => {
      return await api.fetch<DBClientRebateCalculationApi['list']>(
        'get_rebates',
        {
          search: '',
          pageSize: 100,
        }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data?.data.map((item) => ({
          label: item.groupName,
          value: item.id,
        }));

        setRebateData(dropdownItems);
      },
      enabled: enabled,
    }
  );

  return { ...rebates, rebatesData };
};
