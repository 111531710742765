import styled from '@emotion/styled';
import { ProductsQuantity } from '@tyrio/wms-ui-library';

interface ProductItemWithQuantitiesProps {
  product: {
    productName: string | undefined;
    ean: string;
    sku: string;
    productYear: string | number;
  };
  requested: number;
  available: number;
}

const ProductItemWithQuantities = ({
  product,
  requested,
  available,
}: ProductItemWithQuantitiesProps) => {
  return (
    <Container>
      <ProductDetails>
        <ProductName>{product.productName}</ProductName>
        <EanIpcSkuWrapper>
          <div>EAN: {`${product.ean} `}</div>
          <div> SKU: {`${product.sku} `}</div>
          <div>DOT: {`${product.productYear} `}</div>
        </EanIpcSkuWrapper>
      </ProductDetails>
      <ProductsQuantity
        reserved={requested | 0}
        available={available | 0}
        isModal={true}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 12px;
  gap: 8px;
  height: 70px;
  border-bottom: 1px solid #dfe3e8;
  cursor: default;
  justify-content: space-between;
`;

const ProductName = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const EanIpcSkuWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  max-width: 400px;
  flex-direction: row;
  color: #919eab;
`;

export default ProductItemWithQuantities;
