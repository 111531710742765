import * as z from "zod"
import { Code } from ".prisma/client"
import { CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBWarehouseZones, RelatedDBWarehouseZonesModel, CompleteDBWarehouseFloors, RelatedDBWarehouseFloorsModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBWarehouseSubzonesModel = z.object({
  id: z.string(),
  warehouseAreaId: z.string(),
  zoneId: z.string(),
  code: z.nativeEnum(Code),
  displayName: z.string(),
  name: z.string().nullish(),
  qrCode: z.string(),
  description: z.string(),
  settings: jsonSchema,
  meta: jsonSchema,
})

export interface CompleteDBWarehouseSubzones extends z.infer<typeof DBWarehouseSubzonesModel> {
  warehouseArea: CompleteDBBranchWarehouseAreas
  zone: CompleteDBWarehouseZones
  floors: CompleteDBWarehouseFloors[]
}

/**
 * RelatedDBWarehouseSubzonesModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWarehouseSubzonesModel: z.ZodSchema<CompleteDBWarehouseSubzones> = z.lazy(() => DBWarehouseSubzonesModel.extend({
  warehouseArea: RelatedDBBranchWarehouseAreasModel,
  zone: RelatedDBWarehouseZonesModel,
  floors: RelatedDBWarehouseFloorsModel.array(),
}))
