import * as z from "zod"
import { CompleteDBClient, RelatedDBClientModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBCustomPriceCatalogModel = z.object({
  id: z.string(),
  clientId: z.string(),
  ean: z.string(),
  price: z.number(),
  calculatedPrice: z.number().nullish(),
  active: z.boolean(),
  productMeta: jsonSchema,
  meta: jsonSchema,
  createdAt: z.date(),
})

export interface CompleteDBCustomPriceCatalog extends z.infer<typeof DBCustomPriceCatalogModel> {
  client: CompleteDBClient
}

/**
 * RelatedDBCustomPriceCatalogModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBCustomPriceCatalogModel: z.ZodSchema<CompleteDBCustomPriceCatalog> = z.lazy(() => DBCustomPriceCatalogModel.extend({
  client: RelatedDBClientModel,
}))
