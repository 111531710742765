import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';

export function calculateSuperiorPackageQuantity(
  input: RawImport,
  _rule: TRule
) {
  let packageQuantity = 1;
  if (
    parseFloat(input['Rim_Size_1']) >= 13 &&
    parseFloat(input['Rim_Size_1']) <= 17
  ) {
    packageQuantity = 2;
  }
  if (
    parseFloat(input['Width']) >= 8 ||
    (input['Design'] &&
      (input['Design'].toLowerCase().includes('transporter') ||
        input['Design'].toLowerCase().includes('titan')))
  ) {
    packageQuantity = 1;
  }

  return packageQuantity;
}

export function calculateSuperiorPrice(input: RawImport, _rule: TRule) {
  const price =
    input['Accessories'] === 'Serie' || parseFloat(input['Netto']) === 0
      ? parseFloat(input['Netto'])
      : parseFloat(input['Netto']) + 1.95;

  return price;
}
