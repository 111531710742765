import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetPaymentMethods = (
  search?: string,
  page?: number,
  pageSize?: number
) => {
  const { data, isFetching, isError } = useQuery(
    ['get_payment_method', { search, page, pageSize }],
    () =>
      api.fetch<DBPaymentTypesApi['list']>('get_payment_method', {
        search,
        page,
        pageSize,
      }),

    {}
  );

  return { paymentData: data?.data, count: data?.count, isFetching, isError };
};
