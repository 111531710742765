import { TRule } from '../../typings';

export const INTERPNEU_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: 'c.ean',
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.manufacturerCode',
    destination: 'product',
    targetKey: 'supplierCode',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.brand',
  //   destination: 'product',
  //   targetKey: 'brand',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.price',
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
