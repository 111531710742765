import { DBProductApi } from '@tyrio/dto';
import styled from '@emotion/styled';
import ProductEprelGenerator from './ProductEprelGenerator';
import { forwardRef, Ref } from 'react';

interface EprelToPrintProps {
  product: DBProductApi['getOne']['response'];
}

const EprelToPrint = forwardRef(
  ({ product }: EprelToPrintProps, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <Container>
          <Wrapper>
            <Item>
              <ProductEprelGenerator product={product} />
            </Item>
            <Item>
              <ProductEprelGenerator product={product} />
            </Item>
          </Wrapper>
          <Wrapper>
            <Item>
              <ProductEprelGenerator product={product} />
            </Item>
            <Item>
              <ProductEprelGenerator product={product} />
            </Item>
          </Wrapper>
        </Container>
      </div>
    );
  }
);

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10mm;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Item = styled.div`
  width: 100%;
  padding: 10mm;
  transform: scale(1.17);
  margin-bottom: 8mm;
`;

export default EprelToPrint;
