import * as z from "zod"
import { CompleteDBClientStockList, RelatedDBClientStockListModel, CompleteDBWarehouseShelfs, RelatedDBWarehouseShelfsModel, CompleteDBWarehouseBins, RelatedDBWarehouseBinsModel, CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBStockListItemLocationModel = z.object({
  id: z.string(),
  clientStockListId: z.string(),
  shelfId: z.string().nullish(),
  binId: z.string().nullish(),
  warehouseAreaId: z.string(),
  ean: z.string(),
  ipc: z.string(),
  sku: z.string(),
  productionYear: z.string(),
  dot: z.string(),
  locationMeta: jsonSchema,
  productDimensions: jsonSchema,
  quantity: z.number().int(),
  reserved: z.number().int(),
  putaway: z.number().int(),
})

export interface CompleteDBStockListItemLocation extends z.infer<typeof DBStockListItemLocationModel> {
  clientStockList: CompleteDBClientStockList
  shelf?: CompleteDBWarehouseShelfs | null
  bin?: CompleteDBWarehouseBins | null
  warehouseArea: CompleteDBBranchWarehouseAreas
}

/**
 * RelatedDBStockListItemLocationModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBStockListItemLocationModel: z.ZodSchema<CompleteDBStockListItemLocation> = z.lazy(() => DBStockListItemLocationModel.extend({
  clientStockList: RelatedDBClientStockListModel,
  shelf: RelatedDBWarehouseShelfsModel.nullish(),
  bin: RelatedDBWarehouseBinsModel.nullish(),
  warehouseArea: RelatedDBBranchWarehouseAreasModel,
}))
