import * as z from "zod"
import { CompleteDBPartner, RelatedDBPartnerModel } from "./index"

export const DBPartnerLocationModel = z.object({
  id: z.string(),
  partnerId: z.string().nullish(),
  locationName: z.string(),
  invoiceAddress: z.boolean(),
  shippingAddress: z.boolean(),
  zipCode: z.string(),
  city: z.string(),
  address: z.string(),
  email: z.string().nullish(),
  businessPhone: z.string().nullish(),
  erpId: z.string().nullish(),
})

export interface CompleteDBPartnerLocation extends z.infer<typeof DBPartnerLocationModel> {
  partner?: CompleteDBPartner | null
}

/**
 * RelatedDBPartnerLocationModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBPartnerLocationModel: z.ZodSchema<CompleteDBPartnerLocation> = z.lazy(() => DBPartnerLocationModel.extend({
  partner: RelatedDBPartnerModel.nullish(),
}))
