import React from 'react';
import styled from '@emotion/styled';

interface Props {
  price: number | null;
  quantity: number | null;
  isFulfilled?: boolean;
  requestedQuantity?: number;
}

export const RenderTotalFooterCell = ({
  price,
  quantity,
  isFulfilled,
  requestedQuantity,
}: Props) => {
  const safePrice = price || 0;
  const qtyDisplay = [quantity, requestedQuantity]
    .filter((x) => typeof x !== 'undefined')
    .join(' / ');

  return (
    <Wrapper>
      <Price>
        {safePrice.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </Price>

      <Qty
        fulfilled={typeof isFulfilled !== 'undefined' ? !!isFulfilled : true}
      >
        {qtyDisplay} pcs
      </Qty>
    </Wrapper>
  );
};

const Price = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 2px;
`;

const Qty = styled.div<{ fulfilled: boolean }>`
  font-size: 16px;
  text-align: center;
  padding: 2px 6px;
  font-weight: 500;
  border-radius: 6px;
  ${(props) =>
    props.fulfilled
      ? `
    color: #3CB44B;
    background: #E0F4EA;
  `
      : `
    background: #f77b7b;
    `}
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
