import api from '@tyrio/api-factory';
import { DBBranchStockListApi, DBBranchStockListResponse } from '@tyrio/dto';
import { useQuery } from 'react-query';
import { IAvailable } from '../../../../../context/CartContext';
import { useContext } from 'react';
import { StockListContext } from '../../../../../context/StockListContext';

// FILLS ARRAY WITH AVAILABLE QUANTITIES FOR EACH ITEM
export const getAvailableCartValues = (
  key: string,
  available: number,
  reserved: number,
  availableItems: Record<string, IAvailable[]>,
  type: 'inbound' | 'outbound',
  warehouseId: string,
  setAvailableItems: (a: Record<string, IAvailable[]>) => void
) => {
  let newObj = availableItems;
  const obj = {
    id: key,
    maxAvailable: available - reserved,
    type: type,
  };

  if (availableItems[warehouseId] !== undefined) {
    const findItem = availableItems[warehouseId].find((a) => a.id === key);
    if (findItem === undefined) {
      availableItems[warehouseId].push({
        ...obj,
      });
    } else {
      findItem.maxAvailable = obj.maxAvailable;
    }
  } else {
    newObj = {
      ...newObj,
      [warehouseId]: [
        {
          ...obj,
        },
      ],
    };
  }
  setAvailableItems({ ...newObj });
};

const helper = (
  res: DBBranchStockListResponse[],
  availableItems: Record<string, IAvailable[]>,
  warehouseId: string,
  setAvailableItems: (a: Record<string, IAvailable[]>) => void,
  type: 'inbound' | 'outbound'
) => {
  res?.forEach((singleItem) => {
    const branchId = singleItem.branchId;
    singleItem.lineItems.forEach((item) => {
      const key = item.ean + '_' + item.dot + '_' + branchId;
      getAvailableCartValues(
        key,
        item.quantity,
        item.reserved,
        availableItems,
        type,
        warehouseId,
        setAvailableItems
      );
    });
  });
};

export const useGetBranchesStock = (
  ean: string,
  warehouseId: string,
  availableItems: Record<string, IAvailable[]>,
  setAvailableItems: (a: Record<string, IAvailable[]>) => void
) => {
  const { input } = useContext(StockListContext);
  const newEan = ean === '' ? input?.selectedTableRow?.ean : ean;

  const { data, refetch, isSuccess, isLoading } = useQuery(
    [`branches_stock_list_outbound_${warehouseId}_${ean}`],
    () =>
      api.fetch<DBBranchStockListApi['list']>('branches_stock_list_outbound', {
        ean: newEan,
        warehouseId: warehouseId,
      }),
    {
      enabled: !!ean,
      onSuccess: (res) => {
        // FILL ARRAY WITH MAX AVAILABLE QTY VALUES FOR CART
        helper(res, availableItems, warehouseId, setAvailableItems, 'inbound');
      },
    }
  );

  return {
    branchesStock: data,
    refetchBranchesStock: refetch,
    isSuccess,
    isLoading,
  };
};

export const useGetMainBranchesStock = (
  ean: string,
  warehouseId: string,
  availableItems: Record<string, IAvailable[]>,
  setAvailableItems: (a: Record<string, IAvailable[]>) => void
) => {
  const { input } = useContext(StockListContext);
  const newEan = ean === '' ? input.selectedTableRow?.ean : ean;

  const {
    data,
    refetch,
    isSuccess: mainBranchSuccess,
    isLoading: mainBranchLoading,
  } = useQuery(
    [`main_branches_stock_list_${warehouseId}_${ean}`],
    () =>
      api.fetch<DBBranchStockListApi['list']>('main_branches_stock_list', {
        ean: newEan,
        warehouseId: warehouseId,
      }),
    {
      enabled: !!ean,
      onSuccess: (res) => {
        // FILL ARRAY WITH MAX AVAILABLE QTY VALUES FOR CART
        helper(res, availableItems, warehouseId, setAvailableItems, 'outbound');
      },
    }
  );

  // if (warehouseId === '' || warehouseId === undefined)
  //   return {
  //     mainBranchStock: [],
  //     refetchMainBranchesStock: refetch,
  //     mainBranchSuccess: false,
  //     mainBranchLoading,
  //   };

  return {
    mainBranchStock: data,
    refetchMainBranchesStock: refetch,
    mainBranchSuccess,
    mainBranchLoading,
  };
};
