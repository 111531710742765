import styled from '@emotion/styled';
import { noData } from '@tyrio/ui-library';

export const NoDataFound = () => {
  return (
    <Wrapper>
      <img src={noData} alt="no data" style={{ width: 100, height: 100 }}></img>
      <p>No data found</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
`;

export default NoDataFound;
