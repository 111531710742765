import api from '@tyrio/api-factory';
import { DBCountryApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import { useQuery } from 'react-query';

export const useGetCountries = (
  setCountries?: (a: TyrioSelectInputOption[]) => void,
  countries?: TyrioSelectInputOption[]
) => {
  const { data: countryList } = useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
            name: item.name,
            code: item.iso_code,
          })
        );

        setCountries && setCountries(dropdownItems);
      },
    }
  );

  const findCountryCode = (selectedCountryId: string) => {
    const findCountry = countries?.find((f) => f.value === selectedCountryId);
    if (findCountry !== undefined)
      return findCountry?.code?.toLocaleLowerCase();
    else return 'hr';
  };

  return { countryList, findCountryCode };
};
