import * as z from "zod"
import { CompleteDBSupplierConnection, RelatedDBSupplierConnectionModel } from "./index"

export const DBEmailConnectionModel = z.object({
  id: z.string(),
  supplierEmail: z.string(),
  supplierConnectionId: z.string().nullish(),
  remark: z.string(),
})

export interface CompleteDBEmailConnection extends z.infer<typeof DBEmailConnectionModel> {
  supplierConnection?: CompleteDBSupplierConnection | null
}

/**
 * RelatedDBEmailConnectionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBEmailConnectionModel: z.ZodSchema<CompleteDBEmailConnection> = z.lazy(() => DBEmailConnectionModel.extend({
  supplierConnection: RelatedDBSupplierConnectionModel.nullish(),
}))
