/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */

import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  InputOption,
  parseEnumToInputOption,
} from '../../../../../../helpers/enum-parser';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ComponentType } from '../../helper/enum';
import { DEFAULT_OBJECT, TRANSLATION_MAP } from '../../helper/constants';
import {
  ActionValues,
  ActionValuesPriceProps,
  RulesContext,
} from '../../context/RulesContext';
import { PopUp } from '../Dialog';

enum margins {
  noRound = '%',
  round = '€',
}

export const Actions = ({
  componentType,
  actionValues,
  index,
}: {
  componentType: ComponentType;
  actionValues: ActionValues;
  index: number;
}) => {
  const DEFAULT_FIELDS = DEFAULT_OBJECT[componentType];

  const marginOptions = parseEnumToInputOption(margins);
  const { rules, setRules, setDirty, error } = useContext(RulesContext);

  const [inputValues, setInputValues] = useState<ActionValues>(
    actionValues ? actionValues : [DEFAULT_FIELDS as any]
  );

  const [showPopUp, setShowPopUp] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  useEffect(() => {
    setInputValues(actionValues);
  }, [actionValues, index]);

  const addNewSetOfInputFields = () => {
    setInputValues([...inputValues, DEFAULT_FIELDS] as ActionValues);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    index: number
  ) => {
    const key =
      e.target.name || (e.target as HTMLInputElement | HTMLTextAreaElement).id;
    inputValues[index] = {
      ...inputValues[index],
      [key]: e.target.value,
    };
    setInputValues([...inputValues] as ActionValues);
    setDirty(true);
  };

  const handleChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const key = e.target.id;
    inputValues[index] = {
      ...inputValues[index],
      [key]: e.target.checked,
    };
    setInputValues([...inputValues] as ActionValues);
    setDirty(true);
  };

  const handleDeleteIcon = (index: number) => {
    setShowPopUp(true);
    setDeleteIndex(index);
  };

  const handleDelete = (index: number) => {
    inputValues.splice(index, 1);
    setInputValues([...inputValues] as ActionValues);
    setDirty(true);
  };

  useMemo(() => {
    rules[index] = {
      actions: inputValues,
      conditions: rules[index]?.conditions,
      name: rules[index]?.name,
      ruleDescription: rules[index]?.ruleDescription,
      id: rules[index]?.id,
      newConditions: rules[index]?.newConditions,
    };

    setRules((rules) => {
      return [...rules];
    });
  }, [inputValues, index]);

  useEffect(() => {
    if (deleteAction && deleteIndex !== null) handleDelete(deleteIndex);
  }, [deleteAction]);

  const getObjectValue = (objKey: string, values: any) => {
    return values[objKey];
  };

  const preventNegativeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isNaN(Number(value)) && Number(value) < 0) {
      e.target.value = '';
    }
  };

  return (
    <>
      {showPopUp && (
        <PopUp
          open={showPopUp}
          setDeleteAction={setDeleteAction}
          setShowPopUp={setShowPopUp}
          text="Are you sure you want to delete this action?"
        />
      )}
      <ActionsWrapper>
        <Typography
          fontSize={18}
          fontWeight={600}
          sx={{ marginBottom: '19px' }}
        >
          Actions
        </Typography>

        <RowWrapper>
          {inputValues.map((values, i) => {
            return (
              <FieldsWrapper>
                <InputWrappers>
                  {Object.entries(DEFAULT_FIELDS).map((o) => {
                    const key_id = o[0];
                    const value_v = getObjectValue(key_id, inputValues[i]);

                    // 'index' IS RULE INDEX AND 'i' IS ACTION INDEX
                    const showError = error[key_id + '_' + index + '_' + i];

                    return key_id === 'marginType' ||
                      key_id === 'useSuggestedRetailPrice' ? null : key_id !==
                      'margin' ? (
                      <TextField
                        value={value_v}
                        onChange={(e) => handleChange(e, i)}
                        id={key_id}
                        name={key_id}
                        label={TRANSLATION_MAP.get(key_id)}
                        variant="standard"
                        style={{ background: 'white', width: '20%' }}
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onInput: preventNegativeInput,
                          },
                        }}
                        error={showError !== undefined}
                        helperText={showError}
                      />
                    ) : (
                      <DropdownWrapper>
                        <TextField
                          id="margin"
                          key="marginInput"
                          label="Margin"
                          variant="standard"
                          value={(values as ActionValuesPriceProps).margin}
                          onChange={(e) => handleChange(e, i)}
                          style={{ width: '50%', background: 'white' }}
                          InputProps={{
                            style: {
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                            inputProps: {
                              min: 0,
                              onInput: preventNegativeInput,
                            },
                          }}
                          error={showError !== undefined}
                        />
                        <Select
                          id="marginType"
                          key="marginType"
                          name="marginType"
                          variant="standard"
                          defaultValue={
                            (values as ActionValuesPriceProps)?.marginType
                          }
                          onChange={(e) => handleChange(e, i)}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: 0,
                            width: '50%',
                            background: 'white',
                          }}
                          error={
                            error['marginType' + '_' + index + '_' + i] !==
                            undefined
                          }
                        >
                          {marginOptions.map((item: InputOption) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </DropdownWrapper>
                    );
                  })}
                </InputWrappers>

                {componentType === ComponentType.price_calculation && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="useSuggestedRetailPrice"
                          checked={values.useSuggestedRetailPrice}
                          color="info"
                          onChange={(e) => {
                            handleChecked(e, i);
                          }}
                        />
                      }
                      label="Use suggested retail price"
                      sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                    />
                  </FormGroup>
                )}

                <IconButton onClick={() => handleDeleteIcon(i)} color="error">
                  <DeleteIcon />
                </IconButton>
              </FieldsWrapper>
            );
          })}
        </RowWrapper>

        <ButtonWrapper>
          <Button
            variant="text"
            color="info"
            onClick={addNewSetOfInputFields}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </ButtonWrapper>
      </ActionsWrapper>
    </>
  );
};

export const ActionsWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background: ${tyrioUI.colors.black.B10};
  border: 1px solid #dfe3e8;
  border-radius: 16px;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputWrappers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 65%;
  align-items: flex-start;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  background: white;
  padding: 8px 16px 16px 16px;
  border-radius: 8px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  width: 20%;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
