import api from '@tyrio/api-factory';
import { ClientShippingCourierApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';

export const useUpdateShippingCouriers = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_client_shipping_courier'],
    (body: ClientShippingCourierApi['updateOne']['requestBody']) =>
      api.fetch<ClientShippingCourierApi['updateOne']>(
        'update_client_shipping_courier',
        {
          ...body,
        }
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_one_client_shipping_courier');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateShippingCouriers: mutate };
};
