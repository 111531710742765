import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { WHEELTRADE_CATALOG_RULESET } from './wheeltrade/catalog';
import { WHEELTRADE_PRICELIST_RULESET } from './wheeltrade/pricelist';
import { WHEELTRADE_STOCKLIST_RULESET } from './wheeltrade/stocklist';

export const WHEELTRADE_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: WHEELTRADE_CATALOG_RULESET,
  pricelistRuleset: WHEELTRADE_PRICELIST_RULESET,
  stockRuleset: WHEELTRADE_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'WHEELTRADE',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    width: {
      ',': '.',
      "''": '',
      '"': '',
      '”': '',
    },
    size: {
      "''": '',
      '"': '',
      '”': '',
    },
    center_bore: {
      ',': '.',
    },
    brand: {
      'JAPAN RACING': 'JR WHEELS',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  // TO DO:

  // const eanCount = countBy(inventoryFileRows, (r: WheeltradeInventoryFileRow) => r.ean === fileRow.ean)
  // if (eanCount.true > 1) {
  //   return false
  // }
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        !['Used', 'Showroom', 'Keys', 'Spacers'].includes(item.model) &&
        !!item.brand
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        !['Used', 'Showroom', 'Keys', 'Spacers'].includes(item.model) &&
        !!item.brand
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        ![
          'ANTI-THEFT',
          'CENTER-CAPS',
          'USED',
          'SHOWROOM',
          'KEYS',
          'SPACERS',
          'ADVERTISING',
          'BOLTS',
          'RINGS',
          'JUNIOR',
          'MEN',
          'CENTER CAPS',
          'NUTS',
          'WHEELS CLEANERS',
          'CERAMIC COATING',
          'AIR VALVES',
          'TPMS',
          'COMPLETE SETS',
        ].some((name) =>
          item.model.toUpperCase().includes(name.toUpperCase())
        ) &&
        !!item.brand &&
        !['SET'].some((name) =>
          item.part_number.toUpperCase().includes(name.toUpperCase())
        )
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
