import {
  DBClientPriceCalculation,
  DBMargin,
  DBPriceRound,
} from '@prisma/client';

export const clientPriceCalculation: DBClientPriceCalculation[] = [
  {
    id: '00000000-0000-0000-0000-000000000001',
    clientId: '00000000-0000-1000-b000-0000000000022',
    supplierId: '07c91a78-e661-47df-800e-f197ea7b7022',
    margin: 30,
    marginType: DBMargin.EUR,
    priceRound: DBPriceRound.ROUND,
    useSuggestedRetailPrice: true,
    rules: [],
    isActive: true,
    createdAt: new Date(),
    name: 'Vanderban Price Calculation',
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    clientId: '00000000-0000-1000-b000-0000000000022',
    supplierId: '33b2ab3a-f07f-426d-a637-da5647ddfc5e',
    margin: 50,
    marginType: DBMargin.PERCENTAGE,
    priceRound: DBPriceRound.ROUND_DOWN,
    useSuggestedRetailPrice: true,
    rules: [],
    isActive: true,
    createdAt: new Date(),
    name: 'Continental BIH Price Calculation',
  },
];
