import { Chip } from '@mui/material';

export const YearChip = ({
  year,
  color,
  customColor,
  variant = 'outlined',
}: {
  year: number | string;
  color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  variant?: 'outlined' | 'filled';
  customColor?: string;
}) => {
  return (
    <Chip
      label={year}
      variant={variant}
      color={color}
      size="small"
      style={{
        fontFamily: 'Roboto',
        fontWeight: 400,
        background: customColor,
        color: customColor && 'white',
      }}
    />
  );
};
