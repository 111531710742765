import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';

export const handleValidate = (
  errors: {
    [x: string]: boolean;
  },
  register: UseFormRegister<FieldValues>,
  key: string,
  validationRules?: RegisterOptions,
  validationText?: string
) => {
  return {
    errorKey: key,
    errors: errors,
    register: register,
    validationText: validationText ? validationText : ERROR_MESSAGES.REQUIRED,
    validationRules: validationRules ? validationRules : ERROR_OBJECTS.REQUIRED,
  };
};

export const ERROR_MESSAGES = {
  REQUIRED: 'Field is required!',
  MIN_LENGTH: 'Must be greater than 0',
};

export const ERROR_OBJECTS = {
  REQUIRED: {
    required: true,
  },
  MIN_LENGTH: {
    min: 1,
  },
};
