import styled from '@emotion/styled';

interface BorderedBoxProps {
  children: JSX.Element;
}

export const BorderedBox = (props: BorderedBoxProps) => {
  const { children } = props;
  return <Container>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  border: 1px dashed #dfe3e8;
  padding: 16px;
  border-radius: 8px;
`;
