import api from '@tyrio/api-factory';
import { DBWorkOrdersApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';

import { useMutation } from 'react-query';

export const useUpdateWorkOrderAfterInvoice = () => {
  const mutation = useMutation(
    ['update_after_inbound_invoices_many'],
    async (body: DBWorkOrdersApi['updateAfterInvoiceMany']['requestBody']) => {
      const response = await api.fetch<
        DBWorkOrdersApi['updateAfterInvoiceMany']
      >('update_after_inbound_invoices_many', {
        ...body,
      });

      return response;
    },
    {
      onError: () => {
        ToastHelper.showToast(
          'Update work orders',
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  return { ...mutation };
};
