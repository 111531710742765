import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Tooltip } from '@mui/material';
import { DBStockList, DBTransferOrderItemResponse } from '@tyrio/dto';
import { documentIcon } from '@tyrio/ui-library';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useWS } from '../../../../context/WSContext';
import noDocument from '../../assets/no-document.png';
import { findReservedValue } from '../Cart/helper/socket-helper';
import CartReservedItems from './CartReservedItems';
import {
  DocumentDetailsWrapper,
  DocumentNameWrapper,
  DocumentReferenceWrapper,
  FileIconNameWrapper,
  IconButtonStyled,
  IconsWrapper,
  ReservedNumberWrapper,
  ReservedTextWrapper,
  ReservedWrapper,
} from './ItemReservationInfo.style';

interface TransferOrderDocumentsProps {
  reservedData: DBTransferOrderItemResponse[];
  selectedStockListItem: DBStockList;
}

interface LineItem {
  ean: string;
  sku: string;
  quantity: number;
  productName: string;
  productionYear: number;
  manufacturerCode: number;
}

const TransferOrderDocuments = ({
  reservedData,
  selectedStockListItem,
}: TransferOrderDocumentsProps) => {
  const [hoverState, setHoverState] = useState<Record<number, boolean>>({
    0: false,
  });

  const getReservedQuantityByOrder = (item: DBTransferOrderItemResponse) => {
    if (item.lineItems) {
      const selectedItem = (item.lineItems as unknown as LineItem[]).filter(
        (item) => item.ean === selectedStockListItem?.ean
      );
      return selectedItem[0].quantity;
    }

    return 0;
  };

  const { user } = useAuth();

  // TODO (stock-list): FIX!!
  const { userKeys } = findReservedValue(
    [
      selectedStockListItem.ean,
      selectedStockListItem.dot,
      selectedStockListItem.branchId,
    ].join('_'),
    useWS(),
    user?.id
  );

  const handleHoverState = (state: string, index: number) => {
    if (!isEmpty(userKeys)) {
      if (state === 'enter') {
        hoverState[index] = true;
        setHoverState({ ...hoverState });
      } else {
        hoverState[index] = false;
        setHoverState({ ...hoverState });
      }
    }
  };

  return (
    <>
      {reservedData.map((item, index) => {
        return !hoverState[index] ? (
          <DocumentDetailsWrapper
            key={item.id}
            onMouseEnter={() => handleHoverState('enter', index)}
            onMouseLeave={() => handleHoverState('leave', index)}
          >
            <ReservedWrapper>
              <ReservedTextWrapper>Reserved</ReservedTextWrapper>
              <ReservedNumberWrapper>
                {getReservedQuantityByOrder(item)}
              </ReservedNumberWrapper>
            </ReservedWrapper>

            {item.DBOrderDocument && item.DBOrderDocument.length > 0 ? (
              item.DBOrderDocument.map((el) => (
                <>
                  <FileIconNameWrapper>
                    <img src={documentIcon} alt="file-icon" />

                    <ReservedWrapper
                      style={{ alignItems: 'flex-start', gap: '5px' }}
                    >
                      <DocumentNameWrapper>Order</DocumentNameWrapper>
                      <DocumentReferenceWrapper>
                        {item.orderNumber}
                      </DocumentReferenceWrapper>
                    </ReservedWrapper>
                  </FileIconNameWrapper>

                  <IconsWrapper>
                    <a
                      href={el.url}
                      target="_blank"
                      rel="noreferrer"
                      download
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <IconButtonStyled color="info" id="icon_button">
                        <ArrowForwardOutlinedIcon sx={{ color: '#919eab' }} />
                      </IconButtonStyled>
                    </a>
                  </IconsWrapper>
                </>
              ))
            ) : (
              <Tooltip
                title={'No document uploaded'}
                children={
                  <FileIconNameWrapper>
                    <img
                      src={noDocument}
                      alt="no document"
                      style={{ width: '20px', height: '21px' }}
                    />
                    <ReservedWrapper
                      style={{ alignItems: 'flex-start', gap: '5px' }}
                    >
                      <DocumentNameWrapper>Order</DocumentNameWrapper>
                      <DocumentReferenceWrapper>
                        {item.orderNumber}
                      </DocumentReferenceWrapper>
                    </ReservedWrapper>
                  </FileIconNameWrapper>
                }
              />
            )}
          </DocumentDetailsWrapper>
        ) : (
          <CartReservedItems
            reserved={getReservedQuantityByOrder(item)}
            handleHoverState={handleHoverState}
            index={index}
            userIds={userKeys as string[]}
          />
        );
      })}
    </>
  );
};

export default TransferOrderDocuments;
