import api from '@tyrio/api-factory';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SideMenuLayout } from '../../../components/SideMenuLayout/SideMenuLayout';
import { Footer } from '../components/SearchPOS/Footer';
import {
  PosSearchActiveTab,
  PosSearchContext,
} from '../components/SearchPOS/SearchContext';
import { SelectStepper } from './Search/SelectStepper';
import { SearchVehicleFooter } from '../components/SearchVehiclePOS/Footer';

export interface TransformedCategoryShape {
  mainCategories: { id: number; name: string }[];
  withSubcategories: Record<string, { id: number; name: string }[]>;
}

const SearchContent = () => {
  const { data: listOfDimensions } = useQuery(['get_list_of_dimensions'], () =>
    api.fetch<{
      requestBody: never;
      request: never;
      response: Record<
        string,
        Record<string, Record<string, (string | null)[]>>
      >;
    }>(`get_list_of_dimensions`)
  );

  return <SelectStepper listOfDimensions={listOfDimensions} />;
};

export const Search = () => {
  const { category, activeTab } = useContext(PosSearchContext);

  return (
    <SideMenuLayout
      type="posSearch"
      hideTitle={!!category}
      children={<SearchContent />}
      shouldShowTitle={false}
      showSwitch={false}
      checked={false}
      footer={
        activeTab === PosSearchActiveTab.default_search ? (
          <Footer />
        ) : (
          <SearchVehicleFooter />
        )
      }
    />
  );
};
