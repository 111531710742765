import { AppConfig } from './config.app';

export const defaultEnv: AppConfig = {
  production: false,
  apiUrl: 'http://localhost:3333/api',
  wsUrl: 'ws://localhost:3333',
  appUrl: 'http://localhost:4200',
  tinyMCEApiKey: '54u9rax2tboahret04mq704ah85i4vcreaug9xdrorno9nu6',
  mapboxApiKey: 'NEDIMOVSKI',
  firebase: {
    apiKey: 'AIzaSyDpc67TS4RJP9JVXDB0kC7KESGp9mmrUPI',
    authDomain: 'tyrio-b4941.firebaseapp.com',
    projectId: 'tyrio-b4941',
    storageBucket: 'tyrio-b4941.appspot.com',
    senderId: '89875125925',
    appId: '1:89875125925:web:fcef1b8b0e3b084b07df4c',
    measurementId: 'G-JXD5JC0FSK',
  },
  mapBox:
    'pk.eyJ1IjoiZHlzZmEiLCJhIjoiY2xiOGpweXRnMGxtaDNycmV0NGh5dmkwZSJ9.I4-M2iPOHY1e1NV1gfEz3g',
};
