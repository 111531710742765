import { useMutation } from 'react-query';
import api from '@tyrio/api-factory';
import { DBEdiOrderApi, DBEdiOrderInquiryBody } from '@tyrio/dto';

export const useInquiryMutation = (
  supplierId: string,
  branchId?: number,
  preferredDeliveryDate?: string,
  transportPriority?: string
) => {
  return useMutation(
    [
      'inquiry_eans',
      supplierId,
      branchId,
      preferredDeliveryDate,
      transportPriority,
    ],
    ({
      connection,
      supplierId,
      items,
      branchId,
      supplierClientSettingsId,
      preferredDeliveryDate,
      transportPriority,
    }: Omit<DBEdiOrderInquiryBody, 'adminConnectionId'>) =>
      api.fetch<DBEdiOrderApi['inquire']>('inquire_eans', {
        adminConnectionId: connection.adminConnectionId,
        branchId,
        supplierClientSettingsId,
        connection,
        supplierId,
        items,
        preferredDeliveryDate,
        transportPriority,
      })
  );
};
