/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { DBTransferOrderType } from '@prisma/client';
import { QuantityComponent, WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import { isEmpty, isNaN, isNull } from 'lodash';
import { useContext } from 'react';
import { CartContext } from '../../../../context/CartContext';
import { StockListContext } from '../../../../context/StockListContext';
import { useWS } from '../../../../context/WSContext';
import { SingleCartItemProps } from './helper/interfaces';
import { getReservedAndUpcomingValues } from './helper/socket-helper';

enum itemTypes {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export const SingleCartItem = ({
  item,
  handleDelete,
  branchId,
  branchName,
  branchCity,
  type,
  warehouseId,
}: SingleCartItemProps) => {
  const ws = useWS();
  const { outboundOrderItems } = useContext(StockListContext);
  const { availableItems, timer } = useContext(CartContext);

  const {
    productName,
    ean,
    ipc,
    sku,
    productionYear,
    quantity,
    uid,
    dot,
    stockListItemId,
  } = item;

  const emitInbound = (newQuantity: number) => {
    ws.socket?.emit('inbound-add-to-cart', {
      stockListItemId,
      branchId,
      branchName,
      branchCity,
      warehouseId: warehouseId,
      req: {
        quantity: newQuantity,
        ean,
        ipc,
        sku,
        dot,
        uid,
        productionYear,
        productName,
      },
    });
  };

  const emitOutbound = (newQuantity: number) => {
    // CHANGE QUANTITY ON MAIN BRANCH IN OUTBOUND ORDERS
    // leave until everything is tested
    // if (!isEmpty(outboundOrders)) {
    //   const singleLineItem = outboundOrders[0].lineItems.filter(
    //     (o: DBBranchStockLineItems) => o.ean === item.ean && o.dot === item.dot
    //   )[0];

    //   if (singleLineItem !== undefined) {
    //     singleLineItem.quantity =
    //       singleLineItem?.quantity + quantity - newQuantity;
    //   }
    // }

    // CHANGE QTY ON outboundOrderItems
    if (!isEmpty(outboundOrderItems)) {
      const n = outboundOrderItems.filter(
        (order) => order.branchId === branchId
      )[0];
      if (!isEmpty(n)) {
        n.lineItems.filter(
          (i: any) => i.ean === item.ean && i.dot === item.dot
        )[0].quantity = newQuantity;
      }
    }

    ws.socket?.emit('outbound-add-single-item-to-cart', {
      stockListItemId,
      branchId,
      branchName,
      branchCity,
      warehouseId: warehouseId,
      req: {
        quantity: newQuantity,
        ean,
        ipc,
        sku,
        dot,
        uid,
        productionYear,
        productName,
      },
    });
  };

  const changeCartItemQuantity = (newQuantity: number) => {
    if (quantity !== newQuantity && !isNull(quantity) && !isNaN(newQuantity)) {
      timer?.restart();

      if (type === DBTransferOrderType.INBOUND) emitInbound(newQuantity);
      else if (type === DBTransferOrderType.OUTBOUND) emitOutbound(newQuantity);
    }
  };

  const getAvbValue = () => {
    const itemType = itemTypes[type];
    const key = ean + '_' + dot;
    const { reserved } = getReservedAndUpcomingValues(
      ws?.state?.cart?.userCart,
      key,
      type === 'OUTBOUND' ? Number(warehouseId) : branchId
    );
    if (availableItems[warehouseId] !== undefined) {
      const b = availableItems[warehouseId].find(
        (a) => a.id.includes(key) && a.type === itemType
      );

      if (b) return b?.maxAvailable - reserved + item.quantity;
      else return item.quantity;
    } else return item.quantity;
  };

  return (
    <SingleUnitWrapper>
      <Wrapper>
        <Typography variant="body2">{productName}</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Typography fontSize={12} color="#919EAB">
            EAN: {ean}
          </Typography>
          <Typography fontSize={12} color="#919EAB">
            IPC: {ipc}
          </Typography>
          <Typography fontSize={12} color="#919EAB">
            SKU: {sku}
          </Typography>
          <Typography fontSize={12} color="#919EAB">
            DOT: {dot}
          </Typography>
        </div>
      </Wrapper>

      <QuantityWrapper>
        <QuantityComponent
          quantity={quantity}
          showButton={false}
          maxAvailableQuantity={getAvbValue()}
          cart={true}
          changeCartItemQuantity={changeCartItemQuantity}
        />
        <WmsIconStyled
          icon={wmsIcons.close}
          sx={{ color: '#B9C4CE', cursor: 'pointer' }}
          onClick={() =>
            handleDelete(
              item,
              branchId,
              branchName,
              type,
              branchCity,
              warehouseId
            )
          }
        />
      </QuantityWrapper>
    </SingleUnitWrapper>
  );
};

const SingleUnitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 0px 11px 4px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;
  gap: 8px;

  &:hover {
    background: #f9fafb;

    #quantity_component {
      background: white;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 170px);
`;

const QuantityWrapper = styled.div`
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const WmsIconStyled = styled(WmsIcon)`
  cursor: pointer;
  color: #b9c4ce;
  &:hover {
    color: #db1e1e;
  }
`;
