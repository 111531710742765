import api from '@tyrio/api-factory';
import { queryClient } from '../../../../../query-client';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

export const useOrderDetails = (orderId?: string) => {
  const orderStatusQuery = useMutation(
    ['order_status', orderId],
    () => api.fetch('edi_order_status', { orderId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('client_orders');
      },
    }
  );

  const handleRefetchOrderDetails = useCallback(() => {
    return orderStatusQuery.mutateAsync();
  }, [orderStatusQuery]);

  return {
    data: orderStatusQuery.data,
    handleRefetchOrderDetails,
    fetching: orderStatusQuery.isLoading,
  };
};
