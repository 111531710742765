/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import api, { ENDPOINTS } from '@tyrio/api-factory';
import { DBSupplierImportApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useMutation } from 'react-query';
import { getEndpoint, getFileData } from './download-file-data';
import {
  AddNewRepeaterFieldProps,
  AlertFieldProps,
  ConfirmModalProps,
  DeleteRepeaterFieldProps,
  ExpandCollapseButtonsProps,
  FileControlButtonsProps,
  MultiSelectInputProps,
  PasswordFieldProps,
  PhoneNumberInputProps,
  SelectListProps,
  TextFieldInputProps,
  UploadButtonProps,
} from './types';

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  return (
    <FormControl fullWidth>
      <PhoneInput
        {...props.validationProps?.register(
          props.validationProps.errorKey,
          props.validationProps.validationRules
        )}
        onChange={(value) => props.onChange(value)}
        specialLabel={props.label}
        inputStyle={{
          width: '100%',
          height: 56,
          borderRadius: 8,
          border: props.validationProps?.errors[props.validationProps?.errorKey]
            ? '0.5px solid #d32f2f'
            : '',
        }}
        containerStyle={{
          color: `${
            props.validationProps?.errors[props.validationProps?.errorKey]
              ? '#d32f2f'
              : 'rgba(0, 0, 0, 0.6)'
          }`,
        }}
        inputProps={{ autoComplete: 'off' }}
        country={'ba'}
        value={props.value}
      />
      {props.validationProps?.errors[props.validationProps?.errorKey] && (
        <FormHelperText sx={{ color: '#d32f2f;' }}>
          {props.validationProps.validationText
            ? props.validationProps.validationText
            : 'Field is required'}
        </FormHelperText>
      )}
      {!props.validationProps?.errors[props.validationProps.errorKey] &&
        props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export const SelectList = (props: SelectListProps) => {
  return (
    <FormControl fullWidth>
      <InputLabelStyle
        isError={
          props.validationProps?.errors[props.validationProps?.errorKey] ??
          false
        }
        color={
          props.validationProps?.errors[props.validationProps?.errorKey]
            ? 'error'
            : 'primary'
        }
      >
        {props.label}
      </InputLabelStyle>
      <Select
        {...props.validationProps?.register(
          props.validationProps.errorKey,
          props.validationProps.validationRules
        )}
        error={props.validationProps?.errors[props.validationProps?.errorKey]}
        value={props.value}
        label={props.label}
        inputProps={{ autoComplete: 'off' }}
        autoComplete="off"
        onChange={props.onChange}
        disabled={props.disabled}
        name={props.name}
      >
        {props.dropdownData?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {props.validationProps?.errors[props.validationProps?.errorKey] && (
        <FormHelperText sx={{ color: '#d32f2f;' }}>
          {props.validationProps.validationText
            ? props.validationProps.validationText
            : 'Field is required'}
        </FormHelperText>
      )}
      {!props.validationProps?.errors[props.validationProps.errorKey] &&
        props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export const TextFieldInput = (props: TextFieldInputProps) => {
  return (
    <TextField
      id={props.id}
      name={props.name}
      {...props.validationProps?.register(
        props.validationProps.errorKey,
        props.validationProps.validationRules
      )}
      error={props.validationProps?.errors[props.validationProps?.errorKey]}
      helperText={
        props.validationProps?.errors[props.validationProps.errorKey]
          ? props.validationProps.validationText
            ? props.validationProps.validationText
            : 'Field is required'
          : props.helperText
      }
      autoComplete="off"
      placeholder={props.placeholder}
      multiline={props.label === 'Remark' ? true : false}
      inputProps={{ spellCheck: 'false', ...(props.inputProps || {}) }}
      InputProps={{ autoComplete: 'off' }}
      sx={{ backgroundColor: 'white' }}
      fullWidth
      value={props.value}
      onChange={props.onChange}
      label={props.label}
      type={props.type ?? 'text'}
      disabled={props.disabled}
    />
  );
};

export const UploadCompanyLogoButton = (props: UploadButtonProps) => {
  return (
    <Button
      size="small"
      component="label"
      color="info"
      variant="outlined"
      endIcon={<AddIcon />}
    >
      {props.text}
      <UploadLogoInput
        hidden
        multiple
        type="file"
        accept=".png, .jpg, .jpeg .svg"
        onChange={props.onImageChange}
      />
    </Button>
  );
};

export const AddNewRepeaterFieldButton = (props: AddNewRepeaterFieldProps) => {
  return (
    <Button
      size="small"
      component="label"
      color="info"
      variant="outlined"
      endIcon={<AddIcon />}
      onClick={props.onAddNewRepeaterField}
    >
      {props.buttonText}
    </Button>
  );
};

export const DeleteRepeaterFieldButton = (props: DeleteRepeaterFieldProps) => {
  return (
    <Button
      variant="text"
      color="error"
      disabled={props.disabled}
      onClick={props.onDeleteRepeaterField}
    >
      DELETE
    </Button>
  );
};

export const AlertField = (props: AlertFieldProps) => {
  return <Alert severity={props.type}>{props.text}</Alert>;
};
export interface CatalogImportPayload {
  supplierShape: string;
  supplierId: string;
  connectionId: string;
}

export const FileControlButtons = (props: FileControlButtonsProps) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [, setCustomFileName] = useState('');
  const [updateTime, setUpdateTime] = useState('');

  const getFile = async (fileName: string) => {
    const file = await axios.get(`${ENDPOINTS.get_file.uri}/${fileName}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@@apiToken')}`,
      },
    });
    fileDownload(file.data, fileName);
  };

  const downloadFile = async (type?: string) => {
    const data = getFileData(props, type);
    downloadFileMutation.mutate(data);
    setIsAlertOpen(true);
  };

  const downloadFileMutation = useMutation(
    'download_file',
    (data: DBSupplierImportApi['create']['requestBody']) =>
      api.fetch<DBSupplierImportApi['create']>(getEndpoint(props), {
        ...data,
      }),
    {
      onSuccess: (response) => {
        getFile(response.fileName);
        setCustomFileName(response.fileName);
      },
    }
  );

  const importCatalogMutation = useMutation(
    ['import_catalog'],
    (payload: CatalogImportPayload) =>
      api.fetch<{
        request: CatalogImportPayload;
        requestBody: CatalogImportPayload;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response: any;
      }>('import_catalog', payload),
    {
      onSuccess: () => {
        setUpdateTime(moment().format('LLL'));
      },
    }
  );

  const initJobsMutation = useMutation(
    ['import_stock_price'],
    ({
      supplierId,
      connectionId,
    }: DBSupplierImportApi['import_stock']['request']) =>
      api.fetch<DBSupplierImportApi['import_stock']>('import_stock_price', {
        supplierId,
        connectionId,
      }),
    {
      onSuccess: () => {
        setUpdateTime(moment().format('LLL'));
      },
    }
  );

  if (props.connection.connectionTypes.includes('ORDER')) return null;

  return (
    <DownloadUpdateContent>
      <Grid container spacing={1} sx={{ paddingTop: 2 }}>
        <Grid item xs={5} sm={5} md={5} lg={3} xl={2}>
          <Button
            size="small"
            component="label"
            color="info"
            variant="outlined"
            disabled={downloadFileMutation.isLoading}
            startIcon={
              downloadFileMutation.isLoading ? (
                <CircularProgress sx={{ marginRight: '2px' }} size={16} />
              ) : (
                <CloudDownloadIcon />
              )
            }
            onClick={async () => {
              return downloadFile();
            }}
            style={{ width: '150px', height: '32px', marginRight: '10px' }}
          >
            DOWNLOAD FILE
          </Button>
        </Grid>

        {!!props.supplierId &&
          !!props.connection?.rulesetKey &&
          !!props.connection?.id && (
            <Grid item xs={5} sm={5} md={5} lg={3} xl={2}>
              <Button
                size="small"
                component="label"
                color="info"
                variant="outlined"
                disabled={importCatalogMutation.isLoading}
                startIcon={
                  importCatalogMutation.isLoading ? (
                    <CircularProgress sx={{ marginRight: '2px' }} size={16} />
                  ) : (
                    <ReplayIcon />
                  )
                }
                onClick={async () => {
                  if (props.connection.connectionTypes.includes('CATALOGUE')) {
                    importCatalogMutation.mutate({
                      supplierId: props.supplierId!,
                      supplierShape: props.connection.rulesetKey!,
                      connectionId: props.connection.id!,
                    });
                  } else if (props.connection?.id && props.supplierId) {
                    initJobsMutation.mutate({
                      supplierId: props.supplierId,
                      connectionId: props.connection.id,
                    });
                  }
                }}
                style={{ width: '130px', height: '32px', marginRight: '10px' }}
              >
                UPDATE NOW
              </Button>
            </Grid>
          )}
        {updateTime !== '' && (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={8}>
            <LastUpdateText>
              Last updated at: <StrongText>{updateTime}</StrongText>
            </LastUpdateText>
          </Grid>
        )}
        {isAlertOpen && downloadFileMutation.isError && (
          <Grid item xs={12}>
            <Alert
              severity="error"
              onClose={() => {
                setIsAlertOpen(false);
              }}
            >
              There is an error with file download.{' '}
              <StrongText>
                Please check that all required information is correct!
              </StrongText>
            </Alert>
          </Grid>
        )}

        {isAlertOpen && downloadFileMutation.isSuccess && (
          <Grid item xs={12}>
            <Alert
              severity="success"
              onClose={() => {
                setIsAlertOpen(false);
              }}
            >
              File is successfully downloaded.{' '}
              <StrongText>Please check your download folder!</StrongText>
            </Alert>
          </Grid>
        )}
      </Grid>
    </DownloadUpdateContent>
  );
};

export const ExpandCollapseButtons = (props: ExpandCollapseButtonsProps) => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '15px',
      }}
    >
      <Grid item>
        <Button
          variant="text"
          color={props.color}
          onClick={props.onExpandedDataChange}
          disabled={props.disabled}
        >
          {props.flag ? 'COLLAPSE ALL' : 'EXPAND ALL'}
        </Button>
      </Grid>
    </Grid>
  );
};

export const PasswordField = (props: PasswordFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <FormControl fullWidth variant="outlined" disabled={props.disabled}>
      <InputLabelStyle
        isError={
          props.validationProps?.errors[props.validationProps?.errorKey] ??
          false
        }
        disabled={props.disabled}
        color={
          props.validationProps?.errors[props.validationProps?.errorKey]
            ? 'error'
            : 'primary'
        }
      >
        {props.label}
      </InputLabelStyle>
      <OutlinedInput
        {...props.validationProps?.register(
          props.validationProps.errorKey,
          props.validationProps.validationRules
        )}
        inputProps={{ autoComplete: 'off' }}
        autoComplete="off"
        disabled={props.disabled}
        error={props.validationProps?.errors[props.validationProps?.errorKey]}
        label={props.label}
        onChange={props.onChange}
        type={isPasswordVisible ? 'text' : 'password'}
        value={props.value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible);
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {props.validationProps?.errors[props.validationProps?.errorKey] && (
        <FormHelperText sx={{ color: '#d32f2f;' }}>
          This field is required!
        </FormHelperText>
      )}
      {props.helperText &&
        !props.validationProps?.errors[props.validationProps?.errorKey] && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
    </FormControl>
  );
};

export const ConfirmModal = (props: ConfirmModalProps) => {
  return (
    <ModalWrapper>
      <ModalContentWrapper>
        <ModalContent>
          <IconWrapper>
            <ErrorOutlinedIcon color="warning" sx={{ fontSize: '70px' }} />
          </IconWrapper>
          <ModalTextWrapper>
            <ModalHeaderText>{props.headerText}</ModalHeaderText>
            <ModalContentText>
              This item will be deleted immediately. You can't undo this action.
            </ModalContentText>
          </ModalTextWrapper>
          <ButtonsWrapper>
            <Button
              color="info"
              variant="outlined"
              startIcon={<CancelIcon />}
              size="small"
              onClick={props.cancelAction}
            >
              CANCEL
            </Button>
            <Button
              color="error"
              variant="outlined"
              startIcon={<DeleteIcon />}
              size="small"
              onClick={props.confirmAction}
            >
              YES, DELETE
            </Button>
          </ButtonsWrapper>
        </ModalContent>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export const MultiSelectInput = (props: MultiSelectInputProps) => {
  const getCountryName = (id: string): TyrioSelectInputOption => {
    const a = props.dropdownData?.find((i) => i.value === id) ?? {
      value: '',
      label: '',
    };
    return a;
  };
  const [value, setValue] = useState<TyrioSelectInputOption>(
    getCountryName(props.value)
  );

  useEffect(() => {
    setValue(getCountryName(props.value) ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        options={props.dropdownData ?? []}
        autoComplete={false}
        value={value}
        getOptionLabel={(option) => option.label}
        onChange={(_e, x) => {
          x && props.onChange(x.value.toString());
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            {...props.validationProps?.register(
              props.validationProps.errorKey,
              props.validationProps.validationRules
            )}
            disabled={props.disabled}
            error={
              props.validationProps?.errors[props.validationProps?.errorKey]
            }
          />
        )}
      />
      {props.validationProps?.errors[props.validationProps?.errorKey] && (
        <FormHelperText sx={{ color: '#d32f2f;' }}>
          {props.validationProps.validationText
            ? props.validationProps.validationText
            : 'Field is required'}
        </FormHelperText>
      )}
      {!props.validationProps?.errors[props.validationProps.errorKey] &&
        props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

const StrongText = styled.strong``;

const DownloadUpdateContent = styled.div``;

const LastUpdateText = styled.span``;

const UploadLogoInput = styled.input``;

const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContentWrapper = styled.div`
  display: flex;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border-radius: 9px;
  width: 350px;
`;

const ModalContent = styled.div``;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

const ModalTextWrapper = styled.div`
  text-align: center;
`;

const ModalHeaderText = styled.b``;

const ModalContentText = styled.p``;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;
export const InputLabelStyle = styled(InputLabel)<{ isError: boolean }>`
  color: ${(props) => props.isError && '#d32f2f'};
`;
