import {
  DBAppointments,
  DBDeliveryTypes,
  DBWarehouseShelfs,
  DeliveryType,
  Prisma,
} from '@prisma/client';
import { VehicleShape } from './pos';
import { LocationMeta, ZoneStagingType } from './stock-locations';
import { ReservedOnLocation } from './transfer-order';

interface DestinationAddress {
  companyName?: string;
  name: string;
  address?: string;
  phoneNumber: string;
  zipCode?: string;
  city?: string;
  country?: string;
}

export interface StockOutResponse {
  id: string | null;
  orderId: string | number | null; //customerOrderId or transferOrderId
  lineItems: Prisma.JsonValue;
  reference: string | null; // this should be B2B-000001 for example
  remark: string | null;
  customer?: DestinationAddress | null;
  branchId?: number;
  orderType: 'TRANSFER_ORDER' | 'SALES_ORDER';
  deliveryDateFrom?: Date | null;
  deliveryDateTo: Date | null;
  deliveryType?: DBDeliveryTypes | null;
  customerOrderId?: string | null;
  customerOrderNumber?: string | null;
  destinationWarehouse?: DestinationAddress;
  appointments?: DBAppointments[];
  createdAt?: Date | null;
  erpDocNumber?: string | null;
  vehicleInfo?: VehicleShape;
  vehicleInfoCO?: VehicleShape;
  vehicleInfoAPP?: VehicleShape;
  hotel?: {
    code: string;
    qty: number;
  };
}

export type StockOutDeliveryType =
  | 'SHIPPING'
  | 'FITTING'
  | 'DISPATCH'
  | 'COLLECTION'
  | 'All';

export interface DBStockOutListRequest {
  deliveryType?: StockOutDeliveryType;
}

export interface OrdersLocationInWarehouseBody {
  items: { ean: string; dot: string }[];
  // example: {ean_dot: nrOfOrderedItems} , {"4019238034752_XX23":2}
  requiredQty: Record<string, number>;
}

export interface GetLocationsBody {
  orderId: string;
  orderType: 'TRANSFER_ORDER' | 'SALES_ORDER';
  orderReference?: string;
  customerOrderNumber?: string;
}

// **** example ****
// {
//   newLocation: 'SHELF-2-DA0-A1-0',
//   oldLocationData: { 'SHELF-2-EA0-A1-0': { '6924064104167': 2 } }
//   expectedLocationType:"COLLECTION",
//   order:{
//     id:'1', type:"TRANSFER_ORDER"
//   }
// }

interface SingleOrder {
  id: string;
  type: 'TRANSFER_ORDER' | 'SALES_ORDER';
}

interface LocationDetails {
  newLocation: string;
  oldLocationData: Record<string, Record<string, number>>;
  expectedLocationType: ZoneStagingType;
}

export type CreateStockOut = LocationDetails & { orders: SingleOrder[] };

export type CreateStockOutMany = LocationDetails & { order: SingleOrder[] };

export interface StockOutOrderDetails {
  ean: string;
  dot: string;
  reservedOnLocation?: ReservedOnLocation[];
}

export interface LocationLineItems {
  clientStockListId: string;
  ean: string;
  quantity: number;
  reserved: number;
  dot: string;
  ordered: number;
  putaway?: number;
}
export interface SingleLocationResponse {
  locationId: string;
  locationMeta: LocationMeta;
  lineItems?: LocationLineItems[];
  qtyMeta?: { [key: string]: number };
  locationQrCode: string;
}

export interface GetLocationsResponse {
  data: SingleLocationResponse[];
  locationsCount: number;
  itemsCount: number;
}

export const matchDeliveryType = {
  SHIPPING: DeliveryType.WMS_SHIPPING,
  FITTING: DeliveryType.WMS_FITTING,
  DISPATCH: DeliveryType.WMS_DISPATCH,
  COLLECTION: DeliveryType.WMS_COLLECTION,
};

export interface IModalData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lineItems: any;
  deliveryMethod: string;
  orderNumber: string;
  reference: string;
  deliveryType: string;
  branchName: string;
  issueDate?: Date;
  customer?: string;
  orderId: string;
}

export interface IShippingLineItem {
  title: string;
  subtitle: string;
  orderNr: string;
  quantity: number;
  orderDetails: IModalData[];
}

export interface ShippingResponse {
  openOrders: IShippingLineItem[];
  readyOrders: IShippingLineItem[];
}

export interface HotelLocationListItem {
  code: string;
  qrCode: string;
  qty: number;
}

export interface GetAllHotelLocationsSingleItem {
  locationMeta: DBWarehouseShelfs;
  listItems: HotelLocationListItem[];
  qtyMeta: { [key: string]: number };
}

export interface GetAllHotelLocationsResponse {
  data: GetAllHotelLocationsSingleItem[];
  locationsCount: number;
  itemsCount: number;
}

export interface MoveToFinalLocationReq {
  code: string;
  qty: number;
  newLocation: string;
  workOrderId?: string;
  customerOrderId?: string;
}

interface DBStockOutListApi {
  list: {
    requestBody: DBStockOutListRequest;
    requestParams: DBStockOutListRequest;
    request: DBStockOutListRequest;
    response: { data: (StockOutResponse | null)[]; count: number };
  };
  getOne: {
    requestBody: { orderId: string };
    requestParams: { orderId: string };
    request: { orderId: string };
    response: never;
  };
  create: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: { orderId: string };
    request: never | { orderId: string };
    response: never;
  };
  shippingOrders: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: ShippingResponse;
  };
}

export { DBStockOutListApi };
