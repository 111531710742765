import api from '@tyrio/api-factory';
import { DBStockListApi, PosApi, PosItem } from '@tyrio/dto';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import favoriteBasicIcon from '../../../assets/productDetailsNew/favorite-basic.svg';
import favoriteHoverIcon from '../../../assets/productDetailsNew/favorite-hover.svg';
import favoriteSelectedIcon from '../../../assets/productDetailsNew/favorite-selected.svg';

interface FavoriteProduct {
  stockListItemId: string;
  isFavoriteByUser: boolean;
  selectedTableRow?: PosItem;
  setSelectedTableRow?: (posItem: PosItem) => void;
}

const FavoriteProduct = ({
  stockListItemId,
  isFavoriteByUser,
  selectedTableRow,
}: FavoriteProduct) => {
  const [favorite, setFavorite] = useState(isFavoriteByUser);

  const [favoriteIcon, setFavoriteIcon] = useState(
    favorite ? favoriteSelectedIcon : favoriteBasicIcon
  );

  useEffect(() => {
    setFavorite(isFavoriteByUser);
    setFavoriteIcon(
      isFavoriteByUser ? favoriteSelectedIcon : favoriteBasicIcon
    );
  }, [isFavoriteByUser, stockListItemId]);

  const queryClient = useQueryClient();

  // move this to parent component
  const useFavoriteMutation = useMutation(
    () =>
      api.fetch<DBStockListApi['getOne']>('sl_change_favorite_status', {
        id: stockListItemId,
      }),
    {
      mutationKey: 'change_favorite_status',
      onSuccess: () => {
        setFavoriteIcon(favorite ? favoriteBasicIcon : favoriteSelectedIcon);
        setFavorite(!favorite);
        queryClient.invalidateQueries('get_stock_list_products');
        queryClient.invalidateQueries('favorited_items');
      },
    }
  );

  // move this to parent component
  const setPosFavorite = useMutation(
    () =>
      api.fetch<PosApi['addFavorite']>('add_pos_favorite', {
        ean: selectedTableRow?.product.ean ?? '',
      }),
    {
      onSuccess: () => {
        setFavoriteIcon(favorite ? favoriteBasicIcon : favoriteSelectedIcon);
        setFavorite(!favorite);
        queryClient.invalidateQueries('get_my_stock_pos_list');
        queryClient.invalidateQueries('get_branches_stock_pos_list');
        queryClient.invalidateQueries('get_supplier_stock_pos_list');
      },
    }
  );

  return (
    <img
      src={favoriteIcon}
      alt="favorite-icon"
      onClick={() => {
        selectedTableRow === undefined
          ? useFavoriteMutation.mutate()
          : setPosFavorite.mutate();
      }}
      onMouseEnter={() => setFavoriteIcon(favoriteHoverIcon)}
      onMouseLeave={() =>
        setFavoriteIcon(favorite ? favoriteSelectedIcon : favoriteBasicIcon)
      }
      width="32px"
      style={{ marginBottom: '10px' }}
    />
  );
};

export default FavoriteProduct;
