import * as z from "zod"
import { CompleteDBUser, RelatedDBUserModel, CompleteDBReservationsItems, RelatedDBReservationsItemsModel } from "./index"

export const DBReservationSessionModel = z.object({
  id: z.string(),
  createdAt: z.date(),
  createdById: z.string().nullish(),
  validUntil: z.date(),
  lastUpdateBy: z.string().nullish(),
})

export interface CompleteDBReservationSession extends z.infer<typeof DBReservationSessionModel> {
  createdBy?: CompleteDBUser | null
  reservationItems: CompleteDBReservationsItems[]
}

/**
 * RelatedDBReservationSessionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBReservationSessionModel: z.ZodSchema<CompleteDBReservationSession> = z.lazy(() => DBReservationSessionModel.extend({
  createdBy: RelatedDBUserModel.nullish(),
  reservationItems: RelatedDBReservationsItemsModel.array(),
}))
