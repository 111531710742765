import api from '@tyrio/api-factory';
import { DBStockListCronApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface ErrorProps {
  error: { name: string };
}
export const useUpdateFtpSettings = () => {
  const query = useMutation(
    (req: DBStockListCronApi['post_settings']['requestBody']) =>
      api.fetch<DBStockListCronApi['post_settings']>('update_ftp_settings', {
        ...req,
      }),
    {
      mutationKey: 'update_ftp_settings',
      onSuccess: () => {
        ToastHelper.showToast(
          'Successfully updated user settings',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE_SETTINGS
        );
      },

      onError: (e: AxiosError) => {
        const errorData = e as AxiosError<unknown>;

        ToastHelper.showToast(
          (errorData.response?.data as ErrorProps)?.error?.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  return { ...query };
};
