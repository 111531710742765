import styled from '@emotion/styled';
import backIcon from '../../../assets/vertical-list/back-icon.svg';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

interface HeaderProps {
  title: string;
  backButton?: () => void;
  addButton?: () => void;
  importButton?: () => void;
}

const Header = ({
  title,
  backButton,
  addButton,
  importButton,
}: HeaderProps) => {
  const history = useHistory();
  return (
    <Wrapper justifyBetween={addButton !== undefined}>
      {backButton && (
        <BackButton
          onClick={() => history.push('/dashboard')}
          data-cy="back-button"
        >
          <img src={backIcon} alt="back-icon" />
        </BackButton>
      )}
      {title}
      <Grid sx={{ display: 'flex', gap: '20px' }}>
        {importButton && (
          <Button
            title="Import"
            color="info"
            variant="text"
            onClick={importButton}
          >
            IMPORT
          </Button>
        )}
        {addButton && (
          <Button
            title="Add"
            color="info"
            variant="outlined"
            onClick={addButton}
          >
            ADD ITEM
          </Button>
        )}
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ justifyBetween?: boolean }>`
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfe3e8;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding: 16px;
  justify-content: ${(props) => (props.justifyBetween ? 'space-between' : '')};
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

export default Header;
