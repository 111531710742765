import { ArrayType } from './cart-customer-constants';

export const vehicleInputFields: ArrayType = [
  [
    {
      id: 'brand',
      placeholder: 'Brand',
      disabled: false,
      customWidth: '49%',
    },
    {
      id: 'model',
      placeholder: 'Model',
      disabled: false,
      customWidth: '49%',
    },
  ],
  [
    {
      id: 'year',
      placeholder: 'Year',
      disabled: false,
      customWidth: '49%',
    },
    {
      id: 'licence_plate',
      placeholder: 'Licence plate number',
      disabled: false,
      customWidth: '49%',
    },
  ],
  [
    {
      id: 'engine_displacement',
      placeholder: 'Engine displacement ccm',
      disabled: false,
      customWidth: '49%',
    },
    {
      id: 'engine_power',
      placeholder: 'Engine power kW',
      disabled: false,
      customWidth: '49%',
    },
  ],
  [
    {
      id: 'homologation_nr',
      placeholder: 'Homologation number',
      disabled: false,
      customWidth: '100%',
      helperText:
        '“D2“ found in the registration certificate, with all special characters.',
    },
  ],
  [
    {
      id: 'type_approval_number',
      placeholder: 'Type approval number',
      disabled: false,
      customWidth: '100%',
      helperText:
        '“K“ found in the registration certificate, with all special characters.',
    },
  ],
];
