import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { ATRAXION_PRICELIST_RULESET } from './atraxion/pricelist';
import { ATRAXION_STOCKLIST_RULESET } from './atraxion/stocklist';
import { TRule } from '../typings';

export const ATRAXION_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: ATRAXION_PRICELIST_RULESET,
  stockRuleset: ATRAXION_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'ATRAXION',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {},
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) => parseFloat(item['Demo']) == 0 && parseFloat(item['Dot']) == 0
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) => parseFloat(item['Demo']) == 0 && parseFloat(item['Dot']) == 0
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
