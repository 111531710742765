import styled from '@emotion/styled/macro';

interface MainInfoProps {
  branchName: string;
  issueDate?: Date;
  reference: string;
  issuePlace: string;
  dueDate: string;
  deliveryMethod?: string;
  showSecondRow?: boolean;
}

export const MainInfo = ({
  branchName,
  issueDate,
  reference,
  issuePlace,
  dueDate,
  deliveryMethod,
  showSecondRow = true,
}: MainInfoProps) => {
  const formatDate = (format: string) => {
    const date = new Date(format);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };

  return (
    <Wrapper>
      <JustifyBetween>
        <Info>
          <DetailType>Branch</DetailType>
          <DetailInfo>{branchName}</DetailInfo>
        </Info>
        {issueDate && (
          <Info>
            <DetailType>Issue date</DetailType>
            <DetailInfo>{formatDate(issueDate.toString())}</DetailInfo>
          </Info>
        )}
        <Info>
          <DetailType>Reference</DetailType>
          <DetailInfo>{`${reference}`}</DetailInfo>
        </Info>
      </JustifyBetween>
      {showSecondRow && (
        <JustifyBetween>
          <Info>
            <DetailType>Issue place</DetailType>
            <DetailInfo>{issuePlace}</DetailInfo>
          </Info>
          <Info>
            <DetailType>Due date</DetailType>
            <DetailInfo>{dueDate}</DetailInfo>
          </Info>
          <Info>
            <DetailType>Delivery method</DetailType>
            <DetailInfo>{deliveryMethod}</DetailInfo>
          </Info>
        </JustifyBetween>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ showSecondRow?: boolean }>`
  height: ${(props) => (props.showSecondRow ? '58px' : '40px')};
  border-top: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 230px;
`;

export const DetailType = styled.div`
  color: #000;
  font-family: Barlow;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  width: 90px;
  display: flex;
  justify-content: start;
`;

export const DetailInfo = styled.div`
  color: #000;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  width: 130px;
  display: flex;
  justify-content: start;
`;
