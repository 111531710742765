import * as z from "zod"
import { CompleteDBProductModel, RelatedDBProductModelModel, CompleteDBImport, RelatedDBImportModel, CompleteDBSupplierStock, RelatedDBSupplierStockModel, CompleteDBClientStockList, RelatedDBClientStockListModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBProductModel = z.object({
  uid: z.string(),
  ean: z.string(),
  tyrio_generated_ean: z.string().nullish(),
  manufacturerCode: z.string(),
  sku: z.string(),
  productModelUid: z.string(),
  productName: z.string(),
  productName2: z.string().nullish(),
  importKey: z.string(),
  deprecated: z.boolean(),
  rfid: z.string().nullish(),
  width: z.string().nullish(),
  width2: z.string().nullish(),
  height: z.string().nullish(),
  construction: z.string().nullish(),
  rimDiameter: z.string().nullish(),
  rimDiameter2: z.string().nullish(),
  size: z.string().nullish(),
  size2: z.string().nullish(),
  loadIndex: z.string().nullish(),
  speedIndex: z.string().nullish(),
  loadIndex2: z.string().nullish(),
  speedIndex2: z.string().nullish(),
  specialMarking: z.string().nullish(),
  specialMarkingType: z.string().nullish(),
  ms: z.boolean().nullish(),
  threepmfs: z.boolean().nullish(),
  iceGrip: z.boolean().nullish(),
  tubeType: z.string().nullish(),
  pr: z.string().nullish(),
  rfd: z.string().nullish(),
  structure: z.string().nullish(),
  compound: z.string().nullish(),
  commMark: z.boolean().nullish(),
  runFlat: z.boolean().nullish(),
  flank: z.boolean().nullish(),
  seal: z.boolean().nullish(),
  silent: z.boolean().nullish(),
  electric: z.boolean().nullish(),
  oem: z.boolean().nullish(),
  other: z.boolean().nullish(),
  demo: z.boolean().nullish(),
  euDirectiveNumber: z.string().nullish(),
  rollingResistance: z.string().nullish(),
  wetGrip: z.string().nullish(),
  noisePerformance: z.number().int().nullish(),
  noiseClassType: z.string().nullish(),
  vehicleClass: z.string().nullish(),
  eprelId: z.string().nullish(),
  eprelUrl: z.string().nullish(),
  holeNumber: z.string().array(),
  holeCircle: z.string().array(),
  pcd: z.string().array(),
  et: z.number().array(),
  cb: z.number().nullish(),
  installationKit: z.string().nullish(),
  vehicleBrand: z.string().nullish(),
  origin: z.string().nullish(),
  weight: z.string().nullish(),
  crossSellProducts: z.string().nullish(),
  crossSellServices: z.string().nullish(),
  relatedProducts: z.string().nullish(),
  remark: z.string().nullish(),
  dataSource: z.string().nullish(),
  created: z.date(),
  updated: z.date(),
  active: z.boolean(),
  weightMap: jsonSchema,
  productMeta: jsonSchema,
  dirtyFields: jsonSchema,
})

export interface CompleteDBProduct extends z.infer<typeof DBProductModel> {
  model: CompleteDBProductModel
  importObject: CompleteDBImport
  supplierStock: CompleteDBSupplierStock[]
  stockListItems: CompleteDBClientStockList[]
}

/**
 * RelatedDBProductModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBProductModel: z.ZodSchema<CompleteDBProduct> = z.lazy(() => DBProductModel.extend({
  model: RelatedDBProductModelModel,
  importObject: RelatedDBImportModel,
  supplierStock: RelatedDBSupplierStockModel.array(),
  stockListItems: RelatedDBClientStockListModel.array(),
}))
