/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Actions } from './Actions';
import Conditions from './Conditions';
import { IRules } from '../../context/RulesContext';
import { ComponentType } from '../../helper/enum';

export const ConditionsAndActions = ({
  rule,
  index,
  componentType,
}: {
  rule: IRules;
  index: number;
  componentType: ComponentType | string;
}) => {
  const { conditions, actions } = rule;

  return (
    <Wrapper>
      <Conditions conditions={conditions} index={index} />
      <Actions
        componentType={componentType as ComponentType}
        actionValues={actions}
        index={index}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
