import * as z from "zod"
import { DBCurrency } from ".prisma/client"
import { CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel } from "./index"

export const DBSupplierClientPaymentSettingsModel = z.object({
  id: z.string(),
  supplierClientSettingsId: z.string(),
  supplierIBAN: z.string(),
  supplierSwift: z.string(),
  supplierCurrency: z.nativeEnum(DBCurrency),
  remark: z.string(),
})

export interface CompleteDBSupplierClientPaymentSettings extends z.infer<typeof DBSupplierClientPaymentSettingsModel> {
  supplierClientSettings: CompleteDBSupplierClientSettings
}

/**
 * RelatedDBSupplierClientPaymentSettingsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierClientPaymentSettingsModel: z.ZodSchema<CompleteDBSupplierClientPaymentSettings> = z.lazy(() => DBSupplierClientPaymentSettingsModel.extend({
  supplierClientSettings: RelatedDBSupplierClientSettingsModel,
}))
