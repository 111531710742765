import { TRule } from '../../typings';

export const EDI_WHEEL_B4_RULESET: TRule[] = [
  // {
  //   type: 'rule.combine',
  //   sourceKeys: ['{{GERMANY}}'],
  //   destination: 'product',
  //   targetKey: 'origin',
  //   delimiter: '',
  // },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.VEHICLE_TYPE',
    condition: 'equals',
    conditionValues: [
      'C0',
      'CC',
      'CB',
      'L0',
      'LS',
      'L4',
      'F0',
      'TC',
      'TT',
      'TB',
      'T0',
      'E0',
      'CA',
    ],
    conditionOutputs: [
      '30',
      '30',
      '31',
      '32',
      '31',
      '31',
      '66',
      '50',
      '50',
      '51',
      '50',
      '52',
      '33',
    ],
    targetKey: 'category_id',
    outputType: 'forced_integer',
    destination: 'model',
  },

  // Season types
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '1',
    outputLogic: 'custom',
    outputValue: 'summer',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '4',
    outputLogic: 'custom',
    outputValue: 'summer',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '5',
    outputLogic: 'custom',
    outputValue: 'summer',
    destination: 'model',
    targetKey: 'season',
  },

  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '6',
    outputLogic: 'custom',
    outputValue: 'summer',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: 'D',
    outputLogic: 'custom',
    outputValue: 'summer',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: 'E',
    outputLogic: 'custom',
    outputValue: 'summer',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '2',
    outputLogic: 'custom',
    outputValue: 'winter',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '7',
    outputLogic: 'custom',
    outputValue: 'winter',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '8',
    outputLogic: 'custom',
    outputValue: 'winter',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '9',
    outputLogic: 'custom',
    outputValue: 'winter',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: '3',
    outputLogic: 'custom',
    outputValue: 'allseason',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: 'A',
    outputLogic: 'custom',
    outputValue: 'allseason',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: 'B',
    outputLogic: 'custom',
    outputValue: 'allseason',
    destination: 'model',
    targetKey: 'season',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'startsWith',
    conditionValue: 'C',
    outputLogic: 'custom',
    outputValue: 'allseason',
    destination: 'model',
    targetKey: 'season',
  },
  // Fix for spare tyre
  {
    type: 'rule.condition',
    sourceKey: 'c.PRODUCT_TYPE',
    condition: 'equals',
    conditionValue: '115',
    outputLogic: 'custom',
    outputValue: '34',
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },

  // Brand ima pod attribute, brand class i brand group.
  {
    type: 'rule.map',
    sourceKey: 'c.BRAND_TEXT',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.DESIGN_1',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.SIDEWALL',
    targetKey: 'sidewall',
    destination: 'model',
    outputType: 'string',
  },

  // PRODUCT
  {
    type: 'rule.map',
    sourceKey: 'c.EAN',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.SUPPLIER_CODE',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },

  {
    type: 'rule.template',
    targetKey: 'productName2',
    destination: 'product',
    template: '',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.OVL_DIAMETER',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.WIDTH_INCH',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.WIDTH_INCH',
    destination: 'product',
    targetKey: 'width',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.WIDTH_MM',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.WIDTH_MM',
    destination: 'product',
    targetKey: 'width',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.ASPECT_RATIO',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
    postProcess: ['convertToDecimalNumber', 'convertToIntegerString'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.CONSTRUCTION_2',
    targetKey: 'construction',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.CONSTRUCTION_1',
    targetKey: 'construction',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.RIM_INCH',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.combine',
    sourceKeys: [
      't.product.width',
      '{{/}}',
      't.product.height',
      '{{ }}',
      't.product.construction',
      '{{ }}',
      't.product.rimDiameter',
    ],
    targetKey: 'size',
    destination: 'product',
    delimiter: '',
  },
  {
    type: 'rule.combine',
    sourceKeys: ['c.LI1', 'c.LI3(DWB)'],
    targetKey: 'loadIndex',
    destination: 'product',
    delimiter: '/',
  },
  {
    type: 'rule.combine',
    sourceKeys: ['c.LI2', 'c.LI4(DWB)'],
    targetKey: 'loadIndex2',
    destination: 'product',
    delimiter: '/',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.SP1',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.SP2',
    targetKey: 'speedIndex2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.RFID',
    targetKey: 'rfid',
    destination: 'product',
    outputType: 'string',
  },
  // TODO: Should be number
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.M+S_MARK',
    condition: 'equals',
    conditionValues: ['Y', 'N'],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'ms',
    outputType: 'boolean',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.3PMSF_MARK',
    condition: 'equals',
    conditionValues: ['Y', 'N'],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.FLANK',
    condition: 'equals',
    conditionValues: ['Y', 'YES', 'N', 'NO'],
    conditionOutputs: [true, true, false, false],
    destination: 'product',
    targetKey: 'flank',
    outputType: 'boolean',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.TL/TT',
    condition: 'equals',
    conditionValues: ['Y', 'N'],
    conditionOutputs: ['TT', 'TL'],
    destination: 'product',
    targetKey: 'tubeType',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.PR',
    targetKey: 'pr',
    destination: 'product',
    outputType: 'number',
    postProcess: ['trimZeroes'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.RFD',
    targetKey: 'rfd',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.RFD',
    condition: 'equals',
    conditionValue: 'SL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: '',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.EU_DIRECTIVE_NUMBER',
    targetKey: 'euDirectiveNumber',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.ROLLING_RESISTANCE',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.WET_GRIP',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.NOISE_PERFORMANCE',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.NOISE_CLASS_TYPE',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.EC_VEHICLE_CLASS',
    targetKey: 'vehicleClass',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.URL_5',
    targetKey: 'eprelId',
    destination: 'product',
    postProcess: ['getEprelIdFromUrl'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.URL_5',
    targetKey: 'eprelUrl',
    destination: 'product',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.WEIGHT',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
    postProcess: ['convertToDecimalNumber'],
  },
];
