import { DBUserRole } from '@prisma/client';

export const roles = [
  {
    id: '00000000-0000-0000-0000-000000000001',
    roleName: 'Super Admin',
    type: DBUserRole.ADMIN,
    roleDescription:
      'Owner role cannot be edited or deleted. Unlimited access to all modules. ',
    active: true,
    permissions: [
      {
        id: 1,
        label: 'Admin Users',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 2,
        label: 'Suppliers',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 3,
        label: 'Clients',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 4,
        label: 'Shipping Couriers',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 5,
        label: 'Catalogues',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 51,
            label: 'Product Items',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 52,
            label: 'Product Categories',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 53,
            label: 'Product Attributes',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 6,
        label: 'Settings',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 61,
            label: 'Roles',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 62,
            label: 'Countries',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    roleName: 'Admin',
    roleDescription: 'Admin description',
    type: DBUserRole.ADMIN,
    active: true,
    permissions: [
      {
        id: 1,
        label: 'Admin Users',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 2,
        label: 'Suppliers',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 3,
        label: 'Clients',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 4,
        label: 'Shipping Couriers',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
      },
      {
        id: 5,
        label: 'Catalogues',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 51,
            label: 'Product Items',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 52,
            label: 'Product Categories',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 53,
            label: 'Product Attributes',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 6,
        label: 'Settings',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 61,
            label: 'Roles',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 62,
            label: 'Countries',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    roleName: 'Developer',
    roleDescription: 'Developer description',
    type: DBUserRole.ADMIN,
    active: true,
    permissions: [
      {
        id: 1,
        label: 'Admin Users',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 2,
        label: 'Suppliers',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 3,
        label: 'Clients',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 4,
        label: 'Shipping Couriers',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 5,
        label: 'Catalogues',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
        childs: [
          {
            id: 51,
            label: 'Product Items',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 52,
            label: 'Product Categories',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 53,
            label: 'Product Attributes',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
        ],
      },
      {
        id: 6,
        label: 'Settings',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
        childs: [
          {
            id: 61,
            label: 'Roles',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 62,
            label: 'Countries',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
        ],
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000004',
    roleName: 'Editor',
    roleDescription: 'Editor description',
    type: DBUserRole.ADMIN,
    active: true,
    permissions: [
      {
        id: 1,
        label: 'Admin Users',
        values: {
          view: true,
          create: false,
          edit: true,
          delete: false,
        },
      },
      {
        id: 2,
        label: 'Suppliers',
        values: {
          view: true,
          create: false,
          edit: true,
          delete: false,
        },
      },
      {
        id: 3,
        label: 'Clients',
        values: {
          view: true,
          create: false,
          edit: true,
          delete: false,
        },
      },
      {
        id: 4,
        label: 'Shipping Couriers',
        values: {
          view: true,
          create: false,
          edit: true,
          delete: false,
        },
      },
      {
        id: 5,
        label: 'Catalogues',
        values: {
          view: true,
          create: false,
          edit: true,
          delete: false,
        },
        childs: [
          {
            id: 51,
            label: 'Product Items',
            values: {
              view: true,
              create: false,
              edit: true,
              delete: false,
            },
          },
          {
            id: 52,
            label: 'Product Categories',
            values: {
              view: true,
              create: false,
              edit: true,
              delete: false,
            },
          },
          {
            id: 53,
            label: 'Product Attributes',
            values: {
              view: true,
              create: false,
              edit: true,
              delete: false,
            },
          },
        ],
      },
      {
        id: 6,
        label: 'Settings',
        values: {
          view: true,
          create: false,
          edit: true,
          delete: false,
        },
        childs: [
          {
            id: 61,
            label: 'Roles',
            values: {
              view: true,
              create: false,
              edit: true,
              delete: false,
            },
          },
          {
            id: 62,
            label: 'Countries',
            values: {
              view: true,
              create: false,
              edit: true,
              delete: false,
            },
          },
        ],
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000006',
    roleName: 'Super Admin',
    type: DBUserRole.CLIENT,
    roleDescription:
      'Owner role cannot be edited or deleted. Unlimited access to all modules. ',
    active: true,
    clientPredefined: true,
    permissions: [
      {
        id: 1,
        label: 'Purchasing',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 11,
            label: 'Price Compare',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 12,
            label: 'Purchase Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 13,
            label: 'Customer Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 14,
            label: 'Supplier Settings',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 2,
        label: 'Catalogues',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 21,
            label: 'Product Items',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 3,
        label: 'Settings',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 31,
            label: 'Roles',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 32,
            label: 'Users',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 33,
            label: 'Company Settings',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 4,
        label: 'WMS',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 41,
            label: 'Stock Locations',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 42,
            label: 'Stock List',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 43,
            label: 'Transfer Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 44,
            label: 'Stock IN',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 5,
        label: 'Sales',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 51,
            label: 'Customer Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 52,
            label: 'Price Calculation',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 53,
            label: 'Customer Rebates',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000007',
    roleName: 'Admin',
    roleDescription: 'Admin description',
    type: DBUserRole.CLIENT,
    active: true,
    clientPredefined: true,
    permissions: [
      {
        id: 1,
        label: 'Purchasing',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 11,
            label: 'Price Compare',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 12,
            label: 'Purchase Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 13,
            label: 'Customer Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 14,
            label: 'Supplier Settings',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 2,
        label: 'Catalogues',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 21,
            label: 'Product Items',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 3,
        label: 'Settings',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 31,
            label: 'Roles',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 32,
            label: 'Users',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 33,
            label: 'Company Settings',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 4,
        label: 'WMS',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 41,
            label: 'Stock Locations',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 42,
            label: 'Stock List',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 43,
            label: 'Transfer Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 44,
            label: 'Stock IN',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
      {
        id: 5,
        label: 'Sales',
        values: {
          view: true,
          create: true,
          edit: true,
          delete: true,
        },
        childs: [
          {
            id: 51,
            label: 'Customer Orders',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 52,
            label: 'Price Calculation',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
          {
            id: 53,
            label: 'Customer Rebates',
            values: {
              view: true,
              create: true,
              edit: true,
              delete: true,
            },
          },
        ],
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000008',
    roleName: 'Developer',
    roleDescription: 'Client developer',
    type: DBUserRole.CLIENT,
    active: true,
    clientPredefined: true,
    permissions: [
      {
        id: 1,
        label: 'Purchasing',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
        childs: [
          {
            id: 11,
            label: 'Price Compare',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 12,
            label: 'Purchase Orders',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 13,
            label: 'Customer Orders',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 14,
            label: 'Supplier Settings',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
        ],
      },
      {
        id: 2,
        label: 'Catalogues',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
        childs: [
          {
            id: 21,
            label: 'Product Items',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
        ],
      },
      {
        id: 3,
        label: 'Settings',
        values: {
          view: true,
          create: false,
          edit: false,
          delete: false,
        },
        childs: [
          {
            id: 31,
            label: 'Roles',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 32,
            label: 'Users',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
          {
            id: 33,
            label: 'Company Settings',
            values: {
              view: true,
              create: false,
              edit: false,
              delete: false,
            },
          },
        ],
      },
    ],
  },
];
