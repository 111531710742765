import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { UNIKOMERC_PRICELIST_RULESET } from './unikomerc/pricelist';
import { UNIKOMERC_STOCKLIST_RULESET } from './unikomerc/stocklist';
import { TRule } from '../typings';

export const UNIKOMERC_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: UNIKOMERC_PRICELIST_RULESET,
  stockRuleset: UNIKOMERC_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'UNIKOMERC',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    CIJENA_BEZ_PDV_SA_24_RABATOM: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: '|' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
