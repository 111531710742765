import { useMutation } from 'react-query';
import api from '@tyrio/api-factory';
import { DBSupplierClientSettingsApi } from '@tyrio/dto';

export const useUpdateClientSupplierMutation = () => {
  return useMutation(
    (payload: DBSupplierClientSettingsApi['updateOne']['request']) =>
      api.fetch<DBSupplierClientSettingsApi['updateOne']>(
        'patch_supplier_client_settings',
        payload
      ),
    {
      mutationKey: 'path_supplier_client_settings',
    }
  );
};
