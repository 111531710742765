import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

export const GOODYEAR_CATALOG_RULESET: TRule[] = [
  {
    type: 'rule.combine',
    sourceKeys: ['{{DE}}'],
    destination: 'product',
    targetKey: 'origin',
    delimiter: '',
  },
  // Category types
  {
    type: 'rule.condition',
    sourceKey: 'c.VEHICLE_TYPE',
    condition: 'equals',
    conditionValue: 'C0',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '30',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.VEHICLE_TYPE',
    condition: 'equals',
    conditionValue: 'L0',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '32',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.VEHICLE_TYPE',
    condition: 'equals',
    conditionValue: 'L4',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },

  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateGoodyearSpecialMarkings',
  },
  ...EDI_WHEEL_B4_RULESET,
  ...GLOBAL_RULESET,
];
