import api from '@tyrio/api-factory';
import { DBProductApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetProductByEan = (barcode: string) => {
  const { data, error, isFetched, isLoading } = useQuery(
    ['get_product_by_ean', barcode],
    () =>
      api.fetch<{
        response: DBProductApi['getOne']['response'];
        requestBody: never;
        request: { ean: string };
      }>(`get_product_by_ean`, { ean: barcode }),
    {
      cacheTime: 0,
      enabled: !!barcode && barcode.length === 13,
    }
  );

  return {
    productData: data,
    queryError: error,
    isFetched,
    isLoading,
  };
};
