import styled from '@emotion/styled';
import { noData } from '@tyrio/ui-library';

const NoItemsFound = () => {
  return (
    <NoAvailableItemsWrapper>
      <img src={noData} alt="no data" style={{ width: 100, height: 100 }} />
      <p>No available items</p>
    </NoAvailableItemsWrapper>
  );
};

const NoAvailableItemsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default NoItemsFound;
