import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
// import { genericGenerateSpecialMarkings } from './operations.helpers';

export function calculateAtraxionPackageQuantity(
  input: RawImport,
  _rule: TRule
) {
  const packageQuantity = parseFloat(input['Breedte_b']) > 255 ? 1 : 2;

  return packageQuantity;
}
