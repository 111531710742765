import InboundInvoiceProvider from '../../../context/InboundInvoiceContext';
import StockInProvider from '../../../context/StockInContext';
import { StockInOverview } from '../../../features/stock-in/StockInOverview';

interface StockInProps {
  sidebarOpen: boolean;
}

const StockIn = ({ sidebarOpen }: StockInProps) => {
  return (
    <InboundInvoiceProvider>
      <StockInProvider>
        <StockInOverview sidebarOpen={sidebarOpen} />
      </StockInProvider>
    </InboundInvoiceProvider>
  );
};

export default StockIn;
