import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';

interface AddedItemsButtonProps {
  quantity: number;
  onClick: (key: string) => void;
}

export const AddedItemsButton = ({
  quantity,
  onClick,
}: AddedItemsButtonProps) => {
  return (
    <Wrapper>
      <WmsIconStyled
        icon={wmsIcons.close}
        sx={{ color: '#B9C4CE', cursor: 'pointer' }}
        onClick={onClick}
      />
      <ButtonStyled variant="contained">ADDED {quantity} pcs</ButtonStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  max-width: 220px;
  width: 100%;
`;

export const WmsIconStyled = styled(WmsIcon)`
  cursor: pointer;
  color: #b9c4ce;
  &:hover {
    color: #db1e1e;
  }
`;

export const ButtonStyled = styled(Button)`
  cursor: default;
  background: #3eb274;
  width: 200px;
  height: 42px;
  opacity: 1;
  box-shadow: none;

  &:hover {
    opacity: 1;
    background: #3eb274;
    box-shadow: none;
  }
`;
