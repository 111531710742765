import React from 'react';
import styled from '@emotion/styled';

interface Props {
  ean: string;
  manufacturerCode: string;
}
export const RenderTableCode = ({ ean, manufacturerCode }: Props) => {
  return (
    <>
      <Ean>{ean}</Ean>
      <ManufacturerCode>{manufacturerCode}</ManufacturerCode>
    </>
  );
};

const Ean = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const ManufacturerCode = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #919eab;
`;
