import * as z from "zod"
import { DBSupplierType } from ".prisma/client"
import { CompleteDBFile, RelatedDBFileModel, CompleteDBCountry, RelatedDBCountryModel, CompleteDBSupplierContact, RelatedDBSupplierContactModel, CompleteDBSupplierLocation, RelatedDBSupplierLocationModel, CompleteDBSupplierConnection, RelatedDBSupplierConnectionModel, CompleteDBSupplierStock, RelatedDBSupplierStockModel, CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel, CompleteDBClientSupplierOrderItem, RelatedDBClientSupplierOrderItemModel } from "./index"

export const DBSupplierModel = z.object({
  id: z.string(),
  companyLogoId: z.string().nullish(),
  supplierType: z.nativeEnum(DBSupplierType),
  vatNumber: z.string(),
  vies: z.boolean(),
  companyOfficialName: z.string(),
  companyShortName: z.string(),
  slug: z.string(),
  countryId: z.string().nullish(),
  zipCode: z.string(),
  city: z.string(),
  address: z.string(),
  remark: z.string(),
  active: z.boolean(),
  isTyrioClient: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteDBSupplier extends z.infer<typeof DBSupplierModel> {
  companyLogo?: CompleteDBFile | null
  country?: CompleteDBCountry | null
  supplierContacts: CompleteDBSupplierContact[]
  supplierLocations: CompleteDBSupplierLocation[]
  supplierConnections: CompleteDBSupplierConnection[]
  supplierStock: CompleteDBSupplierStock[]
  supplierClientSettings: CompleteDBSupplierClientSettings[]
  DBClientSupplierOrderItem: CompleteDBClientSupplierOrderItem[]
}

/**
 * RelatedDBSupplierModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierModel: z.ZodSchema<CompleteDBSupplier> = z.lazy(() => DBSupplierModel.extend({
  companyLogo: RelatedDBFileModel.nullish(),
  country: RelatedDBCountryModel.nullish(),
  supplierContacts: RelatedDBSupplierContactModel.array(),
  supplierLocations: RelatedDBSupplierLocationModel.array(),
  supplierConnections: RelatedDBSupplierConnectionModel.array(),
  supplierStock: RelatedDBSupplierStockModel.array(),
  supplierClientSettings: RelatedDBSupplierClientSettingsModel.array(),
  DBClientSupplierOrderItem: RelatedDBClientSupplierOrderItemModel.array(),
}))
