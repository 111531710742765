import styled from '@emotion/styled';

interface BorderedBoxProps {
  text: string;
  icon: JSX.Element;
}

export const IconLabel = (props: BorderedBoxProps) => {
  const { icon, text } = props;
  return (
    <Container>
      {icon}
      <Text>{text}</Text>
    </Container>
  );
};

const Container = styled.span`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  padding-left: 12px;
`;
