import api from '@tyrio/api-factory';
import {
  DBStockList,
  DBTransferOrderItemApi,
  DBWorkOrdersApi,
} from '@tyrio/dto';
import { wmsIcons } from '@tyrio/wms-ui-library';
import { useQuery } from 'react-query';
import CustomDrawerContainer from '../../../../components/Drawers/CustomDrawerContainer';
import { ActiveDrawer } from '../../../../context/StockListContext';
import { DocumentsWrapper, Wrapper } from './ItemReservationInfo.style';
import TransferOrderDocuments from './TransferOrderDocument';
import { WorkOrdersReservation } from './WorkOrdersReservation';

interface ItemReservationInfoProps {
  activeDrawer: ActiveDrawer;
  setActiveDrawer: (a: ActiveDrawer) => void;
  selectedTableRow: DBStockList;
  warehouseId?: string;
}

export const ItemReservationInfo = ({
  activeDrawer,
  setActiveDrawer,
  selectedTableRow,
  warehouseId,
}: ItemReservationInfoProps) => {
  const { data: transferReservedData } = useQuery(
    ['get_transfer_order_ean', selectedTableRow?.product?.ean],
    () =>
      api.fetch<DBTransferOrderItemApi['list']>(`get_transfer_order_ean`, {
        ean: selectedTableRow?.product?.ean ?? '',
      }),
    {
      enabled: !!selectedTableRow?.product?.ean,
    }
  );

  const { data: workOrders } = useQuery(
    ['get_work_orders_by_ean', selectedTableRow?.product?.ean],
    () =>
      api.fetch<DBWorkOrdersApi['getByEan']>(`get_work_orders_by_ean`, {
        ean: selectedTableRow?.product?.ean ?? '',
        branchId: Number(warehouseId),
      }),
    {
      enabled: !!selectedTableRow?.product?.ean,
    }
  );

  const renderReservedData = () => {
    return (
      <Wrapper>
        <DocumentsWrapper>
          {/* Reserved items from transfer orders */}
          {transferReservedData &&
            selectedTableRow &&
            transferReservedData.count > 0 && (
              <TransferOrderDocuments
                reservedData={transferReservedData.data}
                selectedStockListItem={selectedTableRow}
              />
            )}
          {workOrders && selectedTableRow && (
            <WorkOrdersReservation
              data={workOrders.data}
              selectedStockListItem={selectedTableRow}
            />
          )}

          {!transferReservedData && <div>No reserved items</div>}
        </DocumentsWrapper>
      </Wrapper>
    );
  };

  return (
    <CustomDrawerContainer
      open={activeDrawer === 'RESERVATION'}
      child={renderReservedData()}
      title={'ITEM RESERVATION INFO'}
      icon={wmsIcons.details}
      onClick={() => setActiveDrawer('')}
    />
  );
};
