import Button from '@mui/material/Button/Button';
import { useTranslation } from 'react-i18next';
import cloneIconDisabled from '../../../assets/other-icons/clone-icon-disabled.svg';
import cloneIcon from '../../../assets/other-icons/clone-icon.svg';
import { spacing } from '../../../core';

interface CloneButtonProps {
  handleCloneRole: () => void;
  disabled?: boolean;
}
const CloneButton = ({ handleCloneRole, disabled }: CloneButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      color="info"
      variant="outlined"
      style={{
        marginRight: spacing.l,
        marginTop: spacing.l,
      }}
      onClick={handleCloneRole}
      disabled={disabled ?? false}
    >
      {t('CLONE ROLE')}
      <img
        src={disabled ? cloneIconDisabled : cloneIcon}
        alt="clone-icone"
        style={{ marginLeft: '11px' }}
      />
    </Button>
  );
};

export default CloneButton;
