import { tyrioIcons } from '@tyrio/ui-library';
import { UnselectedRowWarning } from '@tyrio/wms-ui-library';
import { isEmpty, isUndefined } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { SideMenuLayout } from '../../../components/SideMenuLayout/SideMenuLayout';
import { POSContext } from '../../../context/POSContext';
import { AddItemsFooter } from '../components/AddItemsFooter';
import { StockItems } from '../components/StockItemDetails/StockItems';
import { SuppliersHeader } from '../components/Suppliers/SuppliersHeader';
import { useGetSuppliersStock } from '../query/get-suppliers-stock';
import {
  PosSearchActiveTab,
  usePosSearchContext,
} from '../components/SearchPOS/SearchContext';
import { useVehicleCtx } from '../../../context/VehicleContext';
import { LoaderWrapper } from '../../../components/PageTemplate/PageTemplate';
import { CircularProgress } from '@mui/material';
import { usePosCartCtx } from '../../../context/PosCartContext';

export const Suppliers = () => {
  const [sortingValue, setSortingValue] = useState('');

  const {
    selectedWarehouseId,
    selectedCustomer,
    selectedTableRow,
    badgeContent,
    setBadgeContent,
  } = useContext(POSContext);

  const { activeTab } = usePosSearchContext();
  const { selectedProduct } = useVehicleCtx();
  const { cartData } = usePosCartCtx();

  const ean = useMemo(() => {
    if (activeTab === PosSearchActiveTab.default_search)
      return selectedTableRow?.product?.ean;
    else return selectedProduct?.ean;
  }, [activeTab, selectedProduct?.ean, selectedTableRow?.product?.ean]);

  const { suppliersStock, showLoader } = useGetSuppliersStock(
    ean ?? '',
    sortingValue ?? '',
    selectedWarehouseId?.toString() as string,
    selectedCustomer?.id ?? 'default-customer',
    badgeContent,
    setBadgeContent
  );

  console.log({ suppliersStock });

  const renderSuppliers = () => {
    if (showLoader)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );
    if (!isUndefined(suppliersStock) && !isEmpty(suppliersStock))
      return (
        <div>
          <SuppliersHeader
            sortingValue={sortingValue}
            setSortingValue={setSortingValue}
          />
          {suppliersStock.map((d, index) => (
            <StockItems
              data={d}
              stockType={'SUPPLIERS STOCK'}
              sale={false}
              key={index}
              index={index + 1}
            />
          ))}
        </div>
      );
    else if (isEmpty(suppliersStock))
      return (
        <UnselectedRowWarning
          icon={tyrioIcons.stepMenuSuppliers}
          emptyData="No available items for this product"
        />
      );
    else
      return (
        <UnselectedRowWarning
          icon={tyrioIcons.stepMenuSuppliers}
          text="suppliers stock list"
        />
      );
  };

  return (
    <SideMenuLayout
      type="suppliers"
      children={renderSuppliers()}
      showSwitch={false}
      checked={false}
      footer={
        !isUndefined(suppliersStock) &&
        !isEmpty(suppliersStock) &&
        cartData.length > 0 && <AddItemsFooter />
      }
    />
  );
};
