import styled from '@emotion/styled';
import { Grid, TextField, Tooltip } from '@mui/material';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioFormError, TyrioStringInput } from '../types';
import styles from './style-overrides/NumberField.module.scss';

interface TyrioInputStringProps {
  item: TyrioStringInput;
  id: string;
  gridProps?: FormGridProps;
  error?: TyrioFormError;
  register: UseFormRegister<FieldValues>;
  customDisabled?: boolean;
  helperText?: string;
  disableUnderline?: boolean;
  isTooltipVisible?: boolean;
}

export const TyrioInputString = ({
  item,
  id,
  gridProps,
  error,
  register,
  customDisabled,
  helperText,
  disableUnderline,
  isTooltipVisible,
}: TyrioInputStringProps) => {
  const { t } = useTranslation();

  const partnerDisabledFieldKeys = [
    'companyOfficialName',
    'zipCode',
    'city',
    'address',
  ];

  const shrinkServiceFields = [
    'servicePrice.chargedByLaborTime.finalPrice',
    'servicePrice.fixedPrice.inclusiveVat',
    'servicePrice.fixedPrice.exclusiveVat',
  ].includes(item.id);

  const partnerCustomDisabled =
    partnerDisabledFieldKeys.includes(item.id) && customDisabled;

  const bg = item.variant === 'standard' ? 'transparent' : 'white';
  const underline =
    disableUnderline && item.variant === 'standard'
      ? { disableUnderline: true }
      : null;

  return !item.hidden ? (
    <GridContainer item {...gridProps} hasPadding={item?.hasPadding ?? true}>
      <Tooltip
        title={isTooltipVisible ? error?.message ?? helperText ?? '' : ''}
        arrow={true}
        placement="top"
      >
        <TextFieldStyled
          style={{ marginBottom: error?.message && '-23px' }}
          multiline={item.label === 'Remark' ? true : false}
          className={`${item.inputType === 'number' && styles}`}
          fullWidth
          disabled={item.disabled || partnerCustomDisabled}
          error={!!error?.message}
          helperText={error?.message ?? helperText}
          id={id}
          label={t(`${item.label}`)}
          type={item.inputType}
          variant={item.variant}
          InputLabelProps={{
            shrink:
              partnerCustomDisabled || shrinkServiceFields ? true : undefined,
          }}
          InputProps={{
            autoComplete: 'off',
            style: { background: bg },
            ...underline,
          }}
          inputProps={{ step: 'any' }} //this allows decimal numbers
          autoComplete="off"
          required={item.required}
          {...(item.inputType === 'number'
            ? { ...register(id, { valueAsNumber: true }) }
            : { ...register(id) })}
        />
      </Tooltip>
    </GridContainer>
  ) : null;
};

const GridContainer = styled(Grid)<{ hasPadding?: boolean }>`
  padding-bottom: ${(props) => (props.hasPadding ? '22px' : null)};
  align-self: center;
`;

const TextFieldStyled = styled(TextField)({
  '& .MuiFormHelperText-root.Mui-error': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
