import * as z from "zod"
import { WorkOrdersStatus } from ".prisma/client"
import { CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBWorkOrdersNumber, RelatedDBWorkOrdersNumberModel, CompleteDBAppointments, RelatedDBAppointmentsModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBWorkOrdersModel = z.object({
  id: z.string(),
  customerOrderId: z.string().nullish(),
  erpBranchId: z.string(),
  erpOrderId: z.string().nullish(),
  lineItems: jsonSchema,
  webOrderId: z.string().nullish(),
  orderStatus: jsonSchema,
  externalStatus: z.nativeEnum(WorkOrdersStatus).nullish(),
  dBWorkOrdersNumberId: z.string().nullish(),
  orderNumber: z.string().nullish(),
  stockOutData: jsonSchema,
  defaultWorkOrderId: z.string().nullish(),
  fileUrl: z.string().nullish(),
  createdAt: z.date().nullish(),
  updatedAt: z.date().nullish(),
  erpDocNumber: z.string().nullish(),
  hotelCode: z.string().nullish(),
})

export interface CompleteDBWorkOrders extends z.infer<typeof DBWorkOrdersModel> {
  customerOrder?: CompleteDBCustomerOrderItem | null
  DBWorkOrdersNumber?: CompleteDBWorkOrdersNumber | null
  appointments: CompleteDBAppointments[]
}

/**
 * RelatedDBWorkOrdersModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWorkOrdersModel: z.ZodSchema<CompleteDBWorkOrders> = z.lazy(() => DBWorkOrdersModel.extend({
  customerOrder: RelatedDBCustomerOrderItemModel.nullish(),
  DBWorkOrdersNumber: RelatedDBWorkOrdersNumberModel.nullish(),
  appointments: RelatedDBAppointmentsModel.array(),
}))
