import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { CustomModal } from '../Modal/Modal';
import { Table } from './Table';
import styled from '@emotion/styled/macro';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useAuth } from '../../context/AuthContext';
import { tyrioIcons } from '@tyrio/ui-library';
// import JsBarcode from 'jsbarcode';
import { BarcodeData } from '@tyrio/dto';
import { usePrintBarcode } from '../../query/print-barcode';

export interface ISingleItem {
  ean: string;
  name: string;
  qty: number;
}

export const BarcodeModal = ({
  open,
  handleClose,
  items,
}: {
  open: boolean;
  handleClose: () => void;
  items: ISingleItem[];
}) => {
  const { user } = useAuth();

  const [selectedPrinter, setSelectedPrinter] = useState<string | null>(null);

  const clientPrinters = !isEmpty(user?.client?.printers)
    ? JSON.parse(user?.client?.printers as string)
    : {};

  return (
    <div style={{ background: 'white' }}>
      <CustomModal
        open={open}
        handleClose={handleClose}
        child={
          <ModalContent
            selectedPrinter={selectedPrinter}
            setSelectedPrinter={setSelectedPrinter}
            printers={clientPrinters?.['thermalLabels'] ?? []}
            items={items}
            handleClose={handleClose}
          />
        }
      />
    </div>
  );
};

const ModalContent = ({
  selectedPrinter,
  setSelectedPrinter,
  printers,
  items,
  handleClose,
}: {
  selectedPrinter: string | null;
  setSelectedPrinter: (a: string) => void;
  printers: { name: string; id: string }[];
  items: ISingleItem[];
  handleClose: () => void;
}) => {
  const setValues = () => {
    const initialCheckedState: Record<string, boolean> = {};
    const textState: Record<string, number> = {};

    items.forEach((item) => {
      initialCheckedState[item.ean] = true;
      textState[item.ean] = item.qty;
    });
    return { initialCheckedState, textState };
  };

  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(
    setValues().initialCheckedState
  );

  const [textValues, setTextValues] = useState<Record<string, number>>(
    setValues().textState
  );

  const { printBarcode, isLoading } = usePrintBarcode(() => handleClose());

  const handleCheckboxChange = (id: string, checked?: boolean) => {
    if (id === 'all') {
      Object.keys(checkedItems).forEach((key) => {
        checkedItems[key] = checked ?? false;
      });
      setCheckedItems({ ...checkedItems });
    } else
      setCheckedItems((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
  };

  const handleTextChange = (id: string, value: number) => {
    setTextValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // function generateBarcodeImage(ean: string, format = 'png') {
  //   // Create a canvas element
  //   const canvas = document.createElement('canvas');

  //   // Generate the barcode on the canvas
  //   JsBarcode(canvas, ean, {
  //     format: 'ean13',
  //     lineColor: 'black',
  //     displayValue: true,
  //   });

  //   // Get the image as a data URL (base64 encoded string)
  //   const mimeType = format === 'png' ? 'image/png' : 'image/jpeg';
  //   const base64Image = canvas.toDataURL(mimeType);

  //   return base64Image;
  // }

  const getBody = () => {
    const data: BarcodeData[] = [];
    items.forEach((item) => {
      if (checkedItems[item.ean] && textValues?.[item.ean] > 0) {
        item.qty = textValues[item.ean];

        data.push({
          ean: item.ean,
          quantity: textValues[item.ean],
          // url: generateBarcodeImage(item.ean),
        });
      }
    });

    return data;
  };

  const handlePrint = () => {
    const data = getBody();
    const printerId = selectedPrinter;

    printBarcode({ data, printerId: Number(printerId) });
  };

  return (
    <Wrapper>
      <IconWrapper>
        <img
          src={tyrioIcons.XIcon}
          alt="close"
          onClick={handleClose}
          style={{ cursor: 'pointer' }}
        />
      </IconWrapper>
      <Table
        items={items}
        handleCheckboxChange={handleCheckboxChange}
        checkedItems={checkedItems}
        handleTextChange={handleTextChange}
      />

      <FormControl sx={{ width: '40%' }}>
        <InputLabel id="printers-label">Printers</InputLabel>
        <Select
          labelId="printers-label"
          id="demo-simple-select"
          value={selectedPrinter}
          label="Printers"
          onChange={(e) => {
            setSelectedPrinter(e.target.value as string);
          }}
        >
          {printers.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <ButtonWrapper>
        <Button
          variant="contained"
          color="info"
          sx={{ width: '20%', height: '50px' }}
          disabled={
            !selectedPrinter ||
            Object.values(checkedItems).every((v) => v === false) ||
            isLoading
          }
          startIcon={isLoading ? <CircularProgress size={10} /> : null}
          onClick={() => handlePrint()}
        >
          Print
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
