import styled from '@emotion/styled/macro';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { StockType } from '@tyrio/dto';
import { usePosCtx } from '../../../../../context/POSContext';
import { useContext, useMemo } from 'react';
import { PosCartContext } from '../../../../../context/PosCartContext';
import { useWS } from '../../../../../context/WSContext';
import QuantityComponent from '../../../components/StockItemDetails/QuantityComponent/QuantityComponent';
import { usePosCartData } from '../helpers/cart-data';
import { useAppointmentsHelper } from '../Steps/Appointment/appointment-helper';
import { Typography } from '@mui/material';
import { formatCurrencyWithoutSymbol } from '../../../../../helpers/currency-format';

interface ReservedItemProps {
  productName: string;
  productBrand: string;
  productDescription: string;
  price: number;
  discount: number;
  rebate: number;
  quantity: number;
  itemKey: string;
  stockType: StockType;
  realQuantity: number;
  inclusiveVat: number;
  serviceCategory?: string;
  pcd?: string;
  et?: number;
}

export const ReservedItem = ({
  productName,
  productBrand,
  productDescription = '',
  price,
  discount,
  rebate,
  quantity,
  itemKey,
  stockType,
  realQuantity,
  inclusiveVat,
  serviceCategory,
  pcd,
  et,
}: ReservedItemProps) => {
  const { updateServiceQty, getAppointments } = useAppointmentsHelper();

  const ws = useWS();
  const { isVatIncluded } = usePosCtx();
  const { timer, appointmentDetails, setAppointmentDetails } =
    useContext(PosCartContext);
  const { modalData } = usePosCartData();

  const handleDelete = (key: string) => {
    ws.socket?.emit('remove-item-from-pos-cart', { key });
    timer?.restart();
  };

  const updateReservedItem = (quantity: number) => {
    timer?.restart();
    ws.socket?.emit('update-pos-cart-item', {
      quantity,
      key: itemKey,
      stockType,
    });

    if (serviceCategory) {
      const { updated } = updateServiceQty(
        getAppointments(),
        serviceCategory as string,
        itemKey,
        quantity
      );
      setAppointmentDetails({
        ...appointmentDetails,
        appointments: updated as unknown as string,
      });
    }
  };

  const total = useMemo(() => {
    const item = modalData.find((item) => item.key === itemKey);
    if (item) {
      let finalPrice = 0;
      finalPrice = !isVatIncluded ? price : inclusiveVat;
      if (rebate && rebate !== 0)
        finalPrice = finalPrice - finalPrice * (rebate / 100);
      if (discount && discount !== 0)
        finalPrice = finalPrice - finalPrice * (discount / 100);
      return item?.req?.quantity * finalPrice;
    }
    return 0;
  }, [
    discount,
    inclusiveVat,
    isVatIncluded,
    itemKey,
    modalData,
    price,
    rebate,
  ]);

  return (
    <MainWrapper>
      <Wrapper>
        <ProductDetailsWrapper>
          <ProductDetailsText>{`${productBrand.toUpperCase()} ${productName}`}</ProductDetailsText>
          <ProductDescription>{productDescription}</ProductDescription>
        </ProductDetailsWrapper>
        <Wrap>
          <PricesWrapper>
            <Text>
              {isVatIncluded
                ? formatCurrencyWithoutSymbol(inclusiveVat)
                : formatCurrencyWithoutSymbol(price)}
            </Text>
            <Text>{formatCurrencyWithoutSymbol(discount)}%</Text>
            <Text>{formatCurrencyWithoutSymbol(rebate)}%</Text>
          </PricesWrapper>
          <QuantityComponent
            quantity={quantity}
            maxAvailableQuantity={realQuantity}
            showButton={false}
            cart={true}
            changeCartItemQuantity={updateReservedItem}
            minQuantity={1}
          />
          <Text>{formatCurrencyWithoutSymbol(total)}</Text>
          <CancelRoundedIcon
            style={{ cursor: 'pointer' }}
            sx={{ color: '#B9C4CE' }}
            onClick={() => handleDelete(itemKey)}
          />
        </Wrap>
      </Wrapper>
      {pcd && et && (
        <WheelDetailsWrapper>
          <Typography fontSize={12} fontWeight={400}>
            PCD: {pcd} - ET: {et}
          </Typography>
        </WheelDetailsWrapper>
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dfe3e8;
`;

const Wrapper = styled.div`
  width: 100%;
  max-height: 72px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70px;
  gap: 10px;
  white-space: nowrap;
  overflow-x: auto;
  width: calc(100% - 400px);
`;

const Text = styled.div`
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  width: 60px;
`;

const ProductDetailsText = styled.div`
  font-family: Barlow;
  font-weight: 400;
  max-height: 40px;
`;

const ProductDescription = styled.div`
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #919eab;
`;

const PricesWrapper = styled.div`
  width: 240px;
  display: flex;
  justify-content: space-around;
`;

export const Wrap = styled.div`
  min-width: 400px;
  max-width: 400px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const WheelDetailsWrapper = styled.div`
  padding: 4px 8px;
  border: 1px dashed #dfe3e8;
  border-radius: 4px;
  margin: 4px 8px;
`;
