import InboundInvoiceProvider from '../../context/InboundInvoiceContext';
import { InboundInvoice } from './InboundInvoice';

export const InboundInvoiceOverview = () => {
  return (
    <InboundInvoiceProvider>
      <InboundInvoice />
    </InboundInvoiceProvider>
  );
};
