import React from 'react';
import styled from '@emotion/styled';

interface Props {
  brand: string;
  productName: string;
}
export const RenderTableProductName = ({ brand, productName }: Props) => {
  return (
    <>
      <Brand>{brand}</Brand>
      <ProductName>{productName}</ProductName>
    </>
  );
};

const Brand = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #919eab;
`;

const ProductName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
`;
