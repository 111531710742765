import api from '@tyrio/api-factory';
import { DBStockListCronApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetFtpLogs = (page: number) => {
  const query = useQuery([page], () =>
    api.fetch<DBStockListCronApi['get_logs']>('get_ftp_logs', {
      page,
    })
  );

  return {
    ...query,
  };
};
