import { Dispatch, SetStateAction } from 'react';
import { EnlargeWrapper } from './style/Menu.style';
import MenuIcon from '@mui/icons-material/Menu';

interface PopoverButtonProps {
  isHovered: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
  setIsMenuPopupVisible: Dispatch<SetStateAction<boolean>>;
}

const PopoverButton = ({
  isHovered,
  setIsMenuPopupVisible,
  setIsHovered,
}: PopoverButtonProps) => {
  return (
    <EnlargeWrapper
      onClick={() => setIsMenuPopupVisible(true)}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <MenuIcon
        sx={{
          width: '30px',
          height: '40px',
          color: !isHovered ? '#c8ccc9' : '#1976d2',
        }}
      />
    </EnlargeWrapper>
  );
};

export default PopoverButton;
