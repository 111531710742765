import {
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { DBCurrency } from '@prisma/client';
import { DBSupplierClientPriceCalculationApi } from '@tyrio/dto';
import { useCallback, useState } from 'react';
import {
  SelectList,
  TextFieldInput,
} from '../supplier-form/helpers/components';
import { parseEnumToArray } from '../../../../helpers/enum-parser';

export interface SupplierClientPriceCalculationProps {
  getData: (
    data: DBSupplierClientPriceCalculationApi['create']['requestBody']
  ) => void;
  initialData?: Partial<
    DBSupplierClientPriceCalculationApi['create']['requestBody']
  >;
}

export const SUPPLIER_CLIENT_PRICE_CALCULATION_INITIAL_DATA: DBSupplierClientPriceCalculationApi['create']['requestBody'] =
  {
    supplierCurrency: DBCurrency.EUR,
    ecoTax: false,
    rebate: undefined,
    yearlyBonus: undefined,
  };

const SupplierClientPriceCalculation = ({
  getData,
  initialData = {},
}: SupplierClientPriceCalculationProps) => {
  const [
    supplierClientPriceCalculation,
    setSupplierClientPriceCalculationState,
  ] = useState<DBSupplierClientPriceCalculationApi['create']['requestBody']>({
    ...SUPPLIER_CLIENT_PRICE_CALCULATION_INITIAL_DATA,
    ...initialData,
  });

  const setSupplierClientPriceCalculation = useCallback(
    (data: DBSupplierClientPriceCalculationApi['create']['requestBody']) => {
      setSupplierClientPriceCalculationState(data);
      getData(data);
    },
    [getData]
  );

  return (
    <Grid>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '28px',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '0.3px',
          color: '#212B36',
          marginBottom: '9px',
        }}
      >
        Purchase price netto to bruto calc
      </Typography>
      <Divider light />
      <Grid
        spacing={2}
        container
        sx={{
          marginTop: '30px',
        }}
      >
        <Grid item xs={9} lg={3}>
          <SelectList
            label="Supplier payment currency"
            value={supplierClientPriceCalculation.supplierCurrency}
            dropdownData={parseEnumToArray(DBCurrency)}
            onChange={(e) => {
              setSupplierClientPriceCalculation({
                ...supplierClientPriceCalculation,
                ...{
                  supplierCurrency: e.target.value as DBCurrency,
                },
              });
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          lg={2}
          sx={{
            marginTop: '7px',
          }}
        >
          <FormControlLabel
            label="Eco Tax"
            control={
              <Switch
                color="info"
                checked={supplierClientPriceCalculation.ecoTax}
                onChange={(e) => {
                  setSupplierClientPriceCalculation({
                    ...supplierClientPriceCalculation,
                    ...{ ecoTax: e.target.checked },
                  });
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextFieldInput
            label="Rebate %"
            type="number"
            value={supplierClientPriceCalculation.rebate}
            onChange={(e) => {
              setSupplierClientPriceCalculation({
                ...supplierClientPriceCalculation,
                ...{ rebate: parseInt(e.target.value) },
              });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldInput
            label="Yearly bonus %"
            type="number"
            helperText="only used for info on price compare"
            value={supplierClientPriceCalculation.yearlyBonus}
            onChange={(e) => {
              setSupplierClientPriceCalculation({
                ...supplierClientPriceCalculation,
                ...{ yearlyBonus: parseInt(e.target.value) },
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        sx={{ marginTop: '20px' }}
        justifyContent="flex-end"
      ></Grid>
    </Grid>
  );
};

export default SupplierClientPriceCalculation;
