import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { QueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

export const useDeletePaymentMethod = (queryClient: QueryClient) => {
  const history = useHistory();
  const { mutate } = useMutation(
    ['delete_payment_method'],
    (id: DBPaymentTypesApi['deleteOne']['requestBody']) =>
      api.fetch<DBPaymentTypesApi['deleteOne']>('delete_payment_method', {
        ...id,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_payment_method');
        history.push('/dashboard/company-settings/payment-method');

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.DELETE,
          ''
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { deletePaymentMethod: mutate };
};
