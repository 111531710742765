import styled from '@emotion/styled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BuildIcon from '@mui/icons-material/Build';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WarningIcon from '@mui/icons-material/Warning';
import { Alert, Box, Button, Typography } from '@mui/material';
import { AppointmentReq } from '@tyrio/dto';
import { WmsIcon } from '@tyrio/wms-ui-library';
import _ from 'lodash';
import moment from 'moment';
import { FunctionComponent, SVGProps, useState } from 'react';
import { usePosCartCtx } from '../../../../../context/PosCartContext';
import {
  _warning,
  AppointmentItemLabel,
  getColor,
} from '../Steps/Appointment/Appointment';
import EditIcon from '@mui/icons-material/Edit';

interface InformationCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: string;
  onClick: () => void;
  disabled: boolean;
}

export const AppointmentCard = ({
  icon,
  text,
  onClick,
  disabled,
}: InformationCardProps) => {
  const { appointmentDetails, setSelectedAppointment } = usePosCartCtx();

  const appointmentsObj = _.get(appointmentDetails, 'appointments') ?? {};

  const appointments = Object.values(
    appointmentsObj
  ) as unknown as AppointmentReq[];

  const [isHovered, setIsHovered] = useState(false);

  const isWarning = appointments?.some(
    (item) => _.isUndefined(item['appointmentDateTo']) || _warning(item)
  );

  const isAllCompleted = appointments?.some((item) =>
    _.has(item, 'appointmentDateTo')
  );

  const getIconColor = () => {
    if (isWarning) return '#E75524';

    if (isHovered) return '#1976d2';

    return '#DFE3E8';
  };

  const getTextColor = () => {
    if (isWarning) return '#E75524';

    if (isHovered) return 'black';

    return '#919eab;';
  };

  const getBtnColor = () => {
    if (isWarning) return 'warning';

    return 'info';
  };

  const iconsProps = {
    sx: { opacity: '0.2' },
  };

  return (
    <Wrapper
      warning={isWarning}
      disabled={_.isEmpty(appointmentsObj)}
      allCompleted={isAllCompleted}
      onClick={() => {
        !disabled && onClick();
      }}
      onMouseOver={() => {
        if (!isAllCompleted) setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isAllCompleted ? (
        <InfoWrapper2>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignContent="center">
              <WmsIcon
                icon={icon}
                sx={{
                  color: getIconColor(),
                  width: '25px',
                  height: '25px',
                  marginRight: '12px',
                }}
                viewBox={'0 0 32 32'}
              />
              <Text2 textColor={getTextColor()}>{text}</Text2>
            </Box>
            <Box>
              <EditIcon
                sx={{
                  color: getIconColor(),
                  '&:hover': {
                    color: isWarning ? '#E75524' : '#1976d2',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            {appointments.map((item) =>
              item.appointmentDateFrom ? (
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginTop="10px"
                  >
                    <AppointmentItemLabel
                      icon={
                        <DateRangeIcon color={getColor(item)} {...iconsProps} />
                      }
                      isWarning={_warning(item)}
                      label={moment(item.appointmentDateFrom).format(
                        'DD.MM.YYYY'
                      )}
                    />
                    <AppointmentItemLabel
                      icon={
                        <AccessTimeIcon
                          color={getColor(item)}
                          {...iconsProps}
                        />
                      }
                      isWarning={_warning(item)}
                      label={`${moment(item.appointmentDateFrom).format(
                        'HH:mm'
                      )} - ${moment(item.appointmentDateTo).format('HH:mm')}`}
                    />
                    <AppointmentItemLabel
                      icon={
                        <BuildIcon color={getColor(item)} {...iconsProps} />
                      }
                      isWarning={_warning(item)}
                      label={item.resource?.meta.subzoneName as string}
                    />
                  </Box>
                  <Box>
                    {_warning(item, 'duration') && (
                      <Box marginTop="10px">
                        <Alert severity="warning">
                          The selected time slot is not suitable for added
                          services
                        </Alert>
                      </Box>
                    )}
                    {_warning(item, 'box-error') && (
                      <Box marginTop="10px">
                        <Alert severity="warning">
                          {`${item.resource?.meta.subzoneName} is not suitable for the selected vehicle`}
                        </Alert>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={1}
                >
                  <WarningIcon color="warning" />
                  <Text2 textColor={getTextColor()}>Appointment missing</Text2>
                  <Button
                    endIcon={<AddCircleOutlineIcon color={getBtnColor()} />}
                    color={getBtnColor()}
                    onClick={() => {
                      setSelectedAppointment(item.id);
                    }}
                  >
                    ADD
                  </Button>
                </Box>
              )
            )}
          </Box>
        </InfoWrapper2>
      ) : (
        <InfoWrapper>
          <WmsIcon
            icon={icon}
            sx={{
              color: getIconColor(),
              width: '30px',
              height: '30px',
            }}
            viewBox={'0 0 32 32'}
          />
          <Text textColor={getTextColor()}>{text}</Text>
        </InfoWrapper>
      )}
      {isHovered && !isAllCompleted && (
        <Button
          endIcon={<AddCircleOutlineIcon color={getBtnColor()} />}
          color={getBtnColor()}
          onClick={onClick}
        >
          ADD
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  warning: boolean;
  allCompleted: boolean;
  disabled: boolean;
}>`
  border: 1px dashed ${(props) => (props.warning ? `#E75524` : `#e6e6e6`)};
  border-radius: 8px;
  height: ${(props) => (props.allCompleted ? `unset` : `74px !important`)};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  cursor: pointer;
  &:hover {
    border: 1px solid #1976d2;
    background: rgb(25, 117, 210, 0.04);
  }

  ${(props) =>
    !props.allCompleted &&
    `
    &:hover {
      border: 1px dashed ${props.warning ? `#E75524` : `#1976d2`};
      background: ${
        props.warning ? `rgba(231, 85, 36, 0.04)` : `rgba(25, 117, 210, 0.04)`
      };
    }
  `}
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const InfoWrapper2 = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
`;

const Text = styled(Typography)<{ textColor: string }>`
  color: ${(props) => props.textColor};
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
`;

const Text2 = styled(Typography)<{ textColor: string }>`
  color: ${(props) => props.textColor};
  font-family: Barlow;
  font-weight: 400;
  font-size: 14px;
`;
