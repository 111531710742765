import api from '@tyrio/api-factory';
import { ClientShippingCourierApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { QueryClient, useMutation } from 'react-query';

export const useUpdateStatus = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['change_courier_status'],
    (data: ClientShippingCourierApi['changeStatus']['requestBody']) =>
      api.fetch<ClientShippingCourierApi['changeStatus']>(
        'change_courier_status',
        { ...data }
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_one_client_shipping_courier');
        queryClient.refetchQueries('get_client_shipping_couriers');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateStatus: mutate };
};
