import styled from '@emotion/styled';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import SourceContainer from '@tyrio/forms';
import {
  CancelModal,
  CategoryDropdownInput,
  DeleteModal,
  RouteRouterPrompt,
  Title,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
  backIcon,
} from '@tyrio/ui-library';
import _, { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import slugify from 'slugify';
import { queryClient } from '../../../query-client';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';
import ComingSoon from '../../pages/dashboard/ComingSoon';
import { getTextField } from '../form-helper';

const CategoryForm = () => {
  const { categoryId } = useParams<{ categoryId: string }>();

  const history = useHistory();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  //GET category by ID
  const {
    status,
    data: category_data,
    isFetching: isCategoryDataFetching,
    refetch: getProductCategoryById,
  } = useQuery(
    ['get_category_id', categoryId, 'update_category'],
    () =>
      api.fetch<DBProductCategoryApi['getOne']>(`category_id`, {
        id: parseInt(categoryId, 10),
      }),
    {
      enabled: !!categoryId,
    }
  );

  const generateDefaultValues = useCallback(() => {
    if (!category_data)
      return {
        name: '',
        slug: '',
        parentCategory: null,
        taricCode: '',
        remark: '',
      };

    return {
      name: category_data.name,
      slug: category_data.slug.toLowerCase(),
      parentCategory: category_data.parent_category_id,
      taricCode: category_data.taric_code,
      remark: category_data.remark,
    };
  }, [category_data]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isDirty },
    watch,
    reset,
  } = useForm({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...generateDefaultValues() } as any,
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: true,

    resolver: (formValues) => {
      //Required fields
      const validate = ['name', 'taricCode'];

      const errors: Record<string, string> = {};
      validate.forEach((item, index) => {
        if (formValues[validate[index]].trim() === '') {
          errors[validate[index]] = 'This field is required!';
        }
      });

      return {
        values: formValues,
        errors,
      };
    },
  });

  //DELETE category mutation
  const deleteCategoryMutation = useMutation(
    () =>
      api.fetch<DBProductCategoryApi['getOne']>('delete_category', {
        id: parseInt(categoryId, 10),
      }),
    {
      mutationKey: 'delete_category',
      onSuccess: () => {
        queryClient.refetchQueries('category_list');
        history.push(`/dashboard/categories`);
        setIsSaving(false);
        ToastHelper.showToast(
          'Category',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Category',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  //UPDATE category mutation
  const updateCategoryMutation = useMutation(
    (category: DBProductCategoryApi['updateOne']['request']) =>
      api.fetch<DBProductCategoryApi['updateOne']>('update_category', {
        ...category,
      }),
    {
      mutationKey: 'update_category',
      onSuccess: () => {
        queryClient.refetchQueries('category_list');
        getProductCategoryById();
        ToastHelper.showToast(
          'Category',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        setIsSaving(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          'Category',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          e.response.data.error.name
        );
      },
    }
  );

  //CREATE new category mutation
  const createCategoryMutation = useMutation(
    (category: DBProductCategoryApi['create']['request']) =>
      api.fetch<DBProductCategoryApi['create']>('create_category', {
        ...category,
      }),
    {
      mutationKey: 'create_category',
      onSuccess: (item) => {
        queryClient.refetchQueries('category_list');
        ToastHelper.showToast(
          'Category',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/categories/${item.id}`);
        setIsCreated(true);
        setIsSaving(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          'Category',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          e.response.data.error.name
        );
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    const parentCategory = data.parentCategory
      ? parseInt(data.parentCategory.id, 10)
      : null;

    setIsSaving(true);
    if (categoryId) {
      const category_data: DBProductCategoryApi['updateOne']['request'] = {
        id: parseInt(categoryId, 10),
        name: data.name,
        slug: data.slug.toLowerCase(),
        taric_code: data.taricCode,
        parent_category_id: parentCategory,
        remark: data.remark,
      };
      updateCategoryMutation.mutate(category_data);
    } else {
      const category_data: DBProductCategoryApi['create']['request'] = {
        parent_category_id: parentCategory,
        name: data.name,
        slug: data.slug.toLowerCase(),
        taric_code: data.taricCode,
        remark: data.remark || '',
        model_attributes: null,
        item_attributes: null,
        active: true,
        non_deletable: false,
        parent_category_name: null,
      };
      createCategoryMutation.mutate(category_data);
    }
  };

  const handleChangeCategoryStatus = () => {
    if (categoryId) {
      updateCategoryMutation.mutate({
        id: parseInt(categoryId, 10),
        active: !category_data?.active,
      });
    }
  };

  const handleDeleteCategory = () => {
    setIsDeleteModalVisible(true);
  };

  const handleCancelBranch = () => {
    isDirty && setIsCancelModalVisible(true);
    !isDirty && history.push('/dashboard/categories');
  };

  useEffect(() => {
    reset(generateDefaultValues());
  }, [category_data, generateDefaultValues, reset]);

  const generateSlugValue = useCallback(() => {
    const nameValue: string = watch('name');
    return slugify(nameValue.toLowerCase());
  }, [watch]);

  useEffect(() => {
    setValue('slug', generateSlugValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, generateSlugValue, watch('name')]);

  const checkIsShrink = (key: string) => {
    return !!categoryId && !_.isEmpty(watch(key));
  };

  return (
    <PageTemplateContent>
      <PageLayout>
        {categoryId &&
        isCategoryDataFetching &&
        !!category_data &&
        status !== 'success' ? (
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          </Grid>
        ) : (
          <Grid>
            {!isCancelModalVisible && (
              <RouteRouterPrompt
                when={isDirty && !isSaving}
                navigate={(path) => history.push(path)}
                shouldBlockNavigation={() => true}
              />
            )}
            {isCancelModalVisible && (
              <CancelModal
                LBAction={() => setIsCancelModalVisible(false)}
                RBAction={() => {
                  history.push(`/dashboard/categories`);
                  setIsCancelModalVisible(false);
                }}
              />
            )}
            {isDeleteModalVisible && (
              <DeleteModal
                LBAction={() => setIsDeleteModalVisible(false)}
                RBAction={() => {
                  setIsSaving(true);
                  deleteCategoryMutation.mutate();
                  setIsDeleteModalVisible(false);
                }}
                itemName={category_data?.name ? category_data.name : ''}
              />
            )}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <TitleWrapper>
                <Title>
                  <BackButton
                    onClick={() => history.push(`/dashboard/categories`)}
                  >
                    <img src={backIcon} alt="back-icon" />
                  </BackButton>
                  {categoryId ? category_data?.name : 'Create new category'}
                </Title>

                <FormControlLabel
                  control={
                    <Switch
                      {...register('active')}
                      color="info"
                      checked={category_data?.active ?? true}
                      onChange={handleChangeCategoryStatus}
                    />
                  }
                  label="Active"
                />
              </TitleWrapper>
              <TyrioTabs>
                <TyrioTab title={'Main'} key="main" errored={!isEmpty(errors)}>
                  <Grid container spacing={2}>
                    <>
                      {getTextField(
                        'Name *',
                        'name',
                        () => false,
                        errors,
                        register,
                        { lg: 6, xs: 12 },
                        undefined,
                        {
                          shrink: checkIsShrink('name'),
                        }
                      )}

                      <Grid item xs={12} lg={6}>
                        <TextField
                          fullWidth
                          label="Slug"
                          id="slug"
                          error={!!errors['slug']}
                          helperText={
                            errors['slug'] && 'This field is required!'
                          }
                          defaultValue={() => generateSlugValue()}
                          InputLabelProps={{
                            shrink:
                              checkIsShrink('name') || watch('slug') !== '',
                          }}
                          {...register('slug', {
                            onChange: (e) => {
                              setValue(
                                'slug',
                                e.target.value.toLowerCase().replace(/ /g, '-')
                              );
                            },
                          })}
                        />
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <CategoryDropdownInput
                          label="Parent category"
                          id="parentCategory"
                          control={control}
                          isEditDisabled={false}
                          isMain={true}
                          isCreated={isCreated}
                          categoryId={category_data?.id}
                        />
                      </Grid>

                      {getTextField(
                        'Taric code *',
                        'taricCode',
                        () => false,
                        errors,
                        register,
                        { lg: 6, xs: 12 },
                        undefined,
                        {
                          shrink: checkIsShrink('taricCode'),
                          type: 'number',
                        }
                      )}
                      {getTextField(
                        'Remark',
                        'remark',
                        () => false,
                        errors,
                        register,
                        { lg: 12, xs: 12 },
                        undefined,
                        {
                          shrink: checkIsShrink('remark'),
                        }
                      )}
                    </>
                  </Grid>
                </TyrioTab>
                <TyrioTab title={'Custom attributes'} key="custom-attributes">
                  <ComingSoon />
                </TyrioTab>
              </TyrioTabs>

              <SourceContainer
                data={{
                  data_source: 'source',
                  created: 'created',
                  last_edited: 'edited',
                }}
                onCancel={handleCancelBranch}
                onDelete={handleDeleteCategory}
                disabledDelete={!categoryId}
              />
            </form>
          </Grid>
        )}
      </PageLayout>
    </PageTemplateContent>
  );
};

const PageLayout = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 16px;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`;

const BackButton = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

export default CategoryForm;
