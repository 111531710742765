import styled from '@emotion/styled/macro';
import { OrderTable } from '../../../../components/Pdf/components/Table/OrderTable';
import { PdfHeader } from '../../../../components/Pdf/components/PdfHeader';
import { ShippingDetails } from '../../../../components/Pdf/components/ShippingDetails';
import { Button, CircularProgress } from '@mui/material';
import { MainInfo } from '../../../../components/Pdf/components/MainInfo';
import { IModalData } from '@tyrio/dto';
import { usePrintData } from '../../queries/print-data';

export const ModalContent = ({
  handleClose,
  modalData,
}: {
  handleClose: () => void;
  modalData: IModalData;
}) => {
  const isTransferOrder = modalData.orderNumber.includes('TO');

  const { mutateAsync, isLoading } = usePrintData();

  const printData = () => {
    mutateAsync(modalData.orderId);
  };

  return (
    <Wrapper>
      <PdfHeader
        logoUrl={''}
        documentType={'WORK ORDER'}
        orderNumber={modalData?.orderNumber ?? ''}
        headerText={''}
      />

      <MainInfo
        branchName={modalData?.branchName ?? ''}
        issueDate={modalData?.issueDate}
        reference={modalData?.reference ?? ''}
        issuePlace={''}
        dueDate={''}
        showSecondRow={false}
      />
      <OrderTable
        lineItems={modalData?.lineItems ?? []}
        tableProps={{
          showWeight: false,
          showQuantity: true,
          showTotalWeight: false,
          showTotalFooter: false,
          columnCount: 2,
        }}
      />

      <ShippingDetails
        deliveryMethod={{
          label1: modalData.deliveryType,
          value1: '',
          label2: '',
          value2: '',
        }}
        shippingDetails={{
          label1: '',
          value1: ` `,
          label2: '',
          value2: ` kg`,
        }}
        recipientName={modalData.customer ?? ''}
        recipientCity={''}
        hasSideBorders={true}
      />

      <ButtonWrapper>
        <Button variant="outlined" color="info" onClick={handleClose}>
          CLOSE
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={isTransferOrder}
          startIcon={isLoading && <CircularProgress size={13} />}
          onClick={() => printData()}
          disableElevation={true}
        >
          PRINT
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;
