import styled from '@emotion/styled';
import noisePerformanceIcon from '../../../assets/productDetailsNew/icon3.svg';

interface NoisePerformanceProps {
  value: number | null;
}
export const NoisePerformance = ({ value }: NoisePerformanceProps) => {
  return value !== null ? (
    <Container>
      <Value>
        {value}
        <Db>dB</Db>
      </Value>
      <Img src={noisePerformanceIcon} alt="noise-icon" />
    </Container>
  ) : (
    <div></div>
  );
};

const Container = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #454f5b;
  display: flex;
  width: 80px;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Value = styled.span`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: 27px;
  height: 32px;
`;

const Db = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #454f5b;
  margin-left: 2px;
`;

export default NoisePerformance;
