/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { StockListSortingType } from '@tyrio/dto';
import { useContext, useState } from 'react';
import { parseEnumToInputOption } from '../../../../../helpers/enum-parser';
import { StockListContext } from '../../../context/StockListContext';

const FilterFooter = () => {
  const {
    filterValues,
    setFilterValue,
    sidebarOpen,
    filterOptions,
    setOnlyAvailable,
  } = useContext(StockListContext);

  const sortingOptions = parseEnumToInputOption(StockListSortingType);
  const [isOnlyAvailableChecked, setIsOnlyAvailableChecked] = useState<boolean>(
    filterValues?.['onlyAvailable'] === 'true'
  );
  const [isStockChecked, setIsStockChecked] = useState<boolean>(
    filterValues?.['stock'] === 'true'
  );

  return filterOptions ? (
    <Container sidebarOpen={sidebarOpen}>
      <SwitchWrapper sidebarOpen={sidebarOpen}>
        <FormControlLabel
          control={
            <Switch
              color="info"
              checked={isOnlyAvailableChecked}
              onChange={(e) => {
                if (e.target.checked === true) {
                  setFilterValue({
                    onlyAvailable: e.target.checked.toString(),
                  });
                } else {
                  setFilterValue({
                    onlyAvailable: undefined,
                  });
                }
                setOnlyAvailable(e.target.checked);
                setIsOnlyAvailableChecked(e.target.checked);
              }}
            />
          }
          label="Only available"
        />
        <FormControlLabel
          control={
            <Switch
              color="info"
              checked={isStockChecked}
              onChange={(e) => {
                if (e.target.checked === true) {
                  setFilterValue({ stock: e.target.checked.toString() });
                } else {
                  setFilterValue({
                    stock: undefined,
                  });
                }
                setIsStockChecked(e.target.checked);
              }}
            />
          }
          label="Stock 0"
        />
      </SwitchWrapper>
      <SelectWrapper sidebarOpen={sidebarOpen}>
        {filterValues?.['warehouseId'] && (
          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel id="warehouseId">Warehouse</InputLabel>
            <Select
              labelId="warehouseId"
              id="warehouseId"
              value={filterValues?.['warehouseId']}
              label="Warehouse"
              onChange={(e) => {
                e.target.value &&
                  setFilterValue({ warehouseId: e.target.value.toString() });
              }}
              style={{ width: '190px' }}
            >
              {filterOptions?.branches.map((item: any) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <StyledForm size="small" fullWidth sidebarOpen={sidebarOpen}>
          <InputLabel id="sorting">Sorting</InputLabel>
          <Select
            labelId="sorting"
            id="sorting"
            value={filterValues?.['sorting']}
            label="Sorting"
            onChange={(e) => {
              setFilterValue({ sorting: e.target.value.toString() });
            }}
            style={{ width: '190px' }}
            defaultValue={StockListSortingType.defaultSorting}
          >
            {sortingOptions.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </StyledForm>
      </SelectWrapper>
    </Container>
  ) : (
    <div>No filters options</div>
  );
};

const Container = styled.div<{ sidebarOpen: boolean }>`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dfe3e8;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding: 16px;
  position: absolute;
  background: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  bottom: 0;
  @media (max-width: 1630px) {
    height: 120px;
    flex-direction: column;
  }

  @media (max-width: 1440px) {
    height: ${(props) => props.sidebarOpen && '150px'};
  }

  @media (max-width: 1330px) {
    height: ${(props) => props.sidebarOpen && '190px'};
  }

  @media (max-width: 1270px) {
    height: ${(props) => (props.sidebarOpen ? '190px' : '150px')};
  }

  @media (max-width: 1150px) {
    height: 190px;
  }
`;

const SwitchWrapper = styled.div<{ sidebarOpen: boolean }>`
  display: flex;
  @media (max-width: 1720px) {
    flex-direction: ${(props) => (props.sidebarOpen ? 'column' : 'row')};
  }

  @media (max-width: 1630px) {
    flex-direction: row;
  }

  @media (max-width: 1330px) {
    flex-direction: ${(props) => props.sidebarOpen && 'column'};
  }

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

const SelectWrapper = styled.div<{ sidebarOpen: boolean }>`
  display: flex;
  @media (max-width: 1440px) {
    flex-direction: ${(props) => (props.sidebarOpen ? 'column' : 'row')};
  }

  @media (max-width: 1270px) {
    flex-direction: column;
  }
`;

const StyledForm = styled(FormControl)<{ sidebarOpen: boolean }>`
  width: 100%;
  margin-left: 10px;
  @media (max-width: 1440px) {
    margin-left: ${(props) => props.sidebarOpen && 0};
    margin-top: ${(props) => props.sidebarOpen && '10px'};
  }

  @media (max-width: 1270px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

export default FilterFooter;
