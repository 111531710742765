import api from '@tyrio/api-factory';
import { DBServiceCategoryApi, DBServicesApi } from '@tyrio/dto';
import { QueryClient, useMutation } from 'react-query';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';

// UPDATE MAIN CATEGORY FOR SERVICES
export const useUpdateServiceCategory = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_service_category'],
    (body: DBServiceCategoryApi['updateOne']['requestBody']) =>
      api.fetch<DBServiceCategoryApi['updateOne']>('update_service_category', {
        ...body,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_service_categories');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateServiceCategory: mutate };
};

// UPDATE SINGLE SERVICE
export const useUpdateService = (queryClient: QueryClient) => {
  const { data, mutate } = useMutation(
    ['update_services'],
    (body: DBServicesApi['updateOne']['requestBody']) =>
      api.fetch<DBServicesApi['updateOne']>('update_services', {
        ...body,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get_service_categories');
        queryClient.refetchQueries('get_service_by_id');
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast('Error', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  return { data, updateService: mutate };
};
