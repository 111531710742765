import { TRule } from '../../typings';

export const SUPERIOR_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EAN']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['ArtNr']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['NMarke']",
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.operate',
    sourceKey: "c.['your_net_price']",
    destination: 'product',
    targetKey: 'priceEUR',
    operationName: 'calculateSuperiorPrice',
  },
];
