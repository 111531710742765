import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled/macro';
import { CircularProgress, Divider, SelectChangeEvent } from '@mui/material';
import { AxiosError } from 'axios';

import { DBTransferOrderItem } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBTransferOrderItemApi,
  DBTransferOrderItemCountByStatus,
  DBTransferOrderItemFilters,
  DBTransferOrderItemResponse,
  SSE_ENTITY_KEYS,
  TransferOrderStatusShape,
} from '@tyrio/dto';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  backIcon,
} from '@tyrio/ui-library';
import NoDataFound from '../../components/NoData/NoDataFound';
import {
  LoaderWrapper,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import StyledPagination from '../../components/Pagination/Pagination';
import { useAuth } from '../../context/AuthContext';
import { useWS } from '../../context/WSContext';
import { useDebounce } from '../../hooks/useDebounce';
import { useFilter } from '../../hooks/useFilter';
import TransferOrderTable from './TransferOrderTable';
import TransferOrderDetails from './details/TransferOrderDetails';
import TransferOrderTableFilters from './filter/TransferOrderTableFilters';

interface TransferOrderOverviewProps {
  sidebarOpen: boolean;
}

const TransferOrderOverview = ({ sidebarOpen }: TransferOrderOverviewProps) => {
  const history = useHistory();
  const [isUploadInProcess, setIsUploadInProcess] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { state, socket } = useWS();

  const [selectedOrderData, setSelectedOrderData] =
    useState<DBTransferOrderItem | null>(null);

  const [originalOrderData, setOriginalOrderData] =
    useState<DBTransferOrderItem | null>(null);

  const originalOrderIdRef = useRef<string | null>(null);

  const { filterValues, ...filtersHook } = useFilter({
    searchType: 'orderId',
    searchKeyword: '',
    source: 'all',
    destination: 'all',
    startDate: undefined,
    endDate: undefined,
    orderStatus: 'all',
  });

  const debouncedSearch = useDebounce({ filterValues, page, pageSize }, 500);

  const { data: countData } = useQuery(
    ['count_orders_by_status', { debouncedSearch }],
    () => getCountData(),
    { enabled: true }
  );

  const getCountData = async () => {
    return await api.fetch<{
      request: DBTransferOrderItemApi['list']['request'];
      requestBody: DBTransferOrderItemApi['list']['requestBody'];
      response: DBTransferOrderItemCountByStatus[];
    }>('count_transfer_orders', {
      ...filterValues,
      page: page.toString(),
      pageSize: pageSize.toString(),
    });
  };

  const { data: transferOrderData, isFetching } = useQuery(
    ['transfer_orders', { debouncedSearch }],
    () =>
      searchTransferOrders({
        ...(filterValues as unknown as DBTransferOrderItemFilters),
        page: page.toString(),
        pageSize: pageSize.toString(),
      }),
    {
      enabled: true,
    }
  );

  const updateOrderMutation = useMutation(
    (reqBody: DBTransferOrderItemApi['updateOne']['request']) => {
      return api.fetch<DBTransferOrderItemApi['updateOne']>(
        'update_transfer_order',
        {
          orderId: reqBody.orderId,
          remark: reqBody.remark,
          orderStatus: reqBody.orderStatus,
          deliveryType: reqBody.deliveryType,
        }
      );
    },
    {
      mutationKey: 'update_transfer_order',
      onSuccess: () => {
        getTableCountData();
        ToastHelper.showToast(
          'Transfer order',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error?.response?.data?.error.name;
          ToastHelper.showToast(
            'Order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? `Update failed!`
          );
        }
        throw error;
      },
    }
  );

  const updateFavoriteStatusMutation = useMutation(
    (id: string) =>
      api.fetch<DBTransferOrderItemApi['getOne']>('to_change_favorite_status', {
        orderId: id,
      }),
    {
      mutationKey: 'to_change_favorite_status',
      onSuccess: () => {
        const isFavorited = selectedOrderData?.favoritedBy.includes(
          user?.id ?? ''
        );
        getTableCountData();
        selectedOrderData &&
          setSelectedOrderData({
            ...selectedOrderData,
            favoritedBy: isFavorited
              ? selectedOrderData.favoritedBy.filter(
                  (item) => item !== user?.id
                )
              : [...selectedOrderData.favoritedBy, user?.id ?? ''],
          });
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          undefined,
          `Order successfully ${
            isFavorited ? 'removed from' : 'added to'
          } favorites`
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Order',
          ToastType.ERROR,
          undefined,
          'An error occured!'
        );
      },
    }
  );

  const searchTransferOrders = async (
    filterValues: DBTransferOrderItemFilters
  ) => {
    return await api.fetch<DBTransferOrderItemApi['list']>(
      'get_transfer_orders',
      {
        ...filterValues,
      }
    );
  };

  const count = transferOrderData?.count;

  const totalPageNumber =
    transferOrderData && count
      ? count / pageSize - Math.floor(count / pageSize) === 0
        ? count / pageSize
        : Math.floor(count / pageSize) + 1
      : 1;

  const setFavorite = () => {
    updateFavoriteStatusMutation.mutate(selectedOrderData?.id ?? '');
  };

  const setLockStatus = () => {
    const key =
      state?.locks &&
      state?.locks[`${SSE_ENTITY_KEYS.TRANSFER_ORDER}/${selectedOrderData?.id}`]
        ? 'unlock'
        : 'lock';

    socket?.emit(key, {
      entityName: SSE_ENTITY_KEYS.TRANSFER_ORDER,
      entityId: selectedOrderData?.id,
    });
  };

  const getTableCountData = () => {
    queryClient.refetchQueries('transfer_orders');
    queryClient.refetchQueries('count_orders_by_status');
  };

  const handleChangePageSize = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
    filtersHook.setFilterValue({ pageSize: event.target.value });
    setPage(1);
  };

  const handleSave = () => {
    const requestBody: DBTransferOrderItemApi['updateOne']['requestBody'] = {
      orderId: selectedOrderData?.id ?? '',
      remark: selectedOrderData?.remark ?? undefined,
      orderStatus:
        selectedOrderData?.orderStatus as unknown as TransferOrderStatusShape,
      deliveryType: selectedOrderData?.deliveryTypeId || null,
    };
    updateOrderMutation.mutate(requestBody);
    setOriginalOrderData(selectedOrderData);
  };

  useEffect(() => {
    if (
      selectedOrderData &&
      selectedOrderData.id !== originalOrderIdRef.current
    ) {
      setOriginalOrderData(selectedOrderData);
      originalOrderIdRef.current = selectedOrderData.id;
    }
  }, [filterValues, selectedOrderData]);

  return (
    <PageTemplateWrapper
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        right: 0,
        position: 'absolute',
      }}
    >
      <Container selectedOrder={selectedOrderData} open={sidebarOpen}>
        <HeaderWrapperTop>
          <BackButton onClick={() => history.push('/dashboard')}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>Warehouse transfer orders</HeaderText>
        </HeaderWrapperTop>
        <Divider />

        <TransferOrderTableFilters
          isSidebarOpen={selectedOrderData !== null}
          filters={filterValues}
          setPage={setPage}
          setFilters={filtersHook.setFilterValue}
          countOrders={countData ?? []}
        />

        {isFetching ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : transferOrderData && transferOrderData.count > 0 ? (
          <>
            <TransferOrderTable
              data={transferOrderData.data || []}
              selectedOrder={selectedOrderData}
              setSelectedOrder={setSelectedOrderData}
              isUploadInProcess={isUploadInProcess}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
            />
            <StyledPagination
              totalPageNumber={totalPageNumber}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              selectedOrderData={selectedOrderData}
              handleChangePageSize={handleChangePageSize}
              menuItems={[20, 50, 100, 200]}
            />
          </>
        ) : (
          <NoDataFound />
        )}
      </Container>
      {selectedOrderData !== null && (
        <TransferOrdersDetailsWrapper>
          <TransferOrderDetails
            selectedOrderData={selectedOrderData as DBTransferOrderItemResponse}
            originalOrderData={originalOrderData as DBTransferOrderItemResponse}
            setSelectedOrderData={setSelectedOrderData}
            setFavorite={setFavorite}
            setLockStatus={setLockStatus}
            handleSave={handleSave}
            isUploadInProcess={isUploadInProcess}
            setIsUploadInProcess={setIsUploadInProcess}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
          />
        </TransferOrdersDetailsWrapper>
      )}
    </PageTemplateWrapper>
  );
};
const Container = styled.div<{
  selectedOrder: DBTransferOrderItem | null;
  open: boolean;
}>`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  width: ${(prop) =>
    prop.selectedOrder === null ? '100%' : `calc(100% - 800px)`};
  transition: linear 0.4s;
  position: relative;
  left: 0;
  height: calc(100svh - 44px);
`;

const TransferOrdersDetailsWrapper = styled.div`
  min-width: 800px;
  max-width: 800px;
  width: 100%;
  margin-left: 16px;
  position: relative;
  right: 0;
  height: (100svh - 44px);
`;

export const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const HeaderWrapperTop = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

export default TransferOrderOverview;
