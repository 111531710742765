import { Content } from '../../../steps/Search/SelectStepper';
import { useVehicleCtx } from '../../../../../context/VehicleContext';
import styled from '@emotion/styled/macro';
import { IName, SearchCard } from './SearchCard';
import { VehicleDetails } from './VehicleDetails';
import { isEmpty, isNull, uniq } from 'lodash';
import { useSearchByModel } from '../query/search-by-model';
import { useGetWheelsFilter } from '../query/get-filter';
import { WheelsSearchFilter } from '../../../constants/wheels-search-filter';
import NoData from '../../DataIndicators/NoData';
import { usePrevious } from '../../../../../hooks/usePrevious';
import { useCallback, useMemo } from 'react';
import { IFilterData, IWheelsApiData } from '@tyrio/dto';
import { CircularProgress } from '@mui/material';
import { LoaderWrapper } from '../../../../../components/PageTemplate/PageTemplate';
import { usePosCartCtx } from '../../../../../context/PosCartContext';

export const SearchFilter = () => {
  const {
    selectedModel,
    selectedBrand,
    selectedGeneration,
    selectedModification,
    setWheelsApiData,
    setBackendFilterData,
    posWheelsFilterData,
    filter,
    apiData,
  } = useVehicleCtx();

  const { setVehicleDetails, setInput, input } = usePosCartCtx();

  const oldFilterData: IFilterData | undefined =
    usePrevious(posWheelsFilterData);

  const onSuccess = (data: IWheelsApiData[]) => {
    setWheelsApiData(data);

    const res = data[0];
    const enginePower = res?.engine?.['power'] as Record<
      string,
      string | number
    >;

    const obj = {
      brand: res?.make?.['slug'],
      model: res?.model?.['slug'],
      engine_power: (enginePower?.['kW'] ?? '').toString(),
    };
    setInput({
      ...input,
      vehicle: obj,
    });
    setVehicleDetails(obj);
  };

  // QUERIES
  const { data, isFetched } = useSearchByModel(
    selectedBrand ?? '',
    selectedModel ?? '',
    selectedGeneration ?? '',
    selectedModification ?? '',
    selectedModification !== '' && !isNull(selectedModification),
    onSuccess
  );

  const { filterData, showLoader } = useGetWheelsFilter(
    apiData?.cb ?? '',
    apiData?.pcd ?? '',
    uniq(apiData?.rimDiameter ?? []),
    !isEmpty(data) && !isEmpty(apiData),
    setBackendFilterData,
    uniq(apiData?.et) ?? []
  );

  const noData =
    filterData && Object.values(filterData).every((s) => isEmpty(s));

  const isFilterEmpty = useMemo(() => {
    return Object.values(filter).every((s) => isEmpty(s) || isNull(s));
  }, [filter]);

  // returns enabled fields
  const compareFilter = useCallback(
    (key: string) => {
      const path = key as unknown as keyof IFilterData;
      const name = path === 'rimSize' ? 'rimDiameter' : path;

      const filterValue = filter[name];
      const oldValues =
        oldFilterData?.[path] === undefined || isEmpty(oldFilterData?.[path])
          ? filterData?.[name] ?? []
          : oldFilterData?.[path] ?? [];

      const newValues = posWheelsFilterData?.[path] ?? [];

      if (isFilterEmpty) return [];

      let takeValue: (string | number | null)[] = oldValues ?? [];

      if (path === 'rimSize') return newValues;

      if (!isEmpty(filterValue)) {
        if (oldValues.length > newValues?.length) takeValue = oldValues;
        else takeValue = newValues;
      } else takeValue = newValues;

      return takeValue;
    },
    [filter, oldFilterData, filterData, posWheelsFilterData, isFilterEmpty]
  );

  const memoizedData = useMemo(() => {
    return WheelsSearchFilter.map(({ key, text, checkboxId }) => {
      const enabled = compareFilter(key) ?? [];
      const value = filterData?.[key] ?? [];

      return (
        <SearchCard
          key={key}
          text={text}
          values={value}
          checkboxId={checkboxId}
          name={key as IName}
          enabledValues={isEmpty(enabled) ? value : enabled}
        />
      );
    });
  }, [compareFilter, filterData]);

  return (
    <Content
      height={isFilterEmpty ? `calc(100vh - 202px)` : `calc(100vh - 242px)`}
      id="search_filter"
      style={{ justifyContent: 'flex-start' }}
    >
      {isFetched && data && <VehicleDetails />}

      {noData ? (
        <div style={{ marginTop: '16px' }}>
          <NoData
            color="warning"
            title="No filters available for current search"
          />
        </div>
      ) : showLoader ? (
        <LoaderWrapper style={{ height: '100svh' }}>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <CardWrapper style={{ flexDirection: 'column' }}>
          {memoizedData}
        </CardWrapper>
      )}
    </Content>
  );
};

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  gap: 16px;
`;
