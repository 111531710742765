import ListItem from '@mui/material/ListItem';
import { ListItemAvatar, ListItemButton, Skeleton } from '@mui/material';
import React from 'react';

export const EDIOrderListSkeleton = () => {
  return (
    <>
      {[0, 1, 2, 3].map((value) => {
        return (
          <ListItem
            key={value}
            secondaryAction={
              <Skeleton variant="rectangular" width={20} height={20} />
            }
            disablePadding
          >
            <ListItemButton disabled>
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
              </ListItemAvatar>
              <Skeleton variant="rectangular" width={180} height={25} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};
