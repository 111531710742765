/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DBClientPriceCalculation,
  DBCustomPriceCatalog,
  Prisma,
} from '@prisma/client';
import { DBProductApi } from './product';

interface PriceCalculationApi {
  list: {
    requestBody: never;
    request: CheckPriceReq;
    response: CheckPriceRes;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: CheckPriceReq;
    requestParams: never;
    request: CheckPriceReq;
    response: CheckPriceRes;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}

export interface CheckPriceReq {
  ean: string;
  supplierId: string;
  priceCalculationId?: string;
  price?: Price;
  isCheckPrice?: boolean;
}

export interface Price {
  purchasePrice: number | null;
  suggestedRetailPrice?: number | null;
  ecoTax?: number | null;
  transportCost?: number | null;
}

export interface CalculatePriceReq {
  ean?: string | null | undefined;
  supplierId: string;
  priceCalculation: DBClientPriceCalculation | null;
  price: Price;
  customPrice?: DBCustomPriceCatalog | null;
}

export interface CalculatePriceRes {
  meta?: PriceCalculationMeta;
  price: string;
}

export interface CheckPriceRes {
  product?: DBProductApi['getOne']['response'];
  meta?: PriceCalculationMeta;
  price: string;
}

export interface PriceCalculationMeta {
  priceCalculation?: Partial<DBClientPriceCalculation> | null;
  priceType?: PriceCalculationType;
  timestamp?: Date;
  appliedRuleId?: string;
  prices?: Price;
  rules?: PriceCalculationMetaRule[];
}

export interface PriceCalculationMetaRule {
  id: string;
  isApplied: boolean;
  inRange: boolean;
  timestamp: Date | null;
  ruleName: string;
  description: string;
  operator: string;
  checks: Record<string, boolean>;
  margin: number | null;
  marginType: string | null;
  useSuggestedRetailPrice: boolean | null;
}

export enum PriceCalculationType {
  DEFAULT_WITH_MARGIN = 'Default price calculation with margin',
  DEFAULT_WITH_SUGGESTED_PRICE = 'Default price calculation with suggested retail price',
  RULE_WITH_ACTION_MARGIN = 'Rule price calculation with margin',
  RULE_WITH_ACTION_SUGGESTED_PRICE = 'Rule price with suggested retail price',
  RULE_WITH_MARGIN_NO_RULES = 'Default price calculation with default margin when no rules for apply',
  RULE_WITH_SUGGESTED_PRICE_NO_RULES = 'Default price calculation with suggested price when no rules for apply',
  RULE_WITH_MARGIN_NO_RANGE = 'Default price calculation with default margin when price is not in action range',
  RULE_WITH_SUGGESTED_PRICE_NO_RANGE = 'Default price calculation with suggested price when price is not in action range',
  CUSTOM_PRICE = 'Custom price',
}

export interface Rule {
  id: string;
  name: string;
  actions: RuleAction[];
  conditions: Prisma.JsonValue;
  newConditions: {
    id: string;
    type: string;
    children1: RuleConditions[];
    properties: { not: boolean; conjunction: string };
  };
  ruleDescription: string;
}

export interface RuleConditions {
  id: string;
  type: string;
  properties: RuleConditionsProperties;
}

export interface RuleConditionsProperties {
  field: string;
  value: any;
  operator: string;
  valueSrc: string[];
  valueType: string[];
}

export interface RuleAction {
  margin: number;
  rebates: number;
  marginType: string;
  price_range_max: number;
  price_range_min: number;
  useSuggestedRetailPrice: boolean;
}

export interface RuleAttributes {
  isNegative: boolean;
  operator: string;
  field: string;
  value: any;
}

export { PriceCalculationApi };
