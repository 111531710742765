import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const DELDO_CATALOG_RULESET: TRule[] = [
  // DEFAULT VALUES
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'pr',
    template: '',
  },
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'sidewall',
    template: '',
  },
  // Category types
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Vehicle',
    condition: 'equals',
    conditionValues: ['Passenger car', 'Jeep / 4x4', 'Light Truck', 'Truck'],
    conditionOutputs: ['30', '31', '32', '50'],
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.model.brand',
    matchValue: [' FS', ' WI', ' ZO', ' SU', ' FS ALL', ' AO', ' ALL', ' all'],
    replaceValue: ['', '', '', '', '', '', '', ''],
  },
  // Season
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Quality',
    condition: 'equals',
    conditionValues: ['Summer', 'Winter', 'All Season'],
    conditionOutputs: ['summer', 'winter', 'allseason'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  // Model name is generated from getSpecialMarkings
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.Pattern',
  //   targetKey: 'modelName',
  //   destination: 'model',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: "c['EAN']",
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: "c['IPCODE']",
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.width',
    matchValue: ['X', 'x', '550'],
    replaceValue: ['', '', '5.50'],
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Height',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.height',
    matchValue: ['105'],
    replaceValue: ['10.50'],
  },
  // OVAJ SUPPLIER NE ŠALJE WEIGHT - tribalo bi ubacit globalni rule weight=(width/10)-10
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.Weight',
  //   targetKey: 'weight',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.Rim',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.rimDiameter',
    matchValue: ['225', '175', '195'],
    replaceValue: ['22.5', '17.5', '19.5'],
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'construction',
    template: 'R',
  },
  // Load index and Speed Index
  {
    type: 'rule.global.operate',
    sourceKey: '',
    targetKey: '',
    destination: 'product',
    operationName: 'globalGetDeldoLoadSpeedIndex',
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'getDeldoSpecialMarkingsAndModelName',
  },
  // ThreePMFs
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Eprel_severe_snow',
    condition: 'equals',
    conditionValues: ['1', '0'],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
  },
  // iceGrip
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Eprel_Ice_Tyre',
    condition: 'equals',
    conditionValues: ['1', '0'],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'iceGrip',
    outputType: 'boolean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Eprel_RR"]',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },

  // Globalni fieldovi
  {
    type: 'rule.map',
    sourceKey: 'c["Eprel_Wet"]',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Eprel_decibel"]',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Eprel_Noise"]',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["C Class"]',
    targetKey: 'vehicleClass',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Eprel_code"]',
    targetKey: 'eprelId',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.["Eprel_code"]',
    condition: 'equals',
    conditionValue: '0',
    outputValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'eprelId',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 't.product.eprelId',
    targetKey: 'eprelUrl',
    destination: 'product',
    postProcess: ['getEprelUrlFromId'],
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.["PR/RF"]',
    condition: 'contains',
    conditionValue: 'XL',
    outputValue: 'XL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Loadindex',
    condition: 'contains',
    conditionValue: 'XL',
    outputValue: 'XL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.["PR/RF"]',
    condition: 'contains',
    conditionValue: 'PR',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.["PR/RF"]',
    destination: 'product',
    targetKey: 'pr',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.["PR/RF"]',
    condition: 'contains',
    conditionValue: 'pr',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.["PR/RF"]',
    destination: 'product',
    targetKey: 'pr',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.pr',
    matchValue: ['PR', 'pr'],
    replaceValue: ['', ''],
  },

  ...GLOBAL_RULESET,

  {
    type: 'rule.map_array',
    sourceKey: 'c.URL_Pattern',
    targetKey: 'originalPhotos',
    delimiter: ',',
    destination: 'model',
    outputType: 'string',
  },
];
