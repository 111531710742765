import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

export const FullScreenLoader = () => (
  <Wrapper>
    <CircularProgress variant="indeterminate" />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
