import { Alert, AlertColor, AlertTitle } from '@mui/material';

const defaultValues = {
  title: 'No products available for your current search',
  subtitle: 'Please choose different criteria',
};

const NoData = ({
  title = defaultValues.title,
  subtitle = defaultValues.subtitle,
  color,
}: {
  title?: string;
  subtitle?: string;
  color?: AlertColor;
}) => {
  return (
    <Alert severity={color ?? 'info'} style={{ width: '100%' }}>
      <AlertTitle>{title}</AlertTitle>
      {subtitle}
    </Alert>
  );
};

export default NoData;
