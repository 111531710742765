/* eslint-disable @typescript-eslint/no-empty-function */
import { AppointmentDetails } from '@tyrio/dto';
import React, { createContext, useState } from 'react';

export interface DeliveryAddressShape {
  customer: string;
  recipientName: string;
  address: string;
  zipCode: string;
  city: string;
  mobilePhone?: string;
}

export interface BillingAddressShape {
  customer: string;
  vat: string;
  address: string;
  zipCode: string;
  city: string;
  email?: string;
}

export type DocumentType = 'OTPREMNICA' | 'MEĐUSKLADIŠNICA' | 'WORK ORDER';

export interface LineItemPrintShape {
  ean: string;
  productName: string;
  sku: string;
  brand: string;
  dot: string;
  quantity: number;
  weight: string;
  category: string;
}

export interface ProductsTableProperties {
  showWeight: boolean;
  showQuantity: boolean;
  showTotalWeight: boolean;
  showTotalFooter: boolean;
  columnCount: number;
}

export interface RowDetail {
  name: string;
  details: string;
  weight?: number;
  quantity: number;
  totalWeight?: number;
}

export interface PrintDataShape {
  data: {
    documentsLook: {
      logoUrl: string;
      headerText: string;
      mainTerms: string;
      paymentTerms: string;
      footerText: string;
    };
    header: {
      documentType: DocumentType;
      documentNumber: string;
    };
    address: {
      showDeliveryAddress: boolean;
      showBillingAddress: boolean;
      deliveryAddress?: DeliveryAddressShape;
      billingAddress?: BillingAddressShape;
    };
    table: ProductsTableProperties;
    sourceBranch: {
      branchName: string;
      city: string;
    };
    appointmentsDetails: {
      hasAppointments: boolean;
      appointments?: AppointmentDetails[];
    };
    orderDetails: {
      lineItems: LineItemPrintShape[];
      orderNumber: string;
      deliveryDate: Date;
    };
    shippingDetails: {
      showShippingDetails: boolean;
      recipientName: string;
      city: string;
      hasSideBorders: boolean;
      deliveryMethod: string;
    };
    issuedBy: string;
    showRemark: boolean;
    remark: string;
    reference: string;
    showConfirmationHeader: boolean;
  };
}

type PrintContextInterface = {
  pageNumber: number;
  setPageNumber: (page: number) => void;
  numberOfPages: number;
  setNumberOfPages: (numberOfPages: number) => void;
};

export const PrintContext = createContext<PrintContextInterface>({
  pageNumber: 1,
  setPageNumber: () => {},
  numberOfPages: 1,
  setNumberOfPages: () => {},
});

interface PrintProviderProps {
  children: React.ReactNode;
}

export const PrintProvider = ({ children }: PrintProviderProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  return (
    <PrintContext.Provider
      value={{
        pageNumber,
        setPageNumber,
        numberOfPages,
        setNumberOfPages,
      }}
    >
      {children}
    </PrintContext.Provider>
  );
};

export default PrintProvider;

export const usePrintContext = () => React.useContext(PrintContext);
