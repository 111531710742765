import styled from '@emotion/styled';
import { TableCell, TableRow } from '@mui/material';

export const textStyle = {
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '500',
};

export const textStyleOther = { ...textStyle, width: '80px' };

export const MainBranchWrapper = styled.div`
  display: flex;
  width: 100px;
  justify-content: center;
`;

export const BranchNamesWrapper = styled.div<{ shouldEnlarge: boolean }>`
  display: flex;
  max-width: 480px;
  width: 100%;
  padding: 0px 10px;
  div {
    width: 100px !important;
  }
`;

export const BranchNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ItemsWrapper = styled.div<{ shouldEnlarge: boolean }>`
  background: #f4f6f8;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px 0 0 8px;
  padding: 8px 10px;
  max-width: ${(props) => (props.shouldEnlarge ? '' : '480px')};
  float: right;
  margin-bottom: 8px;
  margin-left: ${(props) => (props.shouldEnlarge ? '20px' : '')};
`;

export const ItemWrapper = styled.div<{ borderR: boolean }>`
  background: #f4f6f8;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: ${(props) => (props.borderR ? '8px 0 0 8px' : '0')};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: white;
  position: sticky;
  bottom: 0px;
  flex-direction: column;
  align-items: center;
`;

export const StyledTableRow = styled(TableRow)`
  display: flex;
  margin: 0;
  padding: 0;
  padding-top: 20px;
`;

export const StyledTableCell = styled(TableCell)`
  background: white;
  position: sticky;
  left: 0;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  z-index: 20;
`;

export const StyleTableHeadCell = styled(TableCell)`
  width: 100px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid #e6e8f0;
`;
