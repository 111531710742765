export const services = [
  {
    id: '00000000-0000-1000-c000-0000000000001',
    name: 'Installation and balancing of car tires 12ʺ-14ʺ',
    description: 'Description',
    servicePrice: {
      chargedByLaborTime: {
        laborTime: 12,
      },
    },
    subcategory: [
      { label: 'Osobna vozila', value: '30' },
      { label: '4x4 / Suv vozila', value: '31' },
    ],
    rimDiameter: [
      { label: '13', value: '13' },
      { label: '12', value: '12' },
    ],
    rft: false,
    deleted: false,
    active: true,
    productCategoryId: 1,
    serviceCategoryId: '00000000-0000-1000-s000-0000000000001',
    code: 'C001',
    serviceCodeId: '00000000-0000-1000-s100-0000000000001',
    canDelete: false,
  },
  {
    id: '00000000-0000-1000-c000-0000000000002',
    name: 'Installation and balancing of car tires 13ʺ-14ʺ',
    description: 'Description',
    servicePrice: {
      fixedPrice: {
        exclusiveVat: 10,
        inclusiveVat: 22,
      },
    },
    subcategory: [
      { label: 'Osobna vozila', value: '30' },
      { label: '4x4 / Suv vozila', value: '31' },
    ],
    rimDiameter: [
      { label: '13', value: '13' },
      { label: '14', value: '14' },
    ],
    rft: false,
    deleted: false,
    active: true,
    productCategoryId: 1,
    serviceCategoryId: '00000000-0000-1000-s000-0000000000002',
    code: 'C001',
    serviceCodeId: '00000000-0000-1000-s100-0000000000002',
    canDelete: false,
  },
];
