import {
  TyrioInputCheckbox,
  TyrioInputRadio,
  TyrioInputSelect,
  TyrioInputString,
  TyrioInputMultiselect,
  TyrioInputSwitch,
} from '@tyrio/forms';
import { FormShape, InputType } from '../constants/service.form';
import styled from '@emotion/styled';
import { Box, Divider } from '@mui/material';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { has } from 'lodash';

export const renderFields = (
  fields: FormShape,
  register: UseFormRegister<FieldValues>,
  control: Control,
  errors: FieldErrors,
  showField?: boolean,
  key?: 'services' | 'payment' | 'delivery' | 'couriers'
) => {
  const accessNestedErrors = (path: string) => {
    const keys = path.split('.');
    let current = errors;

    for (const key of keys) {
      if (current && has(current, key)) current = current[key];
      else return undefined;
    }

    return current;
  };

  const isFieldVisible = (item: InputType) => {
    if (item.skipField === true) return showField;
    else return true;
  };

  return (
    <FieldsWrapper hasGap={key === 'payment' || key === 'delivery'}>
      {fields.inputs.map((item) => {
        const isVisible = isFieldVisible(item);

        return (
          <Box
            sx={isVisible ? { width: item.width } : null}
            style={
              key === 'delivery'
                ? {
                    marginTop: '15px',
                  }
                : {}
            }
            key={item.id}
          >
            {item.type === 'input.string' && isVisible ? (
              <TyrioInputString
                item={item}
                id={item.id}
                register={register}
                error={errors[item.id] ?? accessNestedErrors(item.id)}
                helperText={item.helperText}
                disableUnderline={item.variant === 'standard' ? true : false}
                isTooltipVisible={item.id === 'restrictions.maxAmount'}
              />
            ) : item.type === 'input.select' && isVisible ? (
              <TyrioInputSelect
                id={item.id}
                item={item}
                control={control}
                register={register}
                error={errors[item.id]}
              />
            ) : item.type === 'input.radio' && isVisible ? (
              <TyrioInputRadio
                item={item}
                id={item.id}
                control={control}
                error={errors[item.id]}
              />
            ) : item.type === 'input.checkbox' && isVisible ? (
              <TyrioInputCheckbox
                id={item.id}
                item={item}
                register={register}
                error={errors[item.id]}
              />
            ) : item.type === 'input.multiselect' && isVisible ? (
              <TyrioInputMultiselect
                id={item.id}
                item={item}
                control={control}
              />
            ) : item.type === 'input.switch' && isVisible ? (
              <TyrioInputSwitch id={item.id} item={item} control={control} />
            ) : item.type === 'input.divider' && isVisible ? (
              <Divider />
            ) : null}
          </Box>
        );
      })}
    </FieldsWrapper>
  );
};

const FieldsWrapper = styled.div<{ hasGap?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: ${(props) => (props.hasGap ? '26px' : null)};
`;
