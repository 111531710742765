import { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { Divider } from '@mui/material';
import { DBService, DBServiceCategory } from '@prisma/client';
import { PosCartContext } from '../../../../../context/PosCartContext';
import { Card } from './Card';

export const Recommended = () => {
  const { recommended } = useContext(PosCartContext);
  return (
    <RecommendedWrapper>
      <RecommendedHeader>Recommended</RecommendedHeader>
      <Divider />
      <RecommendedCardsWrapper>
        {recommended.length > 0 &&
          recommended.map(
            (
              item: DBService & { serviceCategory: DBServiceCategory },
              index: number
            ) => <Card key={index} service={item} />
          )}
      </RecommendedCardsWrapper>
    </RecommendedWrapper>
  );
};

const RecommendedWrapper = styled.div`
  height: 94%;
  width: 100%;
  // padding: 10px;
`;

const RecommendedHeader = styled.div`
  margin-bottom: 10px;
  font-family: Barlow;
  font-size: 20px;
`;

const RecommendedCardsWrapper = styled.div`
  margin-top: 20px;
  max-height: calc(100svh - 120px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
