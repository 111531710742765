import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
import {
  genericGenerateSpecialMarkings,
  splitSpecialMarkingString,
} from './operations.helpers';

export function generateContinentalSpecialMarkings(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateContinentalSpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = [input['PROD_INFO'], input['RUN_FLAT']].join(' ');

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => input['RUN_FLAT'] === 'SSR',
    false
  );

  if (!data?.product?.['specialMarking']) {
    data.product = {
      ...data.product,
      specialMarking: '',
    };
  }
  const splitSpecialMarkings = splitSpecialMarkingString(
    data.product['specialMarking']
  );

  if (input['FLANK'] === 'Y') {
    splitSpecialMarkings.push('FR');
  }

  if (
    input['DESCRIPTION_1'].toLowerCase().endsWith('sil') ||
    input['DESCRIPTION_1'].toLowerCase().endsWith('silent')
  ) {
    splitSpecialMarkings.push('SILENT');
  }

  if (input['DESIGN_1'].toLowerCase().includes('econtact')) {
    splitSpecialMarkings.push('ELECTRIC');
  }

  data.product['specialMarking'] = [...new Set(splitSpecialMarkings)]
    .join(' ')
    .replace(/ +(?= )/g, '');

  return data;
}

export function runTodoField(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.operate')
    throw new Error('runTodoField requires rule to be of type TOperateRule');

  return 'TODO: Functionality in progress';
}
