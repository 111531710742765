import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  AccordionDetails,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import api from '@tyrio/api-factory';
import moment from 'moment';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useWSAdmin } from '../../../context/WSAdminContext';
import { CatalogImportPayload } from '../../../features/catalog/CatalogSidebar';

// eslint-disable-next-line
const safeParseDate = (data?: any) => {
  if (!data) return 'N/A';
  const date = moment(data);

  return date.format('LLL');
};

export const ImportStatusAdminTable = () => {
  const status = useWSAdmin();

  console.log(status.state?.admin ?? {});

  const { mutate } = useMutation(
    ['import_catalog'],
    (payload: CatalogImportPayload) =>
      api.fetch<{
        request: CatalogImportPayload;
        requestBody: CatalogImportPayload;
        response: any;
      }>('import_catalog', payload)
  );

  return (
    <div>
      <Accordion
        sx={{
          boxShadow: 'none',
          border: '1px dashed #DFE3E8',
          marginBottom: 2,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Tyrio Admin</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Source
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Connection name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Last scheduled
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Last started
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Last finished
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Items found
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Valid items
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      New items
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Next schedule
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    >
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={12}
                      fontWeight={300}
                      letterSpacing={0.3}
                    ></Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(status?.state?.admin ?? {})
                  .sort((keyA: string, keyB: string) => {
                    const itemA = status?.state?.admin[keyA];
                    const itemB = status?.state?.admin[keyB];

                    if (!itemA || !itemB) return 0;

                    const a = [
                      itemA.details?.source,
                      itemA.details?.connectionName,
                    ]
                      .filter((x) => !!x)
                      .join(' ');
                    const b = [
                      itemB.details?.source,
                      itemB.details?.connectionName,
                    ]
                      .filter((x) => !!x)
                      .join(' ');

                    return a.localeCompare(b);
                  })
                  .map((key: string) => {
                    const item = status?.state?.admin[key];
                    if (!item) return null;
                    if (!item.details) return null;

                    return (
                      <TableRow key={`${item.name}`}>
                        <TableCell>{item.details.source}</TableCell>
                        <TableCell>{item.details.connectionName}</TableCell>
                        <TableCell>
                          {safeParseDate(item.details.lastScheduled) ?? 'N/A'}
                        </TableCell>
                        <TableCell>
                          {safeParseDate(item.details.lastStarted) ?? 'N/A'}
                        </TableCell>
                        <TableCell>
                          {safeParseDate(item.details.lastFinished) ?? 'N/A'}
                        </TableCell>
                        <TableCell>
                          {item.details.itemsFound ?? 'N/A'}
                        </TableCell>
                        <TableCell>
                          {item.details.validItems ?? 'N/A'}
                        </TableCell>
                        <TableCell>{item?.details?.newProducts ?? 0}</TableCell>
                        <TableCell>
                          {safeParseDate(item.details.nextSchedule) ?? 'N/A'}
                        </TableCell>
                        <TableCell>{item.details.status}</TableCell>
                        <TableCell
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <MenuItem
                            disabled={item.details.status === 'running'}
                            onClick={() => {
                              if (item?.details?.status === 'running') return;

                              if (!item.details?.connectionId) {
                                alert(
                                  'No connection ID provided. Please contact support.'
                                );
                                return;
                              }
                              if (!item.details?.supplierId) {
                                alert(
                                  'No supplier ID provided. Please contact support.'
                                );
                                return;
                              }
                              mutate({
                                connectionId: item.details?.connectionId,
                                supplierId: item.details.supplierId,
                                supplierShape: 'NOT_REQUIRED',
                              });
                            }}
                          >
                            {item.details.status !== 'running' && (
                              <RefreshIcon sx={{ marginRight: '8px' }} />
                            )}{' '}
                            Update now
                          </MenuItem>
                          {item.details.status === 'running' && (
                            <MenuItem
                              onClick={() => {
                                if (!item.details?.connectionId) {
                                  alert(
                                    'No connection ID provided. Please contact support.'
                                  );
                                  return;
                                }
                                if (!item.details?.supplierId) {
                                  alert(
                                    'No supplier ID provided. Please contact support.'
                                  );
                                  return;
                                }
                                mutate({
                                  connectionId: item.details?.connectionId,
                                  supplierId: item.details.supplierId,
                                  supplierShape: 'NOT_REQUIRED',
                                });
                              }}
                            >
                              <RefreshIcon sx={{ marginRight: '8px' }} />
                            </MenuItem>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
