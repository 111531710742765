import { Grid } from '@mui/material';
import { ProductImages } from '@tyrio/products';
import React from 'react';
import { FormGridProps } from '../types';

interface TyrioComponentImagesProps {
  gridProps: FormGridProps;
}
export const TyrioComponentImages = ({
  gridProps,
}: TyrioComponentImagesProps) => {
  return (
    <Grid {...gridProps}>
      <ProductImages
        data={[
          {
            source:
              'https://w7.pngwing.com/pngs/959/1001/png-transparent-performance-car-continental-tire-tread-tires-car-performance-car-vehicle.png',
            title: 'Guma gumicasss',
            type: 'link',
          },
          {
            source:
              'https://w7.pngwing.com/pngs/428/334/png-transparent-car-pirelli-tyre-s-p-a-tire-rim-car.png',
            title: 'Gumesx',
            type: 'link',
          },
        ]}
      />
    </Grid>
  );
};
