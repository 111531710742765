import styled from '@emotion/styled/macro';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl/FormControl';
import TextField from '@mui/material/TextField';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormGridProps,
  TyrioMultiselectInput,
  TyrioSelectInput,
} from '../types';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';

interface TyrioInputMultiselectProps {
  gridProps?: FormGridProps;
  item: TyrioMultiselectInput;
  id: string;
  control: Control;
  filter?: string;
}

export const TyrioInputMultiselect = ({
  gridProps,
  item,
  id,
  control,
  filter,
}: TyrioInputMultiselectProps) => {
  const { t } = useTranslation();
  return !item.hidden ? (
    <GridContainer item {...gridProps} hasPadding={item?.hasPadding ?? true}>
      <FormControl fullWidth>
        <Controller
          name={id}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                multiple
                fullWidth
                id="multiselect-special-markings"
                options={item.options
                  .filter((i) => i.value !== filter)
                  .map((el) => el)}
                disableCloseOnSelect
                filterSelectedOptions
                disabled={item.disabled}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.label}
                defaultValue={
                  value !== undefined && !isEmpty(value)
                    ? value.map((el: TyrioSelectInput) => el)
                    : []
                }
                value={value ?? []}
                onChange={(__, value, reason) => {
                  if (reason === 'clear') onChange([]);
                  else onChange(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t(`${item.label}`)} />
                )}
                style={{ background: 'white', width: '100%' }}
              />
            );
          }}
        />
      </FormControl>
    </GridContainer>
  ) : null;
};

const GridContainer = styled(Grid)<{
  hasPadding?: boolean;
}>`
  padding-bottom: ${(props) => (props.hasPadding ? '22px' : null)};
`;
