import api from '@tyrio/api-factory';
import { DBClientRebateCalculationApi } from '@tyrio/dto';
import {
  LandingPage,
  SearchableVerticalList,
  SearchableVerticalListDataType,
} from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import { ExpandableLayout } from '../../layouts/ExpandableLayout';
import routeNames from '../../lib/routeNames';

interface CustomerRebatesProps {
  children: React.ReactNode;
}

const CustomerRebates = ({ children }: CustomerRebatesProps) => {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);
  const pageSize = 10;

  const {
    isFetching,
    isError,
    data: rebatesData,
  } = useQuery(
    ['get_rebates', { debouncedSearch, page }],
    () => searchRebates(search, page),
    {}
  );

  const [selected, setSelected] = useState<Record<string, boolean>>(
    location.pathname.split('/').pop() !== 'customer-rebates'
      ? { [location.pathname.split('/').pop()]: true }
      : {}
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (type: string, item?: any) => {
    Object.keys(selected).forEach((key) => {
      selected[key] = false;
    });
    if (type === 'itemClicked') {
      history.push(`/dashboard/customer-rebates/${item.id}`);
    }
    if (type === 'new') {
      history.push(`/dashboard/customer-rebates/new`);
    }
  };

  useEffect(() => {
    if (location.pathname.split('/').pop() !== 'customer-rebates')
      setSelected({ [location.pathname.split('/').pop()]: true });
    else setSelected({});
  }, [location.pathname]);

  const searchRebates = async (search: string, page: number) => {
    return await api.fetch<DBClientRebateCalculationApi['list']>(
      'get_rebates',
      {
        search,
        page,
        pageSize,
      }
    );
  };

  useEffect(() => {
    setPage(1);
    if (location.pathname === path) setSelected({});
  }, [debouncedSearch, location.pathname, path]);
  return (
    <ExpandableLayout
      sidebarComponent={
        <SearchableVerticalList
          header="Customer rebates"
          type={SearchableVerticalListDataType.WITHOUT_CHILDREN}
          data={
            rebatesData?.data?.map((item) => ({
              id: item.id,
              name: item.groupName,
              type: item.groupType,
              active: item.isActive,
              disabled: !item.isActive,
              isSelected: selected[item.id],
            })) || []
          }
          path={routeNames.dashboard()}
          isFetching={isFetching}
          isError={isError}
          onSearchChange={setSearch}
          onCreateNew={() => handleClick('new', {})}
          handleItemClick={(item) => handleClick('itemClicked', item)}
          onChangePage={(page) => setPage(page)}
          count={rebatesData?.count}
          pageNumber={page}
        />
      }
      landingPage={
        <LandingPage
          title={'Welcome to Customer rebates'}
          subtitle={'Choose rebate from the list or create new.'}
          buttonText={'Add new rebate'}
          onClick={() => handleClick('new', {})}
        />
      }
    >
      {children}
    </ExpandableLayout>
  );
};

export default CustomerRebates;
