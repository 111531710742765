import styled from '@emotion/styled/macro';

import { useContext } from 'react';
import {
  Container,
  TabsWrapper,
  Wrapper,
} from '../../../transfer-orders/details/TransferOrderDetailsTabs';
import Grid from '@mui/material/Grid/Grid';
import Box from '@mui/material/Box/Box';
import { SearchVehicle } from '../SearchVehiclePOS/SearchVehicle';
import { PosSearchContext } from './SearchContext';
import {
  TabPanelProps,
  TabsStyled,
  TabStyled,
  tabProps,
} from '../../../../components/Tabs/Tabs';

const TabContent = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return <Grid>{value === index && <Box sx={{}}>{children}</Box>}</Grid>;
};

interface TabSelectorProps {
  category?: string;
  children?: JSX.Element;
}

export const TabSelector = ({ category, children }: TabSelectorProps) => {
  const { activeTab, setActiveTab, categoryId } = useContext(PosSearchContext);
  const categoryName = category && category.split('|')[1].toLocaleUpperCase();

  const isWheels = categoryId === 8; //category ids are hardcoded in db

  return (
    <Container id="#tab_selector">
      <Wrapper>
        <TabsWrapper>
          <TabsStyled value={activeTab} onChange={(_e, v) => setActiveTab(v)}>
            <TabStyled label="Search by Size" {...tabProps(0)} />
            {isWheels && (
              <TabStyled
                label="Search by vehicle"
                {...tabProps(0)}
                datatype="search_by_vehicle"
              />
            )}
          </TabsStyled>
          {category && <SelectedCategory>{categoryName}</SelectedCategory>}
        </TabsWrapper>

        <TabContent value={activeTab} index={0}>
          {children}
        </TabContent>

        {isWheels && (
          <TabContent value={activeTab} index={1}>
            <SearchVehicle />
          </TabContent>
        )}
      </Wrapper>
    </Container>
  );
};
const SelectedCategory = styled.div`
  position: absolute;
  right: 0;
  color: #e75524;
  font-size: 14px;
  font-weight: 400;
`;
