import * as z from "zod"
import { DBTransferOrderType } from ".prisma/client"
import { CompleteDBClientTransferOrder, RelatedDBClientTransferOrderModel, CompleteDBClient, RelatedDBClientModel, CompleteDBUser, RelatedDBUserModel, CompleteDBOrderDocument, RelatedDBOrderDocumentModel, CompleteDBDeliveryTypes, RelatedDBDeliveryTypesModel, CompleteDBTrackingInfo, RelatedDBTrackingInfoModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBTransferOrderItemModel = z.object({
  id: z.string(),
  orderId: z.string(),
  clientId: z.string(),
  orderStatus: jsonSchema,
  reference: z.string(),
  createdById: z.string().nullish(),
  createdAt: z.date(),
  lastEditedBy: z.string().nullish(),
  lastEditTime: z.date().nullish(),
  remark: z.string(),
  lockedBy: z.string().nullish(),
  favoritedBy: z.string().array(),
  inboundBranchId: z.number().int(),
  inboundBranchDetails: jsonSchema,
  outboundBranchId: z.number().int(),
  outboundBranchDetails: jsonSchema,
  lineItems: jsonSchema,
  total: z.number().int(),
  deliveryDate: z.date(),
  supplier: z.string().nullish(),
  buyerInfo: jsonSchema,
  type: z.nativeEnum(DBTransferOrderType).nullish(),
  deliveryTypeId: z.string().nullish(),
  orderNumber: z.string().nullish(),
  stockOutData: jsonSchema,
  erpOrderId: z.string().nullish(),
  erpDocNumber: z.string().nullish(),
})

export interface CompleteDBTransferOrderItem extends z.infer<typeof DBTransferOrderItemModel> {
  order: CompleteDBClientTransferOrder
  client: CompleteDBClient
  createdBy?: CompleteDBUser | null
  DBOrderDocument: CompleteDBOrderDocument[]
  deliveryType?: CompleteDBDeliveryTypes | null
  trackingInfoData: CompleteDBTrackingInfo[]
}

/**
 * RelatedDBTransferOrderItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBTransferOrderItemModel: z.ZodSchema<CompleteDBTransferOrderItem> = z.lazy(() => DBTransferOrderItemModel.extend({
  order: RelatedDBClientTransferOrderModel,
  client: RelatedDBClientModel,
  createdBy: RelatedDBUserModel.nullish(),
  DBOrderDocument: RelatedDBOrderDocumentModel.array(),
  deliveryType: RelatedDBDeliveryTypesModel.nullish(),
  trackingInfoData: RelatedDBTrackingInfoModel.array(),
}))
