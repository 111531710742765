import api from '@tyrio/api-factory';
import { WheelSizeApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useGetBrands = () => {
  const [brands, setBrands] = useState<TyrioSelectInputOption[]>([]);

  const query = useQuery(
    ['get_brands'],
    () => api.fetch<WheelSizeApi['getBrands']>('get_brands'),
    {
      onSuccess(data) {
        setBrands(
          data.data
            ? data.data.map((item) => ({
                value: item.slug,
                label: item.name,
              }))
            : []
        );
      },
    }
  );

  return { brands, ...query };
};
