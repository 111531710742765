import { TextField } from '@mui/material';
import { DBFile } from '@prisma/client';
import api, { ENDPOINTS } from '@tyrio/api-factory';
import { DBDocumentsLookApi, DBDocumentsLookPayload } from '@tyrio/dto';
import SourceContainer from '@tyrio/forms';
import {
  CancelModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { PageTemplateContent } from '../../../components/PageTemplate/PageTemplate';
import { textsForm } from '../../../features/documents-look/form/texts.form';
import { InputType } from '../../../features/services/constants/service.form';
import {
  Barlow,
  BodyWrapper,
  FormWrapper,
  SectionWrapper,
  SourceContainerDivider,
  TitleWrapper,
  Wrapper,
} from './style';
import { UploadLogo } from './UploadLogo';

export const DocumentsLookPage = () => {
  const [logoUrl, setLogoUrl] = useState('');
  const [logoId, setLogoId] = useState('');
  const [textsPayload, setTextsPayload] = useState<Record<string, string>>({
    headerText: '',
    mainTermsText: '',
    paymentTermsText: '',
    footerText: '',
  });
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  // get existing documents look
  const { data, refetch } = useQuery(
    'get_documents-look',
    () => api.fetch<DBDocumentsLookApi['get']>('get_documents_look'),
    {
      onSuccess: (data: DBDocumentsLookApi['get']['response']) => {
        if (data && data.logo && data.logo.fileUrl) {
          setLogoUrl(data?.logo.fileUrl ?? '');
          setLogoId(data?.logo.id);
        }
        setTextsPayload({
          headerText: data?.headerText ?? '',
          mainTermsText: data?.mainTermsText ?? '',
          paymentTermsText: data?.paymentTermsText ?? '',
          footerText: data?.footerText ?? '',
        });
      },
    }
  );

  // image upload
  const { mutate: uploadLogo, isLoading } = useMutation(
    (formData: {
      method: string;
      url: string;
      data: FormData;
      headers: {
        Authorization: string;
        'Content-Type': string;
      };
    }) => axios.request(formData),
    {
      mutationKey: 's3_add_documents_look_logo',
      onSuccess: (res: { data: DBFile }) => {
        setLogoId(res.data.id);
        setLogoUrl(res.data.fileUrl);
      },
      onError: () => {
        ToastHelper.showToast(
          'Documents look',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const onImageChange = (acceptedFiles: File[]) => {
    if (acceptedFiles !== null && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      const token = window.localStorage.getItem('@@apiToken');

      form.append('file', file, 'documents-look-logo');

      const options = {
        method: 'PUT',
        url: ENDPOINTS.upload_documents_look_logo.uri,
        data: form,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
        },
      };

      uploadLogo(options);
    }
  };

  // create documents look
  const createDocumentsLook = useMutation(
    (payload: DBDocumentsLookPayload) =>
      api.fetch<DBDocumentsLookApi['create']>('create_documents_look', {
        ...payload,
      }),
    {
      mutationKey: 'create_documents_look',
      onSuccess: () => {
        ToastHelper.showToast(
          'Documents look',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.message.name;
          ToastHelper.showToast(
            'Documents look',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'Error occured'
          );
        }
        throw error;
      },
    }
  );

  // update documents look
  const updateDocumentsLook = useMutation(
    (payload: DBDocumentsLookPayload) =>
      api.fetch<DBDocumentsLookApi['update']>('update_documents_look', {
        ...payload,
      }),
    {
      mutationKey: 'update_documents_look',
      onSuccess: () => {
        ToastHelper.showToast(
          'Documents look',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.message.name;
          ToastHelper.showToast(
            'Documents look',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'Error occured'
          );
        }
        throw error;
      },
    }
  );

  const handleSave = () => {
    const body = {
      logoId,
      headerText: textsPayload['headerText'],
      mainTermsText: textsPayload['mainTermsText'],
      paymentTermsText: textsPayload['paymentTermsText'],
      footerText: textsPayload['footerText'],
    };
    if (data) updateDocumentsLook.mutateAsync(body);
    else createDocumentsLook.mutateAsync(body);
  };

  return (
    <PageTemplateContent>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            refetch();
            setIsCancelModalVisible(false);
            setTextsPayload({});
            setLogoUrl('');
          }}
        />
      )}
      <TitleWrapper>A4 Documents look</TitleWrapper>
      <Wrapper>
        <BodyWrapper>
          <UploadLogo
            logoUrl={logoUrl}
            setLogoUrl={setLogoUrl}
            onImageChange={onImageChange}
            isUploading={isLoading}
          />
          <SectionWrapper height={'100%'}>
            <FormWrapper>
              <Barlow>Texts</Barlow>
              <form onSubmit={handleSave} style={{ width: '100%' }}>
                {textsForm.inputs.map((field: InputType) => {
                  return (
                    <div style={{ paddingBottom: '25px' }} key={field.id}>
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        id={field.id}
                        label={field.label}
                        type="text"
                        sx={{ marginBottom: '15px' }}
                        value={textsPayload[field.id]}
                        onChange={(e) =>
                          setTextsPayload((prevState) => ({
                            ...prevState,
                            [field.id]: e.target.value,
                          }))
                        }
                      />
                    </div>
                  );
                })}
              </form>
            </FormWrapper>
          </SectionWrapper>
        </BodyWrapper>
        <SourceContainerDivider>
          <SourceContainer
            data={{
              data_source: 'source',
              created: 'created',
              last_edited: 'edited',
            }}
            customStyle={{ position: 'unset' }}
            onCancel={() => setIsCancelModalVisible(true)}
            disabledDelete={true}
            disableSave={isLoading}
            onSubmit={handleSave}
          />
        </SourceContainerDivider>
      </Wrapper>
    </PageTemplateContent>
  );
};
