import { DBLogs } from '@prisma/client';

// export const LogKeys: Record<string, string> = {
//   RITAM_CRON: 'RITAM_CRON',
// };

export enum LogKeys {
  RITAM_CRON = 'RITAM_CRON',
  RITAM_ORDER = 'RITAM_ORDER',
  RITAM_ARTICLE = 'RITAM_ARTICLE',
  RITAM_INVOICE = 'RITAM_INVOICE',
}

type DBCreateLog = Omit<DBLogs, 'id' | 'createdAt' | 'updatedAt'>;

export interface QueryParams {
  key?: string;
  time?: 'today' | 'monthly' | 'weekly' | 'all';
}

export interface DBLogsApi {
  create: {
    requestBody: DBCreateLog;
    requestParams: never;
    request: DBCreateLog;
    response: never;
  };
}
