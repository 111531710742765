/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioCustomButtonInput } from '../types';

interface TyrioCustomButtonProps {
  item: TyrioCustomButtonInput;
  id: string;
  gridProps: FormGridProps;
  register: UseFormRegister<FieldValues>;
}

export const TyrioCustomButton = ({
  item,
  id,
  gridProps,
  register,
}: TyrioCustomButtonProps) => {
  const { t } = useTranslation();
  if (item.hidden) return null;
  return (
    <GridContainer item {...gridProps}>
      <ButtonContainer>
        <Button onClick={() => item.onClick()}>{t('DELETE CONNECTION')}</Button>
      </ButtonContainer>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 8.3%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  //   margin-left: 16px;
  cursor: pointer;
`;

const Button = styled.div`
  width: 100%;
  height: 25px;
  color: red;
  background: none;
  font-weight: 500;
  font-size: 12px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
`;
