import { DBServiceCategory } from '@prisma/client';

export const serviceCategories: DBServiceCategory[] = [
  {
    id: '00000000-0000-1000-s000-0000000000001',
    name: 'Installation of car tires',
    description: 'Mount',
    vatRate: '25',
    unitOfMeasurment: 'DAY',
    generalLedgeAcc: '0001',
    deleted: false,
    active: true,
    clientId: '00000000-0000-1000-b000-0000000000022',
    code: 'C001',
    serviceCategoryCodeId: '00000000-0000-1000-c100-0000000000001',
    canDelete: false,
  },
  {
    id: '00000000-0000-1000-s000-0000000000002',
    name: 'Installation of motorcycle tires',
    description: 'Mount',
    vatRate: '25',
    unitOfMeasurment: 'DAY',
    generalLedgeAcc: '0002',
    deleted: false,
    active: true,
    clientId: '00000000-0000-1000-b000-0000000000022',
    code: 'C002',
    serviceCategoryCodeId: '00000000-0000-1000-c100-0000000000002',
    canDelete: false,
  },
];
