import * as z from "zod"
import { DBShopTypesEnum, DBShopSalesChannels } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel } from "./index"

export const DBShopTypesModel = z.object({
  id: z.string(),
  clientId: z.string(),
  code: z.string(),
  shopName: z.string(),
  shopURL: z.string(),
  shopType: z.nativeEnum(DBShopTypesEnum).nullish(),
  salesChannel: z.nativeEnum(DBShopSalesChannels).nullish(),
  isActive: z.boolean(),
  branchId: z.number().int().nullish(),
})

export interface CompleteDBShopTypes extends z.infer<typeof DBShopTypesModel> {
  client: CompleteDBClient
  branch?: CompleteDBBranch | null
  orders: CompleteDBCustomerOrderItem[]
}

/**
 * RelatedDBShopTypesModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBShopTypesModel: z.ZodSchema<CompleteDBShopTypes> = z.lazy(() => DBShopTypesModel.extend({
  client: RelatedDBClientModel,
  branch: RelatedDBBranchModel.nullish(),
  orders: RelatedDBCustomerOrderItemModel.array(),
}))
