import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, IconButton, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useGetCategories } from '../query/get-categories';
import { useContext } from 'react';
import { PosSearchContext } from './SearchPOS/SearchContext';
import { useFilter } from '../../../hooks/useFilter';

interface FilterProps {
  onOpen: () => void;
}

const SidebarFiltersList = ({ onOpen }: FilterProps) => {
  const { filterValues, setFilterValue } = useFilter();
  const { category_data } = useGetCategories(
    filterValues?.['categoryId'] as string
  );

  const {
    selectedSizes,
    setSelectedSizes,
    setCurrentStepIndex,
    resetAllValues,
  } = useContext(PosSearchContext);

  const { subcategories } = useContext(PosSearchContext);

  const get = (path: string) => _.get(filterValues, path, []) as string[];

  const handleDelete = (path: string, key: string) => {
    setFilterValue({
      [path]: get(path).filter((elem) => elem !== key),
    });

    if (path === 'size' && selectedSizes) {
      const newSelectedSizes = selectedSizes.filter((el) => el !== key);
      setSelectedSizes(newSelectedSizes);

      if (newSelectedSizes.length === 0) {
        resetAllValues();
        setCurrentStepIndex(0);
      }
    }
  };

  const subcategoriesById = get('subcategory');

  const selectedSubcategories = subcategories?.filter((el) =>
    subcategoriesById.includes(el.value.toString())
  );

  return (
    <Container>
      <IconWrapper>
        <IconButton onClick={onOpen}>
          <MoreVertIcon />
        </IconButton>
        <Typography fontSize={14} fontWeight={500}>
          Filter
        </Typography>
      </IconWrapper>

      <FilterWrapper>
        {category_data && filterValues?.['categoryId'] && (
          <>
            <Item>Category:</Item>
            <Chip
              label={category_data?.name}
              variant="outlined"
              size="small"
              color="info"
              onDelete={() => {
                setFilterValue({ categoryId: undefined });
              }}
              key={Number(filterValues?.['categoryId'])}
              style={{ marginBottom: 8, marginLeft: '5px' }}
            />
          </>
        )}

        {get('brand').length > 0 && (
          <>
            <Item>Brand:</Item>
            {get('brand').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                size="small"
                onDelete={() => handleDelete('brand', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('season').length > 0 && (
          <>
            <Item>Season:</Item>
            {get('season').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                size="small"
                onDelete={() => handleDelete('season', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('wheelPosition').length > 0 && (
          <>
            <Item>Wheel position:</Item>
            {get('wheelPosition').map((elem) => (
              <Chip
                label={elem.toUpperCase()}
                variant="outlined"
                color="info"
                size="small"
                onDelete={() => handleDelete('wheelPosition', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('specialMarking').length > 0 && (
          <>
            <Item>Special marking:</Item>
            {get('specialMarking').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('specialMarking', elem)}
                key={elem}
              />
            ))}
          </>
        )}

        {get('size').length > 0 && (
          <>
            <Item>Size:</Item>
            {get('size').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('size', elem)}
                key={elem}
              />
            ))}
          </>
        )}

        {get('color').length > 0 && (
          <>
            <Item>Color:</Item>
            {get('color').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('color', elem)}
                key={elem}
              />
            ))}
          </>
        )}

        {get('rimSize').length > 0 && (
          <>
            <Item>Rim size:</Item>
            {get('rimSize').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('rimSize', elem)}
                key={elem}
              />
            ))}
          </>
        )}

        {selectedSubcategories && subcategoriesById.length > 0 && (
          <>
            <Item>Subcategory:</Item>
            {Object.values(selectedSubcategories).map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem.label}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() =>
                  handleDelete('subcategory', elem.value.toString())
                }
                key={elem.value.toString()}
              />
            ))}
          </>
        )}

        {filterValues?.['moto'] && (
          <>
            <Item>Desired option:</Item>
            <Chip
              label={filterValues?.['moto'] as unknown as string}
              variant="outlined"
              size="small"
              color="info"
              onDelete={() => {
                setFilterValue({ moto: undefined });
              }}
              key={filterValues?.['moto'] as unknown as string}
              style={{ marginBottom: 8, marginLeft: '5px' }}
            />
          </>
        )}
      </FilterWrapper>
    </Container>
  );
};

export const Item = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  color: gray;
  font-style: italic;
  margin-bottom: 8px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  overflow-x: auto;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
`;

export default SidebarFiltersList;
