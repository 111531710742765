export const WHEEL_POSITION_MAP: Record<string, string> = {
  front: 'Front',
  rear: 'Rear',
  frontrear: 'Front/Rear',
  drive: 'Drive',
  steer: 'Steer',
  trailer: 'Trailer',
  allposition: 'Allposition',
  unknown: 'Unknown',
};

export const SEASON_MAP: Record<string, string> = {
  summer: 'Summer',
  winter: 'Winter',
  allseason: 'All Season',
};

export const CONSTRUCTIONS_MAP: Record<
  string,
  { name: string; label: string }
> = {
  R: {
    name: 'R',
    label: 'Radial (R)',
  },
  ZR: {
    name: 'ZR',
    label: 'Radial (ZR)',
  },
  D: {
    name: 'D',
    label: 'Diagonal (D)',
  },
  B: {
    name: 'B',
    label: 'Bias (B)',
  },
  '-': {
    name: '-',
    label: 'Diagonal (-)',
  },
};

export const HOLE_CIRCLE_MAP = [
  '98',
  '100',
  '105',
  '108',
  '110',
  '112',
  '114.3',
  '115',
  '118',
  '120',
  '127',
  '130',
  '139.7',
  '150',
  '160',
];

export const HOLE_NUMBER_MAP = ['3', '4', '5', '6'];

export const PR_MAP = [
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '30',
];

export const SPEED_INDEX_MAP: Record<string, unknown> = {
  A1: { value: 'A1', description: '5 km/h' },
  A2: { value: 'A2', description: '10 km/h' },
  A3: { value: 'A3', description: '15 km/h' },
  A4: { value: 'A4', description: '20 km/h' },
  A5: { value: 'A5', description: '25 km/h' },
  A6: { value: 'A6', description: '30 km/h' },
  A7: { value: 'A7', description: '35 km/h' },
  A8: { value: 'A8', description: '40 km/h' },
  B: { value: 'B', description: '50 km/h' },
  C: { value: 'C', description: '60 km/h' },
  D: { value: 'D', description: '65 km/h' },
  E: { value: 'E', description: '70 km/h' },
  F: { value: 'F', description: '80 km/h' },
  G: { value: 'G', description: '90 km/h' },
  J: { value: 'J', description: '100 km/h' },
  K: { value: 'K', description: '110 km/h' },
  L: { value: 'L', description: '120 km/h' },
  M: { value: 'M', description: '130 km/h' },
  N: { value: 'N', description: '140 km/h' },
  P: { value: 'P', description: '150 km/h' },
  Q: { value: 'Q', description: '160 km/h' },
  R: { value: 'R', description: '170 km/h' },
  S: { value: 'S', description: '180 km/h' },
  T: { value: 'T', description: '190 km/h' },
  U: { value: 'U', description: '200 km/h' },
  H: { value: 'H', description: '210 km/h' },
  V: { value: 'V', description: '240 km/h' },
  '(V)': { value: '(V)', description: '> 240 km/h' },
  W: { value: 'W', description: '270 km/h' },
  '(W)': { value: '(W)', description: '> 270 km/h' },
  Y: { value: 'Y', description: '300 km/h' },
  '(Y': { value: '(Y', description: '> 300 km/h' },
  '(Y)': { value: '(Y)', description: '> 300 km/h' },
  Z: { value: 'Z', description: '> 240 km/h' },
  ZR: { value: 'ZR', description: '> 240 km/h' },
  X: { value: 'X', description: '> 300 km/h' },
};

export const VEHICLE_CLASS_MAP: Record<string, string> = {
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
};

export const TUBE_TYPE_MAP: Record<string, { label: string; value: string }> = {
  TL: {
    label: 'TL',
    value: 'TL',
  },
  TT: {
    label: 'TT',
    value: 'TT',
  },
  TLTT: {
    label: 'TL/TT',
    value: 'TLTT',
  },
  TLR: {
    label: 'TLR',
    value: 'TLR',
  },
};

export const INSTALLATION_KIT_MAP: Record<string, string> = {
  oem: 'Original equipment',
  partialKit: 'Partial kit',
  fullKit: 'Full kit',
};

export const EU_DIRECTIVE_NUMBER_MAP: Record<string, string> = {
  '2020/740': '2020/740',
  '1222/2009': '1222/2009',
};

export const ROLLING_RESISTANCE_MAP: Record<
  '2020/740' | '1222/2009',
  Record<string, string>
> = {
  '2020/740': { A: 'A', B: 'B', C: 'C', D: 'D', E: 'E' },
  '1222/2009': { A: 'A', B: 'B', C: 'C', D: 'D', E: 'E', F: 'F', G: 'G' },
};

export const WET_GRIP_MAP: Record<
  '2020/740' | '1222/2009',
  Record<string, string>
> = {
  '2020/740': { A: 'A', B: 'B', C: 'C', D: 'D', E: 'E' },
  '1222/2009': { A: 'A', B: 'B', C: 'C', D: 'D', E: 'E', F: 'F', G: 'G' },
};

export const NOISE_CLASS_MAP: Record<
  '2020/740' | '1222/2009',
  Record<string, string>
> = {
  '2020/740': { A: 'A', B: 'B', C: 'C' },
  '1222/2009': { '1': '1', '2': '2', '3': '3' },
};

export const COMPOUND_MAP: Record<string, string> = {
  soft: 'Soft',
  medium: 'Medium',
  hard: 'Hard',
  supersoft: 'Super Soft',
  mediumsoft: 'Medium Soft',
  mediumsoftplus: 'Medium Soft +',
  mediumhard: 'Medium Hard',
  mediumhardplus: 'Medium Hard +',
  endurance: 'Endurance',
};

export const FAVORITE_SIZES = [
  '165/70 R 14',
  '175/65 R 14',
  '185/60 R 14',
  '195/50 R 15',
  '175/65 R 15',
  '185/60 R 15',
  '185/65 R 15',
  '195/65 R 15',
  '205/55 R 16',
  '195/55 R 16',
  '205/60 R 16',
  '215/60 R 16',
  '215/65 R 16',
  '225/45 R 17',
  '205/55 R 17',
  '215/55 R 17',
  '225/50 R 17',
  '225/55 R 17',
  '215/60 R 17',
  '225/40 R 18',
];
