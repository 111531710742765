export const colors = [
  { label: 'Black Gloss', value: 'Black Gloss' },
  { label: 'Black Matt', value: 'Black Matt' },
  { label: 'Graphite Gloss', value: 'Graphite Gloss' },
  { label: 'Graphite Matt', value: 'Graphite Matt' },
  { label: 'Silver Gloss', value: 'Silver Gloss' },
  { label: 'Silver Matt', value: 'Silver Matt' },
  { label: 'Bronze Gloss', value: 'Bronze Gloss' },
  { label: 'Bronze Matt', value: 'Bronze Matt' },
  { label: 'Gold Gloss', value: 'Gold Gloss' },
  { label: 'Gold Matt', value: 'Gold Matt' },
  { label: 'Candy Apple Red Gloss', value: 'Candy Apple Red Gloss' },
  { label: 'Candy Apple Red Matt', value: 'Candy Apple Red Matt' },
  { label: 'Candy Violet Gloss', value: 'Candy Violet Gloss' },
  { label: 'Candy Violet Matt', value: 'Candy Violet Matt' },
  {
    label: 'Blue Purple Chameleon Gloss',
    value: 'Blue Purple Chameleon Gloss',
  },
  { label: 'Blue Purple Chameleon Matt', value: 'Blue Purple Chameleon Matt' },
  {
    label: 'Bronze Green Chameleon Gloss',
    value: 'Bronze Green Chameleon Gloss',
  },
  {
    label: 'Bronze Green Chameleon Matt',
    value: 'Bronze Green Chameleon Matt',
  },
  { label: 'Light Blue Gloss', value: 'Light Blue Gloss' },
  { label: 'Light Blue Matt', value: 'Light Blue Matt' },
  { label: 'Candy Apple Green Gloss', value: 'Candy Apple Green Gloss' },
  { label: 'Candy Apple Green Matt', value: 'Candy Apple Green Matt' },
  { label: 'Green Gloss', value: 'Green Gloss' },
  { label: 'Green Matt', value: 'Green Matt' },
  { label: 'Neon Yellow Gloss', value: 'Neon Yellow Gloss' },
  { label: 'Neon Yellow Matt', value: 'Neon Yellow Matt' },
  { label: 'Neon Pink Gloss', value: 'Neon Pink Gloss' },
  { label: 'Neon Pink Matt', value: 'Neon Pink Matt' },
  { label: 'White Gloss', value: 'White Gloss' },
  { label: 'White Matt', value: 'White Matt' },
];

export const model_colors: Record<string, { label: string; value: string }[]> =
  {
    ['JR WHEELS']: colors,
    CONCAVER: colors,
  };
