import { FEATURE_FLAGS } from '@tyrio/feature-flags';
import TransferOrderOverview from '../../../features/transfer-orders/TransferOrdersOverview';
import ComingSoon from '../ComingSoon';

const TransferOrders = () => {
  return FEATURE_FLAGS.SHOW_TRANSFER_ORDERS ? (
    <TransferOrderOverview sidebarOpen={false} />
  ) : (
    <ComingSoon />
  );
};

export default TransferOrders;
