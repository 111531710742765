import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {
  CheckPriceRes,
  DBClientPriceCalculationApi,
  PriceCalculationMetaRule,
  PriceCalculationType,
} from '@tyrio/dto';
import { ProductDescription } from '@tyrio/products';
import _ from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { currency, getMarginType } from '../../helper/parser';
import { useGetPrice } from '../../queries/get-price';
import RuleDetails from './RuleDetails';
import { useGetSuppliers } from '../../queries/get-suppliers';

interface ICheckRulesModal {
  isOpen: boolean;
  close: () => void;
  priceCalculationData?:
    | DBClientPriceCalculationApi['getOne']['response']
    | null;
}

export default function CheckRulesModal(props: ICheckRulesModal) {
  const { isOpen, close, priceCalculationData } = props;

  const isBasicPriceCalc = priceCalculationData?.supplierId === null;
  //STATES
  const [ean, setEan] = useState('');
  const [supplierId, setSupplierId] = useState(
    priceCalculationData?.supplier?.supplierId ?? ''
  );
  const [data, setData] = useState<CheckPriceRes>();
  const [errorText, setErrorText] = useState('');

  //PROPS
  const { priceCalculationId } = useParams<{ priceCalculationId: string }>();

  //MUTATIONS
  const { getPrice } = useGetPrice({ setData, setErrorText });
  const { suppliersData } = useGetSuppliers({
    enabled: isBasicPriceCalc,
  });

  //FUNCTIONS
  const onClose = () => {
    setEan('');
    setSupplierId('');
    setData(undefined);
    setErrorText('');
    close();
  };

  const getItem = (label: string, content: string, sx?: SxProps<Theme>) => {
    return (
      <ItemContainer>
        <Item>{label}</Item>
        <Typography sx={sx}>{content}</Typography>
      </ItemContainer>
    );
  };

  const getMargin = (
    margin?: number | null,
    marginType?: string | null,
    price?: number | null
  ): { label: string; margin: number } | null => {
    if (!price || !margin || !marginType) return null;

    const calculatedMargin = Number(((price / 100) * margin).toFixed(2));

    let final = `+${margin}${getMarginType(marginType)}`;

    if (marginType === 'PERCENTAGE' || marginType === '%')
      final += ` (${calculatedMargin}${currency})`;

    return { label: final, margin: calculatedMargin };
  };

  const getAppliedRule = () => {
    return data?.meta?.rules?.find(
      (item) => item.id === data.meta?.appliedRuleId
    );
  };

  const getRuleAtt = (att: string) => {
    let conf: {
      ruleName?: string;
      priceTitle?: string;
      price?: string;
      ecoTax?: string;
      transportCost?: string;
      margin?: string;
      rulesCount?: number;
    } = {};

    switch (data?.meta?.priceType) {
      case PriceCalculationType.DEFAULT_WITH_MARGIN: {
        conf = {
          ruleName: 'purchase price with default margin',
          priceTitle: 'PURCHASE PRICE',
          price: `${data.meta.prices?.purchasePrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: 0,
          margin: getMargin(
            data?.meta?.priceCalculation?.margin,
            data.meta.priceCalculation?.marginType,
            data?.meta?.prices?.purchasePrice
          )?.label,
        };
        break;
      }
      case PriceCalculationType.RULE_WITH_MARGIN_NO_RULES: {
        conf = {
          ruleName: 'purchase price with default margin',
          priceTitle: 'PURCHASE PRICE',
          price: `${data.meta.prices?.purchasePrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: data.meta.rules?.length,
          margin: getMargin(
            data?.meta?.priceCalculation?.margin,
            data.meta.priceCalculation?.marginType,
            data?.meta?.prices?.purchasePrice
          )?.label,
        };
        break;
      }
      case PriceCalculationType.DEFAULT_WITH_SUGGESTED_PRICE: {
        conf = {
          ruleName: 'suggested retail price',
          priceTitle: 'SUGGESTED PRICE',
          price: `${data.meta.prices?.suggestedRetailPrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: 0,
          margin: '',
        };
        break;
      }
      case PriceCalculationType.RULE_WITH_SUGGESTED_PRICE_NO_RULES: {
        conf = {
          ruleName: 'suggested retail price',
          priceTitle: 'SUGGESTED PRICE',
          price: `${data.meta.prices?.suggestedRetailPrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: data.meta.rules?.length,
          margin: '',
        };
        break;
      }
      case PriceCalculationType.RULE_WITH_ACTION_MARGIN: {
        conf = {
          ruleName: getAppliedRule()?.ruleName,
          priceTitle: 'PURCHASE PRICE',
          price: `${data.meta.prices?.purchasePrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: data.meta.rules?.length,
          margin: getMargin(
            getAppliedRule()?.margin,
            getAppliedRule()?.marginType,
            data?.meta?.prices?.purchasePrice
          )?.label,
        };
        break;
      }
      case PriceCalculationType.RULE_WITH_ACTION_SUGGESTED_PRICE: {
        conf = {
          ruleName: `${getAppliedRule()?.ruleName} - suggested retail price`,
          priceTitle: 'SUGGESTED PRICE',
          price: `${data.meta.prices?.suggestedRetailPrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: data.meta.rules?.length,
          margin: '',
        };
        break;
      }
      case PriceCalculationType.RULE_WITH_MARGIN_NO_RANGE: {
        conf = {
          ruleName: 'purchase price with default margin',
          priceTitle: 'PURCHASE PRICE',
          price: `${data.meta.prices?.purchasePrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: data.meta.rules?.length,
          margin: '',
        };
        break;
      }
      case PriceCalculationType.RULE_WITH_SUGGESTED_PRICE_NO_RANGE: {
        conf = {
          ruleName: `suggested retail price`,
          priceTitle: 'SUGGESTED PRICE',
          price: `${data.meta.prices?.suggestedRetailPrice}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: data.meta.rules?.length,
          margin: '',
        };
        break;
      }
      case PriceCalculationType.CUSTOM_PRICE: {
        conf = {
          ruleName: `custom price catalog`,
          priceTitle: 'PRICE',
          price: `${data.price}${currency}`,
          ecoTax: `${data.meta.prices?.ecoTax}${currency}`,
          transportCost: `${data.meta.prices?.transportCost}${currency}`,
          rulesCount: 0,
          margin: '',
        };
        break;
      }

      default:
        break;
    }

    return _.get(conf, att);
  };

  const error = () => {
    return (
      <Alert severity="error" sx={{ marginTop: '10px' }}>
        {errorText}
      </Alert>
    );
  };

  const check = () => {
    if (_.isEmpty(ean)) {
      setErrorText('You must enter an EAN!');
      return false;
    }

    if (ean.length !== 13) {
      setErrorText('EAN must have 13 numbers!');
      return false;
    }

    if (_.isEmpty(supplierId) && isBasicPriceCalc) {
      setErrorText('You must select an supplier!');
      return false;
    }

    return true;
  };

  return (
    <Grid>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isOpen}
        onClose={onClose}
      >
        <Container>
          <Grid>
            <Header>
              <Typography variant="h6" component="h2">
                Check price calculation
              </Typography>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </Header>
            {data && (
              <PriceTypeTypography style={{ fontSize: '14px' }}>
                {data?.meta?.priceCalculation?.name?.toUpperCase()}
              </PriceTypeTypography>
            )}
          </Grid>
          <Grid>
            {(data === undefined || !_.isEmpty(errorText)) && (
              <Grid>
                <TextField
                  label="EAN"
                  variant="outlined"
                  value={ean}
                  type="number"
                  onChange={(e) => {
                    setEan(e.target.value);
                    setErrorText('');
                  }}
                />
                {suppliersData && isBasicPriceCalc && (
                  <FormControl fullWidth sx={{ marginTop: '10px' }}>
                    <InputLabel>Supplier</InputLabel>
                    <Select
                      value={supplierId}
                      label="Supplier"
                      onChange={(e) => {
                        setSupplierId(e.target.value);
                        setErrorText('');
                      }}
                    >
                      {suppliersData.map((s) => (
                        <MenuItem value={s.value}>{s.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  sx={{ marginTop: '10px' }}
                  onClick={() => {
                    if (!check()) return;

                    getPrice.mutate({
                      isCheckPrice: true,
                      ean: ean,
                      priceCalculationId: priceCalculationId,
                      supplierId:
                        priceCalculationData?.supplier?.supplierId ??
                        supplierId,
                    });
                  }}
                >
                  Check price
                </Button>
                {!_.isEmpty(errorText) && error()}
              </Grid>
            )}
            {data && ean !== '' && (
              <Grid>
                <Grid sx={{ marginBottom: '10px', marginTop: '15px' }}>
                  {data.product && (
                    <ProductDescription product={data.product} />
                  )}
                </Grid>
                <PriceTypeContainer>
                  <PriceTypeTypography>
                    FINAL PRICE CALCULATION
                  </PriceTypeTypography>
                  {getItem('APPLIED RULE', getRuleAtt('ruleName'), {
                    color: '#56C489',
                  })}
                  {getItem(getRuleAtt('priceTitle'), getRuleAtt('price'))}
                  {getItem('ECO TAX', getRuleAtt('ecoTax'))}
                  {getItem('TRANSPORT COST', getRuleAtt('transportCost'))}
                  {data.meta?.rules &&
                    data.meta?.rules?.length > 0 &&
                    getItem('RULES COUNT', getRuleAtt('rulesCount'))}
                  {getRuleAtt('margin') !== '' &&
                    getItem('MARGIN', getRuleAtt('margin'))}
                </PriceTypeContainer>
                <FinalPriceContainer>
                  <Total>FINAL PRICE:</Total>
                  <Total>
                    {data.price}
                    {currency}
                  </Total>
                </FinalPriceContainer>
                {(!data?.meta?.rules || data?.meta?.rules?.length === 0) && (
                  <Grid sx={{ marginTop: '15px' }}>
                    <Alert severity="info">
                      This price calculation doesn't have any rule for apply!
                    </Alert>
                  </Grid>
                )}
                {data?.meta?.rules && data.meta.rules.length > 0 && (
                  <Grid sx={{ marginTop: '15px' }}>
                    {data.meta.rules.map((rule: PriceCalculationMetaRule) => {
                      return (
                        <RuleDetails
                          type="rrp"
                          rule={rule}
                          appliedRuleId={data.meta?.appliedRuleId}
                        />
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      </Modal>
    </Grid>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: white;
  box-shadow: 24;
  padding: 16px;
  border-radius: 16px;
`;

const PriceTypeContainer = styled.div`
  background-color: #ecf0f4;
  padding: 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const FinalPriceContainer = styled.div`
  background: #637381;
  padding: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceTypeTypography = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const ItemContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Item = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #454f5b;
`;

const Total = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  color: #fff;
`;
