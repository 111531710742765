import { DBProductModel } from '@prisma/client';
import { DBProductApi, DBProductModelApi, PhotoShape } from '@tyrio/dto';
import {
  ShortProductDescription,
  ProductHeader,
  ProductReviews,
  ProductSection,
  ProductTests,
} from '@tyrio/products';
import { startCase } from 'lodash';
import { PageTemplateSidebar } from '../../../../components/PageTemplate/PageTemplate';
import { getProductMetadataString } from '../product-models/helpers';

interface ProductSidebarProps {
  model?: DBProductModelApi['getOne']['response'];
  product?: DBProductApi['getOne']['response'];
}

const ProductSidebar = ({ model, product }: ProductSidebarProps) => {
  if (model && !product) {
    const rawTags = startCase(
      getProductMetadataString(model as DBProductModel)
    );
    const formattedTags = rawTags.split(' ');

    return (
      <PageTemplateSidebar>
        <ProductHeader
          title={model?.brand}
          model={model?.modelName}
          tags={formattedTags}
          photo={
            model?.photos &&
            (model?.photos as unknown as PhotoShape[])?.length > 0
              ? (model?.photos as unknown as PhotoShape[])[0]
              : undefined
          }
        />

        <ProductSection title="Description">
          <ShortProductDescription
            name={model?.modelName}
            description={model?.descTitle}
            content={model?.descContent}
          />
        </ProductSection>
        <ProductSection title="Reviews">
          <ProductReviews />
        </ProductSection>
        <ProductSection title="Test  results">
          <ProductTests />
        </ProductSection>
      </PageTemplateSidebar>
    );
  } else if (!model && product) {
    const rawTags = startCase(
      getProductMetadataString(product.model as DBProductModel)
    );
    const formattedTags = rawTags.split(' ');

    return (
      <PageTemplateSidebar>
        <ProductHeader
          title={product.model?.brand}
          model={product.model?.modelName}
          tags={formattedTags}
          product={product}
        />

        <ProductSection title="Description">
          <ShortProductDescription
            name={product.model?.modelName}
            description={product.model?.descTitle}
            content={product.model?.descContent}
          />
        </ProductSection>
        <ProductSection title="Reviews">
          <ProductReviews />
        </ProductSection>
        <ProductSection title="Test  results">
          <ProductTests />
        </ProductSection>
      </PageTemplateSidebar>
    );
  } else return null;
};

export default ProductSidebar;
