/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { DBSupplier } from '@prisma/client';
import {
  ClientConnectionDataArray,
  DBSupplierConnectionResponse,
} from '@tyrio/dto';
import * as _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
} from 'react-hook-form';
import {
  FileControlButtons,
  PasswordField,
  TextFieldInput,
} from '../../supplier-form/helpers/components';
import { ValidationProps } from '../../supplier-form/helpers/types';
import { getConnectionDetails } from '../helpers';

interface Props {
  c: DBSupplierConnectionResponse;
  connectionData: ClientConnectionDataArray;
  setConnectionData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  getValidationProps: (
    key: string,
    validationRules?: RegisterOptions,
    validationText?: string
  ) => ValidationProps;
  clearErrors: UseFormClearErrors<FieldValues>;
  supplierData: DBSupplier;
}

export const ClientConnectionApiConfiguration = ({
  c,
  connectionData = [],
  setConnectionData,
  getValidationProps,
  clearErrors,
  supplierData,
}: Props) => {
  const rootItem = useMemo(() => {
    return connectionData.find((item) => item.adminConnectionId === c.id);
  }, [c.id, connectionData]);
  const idx = useMemo(() => {
    const caughtIdx = connectionData.findIndex(
      (item) => item.adminConnectionId === c.id
    );
    if (caughtIdx === -1) return connectionData.length;
    return caughtIdx;
  }, [c.id, connectionData]);

  const [itemValue, setItemValueState] = useState(
    Object.assign(
      {
        type: 'API',
        adminConnectionId: c.id,
        connectionType: c.connectionTypes,
        connectionDetails: {
          apiClientId: '',
          apiKey: '',
          apiClientSecret: '',
          url: '',
          remark: '',
          connectionType: c.apiConnection?.connectionType,
        },
      },
      rootItem
    )
  );

  const supplierId = (c as any).supplierId;

  const connection = getConnectionDetails(c, itemValue);

  const setItemValue = useCallback(
    (data: any) => {
      const copied = _.cloneDeep(connectionData);
      copied[idx] = data;
      setItemValueState(data);
      setConnectionData(copied);
    },
    [connectionData, idx, setConnectionData]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          multiple
          id={c.id + '-Connection type'}
          value={c.connectionTypes}
          renderInput={(params) => (
            <TextField {...params} label="Connection type" />
          )}
          renderTags={(tagValue: string[], getTagProps) =>
            c.connectionTypes.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} disabled />
            ))
          }
          disabled
          options={c.connectionTypes}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <FormControl fullWidth>
          <InputLabel id={c.id + '-protocol-label'}>
            Connection protocol
          </InputLabel>
          <Select
            labelId={c.id + '-protocol-label'}
            id={c.id + '-protocol'}
            value={c.connectionProtocol}
            label="Connection protocol"
            onChange={() => null}
            disabled
          >
            <MenuItem value={c.connectionProtocol}>
              {c.connectionProtocol}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={3}>
        <FormControl fullWidth>
          <InputLabel id={c.id + '-file-format-label'}>File format</InputLabel>
          <Select
            labelId={c.id + '-file-format-label'}
            id={c.id + '-file-format'}
            value={`.${c.fileFormat.toLowerCase()}`}
            label="File format"
            onChange={() => null}
            disabled
          >
            <MenuItem
              value={`.${c.fileFormat.toLowerCase()}`}
            >{`.${c.fileFormat.toLowerCase()}`}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextFieldInput
          value={itemValue?.connectionDetails.url}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                url: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-apiConnection-url-${c.apiConnection?.id}`
            );
          }}
          label="URL"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-apiConnection-url-${c.apiConnection?.id}`
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldInput
          value={itemValue?.connectionDetails.apiKey}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                apiKey: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-apiConnection-apiKey-${c.apiConnection?.id}`
            );
          }}
          label="API Key"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-apiConnection-apiKey-${c.apiConnection?.id}`
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldInput
          value={itemValue?.connectionDetails.apiClientId}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                apiClientId: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-apiConnection-apiClientId-${c.apiConnection?.id}`
            );
          }}
          label="API Client ID"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-apiConnection-apiClientId-${c.apiConnection?.id}`
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <PasswordField
          value={itemValue?.connectionDetails.apiClientSecret || ''}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                apiClientSecret: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-apiConnection-apiClientSecret-${c.apiConnection?.id}`
            );
          }}
          label="API Client Secret"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-apiConnection-apiClientSecret-${c.apiConnection?.id}`
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <FileControlButtons
          supplierId={supplierId}
          supplierName={supplierData.companyOfficialName}
          connection={connection}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <TextField
          fullWidth
          value={itemValue?.connectionDetails.remark}
          onChange={(e) =>
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                remark: e.target.value,
              },
            })
          }
          id={c.id + '-remark'}
          label="Remark"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};
