import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import CategoryForm from '../features/category-form/CategoryForm';
import DashboardCategory from '../pages/dashboard/Category';

export const CategoryRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <DashboardCategory>
        {location.pathname.split('/').pop() === 'new' ? (
          <Route exact path={`${path}/new`}>
            <CategoryForm />
          </Route>
        ) : (
          <Route exact path={`${path}/:categoryId`}>
            <CategoryForm />
          </Route>
        )}
      </DashboardCategory>
    </Switch>
  );
};
