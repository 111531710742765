import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';

interface FormRepeaterSeparatorProps {
  buttonText: string;
  onClickDelete: () => void;
}
export const FormRepeaterSeparator = ({
  onClickDelete,
  buttonText,
}: FormRepeaterSeparatorProps) => {
  const handleConfirm = () => {
    if (window.confirm('Are you sure')) {
      // TODO: i18n
      onClickDelete();
    }
  };
  //TODO: i18n
  return (
    <ButtonArrayWrapper>
      <Button variant="text" color="error" onClick={() => handleConfirm()}>
        {buttonText}
      </Button>
    </ButtonArrayWrapper>
  );
};

const ButtonArrayWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
  margin-bottom: ${tyrioUI.spacing.xl}px;
  padding-bottom: 26px;
  padding-top: 4px;
`;
