import {
  Control,
  FieldValues,
  SetFieldValue,
  UseFormGetValues,
  UseFormRegister,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { v4 } from 'uuid';
import { TyrioFormMapping } from './FormMapping';
import {
  FormGridProps,
  FormGridSize,
  TyrioFormError,
  TyrioRepeater,
} from './types';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Alert, Grid, IconButton } from '@mui/material';
import Button from '@mui/material/Button/Button';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormRepeaterSeparator } from './components/FormRepeaterSeparator';
import { useStickyState } from './hooks/useStickyState';

interface TyrioFormRepeaterProps {
  item: TyrioRepeater;
  idx: number;
  errors?: Record<string, Record<string, TyrioFormError>>;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  control: Control;
  setValue: SetFieldValue<FieldValues>;
  stickyKey: string;
  getValues: UseFormGetValues<FieldValues>;
  gridSpacing?: number;
  watch?: UseFormWatch<FieldValues>;
}

const constructInitialValues = (
  obj: Record<string, unknown> | undefined,
  forceFallback: boolean
) => {
  const constructed: Record<string, boolean> = {};
  const keys = Object.keys(obj || {});

  if (!obj || keys.length === 0 || forceFallback) {
    return { initialState: false };
  }

  keys.forEach((k) => {
    constructed[k] = true;
  });
  return constructed;
};

export const FormRepeater = ({
  item,
  control,
  errors,
  register,
  unregister,
  setValue,
  getValues,
  stickyKey,
  gridSpacing,
  watch,
}: TyrioFormRepeaterProps) => {
  const { t } = useTranslation();

  const [reps, setReps] = useStickyState<Record<string, boolean>>(
    constructInitialValues((getValues() || {})[item.id], !!item.single),
    stickyKey
  );

  const [stickyLoadedList, setStickyLoadedList] = useStickyState([], item.id);
  const client = getValues();

  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});
  const [removeEmptyObject, setRemoveEmptyObject] = useState(true);

  useEffect(() => {
    if (stickyLoadedList.indexOf(item.id) === -1) {
      setStickyLoadedList([...stickyLoadedList, item.id]);
    }
  }, [item.id, setStickyLoadedList, stickyLoadedList]);

  useEffect(() => {
    if (Object.keys(reps).filter((el) => el !== 'initialState').length === 1) {
      if (Object.keys(reps).filter((el) => el === 'initialState').length) {
        setIsOpen({ ...isOpen, [Object.keys(reps)[1]]: true });
        removeRepeaterItem('initialState', item);
      } else {
        setIsOpen((prevState) => ({
          ...prevState,
          [Object.keys(reps)[0]]: true,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reps]);

  useEffect(() => {
    if (Object.keys(reps).length > 1) {
      Object.keys(reps).forEach((k) => {
        setIsOpen((prevValue) => ({
          ...prevValue,
          [k]: false,
        }));
      });
    }
    setRemoveEmptyObject(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridProps: FormGridProps = {
    ...item.width,
  };

  const expandCollapseItems = () => {
    Object.keys(isOpen).forEach((k) => {
      setIsOpen((prevValue) => ({
        ...prevValue,
        [k]: !isAnyKeyValueTrue(isOpen),
      }));
    });
  };

  const isAnyKeyValueTrue = (obj: Record<string, boolean>) =>
    !!Object.keys(obj).find((k) => obj[k]);

  const removeRepeaterItem = (key: string, item: TyrioRepeater) => {
    const newReps: Record<string, boolean> = {};

    Object.keys(reps).forEach((repKey) => {
      if (repKey !== key) {
        newReps[repKey] = reps[repKey];
      }
    });

    unregister(`${item.id}.${key}`);

    setReps(newReps);
  };

  return (
    <>
      {((!_.isEmpty(reps) && !_.isEmpty(isOpen)) ||
        Object.values(reps).includes('true') ||
        (client?.['users'] !== undefined &&
          client?.['companyContacts'] !== undefined)) && (
        <ExpandCollapseButtonWrapper>
          <Button
            variant="text"
            color={isAnyKeyValueTrue(isOpen) ? 'warning' : 'success'}
            onClick={() => {
              expandCollapseItems();
            }}
            sx={{ mb: '15px' }}
          >
            {isAnyKeyValueTrue(isOpen) ? t('COLLAPSE ALL') : t('EXPAND ALL')}
          </Button>
        </ExpandCollapseButtonWrapper>
      )}

      <Grid item {...gridProps}>
        {_.isEmpty(reps) ? (
          <AlertField text={GetAlertText(item.id)} />
        ) : (
          Object.keys(reps).map((repKey, repIdx) => {
            const activeTab = item.id;

            if (
              client?.[activeTab] !== undefined &&
              client?.[activeTab][repKey] === undefined &&
              removeEmptyObject
            ) {
              removeRepeaterItem(repKey, item);
              return null;
            } else if (repKey === 'initialState') {
              return <AlertField text={GetAlertText(item.id)}></AlertField>;
            } else
              return (
                <Grid
                  style={{ justifyContent: 'flex-end' }}
                  key={repKey}
                  container
                  spacing={gridSpacing || 2}
                >
                  <IconButton
                    disableRipple
                    size="small"
                    onClick={() => {
                      setIsOpen((prevValue) => ({
                        ...prevValue,
                        [repKey]: !prevValue[repKey],
                      }));
                    }}
                    sx={{
                      borderRadius: '0px',
                      ':hover': { backgroundColor: 'transparent' },
                      position: 'absolute',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {isOpen[repKey] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                  {item.inputs.map((input, index) => {
                    const childGridProps: FormGridProps = {
                      ...(input.width as FormGridSize),
                    };

                    const k = item.single
                      ? `${item.id}.${input.id}`
                      : `${item.id}.${repKey}.${input.id}`;

                    return (
                      <GridBox
                        ishidden={(!isOpen[repKey] && index > 0).toString()}
                        key={k}
                        item
                        {...childGridProps}
                      >
                        <Grid container>
                          <TyrioFormMapping
                            control={control}
                            error={errors?.[repKey]?.[input.id]}
                            key={k}
                            id={k}
                            item={input}
                            gridProps={{ xs: 12 }}
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            repKey={repKey}
                            watch={watch}
                            idx={repIdx}
                            handleCustomSubmit={() => null}
                          />
                        </Grid>
                      </GridBox>
                    );
                  })}
                  {!item.single && isOpen[repKey] && (
                    <Grid item {...gridProps}>
                      <FormRepeaterSeparator
                        buttonText={t(item.deleteRepeaterButtonText)}
                        onClickDelete={() => removeRepeaterItem(repKey, item)}
                      />
                    </Grid>
                  )}
                </Grid>
              );
          })
        )}
        {!item.single && (
          <Grid container spacing={gridSpacing || 2}>
            <Grid item>
              <Button
                data-cy={item.addRepeaterButtonText}
                onClick={() => {
                  const id = v4();
                  setReps({ ...reps, [id]: true });
                  setIsOpen((prevValue) => ({ ...prevValue, [id]: true }));
                  setRemoveEmptyObject(false);
                }}
                variant="outlined"
                color="info"
                endIcon={<AddIcon />}
              >
                {t(item.addRepeaterButtonText)}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const GetAlertText = (value: string) => {
  if (value === 'companyContacts')
    return "This client doesn't have any contact!";

  if (value === 'users') return "This client doesn't have any user!";

  if (value === 'partnerContacts')
    return "This partner doesn't have any contact !";

  if (value === 'partnerLocations')
    return "This partner doesn't have any location !";

  if (value === 'partnerBanks')
    return "This partner doesn't have any bank account !";

  return '';
};

interface AlertFieldProps {
  text: string;
}

const AlertField = (props: AlertFieldProps) => {
  const { t } = useTranslation();

  return (
    <Alert color="warning" sx={{ marginBottom: '25px' }}>
      {t(props.text)}{' '}
    </Alert>
  );
};

const ExpandCollapseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  border-bottom: solid 1.5px #eceef1;
  margin-left: 15px;
`;

export const GridBox = styled(Grid)<{ ishidden: string }>`
  display: ${(props) => (props.ishidden === 'true' ? 'none' : 'flex')};
`;
