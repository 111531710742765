/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Drawer, Grid } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { MultiselectChipInput, tyrioIcons } from '@tyrio/ui-library';
import Button from '@mui/material/Button/Button';

import {
  IVehicleFilter,
  defaultFilterValue,
  useVehicleCtx,
} from '../../../../../context/VehicleContext';
import { get, isEmpty } from 'lodash';
import { COLOR_FINISH } from '@tyrio/shared-vars';
import { parseEnumToStringArray } from '../../../../../../../helpers/enum-parser';
import { Availability } from '@tyrio/dto';

interface FilterSidebarProps {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
}

const SidebarInternal = ({ isOpen, setOpen }: FilterSidebarProps) => {
  const { backendFilterData, filter, setFilter, setPosWheelsFilterData } =
    useVehicleCtx();

  const getFilterValuesFromInitial = useCallback(() => {
    const data = {
      rimDiameter: filter?.['rimDiameter'] ?? [],
      width: filter?.['width'] ?? [],
      cb: filter?.['cb'] ?? [],
      colorGroup: filter?.['colorGroup'] ?? [],
      brand: filter?.['brand'] ?? [],
      modelName: filter?.['modelName'] ?? [],
      colorFinish: filter?.['colorFinish'] ?? [],
      availability: filter?.['availability'] ?? [],
    };

    return data as any;
  }, [filter]);

  const { handleSubmit, control, reset, watch } = useForm({
    mode: 'onChange',
    defaultValues: getFilterValuesFromInitial(),
  });

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(false);
    };

  const onSubmit = ({ ...data }: IVehicleFilter) => {
    const payload = {
      ...data,
    };
    setFilter(payload);
    setOpen(false);
  };

  const getValue = (key: string) => {
    if (key === 'brand') return Object.keys(backendFilterData?.['brand'] ?? []);
    return get(backendFilterData, key, []);
  };

  const selectedBrand: string[] = watch('brand');
  const selectedModels: string[] = watch('modelName');

  const modelNames = useMemo(() => {
    const selectedBrands = selectedBrand ?? [];

    const allBrands = backendFilterData?.['brand'];

    const modelNames: string[] = [];

    selectedBrands.map((m) =>
      modelNames.push(...((allBrands as any)?.[m] ?? []))
    );
    return modelNames;
  }, [backendFilterData, selectedBrand]);

  const constants = [
    {
      title: 'Brand',
      id: 'brand',
      data: getValue('brand') as string[],
    },
    {
      title: 'Model name',
      id: 'modelName',
      data: modelNames,
      disabled: isEmpty(selectedBrand) && isEmpty(selectedModels),
    },
    {
      title: 'Rim size',
      id: 'rimDiameter',
      data: getValue('rimSize') as string[],
    },
    {
      title: 'Color group',
      id: 'colorGroup',
      data: getValue('colorGroup') as string[],
    },
    {
      title: 'Color finish',
      id: 'colorFinish',
      data: Object.values(COLOR_FINISH),
    },
    {
      title: 'Rim width',
      id: 'width',
      data: getValue('width') as string[],
    },
    {
      title: 'Rim center bore',
      id: 'cb',
      data: (getValue('cb') as number[]).map((m) => m.toString()) as string[],
    },
    {
      title: 'Availability',
      id: 'availability',
      data: parseEnumToStringArray(Availability),
    },
  ];

  if (!isOpen) return null;
  return (
    <Drawer anchor={'left'} open={isOpen} onClose={toggleDrawer()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Header>
            <Title>Filter</Title>
            <CloseButton onClick={() => setOpen(false)}>
              <img src={tyrioIcons.XIcon} alt="" />
            </CloseButton>
          </Header>
          <Grid container spacing={2}>
            {constants.map(({ title, id, data, disabled }) => (
              <Grid item xs={12}>
                <MultiselectChipInput
                  title={title}
                  control={control}
                  id={id}
                  data={data}
                  disabled={disabled ?? false}
                />
              </Grid>
            ))}

            <Grid item xs={6}>
              <Button
                fullWidth
                type="button"
                onClick={() => {
                  reset();
                  setFilter(defaultFilterValue);
                  setPosWheelsFilterData(null);
                  setOpen(false);
                }}
                style={{ border: '1px solid #1B2130' }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" type="submit">
                Filter
              </Button>
            </Grid>
          </Grid>
        </Wrapper>
      </form>
    </Drawer>
  );
};

export const WheelsFilterSidebar = ({
  isOpen,
  setOpen,
}: FilterSidebarProps) => {
  if (!isOpen) return null;
  return <SidebarInternal isOpen={isOpen} setOpen={setOpen} />;
};

const Wrapper = styled.div`
  height: 100%;
  background: white;
  border-radius: 22px;
  padding: 20px;
  margin-right: 10px;
  max-width: 80vw;
  width: 425px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Header = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
