import styled from '@emotion/styled';

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #dfe3e8;
  border-radius: 0px 16px 16px 0px;
  min-width: 114px;
  max-width: 114px;
  width: 100%;
  align-items: center;
  padding-top: 13px;
  height: 100%;
  float: right;
`;

export const EnlargeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 90%;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledPopover = styled.div`
  width: 262px;
  height: 132px;
  background: white;
  border: 1px solid #c4cdd5;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(100, 116, 139, 0.12);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PopoverItem = styled.div`
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  padding: 15px;

  &:hover {
    background: rgb(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

export const IconTextWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const PopoverItemSoon = styled(PopoverItem)`
  color: #919eab;
  opacity: 0.5;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: not-allowed;
  }
`;

export const PopoverIcon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 10px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  flex-direction: column;
`;
