import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetPaymentById = (id: string) => {
  const { data, refetch } = useQuery(
    ['get_payment_by_id', id],
    () =>
      api.fetch<DBPaymentTypesApi['getOne']>(`get_payment_by_id`, {
        id,
      }),
    {
      enabled: !!id,
    }
  );
  return { data, refetch };
};
