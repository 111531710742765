/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CartStockListItem } from '@tyrio/dto';
import React, { Dispatch, SetStateAction } from 'react';
import { Timer } from './TimerClass';

export interface IAvailable {
  id: string;
  maxAvailable: number;
  type: 'inbound' | 'outbound';
}
interface ICartContext {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  openSessionExpiredModal: boolean;
  setOpenSessionExpiredModal: Dispatch<SetStateAction<boolean>>;
  availableItems: Record<string, IAvailable[]>;
  setAvailableItems: (a: Record<string, IAvailable[]>) => void;
  unavailableQuantitesData: Record<string, CartStockListItem | number>[];
  setUnavailableQuantitesData: (
    a: Record<string, CartStockListItem | number>[]
  ) => void;
  timer: Timer | null;
}

export const CartContext = React.createContext<ICartContext>({
  openModal: false,
  setOpenModal: () => {},
  openSessionExpiredModal: false,
  setOpenSessionExpiredModal: () => {},
  availableItems: {},
  setAvailableItems: () => {},
  unavailableQuantitesData: [],
  setUnavailableQuantitesData: () => {},
  timer: null,
});

interface CartProviderProps {
  children: React.ReactNode;
  value: ICartContext;
}

const CartProvider = ({ children, value }: CartProviderProps) => {
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartProvider;
