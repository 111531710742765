import { FormShape } from '../../services/constants/service.form';

export const ShippingCourierFormShape = () => {
  const mainForm: FormShape[] = [
    {
      inputs: [
        {
          id: 'clientNumber',
          width: '30%',
          type: 'input.string',
          label: 'Client number',
          hasPadding: false,
        },
        {
          id: 'username',
          width: '30%',
          type: 'input.string',
          label: 'Username',
          hasPadding: false,
        },
        {
          id: 'password',
          width: 'calc(40% - 32px)',
          type: 'input.string',
          label: 'Password',
          hasPadding: false,
        },
      ],
    },
  ];

  return {
    form: mainForm,
  };
};
