import { useContext } from 'react';
import { PriceComparisonContext } from '../context';
import { SalesOrdersFlowContext } from '../../../../features/sales-orders/SalesOrdersFlowContext';
import { Alert, AlertTitle, Button, Grid } from '@mui/material';
import styled from '@emotion/styled/macro';
import { useHistory } from 'react-router-dom';

export const OrderSuccessAlert = () => {
  const ctx = useContext(PriceComparisonContext);
  const soctx = useContext(SalesOrdersFlowContext);

  const history = useHistory();

  return (
    <Grid item xs={12}>
      <Alert severity="success">
        <AlertTitle>Order success</AlertTitle>
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            The entire order has ben successfully passed along to supplier(s).
            You can review their status in{' '}
            <AlertLink
              onClick={() => history.push('/dashboard/purchase-orders')}
            >
              Purchase orders
            </AlertLink>
            .
          </Grid>
          <Grid item xs={12}>
            {soctx.customerOrderId ? (
              <Button
                onClick={() => {
                  history.push(`/dashboard/customer-orders`);
                }}
              >
                Back to customer orders
              </Button>
            ) : (
              <Button
                onClick={() => {
                  ctx.reset();
                }}
              >
                Start new order
              </Button>
            )}
          </Grid>
        </Grid>
      </Alert>
    </Grid>
  );
};

const AlertLink = styled.span`
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: black;
    text-decoration: underline;
  }
`;
