import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import { BRANDS_LIST } from '@tyrio/shared-vars';
import _ from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../context/AuthContext';
import { useFilter } from '../../../../hooks/useFilter';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import {
  ActiveStep,
  activeStepsByIndexAndCategory,
} from './SearchPosConstants';

export type MotoDesiredOption = 'FRONT' | 'REAR' | 'FRONT/REAR';

export enum PosSearchActiveTab {
  default_search,
  wheels_search,
}

interface IPosSearchContext {
  width: string | null;
  setWidth: (item: string | null) => void;
  height: string | null;
  setHeight: (item: string | null) => void;
  rimSize: string | null;
  setRimSize: (item: string | null) => void;
  category: string | null;
  setCategory: (item: string | null) => void;
  subcategories:
    | {
        value: number;
        label: string;
      }[]
    | undefined;
  brands: string[];
  setBrands: (item: string[]) => void;
  selectedSizes: string[] | null;
  setSelectedSizes: (item: string[] | null) => void;
  colorGroups: string[];
  setColorGroups: (item: string[]) => void;
  selectedRimSizes: string[];
  setSelectedRimSizes: (item: string[]) => void;
  resetAllValues: () => void;
  categoryId: number | '' | null;
  categoryName: string | null;
  addNewValue: boolean;
  setAddNewValue: (item: boolean) => void;
  activeStep: ActiveStep;
  currentStepIndex: number;
  setCurrentStepIndex: Dispatch<SetStateAction<number>>;
  motoDesiredOption: MotoDesiredOption | null;
  setMotoDesiredOption: (el: MotoDesiredOption | null) => void;
  activeTab: number;
  setActiveTab: (val: number) => void;
}

export const PosSearchContext = React.createContext<IPosSearchContext>({
  width: null,
  setWidth: () => null,
  height: null,
  setHeight: () => null,
  rimSize: null,
  setRimSize: () => null,
  category: null,
  setCategory: () => null,
  subcategories: [],
  brands: [],
  setBrands: () => null,
  selectedSizes: null,
  setSelectedSizes: () => null,
  colorGroups: [],
  setColorGroups: () => null,
  selectedRimSizes: [],
  setSelectedRimSizes: () => null,
  resetAllValues: () => null,
  categoryId: null,
  categoryName: null,
  addNewValue: true,
  setAddNewValue: () => null,
  activeStep: 'CATEGORY',
  currentStepIndex: 0,
  setCurrentStepIndex: () => null,
  motoDesiredOption: null,
  setMotoDesiredOption: () => null,
  activeTab: 0,
  setActiveTab: () => null,
});

interface PosSearchProviderProps {
  children: React.ReactNode;
}

interface PosSearchInputShape {
  currentStepIndex: number;
  category: string | null;
  width: string | null;
  height: string | null;
  rimSize: string | null;
  selectedSizes: string[] | null;
  motoDesiredOption: MotoDesiredOption | null;
}

const PosSearchProvider = ({ children }: PosSearchProviderProps) => {
  const { filterValues, ...filtersHook } = useFilter();
  const { user } = useAuth();

  const initialInput = {
    currentStepIndex: 0,
    category: null,
    width: null,
    height: null,
    rimSize: null,
    selectedSizes: null,
    motoDesiredOption: null,
    warehouseId: null,
  };

  const [input, setInput] = useLocalStorage<PosSearchInputShape>(
    `@@pos`,
    initialInput
  );

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(
    filterValues['size'] === undefined ? 0 : input.currentStepIndex
  );
  const [width, setWidth] = useState<string | null>(
    filterValues['size'] === undefined ? null : input.width
  );
  const [height, setHeight] = useState<string | null>(
    filterValues['size'] === undefined ? null : input.height
  );
  const [rimSize, setRimSize] = useState<string | null>(
    filterValues['size'] === undefined ? null : input.rimSize
  );
  const [category, setCategory] = useState<string | null>(
    filterValues['size'] === undefined ? null : input.category
  );
  const [selectedSizes, setSelectedSizes] = useState<string[] | null>(
    filterValues['size'] === undefined ? null : input.selectedSizes
  );
  const [selectedRimSizes, setSelectedRimSizes] = useState<string[]>([]);
  const [brands, setBrands] = useState<string[]>(BRANDS_LIST);
  const [motoDesiredOption, setMotoDesiredOption] =
    useState<MotoDesiredOption | null>(
      filterValues['size'] === undefined ? null : input.motoDesiredOption
    );

  const [colorGroups, setColorGroups] = useState<string[]>([]);
  const [addNewValue, setAddNewValue] = useState(true);
  const [activeTab, setActiveTab] = useState<number>(
    PosSearchActiveTab.default_search
  );

  const categoryValues: string[] | null = category
    ? !category.split('|')
      ? null
      : category.split('|')
    : null;

  const categoryId = categoryValues && Number(categoryValues[0]);

  const categoryName = categoryValues && categoryValues[1];

  const activeStep = categoryId
    ? activeStepsByIndexAndCategory[categoryId]?.[currentStepIndex]
    : 'CATEGORY';

  const { data: mainCategories } = useQuery(
    ['all_categories'],
    () => api.fetch<DBProductCategoryApi['list']>(`all_categories`),
    { enabled: !!categoryId }
  );

  const subcategories = mainCategories
    ?.filter((category) => category.parent_category_id === categoryId)
    .map((el) => {
      return { value: el.id, label: el.name.toLocaleUpperCase() };
    });

  const resetAllValues = () => {
    setWidth(null);
    setHeight(null);
    setRimSize(null);
    setCategory(null);
    setMotoDesiredOption(null);
    setSelectedSizes(null);
    setSelectedRimSizes([]);
    setBrands([]);
    setColorGroups([]);
    setAddNewValue(true);
    filtersHook.setFilterValue({
      size: undefined,
      categoryId: undefined,
      brand: undefined,
      season: undefined,
      moto: undefined,
      subcategory: undefined,
      color: undefined,
      rimSize: undefined,
      warehouseId: user?.currentBranchId?.toString() ?? undefined,
    });
    setInputValue(initialInput);
  };

  const setInputValue = useCallback(
    (newData: PosSearchInputShape, hardSet?: boolean) => {
      setInput((oldValue) => {
        if (hardSet) {
          return Object.assign({}, { dirty: true, ...newData });
        }
        return _.cloneDeep(_.mergeWith(oldValue, { dirty: true }, newData));
      });
    },
    [setInput]
  );

  useEffect(() => {
    window.onbeforeunload = function (event) {
      setInputValue(
        {
          currentStepIndex,
          width,
          height,
          category,
          rimSize,
          motoDesiredOption,
          selectedSizes,
        },
        true
      );
      event.preventDefault();
      event.returnValue = '';
    };
  }, [
    category,
    currentStepIndex,
    height,
    input,
    rimSize,
    motoDesiredOption,
    selectedSizes,
    setInputValue,
    width,
  ]);

  return (
    <PosSearchContext.Provider
      value={{
        width,
        setWidth,
        height,
        setHeight,
        rimSize,
        setRimSize,
        category,
        setCategory,
        motoDesiredOption,
        setMotoDesiredOption,
        selectedSizes,
        setSelectedSizes,
        brands,
        setBrands,
        colorGroups,
        setColorGroups,
        subcategories,
        selectedRimSizes,
        setSelectedRimSizes,
        resetAllValues,
        categoryId,
        categoryName,
        addNewValue,
        setAddNewValue,
        currentStepIndex,
        setCurrentStepIndex,
        activeStep,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </PosSearchContext.Provider>
  );
};

export default PosSearchProvider;

export const usePosSearchContext = () => React.useContext(PosSearchContext);
