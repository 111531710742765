import { TRule } from '../../typings';

export const TYROO_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: 'c.EAN',
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c['Tyroo-article-number']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.quantity',
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
  {
    type: 'rule.operate',
    sourceKey: "c['quantity per package']",
    destination: 'product',
    targetKey: 'packageQuantity',
    operationName: 'calculateTyrooPackageQuantity',
  },
];
