import { TRule } from '../typings';
import { RegexList } from '../regex';
import { convertOutputValue } from '../helpers';
import { zipObjectDeep as _zipObjectDeep } from 'lodash';
import { RawImport } from '@tyrio/dto';

export function runRegexOperation(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.regex')
    throw new Error('runRegexOperation requires rule to be of type TRegexRule');

  const fieldKey = rule.sourceKey;
  if (!(fieldKey in input)) {
    throw new Error(
      `Operation error: ${String(fieldKey)} does not exist in ${input}`
    );
  }

  const regex = RegexList[rule.regexName];

  const matched = input[fieldKey].match(regex);

  const constructable: Record<string, string | undefined> = {};

  rule.outputMapping.forEach((deepKey, idx) => {
    constructable[deepKey] = matched?.[rule.matchMappingIdx[idx]];
  });

  return _zipObjectDeep(
    rule.outputMapping,
    rule.matchMappingIdx.map((matchIdx, idx) => {
      const retValue = matched?.[matchIdx];
      if (!retValue) {
        throw new Error(
          `No value found for ${matchIdx}. Matched keys: ${JSON.stringify(
            matched
          )}`
        );
      }
      return convertOutputValue(retValue, rule?.outputTypes?.[idx] || 'string');
    })
  );
}
