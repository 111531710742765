/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '@tyrio/api-factory';
import { DBStockOutListApi, ShippingResponse } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetShipping = (enabled: boolean) => {
  const { data, isFetched, refetch, isLoading } = useQuery(
    ['get_shipping'],
    () =>
      api.fetch<DBStockOutListApi['list']>('get_stock_out', {
        deliveryType: 'SHIPPING',
      }),
    {
      enabled,
    }
  );
  return {
    data: data?.data,
    isFetched,
    refetch,
    isLoading,
  };
};

export const useGetReadyShipping = (
  enabled: boolean,
  setWorkOrders: any,
  setReadyWorkOrders: any
) => {
  const { data, isFetched, refetch, isLoading, status, isFetching } = useQuery(
    ['get_ready_shipping'],
    () => api.fetch<DBStockOutListApi['shippingOrders']>('get_ready_shipping'),
    {
      enabled,
      refetchInterval: 180000,
      onSuccess: (res) => {
        setWorkOrders((prevState: any) => ({
          ...prevState,
          SHIPPING: (res as ShippingResponse).openOrders,
        }));
        setReadyWorkOrders((prevState: any) => ({
          ...prevState,
          SHIPPING: (res as ShippingResponse).readyOrders,
        }));
      },
    }
  );
  return {
    data: data,
    isFetched,
    refetch,
    isLoading,
    status,
    showLoader: isLoading || isFetching,
  };
};
