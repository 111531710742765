import { ENDPOINTS } from '@tyrio/api-factory';
import axios from 'axios';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../query-client';

export const useImportStockList = () => {
  return useMutation(
    (data: FormData) =>
      axios.post(`${ENDPOINTS.import_stock_list.uri}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@@apiToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      mutationKey: 'import_stock_list',
      onSuccess: () => {
        queryClient.refetchQueries('get_stock_list_products');
      },
    }
  );
};
