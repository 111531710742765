import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface IPopUp {
  open: boolean;
  setDeleteAction: (a: boolean) => void;
  setShowPopUp: (a: boolean) => void;
  text: string;
}

export const PopUp = ({
  open,
  setDeleteAction,
  setShowPopUp,
  text,
}: IPopUp) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="info"
          onClick={() => {
            setDeleteAction(false);
            setShowPopUp(false);
          }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          color="info"
          onClick={() => {
            setDeleteAction(true);
            setShowPopUp(false);
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
