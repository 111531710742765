import api from '@tyrio/api-factory';
import { DBFittingBoxSettingsApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { useMutation } from 'react-query';

export const useAddFittingBox = (refetchFittingBoxSettings: () => void) => {
  const addFittingBox = useMutation(
    (data: DBFittingBoxSettingsApi['create']['requestBody']) => {
      return api.fetch<DBFittingBoxSettingsApi['create']>(
        'add_fitting_box_settings',
        {
          ...data,
        }
      );
    },
    {
      onSuccess: () => {
        refetchFittingBoxSettings();
        ToastHelper.showToast(
          'Fitting box',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Fitting box',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          'An error occurred on add fitting box!'
        );
      },
    }
  );

  return { addFittingBox };
};
