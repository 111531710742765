import { Switch } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ApiKeyOverview from '../features/api-keys/ApiKeyOverview';
import DashboardApiKey from '../pages/dashboard/ApiKey';
export const ApiKeyRouter = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <DashboardApiKey>
        <ApiKeyOverview clientId={user?.client?.id ?? ''} />
      </DashboardApiKey>
    </Switch>
  );
};
