import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { tyrioIcons } from '@tyrio/ui-library';
import Dropzone from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';
import { FormGridProps } from '../types';

interface TyrioUserPhotoProps {
  gridProps: FormGridProps;
}
export const TyrioUserPhoto = ({ gridProps }: TyrioUserPhotoProps) => {
  return (
    <Grid {...gridProps}>
      <Container onClick={() => alert('This feature is not spported for now')}>
        <Dropzone
          onDrop={() => {
            toast('You have successfully uploaded photo!');
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Wrapper {...getRootProps()}>
              <input {...getInputProps()} />
              <img src={tyrioIcons.addUsePhoto} alt="dropzone-icon" />

              <Text>Upload photo</Text>
            </Wrapper>
          )}
        </Dropzone>
        <ToastContainer />
      </Container>
    </Grid>
  );
};

const Container = styled.div`
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 1px dashed #e6e8f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 44px;
  margin-top: 44px;
  padding: 7.5px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f6f8;
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;

const Text = styled.div`
  font-size: 12px;
  color: #919eab;
  margin-top: 8px;
`;
