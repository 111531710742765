import { Divider } from '@mui/material';
import {
  Price,
  Qty,
  Subheader,
  SubheaderContents,
  Wrapper,
} from '../OrderComponents/OrderSupplierBoxLine';
import { IWorkOrderLineItem } from '@tyrio/dto';
import classNames from 'classnames';

export const WorkOrderLineItem = ({
  data,
  isScrolling,
}: {
  data: IWorkOrderLineItem;
  isScrolling: boolean;
}) => {
  return (
    <Wrapper orderComplete={false} isScrolling={isScrolling}>
      <h4>
        {data?.brand} {data.productName}
      </h4>
      <Subheader>
        <SubheaderContents>
          <span>EAN: {data.ean}</span>
          <span>SKU: {data.sku}</span>
          <span>
            Delivery date:{' '}
            <span
              className={classNames({
                'calendar-date': true,
                green: false,
                red: false,
              })}
            >
              {data.deliveryDateFrom}
            </span>
          </span>
        </SubheaderContents>

        <Price>
          {data.sellingPrice.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
          })}
        </Price>
        <Qty style={{ minWidth: '60px', textAlign: 'center' }}>
          {data.quantity}
        </Qty>

        <Price>
          {(data.sellingPrice * data.quantity).toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
          })}
        </Price>
      </Subheader>
      <Divider />
    </Wrapper>
  );
};
