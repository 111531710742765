import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const ZPERFORMANCE_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'category_id',
    template: '72',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: "c['Producer']",
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.map',
    sourceKey: 'c.Model',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Articlenumber',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.J',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Inch',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.PCD',
    targetKey: 'pcd',
    delimiter: ', ',
    destination: 'product',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.Offset',
    targetKey: 'et',
    delimiter: ',',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["Center bore"]',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.Color',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'oem',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.Foto',
    targetKey: 'originalPhotos',
    delimiter: '|',
    destination: 'model',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.youtube_link',
  //   targetKey: 'videoUrl',
  //   destination: 'model',
  // },
    {
    type: 'rule.map',
    sourceKey: 'c["Weight of wheel in gramms"]',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  ...GLOBAL_RULESET,
];
