import { Button, CircularProgress, Grid } from '@mui/material';
import { DBSupplier } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBClientSupplierOrderItemFilters,
  InboundInvoiceApi,
} from '@tyrio/dto';
import { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import OrdersHeader from '../../../components/Orders/OrdersHeader';
import { ComponentType } from '../helpers/types';
import styled from '@emotion/styled/macro';
import { PurchaseOrdersContext } from '../../../context/PurchaseOrdersContext';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { InboundInvoiceContext } from '../../../context/InboundInvoiceContext';
import { FEATURE_FLAGS, isFlagEnabled } from '@tyrio/feature-flags';

interface PurchaseOrderTableFiltersProps {
  isSidebarOpen: boolean;
  filters: DBClientSupplierOrderItemFilters;
  setFilters: (value: DBClientSupplierOrderItemFilters) => void;
  setFilterValue?: (data: Partial<qs.ParsedQs>) => void;
}

const PurchaseOrderTableFilters = ({
  isSidebarOpen,
  filters,
  setFilters,
  setFilterValue,
}: PurchaseOrderTableFiltersProps) => {
  const history = useHistory();

  const { checked, checkedSupplier } = useContext(PurchaseOrdersContext);
  const { setInput } = useContext(InboundInvoiceContext);
  const [suppliers, setSuppliers] = useState<
    { value: string; label: string }[]
  >([]);

  useQuery(
    ['get_client_order_suppliers'],
    () =>
      api.fetch<{
        request: object;
        response: Partial<DBSupplier>[];
        requestBody: unknown;
      }>('get_client_order_suppliers'),
    {
      onSuccess: (data: DBSupplier[]) => {
        const dropDownData = data.map((i) => ({
          value: i.id as string,
          label: i.companyShortName,
        }));
        setSuppliers(dropDownData);
      },
    }
  );

  const { data, isLoading, isFetching } = useQuery(
    [
      'get_prepared_invoices',
      Object.keys(checked).join(',').replace(/^"|"$/g, ''),
    ],
    async () => {
      return await api.fetch<InboundInvoiceApi['getPrepared']>(
        'get_prepared_invoices',
        {
          stockInIds: Object.keys(checked).join(',')
            ? Object.keys(checked).join(',').replace(/^"|"$/g, '')
            : '',
          type: 'purchaseOrder',
        }
      );
    },
    {
      enabled: !!(
        !_.isEmpty(checked) && Object.keys(checked).join(',').length > 0
      ),
      onSuccess(data: InboundInvoiceApi['getPrepared']['response']) {
        console.log(data, '===> PREPARED INBOUND INVOICE DATA');
        setInput({
          ids: Object.keys(checked).join(','),
          checkedStockIns: checked,
          checkedSupplier,
          preparedInvoices: data ?? null,
          filteredSupplier:
            filters && filters.supplierIds
              ? filters?.supplierIds[0]?.toString()
              : '',
          type: 'purchaseOrder',
        });
      },
    }
  );

  const prepareInvoices = useCallback(() => {
    if (!isLoading) {
      if (data && !data?.partner)
        ToastHelper.showToast(
          'Inbound invoice',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          `We can't find partner for selected supplier: ${checkedSupplier.name}`
        );
      else if (data) history.push('inbound-invoice');
    }
  }, [checkedSupplier.name, data, history, isLoading]);

  return (
    <Grid
      style={{ padding: '10px 0 15px 0', width: '100%', overflow: 'hidden' }}
    >
      <OrdersHeader
        filters={filters}
        isSidebarOpen={isSidebarOpen}
        setFilters={setFilters}
        suppliers={suppliers}
        componentType={ComponentType.purchase}
        setFilterValue={setFilterValue}
      />
      {isFlagEnabled(FEATURE_FLAGS.SHOW_INBOUND_INVOICE) && (
        <Grid
          item
          xs={2.5}
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '20px',
          }}
        >
          <InboundButton
            disabled={_.size(checked) < 1}
            onClick={prepareInvoices}
          >
            {isLoading || isFetching ? (
              <CircularProgress
                style={{ height: '20px', width: '20px' }}
                sx={{ color: 'white' }}
              />
            ) : (
              'INBOUND INVOICE'
            )}
          </InboundButton>
        </Grid>
      )}
    </Grid>
  );
};

const InboundButton = styled(Button)`
  width: 160px;
  height: 55px;
  background: ${(props) =>
    !props.disabled ? '#1976D2' : 'rgb(0, 0, 0, 0.12)'};
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  font-family: Roboto;
  border: ${(props) => (!props.disabled ? '1px solid #1976D2' : '')};
  color: ${(props) => (!props.disabled ? 'white' : '')};

  &:hover {
    background: ${(props) =>
      !props.disabled ? '#1976D2' : 'rgb(0, 0, 0, 0.12)'};
    border: ${(props) => (!props.disabled ? '1px solid #1976D2' : '')};
    color: ${(props) => (!props.disabled ? 'white' : '')};
  }
`;

export default PurchaseOrderTableFilters;
