import styled from '@emotion/styled/macro';
import {
  CircularProgress,
  Divider,
  Grid,
  SelectChangeEvent,
} from '@mui/material';
import api from '@tyrio/api-factory';
import {
  DBCustomerOrderItemApi,
  DBCustomerOrderItemCountByStatus,
  DBCustomerOrderItemFilters,
  DBCustomerOrderItemResponse,
  SSE_ENTITY_KEYS,
} from '@tyrio/dto';
import { backIcon } from '@tyrio/ui-library';
import _ from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { InputOption } from '../../../../../helpers/enum-parser';
import NoDataFound from '../../../components/NoData/NoDataFound';
import OrdersHeader from '../../../components/Orders/OrdersHeader';
import { LoaderWrapper } from '../../../components/PageTemplate/PageTemplate';
import StyledPagination from '../../../components/Pagination/Pagination';
import FilterContainer from '../../../components/SidebarFilters/FilterContainer';
import { useWS } from '../../../context/WSContext';
import { useFilter } from '../../../hooks/useFilter';
import {
  BackButton,
  HeaderText,
  HeaderWrapperTop,
} from '../../purchase-orders/PurchaseOrderOverview';
import TabsFilter from '../../purchase-orders/components/TabsFilter';
import { ComponentType } from '../../purchase-orders/helpers/types';
import CustomerOrderTable from '../CustomerOrdersTable';
import CustomerOrderFilters from '../filters/CustomerOrderFilterContainer';
import OrderFilters from '../filters/OrderFilters';
import { DBSalesChannel } from '@prisma/client';

const DEFAULT_FILTERS: DBCustomerOrderItemFilters = {
  searchType: '',
  searchKeyword: '',
  salesChannelKeys: [],
  startDate: undefined,
  endDate: undefined,
  orderStatus: 'NEW',
};

export const pageSizeKey = '@customer_orders_page_size';

interface CustomerOrdersProps {
  sidebarOpen: boolean;
  isDirty: boolean;
  setIsDirty: (a: boolean) => void;
  isUploadInProcess: boolean;
}

const CustomerOrderDetails = ({
  sidebarOpen,
  isDirty,
  setIsDirty,
  isUploadInProcess,
}: CustomerOrdersProps) => {
  const { socket } = useWS();

  const storageSalesChannels = JSON.parse(
    (localStorage.getItem('@customer_orders_sales_channel') as string) ?? '[]'
  );

  const storagePageSize = localStorage.getItem(pageSizeKey);

  const history = useHistory();
  const [filters, setFiltersRaw] = useState<DBCustomerOrderItemFilters>({
    ...DEFAULT_FILTERS,
    salesChannelKeys: storageSalesChannels ?? [],
  });

  const [open, setOpen] = useState(false);

  const [filtersWithLabel, setFiltersWithLabel] =
    useState<Record<string, InputOption[]>>();

  const setFilters: Dispatch<SetStateAction<DBCustomerOrderItemFilters>> =
    useCallback((value) => {
      setFiltersRaw(value);
    }, []);

  const { filterValues, ...filtersHook } = useFilter({
    searchFilter: 'orderNumber',
    pageSize: storagePageSize ?? '10',
    page: '1',
    searchKeyword: '',
    salesChannelKeys: storageSalesChannels ?? [],
  });

  useEffect(() => {
    let shouldSet = false;
    const vals: Record<string, string> = {};
    if (!filterValues['searchFilter']) {
      vals['searchFilter'] = 'orderNumber';
      shouldSet = true;
    }
    if (!filterValues['pageSize']) {
      vals['pageSize'] = storagePageSize ?? '20';
      shouldSet = true;
    }
    if (!filterValues['page']) {
      vals['page'] = '1';
      shouldSet = true;
    }

    vals['salesChannelKeys'] = storageSalesChannels;

    if (shouldSet) filtersHook.setFilterValue(vals);
  }, [filterValues, filtersHook, storageSalesChannels, storagePageSize]);

  const handleChangePageSize = (event: SelectChangeEvent) => {
    filtersHook.setFilterValue({ pageSize: event.target.value });
    localStorage.setItem(pageSizeKey, event.target.value);
  };

  const page = useMemo(
    () => (filterValues['page'] ? Number(filterValues['page']) : 1),
    [filterValues]
  );
  const pageSize = useMemo(
    () => (filterValues['page'] ? Number(filterValues['pageSize']) : 20),
    [filterValues]
  );

  const setPage = useCallback(
    (value: string | number) => {
      filtersHook.setFilterValue({
        page: typeof value === 'string' ? value : String(value),
      });
    },
    [filtersHook]
  );

  const getValue = (key: string) => {
    return filterValues[key] ?? '';
  };

  const reqFilters = [
    filters['startDate'],
    filters['endDate'],
    filters['orderStatus'],
    filters['salesChannelKeys'],
    filters['searchKeyword'],
    filters['searchType'],
    filters['sidebarFilterValues'],
    getValue('myOrders'),
    getValue('category'),
    getValue('paymentMethod'),
    getValue('branch'),
    getValue('suppliers'),
    getValue('orderStatus'),
    getValue('paymentStatus'),
    filterValues['page'],
    filterValues['pageSize'],
  ];

  const { data: customerOrdersData, isFetching } = useQuery(
    ['get_customer_orders', reqFilters],
    () =>
      api.fetch<DBCustomerOrderItemApi['list']>('get_customer_orders', {
        filters,
        page: Number(filterValues['page'] ?? 1),
        pageSize: Number(filterValues['pageSize'] ?? 10),
      }),
    {
      onSuccess: () => {
        let orderId = filterValues['selected'];
        let key = 'lock';

        if (!filterValues['selected']) {
          orderId = window.localStorage.getItem(
            'selected-customer-order'
          ) as string;
          key = 'unlock';
        }

        socket?.emit(key, {
          entityName: SSE_ENTITY_KEYS.CUSTOMER_ORDER,
          entityId: orderId,
        });
      },
    }
  );

  useEffect(() => {
    const keyword = (filterValues?.['searchKeyword'] as string) ?? '';

    setFilters((prevState) => ({
      ...prevState,
      orderStatus:
        prevState?.['orderStatus'] === 'NEW' && keyword !== ''
          ? 'all'
          : prevState.orderStatus,
      searchType: (filterValues?.['searchFilter'] as string) ?? '',
      searchKeyword: keyword,
      sidebarFilterValues: _.omit(filterValues, 'selected'),
      salesChannelKeys:
        (filterValues?.['salesChannelKeys'] as DBSalesChannel[]) ?? [],
    }));
  }, [filterValues, setFilters]);

  const setSelectedOrderData = useCallback(
    (value: DBCustomerOrderItemResponse | null) => {
      filtersHook.setFilterValue({ selected: value?.id });
      window.localStorage.setItem('selected-customer-order', `${value?.id}`);
    },
    [filtersHook]
  );

  const { data: countData } = useQuery(
    ['count_customer_orders_by_status', reqFilters],
    () =>
      api.fetch<{
        request: DBCustomerOrderItemApi['list']['request'];
        requestBody: DBCustomerOrderItemApi['list']['requestBody'];
        response: DBCustomerOrderItemCountByStatus[];
      }>('count_customer_orders_by_status', {
        filters,
      })
  );

  const count = customerOrdersData?.count;
  const totalPageNumber =
    customerOrdersData && count
      ? count / pageSize - Math.floor(count / pageSize) === 0
        ? count / pageSize
        : Math.floor(count / pageSize) + 1
      : 1;

  return (
    <CustomerLayout
      selectedOrder={!!filterValues?.['selected']}
      open={sidebarOpen}
    >
      <HeaderWrapperTop>
        <BackButton onClick={() => history.push('/dashboard')}>
          <img src={backIcon} alt="back-icon" />
        </BackButton>
        <HeaderText>Customer orders</HeaderText>
      </HeaderWrapperTop>
      <Divider />

      <>
        <OrdersHeader
          filters={filters}
          isSidebarOpen={true}
          setFilters={setFilters}
          setFilterValue={filtersHook.setFilterValue}
          componentType={ComponentType.customer}
          filterValues={filterValues}
        />

        <Grid
          container
          sx={{
            marginTop: '25px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <FilterContainer onOpen={() => setOpen(true)} />
          <TabsFilter
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
            countOrders={countData ?? []}
            componentType={ComponentType.customer}
            setFilterValue={filtersHook.setFilterValue}
          />
        </Grid>
        <Grid
          container
          sx={{
            marginTop: '15px',
            padding: '0 16px',
          }}
        >
          <OrderFilters
            isOpen={open}
            setOpen={setOpen}
            filterValues={filterValues}
            setFilterValue={filtersHook.setFilterValue}
            setFiltersWithLabel={setFiltersWithLabel}
          />
          <CustomerOrderFilters
            filterValues={filterValues}
            setFilterValue={filtersHook.setFilterValue}
            filtersWithLabel={filtersWithLabel}
          />
        </Grid>
      </>
      {isFetching ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : customerOrdersData && customerOrdersData.count > 0 ? (
        <>
          <CustomerOrderTable
            data={customerOrdersData?.data || []}
            selectedOrderData={null}
            setSelectedOrder={setSelectedOrderData}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            isUploadInProcess={isUploadInProcess}
            filters={filterValues}
          />
          <StyledPagination
            totalPageNumber={totalPageNumber}
            page={page}
            setPage={setPage}
            pageSize={Number(filterValues['pageSize'])}
            selectedOrderData={null}
            handleChangePageSize={handleChangePageSize}
            menuItems={[20, 50, 100, 200]}
          />
        </>
      ) : (
        <NoDataFound />
      )}
    </CustomerLayout>
  );
};

export default CustomerOrderDetails;

export const CustomerLayout = styled.div<{
  selectedOrder: boolean | null;
  open: boolean;
}>`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100svh - 44px);
  border-radius: 8px;
  width: ${(prop) => (!prop.selectedOrder ? '100%' : `calc(100% - 816px)`)};
  transition: linear 0.4s;
  position: relative;
  left: 0;
`;
