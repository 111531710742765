import { useQuery } from 'react-query';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import { DBProductCategory } from '@prisma/client';
import { useMemo, useCallback } from 'react';

export const useFetchCategories = () => {
  const { data: mainCategories } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  );

  const shapedCategories = useMemo(() => {
    const response: Record<number, DBProductCategory> = {};
    mainCategories?.forEach((c) => {
      response[c.id] = c;
    });
    return response;
  }, [mainCategories]);

  const getParentNames = useCallback(
    (c: DBProductCategory, existing: string[]): string[] => {
      if (!c.parent_category_id) return [c.name, ...existing];

      return getParentNames(shapedCategories[c.parent_category_id], [
        c.name,
        ...existing,
      ]);
    },
    [shapedCategories]
  );
  const categories = useMemo(() => {
    return (
      mainCategories
        ?.map((c) => {
          const names = getParentNames(c, []);
          return {
            label: names.join(' > '),
            id: c.id,
            main: !c.parent_category_id,
          };
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1)) || []
    );
  }, [mainCategories, getParentNames]);

  return { categories };
};
