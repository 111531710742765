import { FormControlLabel, Switch, Typography } from '@mui/material';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import { sideMenu } from '../../constants/constants';
import {
  AnimationDiv,
  ChildrenWrapper,
  DeleteWrapper,
  HeadingWrapper,
  Text,
  TitleWrapper,
  WmsIconStyled,
  Wrapper,
} from './SideMenuLayout.style';

type SideMenuType =
  | 'search'
  | 'details'
  | 'inbound'
  | 'outbound'
  | 'pasteList'
  | 'print'
  | 'cart'
  | 'posSearch'
  | 'posDetails'
  | 'branches'
  | 'suppliers'
  | 'services'
  | 'posCart'
  | 'customer'
  | 'deliveryMethod'
  | 'posCustomer'
  | 'vehicle'
  | 'posPayment'
  | 'posAppointment';

interface SideMenuLayoutProps {
  children: React.ReactNode;
  type: SideMenuType;
  showDelete?: boolean;
  deleteText?: string;
  handleDelete?: () => void;
  shouldShowTitle?: boolean;
  showSwitch?: boolean;
  checked?: boolean;
  footer?: React.ReactNode;
  hideTitle?: boolean;
  switchLabel?: string;
  switchChecked?: boolean;
  setSwitchChecked?: (a: boolean) => void;
  viewBox?: string;
  switchDisabled?: boolean;
}

export const SideMenuLayout = ({
  children,
  type,
  showDelete = false,
  handleDelete,
  deleteText,
  shouldShowTitle = true,
  showSwitch,
  footer,
  switchLabel,
  switchChecked,
  setSwitchChecked,
  viewBox = '0 0 24 24',
  switchDisabled = false,
}: SideMenuLayoutProps) => {
  const { icon, title } = sideMenu[type];

  return (
    <AnimationDiv>
      <Wrapper>
        {shouldShowTitle && (
          <HeadingWrapper
            style={{
              // display: !shouldShowTitle ? 'none' : '',
              padding: '0px 16px 0 16px',
            }}
          >
            <TitleWrapper>
              <WmsIcon icon={icon} color="info" viewBox={viewBox} fill="none" />
              <Typography variant="subtitle1" fontSize={18}>
                {title}
              </Typography>
            </TitleWrapper>
            {showDelete && (
              <DeleteWrapper onClick={handleDelete}>
                <Text id="delete_text">{deleteText}</Text>
                <WmsIconStyled icon={wmsIcons.bin} id="delete_icon" />
              </DeleteWrapper>
            )}
            {showSwitch && (
              <FormControlLabel
                control={
                  <Switch
                    data-cy="switch"
                    color="info"
                    onChange={() => {
                      if (setSwitchChecked) setSwitchChecked(!switchChecked);
                    }}
                    checked={switchChecked}
                    disabled={switchDisabled}
                  />
                }
                label={switchLabel ?? 'Analytics'}
              />
            )}
          </HeadingWrapper>
        )}
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {footer && <div id="footer_side_menu">{footer}</div>}
      </Wrapper>
    </AnimationDiv>
  );
};
