/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import { InputRef, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RenderTableCode } from './TableComponents/RenderTableCode';
import { RenderTableCell } from './TableComponents/RenderTableCell';
import _ from 'lodash';
import { RenderTotalCell } from './TableComponents/RenderTotalCell';
import {
  Alert,
  Button,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import classNames from 'classnames';
import { RenderTableProductName } from './TableComponents/RenderTableProductName';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import { RenderFooterCell } from './TableComponents/RenderFooterCell';
import { RenderFooterSupplierTitle } from './TableComponents/RenderFooterSupplierTitle';
import { RenderTotalFooterCell } from './TableComponents/RenderTotalFooterCell';
import {
  DataIndex,
  DataTypeDimension,
  getColumnSearchPropsDimensions,
} from './TableComponents/ColumnSearchProps';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { DBPriceCompareApi } from '@tyrio/dto';
import { PriceComparisonContext } from './context';
import { FlowHeader } from './FlowHeader';
import {
  ArrowBack,
  ArrowForward,
  Cancel,
  UnfoldLess,
  UnfoldMore,
} from '@mui/icons-material';
import { tyrioUI } from '@tyrio/ui-library';
import { useSyncedPrices } from './utils';
import { SwitchHeaders } from './SwitchHeaders';

const COLUMN_WIDTH = 120;

export const PriceCompareTableDimensionV2 = ({
  RES,
}: {
  RES: DBPriceCompareApi['compareCode']['response'];
}) => {
  const ctx = useContext(PriceComparisonContext);

  const [, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const qty = useMemo(() => ctx.data.qty || {}, [ctx.data.qty]);

  const { prices, lowestPrices, mainSupplierId, suppliers, safeSetQty } =
    useSyncedPrices(RES);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const totals = useMemo(() => {
    const totalValues: {
      sum: Record<string, number>;
      price: Record<string, number>;
    } = {
      sum: {},
      price: {},
    };

    Object.keys(qty).forEach((supplierId) => {
      Object.keys(qty[supplierId]).forEach((productId) => {
        if (!totalValues.sum[productId]) totalValues.sum[productId] = 0;
        if (!totalValues.price[productId]) totalValues.price[productId] = 0;
        let price = prices?.[productId]?.[supplierId]?.value || 0;

        const priceObject = RES.prices?.[productId]?.[supplierId];

        if (ctx.data.includeEcoTax && price && priceObject?.ecoTax)
          price += priceObject.ecoTax;
        if (ctx.data.includeBonus && price && priceObject?.bonus)
          price -= price * (priceObject.bonus / 100);

        if (ctx.data.includeDeliveryCost && price && priceObject?.shipping)
          price += priceObject.shipping;

        totalValues.sum[productId] += qty[supplierId][productId];

        totalValues.price[productId] += qty[supplierId][productId] * price;

        totalValues.sum[productId] =
          totalValues.sum[productId] + qty[supplierId][productId];

        totalValues.price[productId] =
          totalValues.price[productId] + qty[supplierId][productId] * price;
      });
    });
    return totalValues;
  }, [
    RES.prices,
    ctx.data.includeBonus,
    ctx.data.includeDeliveryCost,
    ctx.data.includeEcoTax,
    prices,
    qty,
  ]);

  const verticalTotals = useMemo(() => {
    const totalValues: Record<string, { qty: number; price: number }> = {};
    const finalTotalValue: { qty: number; price: number } = {
      qty: 0,
      price: 0,
    };

    const productsSum: Record<string, number> = {};

    Object.values(qty || {}).forEach((shape) => {
      Object.keys(shape).forEach((productId) => {
        if (!productsSum[productId]) productsSum[productId] = 0;

        productsSum[productId] += shape[productId];
      });
    });

    suppliers.forEach((supplier) => {
      let supplierQtySum = 0;
      let supplierPriceSum = 0;
      const supplierId = supplier.supplierId;
      const productQty = qty[supplierId] || {};
      Object.keys(productQty).forEach((productId) => {
        supplierQtySum += productQty[productId] || 0;
        supplierPriceSum +=
          productQty[productId] *
          (prices?.[productId]?.[supplierId]?.value || 0);
      });
      finalTotalValue.qty += supplierQtySum;
      finalTotalValue.price += supplierPriceSum;

      totalValues[supplierId] = {
        qty: supplierQtySum,
        price: supplierPriceSum,
      };
    });
    return { totalValues, finalTotalValue };
  }, [prices, qty, suppliers]);

  const CODE_COLUMNS: ColumnsType<DataTypeDimension> = [
    {
      title: 'Code' as any,
      width: 150,
      dataIndex: 'ean',
      key: 'name',
      fixed: 'left',
      filterMode: 'tree',
      ...getColumnSearchPropsDimensions(
        'ean',
        searchInput,
        searchedColumn,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn
      ),
      render: (__data, row) => {
        if (row.uid.startsWith('SIZE-'))
          return ctx.data.showCode ? <div>{row.productName}</div> : null;
        return (
          <RenderTableCode
            manufacturerCode={row.manufacturerCode}
            ean={row.ean}
          />
        );
      },
    },
  ];

  const PRODUCT_NAME_COLUMNS: ColumnsType<DataTypeDimension> = [
    {
      title: 'Product Name' as any,
      width: 250,
      dataIndex: 'productName',
      key: 'productName',
      fixed: 'left',
      ...getColumnSearchPropsDimensions(
        'productName',
        searchInput,
        searchedColumn,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn
      ),
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      render: (__data, row) => {
        if (row.uid.startsWith('SIZE-'))
          return !ctx.data.showCode ? <div>{row.productName}</div> : null;

        return (
          <RenderTableProductName
            brand={row.brand}
            productName={row.productName}
          />
        );
      },
    },
  ];

  const SUPPLIER_COLUMNS: ColumnsType<DataTypeDimension> = suppliers.map(
    (s, idx) => ({
      title: ctx.data.showSupplierNames
        ? s.name
        : s.mainSupplier
        ? 'Main supplier'
        : `Supplier ${idx + 1}`,
      width: COLUMN_WIDTH,
      dataIndex: 'ean',
      key: s.supplierId,
      fixed: s.mainSupplier ? 'left' : false,
      sorter: (a, b) => {
        if (a.children || b.children) {
          const aChildrenSum = a.children
            .map((item) => prices?.[item.uid]?.[s.supplierId]?.value || 0)
            .reduce((acc, curr) => acc + curr, 0);
          const bChildrenSum = b.children
            .map((item) => prices?.[item.uid]?.[s.supplierId]?.value || 0)
            .reduce((acc, curr) => acc + curr, 0);

          return aChildrenSum - bChildrenSum;
        }

        const a_data = prices?.[a.uid]?.[s.supplierId]?.value || 0;
        const b_data = prices?.[b.uid]?.[s.supplierId]?.value || 0;

        return a_data - b_data;
      },
      render: (__data, row) => {
        if (row.uid.startsWith('SIZE-')) return null;
        const supplierId = s.supplierId;

        const data = prices[row.uid][supplierId];
        const mainSupplierPrice =
          prices?.[row.uid]?.[mainSupplierId]?.value ?? null;

        return (
          <RenderTableCell
            lowestPrices={lowestPrices[row.uid]}
            isManufacturer={s.supplierType === 'MANUFACTURER'}
            shouldShowStockZero={
              s.supplierType === 'MANUFACTURER' && !!ctx.data.showStockZero
            }
            inputValue={qty?.[supplierId]?.[row.uid]}
            setInputValue={(value) => safeSetQty(supplierId, row.uid, value)}
            mainSupplierPrice={mainSupplierPrice}
            price={data?.value}
            priceObject={data}
            quantity={data?.quantity}
            open={ctx.data.openRow === row.uid}
            shouldShowPercentages={!!ctx.data.showPercentages}
          />
        );
      },
    })
  );

  const TOTAL_COLUMNS: ColumnsType<DataTypeDimension> = [
    {
      title: 'Total',
      key: 'operation',
      fixed: 'right',
      className: 'total',
      width: 160,
      sorter: (a, b) => {
        if (a.children || b.children) {
          const aChildrenSum = a.children
            .map((item) => totals.price[item.uid] || 0)
            .reduce((acc, curr) => acc + curr, 0);
          const bChildrenSum = b.children
            .map((item) => totals.price[item.uid] || 0)
            .reduce((acc, curr) => acc + curr, 0);

          return aChildrenSum - bChildrenSum;
        }

        const a_data = totals.price[a.uid] || 0;
        const b_data = totals.price[b.uid] || 0;

        return a_data - b_data;
      },
      render: (_data, row) => {
        if (row.uid.startsWith('SIZE-')) return null;
        const productId = row.uid;

        const totalPrice = totals.price[productId] || 0;
        const totalSum = totals.sum[productId] || 0;

        return <RenderTotalCell price={totalPrice} quantity={totalSum} />;
      },
    },
  ];

  const columns: ColumnsType<DataTypeDimension> = [
    ...(ctx.data.showCode ? CODE_COLUMNS : []),
    ...PRODUCT_NAME_COLUMNS,
    ...SUPPLIER_COLUMNS,
    ...TOTAL_COLUMNS,
  ];

  const groupedProducts = useMemo(() => {
    const productList = Object.values(RES.products || {});
    return _.groupBy(productList, 'size');
  }, [RES.products]);

  const data: DataTypeDimension[] = useMemo(
    () =>
      Object.keys(groupedProducts).map((p) => {
        const children = groupedProducts[p];
        return {
          key: p,
          uid: `SIZE-${p}`,
          productName: p,
          brand: '',
          ean: '',
          manufacturerCode: '',
          children: children.map((child) => ({
            key: child.uid,
            uid: child.uid,
            productName: child.productName,
            brand: child.model?.brand ?? '',
            ean: child.ean ?? '',
            manufacturerCode: child.manufacturerCode ?? '',
          })),
        };
      }),
    [groupedProducts]
  );

  const allExpanded = useMemo(() => {
    return ctx.data.expandedKeys?.length === ctx.data.dimensions?.sizes?.length;
  }, [ctx.data.dimensions?.sizes, ctx.data.expandedKeys]);

  return (
    <PageTemplateWrapper
      style={{
        paddingRight: ctx.canSidebarOpen && ctx.data.sidebarOpen ? 450 : 0,
        transition: 'all .3s ease',
      }}
    >
      <PageTemplateContent>
        <FlowHeader />
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid
            item
            xs={10}
            sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            <SwitchHeaders />
          </Grid>
          <Grid item xs={2}>
            <List sx={{ width: '100%' }}>
              <ListItemButton
                onClick={() =>
                  !allExpanded
                    ? ctx.setInputValue(
                        {
                          ...ctx.data,
                          expandedKeys: ctx.data.dimensions?.sizes || [],
                        },
                        true
                      )
                    : ctx.setInputValue({ ...ctx.data, expandedKeys: [] }, true)
                }
              >
                <ListItemAvatar>
                  {!allExpanded ? <UnfoldMore /> : <UnfoldLess />}
                </ListItemAvatar>
                <ListItemText
                  primary={!allExpanded ? 'Expand all' : 'Collapse all'}
                />
              </ListItemButton>
            </List>
          </Grid>
        </Grid>

        <Wrapper>
          <Table
            expandable={{
              defaultExpandAllRows: true,
              expandRowByClick: true,
              indentSize: 0,
              showExpandColumn: true,
              onExpandedRowsChange: (rowChanges) => {
                ctx.setInputValue(
                  { ...ctx.data, expandedKeys: rowChanges as string[] },
                  true
                );
              },
              expandedRowKeys: ctx.data.expandedKeys,
            }}
            columns={columns}
            dataSource={data}
            bordered
            indentSize={0}
            scroll={{ x: 9 * COLUMN_WIDTH }}
            rowClassName={(record) => {
              return classNames('pc_row', {
                open: record.uid === ctx.data.openRow,
              });
            }}
            pagination={{
              style: {
                paddingLeft: 16,
                paddingRight: 16,
              },
              defaultPageSize: 50,
              size: 'default',
              responsive: true,
              totalBoundaryShowSizeChanger: 10,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  ctx.setInputValue({ openRow: record.uid });
                },
              };
            }}
            summary={() => {
              const OFFSET = ctx.data.showCode ? 1 : 0;
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row className="footerTitles">
                    {ctx.data.showCode && (
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={OFFSET}></Table.Summary.Cell>
                    {suppliers.map((s, idx) => (
                      <Table.Summary.Cell
                        key={s.supplierId}
                        index={idx + 1 + OFFSET}
                      >
                        <RenderFooterSupplierTitle supplierName={s.name} />
                      </Table.Summary.Cell>
                    ))}
                    <Table.Summary.Cell index={suppliers.length + OFFSET + 1}>
                      <RenderFooterSupplierTitle supplierName={'Total'} />
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    {ctx.data.showCode && (
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={OFFSET}>
                      <h1>Total:</h1>
                    </Table.Summary.Cell>
                    {suppliers.map((s, idx) => (
                      <Table.Summary.Cell
                        key={s.supplierId}
                        index={idx + 1 + OFFSET}
                      >
                        <RenderFooterCell
                          price={verticalTotals.totalValues[s.supplierId].price}
                          quantity={
                            verticalTotals.totalValues[s.supplierId].qty
                          }
                        />
                      </Table.Summary.Cell>
                    ))}
                    <Table.Summary.Cell index={suppliers.length + OFFSET + 1}>
                      <RenderTotalFooterCell
                        price={verticalTotals.finalTotalValue.price}
                        quantity={verticalTotals.finalTotalValue.qty}
                      />
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Wrapper>
        <BottomFixed>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
              <Button
                variant={'outlined'}
                color={'info'}
                startIcon={<ArrowBack color={'info'} />}
                sx={{ marginRight: 2 }}
                onClick={() => {
                  ctx.setInputValue({ screen: 'intro' });
                }}
              >
                Back
              </Button>
              <Button
                variant={'outlined'}
                color={'warning'}
                startIcon={<Cancel color={'warning'} />}
                onClick={() => {
                  ctx.reset();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {verticalTotals.finalTotalValue.qty === 0 && (
                <Alert
                  severity="warning"
                  sx={{ marginRight: 2, padding: '0 16px' }}
                >
                  Please fill some values in the order.
                </Alert>
              )}
              {ctx.hasPlacedOrders && (
                <Alert
                  severity="warning"
                  sx={{ marginRight: 2, padding: '0 16px' }}
                >
                  You've already started the order process.
                </Alert>
              )}

              <Button
                variant={'contained'}
                color={'info'}
                disabled={verticalTotals.finalTotalValue.qty === 0}
                onClick={() => ctx.processOrder()}
                endIcon={<ArrowForward />}
                style={{ marginRight: `${tyrioUI.spacing.l}px` }}
              >
                Order preview
              </Button>
            </Grid>
          </Grid>
        </BottomFixed>
        <BottomSpacer />
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};
const BottomFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const BottomSpacer = styled.div`
  display: block;
  height: 100px;
  width: 100%;
`;
const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  * {
    font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
  }

  .ant-table {
    border-radius: 0;
  }

  .ant-table-row-level-0 {
    cursor: pointer;
    .ant-table-cell {
      background: #eff5fc !important;
      //border-left-color: rgba(25, 118, 210, 0.08) !important;
      //border-right-color: rgba(25, 118, 210, 0.08) !important;
      //
      &:nth-child(2n) {
        background: #eff5fc !important;
      }
    }
  }

  .ant-table-row-level-1 {
    .ant-table-row-indent {
      display: none;
    }
    .ant-table-row-expand-icon {
      display: none;
    }
  }

  .ant-table-container {
    border-radius: 0;
  }
  .ant-table-content {
    table {
      border-radius: 0;
    }
  }

  .ant-table-thead {
    tr {
      th {
        border-radius: 0;
      }
    }
  }
  .pc_row {
    & > .ant-table-cell {
      &:nth-child(2n - 1) {
        //background: red;
      }
      &:nth-child(2n) {
        background: #f9fafb;
      }
    }
    &.open {
      & > .ant-table-cell {
        background: #fff8e5;
      }
      &:hover {
        & > .ant-table-cell {
          background: #fff8e5;
        }
        background: #fff8e5;
      }
    }
  }
  .footerTitles {
    & > .ant-table-cell {
      background-color: #ecf0f4;
    }
  }
`;
