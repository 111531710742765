import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';
// import { genericGenerateSpecialMarkings } from './operations.helpers';

export function calculateWheelTradePrice(input: RawImport, _rule: TRule) {
  const PCDarray = input['pcd'].split(' ');
  const ETarray = input['et'].split(' ');

  let price = parseFloat(input['your_net_price']);

  // Custom Drilling Cost - 12 euro per wheel.
  if (PCDarray.length > 3) {
    price += 12;
  }

  // Custom ET change cost - 12 euro per wheel.
  if (ETarray.length > 2) {
    price += 12;
  }

  return price;
}
