/* eslint-disable @typescript-eslint/no-explicit-any */
import { SxProps, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField/TextField';
import * as React from 'react';
import { useCallback } from 'react';

interface MultiselectChipProps {
  data: string[] | { label: string; value: string }[];
  title: string;
  error?: string;
  onChange: (value: string[]) => void;
  sx?: SxProps<Theme> | undefined;
}
type InputOption = { label: string; value: string };
/**
 * !!!!!!!!!!! DO NOT USE !!!!!!!!!!!
 * Use MultiselectChipInput instead
 *
 * @deprecated
 * */
export default function MultipleSelectChip(props: MultiselectChipProps) {
  const [selectedData, setSelectedDataStateValue] = React.useState<
    string[] | InputOption[]
  >([]);
  const { data, title, error, onChange } = props;

  const setSelectedData = useCallback(
    (data: string[] | InputOption[]) => {
      setSelectedDataStateValue(data);
      onChange(
        data.map((item) => {
          if (typeof item === 'string') return item;
          return item.value;
        })
      );
    },
    [onChange]
  );

  return (
    <FormControl sx={{ m: 1, width: '100%', ...(props.sx || {}) }}>
      <Autocomplete
        disableCloseOnSelect
        multiple
        key={title}
        id={title}
        getOptionLabel={(option: string | InputOption) =>
          typeof option === 'string' ? option : option.label
        }
        defaultValue={[]}
        disablePortal
        filterSelectedOptions
        options={data.map((item) =>
          typeof item === 'string' ? item : item.value
        )}
        value={selectedData.map((item) => {
          if (typeof item === 'string') return item;
          return item.value;
        })}
        onChange={(__, value, reason) => {
          if (reason === 'clear') setSelectedData([]);
          else setSelectedData(value);
        }}
        renderInput={(params) => (
          <TextField
            error={!!error}
            helperText={error}
            {...params}
            label={title}
          />
        )}
      />
    </FormControl>
  );
}
