import styled from '@emotion/styled';

interface ComingSoonMiniProps {
  text?: string;
}
const ComingSoonMini = ({ text }: ComingSoonMiniProps) => {
  return (
    <Container>
      <Message>{text ?? 'Coming Soon'}</Message>
    </Container>
  );
};

const Container = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  background: rgb(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 12px;
`;

export default ComingSoonMini;
