import { PreprocessKeysShape } from './types.helper';

export const handlePreprocessConversions = (
  json: Record<string, string>[],
  preprocessKeys: PreprocessKeysShape
) => {
  console.log('====> Running handlePreprocessConversions');
  return json.map((newItem, idx) => {
    Object.keys(preprocessKeys).forEach((ppKey) => {
      Object.keys(preprocessKeys[ppKey]).forEach((replaceKey) => {
        const replaceValue = preprocessKeys[ppKey][replaceKey];

        if (newItem[ppKey]) {
          newItem[ppKey] = newItem[ppKey]
            .replace(replaceKey, replaceValue)
            .replace(/ +(?= )/g, '')
            .trim();
        }
      });
    });

    json[idx] = newItem;

    return newItem;
  });
};
