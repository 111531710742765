import * as z from "zod"
import { CompleteDBUser, RelatedDBUserModel } from "./index"

export const DBPosItemFavoriteModel = z.object({
  id: z.string(),
  ean: z.string(),
  userId: z.string(),
})

export interface CompleteDBPosItemFavorite extends z.infer<typeof DBPosItemFavoriteModel> {
  user: CompleteDBUser
}

/**
 * RelatedDBPosItemFavoriteModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBPosItemFavoriteModel: z.ZodSchema<CompleteDBPosItemFavorite> = z.lazy(() => DBPosItemFavoriteModel.extend({
  user: RelatedDBUserModel,
}))
