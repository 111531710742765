import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled/macro';

import {
  Card,
  CardWrapper,
} from '../SearchPOS/ReusableSelectors/BrandSelector';
import { Content, HeaderText } from '../../steps/Search/SelectStepper';
import {
  VehicleSteps,
  useVehicleCtx,
} from '../../../../context/VehicleContext';
import { useGetVehicleBrands } from './query/get-brands';
import { IVehicleBrand } from '@tyrio/dto';
import { TitleWrapper } from './Generation';
import { isEmpty } from 'lodash';
import NoData from '../DataIndicators/NoData';
import { useGetData } from './query/data-scraping';
import { useEffect } from 'react';

export const Brand = () => {
  const { selectedBrand, setSelectedBrand, setActiveStep, updateValueChanges } =
    useVehicleCtx();

  const { vehicleBrands, isFetched, isLoading, refetch } =
    useGetVehicleBrands();
  const { mutateAsync, status } = useGetData();

  const vehicleBrandShortcutSlugs = [
    'audi',
    'bmw',
    'hyundai',
    'mazda',
    'mercedes',
    'nissan',
    'renault',
    'skoda',
    'tesla',
    'volkswagen',
  ];
  const vehicleBrandShortcuts: IVehicleBrand[] = vehicleBrands.filter((brand) =>
    vehicleBrandShortcutSlugs.includes(brand.slug)
  );

  useEffect(() => {
    if (status === 'success') refetch();
  }, [refetch, status]);

  if (isLoading) {
    return (
      <Content
        height={'700px'}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '700px',
        }}
      >
        <CircularProgress />
      </Content>
    );
  }

  if (isEmpty(vehicleBrands)) {
    return (
      <Content
        height={'700px'}
        style={{
          marginTop: '20px',
          alignItems: 'center',
          minHeight: '700px',
          justifyContent: 'flex-start',
          gap: '16px',
        }}
      >
        <NoData
          title="Data is currently not available"
          subtitle="If you'd like to add data, please press the button below."
          color="warning"
        />
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            mutateAsync();
          }}
        >
          Add data
        </Button>
        {status === 'loading' ? (
          <Typography>
            Please wait...{' '}
            <CircularProgress
              sx={{ width: '15px !important', height: '15px !important' }}
            />
          </Typography>
        ) : null}
      </Content>
    );
  }

  return (
    <Content
      height={`calc(100vh - 202px)`}
      id="vehicle_brand"
      style={{ justifyContent: 'flex-start' }}
    >
      <TitleWrapper>
        <HeaderText>SELECT BRAND</HeaderText>
      </TitleWrapper>

      <CardWrapper>
        {isFetched &&
          vehicleBrandShortcuts.map((value: IVehicleBrand) => {
            const isSelected = selectedBrand === value.slug;

            return (
              <Card
                style={{ justifyContent: 'center', gap: '10px', width: '49%' }}
                onClick={() => {
                  updateValueChanges(
                    VehicleSteps.BRAND,
                    selectedBrand !== value?.slug
                  );

                  setSelectedBrand(value?.slug);
                  setActiveStep(VehicleSteps.MODEL);
                }}
                selected={isSelected}
                key={value.slug}
              >
                <img
                  src={value?.logo}
                  alt="logo"
                  height={40}
                  width={40}
                  style={{ objectFit: 'contain' }}
                />
                {value?.name ?? ''}
              </Card>
            );
          })}
      </CardWrapper>

      <Divider
        variant="fullWidth"
        sx={{
          borderWidth: '2px',
          margin: '24px 0 24px 0',
          borderColor: '#E6E8F0',
        }}
      />

      <AllCardBrandsHeader>All car brands</AllCardBrandsHeader>

      <CardWrapper>
        {isFetched &&
          vehicleBrands.map((value: IVehicleBrand, key: number) => {
            const isSelected = selectedBrand === value.slug;

            return (
              <Card
                style={{ justifyContent: 'center', gap: '10px' }}
                width={170}
                onClick={() => {
                  updateValueChanges(
                    VehicleSteps.BRAND,
                    selectedBrand !== value?.slug
                  );

                  setSelectedBrand(value?.slug);
                  setActiveStep(VehicleSteps.MODEL);
                }}
                selected={isSelected}
                key={key}
              >
                {value?.name ?? ''}
              </Card>
            );
          })}
      </CardWrapper>
    </Content>
  );
};

const AllCardBrandsHeader = styled.div`
  color: #919eab;
  letter-spacing: 0.3px;
  max-width: 86px;
  font: 400 14px/143% Barlow, sans-serif;
`;
