import { TRule } from '../../typings';
import { GLOBAL_RULESET } from '../global';

export const MONGO_OLD_CATALOG_RULESET: TRule[] = [
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.origin',
  //   targetKey: 'origin',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  // {
  //   type: 'rule.global.operate',
  //   destination: 'model',
  //   targetKey: 'category_id',
  //   sourceKey: 'c.Subcategory',
  //   operationName: 'getCategoryNameFromSlug',
  // },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Subcategory',
    condition: 'equals',
    conditionValues: [
      'Passenger',
      '4x4/Suv',
      'Van',
      'Racing',
      'Spare',
      'Kombi',
      'Agro',
      'Farm',
      'Motorcycle',
      'Scooter',
      'Moped',
      'carChains',
      'offRoadChains',
      'Truck',
      'Road',
      'MTB',
      'Unrated',
      'Trekking',
      'City',
      'AllTerrain',
      'Rental',
      'alloyWheels',
      'steelWheels',
      'scooterTubes',
      'motoTubes',
      'BibMousse',
      'bicycleTubes',
      'Accessories',
      'carTubes',
      'Flap',
      'agroTubes',
      'truckTubes',
      'Brave',
      'Noge',
      'Nosaci za bicikle',
      'Nosaci za skije',
      'Sipke',
      'Krovne kutije',
      'miniMoto',
      'vanChains',
      'Sand',
      'Mud',
      'HardTerrain',
      'Rock',
      'Grass',
      'SkiCarrier',
      'Forklift',
      'Dumper',
      'Moto',
      'BibMouse',
      'Valve',
      'Adapter',
      'Kompleti',
      'Noge',
      'Redukcija',
      'Iglica',
      'Nastavak',
      'Nosac ventila',
      'Branik',
      'Cockpit',
      'Felge',
      'Gume',
      'Hladnjak',
      'Insekti',
      'Koza',
      'Miris',
      'Motor',
      'Odledivanje',
      'Polir',
      'Pvc',
      'Sjedala',
      'Smola',
      'Spuzva-krpa',
      'Staklo',
      'Start sprej',
      'Sampon',
      'Vosak',
      'Wd',
      'wheelValve',
      'goKartTubes',
      'wheelBolts',
      'wheelNuts',
      'wheelBoltsLocks',
      'wheelNutsLocks',
      'Navlaka za mobitel',
      'Mobiteli',
      'Kit',
      'Sport',
      'wheelSticker',
      'wheelSpacers',
      'wheelCap',
      'wheelRings',
      'Bags',
    ],
    conditionOutputs: [
      '30',
      '31',
      '32',
      '33',
      '34',
      '32',
      '65',
      '65',
      '36',
      '37',
      '39',
      '90',
      '94',
      '50',
      '56',
      '55',
      '61',
      '58',
      '56',
      '48',
      '62',
      '72',
      '73',
      '78',
      '77',
      '83',
      '81',
      '84',
      '76',
      '86',
      '85',
      '82',
      '98',
      '98',
      '96',
      '95',
      '98',
      '97',
      '41',
      '91',
      '44',
      '43',
      '47',
      '45',
      '46',
      '95',
      '53',
      '52',
      '77',
      '83',
      '87',
      '98',
      '98',
      '98',
      '89',
      '89',
      '89',
      '89',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '23',
      '89',
      '115',
      '103',
      '108',
      '110',
      '109',
      '114',
      '114',
      '116',
      '113',
      '105',
      '104',
      '106',
      '107',
      '101',
    ],
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.global.operate',
    destination: 'model',
    targetKey: 'category_id',
    operationName: 'getMongoOtherCategories',
  },

  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'equals',
    conditionValue: 'Wipers',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '24',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.Season',
    condition: 'equals',
    conditionValues: ['Summer', 'Winter', 'Allseason'],
    conditionOutputs: ['summer', 'winter', 'allseason'],
    destination: 'model',
    targetKey: 'season',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Usage',
    targetKey: 'usage',
    destination: 'model',
    outputType: 'string',
  },

  {
    type: 'rule.map',
    sourceKey: 'c.Axle',
    targetKey: 'wheelPosition',
    destination: 'model',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.size',
  //   targetKey: 'size',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.Brand',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Model_1',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },

  // PRODUCT
  {
    type: 'rule.map',
    sourceKey: 'c.Ean',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Manufacturer_Code',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.product_Name_2',
    targetKey: 'productName2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Height',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.width2',
  //   targetKey: 'width2',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.height2',
  //   targetKey: 'height2',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.Construction',
    targetKey: 'construction',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.construction2',
  //   targetKey: 'construction2',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.Rim_Size_1',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Rim_Size_2',
    targetKey: 'rimDiameter2',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.LI',
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.loadIndex2',
  //   targetKey: 'loadIndex2',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.SI',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.speedIndex2',
  //   targetKey: 'speedIndex2',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.Structure',
    targetKey: 'structure',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.MS',
    condition: 'equals',
    conditionValues: ['M+S', ''],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'ms',
    outputType: 'boolean',
    ignoreForCategories: [8, 3, 2], // There's also a global rule for this.
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.Flank',
    condition: 'in',
    conditionValue: 'MFS|FR|TS|FSL|RPB|RP|FP|ML',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'flank',
    outputValue: true,
    outputType: 'boolean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["TL/TT"]',
    targetKey: 'tubeType',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.PR',
    targetKey: 'pr',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.Sidewall',
  //   targetKey: 'sidewall',
  //   destination: 'model',
  //   outputType: 'string',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.RFD',
    targetKey: 'rfd',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Eu_Directive_Number',
    targetKey: 'euDirectiveNumber',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Rolling_resistance',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Wet_Grip',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Noise_Performance',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Noise_Class_Type',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.vehicleClass',
  //   targetKey: 'vehicleClass',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.eprelId',
  //   targetKey: 'eprelId',
  //   destination: 'product',
  // },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.eprelUrl',
  //   targetKey: 'eprelUrl',
  //   destination: 'product',
  // },
  {
    type: 'rule.map',
    sourceKey: 'c.Weight_Netto',
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.et',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c.CB',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  // {
  //   type: 'rule.map',
  //   sourceKey: 'c.pcd',
  //   targetKey: 'pcd',
  //   destination: 'product',
  //   outputType: 'string',
  // },
  {
    type: 'rule.global.operate',
    targetKey: 'special_Marking',
    destination: 'product',
    operationName: 'generateMongoCatalogCustomSpecialMarkings',
  },
  // {
  //   type: 'rule.global.operate',
  //   targetKey: 'pcd',
  //   destination: 'product',
  //   operationName: 'generatePcdEt',
  // },
  {
    type: 'rule.map_array',
    sourceKey: 'c.PCD',
    targetKey: 'pcd',
    delimiter: '|',
    destination: 'product',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c.Color',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  // Photos
  {
    type: 'rule.map_array',
    sourceKey:
      'c["Img_Url_1"]|c["Img_Url_2"]|c["Img_Url_3"]|c["Img_Url_4"]|c["Img_Url_5"]',
    targetKey: 'originalPhotos',
    delimiter: '|',
    destination: 'model',
  },
  // ePrel
  {
    type: 'rule.map',
    sourceKey: 'c.eprelID',
    targetKey: 'eprelId',
    destination: 'product',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.eprelURL',
    targetKey: 'eprelUrl',
    destination: 'product',
  },
  // ThreePMFs
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.eprel3PMSF',
    condition: 'equals',
    conditionValues: ['3PMSF', 'false', ''],
    conditionOutputs: [true, false, ''],
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
  },
  // iceGrip
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.epreliceGrip',
    condition: 'equals',
    conditionValues: ['iceGrip', 'N', ''],
    conditionOutputs: [true, false, ''],
    destination: 'product',
    targetKey: 'iceGrip',
    outputType: 'boolean',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.eprelEuDirectiveNumber',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.eprelEuDirectiveNumber',
    targetKey: 'euDirectiveNumber',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.eprelRollingResistance',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.eprelRollingResistance',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.eprelWetGrip',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.eprelWetGrip',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.eprelNoisePerformance',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.eprelNoisePerformance',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.eprelNoiseClassType',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'map_from_import',
    conditionMapSourceKey: 'c.eprelNoiseClassType',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
  },

  ...GLOBAL_RULESET,
];
