import { TRule } from '../../typings';
// import { EDI_WHEEL_B2_STOCKLIST_RULESET } from '../edi-b2/stocklist';

export const ZBR_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.EAN",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.Artnr",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: "c.Bestand",
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
