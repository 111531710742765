import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function getVandenbanSpecialMarkingsAndModelName(
  input: RawImport,
  rule: TRule
): Partial<UnfinishedData> {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'getVandenbanSpecialMarkingsAndModelName requires rule to be of type TGlobalOperateRule'
    );

  const inputValues = input['Type'].replace('*', ' * ');

  return genericGenerateSpecialMarkings(
    inputValues,
    () => input['RFT'] === 'Ja',
    true
  );
}
