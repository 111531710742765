import styled from '@emotion/styled';

const SPACING = 22;
const MOBILE_SPACING = 10;
export const PageTemplateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${SPACING}px;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    padding: ${MOBILE_SPACING}px;
  }
`;

export const PageTemplateContent = styled.div<{ isFetching?: boolean }>`
  background: white;
  border-radius: 22px;
  flex: 1;
  display: ${(props) => props.isFetching && 'flex'};
  justify-content: ${(props) => props.isFetching && 'center'};
  align-items: ${(props) => props.isFetching && 'center'};
  margin-right: ${SPACING}px;
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageTemplateSidebar = styled.div`
  min-height: 95vh;
  background: white;
  border-radius: 22px;
  width: 380px;
  padding: 16px;
  margin-right: ${SPACING}px;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PageTemplateContainer = styled.div`
  background: white;
  width: 100%;
  border-radius: 22px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;
