import api from '@tyrio/api-factory';
import { DBStockLocationsApi } from '@tyrio/dto';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { WarehouseContext } from '../../../context/WarehouseContext';

export const useCreateVirtualVirtual = () => {
  const { setWarehouse, clearSelected, setActiveStep } =
    useContext(WarehouseContext);

  const query = useMutation(
    (branchId: number) =>
      api.fetch<DBStockLocationsApi['createVirtualLocation']>(
        'create_virtual_location',
        {
          branchId,
        }
      ),
    {
      mutationKey: 'create_virtual_location',
      onSuccess: (d) => {
        if (d) {
          setWarehouse(d);
          clearSelected();
          setActiveStep('DETAILS');
          ToastHelper.showToast(
            'Warehouse',
            ToastType.SUCCESS,
            undefined,
            'Warehouse successfully updated!'
          );
        }
      },
      onError: () => {
        ToastHelper.showToast(
          'Warehouse',
          ToastType.ERROR,
          undefined,
          'There is an error during updating warehouse!'
        );
      },
    }
  );

  return { ...query };
};
