import styled from '@emotion/styled';
import { Badge, Typography } from '@mui/material';

export const Location = ({
  locationName,
  color,
  handleClick,
}: {
  locationName: string;
  color: string;
  handleClick: () => void;
}) => {
  const style = {
    '.MuiBadge-badge': {
      background: color,
    },
  };
  return (
    <Wrapper onClick={handleClick}>
      <Badge sx={style} badgeContent={''} />
      <Typography fontWeight={600} fontSize={18}>
        {locationName}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 220px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 8px;
  cursor: pointer;
`;
