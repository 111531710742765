import styled from '@emotion/styled/macro';
import { Modal, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import PerformanceCategory, {
  CategoryLevels,
} from '../../ProductDetails/ProductComponents/PerformanceCategory';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import threePmfsIcon from '../../../assets/productDetailsNew/eprel-3pmfs.svg';
import iceGripIcon from '../../../assets/productDetailsNew/eprel-icegrip.svg';

import electricIcon from '../../../assets/productDetailsNew/electric.svg';
import rfdIcon from '../../../assets/productDetailsNew/rfd.svg';

import NoisePerformance from '../../ProductDetails/ProductComponents/NoisePerformance';
import ProductEprelGenerator from '../../ProductDetails/ProductEprelGenerator';

import { DBProductApi, PhotoShape } from '@tyrio/dto';
import { ImageGalleryGlobal } from '../image-gallery/ImageGallery';
import SeasonIcon from '../../ProductDetails/ProductComponents/SeasonIcon';
import ProductDescription from '../../ProductDetails/ProductComponents/ProductDescription';
import PhotoIcon from '@mui/icons-material/Photo';

export const ProductSidebarGlobal = ({
  product,
}: {
  product: DBProductApi['getOne']['response'];
}) => {
  const [euLabelModalOpen, setEuLabelModalOpen] = useState(false);
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);

  const hasPhotos =
    product?.model?.photos &&
    (product?.model?.photos as unknown as PhotoShape[]).length > 0;

  return (
    <>
      <Wrapper>
        <Container>
          <ImageWrapper>
            {hasPhotos ? (
              <Image
                className="mainImage"
                src={(product?.model?.photos as unknown as PhotoShape[])[0].url}
                alt="tyre"
              />
            ) : (
              <NoImage style={{ height: '230px', width: '200px' }}>
                <PhotoIcon
                  color="disabled"
                  sx={{ height: '80%', width: '80%' }}
                />
                <div style={{ color: 'gray' }}>No image</div>
              </NoImage>
            )}

            {hasPhotos && (
              <ImageOverlay onClick={() => setGalleryModalOpen(true)}>
                <ZoomInIcon
                  style={{ width: '60px', height: '60px', color: '#565873' }}
                />
              </ImageOverlay>
            )}

            <TooltipWrapper>
              <Tooltip
                placement="top"
                title={'Click to preview and print Eprel/EU Label'}
                style={{ cursor: 'pointer' }}
                onClick={() => setEuLabelModalOpen(true)}
                children={
                  <PerformanceWrapper>
                    <PerformanceCategory
                      category={product.rollingResistance as CategoryLevels}
                      type="rollingResistance"
                    />

                    <PerformanceCategory
                      category={product.wetGrip as CategoryLevels}
                      type="wetGrip"
                    />

                    <NoisePerformance value={product.noisePerformance} />

                    <SpecIconsWrapper>
                      {product.threepmfs && (
                        <img src={threePmfsIcon} alt="three-pmfs" />
                      )}
                      {product.iceGrip && (
                        <img src={iceGripIcon} alt="ice-grip" />
                      )}
                    </SpecIconsWrapper>
                  </PerformanceWrapper>
                }
              />
            </TooltipWrapper>

            <MarkingsWrapper>
              {product.model?.season && (
                <SeasonIcon season={product.model.season} />
              )}

              {/*TODO: bmw logo  */}

              {product.electric && (
                <Img src={electricIcon} alt="electric-icon" />
              )}

              {product.rfd && <Img src={rfdIcon} alt="rfd-icon" />}
            </MarkingsWrapper>
          </ImageWrapper>
          <ProductDescription product={product} />
        </Container>
      </Wrapper>
      <Modal
        open={euLabelModalOpen}
        onClose={() => setEuLabelModalOpen(false)}
        aria-labelledby="eu-label"
        aria-describedby="eu-label-for-EAN"
      >
        <ModalContents>
          <ProductEprelGenerator product={product} />
        </ModalContents>
      </Modal>
      {hasPhotos && (
        <Modal
          open={galleryModalOpen}
          onClose={() => setGalleryModalOpen(false)}
          aria-labelledby="eu-label"
          aria-describedby="eu-label-for-EAN"
        >
          <ModalContents>
            <GalleryWrapper>
              <ImageGalleryGlobal
                modelImages={product?.model?.photos as unknown as PhotoShape[]}
              />
            </GalleryWrapper>
          </ModalContents>
        </Modal>
      )}
    </>
  );
};

const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
const GalleryWrapper = styled.div`
  max-width: 100vw;
  width: 800px;
`;
const ModalContents = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
`;

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
`;

const Container = styled.div`
  padding: 16px;
  gap: 24px;
  width: 100%;
  min-height: 364px;
  display: flex;
  flex-direction: column;
  border: 1px dashed #dfe3e8;
  border-radius: 16px;
  max-width: 100%;
`;

const SpecIconsWrapper = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-between;
`;

const PerformanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover img.mainImage {
    opacity: 0.4;
  }
`;

const Image = styled.img`
  object-fit: contain;
  height: 230px;
  transition: all 0.3s ease;
`;

const ImageOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const MarkingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`;

const Img = styled.img`
  width: 32px;
  margin-bottom: 10px;
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
