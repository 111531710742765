import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { GUNDLACH_STOCK_RULESET } from './gundlach/stock';
import { GUNDLACH_PRICELIST_RULESET } from './gundlach/pricelist';
import { COLUMN_HEADERS } from '../../helpers/column-headers';
import { GUNDLACH_CATALOG_RULESET } from './gundlach/catalog';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';

export const GUNDLACH_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: GUNDLACH_CATALOG_RULESET,
  pricelistRuleset: GUNDLACH_PRICELIST_RULESET,
  stockRuleset: GUNDLACH_STOCK_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      origin: '',
      dirtyFields: '{}',
      productMeta: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'REIFEN GUNDLACH',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    E: {
      '!!!': '',
      '(DEMO,50km)': 'DEMO',
    },
    D: {
      'GT-Radial': 'GT RADIAL',
      'A-Plus': 'APLUS',
      'BF-Goodrich': 'BFGOODRICH',
      'BF-GOODRICH': 'BFGOODRICH',
      'SUPERIA TIRES': 'SUPERIA',
      'Dotz Wheels': 'DOTZ',
      'Wanda Tyre': 'WANDA',
      ADV: 'ADVANTI',
    },
    AD: {
      SCHWARZ: 'black',
      SILBER: 'silver',
      BLACK: 'black',
      BLALCK: 'black',
      SILVER: 'silver',
    },
    AQ: {
      'screen/product/tyres/': 'qr/',
    },
  },
  hardCodes: { 73: { product: {}, model: { modelName: 'STEEL WHEEL' } } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(raw: string) {
    // console.log('raw starting');
    const headers = COLUMN_HEADERS.slice(0, 43);
    // console.log('raw done');
    // console.log(raw);
    // console.log('raw done');
    const file = headers.join(';') + '\n' + raw;
    const json = await csv2json({ delimiter: ';' }).fromString(file);
    for (let i = 0; i < json.length; i++) {
      json[i]['I'] = json[i]['I'].toUpperCase();
      json[i]['AD'] = json[i]['AD'].toLowerCase();
      json[i]['D'] = json[i]['D'].toUpperCase();
    }
    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
