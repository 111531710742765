import * as z from "zod"
import { CompleteDBSupplier, RelatedDBSupplierModel, CompleteDBCollections, RelatedDBCollectionsModel, CompleteDBDispatch, RelatedDBDispatchModel, CompleteDBDocumentsLook, RelatedDBDocumentsLookModel } from "./index"

export const DBFileModel = z.object({
  id: z.string(),
  fileName: z.string(),
  fileUrl: z.string(),
  key: z.string(),
})

export interface CompleteDBFile extends z.infer<typeof DBFileModel> {
  supplier?: CompleteDBSupplier | null
  collectionSignature?: CompleteDBCollections | null
  dispatchSignature: CompleteDBDispatch[]
  documentsLook?: CompleteDBDocumentsLook | null
}

/**
 * RelatedDBFileModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBFileModel: z.ZodSchema<CompleteDBFile> = z.lazy(() => DBFileModel.extend({
  supplier: RelatedDBSupplierModel.nullish(),
  collectionSignature: RelatedDBCollectionsModel.nullish(),
  dispatchSignature: RelatedDBDispatchModel.array(),
  documentsLook: RelatedDBDocumentsLookModel.nullish(),
}))
