import { DBProductModel } from '@prisma/client';
import { TRule } from '../typings';
import md5 from 'md5';

export const GLOBAL_PRODUCT_NAME_RULE: TRule = {
  type: 'rule.operate',
  sourceKey: 't.model.uid',
  destination: 'product',
  targetKey: 'productName',
  operationName: 'generateProductName',
};
export const GLOBAL_SIZE_RULE: TRule = {
  type: 'rule.operate',
  sourceKey: 't.product.width',
  targetKey: 'size',
  destination: 'product',
  operationName: 'generateProductSize',
};
export const GLOBAL_SKU_RULE: TRule = {
  type: 'rule.operate',
  sourceKey: 't.model.uid',
  targetKey: 'sku',
  destination: 'product',
  operationName: 'generateSKUForCategory',
};

export const GLOBAL_MS_RULES: TRule[] = [
  {
    type: 'rule.condition',
    sourceKey: 't.model.season',
    condition: 'equals',
    conditionValue: 'winter',
    outputValue: true,
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'ms',
    outputType: 'boolean',
    ignoreForCategories: [
      8, 48, 3, 2, 36, 37, 38, 39, 40, 41, 42, 9, 72, 73, 74, 75, 76, 77, 78,
      79, 80, 81, 82, 83, 84, 85, 86,
    ],
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.season',
    condition: 'equals',
    conditionValue: 'allseason',
    outputValue: true,
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'ms',
    outputType: 'boolean',
    ignoreForCategories: [
      8, 48, 3, 2, 36, 37, 38, 39, 40, 41, 42, 9, 72, 73, 74, 75, 76, 77, 78,
      79, 80, 81, 82, 83, 84, 85, 86,
    ],
  },
];

export const GLOBAL_THREEPMFS_RULES: TRule[] = [
  {
    type: 'rule.condition',
    sourceKey: 't.model.season',
    condition: 'equals',
    conditionValue: 'allseason',
    outputValue: true,
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
    ignoreForCategories: [8, 72, 73, 74, 75],
  },

  {
    type: 'rule.condition',
    sourceKey: 't.model.season',
    condition: 'equals',
    conditionValue: 'winter',
    outputValue: true,
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
    ignoreForCategories: [8, 72, 73, 74, 75],
  },
];

export const GLOBAL_COMMARK_RULES: TRule[] = [
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'equals',
    conditionValue: '32',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'commMark',
    outputValue: true,
    outputType: 'boolean',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'not.equals',
    conditionValue: '32',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'commMark',
    outputValue: false,
    outputType: 'boolean',
  },
];

export const GLOBAL_HEIGHT_RULE: TRule[] = [
  {
    type: 'rule.global.operate',
    operationName: 'generateWidthHeight',
    destination: 'product',
    targetKey: 'height',
  },
];

export const GLOBAL_ALLSEASON_RULE: TRule[] = [
  {
    type: 'rule.condition',
    sourceKey: 't.model.modelName',
    condition: 'contains',
    conditionValue: 'ALL SEASON',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'allseason',
    outputType: 'string',
  },
];

export const GLOBAL_COLORCOMBO_RULE: TRule[] = [
  {
    type: 'rule.global.operate',
    targetKey: 'color',
    destination: 'model',
    operationName: 'generateColorCombo',
    applyOnlyForCategories: [8, 72, 73, 74, 75], // Wheels
  },
];

export const GLOBAL_MODEL_HOLES: TRule[] = [
  {
    type: 'rule.global.operate',
    targetKey: 'wheelHoles',
    destination: 'model',
    operationName: 'generateModelHoles',
    applyOnlyForCategories: [8, 72, 73, 74, 75], // Wheels
  },
];

export const GLOBAL_PCD_RULES: TRule[] = [
  {
    type: 'rule.global.operate',
    targetKey: 'pcd',
    destination: 'product',
    operationName: 'generateHoleNumbersHoleCirclesPCDs',
    applyOnlyForCategories: [8, 72, 73, 74, 75], // Wheels
  },
];
export const GLOBAL_MODEL_UID_RULE: TRule = {
  type: 'rule.global.operate',
  targetKey: 'uid',
  destination: 'model',
  operationName: 'globalGenerateProductModelUid',
};
// export const GLOBAL_MODEL_UID_RULE: TRule = {
//   type: 'rule.template',
//   destination: 'model',
//   targetKey: 'uid',
//   template:
//     '#{t.model.category_id}#{t.model.brand}#{t.model.modelName}#{t.model.season}#{t.model.wheelPosition}#{t.model.sidewall}#{t.model.color}',
//   postProcess: ['convertToMD5'],
// };

export const GLOBAL_PROCESS_SPECIAL_MARKING_RULE: TRule = {
  type: 'rule.global.operate',
  operationName: 'processSpecialMarkings',
  destination: 'product',
  targetKey: 'runFlat',
};

export const GLOBAL_GET_EU_DIRECTIVE_NO_RULE: TRule = {
  type: 'rule.global.operate',
  operationName: 'updateProductEuDirectiveNumber',
  destination: 'product',
  targetKey: 'euDirectiveNumber',
};

export const GLOBAL_BRAND_NAME_RULE: TRule = {
  type: 'rule.global.operate',
  operationName: 'generateBrandName',
  destination: 'model',
  targetKey: 'brand',
};
export const GLOBAL_FIX_EPRELURL_RULE: TRule = {
  type: 'rule.global.operate',
  operationName: 'fixEprelUrl',
  destination: 'product',
  targetKey: 'eprelUrl',
};
export const GLOBAL_WEIGHT_RULE: TRule = {
  type: 'rule.global.operate',
  operationName: 'calculateWeight',
  destination: 'product',
  targetKey: 'weight',
};
export const GLOBAL_VEHICLE_CLASS_RULE: TRule[] = [
  //  Provjerit zasto ovo ne radi nego se treba zasebno:
  // {
  //   type: 'rule.condition_boolean',
  //   sourceKey: 't.model.category_id',
  //   condition: 'equals',
  //   conditionValues: ['30', '31', , '34', '32', '50'],
  //   conditionOutputs: ['C1', 'C1', 'C1', 'C2', 'C3'],
  //   destination: 'product',
  //   targetKey: 'vehicleClass',
  //   outputType: 'string',
  // },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'in',
    conditionValue: '30|31|34',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'vehicleClass',
    outputValue: 'C1',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'equals',
    conditionValue: '32',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'vehicleClass',
    outputValue: 'C2',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.category_id',
    condition: 'equals',
    conditionValue: '50',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'vehicleClass',
    outputValue: 'C3',
    outputType: 'string',
  },
];
export const GLOBAL_CONSTRUCTION_RULES: TRule[] = [
  {
    type: 'rule.condition',
    sourceKey: 't.product.construction',
    condition: 'equals',
    conditionValue: 'F',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'construction',
    outputValue: 'R',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.construction',
    condition: 'equals',
    conditionValue: 'D',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'construction',
    outputValue: '-',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.construction',
    condition: 'equals',
    conditionValue: 'T',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'construction',
    outputValue: '-',
    outputType: 'string',
  },
];

export const BICYCLE_SIZE_RULE: TRule = {
  type: 'rule.operate',
  sourceKey: 't.product.width2',
  targetKey: 'size2',
  destination: 'product',
  operationName: 'generateProductSize2',
};

export const GENERATE_PRODUCT_UUID_RULE: TRule = {
  type: 'rule.global.operate',
  sourceKey: '',
  targetKey: '',
  destination: 'product',
  operationName: 'generateProductUUID',
};

export const GLOBAL_ORIGIN_RULE: TRule = {
  type: 'rule.global.operate',
  sourceKey: '',
  targetKey: '',
  destination: 'model',
  operationName: 'setDefaultOrigin',
};

export const GLOBAL_FIX_EAN_13: TRule = {
  type: 'rule.global.operate',
  destination: 'product',
  targetKey: 'ean',
  operationName: 'fixEan13',
}

// TODO: Brand name should be uppercase globally
/**
 * ** WARNING **
 * There is a COPY of this list in tyriocustom.ts, if you're making changes here,
 * make sure to reflect them in that file too!
 */
export const GLOBAL_RULESET: TRule[] = [
  // DEFAULT VALUES
  ...GLOBAL_PCD_RULES,
  ...GLOBAL_COMMARK_RULES,
  ...GLOBAL_MS_RULES,
  ...GLOBAL_THREEPMFS_RULES,
  GLOBAL_GET_EU_DIRECTIVE_NO_RULE,
  GLOBAL_FIX_EAN_13,
  GLOBAL_PROCESS_SPECIAL_MARKING_RULE,
  ...GLOBAL_HEIGHT_RULE,
  ...GLOBAL_ALLSEASON_RULE,
  GLOBAL_FIX_EPRELURL_RULE,
  GLOBAL_BRAND_NAME_RULE,
  ...GLOBAL_COLORCOMBO_RULE,
  ...GLOBAL_VEHICLE_CLASS_RULE,
  ...GLOBAL_CONSTRUCTION_RULES,
  ...GLOBAL_MODEL_HOLES,
  GENERATE_PRODUCT_UUID_RULE,
  GLOBAL_SKU_RULE,
  GLOBAL_ORIGIN_RULE,
  GLOBAL_WEIGHT_RULE,
  // These 3 must always be the last and in this order.
  // If you need to change it, update the ProductItemPage logic too
  GLOBAL_SIZE_RULE,
  GLOBAL_PRODUCT_NAME_RULE,
  GLOBAL_MODEL_UID_RULE,
];

export const generateProductModelUid = (data: Partial<DBProductModel>) =>
  md5(
    [
      data.category_id,
      data.brand,
      data.modelName,
      data.season,
      data.wheelPosition,
      data.wheelHoles,
      data.concave,
      data.sidewall,
      data.color,
    ]
      .filter((x) => !!x)
      .join('')
      .replace('+', 'PLUS')
      .replace(/[^a-zA-Z0-9]/g, '')
      .toUpperCase()
  );
