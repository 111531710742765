import styled from '@emotion/styled';
import { Autocomplete, Grid, TextField } from '@mui/material';
import api from '@tyrio/api-factory';
import { DBBranchesApi, DBPartnerApi } from '@tyrio/dto';
import { backIcon } from '@tyrio/ui-library';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { InputOption } from '../../../../../helpers/enum-parser';
import {
  initializeInput,
  PosCartContext,
} from '../../../context/PosCartContext';
import { POSContext } from '../../../context/POSContext';
import { useFilter } from '../../../hooks/useFilter';

interface HeaderProps {
  title: string;
  icon: string;
  isSidebarOpen: boolean;
  isLanding: boolean;
}

const Header = ({ title, icon, isSidebarOpen, isLanding }: HeaderProps) => {
  const history = useHistory();
  const {
    setSelectedCustomer,
    selectedCustomer,
    selectedWarehouseId,
    setSelectedWarehouseId,
    setSelectedTableRow,
  } = useContext(POSContext);
  const { setInput, clearCustomerDetails } = useContext(PosCartContext);
  const { filterValues, setFilterValue } = useFilter();

  const [customers, setCustomers] = useState<InputOption[]>([]);
  const [partnerId, setPartnerId] = useState(
    filterValues['customer']?.toString() ?? ''
  );
  const [branches, setBranches] = useState<InputOption[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<InputOption>();

  useQuery(
    ['get_partners'],
    async () => {
      return await api.fetch<DBPartnerApi['list']>('get_partners', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const customers = data.data.filter(
          (item) => item.customerPartnerType === true
        );
        const dropdownItems = customers.map((item) => ({
          label: item.companyDisplayName
            ? item.companyDisplayName
            : item.companyOfficialName,
          value: item.id.toString(),
        }));
        setCustomers(dropdownItems);
      },
    }
  );

  const { data: branchesData, refetch: refetchBranches } = useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.data
          .filter((item) => item.branchType.includes('POS'))
          .map((item) => ({
            label: item.branchName,
            value: item.id.toString(),
          }));

        setBranches(dropdownItems);
      },
    }
  );

  const { refetch } = useQuery(
    ['get_partner_by_id', partnerId],
    () =>
      api.fetch<DBPartnerApi['getOne']>(`get_partner_by_id`, {
        partnerId,
      }),
    {
      enabled: !!partnerId,
      onSuccess: (data) => {
        setSelectedCustomer(data);
      },
    }
  );

  const defaultCustomer = useMemo(() => {
    if (selectedCustomer !== null)
      return {
        value: selectedCustomer?.id,
        label: selectedCustomer?.companyDisplayName
          ? selectedCustomer?.companyDisplayName
          : selectedCustomer?.companyOfficialName,
      };
    return undefined;
  }, [selectedCustomer]);

  const defaultBranch = useMemo(() => {
    if (
      selectedBranch &&
      selectedBranch.label !== '' &&
      selectedBranch.value !== ''
    ) {
      return {
        value: selectedBranch.value,
        label: selectedBranch.label,
      };
    } else if (branches && selectedWarehouseId) {
      const branch = branches.find(
        (item) => item.value === selectedWarehouseId?.toString()
      );
      if (branch) {
        setSelectedBranch({
          label: branch.label,
          value: selectedWarehouseId?.toString(),
        });
        return {
          label: branch.label,
          value: branch.value,
        };
      }
    }
    return {
      label: '',
      value: '',
    };
  }, [branches, selectedBranch, selectedWarehouseId]);

  useEffect(() => {
    if (partnerId !== '') refetch();
    refetchBranches();
  }, [partnerId, refetch, refetchBranches, selectedWarehouseId]);

  const handleCustomerChange = (value: string | undefined) => {
    setFilterValue({
      ...filterValues,
      customer: value,
    });
    setInput(initializeInput());
    clearCustomerDetails();
    if (value === undefined) setSelectedCustomer(null);
    else setPartnerId(value ?? '');
  };

  const handleWarehouseChange = (
    value: { value: string; label: string } | null
  ) => {
    if (branchesData && branchesData.data.length > 0) {
      const warehouse = branchesData?.data.find(
        (item) => item.id === Number(value?.value)
      );
      const warehouseId = warehouse?.branchType.includes('WAREHOUSE')
        ? Number(value?.value)
        : Number(warehouse?.mainWarehouseId);
      setSelectedWarehouseId(warehouseId);
    }
    setSelectedBranch({
      value: value?.value ?? '',
      label: value?.label ?? '',
    });
    setSelectedTableRow(null);
    setFilterValue({
      ...filterValues,
      warehouseId: value?.value,
    });
  };

  return (
    <Wrapper>
      <TitleWrapper>
        {icon && isLanding ? (
          <TitleImage src={icon} alt="feature-icon" />
        ) : (
          <BackButton onClick={() => history.push(`/dashboard`)}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
        )}

        {title}
      </TitleWrapper>

      <InputsWrapper>
        <Grid item xs={isSidebarOpen ? 12 : 3} style={{ width: '200px' }}>
          <Autocomplete
            disablePortal
            disableClearable
            id="chooseWarehouse"
            value={defaultBranch}
            options={branches}
            sx={{ width: '100%' }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_event, newValue) => handleWarehouseChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Choose Branch" variant="standard" />
            )}
          />
        </Grid>

        <Grid item xs={isSidebarOpen ? 12 : 3} style={{ width: '200px' }}>
          <Autocomplete
            disablePortal
            id="chooseCustomer"
            defaultValue={defaultCustomer}
            options={customers}
            sx={{ width: '100%' }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_event, newValue) =>
              handleCustomerChange(newValue?.value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Customer"
                variant="standard"
              />
            )}
          />
        </Grid>
      </InputsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfe3e8;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding: 16px;
  gap: 20px;
  @media (max-width: 1630px) {
    height: 120px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;

const TitleImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

const BackButton = styled.div`
  margin-right: 16px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 1500px) {
    width: 120px;
    flex-direction: column;
  }
`;

export default Header;
