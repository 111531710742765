import api from '@tyrio/api-factory';
import { DBFittingBoxSettingsApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetFittingBoxSettings = (branchId: string) => {
  const { data, refetch } = useQuery(
    [branchId],
    () =>
      api.fetch<DBFittingBoxSettingsApi['getMany']>(
        'get_fitting_box_settings',
        {
          branchId,
        }
      ),
    {
      enabled: !!branchId,
    }
  );

  return {
    fittingBoxSettingsData: data,
    refetchFittingBoxSettings: refetch,
  };
};
