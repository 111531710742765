import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import {
  Button,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DBWarehouseBins, DBWarehouseShelfs } from '@prisma/client';
import _ from 'lodash';
import { useContext, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { WarehouseContext } from '../../../../context/WarehouseContext';
import { getDirection, getReactToPrintPropsForA3 } from '../../helper/helper';
import HorizontalSticker from './HorizontalSticker';
import VerticalSticker from './VerticalSticker';
import { ShelfSettings } from '@tyrio/dto';

export type PrintModeType = 'horizontal' | 'vertical-left' | 'vertical-right';
export type PrintSize = 'a3' | 'zebra';

export const printModeValues = [
  {
    key: 'horizontal',
    label: 'Horizontal',
  },
  {
    key: 'vertical-left',
    label: 'Vertical left',
  },
  {
    key: 'vertical-right',
    label: 'Vertical right',
  },
];

export const paperSizeValues = [
  {
    key: 'a3',
    label: 'A3',
  },
  {
    key: 'zebra',
    label: 'Zebra printer',
  },
];

interface PrintSticker {
  open: boolean;
  onClose: (val: boolean) => void;
}

const PrintSticker = (props: PrintSticker) => {
  const { open, onClose } = props;

  const [locations, setLocations] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [printMode, setPrintMode] = useState<PrintModeType>('horizontal');

  const componentRef = useRef<HTMLDivElement>(null);

  const { warehouse } = useContext(WarehouseContext);

  const getArr = (val: DBWarehouseShelfs[] | DBWarehouseBins[]) => {
    const arr = val
      .map((item) => ({
        location: item.displayName,
        qrCode: item.qrCode,
        settings: item.settings,
      }))
      .filter(
        (item) => (item.settings as unknown as ShelfSettings).isActive === true
      );

    return arr;
  };

  const getData = () => {
    if (locations.length > 0)
      return allLocations.filter(
        (item) => item.location && locations.includes(item.location)
      );

    return allLocations;
  };

  const handleClose = () => {
    setLocations([]);
    setSearch('');
    onClose(false);
  };

  const shelfs = getArr(warehouse?.shelfs ?? []);
  const bins = getArr(warehouse?.bins ?? []);

  const allLocations = _.merge(shelfs, bins);

  const searched = allLocations?.filter((item) =>
    item.location.startsWith(search)
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ContentContainer>
        <ContentHeader>
          <Typography variant="h6" component="h2">
            Print labels
          </Typography>
          {locations.length === 0 ? (
            <ReactToPrint
              trigger={() => (
                <Button variant="text" color="info" startIcon={<PrintIcon />}>
                  PRINT LABEL
                </Button>
              )}
              {...getReactToPrintPropsForA3(componentRef, printMode)}
            />
          ) : (
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
          )}
        </ContentHeader>
        <PrintModeWrapper>
          <Grid>
            <FormLabel>Print mode</FormLabel>
            <RadioGroup row defaultValue={printMode}>
              {printModeValues.map((item) => {
                return (
                  <FormControlLabel
                    value={item.key}
                    control={<Radio />}
                    label={item.label}
                    onChange={() => {
                      setPrintMode(item.key as PrintModeType);
                    }}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
          {search !== '' && (
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="text"
                color="info"
                onClick={() => {
                  const temp = [
                    ...locations,
                    ...searched.map((item) => item.location),
                  ];
                  setLocations(temp as string[]);
                }}
              >
                SELECT ALL
              </Button>
              <Button
                variant="text"
                color="info"
                onClick={() => {
                  setLocations([]);
                }}
              >
                UNSELECT ALL
              </Button>
            </Grid>
          )}
        </PrintModeWrapper>
        <TextField
          id="outlined-basic"
          label="Search location"
          variant="outlined"
          value={search}
          onChange={(e) => {
            if (e.target.value === '') {
              setLocations([]);
            }
            setSearch(e.target.value);
          }}
        />
        <AllLocationsContainer>
          {search !== '' &&
            searched.map((item) => {
              const location = item.location;

              const isChecked = locations.includes(location);

              return (
                <Chip
                  sx={{
                    marginBottom: '7px',
                    cursor: 'pointer',
                  }}
                  label={item.location}
                  variant={isChecked ? 'filled' : 'outlined'}
                  color={isChecked ? 'success' : 'default'}
                  onClick={() => {
                    let temp = [];

                    if (isChecked) {
                      temp = locations.filter((item) => item !== location);
                    } else {
                      temp = [...locations, item.location];
                    }

                    setLocations(temp);
                  }}
                />
              );
            })}
        </AllLocationsContainer>
        {locations.length > 0 && search !== '' && (
          <ContentHeader>
            <Typography variant="h6" component="h3">
              Selected labels
            </Typography>
            <PrintButtonContainer>
              <ReactToPrint
                trigger={() => (
                  <Button variant="text" color="info" startIcon={<PrintIcon />}>
                    PRINT LABEL
                  </Button>
                )}
                {...getReactToPrintPropsForA3(componentRef, printMode)}
              />
              <Button
                variant="text"
                color="error"
                startIcon={<CancelIcon />}
                onClick={() => {
                  setLocations([]);
                }}
              >
                CLEAR
              </Button>
            </PrintButtonContainer>
          </ContentHeader>
        )}
        <SelectedLocationsContainer>
          {search !== '' &&
            locations.map((item) => (
              <Chip
                sx={{ marginBottom: '7px', cursor: 'pointer' }}
                label={item}
                onDelete={() => {
                  const temp = locations.filter(
                    (location) => location !== item
                  );
                  setLocations(temp);
                }}
              />
            ))}
        </SelectedLocationsContainer>
        <Grid sx={{ display: 'none' }}>
          <div ref={componentRef} className="center-content">
            {getData().map((item) => {
              return printMode === 'horizontal' ? (
                <HorizontalSticker
                  location={item.location}
                  qrCode={item.qrCode}
                />
              ) : (
                <VerticalSticker
                  location={item.location}
                  qrCode={item.qrCode}
                  direction={getDirection(printMode)}
                  paperSize={'a3'}
                />
              );
            })}
          </div>
        </Grid>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 24;
  padding: 16px;
  width: 50%;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const AllLocationsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 300px;
  width: 100%;
  overflow: scroll;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  grid-column-gap: 3px;
`;

const SelectedLocationsContainer = styled.div`
  margin-top: 10px;
  max-height: 300px;
  width: 100%;
  overflow: scroll;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
  grid-column-gap: 3px;
  height: 100%;
`;

const PrintModeWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

const PrintButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default PrintSticker;
