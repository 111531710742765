import styled from '@emotion/styled/macro';
import Modal from '@mui/material/Modal';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: 100%;
`;

export const StyledModal = styled(Modal)`
  background: white;
  width: 600px;
  height: 100%;
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  align-items: flex-end;
  height: 100%;
  padding: 16px;
  width: 100%;
`;

export const Border = styled.div`
  border: 1px solid #1976d2;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  gap: 32px;
  border-radius: 16px;
  padding: 60px 0;
  height: 90%;
  width: 100%;
`;

export const FocusedImage = styled.div`
  width: 100% !important;
  height: 80% !important;
  min-height: 80%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UnfocusedImage = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  place-items: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 500px;
  height: 150px;

  @keyframes slideIn {
    from {
      left: calc(100% - 45px);
      width: 0;
    }
    to {
      left: 0;
      width: 100%;
    }
  }
`;

export const SingleImageWrapper = styled.div<{ focused: boolean }>`
  border-bottom: ${(props) => (props.focused ? '2px solid #1976D2' : null)};
  opacity: ${(props) => (props.focused ? 1 : 0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  width: 70px;

  &:hover {
    opacity: 1;
  }
`;
