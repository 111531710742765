import styled from '@emotion/styled/macro';
import { Box, Divider } from '@mui/material';

import api from '@tyrio/api-factory';
import { DBDeliveryTypesApi, DefaultPrices, DefaultSettings } from '@tyrio/dto';
import { CancelModal, cartIcons, DiscardModal } from '@tyrio/ui-library';
import { wmsIcons } from '@tyrio/wms-ui-library';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  DeleteWrapper,
  Text as DeleteText,
  WmsIconStyled,
} from '../../../../../../components/Cart/Header';
import { SideMenuLayout } from '../../../../../../components/SideMenuLayout/SideMenuLayout';
import {
  AddressProps,
  DeliveryType,
  LocalStorageShape,
  PosCartContext,
} from '../../../../../../context/PosCartContext';
import { usePosCtx } from '../../../../../../context/POSContext';
import { customToast } from '../../../../../stock-list/components/Cart/CartToast';
import { AlertField } from '../../../../../supplier-form/helpers/components';
import { Header } from '../../../common/Header';
import { InformationCard } from '../../InformationList/InformationCard';
import { InfoFooter } from '../Footer';
import { AddressModal } from './AddressModal';
import { AddressCard } from './components/AddressCard';
import { DeliveryCard } from './components/DeliveryCard';

export const DeliveryMethod = () => {
  const {
    setActiveInfo,
    deliveryAddress,
    setDeliveryAddress,
    input,
    setInput,
    setDeliveryPrice,
  } = useContext(PosCartContext);
  const { selectedWarehouseId } = usePosCtx();

  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [isChecked, setIsChecked] = useState<Record<string, boolean>>(
    input.delivery_method['deliveryType']
      ? { [input.delivery_method['deliveryType'].id]: true }
      : {}
  );
  const [index, setIndex] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);

  const [newDeliveryAddress, setNewDeliveryAddress] =
    useState<AddressProps[]>(deliveryAddress);
  const [canShip, setCanShip] = useState(true);

  const { data: activeDeliveries } = useQuery(
    ['get_deliveries_by_branch', selectedWarehouseId?.toString()],
    async () => {
      return await api.fetch<DBDeliveryTypesApi['list']>(
        'get_deliveries_by_branch',
        {
          branchId: selectedWarehouseId?.toString(),
        }
      );
    }
  );

  const checkedDelivery = useMemo(() => {
    const checked = Object.keys(isChecked).find((key) => {
      return isChecked[key];
    });
    if (activeDeliveries)
      return activeDeliveries?.find((item) => item.id === checked);
    return undefined;
  }, [activeDeliveries, isChecked]);

  const handleChecked = (index: string) => {
    setIsDirty(true);
    Object.keys(isChecked).forEach((item) => {
      setIsChecked((prevState) => ({
        ...prevState,
        [item]: false,
      }));
    });
    setIsChecked((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const handleCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else setActiveInfo('');
  };

  const handleSubmit = () => {
    setDeliveryAddress([...newDeliveryAddress]);

    setInput((prevState: LocalStorageShape) => ({
      ...prevState,
      delivery_method: {
        address: deliveryAddress,
        deliveryType: checkedDelivery
          ? ({
              id: checkedDelivery?.id,
              delivery: checkedDelivery?.name,
              code: checkedDelivery?.code,
              text: `${
                (checkedDelivery?.defaultSettings as unknown as DefaultSettings)
                  .daysToDeliver
              } - ${
                (checkedDelivery?.defaultSettings as unknown as DefaultSettings)
                  .maxDaysToDeliver
              } days`,
              icon: '',
              price:
                (checkedDelivery?.defaultPrices as unknown as DefaultPrices)
                  .deliveryPrice ?? 0,
              disabled: false,
            } as unknown as DeliveryType)
          : undefined,
      },
    }));
    setDeliveryPrice(
      (checkedDelivery?.defaultPrices as unknown as DefaultPrices)
        .deliveryPrice ?? 0
    );
    customToast('Delivery method successfully saved.', 'success');
    setActiveInfo('');
  };

  const handleDiscard = () => {
    const addresses = input.delivery_method.address?.filter(
      (item) => item.fromCustomer === true
    );
    setInput((prevState) => ({
      ...prevState,
      delivery_method: {
        address: addresses ?? [],
        deliveryType: undefined,
      },
    }));
    setDeliveryAddress(addresses ?? []);
    setIsDiscardModalVisible(false);
    setActiveInfo('');
    setIsDirty(false);
  };

  const shouldShowNewAddressButton = useMemo(() => {
    if (
      (newDeliveryAddress.length === 1 &&
        newDeliveryAddress[0].fromCustomer === false) ||
      (deliveryAddress.length > 0 &&
        deliveryAddress.at(0)?.fromCustomer === true &&
        newDeliveryAddress.length === 2)
    )
      return false;
    return true;
  }, [deliveryAddress, newDeliveryAddress]);

  // TODO: add validation for international, zones
  useEffect(() => {
    if (activeDeliveries && newDeliveryAddress.length > 0 && checkedDelivery) {
      const selectedDelivery = activeDeliveries?.find(
        (item) => item.id === checkedDelivery?.id
      );
      if (selectedDelivery) {
        // local delivery
        if (selectedDelivery.deliveryTypeEnum === 'WMS_DISPATCH') {
          if (
            newDeliveryAddress[newDeliveryAddress.length - 1].country !== 'HR'
            // '274e7e37-6d32-f25b-4a5f-daf91b949567'
          )
            setCanShip(false);
          else setCanShip(true);
        } else if (
          selectedDelivery.deliveryTypeEnum === 'WMS_SHIPPING' &&
          selectedDelivery.deliveryLocation === 'DOMESTIC'
        ) {
          if (
            (
              selectedDelivery.defaultSettings as unknown as DefaultSettings
            ).blacklistZIPCodes?.includes(
              newDeliveryAddress[newDeliveryAddress.length - 1].zip
            )
          )
            setCanShip(false);
          else setCanShip(true);
        } else setCanShip(true);
      }
    }
  }, [activeDeliveries, checkedDelivery, newDeliveryAddress]);

  const renderDeliveryMethod = () => {
    return (
      <Wrapper>
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              setDeliveryAddress([...(input.delivery_method?.address ?? [])]);
              setIsCancelModalVisible(false);
              setActiveInfo('');
              setIsDirty(false);
            }}
          />
        )}
        {isDiscardModalVisible && (
          <DiscardModal
            LBAction={() => setIsDiscardModalVisible(false)}
            RBAction={handleDiscard}
          />
        )}
        {shouldOpenModal && (
          <AddressModal
            open={shouldOpenModal}
            setOpen={setShouldOpenModal}
            itemIndex={index}
            isNew={isNew}
            newDeliveryAddress={newDeliveryAddress}
            setNewDeliveryAddress={setNewDeliveryAddress}
          />
        )}
        {/* we can have only 2 addresses */}
        {newDeliveryAddress.length > 0
          ? newDeliveryAddress.map((item: AddressProps, index: number) => (
              <AddressCard
                key={index}
                icon={cartIcons.addressCard}
                text={item.address}
                details={`${item.companyName ?? ''} ${item.firstName ?? ''} ${
                  item.lastName ?? ''
                }`}
                onClick={() => {
                  setIndex(index);
                  setIsNew(false);
                  setShouldOpenModal(true);
                  setIsDirty(true);
                }}
                disabled={false}
              />
            ))
          : deliveryAddress.map((item: AddressProps, index: number) => (
              <AddressCard
                key={index}
                icon={cartIcons.addressCard}
                text={item.address}
                details={`${item.companyName ?? ''} ${item.firstName ?? ''} ${
                  item.lastName ?? ''
                }`}
                onClick={() => {
                  setIndex(index);
                  setIsNew(false);
                  setShouldOpenModal(true);
                  setIsDirty(true);
                }}
                disabled={false}
              />
            ))}
        {shouldShowNewAddressButton && (
          <Box marginTop={2}>
            <InformationCard
              icon={cartIcons.addressCard}
              text={'New address'}
              onClick={() => {
                setIsNew(true);
                setShouldOpenModal(true);
                setIsDirty(true);
              }}
              disabled={false}
            />
          </Box>
        )}

        {newDeliveryAddress && newDeliveryAddress.length > 0 && (
          <DeliveryTypesWrapper>
            <Divider />
            <Header
              icon={cartIcons.truck}
              altText={'Truck'}
              text={'Choose delivery type'}
              viewBox={'0 0 32 32'}
            />
            {activeDeliveries &&
              activeDeliveries.length > 0 &&
              activeDeliveries.map((item, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <DeliveryCard
                      key={index}
                      delivery={item.name}
                      text={`${
                        (item.defaultSettings as unknown as DefaultSettings)
                          .daysToDeliver
                      } - ${
                        (item.defaultSettings as unknown as DefaultSettings)
                          .maxDaysToDeliver
                      } days`}
                      onClick={handleChecked}
                      price={
                        (item.defaultPrices as unknown as DefaultPrices)
                          .deliveryPrice ?? 0
                      }
                      icon={''}
                      disabled={item.deliveryTypeEnum === 'WMS_COLLECTION'}
                      isChecked={isChecked[item.id] ?? false}
                      itemIndex={item.id}
                    />
                    {item.id === checkedDelivery?.id && !canShip && (
                      <AlertField
                        text={`We can't ship to selected address with selected delivery method!`}
                        type="warning"
                      />
                    )}
                  </div>
                );
              })}
          </DeliveryTypesWrapper>
        )}

        <DeleteWrapper
          onClick={() => setIsDiscardModalVisible(true)}
          style={{ justifyContent: 'end', position: 'sticky', top: '100%' }}
        >
          <DeleteText id="delete_text">Discard</DeleteText>
          <WmsIconStyled icon={wmsIcons.bin} id="delete_icon" />
        </DeleteWrapper>
      </Wrapper>
    );
  };

  return (
    <SideMenuLayout
      type="deliveryMethod"
      children={renderDeliveryMethod()}
      showSwitch={false}
      checked={false}
      shouldShowTitle={true}
      footer={
        <InfoFooter
          text1="CANCEL"
          text2="SUBMIT"
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          disableSubmit={!canShip}
        />
      }
      viewBox={'0 0 32 32'}
    />
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DeliveryTypesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
