import api from '@tyrio/api-factory';
import { DBClientApiKeyApi } from '@tyrio/dto';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import { useMutation, useQuery } from 'react-query';
import { useDebounce } from '../../hooks/useDebounce';

//FETCH ALL API KEYS
export const useFetchKeys = (search: string, clientId?: string) => {
  const debouncedSearch = useDebounce(search, 500);

  const { data, refetch } = useQuery(
    ['client_api_keys_list', { debouncedSearch }],
    () =>
      api.fetch<DBClientApiKeyApi['list']>('client_api_keys_list', {
        search: search,
      }),
    {
      enabled: !clientId,
    }
  );
  return { apiKeysData: data, refetch };
};

//GET CLIENT BY ID
export const useClientApiKey = (clientId?: string) => {
  const { data, refetch } = useQuery(
    ['client_api_keys_list'],
    () =>
      api.fetch<DBClientApiKeyApi['getOne']>('client_keys', {
        clientId: clientId ?? '',
      }),
    {
      enabled: !!clientId,
    }
  );
  return { clientKey: data, refetchClientKey: refetch };
};

//NEW API KEY GENERATOR
export function useGenerateApiKey(refetch: () => void) {
  return useMutation(
    () => api.fetch<DBClientApiKeyApi['create']>('generateKey'),
    {
      mutationKey: 'generateKey',
      onSuccess: () => {
        getSuccessMessage('API key successfully created!', refetch);
      },
      onError: () => {
        getErrorMessage();
      },
    }
  );
}

//CHANGE API KEY STATUS
export function useChangeStatus(refetch: () => void) {
  return useMutation(
    (apiKey: string) =>
      api.fetch<DBClientApiKeyApi['getOne']>('api_key_status', {
        apiKey: apiKey,
      }),
    {
      mutationKey: 'change_favorite_status',
      onSuccess: () => {
        getSuccessMessage('API key status successfully changed!', refetch);
      },
      onError: () => {
        getErrorMessage();
      },
    }
  );
}

//DELETE API KEY
export function useDeleteKey(refetch: () => void) {
  return useMutation(
    (apiKey: string) =>
      api.fetch<DBClientApiKeyApi['getOne']>('deleteKey', {
        apiKey: apiKey,
      }),
    {
      mutationKey: 'delete_key',
      onSuccess: () => {
        refetch();
        getSuccessMessage('API key successfully deleted!', refetch);
      },
      onError: () => {
        getErrorMessage();
      },
    }
  );
}

export const getSuccessMessage = (message: string, refetch?: () => void) => {
  refetch && refetch();
  ToastHelper.showToast('', ToastType.SUCCESS, undefined, message);
};

const getErrorMessage = () => {
  ToastHelper.showToast('', ToastType.ERROR, undefined, 'An error ocurred');
};
