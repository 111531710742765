import * as z from "zod"
import { DBRepeatUnit } from ".prisma/client"
import { CompleteDBSupplierConnection, RelatedDBSupplierConnectionModel } from "./index"

export const DBHttpsConnectionModel = z.object({
  id: z.string(),
  httpsUrl: z.string(),
  username: z.string(),
  password: z.string(),
  customerId: z.string().nullish(),
  startUpdateTime: z.date(),
  repeatEvery: z.number().int().nullish(),
  repeatUntil: z.nativeEnum(DBRepeatUnit),
  supplierConnectionId: z.string().nullish(),
  remark: z.string(),
})

export interface CompleteDBHttpsConnection extends z.infer<typeof DBHttpsConnectionModel> {
  supplierConnection?: CompleteDBSupplierConnection | null
}

/**
 * RelatedDBHttpsConnectionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBHttpsConnectionModel: z.ZodSchema<CompleteDBHttpsConnection> = z.lazy(() => DBHttpsConnectionModel.extend({
  supplierConnection: RelatedDBSupplierConnectionModel.nullish(),
}))
