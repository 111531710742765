import styled from '@emotion/styled';
import { TyrioLogo, tyrioMUI, tyrioUI } from '@tyrio/ui-library';
import TyrioLogin from '../features/auth/components/Login/TyrioLogin';
import style from './AuthLayout.module.scss';

const AuthLayout = () => {
  return (
    <LayoutContainer className={`${style['layout_container']}`}>
      <Wrapper>
        <LogoWrapper className={`${style['logo_wrapper']}`}>
          <img
            src={TyrioLogo}
            alt="tyrio-logo"
            className={`${style['logo']}`}
          />
          <LogoText>SOFTWARE FOR TYRE PROFESSIONALS</LogoText>
        </LogoWrapper>
        <LoginWrapper className={`${style['login_wrapper']}`}>
          <TyrioLogin />
        </LoginWrapper>
      </Wrapper>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LogoWrapper = styled.div``;

const LogoText = styled.p`
  color: #919eab;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-bottom: 90px;
`;

const LoginWrapper = styled.div`
  background-color: ${tyrioMUI.muiTheme.palette.background.paper};
  padding: ${tyrioUI.spacing.xl}px;
  min-width: 500px;
  max-width: 550px;
  width: 100%;
  //flex: 1;
`;

export default AuthLayout;
