import { TRule } from '../../typings';

export const VULKAL_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['EANCode']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['IDArtikl']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Naziv',
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['VPCsRabatom']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
  // Check if there is a discounted price
  {
    type: 'rule.condition',
    sourceKey: 'c.Akcija',
    condition: 'not.equals',
    conditionValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'priceEUR',
    outputValue: '#{c.Akcija}',
    outputType: 'number',
  },
];
