import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { BranchesStockResponse, SupplierStockResponse } from '@tyrio/dto';
import { useContext } from 'react';
import { POSContext } from '../../../../context/POSContext';
import { StockItems } from '../StockItemDetails/StockItems';
import { StockInfoHeader } from './StockInfoHeader';

interface StockInfoProps {
  data: BranchesStockResponse[] | SupplierStockResponse[];
  text:
    | 'BRANCHES STOCK'
    | 'SUPPLIERS STOCK'
    | 'ON STOCK'
    | 'UPCOMING ON STOCK'
    | '';
  showText?: boolean;
  showVisibility?: boolean;
}

export const StockInfo = ({
  data,
  text,
  showText = true,
  showVisibility = false,
}: StockInfoProps) => {
  const {
    isSupplierNameVisible: visible,
    setIsSupplierNameVisible: setVisible,
  } = useContext(POSContext);
  return (
    <div>
      {data.length > 0 && (
        <div>
          {text && showText && <StockInfoHeader text={text} />}
          {showVisibility && (
            <div
              style={{ marginTop: '20px', cursor: 'pointer', padding: '8px' }}
              onClick={() => setVisible && setVisible(!visible)}
            >
              {visible ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon opacity="0.5" />
              )}
            </div>
          )}
          {data.map(
            (
              item: BranchesStockResponse | SupplierStockResponse,
              idx: number
            ) => (
              <StockItems
                data={item}
                stockType={text ?? ''}
                key={
                  text !== 'SUPPLIERS STOCK'
                    ? (item as unknown as BranchesStockResponse).branchId
                    : (item as unknown as SupplierStockResponse).supplierName
                }
                index={idx + 1}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};
