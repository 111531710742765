/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { DBProduct } from '@prisma/client';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  SalesOrderQty,
  SalesOrdersFlowContext,
} from '../SalesOrdersFlowContext';
import { OrderAccordionHeaderCounter } from './OrderAccordionHeaderCounter';
import { OrderItem, Wrapper } from './OrderItem';
import { UseInquireMultipleEansState } from '../../../hooks/api/useInquireEansMultipleSuppliers';
import { Divider, Typography } from '@mui/material';
import _, { get, sumBy, values } from 'lodash';
import { LagerItem } from './LagerItem';
import moment from 'moment';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { tyrioUI } from '@tyrio/ui-library';
import { CalendarDate } from 'calendar-date';

interface OrderAccordionProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  product: DBProduct & { model: { uid: string; brand: string | null } };
  inquiryState: UseInquireMultipleEansState;
}

export const OrderAccordion = ({
  isOpen,
  setIsOpen,
  product,
  inquiryState,
}: OrderAccordionProps) => {
  const ctx = useContext(SalesOrdersFlowContext);

  const suppliers = ctx.data.suppliers;
  const supplierQty = ctx.data.supplierQty?.[product.uid];

  const warehouseQty = ctx.data.warehouseQty?.[product.uid];

  const purchased = values(ctx.data.purchased);
  const purchasedQty = sumBy(purchased, product.uid);

  const requestedQty = ctx.data.requested[product.uid] ?? 0;

  const mainSupplier = get(ctx.data.mainSupplierId, `${product.uid}`, '');

  const lineItem = ctx.data.lineItems.find((f) => f.ean === product.ean);

  const mainSupplierSlug = lineItem?.purchasing?.supplierSettings?.supplierSlug;

  const isAutoAntonio = mainSupplierSlug === 'autoantonio';

  const allWarehouses = useMemo(() => {
    return ctx.data.warehouses;
  }, [ctx.data.warehouses]);

  const prices = useMemo(() => {
    return Object.values(ctx.data.prices ?? {})
      .map((prices) => {
        const priceObj = prices[product.uid];
        if (!priceObj) return Infinity;

        return (
          (priceObj.price ?? 0) +
          (priceObj.ecoTax ?? 0) +
          (priceObj.shipping ?? 0)
        );
      })
      .sort((a, b) => a - b);
  }, [ctx.data.prices, product.uid]);

  const lowestProductPrice = prices[0] ?? Infinity;

  const prefillQuantities = useCallback(() => {
    let remainingQty = requestedQty;
    const accumulatedQty: SalesOrderQty = {
      stock: {},
      supplier: {},
    };

    const sortedWarehouses = Object.entries(warehouseQty || {}).sort(
      ([warehouseABranchId, valueA], [warehouseBBranchId, valueB]) => {
        const warehouseA = allWarehouses[warehouseABranchId];
        const warehouseB = allWarehouses[warehouseBBranchId];

        if (warehouseA.branchName.toLocaleLowerCase().includes('dugo selo'))
          return -1;
        if (warehouseB.branchName.toLocaleLowerCase().includes('dugo selo'))
          return 1;
        if (warehouseA.branchName.toLocaleLowerCase().includes('split'))
          return -1;
        if (warehouseB.branchName.toLocaleLowerCase().includes('split'))
          return 1;

        return (valueB?.quantity ?? 0) - (valueA?.quantity ?? 0);
      }
    );

    const sortedWarehousesEntries = Object.fromEntries(sortedWarehouses);

    // Prefill from warehouse (lager)
    for (const [warehouse, value] of Object.entries(
      sortedWarehousesEntries || {}
    )) {
      const availableQty = value?.quantity ?? 0;

      if (availableQty >= remainingQty) {
        _.set(
          accumulatedQty.stock,
          `${warehouse}.${product.uid}`,
          remainingQty
        );

        remainingQty = 0;
        _.set(ctx.selected, product.uid, warehouse);
        break;
      }
      // else if (availableQty > 0) {
      //   _.set(
      //     accumulatedQty.stock,
      //     `${warehouse}.${product.uid}`,
      //     availableQty
      //   );
      //   remainingQty -= availableQty;
      // }
    }

    // Prefill from suppliers
    // do not prefill for autoantonio
    if (remainingQty > 0 && !isAutoAntonio) {
      for (const [supplierId, quantity] of Object.entries(supplierQty || {})) {
        const availableSupplierQty = quantity ?? 0;

        const isMainSupplier = supplierId === mainSupplier;
        const priceObj = ctxPrices?.[supplierId]?.[product.uid] ?? null;

        const price =
          (priceObj?.price ?? 0) +
          (priceObj?.ecoTax ?? 0) +
          (priceObj?.shipping ?? 0);

        const originalPrice = priceObj?.original;

        const isLowestPrice = lowestProductPrice === price;

        const mainSupplierCondition =
          isMainSupplier && originalPrice === price && isLowestPrice;

        if (availableSupplierQty >= remainingQty && mainSupplierCondition) {
          _.set(
            accumulatedQty.supplier,
            `${supplierId}.${product.uid}`,
            remainingQty
          );
          remainingQty = 0;

          _.set(ctx.selected, product.uid, supplierId);
          break;
        } else if (availableSupplierQty > 0 && mainSupplierCondition) {
          _.set(
            accumulatedQty.supplier,
            `${supplierId}.${product.uid}`,
            availableSupplierQty
          );
          remainingQty -= availableSupplierQty;

          _.set(ctx.selected, product.uid, supplierId);
        }
      }
    }

    ctx.batchQtyUpdate(accumulatedQty);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedQty, warehouseQty, supplierQty, product.uid]);

  useEffect(() => {
    prefillQuantities();
  }, [prefillQuantities]);

  const fulfilledProductQty = useMemo(() => {
    const supplierValue =
      sumBy(Object.values(ctx?.qty?.supplier), product?.uid) ?? 0;

    const stockValue = sumBy(Object.values(ctx?.qty?.stock), product?.uid) ?? 0;

    const value = stockValue + supplierValue;

    if (purchasedQty > 0) {
      const supplierId = ctx.selected[product.uid];
      const prevQty = get(ctx.data.purchased[supplierId], product.uid) ?? 0;

      const currentQty = value - prevQty;

      return currentQty;
    }

    // if (!ctx.selected[product.uid]) return 0;

    return value;
  }, [ctx, product.uid, purchasedQty]);

  const mainSupplierPrice =
    ctx.data.prices[mainSupplier]?.[product.uid]?.['price'];

  const ctxPrices = useMemo(() => {
    return ctx.data.prices;
  }, [ctx.data.prices]);

  const mapping = useMemo(() => {
    return Object.keys(supplierQty || {})
      .sort((a, b) => {
        if (a === mainSupplier) return -1;
        if (b === mainSupplier) return 1;

        const supplierAPrice = ctxPrices?.[a]?.[product.uid];
        const supplierBPrice = ctxPrices?.[b]?.[product.uid];

        const aPrice =
          (supplierAPrice?.price ?? 0) +
          (supplierAPrice?.ecoTax ?? 0) +
          (supplierAPrice?.shipping ?? 0);

        const bPrice =
          (supplierBPrice?.price ?? 0) +
          (supplierBPrice?.ecoTax ?? 0) +
          (supplierBPrice?.shipping ?? 0);

        const price = aPrice - bPrice;

        if (price > 0) return 1;
        else return -1;
      })
      .map((supplierId) => {
        const priceObj = ctxPrices?.[supplierId]?.[product.uid] ?? null;

        const price =
          (priceObj?.price ?? 0) +
          (priceObj?.ecoTax ?? 0) +
          (priceObj?.shipping ?? 0);

        const supplierInquiry = inquiryState.inquiryState?.[supplierId];

        const resolvedSupplierQty = supplierInquiry?.[priceObj?.ean]?.qty;

        let resolvedSupplierDeliveryDate: string | object =
          supplierInquiry?.[priceObj?.ean]?.deliveryDate;

        if (
          !resolvedSupplierDeliveryDate ||
          typeof resolvedSupplierDeliveryDate !== 'string'
        ) {
          resolvedSupplierDeliveryDate = '';
        }
        const resolvingSupplierInquiry =
          inquiryState.inquiryLoadingState?.[supplierId];

        const inquiryError = inquiryState.apiErrorState?.[supplierId];

        const isMainSupplier = mainSupplier === supplierId;

        if (price === 0) return null;

        let minDeliveryDate = supplierInquiry?.[product.ean]?.minDeliveryDate;
        let maxDeliveryDate = supplierInquiry?.[product.ean]?.maxDeliveryDate;

        if (!minDeliveryDate || !maxDeliveryDate) {
          const supplierConfig = (
            Object.values(ctx.data.clientSupplierConfigsMap ?? {}) || []
          )?.find((x) => x.supplierId === supplierId);
          const shippingConfig =
            supplierConfig?.supplierClientShippingSettings?.[0];

          const now = new Date();

          let today = new CalendarDate(
            `${String(now.getFullYear()).padStart(2, '0')}-${String(
              now.getMonth() + 1
            ).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`
          );

          // const today = new CalendarDate(new Date().toISOString());
          const isAfterNoon = new Date().getHours() >= 12;
          if (isAfterNoon) today = today.addDays(1);

          if (
            shippingConfig?.daysToDeliver &&
            shippingConfig?.maxDaysToDeliver
          ) {
            minDeliveryDate = today
              .addDays(shippingConfig?.daysToDeliver)
              .toString();
            maxDeliveryDate = today
              .addDays(shippingConfig?.maxDaysToDeliver)
              .toString();
          }
        }

        return {
          price,
          supplierId,
          priceObj,
          resolvedSupplierQty,
          resolvedSupplierDeliveryDate,
          resolvingSupplierInquiry,
          isMainSupplier,
          inquiryError,
          minDeliveryDate,
          maxDeliveryDate,
        };
      })
      .filter((x) => !!x);
  }, [
    supplierQty,
    mainSupplier,
    ctxPrices,
    product.uid,
    product.ean,
    inquiryState.inquiryLoadingState,
    inquiryState.apiErrorState,
    inquiryState.inquiryState,
  ]);

  const lager = useMemo(() => {
    return Object.keys(warehouseQty || {}).map((warehouse) => {
      return { branchId: warehouse };
    });
  }, [warehouseQty]);

  const makeDateComparison = (maxDeliveryDate: any) => {
    if (lineItem?.deliveryDateTo) {
      const date = moment(lineItem.deliveryDateTo, 'DD.MM.YYYY');
      const max = moment(maxDeliveryDate, 'YYYY-MM-DD');

      return max.isAfter(date);
    }
    return false;
  };

  const notInCatalog =
    _.isEmpty(mapping) &&
    _.isEmpty(ctx.data.warehouseQty) &&
    _.isEmpty(ctx.data.supplierQty) &&
    _.isEmpty(ctx.data.historySupplier);

  const history = useMemo(() => {
    if (ctx.data.historySupplier) return ctx.data?.historySupplier[product.uid];
    else return null;
  }, []);

  const showLager = lager.length > 0 || isAutoAntonio;

  return (
    <AccordionWrapper id="order_accordion">
      <AccordionHeader>
        <HeaderTitleWrapper>
          <p>
            {product?.model?.brand} {product.productName}
          </p>
          <SubtitleWrapper>
            <div>
              <span>EAN: {product.ean}</span>
              <span>IPC: {product.manufacturerCode}</span>
              <span>SLUG: {mainSupplierSlug ?? ''}</span>
              <span>PRICE:{lineItem?.purchasing?.purchasePrice ?? ''}</span>
            </div>
            <div>
              {lineItem?.deliveryDateFrom && lineItem.deliveryDateTo && (
                <span>
                  Expected delivery time:
                  {lineItem?.deliveryDateFrom as any}-{' '}
                  {lineItem?.deliveryDateTo as any}
                </span>
              )}
            </div>
          </SubtitleWrapper>
        </HeaderTitleWrapper>
        <OrderAccordionHeaderCounter
          fulfilled={fulfilledProductQty}
          requested={ctx.data.requested[product.uid] ?? 0}
        />
        <IconButton onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </AccordionHeader>
      <AnimatePresence initial={true}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.2, ease: [0.04, 0.62, 0.73, 0.98] }}
          >
            {notInCatalog ? (
              <AccordionContent>
                <OrderItemContainer>
                  <Typography>
                    This item doesn't exists in tyrio catalog!
                  </Typography>
                </OrderItemContainer>
              </AccordionContent>
            ) : requestedQty !== 0 ? (
              <AccordionContent>
                <Scrollbars
                  style={{
                    width: '100%',
                    height: showLager && mapping.length > 0 ? 500 : 216,
                    paddingRight: 16,
                    marginRight: 16,
                  }}
                >
                  <ShowDivider
                    text="Warehouse stock"
                    showDivider={showLager && mapping.length > 0}
                  />

                  {lager && showLager && (
                    <OrderItemContainer>
                      {Object.values(ctx.data.warehouses).map((item, index) => {
                        return (
                          <OrderItemGridElement key={index + item.id}>
                            <LagerItem
                              branchId={item.id.toString()}
                              product={product}
                              purchasedQuantity={purchasedQty ?? 0}
                              lowestSupplierPrice={lowestProductPrice}
                            />
                          </OrderItemGridElement>
                        );
                      })}
                    </OrderItemContainer>
                  )}

                  <ShowDivider
                    text="Supplier stock"
                    showDivider={lager.length > 0 && mapping.length > 0}
                  />

                  <OrderItemContainer>
                    {!mainSupplier && !history && !isAutoAntonio && (
                      <HistoryMissing slug={mainSupplierSlug} />
                    )}
                    {!mainSupplier && history && (
                      <Div>
                        <OrderItemGridElement>
                          <OrderItem
                            supplier={history}
                            product={product}
                            price={lineItem?.purchasing?.purchasePrice ?? 0}
                            requestedQuantity={
                              ctx.data.requested?.[product.uid] ?? 0
                            }
                            supplierQuantity={0}
                            purchasedQuantity={0}
                            minDeliveryDate={null}
                            maxDeliveryDate={null}
                            isOrderLate={false}
                            historyMissing={true}
                          ></OrderItem>
                        </OrderItemGridElement>
                        <Divider
                          orientation="vertical"
                          sx={{ borderWidth: '2px' }}
                        />
                      </Div>
                    )}
                    {mapping.map(
                      (
                        {
                          resolvedSupplierQty,
                          resolvedSupplierDeliveryDate,
                          resolvingSupplierInquiry,
                          supplierId,
                          price,
                          isMainSupplier,
                          inquiryError,
                          priceObj,
                          minDeliveryDate,
                          maxDeliveryDate,
                        }: // eslint-disable-next-line
                        any,
                        index
                      ) => {
                        return (
                          <Div>
                            <OrderItemGridElement key={index}>
                              <OrderItem
                                product={product}
                                supplier={suppliers[supplierId]}
                                lowestProductPrice={lowestProductPrice}
                                mainSupplierPrice={mainSupplierPrice}
                                price={price}
                                resolvedSupplierQty={resolvedSupplierQty}
                                resolvedSupplierDeliveryDate={
                                  resolvedSupplierDeliveryDate
                                }
                                resolvingSupplierInquiry={
                                  resolvingSupplierInquiry
                                }
                                originalPrice={
                                  ctx.data.prices?.[supplierId]?.[product.uid]
                                    ?.original
                                }
                                isMainSupplier={isMainSupplier ?? false}
                                requestedQuantity={
                                  ctx.data.requested?.[product.uid] ?? 0
                                }
                                supplierQuantity={
                                  ctx.data.supplierQty?.[product.uid]?.[
                                    supplierId
                                  ] ?? 0
                                }
                                purchasedQuantity={purchasedQty ?? 0}
                                inquiryError={inquiryError}
                                priceObj={priceObj}
                                minDeliveryDate={minDeliveryDate}
                                maxDeliveryDate={maxDeliveryDate}
                                isOrderLate={makeDateComparison(
                                  maxDeliveryDate
                                )}
                              />
                            </OrderItemGridElement>

                            {isMainSupplier && (
                              <Divider
                                orientation="vertical"
                                sx={{ borderWidth: '2px' }}
                              />
                            )}
                          </Div>
                        );
                      }
                    )}
                  </OrderItemContainer>
                </Scrollbars>
              </AccordionContent>
            ) : (
              <AccordionContent>
                <OrderItemContainer style={{ paddingBottom: '16px' }}>
                  <Typography>Item is processed</Typography>
                </OrderItemContainer>
              </AccordionContent>
            )}
          </motion.section>
        )}
      </AnimatePresence>
    </AccordionWrapper>
  );
};

const HistoryMissing = ({ slug }: { slug?: string }) => {
  return (
    <>
      <OrderItemGridElement>
        <Wrapper
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: '#e86032',
          }}
        >
          <WarningAmberIcon
            color="warning"
            style={{ width: '2em', height: '2em' }}
          />
          <Typography color={'#e86032'}>Supplier missing</Typography>
          {slug && (
            <Typography fontWeight={700} color={tyrioUI.colors.black.B80}>
              {slug}
            </Typography>
          )}
        </Wrapper>
      </OrderItemGridElement>
      <Divider
        orientation="vertical"
        sx={{ borderWidth: '2px', height: '165px' }}
      />
    </>
  );
};

const ShowDivider = ({
  text,
  showDivider,
}: {
  text: string;
  showDivider: boolean;
}) => {
  return showDivider ? (
    <Divider style={{ padding: '15px 0' }}>
      <Typography>{text}</Typography>
    </Divider>
  ) : null;
};

const AccordionWrapper = styled.div`
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: #f4f6f8;
  padding: 8px 16px;
  min-height: 60px;
`;
const AccordionContent = styled.div`
  display: block;
  box-sizing: border-box;
  //padding-top: 4px;
`;
const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  //padding: 16px 0 0 16px;
  padding: 16px 16px 0 16px;
  gap: 16px;
  margin-bottom: 16px;
`;
const OrderItemGridElement = styled.div`
  flex: 1;
  height: 165px;
  max-width: 160px;
  min-width: 160px;
  border-radius: 16px;
`;

const HeaderTitleWrapper = styled.div`
  flex: 2;
  //max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  p {
    display: block;
    margin: 0;
    padding: 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    align-items: center;
    letter-spacing: 0.3px;

    color: #212b36;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      display: flex;
      align-items: center;
      letter-spacing: 0.3px;

      color: #919eab;
      margin-right: 24px;
      // &:first-child {
      //   margin-right: 24px;
      // }
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 175px;
  min-width: 175px;
  justify-content: space-between;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100%;
`;
