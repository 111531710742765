import CustomerOrdersOverview from '../../features/customer-orders/CustomerOrdersOverview';

interface CustomerOrdersProps {
  sidebarOpen: boolean;
}

const CustomerOrders = ({ sidebarOpen }: CustomerOrdersProps) => {
  return <CustomerOrdersOverview sidebarOpen={sidebarOpen} />;
};

export default CustomerOrders;
