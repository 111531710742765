import styled from '@emotion/styled';
import { Button, Modal, TableBody, TableHead, Typography } from '@mui/material';
import { CartStockListItem } from '@tyrio/dto';
import { tyrioIcons } from '@tyrio/ui-library';
import { useContext } from 'react';
import { CartContext } from '../../../../context/CartContext';
import { useWS } from '../../../../context/WSContext';
import ProductItemWithQuantities from './ProductItemWithQuantities';

export const QuantitiesNotAvailableModal = () => {
  const ws = useWS();
  const {
    openModal,
    setOpenModal,
    unavailableQuantitesData,
    setUnavailableQuantitesData,
    timer,
  } = useContext(CartContext);

  const handleCancel = () => {
    timer?.clear();
    ws.socket?.emit('remove-all-cart-items', {});
    setOpenModal(false);
    setUnavailableQuantitesData([]);
  };

  const handleContinue = () => {
    setOpenModal(false);
    timer?.restart();
    setUnavailableQuantitesData([]);
  };

  return (
    <Modal
      open={openModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalWrapper>
        <img
          src={tyrioIcons.warning}
          alt="warning"
          style={{ marginTop: '22px' }}
        />
        <Typography fontSize={20} fontWeight={600} letterSpacing={0.3}>
          Requested quantities are no longer available!
        </Typography>
        <Typography
          fontSize={18}
          color="#919EAB"
          letterSpacing={0.3}
          fontWeight={400}
        >
          Do you wish to continue or cancel?
        </Typography>

        <ProductsTable>
          <StyledTableHead>
            <TableHeadItem>Item</TableHeadItem>
            <div style={{ display: 'flex' }}>
              <TableHeadItem>Requested</TableHeadItem>
              <TableHeadItem>Available</TableHeadItem>
            </div>
          </StyledTableHead>
          <TableBody style={{ overflowY: 'auto', maxHeight: '400px' }}>
            {unavailableQuantitesData.map((data) => {
              const v = Object.values(data);
              const value = v[0];
              const available = v[1];
              const val = value as unknown as CartStockListItem;
              return (
                <ProductItemWithQuantities
                  product={{
                    productName: val.productName,
                    ean: val.ean,
                    sku: val.sku.toString(),
                    productYear: val.dot,
                  }}
                  requested={val.quantity}
                  available={(available as number) | 0}
                />
              );
            })}
          </TableBody>
        </ProductsTable>

        <ButtonWrapper>
          <StyledButton
            variant="outlined"
            color="info"
            size="large"
            onClick={handleCancel}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="info"
            size="large"
            onClick={handleContinue}
          >
            CONTINUE
          </StyledButton>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  background: #fafafa;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22),
    0px 19px 38px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  max-height: 800px;
`;

const StyledTableHead = styled(TableHead)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #dfe3e8;
  height: 50px;
  margin-top: 20px;
`;

const TableHeadItem = styled.div`
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 264px;
  height: 60px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 24px;
  margin-top: 16px;
`;

const ProductsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
