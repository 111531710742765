import api from '@tyrio/api-factory';
import { DBStockLocationsApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetWarehouseBranches = () => {
  const warehouseBranches = useQuery(['warehouse_branches'], async () => {
    return await api.fetch<DBStockLocationsApi['warehouseBranches']>(
      'warehouse_branches'
    );
  });

  return { warehouseBranches };
};
