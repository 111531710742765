import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import { DBBusinessType, DBClientType, DBCurrency } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBClientApi,
  DBCompanyContactApi,
  DBCountryApi,
  DBRoleApi,
  DBUserApi,
} from '@tyrio/dto';
import {
  FormController,
  TyrioSelectInputOption,
  generateClientForm,
} from '@tyrio/forms';
import {
  DeleteModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import { get, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';

export function remapToIds<T extends { id: string; tempId?: string }>(
  data?: T[]
) {
  const response: Record<string, T> = {};
  (data || []).forEach((item) => {
    if (item.tempId) {
      response[item.tempId] = item;
    } else {
      response[item.id] = item;
    }
  });
  return response;
}

interface AddTempInt {
  address1?: string;
  city?: string;
  countryId?: string;
  zipCode?: string;
}
const ClientForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { clientId } = useParams<{ clientId: string }>();

  const [partialClientData, setPartialClientData] = useState<
    Partial<DBClientApi['getOne']['response']> | undefined
  >(undefined);

  const [partialAddress, setPartialAddress] = useState<AddTempInt | undefined>(
    undefined
  );

  const rolesListQuery = useQuery(
    ['roles_list_client_form', clientId],
    () =>
      api.fetch<DBRoleApi['list']>('role_list', {
        search: '',
        isActive: true,
        clientPredefined: true,
      }),
    {
      // onSuccess: (roles: DBRoleApi['list']['response']) => {
      //   const dropdownItems: { label: string; value: string }[] = [];
      //   roles.data.map((item: DBRole) => {
      //     if (item.id === '00000000-0000-0000-0000-000000000006') {
      //       return dropdownItems.push({
      //         label: item.roleName,
      //         value: item.id,
      //         disabled: true,
      //       });
      //     } else return null;
      //   });
      //   setRolesDataDropdownData(dropdownItems);
      // },
    }
  );

  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setCountries(dropdownItems);
      },
    }
  );

  // GET client by id
  const {
    data: client_data,
    isFetching: isClientDataFetching,
    refetch: getClientById,
  } = useQuery(
    ['get_client_id', clientId, 'update_client'],
    () =>
      api.fetch<DBClientApi['getOne']>(`client_id`, {
        id: clientId,
      }),
    {
      enabled: !!clientId,
    }
  );

  // CREATE new client mutation
  const createClientMutation = useMutation(
    (client: DBClientApi['create']['request']) =>
      api.fetch<DBClientApi['create']>('create_client', {
        ...client,
      }),
    {
      mutationKey: 'create_client',
      onSuccess: (data) => {
        queryClient.refetchQueries('client_list');
        ToastHelper.showToast(
          'Client',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/clients/${data.id}`);
        setPartialAddress(undefined);
        setPartialClientData(undefined);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  // UPDATE client mutation
  const updateClientMutation = useMutation(
    (client: DBClientApi['updateOne']['request']) =>
      api.fetch<DBClientApi['updateOne']>('update_client', {
        ...client,
      }),
    {
      mutationKey: 'update_client',
      onSuccess: () => {
        queryClient.refetchQueries('client_list');
        queryClient.refetchQueries('get_client_id');

        getClientById();

        ToastHelper.showToast(
          'Client',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        setPartialAddress(undefined);
        setPartialClientData(undefined);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  // DELETE client mutation
  const deleteClientMutation = useMutation(
    () =>
      api.fetch<DBClientApi['getOne']>('delete_client', {
        id: clientId,
      }),
    {
      mutationKey: 'delete_client',
      onSuccess: () => {
        queryClient.refetchQueries('client_list');
        queryClient.refetchQueries('get_client_id');
        ToastHelper.showToast(
          'Client',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
        setPartialClientData(undefined);
        setPartialAddress(undefined);
        history.push('/dashboard/clients');
      },
      onError: () => {
        ToastHelper.showToast(
          'Client',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const handleClientForm = async (data: z.infer<typeof zodSchema>) => {
    setPartialClientData({
      clientType: DBClientType[data.clientType as keyof typeof DBClientType],
      businessType:
        DBBusinessType[data.businessType as keyof typeof DBBusinessType],
      vatNumber: data.vatNumber,
      vies: data.vies || false,
      euVatNumber: data.euVatNumber || '',
      officialName: data.officialName,
      shortName: data.shortName,
      remark: data.remark || '',
      IBAN: data.IBAN || '',
      swift: data.swift || '',
      currency: DBCurrency[data.currency as keyof typeof DBCurrency],
      companyContacts: Object.keys(data?.companyContacts || []).map(
        (compKey) => {
          return {
            ...get(data, `companyContacts[${compKey}]`),
            clientId: compKey,
            tempId:
              get(data, `companyContacts[${compKey}][key]`) ||
              get(data, `companyContacts[${compKey}][id]`),
          };
        }
      ),
      users: Object.keys(data?.users || []).map((userKey) => {
        return {
          ...get(data, `users[${userKey}]`),
          clientId: userKey,
          tempId:
            get(data, `users[${userKey}][key]`) ||
            get(data, `users[${userKey}][id]`),
        };
      }),
    });
    setPartialAddress({
      address1: data.address,
      city: data.city,
      countryId: data.countryId,
      zipCode: data.zipCode,
    });

    if (clientId) {
      const client_data: DBClientApi['updateOne']['request'] = {
        id: clientId,
        shortName: data.shortName,
        remark: data.remark,
        IBAN: data.IBAN,
        swift: data.swift,
        currency: DBCurrency[data.currency as keyof typeof DBCurrency],
        euVatNumber: data.euVatNumber,
        vatNumber: data.vatNumber,
        clientType: data.clientType as DBClientType,
        companyContacts: Object.keys(data?.companyContacts || []).map((key) => {
          return omit(
            {
              ...get(data, `companyContacts[${key}]`),
              clientId: clientId,
            },
            'key'
          );
        }) as unknown as DBCompanyContactApi['updateOne']['requestBody'][],
        users: Object.keys(data?.users || []).map((key) => {
          return omit(
            { ...get(data, `users[${key}]`), clientId: clientId },
            'key'
          );
        }) as unknown as DBUserApi['create']['requestBody'][],
      };

      let result = undefined;
      await updateClientMutation.mutateAsync(client_data).then((res) => {
        result = res;
      });

      return result !== undefined;
    } else {
      const client_data: DBClientApi['create']['request'] = {
        clientType: DBClientType[data.clientType as keyof typeof DBClientType],
        businessType:
          DBBusinessType[data.businessType as keyof typeof DBBusinessType],
        vatNumber: data.vatNumber,
        vies: data.vies || false,
        euVatNumber: data.euVatNumber || '',
        officialName: data.officialName,
        shortName: data.shortName,
        address: {
          address1: data.address,
          city: data.city,
          countryId: data.countryId,
          zipCode: data.zipCode,
        },
        IBAN: data.IBAN || '',
        swift: data.swift || '',
        currency: DBCurrency[data.currency as keyof typeof DBCurrency],
        remark: data.remark || '',
        companyContacts: Object.keys(data?.companyContacts || []).map((key) => {
          return omit(
            {
              ...get(data, `companyContacts[${key}]`),
              clientId: clientId,
            },
            'key'
          );
        }) as unknown as DBCompanyContactApi['updateOne']['requestBody'][],
        users: Object.keys(data?.users || []).map((key) => {
          return omit(
            { ...get(data, `users[${key}]`), clientId: clientId },
            'key'
          );
        }) as unknown as DBUserApi['create']['requestBody'][],
      };
      let result = undefined;
      await createClientMutation.mutateAsync(client_data).then((res) => {
        result = res;
      });

      return result !== undefined;
    }
  };

  const handleOnCancel = () => {
    history.push(`/dashboard/clients`);
  };

  const handleDeleteClient = () => {
    if (clientId) setIsDeleteModalVisible(true);
  };

  const handleChangeClientStatus = () => {
    if (clientId) {
      updateClientMutation.mutate({
        id: clientId,
        active: !client_data?.active,
      });
    }
  };

  const { form, zodSchema } = useMemo(
    () =>
      generateClientForm(
        rolesListQuery.data?.data?.map((item) => ({
          label: item.roleName,
          value: item.id,
          disabled: item.id !== '00000000-0000-0000-0000-000000000006',
        })) || [],
        countries,
        clientId !== undefined
      ),
    [clientId, countries, rolesListQuery.data?.data]
  );

  const clientName = client_data?.officialName;

  useEffect(() => {
    if (history.location.pathname.includes('new')) {
      setPartialClientData({});
      setPartialAddress({});
    }
  }, [history.location, history.location.pathname]);

  return (
    <PageTemplateContent>
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            deleteClientMutation.mutate();
            setIsDeleteModalVisible(false);
          }}
          itemName={clientName as string}
        />
      )}
      {isClientDataFetching && clientId ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <FormController<z.infer<typeof zodSchema>>
          form={form}
          key={`${client_data?.id || 'new'}`}
          initialValues={{
            clientType:
              client_data?.clientType || partialClientData?.clientType || '',
            businessType:
              client_data?.businessType ||
              partialClientData?.businessType ||
              '',
            euVatNumber: client_data?.euVatNumber || '',
            vatNumber:
              client_data?.vatNumber || partialClientData?.vatNumber || '',
            officialName:
              client_data?.officialName ||
              partialClientData?.officialName ||
              '',
            shortName:
              client_data?.shortName || partialClientData?.shortName || '',
            address:
              client_data?.address.address1 || partialAddress?.address1 || '',
            zipCode:
              client_data?.address.zipCode || partialAddress?.zipCode || '',
            countryId:
              client_data?.address.countryId || partialAddress?.countryId || '',
            city: client_data?.address.city || partialAddress?.city || '',
            referralId: client_data?.referralId || '',
            remark: client_data?.remark || partialClientData?.remark || '',
            IBAN: client_data?.IBAN || '',
            swift: client_data?.swift || '',
            currency: client_data?.currency || '',
            companyContacts:
              client_data &&
              client_data.companyContacts &&
              client_data?.companyContacts?.length
                ? remapToIds(client_data.companyContacts)
                : partialClientData &&
                  partialClientData.companyContacts &&
                  partialClientData?.companyContacts?.length > 0
                ? remapToIds(partialClientData?.companyContacts)
                : undefined,
            users:
              client_data && client_data.users && client_data?.users?.length
                ? remapToIds(client_data.users)
                : partialClientData &&
                  partialClientData.users &&
                  partialClientData?.users?.length > 0
                ? remapToIds(partialClientData?.users)
                : undefined,
          }}
          zodSchema={zodSchema}
          onSubmit={(data) => handleClientForm(data)}
          onCancel={handleOnCancel}
          onDelete={handleDeleteClient}
          onChangeStatus={handleChangeClientStatus}
          active={client_data ? client_data.active : true}
          title={
            clientId
              ? client_data?.officialName || 'companyName'
              : t('Create new client')
          }
          objectId={client_data?.id}
        />
      )}
    </PageTemplateContent>
  );
};

export default ClientForm;
