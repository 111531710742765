import styled from '@emotion/styled';
import { ServiceInfo } from '@tyrio/dto';

interface ItemProps {
  category: string;
  services: Record<string, ServiceInfo>;
}

export const Services = (props: ItemProps) => {
  const { category, services } = props;

  return (
    <Container>
      <Category>{category}</Category>
      <ServiceList>
        {Object.values(services).map((item) => (
          <Service key={item.id}>{`${item.name} (x${item.qty})`}</Service>
        ))}
      </ServiceList>
    </Container>
  );
};

const Container = styled.span`
  display: flex;
  flex-direction: column;
`;

export const Category = styled.span<{
  isWarning?: boolean;
}>`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  color: ${({ isWarning }) => (isWarning ? '#E75524' : '#212B36')};
`;

const Service = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
`;
