import api from '@tyrio/api-factory';
import { WheelSizeApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetVehicleModifications = (
  make: string,
  model: string,
  generation: string,
  enabled: boolean
) => {
  const { data, isFetched, isError, isLoading, isRefetching } = useQuery(
    ['get_modifications'],
    () =>
      api.fetch<WheelSizeApi['getOneModification']>('get_modifications', {
        make,
        model,
        generation,
      }),

    { enabled }
  );

  return {
    vehicleModifications: data?.data ?? [],
    isFetched,
    isError,
    isLoading,
    isRefetching,
  };
};
