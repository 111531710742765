import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';
import { DBService, DBServiceCategory } from '@prisma/client';
import { ServicePriceShape, StockType } from '@tyrio/dto';
import { POSContext } from '../../../../../context/POSContext';
import { useCallback, useContext, useMemo, useState } from 'react';
import { PosCartContext } from '../../../../../context/PosCartContext';
import { useWS } from '../../../../../context/WSContext';
import { usePosCartData } from '../helpers/cart-data';
import { formatCurrencyWithSymbol } from '../../../../../helpers/currency-format';

interface CardProps {
  service: DBService & { serviceCategory: DBServiceCategory };
  isTyreDisposal?: boolean;
}

export const Card = ({ service, isTyreDisposal = false }: CardProps) => {
  const ws = useWS();
  const { servicesData, modalData } = usePosCartData();
  const { timer } = useContext(PosCartContext);
  const { isVatIncluded } = useContext(POSContext);

  const [isHovered, setIsHovered] = useState(false);

  const priceData = service.servicePrice as unknown as ServicePriceShape;

  const exclusiveVatPrice = priceData.fixedPrice
    ? priceData.fixedPrice?.exclusiveVat
    : priceData.chargedByLaborTime?.finalPrice ?? 0;

  const inclusiveVatPrice = priceData.fixedPrice
    ? priceData.fixedPrice?.inclusiveVat
    : priceData.chargedByLaborTime?.finalPrice ?? 0;

  const finalPrice = priceData.fixedPrice
    ? isVatIncluded
      ? inclusiveVatPrice
      : exclusiveVatPrice
    : priceData.chargedByLaborTime?.finalPrice ?? 0;

  const addServiceToCart = useCallback(() => {
    const items = modalData.filter(
      (item) =>
        (
          service.subcategory as unknown as { value: number; label: string }[]
        ).find((el) => Number(el.value) === Number(item?.req?.categoryId)) !==
          undefined && item.stockType !== ('SERVICES' as StockType)
    );
    const quantity =
      items.reduce((acc, curr) => acc + curr.req.quantity, 0) ?? 0;

    ws.socket?.emit('add-to-pos-cart', {
      key: service.id,
      req: {
        quantity,
        ean: '',
        dot: '',
        sku: '',
        uid: '',
        productName: service.name,
        productBrand: '',
        productDescription: service.description,
        price: exclusiveVatPrice ?? 0,
        reserved: quantity,
        total: 0,
        realQuantity: 1000,
        inclusiveVat: inclusiveVatPrice ?? 0,
        serviceCategory: service.serviceCategoryId,
      },
      stockType: 'SERVICES',
    });
    timer?.restart();
  }, [
    exclusiveVatPrice,
    inclusiveVatPrice,
    modalData,
    service.description,
    service.id,
    service.name,
    service.serviceCategoryId,
    service.subcategory,
    timer,
    ws.socket,
  ]);

  const isItemInCart = useMemo(() => {
    const found = servicesData.find((item) => item.key === service.id);
    return found !== undefined;
  }, [service.id, servicesData]);

  const renderPrice = useMemo(() => {
    return (
      <Price
        color={finalPrice !== 0 ? 'black' : '#3EB274'}
        fontWeight={finalPrice !== 0 ? '400' : '500'}
      >
        {finalPrice && finalPrice !== 0
          ? formatCurrencyWithSymbol(finalPrice)
          : 'FREE'}
      </Price>
    );
  }, [finalPrice]);

  const renderButton = useMemo(() => {
    return (
      <Button
        title="ADD"
        color="info"
        variant="contained"
        type="button"
        style={{
          width: '100px',
          height: '20px',
          borderRadius: '4px',
          color: 'white',
          background: !isItemInCart ? '#1976d2' : '#3EB274',
          padding: '8px',
        }}
        onClick={addServiceToCart}
        disabled={isItemInCart}
        disableElevation
      >
        {!isItemInCart ? 'ADD' : 'ADDED'}
      </Button>
    );
  }, [addServiceToCart, isItemInCart]);

  return (
    <RecommendedCardWrapper
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isAdded={isItemInCart}
      tyredisposal={isTyreDisposal}
    >
      <Price color={'black'}>{service.serviceCategory.name}</Price>
      <Description>{service.name}</Description>
      <RecommendedCardChildrenWrapper>
        {!isHovered && !isItemInCart ? renderPrice : renderButton}
      </RecommendedCardChildrenWrapper>
    </RecommendedCardWrapper>
  );
};

const RecommendedCardWrapper = styled.div<{
  isAdded: boolean;
  tyredisposal?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  height: ${(props) => (props.tyredisposal ? '150px' : 'auto')};
  border-radius: 8px;
  border: 1px solid ${(props) => (!props.isAdded ? '#dfe3e8' : '#3EB274')};
  gap: 5px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${(props) => (!props.isAdded ? '#1976d2' : '#3EB274')};
  }
`;

const Description = styled.div`
  font-family: Barlow;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #919eab;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const Price = styled.div<{ color: string; fontWeight?: string }>`
  font-family: Barlow;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  color: ${(props) => props.color};
  text-align: center;
`;

const RecommendedCardChildrenWrapper = styled.div`
  height: 30px;
`;
