import styled from '@emotion/styled';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGridProps, TyrioCheckboxInput, TyrioFormError } from '../types';

interface TyrioInputCheckboxProps {
  id: string;
  item: TyrioCheckboxInput;
  gridProps?: FormGridProps;
  register: UseFormRegister<FieldValues>;
  error?: TyrioFormError;
}
export const TyrioInputCheckbox = ({
  id,
  item,
  gridProps,
  register,
}: TyrioInputCheckboxProps) => {
  const { t } = useTranslation();
  return (
    <GridContainer item {...gridProps} style={{ paddingTop: 0 }}>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            defaultChecked={item.defaultChecked}
            value={item.value}
            disabled={item.disabled}
            color="info"
            {...register(id)}
          />
        }
        label={t(`${item.label}`)}
      />
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  padding-bottom: 22px;
  display: flex;
  align-items: center;
`;
