/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { ExpandableLayout } from '../../layouts/ExpandableLayout';
import {
  CatalogImportPayload,
  CatalogSidebar,
} from '../../features/catalog/CatalogSidebar';
import styled from '@emotion/styled';
import { PageTemplateContent } from '../../components/PageTemplate/PageTemplate';
import { Title } from '@tyrio/ui-library';
import { Button, CircularProgress, Divider } from '@mui/material';
import { useMutation } from 'react-query';

import { DBProduct, DBProductModel } from '@prisma/client';
import { CatalogImportEntries } from '../../features/catalog/CatalogImportEntries';
import api from '@tyrio/api-factory';

interface ImportError {
  message: string;
  data: any;
  context?: any;
}
interface TempResponseInterface {
  models: Record<string, DBProductModel>;
  products: Record<string, DBProduct>;
  productErrors: ImportError[];
  modelErrors: ImportError[];
  skippedErrors: ImportError[];
}
export const CatalogImport = () => {
  const [showDetails, setShowDetails] = useState(false);
  const { mutate, isLoading, data, isSuccess } = useMutation(
    ['import_catalog'],
    (payload: CatalogImportPayload) =>
      api.fetch<{
        request: CatalogImportPayload;
        requestBody: CatalogImportPayload;
        response: TempResponseInterface;
      }>('import_catalog', payload)
  );

  return (
    <ExpandableLayout
      sidebarComponent={
        <CatalogSidebar onCatalogImport={(data) => mutate(data)} />
      }
      landingPage={
        <PageTemplateContent>
          <Wrapper>
            {isLoading && <CircularProgress />}
            {isSuccess && (
              <>
                <Title>
                  <strong>IMPORT SUCCESSFUL!</strong>
                </Title>
                <Divider />
              </>
            )}
            {data && (
              <Button
                variant={'contained'}
                onClick={() => setShowDetails(true)}
              >
                Show import details
              </Button>
            )}
            {data && showDetails && (
              <>
                <Title>Model import errors</Title>
                <CatalogImportEntries data={data.productErrors} />
                <Title>Product import errors</Title>
                <CatalogImportEntries data={data.modelErrors} />
                <Title>Skip import errors</Title>
                <CatalogImportEntries data={data.skippedErrors} />
              </>
            )}
          </Wrapper>
        </PageTemplateContent>
      }
    >
      <div></div>
    </ExpandableLayout>
  );
};

const Wrapper = styled.div`
  padding: 20px;
`;
