import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import { CircularProgress, Typography } from '@mui/material';

import { TrackingItem } from './TrackingItem';
import { OrderTypesEnum, TrackInfoPreviewModel } from '@tyrio/dto';
import { useGetTrackingInfo } from './queries/get-tracking-info';
import { useUpdateManyTrackingItems } from './queries/update-many-tracking-info';
import { DBTrackingInfo } from '@prisma/client';

interface TrackingTabsProps {
  zipCode: string | null;
  orderId: string;
  orderType: OrderTypesEnum;
}

export const TrackingTabs = ({
  zipCode,
  orderId,
  orderType,
}: TrackingTabsProps) => {
  const { trackingInfoData, refetchTrackingInfo } = useGetTrackingInfo(
    orderId,
    orderType
  );

  const [trackingInfoItems, setTrackingInfoItems] = useState<
    TrackInfoPreviewModel[]
  >([]);
  const { updateManyTrackItems } =
    useUpdateManyTrackingItems(refetchTrackingInfo);

  const refreshLastUpdated = (trackingInfo: DBTrackingInfo[]) => {
    const updatedData = trackingInfo.map((item) => ({
      ...item,
      lastUpdated: new Date().toISOString(),
    }));

    setTrackingInfoItems(updatedData);
  };

  useEffect(() => {
    if (trackingInfoData) {
      refreshLastUpdated(trackingInfoData);
    }
  }, [trackingInfoData]);

  const handleUpdateMany = () => {
    const trackNumbers = trackingInfoItems
      ? trackingInfoItems.map((item) => item.trackingNumber)
      : [];

    const request = {
      trackNumbers: trackNumbers,
      orderType,
    };

    updateManyTrackItems.mutate(request, {
      onSuccess: () => {
        if (trackingInfoData) {
          refreshLastUpdated(trackingInfoData);
        }
      },
    });
  };

  if (trackingInfoItems && trackingInfoItems.length > 0) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '0px 16px 16px',
          }}
          id="tracking_info"
        >
          <Button
            onClick={handleUpdateMany}
            sx={{
              backgroundColor: 'white',
              border: 'none',
            }}
            color="info"
          >
            UPDATE NOW <RefreshIcon sx={{ marginLeft: '8px' }} />
          </Button>
        </Box>

        {updateManyTrackItems.isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 16px 16px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          trackingInfoItems.map((data: TrackInfoPreviewModel) => (
            <TrackingItem
              key={data.id}
              trackingItemData={data}
              zipCode={zipCode}
              refetchTrackingInfo={refetchTrackingInfo}
            />
          ))
        )}
      </>
    );
  } else {
    return (
      <Typography sx={{ margin: '16px' }}>
        There are no items currently in the Tracking tab.
      </Typography>
    );
  }
};
