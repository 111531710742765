import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ClientForm from '../features/client-form/ClientForm';
import DashboardClient from '../pages/dashboard/Client';

export const ClientRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <DashboardClient>
        {location.pathname.split('/').pop() === 'new' ? (
          <Route exact path={`${path}/new`}>
            <ClientForm />
          </Route>
        ) : (
          <Route exact path={`${path}/:clientId`}>
            <ClientForm />
          </Route>
        )}
      </DashboardClient>
    </Switch>
  );
};
