import styled from '@emotion/styled/macro';
import { Add, Cancel } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import * as _ from 'lodash';
import { useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
interface CodeQuantityInterface {
  value: string;
  qty: number;
}

export const CodeTypes = {
  ean: 'EAN',
  ipc: 'IPC/CAI',
  sku: 'SKU',
} as const;

export type CodeTypes = typeof CodeTypes[keyof typeof CodeTypes];

interface CodeTypeComparisonFormProps {
  codeType: CodeTypes;
  onCodeChange: (code: string) => void;
  onListChange: (list: CodeQuantityInterface[]) => void;
  setIsDirty: (dirty: boolean) => void;
}
const CodeTypeComparisonForm = (props: CodeTypeComparisonFormProps) => {
  const { codeType, onCodeChange, onListChange, setIsDirty } = props;

  const [selectedCodeType, setSelectedCodeType] = useState(codeType);

  const [bulkInput, setBulkInput] = useState('');
  const [bulkError, setBulkError] = useState<string | undefined>(undefined);

  const [arrayOfCodes, setArrayOfCodes] = useState<CodeQuantityInterface[]>([]);

  const [manualCode, setManualCode] = useState('');
  const [manualCodeError, setManualCodeError] = useState<string | undefined>(
    undefined
  );
  const [manualQty, setManualQty] = useState('');

  const [searchValue, setSearchValue] = useState('');

  const splitBulk = (val: string) => {
    setBulkError(undefined);
    setIsDirty(true);
    let newArray = _.cloneDeep(arrayOfCodes);
    const linesArray = val.split('\n');
    linesArray.forEach((elem) => {
      if (elem === '') return;

      const regexOnlyNumbersAndLetters = /^[A-Za-z0-9]*$/;
      if (regexOnlyNumbersAndLetters.test(elem)) {
        const elOfArray: CodeQuantityInterface = {
          value: elem,
          qty: 1,
        };

        if (newArray.find((el) => el.value === elOfArray.value)) {
          // TODO: Find better way of handling this.
          newArray.forEach((element) => {
            if (element.value === elOfArray.value) {
              element.qty += elOfArray.qty;
              return;
            }
          });
        } else {
          newArray = [elOfArray, ...newArray];
        }
      } else {
        const newElem = elem.split('\t');
        const re = /^[0-9\b]+$/;

        if (newElem[0] && newElem[1] && re.test(newElem[1])) {
          const elOfArray: CodeQuantityInterface = {
            value: newElem[0],
            qty: parseInt(newElem[1]),
          };

          if (newArray.find((el) => el.value === elOfArray.value)) {
            // TODO: Find better way of handling this.
            newArray.forEach((element) => {
              if (element.value === elOfArray.value) {
                element.qty += elOfArray.qty;
                return;
              }
            });
          } else {
            newArray = [elOfArray, ...newArray];
          }
        } else {
          setBulkError('Given data is not in right format.');
          return;
        }
      }
    });
    if (val !== bulkInput) {
      setBulkInput(val);
    }
    return newArray;
  };

  const handleAddToListBulk = () => {
    const val = splitBulk(bulkInput);
    setIsDirty(true);
    const temp = arrayOfCodes;
    setArrayOfCodes(val);
    onListChange(val);
    let isEqual = true;

    val.forEach((valEl) => {
      if (
        !temp.find(
          (aOCElem) =>
            valEl.value === aOCElem.value && valEl.qty === aOCElem.qty
        )
      ) {
        isEqual = false;
      }
    });
    if (!isEqual) {
      setBulkInput('');
      setIsDirty(false);
    }
  };

  const handleAddManuallyCodeWithQty = () => {
    setManualCodeError(undefined);

    if (manualCode === '') {
      setManualCodeError(`${selectedCodeType} is required.`);
    }

    const existingElement = arrayOfCodes.find((el) => el.value === manualCode);
    if (existingElement) {
      const newElem: CodeQuantityInterface = {
        value: existingElement.value,
        qty: existingElement.qty + (manualQty === '' ? 1 : parseInt(manualQty)),
      };

      const index = arrayOfCodes.indexOf(existingElement);
      setArrayOfCodes((prevVal) => {
        const firstPart = prevVal.slice(0, index);
        const secondPart = prevVal.slice(index + 1, prevVal.length);
        onListChange([...firstPart, newElem, ...secondPart]);
        return [...firstPart, newElem, ...secondPart];
      });
    } else {
      setArrayOfCodes((prevVal) => [
        {
          value: manualCode,
          qty: manualQty === '' ? 1 : parseInt(manualQty),
        } as CodeQuantityInterface,
        ...prevVal,
      ]);
      onListChange([
        ...arrayOfCodes,
        { value: manualCode, qty: manualQty === '' ? 1 : parseInt(manualQty) },
      ]);
    }
    setManualCode('');
    setManualQty('');
    setIsDirty(false);
  };

  const handleSelectionChange = (codeType: CodeTypes) => {
    if (bulkInput !== '' || arrayOfCodes.length > 0) {
      if (
        window.confirm(
          'You will lose all input data. Are you sure you want to continue?'
        )
      ) {
        setSelectedCodeType(codeType);
        onCodeChange(codeType);
        clearAllData();
      }
    } else {
      setSelectedCodeType(codeType);
      onCodeChange(codeType);
      clearAllData();
    }
  };

  const clearAllData = () => {
    setArrayOfCodes([]);
    onListChange([]);
    setManualCode('');
    setBulkInput('');
    setManualQty('');
    setSearchValue('');
    setManualCodeError(undefined);
    setBulkError(undefined);

    setIsDirty(false);
  };

  const handleOnChangeManualQty = (value: string) => {
    // Quantity can only be number.

    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setIsDirty(true);
      setManualQty(value);
    }
  };

  const filteredCodeArray = useMemo(
    () => arrayOfCodes.filter((el) => el.value.includes(searchValue)),
    [arrayOfCodes, searchValue]
  );

  return (
    <CodeFormWrapper>
      <RadioButtonsWrapper>
        <RadioGroup row>
          {(Object.keys(CodeTypes) as Array<keyof typeof CodeTypes>).map(
            (code) => (
              <FormControlLabel
                key={`radio_btn_${code}`}
                control={
                  <Radio
                    checked={selectedCodeType === CodeTypes[code]}
                    color={'info'}
                    onClick={() => {
                      handleSelectionChange(CodeTypes[code]);
                      setIsDirty(true);
                    }}
                  />
                }
                label={CodeTypes[code]}
              />
            )
          )}
        </RadioGroup>
      </RadioButtonsWrapper>
      <ExcelBulkInput
        variant={'outlined'}
        label={'Bulk paste from Excel'}
        multiline
        rows={4}
        onChange={(e) => {
          setBulkInput(e.target.value);
          setBulkError(undefined);
          setIsDirty(true);
        }}
        helperText={bulkError ? bulkError : ''}
        error={!!bulkError}
        value={bulkInput}
      />
      <ButtonWithIconWrapper>
        <Button
          variant={'outlined'}
          startIcon={<Add />}
          color={'info'}
          style={{ fontSize: `${tyrioUI.fontSize.smallText}px` }}
          onClick={handleAddToListBulk}
        >
          Add to list
        </Button>
      </ButtonWithIconWrapper>
      <Divider sx={{ marginTop: 2 }} textAlign={'left'}>
        <DividerLabel>or add manual</DividerLabel>
      </Divider>
      <ManualAddTextFieldsWrapper>
        <ManualAddTextField
          variant={'standard'}
          label={selectedCodeType}
          style={{ width: '70%', marginRight: `${tyrioUI.spacing.l}px` }}
          value={manualCode}
          helperText={manualCodeError ? manualCodeError : ''}
          error={!!manualCodeError}
          onChange={(val) => {
            setIsDirty(true);
            setManualCodeError(undefined);
            setManualCode(val.target.value);
          }}
        />
        <TextField
          variant={'standard'}
          label={'Qty'}
          value={manualQty}
          type={'number'}
          onChange={(val) => {
            handleOnChangeManualQty(val.target.value);
            setIsDirty(true);
          }}
        />
      </ManualAddTextFieldsWrapper>
      <ButtonWithIconWrapper>
        <Button
          variant={'outlined'}
          startIcon={<Add />}
          color={'info'}
          style={{ fontSize: `${tyrioUI.fontSize.smallText}px` }}
          onClick={handleAddManuallyCodeWithQty}
        >
          Add to list
        </Button>
      </ButtonWithIconWrapper>
      <ListWithSearchWrapper>
        <TextField
          variant={'standard'}
          label={'Search'}
          sx={{ marginLeft: `${tyrioUI.spacing.l}px` }}
          value={searchValue}
          onChange={(event) => {
            setSearchValue(event.target.value);
            setIsDirty(true);
          }}
        />

        <List
          sx={{
            margin: `0px ${tyrioUI.spacing.m}px`,
            maxHeight: '520px',
            overflow: 'scroll',
          }}
        >
          <FixedSizeList
            height={520}
            width={'100%'}
            itemCount={filteredCodeArray.length}
            itemSize={50}
          >
            {({ index, style }) => {
              const elem = filteredCodeArray[index];
              return (
                <ListItem
                  style={style}
                  key={`${elem.value}_${index}_${elem.qty}`}
                  sx={{
                    backgroundColor: `${tyrioUI.colors.lightGrey}`,
                    marginBottom: `${tyrioUI.spacing.m}px`,
                    height: '42px',
                    padding: `${tyrioUI.spacing.m}px`,
                    '&:focus-within': {
                      backgroundColor: '#E4F5FE',
                    },
                  }}
                >
                  <TextField
                    defaultValue={elem.value}
                    variant={'standard'}
                    sx={{ marginRight: `${tyrioUI.spacing.m}px`, flex: 1 }}
                    onChange={(e) => {
                      const newState = _.cloneDeep(arrayOfCodes);
                      newState[index].value = e.target.value;
                      setArrayOfCodes(newState);
                      onListChange(newState);
                      setIsDirty(true);
                    }}
                  />
                  <TextField
                    defaultValue={elem.qty}
                    variant={'standard'}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'right' },
                      },
                    }}
                    onChange={(e) => {
                      const newState = _.cloneDeep(arrayOfCodes);
                      newState[index].qty = parseInt(e.target.value);
                      setArrayOfCodes(newState);
                      onListChange(newState);
                      setIsDirty(true);
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      const firstPart = arrayOfCodes.slice(0, index);
                      const secondPart = arrayOfCodes.slice(
                        index + 1,
                        arrayOfCodes.length
                      );
                      setArrayOfCodes([...firstPart, ...secondPart]);
                      onListChange([...firstPart, ...secondPart]);
                      setIsDirty(true);
                    }}
                    sx={{ '&:hover': { color: 'red' } }}
                  >
                    <Cancel />
                  </IconButton>
                </ListItem>
              );
            }}
          </FixedSizeList>
        </List>
        <ButtonWithIconWrapper style={{ padding: `${tyrioUI.spacing.m}px` }}>
          <Button
            variant={'outlined'}
            startIcon={<Cancel />}
            color={'error'}
            sx={{ fontSize: `${tyrioUI.fontSize.smallText}px` }}
            onClick={() => {
              setArrayOfCodes([]);
              onListChange([]);
              setIsDirty(true);
            }}
          >
            Delete all
          </Button>
        </ButtonWithIconWrapper>
      </ListWithSearchWrapper>
    </CodeFormWrapper>
  );
};
const CodeFormWrapper = styled.div`
  padding-left: ${tyrioUI.spacing.l}px;
  padding-right: 60px;
  border-right: 1px solid ${tyrioUI.colors.black.B40};
  width: 100%;
`;

const RadioButtonsWrapper = styled(FormControl)`
  width: 100%;
`;

const ExcelBulkInput = styled(TextField)`
  margin-top: ${tyrioUI.spacing.l}px;
  height: 120px;
  flex-direction: unset;
  width: 100%;
  display: grid;
`;

const ManualAddTextField = styled(TextField)`
  margin-right: ${tyrioUI.spacing.m}px;
`;

const ManualAddTextFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DividerLabel = styled.label`
  color: ${tyrioUI.colors.darkGrey};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const ListWithSearchWrapper = styled.div`
  border: 1px solid ${tyrioUI.colors.black.B40};
  border-radius: ${tyrioUI.borderRadius.m}px;
  margin-top: ${tyrioUI.spacing.xl}px;
`;

const ButtonWithIconWrapper = styled.div`
  width: 100%;
  margin-top: ${tyrioUI.spacing.l}px;
  display: flex;
  flex-direction: row-reverse;
`;

export default CodeTypeComparisonForm;
