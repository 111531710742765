import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function getGumaxQty(input: RawImport, _rule: TRule) {
  const qty = Number(input['zaliha'].substring(0, 2).trim());

  return qty;
}

export function getGumaxShippingDate(input: RawImport, _rule: TRule) {
  const dateMatch = input['zaliha'].match(/kom\s*(.*)$/);

  // If there's no match or it's empty, return an empty string
  if (!dateMatch || !dateMatch[1].trim()) {
    return '';
  }

  // Split the extracted date portion into day, month, and year parts
  const dateParts = dateMatch[1].trim().split('.');

  if (dateParts.length === 3) {
    // Extract day, month, and year and pad them if necessary
    const day = dateParts[0].padStart(2, '0');
    const month = dateParts[1].padStart(2, '0');
    const year = dateParts[2];

    // Return the formatted date in YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }

  // If parsing fails, return an empty string
  return '';
}

export function getGumaxModelName(input: RawImport, _rule: TRule) {
  // Create a regex pattern dynamically using the brand and width values
  const pattern = new RegExp(`${input['brand']}\\s+(.*?)\\s+${input['width']}`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress TypeScript error for implicit 'any' type in index expression
  const match = input['name']?.['$'].match(pattern);

  // If a match is found, return the extracted model name (second group)
  return match ? match[1].trim() : '';
}

export function globalGetGumaxLoadSpeedIndex(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetGumaxLoadIndex requires rule to be of type TOperateRule'
    );

  let loadIndex1 = '';
  let loadIndex2 = '';
  let speedIndex1 = '';
  let speedIndex2 = '';

  // Build a pattern dynamically to match R + inch (with optional C suffix)
  const pattern = new RegExp(`R${input['inch']}C?\\s+([^\\s\\]]+)`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress TypeScript error for implicit 'any' type in index expression
  const match = input['name']?.['$'].match(pattern);

  if (match) {
    // Extract the combined load and speed index as a single string
    const loadSpeedIndex = match[1] || '';

    // Check if loadSpeedIndex contains dual indices (indicated by a `/`)
    if (loadSpeedIndex.includes('/')) {
      // Split the dual indices into load and speed parts
      const [primary, secondary] = loadSpeedIndex.split('/');

      // Extract primary load and speed index
      loadIndex1 = primary.replace(/[^0-9]/g, '');
      speedIndex1 = primary.replace(/[0-9]/g, '');

      // Extract secondary load and speed index
      loadIndex2 = secondary.replace(/[^0-9]/g, '');
      speedIndex2 = secondary.replace(/[0-9]/g, '');
    } else {
      // Single load and speed index, assign to loadIndex1 and speedIndex1
      loadIndex1 = loadSpeedIndex.replace(/[^0-9]/g, '');
      speedIndex1 = loadSpeedIndex.replace(/[0-9]/g, '');
    }
  }

  if (speedIndex1 === '' && speedIndex2 !== '') {
    speedIndex1 = speedIndex2;
    speedIndex2 = '';
  }

  const data = {
    product: {
      loadIndex: loadIndex1,
      loadIndex2,
      speedIndex: speedIndex1,
      speedIndex2
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}

export function getGumaxSpecialMarkings(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData,
): Partial<UnfinishedData> {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'getGumaxSpecialMarkings requires rule to be of type TGlobalOperateRule'
    );

  const indexPattern = data?.product['loadIndex2']
    ? `${data?.product['loadIndex']}/${data?.product['loadIndex2']}${data?.product['speedIndex']}`
    : `${data?.product['loadIndex']}${data?.product['speedIndex']}`;

  // Create a pattern to match everything after the indexPattern, up to "DOT" if present
  const pattern = new RegExp(`${indexPattern}\\s*(.*?)(\\s*DOT.*)?$`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress TypeScript error for implicit 'any' type in index expression
  const match = input['name']?.['$'].match(pattern);

  // If there's a match, return the special markings; otherwise, return an empty string
  const cleanInput = match ? match[1].trim() : '';

  return genericGenerateSpecialMarkings(cleanInput, () => false, false);
}

export function getGumaxDOT(input: RawImport, _rule: TRule) {
  const dotPattern = /DOT(\w+)/;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress TypeScript error for implicit 'any' type in index expression
  const match = input['name']?.['$'].match(dotPattern);

  if (match) {
    // Extract the characters after "DOT"
    const dotInfo = match[1];

    // Check length and adjust if needed
    if (dotInfo.length === 4) {
      return dotInfo;
    } else if (dotInfo.length === 2) {
      return `XX${dotInfo}`;
    }

    return ''
  }

  // Return an empty string if "DOT" is not found or if there's no valid match
  return '';
}