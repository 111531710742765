import { PaymentTypeEnum } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBPaymentTypesApi } from '@tyrio/dto';
import { isEmpty, sum } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { usePosCartCtx } from '../../../../../../context/PosCartContext';
import { POSContext } from '../../../../../../context/POSContext';
import { PaymentFormWrapper } from '../../../../styles/PaymentForm.style';
import { useCalculatePrice } from '../../helpers/calculation';
import { usePosCartData } from '../../helpers/cart-data';
import { PaymentCard } from './PaymentCard';

interface PaymentFormProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

export const PaymentForm = ({ setIsDirty }: PaymentFormProps) => {
  const { paymentDetails, setPaymentDetails, setInput, input, deliveryPrice } =
    usePosCartCtx();
  const { selectedWarehouseId, isVatIncluded, selectedCustomer } =
    useContext(POSContext);
  const { modalData } = usePosCartData();
  const { grandTotal } = useCalculatePrice(
    modalData,
    deliveryPrice,
    isVatIncluded,
    selectedCustomer?.rebateCalculation?.rebate ?? 0,
    selectedCustomer?.rebateCalculation?.discount ?? 0
  );

  const [shouldDisableFields, setShouldDisableFields] = useState(
    input.shouldDisableFields ?? false
  );

  const { data } = useQuery(
    ['get_payments_by_branch', selectedWarehouseId?.toString()],
    async () => {
      return await api.fetch<DBPaymentTypesApi['list']>(
        'get_payments_by_branch',
        {
          branchId: selectedWarehouseId?.toString(),
        }
      );
    }
  );

  const handleClick = (
    isChecked: boolean,
    title: string,
    type: PaymentTypeEnum | null,
    index: number,
    paymentCode: string
  ) => {
    setIsDirty(true);
    // we can combine all payment methods except bank transfer
    if (type === 'BANK_TRANSFER' && isChecked) {
      setPaymentDetails([]);
      setPaymentDetails([
        { selectedMethod: title, amount: grandTotal, code: paymentCode },
      ]);
      setShouldDisableFields(true);
      setInput((prevState) => ({
        ...prevState,
        shouldDisableFields: true,
      }));
    } else {
      setShouldDisableFields(false);
      setInput((prevState) => ({
        ...prevState,
        shouldDisableFields: false,
      }));
      if (isChecked) {
        let amount = 0;
        if (isEmpty(paymentDetails)) amount = grandTotal;

        // Add the selected payment method to the paymentDetails array
        setPaymentDetails([
          ...paymentDetails,
          { selectedMethod: title, amount, code: paymentCode },
        ]);
      } else {
        if (paymentDetails.length === 2)
          index === 0
            ? (paymentDetails[1].amount = grandTotal)
            : (paymentDetails[0].amount = grandTotal);
        // Remove the selected payment method from the paymentDetails array
        setPaymentDetails(
          paymentDetails.filter((method) => method.selectedMethod !== title)
        );
      }
    }
  };

  const changeAmount = useCallback(
    (id: string, value: string) => {
      setIsDirty(true);

      const secondSelected = paymentDetails.find(
        (selectedPayment) => selectedPayment.selectedMethod === id
      );

      if (secondSelected) {
        if (value === '') secondSelected.amount = 0;
        else if (!isNaN(parseFloat(value))) {
          secondSelected.amount = Number(parseFloat(value).toFixed(2)) ?? 0;
        }
      }

      const firstSelected = paymentDetails.find(
        (selectedPayment) => selectedPayment.selectedMethod !== id
      );

      if (
        firstSelected &&
        secondSelected &&
        paymentDetails.length === 2 &&
        secondSelected.amount > 0
      ) {
        const summ = sum(
          paymentDetails
            .filter((method) => method.selectedMethod !== id)
            .map((a) => a.amount)
        );

        if (grandTotal - summ >= 0 && !isNaN(parseFloat(value))) {
          firstSelected.amount = grandTotal - parseFloat(value);
          if (firstSelected && firstSelected?.amount < 0)
            firstSelected.amount = 0;
        }
      } else if (
        firstSelected &&
        (paymentDetails.length === 1 || secondSelected?.amount === 0)
      )
        firstSelected.amount = grandTotal;

      setPaymentDetails([...paymentDetails]);
    },
    [paymentDetails, setIsDirty, setPaymentDetails, grandTotal]
  );

  const isSelected = (title: string) => {
    return paymentDetails.some((method) => method.selectedMethod === title);
  };

  const findPaymentMethod = (title: string, isEqual: boolean) => {
    return paymentDetails.find((f) => {
      if (isEqual) return f.selectedMethod === title;
      else return f.selectedMethod !== title;
    });
  };

  return (
    <PaymentFormWrapper>
      {data &&
        data?.data &&
        data?.data.length > 0 &&
        data?.data.map((p, index) => (
          <PaymentCard
            key={p.code}
            paymentType={p.paymentType}
            paymentName={p.name}
            paymentCode={p.code}
            onClick={handleClick}
            selected={isSelected(p.name)}
            disableCheckbox={
              p.paymentType === 'BANK_TRANSFER' && isSelected(p.name)
                ? false
                : shouldDisableFields
            }
            index={index}
            value={Number(
              findPaymentMethod(p.name, true)?.amount.toFixed(2) ?? 0
            )}
            changeAmount={changeAmount}
          />
        ))}
    </PaymentFormWrapper>
  );
};
