import { ClientShippingResponse } from '@tyrio/dto';

export const initialValues = (data?: ClientShippingResponse) => {
  if (data !== undefined) {
    return {
      clientNumber: data.clientNumber,
      username: data.username,
      password: data.password,
    };
  } else
    return {
      clientNumber: '',
      username: '',
      password: '',
    };
};
