import api from '@tyrio/api-factory';
import {
  DBFittingBoxSettingsApi,
  FittingBoxesRes,
  Resource,
  WorkingTime,
} from '@tyrio/dto';
import _ from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../context/AuthContext';
import getMinAndMaxHour from '../../hooks/get-min-and-max-hour';

export const useGetFittingBoxSettings = () => {
  const [resources, setResources] = useState<Record<string, Resource>>({});
  const [branchName, setBranchName] = useState('');
  const [minMaxHour, setMinMaxHour] = useState({ min: 0, max: 0 });
  const { user } = useAuth();

  const branchId = _.toString(user?.currentBranchId);

  const query = useQuery(
    [branchId],
    () =>
      api.fetch<DBFittingBoxSettingsApi['getMany']>(
        'get_fitting_box_settings',
        {
          branchId,
        }
      ),
    {
      enabled: !!branchId,
      onSuccess: (d: FittingBoxesRes) => {
        const tempResources: Record<string, Resource> = {};

        d.data
          .sort((a, b) => {
            const numA = parseInt(
              (a.shelf.meta.subzoneName as string).replace('BOX ', '')
            );
            const numB = parseInt(
              (b.shelf.meta.subzoneName as string).replace('BOX ', '')
            );

            return numA - numB;
          })
          .forEach((item) => {
            tempResources[item.id] = {
              id: item.id,
              shelfId: item.shelfId,
              title: item.shelf.meta.subzoneName as string,
              description: item.description,
              serviceCategories: item.serviceCategories.map((item) => item.id),
              workingTime: item.workingTime as unknown as WorkingTime,
              stepper: item.stepper,
              minLength: item.minLength,
              isActive: item.active,
              meta: item.shelf.meta,
            };
          });

        const { minHour, maxHour } = getMinAndMaxHour(
          Object.values(tempResources)
        );
        setMinMaxHour({ min: minHour, max: maxHour });

        setBranchName(d.branchName);

        setResources(tempResources);
      },
    }
  );

  return {
    ...query,
    data: query.data?.data,
    resources,
    minMaxHour,
    branchName,
  };
};
