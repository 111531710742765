import { z } from 'zod';

const FixedPriceSchema = {
  fixedPrice: z.object({
    exclusiveVat: z
      .number({
        required_error: 'This field is required!',
        invalid_type_error: 'Number required!',
      })
      .positive(),
    inclusiveVat: z
      .number({
        required_error: 'This field is required!',
        invalid_type_error: 'Number required!',
      })
      .positive(),
  }),
};

const LaborTimeSchema = {
  chargedByLaborTime: z.object({
    pricePerHour: z.number().positive(),
    laborTime: z.number().positive(),
    finalPrice: z.number().positive(),
  }),
};

const FreeService = {
  freeService: z.object({}),
};

export const ServicePriceSchema = z
  .object(FixedPriceSchema)
  .or(z.object(LaborTimeSchema))
  .or(z.object(FreeService))
  .optional();

export const ValidateSubcategoriesOrRim = z
  .array(z.object({ label: z.string(), value: z.string() }))
  .or(z.array(z.tuple([])))
  .optional();

// SERVICE FORM
export const zodServiceSchema = z.object({
  name: z
    .string({
      required_error: 'This field is required',
    })
    .min(2, { message: 'This field is required' })
    .nonempty(),
  description: z.string().optional(),
  erpId: z.string().optional(),
  servicePrice: ServicePriceSchema,
  radioButton: z.string().optional(),
  serviceDuration: z.number().positive().or(z.nan()).optional(),
  productCategoryId: z.string().optional(),
  subCategoryId: ValidateSubcategoriesOrRim,
  rimDiameter: ValidateSubcategoriesOrRim,
  rft: z.boolean().optional(),
  code: z.string().optional(),
});

// SERVICE CATEGORY FORM
export const zodServiceCategoryObject = z.object({
  name: z
    .string({
      required_error: 'This field is required',
    })
    .min(2, { message: 'This field is required' })
    .nonempty(),
  description: z.string().optional(),
  vatRate: z.string().nonempty(),
  serviceType: z.string(),
  unitOfMeasurment: z
    .string({
      required_error: 'This field is required',
    })
    .min(2, { message: 'This field is required' })
    .nonempty(),
  generalLedgeAcc: z.string(),
  code: z.string().optional(),
});

// FIRST ROW VALIDATION
export const zodCategoryAndCodeSchema = z.object({
  category: z.string({ required_error: 'This field is required' }).nonempty(),
  code: z.string().optional(),
});
