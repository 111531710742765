import styled from '@emotion/styled';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Alert,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { ZoneStagingType } from '@tyrio/dto';
import { Typography } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from './Location';
import { useGetWarehouses } from '../warehouse/query/get-branches';
import { useGetWarehouse } from './queries/get-location';

const WmsLocationDetails = () => {
  const history = useHistory();

  const [selectedWarehouse, setSelectedWarehouse] = useState('');

  const { warehouses, warehouseData } = useGetWarehouses({
    setSelectedWarehouse: setSelectedWarehouse,
  });

  const { getLocationDetails } = useGetWarehouse({ id: selectedWarehouse });

  const [value, setValue] = useState<ZoneStagingType>(
    ZoneStagingType.STATIC_STORAGE
  );

  const data = getLocationDetails.data;

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ZoneStagingType
  ) => {
    setValue(newValue);
  };

  const getLabel = (label: string, color: string) => {
    return (
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid
          sx={{
            width: '10px',
            height: '10px',
            backgroundColor: color,
            borderRadius: '50%',
            marginRight: '7px',
          }}
        ></Grid>
        <Typography>{label}</Typography>
      </Grid>
    );
  };

  const getColor = (att: ZoneStagingType) => {
    if (data && data[att]) return data[att][0].color;

    return 'black';
  };

  const getTextLabel = (att: ZoneStagingType) => {
    switch (att) {
      case ZoneStagingType.STATIC_STORAGE:
        return 'STORAGE';
      case ZoneStagingType.MOBILE_STORAGE:
        return 'MOBILE';
      case ZoneStagingType.ON_HOLD:
        return 'ON HOLD';

      default:
        return att;
    }
  };

  const getProps = (att: ZoneStagingType) => {
    const label = getTextLabel(att);
    const color = getColor(att);
    return {
      value: att,
      label: getLabel(label, color),
      onChange: () => handleChange,
    };
  };

  const getContent = () => {
    return Object.keys(data ?? {})
      .filter((item) => item === value)
      .map((location) => {
        const locationInfo = (data ?? {})[location as ZoneStagingType];

        return (
          <Grid
            key={location}
            sx={{
              maxHeight: '75vh',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              overflow: 'scroll',
              gap: '20px',
              columnGap: '20px',
            }}
          >
            {locationInfo?.map((item) => {
              return (
                <Location
                  key={item.qrCode}
                  type={location as ZoneStagingType}
                  location={item}
                  count={locationInfo.length}
                />
              );
            })}
          </Grid>
        );
      });
  };

  return (
    <Grid>
      <HeaderWrapper>
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <ArkeyBackIosIconStyled onClick={() => history.push('/dashboard')} />
          <HeaderText>Warehouse details</HeaderText>
        </Grid>
        <Grid sx={{ width: '300px' }}>
          {warehouseData.status === 'success' && warehouseData.data && (
            <FormControl fullWidth>
              <InputLabel>Warehouse</InputLabel>
              <Select
                size="small"
                value={selectedWarehouse}
                label="Warehouse"
                onChange={(e) => {
                  setSelectedWarehouse(e.target.value);
                }}
              >
                {warehouses.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </HeaderWrapper>
      <Grid>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.keys(ZoneStagingType).map((key) => (
            <Tab key={key} {...getProps(key as ZoneStagingType)} />
          ))}
        </Tabs>
      </Grid>
      {warehouseData.status === 'loading' && (
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <CircularProgress />
        </Grid>
      )}
      {data && data[value] ? (
        <Grid sx={{ marginTop: '24px' }}>{getContent()}</Grid>
      ) : (
        <Alert severity="warning" sx={{ marginTop: '20px' }}>
          No location info!
        </Alert>
      )}
    </Grid>
  );
};

const HeaderWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;
const HeaderText = styled(Grid)`
  margin-left: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  cursor: default;
`;

const ArkeyBackIosIconStyled = styled(ArrowBackIosIcon)`
  cursor: pointer;
`;

export default WmsLocationDetails;
