import * as z from "zod"
import { DBAppointmentStatus } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBWarehouseShelfs, RelatedDBWarehouseShelfsModel, CompleteDBUser, RelatedDBUserModel, CompleteDBServiceAppointments, RelatedDBServiceAppointmentsModel, CompleteDBWorkOrders, RelatedDBWorkOrdersModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBAppointmentsModel = z.object({
  id: z.string(),
  clientId: z.string(),
  orderId: z.string().nullish(),
  reservedBoxId: z.string(),
  completedBoxId: z.string().nullish(),
  userId: z.string().nullish(),
  createdAt: z.date(),
  createdBy: z.string().nullish(),
  vehicleInfo: jsonSchema,
  lineItems: jsonSchema,
  isActive: z.boolean(),
  status: z.nativeEnum(DBAppointmentStatus),
  remark: z.string(),
  startedTime: z.date().nullish(),
  finishedTime: z.date().nullish(),
  completedServices: z.string().array(),
  appointmentDate: z.date(),
  workOrdersId: z.string().nullish(),
  appointmentDateFrom: z.date().nullish(),
  appointmentDateTo: z.date().nullish(),
  erpId: z.string().nullish(),
})

export interface CompleteDBAppointments extends z.infer<typeof DBAppointmentsModel> {
  client: CompleteDBClient
  order?: CompleteDBCustomerOrderItem | null
  reservedBox: CompleteDBWarehouseShelfs
  completedBox?: CompleteDBWarehouseShelfs | null
  user?: CompleteDBUser | null
  services: CompleteDBServiceAppointments[]
  workOrders?: CompleteDBWorkOrders | null
}

/**
 * RelatedDBAppointmentsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBAppointmentsModel: z.ZodSchema<CompleteDBAppointments> = z.lazy(() => DBAppointmentsModel.extend({
  client: RelatedDBClientModel,
  order: RelatedDBCustomerOrderItemModel.nullish(),
  reservedBox: RelatedDBWarehouseShelfsModel,
  completedBox: RelatedDBWarehouseShelfsModel.nullish(),
  user: RelatedDBUserModel.nullish(),
  services: RelatedDBServiceAppointmentsModel.array(),
  workOrders: RelatedDBWorkOrdersModel.nullish(),
}))
