import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';

interface SelectedSizeProps {
  label: string;
  width: string;
  onReset: () => void;
  addAnotherSize?: () => void;
}

export const SelectedSize = ({ label, width, onReset }: SelectedSizeProps) => {
  return (
    <SelectedSizeValue>
      <SizeNumber>{label}</SizeNumber>
      <SelectedSizeForFilter>{width}</SelectedSizeForFilter>
      <Tooltip title="Reset selected value">
        <ClearSize onClick={onReset}>
          <CancelIcon color="primary" />
        </ClearSize>
      </Tooltip>
    </SelectedSizeValue>
  );
};

const ClearSize = styled.button`
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;
`;

const SizeNumber = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const SelectedSizeForFilter = styled.p`
  color: #212b36;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
`;

const SelectedSizeValue = styled.div`
  width: 100%;
  max-width: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: 16px;
  padding: 12px 16px;
  height: 80px;
  border-radius: 8px;
  border: 1px dashed #dfe3e8;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;
