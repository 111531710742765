import { defaultEnv } from './default.app';
import { AppConfig } from './config.app';

export const environment: AppConfig = {
  ...defaultEnv,
  production: false,
  apiUrl: 'https://d-api.tyrio-api.link/api',
  appUrl: 'https://d-app.tyrio-api.link/',
  wsUrl: 'https://d-api.tyrio-api.link',
};
