import { TRule } from '../../typings';

export const INTERPNEU_STOCKLIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: 'c.ean',
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.manufacturerCode',
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.stock',
    destination: 'product',
    targetKey: 'qty',
    outputType: 'number',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'packageQuantity',
    template: '1',
    outputType: 'number',
  },
];
