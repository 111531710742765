import styled from '@emotion/styled/macro';
import { TextField, Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';

export const AddressCardWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${tyrioUI.colors.black.B40};
  border-radius: 8px;
  // width: 48%;
  padding: 16px;
  gap: ${(props) => (props.disabled ? '2px' : '12px')};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LastEditTypographyHeader = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #919eab;
  cursor: default;
`;

export const LastEditData = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  cursor: default;
  color: '#C4CDD5';
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
  cursor: default;
`;

export const AddressDetails = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  cursor: default;
`;

export const TextFieldStyled = styled(TextField)(
  (props: {
    top?: number;
    customPadding?: string;
    hasValue: boolean;
    fontSize?: string;
    fontWeight?: number;
  }) => ({
    '& .MuiOutlinedInput-input.Mui-disabled': {
      padding: props.customPadding ?? 0,
      fontFamily: 'Barlow',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: props.fontSize ? props.fontSize : '14px',
      padding: props.customPadding ?? '10px 8px',
      fontWeight: props.fontWeight ? props.fontWeight : 400,
      fontFamily: 'Roboto',
    },
    '& .MuiInput-input': {
      fontSize: '14px',
      padding: '0 0 5px 0',
      color: 'rgb(187 24 24 / 38%)',
    },
    '& .MuiInput-root:before': {
      borderColor: '#0000001f',
    },
    '& #companyName': {
      fontWeight: 500,
      fontSize: '16px',
    },

    '& .MuiInputLabel-root': {
      top: props.hasValue ? 0 : props.top ?? 0,
    },

    '& .MuiInputLabel-root.Mui-focused': {
      top: 0,
    },
  })
);

export const StyledText = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: 400;
`;

export const Title = styled(Typography)`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #919eab;
`;
