import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import { DBSupplierClientPaymentSettingsApi } from '@tyrio/dto';
import { useCallback, useState } from 'react';
import {
  TextFieldInput,
  SelectList,
} from '../supplier-form/helpers/components';
import { DBCurrency } from '@prisma/client';
import { parseEnumToArray } from '../../../../helpers/enum-parser';
import { ExpandMore } from '@mui/icons-material';
import { ContactHeader } from '../supplier-form/SupplierAdminForm';

export interface SupplierClientPaymentSettingsProps {
  getData: (
    data: DBSupplierClientPaymentSettingsApi['create']['requestBody']
  ) => void;
  initialData?: Partial<
    DBSupplierClientPaymentSettingsApi['create']['requestBody']
  >;
}

export const SUPPLIER_CLIENT_PAYMENT_SETTINGS_INITIAL_DATA: DBSupplierClientPaymentSettingsApi['create']['requestBody'] =
  {
    supplierIBAN: '',
    supplierSwift: '',
    supplierCurrency: DBCurrency.EUR,
    remark: '',
  };

const SupplierClientPaymentSettings = ({
  getData,
  initialData = {},
}: SupplierClientPaymentSettingsProps) => {
  const [supplierClientPaymentSettings, setSupplierClientPaymentSettingsState] =
    useState<DBSupplierClientPaymentSettingsApi['create']['requestBody']>({
      ...SUPPLIER_CLIENT_PAYMENT_SETTINGS_INITIAL_DATA,
      ...initialData,
    });

  const setSupplierClientPaymentSettings = useCallback(
    (data: DBSupplierClientPaymentSettingsApi['create']['requestBody']) => {
      setSupplierClientPaymentSettingsState(data);
      getData(data);
    },
    [getData]
  );

  return (
    <Accordion
      square
      defaultExpanded
      sx={{
        boxShadow: '0px 1px 0px #DFE3E8',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          paddingLeft: '0px',
        }}
      >
        <ContactHeader>Payment details</ContactHeader>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingLeft: '0px',
        }}
      >
        <Grid spacing={2} container>
          <Grid item xs={12} lg={4}>
            <TextFieldInput
              label="Supplier IBAN"
              value={supplierClientPaymentSettings.supplierIBAN}
              onChange={(e) => {
                setSupplierClientPaymentSettings({
                  ...supplierClientPaymentSettings,
                  ...{ supplierIBAN: e.target.value },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextFieldInput
              label="Supplier SWIFT"
              value={supplierClientPaymentSettings.supplierSwift}
              onChange={(e) => {
                setSupplierClientPaymentSettings({
                  ...supplierClientPaymentSettings,
                  ...{ supplierSwift: e.target.value },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectList
              label="Supplier payment currency"
              value={supplierClientPaymentSettings.supplierCurrency}
              dropdownData={parseEnumToArray(DBCurrency)}
              onChange={(e) => {
                setSupplierClientPaymentSettings({
                  ...supplierClientPaymentSettings,
                  ...{
                    supplierCurrency: e.target.value as DBCurrency,
                  },
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container sx={{ marginTop: '30px' }}>
          <Grid item xs={12} lg={12}>
            <TextFieldInput
              label="Remark"
              value={supplierClientPaymentSettings.remark}
              onChange={(e) => {
                setSupplierClientPaymentSettings({
                  ...supplierClientPaymentSettings,
                  ...{ remark: e.target.value },
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          sx={{ marginTop: '20px' }}
          justifyContent="flex-end"
        ></Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SupplierClientPaymentSettings;
