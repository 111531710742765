// 2.333

export const roundPriceToTwoDecimals = (a: number) => {
  let base = Number(a.toFixed(2));
  // 2.33

  const rest = a - base;
  // 0.003

  if (rest >= 0.005) {
    base += 0.01;
  }

  return base;
};
