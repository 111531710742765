/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@mui/material';
import { ExpandCollapseButtons } from '../../../supplier-form/helpers/components';
import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import { RulesContext } from '../../context/RulesContext';
import { SingleRule } from './SingleRule';
import { v4 as uuid } from 'uuid';
import { PopUp } from '../Dialog';

export const RulesList = () => {
  const [expandedData, setExpandedData] = useState<number[]>([]);

  const { rules, setRules, componentType, setDirty } = useContext(RulesContext);
  const [showPopUp, setShowPopUp] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  // ACTIVATES POPUP AND SETS INDEX OF RULE THAT SHOULD BE DELETED
  const handleRulesDelete = (index: number) => {
    setShowPopUp(true);
    setDeleteIndex(index);
  };

  const handleDelete = (index: number) => {
    rules.splice(index, 1);
    setRules((rules) => {
      return [...rules];
    });
    setDirty(true);
  };

  const swapRules = (index1: number, index2: number) => {
    rules[index1] = rules.splice(index2, 1, rules[index1])[0];
    setRules((rules) => {
      return [...rules];
    });
    setDirty(true);
  };

  useEffect(() => {
    if (deleteAction && deleteIndex !== null) handleDelete(deleteIndex);
  }, [deleteAction]);

  return (
    <>
      {showPopUp && (
        <PopUp
          open={showPopUp}
          setDeleteAction={setDeleteAction}
          setShowPopUp={setShowPopUp}
          text="Are you sure you want to delete this rule?"
        />
      )}
      <RulesWrapper>
        {rules.length > 0 && (
          <ExpandCollapseButtons
            color={expandedData.length > 0 ? 'warning' : 'success'}
            onExpandedDataChange={() =>
              setExpandedData(
                expandedData.length > 0 ? [] : rules.map((a, index) => index)
              )
            }
            flag={expandedData.length > 0}
          />
        )}

        {rules.map((rule, index) => {
          return (
            <SingleRule
              key={rule.name}
              rule={rule}
              index={index}
              expandedData={expandedData}
              setExpandedData={setExpandedData}
              handleRulesDelete={handleRulesDelete}
              swapRules={swapRules}
              componentType={componentType as string}
            />
          );
        })}
      </RulesWrapper>

      <Button
        variant="outlined"
        color="info"
        endIcon={<AddIcon />}
        sx={{ width: '125px', marginTop: '32px' }}
        onClick={() => {
          setRules([
            ...rules,
            {
              actions: [],
              conditions: '',
              name: `Rule #${rules.length + 1}`,
              ruleDescription: '',
              id: uuid(),
            },
          ]);
          setDirty(true);
          setExpandedData([rules.length]);
        }}
      >
        Add Rule
      </Button>
    </>
  );
};

export const RulesWrapper = styled.div`
  margin-top: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
`;
