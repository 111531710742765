import { TRule } from '../../typings';
// import { EDI_WHEEL_B2_PRICELIST_RULESET } from '../edi-b2/pricelist';

export const ZBR_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.EAN",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.Artnr",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Hersteller',
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['EK-Preis']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
