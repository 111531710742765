/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { ComingSoonMini } from '@tyrio/ui-library';
import reward1 from '../../assets/rewards/reward1.png';
import reward2 from '../../assets/rewards/reward2.png';
import reward3 from '../../assets/rewards/reward3.png';

const ProductTests = () => {
  return (
    <Container>
      <ComingSoonMini text={'No test results yet'} />
      {/* <Image src={reward1} alt="reward-image" />
      <Image src={reward2} alt="reward-image" />
      <Image src={reward3} alt="reward-image" /> */}
    </Container>
  );
};

const Container = styled.div``;

// const Image = styled.img`
//   margin-right: 10px;
// `;

export default ProductTests;
