import { DBServiceCategoryCode } from '@prisma/client';

export const serviceCategoryCodes: DBServiceCategoryCode[] = [
  {
    id: '00000000-0000-1000-c100-0000000000001',
    code: 1,
  },
  {
    id: '00000000-0000-1000-c100-0000000000002',
    code: 2,
  },
];
