/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Box, Modal, Typography } from '@mui/material';
import { cartIcons } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import { AddressProps } from '../../../../../../context/PosCartContext';
import { Dispatch, SetStateAction } from 'react';

import { AddressForm } from './AddressForm';

interface IAddressModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  itemIndex: number;
  isNew: boolean;
  newDeliveryAddress: AddressProps[];
  setNewDeliveryAddress: Dispatch<SetStateAction<AddressProps[]>>;
}

export const AddressModal = ({
  open,
  setOpen,
  itemIndex,
  isNew,
  newDeliveryAddress,
  setNewDeliveryAddress,
}: IAddressModal) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '500px',
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
          maxHeight: '800px',
          padding: '24px',
        }}
      >
        <TitleWrapper>
          <WmsIcon
            icon={cartIcons.addressCard}
            color="info"
            fill="none"
            viewBox={'0 0 32 32'}
          />
          <Typography variant="subtitle1" fontSize={18}>
            DELIVERY ADDRESS
          </Typography>
        </TitleWrapper>
        <AddressForm
          setOpen={setOpen}
          itemIndex={itemIndex}
          isNew={isNew}
          newDeliveryAddress={newDeliveryAddress}
          setNewDeliveryAddress={setNewDeliveryAddress}
        />
      </Box>
    </Modal>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-bottom: 18px;
`;
