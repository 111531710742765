import { MenuItem } from '@tyrio/wms-ui-library';
import PopoverButton from '../../../../../../src/components/StepsMenu/PopoverButton';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import MenuPopover from '../../../../../../src/components/StepsMenu/Popover';
import { ActiveStep } from '../../../../../context/StockListContext';
import { useCartData } from '../../../../../features/stock-list/components/Cart/helper/cart-helper';
import { Countdown } from '../../../../../components/Timer/Countdown';
import { MenuList } from './constants/MenuList';
import { MenuWrapper } from '../../../../../../src/components/StepsMenu/style/Menu.style';
import { CartContext } from '../../../../../context/CartContext';

interface MenuProps {
  shouldDisableMenuItems: boolean;
  shouldEnlarge: boolean;
  setShouldEnlarge: Dispatch<SetStateAction<boolean>>;
  activeStep: ActiveStep;
  setActiveStep: Dispatch<SetStateAction<ActiveStep>>;
}

export const Menu = ({
  shouldDisableMenuItems,
  shouldEnlarge,
  setShouldEnlarge,
  activeStep,
  setActiveStep,
}: MenuProps) => {
  const [isMenuPopupVisible, setIsMenuPopupVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { timer } = useContext(CartContext);

  const handleClick = (m: ActiveStep, isDisabled: boolean) => {
    if (!isDisabled) {
      if (m.includes('PASTE')) setActiveStep('PASTE_LIST');
      else setActiveStep(m);
    }
  };

  const { hasItems, numberOfCartItems } = useCartData();
  const displayTime = timer?.getDisplayTime();
  const { minutes, seconds } = displayTime ?? { minutes: 0, seconds: 0 };

  return (
    <MenuWrapper>
      {MenuList.map((m, index) => {
        const shouldDisable = !shouldDisableMenuItems
          ? m.disabled
          : m.text === 'CART' && hasItems
          ? false
          : m.text !== 'SEARCH' && shouldDisableMenuItems;

        return (
          <MenuItem
            icon={m.icon}
            text={m.text}
            status={
              m.text === activeStep ||
              (m.text === 'PASTE LIST' && activeStep === 'PASTE_LIST')
                ? 'HOVER'
                : 'DEFAULT'
            }
            disabled={shouldDisable}
            onClick={() => handleClick(m.text as ActiveStep, shouldDisable)}
            isBadgeInvisible={m.text === 'CART' ? false : true}
            key={index}
            activeStep={activeStep}
            badgeContent={numberOfCartItems}
            componentType="STOCK"
          />
        );
      })}
      {hasItems && (
        <Countdown cart={false} minutes={minutes} seconds={seconds} />
      )}

      <PopoverButton
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        setIsMenuPopupVisible={setIsMenuPopupVisible}
      />

      <MenuPopover
        isMenuPopupVisible={isMenuPopupVisible}
        setIsMenuPopupVisible={setIsMenuPopupVisible}
        shouldEnlarge={shouldEnlarge}
        setShouldEnlarge={setShouldEnlarge}
      />
    </MenuWrapper>
  );
};
