import styled from '@emotion/styled';
import TextField from '@mui/material/TextField/TextField';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box/Box';
import Grid from '@mui/material/Grid/Grid';
import { Button } from '@mui/material';
import { useState } from 'react';
interface CardProps {
  title: string;
  source: string;
  type: string;
}

const Card = ({ title, source, type }: CardProps) => {
  const [bookmark, setBookmark] = useState(false);
  return (
    <Grid item xs={4}>
      <Container>
        <ImageContainer>
          <Image src={source} />
        </ImageContainer>
        <TitleContainer>
          <TextField
            label="Image Title"
            variant="outlined"
            style={{ width: '260px' }}
            defaultValue={title}
          />
        </TitleContainer>
        <SourceContainer>
          <Box style={{ display: 'flex' }}>
            Image source:<ImageSource>{type}</ImageSource>
          </Box>
          <Footer>
            <Button onClick={() => setBookmark(!bookmark)}>
              {bookmark ? (
                <BookmarkIcon color="info" />
              ) : (
                <BookmarkBorderOutlinedIcon color="info" />
              )}
            </Button>
            <Button>
              <DeleteOutlineOutlinedIcon color="error" />
            </Button>
          </Footer>
        </SourceContainer>
      </Container>
    </Grid>
  );
};

const Container = styled.div`
  height: 420px;
  border-radius: 8px;
  border: 1px solid #e6e8f0;
`;

const ImageContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  height: 262px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e6e8f0;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e6e8f0;
  padding: 8px;
`;

const Image = styled.img`
  height: 100%;
`;

const SourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  padding: 10px;
`;

const ImageSource = styled.p`
  color: #919eab;
  margin: 0;
  margin-left: 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Card;
