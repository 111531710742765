import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { Code, ShelfType } from '@prisma/client';
import {
  WarehouseBranchResponse,
  WarehouseSettings,
  ZoneStagingType,
  ZoneType,
} from '@tyrio/dto';
import _ from 'lodash';
import { MuiColorInput } from 'mui-color-input';
import { useContext, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import { WarehouseContext } from '../../../context/WarehouseContext';
import Location from '../components/location/Location';
import LocationName from '../components/location/LocationName';
import MinimalLocation from '../components/location/MininalLocation';
import HorizontalSticker from '../components/sticker/HorizontalSticker';
import {
  paperSizeValues,
  PrintModeType,
  printModeValues,
  PrintSize,
} from '../components/sticker/PrintSticker';
import VerticalSticker from '../components/sticker/VerticalSticker';
import { useCheckCurrent } from '../helper/check-current';
import {
  format,
  getCurrent,
  getDirection,
  getReactToPrintPropsForA3,
  getReactToPrintPropsForZebra,
  replaceFromStart,
} from '../helper/helper';
import { CustomObject, useWarehouseHelper } from '../helper/warehouse-helper';
import { useGetCategories } from '../query/get-categories';
import { useUpdateWarehouse } from '../query/update-warehouse';
import { Container } from './style/styled';

type WarehouseValueType = string | boolean | number | string[];

export const codes = parseEnumToArray(Code);
const subzoneTypes = parseEnumToArray(ZoneType);
const stagingTypes = parseEnumToArray(ZoneStagingType);
const storageTypes = parseEnumToArray(ShelfType);
const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({
  value: item,
  label: item,
}));

export const Details = () => {
  const {
    selected,
    warehouse,
    setSelected,
    setWarehouse,
    req,
    setReq,
    setIsNewModalOpen,
  } = useContext(WarehouseContext);
  const [printMode, setPrintMode] = useState<PrintModeType>('horizontal');
  const [paperSize, setPaperSize] = useState<PrintSize>('a3');

  const componentRef = useRef<HTMLDivElement>(null);

  const getCurrentLocation = () => {
    if (!selected.locations.subzone) {
      if (selected.locations.zone?.code === 'X') return 'FITTING';

      return `ZONE ${getCurrent(selected, 'displayName')}`;
    }

    return `${getCurrent(selected, 'displayName')}`;
  };

  const last = () => {
    return _.last(selected.current);
  };

  const getCurrentLocationLabel = () => {
    if (!selected.locations.subzone) return 'ZONE';

    return `${getCurrent(selected, 'type').toUpperCase()} ${getCurrent(
      selected,
      'code'
    )}`;
  };

  const getWidth = () => {
    let index = 1;
    if (last() === 'rack' || last() === 'shelf') index = 2;

    if (last() === 'bin' && selected.locations.bin) return 1.714;

    return 12 / (selected.current.length - index);
  };

  const _path = (att: string) => {
    return ['description'].includes(att) ? att : `settings.${att}`;
  };

  const _set = (
    location: string,
    att: string,
    val: WarehouseValueType,
    itemId?: string
  ) => {
    const arr = _.get(warehouse, `${location}s`);

    let id = _.get(selected.locations, `${location}.id`);

    if (itemId !== undefined) id = itemId;

    const idx = _.findIndex(
      arr,
      (e: CustomObject) => {
        return e.id === id;
      },
      0
    );

    if (_.isUndefined(idx)) return;

    const items = [...arr];
    const item = { ...items[idx] };
    items[idx] = item;

    _.set(item, _path(att), val);

    !['floor', 'section'].includes(location) &&
      setSelected({
        ...selected,
        locations: {
          ...selected?.locations,
          [location]: item,
        },
      });

    setWarehouse({
      ...warehouse,
      [`${location}s`]: items,
    } as WarehouseBranchResponse);

    const reqUpdate = _.get(req?.update, `${location}s`);

    const isIncluded = _.includes(reqUpdate, item.id);

    if (
      !(item.id as string).startsWith(`${location.toUpperCase()}`) &&
      !isIncluded
    ) {
      setReq({
        ...req,
        update: {
          ...req?.update,
          [`${location}s`]: [...reqUpdate, item.id],
        },
      });
    }
  };

  const {
    initFloor,
    initRack,
    initSection,
    initShelf,
    initBin,
    _setWarehouse,
    _isNewLocation,
    _delete,
  } = useWarehouseHelper();

  const { categories } = useGetCategories();

  const { updateStatus } = useUpdateWarehouse();

  const id = getCurrent(selected, 'id');

  const { isZone, isSubzone, isRack, isShelf } = useCheckCurrent();

  const reactToPrint =
    paperSize === 'a3'
      ? getReactToPrintPropsForA3(componentRef, printMode)
      : getReactToPrintPropsForZebra(componentRef);

  return (
    <Container>
      <Grid>
        {!selected.locations.zone && (
          <Alert severity="info">
            <AlertTitle>No selected location</AlertTitle>
            Please select zone to see location details
          </Alert>
        )}
        {selected.locations.zone && (
          <Grid>
            <Location
              location={replaceFromStart(
                getCurrent(selected, 'displayName'),
                getCurrent(selected, 'displayName').length
              )}
            />
            <Grid
              sx={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <MinimalLocation
                location={getCurrentLocation()}
                stagingType={getCurrentLocationLabel()}
                color={getCurrent(selected, 'color')}
              />
              <FormControlLabel
                control={
                  <Switch
                    color="success"
                    checked={getCurrent(selected, 'isActive')}
                    onChange={(e) => {
                      const val = e.target.checked;

                      if (last() === 'subzone' && selected.locations.zone) {
                        _set('zone', 'isActive', val);
                      }

                      if (last() === 'rack' && selected.locations.subzone) {
                        _set('subzone', 'isActive', val);
                      }

                      if (last() === 'shelf' && selected.locations.rack) {
                        _set('rack', 'isActive', val);
                      }

                      if (last() === 'bin' && selected.locations.shelf) {
                        _set('shelf', 'isActive', val);
                      }

                      if (last() === 'bin' && selected.locations.bin) {
                        _set('bin', 'isActive', val);
                      }
                    }}
                  />
                }
                label={'Active'}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              sx={{
                marginTop: '20px',
                display: 'flex',
              }}
            >
              <Grid item xs={getWidth()}>
                <FormControl fullWidth disabled>
                  <InputLabel>Zone</InputLabel>
                  <Select label="Zone" value={selected.locations.zone.code}>
                    {codes.map((item) => (
                      <MenuItem value={item.value}>{item.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {selected.locations.subzone && (
                <Grid item xs={getWidth()}>
                  <FormControl fullWidth disabled>
                    <InputLabel>Subzone</InputLabel>
                    <Select
                      label="Subzone"
                      value={selected.locations.subzone.code}
                    >
                      {codes.map((item) => (
                        <MenuItem value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {selected.locations.floor && (
                <Grid item xs={getWidth()}>
                  <FormControl fullWidth disabled>
                    <InputLabel>Floor</InputLabel>
                    <Select label="Floor" value={selected.locations.floor.code}>
                      {numbers.map((item) => (
                        <MenuItem value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {selected.locations.rack && (
                <Grid item xs={getWidth()}>
                  <FormControl fullWidth disabled>
                    <InputLabel>Rack</InputLabel>
                    <Select label="Rack" value={selected.locations.rack.code}>
                      {codes.map((item) => (
                        <MenuItem value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {selected.locations.section && (
                <Grid item xs={getWidth()}>
                  <FormControl fullWidth disabled>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      value={selected.locations.section.code}
                    >
                      {numbers.map((item) => (
                        <MenuItem value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {selected.locations.shelf && (
                <Grid item xs={getWidth()}>
                  <FormControl fullWidth disabled>
                    <InputLabel>Shelf</InputLabel>
                    <Select label="Shelf" value={selected.locations.shelf.code}>
                      {numbers.map((item) => (
                        <MenuItem value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {selected.locations.bin && (
                <Grid item xs={getWidth()}>
                  <FormControl fullWidth disabled>
                    <InputLabel>Bin</InputLabel>
                    <Select label="Bin" value={selected.locations.bin.code}>
                      {codes.map((item) => (
                        <MenuItem value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            {isZone && (
              <Grid sx={{ marginTop: '10px' }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MuiColorInput
                      format="hex"
                      disabled={!selected.locations.zone.id.startsWith('ZONE')}
                      value={
                        (selected.locations.zone.settings as WarehouseSettings)
                          .color as string
                      }
                      onChange={(v) => {
                        _set('zone', 'color', v);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      value={selected.locations.zone.description}
                      onChange={(v) => {
                        _set('zone', 'description', v.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="text"
                      color="info"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setIsNewModalOpen(true);
                      }}
                    >
                      ADD SUBZONE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isSubzone && (
              <Grid sx={{ marginTop: '10px' }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Type</InputLabel>
                      <Select
                        label="Type"
                        disabled={
                          !selected.locations.subzone?.id.startsWith('SUBZONE')
                        }
                        value={
                          (
                            selected.locations.subzone
                              ?.settings as WarehouseSettings
                          ).type as string
                        }
                        onChange={(e) => {
                          _set('subzone', 'type', e.target.value);
                        }}
                      >
                        {subzoneTypes.map((item) => (
                          <MenuItem value={item.value}>{item.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Staging type</InputLabel>
                      <Select
                        label="Staging type"
                        disabled={
                          !selected.locations.subzone?.id.startsWith('SUBZONE')
                        }
                        value={
                          (
                            selected.locations.subzone
                              ?.settings as WarehouseSettings
                          ).stagingType as string
                        }
                        onChange={(e) => {
                          _set('subzone', 'stagingType', e.target.value);
                        }}
                      >
                        {stagingTypes.map((item) => (
                          <MenuItem value={item.value}>
                            {format(item.value as string)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      value={selected.locations.subzone?.description}
                      onChange={(e) => {
                        _set('subzone', 'description', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid sx={{ marginTop: '20px' }}>
                  {warehouse?.floors
                    ?.filter(
                      (item) => item.subzoneId === getCurrent(selected, 'id')
                    )
                    .map((item) => (
                      <Grid
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                          }}
                        >
                          <LocationName
                            title={`FLOOR ${item.code}`}
                            subtitle={`${item.displayName}`}
                            color={getCurrent(selected, 'color')}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                color="success"
                                checked={
                                  (item?.settings as WarehouseSettings)
                                    .isActive as boolean
                                }
                                onChange={(e) => {
                                  _set(
                                    'floor',
                                    'isActive',
                                    e.target.checked,
                                    item.id
                                  );
                                }}
                              />
                            }
                            label={'Active'}
                          />
                        </Grid>
                        <TextField
                          label="Description"
                          variant="outlined"
                          value={item?.description}
                          onChange={(e) => {
                            _set('floor', 'description', e.target.value, id);
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
                <Button
                  variant="text"
                  color="info"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    const { floor, updated } = initFloor();

                    const { rack } = initRack(floor, updated);

                    _setWarehouse({ floor, rack }, updated);
                  }}
                >
                  ADD RACK
                </Button>
              </Grid>
            )}
            {isRack && (
              <Grid sx={{ marginTop: '10px' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        label="Category"
                        onChange={(e) => {
                          _set('rack', 'productCategory', e.target.value);
                        }}
                        multiple
                        input={<OutlinedInput label="Category" />}
                        value={
                          (
                            selected.locations.rack
                              ?.settings as WarehouseSettings
                          ).productCategory as string[]
                        }
                        renderValue={(selected: string[]) =>
                          selected.join(', ')
                        }
                      >
                        {categories.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox
                              checked={(
                                (
                                  selected.locations.rack
                                    ?.settings as WarehouseSettings
                                ).productCategory as string[]
                              ).includes(item.value as string)}
                            />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      value={selected.locations.rack?.description}
                      onChange={(e) => {
                        _set('rack', 'description', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          color="info"
                          onChange={(e) => {
                            _set('rack', 'isRecommended', e.target.checked);
                          }}
                          checked={
                            (
                              selected.locations.rack
                                ?.settings as WarehouseSettings
                            ).isRecommended as boolean
                          }
                        />
                      }
                      label={'Recommended'}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="info"
                          checked={
                            (
                              selected.locations.rack
                                ?.settings as WarehouseSettings
                            ).isHotel as boolean
                          }
                          onChange={(e) => {
                            _set('rack', 'isHotel', e.target.checked);
                          }}
                        />
                      }
                      label={'Hotel'}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="info"
                          checked={
                            (
                              selected.locations.rack
                                ?.settings as WarehouseSettings
                            ).mixedSKU as boolean
                          }
                          onChange={(e) => {
                            _set('rack', 'mixedSKU', e.target.checked);
                          }}
                        />
                      }
                      label={'Mixed SKU'}
                    />
                  </Grid>
                </Grid>
                <Grid sx={{ marginTop: '20px' }}>
                  {warehouse?.sections
                    ?.filter(
                      (item) => item.rackId === getCurrent(selected, 'id')
                    )
                    .map((section) => (
                      <Grid
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                          }}
                        >
                          <LocationName
                            title={`SECTION ${section.code}`}
                            subtitle={`${section.displayName}`}
                            color={getCurrent(selected, 'color')}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                color="success"
                                checked={
                                  (section.settings as WarehouseSettings)
                                    .isActive as boolean
                                }
                                onChange={(e) => {
                                  _set(
                                    'section',
                                    'isActive',
                                    e.target.checked,
                                    section.id
                                  );
                                }}
                              />
                            }
                            label={'Active'}
                          />
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel>Category</InputLabel>
                              <Select
                                label="Category"
                                multiple
                                input={<OutlinedInput label="Category" />}
                                onChange={(e) => {
                                  _set(
                                    'section',
                                    'productCategory',
                                    e.target.value,
                                    id
                                  );
                                }}
                                value={
                                  (section.settings as WarehouseSettings)
                                    .productCategory as string[]
                                }
                                renderValue={(selected: string[]) =>
                                  selected.join(', ')
                                }
                              >
                                {categories.map((item) => (
                                  <MenuItem key={item.value} value={item.value}>
                                    <Checkbox
                                      checked={(
                                        (section.settings as WarehouseSettings)
                                          .productCategory as string[]
                                      ).includes(item.value as string)}
                                    />
                                    <ListItemText primary={item.label} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Description"
                              variant="outlined"
                              value={section.description}
                              onChange={(e) => {
                                _set(
                                  'section',
                                  'description',
                                  e.target.value,
                                  id
                                );
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  color="info"
                                  checked={
                                    (section.settings as WarehouseSettings)
                                      .isRecommended as boolean
                                  }
                                  onChange={(e) => {
                                    _set(
                                      'section',
                                      'isRecommended',
                                      e.target.checked,
                                      id
                                    );
                                  }}
                                />
                              }
                              label={'Recommended'}
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  color="info"
                                  checked={
                                    (section.settings as WarehouseSettings)
                                      .isHotel as boolean
                                  }
                                  onChange={(e) => {
                                    _set(
                                      'section',
                                      'isHotel',
                                      e.target.checked,
                                      id
                                    );
                                  }}
                                />
                              }
                              label={'Hotel'}
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  color="info"
                                  checked={
                                    (section.settings as WarehouseSettings)
                                      .mixedSKU as boolean
                                  }
                                  onChange={(e) => {
                                    _set(
                                      'section',
                                      'mixedSKU',
                                      e.target.checked,
                                      id
                                    );
                                  }}
                                />
                              }
                              label={'Mixed SKU'}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <Button
                  variant="text"
                  color="info"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    const { section, updated } = initSection();

                    const { shelf } = initShelf(section, updated);

                    _setWarehouse({ section, shelf }, updated);
                  }}
                >
                  ADD SECTION
                </Button>
              </Grid>
            )}
            {isShelf && (
              <Grid sx={{ marginTop: '10px' }}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <TextField
                      label="Depth"
                      variant="outlined"
                      type="number"
                      value={
                        (
                          selected.locations.shelf
                            ?.settings as WarehouseSettings
                        ).depth as number
                      }
                      onChange={(e) => {
                        _set('shelf', 'depth', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Width"
                      variant="outlined"
                      type="number"
                      value={
                        (
                          selected.locations.shelf
                            ?.settings as WarehouseSettings
                        ).width as number
                      }
                      onChange={(e) => {
                        _set('shelf', 'width', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Height"
                      variant="outlined"
                      type="number"
                      value={
                        (
                          selected.locations.shelf
                            ?.settings as WarehouseSettings
                        ).height as number
                      }
                      onChange={(e) => {
                        _set('shelf', 'height', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Min height"
                      variant="outlined"
                      type="number"
                      value={
                        (
                          selected.locations.shelf
                            ?.settings as WarehouseSettings
                        ).minHeight as number
                      }
                      onChange={(e) => {
                        _set('shelf', 'minHeight', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        label="Category"
                        multiple
                        input={<OutlinedInput label="Category" />}
                        value={
                          (
                            selected.locations.shelf
                              ?.settings as WarehouseSettings
                          ).productCategory as string[]
                        }
                        renderValue={(selected: string[]) =>
                          selected.join(', ')
                        }
                        onChange={(e) => {
                          _set('shelf', 'productCategory', e.target.value);
                        }}
                      >
                        {categories.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox
                              checked={(
                                (
                                  selected.locations.shelf
                                    ?.settings as WarehouseSettings
                                ).productCategory as string[]
                              ).includes(item.value as string)}
                            />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Storage type</InputLabel>
                      <Select
                        label="Storage type"
                        value={
                          (
                            selected.locations.shelf
                              ?.settings as WarehouseSettings
                          ).shelfType as string
                        }
                        onChange={(e) => {
                          _set('shelf', 'shelfType', e.target.value);
                        }}
                      >
                        {storageTypes.map((item) => (
                          <MenuItem value={item.value}>
                            {format(item.value as string)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      value={selected.locations.shelf?.description}
                      disabled={
                        !selected.locations.shelf?.id.startsWith('SHELF')
                      }
                      onChange={(e) => {
                        _set('shelf', 'description', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          color="info"
                          checked={
                            (
                              selected.locations.shelf
                                ?.settings as WarehouseSettings
                            ).isRecommended as boolean
                          }
                          onChange={(e) => {
                            _set('shelf', 'isRecommended', e.target.checked);
                          }}
                        />
                      }
                      label={'Recommended'}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="info"
                          checked={
                            (
                              selected.locations.shelf
                                ?.settings as WarehouseSettings
                            ).isHotel as boolean
                          }
                          onChange={(e) => {
                            _set('shelf', 'isHotel', e.target.checked);
                          }}
                        />
                      }
                      label={'Hotel'}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="info"
                          checked={
                            (
                              selected.locations.shelf
                                ?.settings as WarehouseSettings
                            ).mixedSKU as boolean
                          }
                          onChange={(e) => {
                            _set('shelf', 'mixedSKU', e.target.checked);
                          }}
                        />
                      }
                      label={'Mixed SKU'}
                    />
                  </Grid>
                  <Button
                    variant="text"
                    color="info"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const { bin, updated } = initBin();

                      _setWarehouse({ bin }, updated);
                    }}
                  >
                    ADD BIN
                  </Button>
                </Grid>
                <FormLabel>Print mode</FormLabel>
                <RadioGroup row defaultValue={printMode}>
                  {printModeValues.map((item) => {
                    return (
                      <FormControlLabel
                        value={item.key}
                        control={<Radio />}
                        label={item.label}
                        onChange={() => {
                          setPrintMode(item.key as PrintModeType);
                        }}
                      />
                    );
                  })}
                </RadioGroup>
                <FormLabel>Paper size</FormLabel>
                <RadioGroup row defaultValue={paperSize}>
                  {paperSizeValues.map((item) => {
                    return (
                      <FormControlLabel
                        value={item.key}
                        control={<Radio />}
                        label={item.label}
                        onChange={() => {
                          setPaperSize(item.key as PrintSize);
                        }}
                      />
                    );
                  })}
                </RadioGroup>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="text"
                      color="info"
                      startIcon={<PrintIcon />}
                    >
                      PRINT LABEL
                    </Button>
                  )}
                  {...reactToPrint}
                />
              </Grid>
            )}
            {last() === 'bin' && selected.locations.bin && (
              <Grid sx={{ marginTop: '10px' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      value={selected.locations.bin?.description}
                      onChange={(e) => {
                        _set('bin', 'description', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <FormLabel>Print mode</FormLabel>
                <RadioGroup row defaultValue={printMode}>
                  {printModeValues.map((item) => {
                    return (
                      <FormControlLabel
                        value={item.key}
                        control={<Radio />}
                        label={item.label}
                        onChange={() => {
                          setPrintMode(item.key as PrintModeType);
                        }}
                      />
                    );
                  })}
                </RadioGroup>
                <FormLabel>Paper size</FormLabel>
                <RadioGroup row defaultValue={paperSize}>
                  {paperSizeValues.map((item) => {
                    return (
                      <FormControlLabel
                        value={item.key}
                        control={<Radio />}
                        label={item.label}
                        onChange={() => {
                          setPaperSize(item.key as PrintSize);
                        }}
                      />
                    );
                  })}
                </RadioGroup>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="text"
                      color="info"
                      startIcon={<PrintIcon />}
                    >
                      PRINT LABEL
                    </Button>
                  )}
                  {...reactToPrint}
                />
              </Grid>
            )}
            {selected.locations.shelf && (
              <Grid sx={{ display: 'none' }}>
                <div ref={componentRef} className="center-content">
                  {printMode === 'horizontal' ? (
                    <HorizontalSticker
                      location={getCurrent(selected, 'displayName')}
                      qrCode={getCurrent(selected, 'qrCode')}
                    />
                  ) : (
                    <VerticalSticker
                      location={getCurrent(selected, 'displayName')}
                      qrCode={getCurrent(selected, 'qrCode')}
                      direction={getDirection(printMode)}
                      paperSize={paperSize}
                    />
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: _isNewLocation(id) ? 'space-between' : 'right',
        }}
      >
        {_isNewLocation(id) && (
          <Button
            color="error"
            variant="text"
            onClick={() => {
              if (
                selected.locations.zone &&
                !selected.locations.subzone &&
                !selected.locations.floor &&
                !selected.locations.rack &&
                !selected.locations.section &&
                !selected.locations.shelf &&
                !selected.locations.bin
              ) {
                _delete(selected.locations.zone.id);
              }

              if (
                selected.locations.zone &&
                selected.locations.subzone &&
                !selected.locations.floor &&
                !selected.locations.rack &&
                !selected.locations.section &&
                !selected.locations.shelf &&
                !selected.locations.bin
              ) {
                _delete(selected.locations.subzone.id);
              }

              if (
                selected.locations.zone &&
                selected.locations.subzone &&
                selected.locations.floor &&
                selected.locations.rack &&
                !selected.locations.section &&
                !selected.locations.shelf &&
                !selected.locations.bin
              ) {
                _delete(selected.locations.rack.id);
              }

              if (
                selected.locations.zone &&
                selected.locations.subzone &&
                selected.locations.floor &&
                selected.locations.rack &&
                selected.locations.section &&
                selected.locations.shelf &&
                !selected.locations.bin
              ) {
                _delete(selected.locations.shelf.id);
              }

              if (
                selected.locations.zone &&
                selected.locations.subzone &&
                selected.locations.floor &&
                selected.locations.rack &&
                selected.locations.section &&
                selected.locations.shelf &&
                selected.locations.bin
              ) {
                _delete(selected.locations.bin.id);
              }
            }}
          >
            DELETE
          </Button>
        )}
        <Button
          color="info"
          startIcon={<SaveIcon />}
          onClick={() => {
            updateStatus.mutate(warehouse?.id as string);
          }}
        >
          SAVE
        </Button>
      </Grid>
    </Container>
  );
};
