import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

export const HANKOOK_CATALOG_RULESET: TRule[] = [
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateBridgestoneSpecialMarkings',
  },
  ...EDI_WHEEL_B4_RULESET,
  {
    type: 'rule.replace',
    targetKey: 't.product.width',
    matchValue: ['000000'],
    replaceValue: [''],
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.rimDiameter',
    matchValue: ['.00', '.0'],
    replaceValue: ['', ''],
  },
  // Model
  {
    type: 'rule.map',
    sourceKey: "c.['DESIGN_2']",
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },
  {
    type: 'rule.replace',
    targetKey: 't.model.modelName',
    matchValue: ['i*cept'],
    replaceValue: ['i-cept'],
  },
  {
    type: 'rule.combine',
    sourceKeys: ['c.LI1', 'c.LI2'],
    targetKey: 'loadIndex',
    destination: 'product',
    delimiter: '/',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'loadIndex2',
    template: '',
  },
  ...GLOBAL_RULESET,
];
