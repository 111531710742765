import api from '@tyrio/api-factory';
import { DBSupplierClientSettingsApi } from '@tyrio/dto';
import { useState } from 'react';
import { useQuery } from 'react-query';

interface GetSuppliersProps {
  enabled: boolean;
}

interface DropdownItems {
  label: string;
  value: string;
}

export const useGetSuppliers = (props: GetSuppliersProps) => {
  const [suppliersData, setSuppliersData] = useState<DropdownItems[]>([]);
  const { enabled } = props;

  const suppliers = useQuery(
    ['get_supplier_client_settings'],
    async () => {
      return await api.fetch<DBSupplierClientSettingsApi['list']>(
        'get_supplier_client_settings',
        {
          search: '',
          pageSize: 100,
        }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data?.data.map((item) => ({
          label: item.supplier?.companyShortName || '',
          value: item.supplierId,
        }));

        setSuppliersData(dropdownItems);
      },
      enabled: enabled,
    }
  );

  return { ...suppliers, suppliersData };
};
