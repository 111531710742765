import { usePosCartCtx } from '../../../../../../context/PosCartContext';
import { SideMenuLayout } from '../../../../../../components/SideMenuLayout/SideMenuLayout';
import { InfoFooter } from '../Footer';
import {
  Text,
  WmsIconStyled,
} from '../../../../../../components/SideMenuLayout/SideMenuLayout.style';
import {
  DeleteWrapper,
  MainWrapper,
} from '../../../../styles/CustomerForm.style';
import { wmsIcons } from '@tyrio/wms-ui-library';
import { PaymentForm } from './PaymentForm';
import { sum } from 'lodash';
// import { CashDiscount } from './CashDiscount';
// import { PromoCode } from './PromoCode';
import {
  CancelModal,
  DiscardModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import { usePosCartData } from '../../helpers/cart-data';
import { useCalculatePrice } from '../../helpers/calculation';
import { useState } from 'react';
import { usePosCtx } from '../../../../../../context/POSContext';

export const Payment = () => {
  const {
    setActiveInfo,
    paymentDetails,
    setPaymentDetails,
    input,
    setInput,
    deliveryPrice,
  } = usePosCartCtx();
  const { modalData } = usePosCartData();
  const { isVatIncluded, selectedCustomer } = usePosCtx();

  const { grandTotal } = useCalculatePrice(
    modalData,
    deliveryPrice,
    isVatIncluded,
    selectedCustomer?.rebateCalculation?.rebate ?? 0,
    selectedCustomer?.rebateCalculation?.discount ?? 0
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);

  const isPaymentAmountValid = () => {
    const amount = sum(paymentDetails.map((p) => p.amount));
    const isAnyNegative =
      paymentDetails.find((item) => item.amount < 0) !== undefined;
    return {
      isValid: amount === grandTotal && !isAnyNegative,
      isBigger: amount > grandTotal && !isAnyNegative,
      isNegative: isAnyNegative,
    };
  };

  const handleSubmit = () => {
    const { isValid, isBigger, isNegative } = isPaymentAmountValid();

    if (!isValid || isNegative) {
      const msg = isBigger
        ? 'The payment amount exceeds the requested amount.'
        : isNegative
        ? `The payment amount can't be negative.`
        : 'The payment amount is less than the requested amount.';
      ToastHelper.showToast(
        'Payment',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        msg
      );
    } else {
      setInput((prevState) => ({
        ...prevState,
        payment: paymentDetails,
      }));
      setActiveInfo('');
    }
  };

  const handleCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else setActiveInfo('');
  };

  const handleDiscard = () => {
    setInput((prevState) => ({
      ...prevState,
      payment: [],
    }));
    setPaymentDetails([]);
    setIsDiscardModalVisible(false);
    setActiveInfo('');
    setIsDirty(false);
  };

  const renderCustomer = () => {
    return (
      <MainWrapper>
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              setPaymentDetails(input.payment);
              setIsCancelModalVisible(false);
              setActiveInfo('');
              setIsDirty(false);
            }}
          />
        )}
        {isDiscardModalVisible && (
          <DiscardModal
            LBAction={() => setIsDiscardModalVisible(false)}
            RBAction={handleDiscard}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <PaymentForm setIsDirty={setIsDirty} />

          {/* <CashDiscount />
          <PromoCode /> */}
        </div>
        <DeleteWrapper onClick={() => setIsDiscardModalVisible(true)}>
          <Text id="delete_text">Discard</Text>
          <WmsIconStyled icon={wmsIcons.bin} id="delete_icon" />
        </DeleteWrapper>
      </MainWrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posPayment"
      children={renderCustomer()}
      showSwitch={false}
      switchLabel="Vehicle"
      shouldShowTitle={true}
      footer={
        <InfoFooter
          text1="CANCEL"
          text2="SUBMIT"
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      }
      viewBox={'0 0 32 32'}
    />
  );
};
