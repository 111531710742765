import { z } from 'zod';

export const InboundInvoiceValidation = (isEu: boolean) => {
  const euTerms = isEu ? z.string() : z.string().optional().nullable();

  return z.object({
    branchId: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    date: z.string({ required_error: 'This field is required!' }).datetime(),
    supplierName: z.string().optional(),
    vatChargeType: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    currency: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    exchangeRate: z.number().optional(),
    supplierInvoiceNumber: z
      .string({ required_error: 'This field is required!' })
      .nonempty({ message: 'This field is required!' }),
    invoiceIssueDate: z.date({ required_error: 'This field is required!' }),
    delay: z.any().optional().nullable(),
    invoiceDueDate: z.any().nullish(),
    internalRemark: z.string().optional(),
    deliveryTermsAndConditions: euTerms,
    natureOfTransaction: euTerms,
    modeOfTransport: isEu ? z.number() : z.number().optional().nullable(),
    dispatchingCountry: euTerms,
  });
};
