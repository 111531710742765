import styled from '@emotion/styled/macro';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { StyledDivider } from '../../../stock-list/components/InboundOrders/InboundOrders.style';
import {
  VehicleSteps,
  defaultFilterValue,
  useVehicleCtx,
} from '../../../../context/VehicleContext';
import { isEmpty } from 'lodash';
import {
  PosSearchActiveTab,
  usePosSearchContext,
} from '../SearchPOS/SearchContext';

export const SearchVehicleFooter = () => {
  const {
    setActiveStep,
    activeStep,
    clearVehicleContext,
    clearOnBackButton,
    filter,
    setFilter,
    setPosWheelsFilterData,
    // backendFilterData,
    // apiData,
  } = useVehicleCtx();

  const { resetAllValues, setCurrentStepIndex, setActiveTab } =
    usePosSearchContext();

  const hasSelectedValues = Object.values(filter).some((s) => !isEmpty(s));

  return (
    <Container>
      {hasSelectedValues && (
        <ResetButtonWrapper>
          <Button
            color="info"
            onClick={() => {
              setFilter(defaultFilterValue);
              // queryClient.fetchQuery('get_wheels', queryFn);
              setPosWheelsFilterData(null);
            }}
          >
            RESET ALL FILTERS
          </Button>
        </ResetButtonWrapper>
      )}
      <StyledDivider style={hasSelectedValues ? { marginTop: 0 } : undefined} />
      <ButtonWrapper>
        <Button
          startIcon={<ArrowBackIcon />}
          color="info"
          disabled={activeStep === VehicleSteps.BRAND}
          onClick={() => {
            if (activeStep) {
              const currentIndex =
                Object.keys(VehicleSteps).indexOf(activeStep);
              const newStep = Object.values(VehicleSteps)[currentIndex - 1];

              if (currentIndex !== 0) setActiveStep(newStep);

              clearOnBackButton();
            }
          }}
        >
          Back
        </Button>

        <StyledButton
          onClick={() => {
            clearVehicleContext();
            resetAllValues();
            setCurrentStepIndex(0);
            setActiveTab(PosSearchActiveTab.default_search);
          }}
          endIcon={<DeleteIcon />}
        >
          Discard
        </StyledButton>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
  padding-top: 0;
`;

const StyledButton = styled(Button)`
  opacity: 0.5;
  &:hover {
    opacity: 1;
    color: #db1e1ecc;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResetButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: white;
  height: 40px;
`;
