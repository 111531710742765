interface StatusInfo {
  title: string;
  desc: string;
}

interface StatusMapping {
  [key: string]: StatusInfo | undefined;
}

const statusMapping: StatusMapping = {
  CANCELLED: {
    title: 'CANCELLED',
    desc: 'Parcel data cancelled after PDF label was created',
  },
  CANCELLED_AND_SENT: {
    title: 'CANCELLED AND SENT',
    desc: 'Parcel data cancelled after it was sent to DPD',
  },
  CONSOLIDATION: {
    title: 'CONSOLIDATION',
    desc: 'Parcel is consolidated before export',
  },
  CREATED: {
    title: 'CREATED',
    desc: 'Parcel data was inserted, and parcel number was assigned',
  },
  CUSTOMS_CLEARANCE_PROCESS: {
    title: 'CUSTOMS CLEARANCE PROCESS',
    desc: 'Parcel is at customs clearance process',
  },
  DELETED: {
    title: 'DELETED',
    desc: 'Parcel deleted',
  },
  DELIVERED: {
    title: 'DELIVERED',
    desc: 'Parcel was delivered to receiver',
  },
  DELIVERED_BY_DRIVER_TO_DPD_PARCELSHOP: {
    title: 'DELIVERED BY DRIVER TO DPD PARCELSHOP',
    desc: 'Parcel was delivered to PUDO location',
  },
  DELIVERY_ATTEMPT_NOT_SUCCESSFUL: {
    title: 'DELIVERY ATTEMPT NOT SUCCESSFUL',
    desc: 'Parcel was not delivered to receiver',
  },
  DRIVERS_PICK_UP: {
    title: 'DRIVERS PICK UP',
    desc: "Courier picked up the parcel at sender's address",
  },
  DRIVERS_RETURN: {
    title: 'DRIVERS RETURN',
    desc: 'Courier returned the parcel to DPD depot after unsuccessful delivery',
  },
  EXPORT_IMPORT_CLEARED: {
    title: 'EXPORT IMPORT CLEARED',
    desc: 'Parcel passed the customs clearance',
  },
  HUB_SCAN: {
    title: 'HUB SCAN',
    desc: 'Parcel arrived in sorting depot',
  },
  INBOUND: {
    title: 'INBOUND',
    desc: 'Parcel arrived at delivery depot',
  },
  INBOUND_EXCEPTION: {
    title: 'INBOUND EXCEPTION',
    desc: 'Parcel is at warehouse and will be out for delivery next working day',
  },
  LOADING: {
    title: 'LOADING',
    desc: 'Parcel was loaded to swap body',
  },
  NOT_YET_PRINTED: {
    title: 'NOT YET PRINTED',
    desc: 'Parcel created but not printed',
  },
  OUT_FOR_DELIVERY: {
    title: 'OUT FOR DELIVERY',
    desc: 'Parcel is out for delivery to receiver',
  },
  PICK_UP: {
    title: 'PICK UP',
    desc: 'Parcel entered DPD depot',
  },
  PREPARED_TO_CANCEL: {
    title: 'PREPARED TO CANCEL',
    desc: 'Parcel data will be cancelled',
  },
  PREPARED_TO_SEND: {
    title: 'PREPARED TO SEND',
    desc: 'Parcel data is ready to be transmitted to DPD',
  },
  PRINTED: {
    title: 'PRINTED',
    desc: 'PDF label was created',
  },
  RETURN_TO_SENDER: {
    title: 'RETURN TO SENDER',
    desc: 'Parcel returned to sender',
  },
  SENT: {
    title: 'SENT',
    desc: 'Parcel data was transmitted to DPD',
  },
  SYSTEM_RETURN: {
    title: 'SYSTEM RETURN',
    desc: 'Parcel was prepared to be returned to sender',
  },
  UNKNOWN: {
    title: 'UNKNOWN',
    desc: 'No data at the moment',
  },
  WAREHOUSE: {
    title: 'WAREHOUSE',
    desc: 'Parcel is in DPD depot',
  },
};

export default statusMapping;

export const glsParcelStatus: Record<number, string> = {
  1: 'The parcel was handed over to GLS.',
  2: 'The parcel has left the parcel center.',
  3: 'The parcel has reached the parcel center.',
  4: 'The parcel is expected to be delivered during the day.',
  5: 'The parcel has been delivered.',
  6: 'The parcel is stored in the parcel center.',
  7: 'The parcel is stored in the parcel center.',
  8: 'The parcel is stored in the GLS parcel center. The consignee has agreed to collect the goods himself.',
  9: 'The parcel is stored in the parcel center to be delivered at a new delivery date.',
  10: 'Check scan normal.',
  11: 'The parcel could not be delivered as the consignee is on holidays.',
  12: 'The parcel could not be delivered as the consignee was absent.',
  13: 'Sorting error at the depot.',
  14: 'The parcel could not be delivered as the reception was closed.',
  15: 'Not delivered due to lack of time.',
  16: 'The parcel could not be delivered as the consignee had no cash available/suitable.',
  17: 'The parcel could not be delivered as the recipient refused acceptance.',
  18: 'The parcel could not be delivered as further address information is needed.',
  19: 'The parcel could not be delivered due to the weather condition.',
  20: 'The parcel could not be delivered due to wrong or incomplete address.',
  21: 'Forwarded sorting error.',
  22: 'Parcel is sent from the depot to the sorting center.',
  23: 'The parcel has been returned to sender.',
  24: 'The changed delivery option has been saved in the GLS system and will be implemented as requested.',
  25: 'Forwarded misrouted.',
  26: 'The parcel has reached the parcel center.',
  27: 'The parcel has reached the parcel center.',
  28: 'Disposed.',
  29: 'Parcel is under investigation.',
  30: 'Inbound damaged.',
  31: 'Parcel was completely damaged.',
  32: 'The parcel will be delivered in the evening.',
  33: 'The parcel could not be delivered due to exceeded time frame.',
  34: 'The parcel could not be delivered as acceptance has been refused due to delayed delivery.',
  35: 'Parcel was refused because the goods were not ordered.',
  36: 'Consignee was not in, contact card couldn’t be left.',
  37: 'Change delivery for shipper’s request.',
  38: 'The parcel could not be delivered due to missing delivery note.',
  39: 'Delivery note not signed.',
  40: 'The parcel has been returned to sender.',
  41: 'Forwarded normal.',
  42: 'The parcel was disposed upon shipper’s request.',
  43: 'Parcel is not to be located.',
  44: 'Parcel is excluded from General Terms and Conditions.',
  46: 'Change completed for delivery address.',
  47: 'The parcel has left the parcel center.',
  51: 'The parcel data was entered into the GLS IT system; the parcel was not yet handed over to GLS.',
  52: 'The COD data was entered into the GLS IT system.',
  54: 'The parcel has been delivered to the parcel box.',
  55: 'The parcel has been delivered at the ParcelShop (see ParcelShop information).',
  56: 'Parcel is stored in GLS ParcelShop.',
  57: 'The parcel has reached the maximum storage time in the ParcelShop.',
  58: 'The parcel has been delivered at the neighbour’s (see signature).',
  60: 'Customs clearance is delayed due to a missing invoice.',
  61: 'The customs documents are being prepared.',
  62: 'Customs clearance is delayed as the consignee’s phone number is not available.',
  64: 'The parcel was released by customs.',
  65: 'The parcel was released by customs. Customs clearance is carried out by the consignee.',
  66: 'Customs clearance is delayed until the consignee’s approval is available.',
  67: 'The customs documents are being prepared.',
  68: 'The parcel could not be delivered as the consignee refused to pay charges.',
  69: 'The parcel is stored in the parcel center. It cannot be delivered as the consignment is not complete.',
  70: 'Customs clearance is delayed due to incomplete documents.',
  71: 'Customs clearance is delayed due to missing or inaccurate customs documents.',
  72: 'Customs data must be recorded.',
  73: 'Customs parcel locked in origin country.',
  74: 'Customs clearance is delayed due to a customs inspection.',
  75: 'Parcel was confiscated by the Customs authorities.',
  76: 'Customs data recorded, parcel can be sent to final location.',
  80: 'The parcel has been forwarded to the desired address to be delivered there.',
  83: 'The parcel data for Pickup-Service was entered into the GLS system.',
  84: 'The parcel label for the pickup has been produced.',
  85: 'The driver has received the order to pick up the parcel during the day.',
  86: 'The parcel has reached the parcel center.',
  87: 'The pickup request has been cancelled as there were no goods to be picked up.',
  88: 'The parcel could not be picked up as the goods to be picked up were not packed.',
  89: 'The parcel could not be picked up as the customer was not informed about the pickup.',
  90: 'The pickup request has been cancelled as the goods were sent by other means.',
  91: 'Pick and Ship/Return cancelled.',
  92: 'The parcel has been delivered.',
  93: 'Signature confirmed.',
  99: 'Consignee contacted, Email delivery notification.',
};
