import styled from '@emotion/styled';

interface MinimalLocationProps {
  location: string;
  color: string;
  stagingType?: string;
}

const MinimalLocation = (props: MinimalLocationProps) => {
  const { location, color, stagingType } = props;

  return (
    <Container>
      <LocationColor color={color} />
      <LocationContainer>
        {stagingType && <LocationTitle>{stagingType}</LocationTitle>}
        <LocationName>{location}</LocationName>
      </LocationContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  width: 180px;
  cursor: pointer;
`;

const LocationTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.3px;
`;

const LocationName = styled.span`
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.3px;
`;

const LocationColor = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 6px;
  height: 60px;
`;

export default MinimalLocation;
