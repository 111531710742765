import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface TimeOverlayProps {
  min: number;
  max: number;
  res: number;
}

const TimeOverlay = (timeOverlayProps: TimeOverlayProps) => {
  const { min, max, res } = timeOverlayProps;
  const [_currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const calculateHeight = () => {
    const now = moment();

    const startOfDay = moment().startOf('day').add(min, 'hours');
    const endOfDay = moment().startOf('day').add(max, 'hours');

    if (now.isBefore(startOfDay)) {
      return 0;
    }
    if (now.isAfter(endOfDay)) {
      return 100;
    }

    const totalMinutes = endOfDay.diff(startOfDay, 'minutes');
    const minutesSinceStartOfDay = now.diff(startOfDay, 'minutes');

    return (minutesSinceStartOfDay / totalMinutes) * 100;
  };

  const height = calculateHeight() - 0.5;

  return <Container height={height} sx={{ minWidth: `${res * 140}px` }} />;
};

export default TimeOverlay;

const Container = styled(Box)<{
  height: number;
}>`
  margin-top: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ height }) => `${height}%`};
  background-color: white;
  opacity: 0.7;
  z-index: 99;
  pointer-events: none;
  border-bottom: 1px solid #db1e1e;
`;
