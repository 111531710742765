import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DBSupplierClientB2BSettingsApi } from '@tyrio/dto';
import { useCallback, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  PasswordField,
  TextFieldInput,
} from '../supplier-form/helpers/components';
import { ExpandMore } from '@mui/icons-material';
import { ContactHeader } from '../supplier-form/SupplierAdminForm';
import {
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
} from 'react-hook-form';
import { ValidationProps } from '../supplier-form/helpers/types';

export interface SupplierClientB2BSettingsProps {
  getData: (
    data: DBSupplierClientB2BSettingsApi['create']['requestBody']
  ) => void;
  initialValues?: Partial<
    DBSupplierClientB2BSettingsApi['create']['requestBody']
  >;
  getValidationProps: (
    key: string,
    validationRules?: RegisterOptions,
    validationText?: string
  ) => ValidationProps;
  clearErrors: UseFormClearErrors<FieldValues>;
}

export const SUPPLIER_CLIENT_B2B_SETTINGS_INITIAL_DATA: DBSupplierClientB2BSettingsApi['create']['requestBody'] =
  {
    b2bUrl: '',
    b2bUserName: '',
    b2bPassword: '',
  };

const SupplierClientB2BSettingsTab = ({
  getData,
  initialValues = {},
  clearErrors,
}: SupplierClientB2BSettingsProps) => {
  const [supplierClientB2BSettings, setSupplierClientB2BSettingsState] =
    useState<DBSupplierClientB2BSettingsApi['create']['requestBody']>({
      ...SUPPLIER_CLIENT_B2B_SETTINGS_INITIAL_DATA,
      ...initialValues,
    });

  const setSupplierClientB2BSettings = useCallback(
    (data: DBSupplierClientB2BSettingsApi['create']['requestBody']) => {
      setSupplierClientB2BSettingsState(data);
      getData(data);
    },
    [getData]
  );

  return (
    <Accordion
      square
      defaultExpanded
      sx={{
        boxShadow: '0px 1px 0px #DFE3E8',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          paddingLeft: '0px',
        }}
      >
        <ContactHeader>B2B shop login details</ContactHeader>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingLeft: '0px',
        }}
      >
        <Grid
          spacing={2}
          container
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={7} lg={9}>
            <TextFieldInput
              label="B2B Url"
              value={supplierClientB2BSettings.b2bUrl}
              onChange={(e) => {
                setSupplierClientB2BSettings({
                  ...supplierClientB2BSettings,
                  ...{ b2bUrl: e.target.value },
                });
              }}
            />
          </Grid>
          <Grid
            item
            xs={5}
            lg={3}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              size="medium"
              component="label"
              color="info"
              variant="outlined"
              endIcon={<LaunchIcon />}
              onClick={() => {
                window
                  .open(supplierClientB2BSettings.b2bUrl, '_blank')
                  ?.focus();
              }}
            >
              LAUNCH B2B
            </Button>
          </Grid>
        </Grid>
        <Grid spacing={2} container sx={{ marginTop: '30px' }}>
          <Grid item xs={12} lg={6}>
            <TextFieldInput
              label="B2B login username"
              value={supplierClientB2BSettings.b2bUserName}
              onChange={(e) => {
                setSupplierClientB2BSettings({
                  ...supplierClientB2BSettings,
                  ...{ b2bUserName: e.target.value },
                });
                clearErrors('b2bUserName');
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <PasswordField
              label="B2B login password"
              value={supplierClientB2BSettings.b2bPassword}
              onChange={(e) => {
                setSupplierClientB2BSettings({
                  ...supplierClientB2BSettings,
                  ...{ b2bPassword: e.target.value },
                });
                clearErrors('b2bPassword');
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          sx={{ marginTop: '20px' }}
          justifyContent="flex-end"
        ></Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SupplierClientB2BSettingsTab;
