import styled from '@emotion/styled';
import green from '../../../assets/vertical-list/green-badge.svg';
import red from '../../../assets/vertical-list/red-badge.svg';
import { tyrioUI } from '../../../index';

interface LineItemProps {
  name: string;
  type: string;
  active: boolean;
  disabled: boolean;
  onClick: () => void;
  isSelected: boolean;
}

const LineItem = ({
  name,
  type,
  active,
  disabled,
  onClick,
  isSelected,
}: LineItemProps) => {
  return (
    <Container isSelected={isSelected} onClick={onClick}>
      <Wrapper>
        <Name disabled={disabled}>{name}</Name>
        <Type disabled={disabled}>{type}</Type>
      </Wrapper>
      <Active>
        <img src={active ? green : red} alt="" />
      </Active>
    </Container>
  );
};

const Container = styled.div<{ isSelected: boolean }>`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba(25, 118, 210, 0.08);
  }
  background-color: ${(props) => (props.isSelected ? '#19c5d214' : '#ffffff')};
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const Wrapper = styled.div`
  font-weight: 400;
  padding-right: ${tyrioUI.spacing.l}px;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Active = styled.div``;

const Name = styled.span<{ disabled?: boolean }>`
  font-size: 16px;
  color: ${(props) => (props.disabled ? '#00000061' : 'black')};
  word-break: break-all;
`;

const Type = styled.span<{ disabled?: boolean }>`
  margin-top: 5px;
  font-size: 14px;
  color: ${(props) => (props.disabled ? '#00000061' : '#b9c4ce')};
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 285px;
`;

export default LineItem;
