import api from '@tyrio/api-factory';
import { useMutation } from 'react-query';

export const useGetData = () => {
  const { data, mutateAsync, status } = useMutation(
    ['wheels_data_scraping'],
    () =>
      api.fetch<{
        requestBody: never;
        requestParams: never;
        request: never;
        response: never;
      }>('wheels_data_scraping'),

    {}
  );

  return { data, mutateAsync, status };
};
