import { DBLanguage, DBUserRole } from '@prisma/client';

export const users = [
  {
    id: '00000000-0000-1000-a000-000000000000',
    adminId: '00000000-0000-1000-a000-000000000000',
    birthday: '03/05/1997',
    clientId: null,
    email: 'brackovic97@gmail.com',
    firstName: 'Emir',
    lastName: 'Brackovic',
    mobilePhone: '+38761000000',
    remark: 'No remark',
    roleId: '00000000-0000-0000-0000-000000000001',
    language: DBLanguage.Bosnian,
    userRole: DBUserRole.ADMIN,
  },
  {
    id: '00000000-0000-1000-a000-000000000001',
    adminId: '00000000-0000-1000-a000-000000000001',
    birthday: '01/01/1993',
    clientId: null,
    email: 'haris@tyrio.com',
    firstName: 'Haris',
    lastName: 'Pandzic',
    mobilePhone: '+38762209709',
    remark: 'No remark',
    roleId: '00000000-0000-0000-0000-000000000002',
    language: DBLanguage.Croatian,
    userRole: DBUserRole.ADMIN,
  },
  {
    id: '00000000-0000-1000-a000-000000000002',
    adminId: null,
    birthday: '01/01/1993',
    clientId: '00000000-0000-1000-b000-0000000000022',
    email: 'ismet@ankorainc.com',
    firstName: 'Ismet',
    lastName: 'Ferhatbegovic',
    mobilePhone: '+38761111111',
    remark: 'No remark',
    roleId: '00000000-0000-0000-0000-000000000006',
    personalIdentificationNumber: '1234567891011',
    businessPhone: '+38712232333',
    language: DBLanguage.Bosnian,
    userRole: DBUserRole.CLIENT,
  },
  {
    id: '00000000-0000-1000-a000-000000000003',
    adminId: '00000000-0000-1000-a000-000000000002',
    birthday: '01/01/1993',
    clientId: null,
    email: 'nedim@tyrio.com',
    firstName: 'Nedim',
    lastName: 'Muhamedagic',
    mobilePhone: '+38765685479',
    remark: 'No remark',
    roleId: '00000000-0000-0000-0000-000000000001',
    language: DBLanguage.English,
    userRole: DBUserRole.ADMIN,
  },
  {
    id: '00000000-0000-1000-a000-000000000004',
    adminId: null,
    birthday: '01/01/1993',
    clientId: '00000000-0000-1000-b000-0000000000011',
    personalIdentificationNumber: '12345',
    businessPhone: '+38712232333',
    email: 'beckaskola033@gmail.com',
    firstName: 'Haris',
    lastName: 'Zorlak',
    mobilePhone: '+38761468135',
    remark: 'No remark',
    roleId: '00000000-0000-0000-0000-000000000006',
    language: DBLanguage.English,
    userRole: DBUserRole.CLIENT,
  },
  {
    id: '00000000-0000-1000-a000-000000000005',
    adminId: '00000000-0000-1000-a000-000000000003',
    birthday: '01/01/1993',
    clientId: null,
    email: 'adna@ankorainc.com',
    firstName: 'Adna',
    lastName: 'Pasic',
    mobilePhone: '+38762142000',
    remark: 'No remark',
    roleId: '00000000-0000-0000-0000-000000000001',
    language: DBLanguage.Bosnian,
    userRole: DBUserRole.ADMIN,
  },
  {
    id: '00000000-0000-1000-a000-000000000006',
    adminId: '00000000-0000-1000-a000-000000000004',
    birthday: '01/29/1999',
    clientId: null,
    email: 'neirapiranic@gmail.com',
    firstName: 'Neira',
    lastName: 'Piranic',
    mobilePhone: '+38761989187',
    remark: 'No remark',
    roleId: '00000000-0000-0000-0000-000000000001',
    language: DBLanguage.Bosnian,
    userRole: DBUserRole.ADMIN,
  },
];
