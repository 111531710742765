import api from '@tyrio/api-factory';
import { DBStockListApi } from '@tyrio/dto';
import _ from 'lodash';
import { useMutation } from 'react-query';

export const useGetStockListByProductIds = (
  onStockListSuccess: (data: DBStockListApi['byProductIds']['response']) => void
) => {
  const data = useMutation(
    ['get_stock_list_by_product_ids'],
    (productIds: string[]) =>
      api.fetch<DBStockListApi['byProductIds']>(
        'get_stock_list_by_product_ids',
        {
          productIds,
        }
      ),

    {
      onSuccess: (res) => {
        onStockListSuccess(res);
      },
    }
  );

  return {
    ...data,
  };
};
