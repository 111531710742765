import api from '@tyrio/api-factory';
import { DBCustomPriceCatalogApi } from '@tyrio/dto';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface CheckEanProps {
  setErrorText: (val: string) => void;
}

export const useCheckEAN = (props: CheckEanProps) => {
  const { setErrorText } = props;

  const checkEan = useMutation(
    (req: DBCustomPriceCatalogApi['checkEAN']['request']) =>
      api.fetch<DBCustomPriceCatalogApi['checkEAN']>('cpc_check_ean', {
        ...req,
      }),
    {
      mutationKey: 'cpc_check_ean',
      onError: (error) => {
        const errorData = error as AxiosError<unknown>;
        setErrorText((errorData.response?.data as ErrorProps)?.error?.name);
      },
    }
  );

  return { checkEan };
};

interface ErrorProps {
  error: { name: string };
}
