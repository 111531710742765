import { UnselectedRowWarning, wmsIcons } from '@tyrio/wms-ui-library';
import { isEmpty, isUndefined } from 'lodash';
import { useContext, useMemo } from 'react';
import { SideMenuLayout } from '../../../components/SideMenuLayout/SideMenuLayout';
import { POSContext } from '../../../context/POSContext';
import { AddItemsFooter } from '../components/AddItemsFooter';
import { StockInfo } from '../components/StockInfo/StockInfo';
import { useGetBranchesStock } from '../query/get-branches-stock';
import {
  PosSearchActiveTab,
  usePosSearchContext,
} from '../components/SearchPOS/SearchContext';
import { useVehicleCtx } from '../../../context/VehicleContext';
import { usePosCartCtx } from '../../../context/PosCartContext';

export const Branches = () => {
  const {
    selectedTableRow,
    setBadgeContent,
    badgeContent,
    shouldShowDot,
    selectedWarehouseId,
  } = useContext(POSContext);

  const { activeTab } = usePosSearchContext();
  const { selectedProduct } = useVehicleCtx();
  const { cartData } = usePosCartCtx();

  const ean = useMemo(() => {
    if (activeTab === PosSearchActiveTab.default_search)
      return selectedTableRow?.product?.ean;
    else return selectedProduct?.ean;
  }, [activeTab, selectedProduct?.ean, selectedTableRow?.product?.ean]);

  const { warehouseStock } = useGetBranchesStock(
    ean ?? '',
    selectedWarehouseId?.toString(),
    badgeContent,
    setBadgeContent,
    selectedTableRow?.stockType,
    shouldShowDot
  );

  const unselectedRowCondition =
    (activeTab === PosSearchActiveTab.default_search && !selectedTableRow) ||
    (activeTab === PosSearchActiveTab.wheels_search && !selectedProduct);

  const renderBranches = () => {
    if (!isUndefined(warehouseStock) && !isEmpty(warehouseStock))
      return (
        <StockInfo
          data={warehouseStock}
          text={'BRANCHES STOCK'}
          showText={false}
        />
      );
    else if (unselectedRowCondition)
      return <UnselectedRowWarning icon={wmsIcons.branches} text="branches" />;
    else
      return (
        <UnselectedRowWarning
          icon={wmsIcons.branches}
          emptyData="No available items for this product"
        />
      );
  };

  return (
    <SideMenuLayout
      type="branches"
      children={renderBranches()}
      showSwitch={false}
      checked={false}
      footer={
        !isUndefined(warehouseStock) &&
        !isEmpty(warehouseStock) &&
        cartData.length > 0 && <AddItemsFooter />
      }
    />
  );
};
