import * as z from "zod"
import { CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBBranch, RelatedDBBranchModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBDeliveryAddressModel = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  address: z.string(),
  zip: z.string(),
  city: z.string(),
  country: z.string(),
  phoneNumberDetails: jsonSchema,
  companyInfo: jsonSchema,
  editedAddress: jsonSchema,
  branchId: z.number().int().nullish(),
})

export interface CompleteDBDeliveryAddress extends z.infer<typeof DBDeliveryAddressModel> {
  customerOrderItem: CompleteDBCustomerOrderItem[]
  branch?: CompleteDBBranch | null
}

/**
 * RelatedDBDeliveryAddressModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBDeliveryAddressModel: z.ZodSchema<CompleteDBDeliveryAddress> = z.lazy(() => DBDeliveryAddressModel.extend({
  customerOrderItem: RelatedDBCustomerOrderItemModel.array(),
  branch: RelatedDBBranchModel.nullish(),
}))
