import { Box, Grid, Modal } from '@mui/material';
import {
  AddressDetails,
  Header,
  LastEditData,
  LastEditTypographyHeader,
} from '../../styles/AddressCard.style';
import CloseIcon from '@mui/icons-material/Close';
import { PhoneNumberDetails } from '@tyrio/dto';
import moment from 'moment';
import { DBBillingAddress, DBDeliveryAddress } from '@prisma/client';

interface IAddressModal {
  open: boolean;
  handleClose: () => void;
  editedMeta: Record<string, string>;
  addressData: DBDeliveryAddress | DBBillingAddress | null;
}

export const AddressModal = ({
  open,
  handleClose,
  editedMeta,
  addressData,
}: IAddressModal) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            padding: '16px',
            borderBottom: '1px solid #DFE3E8',
            display: 'flex',
            justifyContent: 'space-between  ',
          }}
        >
          <Header>ORIGINAL ADDRESS</Header>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
        </Box>
        <Box sx={{ padding: '16px' }}>
          <Header>
            {`${addressData?.firstName?.toUpperCase()} ${addressData?.lastName?.toUpperCase()}`}
          </Header>
          <AddressDetails>{addressData?.address}</AddressDetails>
          <AddressDetails>
            {`${addressData?.zip} ${addressData?.city} `}
          </AddressDetails>
          <AddressDetails>{addressData?.country}</AddressDetails>
          <AddressDetails>
            {
              (addressData?.phoneNumberDetails as unknown as PhoneNumberDetails)
                ?.phoneNumber
            }
          </AddressDetails>
          <Box
            sx={{
              background: '#F4F6F8',
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              borderRadius: '10px',
            }}
          >
            <Grid>
              <LastEditTypographyHeader>Last edit: </LastEditTypographyHeader>
              <LastEditData>
                {editedMeta['editedBy'] ?? ''}
                {' - '}
                {moment(editedMeta['updatedAt']).format(
                  'DD.MM.YYYY - HH:mm:ss'
                )}
              </LastEditData>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
