import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

/**
 * specialMarking => DESCRIPTION_2
 */
export const KUMHO_CATALOG_RULESET: TRule[] = [
  // {
  //   type: 'rule.combine',
  //   sourceKeys: ['{{DE}}'],
  //   destination: 'product',
  //   targetKey: 'origin',
  //   delimiter: '',
  // },
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateKumhoSpecialMarkings',
  },

  ...EDI_WHEEL_B4_RULESET,

  // Model
  {
    type: 'rule.map',
    sourceKey: "c.['DESIGN_2']",
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },

  ...GLOBAL_RULESET,
];
