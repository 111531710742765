import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Branches from './pages/Branches';
import DashboardRoles from './pages/Roles';
import PaymentMethods from '../../pages/company-settings/PaymentMethods';
import UsersCompanySettings from '../../pages/company-settings/UsersCompanySettings';
import DeliveryMethods from '../../pages/company-settings/DeliveryMethods';
import ShippingCouriersMenu from '../../pages/company-settings/ShippingCouriersMenu';

interface CompanySettingsMenuSwitchProps {
  switchMenu?: boolean;
  setSwitchMenu: (menu: boolean) => void;
  setIsModalOpen: (value: boolean) => void;
}

const CompanySettingsMenuSwitch = ({
  switchMenu,
  setSwitchMenu,
  setIsModalOpen,
}: CompanySettingsMenuSwitchProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/branches`}>
        <Branches switchMenu={switchMenu} setSwitchMenu={setSwitchMenu} />
      </Route>
      <Route path={`${path}/roles`}>
        <DashboardRoles switchMenu={switchMenu} setSwitchMenu={setSwitchMenu} />
      </Route>
      <Route path={`${path}/payment-method`}>
        <PaymentMethods switchMenu={switchMenu} setSwitchMenu={setSwitchMenu} />
      </Route>
      <Route path={`${path}/users`}>
        <UsersCompanySettings
          switchMenu={switchMenu}
          setSwitchMenu={setSwitchMenu}
        />
      </Route>
      <Route path={`${path}/delivery-method`}>
        <DeliveryMethods
          switchMenu={switchMenu}
          setSwitchMenu={setSwitchMenu}
        />
      </Route>
      <Route path={`${path}/shipping-couriers`}>
        <ShippingCouriersMenu
          switchMenu={switchMenu}
          setSwitchMenu={setSwitchMenu}
          setIsModalOpen={setIsModalOpen}
        />
      </Route>
    </Switch>
  );
};

export default CompanySettingsMenuSwitch;
