import { Box, Modal } from '@mui/material';

export const CustomModal = ({
  open,
  handleClose,
  child,
  removeScroll,
}: {
  open: boolean;
  handleClose: () => void;
  child: JSX.Element;
  removeScroll?: boolean;
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
    height: '70%',
    overflow: removeScroll ? 'hidden' : 'scroll',
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{child}</Box>
    </Modal>
  );
};
