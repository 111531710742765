import { DateRangePicker } from 'mui-daterange-picker';
import { useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField, InputAdornment, Grid } from '@mui/material';
import { DBClientSupplierOrderItemFilters } from '@tyrio/dto';
import './date-picker.css';
import { ComponentType } from '../helpers/types';

interface DateTimeRangePickerProps {
  isSidebarOpen: boolean;
  filters: DBClientSupplierOrderItemFilters;
  setFilters: (value: DBClientSupplierOrderItemFilters) => void;
  componentType?: ComponentType;
}

const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
  const [open, setOpen] = useState(false);

  const getDateString = (date?: Date) => {
    if (date === undefined) date = new Date();
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  };

  const getPeriodString = (start?: Date, end?: Date) => {
    return `${getDateString(start)} - ${getDateString(end)}`;
  };

  return (
    <>
      <TextField
        fullWidth
        label="Period"
        value={getPeriodString(
          props.filters?.startDate ??
            new Date(new Date().setDate(new Date().getDate() - 30)),
          props.filters?.endDate ?? new Date()
        )}
        onClick={() => setOpen(!open)}
        sx={{
          '.MuiOutlinedInput-input': {
            cursor: 'pointer',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid
        sx={{
          position: 'absolute',
          right: props.isSidebarOpen ? '43%' : '2%',
          zIndex: '4',
        }}
      >
        <DateRangePicker
          open={open}
          toggle={() => setOpen(!open)}
          maxDate={
            props.componentType === ComponentType.customer
              ? new Date()
              : undefined
          }
          onChange={(range) => {
            props.setFilters({
              ...props.filters,
              startDate:
                range.startDate &&
                new Date(range.startDate.setHours(0, 0, 0, 0)),
              endDate:
                range.endDate &&
                new Date(range.endDate.setHours(23, 59, 59, 999)),
            });
          }}
          initialDateRange={{
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date(),
          }}
        />
      </Grid>
    </>
  );
};

export default DateTimeRangePicker;
