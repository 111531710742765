import { Tooltip } from '@mui/material';
import { deliveryLate, concludedBy, processedBy } from '@tyrio/ui-library';
import { OrderStatus } from '../../purchase-orders/PurchaseOrderTable';
import HttpsIcon from '@mui/icons-material/Https';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const TooltipData = (
  assignedTo = '',
  concluded = '',
  processed = ''
) => {
  const assigned = assignedTo?.split(' ');
  const iconsStyledProps = { width: '20px', height: '20px', cursor: 'pointer' };

  const tooltip = {
    delivery_late: (
      <Tooltip title="Delivery late">
        <img src={deliveryLate} alt="delivery-late" />
      </Tooltip>
    ),

    favourite: (
      <Tooltip title="Favourite" placement="top">
        <FavoriteIcon sx={{ ...iconsStyledProps, color: '#FFDB80' }} />
      </Tooltip>
    ),

    assigned_to: (
      <Tooltip title={`Assigned to ${assignedTo}`}>
        <OrderStatus color="#BDBDBD">
          {assigned[0]?.charAt(0) + assigned[1]?.charAt(0)}
        </OrderStatus>
      </Tooltip>
    ),

    conclused_by: (
      <Tooltip title={`Concluded by ${concluded}`}>
        <img src={concludedBy} alt="concluded-by" />
      </Tooltip>
    ),

    processed_by: (
      <Tooltip title={`Processes by ${processed}`}>
        <img src={processedBy} alt="concluded-by" />
      </Tooltip>
    ),

    // purchase order

    drop_shipping: (
      <Tooltip title="Drop-shipping">
        <OrderStatus color="#454F5B">DS</OrderStatus>
      </Tooltip>
    ),

    pre_order: (
      <Tooltip title="Pre-order for lager">
        <OrderStatus color="#454F5B">FOR STOCK</OrderStatus>
      </Tooltip>
    ),

    locked: (
      <Tooltip title="Locked" placement="top">
        <HttpsIcon sx={{ height: '20px' }} color="error" />
      </Tooltip>
    ),
  };

  return tooltip;
};
