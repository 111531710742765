import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { GUMIIMPEX_PRICELIST_RULESET } from './gumiimpex/pricelist';
import { GUMIIMPEX_STOCKLIST_RULESET } from './gumiimpex/stocklist';
import { GUMIIMPEX_CATALOG_RULESET } from './gumiimpex/catalog';

export const GUMIIMPEX_SUPPLIER: SupplierShape = {
  weight: 7,
  override: true,
  ruleset: GUMIIMPEX_CATALOG_RULESET,
  pricelistRuleset: GUMIIMPEX_PRICELIST_RULESET,
  stockRuleset: GUMIIMPEX_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
      dataSource: 'GUMI-IMPEX',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'GUMI-IMPEX',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    VP_sa_popustom: {
      ',': '.',
    },
    wetGrip: {
      'n.a.n. a.' : '',
      'n.a.' : '',
      'n/a' : '',
    },
    rollingResistance: {
      'n.a.n. a.' : '',
      'n.a.' : '',
      'n/a' : '',
    },
    noiseClass: {
      'n.a.n. a.' : '',
      'n.a.' : '',
      'n/a' : '',
    },
    Sirina: {
      ',': '.',
      '85': '8.5',
      '95': '9.5',
      '825': '8.25',
    },
    Visina: {
      ',': '.'
    },
    Promjer: {
      'R': '',
      'C': '',
      'r': '',
      'c': '',
    },
    Dezen: {
      'TORQUE ': '',
      '215/65R16 102H XL MP93 Nordicc' : 'MP93 Nordicca',
      '165/65R14 79T MP93 Nordicca' : 'MP93 Nordicca',
      'KELLY HP': 'HP',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          item['EAN'] !== '6953913191959' && !item['Naziv'].includes('DOT')
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          item['EAN'] !== '6953913191959' && !item['Naziv'].includes('DOT')
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          item['EAN'] !== '6953913191959' && !item['Naziv'].includes('DOT')
      ),
      this.preprocessKeys
    );
  },
};
