import { Dispatch, SetStateAction, useState } from 'react';
import styled from '@emotion/styled/macro';
import {
  Button,
  Chip,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import api from '@tyrio/api-factory';
import { WheelSizeApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

interface InputShape {
  id: string;
  name: string;
}

interface BlackListSettingsProps {
  blackList?: InputShape[];
  setBlackList: Dispatch<SetStateAction<InputShape[]>>;
}

const BlackListSettings = ({
  blackList,
  setBlackList,
}: BlackListSettingsProps) => {
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState<string>('');

  const [brands, setBrands] = useState<InputShape[]>([]);
  const [models, setModels] = useState<InputShape[]>([]);

  useQuery(
    ['get_brands'],
    () => api.fetch<WheelSizeApi['getBrands']>('get_brands'),
    {
      onSuccess(data) {
        setBrands(
          data.data
            ? data.data.map((item) => ({
                id: item.slug,
                name: item.name,
              }))
            : []
        );
      },
    }
  );

  useQuery(
    ['get_models', selectedBrand],
    () =>
      api.fetch<WheelSizeApi['getOneModel']>('get_models', {
        model: selectedBrand,
      }),
    {
      enabled: !!selectedBrand,
      onSuccess(data) {
        setModels(
          data.data
            ? data.data.map((item) => ({
                id: item.slug,
                name: item.name,
              }))
            : []
        );
      },
    }
  );

  const handleDeletePair = (id: string) => {
    setBlackList((current) => current.filter((pair) => pair.id !== id));
  };

  const handleAddPair = () => {
    if (selectedBrand && selectedModel) {
      const newPair: InputShape = {
        id: selectedModel,
        name: `${brands.find((name) => name.id === selectedBrand)?.name}/${
          models.find((model) => model.id === selectedModel)?.name
        }`,
      };

      setBlackList &&
        setBlackList((currentPairs) => [...currentPairs, newPair]);

      setSelectedBrand('');
      setSelectedModel('');
    }
  };

  return (
    <Container>
      <Grid>
        <SettingContainer>
          <FormControl>
            <FormLabel>Black list:</FormLabel>
            <CarSelectRow container spacing={2}>
              <Grid item xs={5}>
                <FormControl>
                  <InputLabel id="car-name-label">Brand</InputLabel>
                  <Select
                    fullWidth
                    labelId="car-name-label"
                    id="car-name-select"
                    value={selectedBrand}
                    label="Brand"
                    onChange={(event) =>
                      setSelectedBrand(event.target.value as string)
                    }
                    sx={{ marginBottom: '20px' }}
                  >
                    {brands.map((option, idx) => (
                      <MenuItem key={`${option.id}-${idx}`} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={5}>
                <FormControl>
                  <InputLabel id="car-model-label">Model</InputLabel>
                  <Select
                    fullWidth
                    labelId="car-model-label"
                    id="car-model-select"
                    value={selectedModel}
                    label="Model"
                    onChange={(event) =>
                      setSelectedModel(event.target.value as string)
                    }
                    sx={{ marginBottom: '20px' }}
                    disabled={!selectedBrand}
                  >
                    {models.map((option, idx) => (
                      <MenuItem key={`${option.id}-${idx}`} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <AddButton
                  variant="contained"
                  onClick={handleAddPair}
                  disabled={!selectedBrand || !selectedModel}
                >
                  Add
                </AddButton>
              </Grid>
            </CarSelectRow>

            <Grid container spacing={2} sx={{ pt: 2 }}>
              <Grid item xs={12}>
                {blackList &&
                  blackList?.map((pair, idx) => (
                    <Chip
                      key={`${pair.id}-${idx}`}
                      label={pair.name}
                      onDelete={() => handleDeletePair(pair.id)}
                      variant="outlined"
                      sx={{ mr: 1 }}
                    />
                  ))}
              </Grid>
            </Grid>
          </FormControl>
        </SettingContainer>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const SettingContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  gap: 25px;
`;

const CarSelectRow = styled(Grid)`
  padding-top: 10px;
`;

const AddButton = styled(Button)`
  height: 57px;
`;

export default BlackListSettings;
