import * as z from "zod"
import { CompleteDBSupplier, RelatedDBSupplierModel } from "./index"

export const DBSupplierContactModel = z.object({
  id: z.string(),
  supplierId: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  businessPhone: z.string(),
  mobilePhone: z.string(),
  remark: z.string(),
  createdAt: z.date(),
})

export interface CompleteDBSupplierContact extends z.infer<typeof DBSupplierContactModel> {
  supplier?: CompleteDBSupplier | null
}

/**
 * RelatedDBSupplierContactModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierContactModel: z.ZodSchema<CompleteDBSupplierContact> = z.lazy(() => DBSupplierContactModel.extend({
  supplier: RelatedDBSupplierModel.nullish(),
}))
