/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import React, { Dispatch, SetStateAction } from 'react';
import { ImmutableTree } from 'react-awesome-query-builder';

export interface ActionValuesPriceProps {
  price_range_min: string;
  price_range_max: string;
  margin: string;
  marginType?: string;
  useSuggestedRetailPrice: boolean;
}

export interface ActionValuesRebatesProps {
  price_range_min: string;
  price_range_max: string;
  rebates: string;
  discount: string;
  useSuggestedRetailPrice: boolean;
}

export type ActionValues =
  | ActionValuesPriceProps[]
  | ActionValuesRebatesProps[];

export interface IRules {
  actions: ActionValues;
  conditions: string;
  name: string;
  ruleDescription?: string;
  id: string;
  newConditions?: ImmutableTree;
}

export interface IJsonLogic {
  ruleIndex: string | null;
  jsonLogic: string;
}

export interface INewCondition {
  id: string;
  tree: ImmutableTree;
}

interface IRulesContext {
  rules: IRules[];
  setRules: Dispatch<SetStateAction<IRules[]>>;
  componentType?: string;
  updatedConditions: { current: IJsonLogic[] };
  setDirty: Dispatch<SetStateAction<boolean>>;
  error?: any;
  setError?: Dispatch<SetStateAction<any>>;
  newRuleCondition?: { current: INewCondition[] };
}

export const RulesContext = React.createContext<IRulesContext>({
  rules: [{ actions: [], conditions: '', name: '', id: '' }],
  setRules: () => {},
  updatedConditions: { current: [] },
  setDirty: () => {},
  newRuleCondition: { current: [] },
});
