import {
  FEATURE_FLAGS,
  isFlagEnabled,
  isFlagOptionEnabled,
} from '@tyrio/feature-flags';
import { tyrioIcons } from '@tyrio/ui-library';

export const companySettingsMenuLinks = [
  {
    link: '/dashboard/company-settings/general',
    icon: tyrioIcons.generalBlack,
    icon2: tyrioIcons.generalWhite,
    label: 'General',
    isVisible:
      isFlagOptionEnabled(
        FEATURE_FLAGS.SHOW_COMPANY_SETTING_OPTIONS,
        'general'
      ) ?? false,
    permissionPath: 'settings.general',
  },
  {
    link: '/dashboard/company-settings/company-info',
    icon: tyrioIcons.companyInfoIconeBlack,
    icon2: tyrioIcons.companyInfoIconeWhite,
    label: 'Company Info',
    isVisible: true,
    permissionPath: 'settings.companyInfo',
  },
  {
    link: '/dashboard/company-settings/printers',
    icon: tyrioIcons.printerBlack,
    icon2: tyrioIcons.printerWhite,
    label: 'Printers',
    isVisible: true,
    permissionPath: 'settings.printers',
  },
  {
    link: '/dashboard/company-settings/documents-look',
    icon: tyrioIcons.documentBlack,
    icon2: tyrioIcons.document,
    label: 'Documents look',
    isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_DOCUMENTS_LOOK) ?? false,
    permissionPath: 'settings.documentsLook',
  },
  {
    link: '/dashboard/company-settings/branches',
    icon: tyrioIcons.companyBranchesIconeBlack,
    icon2: tyrioIcons.companyBranchesIconeWhite,
    label: 'Branches',
    isVisible: true,
    permissionPath: 'settings.branches',
  },
  // {
  //   link: '/dashboard/company-settings/order-config',
  //   icon: tyrioIcons.visibilityBlack,
  //   icon2: tyrioIcons.visibilityWhite,
  //   label: 'EDI Order configuration',
  //   isVisible: true,
  //   permissionPath: 'settings.ediOrderConfiguration',
  // },
  {
    link: '/dashboard/company-settings/users',
    icon: tyrioIcons.menuIcon4,
    icon2: tyrioIcons.menuIcon4,
    label: 'Users',
    isVisible: true,
    permissionPath: 'settings.users',
  },
  {
    link: '/dashboard/company-settings/roles',
    icon: tyrioIcons.usersIcon,
    icon2: tyrioIcons.generalWhite,
    label: 'Roles',
    isVisible: true,
    permissionPath: 'settings.roles',
  },
  {
    link: '/dashboard/company-settings/delivery-method',
    icon: tyrioIcons.deliverySmallBlack,
    icon2: tyrioIcons.deliverySmallWhite,
    label: 'Delivery methods',
    isVisible:
      isFlagOptionEnabled(
        FEATURE_FLAGS.SHOW_COMPANY_SETTING_OPTIONS,
        'delivery_method'
      ) ?? false,
    permissionPath: 'settings.deliveryMethods',
  },
  {
    link: '/dashboard/company-settings/payment-method',
    icon: tyrioIcons.creditCard,
    icon2: tyrioIcons.generalWhite,
    label: 'Payment methods',
    isVisible:
      isFlagOptionEnabled(
        FEATURE_FLAGS.SHOW_COMPANY_SETTING_OPTIONS,
        'payment_method'
      ) ?? false,
    permissionPath: 'settings.paymentMethods',
  },
  {
    link: '/dashboard/company-settings/shipping-couriers',
    icon: tyrioIcons.deliverySmallBlack,
    icon2: tyrioIcons.deliverySmallWhite,
    label: 'Shipping couriers',
    isVisible:
      isFlagOptionEnabled(
        FEATURE_FLAGS.SHOW_COMPANY_SETTING_OPTIONS,
        'shipping_couriers'
      ) ?? false,
    permissionPath: 'settings.shippingCouriers',
  },
];
