import { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from 'react-query';

import styled from '@emotion/styled/macro';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Tooltip from '@mui/material/Tooltip';
import { AxiosError } from 'axios';
import moment from 'moment';

import api from '@tyrio/api-factory';
import {
  DBTransferOrderItemApi,
  DBTransferOrderItemResponse,
  DBTransferOrderStatusEnum,
  SSE_ENTITY_KEYS,
  TransferOrderStatusShape,
} from '@tyrio/dto';
import {
  CancelModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import { queryClient } from '../../../../query-client';
import { useAuth } from '../../../context/AuthContext';
import { useWS } from '../../../context/WSContext';
import TransferOrderDetailsSidebarTabs from './TransferOrderDetailsTabs';

interface TransferOrderDetailsProps {
  selectedOrderData: DBTransferOrderItemResponse;
  originalOrderData: DBTransferOrderItemResponse;
  setSelectedOrderData: (
    item: DBTransferOrderItemApi['getOne']['response'] | null
  ) => void;
  setFavorite: () => void;
  handleSave: () => void;
  setLockStatus?: () => void;
  setIsUploadInProcess: (item: boolean) => void;
  isUploadInProcess: boolean;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const TransferOrderDetails = ({
  selectedOrderData,
  originalOrderData,
  setSelectedOrderData,
  setFavorite,
  setLockStatus,
  handleSave,
  setIsUploadInProcess,
  isUploadInProcess,
  isDirty,
  setIsDirty,
}: TransferOrderDetailsProps) => {
  const iconsStyledProps = { width: '20px', height: '20px', cursor: 'pointer' };
  const { user } = useAuth();
  const { state } = useWS();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const handleClose = () => {
    if (isDirty) {
      const feedback = window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
      if (feedback) {
        setSelectedOrderData(null);
        setIsDirty(false);
      }
    } else if (isUploadInProcess) {
      window.alert(`You can't close order details while uploading documents!`);
    } else setSelectedOrderData(null);
  };

  const handleOnCancel = () => {
    setIsCancelModalVisible(true);
  };

  const isFavorite = () => {
    if (user) return selectedOrderData?.favoritedBy.includes(user.id);
    return false;
  };

  const stornTransferOrder = useMutation(
    () => {
      return api.fetch<DBTransferOrderItemApi['updateOne']>(
        'change_transfer_order_status',
        {
          orderId: selectedOrderData.id,
          orderStatus: {
            status: DBTransferOrderStatusEnum.CANCELLED,
            subStatus: '',
          } as unknown as TransferOrderStatusShape,
          deliveryType: selectedOrderData.deliveryTypeId,
        }
      );
    },
    {
      mutationKey: 'change_transfer_order_status',
      onSuccess: () => {
        queryClient.refetchQueries('transfer_orders');
        queryClient.refetchQueries('count_orders_by_status');
        ToastHelper.showToast(
          'Order ',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Transfer order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const lockEntity =
    state.locks &&
    state.locks[`${SSE_ENTITY_KEYS.TRANSFER_ORDER}/${selectedOrderData.id}`];

  const isDisabled = !(
    lockEntity === undefined ||
    lockEntity === null ||
    lockEntity?.userId === user?.id
  );

  return (
    <Container>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => {
            setIsCancelModalVisible(false);
          }}
          RBAction={() => {
            stornTransferOrder.mutateAsync();

            setIsCancelModalVisible(false);
            setSelectedOrderData(null);
          }}
          text="You are about to cancel the order."
        />
      )}
      <Header>
        <BasicOrderInfoContainer>
          {selectedOrderData.orderNumber}
          <TimeInfo>
            {moment(selectedOrderData?.createdAt).format(
              'DD.MM.YYYY - h:mm:ss'
            )}
          </TimeInfo>
        </BasicOrderInfoContainer>

        <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {(lockEntity === undefined ||
            lockEntity === null ||
            lockEntity?.userId === user?.id) && (
            <Grid>
              {lockEntity ? (
                <Tooltip placement="top" title="Unlock order">
                  <LockOutlinedIcon
                    sx={{ ...iconsStyledProps }}
                    onClick={setLockStatus}
                  />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Lock order">
                  <LockOpenOutlinedIcon
                    sx={{ ...iconsStyledProps }}
                    onClick={setLockStatus}
                  />
                </Tooltip>
              )}
            </Grid>
          )}

          {isFavorite() ? (
            <Tooltip placement="top" title="Remove from favorites">
              <FavoriteIcon
                sx={{ ...iconsStyledProps, color: '#FFDB80' }}
                onClick={setFavorite}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Add to favorites">
              <FavoriteBorderIcon sx={iconsStyledProps} onClick={setFavorite} />
            </Tooltip>
          )}

          <CloseIcon sx={iconsStyledProps} onClick={handleClose} />
        </Grid>
      </Header>
      <TransferOrderDetailsSidebarTabs
        selectedOrderData={selectedOrderData}
        originalOrderData={originalOrderData}
        setSelectedOrderData={setSelectedOrderData}
        setIsUploadInProcess={setIsUploadInProcess}
      />
      <FooterContainer>
        <Footer>
          <Button
            color="error"
            variant="text"
            onClick={handleOnCancel}
            disabled={isUploadInProcess || isDisabled}
          >
            CANCEL ORDER
          </Button>
          <Button
            color="info"
            variant="outlined"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={isUploadInProcess || isDisabled}
          >
            SAVE
          </Button>
        </Footer>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BasicOrderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  background: #454f5b;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: 700;
  font-size: 24px;
  color: white;
`;

const FooterContainer = styled.div`
  padding: 16px;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 22;
  border-radius: 8px;
`;

const Footer = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  border-top: 4px solid #e6e8f0;
`;

export default TransferOrderDetails;
