import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: Barlow;
  font-weight: 600;
  font-size: 16px;
`;

export const LocationTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const LocationTypeText = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-right: 20px;
`;

export const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 24;
  height: 700px;
  padding: 24px;
`;
