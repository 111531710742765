import { TextFieldStyled } from '../../styles/AddressCard.style';

export const AddressTextField = ({
  id,
  value,
  handleChange,
  placeholder,
  required,
  customPadding,
  topLabelBorder,
  helperText,
  disabled = false,
  fontSize,
  fontWeight,
  error,
}: {
  id: string;
  value: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (e: any) => void;
  disabled: boolean;
  placeholder: string;
  color?: string;
  required?: boolean;
  customPadding?: string;
  topLabelBorder?: number;
  helperText?: string;
  fontSize?: string;
  fontWeight?: number;
  error?: boolean;
}) => {
  return (
    <TextFieldStyled
      id={id}
      value={value}
      label={placeholder}
      size="small"
      placeholder={placeholder}
      fullWidth
      onChange={(e) => {
        handleChange(e);
      }}
      disabled={disabled}
      variant={'outlined'}
      required={required}
      top={topLabelBorder}
      customPadding={customPadding}
      helperText={helperText}
      hasValue={value !== ''}
      fontSize={fontSize}
      fontWeight={fontWeight}
      error={error}
    />
  );
};
