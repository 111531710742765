import { TRule } from '../../typings';

export const DELDO_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.EAN",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Article']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Brand',
    destination: 'product',
    targetKey: 'brand',
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.brand',
    matchValue: [' FS', ' WI', ' ZO', ' SU', ' FS ALL', ' AO', ' ALL', ' all'],
    replaceValue: ['', '', '', '', '', '', '', ''],
  },
  {
    type: 'rule.map',
    sourceKey: "c.['Price']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c['IPCODE']",
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['IPCODE']",
    condition: 'equals',
    conditionValue: '',
    outputValue: "c['Article']",
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'manufacturerCode',
    outputType: 'string',
  },
];
