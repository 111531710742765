import api from '@tyrio/api-factory';
import { ShippingCourierApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetAllShippingCouriers = () => {
  const { data, isFetching, isError } = useQuery(
    ['get_all_shipping_couriers'],
    () => api.fetch<ShippingCourierApi['list']>('get_all_shipping_couriers'),

    {}
  );

  return { allCouriers: data ?? [], isFetching, isError };
};
