import { TRule } from '../../typings';

export const BFPNEUMATIC_PRICELIST_RULESET: TRule[] = [
  {
    type: 'rule.map',
    sourceKey: "c.['eancode']",
    destination: 'product',
    targetKey: 'ean',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['id']",
    destination: 'product',
    targetKey: 'supplierCode',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'brand',
    template: '',
  },
  {
    type: 'rule.map',
    sourceKey: "c.['yourprice']",
    destination: 'product',
    targetKey: 'priceEUR',
    outputType: 'number',
  },
];
