import styled from '@emotion/styled';
import { Divider, FormControlLabel, Grid } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import { CategoryDropdownInputSingle, tyrioUI } from '@tyrio/ui-library';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { TyrioSelectInputOption } from '@tyrio/forms';

import MultipleSelectChip from '../../components/MultiselectChip/MultiselectChip';
import { SEASON_DROPDOWN_DATA } from '@tyrio/shared-vars';

export type DimTypeComparisonFormRefType = React.ElementRef<
  typeof DimTypeComparisonForm
>;
interface DimTypeComparisonFormRefProps {
  getAllData: () => {
    category?: string;
    brands?: string[];
    dimensions: string[];
    seasons?: string[];
    shouldShowRunFlat?: boolean;
    hasErrors: boolean;
  };
  showErrors: (e: boolean) => void;
}
interface DimTypeComparisonFormProps {
  brands: { value: string; label: string }[];
  dimensions: string[];
  categories: TyrioSelectInputOption[];
  seasons: { label: string; value: string }[];
  setIsDirty?: Dispatch<SetStateAction<boolean>>;
}
const DimTypeComparisonForm: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<DimTypeComparisonFormProps> &
    React.RefAttributes<DimTypeComparisonFormRefProps>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = React.forwardRef(({ brands, dimensions, seasons, setIsDirty }, ref) => {
  const [selectedCategory, setSelectedCategory] =
    useState<TyrioSelectInputOption | null>(null);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>([]);
  const [selectedSeasons, setSelectedSeasons] = useState<string[]>([]);
  const [shouldShowRunFlat, setShouldShowRunFlat] = useState(true);
  // const [errors, setErrors] = useState<Record<string, string>>({});
  const [errorsVisible, setShowErrors] = useState(false);

  const errors = useMemo(
    () => ({
      hasErrors:
        selectedDimensions.length === 0 ||
        selectedBrands.length === 0 ||
        selectedSeasons.length === 0 ||
        !selectedCategory?.value,
      dimensions:
        selectedDimensions.length === 0
          ? 'You must select dimensions before continuing.'
          : undefined,
      brands:
        selectedBrands.length === 0
          ? 'You must at select least one brand before continuing.'
          : undefined,
      seasons:
        selectedSeasons.length === 0
          ? 'You must select at least one season before continuing.'
          : undefined,
      categoryId: !selectedCategory?.value
        ? 'You must select a category before continuing.'
        : undefined,
    }),
    [
      selectedBrands.length,
      selectedCategory?.value,
      selectedDimensions.length,
      selectedSeasons.length,
    ]
  );

  const showErrors = useCallback((errs: boolean) => {
    setShowErrors(errs);
  }, []);

  const getAllData = useCallback(() => {
    return {
      category: selectedCategory?.value.toString(),
      brands: selectedBrands,
      dimensions: selectedDimensions,
      seasons: selectedSeasons,
      shouldShowRunFlat: shouldShowRunFlat,
      hasErrors: errors.hasErrors,
    };
  }, [
    errors.hasErrors,
    selectedBrands,
    selectedCategory?.value,
    selectedDimensions,
    selectedSeasons,
    shouldShowRunFlat,
  ]);

  useImperativeHandle(ref, () => ({
    getAllData,
    showErrors,
  }));

  return (
    <CodeFormWrapper>
      <FormWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CategoryDropdownInputSingle
              id={'category_picker_id'}
              value={selectedCategory}
              error={!errorsVisible ? undefined : errors['categoryId']}
              onChange={(cat) => {
                if (cat) {
                  setSelectedCategory(cat);
                  if (setIsDirty) setIsDirty(true);
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <MultipleSelectChip
              sx={{ m: 0 }}
              key={'multiselect_seasons'}
              data={SEASON_DROPDOWN_DATA}
              error={!errorsVisible ? undefined : errors['seasons']}
              title={'Seasons'}
              onChange={setSelectedSeasons}
            />
          </Grid>
        </Grid>
      </FormWrapper>
      <Divider
        sx={{
          marginTop: `${tyrioUI.spacing.xl}px`,
          marginBottom: `${tyrioUI.spacing.xl}px`,
        }}
        textAlign={'left'}
      >
        <DividerLabel>Choose brand for comparison</DividerLabel>
      </Divider>
      {/*<FormWrapper>*/}
      {/*  <FormGroup*/}
      {/*    sx={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'row',*/}
      {/*      margin: `${tyrioUI.spacing.l}px`,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {tyreClasses.map((tyreClass: string) => (*/}
      {/*      <FormControlLabel*/}
      {/*        key={`tyre_class_${tyreClass}`}*/}
      {/*        control={*/}
      {/*          <Checkbox*/}
      {/*            color={'info'}*/}
      {/*            defaultChecked={tyreClass === 'Premium Class'}*/}
      {/*          />*/}
      {/*        }*/}
      {/*        label={tyreClass}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </FormGroup>*/}
      {/*</FormWrapper>*/}
      <FormWrapper>
        <MultipleSelectChip
          key={'multiselect_brands'}
          data={brands.map((el) => el.label)}
          error={!errorsVisible ? undefined : errors['brands']}
          title={'Brands'}
          onChange={setSelectedBrands}
        />
      </FormWrapper>
      <Divider
        sx={{
          marginTop: `${tyrioUI.spacing.xl}px`,
          marginBottom: `${tyrioUI.spacing.xl}px`,
        }}
        textAlign={'left'}
      >
        <DividerLabel>Choose dimensions for comparison</DividerLabel>
      </Divider>
      <FormWrapper>
        <FormGroup
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: `${tyrioUI.spacing.l}px`,
          }}
        >
          <FormControlLabel
            disabled
            control={<Checkbox color={'info'} />}
            label="Favourite"
          />
          <FormControlLabel
            disabled
            control={<Checkbox color={'info'} />}
            label="20 most selling dimensions"
          />
        </FormGroup>
      </FormWrapper>
      <FormWrapper>
        <MultipleSelectChip
          key={'multiselect_dimensions'}
          data={dimensions}
          error={!errorsVisible ? undefined : errors['dimensions']}
          title={'Dimensions'}
          onChange={setSelectedDimensions}
        />
      </FormWrapper>
      <FormWrapper>
        <FormGroup
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '16px',
            marginTop: '8px',
          }}
        >
          <FormControlLabel
            control={<Checkbox color={'info'} />}
            label="Do not show Run Flat tyres"
            onChange={() => {
              setShouldShowRunFlat(!shouldShowRunFlat);
              if (setIsDirty) setIsDirty(true);
            }}
          />
        </FormGroup>
      </FormWrapper>
    </CodeFormWrapper>
  );
});
const CodeFormWrapper = styled.div`
  padding-left: ${tyrioUI.spacing.l}px;
  padding-right: 60px;
  border-right: 1px solid #dfe3e8;
  width: 100%;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const DividerLabel = styled.label`
  color: ${tyrioUI.colors.darkGrey};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export default DimTypeComparisonForm;
