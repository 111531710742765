import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button from '@mui/material/Button/Button';
import { DBUserApi } from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import { useHistory } from 'react-router-dom';

interface HeaderProps {
  title: string;
  onClickNew?: () => void;
  user: DBUserApi['getOne']['response'] | null;
}

export const Header = ({ title, onClickNew, user }: HeaderProps) => {
  const history = useHistory();

  const adminId = user?.adminId || undefined;
  return (
    <Container>
      <Title>{title}</Title>
      <Wrapper>
        {adminId && (
          <ButtonContainer>
            <StyledButton
              endIcon={<FileDownloadOutlinedIcon />}
              onClick={() => history.push(`/dashboard/catalog`)}
            >
              IMPORT
            </StyledButton>
            <StyledButton
              data-cy="newModel"
              variant="outlined"
              color="info"
              endIcon={<AddIcon />}
              onClick={onClickNew}
            >
              NEW MODEL
            </StyledButton>
          </ButtonContainer>
        )}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: ${tyrioUI.spacing.l}px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;
  @media (max-width: 580px) {
    height: 90px;
  }
`;

const StyledButton = styled(Button)`
  width: 140px;
  margin-right: 12px;

  @media (max-width: 580px) {
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  color: ${tyrioUI.colors.primary};
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const Wrapper = styled.div`
  display: flex;
`;

export default Header;
