import {
  SIDEWALL_DATA,
  SPECIAL_MARKING,
  SPECIAL_MARKING_TYPE_MAP,
} from './special-markings';
import { RFD_KEYS } from './rfd';
import {
  COMPOUND_MAP,
  CONSTRUCTIONS_MAP,
  EU_DIRECTIVE_NUMBER_MAP,
  HOLE_CIRCLE_MAP,
  HOLE_NUMBER_MAP,
  INSTALLATION_KIT_MAP,
  NOISE_CLASS_MAP,
  PR_MAP,
  ROLLING_RESISTANCE_MAP,
  SEASON_MAP,
  SPEED_INDEX_MAP,
  TUBE_TYPE_MAP,
  VEHICLE_CLASS_MAP,
  WET_GRIP_MAP,
  WHEEL_POSITION_MAP,
} from './catalog-data';
import { countries } from '../seeds/nonprod/countries';
import { BRANDS } from './brands';
import {
  // COLOR,
  COLOR_FINISH,
  COLOR_GROUP,
} from './color.helper';

export { SPECIAL_MARKING };

export const WHEEL_POSITIONS_DROPDOWN_DATA = Object.keys(
  WHEEL_POSITION_MAP
).map((w) => ({
  label: WHEEL_POSITION_MAP[w],
  value: w,
}));

export const SEASON_DROPDOWN_DATA = Object.keys(SEASON_MAP).map((s) => ({
  label: SEASON_MAP[s],
  value: s,
}));

export const RFD_DROPDOWN_DATA = Object.values(RFD_KEYS).map((m) => ({
  label: `${m.name} - ${m.description}`,
  value: m.name,
}));

export const SPECIAL_MARKING_WO_SIDEWALL_DROPDOWN_DATA = Object.values(
  SPECIAL_MARKING
)
  .filter((m) => !m.type.includes('sidewall'))
  .map((m) => ({
    label: `${m.key} - ${m.shortDescription}`,
    value: m.key,
  }));

export const SIDEWALL_DROPDOWN_DATA = Object.values(SIDEWALL_DATA).map((m) => ({
  label: `${m.name} - ${m.shortDescription}`,
  value: m.name,
}));

export const SPECIAL_MARKINGS_DROPDOWN_DATA = Object.values(
  SPECIAL_MARKING
).map((specialMarking) => ({
  label: `${specialMarking.key} - ${specialMarking.shortDescription}`,
  value: specialMarking.key,
}));

export const CONSTRUCTION_DROPDOWN_DATA = Object.keys(CONSTRUCTIONS_MAP).map(
  (c) => ({
    value: c,
    label: CONSTRUCTIONS_MAP[c].name,
  })
);

export const STRUCTURE_DROPDOWN_DATA = Object.keys(CONSTRUCTIONS_MAP).map(
  (c) => ({
    label: CONSTRUCTIONS_MAP[c].label,
    value: CONSTRUCTIONS_MAP[c].name,
  })
);

export const SPEED_INDEX_DROPDOWN_DATA = Object.keys(SPEED_INDEX_MAP).map(
  (s) => ({
    value: s,
    label: s,
  })
);

export const ROLLING_RESISTANCE_DROPDOWN_DATA = (
  key: '2020/740' | '1222/2009'
) =>
  Object.keys(ROLLING_RESISTANCE_MAP[key] || {}).map((r) => ({
    value: r,
    label: r,
  }));

export const WET_GRIP_DROPDOWN_DATA = (key: '2020/740' | '1222/2009') =>
  Object.keys(WET_GRIP_MAP[key] || {}).map((r) => ({
    value: r,
    label: r,
  }));

export const NOISE_CLASS_TYPE_DROPDOWN_DATA = (key: '2020/740' | '1222/2009') =>
  Object.keys(NOISE_CLASS_MAP[key] || {}).map((r) => ({
    value: r,
    label: r,
  }));

export const VEHICLE_CLASS_DROPDOWN_DATA = Object.keys(VEHICLE_CLASS_MAP).map(
  (r) => ({
    value: r,
    label: r,
  })
);

export const WHEEL_WIDTH_DROPDOWN_DATA = Array(60)
  .fill(1)
  .map((__, idx) => ({
    label: `${(idx + 21) * 5}`,
    value: (idx + 21) * 5,
  }));

export const WHEEL_HEIGHT_DROPDOWN_DATA = Array(20)
  .fill(1)
  .map((__, idx) => ({
    label: `${(idx + 1) * 5}`,
    value: (idx + 1) * 5,
  }));

export const WHEEL_RIM_DIAMETER_DROPDOWN_DATA = Array(30)
  .fill(1)
  .map((__, idx) => ({
    label: `${idx + 6}`,
    value: idx + 6,
  }));

export const TUBE_TYPE_DROPDOWN_DATA = Object.keys(TUBE_TYPE_MAP).map((r) => ({
  value: r,
  label: TUBE_TYPE_MAP[r].label,
}));

export const INSTALLATION_KIT_DROPDOWN_DATA = Object.keys(
  INSTALLATION_KIT_MAP
).map((k) => ({
  value: k,
  label: INSTALLATION_KIT_MAP[k],
}));

export const COUNTRY_DROPDOWN_DATA = countries.map((c) => ({
  value: c.intrastat_code,
  label: `${c.intrastat_code} - ${c.name}`,
}));

export const BRAND_DROPDOWN_DATA = Object.values(BRANDS).map((b) => ({
  value: b.name,
  label: b.name,
}));

// export const COLOR_DROPDOWN_DATA = Object.values(COLOR).map((c) => ({
//   value: c.name,
//   label: c.name,
// }));

export const COLOR_FINISH_DROPDOWN_DATA = Object.values(COLOR_FINISH).map(
  (c) => ({
    value: c,
    label: c,
  })
);

export const COLOR_GROUP_DROPDOWN_DATA = Object.values(COLOR_GROUP).map(
  (c) => ({
    value: c,
    label: c,
  })
);

export const EU_DIRECTIVE_NUMBER_DROPDOWN_DATA = Object.values(
  EU_DIRECTIVE_NUMBER_MAP
).map((c) => ({
  value: c,
  label: c,
}));

export const COMPOUND_DROPDOWN_DATA = Object.keys(COMPOUND_MAP).map((c) => ({
  value: c,
  label: COMPOUND_MAP[c],
}));

export const SPECIAL_MARKING_TYPE_DROPDOWN_DATA = Object.values(
  SPECIAL_MARKING_TYPE_MAP
);
export { SPECIAL_MARKING_TYPE_MAP };

export const HOLE_CIRCLE_DROPDOWN_DATA = HOLE_CIRCLE_MAP.map((item) => ({
  label: item,
  value: item,
}));
export const HOLE_NUMBER_DROPDOWN_DATA = HOLE_NUMBER_MAP.map((item) => ({
  label: item,
  value: item,
}));

export const PR_DROPDOWN_DATA = PR_MAP.map((item) => ({
  label: item,
  value: item,
}));
