/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ClientPrinters,
  DocumentType,
  PrinterFunctionalities,
} from '@tyrio/dto';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAutocompleteSingleField } from '../../form-helper';
import { useAuth } from '../../../context/AuthContext';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { tyrioUI } from '@tyrio/ui-library';

const accordionData = [
  {
    functionality: PrinterFunctionalities.WMS_STOCK_IN,
    accordionTitle: 'WMS - Stock IN',
  },
  {
    functionality: PrinterFunctionalities.WMS_SHIPPING,
    accordionTitle: 'WMS - Shipping',
  },
  {
    functionality: PrinterFunctionalities.WMS_DISPATCH,
    accordionTitle: 'WMS - Dispatch',
  },
  {
    functionality: PrinterFunctionalities.WMS_COLLECTION,
    accordionTitle: 'WMS - Collection',
  },
  {
    functionality: PrinterFunctionalities.WMS_FITTING,
    accordionTitle: 'WMS - Fitting',
  },
  {
    functionality: PrinterFunctionalities.WMS_DECLARATION,
    accordionTitle: 'Declarations',
  },
];

interface BranchPrintersProps {
  errors: any;
  control: any;
}

interface PrintersAccordionProps {
  title: string;
  functionality: string;
  expandedData: string[];
  setExpandedData: (value: string[]) => void;
  errors: any;
  control: any;
}

interface SelectPrinterWrapper {
  keyName: string;
  title: string;
  sizeText: string;
  options: ClientPrinters[];
  errors: any;
  control: any;
}
const SelectPrinterWrapper = ({
  keyName,
  title,
  sizeText,
  options,
  errors,
  control,
}: SelectPrinterWrapper) => {
  const { t } = useTranslation();

  console.log('options', options);

  return (
    <SelectWrapper>
      <DocumentTypeTitle>{title}</DocumentTypeTitle>
      <DocumentSizeText>{sizeText}</DocumentSizeText>
      {getAutocompleteSingleField(
        t('Label'),
        `${keyName}`,
        () => false,
        errors,
        control,
        { lg: 4, xs: 12 },
        {},
        options?.map((printer: ClientPrinters) => {
          return { label: printer.name, value: printer.id };
        })
      )}
    </SelectWrapper>
  );
};

const PrintersAccordion = ({
  functionality,
  title,
  expandedData,
  setExpandedData,
  errors,
  control,
}: PrintersAccordionProps) => {
  const { user } = useAuth();
  const clientPrinters = !isEmpty(user?.client?.printers)
    ? JSON.parse(user?.client?.printers as string)
    : {};

  const selectData = [
    {
      title: 'A4 documents',
      key: DocumentType.A4_DOCUMENTS,
      sizeText: 'size: 210 x 297 mm',
      options: clientPrinters?.a4Documents,
    },
    {
      title: 'POS receipts',
      sizeText: 'size: 80 mm',
      key: DocumentType.POS_RECEIPTS,
      options: clientPrinters?.posReceipts,
    },
    {
      title: 'Thermal Labels',
      sizeText: 'size: 102mm',
      key: DocumentType.THERMAL_LABELS,
      options: clientPrinters?.thermalLabels,
    },
  ];
  return (
    <AccordionStyled square expanded={expandedData?.includes(functionality)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() =>
          setExpandedData(
            expandedData?.includes(functionality)
              ? expandedData?.filter((i: string) => i !== functionality)
              : [...expandedData, functionality]
          )
        }
      >
        {title}
      </AccordionSummary>

      <AccordionDetailsStyled>
        <>
          {selectData.map((item) => (
            <SelectPrinterWrapper
              title={item.title}
              sizeText={item.sizeText}
              keyName={`${functionality}_${item.key}`}
              options={item.options ?? []}
              errors={errors}
              control={control}
            />
          ))}
        </>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

const BranchPrinters = ({ errors, control }: BranchPrintersProps) => {
  const { user } = useAuth();
  const [expandedData, setExpandedData] = useState<string[]>([]);
  const clientPrinters = !isEmpty(user?.client?.printers)
    ? JSON.parse(user?.client?.printers as string)
    : {};

  const isExpanded = expandedData?.length === accordionData?.length;

  const handleExpandAll = () => {
    if (isExpanded) setExpandedData([]);
    else
      setExpandedData(
        accordionData.map((accordion) => accordion.functionality)
      );
  };

  return (
    <div id="branch_printers">
      {clientPrinters ? (
        <>
          {' '}
          <Flex>
            <Typography>Choose default printers</Typography>{' '}
            <Button
              variant="text"
              color={!isExpanded ? 'success' : 'warning'}
              onClick={handleExpandAll}
            >
              {isExpanded ? 'COLLAPSE ALL' : 'EXPAND ALL'}
            </Button>
          </Flex>
          {accordionData.map((acc) => (
            <PrintersAccordion
              functionality={acc.functionality}
              title={acc.accordionTitle}
              errors={errors}
              control={control}
              expandedData={expandedData}
              setExpandedData={setExpandedData}
            />
          ))}
        </>
      ) : (
        <p>Setup default clients printers</p>
      )}
    </div>
  );
};

const Typography = styled.p`
  color: #212b36;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin: 0;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 12px 8px 12px;
  margin-bottom: 16px;
`;

const SelectWrapper = styled.div`
  width: 30%;
  max-width: 220px;
`;
const AccordionStyled = styled(Accordion)({
  padding: 0,
  boxShadow: 'none',
  '& .MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: `1px solid ${tyrioUI.colors.black.B40}`,
    minHeight: '38px',
    padding: 0,
  },
  '& .MuiAccordionSummary-root': { padding: 0 },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0 0 8px 0',
  },
  '& .MuiAccordionDetails-root': {
    paddingLeft: '0',
    paddingTop: '33px',
  },
});

const AccordionDetailsStyled = styled(AccordionDetails)`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const DocumentTypeTitle = styled.h1`
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #212b36;
  margin: 0;
`;

const DocumentSizeText = styled.p`
  color: #919eab;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3px;
  margin: 0 0 16px 0;
`;
export default BranchPrinters;
