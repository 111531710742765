import api from '@tyrio/api-factory';
import { DBDeliveryTypesApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetDeliveryMethods = () => {
  const { data, isFetching, isError } = useQuery(
    ['get_client_delivery_types'],
    () => api.fetch<DBDeliveryTypesApi['list']>('get_client_delivery_types'),

    {}
  );

  return { deliveryMethodData: data, isFetching, isError };
};
