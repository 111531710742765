import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const AdWrapper = styled.div`
  height: 51px;
`;

export const AdImg = styled.img`
  height: 51px;
  width: 100%;
`;

export const StockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StockHeader = styled.div<{ bgColor: string }>`
  width: 100%;
  height: 32px;
  background: ${(props) => props.bgColor};
  padding: 4px 12px;

  // text style
  color: white;
  font-family: 'Barlow';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const StockItemsWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  height: calc(100svh - 600px);
`;
