import * as z from "zod"
import { DBCurrency } from ".prisma/client"
import { CompleteDBPartner, RelatedDBPartnerModel } from "./index"

export const DBPartnerBankModel = z.object({
  id: z.string(),
  partnerId: z.string().nullish(),
  bankName: z.string(),
  iban: z.string(),
  swift: z.string(),
  currency: z.nativeEnum(DBCurrency),
})

export interface CompleteDBPartnerBank extends z.infer<typeof DBPartnerBankModel> {
  partner?: CompleteDBPartner | null
}

/**
 * RelatedDBPartnerBankModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBPartnerBankModel: z.ZodSchema<CompleteDBPartnerBank> = z.lazy(() => DBPartnerBankModel.extend({
  partner: RelatedDBPartnerModel.nullish(),
}))
