import * as z from "zod"
import { CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel, CompleteDBSupplierClientShippingSettings, RelatedDBSupplierClientShippingSettingsModel, CompleteDBProductCategory, RelatedDBProductCategoryModel } from "./index"

export const DBSupplierClientPackageCostModel = z.object({
  id: z.string(),
  supplierClientSettingsId: z.string(),
  supplierClientShippingSettingsId: z.string(),
  rimDiameters: z.number().array(),
  widths: z.number().array(),
  costPerPackage: z.number(),
  createdAt: z.date(),
})

export interface CompleteDBSupplierClientPackageCost extends z.infer<typeof DBSupplierClientPackageCostModel> {
  supplierClientSettings: CompleteDBSupplierClientSettings
  supplierClientShippingSettings: CompleteDBSupplierClientShippingSettings
  categoryIds: CompleteDBProductCategory[]
}

/**
 * RelatedDBSupplierClientPackageCostModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBSupplierClientPackageCostModel: z.ZodSchema<CompleteDBSupplierClientPackageCost> = z.lazy(() => DBSupplierClientPackageCostModel.extend({
  supplierClientSettings: RelatedDBSupplierClientSettingsModel,
  supplierClientShippingSettings: RelatedDBSupplierClientShippingSettingsModel,
  categoryIds: RelatedDBProductCategoryModel.array(),
}))
