import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

export const MICHELIN_CATALOG_RULESET: TRule[] = [
  // Category types
  //  {
  //   type: 'rule.combine',
  //   sourceKeys: ['{{FR}}'],
  //   destination: 'product',
  //   targetKey: 'origin',
  //   delimiter: '',
  // },
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateMichelinSpecialMarkings',
  },
  ...EDI_WHEEL_B4_RULESET,
  ...GLOBAL_RULESET,
];
