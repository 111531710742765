import { DBOrderStatus, DBSalesChannel } from '@prisma/client';
import {
  DBCustomerOrderStatusEnum,
  DBTransferOrderStatusEnum,
} from '@tyrio/dto';

export const ORDER_FILTERS = [
  {
    value: 'supplierName',
    label: 'Supplier name',
  },
  {
    value: 'orderNumber',
    label: 'Purchase order number',
  },
  {
    value: 'reference',
    label: 'Reference',
  },
  {
    value: 'ean',
    label: 'EAN',
  },
  {
    value: 'manufacturerCode',
    label: 'Manufacturer code',
  },
  {
    value: 'supplierReference',
    label: 'Supplier reference',
  },
];

export const SALES_CHANNELS = [
  {
    value: DBSalesChannel.BULK_ORDERS,
    label: 'Bulk orders',
  },
  /* tyre_pos is not currently in function, will be added later */
  // {
  //   value: DBSalesChannel.TYRE_POS,
  //   label: 'Tyrio POS',
  // },
];

export const SUPPLIER_ORDER_STATUSES = [
  {
    value: 'all',
    label: 'ALL',
    color: 'red',
    width: '30px',
  },
  {
    value: DBOrderStatus.DELIVERY_LATE,
    label: 'DELIVERY LATE',
    color: '#D14343',
    width: '135px',
  },
  {
    value: DBOrderStatus.IN_TRANSIT,
    label: 'IN TRANSIT',
    color: '#10B981',
    width: '110px',
  },
  {
    value: DBOrderStatus.PROCESSING,
    label: 'PROCESSING',
    color: '#1976D2',
    width: '120px',
  },
  {
    value: DBOrderStatus.DELIVERED,
    label: 'DELIVERED',
    color: '#10B981',
    width: '105px',
  },
  {
    value: DBOrderStatus.BACKORDER,
    label: 'BACKORDER',
    color: 'black',
    width: '115px',
  },
  {
    value: DBOrderStatus.MISSING,
    label: 'MISSING',
    color: '#D14343',
    width: '95px',
  },
];

export const CUSTOMER_ORDER_STATUSES = [
  {
    value: 'all',
    label: 'ALL',
    color: 'red',
    width: '30px',
  },
  {
    value: DBCustomerOrderStatusEnum.NEW,
    label: 'NEW',
    color: '#D14343',
    width: '70px',
  },
  {
    value: DBCustomerOrderStatusEnum.IN_PROCESS,
    label: 'PROCESSING',
    color: '#10B981',
    width: '120px',
  },
  {
    value: DBCustomerOrderStatusEnum.ORDERED,
    label: 'ORDERED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.SHIPPED,
    label: 'SHIPPED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.PENDING,
    label: 'PENDING',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.ON_HOLD,
    label: 'ON HOLD',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.DELIVERED,
    label: 'DELIVERED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.COLLECTED,
    label: 'COLLECTED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.FITTED,
    label: 'FITTED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.CANCELLED,
    label: 'CANCELLED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.RETURN_REQUESTED,
    label: 'RETURN REQUESTED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.RETURNED,
    label: 'RETURNED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.EXCHANGE_REQUESTED,
    label: 'EXCHANGE REQUESTED',
    color: '#10B981',
    width: '100px',
  },
  {
    value: DBCustomerOrderStatusEnum.EXCHANGED,
    label: 'EXCHANGED',
    color: '#10B981',
    width: '100px',
  },
];

export const TRANSFER_ORDER_STATUSES = [
  {
    value: 'all',
    label: 'ALL',
    color: 'red',
    width: '30px',
  },
  {
    value: DBTransferOrderStatusEnum.IN_PROGRESS,
    label: 'IN PROGRESS',
    color: '#FFCC4D',
    width: '130px',
  },
  {
    value: DBTransferOrderStatusEnum.NEW,
    label: 'NEW',
    color: '#FFCC4D',
    width: '80px',
  },
  {
    value: DBTransferOrderStatusEnum.IN_PROCESS,
    label: 'IN PROCESS',
    color: '#1976D2',
    width: '120px',
  },
  {
    value: DBTransferOrderStatusEnum.SHIPPED,
    label: 'SHIPPED',
    color: '#38A169',
    width: '110px',
  },
];
