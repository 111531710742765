import { Typography } from '@mui/material';
import { TransferOrderByEanResponse } from '@tyrio/dto';
import {
  ProductsQuantity,
  WmsIcon,
  YearChip,
  wmsIcons,
} from '@tyrio/wms-ui-library';
import { useContext } from 'react';
import { StockListContext } from '../../../../context/StockListContext';
import {
  DocumentTitleWrapper,
  DocumentWrapper,
  ItemWrapper,
  ItemWrapperDiv,
  YearsWrapper,
} from './InboundOrders.style';
import moment from 'moment';

interface TransferOrderItemProps {
  item: TransferOrderByEanResponse;
}

const TransferOrderItem = ({ item }: TransferOrderItemProps) => {
  const { onlyAvailable, setActiveDrawer } = useContext(StockListContext);

  return (
    <ItemWrapper>
      <ItemWrapperDiv>
        <a
          href={item.documents?.[0]?.url}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: 'initial',
          }}
          download
        >
          <DocumentWrapper>
            <WmsIcon
              icon={wmsIcons.document}
              id="document_svg"
              sx={{ cursor: 'pointer' }}
            />

            <DocumentTitleWrapper>
              <Typography
                fontSize={16}
                fontWeight={400}
                id="document_name"
                sx={{ cursor: 'pointer' }}
              >
                {item.orderName}
              </Typography>

              <Typography fontSize={12} fontWeight={400} color="#919EAB">
                {`${moment(item.createdAt).format('DD.MM - h:mm')}`}
              </Typography>
            </DocumentTitleWrapper>
          </DocumentWrapper>
        </a>
        <div>
          <Typography fontSize={16} fontWeight={400}>
            {item.branchCity}
          </Typography>

          <Typography fontSize={12} fontWeight={400} color="#919EAB">
            {item.branchName}
          </Typography>
        </div>
      </ItemWrapperDiv>
      <YearsWrapper>
        {item.dots.map(
          (el, index) =>
            el && <YearChip year={el} color="success" key={index} />
        )}
      </YearsWrapper>
      <ProductsQuantity
        reserved={item.reserved | 0}
        available={item.available | 0}
        setActiveDrawer={setActiveDrawer}
        onlyAvailable={onlyAvailable}
      />
    </ItemWrapper>
  );
};

export default TransferOrderItem;
