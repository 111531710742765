/* eslint-disable no-self-compare */
import styled from '@emotion/styled/macro';
import { ExpandMore } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import {
  DBCurrency,
  DBDeliveryTypeEnum,
  DBProductCategory,
  DBShippingDays,
} from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBProductCategoryApi,
  DBSupplierApi,
  DBSupplierClientPackageCostApi,
  DBSupplierClientShippingSettingsApi,
} from '@tyrio/dto';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
  UseFormRegister,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { parseEnumToArray } from '../../../../helpers/enum-parser';
import {
  ExpandCollapseButtons,
  InputLabelStyle,
  TextFieldInput,
} from '../supplier-form/helpers/components';
import { getClientCurrency } from '../supplier-form/helpers/default-values';
import { ValidationProps } from '../supplier-form/helpers/types';
import { ERROR_MESSAGES } from '../supplier-form/helpers/validation';
import {
  AccordionWrapper,
  ChipsWrapper,
  ContactHeader,
  StatusBadge,
} from '../supplier-form/SupplierAdminForm';

interface CustomDividerProps {
  text: string;
}

interface ExpandDataProps {
  locations: number[];
  packageCosts: number[];
}

const DELIVERY_TYPES = parseEnumToArray(DBDeliveryTypeEnum);

export interface SupplierClientShippingSettingsProps {
  selectedSupplier?: DBSupplierApi['getOne']['response'];
  getData: (
    data: DBSupplierClientShippingSettingsApi['create']['requestBody'][]
  ) => void;
  initialData?: DBSupplierClientShippingSettingsApi['create']['requestBody'][];
  getValidationProps: (
    key: string,
    validationRules?: RegisterOptions,
    validationText?: string
  ) => ValidationProps;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: boolean;
  };
  clearErrors: UseFormClearErrors<FieldValues>;
}

const getRimDiameters = () => {
  const rimDiameters = [];
  for (let index = 5; index < 31; index++) {
    rimDiameters.push({
      value: index,
      label: index,
    });
  }
  return rimDiameters;
};

const getWidth = () => {
  const width = [];
  for (let index = 150; index < 326; index++) {
    if (index % 5 === 0)
      width.push({
        value: index,
        label: index,
      });
  }
  return width;
};

const SUPPLIER_CLIENT_PACKAGE_COST_INITIAL_DATA: DBSupplierClientPackageCostApi['create']['requestBody'] =
  {
    categoryIds: [],
    rimDiameters: [],
    widths: [],
    costPerPackage: 0,
  };

const SUPPLIER_CLIENT_SHIPPING_SETTINGS_INITIAL_DATA: DBSupplierClientShippingSettingsApi['create']['requestBody'] =
  {
    locationId: '',
    isActive: false,
    minOrderQty: undefined,
    minQtySurchange: undefined,
    defaultCostPerPackage: undefined,
    dropShipping: false,
    cod: false,
    codSurchange: undefined,
    deliveryToIsland: false,
    islandSurchange: undefined,
    daysToDeliver: undefined,
    maxDaysToDeliver: undefined,
    nextShippingDays: [],
    locationClosedFrom: null,
    locationClosedUntil: null,
    hideStockNonWorkingDays: false,
    showBeforeReopened: undefined,
    supplierClientPackageCost: [],
    defaultShippingOption: 'TRUCK',
  };

const SUPPLIER_CLIENT_SHIPPING_EXPANDED_INITIAL_DATA: ExpandDataProps = {
  locations: [],
  packageCosts: [],
};

const SupplierClientShippingSettings = ({
  selectedSupplier,
  getData,
  initialData = [],
  getValidationProps,
  register,
  errors,
  clearErrors,
}: SupplierClientShippingSettingsProps) => {
  const params = useParams<{ supplierId: string }>();

  const [
    supplierClientShippingSettings,
    setSupplierClientShippingSettingsState,
  ] = useState<DBSupplierClientShippingSettingsApi['create']['requestBody'][]>({
    ...initialData,
  });
  const [categories, setCategories] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const [expandedData, setExpandedData] = useState<ExpandDataProps>({
    ...SUPPLIER_CLIENT_SHIPPING_EXPANDED_INITIAL_DATA,
  });

  const { data: categorydata } = useQuery(
    ['categories_list'],
    () => api.fetch<DBProductCategoryApi['list']>('all_categories'),
    {
      onSuccess: (data: DBProductCategory[]) => {
        const items = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setCategories(items);
      },
    }
  );

  const setSupplierClientShippingSettings = useCallback(
    (data: DBSupplierClientShippingSettingsApi['create']['requestBody'][]) => {
      setSupplierClientShippingSettingsState(data);
      getData(data);
    },
    [getData]
  );

  useEffect(() => {
    let object =
      selectedSupplier?.supplierLocations.map((o) => ({
        ...SUPPLIER_CLIENT_SHIPPING_SETTINGS_INITIAL_DATA,
        locationId: o.id,
      })) ?? [];
    if (!_.isEmpty(params)) {
      object = initialData.map((obj) => ({
        ...obj,
      }));
    }

    setSupplierClientShippingSettings(object);
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoryName = (categroyId: number) => {
    return categorydata?.find((i) => i.id === categroyId)?.name;
  };

  function onValueChange<T>(
    key: keyof DBSupplierClientShippingSettingsApi['create']['requestBody'],
    elementIdx: number,
    value: T,
    errorKey?: string
  ) {
    const changed = supplierClientShippingSettings.map((obj, objIdx) => {
      if (elementIdx === objIdx) {
        return {
          ...obj,
          [key]: value,
        };
      }
      return obj;
    });

    setSupplierClientShippingSettings(changed);
    clearErrors(errorKey);
  }

  function onPackageCostValueChange(
    key: keyof DBSupplierClientPackageCostApi['create']['requestBody'],
    elementIdx: number,
    pcgCostIdx: number,
    value: any // eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    const checkedOption = (
      key: { label: string | number; value: string | number }[]
    ) => {
      if (value[value.length - 1] === 'all') {
        if (value.length - 1 === key.length) {
          return [];
        }
        return key.map((element: { value: string | number }) => element.value);
      } else return value;
    };

    const newState = supplierClientShippingSettings.map((obj, objIdx) => {
      if (elementIdx === objIdx) {
        let changed: DBSupplierClientPackageCostApi['create']['requestBody'];
        obj.supplierClientPackageCost.forEach(
          (packageCostItem, packageCostIdx) => {
            if (packageCostIdx === pcgCostIdx) {
              const selectedDate = (() => {
                switch (key) {
                  case 'categoryIds': {
                    return checkedOption(categories);
                  }
                  case 'widths': {
                    return checkedOption(getWidth());
                  }
                  case 'rimDiameters': {
                    return checkedOption(getRimDiameters());
                  }
                  default: {
                    return value;
                  }
                }
              })();
              changed = {
                ...packageCostItem,
                [key]: selectedDate,
              };
              obj.supplierClientPackageCost[packageCostIdx] = changed;
            }
          }
        );

        return {
          ...obj,
          supplierClientPackageCost: obj.supplierClientPackageCost,
        };
      }
      return obj;
    });

    setSupplierClientShippingSettings(newState);
  }

  const onExpandedDataChange = (
    attributeKey: keyof ExpandDataProps,
    value: number[]
  ) => {
    const object = expandedData[attributeKey];
    if (typeof object !== 'object' && !Array.isArray(object)) return;
    setExpandedData({
      ...expandedData,
      [attributeKey]: value,
    });
  };

  return (
    <AccordionWrapper>
      {supplierClientShippingSettings.length > 0 && (
        <ExpandCollapseButtons
          color={expandedData.locations.length > 0 ? 'warning' : 'success'}
          onExpandedDataChange={() =>
            onExpandedDataChange(
              'locations',
              expandedData.locations.length > 0
                ? []
                : supplierClientShippingSettings.map(
                    (_shippingSettings, index) => index
                  )
            )
          }
          flag={expandedData.locations.length > 0}
        />
      )}

      {supplierClientShippingSettings.length > 0 &&
        supplierClientShippingSettings.map(
          (shippingSettingsItem, shippingSettingsItemIdx) => {
            return (
              <Grid>
                <Accordion
                  expanded={expandedData.locations.includes(
                    shippingSettingsItemIdx
                  )}
                  square
                  defaultExpanded
                  sx={{
                    boxShadow: 0,
                    borderBottom: '1px solid #DFE3E8',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      paddingLeft: '0px',
                    }}
                    onClick={() => {
                      onExpandedDataChange(
                        'locations',
                        expandedData.locations.includes(shippingSettingsItemIdx)
                          ? expandedData.locations.filter(
                              (i) => i !== shippingSettingsItemIdx
                            )
                          : [...expandedData.locations, shippingSettingsItemIdx]
                      );
                    }}
                  >
                    <ContactHeader>
                      <Grid
                        style={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          pointerEvents: 'none',
                        }}
                      >
                        <Grid
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '97%',
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#212B36',
                              fontSize: '18px',
                              fontWeight: '500',
                              lineHeight: '28px',
                              display: 'flex',
                              alignItems: 'center',
                              letterSpacing: '0.3px',
                            }}
                          >
                            {
                              selectedSupplier?.supplierLocations[
                                shippingSettingsItemIdx
                              ]?.locationName
                            }
                          </Typography>
                          <StatusBadge
                            color={
                              shippingSettingsItem.isActive
                                ? '#56C489'
                                : '#E64B4B'
                            }
                          />
                        </Grid>
                      </Grid>
                    </ContactHeader>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      paddingLeft: '0px',
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '35px',
                      }}
                    >
                      <FormControlLabel
                        label="Active"
                        control={
                          <Switch
                            color="info"
                            checked={shippingSettingsItem.isActive}
                            onChange={(e) => {
                              onValueChange(
                                'isActive',
                                shippingSettingsItemIdx,
                                e.target.checked
                              );
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid spacing={2} container>
                      <Grid item xs={12} lg={3}>
                        <TextFieldInput
                          label="Min order quantity *"
                          type="number"
                          value={shippingSettingsItem.minOrderQty?.toString()}
                          onChange={(e) => {
                            const num = Number(e.target.value);
                            const finalNum = num < 0 ? 0 : num;
                            onValueChange(
                              'minOrderQty',
                              shippingSettingsItemIdx,
                              finalNum,
                              `supplierShippingSettings-minOrderQty-${shippingSettingsItemIdx}`
                            );
                          }}
                          validationProps={{
                            ...getValidationProps(
                              `supplierShippingSettings-minOrderQty-${shippingSettingsItemIdx}`
                            ),
                            validationRules: {
                              required: true,
                              min: 0,
                            },
                            validationText: ERROR_MESSAGES.MIN_LENGTH,
                          }}
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <NumericFormat
                          customInput={TextField}
                          label="Min quantity surcharge"
                          value={shippingSettingsItem.minQtySurchange}
                          fullWidth
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          placeholder="0"
                          onChange={(e) => {
                            onValueChange(
                              'minQtySurchange',
                              shippingSettingsItemIdx,
                              e.target.value
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getClientCurrency(DBCurrency.EUR)}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <NumericFormat
                          {...register(
                            `supplierShippingSettings-defaultCostPerPackage-${shippingSettingsItemIdx}`,
                            {
                              validate: () => {
                                if (
                                  shippingSettingsItem.defaultCostPerPackage ===
                                  undefined
                                )
                                  return false;
                                else {
                                  const result = parseFloat(
                                    shippingSettingsItem.defaultCostPerPackage
                                      ?.toString()
                                      .replace(/.|,/g, function (match) {
                                        return match === '.'
                                          ? ''
                                          : match === ','
                                          ? '.'
                                          : match;
                                      }) ?? ''
                                  );
                                  if (isNaN(result)) return false;
                                  else return true;
                                }
                              },
                            }
                          )}
                          error={
                            errors[
                              `supplierShippingSettings-defaultCostPerPackage-${shippingSettingsItemIdx}`
                            ]
                          }
                          helperText={
                            errors[
                              `supplierShippingSettings-defaultCostPerPackage-${shippingSettingsItemIdx}`
                            ]
                              ? shippingSettingsItem.defaultCostPerPackage ===
                                undefined
                                ? 'Field is required'
                                : 'Must be greater than 0'
                              : ''
                          }
                          placeholder="0.00"
                          customInput={TextField}
                          label="Default cost per package *"
                          value={shippingSettingsItem.defaultCostPerPackage}
                          fullWidth
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          onChange={(e) => {
                            onValueChange(
                              'defaultCostPerPackage',
                              shippingSettingsItemIdx,
                              e.target.value,
                              `supplierShippingSettings-defaultCostPerPackage-${shippingSettingsItemIdx}`
                            );
                            clearErrors(
                              `supplierShippingSettings-defaultCostPerPackage-${shippingSettingsItemIdx}`
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getClientCurrency(DBCurrency.EUR)}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl fullWidth>
                          <InputLabel>Default shipping option</InputLabel>
                          <Select
                            label="Default shipping option"
                            value={shippingSettingsItem.defaultShippingOption}
                            onChange={(e) => {
                              onValueChange(
                                'defaultShippingOption',
                                shippingSettingsItemIdx,
                                e.target.value,
                                `supplierShippingSettings-defaultShippingOption-${shippingSettingsItemIdx}`
                              );
                              clearErrors(
                                `supplierShippingSettings-defaultShippingOption-${shippingSettingsItemIdx}`
                              );
                            }}
                          >
                            {DELIVERY_TYPES.map((item) => (
                              <MenuItem value={item.value} key={item.label}>
                                <Typography> {item.label}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container sx={{ marginTop: '30px' }}>
                      <Grid item xs={12} lg={12}>
                        <CustomDivider text="CUSTOM PACKAGE COST" />
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container sx={{ marginTop: '10px' }}>
                      <Grid item xs={12} lg={12}>
                        {shippingSettingsItem.supplierClientPackageCost
                          .length === 0 && (
                          <Alert severity="info">
                            This location doesn't have any custom package cost!
                          </Alert>
                        )}
                        {shippingSettingsItem.supplierClientPackageCost.length >
                          0 && (
                          <ExpandCollapseButtons
                            color={
                              expandedData.packageCosts.length > 0
                                ? 'warning'
                                : 'success'
                            }
                            onExpandedDataChange={() =>
                              onExpandedDataChange(
                                'packageCosts',
                                expandedData.packageCosts.length > 0
                                  ? []
                                  : supplierClientShippingSettings.map(
                                      (_shippingSettings, index) => index
                                    )
                              )
                            }
                            flag={expandedData.packageCosts.length > 0}
                          />
                        )}
                        {shippingSettingsItem.supplierClientPackageCost.length >
                          0 && (
                          <Grid>
                            {shippingSettingsItem.supplierClientPackageCost.map(
                              (item, packageCostIndex) => {
                                const isAllCategoryIdsSelected =
                                  item.categoryIds.length > 0 &&
                                  item.categoryIds.length ===
                                    item.categoryIds.length;
                                const isAllRimDiametersSelected =
                                  item.rimDiameters.length > 0 &&
                                  item.rimDiameters.length ===
                                    item.rimDiameters.length;
                                const isAllWidthsSelected =
                                  item.widths.length > 0 &&
                                  item.widths.length === item.widths.length;
                                return (
                                  <Accordion
                                    expanded={expandedData.packageCosts.includes(
                                      packageCostIndex
                                    )}
                                    elevation={0}
                                    defaultExpanded
                                    sx={{
                                      marginBottom: '10px',
                                      background: ' #F9FAFB',
                                      border: '1px solid #DFE3E8',
                                      borderRadius: '8px',
                                      position: 'unset',
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMore />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      onClick={() => {
                                        onExpandedDataChange(
                                          'packageCosts',
                                          expandedData.packageCosts.includes(
                                            packageCostIndex
                                          )
                                            ? expandedData.packageCosts.filter(
                                                (i) => i !== packageCostIndex
                                              )
                                            : [
                                                ...expandedData.packageCosts,
                                                packageCostIndex,
                                              ]
                                        );
                                      }}
                                    >
                                      <ContactHeader>
                                        <Typography
                                          sx={{
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            letterSpacing: '0.3px',
                                          }}
                                        >
                                          PACKAGE COST #{packageCostIndex + 1}
                                        </Typography>
                                      </ContactHeader>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container columnSpacing={2}>
                                        <Grid item xs={12} lg={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>Category</InputLabel>
                                            <Select
                                              SelectDisplayProps={{
                                                style: {
                                                  padding:
                                                    item.categoryIds.length > 0
                                                      ? '12px 0px 12px 14px'
                                                      : '',
                                                },
                                              }}
                                              style={{ maxWidth: '25vw' }}
                                              sx={{
                                                backgroundColor: 'white',
                                              }}
                                              label="Category"
                                              multiple
                                              value={item.categoryIds}
                                              onChange={(e) => {
                                                onPackageCostValueChange(
                                                  'categoryIds',
                                                  shippingSettingsItemIdx,
                                                  packageCostIndex,
                                                  e.target.value as number[]
                                                );
                                              }}
                                              endAdornment={
                                                <InputAdornment
                                                  position="end"
                                                  sx={{
                                                    marginRight: '8px',
                                                  }}
                                                  onClick={() => {
                                                    onPackageCostValueChange(
                                                      'categoryIds',
                                                      shippingSettingsItemIdx,
                                                      packageCostIndex,
                                                      []
                                                    );
                                                  }}
                                                >
                                                  <IconButton>
                                                    <CancelIcon
                                                      sx={{
                                                        color:
                                                          'rgba(0, 0, 0, 0.26)',
                                                      }}
                                                    />
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                              renderValue={(selected) => (
                                                <ChipsWrapper>
                                                  {(selected as number[]).map(
                                                    (value) => (
                                                      <Chip
                                                        key={value}
                                                        label={getCategoryName(
                                                          value
                                                        )}
                                                        clickable
                                                        deleteIcon={
                                                          <CancelIcon
                                                            onMouseDown={(
                                                              event
                                                            ) =>
                                                              event.stopPropagation()
                                                            }
                                                          />
                                                        }
                                                        onDelete={() => {
                                                          onPackageCostValueChange(
                                                            'categoryIds',
                                                            shippingSettingsItemIdx,
                                                            packageCostIndex,
                                                            item.categoryIds.filter(
                                                              (i) => i !== value
                                                            )
                                                          );
                                                        }}
                                                        sx={{
                                                          backgroundColor:
                                                            'white',
                                                          border:
                                                            '1px solid rgba(0, 0, 0, 0.26);',
                                                        }}
                                                      />
                                                    )
                                                  )}
                                                </ChipsWrapper>
                                              )}
                                            >
                                              <MenuItem value="all">
                                                <ListItemIcon>
                                                  <Checkbox
                                                    checked={
                                                      isAllCategoryIdsSelected
                                                    }
                                                    indeterminate={
                                                      item.categoryIds.length >
                                                        0 &&
                                                      item.categoryIds.length <
                                                        categories.length
                                                    }
                                                  />
                                                </ListItemIcon>
                                                <ListItemText primary="Select All" />
                                              </MenuItem>
                                              {categories.map(
                                                (i: {
                                                  label: string;
                                                  value: number;
                                                }) => (
                                                  <MenuItem
                                                    key={i.value}
                                                    value={i.value}
                                                  >
                                                    <Checkbox
                                                      color="info"
                                                      checked={
                                                        item.categoryIds.indexOf(
                                                          i.value
                                                        ) > -1
                                                      }
                                                    />
                                                    <ListItemText
                                                      primary={i.label}
                                                    />
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>
                                              Rim diameters
                                            </InputLabel>
                                            <Select
                                              SelectDisplayProps={{
                                                style: {
                                                  padding:
                                                    item.rimDiameters.length > 0
                                                      ? '12px 0px 12px 14px'
                                                      : '',
                                                },
                                              }}
                                              style={{ maxWidth: '25vw' }}
                                              sx={{
                                                backgroundColor: 'white',
                                              }}
                                              label="Rim diameters"
                                              multiple
                                              value={item.rimDiameters}
                                              onChange={(e) => {
                                                onPackageCostValueChange(
                                                  'rimDiameters',
                                                  shippingSettingsItemIdx,
                                                  packageCostIndex,
                                                  e.target.value as number[]
                                                );
                                              }}
                                              endAdornment={
                                                <InputAdornment
                                                  position="end"
                                                  sx={{
                                                    marginRight: '8px',
                                                  }}
                                                  onClick={() => {
                                                    onPackageCostValueChange(
                                                      'rimDiameters',
                                                      shippingSettingsItemIdx,
                                                      packageCostIndex,
                                                      []
                                                    );
                                                  }}
                                                >
                                                  <IconButton>
                                                    <CancelIcon
                                                      sx={{
                                                        color:
                                                          'rgba(0, 0, 0, 0.26)',
                                                      }}
                                                    />
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                              renderValue={(selected) => (
                                                <ChipsWrapper>
                                                  {selected.map((value) => (
                                                    <Chip
                                                      key={value}
                                                      label={value}
                                                      clickable
                                                      deleteIcon={
                                                        <CancelIcon
                                                          onMouseDown={(
                                                            event
                                                          ) =>
                                                            event.stopPropagation()
                                                          }
                                                        />
                                                      }
                                                      onDelete={() => {
                                                        onPackageCostValueChange(
                                                          'rimDiameters',
                                                          shippingSettingsItemIdx,
                                                          packageCostIndex,
                                                          item.rimDiameters.filter(
                                                            (i) => i !== value
                                                          )
                                                        );
                                                      }}
                                                      sx={{
                                                        backgroundColor:
                                                          'white',
                                                        border:
                                                          '1px solid rgba(0, 0, 0, 0.26);',
                                                      }}
                                                    />
                                                  ))}
                                                </ChipsWrapper>
                                              )}
                                            >
                                              <MenuItem value="all">
                                                <ListItemIcon>
                                                  <Checkbox
                                                    checked={
                                                      isAllRimDiametersSelected
                                                    }
                                                    indeterminate={
                                                      item.rimDiameters.length >
                                                        0 &&
                                                      item.rimDiameters.length <
                                                        getRimDiameters().length
                                                    }
                                                  />
                                                </ListItemIcon>
                                                <ListItemText primary="Select All" />
                                              </MenuItem>
                                              {getRimDiameters().map((i) => (
                                                <MenuItem
                                                  key={i.value}
                                                  value={i.value}
                                                >
                                                  <Checkbox
                                                    color="info"
                                                    checked={
                                                      item.rimDiameters.indexOf(
                                                        i.value
                                                      ) > -1
                                                    }
                                                  />
                                                  <ListItemText
                                                    primary={i.label}
                                                  />
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>Width</InputLabel>
                                            <Select
                                              SelectDisplayProps={{
                                                style: {
                                                  padding:
                                                    item.widths.length > 0
                                                      ? '12px 0px 12px 14px'
                                                      : '',
                                                },
                                              }}
                                              style={{ maxWidth: '25vw' }}
                                              sx={{
                                                backgroundColor: 'white',
                                              }}
                                              label="Width"
                                              multiple
                                              value={item.widths}
                                              onChange={(e) => {
                                                onPackageCostValueChange(
                                                  'widths',
                                                  shippingSettingsItemIdx,
                                                  packageCostIndex,
                                                  e.target.value as number[]
                                                );
                                              }}
                                              endAdornment={
                                                <InputAdornment
                                                  position="end"
                                                  sx={{
                                                    marginRight: '8px',
                                                  }}
                                                  onClick={() => {
                                                    onPackageCostValueChange(
                                                      'widths',
                                                      shippingSettingsItemIdx,
                                                      packageCostIndex,
                                                      []
                                                    );
                                                  }}
                                                >
                                                  <IconButton>
                                                    <CancelIcon
                                                      sx={{
                                                        color:
                                                          'rgba(0, 0, 0, 0.26)',
                                                      }}
                                                    />
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                              renderValue={(selected) => (
                                                <ChipsWrapper>
                                                  {selected.map((value) => (
                                                    <Chip
                                                      key={value}
                                                      label={value}
                                                      clickable
                                                      deleteIcon={
                                                        <CancelIcon
                                                          onMouseDown={(
                                                            event
                                                          ) =>
                                                            event.stopPropagation()
                                                          }
                                                        />
                                                      }
                                                      onDelete={() => {
                                                        onPackageCostValueChange(
                                                          'widths',
                                                          shippingSettingsItemIdx,
                                                          packageCostIndex,
                                                          item.widths.filter(
                                                            (i) => i !== value
                                                          )
                                                        );
                                                      }}
                                                      sx={{
                                                        backgroundColor:
                                                          'white',
                                                        border:
                                                          '1px solid rgba(0, 0, 0, 0.26);',
                                                      }}
                                                    />
                                                  ))}
                                                </ChipsWrapper>
                                              )}
                                            >
                                              <MenuItem value="all">
                                                <ListItemIcon>
                                                  <Checkbox
                                                    checked={
                                                      isAllWidthsSelected
                                                    }
                                                    indeterminate={
                                                      item.widths.length > 0 &&
                                                      item.widths.length <
                                                        getWidth().length
                                                    }
                                                  />
                                                </ListItemIcon>
                                                <ListItemText primary="Select All" />
                                              </MenuItem>
                                              {getWidth().map((i) => (
                                                <MenuItem
                                                  key={i.value}
                                                  value={i.value}
                                                >
                                                  <Checkbox
                                                    color="info"
                                                    checked={
                                                      item.widths.indexOf(
                                                        i.value
                                                      ) > -1
                                                    }
                                                  />
                                                  <ListItemText
                                                    primary={i.label}
                                                  />
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={2}
                                        display="flex"
                                        sx={{ marginTop: '20px' }}
                                        justifyContent="flex-end"
                                      >
                                        <Grid item xs={12} lg={2.5}>
                                          <NumericFormat
                                            customInput={TextField}
                                            label="Cost per package"
                                            value={item.costPerPackage}
                                            fullWidth
                                            allowLeadingZeros
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            allowNegative={false}
                                            sx={{
                                              backgroundColor: 'white',
                                            }}
                                            onChange={(e) => {
                                              onPackageCostValueChange(
                                                'costPerPackage',
                                                shippingSettingsItemIdx,
                                                packageCostIndex,
                                                e.target.value
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  {getClientCurrency(
                                                    DBCurrency.EUR
                                                  )}
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={2}
                                        display="flex"
                                        sx={{ marginTop: '10px' }}
                                        justifyContent="flex-end"
                                      >
                                        <Grid item>
                                          <Button
                                            color="error"
                                            onClick={() => {
                                              onValueChange(
                                                'supplierClientPackageCost',
                                                shippingSettingsItemIdx,
                                                shippingSettingsItem.supplierClientPackageCost.filter(
                                                  (i, x) =>
                                                    x !== packageCostIndex
                                                )
                                              );
                                            }}
                                          >
                                            DELETE
                                          </Button>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        display="flex"
                                        justifyContent="flex-end"
                                      ></Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              }
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container sx={{ marginTop: '5px' }}>
                      <Grid item xs={12} lg={12}>
                        <Button
                          size="medium"
                          component="label"
                          color="info"
                          endIcon={<AddCircleOutlineOutlinedIcon />}
                          onClick={() => {
                            onValueChange(
                              'supplierClientPackageCost',
                              shippingSettingsItemIdx,
                              [
                                ...shippingSettingsItem.supplierClientPackageCost,
                                SUPPLIER_CLIENT_PACKAGE_COST_INITIAL_DATA,
                              ]
                            );
                            setExpandedData({
                              ...expandedData,
                              packageCosts: [
                                shippingSettingsItem.supplierClientPackageCost
                                  .length,
                              ],
                            });
                          }}
                        >
                          NEW PACKAGE COST
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid spacing={2} container sx={{ marginTop: '10px' }}>
                      <Grid item xs={12} lg={12}>
                        <CustomDivider text="DROP SHIPPING" />
                      </Grid>
                    </Grid>
                    <Grid
                      spacing={2}
                      container
                      sx={{
                        marginTop: '30px',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item xs={12} lg={2.1}>
                        <FormControlLabel
                          label="Drop shipping"
                          control={
                            <Checkbox
                              color="info"
                              checked={shippingSettingsItem.dropShipping}
                              onChange={(e) => {
                                onValueChange(
                                  'dropShipping',
                                  shippingSettingsItemIdx,
                                  e.target.checked
                                );
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormControlLabel
                          label="COD"
                          control={
                            <Checkbox
                              color="info"
                              checked={shippingSettingsItem.cod}
                              onChange={(e) => {
                                onValueChange(
                                  'cod',
                                  shippingSettingsItemIdx,
                                  e.target.checked
                                );
                                clearErrors(
                                  `supplierShippingSettings-codSurchange-${shippingSettingsItemIdx}`
                                );
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={2.4}>
                        <NumericFormat
                          {...register(
                            `supplierShippingSettings-codSurchange-${shippingSettingsItemIdx}`,
                            {
                              validate: () => {
                                if (
                                  shippingSettingsItem.cod &&
                                  shippingSettingsItem.codSurchange ===
                                    undefined
                                ) {
                                  return false;
                                } else {
                                  const result = parseFloat(
                                    shippingSettingsItem.codSurchange
                                      ?.toString()
                                      .replace(/.|,/g, function (match) {
                                        return match === '.'
                                          ? ''
                                          : match === ','
                                          ? '.'
                                          : match;
                                      }) ?? ''
                                  );
                                  if (result === undefined) return false;
                                  return true;
                                }
                              },
                            }
                          )}
                          allowNegative={false}
                          error={
                            errors[
                              `supplierShippingSettings-codSurchange-${shippingSettingsItemIdx}`
                            ]
                          }
                          helperText={
                            errors[
                              `supplierShippingSettings-codSurchange-${shippingSettingsItemIdx}`
                            ] && shippingSettingsItem.codSurchange === undefined
                              ? 'Field is required'
                              : ''
                          }
                          placeholder="0.00"
                          customInput={TextField}
                          label="COD surcharge"
                          value={shippingSettingsItem.codSurchange ?? 0}
                          fullWidth
                          disabled={!shippingSettingsItem.cod}
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          onChange={(e) => {
                            onValueChange(
                              'codSurchange',
                              shippingSettingsItemIdx,
                              e.target.value,
                              `supplierShippingSettings-codSurchange-${shippingSettingsItemIdx}`
                            );
                            clearErrors(
                              `supplierShippingSettings-codSurchange-${shippingSettingsItemIdx}`
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getClientCurrency(DBCurrency.EUR)}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2.5}>
                        <FormControlLabel
                          label="Delivery to Island"
                          control={
                            <Checkbox
                              color="info"
                              checked={shippingSettingsItem.deliveryToIsland}
                              onChange={(e) => {
                                onValueChange(
                                  'deliveryToIsland',
                                  shippingSettingsItemIdx,
                                  e.target.checked
                                );
                                clearErrors(
                                  `supplierShippingSettings-islandSurchange-${shippingSettingsItemIdx}`
                                );
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={2.4}>
                        <NumericFormat
                          {...register(
                            `supplierShippingSettings-islandSurchange-${shippingSettingsItemIdx}`,
                            {
                              validate: () => {
                                if (
                                  shippingSettingsItem.deliveryToIsland &&
                                  shippingSettingsItem.islandSurchange ===
                                    undefined
                                ) {
                                  return false;
                                } else {
                                  const result = parseFloat(
                                    shippingSettingsItem.islandSurchange
                                      ?.toString()
                                      .replace(/.|,/g, function (match) {
                                        return match === '.'
                                          ? ''
                                          : match === ','
                                          ? '.'
                                          : match;
                                      }) ?? ''
                                  );
                                  if (result === undefined) return false;
                                  return true;
                                }
                              },
                            }
                          )}
                          error={
                            errors[
                              `supplierShippingSettings-islandSurchange-${shippingSettingsItemIdx}`
                            ]
                          }
                          helperText={
                            errors[
                              `supplierShippingSettings-islandSurchange-${shippingSettingsItemIdx}`
                            ] &&
                            shippingSettingsItem.islandSurchange === undefined
                              ? 'Field is required'
                              : ''
                          }
                          placeholder="0.00"
                          customInput={TextField}
                          label="Island surcharge"
                          allowNegative={false}
                          value={shippingSettingsItem.islandSurchange ?? 0}
                          fullWidth
                          disabled={!shippingSettingsItem.deliveryToIsland}
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          onChange={(e) => {
                            onValueChange(
                              'islandSurchange',
                              shippingSettingsItemIdx,
                              e.target.value,
                              `supplierShippingSettings-islandSurchange-${shippingSettingsItemIdx}`
                            );
                            clearErrors(
                              `supplierShippingSettings-islandSurchange-${shippingSettingsItemIdx}`
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getClientCurrency(DBCurrency.EUR)}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container sx={{ marginTop: '30px' }}>
                      <Grid item xs={12} lg={12}>
                        <CustomDivider text="DELIVERY TIMES" />
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container sx={{ marginTop: '10px' }}>
                      <Grid item xs={12} lg={3}>
                        <TextFieldInput
                          label="Days to deliver *"
                          value={shippingSettingsItem.daysToDeliver
                            ?.toString()
                            .replace(/^0+/, '')}
                          type="number"
                          onChange={(e) => {
                            let value = Number(e.target.value);
                            if (Number(e.target.value) < 0) value = 0;
                            onValueChange(
                              'daysToDeliver',
                              shippingSettingsItemIdx,
                              value,
                              `supplierShippingSettings-daysToDeliver-${shippingSettingsItemIdx}`
                            );
                          }}
                          validationProps={{
                            ...getValidationProps(
                              `supplierShippingSettings-daysToDeliver-${shippingSettingsItemIdx}`
                            ),
                            validationRules: {
                              required: true,
                              min: 1,
                            },
                            validationText: ERROR_MESSAGES.MIN_LENGTH,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <TextFieldInput
                          label="Max days to deliver *"
                          value={shippingSettingsItem.maxDaysToDeliver
                            ?.toString()
                            .replace(/^0+/, '')}
                          type="number"
                          onChange={(e) => {
                            let value = Number(e.target.value);
                            if (Number(e.target.value) < 0) value = 0;
                            onValueChange(
                              'maxDaysToDeliver',
                              shippingSettingsItemIdx,
                              value,
                              `supplierShippingSettings-maxDaysToDeliver-${shippingSettingsItemIdx}`
                            );
                          }}
                          validationProps={{
                            ...getValidationProps(
                              `supplierShippingSettings-maxDaysToDeliver-${shippingSettingsItemIdx}`
                            ),
                            validationRules: {
                              required: true,
                              min: 1,
                            },
                            validationText: ERROR_MESSAGES.MIN_LENGTH,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl fullWidth>
                          <InputLabelStyle
                            isError={
                              errors[
                                `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`
                              ] ?? false
                            }
                            color={
                              errors[
                                `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`
                              ]
                                ? 'error'
                                : 'primary'
                            }
                          >
                            Next shipping day *
                          </InputLabelStyle>
                          <Select
                            {...register(
                              `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`,
                              {
                                required: true,
                              }
                            )}
                            error={
                              errors[
                                `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`
                              ]
                            }
                            SelectDisplayProps={{
                              style: {
                                padding:
                                  shippingSettingsItem.nextShippingDays.length >
                                  0
                                    ? '12px 14px'
                                    : '',
                              },
                            }}
                            fullWidth
                            label="Next shipping day"
                            multiple
                            value={shippingSettingsItem.nextShippingDays}
                            onChange={(e) => {
                              onValueChange(
                                'nextShippingDays',
                                shippingSettingsItemIdx,
                                e.target.value as DBShippingDays[],
                                `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`
                              );
                            }}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ position: 'absolute', right: '20px' }}
                                onClick={() => {
                                  onValueChange(
                                    'nextShippingDays',
                                    shippingSettingsItemIdx,
                                    [],
                                    `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`
                                  );
                                }}
                              >
                                <IconButton>
                                  <CancelIcon
                                    sx={{
                                      color: 'rgba(0, 0, 0, 0.26)',
                                      position: 'absolute',
                                      right: '20px',
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                            renderValue={(selected) => (
                              <ChipsWrapper
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '7px 17px',
                                }}
                              >
                                {(selected as string[]).map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    clickable
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                    onDelete={() => {
                                      onValueChange(
                                        'nextShippingDays',
                                        shippingSettingsItemIdx,
                                        shippingSettingsItem.nextShippingDays.filter(
                                          (item) => item !== value
                                        ),
                                        `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`
                                      );
                                    }}
                                    sx={{
                                      backgroundColor: 'white',
                                      border: '1px solid rgba(0, 0, 0, 0.26);',
                                    }}
                                  />
                                ))}
                              </ChipsWrapper>
                            )}
                          >
                            {parseEnumToArray(DBShippingDays).map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                <Checkbox
                                  color="info"
                                  checked={shippingSettingsItem.nextShippingDays.includes(
                                    DBShippingDays[
                                      item.value as keyof typeof DBShippingDays
                                    ]
                                  )}
                                />
                                <ListItemText primary={item.label} />
                              </MenuItem>
                            ))}
                          </Select>
                          {errors[
                            `supplierShippingSettings-nextShippingDays-${shippingSettingsItemIdx}`
                          ] && (
                            <FormHelperText sx={{ color: '#d32f2f;' }}>
                              This field is required!
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container sx={{ marginTop: '30px' }}>
                      <Grid item xs={12} lg={12}>
                        <CustomDivider text="NON-WORKING DAYS" />
                      </Grid>
                    </Grid>
                    <Grid
                      spacing={2}
                      container
                      sx={{ marginTop: '30px', alignItems: 'center' }}
                    >
                      <Grid item xs={12} lg={4}>
                        <DateTimePicker
                          label="Closed from"
                          value={shippingSettingsItem.locationClosedFrom}
                          disablePast={true}
                          onChange={(e) => {
                            onValueChange(
                              'locationClosedFrom',
                              shippingSettingsItemIdx,
                              e as Date
                            );
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <DateTimePicker
                          label="Next shipping date"
                          value={shippingSettingsItem.locationClosedUntil}
                          minDate={shippingSettingsItem?.locationClosedFrom}
                          onChange={(e) => {
                            onValueChange(
                              'locationClosedUntil',
                              shippingSettingsItemIdx,
                              e
                            );
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormControlLabel
                          label="Hide stock"
                          control={
                            <Checkbox
                              color="info"
                              checked={
                                shippingSettingsItem.hideStockNonWorkingDays
                              }
                              onChange={(e) => {
                                onValueChange(
                                  'hideStockNonWorkingDays',
                                  shippingSettingsItemIdx,
                                  e.target.checked
                                );
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <TextFieldInput
                          label="Show before reopened"
                          type="number"
                          value={shippingSettingsItem.showBeforeReopened}
                          onChange={(e) => {
                            onValueChange(
                              'showBeforeReopened',
                              shippingSettingsItemIdx,
                              Number(e.target.value)
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          }
        )}
    </AccordionWrapper>
  );
};

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDivider = (props: CustomDividerProps) => {
  return (
    <DividerContainer>
      <Divider
        sx={{
          width: '100%',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: ' 20px',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '0.3px',
          color: '#212B36',
        }}
        textAlign="left"
      >
        {props.text}
      </Divider>
    </DividerContainer>
  );
};

export default SupplierClientShippingSettings;
