import {
  DBDeliveryTypes,
  DBPaymentTypes,
  DBServiceCategory,
} from '@prisma/client';
import { Dispatch, SetStateAction } from 'react';

export const handleActive = (
  id: string,
  setIsActive: Dispatch<SetStateAction<Record<string, boolean>>>,
  setAreAllActive: Dispatch<SetStateAction<boolean>>
) => {
  setIsActive((prevState) => {
    if (prevState[id] === true) setAreAllActive(false);
    return { ...prevState, [id]: !prevState[id] };
  });
};

export const handleAllActive = (
  isActive: Record<string, boolean>,
  setIsActive: Dispatch<SetStateAction<Record<string, boolean>>>,
  areAllActive: boolean,
  setAreAllActive: Dispatch<SetStateAction<boolean>>,
  items: DBServiceCategory[] | DBPaymentTypes[] | DBDeliveryTypes[]
) => {
  if (areAllActive) {
    // set all items to innactive
    Object.keys(isActive).forEach((item) => {
      setIsActive((prevState) => ({
        ...prevState,
        [item]: false,
      }));
    });
    setAreAllActive(false);
  } else if (items.length > 0) {
    // set all items to active
    items.forEach((item) => {
      setIsActive((prevState) => ({
        ...prevState,
        [item.id]: true,
      }));
    });
    setAreAllActive(true);
  }
};
