import { DBClientRebateCalculation } from '@prisma/client';
import { DBProductApi } from './product';
import { Price } from './price-calculation';

interface RebateCalculationApi {
  list: {
    requestBody: never;
    request: CheckRebateReq;
    response: CheckRebateRes;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: CheckRebateReq;
    requestParams: never;
    request: CheckRebateReq;
    response: CheckRebateRes;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}

export interface CheckRebateReq {
  ean: string;
  rebateCalculationId: string;
  priceMeta: {
    supplierId: string;
    priceCalculationId?: string;
    isCheckPrice?: boolean;
    price?: Price;
  };
}

export interface CalculateRebateReq {
  ean?: string | null | undefined;
  rebateCalculation: DBClientRebateCalculation;
  price?: number | null;
}

export interface CalculateRebateRes {
  meta?: RebateCalculationMeta;
  price: string;
}

export interface RebateCalculationMeta {
  rebateCalculation?: Partial<DBClientRebateCalculation>;
  priceType?: RebateCalculationType;
  timestamp?: Date;
  appliedRuleId?: string;
  calculatedPrice?: number | null;
  rules?: RebateCalculationMetaRule[];
}

export interface RebateCalculationMetaRule {
  id: string;
  isApplied: boolean;
  inRange: boolean;
  timestamp: Date | null;
  ruleName: string;
  description: string;
  operator: string;
  checks: Record<string, boolean>;
  rebate: number | null;
}

export enum RebateCalculationType {
  DEFAULT_WITH_REBATE = 'Default rebate calculation',
  RULE_WITH_ACTION_REBATE = 'Rule rebate calculation',
  RULE_WITH_DEFAULT_REBATE_NO_RULES = 'Default rebate calculation when no rules for apply',
  RULE_WITH_DEFAULT_REBATE_NO_RANGE = 'Default rebate calculation when price is not in action range',
  REBATE_IS_ZERO = 'Rebate is zero',
}

export interface CheckRebateRes {
  product?: DBProductApi['getOne']['response'];
  meta?: RebateCalculationMeta;
  price: string;
}

export { RebateCalculationApi };
