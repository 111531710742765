import * as z from "zod"
import { CompleteDBFile, RelatedDBFileModel, CompleteDBClient, RelatedDBClientModel } from "./index"

export const DBDocumentsLookModel = z.object({
  id: z.string(),
  logoId: z.string().nullish(),
  headerText: z.string().nullish(),
  mainTermsText: z.string().nullish(),
  paymentTermsText: z.string().nullish(),
  footerText: z.string().nullish(),
  clientId: z.string(),
})

export interface CompleteDBDocumentsLook extends z.infer<typeof DBDocumentsLookModel> {
  logo?: CompleteDBFile | null
  client?: CompleteDBClient | null
}

/**
 * RelatedDBDocumentsLookModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBDocumentsLookModel: z.ZodSchema<CompleteDBDocumentsLook> = z.lazy(() => DBDocumentsLookModel.extend({
  logo: RelatedDBFileModel.nullish(),
  client: RelatedDBClientModel.nullish(),
}))
