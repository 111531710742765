import { CircularProgress } from '@mui/material';
import { DBLanguage, DBUserRole } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBAdminApi, DBRoleApi } from '@tyrio/dto';
import {
  FormController,
  generateAdminForm,
  TyrioSelectInputOption,
} from '@tyrio/forms';
import {
  DeleteModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';
import { useAuth } from '../../context/AuthContext';

const AdminUpsertForm = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { adminId } = useParams<{ adminId: string }>();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [rolesDataDropdownData, setRolesDataDropdownData] = useState<
    TyrioSelectInputOption[]
  >([]);
  const [languagesDropDownData, setLanguagesDropDownData] = useState<
    TyrioSelectInputOption[]
  >([]);

  const [partialAdminData, setPartialAdminData] = useState<
    Partial<DBAdminApi['create']['request']> | undefined
  >(undefined);

  const { user } = useAuth();

  const loggedUserAdminId = user?.adminId || undefined;

  //GET roles and languages for dropdown in form
  useQuery(
    ['role_languages_list'],
    () =>
      api.fetch<DBRoleApi['list']>('role_list', {
        isActive: true,
      }),
    {
      onSuccess: (roles: DBRoleApi['list']['response']) => {
        const dropdownItems = roles.data.map(
          (item: DBRoleApi['getOne']['response']) => ({
            label: item.roleName,
            value: item.id,
          })
        );
        const languages = Object.values(DBLanguage).map((item: string) => ({
          label: capitalize(item),
          value: item,
        }));
        setLanguagesDropDownData(languages);
        setRolesDataDropdownData(dropdownItems);
      },
    }
  );

  //GET admin by ID
  const {
    data: admin_data,
    isFetching: isAdminDataFetching,
    refetch: getAdminById,
  } = useQuery(
    ['get_admin_id', adminId, 'update_admin'],
    () =>
      api.fetch<DBAdminApi['getOne']>(`admin_id`, {
        id: adminId,
      }),
    {
      enabled: !!adminId,
    }
  );

  //CREATE new admin mutation
  const createAdminMutation = useMutation(
    (admin: DBAdminApi['create']['request']) =>
      api.fetch<DBAdminApi['create']>('create_admin', {
        ...admin,
      }),
    {
      mutationKey: 'create_admin',
      onSuccess: (data) => {
        queryClient.refetchQueries('admin_list');
        ToastHelper.showToast(
          'Admin',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        history.push(`/dashboard/admin/${data.id}`);
        setPartialAdminData({});
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  //UPDATE admin mutation
  const updateAdminMutation = useMutation(
    (admin: DBAdminApi['updateOne']['request']) =>
      api.fetch<DBAdminApi['updateOne']>('update_admin', {
        ...admin,
      }),
    {
      mutationKey: 'update_admin',
      onSuccess: () => {
        queryClient.refetchQueries('admin_list');
        getAdminById();
        ToastHelper.showToast(
          'Admin',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        setPartialAdminData({});
      },
      onError: () => {
        ToastHelper.showToast('Admin', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  //DELETE admin mutation
  const deleteAdminMutation = useMutation(
    () =>
      api.fetch<DBAdminApi['getOne']>('delete_admin', {
        id: adminId,
      }),
    {
      mutationKey: 'delete_admin',
      onSuccess: () => {
        queryClient.refetchQueries('admin_list');
        history.push(`/dashboard/admin/`);
        ToastHelper.showToast(
          'Admin',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast('Admin', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  const handleUpsertAdmin = (data: z.infer<typeof zodSchema>) => {
    setPartialAdminData({
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobilePhone: data.mobilePhone,
        birthday: data.birthday?.toString() || '',
        remark: data.remark || '',
        userRole: DBUserRole.ADMIN,
        roleId: data.roleId,
        language: DBLanguage[data.language as keyof typeof DBLanguage],
      },
    });
    if (adminId) {
      const admin_data: DBAdminApi['updateOne']['request'] = {
        id: adminId,
        user: {
          roleId: data.roleId,
          remark: data.remark,
          language: DBLanguage[data.language as keyof typeof DBLanguage],
          firstName: data.firstName,
          lastName: data.lastName,
          birthday: data.birthday?.toString() || '',
        },
      };
      updateAdminMutation.mutate(admin_data);
    } else {
      const admin_data: DBAdminApi['create']['request'] = {
        user: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobilePhone: data.mobilePhone,
          birthday: data.birthday?.toString() || '',
          remark: data.remark || '',
          userRole: DBUserRole.ADMIN,
          roleId: data.roleId,
          language: DBLanguage[data.language as keyof typeof DBLanguage],
        },
      };
      createAdminMutation.mutate(admin_data);
    }
  };

  const handleOnCancel = () => {
    history.push(`/dashboard/admin`);
  };

  const handleDeleteAdmin = () => {
    if (adminId) {
      setIsDeleteModalVisible(true);
    }
  };

  const handleChangeAdminStatus = () => {
    if (adminId) {
      updateAdminMutation.mutate({ id: adminId, active: !admin_data?.active });
    }
  };

  const isAdminOrSuperAdmin: boolean =
    user?.roleId === '00000000-0000-0000-0000-000000000001' ||
    user?.roleId === '00000000-0000-0000-0000-000000000002';

  const { form, zodSchema } = generateAdminForm(
    rolesDataDropdownData ?? [],
    languagesDropDownData ?? [],
    adminId !== undefined,
    !isAdminOrSuperAdmin
  );

  return (
    <PageTemplateContent>
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            deleteAdminMutation.mutate();
            setIsDeleteModalVisible(false);
          }}
          itemName={admin_data?.user?.firstName || ''}
        />
      )}
      {isAdminDataFetching && adminId ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <FormController<z.infer<typeof zodSchema>>
          form={form}
          key={admin_data?.id || Date.now().toString()}
          initialValues={{
            roleId: admin_data?.user?.roleId || partialAdminData?.user?.roleId,
            language:
              admin_data?.user?.language || partialAdminData?.user?.language,
            firstName:
              admin_data?.user?.firstName || partialAdminData?.user?.firstName,
            birthday: admin_data?.user?.birthday
              ? moment(admin_data?.user?.birthday).toDate()
              : partialAdminData?.user?.birthday
              ? moment(
                  partialAdminData?.user?.birthday || 'mm/dd/yyyy'
                ).toDate()
              : null,
            lastName:
              admin_data?.user?.lastName || partialAdminData?.user?.lastName,
            email: admin_data?.user?.email || partialAdminData?.user?.email,
            mobilePhone:
              admin_data?.user?.mobilePhone ||
              partialAdminData?.user?.mobilePhone,
            remark:
              admin_data?.user?.remark || '' || partialAdminData?.user?.remark,
          }}
          isDeleteDisabled={loggedUserAdminId === adminId}
          zodSchema={zodSchema}
          onSubmit={(data) => handleUpsertAdmin(data)}
          onDelete={handleDeleteAdmin}
          onCancel={handleOnCancel}
          onChangeStatus={handleChangeAdminStatus}
          active={admin_data ? admin_data.active : true}
          title={
            adminId
              ? [admin_data?.user?.firstName, admin_data?.user?.lastName].join(
                  ' '
                )
              : t('Create new admin')
          }
          objectId={admin_data?.id}
        />
      )}
    </PageTemplateContent>
  );
};

export default AdminUpsertForm;
