import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { GUMIIMPEX_PRICELIST_RULESET } from './gumiimpex/pricelist';
import { GUMIIMPEX_STOCKLIST_RULESET } from './gumiimpex/stocklist';
import { TRule } from '../typings';

export const GUMIIMPEX_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: GUMIIMPEX_PRICELIST_RULESET,
  stockRuleset: GUMIIMPEX_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'GUMI-IMPEX',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    VP_sa_popustom: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          item['EAN'] !== '6953913191959' && !item['Naziv'].includes('DOT')
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          item['EAN'] !== '6953913191959' && !item['Naziv'].includes('DOT')
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          item['EAN'] !== '6953913191959' && !item['Naziv'].includes('DOT')
      ),
      this.preprocessKeys
    );
  },
};
