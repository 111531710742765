import styled from '@emotion/styled';
import Chip from '@mui/material/Chip/Chip';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi } from '@tyrio/dto';
import _, { startCase } from 'lodash';
import { useQuery } from 'react-query';
import { InputOption } from '../../../../../helpers/enum-parser';

interface CustomerOrderFiltersProps {
  filterValues: qs.ParsedQs;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
  filtersWithLabel?: Record<string, InputOption[]>;
}
const CustomerOrderFilters = ({
  filterValues,
  setFilterValue,
  filtersWithLabel,
}: CustomerOrderFiltersProps) => {
  const get = (path: string) => _.get(filterValues, path, []) as string[];

  const handleDelete = (path: string, key: string) => {
    setFilterValue({
      [path]: get(path).filter((elem) => elem !== key),
    });
  };

  const categoryId = filterValues['category_id']?.toString();

  //GET category by ID
  const { data: category_data } = useQuery(
    ['get_category_id', categoryId],
    () =>
      api.fetch<DBProductCategoryApi['getOne']>(`category_id`, {
        id: Number(categoryId),
      }),
    {
      enabled: !!categoryId,
    }
  );

  return (
    <Container>
      {/* <Grid sx={{ width: '100%', display: 'flex' }}>
        {category_data === undefined &&
          categoryId === undefined &&
          get('paymentMethod').length === 0 &&
          get('branch').length === 0 &&
          get('suppliers').length === 0 &&
          get('orderStatus').length === 0 &&
          get('paymentStatus').length === 0 && (
            <Alert sx={{ width: '100%' }} severity="warning">
              No additional filters selected! If you want, you can{' '}
              <strong style={{ cursor: 'pointer' }} onClick={onOpen}>
                choose
              </strong>{' '}
              some filters!
            </Alert>
          )}
      </Grid> */}
      {category_data && categoryId && (
        <>
          <Item>Category:</Item>
          <Chip
            label={
              category_data.parent_category_name
                ? category_data.parent_category_name + '>' + category_data.name
                : category_data.name
            }
            variant="outlined"
            color="info"
            onDelete={() =>
              setFilterValue({
                category_id: undefined,
              })
            }
            key={categoryId}
            style={{ marginBottom: 8 }}
          />
        </>
      )}
      {get('paymentMethod').length > 0 && (
        <>
          <Item>Payment method:</Item>
          {get('paymentMethod').map((elem) => (
            <Chip
              label={startCase(elem)}
              variant="outlined"
              color="info"
              onDelete={() => handleDelete('paymentMethod', elem)}
              key={elem}
              style={{ marginBottom: 8 }}
            />
          ))}
        </>
      )}
      {get('branch').length > 0 && (
        <>
          <Item>Branch:</Item>
          {get('branch').map((elem) => {
            if (filtersWithLabel)
              return Object.values(filtersWithLabel['branches']).map((item) => {
                if (item.value === elem) {
                  return (
                    <Chip
                      label={item.label}
                      variant="outlined"
                      color="info"
                      onDelete={() => handleDelete('branch', elem)}
                      key={elem}
                      style={{ marginBottom: 8 }}
                    />
                  );
                }
                return null;
              });
            return null;
          })}
        </>
      )}
      {get('suppliers').length > 0 && (
        <>
          <Item>Suppliers:</Item>
          {get('suppliers').map((elem) => {
            if (filtersWithLabel)
              return Object.values(filtersWithLabel['suppliers']).map(
                (item) => {
                  if (item.value === elem) {
                    return (
                      <Chip
                        label={item.label}
                        variant="outlined"
                        color="info"
                        onDelete={() => handleDelete('suppliers', elem)}
                        key={elem}
                        style={{ marginBottom: 8 }}
                      />
                    );
                  }
                  return null;
                }
              );
            return null;
          })}
        </>
      )}
      {get('orderStatus').length > 0 && (
        <>
          <Item>Order status:</Item>
          {get('orderStatus').map((elem) => (
            <Chip
              label={startCase(elem)}
              variant="outlined"
              color="info"
              onDelete={() => handleDelete('orderStatus', elem)}
              key={elem}
              style={{ marginBottom: 8 }}
            />
          ))}
        </>
      )}
      {get('paymentStatus').length > 0 && (
        <>
          <Item>Payment status:</Item>
          {get('paymentStatus').map((elem) => (
            <Chip
              label={startCase(elem)}
              variant="outlined"
              color="info"
              onDelete={() => handleDelete('paymentStatus', elem)}
              key={elem}
              style={{ marginBottom: 8 }}
            />
          ))}
        </>
      )}
    </Container>
  );
};

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-style: italic;
  margin-bottom: 8px !important;
`;

const Container = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 16px;
`;

export default CustomerOrderFilters;
