import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PhotoIcon from '@mui/icons-material/Photo';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Collapse from '@mui/material/Collapse/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel';
import { DBProductModelApi, PhotoShape } from '@tyrio/dto';
import { ProductItems } from '@tyrio/products';
import { tyrioIcons } from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import api from '@tyrio/api-factory';
import { getProductMetadataString } from './helpers';
import qs from 'qs';
import { editIcon } from '@tyrio/ui-library';
import { Tooltip } from '@mui/material';
import { useAuth } from '../../../../context/AuthContext';

interface ProductsTableProps {
  data: DBProductModelApi['getOne']['response'][];
  searchFilter: string;
  searchInput: string;
  sortKey: string;
  sortParam: 'desc' | 'asc' | undefined;
  updateKeyParamValues: (val: {
    sortKey: string;
    sortParam: 'asc' | 'desc';
  }) => void;
  filterValues: qs.ParsedQs;
}

const ProductsTable = ({
  data,
  sortKey,
  filterValues,
  sortParam,
  updateKeyParamValues,
}: ProductsTableProps) => {
  const { user } = useAuth();

  const roleType = user?.userRole;

  const [opens, setOpens] = useState<Record<string, boolean>>({});
  const defaultState = {};
  const history = useHistory();

  const [productModelId, setProductModelId] = useState<string>();

  const switchSort = (sortParam: 'asc' | 'desc', sortKey: string) => {
    updateKeyParamValues({
      sortKey,
      sortParam: sortParam === 'desc' ? 'asc' : 'desc',
    });
  };

  const {
    data: products_data,
    isLoading: isProductsDataFetching,
    mutate: getProducts,
  } = useMutation(
    () =>
      api.fetch<DBProductModelApi['getOne']>(`get_product_model_by_id`, {
        id: productModelId,
        ...filterValues,
      }),
    {
      mutationKey: ['get_product_model_by_id', JSON.stringify(filterValues)],
    }
  );

  useEffect(() => {
    getProducts();
  }, [getProducts, productModelId, setProductModelId, filterValues]);

  return (
    <TableContainer component={Paper} style={{ borderRadius: 0 }}>
      <Table sx={{ overflowX: 'hidden' }} aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ background: '#ECF0F4' }}>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Photo</StyledTableCell>
            <StyledTableCell>Category</StyledTableCell>

            <StyledTableCell>Subcategory</StyledTableCell>
            <StyledTableCell>
              <SortLabel
                active={sortKey === 'brand'}
                direction={sortParam ? sortParam : 'asc'}
                onClick={() => switchSort(sortParam || 'asc', 'brand')}
              >
                Brand
              </SortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <SortLabel
                active={sortKey === 'modelName'}
                direction={sortParam ? sortParam : 'asc'}
                onClick={() => switchSort(sortParam || 'asc', 'modelName')}
              >
                Product Model Name
              </SortLabel>
            </StyledTableCell>
            <StyledTableCell>Product Options</StyledTableCell>
            <StyledTableCell>
              <SortLabel
                active={sortKey === 'active'}
                direction={sortParam ? sortParam : 'asc'}
                onClick={() => {
                  switchSort(sortParam || 'asc', 'active');
                }}
              >
                Active
              </SortLabel>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((el) => (
            <>
              <StyledTableRow
                key={el.uid}
                sx={
                  opens[el.uid]
                    ? { background: '#1976D214' }
                    : { background: 'white' }
                }
                onClick={() => {
                  setProductModelId(el.uid);
                  setOpens({ ...defaultState, [el.uid]: !opens[el.uid] });
                }}
              >
                <StyledTableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setProductModelId(el.uid);
                      setOpens({ ...defaultState, [el.uid]: !opens[el.uid] });
                    }}
                  >
                    {opens[el.uid] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                  {el.photos &&
                  (el.photos as unknown as PhotoShape[])?.length > 0 ? (
                    <img
                      src={(el.photos as unknown as PhotoShape[])[0].url}
                      alt="category"
                      width="24px"
                    />
                  ) : (
                    <PhotoIcon color="disabled" sx={{ fontSize: '30px' }} />
                  )}
                </StyledTableCell>
                {el.category?.parent_category_id ? (
                  <>
                    <StyledTableCell>
                      {el.category?.parent_category_name}
                    </StyledTableCell>
                    <StyledTableCell>{el.category?.name}</StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell>{el.category?.name}</StyledTableCell>
                    <StyledTableCell>/</StyledTableCell>
                  </>
                )}
                <StyledTableCell>{el.brand}</StyledTableCell>
                <StyledTableCell>
                  {el.modelName}
                  <Description>{el.descIntro} </Description>
                </StyledTableCell>
                <StyledTableCell>
                  {getProductMetadataString(el)}
                </StyledTableCell>
                <StyledTableCell>
                  <img
                    src={
                      el.active ? tyrioIcons.greenBadge : tyrioIcons.redBadge
                    }
                    alt="active-badge"
                    width="8px"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip
                    children={
                      <img
                        src={editIcon}
                        alt="edit-icon"
                        onClick={() => {
                          history.push(`/dashboard/product-models/${el.uid}`);
                        }}
                      />
                    }
                    title={'Edit/View Model'}
                    style={{ cursor: 'pointer' }}
                  />
                </StyledTableCell>
              </StyledTableRow>

              <TableRow key={el.uid + '_collapsed_items'}>
                <TableCell
                  style={{
                    padding: 0,
                  }}
                  colSpan={9}
                >
                  <Collapse in={opens[el.uid]} timeout="auto" unmountOnExit>
                    <Box>
                      <Table>
                        <TableBody>
                          {isProductsDataFetching ? (
                            <Loader>
                              <CircularProgress />
                            </Loader>
                          ) : products_data?.DBProduct &&
                            products_data?.DBProduct.length !== 0 ? (
                            <ProductsContainer>
                              <ProductItems
                                data={products_data?.DBProduct}
                                roleType={roleType}
                                modelSidewall={products_data.sidewall}
                              />
                            </ProductsContainer>
                          ) : (
                            <TableRow>
                              <StyledTableCell
                                colSpan={9}
                                style={{ cursor: 'default' }}
                              >
                                There are no items for this model.
                              </StyledTableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <NewModel colSpan={9} align="right">
                              {opens[el.uid] && user?.adminId ? (
                                <Button
                                  data-cy="newItem"
                                  variant="contained"
                                  color="info"
                                  endIcon={<AddIcon />}
                                  onClick={() =>
                                    history.push(
                                      `/dashboard/products/${el.uid}/new`
                                    )
                                  }
                                  disableElevation
                                >
                                  NEW ITEM
                                </Button>
                              ) : (
                                <span></span>
                              )}
                            </NewModel>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Description = styled.div`
  color: #919eab;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  :hover {
    background: #fff8e5;
  }
`;

const StyledTableCell = styled(TableCell)`
  text-align: center;
`;

const SortLabel = styled(TableSortLabel)`
  padding-left: 20px;
`;

const NewModel = styled(TableCell)`
  background: #ecf0f4;
  padding: 10px;
  padding-right: 20px;
  height: 60px;
`;

const ProductsContainer = styled.div`
  width: 100%;
  border-left: 3px solid #1976d2;
`;

const Loader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

export default ProductsTable;
