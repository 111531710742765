/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DBRepeatUnit, DBSupplier } from '@prisma/client';
import {
  ClientConnectionDataArray,
  DBSupplierConnectionResponse,
} from '@tyrio/dto';
import * as _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
} from 'react-hook-form';
import {
  FileControlButtons,
  PasswordField,
  SelectList,
  TextFieldInput,
} from '../../supplier-form/helpers/components';
import { dropdownDataDefaultProps } from '../../supplier-form/helpers/default-values';
import { ValidationProps } from '../../supplier-form/helpers/types';
import { getConnectionDetails } from '../helpers';

interface Props {
  c: DBSupplierConnectionResponse;
  connectionData: ClientConnectionDataArray;
  setConnectionData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  getValidationProps: (
    key: string,
    validationRules?: RegisterOptions,
    validationText?: string
  ) => ValidationProps;
  clearErrors: UseFormClearErrors<FieldValues>;
  supplierData: DBSupplier;
}

export const ClientConnectionFtpConfiguration = ({
  c,
  connectionData = [],
  setConnectionData,
  getValidationProps,
  clearErrors,
  supplierData,
}: Props) => {
  const rootItem = useMemo(() => {
    return connectionData.find((item) => item.adminConnectionId === c.id);
  }, [c.id, connectionData]);
  const idx = useMemo(() => {
    const caughtIdx = connectionData.findIndex(
      (item) => item.adminConnectionId === c.id
    );
    if (caughtIdx === -1) return connectionData.length;
    return caughtIdx;
  }, [c.id, connectionData]);

  const [itemValue, setItemValueState] = useState(
    Object.assign(
      {
        adminConnectionId: c.id,
        type: 'FTP',
        connectionType: c.connectionTypes,
        connectionDetails: {
          ftpHost: '',
          ftpPort: '',
          fileName: '',
          username: '',
          password: '',
          repeatEvery: 0,
          repeatUnit: DBRepeatUnit.HOURS,
          startUpdateTime: new Date(),
          remark: '',
        },
      },
      rootItem
    )
  );

  const supplierId = (c as any).supplierId;

  const connection = getConnectionDetails(c, itemValue);

  const setItemValue = useCallback(
    (data: any) => {
      const copied = _.cloneDeep(connectionData);
      copied[idx] = data;
      setItemValueState(data);
      setConnectionData(copied);
    },
    [connectionData, idx, setConnectionData]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          multiple
          id={c.id + '-Connection type'}
          value={c.connectionTypes}
          renderInput={(params) => (
            <TextField {...params} label="Connection type" />
          )}
          renderTags={(tagValue: string[], getTagProps) =>
            c.connectionTypes.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} disabled />
            ))
          }
          disabled
          options={c.connectionTypes}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <FormControl fullWidth>
          <InputLabel id={c.id + '-protocol-label'}>
            Connection protocol
          </InputLabel>
          <Select
            labelId={c.id + '-protocol-label'}
            id={c.id + '-protocol'}
            value={c.connectionProtocol}
            label="Connection protocol"
            onChange={() => null}
            disabled
          >
            <MenuItem value={c.connectionProtocol}>
              {c.connectionProtocol}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={3}>
        <FormControl fullWidth>
          <InputLabel id={c.id + '-file-format-label'}>File format</InputLabel>
          <Select
            labelId={c.id + '-file-format-label'}
            id={c.id + '-file-format'}
            value={`.${c.fileFormat.toLowerCase()}`}
            label="File format"
            onChange={() => null}
            disabled
          >
            <MenuItem
              value={`.${c.fileFormat.toLowerCase()}`}
            >{`.${c.fileFormat.toLowerCase()}`}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={12}>
        <TextFieldInput
          value={itemValue?.connectionDetails.fileName}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                fileName: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-ftpConnection-fileName-${c.ftpConnection?.id}`
            );
          }}
          label="File path"
          placeholder="eg. /var/www/suppliers/MY_FILE.csv"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-ftpConnection-fileName-${c.ftpConnection?.id}`
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <TextFieldInput
          value={itemValue?.connectionDetails.ftpHost}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                ftpHost: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-ftpConnection-ftpHost-${c.ftpConnection?.id}`
            );
          }}
          label="FTP Host"
          placeholder="ftp.my-ftp.com"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-ftpConnection-ftpHost-${c.ftpConnection?.id}`
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextFieldInput
          value={itemValue?.connectionDetails.ftpPort}
          onChange={(e) =>
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                ftpPort: e.target.value,
              },
            })
          }
          label="FTP Port"
          placeholder="21"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextFieldInput
          value={itemValue?.connectionDetails.username}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                username: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-ftpConnection-username-${c.ftpConnection?.id}`
            );
          }}
          label="FTP Username"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-ftpConnection-username-${c.ftpConnection?.id}`
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <PasswordField
          value={itemValue?.connectionDetails.password}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                password: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-ftpConnection-password-${c.ftpConnection?.id}`
            );
          }}
          label="FTP Password"
          validationProps={{
            ...getValidationProps(
              `connectionDetails-ftpConnection-password-${c.ftpConnection?.id}`
            ),
          }}
        />
      </Grid>

      {/*/////////////////////*/}
      {/*/////////////////////*/}
      {/*/////////////////////*/}
      {/*/////////////////////*/}
      {/*/////////////////////*/}
      <Grid item xs={12} lg={5}>
        <DateTimePicker
          label="Start update At"
          value={itemValue?.connectionDetails.startUpdateTime}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                startUpdateTime: e,
              },
            });
            clearErrors(
              `connectionDetails-ftpConnection-startUpdateTime-${c.ftpConnection?.id}`
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              helperText="Choose date and time of first update"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextFieldInput
          type="number"
          label="Repeat Every"
          helperText="How often to repeat"
          value={itemValue?.connectionDetails.repeatEvery}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                repeatEvery: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-ftpConnection-repeatEvery-${c.ftpConnection?.id}`
            );
          }}
          validationProps={{
            ...getValidationProps(
              `connectionDetails-ftpConnection-repeatEvery-${c.ftpConnection?.id}`
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <SelectList
          value={itemValue?.connectionDetails.repeatUnit}
          label="Time Unit"
          dropdownData={dropdownDataDefaultProps.timeUnits}
          onChange={(e) => {
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                repeatUnit: e.target.value,
              },
            });
            clearErrors(
              `connectionDetails-ftpConnection-repeatUnit-${c.ftpConnection?.id}`
            );
          }}
          validationProps={{
            ...getValidationProps(
              `connectionDetails-ftpConnection-repeatUnit-${c.ftpConnection?.id}`
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <FileControlButtons
          supplierId={supplierId}
          supplierName={supplierData.companyOfficialName}
          connection={connection}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <TextField
          fullWidth
          value={itemValue?.connectionDetails.remark}
          onChange={(e) =>
            setItemValue({
              ...itemValue,
              connectionDetails: {
                ..._.get(itemValue, `connectionDetails`, {}),
                remark: e.target.value,
              },
            })
          }
          id="remark"
          label="Remark"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};
