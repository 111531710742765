import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProductModelList from '../features/products/pages/product-models/ProductModelList';
import { ProductModelWrapper } from '../features/products/pages/ProductModelWrapper';

export const ProductModelRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ProductModelList />
      </Route>
      <Route exact path={`${path}/:id`}>
        <ProductModelWrapper />
      </Route>
    </Switch>
  );
};
