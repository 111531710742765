import { DBProductCategory } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBProductCategoryApi,
  DBProductModelApi,
  DBProductModelPayload,
  PhotoShape,
} from '@tyrio/dto';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../../components/PageNotFound/PageNotFound';
import { useAuth } from '../../../context/AuthContext';
import ProductModelPage from './ProductModelPage';

export const ProductModelWrapper = () => {
  const { user } = useAuth();
  const params = useParams();
  const pathId = _.get(params, 'id');
  const productId = pathId !== 'new' ? pathId : undefined;
  const photoss: Record<string, PhotoShape> = {};

  const [ready, setReady] = useState(!productId);

  const {
    data: productData,
    isFetching,
    isRefetching,
  } = useQuery(
    ['get_product_model_by_id', productId],
    () =>
      api.fetch<DBProductModelApi['getOne']>(`get_product_model_by_id`, {
        id: productId,
      }),
    {
      enabled: !!productId,
      onSuccess(data: DBProductModelPayload) {
        data?.photos &&
          (data?.photos as unknown as PhotoShape[])?.length > 0 &&
          ((data?.photos as unknown as PhotoShape[]).forEach((photo) => {
            return _.merge(photoss, { [photo.url]: photo });
          }) as unknown as Record<string, PhotoShape>);
      },
    }
  );

  const [modelPhotos, setModelPhotos] = useState<Record<string, PhotoShape>>(
    productData?.photos &&
      (productData?.photos as unknown as PhotoShape[])?.length > 0 &&
      photoss
      ? photoss
      : {}
  );

  const { data: mainCategories } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  );

  const shapedCategories = useMemo(() => {
    const response: Record<number, DBProductCategory> = {};
    mainCategories?.forEach((c) => {
      response[c.id] = c;
    });

    return response;
  }, [mainCategories]);

  useEffect(() => {
    const images: Record<string, PhotoShape> = {};
    productData?.photos &&
      (productData?.photos as unknown as PhotoShape[])?.length > 0 &&
      ((productData?.photos as unknown as PhotoShape[]).forEach((photo) => {
        return _.merge(images, { [photo.url]: photo });
      }) as unknown as Record<string, PhotoShape>);
    setModelPhotos(images);
  }, [productData?.photos]);

  useEffect(() => {
    if (isFetching && !isRefetching) setReady(false);
    if (!productId) setReady(true);
  }, [isFetching, isRefetching, productId]);

  useEffect(() => {
    if (!ready && !!productData && !!mainCategories && !isFetching)
      setReady(true);
  }, [isFetching, isRefetching, mainCategories, productData, ready]);

  if (!ready) return <div>LOADING...</div>;

  if (!user?.adminId && pathId === 'new') return <PageNotFound />;

  return (
    <ProductModelPage
      model={productData}
      shapedCategories={shapedCategories}
      permissions={
        !user?.adminId
          ? { edit: false, create: false, delete: false, view: true }
          : { edit: true, create: true, delete: true, view: true }
      }
      modelPhotos={modelPhotos}
      setModelPhotos={setModelPhotos}
    />
  );
};
