import { createTheme } from '@mui/material';
import { colors } from '../core';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    info: {
      main: colors.lightBlue,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'Barlow',
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body2: {
      fontSize: 14,
    },
  },
});
