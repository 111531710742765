export const COLOR_FINISH = {
  standard: 'standard',
  matte: 'matte',
  gloss: 'gloss',
  satin: 'satin',
  chrome: 'chrome',
  polished: 'polished',
  brushed: 'brushed',
  textured: 'textured',
  custom: 'custom',
};

export const COLOR_GROUP = {
  silver: 'silver',
  red: 'red',
  bronze: 'bronze',
  black: 'black',
  grey: 'grey',
  titan: 'titan',
  chrome: 'chrome',
  white: 'white',
  green: 'green',
  gold: 'gold',
  orange: 'orange',
  yellow: 'yellow',
  blue: 'blue',
  carbon: 'carbon',
  purple: 'purple',
  custom: 'custom',
  other: 'other',
};

export const COLOR_GROUP_MAPPING = {
  [COLOR_GROUP.custom]: ['custom'],
  [COLOR_GROUP.black]: [
    'black',
    'schwarz',
    'schw',
    'hyperbl',
    'sw.r.pol rand sw',
    'dark',
    'sw-glanz',
  ],
  [COLOR_GROUP.blue]: ['blue'],
  [COLOR_GROUP.bronze]: [
    'bronze',
    'bronce',
    'americano',
    'champagne',
    'copper',
  ],
  [COLOR_GROUP.gold]: ['gold'],
  [COLOR_GROUP.green]: ['green'],
  [COLOR_GROUP.grey]: [
    'gun',
    'graphit',
    'grey',
    'anthr',
    'antrazit',
    'dark sparkle',
    'grau',
    'matt gm',
    'gray',
    'grigio corsa',
    'palladium',
    'hyper',
    'anthracite',
    'graphite',
  ],
  [COLOR_GROUP.titan]: ['titan', 'titanium'],
  [COLOR_GROUP.carbon]: ['carbon'],
  [COLOR_GROUP.purple]: ['purple', 'violet'],
  [COLOR_GROUP.red]: ['red', 'rot'],
  [COLOR_GROUP.silver]: [
    'silver',
    'silber',
    'metal',
    'light hyper',
    'ral9006',
    'highgloss',
    'aluminium',
    'chrom',
  ],
  [COLOR_GROUP.chrome]: ['stainless steel', 'inox', 'chrom', 'chrome', 'metal'],
  [COLOR_GROUP.yellow]: ['yellow', 'gelb'],
  [COLOR_GROUP.orange]: ['orange'],
  [COLOR_GROUP.white]: ['white', 'weiss'],
};

export const COLOR_FINISH_MAPPING = {
  [COLOR_FINISH.standard]: ['standard'],
  [COLOR_FINISH.matte]: ['matte', 'matt', 'mat', 'mate'],
  [COLOR_FINISH.gloss]: ['gloss', 'glanz', 'glos', 'glossy', 'shiny', 'shine'],
  [COLOR_FINISH.satin]: ['satin'],
  [COLOR_FINISH.chrome]: ['chrome'],
  [COLOR_FINISH.polished]: [
    'polished',
    'poliert',
    'pol',
    'polish',
    'machined',
    'frontpol',
    'hpol',
  ],
  [COLOR_FINISH.brushed]: ['brushed'],
  [COLOR_FINISH.textured]: ['textured'],
  [COLOR_FINISH.custom]: ['custom'],
};

// We should remove this. You should never need to select color. You should only select color group and finish from the variables above.
export const COLOR = {
  'sparkling silver': {
    name: 'sparkling silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matte gunmetal/polish': {
    name: 'matte gunmetal/polish',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'candy red': {
    name: 'candy red',
    colorGroup: COLOR_GROUP.red,
    colorFinish: [COLOR_FINISH.standard],
  },
  'flowforged matte gunmetal': {
    name: 'flowforged matte gunmetal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  'flowforged liquid bronze': {
    name: 'flowforged liquid bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.standard],
  },
  'flowforged liquid metal': {
    name: 'flowforged liquid metal',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'flowforged satin black': {
    name: 'flowforged satin black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.satin, COLOR_FINISH.matte],
  },
  'flowforged sparkling silver': {
    name: 'flowforged sparkling silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  gunmetal: {
    name: 'gunmetal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'phantom black': {
    name: 'phantom black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'phantom black/polish': {
    name: 'phantom black/polish',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'black chrome/polish': {
    name: 'black chrome/polish',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'hyper black': {
    name: 'hyper black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'flowforged brushed candy red': {
    name: 'flowforged brushed candy red',
    colorGroup: COLOR_GROUP.red,
    colorFinish: [COLOR_FINISH.standard],
  },
  'phantom black/polished': {
    name: 'phantom black/polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'matte black': {
    name: 'matte black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'hyper silver': {
    name: 'hyper silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'graphit frontpoliert': {
    name: 'graphit frontpoliert',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  graphit: {
    name: 'graphit',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'polar-silber': {
    name: 'polar-silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  silber: {
    name: 'silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'silky black': {
    name: 'silky black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  polarsilber: {
    name: 'polarsilber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt black': {
    name: 'matt black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'schwarz matt': {
    name: 'schwarz matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'schwarz poliert': {
    name: 'schwarz poliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'diamant-schwarz': {
    name: 'diamant-schwarz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'diamant-schwarz-frontpoliert': {
    name: 'diamant-schwarz-frontpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'sterling-silber': {
    name: 'sterling-silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'racing-schwarz frontpoliert': {
    name: 'racing-schwarz frontpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'racing-schwarz': {
    name: 'racing-schwarz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'diamant-schwarz frontpoliert': {
    name: 'diamant-schwarz frontpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dull black': {
    name: 'dull black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'polar-silver': {
    name: 'polar-silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'metal-grey': {
    name: 'metal-grey',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'd-schwarz fr.pol': {
    name: 'd-schwarz fr.pol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'silber hornpoliert': {
    name: 'silber hornpoliert',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'cristal silver': {
    name: 'cristal silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'schwarz-glanzend': {
    name: 'schwarz-glanzend',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dark-grey': {
    name: 'dark-grey',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'racing-schwarz-doppel-hornpoliert': {
    name: 'racing-schwarz-doppel-hornpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'blizzard-grau hornpoliert': {
    name: 'blizzard-grau hornpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  brilliantsilber: {
    name: 'brilliantsilber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black glossy': {
    name: 'black glossy',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  kristallsilber: {
    name: 'kristallsilber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  brillantsilber: {
    name: 'brillantsilber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'titan matt': {
    name: 'titan matt',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'crystal silver': {
    name: 'crystal silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'silber, rand polie': {
    name: 'silber, rand polie',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz-poliert': {
    name: 'schwarz-poliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'graphite pol. matt': {
    name: 'graphite pol. matt',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished, COLOR_FINISH.matte],
  },
  'm.anthrazit glossy': {
    name: 'm.anthrazit glossy',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'schwarz-pol matt': {
    name: 'schwarz-pol matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished, COLOR_FINISH.matte],
  },
  'black polish. matt': {
    name: 'black polish. matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished, COLOR_FINISH.matte],
  },
  'schwarz-glanz': {
    name: 'schwarz-glanz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'mattschwarz pol': {
    name: 'mattschwarz pol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz-matt': {
    name: 'schwarz-matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'hima-grey-voll-pol': {
    name: 'hima-grey-voll-pol',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'hyper silber': {
    name: 'hyper silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  silver: {
    name: 'silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'schwarzglanz vollp': {
    name: 'schwarzglanz vollp',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dark sparkle': {
    name: 'dark sparkle',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'mattschw estahlinl': {
    name: 'mattschw estahlinl',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'mattschwarz lack': {
    name: 'mattschwarz lack',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'g.schwarz poliert': {
    name: 'g.schwarz poliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'mattschw horn gr�n': {
    name: 'mattschw horn gr�n',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'anthracite diamond': {
    name: 'anthracite diamond',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black diamond': {
    name: 'black diamond',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'gun metal matt': {
    name: 'gun metal matt',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  'race silber': {
    name: 'race silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'daytona grau': {
    name: 'daytona grau',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  titanium: {
    name: 'titanium',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt anthracite d': {
    name: 'matt anthracite d',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt anthracite diamond': {
    name: 'matt anthracite diamond',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black polished': {
    name: 'black polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'black flat': {
    name: 'black flat',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'high gloss silver': {
    name: 'high gloss silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'silver painted': {
    name: 'silver painted',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black lip polished': {
    name: 'black lip polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'black lip orange': {
    name: 'black lip orange',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'sw.r.pol rand rot': {
    name: 'sw.r.pol rand rot',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'sw pol rand rot': {
    name: 'sw pol rand rot',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'sw pol rand schw': {
    name: 'sw pol rand schw',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'sw.r.pol rand sw': {
    name: 'sw.r.pol rand sw',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'grey polished': {
    name: 'grey polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  chrom: {
    name: 'chrom',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'antrazit poliert': {
    name: 'antrazit poliert',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  mattschwarz: {
    name: 'mattschwarz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'light hyper': {
    name: 'light hyper',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'schwarz randpol': {
    name: 'schwarz randpol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz frontpol': {
    name: 'schwarz frontpol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'matt schwarz': {
    name: 'matt schwarz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'flat black pol': {
    name: 'flat black pol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'cristal silber': {
    name: 'cristal silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'decor titan': {
    name: 'decor titan',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt anthrazit': {
    name: 'matt anthrazit',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'schwarz pol.matt': {
    name: 'schwarz pol.matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished, COLOR_FINISH.matte],
  },
  'flat black': {
    name: 'flat black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  glanzsilber: {
    name: 'glanzsilber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black mirror pol.': {
    name: 'black mirror pol.',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dark gun': {
    name: 'dark gun',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'dark hyperblack p.': {
    name: 'dark hyperblack p.',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'titanium diam pol': {
    name: 'titanium diam pol',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'bright silver': {
    name: 'bright silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'hyperblack poliert': {
    name: 'hyperblack poliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'gunmetal polished': {
    name: 'gunmetal polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'black painted': {
    name: 'black painted',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'hyperblack diampol': {
    name: 'hyperblack diampol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'hyperbl d pol edsl': {
    name: 'hyperbl d pol edsl',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz gl�nzend': {
    name: 'schwarz gl�nzend',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'arktic silber': {
    name: 'arktic silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'dark gun metal': {
    name: 'dark gun metal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'daytona grau pol.': {
    name: 'daytona grau pol.',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz hg pol.': {
    name: 'schwarz hg pol.',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz hochglanzpoliert': {
    name: 'schwarz hochglanzpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matte gunmetal': {
    name: 'matte gunmetal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt gunmetal': {
    name: 'matt gunmetal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'phantom black / polished': {
    name: 'phantom black / polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'matte bronze': {
    name: 'matte bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black chrome': {
    name: 'black chrome',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  bronze: {
    name: 'bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.standard],
  },
  'brushed liquid bronze': {
    name: 'brushed liquid bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.brushed],
  },
  'flowforged gloss metal': {
    name: 'flowforged gloss metal',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'flowforged matte carbon bronze': {
    name: 'flowforged matte carbon bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.matte],
  },
  'matt gun metal polished': {
    name: 'matt gun metal polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'brushed liquid metal': {
    name: 'brushed liquid metal',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'gloss black/polished': {
    name: 'gloss black/polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss, COLOR_FINISH.polished],
  },
  black: {
    name: 'black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'ral9006 (weissaluminium)': {
    name: 'ral9006 (weissaluminium)',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'anthracite polished': {
    name: 'anthracite polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dull black f polished': {
    name: 'dull black f polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'matt gun metal': {
    name: 'matt gun metal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'silver polished': {
    name: 'silver polished',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  anthracite: {
    name: 'anthracite',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt gm polished': {
    name: 'matt gm polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'glossy black polished': {
    name: 'glossy black polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'silver shine': {
    name: 'silver shine',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'diamond black polished': {
    name: 'diamond black polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'anthracite n polished': {
    name: 'anthracite n polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'hyper anthracite': {
    name: 'hyper anthracite',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt grey polished': {
    name: 'matt grey polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dark silver': {
    name: 'dark silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'gm6 dark silver': {
    name: 'gm6 dark silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'silver polished lip': {
    name: 'silver polished lip',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dull black r polished': {
    name: 'dull black r polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'glossy black': {
    name: 'glossy black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'super silver': {
    name: 'super silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt silver': {
    name: 'matt silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'glossy black lip polished': {
    name: 'glossy black lip polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'anthracite lip red': {
    name: 'anthracite lip red',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'gloss gray / polished': {
    name: 'gloss gray / polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'racing-black copper': {
    name: 'racing-black copper',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'metallic-bronze': {
    name: 'metallic-bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.standard],
  },
  'rallye-weiss': {
    name: 'rallye-weiss',
    colorGroup: COLOR_GROUP.white,
    colorFinish: [COLOR_FINISH.standard],
  },
  'schwarz glanzend': {
    name: 'schwarz glanzend',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'racing-grau': {
    name: 'racing-grau',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'royal-silber': {
    name: 'royal-silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'racing-schwarz hornpoliert': {
    name: 'racing-schwarz hornpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'kristall silber': {
    name: 'kristall silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'glossy anthracite': {
    name: 'glossy anthracite',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'gloss black': {
    name: 'gloss black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'dull black / polished': {
    name: 'dull black / polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'anthracite / polish': {
    name: 'anthracite / polish',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'gloss gray': {
    name: 'gloss gray',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'gloss black / polished': {
    name: 'gloss black / polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'dull anthracite / polish': {
    name: 'dull anthracite / polish',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'gloss black / red': {
    name: 'gloss black / red',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'dull anthracite': {
    name: 'dull anthracite',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  white: {
    name: 'white',
    colorGroup: COLOR_GROUP.white,
    colorFinish: [COLOR_FINISH.standard],
  },
  'dull black / red': {
    name: 'dull black / red',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'gunmetal dull': {
    name: 'gunmetal dull',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  gold: {
    name: 'gold',
    colorGroup: COLOR_GROUP.gold,
    colorFinish: [COLOR_FINISH.standard],
  },
  'silver / polished': {
    name: 'silver / polished',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'anthracite dark': {
    name: 'anthracite dark',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'gold / polish': {
    name: 'gold / polish',
    colorGroup: COLOR_GROUP.gold,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dull bronze': {
    name: 'dull bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.matte],
  },
  'black / polished': {
    name: 'black / polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'gloss black / polished lip': {
    name: 'gloss black / polished lip',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'hyper silver / polished': {
    name: 'hyper silver / polished',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'gunmetal / polished': {
    name: 'gunmetal / polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'anthracite dark / polished': {
    name: 'anthracite dark / polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'light gray polished': {
    name: 'light gray polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'silver bright': {
    name: 'silver bright',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'crystal anthracite': {
    name: 'crystal anthracite',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'metal-grey frontpoliert': {
    name: 'metal-grey frontpoliert',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'blood red': {
    name: 'blood red',
    colorGroup: COLOR_GROUP.red,
    colorFinish: [COLOR_FINISH.standard],
  },
  'flowforged gloss black': {
    name: 'flowforged gloss black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'brushed candy red': {
    name: 'brushed candy red',
    colorGroup: COLOR_GROUP.red,
    colorFinish: [COLOR_FINISH.standard],
  },
  'stainless steel face': {
    name: 'stainless steel face',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  chrome: {
    name: 'chrome',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.chrome],
  },
  'hype anthracite': {
    name: 'hype anthracite',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'dark polished': {
    name: 'dark polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'glossary black': {
    name: 'glossary black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  polished: {
    name: 'polished',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'racing-schwarz-frontpoliert': {
    name: 'racing-schwarz-frontpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'silver flow form': {
    name: 'silver flow form',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'white mpl': {
    name: 'white mpl',
    colorGroup: COLOR_GROUP.white,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black fmp': {
    name: 'black fmp',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt white red groove': {
    name: 'matt white red groove',
    colorGroup: COLOR_GROUP.red,
    colorFinish: [COLOR_FINISH.standard],
  },
  'glossary black flow form': {
    name: 'glossary black flow form',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'dull black polished lip': {
    name: 'dull black polished lip',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'diamant-silber': {
    name: 'diamant-silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'gun metal inox': {
    name: 'gun metal inox',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'diamant-schwarz-hornpoliert': {
    name: 'diamant-schwarz-hornpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'brilliant silver': {
    name: 'brilliant silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'hyper silver inox': {
    name: 'hyper silver inox',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dull black polished': {
    name: 'dull black polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'matt titanium full polished': {
    name: 'matt titanium full polished',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'racing-schwarz-hornpoliert': {
    name: 'racing-schwarz-hornpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'blizzard-grau-hornpoliert': {
    name: 'blizzard-grau-hornpoliert',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'carbon-grau': {
    name: 'carbon-grau',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'anthracite matt polished': {
    name: 'anthracite matt polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'matte black inox': {
    name: 'matte black inox',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'platinum matt': {
    name: 'platinum matt',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'crystal titanium': {
    name: 'crystal titanium',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'mat gun metal': {
    name: 'mat gun metal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  sgvp: {
    name: 'sgvp',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  dark: {
    name: 'dark',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'schwarz glanz': {
    name: 'schwarz glanz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz poliert rand schwarz': {
    name: 'schwarz poliert rand schwarz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz glanz voll-poliert': {
    name: 'schwarz glanz voll-poliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'gunmetall matt': {
    name: 'gunmetall matt',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  'himalaya grey front polished': {
    name: 'himalaya grey front polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'sparking silver': {
    name: 'sparking silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt graphite silver lettering': {
    name: 'matt graphite silver lettering',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'high gloss silber': {
    name: 'high gloss silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'dayton grau': {
    name: 'dayton grau',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'metallic-bronze frontpoliert': {
    name: 'metallic-bronze frontpoliert',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.polished],
  },
  'metallic-platinum frontpoliert': {
    name: 'metallic-platinum frontpoliert',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'racing-schwarz-teilpoliert': {
    name: 'racing-schwarz-teilpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'schwarz-glanzend-lackiert': {
    name: 'schwarz-glanzend-lackiert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'diamant-schwarz hornpoliert': {
    name: 'diamant-schwarz hornpoliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'racing-black pad-red': {
    name: 'racing-black pad-red',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'matt anthracite': {
    name: 'matt anthracite',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  'black diamond lip': {
    name: 'black diamond lip',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'glossy antracite dark': {
    name: 'glossy antracite dark',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'matt black diamond lip': {
    name: 'matt black diamond lip',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'satin black diamond': {
    name: 'satin black diamond',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.satin],
  },
  'schwarz matt vorn poliert': {
    name: 'schwarz matt vorn poliert',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'silver chrome': {
    name: 'silver chrome',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.chrome],
  },
  'kristall silver': {
    name: 'kristall silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.polished],
  },
  'gun metal mirror face': {
    name: 'gun metal mirror face',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'glossy black front polished': {
    name: 'glossy black front polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dark grey': {
    name: 'dark grey',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'gun metal': {
    name: 'gun metal',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'neon green': {
    name: 'neon green',
    colorGroup: COLOR_GROUP.green,
    colorFinish: [COLOR_FINISH.standard],
  },
  'machined silver': {
    name: 'machined silver',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'brushed bronze': {
    name: 'brushed bronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.matte],
  },
  'brushed titanium': {
    name: 'brushed titanium',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'carbon graphite': {
    name: 'carbon graphite',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'double tinted black': {
    name: 'double tinted black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'platinum black': {
    name: 'platinum black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'hyper gray': {
    name: 'hyper gray',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'hiper black': {
    name: 'hiper black',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  red: {
    name: 'red',
    colorGroup: COLOR_GROUP.red,
    colorFinish: [COLOR_FINISH.standard],
  },
  blue: {
    name: 'blue',
    colorGroup: COLOR_GROUP.blue,
    colorFinish: [COLOR_FINISH.standard],
  },
  purple: {
    name: 'purple',
    colorGroup: COLOR_GROUP.purple,
    colorFinish: [COLOR_FINISH.standard],
  },
  'pulverbeschichtung silver custom finish': {
    name: 'pulverbeschichtung silver custom finish',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'm.-grey frontpol.': {
    name: 'm.-grey frontpol.',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dia-schw kontur p': {
    name: 'dia-schw kontur p',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'dark grey matt': {
    name: 'dark grey matt',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  'm.anthr glos pol': {
    name: 'm.anthr glos pol',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'graphite polished': {
    name: 'graphite polished',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  sterlingsilber: {
    name: 'sterlingsilber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black rim pol.matt': {
    name: 'black rim pol.matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'black pol.glossy': {
    name: 'black pol.glossy',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.gloss],
  },
  'satin black matt': {
    name: 'satin black matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.satin, COLOR_FINISH.matte],
  },
  'ferric-grey': {
    name: 'ferric-grey',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'ferric-grey-matt': {
    name: 'ferric-grey-matt',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  'himalaya-grey-matt': {
    name: 'himalaya-grey-matt',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  lichtsilber: {
    name: 'lichtsilber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'black shiny': {
    name: 'black shiny',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  grey: {
    name: 'grey',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.standard],
  },
  'd.gunmetal pol': {
    name: 'd.gunmetal pol',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.polished],
  },
  'grigio corsa': {
    name: 'grigio corsa',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  'platinum-silber': {
    name: 'platinum-silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  jetblack: {
    name: 'jetblack',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  schwarz: {
    name: 'schwarz',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.standard],
  },
  'metall silber': {
    name: 'metall silber',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.standard],
  },
  'diamant-schw hpol': {
    name: 'diamant-schw hpol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'k-silber matt': {
    name: 'k-silber matt',
    colorGroup: COLOR_GROUP.silver,
    colorFinish: [COLOR_FINISH.matte],
  },
  'jetblack-matt': {
    name: 'jetblack-matt',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.matte],
  },
  'black diamond pol': {
    name: 'black diamond pol',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  'black rim polished': {
    name: 'black rim polished',
    colorGroup: COLOR_GROUP.black,
    colorFinish: [COLOR_FINISH.polished],
  },
  mattgraphite: {
    name: 'mattgraphite',
    colorGroup: COLOR_GROUP.grey,
    colorFinish: [COLOR_FINISH.matte],
  },
  mattbronze: {
    name: 'mattbronze',
    colorGroup: COLOR_GROUP.bronze,
    colorFinish: [COLOR_FINISH.matte],
  },
  'custom finish': {
    name: 'custom finish',
    colorGroup: COLOR_GROUP.custom,
    colorFinish: [COLOR_FINISH.custom],
  },
};
