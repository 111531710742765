import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

export const APOLLOVREDESTEIN_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.VEHICLE_TYPE',
    condition: 'equals',
    conditionValues: ['C0', 'L0', 'LS'],
    conditionOutputs: ['30', '32', '31'],
    destination: 'model',
    targetKey: 'category_id',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateBridgestoneSpecialMarkings',
  },
  ...EDI_WHEEL_B4_RULESET,

  // Model
  {
    type: 'rule.map',
    sourceKey: "c.['DESIGN_2']",
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
  },

  // RFD Fix
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.RFD',
    condition: 'equals',
    conditionValues: ['YES'],
    conditionOutputs: ['XL'],
    destination: 'product',
    targetKey: 'rfd',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.LI2',
    targetKey: 'loadIndex2',
    destination: 'product',
    outputType: 'string',
  },

  ...GLOBAL_RULESET,
];
