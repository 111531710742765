import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { BFPNEUMATIC_PRICELIST_RULESET } from './bfpneumatic/pricelist';
import { BFPNEUMATIC_STOCKLIST_RULESET } from './bfpneumatic/stocklist';
import { TRule } from '../typings';

export const BFPNEUMATIC_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: BFPNEUMATIC_PRICELIST_RULESET,
  stockRuleset: BFPNEUMATIC_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'BFPNEUMATIC',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {},
  hardCodes: { 1: { product: {}, model: {} } },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async preprocessPricelist(file: any) {

    const items = file.xmlexport.supplier.article; // Extract the array of items
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const json = items.map((item: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newItem: any = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          newItem[key] = item[key];
        }
      }
      return newItem;
    });

    return handlePreprocessConversions(
      json.filter(
        (item: { [x: string]: string; }) =>
          !item['title'].includes('D20') &&
          parseFloat(item['yourprice']) >= 1 &&
          typeof item['dot'] === 'string' &&
          parseFloat(String(new Date().getFullYear()).slice(-2)) -
          parseFloat(item['dot'].slice(-2)) <=
          2 &&
          !item['title'].toUpperCase().includes('DEMO') &&
          !item['title'].toUpperCase().includes('DOT')
      ),
      this.preprocessKeys
    );
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async preprocessStockList(file: any) {

    const items = file.xmlexport.supplier.article; // Extract the array of items

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const json = items.map((item: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newItem: any = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          newItem[key] = item[key];
        }
      }
      return newItem;
    });

    return handlePreprocessConversions(
      json.filter(
        (item: { [x: string]: string; }) =>
          !item['title'].includes('D20') &&
          parseFloat(item['yourprice']) >= 1 &&
          typeof item['dot'] === 'string' &&
          parseFloat(String(new Date().getFullYear()).slice(-2)) -
          parseFloat(item['dot'].slice(-2)) <=
          2 &&
          !item['title'].toUpperCase().includes('DEMO') &&
          !item['title'].toUpperCase().includes('DOT')
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
