import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import { DBSupplierApi, DBSupplierClientContactsApi } from '@tyrio/dto';
import { useCallback, useEffect, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import {
  AddNewRepeaterFieldButton,
  DeleteRepeaterFieldButton,
  ExpandCollapseButtons,
  PhoneNumberInput,
  TextFieldInput,
} from '../supplier-form/helpers/components';
import { emailRegex } from '../supplier-form/helpers/regex';
import { ValidationProps } from '../supplier-form/helpers/types';
import {
  AccordionWrapper,
  ContactHeader,
} from '../supplier-form/SupplierAdminForm';

export interface SupplierClientContactsProps {
  supplierData?: DBSupplierApi['getOne']['response'];
  initialData?: DBSupplierClientContactsApi['create']['requestBody'][];
  getData: (
    data: DBSupplierClientContactsApi['create']['requestBody'][]
  ) => void;
  getValidationProps: (
    key: string,
    validationRules?: RegisterOptions,
    validationText?: string
  ) => ValidationProps;
}

export const SUPPLIER_CLIENT_CONTACTS_INITIAL_DATA: DBSupplierClientContactsApi['create']['requestBody'] =
  {
    firstName: '',
    lastName: '',
    email: '',
    bussinesPhone: '',
    mobilePhone: '',
    remark: '',
  };

const SupplierClientContacts = ({
  supplierData,
  getData,
  initialData = [],
  getValidationProps,
}: SupplierClientContactsProps) => {
  const [
    supplierClientContactsSettings,
    setSupplierClientContactsSettingsState,
  ] =
    useState<DBSupplierClientContactsApi['create']['requestBody'][]>(
      initialData
    );

  const [expandedData, setExpandedData] = useState<number[]>([]);

  const setSupplierClientContactsSettings = useCallback(
    (data: DBSupplierClientContactsApi['create']['requestBody'][]) => {
      setSupplierClientContactsSettingsState(data);
      getData(data);
    },
    [getData]
  );

  useEffect(() => {
    setSupplierClientContactsSettingsState(initialData);
  }, [supplierData, initialData]);

  return (
    <>
      {supplierData && supplierClientContactsSettings.length > 0 && (
        <ExpandCollapseButtons
          color={expandedData.length > 0 ? 'warning' : 'success'}
          onExpandedDataChange={() =>
            setExpandedData(
              expandedData.length > 0
                ? []
                : supplierClientContactsSettings.map((contact, index) => index)
            )
          }
          flag={expandedData.length > 0}
        />
      )}
      <AccordionWrapper>
        {supplierClientContactsSettings.map(
          (
            item: DBSupplierClientContactsApi['create']['requestBody'],
            index: number
          ) => {
            return (
              <Accordion
                expanded={expandedData.includes(index)}
                square
                defaultExpanded
                sx={{
                  boxShadow: '0px 1px 0px #DFE3E8',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    paddingLeft: '0px',
                  }}
                  onClick={() => {
                    setExpandedData(
                      expandedData.includes(index)
                        ? expandedData.filter((i) => i !== index)
                        : [...expandedData, index]
                    );
                  }}
                >
                  <ContactHeader>
                    {item.firstName !== ''
                      ? `${item.firstName + ' ' + item.lastName}`
                      : `Contact #${index + 1}`}
                  </ContactHeader>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingLeft: '0px',
                  }}
                >
                  <Grid spacing={2} container>
                    <Grid item xs={12} lg={6}>
                      <TextFieldInput
                        label="First Name *"
                        value={item.firstName}
                        onChange={(e) => {
                          supplierClientContactsSettings[index].firstName =
                            e.target.value;
                          setSupplierClientContactsSettings([
                            ...supplierClientContactsSettings,
                          ]);
                        }}
                        validationProps={{
                          ...getValidationProps(
                            `supplierContacts-firstName-${index}`
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextFieldInput
                        label="Last Name *"
                        value={item.lastName}
                        onChange={(e) => {
                          supplierClientContactsSettings[index].lastName =
                            e.target.value;
                          setSupplierClientContactsSettings([
                            ...supplierClientContactsSettings,
                          ]);
                        }}
                        validationProps={{
                          ...getValidationProps(
                            `supplierContacts-lastName-${index}`
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid spacing={2} container sx={{ marginTop: '30px' }}>
                    <Grid item xs={12} lg={4}>
                      <TextFieldInput
                        label="E-mail *"
                        value={item.email}
                        onChange={(e) => {
                          supplierClientContactsSettings[index].email =
                            e.target.value;
                          setSupplierClientContactsSettings([
                            ...supplierClientContactsSettings,
                          ]);
                        }}
                        validationProps={{
                          ...getValidationProps(
                            `supplierContacts-email-${index}`,
                            undefined,
                            'Format is not correct!'
                          ),
                          validationRules: {
                            validate: () => {
                              return emailRegex.test(item.email);
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <PhoneNumberInput
                        label="Business Phone"
                        value={item.bussinesPhone}
                        onChange={(e) => {
                          supplierClientContactsSettings[index].bussinesPhone =
                            e;
                          setSupplierClientContactsSettings([
                            ...supplierClientContactsSettings,
                          ]);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <PhoneNumberInput
                        label="Mobile Phone"
                        value={item.mobilePhone}
                        onChange={(e) => {
                          supplierClientContactsSettings[index].mobilePhone = e;
                          setSupplierClientContactsSettings([
                            ...supplierClientContactsSettings,
                          ]);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid spacing={2} container sx={{ marginTop: '30px' }}>
                    <Grid item xs={12} lg={12}>
                      <TextFieldInput
                        label="Remark"
                        value={item.remark}
                        onChange={(e) => {
                          supplierClientContactsSettings[index].remark =
                            e.target.value;
                          setSupplierClientContactsSettings([
                            ...supplierClientContactsSettings,
                          ]);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    sx={{ marginTop: '20px' }}
                    justifyContent="flex-end"
                  >
                    <DeleteRepeaterFieldButton
                      onDeleteRepeaterField={() => {
                        setSupplierClientContactsSettings(
                          supplierClientContactsSettings.filter(
                            (_item, contactsIndex) => contactsIndex !== index
                          )
                        );
                      }}
                      index={index}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          }
        )}
      </AccordionWrapper>
      {supplierData && (
        <Grid container sx={{ marginTop: '25px' }}>
          <AddNewRepeaterFieldButton
            onAddNewRepeaterField={() => {
              setSupplierClientContactsSettings([
                ...supplierClientContactsSettings,
                { ...SUPPLIER_CLIENT_CONTACTS_INITIAL_DATA },
              ]);
              setExpandedData([supplierClientContactsSettings.length]);
            }}
            buttonText="ADD NEW CONTACT"
          />
        </Grid>
      )}
    </>
  );
};

export default SupplierClientContacts;
