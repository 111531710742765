import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { CheckboxCard } from './CheckboxCard';
import { tyrioIcons, tyrioUI } from '@tyrio/ui-library';
import { StyledDivider } from '../../../../stock-list/components/InboundOrders/InboundOrders.style';
import {
  IVehicleFilter,
  useVehicleCtx,
} from '../../../../../context/VehicleContext';
import { difference, get, uniq } from 'lodash';
import { useMemo } from 'react';

export type IName = 'rimSize' | 'colorGroup' | 'cb' | 'width';

export const SearchCard = ({
  text,
  values,
  checkboxId,
  enabledValues,
  name,
}: {
  name: IName;
  text: string;
  values: string[] | number[];
  checkboxId: string;
  enabledValues: (string | number | null)[];
}) => {
  const { filter, setFilter, apiData } = useVehicleCtx();

  const recommendedRimSizes = useMemo(() => {
    return uniq(apiData?.rimDiameter ?? []);
  }, [apiData?.rimDiameter]);

  const notRecommendedSizeChecked = useMemo(() => {
    const notRecommendedSizes = difference(
      filter.rimDiameter,
      recommendedRimSizes.map((r) => r.toString())
    );
    return notRecommendedSizes;
  }, [filter.rimDiameter, recommendedRimSizes]);

  const onChange = (name: keyof IVehicleFilter, value: string) => {
    const currentValue = filter[name] || [];
    const isChecked = currentValue.includes(value);

    let updatedValue;

    if (isChecked) {
      updatedValue = currentValue.filter((v) => v !== value);
    } else {
      updatedValue = [...currentValue, value];
    }

    setFilter({ ...filter, [name]: updatedValue });
  };

  return (
    <Wrapper>
      <StyledDivider />
      <Typography
        style={{
          fontWeight: '400',
          color: tyrioUI.colors.black.B70,
          fontSize: '16px',
        }}
      >
        {text}
      </Typography>{' '}
      <CheckboxWrapper>
        {values.map((m: string | number, idx) => {
          const isDisabled = !enabledValues.includes(m);
          const notRecommended =
            name === 'rimSize'
              ? !recommendedRimSizes.includes(Number(m))
              : false;

          return (
            <CheckboxCard
              text={m.toString()}
              onChange={onChange}
              checkboxId={checkboxId}
              key={idx}
              isDisabled={isDisabled}
              isChecked={
                get(filter, checkboxId, [])?.includes(m.toString()) ?? false
              }
              notRecommended={notRecommended}
            />
          );
        })}
      </CheckboxWrapper>
      {notRecommendedSizeChecked.length > 0 && name === 'rimSize' && (
        <Div>
          <Img src={tyrioIcons.warning} alt="" />
          <Typography
            color={tyrioUI.colors.orange.O100}
            fontWeight={400}
            fontSize={16}
          >
            Selected rim diameter {notRecommendedSizeChecked.join(' ,')} is not
            recommended for your car
          </Typography>
        </Div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;

const Img = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
