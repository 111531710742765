import { useCallback, useContext, useMemo } from 'react';
import { PCSupplier } from '../types';
import { DBPriceCompareApi, DBProductPayload } from '@tyrio/dto';
import { PriceComparisonContext } from './context';
import _ from 'lodash';
import { roundPriceToTwoDecimals } from '@tyrio/shared-vars';

export const isoToCalendarDate = (isoDate: string) => {
  return isoDate.slice(0, 10);
};
export const useSyncedPrices = (
  RES: DBPriceCompareApi['compareCode']['response']
) => {
  const ctx = useContext(PriceComparisonContext);
  const mainSupplier = RES.mainSupplier;

  const mainSupplierId = Object.keys(mainSupplier || {})[0];

  const suppliers = useMemo(() => {
    const supplierList: PCSupplier[] = [];

    if (mainSupplierId && mainSupplier)
      supplierList.push({
        supplierId: mainSupplierId,
        mainSupplier: true,
        ...mainSupplier[mainSupplierId],
      });

    Object.keys(RES.suppliers).forEach((key) => {
      supplierList.push({ ...RES.suppliers[key], supplierId: key });
    });

    return supplierList;
  }, [RES.suppliers, mainSupplier, mainSupplierId]);

  const supplierMap = useMemo(() => {
    const map: Record<string, PCSupplier> = {};
    suppliers.forEach((s) => {
      map[s.supplierId] = s;
    });
    return map;
  }, [suppliers]);

  const prices = useMemo(() => {
    const newPrices: typeof RES.prices = {};

    Object.keys(RES.prices).forEach((productId) => {
      if (!newPrices[productId]) newPrices[productId] = {};

      Object.keys(RES.prices[productId]).forEach((supplierId) => {
        const priceObject = RES.prices?.[productId]?.[supplierId];
        let v = RES.prices?.[productId]?.[supplierId]?.value;
        if (ctx.data.includeEcoTax && v && priceObject?.ecoTax)
          v = v + priceObject.ecoTax;
        if (ctx.data.includeBonus && v && priceObject?.bonus)
          v = v - v * (priceObject.bonus / 100);

        if (ctx.data.includeDeliveryCost && v && priceObject?.shipping)
          v = v + priceObject.shipping;

        if (v) v = roundPriceToTwoDecimals(v);

        newPrices[productId][supplierId] = Object.assign({}, priceObject, {
          value: v,
        });
      });
    });

    return newPrices;
  }, [
    RES,
    ctx.data.includeBonus,
    ctx.data.includeDeliveryCost,
    ctx.data.includeEcoTax,
  ]);

  const lowestPrices = useMemo(() => {
    const lowestPrice: Record<string, Set<number>> = {};
    const priceArrays: Record<string, number[]> = {};

    Object.keys(prices).forEach((productId) => {
      Object.keys(prices[productId]).forEach((supplierId) => {
        let price = prices?.[productId]?.[supplierId]?.value;
        const stock = prices?.[productId]?.[supplierId]?.quantity;

        let isViable = true;
        if (
          stock === 0 &&
          supplierMap[supplierId]?.supplierType === 'DISTRIBUTER'
        ) {
          isViable = false;
        }
        if (price && isViable) {
          if (!lowestPrice[productId]) lowestPrice[productId] = new Set();

          // const priceObject = prices?.[productId]?.[supplierId];

          // // let v = RES.prices?.[productId]?.[supplierId]?.value;
          // if (ctx.data.includeEcoTax && priceObject?.ecoTax)
          //   price = price + priceObject.ecoTax;
          //
          // if (ctx.data.includeBonus && priceObject?.bonus)
          //   price = price - price * (priceObject.bonus / 100);
          //
          // if (ctx.data.includeDeliveryCost && priceObject?.shipping)
          //   price = price + priceObject.shipping;

          price = roundPriceToTwoDecimals(price);

          lowestPrice[productId].add(price);
        }
      });

      if (lowestPrice[productId])
        priceArrays[productId] = [...lowestPrice[productId]].sort().slice(0, 2);
    });
    return priceArrays;
  }, [prices, supplierMap]);

  const productEanMap = useMemo(() => {
    const res = ctx.data.codeResponse;
    const products = res?.products || {};
    const data: Record<string, DBProductPayload> = {};
    Object.keys(products).forEach((k) => {
      if (products[k].ean) data[products[k].ean] = products[k];
    });
    return data;
  }, [ctx.data.codeResponse]);

  const safeSetQty = useCallback(
    (supplierId: string, productId: string, qty: number) => {
      const old = ctx.data.qty;
      ctx.setInputValue({
        qty: {
          ...(old || {}),
          [supplierId]: {
            ..._.get(old, `${supplierId}`, {}),
            [productId]: qty,
          },
        },
      });
    },
    [ctx]
  );

  return {
    prices,
    lowestPrices,
    supplierMap,
    mainSupplier,
    mainSupplierId,
    suppliers,
    productEanMap,
    safeSetQty,
  };
};
