import React from 'react';
import styled from '@emotion/styled';

interface Props {
  price: number | null;
  quantity: number | null;
  requestedQuantity?: number;
}

export const RenderTotalCell = ({
  price,
  quantity,
  requestedQuantity,
}: Props) => {
  const safePrice = price || 0;
  const qtyDisplay = [quantity, requestedQuantity]
    .filter((x) => typeof x !== 'undefined')
    .join(' / ');

  const fulfilled = !requestedQuantity || quantity === requestedQuantity;
  return (
    <Wrapper>
      <Price>
        {safePrice.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </Price>

      <Qty
        fulfilled={fulfilled}
        partiallyFulfilled={!fulfilled && (quantity ?? 0) > 0}
      >
        {qtyDisplay} pcs
      </Qty>
    </Wrapper>
  );
};

const Price = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 2px;
`;

const Qty = styled.div<{ fulfilled: boolean; partiallyFulfilled: boolean }>`
  font-size: 16px;
  text-align: center;
  padding: 2px 6px;
  font-weight: 500;
  border-radius: 6px;
  ${(props) => {
    let bgColor = '#f77b7b';
    let textColor = 'white';
    if (props.partiallyFulfilled) bgColor = '#ff8d4e';
    if (props.fulfilled) {
      textColor = 'white';
      bgColor = '#56c489';
    }
    //   props.fulfilled
    //     ? `
    //   color: #3CB44B;
    //   background: #E0F4EA;
    // `
    //     : `
    //   background: #F8CECE;
    //   `
    //
    return `background-color: ${bgColor}; color: ${textColor}`;
  }}
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
