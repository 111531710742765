import * as z from "zod"
import { Code } from ".prisma/client"
import { CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBWarehouseFloors, RelatedDBWarehouseFloorsModel, CompleteDBWarehouseSections, RelatedDBWarehouseSectionsModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBWarehouseRacksModel = z.object({
  id: z.string(),
  warehouseAreaId: z.string(),
  floorId: z.string(),
  code: z.nativeEnum(Code),
  displayName: z.string(),
  qrCode: z.string(),
  description: z.string(),
  settings: jsonSchema,
  meta: jsonSchema,
})

export interface CompleteDBWarehouseRacks extends z.infer<typeof DBWarehouseRacksModel> {
  warehouseArea: CompleteDBBranchWarehouseAreas
  floor: CompleteDBWarehouseFloors
  sections: CompleteDBWarehouseSections[]
}

/**
 * RelatedDBWarehouseRacksModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWarehouseRacksModel: z.ZodSchema<CompleteDBWarehouseRacks> = z.lazy(() => DBWarehouseRacksModel.extend({
  warehouseArea: RelatedDBBranchWarehouseAreasModel,
  floor: RelatedDBWarehouseFloorsModel,
  sections: RelatedDBWarehouseSectionsModel.array(),
}))
