import {
  DefaultPrices,
  DefaultSettings,
  DefaultSurcharges,
  DeliveryCourier,
  DeliveryZones,
  OneDeliveryType,
  ZoneSettings,
} from '@tyrio/dto';

export enum DBDeliveryTypeEnum {
  WMS_SHIPPING = 'Parcel shipping',
  WMS_COLLECTION = 'In-store pickup',
  WMS_DISPATCH = 'Local dispatch',
  WMS_FITTING = 'Fitting station',
}

export const ZONES_INITIAL_DATA: DeliveryZones = {
  zoneSettings: {
    daysToDeliver: 0,
    maxDaysToDeliver: 0,
    minOrderQuantity: 0,
    maxPackageWeight: 0,
    cashOnDelivery: false,
    cardOnDelivery: false,
    zipCodes: [],
    name: '',
    country: [],
  },
  priceList: {
    priceType: 'pricePerOrder',
    deliveryPrice: 0,
    freeDelivery: false,
    minOrderAmount: 0,
    minOrderQuantity2: 0,
  },
  surcharges: {
    CODSurcharge: '',
    CCODSurcharge: '',
    minQuantitySurcharge: 0,
  },
};

export const initialValues = (data?: OneDeliveryType) => {
  const defaultSettings = data?.defaultSettings as unknown as DefaultSettings;
  const defaultPrices = data?.defaultPrices as unknown as DefaultPrices;
  const defaultSurcharges =
    data?.defaultSurcharges as unknown as DefaultSurcharges;

  return {
    code: data?.code ?? '',
    name: data?.name ?? '',
    sortOrder: data?.sortOrder ?? '',
    description: data?.description ?? '',
    deliveryTypeEnum: data?.deliveryTypeEnum ?? '',
    deliveryLocation: data?.deliveryLocation ?? '',
    deliveryType: data?.deliveryTypeEnum
      ? DBDeliveryTypeEnum[data?.deliveryTypeEnum]
      : undefined,
    dbDeliveryCouriers:
      data?.dbDeliveryCouriers.map((item: DeliveryCourier) => {
        return {
          label: item?.clientCourier.shippingCourier.abbreviation,
          value: item.clientCourierId,
        };
      }) ?? [],
    daysToDeliver:
      defaultSettings && defaultSettings.daysToDeliver
        ? defaultSettings.daysToDeliver
        : 0,
    maxDaysToDeliver:
      defaultSettings && defaultSettings.maxDaysToDeliver
        ? defaultSettings.maxDaysToDeliver
        : 0,
    minOrderQuantity:
      defaultSettings && defaultSettings.minOrderQuantity
        ? defaultSettings.minOrderQuantity
        : 0,
    maxPackageWeight:
      defaultSettings && defaultSettings.maxDaysToDeliver
        ? defaultSettings.maxPackageWeight
        : 0,
    cashOnDelivery:
      defaultSettings && defaultSettings.cashOnDelivery
        ? defaultSettings.cashOnDelivery
        : false,
    cardOnDelivery:
      defaultSettings && defaultSettings.cardOnDellivery
        ? defaultSettings.cardOnDellivery
        : false,
    blacklistZIPCodes:
      defaultSettings && defaultSettings.blacklistZIPCodes
        ? defaultSettings.blacklistZIPCodes
        : '',
    whitelistZIPCodes:
      defaultSettings && defaultSettings.whitelistZIPCodes
        ? defaultSettings.whitelistZIPCodes
        : '',
    priceType:
      defaultPrices && defaultPrices.priceType
        ? defaultPrices.priceType
        : 'pricePerOrder',
    deliveryPrice:
      defaultPrices && defaultPrices.deliveryPrice
        ? defaultPrices.deliveryPrice
        : 0,
    freeDelivery:
      defaultPrices && defaultPrices.freeDelivery
        ? defaultPrices.freeDelivery
        : false,
    minOrderQuantity2:
      defaultPrices && defaultPrices.minOrderQuantity2
        ? defaultPrices.minOrderQuantity2
        : 0,
    minOrderAmount:
      defaultPrices && defaultPrices.minOrderAmount
        ? defaultPrices.minOrderAmount
        : 0,
    CODSurcharge:
      defaultSurcharges && defaultSurcharges.CODSurcharge
        ? defaultSurcharges.CODSurcharge
        : '',
    CCODSurcharge:
      defaultSurcharges && defaultSurcharges.CCODSurcharge
        ? defaultSurcharges.CCODSurcharge
        : '',
    minQuantitySurcharge:
      defaultSurcharges && defaultSurcharges.minQuantitySurcharge
        ? defaultSurcharges.minQuantitySurcharge
        : 0,
    zones:
      data?.zones && data.zones.length > 0
        ? data?.zones?.map((item) => ({
            id: item.id ?? undefined,
            deliveryTypeId: item.deliveryTypeId ?? undefined,
            zoneSettings: item.settings as unknown as ZoneSettings,
            priceList: item.prices as unknown as DefaultPrices,
            surcharges: item.surcharges as unknown as DefaultSurcharges,
          }))
        : [],
  };
};
