import * as z from "zod"
import { CompleteDBDeliveryTypes, RelatedDBDeliveryTypesModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBDeliveryZoneModel = z.object({
  id: z.string(),
  settings: jsonSchema,
  prices: jsonSchema,
  surcharges: jsonSchema,
  deliveryTypeId: z.string().nullish(),
})

export interface CompleteDBDeliveryZone extends z.infer<typeof DBDeliveryZoneModel> {
  deliveryType?: CompleteDBDeliveryTypes | null
}

/**
 * RelatedDBDeliveryZoneModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBDeliveryZoneModel: z.ZodSchema<CompleteDBDeliveryZone> = z.lazy(() => DBDeliveryZoneModel.extend({
  deliveryType: RelatedDBDeliveryTypesModel.nullish(),
}))
