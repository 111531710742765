/* eslint-disable @typescript-eslint/no-empty-function */
import { InboundInvoiceResponse, isEUCountry } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

export enum VAT {
  R1 = 'Pdv obračunat',
  E1 = 'Prijenos porezne obveze',
  NF = 'Otpremnica',
}

export function getVATKey(value: string): keyof typeof VAT | null {
  for (const key of Object.keys(VAT))
    if (VAT[key as keyof typeof VAT] === value) return key as keyof typeof VAT;

  return null;
}

export interface InputShape {
  ids: string;
  checkedStockIns: Record<string, boolean>;
  checkedSupplier: { id: string; name: string };
  preparedInvoices: InboundInvoiceResponse | null;
  filteredSupplier: string;
  type: 'stockIn' | 'purchaseOrder' | '';
}

export interface CustomSummaryShape {
  subtotalExlVat: string;
  vatAmount: string;
  grandTotal: string;
}

interface IInboundInvoiceContext {
  remark: string;
  setRemark: (a: string) => void;
  preparedInvoices: InboundInvoiceResponse | null;
  setPreparedInvoices: Dispatch<SetStateAction<InboundInvoiceResponse | null>>;
  input: InputShape;
  setInput: Dispatch<SetStateAction<InputShape>>;
  isVatIncluded: boolean;
  setIsVatIncluded: Dispatch<SetStateAction<boolean>>;
  splitInvoice: boolean;
  setSplitInvoice: Dispatch<SetStateAction<boolean>>;
  isEU: boolean;
  branches: TyrioSelectInputOption[];
  setBranches: Dispatch<SetStateAction<TyrioSelectInputOption[]>>;
  isCancelModalOpen: boolean;
  setIsCancelModalOpen: Dispatch<SetStateAction<boolean>>;
  customSummary: CustomSummaryShape;
  setCustomSummary: Dispatch<SetStateAction<CustomSummaryShape>>;
  openConfirmModal: boolean;
  setOpenConfirmModal: Dispatch<SetStateAction<boolean>>;
  shouldUpdateWorkOrder: boolean;
  setShouldUpdateWorkOrder: Dispatch<SetStateAction<boolean>>;
}

export const InboundInvoiceContext =
  React.createContext<IInboundInvoiceContext>({
    remark: '',
    setRemark: () => {},
    preparedInvoices: null,
    setPreparedInvoices: () => {},
    input: {
      ids: '',
      checkedStockIns: {},
      checkedSupplier: { id: '', name: '' },
      preparedInvoices: null,
      filteredSupplier: 'all',
      type: '',
    },
    setInput: () => {},
    isVatIncluded: false,
    setIsVatIncluded: () => {},
    splitInvoice: false,
    setSplitInvoice: () => {},
    isEU: false,
    branches: [],
    setBranches: () => {},
    isCancelModalOpen: false,
    setIsCancelModalOpen: () => {},
    customSummary: { subtotalExlVat: '', vatAmount: '', grandTotal: '' },
    setCustomSummary: () => {},
    openConfirmModal: false,
    setOpenConfirmModal: () => {},
    shouldUpdateWorkOrder: false,
    setShouldUpdateWorkOrder: () => {},
  });

interface InboundInvoiceProviderProps {
  children: React.ReactNode;
}

const InboundInvoiceProvider = ({ children }: InboundInvoiceProviderProps) => {
  const [remark, setRemark] = useState('');
  const [isVatIncluded, setIsVatIncluded] = useState(false);
  const [input, setInput] = useLocalStorage<InputShape>('@@SI', {
    ids: '',
    checkedStockIns: {},
    preparedInvoices: null,
    filteredSupplier: 'all',
  });

  const [preparedInvoices, setPreparedInvoices] =
    useState<InboundInvoiceResponse | null>(input?.preparedInvoices ?? null);

  const [splitInvoice, setSplitInvoice] = useState(false);
  const [branches, setBranches] = useState<TyrioSelectInputOption[]>([]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [customSummary, setCustomSummary] = useState<CustomSummaryShape>({
    subtotalExlVat: '',
    vatAmount: '',
    grandTotal: '',
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [shouldUpdateWorkOrder, setShouldUpdateWorkOrder] = useState(false);

  const dispatchingCountry = preparedInvoices?.partner?.countryId as string;

  const isEU = isEUCountry(dispatchingCountry);

  return (
    <InboundInvoiceContext.Provider
      value={{
        remark,
        setRemark,
        preparedInvoices,
        setPreparedInvoices,
        input,
        setInput,
        isVatIncluded,
        setIsVatIncluded,
        splitInvoice,
        setSplitInvoice,
        isEU,
        branches,
        setBranches,
        isCancelModalOpen,
        setIsCancelModalOpen,
        customSummary,
        setCustomSummary,
        openConfirmModal,
        setOpenConfirmModal,
        shouldUpdateWorkOrder,
        setShouldUpdateWorkOrder,
      }}
    >
      {children}
    </InboundInvoiceContext.Provider>
  );
};

export default InboundInvoiceProvider;

export const useInboundInvoiceCtx = () =>
  React.useContext(InboundInvoiceContext);
