import {
  DBClientStockList,
  DBProduct,
  DBProductCategory,
  DBProductModel,
  DBWarehouseShelfs,
} from '@prisma/client';
import { PurchaseOrdersByEanResponse } from './client-supplier-order-item';
import { PosProductResponse } from './pos';
import { CalculatePriceRes, CheckPriceRes } from './price-calculation';
import { ProductDimensions } from './stock-in-list';
import { LocationMeta } from './stock-locations';
import { TransferOrderByEanResponse } from './transfer-order';

export interface SingleItem {
  ean: string;
  dot: string;
  branchId: number;
}

export interface DBCreateStockListItem {
  branchId: number;
  clientId: string;
  locationId: string | undefined;
  ean: string;
  productionYear: string;
  dot: string;
  quantity: number;
  demo: boolean;
  qrCode?: string;
}

export interface DBPurchaseAndTransferOrderDocuments {
  inbound: {
    transfer: TransferOrderByEanResponse[];
    purchase: PurchaseOrdersByEanResponse[];
  };
  outbound: {
    transfer: TransferOrderByEanResponse[];
  };
}

export enum StockListSortingType {
  defaultSorting = 'Default Sorting',
  quantityAscending = 'Quantity ascending',
  quantityDescending = 'Quantity descending',
}

interface Products {
  key: string;
  qty?: number;
}

export interface StockListBody {
  code: string;
  products: Products[];
}

export interface CartProducts {
  branch: {
    branchName: string;
    id: number;
  };
  branchId: number;
  clientId: string;
  ean: string;
  id: string;
  ipc: string;
  product: {
    productName: string;
    uid: string;
  };
  quantity: number;
  sku: string;
  warehouseAreaId: string;
  requiredQuantity?: number;
  price: number | null;
  calculatedPrice: Record<string, Partial<CheckPriceRes>>;
}

interface DBStockListPayload {
  filters: DBStockListFilters;
  pageSize: number;
  page: number;
}

export interface DBStockListFilters {
  categoryId?: string;
  subcategoryId?: string;
  brand?: string[];
  season?: string[];
  wheelPosition?: string[];
  specialMarking?: string[];
  onlyAvailable?: string;
  stock?: string;
  warehouseId?: string;
  sorting?: string;
  searchType?: string;
  searchKeyword?: string;
  eanList?: string[];
}

interface DBStockListOnePayload {
  id: string;
}
export type DBStockList = DBClientStockList & {
  product:
    | (DBProduct & {
        model: (DBProductModel & { category: DBProductCategory | null }) | null;
      })
    | null;
};

export interface InputSelectType {
  id: number | null;
  name: string | undefined;
  parentCategory?: number;
}

export interface Filters {
  categories: InputSelectType[];
  subcategories: InputSelectType[];
  brands: string[];
  seasons: string[];
  wheelPositions: string[];
  specialMarkings: string[];
  branches: InputSelectType[];
}

export interface MainBranchStock {
  currentBranchId: number;
  mainBranchName: string;
  locations: MainBranchLocations[];
}

export interface MainBranchLocations {
  productionYear: string;
  dot: string;
  ean: string;
  locationMeta: {
    qrCode: string;
    displayName: string;
    zoneColor: string;
    subzoneType: string;
    shelfDescription: string;
    subzoneStagingType: string;
  };
  shelfId: string | null;
  binId: string | null;
  productDimensions: ProductDimensions;
  clientStockList: {
    reserved: number;
    quantity: number;
    branchId: number;
    id: string;
  };
  quantity: number;
  reserved: number;
  putaway?: number;
}

export interface ProductShape {
  productName: string;
  ean: string;
  quantity: number;
  dot: string;
  reserved: number;
  category: string;
}

export interface ProductsOnLocationResponse {
  locationMeta: LocationMeta;
  productDetails: ProductShape[];
}

export interface UpdateStockItem {
  key: string;
  ean: string;
  productionYear: string;
  dot: string;
  branchId: number;
  oldQuantity: number;
  quantity: number;
  reserved: number;
  putaway: number;
  moveToNewLocation: boolean;
  location: {
    id: string;
    qrCode: string;
  };
  quantityOnNewLocation: number;
  reservedOnNewLocation: number;
  putawayOnNewLocation: number;
  locationType: LocationType;
  shouldSkip: boolean;
  oldPutaway: number;
  oldReserved: number;
}

export type LocationType = 'SHELF' | 'BIN';
export interface ImportStockListReq {
  createdBy: string;
  product: PosProductResponse;
  location: DBWarehouseShelfs;
  stockList: {
    clientId: string;
    branchId: number;
    warehouseAreaId: string;
    productId: string;
    ean: string;
    ipc: string;
    sku: string;
    productionYear: string;
    dot: string;
    quantity: number;
    demo: boolean;
    favoritedBy: string[];
    reserved: number;
    upcoming: number;
    putaway: number;
    price: number;
    calculatedPrice: CalculatePriceRes;
  };
}

export interface ImportStockListRes {
  requested: number;
  _errors: Record<string, string>;
}

export interface DBStockListApi {
  list: {
    requestBody: DBStockListPayload;
    request: DBStockListPayload;
    response: DBStockList[];
  };
  getOne: {
    requestBody: DBStockListOnePayload;
    requestParams: DBStockListOnePayload;
    request: DBStockListOnePayload;
    response: DBClientStockList & {
      product: DBProduct & {
        model: DBProductModel & { category: DBProductCategory };
      };
    };
  };
  create: {
    requestBody: DBCreateStockListItem;
    requestParams: never;
    request: DBCreateStockListItem;
    response: DBClientStockList;
  };
  updateOne: {
    requestBody: UpdateStockItem[];
    requestParams: never;
    request: UpdateStockItem[];
    response: DBClientStockList;
  };
  getFilters: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: Filters;
  };
  getProductsOnLocation: {
    requestBody: never;
    requestParams: {
      warehouseId: number;
      location: string;
    };
    request: {
      warehouseId: number;
      location: string;
    };
    response: ProductsOnLocationResponse;
  };
  createMore: {
    requestBody: DBCreateStockListItem[];
    requestParams: never;
    request: DBCreateStockListItem[];
    response: DBClientStockList[];
  };
  byProductIds: {
    requestBody: { productIds: string[] };
    requestParams: { productIds: string[] };
    request: { productIds: string[] };
    response: { productId: string; reserved: number; quantity: number }[];
  };
}
