import { DBProductCategory } from '@prisma/client';
import * as _ from 'lodash';
const BASE_CAT_ATTRIBUTES: Record<
  string,
  {
    model_attributes: Record<string, number>;
    item_attributes: Record<string, number>;
  }
> = {
  carTyres: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 2,
      width2: -1,
      height: 2,
      construction: 2,
      rimDiameter: 2,
      rimDiameter2: -2,
      size: 2,
      size2: -2,
      loadIndex: 1,
      speedIndex: 2,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: 0,
      threepmfs: 0,
      iceGrip: 0,
      tubeType: 0,
      pr: 0,
      rfd: 0,
      structure: -2,
      compound: 0,
      commMark: 0,
      oem: 0,
      runFlat: 0,
      seal: 0,
      silent: 0,
      flank: 0,
      electric: 0,
      demo: 0,
      euDirectiveNumber: 1,
      rollingResistance: 1,
      wetGrip: 1,
      noisePerformance: 1,
      noiseClassType: 1,
      vehicleClass: 0,
      eprelId: 0,
      eprelUrl: 0,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: 2,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: -1,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: 0,
      sidewall: 0,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  wheels: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 2,
      height: -2,
      construction: -2,
      rimDiameter: 2,
      rimDiameter2: -2,
      size: 2,
      size2: 0,
      loadIndex: -2,
      speedIndex: -2,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: -2,
      threepmfs: -2,
      iceGrip: -2,
      tubeType: -2,
      pr: -2,
      rfd: -2,
      structure: -2,
      compound: -2,
      commMark: -2,
      euDirectiveNumber: -2,
      rollingResistance: -2,
      wetGrip: -2,
      noisePerformance: -2,
      noiseClassType: -2,
      vehicleClass: -2,
      eprelId: -2,
      eprelUrl: -2,
      holeNumber: 2,
      holeCircle: 2,
      pcd: 2,
      et: 2,
      cb: 2,
      installationKit: 1,
      vehicleBrand: 0,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: 0,
      concave: 0,
      uid: 2,
      category_id: 2,
      season: 0,
      brand: 2,
      modelName: 2,
      usage: -1,
      color: 2,
      colorGroup: 1,
      colorFinish: 1,
      wheelPosition: -1,
      sidewall: -1,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  motoTyres: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 2,
      width2: -1,
      height: 2,
      construction: 2,
      rimDiameter: 2,
      rimDiameter2: -2,
      size: 2,
      size2: -2,
      loadIndex: 2,
      speedIndex: 2,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: 0,
      oem: 0,
      threepmfs: -2,
      iceGrip: -2,
      tubeType: 1,
      pr: -2,
      rfd: 0,
      structure: 0,
      compound: 0,
      commMark: -2,
      euDirectiveNumber: -2,
      rollingResistance: -2,
      wetGrip: -2,
      noisePerformance: -2,
      noiseClassType: -2,
      vehicleClass: -2,
      eprelId: -2,
      eprelUrl: -2,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: 0,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: -1,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: 2,
      sidewall: 0,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  atvTyres: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 2,
      width2: -1,
      height: 2,
      construction: 2,
      rimDiameter: 2,
      rimDiameter2: -2,
      size: 2,
      size2: -2,
      loadIndex: 0,
      speedIndex: 0,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: -1,
      threepmfs: -2,
      iceGrip: -2,
      tubeType: 1,
      pr: 0,
      rfd: 0,
      structure: 0,
      compound: 0,
      commMark: -2,
      euDirectiveNumber: -2,
      rollingResistance: -2,
      wetGrip: -2,
      noisePerformance: -2,
      noiseClassType: -2,
      vehicleClass: -2,
      eprelId: -2,
      eprelUrl: -2,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: 0,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: -1,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: 2,
      sidewall: 0,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  bicycleTyres: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 2,
      width2: 2,
      height: -2,
      construction: 0,
      rimDiameter: 2,
      rimDiameter2: 1,
      size: 2,
      size2: 1,
      loadIndex: -2,
      speedIndex: -2,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: -2,
      threepmfs: -2,
      iceGrip: -2,
      tubeType: 1,
      pr: -2,
      rfd: -2,
      structure: -2,
      compound: 0,
      commMark: -2,
      euDirectiveNumber: -2,
      rollingResistance: -2,
      wetGrip: -2,
      noisePerformance: -2,
      noiseClassType: -2,
      vehicleClass: -2,
      eprelId: -2,
      eprelUrl: -2,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: -1,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: 0,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: 2,
      sidewall: 0,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  agroTyres: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 2,
      width2: -1,
      height: 2,
      construction: 2,
      rimDiameter: 2,
      rimDiameter2: -2,
      size: 2,
      size2: -2,
      loadIndex: 1,
      speedIndex: 1,
      loadIndex2: 0,
      speedIndex2: 0,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: 0,
      threepmfs: -2,
      iceGrip: -2,
      tubeType: 0,
      pr: 0,
      rfd: 0,
      structure: 0,
      compound: -2,
      commMark: -2,
      euDirectiveNumber: -2,
      rollingResistance: -2,
      wetGrip: -2,
      noisePerformance: -2,
      noiseClassType: -2,
      vehicleClass: -2,
      eprelId: -2,
      eprelUrl: -2,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: 0,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: -1,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: 0,
      sidewall: -1,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  truckTyres: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 2,
      width2: -1,
      height: 2,
      construction: 2,
      rimDiameter: 2,
      rimDiameter2: -2,
      size: 2,
      size2: -2,
      loadIndex: 1,
      speedIndex: 2,
      loadIndex2: 0,
      speedIndex2: 0,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: 0,
      threepmfs: 0,
      iceGrip: 0,
      tubeType: 0,
      pr: 0,
      rfd: 0,
      structure: 0,
      compound: -2,
      commMark: 0,
      euDirectiveNumber: 1,
      rollingResistance: 1,
      wetGrip: 1,
      noisePerformance: 1,
      noiseClassType: 1,
      vehicleClass: 0,
      eprelId: 0,
      eprelUrl: 0,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: 2,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: -1,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: 0,
      sidewall: 0,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  tubes: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: -2,
      width2: -1,
      height: -2,
      construction: -2,
      rimDiameter: 2,
      rimDiameter2: -2,
      size: -2,
      size2: -2,
      loadIndex: -2,
      speedIndex: -2,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: -2,
      threepmfs: -2,
      iceGrip: -2,
      tubeType: -2,
      pr: -2,
      rfd: -2,
      structure: -2,
      compound: -2,
      commMark: -2,
      euDirectiveNumber: -2,
      rollingResistance: -2,
      wetGrip: -2,
      noisePerformance: -2,
      noiseClassType: -2,
      vehicleClass: -2,
      eprelId: -2,
      eprelUrl: -2,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: -2,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: -1,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: -1,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: -1,
      sidewall: -1,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
  other: {
    item_attributes: {
      dataSource: 2,
      uid: 2,
      ean: 2,
      manufacturerCode: 2,
      sku: 2,
      rfid: 0,
      productModelUid: 2,
      productName: 2,
      productName2: 0,
      width: 0,
      width2: -1,
      height: 0,
      construction: -2,
      rimDiameter: -2,
      rimDiameter2: -2,
      size: 0,
      size2: -2,
      loadIndex: -2,
      speedIndex: -2,
      specialMarking: 0,
      specialMarkingType: 0,
      ms: -2,
      threepmfs: -2,
      iceGrip: -2,
      tubeType: -2,
      pr: -2,
      rfd: -2,
      structure: -2,
      compound: -2,
      commMark: -2,
      euDirectiveNumber: -2,
      rollingResistance: -2,
      wetGrip: -2,
      noisePerformance: -2,
      noiseClassType: -2,
      vehicleClass: -2,
      eprelId: -2,
      eprelUrl: -2,
      holeNumber: -2,
      holeCircle: -2,
      pcd: -2,
      et: -2,
      cb: -2,
      installationKit: -2,
      vehicleBrand: -2,
      origin: 1,
      weight: 1,
      crossSellProducts: 0,
      crossSellServices: 0,
      relatedProducts: 0,
      remark: 0,
    },
    model_attributes: {
      wheelHoles: -1,
      concave: -1,
      uid: 2,
      category_id: 2,
      season: -1,
      brand: 2,
      modelName: 2,
      usage: 0,
      color: 0,
      colorGroup: -1,
      colorFinish: -1,
      wheelPosition: -1,
      sidewall: -1,
      descTitle: 0,
      descSubtitle: 0,
      descIntro: 0,
      descContent: 0,
      relatedModels: 0,
      videoUrl: 0,
      remark: 0,
      dataSource: 2,
    },
  },
};

const BASE_ATTRIBUTES: {
  model_attributes: Record<string, number>;
  item_attributes: Record<string, number>;
} = {
  model_attributes: {
    wheelHoles: -1,
    concave: -1,
  },
  item_attributes: {},
};

const CATEGORY_LIST: Omit<
  DBProductCategory,
  'model_attributes' | 'item_attributes' | 'active' | 'non_deletable'
>[] = [
  {
    id: 1,
    slug: 'carTyres',
    name: 'AUTO GUME',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '40111000',
    remark: '',
  },
  {
    id: 2,
    slug: 'motoTyres',
    name: 'MOTO GUME',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '40114000',
    remark: '',
  },
  {
    id: 3,
    slug: 'atvTyres',
    name: 'ATV GUME',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '40114000',
    remark: '',
  },
  {
    id: 4,
    slug: 'truckTyres',
    name: 'TERETNE GUME',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '40112090',
    remark: '',
  },
  {
    id: 5,
    slug: 'bicycleTyres',
    name: 'BICIKL GUME',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '40115000',
    remark: '',
  },
  {
    id: 6,
    slug: 'goKartTyres',
    name: 'GOKART GUME',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '40111000',
    remark: '',
  },
  {
    id: 7,
    slug: 'agroTyres',
    name: 'AGRO GUME',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '40119000',
    remark: '',
  },
  {
    id: 8,
    slug: 'wheels',
    name: 'FELGE',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '87087050',
    remark: '',
  },
  {
    id: 9,
    slug: 'tubes',
    name: 'ZRAČNICE',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '',
    remark: '',
  },
  {
    id: 10,
    slug: 'valves',
    name: 'VENTILI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: true,
    taric_code: '',
    remark: '',
  },
  {
    id: 11,
    slug: 'snowChains',
    name: 'LANCI ZA SNIJEG',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 12,
    slug: 'thule',
    name: 'THULE',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 13,
    slug: 'additives',
    name: 'ADITIVI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 14,
    slug: 'batterries',
    name: 'AKUMULATORI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 15,
    slug: 'tools',
    name: 'ALATI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 16,
    slug: 'airSuspensions',
    name: 'AMORTIZERI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 17,
    slug: 'coolants',
    name: 'ANTIFRIZ',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 18,
    slug: 'bicycles',
    name: 'BICIKLI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 19,
    slug: 'accessories',
    name: 'DODATNA OPREMA',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 20,
    slug: 'filters',
    name: 'FILTERI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 21,
    slug: 'exhaustSystem',
    name: 'ISPUŠNI SUSTAVI',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 22,
    slug: 'brakeSystem',
    name: 'KOČIONI SUSTAV',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 23,
    slug: 'cosmetics',
    name: 'KOZMETIKA',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 24,
    slug: 'wipers',
    name: 'METLICE BRISAČA',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 25,
    slug: 'motorOils',
    name: 'MOTORNA ULJA',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 26,
    slug: 'workClothing',
    name: 'RADNA ODJEĆA',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 27,
    slug: 'lights',
    name: 'RASVJETA',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 28,
    slug: 'tyreShopEquipment',
    name: 'VULKANIZERSKA OPREMA',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 29,
    slug: 'other',
    name: 'OSTALO',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 30,
    slug: 'passenger',
    name: 'Osobna vozila',
    parent_category_id: 1,
    parent_category_name: 'AUTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 31,
    slug: 'suv',
    name: '4x4 / Suv vozila',
    parent_category_id: 1,
    parent_category_name: 'AUTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 32,
    slug: 'van',
    name: 'Kombi vozila',
    parent_category_id: 1,
    parent_category_name: 'AUTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 33,
    slug: 'racingCarTyres',
    name: 'Trkaće auto',
    parent_category_id: 1,
    parent_category_name: 'AUTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 34,
    slug: 'spare',
    name: 'Rezervna guma',
    parent_category_id: 1,
    parent_category_name: 'AUTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 35,
    slug: 'otherCarTyres',
    name: 'Ostalo',
    parent_category_id: 1,
    parent_category_name: 'AUTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 36,
    slug: 'motorcycle',
    name: 'Motocikli',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 37,
    slug: 'scooter',
    name: 'Skuteri',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 38,
    slug: 'eScooter',
    name: 'Elektični skuteri',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 39,
    slug: 'moped',
    name: 'Mopedi',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 40,
    slug: 'racingMotoTyres',
    name: 'Trkaće moto',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 41,
    slug: 'mini',
    name: 'Mini moto',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 42,
    slug: 'otherMotoTyres',
    name: 'Ostalo',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 43,
    slug: 'mud',
    name: 'Blato',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 44,
    slug: 'sand',
    name: 'Pijesak',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 45,
    slug: 'rock',
    name: 'Kamen',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 46,
    slug: 'grass',
    name: 'Trava',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 47,
    slug: 'hardTerrain',
    name: 'Tvrdi tereni',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 48,
    slug: 'allTerrain',
    name: 'Svi tereni',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 49,
    slug: 'otherAtvTyres',
    name: 'Ostalo',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 50,
    slug: 'truck',
    name: 'Kamioni',
    parent_category_id: 4,
    parent_category_name: 'TERETNE GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 51,
    slug: 'bus',
    name: 'Autobusi',
    parent_category_id: 4,
    parent_category_name: 'TERETNE GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 52,
    slug: 'dumper',
    name: 'Damperi',
    parent_category_id: 4,
    parent_category_name: 'TERETNE GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 53,
    slug: 'forklift',
    name: 'Viličari',
    parent_category_id: 4,
    parent_category_name: 'TERETNE GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 54,
    slug: 'otherTruckTyres',
    name: 'Ostalo',
    parent_category_id: 4,
    parent_category_name: 'TERETNE GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 55,
    slug: 'mtb',
    name: 'MTB',
    parent_category_id: 5,
    parent_category_name: 'BICIKL GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 56,
    slug: 'road',
    name: 'Cesta',
    parent_category_id: 5,
    parent_category_name: 'BICIKL GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 57,
    slug: 'bmx',
    name: 'BMX',
    parent_category_id: 5,
    parent_category_name: 'BICIKL GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 58,
    slug: 'trekking',
    name: 'Treking',
    parent_category_id: 5,
    parent_category_name: 'BICIKL GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 59,
    slug: 'gravel',
    name: 'Gravel',
    parent_category_id: 5,
    parent_category_name: 'BICIKL GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 60,
    slug: 'eBike',
    name: 'E-bicikli',
    parent_category_id: 5,
    parent_category_name: 'BICIKL GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 61,
    slug: 'otherBicycleTyres',
    name: 'Ostalo',
    parent_category_id: 5,
    parent_category_name: 'BICIKL GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 62,
    slug: 'rental',
    name: 'GoKart najam',
    parent_category_id: 6,
    parent_category_name: 'GOKART GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 63,
    slug: 'racingKart',
    name: 'GoKart utrke',
    parent_category_id: 6,
    parent_category_name: 'GOKART GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 64,
    slug: 'otherKart',
    name: 'Ostalo',
    parent_category_id: 6,
    parent_category_name: 'GOKART GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 65,
    slug: 'tractors',
    name: 'Traktori',
    parent_category_id: 7,
    parent_category_name: 'AGRO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 66,
    slug: 'turf',
    name: 'Travnati tereni',
    parent_category_id: 7,
    parent_category_name: 'AGRO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 67,
    slug: 'wheelBarrow',
    name: 'Građevinska kolica',
    parent_category_id: 7,
    parent_category_name: 'AGRO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 68,
    slug: 'golf',
    name: 'Golf tereni',
    parent_category_id: 7,
    parent_category_name: 'AGRO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 69,
    slug: 'worksite',
    name: 'Gradiilšte',
    parent_category_id: 7,
    parent_category_name: 'AGRO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 70,
    slug: 'industrial',
    name: 'Industrijske',
    parent_category_id: 7,
    parent_category_name: 'AGRO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 71,
    slug: 'otherAgroTyres',
    name: 'Ostalo',
    parent_category_id: 7,
    parent_category_name: 'AGRO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 72,
    slug: 'alloyWheels',
    name: 'Alu felge',
    parent_category_id: 8,
    parent_category_name: 'FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 73,
    slug: 'steelWheels',
    name: 'Čelične felge',
    parent_category_id: 8,
    parent_category_name: 'FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 74,
    slug: 'spareWheels',
    name: 'Rezervna felga',
    parent_category_id: 8,
    parent_category_name: 'FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 75,
    slug: 'otheWheels',
    name: 'Ostalo',
    parent_category_id: 8,
    parent_category_name: 'FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 76,
    slug: 'carTubes',
    name: 'Auto zračnice',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '40131000',
    remark: '',
  },
  {
    id: 77,
    slug: 'motoTubes',
    name: 'Moto zračnice',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '40139000',
    remark: '',
  },
  {
    id: 78,
    slug: 'scooterTubes',
    name: 'Zračnice za skuter',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '40139000',
    remark: '',
  },
  {
    id: 79,
    slug: 'eScooterTubes',
    name: 'Zračnice za električni skuter',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 80,
    slug: 'atvTubes',
    name: 'Atv zračnice',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 81,
    slug: 'bicycleTubes',
    name: 'Bicikl zračnice',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '40132000',
    remark: '',
  },
  {
    id: 82,
    slug: 'truckTubes',
    name: 'Zračnice za kamione',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 83,
    slug: 'mousse',
    name: 'Mousse',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '40161000',
    remark: '',
  },
  {
    id: 84,
    slug: 'accessoriesTubes',
    name: 'Dodaci za zračnice',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 85,
    slug: 'agroTubes',
    name: 'Agro zračnice',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '40131000',
    remark: '',
  },
  {
    id: 86,
    slug: 'otherTubes',
    name: 'Ostalo',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 87,
    slug: 'tubelessValve',
    name: 'Tubeles ventili',
    parent_category_id: 10,
    parent_category_name: 'VENTILI',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 88,
    slug: 'tpmsValve',
    name: 'TPMS ventili',
    parent_category_id: 10,
    parent_category_name: 'VENTILI',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 89,
    slug: 'accessoriesValve',
    name: 'Dodaci za ventile',
    parent_category_id: 10,
    parent_category_name: 'VENTILI',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 90,
    slug: 'chainsPassenger',
    name: 'Osobna vozila',
    parent_category_id: 11,
    parent_category_name: 'LANCI ZA SNIJEG',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 91,
    slug: 'vanSnowChains',
    name: 'Kombi vozila',
    parent_category_id: 11,
    parent_category_name: 'LANCI ZA SNIJEG',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 92,
    slug: 'suvSnowChains',
    name: 'Suv vozila',
    parent_category_id: 11,
    parent_category_name: 'LANCI ZA SNIJEG',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 93,
    slug: 'truckSnowChains',
    name: 'Teretna vozila',
    parent_category_id: 11,
    parent_category_name: 'LANCI ZA SNIJEG',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 94,
    slug: 'otherSnowChains',
    name: 'Ostalo',
    parent_category_id: 11,
    parent_category_name: 'LANCI ZA SNIJEG',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 95,
    slug: 'skiCarrier',
    name: 'Nosači za skije',
    parent_category_id: 12,
    parent_category_name: 'THULE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 96,
    slug: 'bikeCarrier',
    name: 'Nosači za bicikle',
    parent_category_id: 12,
    parent_category_name: 'THULE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 97,
    slug: 'roofBoxes',
    name: 'Krovne kutije',
    parent_category_id: 12,
    parent_category_name: 'THULE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 98,
    slug: 'accessoriesThule',
    name: 'Dodaci za Thule',
    parent_category_id: 12,
    parent_category_name: 'THULE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 99,
    slug: 'chopper',
    name: 'Chopper',
    parent_category_id: 2,
    parent_category_name: 'MOTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 100,
    slug: 'trailer',
    name: 'Trajer',
    parent_category_id: 1,
    parent_category_name: 'AUTO GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 101,
    slug: 'bags',
    name: 'Vreće',
    parent_category_id: 19,
    parent_category_name: 'DODATNA OPREMA',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 102,
    slug: 'wheelAccessories',
    name: 'DODACI ZA FELGE',
    parent_category_id: null,
    parent_category_name: null,
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 103,
    slug: 'wheelBolts',
    name: 'Vide za felge',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 104,
    slug: 'wheelSpacers',
    name: 'Distanceri',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 105,
    slug: 'wheelSticker',
    name: 'Naljepnica za felge',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 106,
    slug: 'wheelCap',
    name: 'Poklopac za felge',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 107,
    slug: 'wheelRings',
    name: 'Prstenovi',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 108,
    slug: 'wheelNuts',
    name: 'Matice za felge',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 109,
    slug: 'wheelNutsLocks',
    name: 'Sigurnosne matice',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 110,
    slug: 'wheelBoltsLocks',
    name: 'Sigurnosni vijci',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 111,
    slug: 'otherWheelAccesories',
    name: 'Ostali dodaci',
    parent_category_id: 102,
    parent_category_name: 'DODACI ZA FELGE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 112,
    slug: 'otherAccessories',
    name: 'Ostala dodatna oprema',
    parent_category_id: 19,
    parent_category_name: 'DODATNA OPREMA',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 113,
    slug: 'roadTerrain',
    name: 'Cesta',
    parent_category_id: 3,
    parent_category_name: 'ATV GUME',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 114,
    slug: 'otherThule',
    name: 'Ostala oprema za Thule',
    parent_category_id: 12,
    parent_category_name: 'THULE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 115,
    slug: 'goKartTubes',
    name: 'GoKart zračnice',
    parent_category_id: 9,
    parent_category_name: 'ZRAČNICE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
  {
    id: 116,
    slug: 'fittingKitThule',
    name: 'Thule kit',
    parent_category_id: 12,
    parent_category_name: 'THULE',
    is_tyrio_catalog: false,
    taric_code: '',
    remark: '',
  },
];
export const categories: DBProductCategory[] = CATEGORY_LIST.map(
  (item, idx) => {
    let attributes = BASE_ATTRIBUTES;

    const id = idx + 1;
    if (id === 1 || item.parent_category_id === 1) {
      attributes = BASE_CAT_ATTRIBUTES['carTyres'];
    } else if (id === 8 || item.parent_category_id === 8) {
      attributes = BASE_CAT_ATTRIBUTES['wheels'];
    } else if (id === 2 || item.parent_category_id === 2) {
      attributes = BASE_CAT_ATTRIBUTES['motoTyres'];
    } else if (id === 3 || item.parent_category_id === 3) {
      attributes = BASE_CAT_ATTRIBUTES['atvTyres'];
    } else if (id === 5 || item.parent_category_id === 5) {
      attributes = BASE_CAT_ATTRIBUTES['bicycleTyres'];
    } else if (id === 7 || item.parent_category_id === 7) {
      attributes = BASE_CAT_ATTRIBUTES['agroTyres'];
    } else if (id === 4 || item.parent_category_id === 4) {
      attributes = BASE_CAT_ATTRIBUTES['truckTyres'];
    } else if (id === 9 || item.parent_category_id === 9) {
      attributes = BASE_CAT_ATTRIBUTES['tubes'];
    } else {
      attributes = BASE_CAT_ATTRIBUTES['other'];
    }

    return {
      ...item,
      ...attributes,
      active: true,
      non_deletable: true,
      id: id,
    };
  }
);

export const categorySlugMap = _.chain(categories).keyBy('slug').value();
