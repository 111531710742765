import { DBSupplier, DBSupplierType } from '@prisma/client';

export const suppliers: DBSupplier[] = [
  {
    id: '00000000-0000-0000-0000-000000000006',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0006',
    vies: true,
    companyOfficialName: 'Vredestein',
    companyShortName: 'Vredestein',
    slug: 'Vredestein',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: 'dd22bff3-1cec-4816-86d8-9779b5b0290d',
  },
  {
    id: '00000000-0000-0000-0000-000000000007',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0007',
    vies: true,
    companyOfficialName: 'Kumho Tires',
    companyShortName: 'Kumho',
    slug: 'Kumho',
    countryId: '67e923b6-e851-8c60-89dc-38aa385bd7e7',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: '4091db0b-78fb-47c9-a3b3-143fba0240fe',
  },
  {
    id: '00000000-0000-0000-0000-000000000001',
    supplierType: DBSupplierType.MANUFACTURER,
    vatNumber: '0001',
    vies: true,
    companyOfficialName: 'Firestone Tires',
    companyShortName: 'Firestone',
    slug: 'Firestone',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '21000',
    city: 'Split',
    address: 'Josipa Jovica 1',
    remark: 'firestone remark',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: 'ccb395be-2352-4da5-8511-13b8b7d3fdb4',
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0002',
    vies: true,
    companyOfficialName: 'Michelin',
    companyShortName: 'Michelin',
    slug: 'Michelin',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: '5e38574d-78fa-4f64-95b3-224e06651960',
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0003',
    vies: true,
    companyOfficialName: 'Pirelli',
    companyShortName: 'Pirelli',
    slug: 'Pirelli',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: 'cf3a5dc1-45f0-4e4b-8f5a-d6ecc9ef3a5a',
  },
  {
    id: '00000000-0000-0000-0000-000000000004',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0004',
    vies: true,
    companyOfficialName: 'Good Year',
    companyShortName: 'Goodyear',
    slug: 'Goodyear',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: '1789fd1a-4f50-4bc9-9cd3-725f97a71e6d',
  },
  {
    id: '00000000-0000-0000-0000-000000000008',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0014',
    vies: true,
    companyOfficialName: 'Bridgestone',
    companyShortName: 'Bridgestone',
    slug: 'Bridgestone',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: 'e98b28cf-8344-40c6-b774-04b93461e45b',
  },
  {
    id: '00000000-0000-0000-0000-000000000009',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0015',
    vies: true,
    companyOfficialName: 'Continental BIH',
    companyShortName: 'Continental',
    slug: 'Continental',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: 'f1319c0e-33cb-4d49-8a4e-40cc9a8ddc78',
  },
  {
    id: '537e4b56-efba-4bb6-bdbb-9cee1dd38b53',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '12345',
    vies: false,
    companyOfficialName: 'Van Den Ban',
    companyShortName: 'Vandenban',
    slug: 'Vandenban',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Sarajevo',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: 'f4ffa54d-8e55-480e-a2ba-18e73e622e07',
  },
  {
    id: 'd80ae0db-ffa8-4a96-9624-dc16da96be01',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '12345',
    vies: false,
    companyOfficialName: 'GMP Italia',
    companyShortName: 'GMP',
    slug: 'gmp-slug',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Sarajevo',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: '3f6ab3a7-7ac9-42fa-8844-092f221498c9',
  },
  {
    id: '00000000-0000-0000-0000-000000000005',
    supplierType: DBSupplierType.DISTRIBUTER,
    vatNumber: '0005',
    vies: true,
    companyOfficialName: 'Gundlach',
    companyShortName: 'Gundlach',
    slug: 'Gundlach',
    countryId: '5fd08dcc-2944-841f-e8a9-9fc180d18ca8',
    zipCode: '71000',
    city: 'Sarajevo',
    address: 'Ferhadija',
    remark: '',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    isTyrioClient: false,
    companyLogoId: 'e23b5b0d-7c3b-4033-ae76-a03a35878db4',
  },
];
