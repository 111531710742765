import styled from '@emotion/styled/macro';
import { CircularProgress } from '@mui/material';
import { DBOrderDocument } from '@prisma/client';
import { DBCustomerOrderItemResponse, DBOrderDocumentApi } from '@tyrio/dto';
import {
  addDocument,
  DeleteModal,
  documentIcon,
  pdfFile,
} from '@tyrio/ui-library';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PrintProvider } from '../../context/PrintContext';
import { WorkOrderDetails } from '../../features/work-orders/WorkOrderDetails';
import { LoaderWrapper } from '../PageTemplate/PageTemplate';
import { DocumentCard } from './DocumentCard';

interface UploadDocumentsProps {
  orderDocuments: DBOrderDocumentApi['list']['response'] | undefined;
  onOpen: (acceptedFiles: File[]) => void;
  onDelete: (id: string) => void;
  isLoading: boolean;
  selectedOrderData?: DBCustomerOrderItemResponse;
  generatedPdf?: string;
  hasConnectedOrder?: boolean;
  connectedOrders?: {
    fileName: string;
    url: string;
    chipLabel: string;
    documentIcon: string;
    reference: string;
  }[];
}

const UploadDocuments = ({
  orderDocuments,
  onOpen,
  onDelete,
  isLoading,
  selectedOrderData,
  generatedPdf,
  hasConnectedOrder,
  connectedOrders,
}: UploadDocumentsProps) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: 25 * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      onOpen(acceptedFiles);
    },
  });
  const [isHovered, setIsHovered] = useState<Record<string, boolean>>({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [documentName, setDocumentName] = useState('');

  const documentDetails = useMemo(() => {
    const pdfUrl = generatedPdf?.split('=');
    let reference = '';
    let printType = 'delivery-note';
    if (pdfUrl) {
      reference = pdfUrl[6];
      printType = pdfUrl[5];
    }
    return { reference, printType };
  }, [generatedPdf]);

  const { reference, printType } = documentDetails;

  const handleDelete = (item: DBOrderDocument) => {
    setDocumentName(item?.name ?? '');
    setDocumentId(item?.id);
    setIsDeleteModalVisible(true);
  };

  return (
    <RelatedDocumentsWrapper>
      <PrintProvider>
        {isDeleteModalVisible && (
          <DeleteModal
            LBAction={() => setIsDeleteModalVisible(false)}
            RBAction={() => {
              onDelete(documentId);
              setIsDeleteModalVisible(false);
            }}
            itemName={documentName}
          />
        )}
        <SelectDocumentWrapper disabled={isLoading}>
          <input style={{ display: 'none' }} {...getInputProps()} />
          <ImgWrapper>
            <Image src={addDocument} alt="add-document-icon" onClick={open} />
          </ImgWrapper>
          <TextWrapper>
            <SelectDocumentText>Select document</SelectDocumentText>
            <DropFilesText>
              <div>Drop files</div>
              <BrowseTextWrapper {...getRootProps()}>browse</BrowseTextWrapper>
              <div>through your machine</div>
            </DropFilesText>
          </TextWrapper>
        </SelectDocumentWrapper>
        {isLoading && (
          <UploadInProgressWrapper>
            <LoaderWrapper
              style={{
                height: '15px',
                width: '15px',
              }}
            >
              <CircularProgress style={{ height: '15px', width: '15px' }} />
            </LoaderWrapper>
            <UploadingText>Uploading files, please be patient!</UploadingText>
          </UploadInProgressWrapper>
        )}

        <DocumentsWrapper disabled={isLoading}>
          {/* uploaded files */}
          {orderDocuments &&
            orderDocuments.map((item: DBOrderDocument) => (
              <DocumentCard
                url={item.url}
                idx={item.id}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                documentIcon={
                  item.type === 'application/pdf' ? pdfFile : documentIcon
                }
                itemType={item.type ?? ''}
                showDelete={true}
                fileName={item.name ?? ''}
                reference={item.type ?? ''}
                onDelete={() => handleDelete(item)}
              />
            ))}

          {/* generated delivery notes */}
          {generatedPdf && (
            <DocumentCard
              url={generatedPdf}
              idx={reference}
              reference={reference}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              hasMarginTop={
                orderDocuments !== undefined && orderDocuments.length > 0
              }
              fileName={
                printType === 'MEĐUSKLADIŠNICA'
                  ? 'Međuskladišnica'
                  : 'Otpremnica'
              }
              documentIcon={pdfFile}
              showArrowIcon={true}
            />
          )}

          {/* connected orders */}
          {hasConnectedOrder &&
            connectedOrders &&
            connectedOrders?.length > 0 &&
            connectedOrders?.map((item) => (
              <DocumentCard
                url={item.url}
                idx={'customer-order'}
                reference={item.reference}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                hasMarginTop={true}
                fileName={item?.fileName ?? ''}
                chipLabel={item?.chipLabel}
                documentIcon={item?.documentIcon}
                showArrowIcon={true}
                shouldDownload={false}
              />
            ))}

          {/* generated work orders delivery notes */}
          {selectedOrderData && (
            <WorkOrderDetails
              workOrders={selectedOrderData?.workOrders}
              customerOrderId={selectedOrderData.id}
            />
          )}
        </DocumentsWrapper>
      </PrintProvider>
    </RelatedDocumentsWrapper>
  );
};

const RelatedDocumentsWrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: calc(100svh - 259px);
`;

const SelectDocumentWrapper = styled.div<{ disabled: boolean }>`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  border-style: dashed;
  height: 250px !important;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  position: sticky;
  top: 0;
`;

const ImgWrapper = styled.div`
  padding-top: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
`;

const SelectDocumentText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 137.5%;
`;

const DropFilesText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6b7280;
  display: flex;
  gap: 5px;
`;

export const DocumentsWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  gap: 16px;
`;

export const DocumentDetailsWrapper = styled.div`
  border: 1px solid #d2d4d2;
  border-radius: 8px;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  &:hover {
    border: 1px solid #0990d9;
    cursor: pointer;
  }
`;

export const FileIconNameWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 30%;
`;

export const ReferenceWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 40%;
`;

export const IconsWrapper = styled.div<{ isHovered?: boolean }>`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  cursor: ${(props) => (props.isHovered ? 'pointer' : 'default')};
`;

const BrowseTextWrapper = styled.div`
  border-bottom: 1px solid #1652f7;
  padding-bottom: 0;
  color: #1652f7;
  &:hover {
    cursor: pointer;
  }
`;

const UploadInProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const UploadingText = styled.p`
  font-size: 15px;
`;

const Image = styled.img``;

export default UploadDocuments;
