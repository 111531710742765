import styled from '@emotion/styled';
import { Alert, Chip, Grid, Tooltip } from '@mui/material';
import { WarehouseDetailsRes, ZoneStagingType } from '@tyrio/dto';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import LocationProducts from './LocationProducts';

interface LocationProps {
  type: ZoneStagingType;
  location: WarehouseDetailsRes;
  count: number;
}

export const Location = ({ type, location, count }: LocationProps) => {
  const [hoveredId, setHoveredId] = useState<string | undefined>(undefined);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  function percentage(x: number, y: number) {
    return ((x / y) * 100).toFixed(2);
  }

  const getOpacity = () => {
    if (count === 1) return 1;

    if (!hoveredId) return 0.1;

    return 1;
  };

  const getDotInfo = (dot: string | null) => {
    const productionYearFromDot = !dot?.includes('/')
      ? dot?.slice(2, 4)
      : dot?.slice(3, 5);

    if (productionYearFromDot && productionYearFromDot !== 'XX') {
      const currentYear = new Date().getFullYear();

      const productionYear =
        Math.floor(currentYear / 100) * 100 + Number(productionYearFromDot);

      if (currentYear - productionYear > 3) {
        return true;
      }
    }

    return false;
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <Container>
      <LocationProducts
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        <Grid sx={{ padding: '12px', width: '200px' }}>
          {Object.keys(location.eans).map((item) => (
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <EanQty
                onClick={() => {
                  navigator.clipboard.writeText(item);
                  ToastHelper.showToast(
                    'Location details',
                    ToastType.SUCCESS,
                    undefined,
                    `${item} copied to clipboard!`
                  );
                }}
              >
                {item}
              </EanQty>
              <EanQty>{location.eans[item].qty}</EanQty>
            </Grid>
          ))}
        </Grid>
      </LocationProducts>
      {type === ZoneStagingType.FITTING && (
        <BoxInfo>{location.subzoneName}</BoxInfo>
      )}
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid
          sx={{
            height: '50px',
            width: '8px',
            backgroundColor: location.color,
            marginRight: '8px',
          }}
        />
        <LocationText>{location.displayName}</LocationText>
      </Grid>
      {type !== ZoneStagingType.STATIC_STORAGE && (
        <Grid
          sx={{
            marginTop: '20px',
          }}
        >
          <CountInfo onClick={handleClick} aria-describedby={id}>
            {location.count} pieces
          </CountInfo>
        </Grid>
      )}

      {type === ZoneStagingType.STATIC_STORAGE && (
        <Grid
          sx={{
            marginTop: '20px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid>
              <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <UsedInfo color="#2e7d32">{location.used}</UsedInfo>
                <UsedInfo>/</UsedInfo>
                <UsedInfo>{location.width} cm</UsedInfo>
              </Grid>
              <CountInfo onClick={handleClick} aria-describedby={id}>
                {location.count} pieces
              </CountInfo>
            </Grid>
          </Grid>
          <Grid>
            <Percentage>
              {percentage(location.used, location.width)}%
            </Percentage>
          </Grid>
        </Grid>
      )}
      {location.type === ZoneStagingType.STATIC_STORAGE && (
        <Grid>
          {location.dots.length > 0 ? (
            <Grid
              sx={{
                width: '200px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                marginTop: '10px',
                height: '40px',
              }}
            >
              {location.dots.map((dot) => {
                const isOutlet = getDotInfo(dot);

                return (
                  <Chip
                    key={dot}
                    label={dot}
                    color={isOutlet ? 'error' : 'success'}
                    size="small"
                    variant="outlined"
                  />
                );
              })}
            </Grid>
          ) : (
            <Alert severity="info" sx={{ marginTop: '10px' }}>
              No dot info!
            </Alert>
          )}
        </Grid>
      )}
      <Tooltip title="Copy QR code" followCursor>
        <Grid
          onMouseOver={() => {
            setHoveredId(location.qrCode);
          }}
          onMouseLeave={() => {
            setHoveredId(undefined);
          }}
          onClick={() => {
            navigator.clipboard.writeText(location.qrCode);
            ToastHelper.showToast(
              'Location details',
              ToastType.SUCCESS,
              undefined,
              `${location.qrCode} copied to clipboard!`
            );
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '30px',
            marginBottom: '10px',
            opacity: getOpacity(),
          }}
        >
          <QRCodeSVG value={location.qrCode} />
        </Grid>
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  border: 1.5px dashed #dfe3e8;
  padding: 12px;
  border-radius: 10px;
  width: 280px;
  cursor: pointer;
  &:hover {
    border-color: #1976d2;
  }
`;

const LocationText = styled.span`
  font-family: Barlow;
  font-size: 45px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
`;

const UsedInfo = styled.span<{ color?: string }>`
  font-family: Barlow;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: ${(props) => props.color ?? '#212B36'};
`;

const CountInfo = styled.span`
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #919eab;
  &:hover {
    text-decoration: underline;
  }
`;

const EanQty = styled.span`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Percentage = styled.span`
  font-family: Barlow;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #d32f2f;
  font-weight: bold;
`;

const BoxInfo = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #e75524;
  margin-bottom: 10px;
`;
