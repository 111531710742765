import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { DetailInfo, DetailType, Info, JustifyBetween } from './MainInfo';

interface InfoShape {
  label1: string;
  value1: string;
  label2: string;
  value2: string;
}

interface ShippingDetailsProps {
  deliveryMethod: InfoShape;
  shippingDetails: InfoShape;
  recipientName: string;
  recipientCity: string;
  hasSideBorders?: boolean;
}

export const ShippingDetails = ({
  deliveryMethod,
  shippingDetails,
  recipientName,
  recipientCity,
  hasSideBorders = false,
}: ShippingDetailsProps) => {
  return (
    <Wrapper hasSideBorders={hasSideBorders}>
      <JustifyBetween>
        <RowWrapper>
          <ColumnWrapper>
            <Text>Delivery method</Text>
            <Info>
              <DetailType>{deliveryMethod.label1}</DetailType>
              <DetailInfo>{deliveryMethod.value1}</DetailInfo>
            </Info>
            <Info>
              <DetailType>{deliveryMethod.label2}</DetailType>
              <DetailInfo>{deliveryMethod.value2}</DetailInfo>
            </Info>
          </ColumnWrapper>
          {!hasSideBorders ? (
            <ColumnWrapper>
              <Text>Shipping details</Text>
              <Info>
                <DetailType>{shippingDetails.label1}</DetailType>
                <DetailInfo>{shippingDetails.value1}</DetailInfo>
              </Info>
              <Info>
                <DetailType>{shippingDetails.label2}</DetailType>
                <DetailInfo>{shippingDetails.value2}</DetailInfo>
              </Info>
            </ColumnWrapper>
          ) : (
            <ColumnWrapper />
          )}

          <ColumnWrapper>
            <Text>Recipient</Text>
            <Text size={'12px'}>{recipientName}</Text>
            <Text size={'12px'} weight={'400'}>
              {recipientCity.toUpperCase()}
            </Text>
          </ColumnWrapper>
        </RowWrapper>
      </JustifyBetween>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hasSideBorders?: boolean }>`
  height: 90px;
  padding: 16px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-left: ${(props) => (props.hasSideBorders ? '1px solid #e6e6e6' : '')};
  border-right: ${(props) => (props.hasSideBorders ? '1px solid #e6e6e6' : '')};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100px;
`;

const Text = styled(Typography)<{ size?: string; weight?: string }>`
  font-family: Barlow;
  font-size: ${(props) => (props.size ? props.size : '14px')};
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : '500')};
`;
