import { useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { PriceComparisonRoot } from '../pages/dashboard/price-comparison/PriceComparisonRoot';
import { OrderOverviewWrapper } from '../pages/dashboard/supplier-order/OrderOverviewWrapper';
import { PriceComparisonFlow } from '../pages/price-compare/PriceComparisonFlow';
import { OrderDataInterface } from '../pages/price-compare/types';

export const PriceComparisonRouter = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const route = location.pathname.split('/').pop();

  const history = useHistory();

  const [orderData, setOrderData] = useState<OrderDataInterface>({});

  return (
    <Switch>
      {route === 'overview' ? (
        <Route exact path={`${path}/overview`}>
          <OrderOverviewWrapper partialOrderData={orderData} />
        </Route>
      ) : (
        <Route exact path={path}>
          <PriceComparisonFlow
            onProcessOrderClicked={(data) => {
              setOrderData(data);
              history.push(`/dashboard/price-comparison/overview`);
            }}
          />
        </Route>
      )}
      <Route path={`${path}/v1`}>
        <PriceComparisonRoot
          onProcessOrderClicked={(data) => {
            setOrderData(data);
            history.push(`/dashboard/price-comparison/overview`);
          }}
        />
      </Route>
    </Switch>
  );
};
