import { TRule } from '../../typings';
import { EDI_WHEEL_B4_RULESET } from '../edi-b4/catalog';
import { GLOBAL_RULESET } from '../global';

export const CONTINENTAL_CATALOG_RULESET: TRule[] = [
  // Category types
  // {
  //   type: 'rule.combine',
  //   sourceKeys: ['{{DE}}'],
  //   destination: 'product',
  //   targetKey: 'origin',
  //   delimiter: '',
  // },
  {
    type: 'rule.global.operate',
    targetKey: 'specialMarking',
    destination: 'product',
    operationName: 'generateContinentalSpecialMarkings',
  },
  ...EDI_WHEEL_B4_RULESET,
  {
    type: 'rule.replace',
    targetKey: 't.product.width',
    matchValue: ['.00', '.0'],
    replaceValue: ['', ''],
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.rimDiameter',
    matchValue: ['.00', '.0'],
    replaceValue: ['', ''],
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.rfid',
    condition: 'equals',
    conditionValue: 'N',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfid',
    outputValue: '',
    outputType: 'string',
  },
  ...GLOBAL_RULESET,
];
